<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="450px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-signal__enable-button elevation-2">
        <v-card-text style="text-align: center;">
          <h1 class="diagnostic-signal__title" :class="{'diagnostic-signal__title--signal_is_ok': signal.signal_is_ok}">
            {{ signal.signal }}
          </h1>
          <v-divider></v-divider>
          <strong>Sinal do dispositivo</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-signal">
      <v-toolbar dense class="diagnostic-signal__toolbar">
        <v-toolbar-title>
          Sinal do dispositivo
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="signal">
        <v-layout row wrap>
          <v-flex xs12 sm12 style="padding-right: 10px;">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-signal__signal diagnostic-signal__title" :class="{'diagnostic-signal__title--signal_is_ok': signal.signal_is_ok}">
                <img v-if="signal.signal_is_ok" src="/static/icons/monitor/timer_96.png" width="100px">
                <img v-else src="/static/icons/monitor/velocimetro_96.png" width="100px">
                <div>
                  <strong>Sinal: </strong> {{ signal.signal }}
                </div>
                <div>
                  <strong>Referência: </strong> {{ signal.signal_reference }}
                </div>
                <div>
                  <strong>Aceitável?: </strong> {{ signal.signal_is_ok ? 'Sim' : 'Não' }}
                </div>
                <br>
                <!-- <v-divider></v-divider>
                <div>
                  <code>{{ signal.complete }}</code>
                </div> -->
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-signal__not-found">
        <h2>Sinal não encontrado.☹️ </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DiagnosticSignal',
    data: () => ({
      dialog: false,
    }),
    props: {
      signal: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .diagnostic-signal {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-signal__enable-button {
    cursor: pointer;
  }
  .diagnostic-signal__toolbar {
    z-index: 999;
  }

  .diagnostic-signal__signal {
    text-align: center;
    font-size: 18px;
  }
  .diagnostic-signal__not-found {
    margin: 50px 0;
  }
  .diagnostic-signal__title {
    color: red;
  }
  .diagnostic-signal__title--signal_is_ok {
    color: green;
  }
</style>
