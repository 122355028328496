<template>
  <v-toolbar :dark="$wiDark" dense class="hidden-xs-only hidden-sm-only">
    <v-list two dense style="padding: 0px;">
      <v-list-tile avatar>
        <v-list-tile-avatar tile>
          <!-- <img v-if="selectedModule && selectedModule.iconSystem" :src="'static/systems/modules/' + selectedModule.iconSystem" :alt="system.name" > -->
          <!-- <img v-else :src="'static/systems/' + system.icon" :alt="system.name" /> -->
          <img :src="'static/systems/' + system.icon" :alt="system.name" />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title style="font-size:20px;">
            <span v-html="system.nameHtml"></span>
          </v-list-tile-title>
          <v-list-tile-sub-title>
            <VersionDialog></VersionDialog>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action class="hidden-xs-only hidden-sm-only" style="min-width: 1px !important;">
          <v-btn icon @click.native.stop="touggleDrawerLeftIsMini()">
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-toolbar>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import VersionDialog from '@/default/pages/MainNew/components/VersionDialog'
  export default {
    name: 'ToolbarSystem',
    computed: {
      ...mapState('Pages/Main', ['selectedModule']),
      system () {
        return this.$store.state.system || []
      }
    },
    methods: {
      ...mapActions('Pages/Main', ['touggleDrawerLeftIsMini'])
    },
    components: {
      VersionDialog
    }
  }
</script>
