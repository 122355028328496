import { MovementAction } from './Movement'
import {
  PERMISSION_STOCK_PRODUCT_REMOVAL,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CREATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_DELETE
} from '@/default/constants/permissions'

export class MovementRemoval extends MovementAction {
  constructor () {
    super()

    this.urlDetails.name = 'product-removal'

    // Change Fields
    this.editStatus.apiUrl = 'stocks/config/status/product-removal'
    this.editCategory.apiUrl = 'stocks/config/category/product-removal'
  }

  name = 'product-removal';
  id = {
    id: PERMISSION_STOCK_PRODUCT_REMOVAL,
    create: PERMISSION_STOCK_PRODUCT_REMOVAL_CREATE,
    update: PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE,
    delete: PERMISSION_STOCK_PRODUCT_REMOVAL_DELETE
  };
  title = {
    list: 'Retirada de Produtos',
    edit: {
      insert: 'Nova Retirada de Produtos',
      update: 'Editar Retirada de Produtos'
    }
  };
  apiUrl = 'stocks/product-removal';
  icon = 'group_add';

}
