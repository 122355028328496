<template>
  <div v-if="checkPermission(module.id) || menu">
    <v-list-tile avatar @click="select()">
      <v-list-tile-avatar>
        <img :src="'static/systems/modules/' + module.icon" :alt="module.title" >
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title v-if="menu" style="font-size: 14px;">
          {{ $tc('components.drawerLeft.modules.title') }}
        </v-list-tile-sub-title>
        <v-list-tile-title style="font-size:18px;">
          <span v-if="module && module.title">{{module.title}}</span>
        </v-list-tile-title>
        <v-list-tile-sub-title v-if="!menu" style="font-size: 14px;">
          {{ module.description }}
        </v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action v-if="menu">
        <v-icon>keyboard_arrow_down</v-icon>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider v-if="!menu"></v-divider>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'ModuleItem',
    data () {
      return {}
    },
    computed: {
      ...mapGetters('User', ['checkPermission']),
      system () {
        return this.$store.state.system || []
      }
    },
    methods: {
      checkPermissionForThisModule: function () {
        return true//this.$store.getters.User.checkPermission(this.module.id)
      },
      select: function () {
        this.setThemeFromSelectedModule()
        this.checkIfSelectedModuleDefaultRoute()
        this.$store.commit('Pages/Main/SET_SELECTED_MODULE', this.module)
      },
      setThemeFromSelectedModule: function () {
        this.$vuetify.theme.primary = this.module.color || this.system.color
      },
      checkIfSelectedModuleDefaultRoute: function () {
        if (this.module.routeDefault) {
          if (!this.menu) {
            this.$router.push(this.module.routeDefault)
          }
        }
      }
    },
    props: {
      module: {
        type: Object
      },
      menu: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
