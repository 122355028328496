<template>
    <div>
        <div v-if="meta">
            <iframe
                width="100%"
                frameborder="0"
                @load="finishLoadMessage"
                allowfullscreen
                :height="iframeHeight"
                :src="`/talk-flow?meta=${meta}`"
            ></iframe>
        </div>
    </div>
</template>

<script>
import { Auth } from '@/default/service/Auth'
export default {
    name: 'TalkFlow',
    data: () => ({
        meta: null,
        iframeHeight: '700px',
        subIframeHeight: '700px'
    }),
    methods: {
        getTalkFlowData: function () {
            let {
                uuid: userId, name: userName
            } = Auth.user()
            let {
                id: workspaceId, name: workspaceName, logo: workspaceImage
            } = Auth.account()
            userName = userName.replace(/[^a-zA-Z0-9]/g, '')
            workspaceName = workspaceName.replace(/[^a-zA-Z0-9]/g, '')
            const userEmail = `${userId}@${workspaceId}.com`
            this.meta = btoa(JSON.stringify({
                workspaceId, workspaceName, workspaceImage,
                userId, userName, userEmail, iframeHeight: this.subIframeHeight,
                domain: window.location.hostname
            }))
        },
        makeIframeHeight: function () {
            this.iframeHeight = `${window.innerHeight - 59}px`
            this.subIframeHeight = `${window.innerHeight - 79}px`
        },
        setInnerHeight: function (height) {
            this.iframeHeight = `${height - 59}px`
            this.subIframeHeight = `${height - 79}px`
            this.$forceUpdate()
        },
        startLoadMessage: function () {
            this.$swal({
              title: 'Iniciando construtor...',
              text: 'Por favor, aguarde um momento.',
              onOpen: () => { this.$swal.showLoading() }
            })
        },
        finishLoadMessage: function () {
            console.log('finishLoadMessage')
            this.$swal.close()
        }
    },
    mounted() {
        this.startLoadMessage()
        this.makeIframeHeight()
        this.getTalkFlowData()
        window.addEventListener('resize', () => {
            this.setInnerHeight(innerHeight)
        }, true)
    },
}
</script>