<template>
  <v-dialog v-model="dialog.active" scrollable persistent :max-width="(85 - (index * 2)) + '%'" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar dense primary>
        <v-toolbar-title>
          <v-icon>attach_file</v-icon>
          <span>Upload de arquivo</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="dialog.active" style="padding: 0 !important;" :style="{'min-height': (380 - (index * 10)) + 'px'}">
        <WiFile
          :info="dialog.info"
          @onSuccess="onSuccess"
          @onError="onError"
        ></WiFile>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import WiFile from '@/default/component/WiFile/WiFile'
  export default {
    name: 'WiFileDialog',
    computed: {
      dialog () {
        var WiFileDialog = this.$store.state.WiFileDialog
        return WiFileDialog.dialogs[this.index]
      }
    },
    methods: {
      close: function () {
        this.$store.dispatch('WiFileDialog/close', this.index)
        this.$forceUpdate()
      },
      onSuccess: function (data) {
        if (this.dialog.onSuccess) {
          this.dialog.onSuccess(data)
          this.close()
        }
      },
      onError: function () {
        if (this.dialog.onError) {
          this.dialog.onError()
        }
      },
    },
    props: {
      'index': {
        default: 0
      }
    },
    components: {
      WiFile
    }
  }
</script>