export default {
  namespaced: true,
  state: {
    id: null,
    uuid: null,
    name: '',
    image: {},
    birthday: '',
    account: {},
    profile: {},
    config: {},
    version: null,
    last_ip: null,
    time: {
      local: null,
      api: null,
      diff: 0
    }
  },
  getters: {
    checkPermission: (state) => (permission) => {
      if (permission) {
        return state.profile.permissions.includes(permission)
      }
      return false
    }
  },
  mutations: {
    SET_ID (state, data) {
      state.id = data
    },
    SET_UUID (state, uuid) {
      state.uuid = uuid
    },
    SET_NAME (state, data) {
      state.name = data
    },
    SET_IMAGE (state, data) {
      state.image = data
    },
    SET_BIRTHDAY (state, data) {
      state.birthday = data
    },
    SET_ACCOUNT (state, data) {
      state.account = data
    },
    SET_PROFILE (state, data) {
      state.profile = data
    },
    SET_CONFIG (state, data) {
      state.config = data
    },
    SET_NOTIFY (state, data) {
      state.notify = data
    },
    SET_LAST_IP (state, data) {
      state.last_ip = data
    },
    SET_VERSION_API (state, data) {
      state.version = data
    },
    SET_TIME (state, time_api) {
      state.time.local = new Date().toLocaleString()
      state.time.api = time_api
    }
  },
  actions: {
    setUser: ({commit}, user) => {
      commit('SET_ID', user.id)
      commit('SET_UUID', user.uuid)
      commit('SET_NAME', user.name)
      commit('SET_IMAGE', user.image)
      commit('SET_BIRTHDAY', user.birthday)
      commit('SET_ACCOUNT', user.account)
      commit('SET_PROFILE', user.profile)
      commit('SET_CONFIG', user.config)
    }
  }
}

