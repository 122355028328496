import PersonFields from '@/modules/register/config/Person/PersonFields'

export default (personName) => {
  return {
    id: 'rgtr',
    list: true,
    title: {
      list: null,
      edit: {
        insert: null,
        update: null
      }
    },
    apiUrl: null,
    urlDetails: {name: 'p', params: {person: null}},
    params: {
      limit: 10
    },
    icon: null,
    search: {
      placeholder: 'Pesquise ID, Nome ou Razão Social, CPF ou CNPJ',
      fields: ['id', 'name', 'first_name', 'cpf_cnpj', 'email', 'reference']
    },
    size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
    components: {
      list: {
        tableRow: () => import('@/modules/register/pages/Persons/Persons')
      }
    },
    fields: PersonFields(personName),
    // imports: {
    //   permission: '',
    //   title: 'Importar Cliente',
    //   origins: {
    //     imanager: {
    //       title: 'I-Manager',
    //       description: 'Importar cliente do I-Manager através do CPF/CNPJ.',
    //       permission: '',
    //       image: '/static/integration/logos/interfocus-tecnologia-logo.jpg',
    //       wiConfig: 'import-customer-from-imanager',
    //       disabled: false
    //     },
    //     hubspot: {
    //       title: 'HubSpot',
    //       description: 'Importar cliente do HubSpot através do e-mail.',
    //       permission: '',
    //       image: '/static/integration/logos/hubspot-logo.png',
    //       wiConfig: '',
    //       disabled: true
    //     }
    //   }
    // }
  }
}
