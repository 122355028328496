<template>
  <div>
    <div class="diagnostic-status" :style="{background: status.color}">
      <v-progress-circular
        v-if="status.name == 'opened'"
        :indeterminate="true"
        :size="23"
        :width="2"
        color="white"
      ></v-progress-circular>
      <v-icon v-else color="white">{{status.icon}}</v-icon>
      <span style="font-size: 18px; padding-left: 5px; color: white;">
        <span> {{status.title}} </span>
        <span v-if="complement && complement.state"> - {{complement.state}}</span>
      </span>
    </div>
    <v-divider></v-divider>
  </div>
</template>
<script>
  export default {
    name: 'WiEventsDiagnosticStatus',
    props: {
      status: {
        required: true,
        default: () => ({})
      },
      complement: {
        default: {}
      }
    }
  }
</script>
<style scoped>
  .diagnostic-status {
    width: 100%;
    text-align: center;
  }
</style>
