<template>
  <v-layout row wrap>
    <v-flex class="form-reply-list__row" xs12 sm12 md12 lg12 xl12>
      <v-layout row wrap>
        <v-flex
          v-for="(field, fieldKey) in reply.fields"
          :key="fieldKey"
          class="form-reply-list__reply"
          v-bind="getItemSize(field.size)"
        >
          <v-card-text v-if="showFormFieldAction(field)" class="form-reply-list__reply-field">
            <v-divider class="form-reply-list__reply-divider"></v-divider>
            <FormReplyListReplyEditor
              v-if="field.type.name == 'editor'"
              :field="field"
            ></FormReplyListReplyEditor>
            <FormReplyListReplyCheckbox
              v-else-if="field.type.name == 'checkbox'"
              :field="field"
            ></FormReplyListReplyCheckbox>
            <FormReplyListReplyTitle
              v-else-if="field.type.name == 'title'"
              :field="field"
            ></FormReplyListReplyTitle>
            <FormReplyListReplyMessage
              v-else-if="field.type.name == 'message'"
              :field="field"
            ></FormReplyListReplyMessage>
            <FormReplyListReplyMoney
              v-else-if="field.type.name == 'money'"
              :field="field"
              @openFieldReplyHistory="openFieldReplyHistory"
            ></FormReplyListReplyMoney>
            <FormReplyListReplyCpf
              v-else-if="field.type.name == 'cpf'"
              :field="field"
              @openFieldReplyHistory="openFieldReplyHistory"
            ></FormReplyListReplyCpf>
            <FormReplyListReplyCnpj
              v-else-if="field.type.name == 'cnpj'"
              :field="field"
              @openFieldReplyHistory="openFieldReplyHistory"
            ></FormReplyListReplyCnpj>
            <FormReplyListReplyDate
              v-else-if="field.type.name == 'date'"
              :field="field"
              @openFieldReplyHistory="openFieldReplyHistory"
            ></FormReplyListReplyDate>
            <FormReplyListReplyPhone
              v-else-if="field.type.name == 'phone'"
              :field="field"
              @openFieldReplyHistory="openFieldReplyHistory"
            ></FormReplyListReplyPhone>
            <FormReplyListReplyDefault
              v-else
              :field="field"
              @openFieldReplyHistory="openFieldReplyHistory"
            ></FormReplyListReplyDefault>
            <v-divider class="form-reply-list__reply-divider"></v-divider>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
  import FormReplyListReplyTitle from './FormReplyListReplyTitle'
  import FormReplyListReplyMoney from './FormReplyListReplyMoney'
  import FormReplyListReplyEditor from './FormReplyListReplyEditor'
  import FormReplyListReplyDefault from './FormReplyListReplyDefault'
  import FormReplyListReplyMessage from './FormReplyListReplyMessage'
  import FormReplyListReplyCheckbox from './FormReplyListReplyCheckbox'
  import FormReplyListReplyCpf from './FormReplyListReplyCpf'
  import FormReplyListReplyCnpj from './FormReplyListReplyCnpj'
  import FormReplyListReplyDate from './FormReplyListReplyDate'
  import FormReplyListReplyPhone from './FormReplyListReplyPhone'
  import { showFormFieldVerification } from '@/modules/forms/utils/showFormFieldVerification'
  export default {
    name: 'FormReplyListReplies',
    methods: {
      getItemSize: function (size) {
        const sizeConfig = {
          'xs12': true, 'sm12': true
        }
        sizeConfig[`md${size}`] = true
        sizeConfig[`lg${size}`] = true
        sizeConfig[`xl${size}`] = true
        return sizeConfig
      },
      showFormFieldAction: function (field) {
        if (!field || !field?.type || !field?.type?.name) {
          return false
        }
        const verification = showFormFieldVerification(field)
        if (verification) {
          return verification(this.replyEditor)
        }
        return true
      },
      openFieldReplyHistory: function (fieldReply) {
        if (fieldReply && fieldReply.id) {
          this.$WiListDialog({
            wiConfig: 'form-field-content-history',
            filter: {
              contentId: fieldReply.id
            }
          })
        }
      }
    },
    props: {
      reply: {
        type: Object,
        required: true
      },
      replyEditor: {
        type: Object,
        required: true
      }
    },
    components: {
      FormReplyListReplyMoney,
      FormReplyListReplyCheckbox,
      FormReplyListReplyDefault,
      FormReplyListReplyMessage,
      FormReplyListReplyEditor,
      FormReplyListReplyTitle,
      FormReplyListReplyCpf,
      FormReplyListReplyCnpj,
      FormReplyListReplyDate,
      FormReplyListReplyPhone
    }
  }
</script>
<style scoped>
  .form-reply-list__title .v-card__title {
    text-align: center !important;
  }
  .form-reply-list__row {
    padding: 5px;
  }
  .form-reply-list__row:first-child {
    padding-left: 0px !important;
    padding-right: 5px;
  }
  .form-reply-list__row:last-child {
    padding-left: 5px;
    padding-right: 0px !important;
  }
  .form-reply-list__reply-field {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .form-reply-list__reply-field p {
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
  .form-reply-list__reply-field:first-child {
    padding-left: 0px !important;
    padding-right: 5px;
  }
  .form-reply-list__reply-field:last-child {
    padding-left: 5px;
    padding-right: 0px !important;
  }
  .form-reply-list__reply-divider {
    border-color: rgb(161 161 161 / 12%) !important;
  }
</style>