export default [
  {
    id: '549b7548',
    icon: 'people',
    title: 'Alunos/Clientes',
    children: [
      {id: '549b7548', icon: 'people', title: 'Todos os Alunos', route: {name: 'graduations-list', params: {page: 'graduations-register-customer'}}},
      {id: '549b7548', icon: 'person', title: 'Alunos Comissão', route: {name: 'graduations-list', params: {page: 'graduations-register-comission'}}}
    ]
  },
  {
    id: '93ce590a-82f4-478a-a10f-affed70c981e',
    icon: 'contact_mail',
    title: 'Contatos/Chamados',
    children: [
      {
        id: '5dc282ab',
        icon: 'all_inbox',
        title: 'Todos os Chamados',
        route: { name: 'chamados', params: { page: 'todos' } }
      },
      {
        id: '5dc282ab',
        icon: 'view_week',
        title: 'Todos os Chamados Por Status',
        route: { name: 'chamados-kanban' }
      },
      {
        id: '5dc282ab',
        icon: 'done_outline',
        title: 'Chamados Encerrados',
        route: { name: 'chamados', params: { page: 'todos-encerrado' } }
      },
      {
        id: '5dc282ab',
        icon: 'close',
        title: 'Chamados Cancelados',
        route: { name: 'chamados', params: { page: 'todos-cancelado' } }
      }
    ]
  },
  // {
  //   id: '22841c3a',
  //   icon: 'bar_chart',
  //   title: 'Relatórios',
  //   children: [
  //     {id: '07a49058', icon: 'people_outline', title: 'Projetos por cliente', route: {name: 'processes-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
  //     {id: 'fe457dce', icon: 'assignment_ind', title: 'Projetos por Colaborador', route: {name: 'processes-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}}
  //   ]
  // },
  {
    id: '4d8hd50b',
    icon: 'school',
    title: 'Projetos de Formatura',
    children: [
      {id: '4d8hd50b', icon: 'view_week', title: 'Processo de Formatura', route: {name: 'process-of-graduations', params: {business: 'graduation'}}},
      {id: '4d8hd50b', icon: 'select_all', title: 'Formaturas em Aberto', route: {name: 'graduations-list', params: {page: 'graduation-open'}}},
      {id: '4d8hd50b', icon: 'done_outline', title: 'Histórico de Formaturas', route: {name: 'graduations-list', params: {page: 'graduation-close'}}},
      {id: '4d8hd50b', icon: 'close', title: 'Formaturas Canceladas', route: {name: 'graduations-list', params: {page: 'graduation-cancel'}}}
    ]
  },
  {
    id: 'a84885b9',
    icon: 'description',
    title: 'Cadastro',
    children: [
      // {
      //   id: 'a84885b9',
      //   icon: 'room_service',
      //   title: 'Cadastro de Serviços',
      //   route: {
      //     name: 'graduations-list',
      //     params: {
      //       page: 'graduations-register-service'
      //     }
      //   }
      // },
      {
        id: 'a84885b9',
        icon: 'domain',
        title: 'Faculdades/Colégios',
        route: {
          name: 'graduations-list',
          params: {
            page: 'graduation-register-college'
          }
        },
        selected: false,
        permission: ['admin']
      },
      {
        id: 'a84885b9',
        icon: 'g_translate',
        title: 'Cursos/Graduações',
        route: {
          name: 'graduations-list',
          params: {
            page: 'graduation-register-course'
          }
        },
        selected: false
      }
    ]
  },
  {
    id: '441ty883',
    icon: 'settings',
    title: 'Configurações',
    children: [
      {
        id: '441ty883',
        icon: 'code',
        title: 'Status/Etapas da Formatura',
        route: {
          name: 'graduations-list',
          params: {
            page: 'config-status-graduation'
          }
        }
      },
      {
        id: '441ty883',
        icon: 'category',
        title: 'Categorias de Formatura',
        route: {
          name: 'graduations-list',
          params: {
            page: 'config-category-graduation'
          }
        }
      }
    ]
  }
]
