// Constantes para tipos de negócio
export const BUSINESS_TYPES = {
  BUDGET: 1,
  QUOTATION: 2,
  PURCHASE: 3,
  SALE: 4,
  ORDER: 5,
  PROCESS: 7,
  PROJECT: 8,
  GRADUATION: 9,
  TEST_MANAGEMENT: 30
}

// Configurações específicas por tipo de negócio
const businessTypes = {
  budget: {
    type: BUSINESS_TYPES.BUDGET,
    module: 'sales',
    title: 'Processo de Orçamento',
    alertTitle: 'Deseja realmente alterar o status deste orçamento?',
    showValidity: true,
    showDelivery: true,
    dateEndLabel: 'Entrega'
  },

  sale: {
    type: BUSINESS_TYPES.SALE,
    module: 'sales',
    title: 'Processo de Vendas',
    alertTitle: 'Deseja realmente alterar o status desta venda?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Entrega'
  },

  purchase: {
    type: BUSINESS_TYPES.PURCHASE,
    module: 'purchases',
    title: 'Processo de Compra',
    alertTitle: 'Deseja realmente alterar o status desta compra?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Entrega'
  },

  quotation: {
    type: BUSINESS_TYPES.QUOTATION,
    module: 'purchases',
    title: 'Processo de Cotação',
    alertTitle: 'Deseja realmente alterar o status desta cotação?',
    showValidity: true,
    showDelivery: true,
    dateEndLabel: 'Entrega'
  },

  order: {
    type: BUSINESS_TYPES.ORDER,
    module: 'service-desk',
    title: 'Processo de Ordem de Serviço',
    alertTitle: 'Deseja realmente alterar o status desta Ordem de Serviço?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Entrega'
  },

  process: {
    type: BUSINESS_TYPES.PROCESS,
    module: 'juridical',
    title: 'Etapas do Processo',
    alertTitle: 'Deseja realmente alterar o status deste Processo?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Previsão'
  },

  project: {
    type: BUSINESS_TYPES.PROJECT,
    module: 'projects',
    title: 'Etapas do Projeto',
    alertTitle: 'Deseja realmente alterar o status deste Projeto?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Previsão'
  },

  graduation: {
    type: BUSINESS_TYPES.GRADUATION,
    module: 'graduations',
    title: 'Etapas da Formatura',
    alertTitle: 'Deseja realmente alterar o status desta Formatura?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Previsão'
  },

  'test-management-vtal': {
    type: BUSINESS_TYPES.TEST_MANAGEMENT,
    module: 'service-desk',
    title: 'Processo de Diagnóstico',
    alertTitle: 'Deseja realmente alterar o status deste Diagnóstico?',
    showValidity: false,
    showDelivery: true,
    dateEndLabel: 'Entrega'
  }
}

/**
 * Retorna a configuração completa para um tipo de negócio específico
 * @param {string} business - Tipo do negócio (ex: 'budget', 'sale', etc)
 * @returns {Object} Configuração completa do negócio
 */
export function getBusinessConfig(business) {
  // Se o tipo de negócio não existir, retorna objeto vazio
  if (!businessTypes[business]) {
    console.warn(`Tipo de negócio não encontrado: ${business}`)
    return {}
  }

  const baseConfig = businessTypes[business]
  
  // Retorna configuração completa com valores padrão
  return {
    ...baseConfig,
    store: business,
    storeStage: `config-status-${business}`,
    getUrl: `${baseConfig.module}/${business}/by-process`,
    putUrl: `${baseConfig.module}/${business}`,
    alertText: 'Essa ação só poderá ser desfeita manualmente!',
    routerClick: {
      name: business,
      params: {}
    }
  }
}

/**
 * Verifica se o tipo de negócio é orçamento ou cotação
 * @param {number} type - Tipo do negócio
 * @returns {boolean}
 */
export function isQuotationOrBudget(type) {
  return type === BUSINESS_TYPES.QUOTATION || type === BUSINESS_TYPES.BUDGET
}

/**
 * Verifica se o tipo de negócio é venda ou compra
 * @param {number} type - Tipo do negócio
 * @returns {boolean}
 */
export function isPurchaseOrSale(type) {
  return type === BUSINESS_TYPES.PURCHASE || type === BUSINESS_TYPES.SALE
}

/**
 * Verifica se o tipo de negócio é projeto, processo ou formatura
 * @param {number} type - Tipo do negócio
 * @returns {boolean}
 */
export function isProjectType(type) {
  return [
    BUSINESS_TYPES.PROCESS,
    BUSINESS_TYPES.PROJECT,
    BUSINESS_TYPES.GRADUATION
  ].includes(type)
}

/**
 * Retorna o label correto para a data de entrega baseado no tipo
 * @param {Object} config - Configuração do negócio
 * @returns {string}
 */
export function getDateEndLabel(config) {
  return config.dateEndLabel || 'Entrega'
} 