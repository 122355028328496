const category = (name, title) => {
  return {
    id: 'a02e979e',
    list: true,
    title: {
      list: 'Categorias de ' + title,
      edit: {
        create: 'Nova categoria de ' + title,
        update: 'Editar categoria de ' + title
      }
    },
    icon: 'category',
    apiUrl: 'clinic/config/category/' + name,
    fields: [
      {
        value: 'id',
        text: 'ID',
        type: 'int',
        list: true,
        edit: false,
        search: true
      },
      {
        value: 'name',
        text: 'Nome da Categoria',
        type: 'text',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        required: true,
        list: true,
        edit: true,
        search: true
      },
      {
        value: 'color',
        text: 'Cor da Categoria',
        type: 'color',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'icon',
        text: 'Ícone da Categoria',
        type: 'wi-google-icons',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'layout',
        text: 'layout desta categoria',
        placeholder: 'Crie a estrutura da categoria.',
        type: 'tiny-editor',
        list: false,
        edit: true
      },
      {
        value: 'description',
        text: 'Observações',
        type: 'textarea',
        required: false,
        list: false,
        edit: true
      }
    ]
  }
}

export default {
  evolution: category('evolution', 'Evolução'),
  anamnesis: category('anamnesis', 'Anamnese'),
  prescription: category('prescription', 'Receituário'),
  examRequest: category('exam-request', 'Solicitação de Exame'),
  attested: category('attested', 'Atestado')
}
