<template>
  <v-card class="elevation-17">
    <v-card-text style="padding: 0;">
      <v-container style="padding: 0px !important;">
        <v-layout row wrap>
          <v-flex v-for="(info, infoKey) in configuration" :key="infoKey" v-bind="sizeField(info.size)">
            <v-list two-line style="padding: 0 !important;">
              <template v-for="(item, itemKey) in info.items">
                <div :key="itemKey">
                  <v-list-tile avatar>
                    <v-list-tile-avatar>
                      <v-avatar>
                        <img v-if="item.image" :src="'/static/icons/monitor/' + getItem('image', item)" :alt="getItem('title', item)">
                        <v-icon v-if="item.icon" large :style="{color: item.color ? getItem('color', item) : '#000'}">{{getItem('icon', item)}}</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-sub-title v-if="item.text" v-html="getItem('text', item)"></v-list-tile-sub-title>
                      <v-list-tile-title v-if="item.data || item.value">
                        <strong v-html="getItem('data', item) || '---'" :style="getItem('style', item)"></strong>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="item.actions">
                      <div v-for="(action, actionKey) in item.actions" :key="actionKey">
                        <wi-copy-to v-if="action.copy && getItem('data', item)" :text="getItem('data', item)" :title="getItem('text', item)"></wi-copy-to>
                        <v-btn v-else icon flat v-prms="{'id': action.id}" :disabled="getItem('disabled', action)" @click="runAction(action, data)">
                          <v-tooltip v-if="getItem('description', action)" top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">{{getItem('icon', action)}}</v-icon>
                            </template>
                            <span>{{getItem('description', action)}}</span>
                          </v-tooltip>
                          <v-icon v-else>{{getItem('icon', action)}}</v-icon>
                        </v-btn>
                      </div>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                </div>
              </template>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
  import { TestManagementEventService } from '@/modules/service-desk/services/TestManagement/TestManagementEventService'
  import { TestManagementEventSocket } from '@/modules/service-desk/services/TestManagement/TestManagementEventSocket'
  export default {
    name: 'WiViewDashboard',
    computed: {
      configuration () {
        if (this.info) {
          return this.info
        }
        return [
          {
            size: ['xs12', 'sm12', 'md8', 'lg8'],
            items: [
              {
                image: 'company_96.png',
                text: this.config.personTitle + ':',
                data: (item, data) => {
                  return data.person.first_name + ' (' + data.person.name + ')'
                },
                actions: [
                  {
                    icon: 'info',
                    action: (data) => {
                      this.$emit('onPerson', data)
                    },
                    description: 'Informações do ' + this.config.personTitle
                  }
                ]
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md4', 'lg4'],
            items: [
              {
                icon: (item, data) => { return data.status.icon },
                color: (item, data) => { return data.status.color },
                text: 'Status/Etapa:',
                data: (item, data) => { return data.status.name },
                actions: [
                  // {
                  //   icon: 'edit',
                  //   description: 'Alterar Status',
                  //   WiEditDialog: {
                  //     wiConfig: 'sale-open',
                  //     onSubmit: this.refresh,
                  //     data: {
                  //       id: this.data.id,
                  //       business_status_id: this.data.business_status_id
                  //     }
                  //   }
                  // }
                ]
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md5', 'lg5'],
            items: [
              {
                image: 'fingerprint_96.png',
                text: 'Código/ID:',
                data: (item, data) => { return '#' + data.id },
                actions: [
                  {
                    copy: true,
                    description: 'Copiar Código/ID'
                  }
                ]
              },
              {
                icon: (item, data) => { return data.category.icon },
                color: (item, data) => { return data.category.color },
                text: 'Categoria:',
                data: (item, data) => { return data.category.name }
              },
              {
                image: 'worker_male_96.png',
                text: 'Responsável',
                data: (item, data) => { return data.collaborator.name },
                actions: [
                  {
                    icon: 'info',
                    route: {name: 'collaborator', params: {id: this.data.collaborator_id}},
                    description: 'Abrir detalhes do colaborador Responsável'
                  }
                ]
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md3', 'lg3'],
            items: [
              {
                image: 'split_transaction_96.png',
                text: 'Valor Total:',
                data: (item, data) => {
                  if (this.config.store === 'graduation') {
                    return data.finance_account ? this.$options.filters.money(data.finance_account.balance_foreseen) : this.$options.filters.money(0)
                  } else {
                    return this.$options.filters.money(data.total_liquid)
                  }
                }
              },
              {
                image: 'date_start_96.png',
                text: this.config.date_start || 'Data de Emissão:',
                value: 'date_start_format'
              },
              {
                image: 'schedule_96.png',
                text: this.config.date_end || 'Data de Entrega:',
                value: 'date_end_format'
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md4', 'lg4'],
            items: [
              {
                image: () => { return this.config.store !== 'budget' || this.config.store !== 'quotation' ? 'paper_money_96.png' : 'event_time_96.png' },
                text: () => { return this.config.store !== 'budget' || this.config.store !== 'quotation' ? 'Situação Financeira:' : 'Data de Validade:' },
                value: () => { return this.config.store !== 'budget' || this.config.store !== 'quotation' ? 'finance_situation' : 'date_validity_format' }
              },
              {
                image: 'anexo_96.png',
                text: 'Arquivos/Anexos:',
                value: 'count_files',
                actions: [
                  (data) => {
                    return {
                      icon: 'info',
                      WiDialog: {
                        title: 'Arquivos/Anexos ' + this.config.idTitle,
                        icon: 'attachment',
                        component: {
                          content: () => import('@/default/component/WiFile/WiFileList'),
                          props: {
                            index: this.config.store + '-fileList',
                            api: {
                              url: 'sales/sale/file',
                              params: '?order=id,desc&where=business_id,' + data.id
                            },
                            permission: 'b41dcfec'
                          }
                        },
                        button: {
                          icon: 'add',
                          text: 'Adicionar Arquivo',
                          permission: 'b41dcfec',
                          disabled: () => (data.stage !== 1),
                          action: () => {
                            this.$WiDialog({
                              title: 'Arquivos/Anexos ' + this.config.idTitle,
                              icon: 'attachment',
                              component: {
                                content: () => import('@/default/component/WiFile/WiFile'),
                                props: {
                                  business_id: data.id
                                }
                              },
                              onClose: () => {
                                this.$WiView(this.config.store + '-fileList').refresh()
                                this.$WiView('business').refresh()
                              }
                            })
                          }
                        }
                      },
                      description: 'Ver todas as notas...'
                    }
                  }
                ]
              },
              {
                image: 'dog_tag_96.png',
                text: 'Código Externo:',
                value: 'external_code',
                actions: [
                  {
                    copy: true,
                    description: 'Copiar código externo.'
                  }
                ]
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md12', 'lg12'],
            items: [
              {
                image: 'note_96.png',
                text: 'Última Nota:',
                style: {'font-size': '12px'},
                value: 'last_note',
                actions: [
                  (data) => {
                    return {
                    icon: 'info',
                    WiEventsDialog: {
                      title: 'Ações/Eventos',
                      icon: 'message',
                      service: TestManagementEventService,
                      serviceParams: {
                        where: 'business_id,' + data.id
                      },
                      socket: TestManagementEventSocket,
                      socketParams: {
                        id: data.id
                      },
                      business: data
                    },
                    description: 'Ver todas as notas...'
                  }
                  }
                ]
              }
            ]
          },
          // {
          //   size: ['xs12', 'sm12', 'md4', 'lg4'],
          //   items: [
          //     {
          //       image: 'people_96.png',
          //       text: 'Pessoas Envolvidas:',
          //       value: 'count_persons',
          //       actions: [
          //         (data) => {
          //           return {
          //             icon: 'info',
          //             WiListDialog: {
          //               wiConfig: this.config.store + '-person',
          //               wiEditData: {business_id: data.id},
          //               filter: {where: 'business_id,' + data.id}
          //             },
          //             description: 'Ver todas as notas...',
          //             disabled: () => {
          //               if (this.config.store === 'sale' || this.config.store === 'order' || this.config.store === 'process') {
          //                 return false
          //               }
          //               return true
          //             }
          //           }
          //         }
          //       ]
          //     }
          //   ]
          // }
        ]
      }
    },
    methods: {
      getItem: function (name, item) {
        if (typeof item === 'function') {
          item = item(this.data)
        }
        if (name === 'data' && item.value) {
          if (typeof item.value === 'function') {
            return this.data[item.value(item, this.data)]
          } else {
            return this.data[item.value]
          }
        } else {
          if (typeof item[name] === 'function') {
            return item[name](item, this.data)
          } else {
            return item[name]
          }
        }
      },
      runAction: function (item, data) {
        if (typeof item === 'function') {
          item = item(data)
        }
        if (item.route) {
          this.$router.push(item.route)
        }
        if (item.WiEditDialog) {
          this.$WiEditDialog({
            wiConfig: item.WiEditDialog.wiConfig,
            onSubmit: item.WiEditDialog.onSubmit,
            data: item.WiEditDialog.data,
            redirectOnSubmit: item.WiEditDialog.redirectOnSubmit})
        }
        if (item.WiListDialog) {
          this.$WiListDialog({
            wiConfig: item.WiListDialog.wiConfig,
            filter: item.WiListDialog.filter,
            wiEditData: item.WiListDialog.wiEditData
          })
        }
        if (item.WiDialog) {
          this.$WiDialog(item.WiDialog)
        }
        if (item.WiEventsDialog) {
          this.$WiEventsDialog(item.WiEventsDialog)
        }
        if (item.WiFileListDialog) {
          this.$WiFileListDialog(item.WiFileListDialog)
        }
        if (item.action) {
          item.action(data)
        }
      },
      sizeField: function (sizes) {
        var size = {}
        sizes = sizes ? (typeof sizes === 'function' ? sizes(this.form) : sizes) : ['xs12', 'sm12', 'md12', 'lg12']
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      setInfo: function () {
        if (this.option) {
          this.info = this.option
        }
      }
    },
    mounted: function () {
      this.setInfo()
    },
    props: {
      'option': {
        type: Object,
        required: false
      },
      'config': {
        default: () => ({})
      },
      'data': {
        default: () => ({})
      },
      'info': {
        default: null
      }
    }
  }
</script>
