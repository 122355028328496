<template>
  <v-card>
    <v-toolbar dense flat color="transparent">
      <v-spacer></v-spacer>
      <v-btn outline small :disabled="!zoomDefined" @click="() => { zoomDefined = false; refresh(); }">
        <v-icon>reset_tv</v-icon>
      </v-btn>
      <v-btn outline small :disabled="width >= widthMax" @click="zoon('+')">
        <v-icon>zoom_in</v-icon>
      </v-btn>
      <v-btn outline small @click="zoon('-')">
        <v-icon>zoom_out</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="overflow: scroll;" :style="{ height: (innerHeight - 170) + 'px' }">
      <vue-mermaid
        :nodes="nodes"
        @nodeClick="openStage"
        type="graph LR"
        :style="{width: width +'px', 'min-height': height + 'px'}"
        :defaultConfig="{
          theme: 'default',
          startOnLoad: false,
          securityLevel: 'loose',
          maxTextSize: 9900000
        }"
      ></vue-mermaid>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'ProcessStagesFlowcharts',
    data: () => ({
      nodes: [],
      width: 0,
      widthMax: 0,
      height: 0,
      heightMax: 0,
      zoomDefined: false,
      processStageDialog: false,
      processStage: {},
      innerHeight: window.innerHeight
    }),
    methods: {
      zoon: function (action) {
        if (action == '+') {
          this.width = this.width + (this.width / 5)
          this.height = this.height + (this.height / 5)
        } else {
          this.width = this.width - (this.width / 5)
          this.height = this.height - (this.height / 5)
        }
        this.zoomDefined = true
      },
      loadProcess: function () {
        this.nodes = []
        this.process.stages.forEach(stage => {
          this.incrementZoomSize()
          this.nodes.push(
            this.createNodeByStage(stage)
          )
        })
        this.setZoomMax()
      },
      createNodeByStage: function (stage) {
        return {
          id: stage.id,
          style: this.getNodeStyle(stage),
          editable: true,
          text: `<strong>${stage?.name}</strong><br>${stage?.type?.name}`,
          link: this.getNextLinks(stage.results),
          edgeType: this.getEdgeType(stage),
          next: this.getNext(stage.results)
        }
      },
      getNodeStyle: function (stage) {
        if (stage.id == this.process.first_stage_id) {
          return `fill:#367DA8,color:white,stroke:#367DA8,stroke-width:4px,box-shadow:10px`
        } else if (this.executableNodes.indexOf(stage.id) > -1) {
          return `fill:black,color:white,stroke:black,stroke-width:4px,box-shadow:10px`
        }
        return `stroke:${stage.type.color},stroke-width:4px,box-shadow:10px`
      },
      incrementZoomSize: function () {
        if (!this.zoomDefined) {
          this.width = this.width + 200
          this.height = this.height + 50
        }
      },
      setZoomMax: function () {
        if (!this.zoomDefined) {
          this.widthMax = this.width
          this.heightMax = this.height
        }
      },
      getNext: function (results) {
        const next = []
        results.forEach(result => {
          next.push(result.result_stage_id)
        })
        if (results?.length <= 0) {
          next.push(this.createLastNode())
        }
        return next
      },
      createLastNode: function () {
        const id = this.$UUID()
        this.nodes.push({
          id: id,
          style: `fill:green,color:white,stroke:green,stroke-width:4px`,
          editable: false,
          text: `<strong>Concluído</strong>`,
          link: [],
          edgeType: 'asymetric',
          next: []
        })
        return id
      },
      getNextLinks: function (results) {
        const nextLinks = []
        let addLinkBaseName = results?.length > 1
        results.forEach(result => {
          let linkName = result?.result?.name
          if (addLinkBaseName) {
            linkName = result.order + ' - ' + linkName
          }
          nextLinks.push('-- ' + linkName + ' --->')
        })
        if (results?.length <= 0) {
          nextLinks.push('-- Encerrar --->')
        }
        return nextLinks
      },
      getEdgeType: function (stage) {
        return stage?.results?.length > 1 ? 'rhombus' : 'stadium'
      },
      openStage: function (stageId) {
        const processStage = this.process.stages.filter(stage => stage.id == stageId)
        if (processStage?.length && processStage[0]) {
          this.$emit('selectStage', processStage[0])
        }
      },
      addStage: function (process) {
        this.$WiEditDialog({
          wiConfig: 'process-stage',
          data: {
            process_id: process.id 
          },
          redirectOnSubmit: false
        })
      },
      listStage: function (process) {
        this.$WiListDialog({
          wiConfig: 'process-stage',
          filter: {
            process_id: process.id,
            order: 'order,asc'
          },
          wiEditData: {
            process_id: process.id
          },
          redirectOnSubmit: false
        })
      }
    },
    mounted: function () {
      this.loadProcess()
    },
    watch: {
      process () {
        this.loadProcess()
      }
    },
    props: {
      process: {
        type: Object,
        required: true
      },
      executableNodes: {
        type: Array,
        default: () => ([])
      },
      loading: {
        type: Boolean,
        required: true
      }
    }
  }
</script>
