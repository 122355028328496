import { Model } from '@/default/model/Model'

class TicketDevice extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1a825af1'
    this.name = 'ticket-device'
    this.actions = {
      delete: true
    }
    this.title = {
      list: 'Dispositivo(s) anexado(s) ao chamado',
      edit: {
        insert: 'Anexar dispositivo',
        update: 'Editar dispositivo anexado'
      }
    }
    this.apiUrl = 'service-desk/ticket/device'
    this.icon = 'dns'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pelo ID ou nome do dispositivo',
      fields: ['device.id', 'device.nome_disp']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.fieldsSelected = {
      itemName: 'dispositivo(s)'
    }
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Ticket/Network/TicketDevices')
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'nome_disp',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'status_disp',
        text: 'Status'
      }),
      this.fieldList({
        value: 'ip_disp',
        text: 'Endereço IP'
      }),
      this.fieldList({
        value: 'grupo_dados_id',
        text: 'Rede Principal'
      }),
      this.fieldList({
        value: 'servidor_dados_id',
        text: 'Pingar'
      })
    ])
  }
}
export default new TicketDevice()
