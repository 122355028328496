import { Contract } from '@/modules/business/config/Contract/Contract'

export class ContractInternet extends Contract {
  constructor () {
    super()
    this.apiUrl = 'internet/contract'
    this.urlDetails.name = 'internet-contract'
    this.components.list.tableRow = () => import('@/modules/internet/pages/Contracts')
    this.fieldsEdit.status.apiUrl = 'internet/config/status/contract'
    this.fieldsEdit.category.apiUrl = 'internet/config/category/contract'
    this.fieldsEdit.externalCode = this.fieldDate({
      value: 'invoice_first_date',
      text: 'Data primeiro pagamento',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    })
    this.clearFields()
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
}

export class ContractOpened extends ContractInternet {
  constructor () {
    super()
    this.title.list = 'Contratos Em Análise'
    this.params.stage = 'open'
  }
}
export class ContractClosed extends ContractInternet {
  constructor () {
    super()
    this.title.list = 'Contratos Ativos'
    this.params.stage = 'close'
  }
}

export class ContractBlocked extends ContractInternet {
  constructor () {
    super()
    this.title.list = 'Contratos Bloqueados'
    this.params.stage = 'close'
    this.params.blocked = true
  }
}
export class ContractCanceled extends ContractInternet {
  constructor () {
    super()
    this.title.list = 'Contratos Encerrados'
    this.params.stage = 'cancel'
  }
}
