<template>
  <div style="max-width: 100%; padding-bottom: 10px;">
    <v-list dense two-line>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-list-tile v-on="on" :disabled="field.disabled" avatar style="border-style: solid; border-color: gray; border-width: 1px; border-radius: 5px; height: 45px !important;" class="asdasdasd">
            <v-list-tile-avatar>
              <v-img v-if="form.file" :src="form.file.url"></v-img>
              <v-icon v-else large color="primary">image</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                {{ typeof field.text == 'function' ? field.text(form) : field.text }}
              </v-list-tile-sub-title>
              <v-list-tile-title v-if="form.file">
                {{ form.file.name }}
              </v-list-tile-title>
              <v-list-tile-title v-else>
                {{ field.placeholder }}
              </v-list-tile-title>
              <v-input
                style="display: none;"
                v-model="form[field.value]"
                :rules="isRequired(field) ? validField(field) : []"
                :required="isRequired(field)"
              ></v-input>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon>{{form[field.value] ? 'edit' : 'search'}}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </template>
        <v-list>
          <v-list-tile v-if="!field.options || field.options.galery" @click="fileListDialog()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>perm_media</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Buscar arquivo na galeria</v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="!field.options || (field.options.galery && field.options.upload)"></v-divider>
          <v-list-tile v-if="!field.options || field.options.upload" @click="uploadNewFile()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>upload</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Inserir novo arquivo</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-list>
  </div>
</template>
<script>
  import WiEditFieldsMixin from '@/default/component/WiEdit/Fields/WiEditFieldsMixin'
  export default {
    name: 'WiEditWiFileDialog',
    data () {
      return {
        active: false
      }
    },
    methods: {
      fileListDialog: function () {
        this.$WiListDialog({
          wiConfig: 'file',
          filter: this.field.filter || {},
          callback: (file) => {
            this.selectedFile(file)
          },
          mode: 'select',
          wiEditData: this.field.data || {},
        })
      },
      uploadNewFile: function () {
        console.log(this.field)
        this.$WiFileDialog({
          info: this.field.data || {},
          onSuccess: (file) => {
            this.selectedFile(file)
          }
        })
      },
      selectedFile: function (file) {
        const selectedDAta = {}
        selectedDAta[this.field.value] = file.id
        selectedDAta[this.field.show] = file
        this.$emit('select', {
          file_id: file.id,
          file: file
        })
        this.$forceUpdate()
        this.active = false
      }
    },
    props: ['form', 'field'],
    mixins: [WiEditFieldsMixin]
  }
</script>

<style scoped>
  .asdasdasd >>> a {
    height: 45px !important;
  }
</style>
