import { Lang } from '@/default/service/Lang'

export default ({name}) => {
  return [
    // Itens que serão listados
    {
      value: 'id',
      text: 'ID',
      list: true,
      search: true
    },
    {
      value: 'name',
      text: 'Nome/Nome Fantasia',
      list: true,
      search: true
    },
    {
      value: 'first_name',
      text: 'Nome Completo/Razão Social',
      list: true
    },
    {
      value: 'cpf_cnpj',
      text: Lang.langText('register.config.person.fields.list.cpf_cnpj.text', 'CPF/CNPJ'),
      list: true,
      search: true
    },
    {
      value: 'status',
      text: 'Status',
      list: true,
      search: false,
      sortable: false
    },
    {
      text: Lang.langText('register.config.person.fields.edit.subheader1.text', 'Informações básicas deste(a) ' + name + '.', {name: name}),
      type: 'subheader',
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    {
      text: Lang.langText('register.config.person.fields.edit.subheader2.text', 'Primeiro, selecione se este(a) ' + name + ' é pessoa Física ou Jurídica', {name}),
      type: 'subheader',
      edit: true,
      showField: (form) => {
        return form.type !== 'Fisica' && form.type !== 'Juridica'
      }
    },
    {
      value: 'type',
      text: Lang.langText('register.config.person.fields.edit.type.text', 'Pessoa Física ou Jurídica'),
      placeholder: Lang.langText('register.config.person.fields.edit.type.placeholder', 'Pessoa Física ou Jurídica.'),
      type: 'select',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      options: [
        {value: 'Fisica', text: Lang.langText('register.config.person.fields.edit.type.option1', 'Pessoa Física')},
        {value: 'Juridica', text: Lang.langText('register.config.person.fields.edit.type.option2', 'Pessoa Jurídica')}
      ],
      required: true,
      edit: true,
      showField: (form) => {
        return form.type !== 'Fisica' && form.type !== 'Juridica'
      }
    },
    {
      value: 'type',
      text: Lang.langText('register.config.person.fields.edit.type.text', 'Pessoa Física ou Jurídica'),
      placeholder: Lang.langText('register.config.person.fields.edit.type.placeholder', 'Pessoa Física ou Jurídica.'),
      type: 'select',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      options: [
        {value: 'Fisica', text: Lang.langText('register.config.person.fields.edit.type.option1', 'Pessoa Física')},
        {value: 'Juridica', text: Lang.langText('register.config.person.fields.edit.type.option2', 'Pessoa Jurídica')}
      ],
      required: true,
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    // Pessoa Singular
    {
      value: 'name',
      text: 'Nome como será chamado',
      placeholder: 'Ex: Pedro Henrique',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true,
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      value: 'first_name',
      text: 'Nome Completo',
      placeholder: 'Ex: Pedro Henrique da Silva',
      type: 'text',
      size: ['xs12', 'sm4', 'md4', 'lg4'],
      required: true,
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      value: 'cpf_cnpj',
      text: Lang.langText('register.config.person.fields.edit.cpf_cnpj1.text', 'CPF'),
      placeholder: Lang.langText('register.config.person.fields.edit.cpf_cnpj1.placeholder', '###.###.###-##'),
      mask: Lang.langText('register.config.person.fields.edit.cpf_cnpj1.mask', '###.###.###-##'),
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      value: 'rg',
      text: Lang.langText('register.config.person.fields.edit.rg.text', 'RG'),
      placeholder: Lang.langText('register.config.person.fields.edit.rg.placeholder', 'Insira o RG'),
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      value: 'birthday',
      text: 'Data de Nascimento',
      type: 'date',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      value: 'sex',
      text: 'Sexo',
      placeholder: 'Masculino/Feminino',
      type: 'select',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      options: [
        {value: 'Feminino', text: 'Feminino'},
        {value: 'Masculino', text: 'Masculino'},
        {value: 'Outro', text: 'Não especificado'}
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    // Pessoa Coletiva
    {
      value: 'name',
      text: 'Nome fantasia',
      placeholder: 'Ex: Casas Bahia',
      type: 'text',
      size: ['xs12', 'sm4', 'md4', 'lg4'],
      required: true,
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'first_name',
      text: 'Razão Social',
      placeholder: 'Ex: Adição Atacadista Casas Bahia ME',
      type: 'text',
      size: ['xs12', 'sm6', 'md6', 'lg6'],
      required: true,
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'cpf_cnpj',
      text: Lang.langText('register.config.person.fields.edit.cpf_cnpj2.text', 'CNPJ'),
      placeholder: Lang.langText('register.config.person.fields.edit.cpf_cnpj2.placeholder', '##.###.###/####-##'),
      mask: Lang.langText('register.config.person.fields.edit.cpf_cnpj2.mask', '##.###.###/####-##'),
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'birthday',
      text: 'Aniversário da Empresa',
      type: 'date',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'state_free',
      text: 'Isento',
      placeholder: 'Sim ou Não',
      type: 'select',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      options: [
        {value: 'yes', text: 'Sim'},
        {value: 'no', text: 'Não'}
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'state_number',
      text: 'Inscrição Estadual',
      placeholder: 'Insira a Inscrição Estadual',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      disabled: (form) => {
        return form.state_free === 'yes'
      },
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'state_id',
      text: 'UF Ins. Estadual',
      placeholder: 'Ex: MG',
      type: 'db-autocomplete',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      edit: true,
      apiUrl: 'register/state',
      apiParams: {
        fields: 'id,code'
      },
      show: 'code',
      disabled: (form) => {
        return form.state_free === 'sim'
      },
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'city_number',
      text: 'Inscrição Municipal',
      placeholder: 'Insira a Inscrição Municipal',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    {
      value: 'site',
      text: 'Site oficial do(a) ' + name,
      placeholder: 'Ex: www.hrtecnologia.com',
      type: 'text',
      size: ['xs12', 'sm4', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Juridica'
      }
    },
    // Comum
    {
      value: 'status',
      text: 'Status do(a) ' + name,
      placeholder: 'Ativo ou Inativo',
      type: 'select',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      options: [
        {value: 'active', text: 'Está Ativo'},
        {value: 'inactive', text: 'Está Inativo'}
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    {
      value: 'reference',
      text: 'Referência Externa',
      placeholder: 'Ex: Código de outro sistema',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: false,
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    {
      value: 'responsible_id',
      text: 'Colaborador Responsável*',
      size: form => { return form.type === 'Fisica' ? ['xs12', 'sm12', 'md4', 'lg4'] : ['xs12', 'sm12', 'md6', 'lg6'] },
      type: 'wi-list-dialog',
      edit: true,
      wiConfig: 'collaborator',
      show: 'responsible.first_name',
      icon: 'person_outline',
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    {
      value: 'channel_id',
      text: 'Canal de Entrada',
      placeholder: 'Canal de Entrada',
      type: 'db-autocomplete',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      apiUrl: 'register/channel',
      apiParams: {
        fields: 'id,name'
      },
      show: 'name',
      clearable: true,
      // disabled: form => (!!form.channel_id && !!form.id),
      showField: form => (form.type === 'Fisica' || form.type === 'Juridica')
    },
    {
      value: 'additional_info',
      text: 'Informações avançadas do(a) ' + name,
      type: 'select',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' }
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      value: 'access_area',
      text: 'Acesso a área do(a) ' + name,
      type: 'select',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' }
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    {
      text: 'Configurações da área do(a) ' + name,
      type: 'subheader',
      edit: true,
      showField: (form) => {
        return form.access_area
      }
    },
    {
      value: 'email',
      text: 'E-mail de acesso da área do(a) ' + name,
      placeholder: 'Insira um e-mail válido. Ex: nome@empresa.com',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true,
      edit: true,
      showField: (form) => {
        return form.access_area
      }
    },
    {
      value: 'password',
      text: 'Nova Senha',
      placeholder: 'Irá substituir a senha atual',
      type: 'password',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: false,
      edit: true,
      showField: (form) => {
        return form.access_area
      }
    },
    {
      value: 'password_change',
      text: 'Forçar alterar senha ao entrar',
      placeholder: name + ' terá de criar uma nova senha.',
      type: 'select',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [
        {value: true, text: 'Sim, alterar'},
        {value: false, text: 'Não alterar'}
      ],
      edit: true,
      showField: (form) => {
        return form.access_area
      }
    },
    {
      value: 'password_date',
      text: 'Data da senha',
      type: 'datetime',
      disabled: true,
      size: ['x12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.access_area
      }
    },
    {
      text: 'Dados avançados do(a) ' + name,
      type: 'subheader',
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'mothers_name',
      text: 'Nome da mãe',
      placeholder: 'Insira o nome da mãe',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'fathers_name',
      text: 'Nome do pai',
      placeholder: 'Insira o nome do pai',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'spouses_name',
      text: 'Nome do cônjuge',
      placeholder: 'Insira o nome do cônjuge',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'companions_name',
      text: 'Responsável ou acompanhante',
      placeholder: 'Insira o nome do responsável ou acompanhante',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'marital_status',
      text: 'Estado civil',
      placeholder: 'Selecione o estado civil',
      type: 'select',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [
        {value: 'Casado(a)', text: 'Casado(a)'},
        {value: 'Divorciado(a)', text: 'Divorciado(a)'},
        {value: 'Separado(a)', text: 'Separado(a)'},
        {value: 'Solteiro(a)', text: 'Solteiro(a)'},
        {value: 'União estável', text: 'União estável'},
        {value: 'Viúvo(a)', text: 'Viúvo(a)'},
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'profession',
      text: 'Profissão',
      placeholder: 'Insira a Profissão',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'blood_group',
      text: 'Grupo sanguíneo',
      placeholder: 'Insira o grupo sanguíneo',
      type: 'select',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      edit: true,
      options: [
        {value: 'Grupo A', text: 'Grupo A'},
        {value: 'Grupo B', text: 'Grupo B'},
        {value: 'Grupo AB', text: 'Grupo AB'},
        {value: 'Grupo O', text: 'Grupo O'},
      ],
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'education',
      text: 'Escolaridade',
      placeholder: 'Insira a Escolaridade',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'naturalness',
      text: 'Naturalidade',
      placeholder: 'Insira a Naturalidade',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'religion',
      text: 'Religião',
      placeholder: 'Insira a Religião',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'ethnicity',
      text: 'Etnia',
      placeholder: 'Insira a etnia',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      text: 'Observações deste(a) ' + name,
      type: 'subheader',
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    },
    {
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para anotar qualquer informação referente a este(a) ' + name + ' que julgar relevante.',
      type: 'textarea',
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' || form.type === 'Juridica'
      }
    }
  ]
}
