import axios from 'axios'
import store from '@/default/store'
import { Auth } from '@/default/service/Auth'
import { Storage } from '@/default/service/Storage'

export class Interceptors {
  static Axios (config) {
    var r = axios.create({
      baseURL: config.url
    })
    r.interceptors.request.use(request => {
      request.headers.Accept = 'application/json'
      if (Auth.check()) {
        request.headers.Authorization = 'Bearer ' + Auth.token()
        request.headers.Hash = Auth.hash()
      }
      return request
    })
    r.interceptors.response.use(response => {
      if (response.headers.insights) {
        const data = Storage.decrypt(response.headers.insights)
        store.commit('system/SET_VERSION_API', data.version)
        store.commit('User/SET_LAST_IP', data.last_ip)
        store.commit('User/SET_VERSION_API', data.version)
        store.commit('User/SET_TIME', data.time)
      }
      return response
    }, 
    error => {
      console.log(error)
      if (error.response && error.response.status === 401) {
        Auth.logout()
      }
      return Promise.reject(error)
    })
    return r
  }
}
