import { Model } from '@/default/model/Model'
import {
  PERMISSION_FORM_ITEM,
  PERMISSION_FORM_ITEM_CREATE,
  PERMISSION_FORM_ITEM_UPDATE,
  PERMISSION_FORM_ITEM_DELETE
} from '@/default/constants/permissions'

export class FormField extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_FORM_ITEM,
      create: PERMISSION_FORM_ITEM_CREATE,
      update: PERMISSION_FORM_ITEM_UPDATE,
      delete: PERMISSION_FORM_ITEM_DELETE
    }
    this.name = 'form-field'
    this.title = {
      list: 'Itens do Formulário',
      edit: {
        insert: 'Novo campo do Formulário',
        update: 'Editar campo do Formulário'
      }
    }
    this.apiParams = {
      limit: 10,
      order: 'order,asc'
    }
    this.icon = 'border_color'
    this.apiUrl = 'forms/form-field'
    this.components.list.tableRow = () => import('@/modules/forms/pages/Forms/FormFields')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID',
      align: 'center'
    }),
    order: this.fieldList({
      value: 'order',
      text: 'Posição'
    }),
    type: this.fieldList({
      value: 'type_id',
      text: 'Tipo'
    }),
    label: this.fieldList({
      value: 'label',
      text: 'Título'
    }),
    required: this.fieldList({
      value: 'required',
      text: 'Obrigatório'
    }),
    placeholder: this.fieldList({
      value: 'active',
      text: 'Ativo'
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      sort: false
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o formulário e tipo do campo. Informe um título.'
    }),
    form_id: this.fieldSelectApi({
      value: 'form_id',
      text: 'Formulário',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      disabled: (form) => { return form.id != null },
      api: {
        url: 'forms/form'
      },
      show: 'name'
    }),
    type_id: this.fieldSelectApi({
      value: 'type_id',
      text: 'Tipo de Campo',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      disabled: (form) => { return form.id != null },
      api: {
        url: 'forms/field/type'
      },
      show: 'title',
      icon: 'icon'
    }),
    label: this.fieldText({
      value: 'label',
      text: 'Título do Campo',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true
    }),
    placeholder: this.fieldText({
      value: 'placeholder',
      text: 'Placeholder',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: form => (!form.type_id || form.type_id != 9)
    }),
    order: this.fieldNumber({
      value: 'order',
      text: 'Posição do campo',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    size: this.fieldSelect({
      value: 'size',
      text: 'Área ocupada',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true,
      options: [
        {
          text: '1',
          value: 1
        },
        {
          text: '2',
          value: 2
        },
        {
          text: '3',
          value: 3
        },
        {
          text: '4',
          value: 4
        },
        {
          text: '5',
          value: 5
        },
        {
          text: '6',
          value: 6
        },
        {
          text: '7',
          value: 7
        },
        {
          text: '8',
          value: 8
        },
        {
          text: '9',
          value: 9
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '11',
          value: 11
        },
        {
          text: '12',
          value: 12
        }
      ]
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm12', 'md1', 'lg1']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    required: this.fieldSwitch({
      value: 'required',
      text: 'Obrigatório?',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: form => (!form.type_id || form.type_id != 9)
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para descrição do campo do formulário.',
      showField: form => (!form.type_id || form.type_id != 9)
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição do Formulário',
      placeholder: 'Ex: Formulário de coleta de dados...',
      showField: form => (!form.type_id || form.type_id != 9)
    }),
    subHeaderMessage: this.fieldSubHeader({
      text: 'Insira o conteúdo da mensagem.',
      showField: form => (form?.type_id == 9)
    }),
    descriptionMessage: this.fieldEditor({
      value: 'description',
      text: 'Conteúdo da mensagem',
      placeholder: 'Ex: Formulário de coleta de dados...',
      showField: form => (form?.type_id == 9),
      required: true
    })
  }
}
