<template>
  <div>
    <v-toolbar flat dense tabs>
      <v-btn icon ripple @click="$router.go(-1)">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-breadcrumbs :items="paginas" >
        <v-icon slot="divider">forward</v-icon>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-btn :disabled="!content.id" icon @click="$router.push({name: 'google-maps-grupos', params: {id: content.id}})">
        <v-icon>map</v-icon>
      </v-btn>
      <v-btn icon @click="loadApi()">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-menu bottom left>
        <!-- <v-btn slot="activator" icon >
          <v-icon>format_list_numbered_rtl</v-icon>
        </v-btn> -->
        <v-list>
          <v-list-tile>
            <v-list-tile-title><v-icon color="green">done_outline</v-icon> Ordenar por Disponíveis</v-list-tile-title>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-title><v-icon color="red">power_settings_new</v-icon> Ordenar por Indisponíveis</v-list-tile-title>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-title><v-icon>text_rotation_none</v-icon> Ordenar por Nome</v-list-tile-title>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-title><v-icon color="orange">access_time</v-icon> Ordenar por Último incidente</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile>
            <v-list-tile-title><v-icon color="red">close</v-icon> Fechar Menu</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div></div>
    <v-container fluid grid-list-md style="padding-top: 8px; padding-bottom: 0px !important">
      <v-layout row wrap>
        <v-flex d-flex xs12 sm12 md12 lg12 class="text-xs-center">
          <strong v-if="content && content.nome_grupo">
            {{content.nome_grupo || 'Minha Rede'}}
          </strong>
        </v-flex>
        <v-alert v-if="!apiError && content.grupos && content.grupos.length < 1" :value="true" type="warning">
          Nenhum grupo encontrado<span v-if="content.nome_grupo"> para {{content.nome_grupo}}</span>.
        </v-alert>
        <v-alert v-if="!apiError && !content.grupos" :value="true" type="info">
          Buscando grupos, por favor aguarde.
        </v-alert>
        <v-alert v-if="apiError" :value="true" type="error">
          Erro ao buscar informações. Verifique sua conexão com a internet, tente novamente ou contacte o suporte técnico.
        </v-alert>
      </v-layout>
    </v-container>
    <grupos-real-time v-if="content.grupos" :grupos="content.grupos" v-on:action="clickGroup"></grupos-real-time>
    <v-bottom-sheet v-model="sheet">
      <v-list>
        <v-subheader><strong>{{selected.nome_grupo}}</strong>: Selecione uma Opção</v-subheader>
        <v-list-tile @click="openGroup()" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon>open_in_browser</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Abrir {{selected.nome_grupo}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="grupoDetalhes = selected; grupoInfo = true; sheet = false" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon>info</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Detalhes de {{selected.nome_grupo}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="$router.push({name: 'indisponibilidades', query: {grupo_dados_id: selected.id, status_indi: 0}})" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon color="red">arrow_downward</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Listar Indisponibilidades</v-list-tile-title>
        </v-list-tile>
        <v-list-tile v-if="selected.funcao_tipo == 1" @click="$router.push({name: 'google-maps-grupos', params: {id: selected.id}}); sheet = false" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon>map</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Abrir no Google Maps</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="selected.funcao_tipo == 1 ? $router.push({name: 'grupo-mapa-grupos', params: {id: selected.id}}) : $router.push({name: 'grupo-mapa-dispositivos', params: {id: selected.id}}); sheet = false" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon>show_chart</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Abrir Topologia</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-bottom-sheet>
    <v-dialog v-model="grupoInfo" scrollable max-width="300px">
      <grupo-info :grupo="grupoDetalhes">
        <span slot="action">
          <v-btn icon @click="grupoInfo = false"><v-icon>close</v-icon></v-btn>
        </span>
      </grupo-info>
    </v-dialog>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import GrupoInfo from '@/modules/network/pages/Grupo/GrupoInfo'
  import GruposRealTime from '@/modules/network/components/GruposRealTime'
  export default {
    data () {
      return {
        sheet: false,
        grupoDetalhes: {},
        grupoInfo: false,
        selected: {},
        paginas: [{ text: 'Início', disabled: true, href: '#/main/dashboard' }, { text: 'Minha Rede', disabled: true }],
        content: {},
        apiError: false,
        background: 'static/background/background-gradiente-1.png'
      }
    },
    methods: {
      loadApi: function () {
        var config = {uri: 'topologia/dados', mode: 1, params: {}}
        if (this.$route.query && this.$route.query.grupo) {
          config.uri = 'grupo/grupo-conexao/' + this.$route.query.grupo
          config.params = {join: 'grupos'}
          config.mode = 2
        }
        callApi.get({
          uri: config.uri,
          params: config.params,
          msgLoad: true,
          sucess: (data) => {
            console.log(data.data.length)
            console.log(config.mode)
            if (config.mode === 1) {
              if (data.data.length > 1) {
                this.content['grupos'] = data.data
              } else {
                if (data && data.data && data.data[0] && data.data[0]['children']) {
                  this.content = data.data[0]
                  this.content['grupos'] = data.data[0]['children']
                  console.log('if')
                } else {
                  console.log('else')
                  this.content['grupos'] = []
                  this.apiError = false
                }
              }
            } else {
              this.content = data.data
            }
            this.apiError = false
            this.$forceUpdate()
            console.log(this.content['grupos'])
          },
          error: (error) => {
            console.log(error)
            this.apiError = true
          }
        })
      },
      clickGroup: function (grupo) {
        this.selected = grupo
        this.sheet = true
      },
      openGroup: function () {
        if (this.selected.funcao_tipo === 1) {
          this.$router.push({name: 'mobile-grupo', query: {grupo: this.selected.id}})
        } else {
          this.$router.push({name: 'mobile-disp', query: {id: this.selected.id}})
        }
        this.sheet = false
      }
    },
    created: function () {
      this.loadApi()
    },
    watch: {
      '$route': 'loadApi'
    },
    components: {
      GrupoInfo,
      GruposRealTime
    }
  }
</script>
