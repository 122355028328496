<template>
  <div>
    <v-data-table
    :headers="header"
    :items="content"
    hide-actions
    :loading="loadingTable"
    item-key="id"
    class="elevation-1" 
    :pagination.sync="pagination"
    :total-items="50"
    :select-all="multiple ? true : false"
    v-model="selected"
    no-results-text="Nenhum resultado encontrado"
    >
    <v-progress-linear slot="progress" color="primary" :indeterminate="true"></v-progress-linear>
    <template slot="items" slot-scope="props">
      <tr>
        <td class="justify-center layout px-0" v-if="multiple">
          <v-checkbox
          v-model="props.selected"
          primary
          hide-details
          :change="multipleAction()"
          ></v-checkbox>
        </td>
        <td class="justify-center layout px-0" v-if="select && !multiple">
          <v-btn icon class="mx-0" @click="selectAction(props.item)">
            <v-icon color="green">check</v-icon>
          </v-btn>
        </td>
        <td class="justify-center layout px-0" v-if="!select">
          <v-btn v-if="!config.config || (typeof config.config.edit == 'function' ? config.config.edit(props.item) : config.config.edit)" @click="editAction(props.item)" v-prms="{'id': config.id, mode: 'disabled'}" slot="active" icon class="mx-0">
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn v-if="!config.config || (typeof config.config.delete == 'function' ? config.config.delete(props.item) : config.config.delete)" v-prms="{'id': config.id, mode: 'disabled'}" icon class="mx-0" @click="deleteAction(props.item)">
            <v-icon color="red">delete</v-icon>
          </v-btn>
        </td>
        <!-- eslint-disable -->
        <td :class="{'pointer': isDetail}" @click="detailAction(props.item)" v-for="(head, keyHeader) in header" v-if="head.value && head.list && head.type !== 'link'">
          <span v-if="resolve(head.value, props.item)">
            <span v-if="head.mask">{{resolve(head.value, props.item) | mask(typeof head.mask == 'function' ? head.mask(props.item) : head.mask)}}</span>
            <span v-else v-html="resolve(head.value, props.item)"></span>
          </span>
          <span v-else-if="head.type == 'int'"> 0 </span>
          <span v-else> - </span>
        </td>
        <td class="justify-center px-0" v-for="(head, keyHeader) in header" v-if="head.type === 'link'" style="text-align: center !important;">
          <span v-for="(link, keyLink) in head.links" v-if="link.show(props.item)">
            <v-btn v-if="link.icon && !link.text" icon class="mx-0" @click="link.action($router, props.item)">
              <v-icon color="blue">{{link.icon}}</v-icon>
            </v-btn>
            <v-btn outline @click="link.action($router, props.item)" v-if="link.text" small color="primary" dark>
              <span style="padding-right: 5px;">{{link.text}}</span>
              <v-icon color="primary" small v-if="link.icon">{{link.icon}}</v-icon>
            </v-btn>
          </span>
        </td>
      </tr>
    </template>
    <template slot="no-data">
      <v-alert :value="true" color="error" icon="warning">
        Sorry, nothing to display here :(
      </v-alert>
    </template>
  </v-data-table>
</div>
</template>
<script>
  export default {
    data () {
      return {
        loading: true,
        pagination: {descending: true},
        selected: []
      }
    },
    props: ['store', 'edit', 'delete', 'select', 'detail', 'multiple'],
    computed: {
      content () {
        return this.$store.state.data[this.store + '_list'] ? this.$store.state.data[this.store + '_list'].data : []
      },
      loadingTable () {
        return this.$store.state.pages[this.store].loading_list || false
      },
      header () {
        var head = this.multiple ? [] : [{text: 'Ação', align: 'center', sortable: false}]
        this.$store.state.pages[this.store].fields.filter(function (h) { if (h.list) { head.push(h) } })
        return head
      },
      config () {
        return this.$store.state.pages[this.store]
      },
      expandedItens () {
        var expand = this.$store.state.pages[this.store].fields
        expand = expand.filter(function (e) { if (e.expand) { return e } })
        return expand
      },
      isDetail () {
        return this.$store.state.pages[this.store].urlDetails || false
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    methods: {
      editAction: function (row) {
        if (this.edit) {
          this.edit(row)
        }
      },
      deleteAction: function (row) {
        if (this.delete) {
          this.delete(row)
        }
      },
      selectAction: function (row) {
        if (this.select) {
          this.select(row)
        }
      },
      detailAction: function (row) {
        if (this.detail) {
          this.detail(row)
        }
      },
      multipleAction: function () {
        if (this.selected) {
          this.multiple(this.selected)
        }
      },
      resolve: function (path, obj) {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : null
        }, obj || self)
      }
    }
  }
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>