<template>
  <v-list-tile dense avatar :style="{background: field.color}">
    <v-list-tile-avatar>
      <v-avatar>
        <v-icon v-if="field.icon" large style="padding-bottom: 2px; padding-right: 2px;">
          {{field.icon}}
        </v-icon>
      </v-avatar>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title>
        <strong>
          {{field.label}}
        </strong>
      </v-list-tile-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
<script>
  export default {
    name: 'FormReplyListReplyDefault',
    props: {
      field: {
        type: Object,
        required: true
      }
    }
  }
</script>