<template>
  <v-card class="elevation-17">
    <v-container class="contract-internet-plan">
      <div v-if="plan && plan.id">
        <ContractInternetPlanTitle
          :business="business"
          :plan="plan"
          @onSubmit="onSubmit"
        ></ContractInternetPlanTitle>
        <ContractInternetPlanSelected
          :business="business"
          :plan="plan"
          @onSubmit="onSubmit"
        ></ContractInternetPlanSelected>
      </div>
      <ContractInternetPlanNotSelected
        v-else
        :business="business"
        :plan="plan"
        @onSubmit="onSubmit"
      ></ContractInternetPlanNotSelected>
    </v-container>
  </v-card>
</template>
<script>
  import ContractInternetPlanTitle from './ContractInternetPlanTitle'
  import ContractInternetPlanSelected from './ContractInternetPlanSelected'
  import ContractInternetPlanNotSelected from './ContractInternetPlanNotSelected'
  export default {
    name: 'ContractInternetPlan',
    methods: {
      onSubmit: function (response) {
        console.log(response)
        this.$emit('onSubmit', response)
      }
    },
    props: {
      business: {
        type: Object,
        required: true,
        default: () => ({})
      },
      plan: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      ContractInternetPlanTitle,
      ContractInternetPlanSelected,
      ContractInternetPlanNotSelected
    }
  }
</script>
<style scoped>
  .contract-internet-plan {
    padding: 0px !important;
  }
</style>