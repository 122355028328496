export default {
  id: '4f1be58b',
  list: true,
  title: 'Urgência do Chamado',
  apiUrl: 'chamado/urgencia',
  fields: [
    {text: 'Descreva a urgência.', type: 'subheader', edit: true},
    {value: 'id', text: 'ID', type: 'text', list: true, edit: false},
    {value: 'nome_urgencia', text: 'Nome', placeholder: 'Insira aqui o nome do urgencia.', type: 'text', size: ['xs12', 'sm12', 'md7', 'lg7'], required: true, list: true, edit: true},
    {value: 'cor_urgencia', text: 'Cor', placeholder: 'Insira aqui a cor da urgencia.', type: 'color', size: ['xs12', 'sm12', 'md2', 'lg2'], required: false, list: false, edit: true},
    {
      value: 'icone_urgencia',
      text: 'Nível de 0 (Baixo) a 9 (Alto)',
      type: 'select',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      required: false,
      options: [
        { value: 0, text: 'Nível 0' },
        { value: 1, text: 'Nível 1' },
        { value: 2, text: 'Nível 2' },
        { value: 3, text: 'Nível 3' },
        { value: 4, text: 'Nível 4' },
        { value: 5, text: 'Nível 5' },
        { value: 6, text: 'Nível 6' },
        { value: 7, text: 'Nível 7' },
        { value: 8, text: 'Nível 8' },
        { value: 9, text: 'Nível 9' },
        { value: 10, text: 'Nível 9+' }
      ],
      list: false,
      edit: true
    },
    {value: 'obs_urgencia', text: 'Descrição', placeholder: 'Se necessário, coloque aqui uma descrição sobre este urgencia.', type: 'textarea', required: false, list: true, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
