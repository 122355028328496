<template>
<div>
    <v-toolbar dense class="elevation-5 theme--light" :color="!$wiDark ? 'primary' : 'dark'" >
      <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div v-if="period" style="text-align: center !important;">
        <div class="v-date-picker-header theme--light" style="max-width: 300px;">
          <v-btn icon :disabled="loading" @click="calendarDateAction({action: 'prev'})">
            <v-icon color="white">chevron_left</v-icon>
          </v-btn>
          <v-dialog ref="currentDateDialog" v-model="currentDateDialog" persistent lazy full-width width="290px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="v-date-picker-header__value white--text" :loading="loading" outline small color="white">
                {{ currentDateTitle || 'Buscando...' }}
              </v-btn>
            </template>
            <v-date-picker v-model="currentDateDialogValue" :value="currentDate" locale="pt-br" :type="period" color="primary" scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="currentDateDialog = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="calendarDateAction({currentDate: currentDateDialogValue, action: 'change'})">OK</v-btn>
            </v-date-picker>
          </v-dialog>
          <v-btn icon :disabled="loading" @click="calendarDateAction({action: 'next'})">
            <v-icon color="white">chevron_right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn outline small color="white" @click="createNewTask()">
        <v-icon>add</v-icon>
        <span>Adicionar Novo</span>
      </v-btn>
      <v-btn icon ripple @click="calendarDateAction({action: 'refresh'})">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true" style="margin: 1px 0 0 0 !important;"></v-progress-linear>
  </div>
</template>
<script>
  export default {
    name: 'CalendarToolbar',
    data: () => ({
      content: {},
      calendar: {},
      period: 'month',
      currentDateDialog: false,
      currentDateDialogValue: null
    }),
    computed: {
      loading () {
        return this.$store.state.WiCalendar.loading
      },
      currentDate () {
        return this.$store.state.WiCalendar.currentDate
      },
      currentDateTitle () {
        return this.$store.state.WiCalendar.currentDateTitle
      }
    },
    methods: {
      calendarDateAction: function ({currentDate, action}) {
        this.$store.dispatch('WiCalendar/getContent', {currentDate: currentDate, action: action})
        this.currentDateDialog = false
      },
      createNewTask: function () {
        this.$WiEditDialog({
          wiConfig: 'task',
          data: {
            date: this.$wiCurrentDate
          },
          onSubmit: () => {
            this.calendarDateAction({currentDate: this.currentDate, action: 'change'})
          },
          redirectOnSubmit: false
        })
      }
    },
    created: function () {}
  }
</script>
<style scoped>
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }
  .calendar__options {
    padding-left: 10px;
  }
  .calendar__calendar {
    padding-right: 10px;
  }
</style>