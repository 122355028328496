import Talk from '@/modules/talk'
import Sales from '@/modules/sales'
import Forms from '@/modules/forms'
import Finance from '@/modules/finance'
import Register from '@/modules/register'
import Projects from '@/modules/projects'
import ServiceDesk from '@/modules/service-desk'

export default {
  name: 'Wise Message',
  nameHtml: 'Wise <strong>Message</strong>',
  icon: 'wise_message.png',
  version: '5.2.15',
  lastUpdate: '18/08/2020',
  color: '#06a34d',
  type: 'web', // web or mobile
  mobile_link: 'https://play.google.com/store/apps/details?id=connectview.insights.com',
  modules: [
    Talk,
    Sales,
    ServiceDesk,
    Finance,
    Projects,
    Register,
    Forms
  ]
}
