import Dashboard from '@/modules/finance/pages/Dashboard/Dashboard'
import Category from '@/default/component/Category/Category'

import FinanceByPersonReport from '@/modules/finance/pages/Report/FinanceByPersonReport'
import FinancesByCategoriesReport from '@/modules/finance/pages/Report/FinancesByCategoriesReport'
import FinanceDRE1 from '@/modules/finance/pages/Report/FinanceDRE1'

export default [
  {
    path: 'dashboard-finance',
    name: 'dashboard-finance',
    component: Dashboard,
    meta: {
      id: 'bc2dcdd9-8571-4515-9917-8c1f871ca822',
      auth: true,
      title: 'Dashboard Financeiro',
      icon: 'dashboard'
    }
  },
  {
    path: 'finance-category',
    name: 'finance-category',
    component: Category,
    meta: {
      id: '1b113a91',
      auth: true,
      title: 'Categorias Financeiras',
      icon: 'category'
    }
  },
  {
    path: 'report-finance-in-by-customer',
    name: 'finance-in-by-customer',
    component: FinanceByPersonReport,
    meta: {
      id: 'd447931a',
      auth: true,
      title: 'Relatório de Receitas por Cliente',
      icon: 'bar_chart',
      touch: true,
      in_out: 1,
      person: 'customer'
    }
  },
  {
    path: 'report-finance-out-by-customer',
    name: 'finance-out-by-customer',
    component: FinanceByPersonReport,
    meta: {
      id: '1bdc29b2',
      auth: true,
      title: 'Relatório de Despesas por Cliente',
      icon: 'bar_chart',
      touch: true,
      in_out: 2,
      person: 'customer'
    }
  },
  {
    path: 'report-finance-out-by-collaborator',
    name: 'finance-out-by-collaborator',
    component: FinanceByPersonReport,
    meta: {
      id: '47434df2',
      auth: true,
      title: 'Relatório de Despesas por Colaborador',
      icon: 'bar_chart',
      touch: true,
      in_out: 2,
      person: 'collaborator'
    }
  },
  {
    path: 'report-finance-out-by-provider',
    name: 'finance-out-by-provider',
    component: FinanceByPersonReport,
    meta: {
      id: '7287bfda',
      auth: true,
      title: 'Relatório de Despesas por Fornecedor',
      icon: 'bar_chart',
      touch: true,
      in_out: 2,
      person: 'provider'
    }
  },
  {
    path: 'report-finances-in-by-categories',
    name: 'finances-in-by-categories',
    component: FinancesByCategoriesReport,
    meta: {
      id: '4da2381f',
      auth: true,
      title: 'Relatório de Receitas por Categorias',
      icon: 'bar_chart',
      touch: true,
      in_out: 1
    }
  },
  {
    path: 'report-finances-out-by-categories',
    name: 'finances-out-by-categories',
    component: FinancesByCategoriesReport,
    meta: {
      id: '3b41a419',
      auth: true,
      title: 'Relatório de Despesas por Categorias',
      icon: 'bar_chart',
      touch: true,
      in_out: 2
    }
  },
  {
    path: 'finance-dre',
    name: 'finance-dre',
    component: FinanceDRE1,
    meta: {
      id: '4da2381f',
      auth: true,
      title: 'DRE Financeiro',
      icon: 'bar_chart',
      touch: true,
      in_out: 1
    }
  }
]
