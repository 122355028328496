<template>
  <wi-report
    v-if="config"
    :title="config.title"
    :config="config"
    :api="{
      url: config.api.url,
      params: config.api.params
    }"
    :xls-export-format="xlsExportFormat"
  >
    <template slot="statistics" slot-scope="props">
      <v-layout row wrap>
        <v-flex
          v-for="(statistic, statisticKey) in config.statistics"
          :key="statisticKey"
          xs12 sm12 md3 lg3
          style="padding: 0px 5px;"
        >
          <v-card :color="statistic.color">
            <v-card-text>
              <strong style="color: white;">
                {{statistic.title}}
              </strong>
              <strong style="color: white;" :style="{'font-size': statistic.size || '16px'}">
                <span v-if="statistic.field == 'count_total'">
                  {{props.statistics[statistic.field] | number}}
                </span>
                <span v-else>
                  {{props.statistics[statistic.field] | money}}
                </span>
              </strong>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template slot-scope="props">
      <tr>
        <td style="text-align: center;">
          <strong>
            {{ props.item.name }}
          </strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.finances_count" style="font-size: 18px;">
            {{ props.item.finances_count }}
          </strong>
          <strong v-else>0</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.finances_sum_amount_total">
            {{ props.item.finances_sum_amount_total | money }}
          </strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.finances_sum_amount_paid_total">
            {{ props.item.finances_sum_amount_paid_total | money }}
          </strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.finances_sum_amount_unpaid_total">
            {{ props.item.finances_sum_amount_unpaid_total | money }}
          </strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <v-btn
            small
            outline
            color="primary"
            :disabled="!props.item.finances_count"
            @click="goToRouterByFinancesByFilter(props.item)"
          >
            Ver {{in_out.title}}(s)
          </v-btn>   
        </td>
      </tr>
    </template>
  </wi-report>
</template>
<script>
  import WiReport from '@/default/component/WiReport/WiReport'
  export default {
    name: 'FinancesByCategoriesReport',
    data () {
      return {
        config: null,
        makeConfigCount: 0
      }
    },
    computed: {
      person: function () {
        var person = {}
        switch (this.$route.meta.person) {
          case 'customer':
            person = {
              id: 'person_id',
              name: 'customer',
              title: 'Cliente',
              first_name: 'Razão Social'
            }
            break
          case 'provider':
            person = {
              id: 'person_id',
              name: 'provider',
              title: 'Fornecedor',
              first_name: 'Razão Social'
            }
            break
          case 'collaborator':
            person = {
              id: 'collaborator_id',
              name: 'collaborator',
              title: 'Colaborador',
              first_name: 'Nome Completo'
            }
            break
        }
        return person
      },
      in_out: function () {
        var inOut = {}
        switch (this.$route.meta.in_out) {
          case 1:
            inOut = {
              name: 'in',
              title: 'Receita',
              titles: 'Receitas',
              action: 'Recebido'
            }
            break
          case 2:
            inOut = {
              name: 'out',
              title: 'Despesa',
              titles: 'Despesas',
              action: 'Pago'
            }
            break
        }
        return inOut
      }
    },
    methods: {
      makeConfig: function () {
        this.config = null
        var config = {
          title: this.in_out.titles + ' por Categorias',
          api: {
            url: 'finance/report/finances-' + this.in_out.name + '-by-categories',
            params: {}
          },
          statistics: [
            {
              field: 'count_total',
              title: 'Total de ' + this.in_out.titles + ': ',
              color: 'orange',
              size: '16px'
            },
            {
              field: 'sum_amount_total',
              title: 'Valor total: ',
              color: 'blue',
              size: '16px'
            },
            {
              field: 'sum_amount_paid_total',
              title: 'Valor pago: ',
              color: 'green',
              size: '16px'
            },
            {
              field: 'sum_amount_unpaid_total',
              title: 'Valor não pago: ',
              color: 'red',
              size: '16px'
            }
          ],
          dates: {
            field: {
              text: 'Data de filtro',
              items: [
                {
                  value: 'date_competence',
                  text: 'Data de Competência'
                },
                {
                  value: 'date_validity',
                  text: 'Data de Validade'
                },
                {
                  value: 'date_receive',
                  text: 'Data de Recebimento'
                },
                {
                  value: 'created_at',
                  text: 'Data de Criação'
                }
              ]
            },
            start: {
              type: 'date',
              text: 'Data Inicial'
            },
            end: {
              type: 'date',
              text: 'Data Final'
            }
          },
          filters: {
            text: 'Filtros',
            fields: []
          },
          fields: [
            {
              text: 'Categorias',
              value: 'name',
              align: 'center'
            },
            {
              text: 'N° de ' + this.in_out.titles,
              value: 'finances_count',
              align: 'center'
            },
            {
              text: 'Valor',
              value: 'finances_sum_amount_total',
              align: 'center'
            },
            {
              text: this.in_out.action,
              value: 'finances_sum_amount_paid_total',
              align: 'center'
            },
            {
              text: 'Não ' + this.in_out.action,
              value: 'finances_sum_amount_unpaid_total',
              align: 'center'
            },
            {
              sortable: false
            }
          ]
        }
        if (this.makeConfigCount) {
          setTimeout(() => {
            this.config = config
          }, 500)
        } else {
          this.config = config
        }
        this.makeConfigCount++
      },
      goToRouterByFinancesByFilter: function (category) {
        let route = {
          name: 'finance-list',
          params: {
            page: 'finance-' + this.in_out.name + '-list'
          },
          query: {
            'finance_category_id': category.id,
            ...this.$route.query
          }
        }
        this.$router.push(route)
      },
      xlsExportFormat: function (data) {
        console.log(data);
        return {
          ...data,
          finances_sum_amount_paid_total: this.$options.filters.money(data.finances_sum_amount_paid_total),
          finances_sum_amount_total: this.$options.filters.money(data.finances_sum_amount_total),
          finances_sum_amount_unpaid_total: this.$options.filters.money(data.finances_sum_amount_unpaid_total)
        }
      }
    },
    mounted: function () {
      this.makeConfig()
    },
    watch: {
      '$route': 'makeConfig'
    },
    components: {
      WiReport
    }
  }
</script>
