import { Filters } from '@/default/model/Filters'

export class LogFilters extends Filters {
  constructor () {
    super()
    this.id = ''
    this.title = 'Filtrar'

    // filter fields

    this.fields = {
      subHeader1: this.fieldSubHeader({
        text: 'Selecione uma ou mais opções para filtrar.'
      }),
      type_id: this.fieldSelectApi ({
        value: 'type_id',
        text: 'Tipo',
        show: 'name',
        // multiple: true,
        api: {
          url: 'system/log/type',
        },
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      collaborator_id: this.fieldWiListDialog({
        value: 'collaborator_id',
        text: 'Usuário',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'collaborator.first_name',
        icon: 'assignment_ind'
      }),
      action_id: this.fieldSelectApi ({
        value: 'action_id',
        text: 'Ação',
        show: 'name',
        // multiple: true,
        api: {
          url: 'system/log/action',
        },
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      origin_id: this.fieldSelectApi ({
        value: 'origin_id',
        text: 'Origem',
        show: 'name',
        // multiple: true,
        api: {
          url: 'system/log/origin',
        },
        size: ['xs12', 'sm12', 'md6', 'lg6']
      })
    }
  }
}
