import { Model } from '@/default/model/Model'

export default class Site extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1d91353e'
    this.name = 'post'
    this.title = {
      list: 'Sites',
      edit: {
        insert: 'Novo Site',
        update: 'Editar Site'
      }
    }
    this.apiUrl = 'sites/site'
    this.icon = 'web'
    this.search = {
      placeholder: 'Pesquise pelo nome ou pelo domínio do site',
      fields: ['name', 'domain']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/sites/pages/Sites/Sites')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe nome, domínio e o repositório deste site.'
      }),
      this.fieldText({
        value: 'name',
        text: 'Nome',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldText({
        value: 'domain',
        text: 'Domínio',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldText({
        value: 'repository',
        text: 'Repositório',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldSubHeader({
        text: 'Informe as configurações de acesso ao banco de dados.',
        showField: form => !form.id
      }),
      this.fieldText({
        value: 'database_host',
        text: 'Endereço do banco de dados',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        showField: form => !form.id
      }),
      this.fieldText({
        value: 'database_name',
        text: 'Nome do banco de dados',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: form => !form.id
      }),
      this.fieldText({
        value: 'database_user',
        text: 'Usuário do banco de dados',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: form => !form.id
      }),
      this.fieldText({
        value: 'database_password',
        text: 'Senha do banco de dados',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: form => !form.id
      }),
      this.fieldText({
        value: 'database_prefix',
        text: 'Prefixo banco de dados',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: form => !form.id
      }),
      this.fieldSubHeader({
        text: 'Insira uma descrição para o site'
      }),
      this.fieldTextArea({
        value: 'description',
        text: 'Descrição',
        placeholder: 'Conteúdo da publicação'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'domain',
        text: 'Domínio'
      }),
      this.fieldList({
        value: 'status_id',
        text: 'Status',
        align: 'center'
      }),
      this.fieldList({
        value: 'access',
        text: 'Acessar o site',
        sortable: false
      }),
      this.fieldList({
        value: 'options',
        text: 'Opções',
        sortable: false
      })
    ])
  }
}
