export default {
  namespaced: true,
  state: {
    dialogs: []
  },
  mutations: {
    dialogs (state, data) {
      state.dialogs = data
    }
  },
  actions: {
    open: function ({ state, commit }, config) {
      console.log(config);
      var dialogs = state.dialogs
      const index = dialogs.length
      config['index'] = index
      config['active'] = false
      dialogs.push(config)
      commit('dialogs', dialogs)
      setTimeout(() => {
        dialogs[index].active = true
        commit('dialogs', dialogs)
      }, 100);
    },
    close: function ({ state, commit }, index) {
      var dialogs = state.dialogs
      if (dialogs[index].onClose) {
        dialogs[index].onClose()
      }
      dialogs[index].active = false
      commit('dialogs', dialogs)
      setTimeout(() => {
        dialogs.splice(index, 1)
        commit('dialogs', dialogs)
      }, 200)
    }
  }
}
