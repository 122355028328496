<template>
  <div>
    <div v-if="contact.type == 1 && contact.person_id && contact.person">
      <v-divider></v-divider>
      <div>
        <v-tabs
          v-model="tabs"
          @change="onTabsChange"
          color="transparent"
          slider-color="primary"
          active-class="talk-contact__tab-selected primary--text"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab class="talk-contact__tab" color="primary">
            <span>Info</span>
          </v-tab>
          <v-tab v-if="$WiCheckPermission('5dc282ab') && (isCustomer || isProvider)" class="talk-contact__tab">
            <v-badge right color="primary" overlap offset-x="22" offset-y="24">
              <template v-slot:badge>
                <span v-if="ticketsCount">
                  <span v-if="ticketsCount > 9">+9</span>
                  <span v-else v-text="ticketsCount"></span>
                </span>
              </template>
              <span>Chamados</span>
            </v-badge>
          </v-tab>
          <v-tab v-if="$WiCheckPermission('10b505c9') && (isCustomer || isProspect)" class="talk-contact__tab">
            <v-badge right color="primary" overlap offset-x="22" offset-y="24">
              <template v-slot:badge>
                <span v-if="budgetsCount">
                  <span v-if="budgetsCount > 9">+9</span>
                  <span v-else v-text="budgetsCount"></span>
                </span>
              </template>
              <span>Orçamentos</span>
            </v-badge>
          </v-tab>
          <v-tab v-if="$WiCheckPermission('c4f8c247')" class="talk-contact__tab">
            <v-badge right color="primary" overlap offset-x="22" offset-y="24">
              <template v-slot:badge>
                <span v-if="tasksCount">
                  <span v-if="tasksCount > 9">+9</span>
                  <span v-else v-text="tasksCount"></span>
                </span>
              </template>
              <span>Tarefas</span>
            </v-badge>
          </v-tab>
          <!-- <v-tab>
            <span>Faturas</span>
          </v-tab> -->
        </v-tabs>
        <v-divider></v-divider>
        <v-card-text class="py-0 px-2" style="background: #F2F2F2; height: calc(100vh - 335px); overflow-y: auto;">
          <v-tabs-items v-model="tabs" style=" margin-bottom: 70px;">
            <v-tab-item lazy>
              <TalkContactMainProfile
                :contact="contact"
                :refresh="refresh"
              />
            </v-tab-item>
            <v-tab-item v-if="$WiCheckPermission('5dc282ab')" lazy>
              <TalkContactTickets
                :talk="talk"
                :contact="contact"
                :refresh="refresh"
              />
            </v-tab-item>
            <v-tab-item v-if="$WiCheckPermission('10b505c9')" lazy>
              <TalkContactBudgets
                :talk="talk"
                :contact="contact"
                :refresh="refresh"
              />
            </v-tab-item>
            <v-tab-item v-if="$WiCheckPermission('c4f8c247')" lazy>
              <TalkContactTasks
                :talk="talk"
                :contact="contact"
                :refresh="refresh"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </div>
      <v-divider></v-divider>
    </div>
  </div>       
</template>
<script>
  import TalkContactTasks from './TalkContactTasks'
  import TalkContactBudgets from './TalkContactBudgets'
  import TalkContactTickets from './TalkContactTickets'
  import TalkContactMainProfile from './TalkContactMainProfile'
  export default {
    name: 'TalkContactTabs',
    data () {
      return {
        tabs: 0
      }
    },
    computed: {
      person () {
        const person = this.contact?.person?.person || this.contact?.person
        return person
      },
      isCustomer () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'customer') > -1
      },
      isProspect () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'prospect') > -1
      },
      isProvider () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'provider') > -1
      },
      ticketsCount () {
        return this.person?.active_tickets?.length || 0
      },
      budgetsCount () {
        return this.person?.active_budgets?.length || 0
      },
      tasksCount () {
        return this.person?.active_tasks?.length || 0
      }
    },
    methods: {
      onTabsChange: function (value) {
        window.localStorage.setItem('talks.talk-view.contact-tabs', value)
      },
      setSavedTabs: function () {
        const savedTab = window.localStorage.getItem('talks.talk-view.contact-tabs')
        this.tabs = parseInt(savedTab)
      }
    },
    mounted: function () {
      this.setSavedTabs()
    },
    props: ['talk', 'contact', 'refresh'],
    components: {
      TalkContactTasks,
      TalkContactBudgets,
      TalkContactTickets,
      TalkContactMainProfile
    }
  }
</script>
<style scoped>
  .talk-contact-budgets__list-tile {
    cursor: pointer;
    padding: 0 0px !important;
    background: lightgray;
  }
  .talk-contact-budgets__list-tile >>> .v-list__tile {
    padding: 0 5px !important;
  }
  /* .talk-contact__tab {
    height: 30px;
  } */

  .talk-contact__tab >>> .v-tabs__item {
    padding: 0 10px !important;
  }
  .talk-contact__tab-selected {
    border-bottom: solid blue 2px;
  }
</style>