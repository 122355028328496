<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar dense>
        <strong>Lista de Chamados</strong>
        <v-spacer></v-spacer>
        <slot></slot>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-if="dialog" style="min-height: 200px; padding: 10px 0 0 0 !important">
        <view-detail title="Chamados Por Rede e Ocorrência" :apiUrl="url" :apiParams="params" :notGetID="true" :notShowToolbar="true">
          <template slot-scope="dados">
            <div style="width: 100%; text-align: center; padding-bottom: 10px;">
              <h4>Exibindo {{dados.content.total}} chamados.</h4>
            </div>
            <v-data-table v-if="dados.content" :headers="headers" :items="dados.content.data" class="elevation-10" hide-actions>
              <template v-slot:items="props">
                <td class="text-xs-center" style="padding: 0 10px !important;">
                  <v-tooltip bottom>
                    <span slot="activator">
                      <v-icon outlined v-ripple @click="$router.push({name: 'chamado', params: {id: props.item.id}})" style="cursor: pointer;">info</v-icon>
                    </span>
                    <span>
                      Detalhes deste item
                    </span>
                  </v-tooltip><br>
                  <strong>#{{ props.item.id }}</strong>
                </td>
                <td class="text-xs-center" style="padding: 0 10px !important;">
                  <v-icon large :style="{color: props.item.cor_status}">{{props.item.icone_status}}</v-icon><br>
                  <strong>{{ props.item.nome_status }}</strong>
                </td>
                <td class="text-xs-center" style="max-width: 200px; padding: 0 10px !important; wid">
                  <v-icon :style="{color: props.item.cor_categoria}">{{props.item.icone_categoria}}</v-icon><br>
                  {{ props.item.ticket_category_group }}
                </td>
                <td class="text-xs-center" style="max-width: 200px; padding: 0 10px !important;">
                  {{ props.item.networks }}
                </td>
                <td class="text-xs-center" style="padding: 0 10px !important;">
                  <strong>{{ props.item.time_start }}</strong>
                </td>
                <td class="text-xs-center" style="padding: 0 10px !important;">
                  <strong>{{ props.item.duration }}</strong>
                </td>
              </template>
            </v-data-table>
          </template>
        </view-detail>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  export default {
    data () {
      return {
        headers: [
        { align: 'center', text: 'ID', value: 'id' },
        { align: 'center', text: 'Status', value: 'ticket_status_id' },
        { align: 'center', text: 'Tipo de Ocorrência', value: 'ticket_category_id' },
        { align: 'center', text: 'Redes Envolvidas', value: 'networks' },
        { align: 'center', text: 'Data de Abetura', value: 'time_start' },
        { align: 'center', text: 'Duração', value: 'duration' }
        ]
      }
    },
    methods: {},
    props: {
      url: String,
      params: String,
      dialog: Boolean
    },
    components: {
      ViewDetail
    }
  }
</script>