export default {
  id: {
    id: 'cb59faf8',
    create: 'a2ee509c',
    update: '742b2ad5',
    delete: 'eab778c7'
  },
  title: 'Dispositivo',
  apiUrl: 'disp/nao-grupo',
  fields: [
    {value: 'nome_disp', counter: 30, text: 'Nome', type: 'text', required: true, list: true, edit: true},
    {value: 'ip_disp', counter: 30, text: 'IP', type: 'text', required: true, list: true, edit: true},
    {value: 'nome_tipo', text: 'Tipo', list: true, edit: false},
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'disp_tipo_id', text: 'Fabricante', required: true, type: 'dbselect', list: false, edit: true, instore: 'dispositivos', outstore: 'dispositivos-tipo', show: 'nome_tipo'},
    {value: 'grupos', text: 'Grupos', multiple: true, autocomplete: true, required: true, type: 'dbselect', list: false, edit: {insert: true, update: false}, instore: 'dispositivos', outstore: 'grupos', show: 'nome_grupo'},
    {value: 'obs_disp', text: 'Observações', type: 'textarea', list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
