<template>
  <v-list class="diagnostic-set-category" dense>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large v-if="data.icon" :color="data.color">
          {{ data.icon }}
        </v-icon>
        <v-icon large v-else>military_tech</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> Nova Categoria: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data.name">
            {{ data.name }}
          </strong>
          <strong v-else>
            Não definido
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>
<script>
export default {
  name: 'DiagnosticSetCategory',
  methods: {
    updateProcess: function (updateProcess) {
      this.$emit('updateProcess', updateProcess)
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style scoped>
  .diagnostic-set-category {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>