<template>
  <div v-if="showMessages">
    <v-card-text>
      <ul>
        <li v-for="(message, messageKey) in messages" :key="messageKey">
          <strong>{{message.criticity}} </strong>
          <span> - </span>
          <span> {{message.description}} </span>
          <span> ({{message.errorCode}})</span>
        </li>
      </ul>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>
<script>
  export default {
    name: 'DiagnosticMessages',
    computed: {
      showMessages () {
        return this.complement?.diagnostic?.messages?.length > 0
      },
      messages () {
        return this.complement?.diagnostic?.messages || []
      }
    },
    props: {
      complement: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
