import { Transfer } from './Transfer'
import {
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_CREATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_DELETE
} from '@/default/constants/permissions'

export class TransferInCollaborator extends Transfer {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR,
      create: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_CREATE,
      update: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_UPDATE,
      delete: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_DELETE
    }
    this.name = 'product-transfer-in-collaborator'
    this.title.list = 'Transfer p/ colaborador'
    this.apiUrl = 'stocks/product-transfer-in/collaborator'
    this.urlDetails.name = 'product-transfer-in-collaborator'
    this.icon = 'social_distance'
    // Fields
    this.fields[2].text = 'Colaborador'
    this.fields[2].wiConfig = 'collaborator'
    this.fields[3].apiUrl = 'sales/config/status/product-transfer-in-collaborator'
    this.fields[4].apiUrl = 'sales/config/category/product-transfer-in-collaborator'
    this.fields[12].text = 'Colaborador'
  }
}
