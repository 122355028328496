import { Model } from '@/default/model/Model'

export class Producer extends Model {
  constructor () {
    super()
    this.list = true
    this.actions = {
      list: true,
      edit: false
    }
    this.title = 'Fabricantes'
    this.apiUrl = 'network/host/producer'
    this.params = {
      limit: 100
    }
    this.icon = 'router'
    this.components.list.tableRow = () => import('@/modules/network/pages/Producers')
    this.setFields(this.fieldsList)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID',
      sortable: false
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome',
      sortable: false
    }),
    models: this.fieldList({
      value: 'models',
      text: 'N° de Modelos',
      sortable: false
    })
  }
}
