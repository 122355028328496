import ClinicRegister from '@/modules/register/config/Person/Person?url=ClinicRegisterCustomer'

var ClinicRegisterCustomer = ClinicRegister({name: 'Paciente', field: 'customer'})

ClinicRegisterCustomer.id = 'd897f3f3'
ClinicRegisterCustomer.title = {
  list: 'Pacientes',
  edit: {
    insert: 'Novo paciente',
    update: 'Editar paciente'
  }
}
ClinicRegisterCustomer.search = {
  placeholder: 'Pesquise o(a) paciente por ID, Nome, CPF ou RG',
  fields: ['id', 'name', 'first_name', 'cpf_cnpj', 'email', 'RG']
}
ClinicRegisterCustomer.icon = 'people_outline'
ClinicRegisterCustomer.apiUrl = 'register/person/customer'
ClinicRegisterCustomer.urlDetails.name = 'patient'

ClinicRegisterCustomer.fields[1].text = 'Nome do paciente'
ClinicRegisterCustomer.fields[2].value = 'birthday'
ClinicRegisterCustomer.fields[2].text = 'Data de Nascimento'
ClinicRegisterCustomer.fields[3].text = 'CPF/RG'

ClinicRegisterCustomer.fields[8].showField = () => ( false )
ClinicRegisterCustomer.fields[10].size = ['xs12', 'sm4', 'md6', 'lg6']
ClinicRegisterCustomer.fields[26].text = 'Médico responsável'

ClinicRegisterCustomer.components.list.tableRow = () => import('@/modules/clinic/pages/Patients/Patients')

ClinicRegisterCustomer.fieldsFixed = [
  { value: 'type', data: 'Fisica' }
] 

export default ClinicRegisterCustomer
