import { Model } from '@/default/model/Model'

export class BusinessCoupon extends Model {
  constructor () {
    super()
    this.list = true
    this.title = {
      list: 'Cupons de Desconto',
      edit: {
        create: 'Criar novo cupom de desconto',
        update: 'Editar cupom de desconto'
      }
    }
    this.icon = 'card_giftcard'
    this.search = {
      placeholder: 'Pesquise ID, Descrição ou Código único',
      fields: ['id', 'slug', 'description']
    }
    this.components.list.tableRow = () => import('@/modules/shared/pages/Businesses/BusinesCoupons')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe abaixo as informações do cupom de desconto.'
      }),
      this.fieldText({
        value: 'description',
        text: 'Descrição do Cupom',
        placeholder: 'Ex: Cupom de Desconto 1',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldMoney({
        value: 'amount',
        text: 'Valor do desconto',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldNumber({
        value: 'quantity',
        text: 'Quantidade Disponível',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldText({
        value: 'slug',
        text: 'Código único do cupom',
        placeholder: 'Ex: cupom-de-desconto-1',
        required: false,
        disabled: false,
        size: ['xs12', 'sm12', 'md3', 'lg3']
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'Token do Cupom'
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição do Cupom'
      }),
      this.fieldList({
        value: 'slug',
        text: 'Código único do cupom'
      }),
      this.fieldList({
        value: 'amount',
        text: 'Valor do desconto'
      }),
      this.fieldList({
        value: 'quantity',
        text: 'Quantidade Disponível/Total'
      }),
      this.fieldList({
        value: 'id',
        text: 'Opções'
      })
    ])
  }
}
