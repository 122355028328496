<template>
  <div>
    <v-toolbar dense class="elevation-5 theme--light white--text" :color="!$wiDark ? 'primary' : 'dark'" >
      <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Agenda</v-toolbar-title>
      <v-spacer></v-spacer>
      <GoToDateDialog :loading="loading" :events="events" v-on:select="goToDate"></GoToDateDialog>
      <v-btn outline small color="white" link :to="{name: 'clinic-list', params: { page: 'task-clinic' }}">
        <v-icon>event_seat</v-icon>
        <span>Fila de Espera</span>
      </v-btn>
      <v-btn outline small color="white" @click="addTask()">
        <v-icon>add</v-icon>
        <span>Adicionar Agendamento</span>
      </v-btn>
      <v-btn icon ripple @click="refreshCalendar()">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true" style="margin: 1px 0 0 0 !important;"></v-progress-linear>
    <v-card style="margin: 10px; padding: 20px;">
      <FullCalendar ref="calendar" :options="calendarOptions"/>
    </v-card>
    <TaskDialog :task="selectedTask" :refresh="refreshCalendar" :active="taskDialog" v-on:active="closeTaskDialog"></TaskDialog>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import GoToDateDialog from './GoToDateDialog'
import TaskDialog from './TaskDialog'
import ptBrLocale from '@fullcalendar/core/locales/pt-br'
export default {
  data() {
    return {
      events: [],
      selectedTask: {},
      taskDialog: false,
      loading: false,
      calendarOptions: {
        height: window.innerHeight - 175,
        locales: [ptBrLocale],
        timeZone: 'local',
        firstDay: 0,
        dayMaxEventRows: 4,
        nowIndicator: true,
        editable: false,
        defaultTimedEventDuration: '00:30',
        plugins: [ listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin ],
        initialView: 'timeGridWeek', // dayGridWeek, timeGridWeek, dayGridMonth, listDay, listWeek, listMonth, listYear
        businessHours: [
          {
            daysOfWeek: [ 1 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 2 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 3 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 4 ], 
            startTime: '08:00',
            endTime: '17:00'
          },
          {
            daysOfWeek: [ 5 ], 
            startTime: '08:00',
            endTime: '17:00'
          }
        ],
        headerToolbar: {
          center: 'title',
          left: 'timeGridDay, timeGridFourDay, timeGridWeek, dayGridMonth' // buttons for switching between views
        },
        views: {
          timeGridFourDay: {
            type: 'timeGridWeek',
            duration: { days: 4 },
            buttonText: '4 dias'
          }
        },
        events: this.getEvents,
        dateClick: this.dayClick,
        loading: this.setLoading,
        eventClick: this.eventClick
      }
    }
  },
  methods: {
    formatCalendarEvents: function (events) {
      let eventsFormated = [];
      events.map(event => {
        let date = event.date
        let title = event.customer ? event.customer.name : event.description
        if (event.time_formated) {
          date = event.date + ' ' + event.time_formated
        }
        eventsFormated.push({
          id: event.id,
          title: title,
          date: date,
          color: event.status_info.color
        })
      })
      return eventsFormated
    },
    createNewTask: function (date) {
      date = this.formatDateTime(date)
      this.$WiEditDialog({
        wiConfig: 'task-clinic',
        data: {
          date: date.date,
          time: date.time
        },
        onSubmit: (response) => {
          if (response?.data?.id) {
            this.selectedTask = response.data
            this.taskDialog = true
          }
          this.refreshCalendar()
        },
        redirectOnSubmit: false
      })
    },
    formatDateTime: function (dateTime) {
      if (dateTime && dateTime.split('T').length > 0) {
        if (dateTime.split('T')[1] && dateTime.split('T')[1].split('-').length > 0) {
          return {
            date: dateTime.split('T')[0],
            time: dateTime.split('T')[1].split('-')[0]
          }
        }
        return {
          date: dateTime.split('T')[0],
          time: dateTime.split('T')[1]
        }
      }
      return { date: dateTime }
    },
    getEvents: function ( fetchInfo, successCallback, errorCallback ) {
      this.$Api.get({
        uri: 'system/calendar/period',
        params: {date_start: fetchInfo.startStr, date_end: fetchInfo.endStr},
        sucess: (response) => {
          if (response?.data?.events && response.data.events.length > 0) {
            this.events = response.data.events
            successCallback(this.formatCalendarEvents(response.data.events))
            this.$forceUpdate()
          } else {
            this.events = []
            successCallback([])
          }
        },
        error: (error) => {
          errorCallback(error)
        }
      })
    },
    setLoading: function ( isLoading ) {
      this.loading = isLoading
      if (isLoading) {
        this.$swal({
          title: 'Buscando agendamentos...',
          text: 'Aguarde até que a ação seja finalizada.',
          onOpen: () => { this.$swal.showLoading() }
        })
      } else {
        this.$swal.close()
      }
    },
    getEventById: function (eventId) {
      return this.events.find(event => (event.id === eventId))
    },
    eventClick: function (event) {
      const eventSelected = this.getEventById(event.event.id)
      this.openTaskDialog(eventSelected)
    },
    dayClick: function (day) {
      this.createNewTask(day.dateStr)
    },
    refreshCalendar: function () {
      this.$refs?.calendar?.getApi()?.refetchEvents()
    },
    goToDate: function (newDate) {
      this.$refs?.calendar?.getApi()?.changeView('dayGridMonth', newDate);
    },
    addTask: function () {
      this.$WiEditDialog({
        wiConfig: 'task-clinic',
        onSubmit: (response) => {
          if (response?.data?.id) {
            this.selectedTask = response.data
            this.taskDialog = true
          }
          this.refreshCalendar()
        },
        redirectOnSubmit: false
      })
    },
    openTaskDialog: function (task) {
      this.selectedTask = task
      this.taskDialog = true
    },
    closeTaskDialog: function () {
      this.selectedTask = {}
      this.taskDialog = false
    }
  },
  components: {
    TaskDialog,
    FullCalendar,
    GoToDateDialog
  }
}
</script>
<style>
  .fc .fc-button .fc-icon {
    margin-top: -10px !important;
  }
</style>