<template>
  <div class="wi-edit__location-field">
    <v-text-field 
      prepend-icon="gps_fixed"
      placeholder="Insira a longitude"
      v-model="location"
      required
      label="Latitude" 
      autocomplete="off"
    ></v-text-field>
  </div>
</template>
<script>
  import WiEditFieldsMixin from '@/default/component/WiEdit/Fields/WiEditFieldsMixin'
  import LocationConverter from '@/default/plugins/Location/Converter'
  export default {
    name: 'WiEditLocationField',
    data: () => ({
    }),
    computed: {
      location: {
        get () {
          return this.convertLocation(this.value)
        },
        set (value) {
          this.$emit('input', this.unconvertLocation(value))
        }
      }
    },
    props: {
      form: {
        type: Object,
        default: () => ({})
      },
      field: {
        type: Object,
        default: () => ({})
      },
      value: {
        required: true
      }
    },
    methods: {
      convertLocation: function () {
        if (this.$Auth.config()?.SYSTEM_LOCATION_FORMAT === 'DMS') {
          if (this.field.location === 'latitude') {
            return LocationConverter.toLat(this.value, 'dms')
          }
          return LocationConverter.toLon(this.value, 'dms')
        }
        return this.value
      },
      unconvertLocation: function (value) {
        if (this.$Auth.config()?.SYSTEM_LOCATION_FORMAT === 'DMS') {
          return LocationConverter.parse(value)
        }
        return value
      }
    },
    mixins: [WiEditFieldsMixin]
  }
</script>

<style scoped>
  .asdasdasd >>> div {
    height: 45px !important;
  }
  .wi-edit__location-field {
    max-width: 100%;
    padding-bottom: 12px;
  }
  .wi-edit__location-field-item {
    border-style: solid !important;
    border-color: gray !important;
    border-width: 1px !important;
    height: 45px !important;
  }
</style>
