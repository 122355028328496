<template>
  <div v-if="host.producer_model_id == 1 && (host.mac_address || host.serial)">
    <v-subheader class="orange--text mb-3">
      <h2>Por favor, defina o modelo do equipamento do cliente.</h2>
      <v-spacer></v-spacer>
      <SelectDialog
        :host="host"
        @onSelectModel="onSelectModel"
      ></SelectDialog>
    </v-subheader>
    <v-divider></v-divider>
  </div>
</template>
<script>
  import SelectDialog from './SelectDialog'
  export default {
    name: 'SelectSubHeader',
    methods: {
      onSelectModel: function (selectedModel) {
        this.$emit('onSelectModel', selectedModel)
      }
    },
    props: {
      host: {
        required: true
      }
    },
    components: {
      SelectDialog
    }
  }
</script>
