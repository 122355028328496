<template>
  <view-detail v-if="showDashboard" index="business-dashboard" :apiUrl="'dashboard/' + module" :notShowToolbar="true" :notGetID="true">
    <template slot-scope="props">
      <v-container style="padding: 10px;" fluid grid-list-md>
        <v-layout row wrap>
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-card>
              <v-list dense style="max-height: none !important;">
                <v-list-tile v-ripple avatar>
                  <v-list-tile-avatar size="45">
                    <v-btn icon ripple @click="$router.go(-1)" style="cursor: pointer;">
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </v-list-tile-avatar>
                  <v-list-tile-avatar @click="props.refresh()" style="cursor: pointer;" size="45">
                    <img :src="modules[module].header.image" :alt="'Wise Control, Dashboard ' + modules[module].header.title" >
                  </v-list-tile-avatar>
                  <v-list-tile-content @click="props.refresh()" style="cursor: pointer;">
                    <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                    <v-list-tile-title style="font-size:18px;">
                      <span>{{modules[module].header.title}}</span>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action @click="props.refresh()" style="min-width: 1px !important;cursor: pointer;">
                    <v-btn flat icon color="green">
                      <v-icon>refresh</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
          <v-flex v-for="(statistic, statisticKey) in modules[module].statistics" :key="'statistic-' + statisticKey" xs12 sm12 md3 lg3 style="padding: 0 5px 5px 5px;">
            <v-card outline :color="statistic.color">
              <v-card-text style="padding: 10px 0; text-align: center;">
                <h2 style="color: white;">
                  <v-icon x-large color="white">{{statistic.icon}}</v-icon>
                  <span style="font-size:38px;">{{props.content.statistics[statistic.field]}}</span>
                </h2>
                <v-btn :disabled="props.content.statistics[statistic.field] < 1" color="white" outline @click="$router.push(statistic.button.route)">{{statistic.button.text}}</v-btn>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding: 10px 5px 5px 5px;">
            <PeriodColumnChart
              :module="modules[module]"
              :config="modules[module].period"
              :data="props.content.months"
            ></PeriodColumnChart>
          </v-flex>
          <v-flex v-for="(status, statusKey) in modules[module].status" :key="'status-' + statusKey" xs12 sm12 md6 lg6 style="padding: 10px 5px 5px 5px;">
            <v-card>
              <v-card-text>
                <h4 @click="$router.push(status.route)" style="cursor: pointer;">
                  <v-icon style="height: 15px;">{{status.icon}}</v-icon>
                  <span> {{status.title}}</span>
                </h4>
                <div v-if="props.content.status && props.content.status[status.field] && props.content.status[status.field].length > 0">
                  <apexchart width="100%" type="donut" :series="setSeries(status.field, props.content.status[status.field])" :options="options[status.field]"></apexchart>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import PeriodColumnChart from './Elements/PeriodColumnChart'
  export default {
    name: 'BusinessDashboard',
    data () {
      return {
        showDashboard: true,
        options: {},
        series: [],
        module: this.moduleName || this.$route.meta.moduleName,
        modules: {
          sales: {
            header: {
              title: 'Vendas',
              image: 'static/systems/modules/wises-technology-module-sale.png'
            },
            statistics: [
              {
                color: '#58ADC0',
                icon: 'add_circle_outline',
                field: 'saleNew',
                button: {
                  text: 'Novas Vendas',
                  route: {name: 'sales-list', params: {page: 'sale-open'}, query: {new: true}}
                }
              },
              {
                color: '#00c853',
                icon: 'add_box',
                field: 'budgetNew',
                button: {
                  text: 'Novos Orçamentos',
                  route: {name: 'sales-list', params: {page: 'budget-open'}, query: {new: true}}
                }
              },
              {
                color: '#b71c1c',
                icon: 'timer_off',
                field: 'saleExpired',
                button: {
                  text: 'Vendas em Atraso',
                  route: {name: 'sales-list', params: {page: 'sale-open'}, query: {expired: true}}
                }
              },
              {
                color: '#f57f17',
                icon: 'event_busy',
                field: 'budgetExpired',
                button: {
                  text: 'Orçamentos em Atraso',
                  route: {name: 'sales-list', params: {page: 'budget-open'}, query: {expired: true}}
                }
              }
            ],
            period: [
              {
                title: 'Orçamentos',
                icon: 'shop',
                field: 'budget',
                route: {name: 'process-of-sales', params: {business: 'budget'}}
              },
              {
                title: 'Vendas',
                icon: 'local_grocery_store',
                field: 'sale',
                route: {name: 'process-of-sales', params: {business: 'sale'}}
              }
            ],
            status: [
              {
                title: 'Vendas por etapa/status',
                icon: 'local_grocery_store',
                field: 'sale',
                route: {name: 'process-of-sales', params: {business: 'sale'}}
              },
              {
                title: 'Orçamentos por etapa/status',
                icon: 'shop',
                field: 'budget',
                route: {name: 'process-of-sales', params: {business: 'budget'}}
              }
            ]
          },
          purchases: {
            header: {
              title: 'Compras',
              image: 'static/systems/modules/wises-technology-module-purchase.png'
            },
            statistics: [
              {
                color: '#5C3B83',
                icon: 'add_circle_outline',
                field: 'purchaseNew',
                button: {
                  text: 'Novas Compras',
                  route: {name: 'purchases-list', params: {page: 'purchase-open'}, query: {new: true}}
                }
              },
              {
                color: '#00c853',
                icon: 'add_box',
                field: 'quotationNew',
                button: {
                  text: 'Novos Cotações',
                  route: {name: 'purchases-list', params: {page: 'quotation-open'}, query: {new: true}}
                }
              },
              {
                color: '#b71c1c',
                icon: 'timer_off',
                field: 'purchaseExpired',
                button: {
                  text: 'Compras em Atraso',
                  route: {name: 'purchases-list', params: {page: 'purchase-open'}, query: {expired: true}}
                }
              },
              {
                color: '#f57f17',
                icon: 'event_busy',
                field: 'quotationExpired',
                button: {
                  text: 'Cotações em Atraso',
                  route: {name: 'purchases-list', params: {page: 'quotation-open'}, query: {expired: true}}
                }
              }
            ],
            period: [
              {
                title: 'Compras',
                icon: 'local_grocery_store',
                field: 'purchase',
                route: {name: 'process-of-sales', params: {business: 'purchase'}}
              },
              {
                title: 'Cotações',
                icon: 'shop',
                field: 'quotation',
                route: {name: 'process-of-sales', params: {business: 'quotation'}}
              }
            ],
            status: [
              {
                title: 'Compras por etapa/status',
                icon: 'local_grocery_store',
                field: 'purchase',
                route: {name: 'process-of-sales', params: {business: 'purchase'}}
              },
              {
                title: 'Cotações por etapa/status',
                icon: 'shop',
                field: 'quotation',
                route: {name: 'process-of-sales', params: {business: 'quotation'}}
              }
            ]
          },
          wells: {
            header: {
              title: 'Poços Tubulares',
              image: 'static/systems/modules/wises-technology-module-wells-1.png'
            },
            statistics: [
              {
                color: '#58ADC0',
                icon: 'add_circle_outline',
                field: 'wellNew',
                button: {
                  text: 'Novos Poços',
                  route: {name: 'wells-list', params: {page: 'well-open'}, query: {new: true}}
                }
              },
              {
                color: '#00c853',
                icon: 'add_box',
                field: 'contractNew',
                button: {
                  text: 'Novos Contratos',
                  route: {name: 'contract-list', params: {page: 'contract-open'}, query: {new: true}}
                }
              },
              {
                color: '#b71c1c',
                icon: 'timer_off',
                field: 'wellExpired',
                button: {
                  text: 'Poços em Atraso',
                  route: {name: 'wells-list', params: {page: 'well-open'}, query: {expired: true}}
                }
              },
              {
                color: '#f57f17',
                icon: 'event_busy',
                field: 'contractExpired',
                button: {
                  text: 'Contratos em Atraso',
                  route: {name: 'contract-list', params: {page: 'contract-open'}, query: {expired: true}}
                }
              }
            ],
            period: [
              {
                title: 'Orçamentos',
                icon: 'shop',
                field: 'budget',
                route: {name: 'process-of-sales', params: {business: 'budget'}}
              },
              {
                title: 'Poços tubulares',
                icon: 'local_grocery_store',
                field: 'well',
                route: {name: 'process-of-wells', params: {business: 'well'}}
              },
              {
                title: 'Contratos',
                icon: 'shop',
                field: 'contract',
                route: {name: 'process-of-contracts', params: {business: 'contract'}}
              }
            ],
            status: [
              {
                title: 'Poços por etapa/status',
                icon: 'local_grocery_store',
                field: 'well',
                route: {name: 'process-of-wells', params: {business: 'well'}}
              },
              {
                title: 'Contratos por etapa/status',
                icon: 'shop',
                field: 'contract',
                route: {name: 'process-of-contracts', params: {business: 'contract'}}
              }
            ]
          }
        }
      }
    },
    methods: {
      setSeries: function (field, data) {
        var r = []
        var categories = []
        data.filter((item) => {
          r.push(item.count)
          categories.push(item.name)
        })
        this.options[field] = {
          chart: {
            id: 'Chamados Por Tipo de Ocorrência'
          },
          labels: []
        }
        this.options[field].labels = categories
        return r
      },
      updateDashboard: function () {
        this.module = this.moduleName || this.$route.meta.moduleName
        this.$forceUpdate();
      }
    },
    watch: {
      '$route': 'updateDashboard'
    },
    props: {
      moduleName: {
        default: null
      }
    },
    components: {
      PeriodColumnChart
    }
  }
</script>
