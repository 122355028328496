<template>
  <v-navigation-drawer
    v-model="active"
    app
    :color="$wiDark ? 'dark' : 'blue-grey darken-4'" 
    :dark="$wiDark"
    fixed
    :mini-variant="drawerLeft.isMini"
    :touchless="touch"
    width="258"
    :temporary="temporary"
    @mouseover="onMouseOver"
    @mouseleave="onMouseOver"
  >
    <div
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
      style="display: flex; flex-direction: column; height: 100%;"
    >
      <ToolbarSystem/>
      <ToolbarProfile/>
      <ListModules v-if="!selectedModule"/>
      <ListModulesMenu v-else/>
      <ListMenu/>
      <v-list class="hidden-md-only hidden-lg-only hidden-xl-only" dense>
        <template>
          <v-list-tile @click="logOut()">
            <v-list-tile-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-t="'components.drawerLeft.menu.logout.label'"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
      <v-list v-if="drawerLeft.isMini" dense>
        <template>
          <v-list-tile @click="touggleDrawerLeftIsMini()">
            <v-list-tile-action>
              <v-tooltip right>
                <span slot="activator">
                  <v-icon>chevron_right</v-icon>
                </span>
                <span v-t="'components.drawerLeft.menu.enlarge.label'"></span>
              </v-tooltip>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-t="'components.drawerLeft.menu.enlarge.label'"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
      <!-- <Footer/> -->
    </div>
  </v-navigation-drawer>
</template>
<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import ToolbarSystem from '@/default/pages/MainNew/components/DrawerLeft/ToolbarSystem'
  import ToolbarProfile from '@/default/pages/MainNew/components/DrawerLeft/ToolbarProfile'
  import ListModules from '@/default/pages/MainNew/components/DrawerLeft/ListModules'
  import ListModulesMenu from '@/default/pages/MainNew/components/DrawerLeft/ListModulesMenu'
  import ListMenu from '@/default/pages/MainNew/components/DrawerLeft/ListMenu'
  // import Footer from '@/default/pages/MainNew/components/DrawerLeft/Footer'
  export default {
    name: 'DrawerLeft',
    data: () => ({
      isMini: true,
      temporary: false
    }),
    computed: {
      ...mapState('Pages/Main', ['drawerLeft', 'selectedModule']),
      active: {
        get: function () {
          return this.$store.state.Pages.Main.drawerLeft.active
        },
        set: function (active) {
          this.$store.commit('Pages/Main/SET_DRAWER_LEFT_ACTIVE', active)
        }
      },
      touch () {
        return this.$route.meta.touch || false
      }
    },
    mounted: function () {
      this.getDrawerLeftIsMiniFromStorage()
    },
    methods: {
      ...mapMutations('Pages/Main', ['SET_DRAWER_LEFT_IS_MINI']),
      ...mapActions('Pages/Main', ['getDrawerLeftIsMiniFromStorage', 'touggleDrawerLeftIsMini']),
      onMouseOver: function () {
        // this.SET_DRAWER_LEFT_IS_MINI(false)
        // this.temporary = true
      },
      onMouseLeave: function () {
        // this.SET_DRAWER_LEFT_IS_MINI(true)
        // this.temporary = false
      }
    },
    components: {
      ToolbarSystem,
      ToolbarProfile,
      ListModules,
      ListModulesMenu,
      ListMenu,
      // Footer
    }
  }
</script>

<style>
  .list__group__items--no-action .list__tile {
    padding-left: 16px !important;
  }
  .list {
    padding: 0 !important;
  }
  .application .theme--dark.navigation-drawer, .theme--dark .navigation-drawer {
    background: #212121 !important;
  }
  .v-list__group__items--no-action .v-list__tile {
    padding-left: 16px !important;
  }
</style>