import { Model } from '@/default/model/Model'

class TaskClinicAll extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'c4f8c247',
      create: '1924fe51',
      update: '324e9d87',
      delete: '22e2e801'
    }
    this.name = 'task-clinic-all'
    this.title = {
      list: 'Agendamentos',
      edit: {
        insert: 'Criar novo Agendamento',
        update: 'Editar Agendamento'
      }
    }
    // this.urlDetails = {name: 'task', params: {}}
    this.apiUrl = 'tasks/task'
    this.icon = 'event_available'
    this.params = {
      limit: 10,
      order: 'date,desc'
    }
    this.search = {
      placeholder: 'Pesquise pelo(a) nome ou código do paciente',
      fields: ['person_id', 'person.first_name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/clinic/pages/Tasks/TasksAll')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe os dados básicos '
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Paciente',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline'
      }),
      this.fieldSelectApi({
        value: 'status',
        text: 'Status',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        api: {
          url: 'tasks/task/status'
        },
        show: 'title',
        selectValue: 'code',
        icon: 'icon',
        color: 'color'
      }),
      this.fieldSelectApi({
        value: 'type',
        text: 'Tipo de Agendamento',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        api: {
          url: 'tasks/task/type'
        },
        show: 'title',
        selectValue: 'code',
        icon: 'icon',
        color: 'color',
        disabled: (form) => { return !!form.id }
      }),
      this.fieldDate({
        value: 'date',
        text: 'Data',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldTime({
        value: 'time',
        text: 'Horário',
        required: false,
        size: ['xs12', 'sm12', 'md1', 'lg1']
      }),
      this.fieldText({
        value: 'description',
        text: 'Descrição',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: false
      }),
      this.fieldWiListDialog({
        value: 'responsible_id',
        text: 'Médico Responsável',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'responsible.first_name',
        icon: 'person_outline'
      }),
      this.fieldSubHeader({
        text: 'Observações ou anotações '
      }),
      this.fieldEditor({
        value: 'observation',
        text: 'Observações',
        placeholder: 'Ex: Como deve ser executado(a).'
      }),
      this.fieldList({
        value: 'person_id',
        text: 'Paciente',
        sortable: false
      }),
      this.fieldList({
        value: 'date',
        text: 'Horário',
        sortable: false
      }),
      this.fieldList({
        value: 'type',
        text: 'Procedimento',
        sortable: false
      }),
      this.fieldList({
        value: 'status',
        text: 'Status',
        align: 'center',
        sortable: false
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição',
        sortable: false
      })
    ])
  }
}
export class TaskClinicOpened extends TaskClinicAll {
  constructor() {
    super()
    this.title.list = 'Agendamentos'
    this.icon = 'event'
    this.params = {
      limit: 10,
      order: 'date,desc',
      where: 'status,!=,100;status,!=,101'
    }
  }
}
export class TaskClinicClosed extends TaskClinicAll {
  constructor() {
    super()
    this.title.list = 'Histórico'
    this.icon = 'event_available'
    this.params = {
      limit: 10,
      order: 'date,desc',
      where: 'status,100'
    }
  }
}
export class TaskClinicCanceled extends TaskClinicAll {
  constructor() {
    super()
    this.title.list = 'Desistências'
    this.icon = 'event_busy'
    this.params = {
      limit: 10,
      order: 'date,desc',
      where: 'status,101'
    }
  }
}
