import { Model } from '@/default/model/Model'

export class TalkChannelTemplate extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '123eab35-8984-4551-bd15-5b054b2ac8bf'
    this.name = 'talk-channel-template'
    this.title = 'Templates de mensagens'
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: false
    }
    this.apiUrl = 'talks/channel/message/templates'
    this.icon = 'message'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pelo nome da mensagem',
      fields: ['name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '1000px'
    this.listFields = {
      id: this.fieldList({
        value: 'id',
        text: 'ID',
        sortable: false
      }),
      name: this.fieldList({
        value: 'name',
        text: 'Nome',
        sortable: false
      }),
      status: this.fieldList({
        value: 'status',
        text: 'Status',
        sortable: false
      }),
      category: this.fieldList({
        value: 'category',
        text: 'Categoria',
        sortable: false
      }),
      language: this.fieldList({
        value: 'language',
        text: 'Idioma',
        sortable: false
      })
    }

    this.setFields(this.listFields)
  }
}
