import { Model } from '@/default/model/Model'
import Contact from '@/modules/register/config/Person/Person?url=Contact'

var contact = Contact({name: 'Contato', field: 'contact'})

contact.id = {
  id: '84b55ddf',
  create: 'c0e98075',
  update: '47ccbc93',
  delete: '4df0f4e5'
}
contact.title = {
  list: 'Contatos',
  edit: {
    insert: 'Novo contato',
    update: 'Editar contato'
  }
}
contact.icon = 'contacts'
contact.apiUrl = 'register/person/contact'
contact.urlDetails.name = 'contact'




contact.fields.unshift({
  value: 'person_title',
  text: 'Título/Função',
  placeholder: 'Ex: colaborador, gerente, etc...',
  type: 'text',
  size: ['xs12', 'sm12', 'md3', 'lg3'],
  edit: true,
  showField: (form) => {
    return form.type === 'Fisica' || form.type === 'Juridica'
  }
})

contact.fields.unshift({
  value: 'person_id',
  text: 'Cliente vinculado*',
  size: ['xs12', 'sm12', 'md6', 'lg6'],
  type: 'wi-list-dialog',
  edit: true,
  required: true,
  wiConfig: 'customer',
  show: 'person.first_name',
  icon: 'person_outline',
  showField: (form) => {
    return (form.type === 'Fisica' || form.type === 'Juridica') && (form.person_type === 'customer' || !form.person_type)
  }
})

contact.fields.unshift({
  value: 'person_id',
  text: 'Fornecedor vinculado*',
  size: ['xs12', 'sm12', 'md6', 'lg6'],
  type: 'wi-list-dialog',
  edit: true,
  required: true,
  wiConfig: 'provider',
  show: 'person.first_name',
  icon: 'person_outline',
  showField: (form) => {
    return (form.type === 'Fisica' || form.type === 'Juridica') && form.person_type === 'provider'
  }
})

contact.fields.unshift({
  value: 'person_type',
  text: 'Contato vinculado a um:',
  type: 'select',
  size: ['xs12', 'sm12', 'md3', 'lg3'],
  options: [
    {value: 'customer', text: 'Cliente'},
    {value: 'provider', text: 'Fornecedor'},
  ],
  edit: true,
  showField: (form) => {
    return form.type === 'Fisica' || form.type === 'Juridica'
  }
})

contact.fields.unshift({
  text: 'Vincule este contato a um cliente ou fornecedor',
  type: 'subheader',
  edit: true,
  showField: (form) => {
    return form.type === 'Fisica' || form.type === 'Juridica'
  }
})

export class ContactBusiness extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: '84b55ddf',
      create: 'c0e98075',
      update: '47ccbc93',
      delete: '4df0f4e5'
    }
    this.title = {
      list: 'Contatos',
      edit: {
        insert: 'Novo contato',
        update: 'Editar contato'
      }
    }
    this.icon = 'contacts'
    this.apiUrl = 'register/person/contact'
    this.search = {
      placeholder: 'Pesquise pelo nome do contato',
      fields: ['id', 'name', 'first_name', 'last_name']
    }
    this.maxWidth = '800px'
    this.setFields([
      this.fieldSubHeader({
        text: 'Vincule este contato a um cliente ou fornecedor'
      }),
      this.fieldSelect({
        value: 'person_type',
        text: 'Contato vinculado a um:',
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        multiple: false,
        options: [
          {value: 'customer', text: 'Cliente'},
          {value: 'provider', text: 'Fornecedor'},
          {value: 'prospect', text: 'Lead/Prospect'},
        ],
        defaultOption: 'customer'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Cliente vinculado*',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline',
        required: true,
        showField: (form) => (form.person_type === 'customer' || !form.person_type)
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Fornecedor vinculado*',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'provider',
        show: 'person.first_name',
        icon: 'person_outline',
        required: true,
        showField: (form) => form.person_type === 'provider'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Lead/Prospect vinculado*',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'prospect',
        show: 'person.first_name',
        icon: 'person_outline',
        required: true,
        showField: (form) => form.person_type === 'prospect'
      }),
      this.fieldText({
        value: 'person_title',
        text: 'Título/Função',
        placeholder: 'Ex: colaborador, gerente, etc...',
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldSubHeader({
        text: 'Informe os dados básicos deste contato.'
      }),
      this.fieldText({
        value: 'name',
        text: 'Nome (Como será chamado)',
        placeholder: 'Ex: João',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      this.fieldText({
        value: 'first_name',
        text: 'Nome Completo',
        placeholder: 'Ex: Silva',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      this.fieldSubHeader({
        text: 'O campo abaixo pode ser usado para descrever o perfil.'
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações da Venda',
        placeholder: 'Ex: Negociação iniciada em...'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição'
      })
    ])
  }
}


export default contact
