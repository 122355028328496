<template>
  <div>
    <v-card flat style="background: #fafafa;">
      <v-toolbar :color="!dark ? 'primary' : 'dark'" flat extended center-align style="color: white;  background-image: url('static/background/background-blue.png');">
        <v-toolbar-title><slot name="title"></slot> <span v-if="total">({{total}})</span>
          <v-btn icon flat color="white" @click="loadList()">
            <v-icon>refresh</v-icon>
          </v-btn></v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="hidden-xs-only hidden-sm-only" style="max-width:600px; min-width: 400px;">
            <div class="text-xs-center">
              <v-text-field 
              style="color: white; width: 100%"
              @keyup.enter="searchLoad(search.query)" 
              v-model="search.query" 
              color="white" 
              @click:append="() => { if(search.run) { searchClear() } else { searchLoad(search.query) }  }" 
              :append-icon="search.run ? 'close' : 'search'" 
              :placeholder="search.placeholder" 
              hide-details 
              single-line dark>
            </v-text-field>
          </div>
        </div>
        <v-spacer></v-spacer>
        <slot name="button"></slot>
      </v-toolbar>
      <v-layout row pb-2 :style="{background: dark ? '#303030 !important' : ''}">
        <v-flex xs12 sm12 md10 offset-md1 ls10 offset-ls1 xl10 offset-xl1>
          <v-card class="card--flex-toolbar">
            <slot name="content"></slot>  
          </v-card><br><br>
        </v-flex>
      </v-layout>
    </v-card>
    <slot name="button-mobile"></slot>
    <v-footer inset  app>
      <v-layout row wrap justify-center>
        <v-flex xs2 sm1 md1 lg1 xl1 text-xs-center white--text>
          <v-select class="padding-inputtt" :items="[5, 10, 20, 50]" :change="loadLimit(paginate.limit)" v-model="paginate.limit" label="Select" hide-details single-line  ></v-select>
        </v-flex>
        <v-flex xs8 text-xs-center white--text>
          <div>
            <v-pagination :disabled="!page || !paginate.last_page" color="blue" :length="paginate.last_page" v-model="page" :total-visible="4"></v-pagination>
          </div>
        </v-flex>
        <v-flex xs1 text-xs-center white--text></v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        store: '',
        params: {
          limit: 5,
          order: 'id,desc'
        },
        search: {
          run: false,
          query: '',
          params: '',
          placeholder: ''
        },
        paginate: {
          limit: 5,
          last_page: null
        },
        total: null
      }
    },
    watch: {
      '$route': 'loadList'
    },
    mounted: function () {
      if (!this.notload) {
        this.store = this.$route.params.page
        this.load()
      }
      this.searchConfig()
    },
    methods: {
      loadList: function (route) {
        const r = route || this.$route
        if (this.store && (this.store !== r.params.page)) {
          this.$store.dispatch('deleteData', this.store + '_list')
        }
        if (route && route.params) {
          this.store = route.params.page
          this.searchConfig()
        }
        this.searchClear()
        this.params = {
          limit: 5,
          order: 'id,desc'
        }
      },
      searchConfig: function () {
        const fields = this.config.fields || []
        const values = []
        const texts = []
        for (var keyFields in fields) {
          if (fields[keyFields].search) {
            values.push(fields[keyFields].value)
            texts.push(fields[keyFields].text)
          }
        }
        if (values.length === 0) {
          for (var keyField in fields) {
            if (keyField < 3 && fields[keyField].list) {
              values.push(fields[keyField].value)
              texts.push(fields[keyField].text)
            }
          }
        }
        this.search.params = values
        this.search.placeholder = ''
        for (var i = 0; i < values.length; i++) {
          // this.search.params += values[i]
          // this.search.params += i < values.length - 1 ? ',' : ''
          this.search.placeholder += texts[i]
          this.search.placeholder += i < texts.length - 2 ? ', ' : i < values.length - 1 ? ' ou ' : ''
        }
      },
      searchLoad: function (query) {
        var searchQuery = ''
        for (var i = 0; i < this.search.params.length; i++) {
          if (i) {
            searchQuery += ';'
          }
          searchQuery += this.search.params[i] + ',' + query
        }
        this.load({orWhere: searchQuery, page: 1})
        this.search.run = true
      },
      searchClear: function () {
        this.search.query = ''
        delete this.params.orWhere
        this.load({page: 1})
        this.search.run = false
      },
      loadLimit: function (limit) {
        if (this.params.limit !== limit) {
          this.params.limit = limit
          this.load({page: 1})
        }
      },
      load: function (param) {
        var keyParam = null
        for (keyParam in this.config.params) {
          this.params[keyParam] = this.config.params[keyParam]
        }
        for (keyParam in param) {
          this.params[keyParam] = param[keyParam]
        }
        this.$store.dispatch('getArray', {name: this.store, params: this.params})
      }
    },
    props: ['str', 'notload'],
    computed: {
      config () {
        return this.$store.state.pages[this.store]
      },
      page: {
        get: function () {
          const data = this.$store.state.data[this.store + '_list'] || false
          // eslint-disable-next-line
          this.paginate.last_page = data ? data.last_page : 0
          // eslint-disable-next-line
          this.total = data.total || 0
          return data ? data.current_page : 0
        },
        set: function (page) {
          this.load({page: page})
        }
      },
      dark () {
        return this.$store.state.style.dark || false
      }
    }
  }
</script>

<style>
.padding-inputtt {
  padding: 4px 0 0 !important;
}
</style>