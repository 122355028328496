<template>
  <v-card class="elevation-10" :color="color" style="border-radius: 10px;">
    <v-card-text style="padding: 5px 0 0 0; text-align: center;border-radius: 10px;">
      <h2 style="color: white; display: flex; align-items: center; justify-content: center; gap: 10px; min-height: 50px;">
        <v-icon large color="white">
          {{ icon }}
        </v-icon>
        <span v-if="!dataSmall" style="font-size: 32px;">
          {{ data }}
        </span>
        <span v-if="dataSmall" style="font-size: 20px;">
          {{ data }}
        </span>
      </h2>
      <v-progress-linear
        color="white"
        :height="1"
        :value="progress"
        :indeterminate="false"
        style="margin: 1px 0 0 0 !important;"
      ></v-progress-linear>
      <div class="talk-dashboard-card-title">
        {{ title }}
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'DashboardTalksCard',
  props: {
    title: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    },
    color: {
      required: false,
      default: 'green'
    },
    data: {
      required: false,
      default: '- - -'
    },
    progress: {
      required: false,
      default: 0
    },
    route: {
      required: false,
      type: Object
    },
    dataSmall: {
      required: false,
      default: false
    }
  }
}
</script>
<style scoped>
  .talk-dashboard-card-title {
    width: 100%;
    display: flex;
    font-size: 14px;
    color: white;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: center;
    text-transform: uppercase;
  }
</style>