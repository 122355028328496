<template>
  <v-card class="elevation-17">
    <v-card-text>
      <v-list-tile-sub-title class="business-observation__title">
        <strong>Observações:</strong>
      </v-list-tile-sub-title>
      <span v-html="observation"></span>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'BusinessObservation',
    props: {
      observation: {
        type: String,
        default: null
      }
    }
  }
</script>
<style scoped>
  .business-observation__title {
    color: gray;
  }
</style>
