<template>
  <div>
    <kanban 
      title="Exibir na tela" 
      store="tag"
      noPaginate
      api-get-url="talks/message/selection-by-status" 
      api-put-url="talks/message/selection-by-status"
      field-reference-in-item="status_id"
      message-alert-title="Deseja realmente alterar a tag deste contato?"
      message-alert-text="Essa ação só poderá ser desfeita manualmente!"
      :router-click="null"
      hideAddButton="true"
      hideReorderButton="true"
    >
      <template slot="items" slot-scope="{ item }">
        <v-card
          style="
            margin-bottom: 5px;
            cursor: grab;
            width: 100% !important;
            width: 270px;
            max-width: 270px;
          "
        >
          <div>
            <v-list style="padding: 0px;">
              <v-list-tile avatar>
                <v-list-tile-avatar style="min-width: 48px;">
                  <img v-if="item.message.contact.file" :src="item.message.contact.file.url">
                  <img v-else src="static/icons/user_icon_1.png">
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong v-if="item.message.contact && item.message.contact.person && item.message.contact.person.name">
                      {{ item.message.contact.person.name | stringlength(22) }}
                    </strong>
                    <strong>
                      {{ item.message.contact.name | stringlength(22) }}
                    </strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title :style="{ color: item.message.type_info.color }">
                    <v-icon small>
                      {{ item.message.type_info.icon }}
                    </v-icon>
                    <strong>
                      Mensagem de {{ item.message.type_info.name }}
                    </strong>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </div>
          <v-divider></v-divider>
          <v-card-text
            v-if="item.message.type == 1 && item.message.text"
            style="padding: 5px; text-align: left;"
          >
            <span>
              {{ item.message.text | stringlength(100) }}
            </span>
          </v-card-text>
          <v-card-text
            v-if="item.message.type == 2 && item.message.file"
            style="padding: 5px; text-align: center; display: flex; justify-content: center; align-items: center;"
          >
            <img :src="item.message.file.url" style="max-width: 100%; max-height: 100px;">
          </v-card-text>
          <v-card-text
            v-if="(item.message.type == 4 || item.message.type == 8) && item.message.file"
            style="padding: 5px; text-align: center; display: flex; justify-content: center; align-items: center;"
          >
            <audio controls style="width: 100%;">
              <source :src="item.message.file.url" type="audio/ogg">
              <source :src="item.message.file.url" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text style="padding: 0px; text-align: center;">
            <v-btn
              small outline color="green"
              @click="openTalkMessage(item)"
            >
              <v-icon>info</v-icon>
              <span> Detalhes</span>
            </v-btn>
            <v-btn
              :disabled="!item.message.text || item.message.text == '🏝️ Imagem' || item.message.text == ''"
              small outline color="primary"
              @click="openMessageEditorDialog(item)"
            >
              <v-icon>edit</v-icon>
              <span> Editar</span>
            </v-btn>
            <v-btn
              small icon
              @click="openTalkContact(item)"
            >
              <v-icon color="primary">message</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </template>
    </kanban>
    <v-dialog v-model="talkDialog" scrollable max-width="70%" persistent lazy :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card style="min-height: 350px; border-radius: 5px; border-radius: 5px;">
        <Talk
          v-if="talkDialog && currentTalkId"
          :setTalkId="currentTalkId"
          mode="dialog"
          @onClose="onCloseTalkDialog()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="talkMessageDialog" scrollable max-width="700px" persistent lazy :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card style="min-height: 350px; border-radius: 5px; border-radius: 5px;">
        <v-toolbar dense flat color="transparent">
          <v-toolbar-title>
            Detalhes da Mensagem
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="onCloseTalkMessageDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <div v-if="talkMessageDialog">
          <v-list style="padding: 0px;">
            <v-list-tile avatar>
              <v-list-tile-avatar style="min-width: 48px;">
                <img v-if="selectedTalkMessage.message.contact.file" :src="selectedTalkMessage.message.contact.file.url">
                <img v-else src="static/icons/user_icon_1.png">
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-sub-title>
                  Contato:
                </v-list-tile-sub-title>
                <v-list-tile-title>
                  <strong v-if="selectedTalkMessage.message.contact && selectedTalkMessage.message.contact.person && selectedTalkMessage.message.contact.person.name">
                    {{ selectedTalkMessage.message.contact.person.name }}
                  </strong>
                  <strong>
                    {{ selectedTalkMessage.message.contact.name }}
                  </strong>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </div>
        <v-divider></v-divider>
        <v-card-text
          v-if="talkMessageDialog"
          style="
            display: flex;
            background-repeat: repeat;
            height: 100vh;
          "
          :style="{
            'background-image': $wiDark ? 'url(\'static/background/background-whatsapp-dark.jpg?url=123\')' : 'url(\'static/background/background-whatsapp.jpg?url=123\')',
          }"
        >
          <TalkMessage
            :talk="{}"
            style="width: 100%;"
            :message="selectedTalkMessage.message"
          ></TalkMessage>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="messageEditorDialog" width="500" persistent lazy>
      <v-card>
        <v-card-title primary-title style="padding: 0 15px;">
          <v-icon>edit</v-icon>
          <strong
            class="ml-2"
            v-t="'modules.talks.pages.talkView.talk.messageDialog.title'"
          ></strong>
          <v-spacer></v-spacer>
          <v-btn icon @click="messageEditorDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <div v-if="selectedTalkMessage">
          <v-list style="padding: 0px;">
            <v-list-tile avatar>
              <v-list-tile-avatar style="min-width: 48px;">
                <img v-if="selectedTalkMessage.message.contact.file" :src="selectedTalkMessage.message.contact.file.url">
                <img v-else src="static/icons/user_icon_1.png">
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-sub-title>
                  Contato:
                </v-list-tile-sub-title>
                <v-list-tile-title>
                  <strong v-if="selectedTalkMessage.message.contact && selectedTalkMessage.message.contact.person && selectedTalkMessage.message.contact.person.name">
                    {{ selectedTalkMessage.message.contact.person.name }}
                  </strong>
                  <strong>
                    {{ selectedTalkMessage.message.contact.name }}
                  </strong>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </div>
        <v-divider></v-divider>
        <v-card-text v-if="selectedTalkMessage" style="padding: 15px 15px 0 15px;">
          <v-textarea 
            v-model="selectedTalkMessage.message.text" 
            class="talk-input" 
            :class="{'talk-footer__input--dark': $wiDark}" 
            type="text" 
            :placeholder="$t('modules.talks.pages.talkView.talk.labels.inputPlaceholder')"
            
            rows="10"
            outline
            ref="talkFooterTextArea"
            :autofocus="true"
            single-line
            hide-details
          ></v-textarea>
        </v-card-text>
        <v-card-actions v-if="messageEditorDialog && selectedTalkMessage">
          <EmojiMenuDialog @onSelect="emojiSelector"></EmojiMenuDialog>
          <v-menu offset-y :loading="aiLoading" lazy>
            <template v-slot:activator="{ on }">
              <v-btn outline ripple v-on="on" style="margin: 8px 8px 8px 0px;" :loading="aiLoading">
                <v-icon>emoji_objects</v-icon>
                <span> Inteligência artificial</span>
              </v-btn>
            </template>
            <MessageAIMenu :message="selectedTalkMessage.message.text" @onSelect="aiOptionAction"></MessageAIMenu>
          </v-menu>
          <v-btn
            outline block color="green"
            :disabled="!selectedTalkMessage.message.text"
            @click="prepareMessage()"
          >
            <v-icon class="ml-3">update</v-icon>
            <span>Atualizar Mensagem</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import Talk from '@/modules/talk/pages/TalkView/Talk/Talk'
  import talkViewMixin from '@/modules/talk/pages/TalkView/talkViewMixin'
  import kanban from '@/default/component/WiKanban/WiKanban'
  import TalkMessage from '@/modules/talk/pages/TalkView/Talk/TalkMessage'
  import MessageAIMenu from '@/modules/talk/pages/TalkView/Talk/MessageAIMenu'
  import EmojiMenuDialog from '@/modules/talk/pages/TalkView/Talk/EmojiMenuDialog'
  export default {
    name: 'BusinessOfProcess',
    data: () => ({
      aiLoading: false,
      talkDialog: false,
      currentTalkId: null,
      talkMessageDialog: false,
      selectedTalkMessage: null,
      messageEditorDialog: false,
    }),
    methods: {
      openTalkContact (item) {
        this.currentTalkId = item.message.talk_id
        this.talkDialog = true
      },
      onCloseTalkDialog () {
        this.talkDialog = false
        this.currentTalkId = null
      },
      openTalkMessage (item) {
        this.selectedTalkMessage = item
        this.talkMessageDialog = true
      },
      onCloseTalkMessageDialog () {
        this.talkMessageDialog = false
        this.selectedTalkMessage = null
      },
      openMessageEditorDialog (item) {
        this.selectedTalkMessage = item
        this.messageEditorDialog = true
      },
      onCloseMessageEditorDialog () {
        this.messageEditorDialog = false
        this.selectedTalkMessage = null
      },
      aiOptionAction: function (action) {
        this.aiLoading = true
        this.$WiApiPost({
          uri: 'talks/talk/ai-assistent',
          data: {
            action: action,
            talk_id: null,
            talk_contact_id: this.selectedTalkMessage.message.talk_contact_id,
            message: this.selectedTalkMessage.message.text
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true
          },
          callback: ({ sucess }) => {
            if (sucess && sucess.status == 'success' && sucess?.text) {
              this.selectedTalkMessage.message.text = sucess.text
            }
            this.aiLoading = false
          }
        })
      }
    },
    components: {
      Talk,
      kanban,
      TalkMessage,
      MessageAIMenu,
      EmojiMenuDialog
    },
    mixins: [talkViewMixin]
  }
</script>