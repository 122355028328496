<template>
  <v-layout v-if="finances" class="person-finances-statistics" row wrap>
    <v-flex v-if="finances.in" xs12 sm12 md5 lg5 xl5>
      <v-card class="elevation-17 person-statistics__info--left">
        <v-layout>
          <v-flex xs12 sm12 md6 lg6 xl6>
            <v-list dense two-line style="padding: 0 !important;">
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-in-list'}, query: {'person_id': person.id}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar>
                    <img src="/static/icons/monitor/finance_in_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Receitas, Valor Total</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.in.total.amount | money}} em {{finances.in.total.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-in-list'}, query: {'person_id': person.id, paid: true}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar>
                    <img src="/static/icons/monitor/paper_money_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Receitas, Valor Pago</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.in.closed.amount | money}} em {{finances.in.closed.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6>
            <v-list dense two-line style="padding: 0 !important;">
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-in-list'}, query: {'person_id': person.id, unpaid: true}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar>
                    <img src="/static/icons/monitor/split_transaction_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Receitas, Em Aberto</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.in.opened.amount | money}} em {{finances.in.opened.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-in-list'}, query: {'person_id': person.id, expired: true}})">
                <v-list-tile-avatar tile class="person-statistics__info-avatar">
                  <v-avatar tile>
                    <img src="/static/icons/monitor/finance_in_expired_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Receitas, Em Atraso</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.in.expired.amount | money}} em {{finances.in.expired.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 md2 lg2 xl2>
      <v-card class="elevation-17">
        <v-list dense two-line style="padding: 0 !important;">
          <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-list'}, query: {'person_id': person.id}})">
            <v-list-tile-content></v-list-tile-content>
            <v-list-tile-avatar class="person-statistics__info-avatar">
              <v-avatar>
                <img src="/static/icons/monitor/money_transfer_96.png">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content></v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-list'}, query: {'person_id': person.id}})">
            <v-list-tile-content>
              <v-list-tile-sub-title style="text-align: center;">Total Líquido</v-list-tile-sub-title>
              <v-list-tile-title style="text-align: center;">
                <strong class="person-statistics__info-text" :style="{ color: financeTotalLiquid(finances) < 0 ? 'red' : 'green' }">
                  {{ financeTotalLiquid((finances)) | money}}
                </strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex v-if="finances.out" xs12 sm12 md5 lg5 xl5>
      <v-card class="elevation-17 person-statistics__info--right">
        <v-layout>
          <v-flex xs12 sm12 md6 lg6 xl6>
            <v-list dense two-line style="padding: 0 !important;">
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-out-list'}, query: {'person_id': person.id}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar>
                    <img src="/static/icons/monitor/finance_out_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Despesas, Valor Total</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.out.total.amount | money}} em {{finances.out.total.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-out-list'}, query: {'person_id': person.id, paid: true}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar tile>
                    <img src="/static/icons/monitor/paper_money_out_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Despesas, Valor Pago</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.out.closed.amount | money}} em {{finances.out.closed.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6>
            <v-list dense two-line style="padding: 0 !important;">
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-out-list'}, query: {'person_id': person.id, unpaid: true}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar>
                    <img src="/static/icons/monitor/split_transaction_out_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Despesas, Em Aberto</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.out.opened.amount | money}} em {{finances.out.opened.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile class="person-statistics__info-tile" avatar @click="$router.push({name: 'finance-list', params: {page: 'finance-out-list'}, query: {'person_id': person.id, expired: true}})">
                <v-list-tile-avatar class="person-statistics__info-avatar">
                  <v-avatar tile>
                    <img src="/static/icons/monitor/finance_out_expired_96.png">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Despesas, Em Atraso</v-list-tile-sub-title>
                  <v-list-tile-title>
                    <strong class="person-statistics__info-text">
                      {{finances.out.expired.amount | money}} em {{finances.out.expired.parcel}}x
                    </strong>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    name: 'PersonFinancesStatistics',
    methods: {
      financeTotalLiquid: function (finances) {
        return finances.in.total.amount - finances.out.total.amount
      }
    },
    props: {
      finances: {
        type: Object,
        required: true
      },
      person: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style>
  .person-statistics__info-text {
    font-size: 14px;
  }
  .person-statistics__info--left {
    margin-right: 5px;
  }
  .person-statistics__info--right {
    margin-left: 5px;
  }
  .person-statistics__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .person-statistics__info-avatar {
    min-width: 50px !important;
  }
  .person-statistics__row--bottom {
    margin-bottom: 10px;
  }
  .person-finances-statistics {
    margin-top: 10px;
  }
</style>