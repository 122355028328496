<template>
  <div style="background: white !important;">
    <v-toolbar dense :color="!$wiDark ? 'primary' : 'dark'" style="color: white;">
      <v-btn  icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{title || 'Processos'}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <WiListFilters
        v-if="wiConfig && wiConfig.filters"
        :config="wiConfig.filters"
        :params="apiGetParams"
        @filter="filters => { setFilters(filters) }"
      ></WiListFilters>
      <v-menu v-if="!hideReorderButton" v-model="orderMenu" :close-on-content-click="true" :nudge-width="300">
        <v-btn class="hidden-xs-only hidden-sm-only" small outline color="white" :disabled="loading" slot="activator">
          <v-icon>sort</v-icon>
          <span>Reordenar</span>
        </v-btn>
        <v-card>
          <v-subheader>Selecione a ordem</v-subheader>
          <v-divider></v-divider>
          <v-list>
            <v-list-tile :class="getOrderClass('order,asc')" @click="setOrder('order,asc')">
              <v-list-tile-avatar><v-icon>timeline</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Ordem padrão (Manual)</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :class="getOrderClass('opened_at,desc')" @click="setOrder('opened_at,desc')">
              <v-list-tile-avatar><v-icon>add_circle_outline</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Data de criação (Mais recentes primeiro)</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :class="getOrderClass('opened_at,asc')" @click="setOrder('opened_at,asc')">
              <v-list-tile-avatar><v-icon>add_circle_outline</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Data de criação (Mais antigas primeiro)</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :class="getOrderClass('date_end,desc')" @click="setOrder('date_end,desc')">
              <v-list-tile-avatar><v-icon>date_range</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Data de Entrega (Mais recentes primeiro)</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :class="getOrderClass('date_end,asc')" @click="setOrder('date_end,asc')">
              <v-list-tile-avatar><v-icon>date_range</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Data de Entrega (Mais antigas primeiro)</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880'" :class="getOrderClass('sum_quantity_unit,desc')" @click="setOrder('sum_quantity_unit,desc')">
              <v-list-tile-avatar><v-icon>list</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Número de itens (Do maior para o menor)</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880'" :class="getOrderClass('sum_quantity_unit,asc')" @click="setOrder('sum_quantity_unit,asc')">
              <v-list-tile-avatar><v-icon>list</v-icon></v-list-tile-avatar>
              <v-list-tile-title>Número de itens (Do menor para o maior)</v-list-tile-title>
            </v-list-tile>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn flat @click="orderMenu = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn v-if="!hideAddButton" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="$WiEditDialog({wiConfig: store + '-open', onSubmit: load, data: {}})" :disabled="loading">
        <v-icon>add</v-icon>
        <span>Adicionar Novo</span>
      </v-btn>
      <v-btn :disabled="loading" icon @click="load()"><v-icon style="color: white;">refresh</v-icon></v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true" style="margin: unset; position: absolute;"></v-progress-linear>
    <v-flex sm12 v-if="stages.length < 1 && !loading">
      <br>
      <div style="padding: 20px;">
        <v-alert :value="true" type="warning" >
          Nenhum registro encontrado!
        </v-alert>
      </div>
    </v-flex>
    <v-flex sm12 v-if="stages.length < 1 && loading">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <h1>Carregando...</h1>
      </div>
    </v-flex>
    <div v-if="stages.length > 0" style="height: calc(100vh - 97px) !important; overflow-x: auto !important; overflow-y: hidden !important; padding: 5px 5px;">
      <div :style="{width: (stages.length * 301) + 'px'}">
        <div class="box" v-for="(stage, stageIndex) in stages" :key="stageIndex">
          <div style="padding: 5px; max-width: 300px !important; margin: unset;">
            <div class="elevation-0">
              <v-toolbar dense class="elevation-10 toolbar_status" style="padding: 0px !important;">
                <v-icon
                  v-if="!storeStage"
                  :color="stage.color"
                  style="padding-right: 5px;"
                >{{stage.icon}}</v-icon>
                <v-btn v-else icon @click="$WiEditDialog({wiConfig: storeStage, onSubmit: load, data: stage})">
                  <v-icon :color="stage.color">{{stage.icon}}</v-icon>
                </v-btn>
                <strong style="font-size: 14px;">{{stage.name}}</strong>
                <v-spacer></v-spacer>
                <span style="font-size: 16px;">{{stage.items.total}}</span>
              </v-toolbar>
              <v-divider></v-divider>
              <div
                class="wi-kanban__header"
                style="height: calc(100vh - 190px) !important; overflow-y: auto; padding: 5px; background: #DFE1E6;"
              >
                <v-toolbar
                  v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880' && stage.items.data && stage.items.data.length > 0 && stage.sum_quantity_unit"
                  dense
                  class="elevation-10 toolbar_status"
                  style="padding: 0px !important; margin-bottom: 5px;"
                >
                  <v-icon>list</v-icon>
                  <strong style="font-size: 14px;">
                    Produtos/Serviços:
                  </strong>
                  <v-spacer></v-spacer>
                  <strong style="font-size: 16px;">
                    {{ stage.sum_quantity_unit }}
                  </strong>
                </v-toolbar>
                <v-toolbar
                  v-if="$Auth.user().account.id != '490fba83-2a5e-4b11-935a-754b25000880' && stage.items.data && stage.items.data.length > 0"
                  dense
                  class="elevation-10 toolbar_status"
                  style="padding: 0px !important; margin-bottom: 5px;"
                >
                  <v-icon>attach_money</v-icon>
                  <strong style="font-size: 14px;">
                    Valor total:
                  </strong>
                  <v-spacer></v-spacer>
                  <strong style="font-size: 16px;">
                    {{ stage.sum_total_liquid | money }}
                  </strong>
                </v-toolbar>
                <Container @drop="onDrop(stageIndex, $event)" group-name="col" :get-child-payload="(index) => { return stage.items.data[index] }" drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="{className: 'cards-drop-preview', animationDuration: '150', showOnTop: true}">            
                  <Draggable v-for="(item, itemIndex) in stage.items.data" :key="itemIndex">
                    <slot name="items" :item="item" :index="itemIndex" :refresh="load" :loading="loading"></slot>
                  </Draggable>
                  <v-btn
                    v-if="!noPaginate && stage.items.data && stage.items.data.length > 0"
                    block
                    outline
                    @click="itemPaginate(stage)"
                    :disabled="stage.items.last_page == stage.items.current_page"
                    :loading="stage.loading"
                  >Buscar Mais</v-btn>
                </Container>
              </div>
              <v-footer style="background: #DFE1E6; min-height: 5px !important; height: 5px !important;"></v-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-btn
    @click="$WiEditDialog({wiConfig: store + '-open', onSubmit: load, data: {}})"
    color="green"
    class="white--text hidden-md-only hidden-lg-only hidden-xl-only elevation-17" 
    fab fixed bottom right
    >
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import WiListFilters from '@/default/component/WiList/Filters'
  import {Container, Draggable} from 'vue-smooth-dnd'
  import {applyDrag} from '@/default/components/utils/helpers'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'WiKanban',
    data () {
      return {
        innerHeight: window.innerHeight,
        stages: [],
        loading: false,
        editModal: false,
        orderMenu: false,
        modalForm: {},
        modalStore: 'chamados-todos',
        currentMovement: {
          added: {},
          removed: {}
        },
        order: 'order,asc',
        filters: {}
      }
    },
    methods: {
      onDrop (collection, dropResult) {
        if (dropResult.addedIndex !== null) {
          this.currentMovement['added']['index'] = collection
          this.currentMovement['added']['data'] = dropResult.payload
          this.currentMovement['added']['position'] = dropResult.addedIndex
        }
        if (dropResult.removedIndex !== null) {
          this.currentMovement['removed']['index'] = collection
          this.currentMovement['removed']['data'] = dropResult.payload
          this.currentMovement['removed']['position'] = dropResult.removedIndex
        }
        if (dropResult.payload.stage === 1) { 
          var itensUpdate = []
          this.stages[collection].items.data = applyDrag(this.stages[collection].items.data, dropResult).filter((item, key) => {
            itensUpdate.push({id: item.id, order: key})
            return item
          })
          this.$forceUpdate()
          if ((dropResult.removedIndex === null && dropResult.addedIndex !== null) && dropResult.payload[this.fieldReferenceInItem] !== this.stages[collection].id) {
              this.agendarDialog = { id: dropResult.payload.id, active: false, stage: this.stages[collection], DateTime: null, order: dropResult.addedIndex }
              if (this.stages[collection].exigir_data === 'sim') {
                this.agendarDialog.active = true
              } else {
                this.$swal({title: this.messageAlertTitle, text: this.messageAlertText, type: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Sim, alterar!', cancelButtonText: 'Cancelar'})
                .then((response) => {
                  if (response.value) {
                    this.alterarChamado(this.agendarDialog)
                    this.setItemAttributesAfterchange()
                  } else {
                    this.revertCardMovement()
                  }
                })
              }
          }
          if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
            this.alterarPrioridade(itensUpdate)
          }
        } else {
          if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
            this.$swal.close()
            this.alterarPrioridade(itensUpdate)
          }
          this.$swal({
            type: 'info',
            title: 'Não é possível mover um item "' + dropResult.payload.status.name + '"!',
            text: 'Altere seu status manualmente.'
          })
        }
      },
      setItemAttributesAfterchange: function () {
        this.stages[this.currentMovement.added.index].items.total++
        this.stages[this.currentMovement.removed.index].items.total--
        this.stages[this.currentMovement.added.index].items.data[this.currentMovement.added.position][this.fieldReferenceInItem] = this.stages[this.currentMovement.added.index].id
      },
      revertCardMovement: function () {
        this.stages[this.currentMovement.added.index].items.data = applyDrag(this.stages[this.currentMovement.added.index].items.data, {
          addedIndex: null,
          payload: this.currentMovement.added.data,
          removedIndex: this.currentMovement.added.position
        })
        this.stages[this.currentMovement.removed.index].items.data = applyDrag(this.stages[this.currentMovement.removed.index].items.data, {
          addedIndex: this.currentMovement.removed.position,
          payload: this.currentMovement.removed.data,
          removedIndex: null
        })
      },
      load: function (isRouteChange) {
        if (isRouteChange) this.stages = []
        this.loading = true
        callApi.get({
          uri: this.apiGetUrl,
          params: this.apiParams,
          sucess: (data) => {
            this.stages = data.data
            this.$forceUpdate()
            this.loading = false
          },
          error: (err) => {
            console.log(err)
            this.loading = false
          }
        })
      },
      alterarChamado: function (config) {
        this.loading = true
        var enviar = {
          id: config.id,
          order: config.order,
          stage_id: config.stage.id
        }
        if (config.DateTime) {
          var v = config.DateTime + ''
          enviar['agendamento_log'] = v.replace('T', ' ')
        }
        callApi.put({
          uri: this.apiPutUrl + '/stage',
          id: config.id,
          data: enviar,
          sucess: (data) => {
            if (data.status === 201) {
              this.agendarDialog.active = false
              this.loading = false
            } else {
              this.$swal({ type: 'error', title: 'Oops... Algo deu errado!', text: 'Tente novamente ou contacte o suporte.' })
            }
          },
          error: (err) => {
            this.loading = false
            console.log(err)
          }
        })
      },
      alterarPrioridade: function () {
        let item = this.stages[this.currentMovement.added.index]
        let data = {
          id: this.currentMovement.added.data.id,
          order: this.currentMovement.added.position,
          stage_id: item.id
        }
        this.loading = true
        callApi.put({
          uri: this.apiPutUrl + '/reorder',
          id: data.id,
          data: data,
          sucess: () => {
            this.loading = false
          },
          error: (err) => {
            this.loading = false
            console.log(err)
          }
        })
      },
      cancelDialog: function () {
        this.load()
        this.agendarDialog.active = false
      },
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      },
      itemPaginate: function (selectedStage) {
        let stageIndex = this.stages.findIndex(stage => (stage.id == selectedStage.id))
        this.stages[stageIndex]['loading'] = true
        this.$forceUpdate()
        this.$WiApiGet({
          uri: this.apiPutUrl,
          params: {
            status: selectedStage.id,
            page: selectedStage.items.current_page + 1,
            limit: 5,
            ...this.apiParams
          },
          callback: response => {
            if (response.success) {
              this.stages[stageIndex].items.total = response.success.total
              response.success.data.map(item => {
                if (this.stages[stageIndex].items.data.findIndex(stageItem => (stageItem.id == item.id)) == -1) {
                  this.stages[stageIndex].items.data.push(item)
                }
              })
              this.stages[stageIndex].items.last_page = response.success.last_page
              this.stages[stageIndex].items.current_page = response.success.current_page
            }
            if (response.error) {
              console.log(response.error)
            }
            this.stages[stageIndex]['loading'] = false
            this.$forceUpdate()
          }
        })
      },
      setOrder: function (order) {
        this.order = order
        this.$forceUpdate()
        this.load()
        localStorage.setItem(this.store + '-kamban-order', this.order)
      },
      setCachedOrder: function () {
        const order = localStorage.getItem(this.store + '-kamban-order')
        if (order) {
          this.order = order
        }
      },
      getOrderClass: function (order) {
        if (order === this.order) {
          return {'kamban__order--selected': true}
        }
        return {}
      },
      setFilters: function (filters) {
        console.log(filters)
        this.filters = filters
        this.$forceUpdate()
        this.load()
      }
    },
    created: function () {
      this.setCachedOrder()
    },
    mounted: function () {
      this.load()
    },
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.user || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.store + '-open'] || {}
      },
      apiParams () {
        return {
          ...this.apiGetParams,
          ...this.filters,
          order: this.order
        }
      }
    },
    watch: {
      '$route': 'load'
    },
    components: {
      Container,
      Draggable,
      WiListFilters
    },
    props: {
      'title': String,
      'store': String,
      'noPaginate': {
        type: Boolean,
        default: false
      },
      'apiGetUrl': String,
      'apiGetParams': Object,
      'apiPutUrl': String,
      'apiPutParams': Object,
      'fieldOrder': String,
      'fieldReferenceInItem': String,
      'messageAlertTitle': String,
      'messageAlertText': String,
      'routerClick': Object,
      'storeStage': String,
      'hideAddButton': String,
      'hideReorderButton': String
    }
  }
</script>
<style>
.toolbar_status .v-toolbar__content {
  padding: 0px 10px;
}
.box {
  display: inline-block;
  width: 300px;
  height: 100px;
}
.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, .125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}
.draggable-item-horizontal {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  text-align: center;
  /* width : 200px; */
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, .125);
  margin-right: 4px;
  cursor: default;
}
.dragging {
  background-color: yellow;
}
.card-scene {
  padding: 50px;
  /* background-color: #fff; */
}
.card-container {
  width: 320px;
  padding: 10px;
  margin: 5px;
  margin-right: 45px;
  background-color: #f3f3f3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}
.card {
  margin: 5px;
  /* border: 1px solid #ccc; */
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
  padding: 10px;
}
.card-column-header {
  font-size: 18px;
}
.column-drag-handle {
  cursor: move;
  padding: 5px;
}
.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg)
}
.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg)
}
.opacity-ghost {
  transition: all .18s ease;
  opacity: 0.8;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}
.opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.0);
}
.form-demo {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex
}
.form {
  flex: 3;
  /* width: 500px; */
  /* background-color: #f3f3f3; */
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 6px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08), 0px 3px 3px rgba(0, 0, 0, 0.08);
}
.form-fields-panel {
  flex: 1;
  margin-right: 50px;
}
.form-ghost {
  transition: 0.18s ease;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
}
.form-ghost-drop {
  box-shadow: 0 0 2px 5px rgba(0, 0, 0, 0.0);
}
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}
.cards-drop-preview {
  background-color: #b3b5b7;
  border: 1px dashed #585859;
  margin: 5px 45px 5px 5px;
  width: 100%;
}
.kamban__order--selected {
  background: rgba(240, 136, 0, 0.801);
}
.kamban__order--selected .v-list__tile__title {
  font-weight: bold !important;
}
.kamban__order--selected .v-icon {
  color: black !important;
}
.wi-kanban__header .v-toolbar__content {
  height: 28px !important;
}
</style>
