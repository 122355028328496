<template>
  <v-dialog v-model="dialog" persistent lazy full-width width="290px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="white--text" :loading="loading" outline small color="white">
        <v-icon>find_in_page</v-icon>
        <span>Ir até uma data</span>
      </v-btn>
    </template>
    <v-date-picker
      v-model="selectedDate"
      :events="arrayEvents"
      type="month"
      locale="pt-br"
      color="primary"
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn flat @click="dialog = false">Cancelar</v-btn>
      <v-btn flat color="primary" @click="goToDate(selectedDate)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  name: 'GoToDateDialog',
  data() {
    return {
      dialog: false,
      selectedDate: this.$DateTime.date(),
      arrayEvents: null
    }
  },
  methods: {
    goToDate: function (date) {
      this.$emit('select', date)
      this.dialog = false
    }
  },
  props: ['loading', 'events']
}
</script>