export default {
  invertStages (state) {
    // const stages = state.stages.filter(stage => stage.id !== state.execution.current_stage_id)
    return state.stages.slice().reverse()
  },
  stageIds (state) {
    return state.stages.map(stage => stage.stage.id)
  },
  stageLoading (state) {
    let loading = false
    state.stages.map(stage => {
      if (stage.status.status_id == 1) {
        loading = true
      }
      return loading
    })
    return loading
  }
}
