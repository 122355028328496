<template>

    <v-layout row wrap>
      <v-flex xs12 sm12 md6 lg6 xl6>
        <v-card class="elevation-17 host-contract__areas">
          <BusinessAddress
            :business="contract"
            :person="contract.person || {}"
            :address="contract.address || {}"
          ></BusinessAddress>
        </v-card>
      </v-flex>
      <v-flex v-if="contract && contract.id" xs12 sm12 md2 lg2 xl2>
        <v-card class="elevation-17 host-contract__areas">
          <v-toolbar dense color="transparent" flat>
            <v-toolbar-title>
              <v-icon>info</v-icon>
              <span> Código(s)</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text
            :style="{
              padding: !!contract.integration_code && !!contract.external_code && '8px 15px'
            }"
            style="text-align: center;"
          >
            <div v-if="!contract.integration_code && !contract.external_code">
              <v-icon>code</v-icon><br>
            </div>
            <div v-if="!!contract.id">
              <strong>ID: </strong>
              <span> {{contract.id}} </span>
            </div>
            <div v-if="!!contract.integration_code">
              <strong>Integração: </strong>
              <span> {{contract.integration_code}} </span>
            </div>
            <div v-if="!!contract.external_code">
              <strong>Externo: </strong>
              <span> {{contract.external_code}} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex v-if="contract && contract.status" xs12 sm12 md2 lg2 xl2>
        <v-card class="elevation-17 host-contract__areas">
          <v-toolbar dense color="transparent" flat>
            <v-toolbar-title>
              <v-icon>info</v-icon>
              <span> Status</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <div style="text-align: center;">
              <v-icon>{{contract.status.icon}}</v-icon><br>
              <strong :style="{color: contract.status.color}">{{contract.status.name}}</strong>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex v-if="contract.category && contract.category.icon" xs12 sm12 md2 lg2 xl2>
        <v-card class="elevation-17 host-contract__areas">
          <v-toolbar dense color="transparent" flat>
            <v-toolbar-title>
              <v-icon>category</v-icon>
              <span> Categoria</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <div style="text-align: center;">
              <v-icon>{{contract.category.icon}}</v-icon><br>
              <strong :style="{color: contract.category.color}">
                {{contract.category.name}}
              </strong>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-card v-if="contract.person" class="elevation-17 host-contract__areas">
          <PersonInfo
            :person="contract.person"
          ></PersonInfo>
        </v-card>
        <v-card v-if="contract.person && (contract.person.phones || contract.person.adresses)" class="elevation-17 host-contract__areas">
          <PersonContactAddress
            personName="customer"
            :person="contract.person"
            wi-config="customer"
            :refresh="() => {}"
          ></PersonContactAddress>
        </v-card>
      </v-flex>
    </v-layout>

</template>
<script>
  import PersonInfo from '@/modules/register/pages/Person/PersonInfo'
  import BusinessAddress from '@/modules/business/pages/Business/BusinessAddress'
  import PersonContactAddress from '@/modules/register/pages/Person/PersonTabs/PersonContactAddress'
  export default {
    name: 'HostContract',
    props: {
      contract: {
        required: true
      }
    },
    components: {
      PersonInfo,
      BusinessAddress,
      PersonContactAddress
    }
  }
</script>
<style scoped>
  .host-contract__areas {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
  }
  .host-contract__info-number {
    font-size: 18px;
  }
  .host-contract__info-text {
    font-size: 14px;
  }
  .host-contract__info--left {
    margin-right: 5px;
  }
  .host-contract__info--right {
    margin-left: 5px;
  }
  .host-contract__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .host-contract__info-avatar {
    min-width: 50px !important;
  }
  .host-contract__row--bottom {
    margin-bottom: 10px;
  }
  .host-contract__row--top {
    margin-top: 10px;
  }
</style>
