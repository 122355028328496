import { File } from '@/default/config/Common/File'

export class PostFile extends File {
  constructor () {
    super()
    this.actions = {
      list: true,
      create: true,
      update: false,
      delete: true
    }
    this.apiUrl = 'sites/post/file'
    this.apiParams = { is_public: true }

    this.clearFields()

    this.editFields.file_id = this.fieldWiFileDialog({
      value: 'file_id',
      text: 'Imagem de destaque da publicação',
      placeholder: 'Selecione uma imagem para a capa da publicação',
      showField: form => (!form.id),
      data: {
        is_public: true
      },
      options: {
        galery: false,
        upload: true
      }
    })

    this.setFields(this.editFields)
    this.setFields(this.listFields)
  }
}