import { Model } from '@/default/model/Model'
import { PERMISSION_WELL_UPDATE } from '@/default/constants/permissions'

export class BusinessLocation extends Model {
  constructor () {
    super()
    this.id = PERMISSION_WELL_UPDATE
    this.name = 'layout-variable'
    this.title = 'Localização'
    this.icon = 'my_location'
    this.apiUrl = 'wells/well'
    this.actions = {
      update: true
    }
    this.maxWidth = '498px'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    name: this.fieldText({
      value: 'latitude',
      text: 'Latitude (Decimal)',
      placeholder: 'Ex: 00.000000',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    variable: this.fieldText({
      value: 'longitude',
      text: 'Longitude (Decimal)',
      placeholder: 'Ex: 00.000000',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    description: this.fieldText({
      value: 'location_name',
      text: 'Nome/Descrição',
      placeholder: 'Ex: Marcação 01',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    })
  }
}
