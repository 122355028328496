<template>
  <v-card class="elevation-17">
    <FormReplyList
      :form="form"
      :reply="reply"
      :replyEditor="replyEditorFormated"
      :showHeader="false"
      @onSubmit="onSubmit"
    ></FormReplyList>
  </v-card>
</template>
<script>
  import FormReplyList from '@/modules/forms/pages/FormReplyList'
  export default {
    name: 'BusinessForm',
    data: () =>({
      loading: false
    }),
    computed: {
      replyEditorFormated () {
        if (Object.keys(this.replyEditor).length > 0) {
          return {
            ...this.replyEditor,
            business_id: this.businessId,
            business_business_id: this.businessBusinessId
          }
        }
        return {
          business_id: this.businessId,
          business_business_id: this.businessBusinessId
        }
      }
    },
    methods: {
      onSubmit: function () {
        this.refresh()
      }
    },
    props: {
      businessId: {
        type: Number,
        required: true
      },
      businessBusinessId: {
        type: Number,
        required: false
      },
      form: {
        type: Object,
        required: true
      },
      reply: {
        type: Object,
        default: () => ({})
      },
      replyEditor: {
        type: Object,
        default: () => ({})
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      FormReplyList
    }
  }
</script>
