export default {
  id: '1a825af1',
  title: 'Por favor, confirme o tipo de ocorrência!',
  apiUrl: 'service-desk/ticket',
  icon: 'done_outline',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Se necessário, selecione abaixo um novo Tipo de Ocorrência e se deseja substituir a previsão atual pela do novo Tipo de Ocorrência.', type: 'subheader', edit: true, showField: (form) => { return form.get_confirmation && form.sub_category_count < 1 }},
    {text: 'Por favor, informe um Tipo de Ocorrência mais específico.', type: 'subheader', edit: true, showField: (form) => { return form.get_confirmation && form.sub_category_count > 0 }},
    {
      value: 'alter_categoria_chamado',
      text: 'Alterar o Tipo de Ocorrência antes de concluir?',
      type: 'select',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      required: true,
      options: [ { value: 'sim', text: 'Sim, alterar' }, { value: 'nao', text: 'Não, irei manter o atual' } ],
      list: false,
      edit: true,
      showField: (form) => { return form.get_confirmation && form.sub_category_count === 0 }
    },
    {
      value: 'chamado_categoria_id',
      text: 'Novo Tipo de Ocorrência*',
      placeholder: 'Você pode selecionar um Tipo de Ocorrência ou sub-ocorrência',
      size: (form) => {
        if (form.sub_category_count < 1) {
          return [ 'xs12', 'sm12', 'md6', 'lg6' ]
        } else {
          return [ 'xs12', 'sm12', 'md9', 'lg9' ]
        }
      },
      required: true,
      type: 'list-ticket-category',
      ticketFunction: 3,
      list: false,
      edit: true,
      disabled: (form) => { return form.alter_categoria_chamado === 'nao' },
      showField: (form) => { return form.get_confirmation }
    },
    {
      value: 'definir_nova_previsao_categoria',
      text: 'Alterar a Previsão do Chamado',
      type: 'select',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      required: false,
      options: [
        { value: 'sim_alterar', text: 'Usar previsão da nova Ocorrência' },
        { value: 'Nao', text: 'Manter previsão atual' }
      ],
      list: false,
      edit: true,
      disabled: (form) => { return (form.alter_categoria_chamado === 'nao' || !form.alter_categoria_chamado) && !form.chamado_categoria_id },
      showField: (form) => { return form.get_confirmation }
    },
    {text: 'Por favor, informe até quando o chamado ficará neste Status.', type: 'subheader', edit: true, showField: (form) => { return form.require_date_limit }},
    {
      value: 'deadline',
      text: 'Data de Agendamento',
      type: 'datetime',
      size: [ 'xs12', 'sm12', 'md6', 'lg6', 'offset-md3', 'offset-lg3' ],
      required: true,
      edit: true,
      showField: (form) => { return form.require_date_limit }
    }
  ],
  fieldsFixed: [
    {
      value: 'concluir_chamado', data: true
    },
    {
      value: 'alterar_status', data: true
    }
  ]
}
