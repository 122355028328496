import {
  PERMISSION_FORM_REPLY,
  PERMISSION_FORM_REPLY_CREATE,
  PERMISSION_FORM_REPLY_UPDATE,
  PERMISSION_FORM_REPLY_DELETE
} from '@/default/constants/permissions'
import { callApi } from '@/default/service/Api' 
import { Model } from '@/default/model/Model'
import { showFormFieldVerification } from '@/modules/forms/utils/showFormFieldVerification'

const getForm = async (formId) => {
  return new Promise((resolve, reject) => {
    callApi.get({
      uri: 'forms/form/' + formId + '/fields',
      params: {},
      sucess: response => {
        resolve(response.data)
      },
      error: err => {
        reject(err)
        return err
      }
    })
  })
}
export default async (formId) => {
  const form = await getForm(formId)
  class FormReplyEditor extends Model {
    constructor () {
      super()
      this.id = {
        id: PERMISSION_FORM_REPLY,
        create: PERMISSION_FORM_REPLY_CREATE,
        update: PERMISSION_FORM_REPLY_UPDATE,
        delete: PERMISSION_FORM_REPLY_DELETE
      }
      this.list = true
      this.name = 'form-reply'
      this.title = form.name
      this.icon = 'border_color'
      this.apiUrl = 'forms/form/' + formId + '/reply/content'
      this.createFieldsEdit()
      this.setFields(this.fieldsEdit)
    }
    createFieldsEdit () {
      form.fields.map(field => {
        const formField = this.getField(field)
        if (field.required) {
          formField.text += '*'
        }
        if (field.active && formField) {
          this.fieldsEdit[field.id] = formField
        }
      })
    }
    getField (field) {
      switch (field?.type?.name) {
        case 'text':
          return this.fieldText({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'number':
          return this.fieldNumber({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'select':
          return this.fieldSelect({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            options: field.options || [],
            showField: showFormFieldVerification(field)
          })
        case 'textarea':
          return this.fieldTextArea({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'date':
          return this.fieldDate({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'editor':
          return this.fieldTinyEditor({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'title':
          return this.fieldSubHeader({
            value: field.type.name + '@' + field.id,
            text: field.label,
            color: field.color,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            showField: showFormFieldVerification(field)
          })
        case 'message':
          return this.fieldMessage({
            value: field.type.name + '@' + field.id,
            text: field.description || field.label,
            color: field.color,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            showField: showFormFieldVerification(field)
          })
        case 'checkbox':
          return this.fieldCheckbox({
            value: field.type.name + '@' + field.id,
            text: field.description || field.label,
            color: field.color,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            showField: showFormFieldVerification(field)
          })
        case 'product':
          return this.fieldWiListDialog({
            value: field.type.name + '@' + field.id,
            text: field.description || field.label,
            required: field.required,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            wiConfig: 'product',
            show: field.type.name + '@' + field.id,
            icon: 'move_to_inbox',
            callbackFields: [
              {
                get: 'name',
                set: field.type.name + '@' + field.id,
              }
            ]
          })
        case 'money':
          return this.fieldMoney({
            value: field.type.name + '@' + field.id,
            text: field.label,
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'cpf':
          return this.fieldText({
            value: field.type.name + '@' + field.id,
            text: field.label,
            mask: '###.###.###-##',
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'cnpj':
          return this.fieldText({
            value: field.type.name + '@' + field.id,
            text: field.label,
            mask: '##.###.###/####-##',
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'phone':
          return this.fieldText({
            value: field.type.name + '@' + field.id,
            text: field.label,
            mask: '(##) #####-####',
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'email':
          return this.fieldText({
            value: field.type.name + '@' + field.id,
            text: field.label,
            mask: 'email',
            placeholder: field.placeholder,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            required: field.required,
            showField: showFormFieldVerification(field)
          })
        case 'bank':
          return this.fieldWiListDialog({
            value: field.type.name + '@' + field.id,
            text: field.label,
            required: field.required,
            size: ['xs12', 'sm12', `md${field.size}`, `lg${field.size}`, `xl${field.size}`],
            wiConfig: 'bank',
            show: field.type.name + '@' + field.id,
            icon: 'account_balance',
            callbackFields: [
              {
                get: 'name',
                set: field.type.name + '@' + field.id,
              }
            ]
          })
        default:
          return false
      }
    }
    fieldsEdit = {}
  }
  return new FormReplyEditor()
}
