import { Model } from '@/default/model/Model'

export class WeekdayHour extends Model {
  constructor() {
    super()
    this.id = '1a825af1'
    this.list = true
    this.title = {
      list: 'Horários',
      edit: {
        insert: 'Novo horário',
        update: 'Editar horário'
      }
    }
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pela descrição',
      fields: ['id', 'description']
    }
    this.fieldsSelected = {
      itemName: ' horários(s)'
    }
    this.icon = 'schedule'
    this.apiUrl = 'register/weekday/hour'
    this.maxWidth = '400px'
    this.components.list.tableRow = () => import('@/modules/register/pages/Tags')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'day_of_week_id',
      text: 'Dia da semana'
    }),
    name: this.fieldList({
      value: 'start_time',
      text: 'Início'
    }),
    description: this.fieldList({
      value: 'end_time',
      text: 'Fim'
    })
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Informe os dados do horário.'
    }),
    name: this.fieldSelect({
      value: 'day_of_week_id',
      text: 'Dia da semana',
      required: true,
      options: [
        {
          value: 1,
          text: 'Segunda-feira'
        },
        {
          value: 2,
          text: 'Terça-feira'
        },
        {
          value: 3,
          text: 'Quarta-feira'
        },
        {
          value: 4,
          text: 'Quinta-feira'
        },
        {
          value: 5,
          text: 'Sexta-feira'
        },
        {
          value: 6,
          text: 'Sábado'
        },
        {
          value: 7,
          text: 'Domingo'
        }
      ]
    }),
    start_time: this.fieldTime({
      value: 'start_time',
      text: 'Início',
      required: true
    }),
    end_time: this.fieldTime({
      value: 'end_time',
      text: 'Fim',
      required: true
    })
  }
}