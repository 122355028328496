<template>
  <v-dialog v-model="dialog" scrollable>
    <v-card v-if="person">
      <v-toolbar dense>
        <span>Parcelas do colaborador </span>
        <strong v-text="person.first_name"></strong>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table v-if="person && person.finances && person.finances.length > 0" v-model="selected" :headers="headers" hide-actions :items="person.finances" item-key="id" select-all class="elevation-1">
          <template v-slot:items="props">
            <td class="justify-center">
              <v-checkbox v-model="props.selected" :disabled="props.item.finance_id" primary hide-details></v-checkbox>
            </td>
            <td class="justify-center">Processo #{{props.item.business_id}} ({{ props.item.first_name }})</td>
            <td class="justify-center">{{ props.item.date_validity_formated }}</td>
            <td class="justify-center">{{ props.item.amount | money }}</td>
            <td class="justify-center">{{ props.item.number_this }}/{{ props.item.number_total }}</td>
            <td class="justify-center">
              <v-btn @click="makeFinanceByComission('unique', props.item)" :disabled="props.item.finance_id" color="green" dark outline small>Lançar no Financeiro</v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-toolbar dense>
        <strong>{{ selected.length  + ' ' }}</strong>
        <span> parcelas selecionadas </span>
        <strong>{{ total | money }}</strong>
        <v-spacer></v-spacer>
        <v-btn @click="makeFinanceByComission('multiple', selected)" :disabled="selected.length <= 0" color="green" dark>
          Lançar no Financeiro
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'BusinessComissionByPersonReportDialog',
    data () {
      return {
        selected: [],
        headers: [
          { text: 'Processo (Cliente)', value: 'name', align: 'center' },
          { text: 'Data', value: 'date_validity', align: 'center' },
          { text: 'Valor', value: 'amount', align: 'center' },
          { text: 'Parcela', value: 'number_this', align: 'center' },
          { text: 'Opções', value: 'id', align: 'center' }
        ]
      }
    },
    computed: {
      total () {
        var total = 0
        this.selected.filter(select => {
          total = total + select.amount
        })
        return total
      },
      dialog: {
        get () {
          return this.active
        },
        set (value) {
          if (!value) {
            this.selected = []
          }
          this.$emit('active')
        }
      }
    },
    methods: {
      makeFinanceByComission: function (type, finance) {
        var financeData = this.getFinanceOutData()
        if (type === 'unique') {
          financeData = this.getFinanceOutDataBySelectedTypeUnique(financeData, finance)
        } else {
          financeData = this.getFinanceOutDataBySelectedTypeMultiple(financeData)
        }
        this.openFinanceOutEditDialog(financeData)
      },
      getFinanceOutData: function () {
        return {
          advanced: true,
          date_validity: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + String(new Date().getDate()).padStart(2, '0'),
          description: 'Pagamento ',
          hide_in_out: true,
          person_id: this.person.id,
          person_make: true,
          person_type: this.person.person_type,
          status: 1,
          amount_initial: null,
          finance_account_id: null,
          finance_form_payment_id: null,
          observation: '',
          business_person_finances: []
        }
      },
      getFinanceOutDataBySelectedTypeUnique: function (financeData, finance) {
        financeData.amount_initial = finance.amount
        financeData.finance_account_id = finance.finance_account_id
        financeData.finance_form_payment_id = finance.finance_form_payment_id
        financeData.observation = this.$options.filters.money(finance.amount) + ' - Processo #' + finance.business_id + ' (' + finance.first_name + ')'
        financeData.business_person_finances.push(finance.id)
        return financeData
      },
      getFinanceOutDataBySelectedTypeMultiple: function (financeData) {
        financeData.amount_initial = this.total
        financeData.finance_account_id = this.selected[0].finance_account_id
        financeData.finance_form_payment_id = this.selected[0].finance_form_payment_id
        this.selected.filter(financeSelected => {
          financeData.observation += this.$options.filters.money(financeSelected.amount) + ' - Processo #' + financeSelected.business_id + ' (' + financeSelected.first_name + ')\n'
          financeData.business_person_finances.push(financeSelected.id)
        })
        return financeData
      },
      openFinanceOutEditDialog: function (financeData) {
        this.$WiEditDialog({
          wiConfig: 'finance-out',
          data: financeData,
          onSubmit: () => {
            this.refresh()
            this.dialog = false
          }
        })
      }
    },
    props: ['active', 'person', 'refresh']
  }
</script>
<style>
  .justify-center {
    text-align: center;
  }
</style>