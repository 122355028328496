import {
  SYSTEM,
  LANG,
  DEBUG,
  LOG,
  WISE_SYSTEM_API_BASE,
  WISE_SYSTEM_PUSH_BASE_URL,
  WISE_SYSTEM_PUSH_PORT,
  WISE_SYSTEM_NETWORK_PUSH_BASE_URL,
  WISE_SYSTEM_NETWORK_PUSH_PORT,
} from '@/default/constants/environments'


const wise_env = document?.wise_env || {
  system: SYSTEM,
  lang: LANG,
  debug: DEBUG,
  log: LOG
}

const baseUrl = document?.wise_env?.baseUrl || WISE_SYSTEM_API_BASE || window.location.hostname
const ssl = typeof document?.wise_env?.ssl == 'boolean' ? document?.wise_env?.ssl : true

const protocol = ssl ? 'https://' : 'http://'
const port = ssl ? ':2087' : ':2082'

let state = {
  ...wise_env,
  remoteUrl: protocol + baseUrl + port + '/api/',
  baseDefault: protocol + baseUrl + port + '/',
  base: protocol + baseUrl + port + '/api/',
  accountLogo: 'system-logo.png',
  version: 'v1/',
  group: '',
  echo: {
    host: WISE_SYSTEM_PUSH_BASE_URL,
    port: WISE_SYSTEM_PUSH_PORT || 2083
  },
  socket: {
    host: WISE_SYSTEM_NETWORK_PUSH_BASE_URL,
    port: WISE_SYSTEM_NETWORK_PUSH_PORT || 2083
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    SET_SYSTEM (state, data) {
      state.system = data
    }
  }
}
