export default {
  id: '758b9a0e',
  title: 'Membro da Equipe',
  apiUrl: 'register/team/person',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'person_id', text: 'Nome do Membro', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md8', 'lg8'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/person/contact-business', apiParams: {fields: 'id,name'}, show: 'name'},
    {value: 'function', text: 'Função na Equipe', type: 'text', size: ['xs12', 'sm12', 'md4', 'lg4'], required: true, list: false, edit: true}
  ]
}
