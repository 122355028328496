import {Model} from '@/default/model/Model'

var config = {
  name: 'anamnesis',
  title: 'Anamnese'
}

export class MedicalRecord extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'bef1bf0e',
      create: '2df4f2c5',
      update: '871f09f4',
      delete: 'eac7db8f'
    }
    this.name = config.name
    this.title = {
      list: config.title + 's',
      edit: {
        insert: 'Nova ' + config.title,
        update: 'Editar ' + config.title
      }
    }
    this.icon = 'list_alt'
    this.apiUrl = 'clinic/' + config.name
    // this.components.list.tableRow = () => import('@/modules/clinic/pages/Consultations/Consultations')
    this.search = {
      placeholder: 'Pesquise ID, Paciente, Status ou Prontuário',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    // this.urlDetails = { name: config.name, params: {} }
    this.setFields([
      this.fieldSubHeader({
        text: `Informe os dados básicos desta ${config.title}.`
      }),
      this.fieldSelectApi({
        value: 'company_id',
        text: 'Consultório/Clínica',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        disabled: (form) => ( form.id != null ),
        api: {
          url: 'register/person/company',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      }),
      this.fieldText({
        value: 'external_code',
        text: 'Prontuário',
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldSelectApi({
        value: 'business_status_id',
        text: 'Status/Etapa*',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        api: {
          url: 'clinic/config/status/' + config.name,
          params: {
            fields: 'id,name',
            where: 'active,1',
            order: 'order,asc'
          }
        },
        show: 'name'
      }),
      this.fieldTinyEditor({
        value: 'description',
        text: `Conteúdo da ${config.title}.`
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'person.name',
        text: 'Nome do Paciente'
      }),
      this.fieldList({
        value: 'business_status_id',
        text: 'Status/Etapa'
      }),
      this.fieldList({
        value: 'opened_at',
        text: 'Data/hora'
      }),
      this.fieldList({
        value: 'collaborator_id',
        text: 'Responsável'
      }),
      this.fieldList({
        value: 'options',
        text: 'Opções',
        sortable: false
      })
    ])
  }
}

export class Anamnesis extends MedicalRecord {
  constructor () {
    super()
    config.name = 'prescription'
    config.title = 'Receituário'
    this.id = {
      id: 'bef1bf0e',
      create: '2df4f2c5',
      update: '871f09f4',
      delete: 'eac7db8f'
    }
    this.icon = 'list_alt'
  }
}

export class Prescription extends MedicalRecord {
  constructor () {
    super()
    config.name = 'exam-request'
    config.title = 'Solicitação de Exame'
    this.id = {
      id: '1a8ecf85',
      create: 'b7325980',
      update: 'd8317aeb',
      delete: 'a7dc9bdc'
    }
    this.icon = 'medication'
  }
}

export class ExamRequest extends MedicalRecord {
  constructor () {
    super()
    config.name = 'attested'
    config.title = 'Atestado'
    this.id = {
      id: '8542199c',
      create: '5becc327',
      update: 'b2812888',
      delete: 'b43274c9'
    }
    this.icon = 'multiline_chart'
  }
}

export class Attested extends MedicalRecord {
  constructor () {
    super()
    config.name = 'evolution'
    config.title = 'Evolução'
    this.id = {
      id: '087a5d15',
      create: '1b58fb35',
      update: '27201def',
      delete: '37772904'
    }
    this.icon = 'verified_user'
  }
}

export class Evolution extends MedicalRecord {
  constructor () {
    super()
    config.name = 'evolution'
    config.title = 'Evolução'
    this.id = {
      id: '47f85bf9',
      create: '5a7f8ce8',
      update: 'c8aaffa1',
      delete: 'ee0fbf04'
    }
    this.icon = 'drive_folder_upload'
  }
}
