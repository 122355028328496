<template>
  <v-dialog v-model="dialog.active" scrollable persistent :max-width="(88 - (index * 2)) + '%'" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar dense primary>
        <v-toolbar-title>
          <v-icon>{{dialog.icon}}</v-icon>
          <span>{{dialog.title}}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="dialog.button" small outline @click="dialog.button.action()" :disabled="dialog.button.disabled && dialog.button.disabled()">
          <v-icon>{{dialog.button.icon}}</v-icon>
          <span class="hidden-xs-only hidden-sm-only">{{dialog.button.text}}</span>
        </v-btn>
        <v-btn icon flat @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="dialog.active" style="padding: 0 !important;" :style="{'min-height': (400 - (index * 10)) + 'px'}">
        <component :is="dialog.component.content" :props="dialog.component.props"></component>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'WiDialog',
    methods: {
      close: function () {
        this.$store.dispatch('WiDialog/close', this.index)
        this.$forceUpdate()
      }
    },
    computed: {
      dialog () {
        var WiDialog = this.$store.state.WiDialog
        return WiDialog.dialogs[this.index]
      }
    },
    props: {
      'index': {
        default: 0
      }
    }
  }
</script>