import { Model } from '@/default/model/Model'

export class ChatGroup extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '550a585c'
    this.name = 'chat-group'
    this.actions = {
      list: false,
      create: true,
      update: true,
      delete: false
    }
    this.title = {
      list: 'Grupos de chat',
      edit: {
        insert: 'Criar novo grupo',
        update: 'Editar grupo'
      }
    }
    this.apiUrl = 'chats/chat'
    this.icon = 'group'
    this.maxWidth = '500px'

    this.editFields = {
      header1: this.fieldSubHeader({
        text: 'Informe o nome e o tipo do grupo.'
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        placeholder: 'Ex: Grupo de atendimento',
        required: true,
        size: form => form.id ? ['xs12'] : ['xs12', 'sm12', 'md8', 'lg8'],
      }),
      type_id: this.fieldSelect({
        value: 'type_id',
        text: 'Tipo',
        options: [
          { value: 2, text: 'Padrão' }
        ],
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        defaultOption: 2,
        showField: form => !form.id
      }),
      header5: this.fieldSubHeader({
        text: 'Defina a imagem do grupo.'
      }),
      file_id: this.fieldWiFileDialog({
        value: 'file_id',
        text: 'Imagem',
        placeholder: 'Selecione a imagem',
        required: false,
        data: {
          is_public: false
        },
        filter: {
          is_public: false
        }
      }),
      header7: this.fieldSubHeader({
        text: 'Selecione os membros do grupo.',
        showField: form => !form.id
      }),
      members: this.fieldSelectApi({
        value: 'members',
        text: 'Membros',
        required: true,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        api: {
          url: 'register/person/collaborator',
          params: {
            where: 'collaborator,1',
          }
        },
        show: 'name',
        multiple: true,
        showField: form => !form.id
      }),
      header3: this.fieldSubHeader({
        text: 'Defina a descrição do grupo.',
        showField: form => !form.id
      }),
      description: this.fieldTextArea({
        value: 'description',
        text: 'Descrição',
        placeholder: 'Ex: Grupo de atendimento ao cliente',
        required: false,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
      })
    }
    this.setFields(this.editFields)
  }
}
