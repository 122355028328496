import {Model} from '@/default/model/Model'

export class Products extends Model {
  constructor () {
    super()
  }

  title = {
    edit: {
      create: 'Incluir Produto',
      update: 'Editar Produto'
    }
  }
  icon = 'shopping_cart'

  // Fields
  subHeader = this.fieldSubHeader({
    text: 'Selecione o Produto, informe a quantidade e valores.'
  })
  stock = this.fieldWiListDialog({
    value: 'stock_id',
    text: 'Estoque',
    required: true,
    size: form => (form.stock_id ? ['xs12', 'sm12', 'md5', 'lg5'] : ['xs12', 'sm12', 'md12', 'lg12']),
    wiConfig: 'stock',
    show: 'stock.name',
    icon: 'my_location',
    disabled: (form) => {
      return form.id != null
    }
  })
  product = this.fieldWiListDialog({
    value: 'item_id',
    text: 'Produto',
    required: true,
    size: ['xs12', 'sm12', 'md8', 'lg8'],
    wiConfig: 'product',
    show: 'item.name',
    icon: 'inventory_2',
    disabled: (form) => {
      return form.id != null && form.stage != 1
    },
    callbackFields: [
      {
        get: 'amount_sale',
        set: 'amount_unit'
      }
    ]
  })
  productStock = this.fieldWiListDialog({
    value: 'item_id',
    text: 'Produto',
    required: true,
    size: ['xs12', 'sm12', 'md8', 'lg8'],
    wiConfig: 'stock-product',
    show: 'item.name',
    icon: 'inventory_2',
    filter: form => ({
      where: 'stock_id,' + form.business.stock_id
    }),
    disabled: (form) => {
      return form.id != null && form.stage != 1
    },
    callbackFields: [
      {
        get: 'amount_sale',
        set: 'amount_unit'
      },
      {
        get: 'item_id',
        set: 'item_id'
      },
      {
        get: 'quantity',
        set: 'quantityMax'
      },
      {
        get: 'item',
        set: 'item'
      }
    ]
  })
  description = this.fieldText({
    value: 'description',
    text: 'Descrição do Produto',
    size: ['xs12', 'sm12', 'md8', 'lg8']
  })
  quantity = this.fieldNumber({
    value: 'quantity',
    text: 'Quantidade',
    size: ['xs12', 'sm6', 'md2', 'lg2'],
    required: true,
    min: 1,
    max: form => (form.quantityMax || null),
    disabled: form => !form.item_id,
    rules: () => ([
      v => !!v || 'Campo obrigatório',
      v => parseInt(v) > 0 || 'Quantidade inválida'
    ])
  })
  amountUnit = this.fieldMoney({
    value: 'amount_unit',
    text: 'Valor Unitário',
    size: ['xs12', 'sm6', 'md2', 'lg2'],
    required: true,
    disabled: true
  })
  subHeaderObservation = this.fieldSubHeader({
    text: 'Use este campo para registrar alguma observação interna.'
  })
  observation = this.fieldTextArea({
    value: 'observation',
    text: 'Observações do Produto'
  })

  // Fields fixed
  fieldsFixed = [
    {
      value: 'amount_total',
      data: 0
    },
    {
      value: 'amount_discount',
      data: 0
    }
  ]

}
