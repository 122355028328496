import { PERMISSION_WELLS_REGISTRATION } from '@/default/constants/permissions'

export default {
  id: PERMISSION_WELLS_REGISTRATION,
  icon: 'description',
  title: 'Cadastro',
  children: [
    {
      id: PERMISSION_WELLS_REGISTRATION,
      icon: 'assignment_ind',
      title: 'Colaboradores',
      route: {
        name: 'wells-list',
        params: {
          page: 'wells-register-collaborator'
        }
      },
      selected: false,
      permission: ['admin']
    },
    {
      id: PERMISSION_WELLS_REGISTRATION,
      icon: 'people_outline',
      title: 'Clientes',
      route: {
        name: 'wells-list',
        params: {
          page: 'wells-register-customer'
        }
      },
      selected: false
    }
  ]
}
