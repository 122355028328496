<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-wlans__enable-button elevation-2">
        <v-card-text style="text-align: center;">
          <h1>{{wlans.length}}</h1>
          <strong>Wlan(s)</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-wlans">
      <v-toolbar dense class="diagnostic-wlans__toolbar">
        <v-toolbar-title>
          Wlan(s)
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="wlans.length > 0">
        <h4>
          <span>{{wlans.length}} </span>
          <span> wlan(s) encontrada(s). Clique sobre para mais detalhes.</span>
        </h4>
        <br>
        <v-layout row wrap>
          <v-flex xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 10px;" v-for="(wlan, wlanKey) in wlans" :key="wlanKey">
            <v-card class="elevation-10">
              <v-dialog v-model="dialogWlan" scrollable hide-overlay max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly">
                <template v-slot:activator="{ on }">
                  <v-card-text v-on="on" class="diagnostic-wlans__wlans" @click="selected = wlan">
                    <img v-if="wlan.enable" src="/static/icons/monitor/wi_fi_connected_96.png" width="50px">
                    <img v-else src="/static/icons/monitor/wi_fi_off_96.png" width="50px">
                    <h4>wlan{{wlan.wifiIndex}}</h4>
                    <h4>SSID: {{wlan.SSID}}</h4>
                    <h5 v-if="wlan.enable" class="diagnostic-wlans__active--enabled">Ativo</h5>
                    <h5 v-else class="diagnostic-wlans__active--disabled">Desativado</h5>
                    <h5>Canal do rádio: {{wlan.wifiChannel}}</h5>
                    <h5>Modo de encriptação: {{wlan.mode}}</h5>
                    <h5>Frequência: {{wlan.frequencyBand}}</h5>
                    <h5>Exposição do SSID: {{wlan.broadcastSSID ? 'Ligado' : 'Desligado'}}</h5>
                  </v-card-text>
                </template>
                <v-card class="diagnostic-wlans">
                  <v-toolbar dense class="diagnostic-wlans__toolbar">
                    <v-toolbar-title>
                      Detalhes da wlan{{selected.wifiIndex}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogWlan = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="text-center">
                    <p v-for="(selectedItem, selectedItemKey) in selected" :key="selectedItemKey" class="text-center">
                      <strong>{{selectedItemKey}}: </strong>
                      <span> {{selectedItem}}</span>
                      <v-divider></v-divider>
                    </p>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-divider></v-divider>
              <v-card-actions v-if="actionsByWlan(wlan).length > 0">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }"> 
                    <v-btn v-on="on" :disabled="status.name != 'closed'" block outline color="primary">
                      <v-icon>more_vert</v-icon>
                      <span>Opções</span>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(action, actionKey) in actionsByWlan(wlan)">
                      <v-divider v-if="actionKey" :key="`${actionKey}-divider`"></v-divider>
                      <v-list-tile :key="`${actionKey}-tile`" @click="executeAction(action)">
                        <v-list-tile-action>
                          <v-icon large v-text="action.icon"></v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title v-text="action.title"></v-list-tile-title>
                          <v-list-tile-sub-title v-text="action.description"></v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-wlans__not-found">
        <h2>Nenhuma wlan encontrada.☹️ </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import ActionsMixin from './ActionsMixin'
  export default {
    name: 'DiagnosticWlans',
    data: () => ({
      dialog: false,
      dialogWlan: false,
      selected: {},
      actionIds: [
        '5b73f81c-8109-4eef-9508-b05892233432',
        'a47b552b-da1d-4c24-b902-00a0915107f1'
      ]
    }),
    methods: {
      actionsByWlan: function (wlan) {
        return this.selectedActions.filter(action => (parseInt(action.content.wifiIndex) == parseInt(wlan.wifiIndex)))
      }
    },
    mixins: [ActionsMixin],
    props: {
      wlans: {
        required: true,
        default: () => ([])
      },
      actions: {
        required: true,
        default: () => ([])
      },
      status: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .diagnostic-wlans {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-wlans__enable-button {
    cursor: pointer;
  }
  .diagnostic-wlans__toolbar {
    z-index: 999;
  }

  .diagnostic-wlans__wlans {
    text-align: center;
    cursor: pointer;
  }
  .diagnostic-wlans__active--enabled {
    color: green;
  }
  .diagnostic-wlans__active--disabled {
    color: red;
  }
  .diagnostic-wlans__not-found {
    margin: 50px 0;
  }
</style>
