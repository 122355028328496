import Transport from '@/modules/register/config/Person/Person?url=Transport'

var transport = Transport({name: 'Transportadora', field: 'transport'})

transport.title = {
  list: 'Transportadoras',
  edit: {
    insert: 'Nova Transportadora',
    update: 'Editar Transportadora'
  }
}
transport.icon = 'local_shipping'
transport.apiUrl = 'register/person/transport'
transport.urlDetails.params.person = 'transport'

export default transport

// export default {
//   id: 'pssa',
//   list: true,
//   title: 'Transportadoras',
//   icon: 'local_shipping',
//   apiUrl: 'register/person/transport',
//   fields: [
//     // Itens que serão listados
//     {value: 'id', text: 'ID', list: true, edit: false, search: true},
//     {value: 'name', text: 'Nome/Nome Fantasia', list: true, edit: false, search: true},
//     {value: 'first_name', text: 'Nome Completo/Razão Social', list: true, edit: false},
//     {value: 'cpf_cnpj', text: 'CPF/CNPJ', list: true, edit: false, search: true},
//     {text: 'Informações básicas desta Transportadora.', type: 'subheader', edit: true, showField: (form) => { return form.type === 'Fisica' || form.type === 'Juridica' }},
//     {text: 'Primeiro, selecione se este Transportadora é pessoa Física ou Jurídica', type: 'subheader', edit: true, showField: (form) => { return form.type !== 'Fisica' && form.type !== 'Juridica' }},
//     {value: 'type', text: 'Pessoa Física ou Jurídica', type: 'select', size: ['xs12', 'sm12', 'md12', 'lg12'], options: [{value: 'Fisica', text: 'Pessoa Física'}, {value: 'Juridica', text: 'Pessoa Jurídica'}], required: true, list: false, edit: true, showField: (form) => { return form.type !== 'Fisica' && form.type !== 'Juridica' }},
//     {value: 'type', text: 'Pessoa Física ou Jurídica', type: 'select', size: ['xs12', 'sm2', 'md2', 'lg2'], options: [{value: 'Fisica', text: 'Pessoa Física'}, {value: 'Juridica', text: 'Pessoa Jurídica'}], required: true, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' || form.type === 'Juridica' }},
//     // Pessoa Física
//     {value: 'name', text: 'Nome como será chamado', placeholder: 'Ex: Pedro Henrique', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: true, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'first_name', text: 'Nome Completo', placeholder: 'Ex: Pedro Henrique da Silva', type: 'text', size: ['xs12', 'sm6', 'md6', 'lg6'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'cpf_cnpj', text: 'CPF', placeholder: '###.###.###-##', mask: '###.###.###-##', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'rg', text: 'RG', placeholder: 'Insira o RG', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'birthday', text: 'Data de Nascimento', type: 'date', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'sex', text: 'Sexo', type: 'select', size: ['xs12', 'sm3', 'md3', 'lg3'], options: [{value: 'Feminino', text: 'Feminino'}, {value: 'Masculino', text: 'Masculino'}, {value: 'Outro', text: 'Outro'}], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'email', text: 'E-mail', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'password', text: 'Senha de Acesso', type: 'password', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     {value: 'password_change', text: 'Alterar Senha', type: 'select', size: ['xs12', 'sm2', 'md2', 'lg2'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' }},
//     // Pessoa Jurídica
//     {value: 'name', text: 'Nome fantasia', placeholder: 'Ex: Casas Bahia', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: true, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'first_name', text: 'Razão Social', placeholder: 'Ex: Adição Atacadista Casas Bahia ME', type: 'text', size: ['xs12', 'sm6', 'md6', 'lg6'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'cpf_cnpj', text: 'CNPJ', placeholder: '##.###.###/####-##', mask: '##.###.###/####-##', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'state_free', text: 'Isento', type: 'select', size: ['xs12', 'sm2', 'md2', 'lg2'], options: [{value: 'yes', text: 'Sim'}, {value: 'no', text: 'Não'}], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'state_number', text: 'Inscrição Estadual', placeholder: 'Insira a Inscrição Estadual', type: 'text', size: ['xs12', 'sm5', 'md5', 'lg5'], required: false, list: false, edit: true, disabled: (form) => { return form.state_free === 'sim' }, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'state_id', text: 'UF Ins. Estadual', type: 'db-autocomplete', size: ['xs12', 'sm2', 'md2', 'lg2'], required: false, list: false, edit: true, apiUrl: 'register/state', apiParams: {fields: 'id,code'}, show: 'code', disabled: (form) => { return form.state_free === 'sim' }, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'city_number', text: 'Inscrição Municipal', placeholder: 'Insira a Inscrição Municipal', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'birthday', text: 'Aniversário da Transportadora', type: 'date', size: ['xs12', 'sm2', 'md2', 'lg2'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     {value: 'site', text: 'Site Oficial', placeholder: 'Insira o site oficial da Transportadora', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Juridica' }},
//     // {value: 'pessoa_seguimento_id', text: 'Seguimento/Ramo de Atuação', size: ['xs12', 'sm4', 'md4', 'lg4'], required: false, type: 'db-select', list: false, edit: true, apiUrl: 'pessoa/seguimento', apiParams: {limit: 2000, order: 'descricao,asc'}, show: 'descricao', showField: (form) => { return form.type === 'Juridica' }},
//     // Comum
//     {value: 'transport', text: 'Status da Transportadora', type: 'select', size: ['xs12', 'sm3', 'md3', 'lg3'], options: [{value: 'ativo', text: 'Está Ativo'}, {value: 'inativo', text: 'Está Inativo'}], required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' || form.type === 'Juridica' }},
//     {text: 'Observações desta Transportadora', type: 'subheader', edit: true, size: ['xs12'], showField: (form) => { return form.type === 'Fisica' || form.type === 'Juridica' }},
//     {value: 'observation', text: 'Observações', type: 'textarea', required: false, list: false, edit: true, showField: (form) => { return form.type === 'Fisica' || form.type === 'Juridica' }},
//     // eslint-disable-next-line
//     {value: 'teste', text: 'Detalhes', type: 'link', align: 'center', list: true, edit: false, links: [
//       { icon: 'info', action: (route, dados) => { route.push({ name: 'p', params: {person: 'transport', id: dados.id} }) }, show: (d) => { return true } }
//     ]}
//   ],
//   loading_edit: false,
//   loading_list: false,
//   loading_view: false
// }
