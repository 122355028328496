<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-services__enable-button">
        <v-card-text style="text-align: center;">
          <h1>{{services.length}}</h1>
          <strong>Serviço(s)</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-services">
      <v-toolbar dense class="diagnostic-services__toolbar">
        <v-toolbar-title>
          Serviço(s)
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="services.length > 0">
        <h4>
          <span>{{services.length}} </span>
          <span> Serviço(s) encontrado(s).</span>
        </h4>
        <br>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg6 xl6 offset-lg3 offset-xl3 style="padding-right: 10px;" v-for="(service, serviceKey) in services" :key="serviceKey">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-services__services">
                <img src="/static/icons/monitor/problema_expirado.png" width="50px">
                <h4>Perfil: {{service.perfil || 'Não informado'}}</h4>
                <h4>Descrição: {{service.description || 'Não informado'}}</h4>
                <h4>Tipo: {{service.type || 'Não informado'}}</h4>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-services__not-found">
        <h2>Nenhum Serviço encontrado.☹️ </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DiagnosticServices',
    data: () => ({
      dialog: false,
    }),
    props: {
      services: {
        required: true,
        default: () => ([])
      }
    }
  }
</script>
<style scoped>
  .diagnostic-services {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-services__enable-button {
    cursor: pointer;
  }
  .diagnostic-services__toolbar {
    z-index: 999;
  }

  .diagnostic-services__services {
    text-align: center;
  }
  .diagnostic-services__not-found {
    margin: 50px 0;
  }
</style>
