<template>
  <v-toolbar dense class="toolbar">
    <v-toolbar-title>
      <span>{{ device.nome_disp }} - </span>
      <label v-if="child.parent">
        <span> {{ child.parent.type.name }} </span>
        <span> {{ child.parent.name }} </span>/
      </label>
      <label>
        <span> {{ child.type.name }} </span>
        <span> {{ child.name }} </span>
      </label>
      <label v-if="hostsCount">
        <span> - </span>
        <span> ({{ hostsCount }}) </span>
        <span> Equipamentos/Clientes</span>
      </label>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="showHostsType == 'map'" small outline @click="touggleView('list')">
      <v-icon>list</v-icon>
      <span> Exibir lista</span>
    </v-btn>
    <v-btn v-if="showHostsType == 'list'" small outline @click="touggleView('map')">
      <v-icon>map</v-icon>
      <span> Exibir mapa</span>
    </v-btn>
    <v-menu bottom left>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" >
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile @click="checkSignal()">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="green">online_prediction</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Atualizar Sinal</v-list-tile-title>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile v-if="$WiCheckPermission(importImanagerPermission)" @click="searchContracts()">
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon color="blue">description</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Buscar Contratos</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-btn icon @click="refresh()">
      <v-icon>refresh</v-icon>
    </v-btn>
    <v-btn icon @click="close()">
      <v-icon>close</v-icon>
    </v-btn>
  </v-toolbar>
</template>
<script>
  import { PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER } from '@/default/constants/permissions'
  export default {
    name: 'DeviceHostsDialogToolbar',
    data: () => ({
      showHostsType: 'list'
    }),
    computed: {
      importImanagerPermission () {
        return PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER
      }
    },
    methods: {
      close: function () {
        this.$emit('close')
      },
      refresh: function () {
        this.$emit('refresh')
      },
      touggleView: function (view) {
        this.showHostsType = view
        this.$emit('touggleView', view)
      },
      checkSignal: function () {
        this.$emit('checkSignal')
      },
      searchContracts: function () {
        this.$emit('searchContracts')
      }
    },
    props: {
      device: {
        required: true
      },
      child: {
        default: () => ({})
      },
      hostsCount: {
        default: 0
      }
    }
  }
</script>
