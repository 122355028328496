<template>
  <wi-report v-if="config" :title="config.title" :config="config" :api="{url: config.api.url, params: config.api.params}" :xlsExportFormat="xlsExportFormat">
    <template slot="statistics" slot-scope="props">
      <v-layout row wrap>
        <v-flex v-for="(statistic, statisticKey) in config.statistics" :key="statisticKey" xs12 sm12 md3 lg3 style="padding: 0px 5px;">
          <v-card :color="statistic.color">
            <v-card-text>
              <strong style="color: white;">{{statistic.title}}</strong>
              <strong style="color: white;" :style="{'font-size': statistic.size || '16px'}">{{props.statistics[statistic.field]}}</strong>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template slot-scope="props">
      <tr>
        <td style="text-align: center;">
          <strong>{{ props.item.name }}</strong>
          <br>
          <span>{{ props.item.first_name }}</span>
        </td>
        <td style="text-align: center;">
          <span v-if="props.item.phones && props.item.phones.length > 0">
            <span v-for="(phone, phoneIndex) in props.item.phones" :key="phoneIndex">
              <span v-if="phoneIndex < 2">
                <br v-if="phoneIndex"/>
                <span>{{ phone.contact | phone }}</span>
              </span>
            </span>
          </span>
          <span v-else>Nenhum telefone cadastrado</span>
        </td>
        <td style="text-align: center;">
          <span v-if="props.item && props.item.entry_date">
            {{ props.item.entry_date | moment('DD/MM/y') }}
          </span>
          <span v-else>Indefinido</span>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.count" style="font-size: 18px;">{{ props.item.count }}</strong>
          <strong v-else>0</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.sum_amount">{{ props.item.sum_amount | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.date_last">{{ props.item.date_last | moment('DD/MM/y') }}</strong>
          <strong v-else>Nenhuma no período</strong>
        </td>
        <td style="text-align: center;">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                Opções
              </v-btn>
            </template>
            <v-list>
              <v-list-tile v-if="props.item.count_opened > 0" @click="$WiListDialog({wiConfig: business.name + '-open', filter: { [person.id]: props.item.id }})">
                <v-list-tile-title>{{business.titles}} em Aberto ({{props.item.count_opened}})</v-list-tile-title>
              </v-list-tile>
              <v-list-tile v-if="props.item.count_closed > 0" @click="$WiListDialog({wiConfig: business.name + '-close', filter: { [person.id]: props.item.id }})">
                <v-list-tile-title>Histórico de {{business.titles}} ({{props.item.count_closed}})</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="$router.push({name: person.name, params: {id: props.item.id}})">
                <v-list-tile-title>Perfil do {{person.title}}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>    
        </td>
      </tr>
    </template>
  </wi-report>
</template>
<script>
  import WiReport from '@/default/component/WiReport/WiReport'
  export default {
    name: 'BusinessReport',
    data () {
      return {
        config: null,
        makeConfigCount: 0
      }
    },
    computed: {
      person: function () {
        var person = {}
        switch (this.$route.meta.person) {
          case 'customer':
            person = {
              id: 'person_id',
              name: 'customer',
              title: 'Cliente',
              first_name: 'Razão Social'
            }
            break
          case 'provider':
            person = {
              id: 'person_id',
              name: 'provider',
              title: 'Fornecedor',
              first_name: 'Razão Social'
            }
            break
          case 'collaborator':
            person = {
              id: 'collaborator_id',
              name: 'collaborator',
              title: 'Colaborador',
              first_name: 'Nome Completo'
            }
            break
        }
        return person
      },
      business: function () {
        var business = {}
        switch (this.$route.meta.business) {
          case 'budget':
            business = {
              module: 'sales',
              name: 'budget',
              names: 'budgets',
              title: 'Orçamento',
              titles: 'Orçamentos'
            }
            break
          case 'quotation':
            business = {
              module: 'purchases',
              name: 'quotation',
              names: 'quotations',
              title: 'Cotação',
              titles: 'Cotações'
            }
            break
          case 'purchase':
            business = {
              module: 'purchase',
              name: 'purchase',
              names: 'purchases',
              title: 'Compra',
              titles: 'Compras'
            }
            break
          case 'sale':
            business = {
              module: 'sales',
              name: 'sale',
              names: 'sales',
              title: 'Venda',
              titles: 'Vendas'
            }
            break
          case 'order':
            business = {
              module: 'service-desk',
              name: 'order',
              names: 'orders',
              title: 'Ordem',
              titles: 'Ordens'
            }
            break
          case 'process':
            business = {
              module: 'juridical',
              name: 'process',
              names: 'processs',
              title: 'Processo',
              titles: 'Processos'
            }
            break
        }
        return business
      }
    },
    methods: {
      makeConfig: function () {
        this.config = null
        var config = {
          title: this.business.titles + ' por ' + this.person.title,
          api: {
            url: this.business.module + '/report/' + this.business.names + '-by-' + this.person.name,
            params: {}
          },
          statistics: [
            {field: 'count_total', title: 'Total de ' + this.business.titles + ': ', color: 'primary', size: '16px'},
            {field: 'count_opened_total', title: 'Total em aberto: ', color: 'orange', size: '16px'},
            {field: 'count_closed_total', title: 'Total concluídos: ', color: 'green', size: '16px'},
            {field: 'sum_amount_total', title: 'Valor total: ', color: 'blue', size: '16px'}
          ],
          dates: {
            field: {
              text: 'Data de filtro',
              items: [
                {value: 'date_start', text: 'Data de Lançamento'},
                {value: 'date_end', text: 'Data de Entrega'},
                {value: 'opened_at', text: 'Data de Criação'},
                {value: 'closed_at', text: 'Data de Conclusão'}
              ]
            },
            start: {
              type: 'date',
              text: 'Data Inicial'
            },
            end: {
              type: 'date',
              text: 'Data Final'
            }
          },
          filters: {
            text: 'Filtros',
            fields: []
          },
          fields: [
            { text: `${this.person.title}/${this.person.first_name}`, value: 'name', align: 'center', sortable: false },
            { text: 'Telefone(s) Cadastrado(s)', value: 'phones', align: 'center', sortable: false },
            { text: 'Cadastro no sistema', value: 'entry_date', align: 'center' },
            { text: 'N° de ' + this.business.titles, value: 'count', align: 'center' },
            { text: 'Valor Total', value: 'sum_amount', align: 'center' },
            { text: 'Última ' + this.business.title, value: 'date_last', align: 'center' },
            { sortable: false }
          ]
        }
        if (this.makeConfigCount) {
          setTimeout(() => {
            this.config = config
          }, 500)
        } else {
          this.config = config
        }
        this.makeConfigCount++
      },
      xlsExportFormat: function (data) {
        return {
          ...data,
          sum_amount: `${data.sum_amount}`.replace('.', ','),
          phones: data.phones.map(
            item => this.$options.filters.phone(item.contact)
          ).join(', ')
        }
      }
    },
    mounted: function () {
      this.makeConfig()
    },
    watch: {
      '$route': 'makeConfig'
    },
    components: {
      WiReport
    }
  }
</script>
