<template>
  <div v-if="message" class="talk-message__message" :class="{'talk-message__message--from-me': message.from_me}">
    <div class="talk-message__message-avatar" :style="{float: message.from_me ? 'right' : 'left'}">
      <v-avatar v-if="message.from_me">
        <img v-if="message.person && message.person.file && message.person.file.url" :src="message.person.file.url">
        <img v-else src="static/icons/user_icon_1.png" alt="">
      </v-avatar>
      <v-avatar v-else>
        <img v-if="message.contact && message.contact.file && message.contact.file.url" :src="message.contact.file.url">
        <img v-else src="static/icons/user_icon_1.png" alt="">
      </v-avatar>
    </div>
    <div class="talk-message__message-balloon" :class="{'talk-message__message-balloon--from-me': message.from_me}">
      <div class="talk-message__message-balloon-contact" :class="{'talk-message__message-balloon-contact--from-me': message.from_me}">
        <label v-if="message.from_me">
          <label v-if="message.person && message.person.name" v-text="message.person.name"></label>
        </label>
        <label v-else>
          <label v-if="message.contact && message.contact.name" v-text="message.contact.name"></label>
        </label>
        <label>
          <v-menu top transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on" style="cursor: pointer;">expand_more</v-icon>
            </template>
            <v-list>
              <v-list-tile @click="shareMessage(message)">
                <v-list-tile-title>Encaminhar Mensagem</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="replyMessage(message)">
                <v-list-tile-title>Responder</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </label>
      </div>
      <div v-if="message.shared" class="talk-message__message-balloon-shared" :class="{'talk-message__message-balloon-contact--from-me': message.from_me}">
        <v-icon small color="gray">reply</v-icon>
        <label>Encaminhada...</label>
      </div>
      <div class="talk-message__message-balloon-text">
        <div v-if="message.message" class="talk-message__message-balloon-text-reply" @click="showReplyMessage(message.message)">
          <div class="talk-message__message-balloon-contact" :class="{'talk-message__message-balloon-contact--from-me': !message.from_me}">
            <div v-if="message.message.from_me">
              <label v-if="message.message.person && message.message.person.name" v-text="message.message.person.name"></label>
            </div>
            <div v-else>
              <label v-if="message.message.contact && message.message.contact.name" v-text="message.message.contact.name"></label>
            </div>
          </div>
          <span>{{message.message.text_html | stringlength(180)}}</span>
          <div class="talk-message__message-balloon-info">
            <label v-if="message.message.from_me" v-text="message.message.created_at_formated"></label>
            <label v-else v-text="message.message.external_time_formated"></label>
            <label v-if="message.message.from_me">
              <v-icon v-if="parseInt(message.message.ack) == -1" small color="red">close</v-icon>
              <v-icon v-if="parseInt(message.message.ack) == 0" small>query_builder</v-icon>
              <v-icon v-if="parseInt(message.message.ack) == 1" small>done</v-icon>
              <v-icon v-if="parseInt(message.message.ack) == 2" small>done_all</v-icon>
              <v-icon v-if="parseInt(message.message.ack) == 3" small color="blue">done_all</v-icon>
            </label>
          </div>
        </div>
        <label>
          <span v-if="parseInt(message.type) == 1">
            <div v-if="checkIfTextIsYouTubeVideoUrl(message.text)">
              <iframe :src="message.text" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br>
              <span v-text="message.text"></span>
            </div>
            <div v-else-if="checkIfTextIsUrl(message.text)">
              <a target="_blank" :href="message.text">
                <span v-text="message.text"></span>
              </a>
            </div>
            <span v-else v-html="message.text_html"></span>
          </span>
          <span v-if="parseInt(message.type) == 2">
            <div v-if="message.file && message.file.url">
              <div>
                <img @click="openFile(message.file)" :src="message.file.url" alt="" style="max-width: 250px; cursor: pointer;">
              </div>
              <div>
                <span v-text="message.caption"></span>
              </div>
            </div>
          </span>
          <span v-if="parseInt(message.type) == 3">
            <div v-if="message.file && message.file.url">
              <div>
                <video controls="controls" controlsList="nodownload" style="max-height: 300px; width: 100%;">
                  <source :src="message.file.url" type="video/mp4" />
                  <!--Suportado em IE9, Chrome 6 e Safari 5 --> 
                  O seu navegador não suporta a tag vídeo
                </video>
              </div>
              <div>
                <span v-text="message.caption"></span>
              </div>
            </div>
          </span>
          <span v-if="parseInt(message.type) == 5">
            <div v-if="message.file && message.file.url">
              <div>
                <img @click="openFile(message.file)" src="/static/icons/file_types/pdf_96.png" alt="" style="max-width: 80px; cursor: pointer;">
              </div>
              <div>
                <span v-text="message.caption"></span>
              </div>
            </div>
          </span>
          <span v-if="parseInt(message.type) == 4 || parseInt(message.type) == 8">
            <div v-if="message.file && message.file.url">
              <div>
                <audio controls>
                  <source :src="message.file.url" type="audio/ogg">
                Your browser does not support the audio element.
                </audio>
              </div>
              <div>
                <span v-text="message.caption"></span>
              </div>
            </div>
          </span>
          <span v-if="parseInt(message.type) == 6">
            <div v-if="message.vcard && message.vcard[0]">
              <div>
                <span v-text="message.vcard[0].vcard"></span>
              </div>
              <div>
                <span v-text="message.caption"></span>
              </div>
            </div>
          </span>
          <span v-if="parseInt(message.type) == 9">
            <div v-if="message.file && message.file.url">
              <div>
                <img @click="openFile(message.file)" :src="message.file.url" alt="" style="max-width: 80px; cursor: pointer;">
              </div>
              <div>
                <span v-text="message.caption"></span>
              </div>
            </div>
          </span>
        </label>
      </div>
      <div class="talk-message__message-balloon-info">
        <label v-if="message.from_me" v-text="message.created_at_formated"></label>
        <label v-else v-text="message.external_time_formated"></label>
        <label v-if="message.from_me">
          <v-icon v-if="parseInt(message.ack) == -1" small color="red">close</v-icon>
          <v-icon v-if="parseInt(message.ack) == 0" small>query_builder</v-icon>
          <v-icon v-if="parseInt(message.ack) == 1" small>done</v-icon>
          <v-icon v-if="parseInt(message.ack) == 2" small>done_all</v-icon>
          <v-icon v-if="parseInt(message.ack) == 3" small color="blue">done_all</v-icon>
        </label>
      </div>
    </div>
  </div>       
</template>
<script>
  export default {
    name: 'TalkMessage',
    data () {
      return {}
    },
    methods: {
      openFile: function (file) {
        window.open(file.url)
      },
      checkIfTextIsYouTubeVideoUrl: function (url) {
        var isYouTubeUrl = false
        if (url !== undefined || url !== '') {
          // eslint-disable-next-line
          url = url + ''
          // eslint-disable-next-line
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
          var match = url.match(regExp)
          if (match && match[2].length === 11) {
            isYouTubeUrl = true
          }
        }
        return isYouTubeUrl
      },
      checkIfTextIsUrl: function (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$', 'i')
        return !!pattern.test(str)
      },
      replyMessage: function (message) {
        this.$emit('reply', message)
      },
      showReplyMessage: function (message) {
        this.$emit('showReply', message)
      },
      shareMessage: function (message) {
        this.$emit('share', message)
      }
    },
    props: ['message']
  }
</script>

<style scoped>
  .talk-message__message {
    width: 100%;
    margin: 30px 5px;
  }
  .talk-message__message--from-me {
    text-align: left;
  }
  .talk-message__message-balloon {
    margin: 0px 60px;
    min-height: 50px;
    background: #FFF;
    border-radius: 0px 15px 15px 15px;;
  }
  .talk-message__message-balloon--from-me {
    background: #D4FCC8 !important;
    border-radius: 15px 0px 15px 15px;
  }
  .talk-message__message-balloon-text {
    padding: 5px 10px;
    color: #000;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    word-wrap: break-word;
  }
  .talk-message__message-balloon-info {
    width: 100% !important;
    text-align: right;
    font-size: 12px;
    padding: 0px 10px;
    color: gray;
  }
  .talk-message__message-avatar img {
    /* max-width: 25% !important; */
    background: #FFF;
  }
  .talk-message__message-balloon-contact {
    width: 100% !important;
    font-weight: bold;
    padding: 3px 10px;
    font-size: 12px;
  }
  .talk-message__message-balloon-contact--from-me {
    text-align: right;
  }
  .talk-message__message-balloon-shared {
    width: 100% !important;
    padding: 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .talk-message__message-balloon-text-reply {
    width: 100%;
    background: #c9c9c977;
    border-radius: 5px;
    padding: 0px 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }
</style>
