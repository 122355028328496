<template>
	<v-layout row style="padding: 8px 0 8px 0;">
		<v-flex xs12 sm12 style="padding-right: 8px; padding-left: 8px;">
			<v-card flat>
				<v-list two-line subheader dense>
					<v-subheader>
						<strong>Usuário do Sistema <span v-if="person.users" style="padding-left: 5px;">({{person.users.length}})</span></strong>
            <v-spacer></v-spacer>
						<v-btn 
              v-if="person.users && person.users.length == 0"
              v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" 
              small 
              outline 
              color="green" 
              @click="$WiEditDialog({wiConfig: 'user', onSubmit: refresh, data: {person_id: $route.params.id, notShowCollaboratorField: true}})"
            >
							<v-icon>add</v-icon>
              <span>Usuário</span>
						</v-btn>
					</v-subheader>
          <div v-if="person.users">
            <div v-for="(usuario, keyUsuario) in person.users" :key="keyUsuario + '-user'" >
              <v-divider inset></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-icon class="red white--text" large>portrait</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong> {{ usuario.login }}</strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    <span>{{usuario.profile.name}}</span>        
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action 
                  v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"
                >
                  <v-btn icon ripple @click="$WiEditDialog({wiConfig: 'user-password', onSubmit: refresh, data: usuario})">
                    <v-icon color="grey lighten-1">vpn_key</v-icon>
                  </v-btn>
                </v-list-tile-action>
                <v-list-tile-action 
                  v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"
                >
                  <v-btn icon ripple @click="$WiEditDialog({wiConfig: 'user', onSubmit: refresh, data: usuario})">
                    <v-icon color="grey lighten-1">edit</v-icon>
                  </v-btn>
                  <db-delete 
                    v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"
                    v-on:action="refresh" 
                    store="user" 
                    :id="usuario.id"
                  ></db-delete>
                </v-list-tile-action>
              </v-list-tile>
            </div>
          </div>
					<v-divider ></v-divider>
				</v-list>
			</v-card>
		</v-flex>
  </v-layout>
</template>
<script>
  export default {
    name: 'PersonUsers',
    data () {
      return {}
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      }
    },
    props: {
      person: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    }
  }
</script>