<template>
  <div>
    <v-btn @click="testErrorExecute()">Testar</v-btn>
  </div>
</template>
<script>
  import {Error} from '@/default/service/Error'
  export default {
    name: 'ErrorTest',
    methods: {
      testErrorExecute: function () {
        let login = {}
        Error.make({
          error: {
            message: 'Hello World!',
            type: 'error-test'
          },
          id: 2001,
          title: 'Error Test',
          message: 'Executing Error Test!',
          login
        })
      }
    },
    mounted: function () {
      console.log(process.env)
    }
  }
</script>