import {
  PERMISSION_INTERNET_CONFIGURATION,
  PERMISSION_FORM
} from '@/default/constants/permissions'

export default {
  id: PERMISSION_INTERNET_CONFIGURATION,
  icon: 'settings',
  title: 'Configurações',
  children: [
    {
      id: PERMISSION_INTERNET_CONFIGURATION,
      icon: 'code',
      title: 'Status/Etapas de Contratos',
      route: {
        name: 'internet-list',
        params: {
          page: 'config-status-contract'
        }
      }
    },
    {
      id: PERMISSION_INTERNET_CONFIGURATION,
      icon: 'category',
      title: 'Categorias de Contrato',
      route: {
        name: 'internet-list',
        params: {
          page: 'config-category-contract'
        }
      }
    },
    {
      id: PERMISSION_FORM,
      icon: 'list_alt',
      title: 'Complemento de Contratos',
      route: {
        name: 'internet-list',
        params: {
          page: 'well-config-form'
        }
      }
    }
  ]
}
