<template>
  <div>
    <!-- eslint-disable -->
    <v-toolbar flat dense tabs>
      <v-btn icon ripple @click="$router.go(-1)">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-breadcrumbs :items="paginas" >
        <v-icon slot="divider">forward</v-icon>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-btn icon @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: content.id}})">
        <v-icon>show_chart</v-icon>
      </v-btn>
      <v-btn icon @click="loadApi()">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-menu bottom left>
        <!-- <v-btn slot="activator" icon >
          <v-icon>format_list_numbered_rtl</v-icon>
        </v-btn> -->
        <v-list>
          <v-list-tile >
            <v-list-tile-title><v-icon color="green">done_outline</v-icon> Ordenar por Disponíveis</v-list-tile-title>
          </v-list-tile>
          <v-list-tile >
            <v-list-tile-title><v-icon color="red">power_settings_new</v-icon> Ordenar por Indisponíveis</v-list-tile-title>
          </v-list-tile>
          <v-list-tile >
            <v-list-tile-title><v-icon>text_rotation_none</v-icon> Ordenar por Nome</v-list-tile-title>
          </v-list-tile>
          <v-list-tile >
            <v-list-tile-title><v-icon color="orange">access_time</v-icon> Ordenar por Último incidente</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile >
            <v-list-tile-title><v-icon color="red">close</v-icon> Fechar Menu</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-container fluid grid-list-md style="padding-top: 8px; padding-bottom: 0px !important">
      <v-layout row wrap>
        <v-flex d-flex xs12 sm12 md12 lg12 class="text-xs-center" style="padding-bottom: 0px !important;">
          <strong v-if="content && content.nome_grupo">
            {{content.nome_grupo}}
          </strong>
        </v-flex>
      </v-layout>
    </v-container>
    <disp-real-time v-if="content.dispositivos && content.dispositivos.length > 0" :dispositivos="content.dispositivos" v-on:action="clickDisp"></disp-real-time>
    <v-bottom-sheet v-model="sheet">
      <v-list>
        <v-subheader><strong>{{dispDetalhes.nome_disp ? dispDetalhes.nome_disp.substring(0, 17) + '...' : ''}}</strong>: Selecione uma Opção</v-subheader>
        <v-list-tile @click="dispInfo = true; sheet = false" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon>info</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Detalhes de {{dispDetalhes.nome_disp ? dispDetalhes.nome_disp.substring(0, 20) + '...' : ''}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="$router.push({name: 'indisponibilidades', query: {disp_dados_id: dispDetalhes.id}})" >
          <v-divider></v-divider>
          <v-list-tile-avatar>
            <v-avatar size="32px" tile>
              <v-icon color="red">arrow_downward</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-title>Listar Indisponibilidades</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-bottom-sheet>
    <v-dialog v-model="dispInfo" scrollable max-width="300px">
      <disp-info v-if="dispInfo && dispDetalhes" :disp="dispDetalhes" :indisp="content.indisponibilidades">
        <span slot="action">
          <v-btn icon @click="dispInfo = false"><v-icon>close</v-icon></v-btn>
        </span>
      </disp-info>
    </v-dialog>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import DispInfo from '@/modules/network/pages/Device/DispInfo'
  import DispRealTime from '@/modules/network/components/DispRealTime'
  export default {
    data () {
      return {
        sheet: false,
        selected: {},
        paginas: [{ text: 'Início', disabled: true, href: '#/main/dashboard' }, { text: 'Minha Rede', disabled: true }],
        content: {},
        dispInfo: false,
        dispDetalhes: {}
      }
    },
    methods: {
      loadApi: function () {
        console.log(this.$router)
        callApi.get({uri: 'grupo/disp-conexao/' + this.$route.query.id || 1, params: {join: 'dispositivos,conexoes,indisponibilidades'} || null, sucess: this.loadSucess, error: this.loadError, msgLoad: true})
      },
      loadSucess: function (data) {
        console.log(data)
        this.content = data.data
        this.$swal.close()
      },
      loadError: function (error) {
        console.log(error)
      },
      clickDisp: function (disp) {
        console.log(disp)
        this.dispDetalhes = disp
        this.sheet = true
      }
    },
    created: function () {
      this.loadApi()
    },
    watch: {
      '$route': 'loadApi'
    },
    computed: {
      style () {
        console.log(this.$store.state.style)
        return this.$store.state.style || {}
      }
    },
    components: {
      DispInfo,
      DispRealTime
    }
  }
</script>