import axios from 'axios'
import Vue from 'vue'

const SLACK_URL = 'https://hooks.slack.com/services/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'

export default {
  namespaced: true,
  state: {
    active: false,
    title: '',
    subject: null,
    message: '',
    callback: null
  },
  mutations: {
    SET_ACTIVE (state, active) {
      state.active = active
    },
    SET_TITLE (state, title) {
      state.title = title
    },
    SET_SUBJECT (state, subject) {
      state.subject = subject
    },
    SET_MESSAGE (state, message) {
      state.message = message
    },
    SET_CALLBACK (state, callback) {
      console.log(callback);
      state.callback = callback
    }
  },
  actions: {
    open: function ({ commit }, { title, message = '', subject = null, callback = null }) {
      commit('SET_TITLE', title)
      commit('SET_SUBJECT', subject)
      commit('SET_MESSAGE', message)
      commit('SET_CALLBACK', callback)
      commit('SET_ACTIVE', true)
    },
    close: function ({ commit }) {
      commit('SET_TITLE', '')
      commit('SET_SUBJECT', null)
      commit('SET_MESSAGE', '')
      commit('SET_CALLBACK', null)
      commit('SET_ACTIVE', false)
    },
    send: function ({ state, dispatch }) {
      axios.post(SLACK_URL, JSON.stringify({ text: state.message }))
      .then((response) => {
        if (response.status) {
          Vue.swal({
            type: 'success',
            title: 'Tudo certo!',
            text: 'Enviado com sucesso.'
          })
          if (state.callback) {
            state.callback()
          }
          dispatch('close')
        }
      })
    }
  }
}
