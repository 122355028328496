<template>
  <view-detail :index="props.index" :apiUrl="props.api.url" :apiParams="`${props.api.params || '?'}&limit=1000`" notGetID="true" notShowToolbar="true">
    <template slot-scope="{ content, refresh }" style="min-height: 300px;">
      <div v-if="content && content.data.length < 1" style="text-align: center; padding: 20px;">
        <img src="/static/icons/monitor/anexo_96.png" alt="">
        <h1>Nenhum arquivo anexado até o momento.</h1>
        <!-- <v-btn outline large class="mt-3" @click="addFile(refresh)">
          <v-icon>add</v-icon>
          <span> Adicionar arquivos</span>
        </v-btn> -->
      </div>
      <v-layout v-else row wrap>
        <v-flex v-for="(file, key) in content.data" xs12 sm3 md2 lg2 style="padding: 5px;" :key="key">
          <v-menu offset-y>
            <template v-slot:activator="{ on }"> 
              <v-card style="cursor: pointer;" v-on="on">
                <WiFileListItem v-if="file.file" :file="file.file"></WiFileListItem>
                <WiFileListItem v-else :file="file"></WiFileListItem>
              </v-card>
            </template>
            <v-list>
              <v-list-tile v-if="selectedMode" @click="$emit('selected', file)">
                <v-list-tile-action>
                  <v-icon color="green" large>done</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>Selecionar</v-list-tile-title>
              </v-list-tile>
              <v-divider v-if="selectedMode"></v-divider>
              <v-list-tile @click="openFile(file)">
                <v-list-tile-action>
                  <v-icon color="blue" large>open_in_new</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>Abrir</v-list-tile-title>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile v-if="props.permission" v-prms="{'id': props.permission}" @click="deleteFile(file, refresh)">
                <v-list-tile-action>
                  <v-icon color="red" large>delete</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>Apagar</v-list-tile-title>
              </v-list-tile>
              <v-list-tile v-else @click="deleteFile(file, refresh)">
                <v-list-tile-action>
                  <v-icon color="red" large>delete</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>Apagar</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </template>
  </view-detail>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import WiFileListItem from '@/default/component/WiFile/WiFileListItem'
  export default {
    name: 'WiFileList',
    data () {
      return {}
    },
    methods: {
      openFile: function (file) {
        if (file.file) {
          window.open(file.file.url)
        } else {
          window.open(file.url)
        }
      },
      deleteFile: function (file, refresh) {
        callApi.delete({
          uri: this.props.api.url,
          id: file.id,
          msgLoad: true,
          msgSucess: true,
          msgError: true,
          sucess: () => {
            refresh()
          },
          error: (err) => {
            console.log(err)
          }
        })
      },
      addFile: function (refresh) {
        this.$WiFileDialog({
          info: this.props?.info,
          onSuccess: () => {
            refresh()
          }
        })
      }
    },
    props: {
      props: {
        default: {},
        required: false
      },
      selectedMode: {
        default: false
      }
    },
    components: {
      WiFileListItem
    }
  }
</script>
