<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="92%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card v-if="dialog" class="host-detail">
      <v-toolbar dense class="host-detail__toolbar white--text" :style="{ background: (host.online ? ( host.signal_is_ok ? '#008000': '#FFA500' ) : '#FF0000') + ' !important' }">
        <v-icon color="white">router</v-icon>
        <v-toolbar-title>
          Detalhes do equipamento
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title class="host-detail__toolbar-signal">
          <HostSignalDialog :host="host">
            <span v-if="host.online">
              <strong>Sinal: </strong>
              <strong>
                {{ host.signal || '- - -' }}
              </strong>
            </span>
            <span v-else style="font-size: 18px;"> Offline </span>
          </HostSignalDialog>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn outline small color="white" @click="showOptions = !showOptions">
          <v-icon>settings</v-icon>
          <span> Opções</span>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <WiView
          index="host-detail-dialog"
          title="Detalhes do equipamento"
          :apiUrl="`network/host/${host.id}`"
          apiParams="?with=contracts.person.phones;contracts.person.adresses;active_devices:id,nome_disp as name,grupo_dados_id"
          :notGetID="true"
          :notShowToolbar="true"
        >
          <template slot-scope="{ content, refresh }">
            <HostDetail
              :host="content"
              :showOptions="showOptions"
              @refresh="(response) => { $emit('refresh', response); refresh() }"
            ></HostDetail>
          </template>
        </WiView>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import HostSignalDialog from '@/modules/network/pages/Host/HostSignalDialog'
  import HostDetail from './index'
  export default {
    name: 'HostDetailDialog',
    data: () => ({
      showOptions: false
    }),
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    props: {
      value: {
        default: false
      },
      host: {
        required: true
      }
    },
    components: {
      HostSignalDialog,
      HostDetail
    }
  }
</script>
<style scoped>
  .host-detail__toolbar-signal {
    cursor: pointer;
    font-size: 23px;
    border-style: solid;
    border-color: white;
    border-width: 1px;
    padding: 0 20px;
  }
  .host-detail {
    width: 100%;
    text-align: center !important;
  }
  .host-detail__enable-button {
    cursor: pointer;
  }
  .host-detail__toolbar {
    z-index: 999;
  }

  .host-detail__signal {
    text-align: center;
    font-size: 18px;
    padding: 0;
  }
  .host-detail__not-found {
    margin: 50px 0;
  }
  .host-detail__title {
    color: orange;
  }
  .host-detail__title--signal_is_ok {
    color: green;
  }
  .diagnostic-offline__title {
    color: red !important;
  }
</style>
