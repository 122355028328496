<template>
  <div>
    <v-tooltip v-if="rule.status" bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click="removeRule()" small outline color="green">Sim</v-btn>
      </template>
      <span>Clique para remover essa permissão.</span>
    </v-tooltip>
    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click="addRule()" small outline color="red">Não</v-btn>
      </template>
      <span>Clique para adicionar essa permissão.</span>
    </v-tooltip>
  </div>
</template>

<script>
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'UserProfileModulesButton',
    data () {
      return {}
    },
    methods: {
      addRule: function () {
        callApi.post({
          uri: 'system/user/profile-rule',
          data: {
            user_profile_id: this.$route.params.id,
            name: this.rule.id
          },
          msgLoad: {
            title: 'Adicionando permissão...'
          },
          msgSucess: {
            title: 'Permissão adicionada com sucesso!'
          },
          msgError: true,
          sucess: () => {
            this.refresh()
          }
        })
      },
      removeRule: function () {
        callApi.delete({
          uri: 'system/user/profile-rule',
          id: this.rule.rule_id,
          msgLoad: {
            title: 'Removendo permissão...'
          },
          msgSucess: {
            title: 'Permissão removida com sucesso!'
          },
          msgAsk: {
            title: 'Deseja realmente remover essa permissão?'
          },
          msgError: true,
          sucess: () => {
            this.refresh()
          }
        })
      }
    },
    props: {
      rule: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function
      }
    }
  }
</script>