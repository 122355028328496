<template>
  <div>
    <view-detail title="Cadastro" :apiUrl="apiUrl" :apiParams="'?with=phones;contacts;adresses;infos;users;users.profile;files;talk_contacts;talk_contacts.file'" :store="store">
      <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
        <v-btn v-prms="{'id': '93dd92fc'}" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="$WiEditDialog({wiConfig: store + '-function', onSubmit: refresh, data: content})" :disabled="loading">
          <v-icon>recent_actors</v-icon>
          <span>Funções/Papeis</span>
        </v-btn>
        <v-btn v-if="portal && $route.name == 'customer' && content && content.access_area && content.email && content.uuid" class="hidden-xs-only hidden-sm-only" :loading="portalLoading" small outline color="white" :disabled="loading" @click="accessPortal(content)">
          <v-icon>launch</v-icon>
          <span>
            <span>Acessar </span> 
            <span v-if="$WiCheckPermission('85352c3c-1321-4cba-b009-d5c4dc909159')"> área do paciente </span>
            <span v-else-if="$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088')"> área do formando </span>
            <span v-else> área do cliente </span>
          </span>
        </v-btn>
      </template>
      <template slot-scope="{ content, refresh }">
        <v-container>
          <v-layout row>
            <v-flex xs12>
              <v-card class="elevation-17">
                <PersonInfo
                  :person="content"
                  :refresh="refresh"
                ></PersonInfo>
              </v-card>
            </v-flex>
          </v-layout>
          <PersonGroups
            v-if="content && content.groups"
            :person="content"
          ></PersonGroups>
          <PersonStatistics
            v-if="content && content.statistics"
            :person="content"
          ></PersonStatistics>
          <PersonTabs
            :person="content"
            :refresh="refresh"
          ></PersonTabs>
        </v-container>
      </template>
    </view-detail>
  </div>
</template>

<script>
  import { callApi } from '@/default/service/Api'
  import PersonInfo from '@/modules/register/pages/Person/PersonInfo'
  import PersonGroups from '@/modules/register/pages/Person/PersonGroups'
  import PersonStatistics from './PersonStatistics/index'
  import PersonTabs from './PersonTabs/index'
  export default {
    name: 'Person',
    data () {
      return {
        apiUrl: '',
        apiParams: '?with=contacts;adresses;infos;users',
        store: null,
        tabAtiva: 'tab-1',
        portalLoading: false
      }
    },
    created: function () {
      this.loadPessoa()
    },
    watch: {
      '$route': 'loadPessoa'
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      },
      portal () {
        return this.$Auth.account().portal
      }
    },
    methods: {
      loadPessoa: function () {
        this.apiUrl = 'register/person/' + this.$route.name
        this.store = this.$route.name
      },
      accessPortal: function (person) {
        this.portalLoading = true
        console.log(this.portal);
        callApi.post({
          uri: 'portal-login-super/' + person.uuid,
          data: {
            email: person.email
          },
          sucess: response => {
            if (response?.data?.status === 'success' && response?.data?.token) {
              window.open(this.portal + '/#/dd18fa5b-3818-4177-a2cf-151b90cd58c8/' + response.data.token, '_blank')
            } else {
              this.$swal({
                type: 'warning',
                title: 'Erro ao buscar credenciais.',
                html: 'Tente novamente ou contacte o suporte.'
              })
            }
            this.portalLoading = false
          },
          error: error => {
            console.log(error)
            this.portalLoading = false
          }
        })
      }
    },
    components: {
      PersonTabs,
      PersonInfo,
      PersonGroups,
      PersonStatistics
    }
  }
</script>