export default {
  list: true,
  title: 'Notificações',
  apiUrl: 'pessoa/notificacao',
  config: {
    new: false,
    delete: false,
    update: true,
    edit: true
  },
  params: {
    where: 'pessoa_dados_id,' + localStorage.getItem('colaborador_id')
  },
  fields: [
    {value: 'id', text: 'ID', type: 'int', required: true, list: true, edit: false, search: true},
    {value: 'desc_notificacao', text: 'Descrição', counter: 15, type: 'text', list: true, edit: false},
    {value: 'momento_notificacao_format', text: 'Data/Hora', type: 'text', list: true, edit: false, search: true},
    {value: 'visto_notificacao', text: 'Status da Notificação', type: 'select', options: [{value: 'Inativo', text: 'Notificação lida'}, {value: 'Ativo', text: 'Notificação não lida'}], required: true, list: true, edit: true},
    // eslint-disable-next-line
    {value: 'teste', text: 'Detalhes', type: 'link', align: 'center', list: true, edit: false, links: [
      { icon: 'info', action: (route, dados) => { route.push(dados.rota_notificacao) }, show: (d) => { return d.rota_notificacao || false } }
    ]}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
