<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="75%" :fullscreen="$vuetify.breakpoint.xsOnly" lazy>
    <v-card>
      <v-toolbar dense>
        <v-toolbar-title>
          <v-icon madium>file_upload</v-icon>
          <span class="pl-2"
            v-t="'modules.talks.pages.talkView.talk.labels.fileUploadTitle'"
          ></span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="min-height: 300px; max-height: 700px; padding: 0;">
        <WiFile
          v-if="dialog"
          :props="{
            chat_id: chat.uuid,
            chatFileType: fileDialog.fileType
          }"
        ></WiFile>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import WiFile from '@/default/component/WiFile/WiFile'
  export default {
    name: 'ChatFileDialog',
    computed: {
      ...mapState('Chat', ['chat', 'fileDialog']),
      dialog: {
        get () {
          return this.$store.state.Chat.fileDialog.active
        },
        set (value) {
          this.$store.commit('Chat/SET_FILE_DIALOG', { active: value, fileType: null })
        }
      }
    },
    components: {
      WiFile
    }
  }
</script>