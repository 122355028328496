import Contract from '@/modules/internet/pages/Contract'
import FormReplyList from '@/modules/forms/pages/FormReplyList'
import FormReplyEdit from '@/modules/forms/pages/FormReplyEdit'
import BusinessDashboard from '@/modules/shared/pages/BusinessDashboard/BusinessDashboard'
import {
  PERMISSION_INTERNET,
  PERMISSION_CONTRACT,
  PERMISSION_CONTRACT_UPDATE
} from '@/default/constants/permissions'

export default [
  {
    path: 'contract/:id',
    name: 'internet-contract',
    component: Contract,
    meta: {
      id: PERMISSION_CONTRACT,
      auth: true,
      title: 'Detalhes do Contrato',
      icon: 'border_color',
      permission: PERMISSION_CONTRACT_UPDATE
    }
  },
  {
    path: 'form-reply-list/:id',
    name: 'form-reply-list',
    component: FormReplyList,
    meta: {
      auth: true,
      title: 'form-reply-list',
      icon: 'privacy_tip'
    }
  },
  {
    path: 'form-reply-edit/:id',
    name: 'form-reply-edit',
    component: FormReplyEdit,
    meta: {
      auth: true,
      title: 'form-reply-edit',
      icon: 'privacy_tip'
    }
  },
  {
    path: 'dashboard-internet',
    name: 'dashboard-internet',
    component: BusinessDashboard,
    meta: {
      id: PERMISSION_INTERNET,
      auth: true,
      title: 'Dashboard de Provedor',
      icon: 'dashboard',
      moduleName: 'internet'
    }
  }
]
