import router from '@/modules/purchases/routes'
import config from '@/modules/purchases/config'
import menu from '@/modules/purchases/menu'

export default {
  id: '2f2b5a92-c209-4f37-beb7-b70ce2059075',
  name: 'purchases',
  title: 'Compras',
  description: 'Gestão Contações e Compras',
  icon: 'wises-technology-module-purchase.png?url=123',
  iconSystem: 'wises-technology-system-purchase.png',
  iconProfile: 'profile_service_desk.png',
  // routeDefault: { name: 'dashboard-purchases' },
  color: '#5C3B83',
  menu,
  router,
  config
}
