export default {
  id: '31f8eef9',
  list: true,
  title: 'Fabricantes',
  apiUrl: 'equipamento/fabricante',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_fabricante', text: 'Nome do Fabricante', type: 'text', list: true, edit: true},
    {value: 'desc_fabricante', text: 'Descrição', type: 'textarea', list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
