<template>
  <view-detail
    index="business"
    :title="config.title"
    :apiUrl="config.apiUrl"
    apiParams="?with=events;finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id,amount_sale,amount_cost,margin;items.item.unit:id,code;businesses:id,type,business_id,created_at;file"
    :store="config.store + '-open'"
  >
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <!-- <v-btn 
      v-prms="{'id': 'a5243554'}" 
      class="hidden-xs-only hidden-sm-only" 
      small outline color="white" 
      @click="$WiEditDialog({wiConfig: config.store + '-event', onSubmit: dados.refresh, data: {business_id: dados.content.id}})" 
      :disabled="dados.loading || dados.content.stage == 2 || dados.content.stage == 3"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Nota</span>
      </v-btn> -->
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
      <v-btn
        v-if="!loading"
        icon 
        flat
        :disabled="loading"
        color="white"
        link
        target="_blank"
        :to="{
          name: 'business-print',
          params: {
            business: config.store,
            id: content.id
          }
        }"
      >
        <v-icon>print</v-icon>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex v-if="dados.content.title" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17">
                <v-toolbar dense flat color="white">
                  <h2>
                    <span style="color: gray;">Projeto:</span>
                    {{dados.content.title}}
                  </h2>
                </v-toolbar>
                <v-divider></v-divider>
                <v-toolbar dense flat color="white">
                  <h3>
                    <span style="color: gray;">Conta Bancária:</span>
                    {{dados.content.finance_account.name}}
                  </h3>
                  <v-spacer></v-spacer> 
                  <h3>
                    Saldo atual:
                    <span v-if="dados.content.finance_account" style="color: green;">{{dados.content.finance_account.balance_current | money}}</span> 
                    Previsão:
                    <span v-if="dados.content.finance_account" style="color: orange;">{{dados.content.finance_account.balance_foreseen | money}}</span>
                  </h3>
                </v-toolbar>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessRelationships
              :business="dados.content"
              :refresh="dados.refresh"
              :loading="dados.loading"
            ></BusinessRelationships>
            <div v-if="false">
              <v-card v-if="dados.content.business_id" class="elevation-17">
                <v-card-text style="text-align: center;">
                  <span v-if="config.store == 'budget' || config.store == 'quotation'">
                    {{config.idTitle}} convertido(a) na {{config.conversion.to}} 
                  </span>
                  <span v-if="config.store == 'sale' || config.store == 'purchase'">
                    {{config.idTitle}} criada a partir do(a) {{config.conversion.from}} 
                  </span>
                  <strong @click="$router.push(config.conversion.route(dados.content.business_id))" style="color: blue; cursor: pointer;">#{{dados.content.business_id}}</strong>
                  <!-- <div style="padding-left: 10px;">
                    <v-btn @click="showMessage = false" color="red" outline small>Fechar</v-btn>
                  </div> -->
                </v-card-text>
              </v-card>
              <v-card v-else-if="config.store == 'budget' || config.store == 'quotation'" class="elevation-17">
                <v-card-text style="text-align: center;">
                  <v-btn outline color="primary" @click="$WiEditDialog({wiConfig: config.conversion.toWiConfig + '-open', onSubmit: dados.refresh, data: getCurrentBusiness(dados.content)})">
                    Converter {{config.idTitle}} em {{config.conversion.to}}
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :config="config"
              :data="dados.content"
              @onPerson="onPerson"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="config.store == 'graduation'" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <business-groups :config="config" :data="dados.content" :refresh="dados.refresh"></business-groups>
          </v-flex>
          <BusinessItemsProviders
            :config="config"
            :business="dados.content"
            @onRefresh="dados.refresh"
          ></BusinessItemsProviders>

          <!-- <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            
          </v-flex> -->

          <v-flex v-if="dados.content.category.form && dados.content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="dados.refresh"
              :reply="dados.content.reply"
              :businessId="dados.content.id"
              :form="dados.content.category.form"
              :replyEditor="dados.content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="config.store == 'purchase' && dados.content.cfop && dados.content.cfop_info" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17">
              <v-card-text>
                <h3>
                  <span style="color: gray;">CFOP: </span>
                  <span> {{ dados.content.cfop }} - </span>
                  <span> {{ dados.content.cfop_info.name }} </span>
                </h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="config.store !== 'graduation'" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <business-items
              :config="config"
              :data="dados.content"
              :refresh="dados.refresh"
            ></business-items>
          </v-flex>
          <v-flex v-if="!config.hideFinances" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17">
              <business-finance :config="config" :data="dados.content" :refresh="dados.refresh"></business-finance>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17" v-if="dados.content.description">
              <v-card-text>
                <v-list-tile-sub-title style="color: gray;"><strong>Descrição/Apresentação:</strong></v-list-tile-sub-title>
                <span v-html="dados.content.description"></span>
              </v-card-text>
            </v-card><br>
            <v-card class="elevation-17" v-if="dados.content.information">
              <v-card-text>
                <v-list-tile-sub-title style="color: gray;"><strong>Formas/condições de pagamento:</strong></v-list-tile-sub-title>
                <span v-html="dados.content.information"></span>
              </v-card-text>
            </v-card><br>
            <v-card class="elevation-17" v-if="dados.content.observation">
              <v-card-text>
                <v-list-tile-sub-title style="color: gray;"><strong>Observações:</strong></v-list-tile-sub-title>
                <span v-html="dados.content.observation"></span>
              </v-card-text>
            </v-card>
            <v-card class="elevation-17" v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880'">
              <v-card-text>
                <v-list-tile-sub-title style="color: gray;">
                  <strong>
                    Códigos de referência:
                  </strong>
                </v-list-tile-sub-title>
                <br>
                <div>
                  <v-btn outline
                    @click="$WiListDialog({
                      wiConfig: 'product-code',
                      filter: {
                        business_id: dados.content.id
                      }
                    })"
                  >
                    <v-icon>list</v-icon>
                    <span> Listar códigos vinculados</span>
                  </v-btn>
                  <v-btn outline @click="$WiEditDialog({ wiConfig: 'sale-codes', data: { business_id: dados.content.id } })">
                    <v-icon>add</v-icon>
                    <span> Adicionar códigos</span>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <!-- <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <banco-master 
              title="Dados Banco Master"
              :fields="bancoMasterFields"
              :data="bancoMasterData"
            ></banco-master>
          </v-flex> -->
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import BusinessRelationships from '@/modules/business/pages/Business/BusinessRelationships'
  import BusinessItemsProviders from '@/modules/business/pages/Business/BusinessItemsProviders'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessItems from '@/modules/shared/pages/Business/Items/BusinessItems'
  import BusinessGroups from '@/modules/shared/pages/Business/Groups/BusinessGroups'
  import BusinessFinance from '@/modules/shared/pages/Business/Finance/BusinessFinance'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  // import BancoMaster from '@/modules/sales/pages/Banks/RegisterCopyView'
  export default {
    name: 'Business',
    data () {
      return {
        config: this.getConfig(),
        showMessage: true,
        bancoMasterFields: [
          {
            key: 'cpf',
            label: 'CPF',
            icon: 'person',
            size: ['xs12', 'sm6', 'md3', 'lg3']
          },
          {
            key: 'product',
            label: 'Produto',
            icon: 'shopping_cart',
            size: ['xs12', 'sm6', 'md3', 'lg3']
          },
          {
            key: 'exclusiveMarginValue',
            label: 'Margem Exclusiva',
            icon: 'attach_money',
            size: ['xs12', 'sm6', 'md3', 'lg3'],
            prefix: 'R$'
          },
          {
            key: 'registration',
            label: 'Matrícula',
            icon: 'badge',
            size: ['xs12', 'sm6', 'md3', 'lg3']
          },
          {
            key: 'birthDate',
            label: 'Data de Nascimento',
            icon: 'cake',
            size: ['xs12', 'sm6', 'md3', 'lg3']
          },
          {
            key: 'agreement',
            label: 'Convênio',
            icon: 'business',
            size: ['xs12', 'sm6', 'md3', 'lg3']
          },
          {
            key: 'organization',
            label: 'Órgão',
            icon: 'account_balance',
            size: ['xs12', 'sm6', 'md3', 'lg3']
          },
          {
            key: 'purchaseMarginValue',
            label: 'Margem Compra',
            icon: 'shopping_cart',
            size: ['xs12', 'sm6', 'md3', 'lg3'],
            prefix: 'R$'
          },
          {
            key: 'purchaseLimitValue',
            label: 'Limite Compra',
            icon: 'credit_card',
            size: ['xs12', 'sm6', 'md3', 'lg3'],
            prefix: 'R$'
          },
          {
            key: 'withdrawalMarginValue',
            label: 'Margem Saque',
            icon: 'account_balance_wallet',
            size: ['xs12', 'sm6', 'md3', 'lg3'],
            prefix: 'R$'
          },
          {
            key: 'withdrawalLimitValue',
            label: 'Limite Saque',
            icon: 'local_atm',
            size: ['xs12', 'sm6', 'md3', 'lg3'],
            prefix: 'R$'
          },
          {
            key: 'maxWithdrawalTerm',
            label: 'Prazo Máximo',
            icon: 'schedule',
            size: ['xs12', 'sm6', 'md3', 'lg3'],
            suffix: 'meses'
          }
        ],
        bancoMasterData: {
          cpf: '098.319.006-26',
          product: 'CREDCESTA',
          exclusiveMarginValue: 159.00,
          registration: '12366',
          birthDate: '12/9/1988',
          agreement: 'CREDCESTA GOV AMAPA',
          organization: 'GOV. AMAPA',
          purchaseMarginValue: 47.70,
          purchaseLimitValue: 667.80,
          withdrawalMarginValue: 111.30,
          withdrawalLimitValue: 1810.85,
          maxWithdrawalTerm: 120
        }
      }
    },
    methods: {
      getCurrentBusiness: function (business) {
        business['business_id'] = business.id
        business['copy_business'] = 'd711a3c2-3809-4ef2-9052-e34fb11ac908'
        delete business.business_status_id
        delete business.business_category_id
        delete business.id
        delete business.uuid
        return business
      },
      getConfig: function () {
        var business = this.$route.name
        var config = {}
        switch (business) {
          case 'budget':
            config = {
              title: 'Detalhes do Orçamento',
              idTitle: 'Orçamento',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'sales/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'sale':
            config = {
              title: 'Detalhes da Venda',
              idTitle: 'Venda',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'sales/' + business,
              store: business,
              showValidity: false,
              collaboratorSize: ['xs12', 'sm12', 'md6', 'lg6'],
              financeName: 'Recebimento(s)',
              conversion: {
                from: 'Orçamento',
                route: (id) => { return {name: 'budget', params: {id: id}} }
              }
            }
            break
          case 'purchase':
            config = {
              title: 'Detalhes da Compra',
              idTitle: 'Compra',
              person: 'provider',
              personTitle: 'Fornecedor',
              apiUrl: 'purchases/' + business,
              store: business,
              showValidity: false,
              collaboratorSize: ['xs12', 'sm12', 'md6', 'lg6'],
              financeName: 'Pagamento(s)',
              conversion: {
                from: 'Cotação',
                route: (id) => { return {name: 'quotation', params: {id: id}} }
              }
            }
            break
          case 'quotation':
            config = {
              title: 'Detalhes da Cotação',
              idTitle: 'Cotação',
              person: 'provider',
              personTitle: 'Fornecedor',
              apiUrl: 'purchases/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Pagamento(s)',
              nameConversion: 'Compra',
              conversion: {
                to: 'Compra',
                toWiConfig: 'purchase',
                route: (id) => { return {name: 'purchase', params: {id: id}} }
              }
            }
            break
          case 'order':
            config = {
              title: 'Detalhes da Ordem de Serviço',
              idTitle: 'Ordem de Serviço',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'service-desk/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Ordem de Serviço',
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'process':
            config = {
              title: 'Detalhes do Processo',
              idTitle: 'Processo',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'juridical/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Processo',
              date_start: 'Data da Entrevista',
              date_end: 'Previsão de Conclusão',
              amount_freight: 'Valor Adicional',
              hideProducts: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'project':
            config = {
              title: 'Detalhes do Projeto',
              idTitle: 'Projeto',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'projects/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Projeto',
              date_start: 'Data de Início',
              date_end: 'Previsão de Conclusão',
              amount_freight: 'Valor Adicional',
              hideProducts: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'graduation':
            config = {
              title: 'Detalhes da Formatura',
              idTitle: 'Formatura',
              person: 'customer',
              personTitle: 'Aluno/Cliente',
              apiUrl: 'graduations/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de Contratação',
              date_end: 'Previsão de Conclusão',
              amount_freight: 'Valor Adicional',
              groupName: 'Turma(s)',
              hideProducts: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'consultation':
            config = {
              title: 'Detalhes da Consulta',
              idTitle: 'Consulta',
              person: 'patient',
              personTitle: 'Paciente',
              apiUrl: 'graduations/' + business,
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de Contratação',
              date_end: 'Previsão de Conclusão',
              amount_freight: 'Valor Adicional',
              groupName: 'Turma(s)',
              hideProducts: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-transfer-in':
            config = {
              title: 'Transferência para o estoque',
              idTitle: 'Transferência',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-transfer-in',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-transfer-in-collaborator':
            config = {
              title: 'Transferência para o colaborador',
              idTitle: 'Transferência',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-transfer-in/collaborator',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-transfer-in-customer':
            config = {
              title: 'Transferência para o cliente',
              idTitle: 'Transferência',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'stocks/product-transfer-in/customer',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-transfer-out':
            config = {
              title: 'Transferência para o estoque',
              idTitle: 'Transferência',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-transfer-out',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-transfer-out-collaborator':
            config = {
              title: 'Transferência do estoque do colaborador',
              idTitle: 'Transferência',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-transfer-out/collaborator',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-transfer-out-customer':
            config = {
              title: 'Transferência do cliente',
              idTitle: 'Transferência',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'stocks/product-transfer-out/customer',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-entry':
            config = {
              title: 'Entrada de Produtos',
              idTitle: 'Entrada',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-entry',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data da Movimentação',
              date_end: 'Data da Movimentação',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-entry-collaborator':
            config = {
              title: 'Entrada para o colaborador',
              idTitle: 'Entrada',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-entry/collaborator',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-entry-customer':
            config = {
              title: 'Entrada para o cliente',
              idTitle: 'Entrada',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'stocks/product-entry/customer',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-removal-collaborator':
            config = {
              title: 'Retirada do estoque do colaborador',
              idTitle: 'Retirada',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-removal/collaborator',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-removal':
            config = {
              title: 'Retirada de Produtos',
              idTitle: 'Retirada',
              person: 'collaborator',
              personTitle: 'Colaborador',
              apiUrl: 'stocks/product-removal',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data da movimentação',
              date_end: 'Data da movimentação',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
          case 'product-removal-customer':
            config = {
              title: 'Retirada do estoque do cliente',
              idTitle: 'Retirada',
              person: 'customer',
              personTitle: 'Cliente',
              apiUrl: 'stocks/product-removal/customer',
              store: business,
              showValidity: true,
              collaboratorSize: ['xs12', 'sm12', 'md3', 'lg3'],
              financeName: 'Recebimento(s)',
              nameConversion: 'Formatura',
              date_start: 'Data de retirada',
              date_end: 'Data de entrada',
              amount_freight: 'Valor Adicional',
              groupName: '',
              hideProducts: false,
              hideServices: true,
              hideFinances: true,
              conversion: {
                to: 'Venda',
                toWiConfig: 'sale',
                route: (id) => { return {name: 'sale', params: {id: id}} }
              }
            }
            break
        }
        return config
      },
      updateView: function () {
        this.config = this.getConfig()
        this.$forceUpdate()
      },
      onPerson: function (data) {
        if (data.person && data.person.profiles && data.person.profiles.length > 0) {
          this.$router.push({
            name: data.person.profiles[0].name || this.config.person,
            params: {
              id: data.person.id
            }
          })
        }
      }
    },
    components: {
      BusinessForm,
      BusinessInfo,
      BusinessItems,
      BusinessGroups,
      BusinessFinance,
      BusinessActions,
      BusinessRelationships,
      BusinessItemsProviders
    },
    watch: {
      '$route': 'updateView'
    }
  }
</script>
<style>
  p {
    margin-bottom: 0px !important;
  }
  .finance_status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .canceled {
    color: grey;
    background: #eceff1;
  }
  .unpaid {
    color: #ff6d00;
    background: #f0f4c3;
  }
  .paid {
    color: green;
    background: #b9f6ca;
  }
  .parcel {
    color: white;
    background: #000;
  }
  .pointer {
    cursor: pointer;
  }
  .v-datatable__actions {
    bottom: 0;
    position: fixed;
    width: 100%;
    left: 0;
    height: 40px;
  }
  .v-datatable__actions__select {
    height: 30px;
  }
  .v-datatable__actions__range-controls {
    min-height: 30px;
  }
  .v-datatable__actions .v-btn {
    height: 30px;
  }
</style>
