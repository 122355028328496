import { Model } from '@/default/model/Model'
import { HostFilters } from './HostFilters'
import {
  PERMISSION_NETWORK_HOST,
  PERMISSION_NETWORK_HOST_CREATE,
  PERMISSION_NETWORK_HOST_UPDATE
} from '@/default/constants/permissions'


export class Host extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_NETWORK_HOST,
      create: PERMISSION_NETWORK_HOST_CREATE,
      update: PERMISSION_NETWORK_HOST_UPDATE,
    }
    this.title = 'Equipamentos'
    this.apiUrl = 'network/host'
    this.params = {
      limit: 10,
      order: 'hosts.created_at,desc',
      with: 'active_devices'
    }
    this.icon = 'router'
    this.search = {
      placeholder: 'Pesquise identificação, IP, ou MAC do equipamento',
      fields: ['id', 'identification', 'ip_address', 'mac_address']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/network/pages/Hosts')
    this.maxWidth = '800px'
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
    this.filters = new HostFilters()
  }

  fieldsList = {
    // name: this.fieldList({
    //   value: 'identification',
    //   text: 'Identificação'
    // }),
    mac_address: this.fieldList({
      value: 'mac_address',
      text: 'Mac/Serial'
    }),
    ip_address: this.fieldList({
      value: 'ip_address',
      text: 'IP Address'
    }),
    signal: this.fieldList({
      value: 'signal',
      text: 'Sinal'
    }),
    technology: this.fieldList({
      value: 'technology_id',
      text: 'Tecnologia'
    }),
    producer: this.fieldList({
      value: 'producer_id',
      text: 'Fabricante/Modelo',
      sortable: false
    }),
    device: this.fieldList({
      value: 'device',
      text: 'Dispositivo/Rede',
      sortable: false
    }),
    child: this.fieldList({
      value: 'child',
      text: 'Interface',
      sortable: false
    }),
    checked_at: this.fieldList({
      value: 'checked_at',
      text: 'Última leitura'
    })
  }
  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados do equipamento.'
    }),
    technology_id: this.fieldSelectApi ({
      value: 'technology_id',
      text: 'Tecnologia',
      show: 'name',
      required: true,
      api: {
        url: 'network/host/technology'
      },
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
      disabled: form => (!!form.id)
    }),
    macAddress: this.fieldText({
      value: 'mac_address',
      text: 'Mac Address',
      required: true,
      placeholder: '##:##:##:##:##:##',
      mask: 'NN:NN:NN:NN:NN:NN',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      rules: [this.rules.required(), this.rules.minLength(12), this.rules.maxLength(17)],
      disabled: form => (!!form.id && !!form.mac_address)
    }),
    serial: this.fieldText({
      value: 'serial',
      text: 'Serial',
      required: true,
      placeholder: '############',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      disabled: form => (!!form.id && !!form.serial)
    }),
    username: this.fieldText({
      value: 'username',
      text: 'Usuário',
      required: true,
      placeholder: 'Insira o usuário PPPoE',
      size: ['xs12', 'sm12', 'md3', 'lg4'],
      rules: [this.rules.required(), this.rules.minLength(8), this.rules.maxLength(100)]
    }),
    password: this.fieldText({
      value: 'password',
      text: 'Senha',
      required: true,
      placeholder: 'Insira a senha PPPoE',
      size: ['xs12', 'sm12', 'md3', 'lg4'],
      rules: [this.rules.required(), this.rules.minLength(8), this.rules.maxLength(100)]
    })
  }
}
