<template>
  <div
    class="wi-edit-message-field"
    :style="{ background: $wiDark ? '#212121' : '#ECEFF1', color: field.color || '#6C6E88', 'border-color': field.color }
  ">
    <span v-html="field.text"></span><br>
    <span v-html="field.calculate(form)"></span>
  </div>
</template>
<script>
  export default {
    name: 'WiEditFieldShowResult',
    props: ['form', 'field']
  }
</script>
<style scoped>
  .wi-edit-message-field {
    margin-bottom: 20px;
    padding: 5px 20px 5px 20px;
    min-height: 48px;
  }
</style>