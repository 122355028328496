import Business from '@/modules/shared/pages/Business/Business'
import BusinessPrint from '@/modules/shared/pages/Business/BusinessPrint'
import BusinessOfProcess from '@/modules/shared/pages/Businesses/BusinessOfProcess'

export default [
  {
    path: 'graduation/:id',
    name: 'graduation',
    component: Business,
    meta: {
      id: '4d8hd50b',
      auth: true,
      title: 'Detalhes da Formatura',
      icon: 'school'
    }
  },
  {
    path: 'business-print',
    name: 'business-print',
    component: BusinessPrint,
    meta: {
      id: '0bae9ce3-400f-8818-94a0-15d1b3bdf088',
      auth: true,
      title: 'Impressão',
      icon: 'print'
    }
  },
  {
    path: 'process-of-graduations/:business',
    name: 'process-of-graduations',
    component: BusinessOfProcess,
    meta: {
      id: '0bae9ce3-400f-8818-94a0-15d1b3bdf088',
      auth: true,
      title: 'Formaturas',
      icon: 'school',
      touch: true
    }
  }
]
