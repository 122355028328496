import { PERMISSION_NETWORK_HOST } from '@/default/constants/permissions'

export default [
  {
    id: '7517de8a',
    icon: 'dashboard',
    title: 'Dashboards',
    children: [
      { id: '7517de8a', icon: 'show_chart', title: 'Redes Dashboard', route: { name: 'networks-dashboard' } },
      { id: 'cb59faf8', icon: 'dns', title: 'Monitoramento de OLTs', route: { name: 'olt-dashboard' } }
    ]
  },
  { id: '7517de8a', icon: 'show_chart', title: 'Minhas Redes', route: { name: 'minha-rede' }, hideOnDevices: ['mobile'] },
  { id: '7517de8a', icon: 'show_chart', title: 'Minhas Redes', route: { name: 'mobile-grupo' }, hideOnDevices: ['desktop'] },
  { id: '83d002ee', icon: 'cloud_queue', title: 'Servidores de Monitoramento', route: { name: 'servidores' } },
  { id: 'cb59faf8', icon: 'dns', title: 'Dispositivos', route: { name: 'network-list', params: { page: 'devices' } } },
  { id: PERMISSION_NETWORK_HOST, icon: 'router', title: 'Equipamentos/Clientes', route: { name: 'network-list', params: { page: 'host' } } },
  // {id: 'free', icon: 'arrow_downward', title: 'Indisponibilidades', route: {name: 'indisponibilidades'}},
  {
    id: '4c231a4d',
    icon: 'build',
    title: 'Ferramentas',
    children: [
      { id: '7ed9eff2', icon: 'filter_1', title: 'Remover Lease', route: { name: 'device-lease-remove' } }
    ]
  },
  {
    id: '31f8eef9',
    icon: 'settings_applications',
    title: 'Cadastro/Configurações',
    children: [
      { id: '31f8eef9', icon: 'vertical_split', title: 'Hierarquia de Rede', route: { name: 'hierarquia-de-rede' } },
      {
        id: '31f8eef9',
        icon: 'location_city',
        title: 'Fabricantes de Equipamentos',
        route: { name: 'network-list', params: { page: 'producer' } }
      },
      {
        id: '7517de8a',
        icon: 'history',
        title: 'Redes Desativadas',
        route: { name: 'network-list', params: { page: 'networks' }, query: { where: 'active,0' } }
      },
      // { id: '31f8eef9', icon: 'surround_sound', title: 'Modelos de Dispositivos', route: { name: 'network-list', params: { page: 'equipamentos' } } },
      { id: '31f8eef9', icon: 'import_export', title: 'Inserir/Importar Dispositivos', route: { name: 'dispositivos-inserir-importar' } },
      { id: '31f8eef9', icon: 'import_export', title: 'Inserir/Importar Grupos', route: { name: 'grupos-inserir-importar' } }
    ],
    hideOnDevices: ['mobile']
  }
]
