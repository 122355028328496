import { Filters } from '@/default/model/Filters'

export class FinanceFilters extends Filters {
  constructor (inOut = false) {
    super()

    const categoryUri = inOut ? `/${inOut}` : ''

    this.id = ''
    this.title = 'Filtrar Movimentações'
    this.fields = {
      status_id: this.fieldSelect ({
        value: 'status_id',
        text: 'Status',
        options: [
          { value: '1', text: 'Em Aberto' },
          { value: '2', text: 'Pago/Recebido' },
          { value: '0', text: 'Cancelado' }
        ],
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      finance_category_id: this.fieldSelectApi ({
        value: 'finance_category_id',
        text: 'Categoria',
        show: 'name',
        api: {
          url: 'finance/category' + categoryUri,
          params: {
            order: 'name,asc'
          }
        },
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      finance_account_id: this.fieldSelectApi ({
        value: 'finance_account_id',
        text: 'Conta Financeira',
        show: 'name',
        api: {
          url: 'finance/account'
        },
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      })
    }
  }
}

export class FinanceInFilters extends FinanceFilters {
  constructor () {
    super('in')
  }
}

export class FinanceOutFilters extends FinanceFilters {
  constructor () {
    super('out')
  }
}
