<template>
  <WiReport
    title="Clientes por canal"
    :config="config"
    :api="{ url: 'register/report/channel-statistics-by-customer' }"
  >
    <template slot-scope="props">
      <tr>
        <td style="text-align: center">
          <strong>{{ props.item.name }}</strong>
        </td>
        <td style="text-align: center">
          <span>{{ props.item.type.name }}</span>
        </td>
        <td style="text-align: center">
          <span>{{ props.item.customers_count }}</span>
        </td>
        <td style="text-align: center">
          <v-btn v-if="props.item.customers_count > 0" small outline link :to="customerRoute(props.item)">
            <v-icon>person</v-icon>
            <span> Listar Clientes</span>
          </v-btn>
          <span v-else>
            Nenhum cliente neste canal, neste período
          </span>
        </td>
      </tr>
    </template>
    <div slot="before-content" slot-scope="{ content }">
      <apexchart v-if="showChart(content.data)" width="50%" height="300px" type="donut" :options="options" :series="setSeries(content.data)"></apexchart>
    </div>
  </WiReport>
</template>
<script>
import WiReport from "@/default/component/WiReport/WiReport";
export default {
  name: "ChannelStatisticsByCustomer",
  data: () => ({
    dialog: false,
    options: {
      chart: {
        id: 'Clientes por canais de entrada'
      },
      labels: []
    }
  }),
  computed: {
    config () {
      return {
        dates: {
          field: {
            text: "Data de filtro",
            items: [
              { value: "entry_date", text: "Data de Entrada" },
              { value: "created_at", text: "Data de Cadastro" }
            ],
            initial: 'created_at'
          },
          start: {
            type: "date",
            text: "Data Inicial",
            initial: this.$DateTime.date()
          },
          end: {
            type: "date",
            text: "Data Final",
            initial: this.$DateTime.date()
          }
        },
        filters: {
          text: "Filtros",
          fields: []
        },
        fields: [
          { text: "Canal", value: "name", align: "center", sortable: true },
          { text: "Tipo", value: "type.name", align: "center", sortable: false },
          { text: "N° de clientes", value: "customers_count", align: "center" }
        ]
      }
    }
  },
  methods: {
    setSeries: function (data) {
      const customersCount = []
      const channels = []
      let count = 0
      data.filter((channel) => {
        if (channel.customers_count && count < 12) {
          customersCount.push(channel.customers_count)
          channels.push(channel.name)
          count++
        }
      })
      this.options.labels = []
      this.options.labels = channels
      return customersCount
    },
    customerRoute: function (customer) {
      return {
        name: 'register-list',
        params: {
          page: 'customer'
        },
        query: {
          channel_id: customer.id,
          ...this.$route.query
        }
      }
    },
    showChart: function (data) {
      let showChart = false
      data.map(channel => {
        if (channel.customers_count > 0) {
          showChart = true
        }
      })
      return showChart
    }
  },
  components: {
    WiReport
  }
};
</script>
