import Vue from 'vue'
import DbDelete from '@/default/components/DBDelete'
import ViewDetail from '@/default/components/ViewDetail'
import WiView from '@/default/component/WiView/WiView'
import EditModal from '@/default/components/Modal/EditModal'
import WiEditDialog from '@/default/component/WiEdit/WiEditDialog'
import WiListDialog from '@/default/component/WiList/WiListDialog'
import WiListComponent from '@/default/component/WiList/WiListComponent'
import WiDialog from '@/default/component/WiDialog/WiDialog'
import WiCopyTo from '@/default/component/WiCopyTo/WiCopyTo'
import WiImageDialog from '@/default/component/WiImageDialog'
import WiLoading from '@/default/component/WiLoading/WiLoading'

Vue.component('DbDelete', DbDelete)
Vue.component('ViewDetail', ViewDetail)
Vue.component('WiView', WiView)
Vue.component('EditModal', EditModal)
Vue.component('WiEditDialog', WiEditDialog)
Vue.component('WiListDialog', WiListDialog)
Vue.component('WiListComponent', WiListComponent)
Vue.component('WiDialog', WiDialog)
Vue.component('WiCopyTo', WiCopyTo)
Vue.component('WiImageDialog', WiImageDialog)
Vue.component('WiLoading', WiLoading)

export default {}
