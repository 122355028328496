<template>
  <v-card v-if="disp">
    <!-- eslint-disable -->
    <v-card-title>
      <strong>{{disp.nome_disp}}</strong>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="min-height: 300px; padding: 0;">
      <v-tabs v-model="tabs" centered slot="extension" grow dense>
        <v-tabs-slider color="grey"></v-tabs-slider>
        <v-tab href="#tab-1">
          <span>Info</span>
        </v-tab>
        <v-tab href="#tab-2">
          <span>Indisponibilidades</span>
        </v-tab>
        <v-tab-item value="tab-1">
          <v-divider></v-divider>
          <div style="width: 100%; padding: 5px 70px 0 70px;">
            <div color="transparent">
              <img :src="'static/network/disp/' + disp.equipamento_fabricante_id + disp.status.cod_status.replace('#', '-') + '.png'" style="width: 100%;" alt="">
            </div>
          </div>
          <v-list one-line style="padding-top: 0">
            <template>
              <v-divider></v-divider>
              <v-list-tile avatar ripple style="padding: 0px !important;">
                <v-list-tile-avatar style="min-width: 0px !important;">
                  <v-icon>contact_support</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>IP: <strong>{{ disp.ip_disp }}</strong></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </template>
            <template>
              <v-list-tile avatar ripple>
                <v-list-tile-avatar style="min-width: 0px !important;">
                  <v-icon color="yellow darken-2">star</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>MAC: <strong>{{disp.mac_disp}}</strong></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </template>
            <template>
              <v-list-tile avatar ripple>
                <v-list-tile-avatar style="min-width: 0px !important;">
                  <v-icon color="orange darken-2">device_hub</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Tipo: <strong>{{ disp.nome_tipo }}</strong></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </template>
            <template>
              <v-list-tile avatar ripple>
                <v-list-tile-avatar style="min-width: 0px !important;">
                  <v-icon color="orange darken-2">device_hub</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Eqpmt: <strong>{{ disp.nome_equipamento }}</strong></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </template>
          </v-list> 
        </v-tab-item>
        <v-tab-item value="tab-2" style="min-height: 250px;">
          <v-list v-if="indisp && indisp.length > 0" three-line style="padding-top: 0">
            <v-divider></v-divider>
            <template v-for="(indi, indiKey) in indisp"  v-if="indi.disp_dados_id == disp.id">
              <v-list-tile :key="indiKey" avatar ripple style="padding: 0px !important;">
                <v-list-tile-avatar style="min-width: 0px !important;">
                  <v-icon v-if="indi.status_indi" color="green">done_outline</v-icon>
                  <v-icon v-else color="red">power_settings_new</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Duração: {{indi.duracao_indi}}</v-list-tile-title>
                  <v-list-tile-sub-title>Início: {{indi.down_indi_format}}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Término: {{indi.up_indi_format || 'Até o Momento'}}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </template>
          </v-list>
          <!-- <v-alert v-if="indisp.length < 1" :value="true" type="warning">
            Nenhuma indisponibilidade para este Dispositivo.
          </v-alert> -->
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot name="action"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
  export default {
    name: 'DispInfo',
    data () {
      return {
        tabs: 'tab-1',
        indispLength: 0
      }
    },
    computed: {
      style () {
        console.log(this.$store.state.style)
        return this.$store.state.style || {}
      }
    },
    props: ['disp', 'indisp']
  }
</script>
<style scoped>
.v-list__tile {
  padding: 0px !important;
}
.v-list__tile--avatar {
  height: 40px;
}
</style>