<template>
  <v-timeline v-if="execution" dense clipped>
    <v-slide-y-transition group>
      <v-timeline-item v-for="stage in invertStages" :key="stage.id" fill-dot :class="{'mb-5': isCurrentStage(stage)}" class="white--text" :color="stage.status.color" large>
        <template v-slot:icon>
          <Status
            :status="stage.status"
          ></Status>
        </template>
        <Stage
          :stage="stage"
          @refresh="refreshExecution"
        ></Stage>
      </v-timeline-item>
    </v-slide-y-transition>
  </v-timeline>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import Status from './Status'
  import Stage from './Stage'
  export default {
    name: 'ProcessTimeLine',
    computed: {
      ...mapState('ProcessExecution', ['execution', 'loading']),
      ...mapGetters('ProcessExecution', ['invertStages'])
    },
    methods: {
      ...mapActions('ProcessExecution', ['refreshExecution']),
      isCurrentStage: function (stage) {
        return stage?.id == this.execution?.current_stage?.id
      }
    },
    components: {
      Status,
      Stage
    }
  }
</script>