import router from '@/modules/sales/routes'
import config from '@/modules/sales/config'
import menu from '@/modules/sales/menu'

export default {
  id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
  name: 'sales',
  title: 'Vendas',
  description: 'Gestão Comercial e Vendas',
  icon: 'wises-technology-module-sale.png',
  iconSystem: 'wises-technology-system-sale.png',
  iconProfile: 'profile_service_desk.png',
  // routeDefault: { name: 'dashboard-sales' },
  color: '#58ADC0',
  menu,
  router,
  config
}
