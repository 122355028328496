export default [
  {id: '2f2b5a92-c209-4f37-beb7-b70ce2059075', icon: 'dashboard', title: 'Dashboard de Compras', route: { name: 'dashboard-purchases' }},
  {
    id: '1b7857f9',
    icon: 'local_shipping',
    title: 'Gestão de Compras',
    children: [
      { id: '1b7857f9', icon: 'view_week', title: 'Processo de Compras', route: { name: 'process-of-purchases', params: { business: 'purchase' } } },
      { id: '1b7857f9', icon: 'select_all', title: 'Compras Em Aberto', route: { name: 'purchases-list', params: { page: 'purchase-open' } } },
      { id: '1b7857f9', icon: 'done_outline', title: 'Histórico de Compras', route: { name: 'purchases-list', params: { page: 'purchase-close' } } },
      { id: '1b7857f9', icon: 'close', title: 'Compras Canceladas', route: { name: 'purchases-list', params: { page: 'purchase-cancel' } } }
    ]
  },
  {
    id: '5bfa5b87',
    icon: 'more',
    title: 'Gestão de Cotações',
    children: [
      { id: '5bfa5b87', icon: 'view_week', title: 'Processo de Cotação', route: { name: 'process-of-purchases', params: { business: 'quotation' } } },
      { id: '5bfa5b87', icon: 'select_all', title: 'Cotações Em Aberto', route: { name: 'purchases-list', params: { page: 'quotation-open' } } },
      { id: '5bfa5b87', icon: 'done_outline', title: 'Cotações Aprovadas', route: { name: 'purchases-list', params: { page: 'quotation-close' } } },
      { id: '5bfa5b87', icon: 'close', title: 'Cotações Rejeitadas', route: { name: 'purchases-list', params: { page: 'quotation-cancel' } } }
    ]
  },
  { id: 'vnds', icon: 'local_activity', title: 'Pedidos de Compra', route: { name: 'disponivel-em-breve' } },
  // {
  //   id: '7bea0895',
  //   icon: 'bar_chart',
  //   title: 'Relatórios',
  //   children: [
  //     {id: '1b4f745c', icon: 'people_outline', title: 'Compras por Fornecedor', route: {name: 'business-report-sale-by-customer'}},
  //     {id: 'dab5ce52', icon: 'assignment_ind', title: 'Compras por Colaborador', route: {name: 'business-report-sale-by-collaborator'}}
  //   ]
  // },
  {
    id: '427a2b08',
    icon: 'description',
    title: 'Cadastro',
    children: [
      {id: '427a2b08', icon: 'local_grocery_store', title: 'Cadastro de Produtos', route: {name: 'purchases-list', params: {page: 'purchases-register-product'}}},
      {id: '427a2b08', icon: 'room_service', title: 'Cadastro de Serviços', route: {name: 'purchases-list', params: {page: 'purchases-register-service'}}},
      {id: '427a2b08', icon: 'assignment_ind', title: 'Colaboradores', route: {name: 'purchases-list', params: {page: 'purchases-register-collaborator'}}},
      {id: '427a2b08', icon: 'people_outline', title: 'Clientes', route: {name: 'purchases-list', params: {page: 'purchases-register-customer'}}}
    ]
  },
  {
    id: 'f0895f87',
    icon: 'settings',
    title: 'Configurações',
    children: [
      {id: 'f0895f87', icon: 'code', title: 'Status/Etapas de Compra', route: { name: 'purchases-list', params: { page: 'config-status-purchase' } }},
      {id: 'f0895f87', icon: 'category', title: 'Categorias de Compra', route: { name: 'purchases-list', params: { page: 'config-category-purchase' } }},
      {id: 'f0895f87', icon: 'code', title: 'Status/Etapas de Cotação', route: { name: 'purchases-list', params: { page: 'config-status-quotation' } }},
      {id: 'f0895f87', icon: 'category', title: 'Categorias de Cotação', route: { name: 'purchases-list', params: { page: 'config-category-quotation' } }}
    ]
  }
]
