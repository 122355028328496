export default {
  namespaced: true,
  state: {
    lists: [
      // {
      //   wiConfig: null,
      //   params: null,
      //   mode: null,
      //   active: false,
      //   button: null,
      //   callback: null,
      //   callbackFields: [],
      //   wiEditData: {}
      // }
    ]
  },
  mutations: {
    lists (state, data) {
      state.lists = data
    }
  },
  actions: {
    open: function ({ state, commit }, config) {
      var lists = state.lists
      const index = lists.length
      config['index'] = index
      config['active'] = false
      config['params'] = config.filter || null
      lists.push(config)
      commit('lists', lists)
      setTimeout(() => {
        lists[index].active = true
        commit('lists', lists)
      }, 50)
    },
    close: function ({ state, commit }, close) {
      var lists = state.lists
      lists[close.index].active = false
      commit('lists', lists)
      setTimeout(() => {
        lists.splice(close.index, 1)
        commit('lists', lists)
      }, 200)
      if (state.lists[close.index].callback) {
        var returnValue = close.selected
        if (close.selected && state.lists[close.index].callbackFields && state.lists[close.index].callbackFields.length > 0) {
          returnValue = {}
          state.lists[close.index].callbackFields.forEach(field => {
            var set = field.set
            var get = close.selected[field.get]
            if (field.get && field.get.split('.').length > 1) {
              get = close.selected[field.set.split('.')[1]]
            }
            if (field.set && field.set.split('.').length > 1) {
              returnValue[field.set.split('.')[0]] = {}
              returnValue[field.set.split('.')[0]][field.set.split('.')[1]] = get
            } else {
              returnValue[set] = get
            }
          })
        }
        state.lists[close.index].callback(returnValue)
      }
    }
  }
}
