<template>
  <v-layout row class="person-statistics">
    <v-flex xs12>
      <v-layout v-if="person.statistics && person.statistics.finances && $Auth.checkPermission('bc2dcdd9-8571-4515-9917-8c1f871ca822')" row wrap class="person-statistics__row--bottom">
        <PersonFinancesStatistics
          :finances="person.statistics.finances"
          :person="person"
        ></PersonFinancesStatistics>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm12 md3 lg3 xl3 v-for="(statistic, statisticKey) in statisticsArray" :key="statisticKey">
          <PersonStatistic
            v-if="$WiCheckPermission(statistic.permission)"
            class="person-statistics__item"
            :class="getStatisticClass(statisticKey)"
            :statistic="statistic"
            :person="person"
          ></PersonStatistic>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  import PersonFinancesStatistics from './PersonFinancesStatistics'
  import PersonStatistic from './PersonStatistic'
  import statistics from './statistics'
  export default {
    name: 'PersonStatistics',
    methods: {
      getStatisticClass: function (index) {
        const leftIndex = [0, 4, 8, 12, 16, 20, 24, 28]
        const rightIndex = [3, 7, 11, 15, 19, 23, 27]
        return {
          'person-statistics__item--left': leftIndex.indexOf(index) > -1,
          'person-statistics__item--right': rightIndex.indexOf(index) > -1
        }
      },
      businessData: function (business) {
        return this.person?.statistics[business]
      },
      businessListAction: function (business) {
        return () => {
          this.$WiListDialog({
            wiConfig: business,
            filter: {
              person_id: this.person.id
            },
            wiEditData: {
              person_id: this.person.id,
              person: this.person
            }
          })
        }
      },
      businessAddAction: function (business) {
        return () => {
          this.$WiEditDialog({
            wiConfig: business,
            data: {
              person_id: this.person.id,
              person: this.person,
              cli_ou_for: 'cli',
            }
          })
        }
      }
    },
    props: {
      person: {
        type: Object,
        required: true
      }
    },
    mixins: [statistics],
    components: {
      PersonFinancesStatistics,
      PersonStatistic
    }
  }
</script>
<style>
  .person-statistics {
    margin-top: 10px;
  }
  .person-statistics__row--bottom {
    margin-bottom: 10px;
  }
  .person-statistics__row--top {
    margin-top: 10px;
  }
  .person-statistics__item {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 10px;
  }
  .person-statistics__item--left {
    margin-left: 0px !important;
  }
  .person-statistics__item--right {
    margin-right: 0px !important;
  }
</style>