import { BusinessStatus } from '@/modules/business/config/BusinessStatus'

class SalesStatus extends BusinessStatus {
  constructor() {
    super()
    this.id = 'a02e979e'
  }
}

export class SaleStatus extends SalesStatus {
  constructor() {
    super()
    this.title.list = 'Etapas de Venda'
    this.apiUrl = 'sales/config/status/sale'
  }
}

export class BudgetStatus extends SalesStatus {
  constructor() {
    super()
    this.title.list = 'Etapas de Orçamento'
    this.apiUrl = 'sales/config/status/budget'
  }
}

export class ContractStatus extends SalesStatus {
  constructor() {
    super()
    this.title.list = 'Etapas de Contrato'
    this.apiUrl = 'sales/config/status/contract'
  }
}

export class GuaranteeStatus extends SalesStatus {
  constructor() {
    super()
    this.title.list = 'Etapas de Garantia'
    this.apiUrl = 'sales/config/status/guarantee'
  }
}
