<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="898px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-model__enable-button elevation-2">
        <v-card-text style="text-align: center;">
          <div>
            <img v-if="action.model.images && action.model.images.length > 0" :src="action.model.images[0]" alt="" style="width: 100px;">
            <br>
          </div>
          <strong>{{ action.model.name }}</strong>
          <br>
          <br>
          <v-divider></v-divider>
          <br>
          <span>Clique para analisar</span>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-model">
      <v-toolbar dense class="diagnostic-model__toolbar">
        <v-toolbar-title>
          Selecionar modelo {{ action.model.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 sm12 style="padding-right: 10px;">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-model__model">
                <div
                  v-html="action.model.layout"
                ></div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions class="elevation-20">
        <v-btn outline block color="red" class="white--text" @click="dialog = false">
          <v-icon>close</v-icon>
          <span> Cancelar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn block color="green" class="white--text" @click="selectModel()">
          <v-icon>done</v-icon>
          <span> Selecionar este modelo</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'ProducerModel',
    data: () => ({
      dialog: false,
    }),
    methods: {
      selectModel: function () {
        this.$WiApiPost({
          uri: 'networks/test-management-vtal/event/action',
          data: this.action.content,
          config: {
            msgLoad: {
              title: `Executando ação (${this.action.title})...`,
              text: this.action.description
            },
            msgSucess: {
              title: 'Solicitação enviada com sucesso.',
              text: 'O resultado da solicitação irá aparecer na timeline.'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja "${this.action.title}"?`,
              text: this.action.description
            }
          }
        })
      }
    },
    props: {
      action: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .diagnostic-model {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-model__enable-button {
    cursor: pointer;
  }
  .diagnostic-model__toolbar {
    z-index: 999;
  }

  .diagnostic-model__model {
    text-align: left;
    font-size: 15px;
    color: gray;
  }
  .diagnostic-model__not-found {
    margin: 50px 0;
  }
  .diagnostic-model__title {
    color: red;
  }
  .diagnostic-model__title--signal_is_ok {
    color: green;
  }
</style>
