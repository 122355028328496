<template>
    <v-layout row wrap>
      <v-flex xs12 sm12 md4 lg4>
        <network-server-info-column1 :data="data" :status="status" :information="information"></network-server-info-column1>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4>
        <network-server-info-column2 :data="data" :status="status" :information="information"></network-server-info-column2>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4>
        <network-server-info-column3 :data="data" :status="status" :information="information"></network-server-info-column3>
      </v-flex>
    </v-layout>
</template>
<script>
  import NetworkServerInfoColumn1 from '@/modules/network/pages/NetworkServer/NetworkServerInfo/Columns/Column1'
  import NetworkServerInfoColumn2 from '@/modules/network/pages/NetworkServer/NetworkServerInfo/Columns/Column2'
  import NetworkServerInfoColumn3 from '@/modules/network/pages/NetworkServer/NetworkServerInfo/Columns/Column3'
  export default {
    name: 'NetworkServerInfo',
    data () {
      return {}
    },
    methods: {},
    props: {
      data: {
        type: Object
      },
      information: {
        type: Object
      },
      status: {
        type: String
      }
    },
    components: {
      NetworkServerInfoColumn1,
      NetworkServerInfoColumn2,
      NetworkServerInfoColumn3
    }
  }
</script>