<template>
  <v-card>
    <div style="text-align: center; min-height: 100px; padding: 10px;">
      <v-icon style="font-size: 30px;">chat</v-icon>
      <h3 style="margin: 0;">Inteligência Artificial (BETA)</h3>
      <p style="max-width: 230px; font-size: 80%;">
        Transforme o seu texto com inteligência artificial antes de o enviar.
      </p>
    </div>
    <v-divider></v-divider>
    <div style="overflow-y: scroll; max-height: 300px;">
      <div v-for="(aiOption, aiOptionIndex) in aiOptions" :key="aiOptionIndex">
        <v-subheader style="height: 25px;" class="grey lighten-3 black--text">
          
          <span
            v-if="!message && (aiOption.action != 'talk-options')"
            class="orange--text"
            style="font-size: 12px;"
          >
              Digite algo na caixa de texto para habilitar
          </span>
          <span v-else>{{ aiOption.section }}</span>
        </v-subheader>
        <v-divider></v-divider>
        <v-list dense avatar style="padding: 0;">
          <v-list-tile
            v-for="(item, itemIndex) in aiOption.items"
            :key="itemIndex"
            style="height: 28px;"
            class="talk-footer__ai-option-item"
            @click="aiOptionAction(item.action)"
            :disabled="!message && (item.action != 'summarize-talk' && item.action != 'suggest-response')"
          >
            <v-list-tile-avatar>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title :class="!message && (item.action != 'summarize-talk' && item.action != 'suggest-response') ? '' : 'black--text'">
                {{ item.name }}. 
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </div>
    </div>
  </v-card>
</template>
<script>
  export default {
    name: 'MessageAIMenu',
    data: () => ({
      aiOptions: [
        {
            "action": "talk-options",
            "section": "Atendimento",
            "items": [
                { "icon": "list", "name": "Resumir Atendimento", "action": "summarize-talk" },
                { "icon": "chat", "name": "Sugerir resposta", "action": "suggest-response" }
            ]
        },
        {
            "action": "change-text",
            "section": "Mudar texto",
            "items": [
                { "icon": "short_text", "name": "Resumir", "action": "summarize" },
                { "icon": "short_text", "name": "Simplificar", "action": "simplify" },
                { "icon": "short_text", "name": "Reescrever", "action": "rewrite" },
                { "icon": "open_in_full", "name": "Expandir", "action": "expand" },
                { "icon": "info", "name": "Explicar", "action": "explain" },
                { "icon": "spellcheck", "name": "Corrigir erros de português", "action": "correct-portuguese-errors" },
                { "icon": "translate", "name": "Traduzir para o inglês", "action": "translate-to-english" },
                { "icon": "translate", "name": "Traduzir para o espanhol", "action": "translate-to-spanish" },
                { "icon": "translate", "name": "Traduzir", "action": "translate" }
            ]
        },
        {
            "action": "change-tone",
            "section": "Mudar tom para",
            "items": [
                { "icon": "emoji_emotions", "name": "Amigável", "action": "friendly" },
                { "icon": "emoji_objects", "name": "Empolgante", "action": "exciting" },
                { "icon": "favorite", "name": "Fofinho", "action": "cute" },
                { "icon": "error", "name": "Urgente", "action": "urgent" },
                { "icon": "sentiment_dissatisfied", "name": "Triste", "action": "sad" },
                { "icon": "emoji_people", "name": "Casual", "action": "casual" },
                { "icon": "work", "name": "Formal", "action": "formal" }
            ]
        },
        {
            "action": "generate-text",
            "section": "Gerar texto",
            "items": [
                { "icon": "campaign", "name": "Promocional", "action": "promotional" },
                { "icon": "sentiment_very_dissatisfied", "name": "Pedido de desculpas", "action": "apology" },
                { "icon": "celebration", "name": "Felicitação", "action": "congratulations" }
            ]
        }
      ],
      aiLoading: false
    }),
    methods: {
      aiOptionAction: function (action) {
        this.$emit('onSelect', action)
      }
    },
    props: {
      message: {
        default: null
      }
    }
  }
</script>
<style scoped>
  .talk-footer__ai-option-item >>> .v-list__tile:not(.v-list__tile--avatar) {
    height: 28px !important;
  }
</style>