import Vue from 'vue'
import i18n from '@/lang/i18n'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.min.css'
import Echo from 'laravel-echo'
import money from 'v-money'
import Vue2Editor from 'vue2-editor'
import JsonExcel from 'vue-json-excel'
import vueXlsxTable from 'vue-xlsx-table'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2'
import url from '@/default/store/modules/url/url'
import { Storage } from '@/default/service/Storage'
import VueRecord from '@codekraft-studio/vue-record'
import VueMermaid from 'vue-mermaid/src'

const moment = require('moment')
require('moment/locale/pt-br')
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(Vue2Editor)
Vue.use(vueXlsxTable, { rABS: false })
Vue.use(VueApexCharts)
Vue.component('downloadExcel', JsonExcel)
Vue.use(money, { precision: 4 })
Vue.use(VueSweetalert2)
Vue.use(Vuetify, {
  theme: {
    primary: '#367da6',
    secondary: colors.blue.lighten4,
    accent: '#000',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
})
Vue.component('apexchart', VueApexCharts)
Vue.use(VueMermaid)

const echo = true

if (echo) {
  const auth = Storage.get('f777bbf0-ef5d-45db-b77a-dd7802f92824')
  if (auth && auth.token && auth.user) {
    const user = Storage.decrypt(auth.user)
    if (auth && user.account && user.account.id) {
      var authHeaders = {}
      authHeaders = {
        'Authorization': 'Bearer ' + auth.token,
        'Hash': auth.hash,
        'X-Tenant': user.account.id,
        'Accept': 'application/json',
        'X-Id': user?.person?.uuid
      }
      window.io = require('socket.io-client')
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: url.state.echo.host + ':' + url.state.echo.port,
        reconnection: true,
        auth: {
          headers: authHeaders
        }
      })
      Vue.use(VueRecord)
    }
  }
}

export default {}
