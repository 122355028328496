<template>
  <v-card>
    <!-- eslint-disable -->
    <v-list dense style="max-height: none !important;">
      <v-list-tile avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <h4>
              <v-icon style="height: 15px;"></v-icon>
              <span>Tempo de Duração</span>
            </h4>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action style="text-align: center; padding: 0 5px;">
          <v-list-tile-sub-title style="font-size:12px;">
            <span>Chamados</span>
          </v-list-tile-sub-title>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile
        v-for="(duration, durationIndex) in data"
        :key="durationIndex"
        v-ripple
        avatar
        link
        :to="{
          name: 'service-desk-list',
          params: {page: 'ticket-opened'},
          query: {
            duration: durationIndex
          }
        }"
        style="height: 40px !important;"
      >
        <v-list-tile-avatar size="45" style="height: 40px !important;">
          <v-avatar size="35">
            <img src="/static/icons/monitor/velocimetro_96.png" width="80px">
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title style="font-size:18px;">
            <strong :style="{color: duration.color}">{{ duration.title }}</strong>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action style="padding: 0 5px;">
          <v-list-tile-title style="text-align: center; font-size:24px;">
            <span>{{ duration.count }}</span>
          </v-list-tile-title>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-card>    
</template>
<script>
  export default {
    name: 'ServiceDeskDashboardTicketsByDurationPerTime',
    props: ['data', 'limit']
  }
</script>