import { Transfer } from './Transfer'
import {
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CREATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_DELETE
} from '@/default/constants/permissions'

export class TransferOut extends Transfer {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT,
      create: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CREATE,
      update: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_UPDATE,
      delete: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_DELETE
    }
    this.name = 'product-transfer-out'
    this.title.list = 'Transferência para um estoque'
    this.apiUrl = 'stocks/product-transfer-out'
    this.urlDetails.name = 'product-transfer-out'
    this.icon = 'social_distance'
    // Fields
    this.fields[2] = this.fieldWiListDialog({
      value: 'stock_id',
      text: 'Estoque de onde os produtos virão',
      required: true,
      size: ['xs12', 'sm12', 'md7', 'lg7'],
      wiConfig: 'stock',
      show: 'stock.name',
      icon: 'my_location',
      disabled: (form) => {
        return form.id != null
      }
    })
    this.fields[3].apiUrl = 'sales/config/status/product-transfer-out'
    this.fields[4].apiUrl = 'sales/config/category/product-transfer-out'
    this.fields[12].text = 'Colaborador'
  }
}
