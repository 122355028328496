<template>
  <v-card-text>
    <div v-html="event.description"></div>
  </v-card-text>
</template>
<script>
  export default {
    name: 'WiEventsEventDefault',
    props: {
      event: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
