import { Model } from '@/default/model/Model'

class TicketNetwork extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1a825af1'
    this.name = 'ticket-network'
    this.actions = {
      delete: true
    }
    this.title = {
      list: 'Rede(s) anexada(s) ao chamado',
      edit: {
        insert: 'Anexar rede',
        update: 'Editar rede anexada'
      }
    }
    this.apiUrl = 'service-desk/ticket/network'
    this.icon = 'group'
    this.params = {
      limit: 10,
      order: 'id,asc'
    }
    this.search = {
      placeholder: 'Pesquise pelo nome da rede',
      fields: ['nome_grupo']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.fieldsSelected = {
      itemName: 'rede(s)'
    }
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Ticket/Network/TicketNetworks')
    this.setFields([
      this.fieldList({
        value: 'grupo_dados_id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'nome_grupo',
        text: 'Nome da rede'
      }),
      this.fieldList({
        value: 'nome_tipo',
        text: 'Tipo de rede',
        align: 'center'
      }),
      this.fieldList({
        value: 'status_disp',
        text: 'Status'
      }),
      // this.fieldList({
      //   value: 'id',
      //   text: 'Ordem de Serviço'
      // })
    ])
  }
}
export default new TicketNetwork()
