export default {
  id: {
    id: '83d002ee',
    create: 'e578c9c7',
    update: '529e4c78',
    delete: '48799725'
  },
  icon: 'cloud_queue',
  title: {
    edit: {
      create: 'Novo Servidor de Monitoramento',
      update: 'Atualizar Servidor de Monitoramento'
    }
  },
  apiUrl: 'servidor/dados',
  urlDetails: {name: 'network-server', params: {}},
  // returnUrl: {name: 'list', params: {page: 'servidores'}},
  maxWidth: '800px',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_servidor', text: 'Nome', type: 'text', size: ['xs12', 'sm12', 'md6', 'ls6'], required: true, list: true, edit: true},
    {value: 'ip_servidor', text: 'IP Cadastro', type: 'text', size: ['xs12', 'sm12', 'md4', 'ls4'], required: false, list: true, edit: true},
    // {value: 'ativo_servidor', text: 'Está ativo?', type: 'switch', size: ['xs12', 'sm12', 'md2', 'ls2'], required: false, list: false, edit: true},
    {
      value: 'ativo_servidor',
      text: 'Está ativo?',
      placeholder: 'Sim ou não',
      type: 'select',
      size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
      edit: true,
      options: [ { value: 'ativo', text: 'Sim' }, { value: 'inativo', text: 'Não' } ],
      default: 'ativo'
    },
    {value: 'ip_interacao', text: 'IP Reconhecido', type: 'text', disabled: true, size: ['xs12', 'sm12', 'md4', 'ls4'], required: false, list: true, edit: true},
    // {value: 'token_servidor', text: 'Token', disabled: true, type: 'text', size: ['xs12', 'sm12', 'md6', 'ls6'], required: false, list: false, edit: true},
    {value: 'latitude_servidor', text: 'Latitude', type: 'text', size: ['xs12', 'sm12', 'md4', 'ls4'], list: true, edit: true},
    {value: 'longitude_servidor', text: 'Longitude', type: 'text', size: ['xs12', 'sm12', 'md4', 'ls4'], list: true, edit: true},
    {value: 'obs_servidor', text: 'Observações', type: 'textarea', list: false, edit: true}
  ]
}
