<template>
  <div>
    <div v-if="contact.person">
      <div>
        <v-card flat color="transparent">
          <v-card-title class="py-1 px-1">
            <v-icon>info</v-icon>
            <span style="padding-left: 10px;">
              <strong>Informações</strong>
            </span>
            <v-spacer></v-spacer>
            <v-btn outline small color="green" class="my-0 mx-0" @click="editPerson()">
              <v-icon>edit</v-icon>
              <span>Editar</span>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0">
            <v-card>
              <v-list dense class="py-0"  color="transparent">
                <template v-for="(info, infoIndex) in informations">
                  <v-list-tile v-if="personFormated[info.id] != null && info.type != 'textarea'" class="talk-contact-budgets__list-tile" :key="`${info.id}__${infoIndex}`">
                    <v-list-tile-content>
                      <v-list-tile-sub-title style="display: flex; justify-content: space-between;">
                        <span>
                          {{ info.title }}: 
                        </span>
                        <strong style="padding-left: 5px;"> {{ personFormated[info.id] }}</strong>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <wi-copy-to
                        :text="personFormated[info.id]"
                        :title="info.title"
                        color="primary"
                      ></wi-copy-to>
                    </v-list-tile-action>
                  </v-list-tile>
                  <div
                    v-if="personFormated[info.id] != null && info.type == 'textarea'"
                    :key="`${info.id}__${infoIndex}--`"
                    style="display: flex; flex-direction: column; text-align: left; padding: 10px"
                  >
                    <div style="color: rgba(0, 0, 0, .54); font-size: 13px; display: flex; justify-content: space-between; align-items: start;">
                      <span>{{ info.title }}:</span>
                      <wi-copy-to
                        :text="personFormated[info.id]"
                        :title="info.title"
                        color="primary"
                      ></wi-copy-to>
                    </div>
                    <strong
                      v-html="personFormated[info.id]"
                      style="white-space: pre-line; font-size: 13px; color: rgba(0, 0, 0, .54);"
                    ></strong>
                  </div>
                  <v-divider v-if="personFormated[info.id] != null" :key="info.id"></v-divider>
                </template>
              </v-list>
            </v-card>
            <v-card class="mt-2">
              <v-card-title class="py-1 px-1">
                <v-icon>text_fields</v-icon>
                <span style="padding-left: 10px;">
                  <strong>Dados Complementares</strong>
                </span>
                <v-spacer></v-spacer>
                <v-btn outline small color="green" class="my-0 mx-0" @click="$WiEditDialog({wiConfig: 'info', data: { person_id: person.id }, onSubmit: refresh})">
                  <v-icon>edit</v-icon>
                  <span>Adicionar</span>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-list v-if="personInfos.length > 0" dense class="py-0"  color="transparent">
                <template v-for="(personInfo, personInfosIndex) in personInfos">
                  <v-list-tile class="talk-contact-budgets__list-tile" :key="`${personInfo.id}__${personInfosIndex}`">
                    <v-list-tile-content>
                      <v-list-tile-sub-title style="display: flex; justify-content: space-between;">
                        <span>
                          {{ personInfo.description }}: 
                        </span>
                        <strong style="padding-left: 5px;"> {{ personInfo.information | stringlength(25) }}</strong>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action style="min-width: 35px !important;">
                      <wi-copy-to
                        :text="personInfo.information"
                        :title="personInfo.description"
                        color="primary"
                      ></wi-copy-to>
                    </v-list-tile-action>
                    <v-list-tile-action style="min-width: 0 !important;">
                      <v-btn icon small @click="$WiEditDialog({wiConfig: 'info', data: personInfo, onSubmit: refresh})">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="personInfo.id"></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>       
</template>
<script>
  export default {
    name: 'TalkContactMainProfile',
    data: () => ({
      informations: [
        { title: 'Nome', id: 'first_name'},
        { title: 'CPF/CNPJ', id: 'cpf_cnpj' },
        { title: 'RG', id: 'rg' },
        { title: 'Nascimento', id: 'birthday_formatted' },
        // { title: 'Idade', id: 'ages' },
        { title: 'E-mail', id: 'email' },
        { title: 'Gênero', id: 'genre' },
        { title: 'Orientação sexual', id: 'sexual_orientation' },
        { title: 'Nome da mãe', id: 'mothers_name' },
        { title: 'Nome do pai', id: 'fathers_name' },
        { title: 'Nome do cônjuge', id: 'spouses_name' },
        { title: 'Nome do responsável', id: 'companions_name' },
        { title: 'Estado civil', id: 'marital_status' },
        { title: 'Profissão', id: 'profession' },
        { title: 'Escolaridade', id: 'education' },
        { title: 'Naturalidade', id: 'naturalness' },
        { title: 'Religião', id: 'religion' },
        { title: 'Grupo sanguíneo', id: 'blood_group' },
        { title: 'Site', id: 'site' },
        { title: 'Observações', id: 'observation_formatted', type: 'textarea' },
        { title: 'Descrições', id: 'description' },
        { title: 'Notas', id: 'notes' },
        { title: 'Local', id: 'locale' },
        { title: 'Fuso horário', id: 'timezone' },
        { title: 'Título', id: 'person_title' },
        { title: 'ID', id: 'id'},
        { title: 'Código externo', id: 'external_code' },
        { title: 'Código de integração', id: 'integration_code' },
        // { title: 'Criado em', id: 'created_at' },
        // { title: 'Atualizado em', id: 'updated_at' }
      ]
    }),
    computed: {
      isCustomer () {
        return this.contact.person_id &&
          this.contact.person &&
          this.contact.person.profiles.findIndex(p => p.name == 'customer') > -1
      },
      isProspect () {
        return this.contact.person_id &&
          this.contact.person &&
          this.contact.person.profiles.findIndex(p => p.name == 'prospect') > -1
      },
      hasBudgets () {
        return this.contact?.person?.active_budgets && this.contact?.person?.active_budgets?.length > 0
      },
      person () {
        const person = this.contact?.person?.person || this.contact?.person
        return person
      },
      personFormated () {
        const birthday_formatted = this.person?.birthday ? this.$moment(this.person?.birthday).format('DD/MM/YYYY') : null
        return {
          birthday_formatted: this.person?.birthday ? `${birthday_formatted} - ${this.person?.ages} anos` : null,
          observation_formatted: this.person?.observation ? `${this.person?.observation}`.replace(/\n/g, '<br>') : null,
          ...this.person
        }
      },
      personInfos () {
        const infos = this.person.infos
        return infos || []
      }
    },
    methods: {
      editPerson: function () {
        const profileName = this.person?.profiles[0]?.name
        if (profileName) {
          const person = this.person
          delete person.status
          this.$WiEditDialog({
            wiConfig: profileName,
            data: person,
            onSubmit: () => {
              this.refresh()
            },
            redirectOnSubmit: false
          })
        }
      }
    },
    props: ['contact', 'refresh']
  }
</script>
<style scoped>
  .talk-contact-budgets__list-tile {
    padding: 0 5px !important;
    color: black;
  }
  .talk-contact-budgets__list-tile >>> .v-list__tile {
    padding: 0 5px !important;
  }
</style>

