import { Model } from '@/default/model/Model'

export class TalkCategory extends Model {
  constructor () {
    super()
    this.list = true
    this.title = {
      list: 'Categorias',
      edit: {
        insert: 'Nova Categoria',
        update: 'Editar Categoria'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome da categoria',
      fields: ['id', 'name']
    }
    this.params = {
      limit: 10
    }
    this.icon = 'category'
    
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls2', 'offset-xl2']

    this.maxWidth = '370px'

    this.components.list.tableRow = () => import('@/modules/business/pages/Businesses/BusinessCategories')

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome da categoria'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome desta categoria.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Categoria',
      required: true
    }),
    subHeader2: this.fieldSubHeader({
      text: '(Opcional) Informe a cor e ícone.'
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria'
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor da Categoria'
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Observações adicionais.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Observações adicionais sobre esta categoria.'
    })
  }
}

export class TalkMessageTemplateCategory extends TalkCategory {
  constructor () {
    super()
    this.id = {
      id: 'cdf8059b',
      create: 'ffb26038',
      update: 'c59c7cd2',
      delete: 'ebbd93cf'
    }
    this.name = 'talk-message-template-category'
    this.apiUrl = 'talks/category'
    this.params['type_id'] = 3
    this.fieldsFixed = [
      {
        value: 'type_id',
        data: 3
      }
    ]
  }
}
