// Common
import Event from '@/modules/clinic/config/Common/Event'
import Item from '@/modules/clinic/config/Common/Item'

// Configurations
import Status from '@/modules/clinic/config/Config/Status'
import Category from '@/modules/clinic/config/Config/Category'

// Registers
import Patient from '@/modules/clinic/config/Register/Patient'
import ClinicRegisterCollaborator from '@/modules/clinic/config/Register/Collaborator'
import TaskClinic from '@/modules/clinic/config/Task/TaskClinic'
import { TaskClinicOpened, TaskClinicClosed, TaskClinicCanceled } from '@/modules/clinic/config/Task/TaskClinicAll'
import MedicalRecords from '@/modules/clinic/config/MedicalRecord/MedicalRecords'
import { Anamnesis, Prescription, ExamRequest, Attested, Evolution } from '@/modules/clinic/config/MedicalRecord/MedicalRecord'

export default {
  'anamnesis': new Anamnesis(),
  'prescription': new Prescription(),
  'exam-request': new ExamRequest(),
  'attested': new Attested(),
  'evolution': new Evolution(),
  'evolution-event': Event.evolution,
  'anamnesis-event': Event.anamnesis,
  'prescription-event': Event.prescription,
  'examRequest-event': Event.examRequest,
  'attested-event': Event.attested,
  'sale-item-product': Item.product.sale,
  'sale-item-service': Item.service.sale,

  'config-status-evolution': Status.evolution,
  'config-status-anamnesis': Status.anamnesis,
  'config-status-prescription': Status.prescription,
  'config-status-exam-request': Status.examRequest,
  'config-status-attested': Status.attested,
  'config-category-evolution': Category.evolution,
  'config-category-anamnesis': Category.anamnesis,
  'config-category-prescription': Category.prescription,
  'config-category-examRequest': Category.examRequest,
  'config-category-attested': Category.attested,

  'patient': Patient,
  'clinic-register-collaborator': ClinicRegisterCollaborator,
  'task-clinic': TaskClinic,
  'task-clinic-opened': new TaskClinicOpened(),
  'task-clinic-closed': new TaskClinicClosed(),
  'task-clinic-canceled': new TaskClinicCanceled(),
  'medical-records': MedicalRecords
}
