import { MovementAction } from './Movement'
import {
  PERMISSION_STOCK_PRODUCT_ENTRY,
  PERMISSION_STOCK_PRODUCT_ENTRY_CREATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_DELETE
} from '@/default/constants/permissions'

export class MovementEntry extends MovementAction {
  constructor () {
    super()

    this.urlDetails.name = 'product-entry'

    // Change Fields
    this.editStatus.apiUrl = 'stocks/config/status/product-entry'
    this.editCategory.apiUrl = 'stocks/config/category/product-entry'
  }

  name = 'product-entry';
  id = {
    id: PERMISSION_STOCK_PRODUCT_ENTRY,
    create: PERMISSION_STOCK_PRODUCT_ENTRY_CREATE,
    update: PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE,
    delete: PERMISSION_STOCK_PRODUCT_ENTRY_DELETE
  };
  title = {
    list: 'Entrada de Produtos',
    edit: {
      insert: 'Nova Entrada de Produtos',
      update: 'Editar Entrada de Produtos'
    }
  };
  apiUrl = 'stocks/product-entry';
  icon = 'group_add';

}
