import StockDashboard from '@/modules/stock/pages/Dashboard/StockDashboard'
import Business from '@/modules/shared/pages/Business/Business'
import DailyReportCollaborator from '@/modules/stock/pages/Reports/DailyReport/DailyReportCollaborator'
import DailyReportCustomer from '@/modules/stock/pages/Reports/DailyReport/DailyReportCustomer'
import DailyReportAll from '@/modules/stock/pages/Reports/DailyReport/DailyReportAll'
// import BusinessPrint from '@/modules/shared/pages/Business/BusinessPrint'
import {
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_ENTRY,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_REMOVAL,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER
} from '@/default/constants/permissions'

export default [
  {
    path: 'dashboard-stock',
    name: 'dashboard-stock',
    component: StockDashboard,
    meta: {
    id: 'b0ce8f81-78ff-4d79-a9f3-d7415343d2e5',
      auth: true,
      title: 'Dashboard de Estoque',
      icon: 'dashboard'
    }
  },
  {
    path: 'product-transfer-in/:id',
    name: 'product-transfer-in',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN,
      auth: true,
      title: 'Transferência para o estoque',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-transfer-in-collaborator/:id',
    name: 'product-transfer-in-collaborator',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR,
      auth: true,
      title: 'Transferência para o colaborador',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-transfer-in-customer/:id',
    name: 'product-transfer-in-customer',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER,
      auth: true,
      title: 'Transferência para o cliente',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-transfer-out/:id',
    name: 'product-transfer-out',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT,
      auth: true,
      title: 'Transferência do estoque',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-transfer-out-collaborator/:id',
    name: 'product-transfer-out-collaborator',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR,
      auth: true,
      title: 'Transferência do estoque do colaborador',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-transfer-out-customer/:id',
    name: 'product-transfer-out-customer',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER,
      auth: true,
      title: 'Transferência do cliente',
      icon: 'local_grocery_store'
    }
  },

  {
    path: 'product-entry/:id',
    name: 'product-entry',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_ENTRY,
      auth: true,
      title: 'Entrada de Produtos no Estoque',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-entry-collaborator/:id',
    name: 'product-entry-collaborator',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR,
      auth: true,
      title: 'Movimentação para o colaborador',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-entry-customer/:id',
    name: 'product-entry-customer',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER,
      auth: true,
      title: 'Movimentação para o cliente',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-removal/:id',
    name: 'product-removal',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_REMOVAL,
      auth: true,
      title: 'Retirada de Produtos do estoque',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-removal-collaborator/:id',
    name: 'product-removal-collaborator',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR,
      auth: true,
      title: 'Movimentação do estoque do colaborador',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'product-removal-customer/:id',
    name: 'product-removal-customer',
    component: Business,
    meta: {
      id: PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER,
      auth: true,
      title: 'Movimentação do cliente',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'collaborator-daily-report/:id',
    name: 'collaborator-daily-report',
    component: DailyReportCollaborator,
    meta: {
      auth: true,
      title: 'Relatório do dia'
    }
  },
  {
    path: 'customer-daily-report/:id',
    name: 'customer-daily-report',
    component: DailyReportCustomer,
    meta: {
      auth: true,
      title: 'Relatório do dia'
    }
  },
  {
    path: 'daily-report/:id',
    name: 'daily-report',
    component: DailyReportAll,
    meta: {
      auth: true,
      title: 'Relatório do dia'
    }
  }
]
