<template>
  <v-card nmi-width="350px" flat>
    <v-toolbar dense flat dark>
      <v-icon :color="event.type.color" v-text="event.type.icon">edit</v-icon> 
      <v-toolbar-title v-html="event.type.title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="editEvent()">
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn icon @click="$WiApiDelete({uri: 'tasks/task', id: event.id, callback: updateCalendar})">
        <v-icon>delete</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="wi-calendar-event__content">
      <v-list dense two-line class="tasks__date" style="padding-top: 0px; padding-bottom: 0px;">
        <v-list-tile v-if="event.description" class="tasks__list-tile">
          <v-list-tile-avatar>
            <v-icon :color="event.type.color" medium>info</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title> Descrição: </v-list-tile-sub-title>
            <v-list-tile-title>
              <strong>{{ event.description }}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider v-if="event.description"></v-divider>
        <v-list-tile v-if="event.person" class="tasks__list-tile">
          <v-list-tile-avatar>
            <v-icon :color="event.type.color" medium>people</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title> Cliente: </v-list-tile-sub-title>
            <v-list-tile-title>
              <strong>{{ event.person.name }}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider v-if="event.person"></v-divider>
        <v-list-tile v-if="event.date_formated" class="tasks__list-tile">
          <v-list-tile-avatar>
            <v-icon :color="event.type.color" medium>date_range</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              <span>Em </span>
              <strong>{{ event.date_formated }}</strong>
              <strong v-if="event.time_formated"> às {{ event.time_formated }}</strong>
            </v-list-tile-sub-title>
            <v-list-tile-sub-title v-if="event.date_end_formated">
              <span> até </span>
              <strong v-if="event.date_formated != event.date_end_formated">{{ event.date_end_formated }}</strong>
              <strong v-if="event.time_end_formated"> às {{ event.time_end_formated }}</strong>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider v-if="event.date_formated"></v-divider>
        <v-list-tile v-if="event.responsible" class="tasks__list-tile">
          <v-list-tile-avatar>
            <v-icon :color="event.type.color" medium>people</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title> Responsável: </v-list-tile-sub-title>
            <v-list-tile-title>
              <strong>{{ event.responsible.name }}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider v-if="event.responsible"></v-divider>
      </v-list>
      <div v-if="event.type && event.type.code > 299 && event.type.code < 399">
        <v-btn v-if="event.business_id" :style="{color: event.type.color}" outline small block @click="goToBusiness(event)">
          <v-icon v-text="event.type.icon"></v-icon>
          <span>Visualizar <span v-text="event.type.title"></span></span>
        </v-btn>
        <v-btn v-else :style="{color: event.type.color}" outline small block @click="createNewBusiness(event)">
          <v-icon v-text="event.type.icon"></v-icon>
          <span>Criar <span v-text="event.type.title"></span></span>
        </v-btn>
      </div>
      <div v-if="event.type && event.type.code > 399 && event.type.code < 499">
        <v-btn v-if="event.ticket_id" :style="{color: event.type.color}" outline small block @click="goToTicket(event)">
          <v-icon v-text="event.type.icon"></v-icon>
          <span>Visualizar <span v-text="event.type.title"></span></span>
        </v-btn>
        <v-btn v-else :style="{color: event.type.color}" outline small block @click="createNewTicket(event)">
          <v-icon v-text="event.type.icon"></v-icon>
          <span>Criar <span v-text="event.type.title"></span></span>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'WiCalendarEvent',
    data: () => ({}),
    computed: {
      currentDate () {
        return this.$store.state.WiCalendar.currentDate
      }
    },
    methods: {
      goToBusiness: function (task) {
        this.$router.push({
          name: task.type.name,
          params: {id: task.business_id}
        })
      },
      goToTicket: function (task) {
        this.$router.push({
          name: task.type.name,
          params: {id: task.ticket_id}
        })
      },
      createNewBusiness: function (task) {
        this.$WiEditDialog({
          wiConfig: task.type.name + '-open',
          data: {
            task_id: task.id,
            person_id: task.person_id,
            person: task.person,
            date_end: task.date,
            collaborator_id: task.responsible_id,
            collaborator: task.responsible
          }
        })
      },
      createNewTicket: function (task) {
        this.$WiEditDialog({
          wiConfig: 'chamados-todos',
          onSubmit: this.updateCalendar,
          data: {
            task_id: task.id,
            person_id: task.person_id,
            person: task.person,
            person_responsible_id: task.responsible_id,
            collaborator: task.responsible
          }
        })
      },
      updateCalendar: function () {
        this.$store.dispatch('WiCalendar/getContent', {currentDate: this.currentDate, action: 'change'})
      },
      editEvent: function () {
        var event = {}
        for (var key in this.event) {
          if (key === 'type') {
            event[key] = this.event[key].code
          } else {
            event[key] = this.event[key]
          }
        }
        this.$WiEditDialog({wiConfig: 'task', data: event, onSubmit: this.updateCalendar})
      }
    },
    props: ['event']
  }
</script>
<style scoped>
  .wi-calendar-event__content {
    padding: 5px !important;
  }
</style>