<template>
  <wi-view index="business" title="Prontuário" apiUrl="clinic/medical-record">
    <template slot="toolbarItensBefore" slot-scope="{ content, loading }">
      <v-btn v-if="content" :to="{ name: 'patient', params: { id: content.patient.id } }" color="white" class="hidden-xs-only hidden-sm-only" small outline :disabled="loading">
        <v-icon>person</v-icon>
        <span>Perfil do Paciente</span>
      </v-btn>
    </template>
    <template slot-scope="{ content, loading, refresh }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17">
              <PatientInfo :person="content.patient"></PatientInfo>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12 md7 lg7 style="padding-right: 10px;"> 
            <MedicalRecordAttendances :content="content" :refresh="refresh" :loading="loading"></MedicalRecordAttendances>
          </v-flex>
          <v-flex xs12 sm12 md5 lg5 style="padding-left: 10px;"> 
            <MedicalRecordFiles :content="content" :refresh="refresh" :loading="loading"></MedicalRecordFiles>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </wi-view>
</template>
<script>
  import PatientInfo from '@/modules/register/pages/Person/PersonInfo'
  import MedicalRecordAttendances from '@/modules/clinic/pages/MedicalRecord/MedicalRecordAttendances'
  import MedicalRecordFiles from '@/modules/clinic/pages/MedicalRecord/MedicalRecordFiles'
  export default {
    name: 'MedicalRecord',
    components: {
      PatientInfo,
      MedicalRecordAttendances,
      MedicalRecordFiles
    }
  }
</script>
