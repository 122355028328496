import { PERMISSION_CONTRACT } from '@/default/constants/permissions'

export default {
  id: PERMISSION_CONTRACT,
  icon: 'border_color',
  title: 'Gestão de Contratos',
  children: [
    {
      id: PERMISSION_CONTRACT,
      icon: 'select_all',
      title: 'Novos Contratos',
      route: {
        name: 'internet-list',
        params: {
          page: 'internet-contract-opened'
        }
      }
    },
    {
      id: PERMISSION_CONTRACT,
      icon: 'done_outline',
      title: 'Contratos Ativos',
      route: {
        name: 'internet-list',
        params: {
          page: 'internet-contract-closed'
        }
      }
    },
    {
      id: PERMISSION_CONTRACT,
      icon: 'block',
      title: 'Contratos Bloqueados',
      route: {
        name: 'internet-list',
        params: {
          page: 'internet-contract-blocked'
        }
      }
    },
    {
      id: PERMISSION_CONTRACT,
      icon: 'close',
      title: 'Contratos Encerrados',
      route: {
        name: 'internet-list',
        params: {
          page: 'internet-contract-canceled'
        }
      }
    }
  ]
}
