import {Products} from './Products'
import {
  PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE
} from '@/default/constants/permissions'

export class ProductEntry extends Products {
  constructor () {
    super()
  }

  id = PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE
  apiUrl = 'stocks/product-entry/item'

  fields = [
    this.subHeader,
    this.product,
    this.quantity,
    this.amountUnit,
    this.subHeaderObservation,
    this.observation
  ]
}

export class ProductEntryCustomer extends ProductEntry {
  constructor () {
    super()
  }

  id = PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE
}

export class ProductEntryCollaborator extends ProductEntry {
  constructor () {
    super()
  }

  id = PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE
}

export class ProductRemoval extends Products {
  constructor () {
    super()
    this.quantity.rules = form => ([
      v => !!v || 'Campo obrigatório',
      v => parseInt(v) > 0 || 'Quantidade inválida',
      v => parseInt(v) <= parseInt(form.quantityMax) || `Acima do estoque (${form.quantityMax})`
    ])
  }

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

  fields = [
    this.subHeader,
    this.productStock,
    this.quantity,
    this.amountUnit,
    this.subHeaderObservation,
    this.observation
  ]
}

export class ProductRemovalCustomer extends ProductRemoval {
  constructor () {
    super()
  }

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE
}

export class ProductRemovalCollaborator extends ProductRemoval {
  constructor () {
    super()
  }

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE
}

class ProductTransfer extends Products {
  constructor () {
    super()
    this.productStock.size = ['xs12', 'sm12', 'md7', 'lg7']
    this.productStock.showField = form => (form.stock_id != null)
    this.quantity.rules = form => ([
      v => !!v || 'Campo obrigatório',
      v => parseInt(v) > 0 || 'Quantidade inválida',
      v => parseInt(v) <= parseInt(form.quantityMax) || `Acima do estoque (${form.quantityMax})`
    ])
  }

  fields = [
    this.subHeader,
    this.stock,
    this.productStock,
    this.description,
    this.quantity,
    this.amountUnit,
    this.subHeaderObservation,
    this.observation
  ]
}

export class ProductTransferIn extends ProductTransfer {
  constructor () {
    super()
    this.stock.text = 'Estoque de onde o produto será retirado'
    this.productStock.filter = form => ({
      where: 'stock_id,' + form.stock_id
    })
  }

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

}

export class ProductTransferInCollaborator extends ProductTransferIn {

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

}

export class ProductTransferInCustomer extends ProductTransferIn {

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

}

export class ProductTransferOut extends ProductTransfer {
  constructor () {
    super()
    this.stock.text = 'Estoque para onde o produto irá'
    this.productStock.filter = form => ({
      where: 'stock_id,' + form.business.stock_id
    })
  }

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

}

export class ProductTransferOutCollaborator extends ProductTransferOut {

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

}

export class ProductTransferOutCustomer extends ProductTransferOut {

  id = PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE
  apiUrl = 'stocks/product-entry/item'

}
