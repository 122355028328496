export default {
  id: '081b9bc8',
  title: 'Alterar a Urgência do Chamado',
  apiUrl: 'service-desk/ticket',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Selecione a nova Urgência deste chamado.', type: 'subheader', edit: true},
    {value: 'chamado_urgencia_id', text: 'Nova Urgência', autocomplete: true, required: true, type: 'db-autocomplete', edit: true, apiUrl: 'chamado/urgencia', apiParams: {fields: 'id,nome_urgencia'}, show: 'nome_urgencia'}
  ],
  fieldsFixed: [
    {
      value: 'change_ticket_urgency', data: true
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
