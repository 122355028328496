const GoogleMapsUrlAddressText = 'https://www.google.com/maps/place/'
const GoogleMapsUrlAddressCoordinates = 'https://maps.google.com/?q='

export class OpenInGoogleMaps {
  static open (address) {
    if (this.cordenateIsValid(address)) {
      this.openAddressCoordinates(address)
    } else {
      this.openAddressText(address)
    }
  }

  static getAddressText (address) {
    const addressString = `${address.address}, ${address.number} - ${address.district}, ${address.city.name} - ${address.city.state.code}, ${address.zip_code}`
    return addressString
  }
  
  static getAddressCoordinates (address) {
    const addressCoordinates = `${address.latitude},${address.longitude}`
    return addressCoordinates
  }

  static cordenateIsValid (address) {
    return (address.latitude && address.latitude != '') && (address.longitude && address.longitude != '')
  }

  static openAddressText (address) {
    window.open(
      GoogleMapsUrlAddressText + `${this.getAddressText(address)}`,
      '_blank'
    ).focus()
  }
  
  static openAddressCoordinates (address) {
    window.open(
      GoogleMapsUrlAddressCoordinates + `${this.getAddressCoordinates(address)}`,
      '_blank'
    ).focus()
  }
}
