<template>
  <v-card v-if="grupo">
    <v-toolbar dense>
      <strong>Detalhes</strong>
      <v-spacer></v-spacer>
      <slot name="action"></slot>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style=" padding: 0;">
      <v-btn small outline style="width: 95%;" @click="openIndisponibilidades(grupo)">
        <v-icon color="red darken-2">arrow_downward</v-icon>
        <span style="padding-right: 5px;">Listar Indisponibilidades</span>
      </v-btn>
      <v-btn v-if="grupo.funcao_tipo == 2" small outline style="width: 95%;" @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: grupo.id}})">
        <v-icon color="blue darken-2">device_hub</v-icon>
        <span style="padding-right: 5px;">Abrir Topologia</span>
      </v-btn>
      <v-btn v-if="grupo.funcao_tipo == 1" small outline style="width: 95%;" @click="$router.push({name: 'grupo-mapa-grupos', params: {id: grupo.id}})">
        <v-icon color="blue darken-2">device_hub</v-icon>
        <span style="padding-right: 5px;">Abrir Topologia</span>
      </v-btn>
      <v-btn v-if="grupo.funcao_tipo == 1" small outline style="width: 95%;" @click="$router.push({name: 'google-maps-grupos', params: {id: grupo.id}})">
        <v-icon color="brown lighten-1">map</v-icon>
        <span style="padding-right: 5px;">Abrir no Google Maps</span>
      </v-btn>
      <v-list one-line style="padding-top: 0">
        <v-divider></v-divider>
        <template>
          <v-list-tile avatar ripple style="padding: 0px !important;">
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon color="blue darken-2">perm_identity</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>Nome: <strong>{{grupo.nome_grupo}}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </template>
        <template>
          <v-list-tile avatar ripple style="padding: 0px !important;">
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon>contact_support</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>Tipo: <strong>{{ grupo.nome_tipo }}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </template>
        <template>
          <v-list-tile avatar ripple>
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon color="yellow darken-2">star</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{grupo.tipo_grupo_pai}}: <strong>{{ grupo.grupo_pai }}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </template>
        <template>
          <v-list-tile v-if="grupo.funcao_tipo == 1" avatar ripple >
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon color="orange darken-2">device_hub</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>Sub-grupos: <strong>{{ grupo.sub_grupos }}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <template>
          <v-divider></v-divider>
          <v-list-tile avatar ripple>
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon color="green darken-2">dns</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>Disponíveis: <strong>{{ grupo.num_disp_up }}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </template>
        <template>
          <v-list-tile avatar ripple>
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon color="red darken-2">dns</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>Indisponíveis: <strong>{{ grupo.num_disp_down }}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </template>
        <template>
          <v-list-tile avatar ripple>
            <v-list-tile-avatar style="min-width: 0px !important;">
              <v-icon color="blue darken-2">dns</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>Dispositivos Ativos: <strong>{{ grupo.num_disp }}</strong></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'GrupoInfo',
    data () {
      return {}
    },
    methods: {
      openIndisponibilidades: function (grupo) {
        var query = {grupo_dados_id: grupo.id}
        if (grupo.num_disp_down > 0) {
          query['status_indi'] = 0
          query['status_disp'] = 0
          query['ativo_disp'] = 1
        }
        this.$router.push({name: 'indisponibilidades', query: query})
      }
    },
    props: ['grupo']
  }
</script>
<style scoped>
.v-list__tile {
  padding: 0px !important;
}
.v-list__tile--avatar {
  height: 40px;
}
</style>