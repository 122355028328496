export default {
  id: 'b41dcfec',
  title: 'Adicionar Códigos',
  icon: 'code',
  apiUrl: 'sales/sale/code-make',
  maxWidth: '700px',
  fields: [
    {
      text: 'Adicionar Códigos de Produto',
      type: 'subheader',
      edit: true
    },
    {
      value: 'codes',
      text: 'Códigos separados por vírgula',
      placeholder: 'Insira aqui os códigos separados por vírgula. Ex: 123,456,789',
      type: 'textarea',
      required: true,
      edit: true,
      rows: 5
    }
  ]
}
