export class NumberFilter {
  constructor (value) {
    this.value = value
    this.digits = 2
  }
  format () {
    if (this.value) {
      return this.getFormatedNumber(this.value)
    } else if (this.value === 0) {
      return this.getFormatedNumberZero()
    } else {
      return this.value
    }
  }
  getFormatedNumber (value) {
    if (this.NumberIsFloat(value)) {
      return this.getFormatedNumberFloat(value)
    } else {
      return this.getFormatedNumberInteger(value)
    }
  }
  getFormatedNumberFloat (value) {
    value = this.getValueCentsDigitsLimited(value)
    value = value.toLocaleString('pt-BR')
    if (value.toString().split(',')[1].length === 1) {
      value = value + '0'
    }
    return ' ' + value
  }
  getFormatedNumberInteger (value) {
    return ' ' + value.toLocaleString('pt-BR') + ',00'
  }
  getFormatedNumberZero () {
    return ' 0,00'
  }
  NumberIsFloat (value) {
    return ((value.toString().split('.').length - 1) > 0 && value.toString().split('.')[1].substring(0, this.digits) > 0)
  }
  getValueCentsDigitsLimited (value) {
    let valueArray = value.toString().split('.')
    return parseFloat(valueArray[0] + '.' + valueArray[1].substring(0, this.digits))
  }
}
