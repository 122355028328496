<template>
  <v-dialog v-model="dialog" persistent scrollable width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar color="transparent" dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="dialog && processId" style="min-height: 400px;">
        <WiView title="Detalhes do processo" :apiUrl="'processes/process/' + processId" apiParams="?with=stages;stages.results;stages.results.result_stage:id,type_id,order" :notGetID="true" :notShowToolbar="true">
          <template slot-scope="{ content, loading }">
            <ProcessStagesFlowcharts
              :loading="loading"
              :process="content"
              :executableNodes="stageIds"
            ></ProcessStagesFlowcharts>
          </template>
        </WiView>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ProcessStagesFlowcharts from '@/modules/service-desk/pages/Processs/ProcessStagesFlowcharts'
  export default {
    name: 'StageFlowchartDialog',
    computed: {
      ...mapGetters('ProcessExecution', ['stageIds']),
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    props: {
      value: {
        required: true
      },
      processId: {
        type: String,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      }
    },
    components: {
      ProcessStagesFlowcharts
    }
  }
</script>
