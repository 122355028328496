import i18n from '@/lang/i18n'
import router from '@/modules/register/router'
import config from '@/modules/register/config'
import menu from '@/modules/register/menu'

export default {
  id: 'bec249c8-3ddc-4113-8dd5-e558fd30baa8',
  name: 'register',
  title: i18n.t('modules.registers.title'),
  icon: 'wises-technology-module-profile.png',
  iconSystem: 'wise-network-system-profile.png',
  color: '#546E7A',
  description: i18n.t('modules.registers.description'),
  menu,
  router,
  config
}
