import { BusinessCategory } from '@/modules/business/config/BusinessCategory'

export class DiagnosticCategory extends BusinessCategory {
  constructor() {
    super()
    this.id = '4f1be58b'
    this.title.list = 'Categorias de Diagnóstico'
    this.apiUrl = 'networks/config/category/test-management-vtal'
  }
}
