<template>
  <div>
    <div v-if="showInList">
      <v-list-tile v-if="isNetwork" avatar>
        <v-list-tile-avatar>
          <v-avatar>
            <img src="static/icons/monitor/network_96.png" alt="Visualizaram">
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Redes</v-list-tile-sub-title>
          <v-list-tile-title style="font-size: 20px;">{{networkCount}}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon flat @click="$WiListDialog({wiConfig: 'ticket-network', button: {text: 'Adicionar Dispositivos', action: () => { dialogNetwork = true }}, filter: {where: 'chamado_dados_id,' + dados.content.id, order: 'nivel_tipo,asc'}})">
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <span>Ver redes anexadas a este chamado</span>
          </v-tooltip>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile v-else avatar>
        <v-list-tile-avatar>
          <v-avatar>
            <img src="static/icons/monitor/wifi_router_96.png" alt="Dispositivos">
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Dispositivos</v-list-tile-sub-title>
          <v-list-tile-title style="font-size: 20px;">{{networkCount}}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon flat @click="$WiListDialog({wiConfig: 'ticket-device', button: {text: 'Adicionar Dispositivos', action: () => { dialogNetwork = true }}, filter: {where: 'chamado_dados_id,' + dados.content.id}})">
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <span>Ver redes anexadas a este chamado</span>
          </v-tooltip>
        </v-list-tile-action>
      </v-list-tile>
    </div>
    <div v-else>
      <v-toolbar dense color="primary">
        <v-btn v-prms="{'id': '1a825af1'}" small outline color="white" @click="dialogNetwork = true">
          <v-icon>add</v-icon>
          <span>Adicionar dispositivos</span>
        </v-btn>
        <div class="hidden-xs-only hidden-sm-only" style="padding-left: 15px; font-size: 20px; color: white;">
          <div v-if="dados.content.dispositivos">
            <strong>Dispositivos:</strong>
            <span>{{dados.content.dispositivos.length}}</span>
          </div>
        </div>
        <div class="hidden-xs-only hidden-sm-only" style="padding-left: 15px; font-size: 20px; color: white;">
          <div v-if="dados.content.grupos">
            <strong>Redes:</strong>
            <span>{{dados.content.grupos.length}}</span>
          </div>
        </div>
      </v-toolbar>
      <div style="width: 100%; text-align: left; padding: 10px 10px 10px 10px;">
        <span style="color: gray;">Redes de Dispositivos anexados:</span><br>
      </div>
      <grupos-real-time :ticket="dados.content" :grupos="selectGroups(dados.content.grupos, 2)" v-on:action="clickGroup"></grupos-real-time>
      <v-divider></v-divider>
      <div style="width: 100%; text-align: left; padding: 10px 10px 10px 10px;">
        <span style="color: gray;">Redes também envolvidos:</span><br>
      </div>
      <grupos-real-time :ticket="dados.content" :grupos="selectGroups(dados.content.grupos, 1)" v-on:action="clickGroupComp"></grupos-real-time>
      <v-dialog v-model="dialog" scrollable max-width="80%">
        <v-card>
          <v-toolbar dense>
            <strong>Dispositivos de  {{ grupo_selected.nome_grupo }}  anexados a este chamado</strong>
            <v-spacer></v-spacer>
            <v-btn @click="$WiEditDialog({wiConfig: 'order-master-os', data: {person_id: dados.content.person_id, person: {id: dados.content.person_id, name: dados.content.nome_pessoa, first_name: dados.content.nome_pessoa},ticket_id: dados.content.id, network_id: grupo_selected.grupo_dados_id, onSubmit: dados.refresh}})" color="blue lighten-1" outline ripple class="hidden-xs-only hidden-sm-only">
              <v-icon color="blue lighten-1">phonelink_setup</v-icon>
              <span>Abrir Ordem de Serviço</span>
            </v-btn>
            <v-btn @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: grupo_selected.grupo_dados_id}})" color="blue lighten-1" outline ripple class="hidden-xs-only hidden-sm-only">
              <v-icon color="blue lighten-1">dns</v-icon>
              <span>Abrir Topologia</span>
            </v-btn>
            <v-btn @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: grupo_selected.grupo_dados_id}})" icon ripple class="hidden-md-only hidden-lg-only hidden-xl-only">
              <v-icon color="blue lighten-1">dns</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text style="height: 300px; padding: 0; margin: 0;">
            <disp-real-time :dispositivos="selectDisp(dados.content.dispositivos)"></disp-real-time>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" flat @click="dialog = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="grupoInfo" scrollable max-width="400px">
        <grupo-info :grupo="grupoDetalhes">
          <span slot="action">
            <v-btn icon flat @click="grupoInfo = false"><v-icon>close</v-icon></v-btn>
          </span>
        </grupo-info>
      </v-dialog>
    </div> 
    <v-dialog v-model="dialogNetwork" scrollable max-width="80%">
      <v-card>
        <v-toolbar dense persistent scrollable max-width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
          <strong>Selecione uma rede para adicionar.</strong>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogNetwork = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="padding: 0; margin: 0;" class="pb-5">
          <GruposTopologia
            v-if="dialogNetwork"
            :select-devices-to-network-calback="selectDevicesOrNetwork"
          ></GruposTopologia>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>    
</template>

<script>
  import GruposTopologia from '@/modules/network/pages/Grupo/GruposTopologia'
  import GruposRealTime from '@/modules/network/components/GruposRealTime'
  import DispRealTime from '@/modules/network/components/DispRealTime'
  import GrupoInfo from '@/modules/network/pages/Grupo/GrupoInfo'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'ChamadoNetwork',
    data () {
      return {
        exibir: 'dispositivos',
        dialog: false,
        grupoInfo: false,
        dialogNetwork: false,
        dispositivos: [],
        grupo_selected: {},
        grupoDetalhes: {}
      }
    },
    methods: {
      clickGroup: function (group) {
        this.grupo_selected = group
        this.dialog = true
      },
      clickGroupComp: function (group) {
        this.grupoDetalhes = group
        this.grupoInfo = true
      },
      selectGroups: function (grupos, tipo) {
        return grupos.filter((grupo) => {
          if (grupo.funcao_tipo === tipo) {
            return grupo
          }
        })
      },
      selectDisp: function (disps) {
        return disps.filter((disp) => {
          if (disp.grupo_dados_id === this.grupo_selected.grupo_dados_id) {
            return disp.device
          }
        })
      },
      selectDevicesOrNetwork: function (selected) {
        if (Array.isArray(selected) && selected.length > 0) {
          this.addDevicesToTicket(selected)
        } else if (selected?.network_id && selected?.count_devices) {
          this.addAllDevicesToTicket(selected)
        }
      },
      addAllDevicesToTicket: function ({ network_id, count_devices }) {
        this.sendDevicesToServer({
          ticket_id: this.dados.content.id,
          network_id,
          devices: []
        }, count_devices)
      },
      addDevicesToTicket: function (devices) {
        this.sendDevicesToServer(this.makeSendDevicesToTicket(this.dados.content.id, devices), devices.length)
      },
      sendDevicesToServer: function (devicesOrNetwork, devicesCount) {
        this.$swal({
          title: `Deseja realmente vincular ${devicesCount} dispositivo(s) a este chamado?`,
          text: 'Esta ação só poderá ser desfeita manualmente!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Vincular!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            callApi.post({
              uri: 'service-desk/ticket/device-and-network',
              data: devicesOrNetwork,
              msgLoad: {title: 'Vinculando, por favor aguarde.'},
              sucess: (data) => {
                console.log(data)
                // this.dados.refresh()
              },
              msgSucess: {
                title: 'Dispositivos vinculados com sucesso!'
              },
              error: (err) => {
                console.log(err)
              },
              msgError: true
            })
          }
        })
      },
      makeSendDevicesToTicket: function (ticketId, devices) {
        var dataToSend = {ticket_id: ticketId, devices: []}
        if (Array.isArray(devices)) {
          devices.filter((device) => {
            dataToSend.devices.push(device.id)
          })
        } else {
          dataToSend.devices.push(devices.id)
        }
        return dataToSend
      }
    },
    components: {
      GruposTopologia,
      GruposRealTime,
      DispRealTime,
      GrupoInfo
    },
    props: ['dados', 'showInList', 'networkCount', 'isNetwork']
  }
</script>