import {Model} from '@/default/model/Model'

export class BusinessPerson extends Model {
  constructor () {
    super()
    this.apiUrl = ''
    this.icon = 'people'
    this.search = {
      placeholder: 'Pesquise pelo nome do Colaborador ou Cliente',
      fields: ['id', 'person.name', 'person.first_name']
    }
    this.components = {
      list: {
        tableRow: () => import('@/modules/shared/pages/Business/Persons/BusinessPersons'),
        tableExpand: () => import('@/modules/shared/pages/Business/Persons/BusinessPersonsExpand')
      }
    }
    this.setFields([
      this.fieldSubHeader({
        text: ''
      }),
      this.fieldSelect({
        value: 'person_type',
        text: 'Vincular um(a)...',
        options: [
          {value: 'collaborator', text: 'Colaborador'},
          {value: 'customer', text: 'Cliente'}
        ],
        size: (form) => {
          return !form.person_type ? ['xs12', 'sm12', 'md12', 'lg12'] : ['xs12', 'sm12', 'md3', 'lg3']
        }
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Colaborador',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'collaborator',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: (form) => { return form.person_type === 'collaborator' }
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Cliente',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: (form) => { return form.person_type === 'customer' }
      }),
      this.fieldSelect({
        value: 'has_commission',
        text: 'Recebe comissão',
        options: [
          {value: true, text: 'Sim'},
          {value: false, text: 'Não'}
        ],
        size: (form) => {
          return form.has_commission ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md4', 'lg4']
        },
        showField: (form) => { return form.person_type }
      }),
      this.fieldSelect({
        value: 'type',
        text: 'Tipo de Comissão',
        required: true,
        options: [
          {value: 1, text: 'Porcentagem'},
          {value: 2, text: 'Valor fixo'}
          // {value: 3, text: 'Por tempo'}
        ],
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission }
      }),
      this.fieldMoney({
        value: 'amount',
        text: 'Valor da comissão',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type === 2 }
      }),
      this.fieldNumber({
        value: 'percentage',
        text: 'Porcentagem',
        min: 1,
        max: 100,
        step: '.01',
        prefix: '%',
        suffix: '%',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type === 1 }
      }),
      this.fieldTime({
        value: 'time',
        text: 'Tempo',
        size: ['xs12', 'sm4', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type === 3 }
      }),
      this.fieldMoney({
        value: 'amount_per_time',
        text: 'Valor por hora',
        size: ['xs12', 'sm4', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type === 3 }
      }),
      this.fieldSelect({
        value: 'status',
        text: 'Status da comissão',
        options: [
          {value: 1, text: 'Ativo'},
          {value: 2, text: 'Inativo'}
        ],
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type }
      }),
      this.fieldSelect({
        value: 'parceled',
        text: 'É parcelado?',
        options: [
          {value: 0, text: 'Não'},
          // {value: 1, text: 'Sim'},
          {value: 2, text: 'Sim, vinculado '}
        ],
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: (form) => { return form.has_commission && form.type }
      }),
      this.fieldText({
        value: 'description',
        text: 'Descrição da comissão',
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        showField: (form) => { return form.has_commission && form.type }
      }),
      this.fieldDate({
        value: 'date_validity',
        text: 'Data de Pagamento',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type && form.parceled === 0 }
      }),
      this.fieldDate({
        value: 'date_validity',
        text: 'Data de Pagamento',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: (form) => { return form.has_commission && form.type && form.parceled === 1 }
      }),
      this.fieldSelectApi({
        value: 'finance_form_payment_id',
        text: 'Forma de Pagamento',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        api: {
          url: 'finance/form-payment',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name',
        showField: (form) => { return form.has_commission && form.type && form.parceled < 2 }
      }),
      this.fieldSelectApi({
        value: 'finance_account_id',
        text: 'Conta Financeira',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        api: {
          url: 'finance/account',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name',
        showField: (form) => { return form.has_commission && form.type && form.parceled < 2 }
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações',
        placeholder: 'Ex: Está vinculado por...'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'person.name',
        text: 'Nome do Colaborador/Cliente'
      }),
      this.fieldList({
        value: 'has_commission',
        text: 'Comissão?'
      }),
      this.fieldList({
        value: 'type',
        text: 'Tipo'
      }),
      this.fieldList({
        value: 'amount',
        text: 'Valor'
      }),
      this.fieldList({
        value: 'percentage',
        text: 'Porcentagem'
      }),
      this.fieldList({
        value: 'parceled',
        text: 'Parcelado?'
      })
    ])
  }
}
