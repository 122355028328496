import Register from '@/modules/register'
import ServiceDesk from '@/modules/service-desk'
import Talk from '@/modules/talk'
import Sites from '@/modules/sites'
import Finance from '@/modules/finance'
import Juridical from '@/modules/juridical'
import Projects from '@/modules/projects'

export default {
  name: 'Wise Juridic',
  nameHtml: 'Wise <strong>Juridic</strong>',
  icon: 'wise_control.png',
  version: '5.2.15',
  lastUpdate: '12/02/2020',
  color: '#367da6',
  type: 'web', // web or mobile
  mobile_link: 'https://play.google.com/store/apps/details?id=connectview.insights.com',
  modules: [
    Juridical,
    Finance,
    Talk,
    Sites,
    ServiceDesk,
    Register,
    Projects
  ]
}
