<template>
  <div>
    <dbsearch v-if="load" :store="getStore">
      <span slot="title">
        {{config.title}}
      </span>
      <div class="hidden-xs-only hidden-sm-only" slot="button" v-prms="{'id': config.id}" v-if="(!config.config || config.config.new)">
        <v-btn color="white" style="color: black" @click="openModal({})" >Adicionar Novo</v-btn>
      </div>
      <div class="hidden-md-only hidden-lg-only hidden-xl-only" slot="button-mobile" v-prms="{'id': config.id}" v-if="(!config.config || config.config.new)">
        <v-btn @click="openModal({})" color="green" class="white--text" fab fixed bottom right>
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <div slot="content">
        <dbdatatable :store="getStore" :edit="openModal" :delete="deleteAction" :detail="detailAction || false"></dbdatatable>
      </div>
    </dbsearch>
    <editmodal v-if="editModal" :store="getStore" :add="true" :setform="form" :action="editModal" v-on:action="closeModal"></editmodal>
  </div>
</template>
<script>
  import DBDataTable from '@/default/components/DBDataTable'
  import DBSearch from '@/default/components/DBSearch'
  import EditModal from '@/default/components/Modal/EditModal'
  import {Api} from '@/default/service/Api'

  export default {
    name: 'List',
    data () {
      return {
        load: false,
        getStore: this.store ? this.store : this.$route.params.page,
        editModal: false,
        form: {},
        params: {
          limit: 5,
          order: 'id,desc'
        }
      }
    },
    watch: {
      '$route': 'loadList'
    },
    computed: {
      config () {
        const page = this.$store.state.pages[this.getStore] || false
        if (!page) {
          // eslint-disable-next-line
          this.$router.push({ name: 'main' })
        } else if (!page.list) {
          // eslint-disable-next-line
          this.$router.push({ name: 'main' })
        } else {
          // eslint-disable-next-line
          this.load = true
        }
        return page || {}
      },
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    mounted: function () {
      const page = this.$store.state.pages[this.getStore] || false
      if (!page) {
        this.$router.push({ name: 'main' })
      } else if (!page.list) {
        this.$router.push({ name: 'main' })
      } else {
        this.load = true
      }
    },
    methods: {
      openModal: function (form) {
        if (form.id) {
          for (var i in form) {
            this.form[i] = form[i]
          }
        } else {
          this.form = form
        }
        this.editModal = true
      },
      closeModal: function (save) {
        if (save) {
          this.$store.dispatch('getArray', {name: this.getStore, params: this.params})
        }
        this.editModal = false
        this.listModal = false
      },
      loadList: function (route) {
        if (route && route.params) {
          this.getStore = route.params.page
        }
        const page = this.$store.state.pages[this.getStore] || false
        if (!page) {
          this.$router.push({ name: 'main' })
        } else if (!page.list) {
          this.$router.push({ name: 'main' })
        } else {
          this.load = true
        }
      },
      detailAction: function (row) {
        if (this.config && this.config.urlDetails) {
          if (typeof (this.config.urlDetails) === 'object') {
            var params = this.config.urlDetails.params
            params['id'] = row.id
            this.$router.push({ name: this.config.urlDetails.name, params: params })
          } else {
            this.$router.push({ name: this.config.urlDetails, params: { id: row.id } })
          }
        }
      },
      deleteAction: function (row) {
        this.$swal({
          title: 'Deseja realmente apagar?',
          text: 'Essa ação não poderá ser desfeita!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Apagar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.$swal({
              title: 'Apagando...',
              text: 'Aguarde até que a ação seja finalizada.',
              onOpen: () => { this.$swal.showLoading() }
            })
            var uri = this.config.apiUrlEdit || this.config.apiUrl
            Api.delete({url: this.url + uri + '/' + row.id})
            .then(() => {
              this.$store.dispatch('getArray', {name: this.getStore, params: this.params})
              this.$swal({
                type: 'success',
                title: 'Tudo certo!',
                text: 'Apagado com sucesso.'
              })
            })
            .catch((error) => {
              console.log(error)
              this.$swal({
                type: 'error',
                title: 'Oops... Algo deu errado!',
                text: 'Tente novamente ou contacte o suporte.'
              })
            })
          }
        })
      }
    },
    components: {
      dbdatatable: DBDataTable,
      dbsearch: DBSearch,
      editmodal: EditModal
    }
  }
</script>

<style>
.card--flex-toolbar {
  margin-top: -56px;
}
</style>