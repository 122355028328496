import router from '@/modules/graduations/routes'
import config from '@/modules/graduations/config'
import menu from '@/modules/graduations/menu'

export default {
  id: '0bae9ce3-400f-8818-94a0-15d1b3bdf088',
  name: 'graduations',
  title: 'Formaturas',
  description: 'Gestão de Projetos de Formaturas',
  icon: 'wises-technology-module-graduations.png',
  iconSystem: 'wises-technology-system-graduations.png',
  iconProfile: 'profile_service_desk.png',
  color: '#B0944B',
  menu,
  router,
  config
}
