<template>
  <v-container class="form-reply-list">
    <div v-if="reply && reply.id">
      <FormReplyListTitle
        v-if="showTitle"
        :form="form"
        :reply="reply"
        :replyEditor="replyEditor"
        @onSubmit="onSubmit"
      ></FormReplyListTitle>
      <FormReplyListHeader
        v-if="showHeader"
        :reply="reply"
      ></FormReplyListHeader>
      <FormReplyListReplies
        v-if="reply"
        :reply="reply"
        :replyEditor="replyEditor"
      ></FormReplyListReplies>
    </div>
    <FormReplyListNotReply
      v-else
      :form="form"
      :reply="reply"
      :replyEditor="replyEditor"
      @onSubmit="onSubmit"
    ></FormReplyListNotReply>
  </v-container>
</template>
<script>
  import FormReplyListTitle from './FormReplyListTitle'
  import FormReplyListHeader from './FormReplyListHeader'
  import FormReplyListReplies from './FormReplyListReplies'
  import FormReplyListNotReply from './FormReplyListNotReply'
  export default {
    name: 'FormReplyList',
    methods: {
      onSubmit: function (response) {
        this.$emit('onSubmit', response)
      }
    },
    props: {
      form: {
        type: Object,
        required: true,
        default: () => ({})
      },
      reply: {
        type: Object,
        required: true,
        default: () => ({})
      },
      replyEditor: {
        type: Object,
        default: () => ({})
      },
      showTitle: {
        type: Boolean,
        default: true
      },
      showHeader: {
        type: Boolean,
        default: true
      },
      showReplies: {
        type: Boolean,
        default: true
      }
    },
    components: {
      FormReplyListTitle,
      FormReplyListHeader,
      FormReplyListReplies,
      FormReplyListNotReply
    }
  }
</script>
<style scoped>
  .form-reply-list {
    padding: 0 !important;
  }
  .form-reply-list__title .v-card__title {
    text-align: center !important;
  }
</style>