import { Model } from '@/default/model/Model'
import {
  PERMISSION_REGISTER_IMPORT_SERVICES_FROM_XLSX
} from '@/default/constants/permissions'

export class ImportServicesExcel extends Model {
  constructor () {
    super()
    this.id = PERMISSION_REGISTER_IMPORT_SERVICES_FROM_XLSX
    this.list = true
    this.title = 'Importar Serviços do Excel'
    this.icon = 'upload'
    this.apiUrl = 'register/import/service/excel'
    this.maxWidth = '420px'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = [
    this.fieldSubHeader({
      text: '1 - Faça download da planilha modelo<br>e importe para o sistema.',
      style: { height: '50px !important', color: 'black' }
    }),
    this.fieldFileDownload({
      fileUrl: '/static/planilhas/ImportarServicos.xlsx',
      text: 'Baixar Modelo de Planilha',
      size: ['xs12', 'sm12', 'md12', 'lg12', 'xl12']
    }),
    this.fieldSubHeader({
      text: '2 - Faça upload da planilha preenchida<br>(arquivo xlsx) com os serviços.',
      style: { height: '50px !important', color: 'black' }
    }),
    this.fieldWiFileDialog({
      value: 'file_id',
      text: 'Arquivo XLSX',
      placeholder: 'Selecione a planilha',
      required: true,
      data: {
        is_public: false
      },
      filter: {
        is_public: false
      }
    })
  ]
}
