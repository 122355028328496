var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wi-view',{attrs:{"index":"business","title":"Prontuário","apiUrl":"clinic/medical-record"},scopedSlots:_vm._u([{key:"toolbarItensBefore",fn:function(ref){
var content = ref.content;
var loading = ref.loading;
return [(content)?_c('v-btn',{staticClass:"hidden-xs-only hidden-sm-only",attrs:{"to":{ name: 'patient', params: { id: content.patient.id } },"color":"white","small":"","outline":"","disabled":loading}},[_c('v-icon',[_vm._v("person")]),_c('span',[_vm._v("Perfil do Paciente")])],1):_vm._e()]}},{key:"default",fn:function(ref){
var content = ref.content;
var loading = ref.loading;
var refresh = ref.refresh;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-card',{staticClass:"elevation-17"},[_c('PatientInfo',{attrs:{"person":content.patient}})],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding-right":"10px"},attrs:{"xs12":"","sm12":"","md7":"","lg7":""}},[_c('MedicalRecordAttendances',{attrs:{"content":content,"refresh":refresh,"loading":loading}})],1),_c('v-flex',{staticStyle:{"padding-left":"10px"},attrs:{"xs12":"","sm12":"","md5":"","lg5":""}},[_c('MedicalRecordFiles',{attrs:{"content":content,"refresh":refresh,"loading":loading}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }