<template>
  <v-list subheader two-line style="padding: 0 5px 0 0;">
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/system_task_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Processos Ativos:
        </v-list-tile-title>
        <v-list-tile-sub-title>Monitorando dispositivos.</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{information.tread_up || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/system_task_down_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Processos Inativos:
        </v-list-tile-title>
        <v-list-tile-sub-title>Interrompidos ou desativados.</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{information.tread_down || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/numbered_list_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Fila de envio:
        </v-list-tile-title>
        <v-list-tile-sub-title>Enviando eventos para o servidor...</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{information.queue_send || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/velocimetro_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          CPU em uso:
        </v-list-tile-title>
        <v-list-tile-sub-title>% de processamento.</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 20px;">{{information.cpu_load || '---'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>
<script>
  export default {
    name: 'NetworkServerInfoColumn2',
    data () {
      return {}
    },
    methods: {},
    props: {
      data: {
        type: Object
      },
      information: {
        type: Object
      },
      status: {
        type: String
      }
    }
  }
</script>