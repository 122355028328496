import PatientMedicalRecord from '@/modules/clinic/config/Register/Patient?url=MedicalRecord'

PatientMedicalRecord.id = 'd897f3f3'
PatientMedicalRecord.title = {
  list: 'Prontuários',
  edit: {
    insert: 'Novo paciente',
    update: 'Editar paciente'
  }
}

PatientMedicalRecord.icon = 'medical_services'
PatientMedicalRecord.urlDetails.name = 'medical-record'
PatientMedicalRecord.components.list.tableRow = () => import('@/modules/clinic/pages/Patients/Patients')

PatientMedicalRecord.fields[1].text = 'Nome do paciente'
PatientMedicalRecord.fields[2].value = 'birthday'
PatientMedicalRecord.fields[2].text = 'Data de Nascimento'
PatientMedicalRecord.fields[3].text = 'CPF/RG'

export default PatientMedicalRecord
