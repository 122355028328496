<template>
  <DbAutocomplete
    :apiUrl="typeof apiUrl == 'function' ? apiUrl(filters) : apiUrl"
    :apiParams="typeof apiParams == 'function' ? apiParams(filters) : apiParams"
    :formField="field" 
    :form="filters" 
    :show="show"
    :label="text"
    autocomplete
    :multiple="multiple"
    :placeholder="placeholder"
    :object="returnObject"
    :icon="icon"
    :color="color"
    :clearable="clearable || true"
  ></DbAutocomplete>
</template>
<script>
  import DbAutocomplete from '@/default/components/DBAutocomplete'
  export default {
    name: 'FilterFieldSelectApi',
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    props: {
      value: {
        required: true
      },
      filters: {
        required: true
      },
      field: {
        required: true
      },
      show: {
        required: true
      },
      apiUrl: {
        required: true
      },
      apiParams: {
        required: true
      },
      prependIcon: {
        default: null
      },
      appendIcon: {
        default: null
      },
      icon: {
        default: 'icon'
      },
      color: {
        default: 'color'
      },
      placeholder: {
        default: 'Insira aqui...'
      },
      text: {
        required: true
      },
      multiple: {
        default: false
      },
      clearable: {
        default: false
      },
      returnObject: {
        default: false
      },
    },
    components: {
      DbAutocomplete
    }
  }
</script>