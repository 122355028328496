<template>
  <div>
    <v-container fluid grid-list-md style="padding-top: 8px;">
      <v-layout row wrap>
        <v-flex d-flex xs6 sm4 md3 lg2 v-for="(disp, dispKey) in dispositivos" :key="dispKey">
          <div @click="$emit('action', disp)" color="transparent" :style="{'border-color': style.dark ? '#282828' : '#e2e2e2'}" style="color: white; border-style: dotted; border-width: 1px; border-radius: 12px 12px 0 0;">
            <img :src="'static/network/disp/' + disp.equipamento_fabricante_id + disp.status.cod_status.replace('#', '-') + '.png'" style="width: 100%;" alt="">
            <p class="text-xs-center" :style="{color: style.dark ? 'white' : 'black'}" style="font-size: 12px; padding: 0px 5px 0 5px; margin-bottom: 0; word-wrap: break-word !important;">
              {{disp.nome_disp}}
            </p>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
  export default {
    name: 'DispRealTime',
    data () {
      return {
        background: 'static/background/background-gradiente-1.png'
      }
    },
    methods: {},
    computed: {
      style () {
        return this.$store.state.style || {}
      }
    },
    components: {},
    props: ['dispositivos']
  }
</script>