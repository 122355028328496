import Vue from 'vue'
import App from './App.vue'
import 'emoji-picker-element'
import i18n from './lang/i18n'
import store from './default/store'
import router from './default/router'
import mixins from './default/mixins'
import filters from './default/filters'
import imports from './default/imports'
import VueAnalytics from 'vue-analytics'
import directives from './default/directives'
import components from './default/components'
import prototypes from './default/prototypes'
// import Sentry from './default/plugins/Sentry'

Vue.use(VueAnalytics, {
  id: 'UA-185476062-1',
  router: router
})

Vue.config.productionTip = false

/* eslint-disable no-new */
const app = new Vue({
  render: h => h(App),
  i18n,
  imports,
  store,
  router,
  directives,
  filters,
  components,
  prototypes,
  mixins
}).$mount('#app')

document.addEventListener('deviceready', app.init)
