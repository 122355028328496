<template>
  <div>
    <slot
      v-if="$slots.toolbar"
      name="toolbar"
      :data="data"
      :config="config"
      :refresh="getData"
      :loading="loading"
      :nextPage="nextPage"
      :addNewItem="addNewItem"
      :hasNextPage="hasNextPage"
    ></slot>
    <WiViewListToolbar
      v-if="toolbar"
      :data="data"
      :config="config"
      @onRefresh="onRefresh"
      @onFilter="onFilter"
      @onClose="onClose"
      @onAdd="addNewItem"
      :nextPage="nextPage"
      :hasNextPage="hasNextPage"
      :loadingPaginate="loadingPaginate"
      :wnConfig="wnConfig"
      :params="serviceParams"
      v-bind="{
        ...toolbar
      }"
    >
      <template v-slot:actionsBefore>
        <slot
          name="toolbarActionsBefore"
          :data="data"
          :config="config"
          :refresh="getData"
          :loading="loading"
          :nextPage="nextPage"
          :hasNextPage="hasNextPage"
        ></slot> 
      </template>
      <template v-slot:actionsAfter>
        <slot
          name="toolbarActionsAfter"
          :data="data"
          :config="config"
          :refresh="getData"
          :loading="loading"
          :nextPage="nextPage"
          :hasNextPage="hasNextPage"
        ></slot> 
      </template>
    </WiViewListToolbar>
    <v-card-text class="wi-view__content">
      <WiLoading :loading="loading" :message="loadingConfig.message">
        <slot
          v-if="firstLoad"
          :data="data"
          :config="config"
          :refresh="getData"
          :loading="loading"
          :nextPage="nextPage"
          :addNewItem="addNewItem"
          :hasNextPage="hasNextPage"
          :loadingPaginate="loadingPaginate"
        ></slot>
        <WiViewListError
          v-if="error"
          @on-refresh="onRefresh"
        ></WiViewListError>
      </WiLoading>
    </v-card-text>
  </div>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import WiViewListError from './components/WiViewListError'
  import WiViewListToolbar from './components/WiViewListToolbar'
  import WiLoading from '@/default/component/WiLoading/WiLoading'
  export default {
    name: 'WNViewList',
    data () {
      return {
        page: null,
        data: [],
        firstLoad: false,
        config: {},
        filters: {},
        error: false,
        loading: false,
        subscriber: null,
        loadingPaginate: false
      }
    },
    computed: {
      wnConfig () {
        var storeConfig = this.$store.state.pages[this.wiConfig] || {}
        return storeConfig
      },
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      formatedServiceParams () {
        return {
          ...this.serviceParams,
          page: this.page
        }
      },
      hasNextPage () {
        return this.config && this.config.last_page > this.config.current_page
      }
    },
    methods: {
      getData: async function () {
        this.page = 1
        this.loading = true
        this.onListing()
        const urlParams = new URLSearchParams(this.serviceParams);
        let params = Object.fromEntries(urlParams);
        params = {
          ...this.wnConfig.params,
          ...this.filters,
          ...params
        }
        callApi.get({
          uri: this.wnConfig.apiUrl,
          params,
          sucess: (data) => {
            this.makeSuccess(data)
          },
          error: (error) => {
            this.makeError(error)
          }
        })
      },
      nextPage: function () {
        if (this.config && this.config.current_page) {
          this.page = this.config.current_page + 1
          this.paginate()
        }
      },
      paginate: async function () {
        this.loadingPaginate = true
        this.onListing()
        const urlParams = new URLSearchParams(this.formatedServiceParams);
        let params = Object.fromEntries(urlParams);
        params = {
          ...this.wnConfig.params,
          ...params
        }
        callApi.get({
          uri: this.wnConfig.apiUrl,
          params,
          sucess: (data) => {
            this.makeSuccessPaginate(data)
          },
          error: (error) => {
            this.makeError(error)
          }
        })
      },
      onListing: function () {
        this.$emit('onListing')
      },
      onListed: function () {
        this.$emit('onListed', {
          data: this.data,
          config: this.config
        })
      },
      onError: function (error) {
        this.$emit('onError', error)
      },
      onFilter: function (filters) {
        this.$emit('onFilter', filters)
        this.filters = {}
        for (const filtersKey in filters) {
          if (filters[filtersKey]) {
            this.filters[filtersKey] = filters[filtersKey]
          }
        }
        this.getData()
      },
      makeSuccess: function (response) {
        this.data = response.data
        delete response.data
        this.config = response
        this.loading = false
        this.firstLoad = true
        this.loadingPaginate = false
        this.onListed()
      },
      makeSuccessPaginate: function (response) {
        response.data.map(item => {
          this.data.push(item)
        })
        delete response.data
        this.config = response
        this.loading = false
        this.loadingPaginate = false
        this.onListed()
      },
      makeError: function (error) {
        this.error = true
        this.loading = false
        this.loadingPaginate = false
        this.onError(error)
        this.$WiMakeError({
          id: 101,
          error: error,
          title: 'Erro ao buscar informações.'
        })
      },
      subscribe: function () {
        if (this.Socket && !this.subscriber) {
          this.subscriber = new this.Socket(this.socketParams)
          this.subscriber.onEventSubscribe(this.onCreated, this.onUpdated)
        }
      },
      onCreated: function (item) {
        this.data.unshift(item)
        this.$forceUpdate()
      },
      onUpdated: function (item) {
        const index = this.data.findIndex(dataItem => (dataItem.id == item.id))
        if (index > -1) {
          this.data.splice(index, 1, item)
        } else {
          this.onCreated(item)
        }
        this.$forceUpdate()
      },
      unSubscribe: function () {
        if (this.Socket && this.subscriber) {
          this.subscriber.onEventUnsubscribe()
          this.subscriber = null
        }
      },
      onRefresh: function () {
        this.getData()
        this.$emit('onRefresh')
      },
      onClose: function () {
        this.$emit('onClose')
      },
      addNewItem: function () {
        this.$WiEditDialog({
          wiConfig: this.wiConfig,
          onSubmit: () => {
            this.getData()
          },
          redirectOnSubmit: false
        })
        this.$emit('onAdd')
      }
    },
    mounted: function () {
      this.getData()
      // this.subscribe()
    },
    destroyed: function () {
      this.unSubscribe()
    },
    components: {
      WiLoading,
      WiViewListError,
      WiViewListToolbar
    },
    props: {
      wiConfig: {
        type: String,
        required: true
      },
      serviceParams: {
        default: () => ({})
      },
      toolbar: {
        default: () => ({
          icon: 'list',
          title: '',
          refresh: true,
          close: true,
          add: true
        })
      },
      loadingConfig: {
        default: () => ({
          type: 'circle',
          message: null
        })
      }
    }
  }
</script>

<style scoped>
  .wi-view__content {
    padding: 0 !important;
  }
  .wi-view-list__error-message { 
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }
  .wi-view-list__error-message h1 { 
    padding-bottom: 20px;
  }
</style>
