import { Business } from './Business'
import {
  BUSINESS_INVOICE_TYPE_ID_PARCEL,
  BUSINESS_INVOICE_TYPE_ID_IN_CACHE,
  BUSINESS_INVOICE_TYPE_ID_IN_TERM,
  BUSINESS_INVOICE_TYPE_ID_RECURRENT,
  BUSINESS_FIDELITY_TYPE_ID_NOT,
  BUSINESS_FIDELITY_TYPE_ID_DATE,
  BUSINESS_FIDELITY_TYPE_ID_MONTH,
  BUSINESS_FIDELITY_TYPE_ID_YEARS
} from '@/modules/business/constants/BusinessConstants'

export class BusinessSimple extends Business {
  constructor () {
    super()

    this.search.placeholder = 'Pesquise ID, Cliente, Status, Categoria ou Colaborador'
    this.components.list.tableRow = () => import('@/modules/business/pages/Businesses/BusinessesSimple')

    this.clearFields()
    
    this.fieldsEdit.company.size = ['xs12', 'sm12', 'md3', 'lg3']
    this.fieldsEdit.person.text = 'Cliente'
    this.fieldsEdit.person.wiConfig = 'customer'
    this.fieldsList.dateStart.text = 'Data Início'
    this.fieldsList.dateEnd.text = 'Data Término'
    this.fieldsEdit.status.size = ['xs12', 'sm12', 'md2', 'lg2']
    this.fieldsEdit.category.size = ['xs12', 'sm12', 'md2', 'lg2']
    
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldsList.id,
    personType: this.fieldsList.personType,
    personName: this.fieldsList.personName,
    status: this.fieldsList.status,
    dateStart: this.fieldsList.dateStart,
    dateEnd: this.fieldsList.dateEnd,
    collaborator: this.fieldsList.collaborator,
    options: this.fieldsList.options
  }

  fieldsEditInvoiceTypeRecurrent = {
    subHeaderInvoice: this.fieldSubHeader({
      text: 'Informe os dados de pagamento recorrente.',
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    }),
    invoicePayDay: this.fieldNumber({
      value: 'invoice_pay_day',
      text: 'Dia de Vencimento',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    }),
    fidelityTypeId: this.fieldSelect({
      value: 'fidelity_type_id',
      text: 'Tipo de fidelidade',
      placeholder: 'Selecione o tipo...',
      size: form => !form.fidelity_type_id || form.fidelity_type_id == BUSINESS_FIDELITY_TYPE_ID_NOT ? ['xs12', 'sm12', 'md4', 'lg4'] : ['xs12', 'sm12', 'md2', 'lg2'],
      required: true,
      options: [
        {
          value: BUSINESS_FIDELITY_TYPE_ID_NOT,
          text: 'Sem Fidelidade'
        },
        {
          value: BUSINESS_FIDELITY_TYPE_ID_DATE,
          text: 'Data Específica'
        },
        // {
        //   value: BUSINESS_FIDELITY_TYPE_ID_MONTH,
        //   text: 'Em meses'
        // },
        // {
        //   value: BUSINESS_FIDELITY_TYPE_ID_YEARS,
        //   text: 'Em anos'
        // }
      ],
      disabled: form => !!form.id,
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    }),
    fidelityMonths: this.fieldNumber({
      value: 'fidelity_number',
      text: 'Mes(es) de fidelidade',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT && form.fidelity_type_id == BUSINESS_FIDELITY_TYPE_ID_MONTH
    }),
    fidelityYears: this.fieldNumber({
      value: 'fidelity_number',
      text: 'Ano(s) de fidelidade',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT && form.fidelity_type_id == BUSINESS_FIDELITY_TYPE_ID_YEARS
    }),
    fidelity_end_date: this.fieldDate({
      value: 'fidelity_end_date',
      text: 'Final da fidelidade',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: form => form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT && (form.id || form.fidelity_type_id == BUSINESS_FIDELITY_TYPE_ID_DATE)
    }),
    financeAccountId: this.fieldWiListDialog({
      value: 'finance_account_id',
      text: 'Conta Financeira',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      wiConfig: 'finance-accounts',
      show: 'finance_account.name',
      icon: 'account_balance',
      disabled: (form) => { console.log(form); return form.id != null },
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    }),
    financeFormPaymentId: this.fieldSelectApi({
      value: 'finance_form_payment_id',
      text: 'Forma de Pagamento',
      required: true,
      api: {
        url: 'finance/form-payment',
        params: form => ({
          fields: 'id,name',
          order: 'name,asc',
          finance_account_id: form.finance_account_id
        })
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      disabled: form => !form.finance_account_id,
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    }),
    autoRenew: this.fieldSelect({
      value: 'auto_renew',
      text: 'Renovar?',
      size: ['xs12', 'sm12', 'md1', 'lg1'],
      required: true,
      options: [
        {
          value: true,
          text: 'Sim'
        },
        {
          value: 'false',
          text: 'Não'
        }
      ],
      showField: form => !form.id && form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste contrato.'
    }),
    company: this.fieldsEdit.company,
    person: this.fieldsEdit.person,
    dateStart: this.fieldsEdit.dateStart,
    dateEnd: this.fieldsEdit.dateEnd,
    amountDiscount: this.fieldsEdit.amountDiscount,
    status: this.fieldsEdit.status,
    category: this.fieldsEdit.category,
    collaborator: this.fieldsEdit.collaborator,
    externalCode: this.fieldsEdit.externalCode,
    invoiceTypeId: this.fieldSelect({
      value: 'invoice_type_id',
      text: 'Como será o pagamento?',
      placeholder: 'Selecione o tipo...',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true,
      options: [
        {
          value: BUSINESS_INVOICE_TYPE_ID_PARCEL,
          text: 'Parcelado'
        },
        {
          value: BUSINESS_INVOICE_TYPE_ID_IN_CACHE,
          text: 'À Vista'
        },
        {
          value: BUSINESS_INVOICE_TYPE_ID_IN_TERM,
          text: 'À Prazo'
        },
        {
          value: BUSINESS_INVOICE_TYPE_ID_RECURRENT,
          text: 'Recorrente'
        }
      ],
      disabled: form => !!form.id,
      showField: form => !form.id
    }),
    ...this.fieldsEditInvoiceTypeRecurrent,
    subHeaderObservation: this.fieldsEdit.subHeaderObservation,
    observation: this.fieldsEdit.observation,
  }

  
}

/**
  invoice_pay_day
  fidelity_end_date
  auto_renew
*/
