<template>
  <v-container align="center">
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg12>
        <v-card>
          <v-form v-model="valid">
            <br>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12 lg12 xl12 style="padding: 0 10px;">
                <h1>Selecione o dispositivo e insira o MAC Address!</h1>
                <v-divider></v-divider>
                <h3 style="color: green;">Como esta ferramenta funciona:</h3>
                <h4>1 - Você seleciona o dispositivo, insere o MAC e clica em "remover";</h4>
                <h4>2 - A solicitação é adicionada em uma fila de execução;</h4>
                <h4>3 - Você recebe uma mensagem de sucesso dizendo que a solicitação foi adicionada à fila;</h4>
                <h4>4 - A solicitação é executada em alguns segundos;</h4>
                <h4>5 - O sistema acessa o dispositivo e executa a ação solicitada. Dependendo do dispositivo e da quantidade de clientes este processo pode demorar entre 1 e 2 minutos;</h4>
                <v-divider></v-divider>
                <h3 style="color: orange;">A solicitação não será executada se:</h3>
                <h4>1 - O dispositivo não estiver devidamente configurado;</h4>
                <h4>2 - O dispositivo não estiver online no momento da execução;</h4>
                <h4>3 - Os dados de acesso do dispositivo estiverem errados;</h4>
                <h4>4 - O MAC Address não for encontrado;</h4>
                <v-divider></v-divider>
                <h3 style="color: red;">Atenção:</h3>
                <h4>1 - Ao receber a mensagem de sucesso aguarde de 1 a 2 minutos e confira o resultado;</h4>
                <h4>2 - Não solicite uma nova remoção antes de 2 minutos, isso pode causar o <span style="color: red;">travamento do dispositivo</span>;</h4>
                <v-divider></v-divider>
                <br>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 xl6 style="padding: 0 10px;">
                <v-select
                  v-model="form.device_id"
                  :items="devices"
                  label="Dispositivo"
                  placeholder="Selecione o Dispositivo"
                  item-text="nome_disp"
                  item-value="id"
                  required
                  :rules="[rules.required]"
                  outline
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 xl6 style="padding: 0 10px;">
                <v-text-field
                  v-model="form.mac_address"
                  label="MAC Address"
                  placeholder="##:##:##:##:##:##"
                  required
                  mask="NN:NN:NN:NN:NN:NN"
                  minlength="12"
                  maxlength="17"
                  :rules="[rules.required, rules.min, rules.max]"
                  outline
                ></v-text-field>
              </v-flex>
              <v-flex v-if="logs && logs.length > 0" xs12 sm12 md12 lg12 xl12 style="padding: 0 10px;">
                <v-alert v-for="(log, logKey) in logs" :value="true" :type="log.status" :key="logKey">
                  {{log.status}} - {{log.routerboard.name}}, MAC: {{log.mac_address}}
                </v-alert>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 xl12 style="padding: 0 10px;">
                <v-btn
                  :disabled="!valid || !form.device_id || !form.mac_address"
                  @click="removeLease()"
                  color="primary"
                  :loading="loading"
                  block
                >Remover</v-btn>
              </v-flex>
            </v-layout>
            <br>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    name: 'DeviceLeaseRemove',
    data: () => ({
      form: {},
      logs: [],
      valid: false,
      loading: false,
      devices: [
        { id: 54791, nome_disp: "CCR Wireless DVL" }
      ],
      rules: {
        required: value => !!value || 'Campo obrigatório!',
        min: v => (!!v && v?.length >= 12) || 'Mínimo de 12 caracteres',
        max: v => (!!v && v?.length <= 17) || 'Máximo de 17 caracteres',
      }
    }),
    methods: {
      getDevices: function () {
        this.$WiApiGet({
          uri: 'networks/device/command/remove-lease',
          callback: (response) => {
            if (response.success) {
              this.devices = response.success
            }
            console.log(this.devices)
          },
          config: {
            msgLoad: true,
            msgError: {
              title: 'Ocorreu um erro ao carregar os dispositivos.',
              text: 'Tente novamente ou entre em contato com o suporte.'
            }
          }
        })
      },
      removeLease: function () {
        this.$WiApiPost({
          uri: 'networks/device/command/remove-lease',
          data: {
            device_id: this.form.device_id,
            mac_address: this.form.mac_address,
          },
          callback: (response) => {
            if (response.sucess) {
              this.logs.push({
                ...this.form,
                status: 'success'
              })
            } else {
              this.logs.push({
                ...this.form,
                status: 'error'
              })
            }
          },
          config: {
            msgLoad: true,
            msgAsk: true,
            msgSucess: {
              title: 'Solicitação adicionada à fila!',
              text: 'Aguarde entre 1 e 2 minutos e verifique o resultado'
            },
            msgError: {
              title: 'Ocorreu um erro ao executar a solicitação.',
              text: 'O dispositivo pode não estar configurado corretamente ou não ter respondido como esperado. Tente novamente ou entre em contato com o suporte.'
            }
          }
        })
      }
    },
    mounted() {
      this.getDevices()
    }
  }
</script>