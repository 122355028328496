<template>
  <div>
    <div class="diagnostic-status" v-ripple :style="{background: status.color}" @click="complementDialog = true">
      <v-progress-circular
        v-if="status.name == 'opened'"
        :indeterminate="true"
        :size="23"
        :width="2"
        color="white"
      ></v-progress-circular>
      <v-icon v-else color="white">{{status.icon}}</v-icon>
      <span style="font-size: 18px; padding-left: 5px; color: white;">
        <span> {{status.title}} </span>
        <span v-if="complement && complement.state"> - {{complement.state}}</span>
      </span>
    </div>
    <v-divider></v-divider>
    <v-dialog v-model="complementDialog" width="780">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-toolbar-title>
            <v-icon>code</v-icon>
            <strong> Dados da Solicitação</strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="complementDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text v-if="complementDialog">
          <code v-if="complement" style="width: 100%;">
            {{ complement }}
          </code>
          <div v-else style="width: 100%; text-align: center; margin-top: 80px; margin-bottom: 100px;">
            <h3>Nenhuma informação encontrada...</h3>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    name: 'WiEventsDiagnosticStatus',
    data: () => ({
      complementDialog: false
    }),
    props: {
      status: {
        required: true,
        default: () => ({})
      },
      complement: {
        default: null
      }
    }
  }
</script>
<style scoped>
  .diagnostic-status {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
</style>
