<template>
  <div style="padding-bottom: 10px;">
    <v-layout row wrap v-if="config.information">
      <v-flex v-if="currentPage == 'finances' || currentPage == 'finance-list'" xs12 sm12 md4 lg4 pa-1>
        <finances-header-card color="#4A925F" icon="get_app" title="Receitas" :amount="config.information.amount_revenue"></finances-header-card>
      </v-flex>
      <v-flex v-if="currentPage == 'finances' || currentPage == 'finance-list'" xs12 sm12 md4 lg4 pa-1>
        <finances-header-card color="red darken-3" icon="publish" title="Despesas" :amount="config.information.amount_expenses"></finances-header-card>
      </v-flex>
      <v-flex v-if="currentPage == 'finances' || currentPage == 'finance-list'" xs12 sm12 md4 lg4 pa-1>
        <finances-header-card color="cyan darken-3" icon="account_balance" title="Balanço" :amount="config.information.amount_balance"></finances-header-card>
      </v-flex>
      <v-flex v-if="currentPage != 'finances' && currentPage != 'finance-list'" xs12 sm12 md4 lg4 pa-1>
        <finances-header-card :color="currentPage == 'finance-in' || currentPage == 'finance-in-list' ||  currentPage == 'finance-in-unpaid' ? '#4A925F' : 'red darken-3'" icon="done" title="Pago" :amount="config.information.amount_paid"></finances-header-card>
      </v-flex>
      <v-flex v-if="currentPage != 'finances' && currentPage != 'finance-list'" xs12 sm12 md4 lg4 pa-1>
        <finances-header-card :color="currentPage == 'finance-in' || currentPage == 'finance-in-list' ||  currentPage == 'finance-in-unpaid' ? '#4A925F' : 'red darken-3'" icon="alarm" title="Pendente" :amount="config.information.amount_unpaid"></finances-header-card>
      </v-flex>
      <v-flex v-if="currentPage != 'finances' && currentPage != 'finance-list'" xs12 sm12 md4 lg4 pa-1>
        <finances-header-card :color="currentPage == 'finance-in' || currentPage == 'finance-in-list' ||  currentPage == 'finance-in-unpaid' ? '#4A925F' : 'red darken-3'" icon="account_balance" title="Total" :amount="config.information.amount_total"></finances-header-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  import FinancesHeaderCard from '@/modules/finance/pages/Finances/FinancesHeaderCard'
  export default {
    data: () => ({}),
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      currentPage () {
        return this.$route.params ? this.$route.params.page : null
      }
    },
    methods: {
      showFinanceHeaderCardIfPage: function (page) {
        return this.currentPage == page
      }
    },
    props: {
      'config': {
        type: Object,
        required: true
      },
      'setRoute': {
        type: Boolean,
        default: false
      }
    },
    components: {
      FinancesHeaderCard
    }
  }
</script>