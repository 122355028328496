import { Auth } from '@/default/service/Auth'

const checkServiceDeskTitlePermission = Auth.checkPermission('8c272772')
const checkNetworksModulePermission = Auth.checkPermission('d237fbcd-2a0d-42eb-beef-409d29813ad3')

export default {
  id: {
    id: '5dc282ab',
    create: '2c491b4c',
    update: '1a825af1'
  },
  title: {
    list: 'Todos os Chamados',
    edit: {
      create: 'Criando novo chamado',
      update: 'Editando chamado'
    }
  },
  icon: 'message',
  apiUrl: 'service-desk/ticket',
  actions: {
    create: true,
    delete: false,
    update: false
  },
  urlDetails: {name: 'chamado', params: {}},
  returnUrl: {name: 'list', params: {page: 'chamados'}},
  components: {
    list: {
      tableRow: () => import('@/modules/service-desk/pages/Tickets/Tickets')
    }
  },
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {text: 'Informe um assunto ou título para o chamado (Opcional).', type: 'subheader', edit: true, showField: () => { return checkServiceDeskTitlePermission }},
    {value: 'assunto_chamado', text: 'Assunto', counter: 100, placeholder: 'Informe o assunto em no máximo 100 caracteres. Ex: Verificar Dispositivos', type: 'text', required: false, list: false, edit: true, showField: () => { return checkServiceDeskTitlePermission }},
    {value: 'nome_status', text: 'Status', type: 'text', align: 'center', list: true, edit: false},
    {value: 'nome_status', text: 'Redes', type: 'text', list: true, edit: false},
    {value: 'nome_categoria', text: 'Tipo de Ocorrência', type: 'text', list: true, edit: false},
    {value: 'last_note_id', text: 'Última Anotação', type: 'text', list: true, edit: false},
    {value: 'team_id', text: 'Equipe/Departamento', type: 'text', list: true, edit: false},
    {value: 'nome_responsavel', text: 'Responsável', type: 'text', list: true, edit: false},
    // {value: 'created_at', text: 'Criação', type: 'date', list: true, edit: false},
    {text: 'Informe se este chamado será vinculado a um cliente ou a um fornecedor.', type: 'subheader', edit: true},
    {value: 'external_code', text: 'Código/Ticket Externo', placeholder: 'Código/Ticket Externo', type: 'text', size: ['xs12', 'sm12', 'md2', 'lg2'], required: false, list: false, edit: true},
    {value: 'cli_ou_for', text: 'Vincular este chamado a um Cliente ou a um Fornecedor*', placeholder: 'Informe se este chamado será vinculado a um cliente ou a um fornecedor', type: 'select', required: true, size: ['xs12', 'sm12', 'md10', 'lg10'], options: [{value: 'cli', text: 'Vincular a um Cliente'}, {value: 'for', text: 'Vincular a um Fornecedor'}], list: false, edit: true, showField: (form) => { return !form.cli_ou_for }},
    {value: 'cli_ou_for', text: 'Cliente ou Fornecedor*', type: 'select', required: true, size: ['xs12', 'sm5', 'md4', 'lg3'], options: [{value: 'cli', text: 'Vincular a um Cliente'}, {value: 'for', text: 'Vincular a um Fornecedor'}], list: false, edit: true, showField: (form) => { return form.cli_ou_for }},
    {
      value: 'person_id',
      text: 'Cliente*',
      autocomplete: true,
      required: true,
      size: ['xs12', 'sm7', 'md6', 'lg7'],
      type: 'wi-list-dialog',
      edit: true,
      wiConfig: 'customer',
      filter: {
        where: 'customer,1',
      },
      show: 'person.first_name',
      icon: 'person_outline',
      showField: (form) => { if (form.cli_ou_for === 'cli') { return true } else { return false } }
    },
    {
      value: 'person_id',
      text: 'Fornecedor*',
      autocomplete: true,
      required: true,
      size: ['xs12', 'sm7', 'md6', 'lg7'],
      type: 'wi-list-dialog',
      edit: true,
      wiConfig: 'provider',
      filter: {
        where: 'provider,1',
      },
      show: 'person.first_name',
      icon: 'local_atm',
      showField: (form) => { if (form.cli_ou_for === 'for') { return true } else { return false } }
    },
    // {value: 'person_id', text: 'Cliente Vinculado*', autocomplete: true, required: true, size: ['xs12', 'sm7', 'md6', 'lg7'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/person/customer', apiParams: {fields: 'id,name', order: 'name,asc'}, show: 'name', showField: (form) => { if (form.cli_ou_for === 'cli') { return true } else { return false } }},
    // {value: 'person_id', text: 'Fornecedor Vinculado*', autocomplete: true, required: true, size: ['xs12', 'sm7', 'md6', 'lg7'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/person/provider', apiParams: {fields: 'id,name', order: 'name,asc'}, show: 'name', showField: (form) => { if (form.cli_ou_for === 'for') { return true } else { return false } }},
    {text: 'Descreva o que precisa ser feito ou o que precisa ser resolvido.', type: 'subheader', edit: true},
    {value: 'desc_chamado', text: 'Descrição/Solicitação*', placeholder: 'Por favor, descreva este chamado de forma detalhada.', type: 'editor', required: true, list: false, edit: {insert: true, update: false}},
    {text: 'Selecione a equipe, Colaborador (Opcional), Impacto, Tipo de Ocorrência e o canal por onde o chamado foi realizado.', type: 'subheader', edit: true},
    {value: 'chamado_categoria_id', text: 'Tipo de Ocorrência*', placeholder: 'Você pode selecionar um Tipo de Ocorrência ou sub-ocorrência', size: ['xs12', 'sm12', 'md7', 'lg7'], required: true, fields: false, type: 'list-ticket-category', ticketFunction: 2, autocomplete: true, list: false, edit: true, apiUrl: 'chamado/ocorrencia', apiParams: {where: 'ativa_categoria,1;funcao_categoria,>,0;funcao_categoria,<,3', fields: 'id,nome_categoria_grupo_previsao,nome_categoria_grupo,previsao_categoria,icone_categoria,cor_categoria', order: 'nome_categoria_grupo'}, show: 'nome_categoria_grupo_previsao', icon: 'icone_categoria', color: 'cor_categoria'},
    {value: 'definir_previsao', text: 'Definir Previsão do Chamado*', type: 'select', required: true, size: ['xs12', 'sm6', 'md3', 'lg3'], options: [{value: 'serv_prev', text: 'Usar previsão da Ocorrência'}, {value: 'def_prev', text: 'Definir Previsão Manualmente'}], list: false, edit: true},
    {value: 'prevision', text: 'Previsão de duração', type: 'text', mask: '##:##:##', size: ['xs12', 'sm6', 'md2', 'lg2'], list: false, edit: true, disabled: (form) => { return form.definir_previsao === 'serv_prev' || !form.definir_previsao }},
    {value: 'team_id', text: 'Equipe Responsável*', placeholder: 'Todos os membros desta equipe serão notificados sobre este chamado', autocomplete: true, required: true, size: ['xs12', 'sm6', 'md3', 'lg4'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/team', apiParams: {fields: 'id,name', where: 'active,1', order: 'name,asc'}, show: 'name'},
    {value: 'person_responsible_id', text: 'Responsável pelo Chamado*', placeholder: '(Opcional) Quem irá ser responsabilizado', autocomplete: true, required: true, size: ['xs12', 'sm6', 'md3', 'lg4'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/person/collaborator', apiParams: {fields: 'id,name', order: 'name,asc'}, show: 'name'},
    {value: 'chamado_impacto_id', text: 'Impacto*', placeholder: 'Baixo, Médio, Alto ou Crítico', autocomplete: true, required: true, fields: false, size: ['xs12', 'sm6', 'md3', 'lg2'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/impacto', show: 'nome_impacto'},
    {value: 'chamado_urgencia_id', text: 'Urgência*', placeholder: 'Baixa, Média, Alta ou Urgente', autocomplete: true, required: true, fields: false, size: ['xs12', 'sm6', 'md3', 'lg2'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/urgencia', show: 'nome_urgencia'},
    // {value: 'chamado_canal_id', text: 'Canal', placeholder: 'Por onde este chamado foi iniciado', size: ['xs12', 'sm6', 'md2', 'lg2'], type: 'db-autocomplete', autocomplete: true, list: false, edit: true, apiUrl: 'chamado/canal', show: 'nome_canal'},
    {value: 'grupos', text: 'Redes Afetadas', multiple: true, autocomplete: true, required: true, type: 'db-autocomplete', list: false, edit: true, apiUrl: 'grupo/dados', apiParams: () => { return {where: 'grupo_tipo_id,' + /* configuracao.Configuracao[2].val_text_config.grupo_tipo_id */ 3, fields: 'grupo_dados.id,nome_grupo', order: 'nome_grupo,asc'} }, show: 'nome_grupo', showField: () => { return checkNetworksModulePermission /* configuracao.Configuracao[2].val_text_config.exibir_grupo */ }}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
