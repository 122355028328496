import {
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE
} from '@/default/constants/permissions'

const event = (id, uri) => {
  return {
    id: id,
    title: 'Adicionar Nota',
    icon: 'message',
    apiUrl: 'stocks/product-transfer-in' + uri + '/event',
    params: {
      order: 'id,desc'
    },
    fields: [
      {text: 'Descreva sua nota de forma que fique clara aos outros usuários.', type: 'subheader', edit: true},
      {value: 'description', text: 'Insira aqui sua nota.*', type: 'editor', required: true, edit: true}
    ],
    fieldsFixed: [
      {
        value: 'type', data: 2
      }
    ]
  }
}

export default {
  productTransferIn: event(PERMISSION_STOCK_PRODUCT_TRANSFER_IN_UPDATE, '', 'à Transferência'),
  productTransferInCollaborator: event(PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_UPDATE, '/collaborator', 'à Transferência'),
  productTransferInCustomer: event(PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER_UPDATE, '/customer', 'à Transferência'),
  productTransferOut: event(PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_UPDATE, '', 'à Transferência'),
  productTransferOutCollaborator: event(PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR_UPDATE, '/collaborator', 'à Transferência'),
  productTransferOutCustomer: event(PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_UPDATE, '/customer', 'à Transferência'),

  productEntry: event(PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE, '', 'à Movimentação'),
  productEntryCollaborator: event(PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE, '/collaborator', 'à Movimentação'),
  productEntryCustomer: event(PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE, '/customer', 'à Movimentação'),
  productRemoval: event(PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE, '', 'à Movimentação'),
  productRemovalCollaborator: event(PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE, '/collaborator', 'à Movimentação'),
  productRemovalCustomer: event(PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE, '/customer', 'à Movimentação'),
}
