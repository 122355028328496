import { BusinessLayout } from '@/modules/business/config/BusinessLayout'
import { PERMISSION_CONTRACT_UPDATE } from '@/default/constants/permissions'

export class ContractLayout extends BusinessLayout {
  constructor () {
    super()
    this.id = PERMISSION_CONTRACT_UPDATE
    this.name = 'contract-layout'
    this.title = 'Editar documento'
    this.icon = 'edit'
    this.apiUrl = 'sales/contract'
  }
}
