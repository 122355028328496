<template>
  <v-card>
    <!-- eslint-disable -->
    <v-list dense style="max-height: none !important;">
      <v-list-tile v-ripple avatar>
          <v-list-tile-content>
            <v-list-tile-title>
                <h4 @click="$emit('action', 'tickets-by-event-collaborator')" style="cursor: pointer;">
                <v-icon style="height: 15px;"></v-icon>
                <span>Chamados por Colaborador (Interação)</span>
                </h4>
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action style="text-align: center; padding: 0 5px;">
            <v-list-tile-sub-title style="font-size:12px;">
                <span>Chamados</span>
            </v-list-tile-sub-title>
          </v-list-tile-action>
      </v-list-tile>
      <v-list-tile @click="$router.push({name: 'service-desk-list', params: {page: 'ticket-opened'}, query: {last_event_person: collaborator.id}})" v-ripple avatar v-for="(collaborator, collaboratorKey) in data" :key="collaboratorKey" v-if="!limit || collaboratorKey < limit">
          <v-list-tile-avatar size="45">
          <v-avatar>
              <img v-if="collaborator.sex == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="Solicitante">
              <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="Solicitante">
          </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
          <v-list-tile-title style="font-size:18px;">
              <span>{{collaborator.name}}</span>
          </v-list-tile-title>
          <v-list-tile-sub-title style="font-size: 12px;">
              <span>{{collaborator.teams}}</span>
          </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action style="padding: 0 5px;">
          <v-list-tile-title style="text-align: center; font-size:24px;">
              <span>{{collaborator.tickets}}</span>
          </v-list-tile-title>
          </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-card>    
</template>
<script>
  export default {
    name: 'ServiceDeskDashboardTicketsByEventCollaborators',
    props: ['data', 'limit']
  }
</script>