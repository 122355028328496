<template>
  <v-dialog v-model="active" scrollable max-width="580px">
    <v-card>
      <v-card-title style="padding: 0 15px 0 15px;">
        <strong>Histórico de alterações do Status</strong>
        <v-spacer></v-spacer>
        <v-btn flat icon color="green" @click="info.refresh()">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px; padding: 0;">
        <wi-view v-if="active" notShowToolbar="true" :apiUrl="'service-desk/ticket/status/log'" :apiParams="'?where=chamado_dados_id,' + chamado + '&order=momento_log,desc'" notGetID="true">
          <template slot-scope="dados"><span style="display: none;">{{info = dados}}</span>
            <v-list two-line>
              <template v-for="(log, logKey) in dados.content.data">
                <v-list-tile avatar :key="logKey + '-tile'">
                  <v-list-tile-avatar>
                    <v-icon x-large :color="log.status.cor_status">{{log.status.icone_status}}</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <strong v-text="log.status.nome_status"></strong>
                      <span v-if="log.deadline_formated" :style="{color: $WiSecondsColor({seconds: log.deadline_seconds, config: {disable: logKey}})}">
                        <small> até </small>
                        <strong v-text="log.deadline_formated"></strong>
                      </span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <span>Duração no momento:</span>
                      <strong v-text="log.duracao_chamado"></strong>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <span>Autor: </span>
                      <strong v-text="log.person.name"></strong>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action-text>
                    <strong v-text="log.created_at_formated"></strong>
                  </v-list-tile-action-text>
                </v-list-tile>
                <v-divider :key="logKey + '-divider'"></v-divider>
              </template>
            </v-list>
          </template>
        </wi-view>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" flat @click.native="$emit('close')">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DialogTicketStatusLog',
    data () {
      return {
        info: null
      }
    },
    props: ['chamado', 'active']
  }
</script>