import router from '@/modules/stock/router'
import config from '@/modules/stock/config'
import menu from '@/modules/stock/menu'

export default {
  id: 'b0ce8f81-78ff-4d79-a9f3-d7415343d2e5',
  name: 'stock',
  title: 'Estoque',
  description: 'Gestão de Estoque e Produtos',
  icon: 'wises-technology-module-stock.png',
  iconSystem: 'wises-technology-system-stock.png',
  iconProfile: 'profile_service_desk.png',
  // routeDefault: { name: 'dashboard-stock' },
  color: '#ba6600',
  menu,
  router,
  config
}
