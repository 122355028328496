<template>
  <div>
    <div v-if="dados.ip_servidor || dados.porta_web || dados.url_servidor || dados.usuario_dude || dados.senha_dude || dados.id_team_viewer || dados.senha_team_viewer">
      <div v-if="dados.ip_servidor || dados.porta_web || dados.url_servidor">
        <v-layout row>
          <v-flex lg1 md1 xs1 sm1> 
            <v-avatar>
              <v-icon style="margin-top: -50%" color="cyan">public</v-icon>
            </v-avatar>
          </v-flex>
          <v-flex lg11 md11 xs11 sm11> 
            <div v-if="dados.ip_servidor">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>IP</span>
                    <v-list-tile-sub-title v-text="dados.ip_servidor"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </div>
            <div v-if="dados.porta_web">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>Porta Web</span>
                    <v-list-tile-sub-title v-text="dados.porta_web"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </div>
            <div v-if="dados.url_servidor">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>URL</span>
                    <v-list-tile-sub-title v-text="dados.url_servidor"></v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-icon style="cursor: pointer;" small color="grey" @click="openURL(dados.url_servidor)">open_in_new</v-icon>
                  </v-list-tile-action>
                </v-list-tile> 
              </v-list>
            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </div>
      <div v-if="dados.usuario_dude || dados.senha_dude ">
        <v-layout row>
          <v-flex lg1 md1 xs1 sm1> 
            <v-avatar>
              <v-icon style="margin-top: -50%" color="cyan">perm_identity</v-icon>
            </v-avatar>
          </v-flex>
          <v-flex lg11 md11 xs11 sm11>
            <div v-if="dados.usuario_dude">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>Usuário Dude</span>
                    <v-list-tile-sub-title v-text="dados.usuario_dude"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </div>
            <div v-if="dados.senha_dude">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>Senha Dude</span>
                    <v-list-tile-sub-title v-text="dados.senha_dude"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </div>
      <div v-if="dados.id_team_viewer || dados.senha_team_viewer ">
        <v-layout row>
          <v-flex lg1 md1 xs1 sm1> 
            <v-avatar>
              <v-icon style="margin-top: -50%" color="cyan">laptop_windows</v-icon>
            </v-avatar>
          </v-flex>
          <v-flex lg11 md11 xs11 sm11>
            <div v-if="dados.id_team_viewer">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>ID TeamViewer</span>
                    <v-list-tile-sub-title v-text="dados.id_team_viewer"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </div>
            <div v-if="dados.senha_team_viewer">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <span>Senha TeamViewer</span>
                    <v-list-tile-sub-title v-text="dados.senha_team_viewer"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </div>
    </div>
    <div v-else>
      <v-alert style="margin:25px;" :value="true" color="yellow" >
        <v-icon color="black">info</v-icon>
        <span style="color: black;"> Para que apareçam as informações do servidor é necessário cadastrá-las.</span>
      </v-alert>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {}
    },
    methods: {
      openURL: function (url) {
        window.open(url, '_blank')
      }
    },
    props: ['dados']
  }
</script>