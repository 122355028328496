import { Model } from '@/default/model/Model'
import { PERMISSION_FORM_REPLY } from '@/default/constants/permissions'

export class FormReply extends Model {
  constructor () {
    super()
    this.list = true
    this.actions = {
      list: true,
      edit: false
    }
    this.id = PERMISSION_FORM_REPLY
    this.name = 'form-reply'
    this.title = 'Respostas do Formulário'
    this.params = {
      limit: 10,
      order: 'created_at,desc',
      with: 'form;person'
    }
    this.apiUrl = 'forms/form-reply'
    this.components.list.tableRow = () => import('@/modules/forms/pages/Forms/FormReplies')
    this.setFields(this.fieldsList)
  }

  fieldsList = {
    form_id: this.fieldList({
      value: 'form_id',
      text: 'Nome do formulário'
    }),
    person_id: this.fieldList({
      value: 'person_id',
      text: 'Usuário'
    }),
    status_id: this.fieldList({
      value: 'status_id',
      text: 'Status'
    }),
    created_at: this.fieldList({
      value: 'created_at',
      text: 'Criação/Atualização'
    })
  }

}
