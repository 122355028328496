export default {
  'system.version': 'Versão',
  'system.currency.name': 'Real Brasileiro',
  'system.currency.simbol': 'R$',
  'register.config.person.fields.list.cpf_cnpj.text': 'CPF/CNPJ',
  'register.config.person.fields.edit.subheader1.text': ({name}) => { return 'Informações básicas deste(a) ' + name + '.' },
  'register.config.person.fields.edit.subheader2.text': ({name}) => { return 'Primeiro, selecione se este(a) ' + name + ' é pessoa Física ou Jurídica' },
  'register.config.person.fields.edit.type.text': 'Pessoa Física ou Jurídica',
  'register.config.person.fields.edit.type.placeholder': 'Pessoa Física ou Jurídica.',
  'register.config.person.fields.edit.type.option1': 'Pessoa Física',
  'register.config.person.fields.edit.type.option2': 'Pessoa Jurídica',
  'register.config.person.fields.edit.cpf_cnpj1.text': 'CPF',
  'register.config.person.fields.edit.cpf_cnpj1.placeholder': '###.###.###-##',
  'register.config.person.fields.edit.cpf_cnpj1.mask': '###.###.###-##',
  'register.config.person.fields.edit.rg.text': 'RG',
  'register.config.person.fields.edit.rg.placeholder': 'Insira o RG',
  'register.config.person.fields.edit.cpf_cnpj2.text': 'CNPJ',
  'register.config.person.fields.edit.cpf_cnpj2.placeholder': '##.###.###/####-##',
  'register.config.person.fields.edit.cpf_cnpj2.mask': '##.###.###/####-##',
  'register.config.person.cell.title.edit.create': 'Novo telefone Celular',
  'register.config.person.cell.title.edit.update': 'Editar telefone Celular',
  'register.config.person.cell.fields.contact.mask': '(##) # ####-####',
  'register.config.person.phone.fields.contact.mask': '(##) ####-####',
  'register.config.person.address.title.edit.create': 'Novo Endereço',
  'register.config.person.address.title.edit.update': 'Editar Endereço',
  'register.config.person.address.fields.zip_code.text': 'CEP',
  'register.config.person.address.fields.zip_code.mask': '#####-###',
  'register.config.person.address.fields.district.text': 'Bairro',
  'register.config.person.address.fields.city_id.text': 'Cidade',
  'register.page.PersonDetail.cpf': 'CPF',
  'register.page.PersonDetail.rg': 'RG',
  'register.page.PersonDetail.cnpj': 'CNPJ',
  'register.page.PersonContactAddress.btn1': 'Celular',
  'register.page.PersonContactAddress.addressTitle': 'Endereços',
  'register.page.PersonContactAddress.btn2': 'Novo Endereço',
  'register.page.Person.tab1': 'Telefones/Endereços'
}
