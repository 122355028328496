export default {
  id: '1a825af1',
  list: true,
  title: 'Justificativas de Chamado',
  apiUrl: 'chamado/justificativa',
  fields: [
    {text: 'Descreva esta justificativa.', type: 'subheader', edit: true},
    {value: 'id', text: 'ID', type: 'text', list: true, edit: false},
    {value: 'nome_just', text: 'Insira aqui a descrição da justificativa.*', type: 'text', required: true, list: true, edit: true},
    {value: 'obs_just', text: 'Se necessário, coloque aqui observações sobre esta justificativa.', type: 'textarea', required: false, list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
