<template>
  <div class="wi-view-list-toolbar">
    <v-toolbar
      dense
      :app="fixed"
      :fixed="fixed"
      :color="getColor"
      :class="{'white--text': getColor}"
    >
      <v-toolbar-title>
        <v-icon v-if="icon" :color="getColor ? 'white' : ''">
          {{icon}}
        </v-icon>
        <span v-if="title">
          {{ title || wnConfig.title || 'Listagem' }}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot
        v-if="data"
        name="actionsBefore"
        :data="data"
        :config="config"
        :loading="loading"
        :close="closeEvent"
        :refresh="refreshEvent"
        :nextPage="nextPageEvent"
      ></slot>
      <WiListFilters
        v-if="wnConfig && wnConfig.filters"
        :config="wnConfig.filters"
        :params="params"
        :mode="!$vuetify.breakpoint.xsOnly ? 'button' : 'icon'"
        @filter="filter"
      ></WiListFilters>
      <v-btn v-if="add" icon @click="addEvent()">
        <v-icon :color="getColor ? 'white' : ''">add</v-icon>
      </v-btn>
      <v-btn v-if="refresh" icon :disabled="loading" @click="refreshEvent()">
        <v-icon :color="getColor ? 'white' : ''">refresh</v-icon>
      </v-btn>
      <v-btn v-if="close" icon @click="closeEvent()">
        <v-icon :color="getColor ? 'white' : ''">close</v-icon>
      </v-btn>
      <slot
        v-if="data"
        name="actionsAfter"
        :data="data"
        :config="config"
        :loading="loading"
        :close="closeEvent"
        :refresh="refreshEvent"
        :nextPage="nextPageEvent"
      ></slot>
    </v-toolbar>
    <v-progress-linear
      v-if="loadingPaginate"
      indeterminate
      color="primary"
    ></v-progress-linear>
  </div>
</template>
<script>
  import WiListFilters from '@/default/component/WiList/Filters'
  export default {
    name: 'WiViewListToolbar',
    computed: {
      getColor () {
        return this.$wiDark ? 'dark' : this.color
      }
    },
    methods: {
      refreshEvent: function () {
        this.$emit('onRefresh')
      },
      closeEvent: function () {
        this.$emit('onClose')
      },
      addEvent: function () {
        this.$emit('onAdd')
      },
      nextPageEvent: function () {
        this.$emit('onNextPage')
      },
      filter: function (filters) {
        this.$emit('onFilter', filters)
      }
    },
    props: {
      title: {
        required: true
      },
      icon: {
        default: 'list'
      },
      fixed: {
        default: false
      },
      color: {
        type: String,
        default: null
      },
      loading: {
        default: false
      },
      loadingPaginate: {
        default: false
      },
      data: {
        required: true
      },
      wnConfig: {
        required: true
      },
      params: {
        required: true
      },
      config: {
        required: true
      },
      nextPage: {
        required: true
      },
      refresh: {
        default: true
      },
      close: {
        default: true
      },
      add: {
        default: true
      }
    },
    components: {
      WiListFilters
    }
  }
</script>
<style scoped>
  /* .wi-view-list-toolbar .v-toolbar {
    z-index: 9 !important;
  } */
  .wi-view-list-toolbar .v-progress-linear {
    margin: 0 !important;
  }
</style>