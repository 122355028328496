import { Model } from '@/default/model/Model'

export class Course extends Model {
  constructor () {
    super()
    this.list = true
    this.id = 'a84885b9'
    this.name = 'course'
    this.title = {
      list: 'Cursos/Graduações',
      edit: {
        insert: 'Criar Cursos/Graduações',
        update: 'Editar Cursos/Graduações'
      }
    }
    this.search = {
      placeholder: 'Pesquise pelo nome ou abreviação',
      fields: ['name', 'tag']
    }
    this.apiUrl = 'graduations/register/course'
    this.apiParams = {
      limit: 10,
      fields: 'id,name,tag,description',
      order: 'created_at,desc'
    }
    this.icon = 'g_translate'
    this.redirectOnSubmit = false
    this.fieldsEdit = {
      header1: this.fieldSubHeader({
        text: 'Informe nome, abreviação e descrição.'
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      tag: this.fieldText({
        value: 'tag',
        text: 'Abreviação',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      description: this.fieldTextArea({
        value: 'description',
        text: 'Descrição'
      })
    }
    this.fieldsList = {
      name: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      tag: this.fieldList({
        value: 'tag',
        text: 'Abreviação'
      }),
      description: this.fieldList({
        value: 'description',
        text: 'Descrição'
      })
    }
    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }
}
