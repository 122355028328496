import {Model} from '@/default/model/Model'

export class BusinessFinance extends Model {
  constructor () {
    super()
    this.apiUrl = 'finance'
    this.icon = 'shopping_cart'
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe abaixo a data de Pagamento/Recebimento desta transação financeira.'
      }),
      this.fieldDate({
        value: 'date_receive',
        text: '',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4', 'offset-md4', 'offset-lg4']
      })
    ])
  }
}
