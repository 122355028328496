<template>
  <v-dialog v-model="config.searchDialog" :dark="$wiDark" persistent max-width="598">
    <v-card>
      <v-toolbar dense flat color="transparent">
        <v-icon>search</v-icon>
        <h3 class="pl-1"> Pesquisa</h3>
        <v-spacer></v-spacer>
        <v-btn icon small @click="config.searchDialogClose()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <WiListInput v-if="config.searchDialog" :config="config" :solo="true" class="mb-3"></WiListInput>
        <v-btn v-if="config.search.run" block outline color="primary" @click="config.searchClear()">
          <v-icon>close</v-icon>
          <span> Limpar pesquisa</span>
        </v-btn>
        <v-btn v-else block outline color="primary" :disabled="!config.search.query" @click="config.searchLoad(config.search.query)">
          <v-icon>search</v-icon>
          <span> Pesquisar</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import WiListInput from './WiListInput'
  export default {
    name: 'WiListInputDialog',
    props: ['config'],
    components: {
      WiListInput
    }
  }
</script>