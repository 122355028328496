<template>
  <view-detail index="business-dashboard" apiUrl="stock/dashboard" :notShowToolbar="true" :notGetID="true">
    <template slot-scope="props">
      <v-container style="padding: 10px;" fluid grid-list-md>
        <v-layout row wrap>
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-card>
              <v-list dense style="max-height: none !important;">
                <v-list-tile v-ripple avatar>
                  <v-list-tile-avatar size="45">
                    <v-btn icon ripple @click="$router.go(-1)" style="cursor: pointer;">
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </v-list-tile-avatar>
                  <v-list-tile-avatar @click="props.refresh()" style="cursor: pointer;" size="45">
                    <img src="static/systems/modules/wises-technology-module-stock.png" alt="Wise Control, Dashboard Estoque" >
                  </v-list-tile-avatar>
                  <v-list-tile-content @click="props.refresh()" style="cursor: pointer;">
                    <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                    <v-list-tile-title style="font-size:18px;">
                      <span>Estoque</span>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action @click="props.refresh()" style="min-width: 1px !important;cursor: pointer;">
                    <v-btn flat icon color="green">
                      <v-icon>refresh</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
          <v-flex v-for="(statistic, statisticKey) in statistics" :key="'statistic-' + statisticKey" xs12 sm12 md3 lg3 style="padding: 0 5px 5px 5px;">
            <v-card>
              <v-card-text style="padding: 10px 0; text-align: center;" :style="{background: statistic.color}">
                <h2 style="color: white;">
                  <v-icon x-large color="white">{{statistic.icon}}</v-icon>
                  <span style="font-size:38px;">{{props.content.statistics[statistic.field]}}</span>
                </h2>
                <v-btn :disabled="props.content.statistics[statistic.field] < 1" color="white" outline @click="$router.push(statistic.button.route)">{{statistic.button.text}}</v-btn>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 style="padding: 10px 5px 5px 5px;">
            <v-card>
              <h4 @click="$router.push({name: 'stock-list', params: {page: 'stock-movement'}})" style="cursor: pointer; padding: 10px 10px 5px 10px;">
                <v-icon style="height: 15px;">swap_horiz</v-icon>
                <span>10 Últimas Movimentações</span>
              </h4>
              <v-list dense style="max-height: none !important;">
                <template v-for="(movement, movementKey) in props.content.last_movements">
                  <v-divider :key="'movement-divider-' + movementKey"></v-divider>
                  <v-list-tile v-ripple avatar :key="'movement-tile-' + movementKey">
                    <v-list-tile-avatar size="45">
                      <div v-if="movement.type == 1" style="text-align: center;">
                        <v-icon color="green" x-large>get_app</v-icon>
                      </div>
                      <div v-if="movement.type == 2" style="text-align: center;">
                        <v-icon color="orange" x-large>publish</v-icon>
                      </div>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title style="font-size:16px;">
                        <span>{{movement.item.name}}</span>
                      </v-list-tile-title>
                      <v-list-tile-sub-title style="font-size: 14px;">
                        <span>{{movement.date_competence_format}}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action style="padding: 0 5px;">
                      <v-list-tile-title style="text-align: center; font-size:24px;">
                        <span>{{movement.quantity}}</span>
                      </v-list-tile-title>
                    </v-list-tile-action>
                  </v-list-tile>
                </template>
              </v-list>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 style="padding: 10px 5px 5px 5px;">
            <v-card>
              <h4 @click="$router.push({name: 'stock-list', params: {page: 'stock-transfer'}})" style="cursor: pointer; padding: 10px 10px 5px 10px;">
                <v-icon style="height: 15px;">import_export</v-icon>
                <span>5 Últimas Transferências</span>
              </h4>
              <v-list dense style="max-height: none !important;">
                <template v-for="(transfer, transferKey) in props.content.last_transfers">
                  <v-divider :key="'movement-divider-' + transferKey"></v-divider>
                  <v-list-tile v-ripple avatar :key="'movement-tile-' + transferKey">
                    <v-list-tile-avatar size="45">
                      <v-icon color="blue" x-large>import_export</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title style="font-size:16px;">
                        <span>De</span>
                        <strong>
                          {{transfer.stock_out.name}}
                        </strong>
                        <span>Para</span>
                        <strong>
                          {{transfer.stock_in.name}}
                        </strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title style="font-size: 14px;">
                        <span>{{transfer.item.name}}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action style="padding: 0 5px;text-align: center; font-size:24px;">
                      <span>{{transfer.quantity}}</span>
                    </v-list-tile-action>
                  </v-list-tile>
                </template>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  export default {
    name: 'StockDashboard',
    data () {
      return {
        statistics: [
          {
            color: '#B47932',
            icon: 'trending_down',
            field: 'min_products',
            button: {
              text: 'Produtos no Mínimo',
              route: {name: 'stock-list', params: {page: 'stock-product'}, query: {quantity_min: true}}
            }
          },
          {
            color: '#6A1B9A',
            icon: 'trending_up',
            field: 'max_products',
            button: {
              text: 'Produtos no Máximo',
              route: {name: 'stock-list', params: {page: 'stock-product'}, query: {quantity_max: true}}
            }
          },
          {
            color: '#b71c1c',
            icon: 'not_interested',
            field: 'zero_products',
            button: {
              text: 'Produtos Zerados',
              route: {name: 'stock-list', params: {page: 'stock-product'}, query: {quantity_zero: true}}
            }
          },
          {
            color: '#00c853',
            icon: 'local_offer',
            field: 'products',
            button: {
              text: 'Total em Estoque',
              route: {name: 'stock-list', params: {page: 'stock-product'}}
            }
          }
        ]
      }
    }
  }
</script>
