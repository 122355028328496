import { Model } from '@/default/model/Model'
import { PERMISSION_TALKS_CAMPAIGN_EXPORT_XLSX } from '@/default/constants/permissions'

export class TalkCampaignExport extends Model {
  constructor () {
    super()
    this.id = PERMISSION_TALKS_CAMPAIGN_EXPORT_XLSX
    this.list = true
    this.title = 'Exportar campanhas'
    this.apiUrl = 'talks/campaign-export'
    this.icon = 'upload'
    
    this.maxWidth = '598px'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    // subHeader1: this.fieldSubHeader({
    //   text: 'Selecione uma ou mais opções para filtrar.'
    // }),
    // status_id: this.fieldSelectApi ({
    //   value: 'status_id',
    //   text: 'Status',
    //   show: 'name',
    //   // multiple: true,
    //   api: {
    //     url: '',
    //     params: {
    //       fields: 'id,name',
    //       where: 'active,1',
    //       order: 'order,asc'
    //     }
    //   },
    //   size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    // }),
    subHeader2: this.fieldSubHeader({
      text: 'Filtrar por data'
    }),
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data Inicial',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true
    }),
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data Final',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true
    })
  }
}
