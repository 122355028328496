const event = (id, name, title) => {
  return {
    id: id,
    title: 'Adicionar Nota ' + title,
    icon: 'message',
    apiUrl: 'clinic/' + name + '/event',
    params: {
      order: 'id,desc'
    },
    fields: [
      {text: 'Descreva sua nota de forma que fique clara e objetiva.', type: 'subheader', edit: true},
      {value: 'description', text: 'Insira aqui sua nota.*', type: 'editor', required: true, edit: true}
    ],
    fieldsFixed: [
      {
        value: 'type', data: 2
      }
    ]
  }
}

export default {
  evolution: event('c8aaffa1', 'evolution', 'à Evolução'),
  anamnesis: event('871f09f4', 'anamnesis', 'à Anamneses'),
  prescription: event('d8317aeb', 'prescription', 'ao Receituário'),
  examRequest: event('b2812888', 'exam-request', 'à Solicitação de Exame'),
  attested: event('27201def', 'attested', 'ao Atestado')
}
