<template>
  <v-container fluid grid-list-lg>
    <Loader :loading="loading">
      <v-layout row wrap>
        <v-flex cols="12">
          <v-toolbar dense>
            <v-btn icon @click="$router.go(-1)">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <h2>{{ title }} </h2>
            <v-spacer></v-spacer>
            <v-btn flat large @click="dailyReportDialog = true">
              <h2>{{date | moment("DD/MM/YYYY")}}</h2>
            </v-btn>
            <v-dialog v-model="dailyReportDialog" width="300px">
              <v-card>
                <v-card-title>
                  <h2>Defina a data do relatório</h2>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <h1>
                    <v-text-field v-model="dailyReportDialogDate" type="date"></v-text-field>
                  </h1>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn block outline color="primary" @click="setNewDate()">
                    definir data
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('refresh')">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex v-for="(item, itemKey) in finances" :key="'finance-' + itemKey" xs12 sm12 md4 lg4 xl4>
          <DailyReportFinance
            :finance="item.finance"
            :title="item.title"
          ></DailyReportFinance>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex v-for="(item, itemKey) in products" :key="'product-' + itemKey" xs12 sm12 md4 lg4 xl4>
          <DailyReportProducts
            :products="item.products"
            :title="item.title"
          ></DailyReportProducts>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex v-if="customers" xs12>
          <v-card class="mb-5">
            <v-toolbar dense color="black" class="daily-report__product-title--text white--text">
              <v-toolbar-title>
                Clientes Atendidos em {{ date | date }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <span>
                {{ customers.length }}
              </span>
            </v-toolbar>
            <v-data-table
              v-if="customers && customers.length > 0"
              :headers="[
                { text: 'ID do Cliente', value: 'id', align: 'center' },
                { text: 'Número/Cliente/Rota', value: 'name', align: 'left' },
                { text: 'Estatísticas', value: 'groups', align: 'center', sortable: false },
              ]"
              :items="customers"
              class="elevation-15"
              hide-actions
            >
              <template v-slot:items="{ item }">
                <tr style="cursor: pointer;" @click="$router.push({ name: 'customer-daily-report', params: { id: item.id } })">
                  <td class="text-xs-center">
                    <h3>{{ item.id }}</h3>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ item.reference }} - {{ item.name }}</h3>
                    <h4 class="grey--text">
                      <span v-for="group in item.groups" :key="group.id">{{ group.description }}</span>
                    </h4>
                  </td>
                  <td class="text-xs-center">
                    <div style="font-size: 15px;">
                      <span class="ml-3">
                        <span>Vendas: </span>
                        <strong>{{ item.total_products_sale || '0' }}</strong>
                      </span>
                      <span class="ml-3">
                        <span>Reposições: </span>
                        <strong>{{ item.total_products_entry || '0' }}</strong>
                      </span>
                      <span class="ml-3">
                        <span>Devoluções: </span>
                        <strong>{{ item.total_products_removal || '0' }}</strong>
                      </span>
                    </div>
                    <div class="grey--text">
                      <span class="ml-3 green--text">
                        <span>Recebido do dia: </span>
                        <strong style="font-size: 15px;">{{ item.total_finances_paid_today | money }}</strong>
                      </span>
                      <span class="ml-3 orange--text">
                        <span>Em aberto do dia: </span>
                        <strong style="font-size: 15px;">{{ item.total_finances_unpaid_today | money }}</strong>
                      </span>
                      <span class="ml-3 red--text">
                        <span>Total em aberto: </span>
                        <strong style="font-size: 15px;">{{ item.total_finances_unpaid | money }}</strong>
                      </span>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-card-text v-else class="mt-4 pb-5" style="text-align: center;">
              <h1>Nenhum cliente atendido em {{ date | moment("DD/MM/YYYY") }}</h1>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </Loader>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/default/component/WiLoading/WiLoading'
import DailyReportFinance from './DailyReportFinance'
import DailyReportProducts from './DailyReportProducts'
export default {
  name: 'DailyReport',
  data: () => ({
    dailyReportDialog: false,
    dailyReportDialogDate: null
  }),
  computed: {
    ...mapState('DailyReport', ['loading']),
    date: {
      get () {
        return this.$store.state.DailyReport.date
      },
      set (value) {
        console.log(value)
        this.$store.commit('DailyReport/SET_DATE', value)
      }
    }
  },
  methods: {
    setInitialDailyReportDialogDate: function () {
      this.dailyReportDialogDate = this.date
    },
    setNewDate: function () {
      this.date = this.dailyReportDialogDate
      this.$emit('refresh')
      this.setInitialDailyReportDialogDate()
      this.dailyReportDialog = false
    }
  },
  mounted: function () {
    this.setInitialDailyReportDialogDate()
  },
  props: {
    title: {
      type: String,
      required: true
    },
    finances: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    customers: {
      default: null
    }
  },
  components: {
    DailyReportProducts,
    DailyReportFinance,
    Loader
  }
}
</script>
<style scoped>
  .daily-report__product-title {
    text-align: center;
    color: white;
    background: black;
    padding: 5px 0;
  }
  .daily-report__product-title--text {
    color: white;
    background: black;
  }
</style>
