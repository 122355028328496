export default {
  id: {
    id: '8b84d7d5',
    create: '42a28932',
    update: '9b01dfc7',
    delete: 'bf8c9137'
  },
  list: true,
  title: {
    list: 'Movimentações',
    edit: {
      insert: 'Nova Movimentação no Estoque',
      update: 'Atualizar Movimentação no Estoque'
    }
  },
  icon: 'swap_horiz',
  apiUrl: 'stock/movement',
  search: {
    placeholder: 'Pesquise pelo ID, Nome do produto ou descrição da movimentação.',
    fields: ['item.name', 'item_id', 'description']
  },
  showFilterDateToolbar: true,
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  components: {
    list: {
      tableRow: () => import('@/modules/stock/pages/Stock/StockMovements'),
      tableExpand: () => import('@/modules/stock/pages/Stock/StockMovementsExpand')
    }
  },
  fields: [
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      list: true,
      edit: false,
      search: true
    },
    {
      value: 'date_competence',
      text: 'Data',
      list: true,
      edit: false
    },
    {
      value: 'item_id',
      text: 'ID Prod.',
      type: 'int',
      list: true,
      edit: false,
      search: true
    },
    {
      value: 'item.name',
      text: 'Nome do Produto',
      type: 'text',
      list: true,
      edit: false,
      search: true
    },
    {
      value: 'type',
      text: 'Tipo',
      list: true,
      edit: false
    },
    {
      value: 'quantity',
      text: 'Qtd.',
      type: 'int',
      list: true,
      edit: false,
      search: false
    },
    {
      value: 'new_cost',
      text: 'Novo $ Compra',
      type: 'text',
      list: true,
      edit: false,
      search: false
    },
    {
      value: 'new_amount_sale',
      text: 'Novo $ Venda',
      type: 'text',
      list: true,
      edit: false,
      search: false
    },
    {
      text: 'Informe o estoque, selecione o produto que deseja movimentar e informe uma descrição.',
      type: 'subheader',
      edit: {
        insert: true
      }
    },
    {
      text: 'Informe uma descrição para esta movimentação.',
      type: 'subheader',
      edit: {
        update: true
      }
    },
    {
      value: 'stock_id',
      text: 'Unidade de Estoque*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      disabled: form => !!form.id,
      edit: true,
      apiUrl: 'stock',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    },
    {
      value: 'item_id',
      text: 'Produto',
      size: ['xs12', 'sm12', 'md8', 'lg8'],
      type: 'wi-list-dialog',
      edit: true,
      wiConfig: 'product',
      show: 'item.name',
      icon: 'person_outline'
    },
    {
      value: 'description',
      text: 'Descrição da Movimentação',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'text',
      edit: true
    },
    {
      text: 'Informe o Tipo de Movimentação e a Quantidade.',
      type: 'subheader',
      edit: true,
      size: ['xs12', 'sm12', 'md6', 'lg6']
    },
    {
      text: 'Informe a data e se desejar, novos preços de compra e venda.',
      type: 'subheader',
      edit: true,
      size: ['xs12', 'sm12', 'md6', 'lg6']
    },
    {
      value: 'type',
      text: 'Entrada ou Saída?',
      type: 'select',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [{value: 1, text: 'Entrada'}, {value: 2, text: 'Retirada'}],
      required: true,
      list: false,
      edit: true
    },
    {
      value: 'quantity',
      text: 'Quantidade Movimentada',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      type: 'int',
      list: false,
      edit: true
    },
    {
      value: 'date_competence',
      text: 'Data da Movimentação',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'date',
      required: true,
      list: false,
      edit: true
    },
    {
      value: 'new_cost',
      text: 'Novo Valor de Custo',
      type: 'money',
      edit: true,
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      value: 'new_amount_sale',
      text: 'Novo Valor de Venda',
      type: 'money',
      edit: true,
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }
  ]
}
