<template>
  <v-dialog v-model="dialog" persistent max-width="90%">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-card-text style="text-align: center;">
          <h3>Acessar documento gerado no diagnóstico.</h3>
        </v-card-text>
        <v-card-actions style="padding-bottom: 25px;">
          <v-spacer></v-spacer>
          <v-btn block outline large color="green">
            <v-icon>touch_app</v-icon>
            <span> Acessar agora </span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </template>
    <v-card>
      <v-toolbar dense flat :color="!$wiDark ? '#FFFFFF': '#212121'">
        <v-icon>description</v-icon>
        <v-toolbar-title>
          <span v-text="stage.stage.name"></span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outline small @click="printLayout()">
          <v-icon>print</v-icon>
          <span>Imprimir</span>
        </v-btn>
        <v-btn outline small @click="selectLayout()">
          <v-icon>select_all</v-icon>
          <span>Selecionar Tudo</span>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn> 
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-if="dialog">
        <WiTinyEditor 
          v-if="data.data"
          v-model="data.data"
        ></WiTinyEditor>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import WiTinyEditor from '@/default/component/WiTinyEditor'
  export default {
    name: 'DiagnosticRecreateDocument',
    data: () => ({
      dialog: false
    }),
    methods: {
      updateProcess: function (updateProcess) {
        this.$emit('updateProcess', updateProcess)
      },
      printLayout: function () {
        // eslint-disable-next-line
        tinymce.activeEditor.execCommand('mcePrint');
      },
      selectLayout: function () {
        // eslint-disable-next-line
        this.selectAll(tinymce.activeEditor)
        // eslint-disable-next-line
        tinymce.activeEditor.focus()
      },
      selectAll: function (editor) {
        let range = editor.dom.createRng();
        range.selectNodeContents(editor.getBody());
        editor.selection.setRng(range);
      }
    },
    props: {
      status: {
        type: Object,
        default: () => ({})
      },
      stage: {
        type: Object,
        default: () => ({})
      },
      data: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      WiTinyEditor
    }
  }
</script>
<style scoped>
  .diagnostic-set-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>