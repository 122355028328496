export default {
  id: 'a08f73b0',
  title: 'Alterar Tipo de Ocorrência',
  apiUrl: 'service-desk/ticket/423974878/category',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Selecione abaixo o novo Tipo de Ocorrência para este chamado.', type: 'subheader', edit: true},
    {value: 'chamado_categoria_id', text: 'Novo Tipo de Ocorrência*', placeholder: 'Você pode selecionar um Tipo de Ocorrência ou sub-ocorrência', required: true, fields: false, type: 'list-ticket-category', autocomplete: true, list: false, edit: true, apiUrl: 'chamado/ocorrencia', apiParams: {where: 'ativa_categoria,1;funcao_categoria,>,0;funcao_categoria,<,3', fields: 'id,nome_categoria_grupo_previsao,nome_categoria_grupo,previsao_categoria,icone_categoria,cor_categoria', order: 'nome_categoria_grupo'}, show: 'nome_categoria_grupo_previsao', icon: 'icone_categoria', color: 'cor_categoria'},
    {text: 'Informe se você deseja substituir a previsão atual do chamado pela previsão do novo Tipo de Ocorrência.', type: 'subheader', edit: true},
    {value: 'definir_nova_previsao_categoria', text: 'Alterar a Previsão do Chamado*', type: 'select', required: true, options: [{value: 'sim_alterar', text: 'Usar previsão da Ocorrência'}, {value: 'Nao', text: 'Manter previsão atual'}], list: false, edit: true}
  ],
  fieldsFixed: [
    {
      value: 'alter_categoria_chamado', data: true
    }
  ]
}
