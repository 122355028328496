<template>
  <div>
    <v-dialog v-if="contact && contact.file && contact.file.url" v-model="profileImageDialog" width="450" lazy>
      <template v-slot:activator="{ on }">
        <img
          v-on="on"
          :src="contact.file.url"
          width="100px;"
          style="border-radius: 5px; cursor: pointer;"
          :alt="`Clique para visualizar a imagem de ${contact.name}`"
        />
      </template>
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>
            <v-icon>image</v-icon>
            <span style="padding-left: 10px;">
              <span v-if="contact.person">
                {{contact.person.name}}
              </span>
              <span
                v-else
                style="color: gray;"
                v-html="contact.name"
              ></span>
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="profileImageDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <img
            :src="contact.file.url"
            width="100%"
            style="border-radius: 5px;"
          />
          <TalkContactDescription :contact="contact" :refresh="refresh" style="text-align: center;" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <img
      v-else-if="contact.type == 1"
      src="static/icons/user_icon_1.png"
      width="100px;"
      style="border-radius: 10px 0 10px 0;border: 1px solid grey;"
    />
    <img
      v-else
      src="static/icons/monitor/user-groups-96.png"
      width="100px;"
      style="border-radius: 10px 0 10px 0;border: 1px solid grey;"
    />
  </div>       
</template>
<script>
  import TalkContactDescription from './TalkContactDescription.vue';
  export default {
    name: 'TalkContactPhoto',
    data () {
      return {
        dialog: false,
        profileImageDialog: false
      }
    },
    props: ['contact', 'refresh'],
    components: {
      TalkContactDescription
    },
  }
</script>