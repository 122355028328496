<template>
  <DailyReportPage
    :title="title"
    :finances="finances"
    :products="products"
    :customers="allCustomers"
    @refresh="getData"
  ></DailyReportPage>
</template>

<script>
import { AllDailyReport } from '@/default/service/SellerAppApi/AllDailyReport'
import { mapState, mapActions } from 'vuex'
import DailyReportPage from './DailyReport'
export default {
  name: 'DailyReportAll',
  computed: {
    ...mapState('DailyReport', [
      'customer',
      'customers',
      'financesOpenedTotal',
      'financesPaidToday',
      'financesUnpaidToday',
      'productCustomer',
      'productsEntry',
      'productsRemoval',
      'productsSale',
      'financeSaleToday'
    ]),
    title () {
      return this.customer?.name || ''
    },
    allCustomers () {
      return this.customers || []
    },
    finances () {
      return [
        {
          title: 'Recebimentos do dia',
          finance: this.financesPaidToday
        },
        {
          title: 'Em aberto do dia',
          finance: this.financesUnpaidToday
        },
        {
          title: 'Valor total em aberto',
          finance: this.financesOpenedTotal
        }
      ]
    },
    products () {
      return [
        {
          title: 'Vendas',
          products: this.productsSale
        },
        {
          title: 'Reposições',
          products: this.productsEntry
        },
        {
          title: 'Devoluções',
          products: this.productsRemoval
        },
        // {
        //   title: 'Produtos com o cliente',
        //   products: this.productCustomer
        // }
      ]
    }
  },
  methods: {
    ...mapActions('DailyReport', ['getReportData']),
    getData: function () {
      this.getReportData({
        service: AllDailyReport,
        id: this.$route.params.id,
        action: this.action
      })
    },
    action: function () {
      this.$forceUpdate()
    }
  },
  mounted: function () {
    this.getData()
  },
  watch: {
    '$route': 'getData'
  },
  components: {
    DailyReportPage
  }
}
</script>
