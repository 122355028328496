import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('Talks', [ 'getTalks' ]),
    openTalkContact: function (contact) {
      if (contact) {
        this.createNewTalk(contact)
      }
    },
    createNewTalk: function (contact) {
      this.$WiApiGet({
        uri: `talks/channel`,
        params: {
          fields: 'id,uuid,name,type_id,status_id',
        },
        callback: ({ success }) => {
          if (success && success.data && success.data.length == 0) {
            this.$swal({
              type: 'warning',
              title: 'Você não possui canais de atendimento disponíveis.',
              html: 'Por favor, entre em contato com o administrador do sistema.',
            })
          } else if (success && success.data && success.data.length > 1) {
            this.$WiEditDialog({
              wiConfig: 'talk',
              data: {
                status: 2,
                talk_contact_id: contact.id,
                // talk_channel_id: contact.talk_channel_id,
                operator_id: this.$Auth.user().id,
                responsible_id: this.$Auth.user().id,
                contact: contact
              },
              onSubmit: ({ status, data }) => {
                if (status === 201) {
                  this.$router.push({
                    name: 'talk-view-messages',
                    params: {
                      id: data.id
                    }
                  })
                  this.getTalks(data)
                }
              }
            })
          } else {
            // if (contact?.opened_talks?.length > 0) {
            //   this.$router.push({
            //     name: 'talk-view-messages',
            //     params: {
            //       id: contact.opened_talks[0].id
            //     }
            //   })
            // } else {
              this.$WiApiPost({
                uri: 'talk/talk',
                data: {
                  status: 2,
                  talk_contact_id: contact.id,
                  talk_channel_id: success?.data[0]?.id,
                  operator_id: this.$Auth.user().id,
                  responsible_id: this.$Auth.user().id
                },
                config: {
                  msgLoad: true,
                  msgSucess: true
                },
                callback: (response) => {
                  this.$router.push({
                    name: 'talk-view-messages',
                    params: {
                      id: response.sucess.id
                    }
                  })
                  this.getTalks()
                }
              })
            // }
          }
        }
      })
    }
  }
}
