<template>
  <v-container>
    <v-layout wrap>
      <v-flex style="padding: 0 10px 0 10px; text-align: center;">
        <div style="width: 100%; text-align: center; margin: 20px 0;">
          <img
            class="elevation-10"
            src="static/background/talks-campaigns-placeholder.png"
            style="width: 100%; max-width: 400px;border-radius: 10px;"
          />
        </div>
        <h1>
          <span>Bem-vindo(a) ao módulo de campanhas e envio em massa.</span>
        </h1>
        <div v-if="!$WiCheckPermission('cdf8059b')">
          <h3 class="grey--text mb-3">Este módulo permite realizar o envio de mensagens em escala para seus contatos.</h3>
          <!-- list -->
          <h3 class="grey--text">Alguns dos recursos deste módulo são:</h3>
          <h3>- Envie mensagens para todos os contados de um grupo;</h3>
          <h3>- Envie mensagens para todos os contatos de tags específicas;</h3>
          <h3>- Programe o envio para horários pré-definidos;</h3>
          <h3>- Importe contatos de uma planilha.</h3>
          <h2 class="orange--text mt-3">
            Para realizar a ativação entre em contato com nosso suporte técnico.
          </h2>
        </div>
        <div v-else>
          <h3 class="grey--text mb-3">
            Acessar campanhas e envio em massa.
          </h3>
          <v-btn outline large color="primary" link :to="{ name: 'talk-list', params: { page: 'talk-campaign' } }">
            <v-icon class="mr-2">campaign</v-icon>
            <span>Acessar Campanhas</span>
          </v-btn>
          <v-divider class="mt-3"></v-divider>
          <h2 class="mt-3">Importar contatos:</h2>
          <h3 class="orange--text mt-3">Faça download da planilha modelo <a target="_blank" href="/static/planilhas/ImportarContatos.xlsx">clicando aqui</a>.</h3>
          <h3 class="orange--text mb-3">Faça upload da planilha no botão abaixo:</h3>
          <h3 class="red--text mb-3">Lembre-se de adicionar tags para filtrar os contatos em uma campanha.</h3>
          <v-btn outline large color="blue" @click="importContacts()">
            <v-icon class="mr-2">upload</v-icon>
            <span>Importar contatos</span>
          </v-btn>
          <h3 class="orange--text mt-3">Aguarde alguns minutos até que a importação seja finalizada.</h3>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    name: 'Campaigns',
    methods: {
      importContacts: function () {
        this.$WiEditDialog({
          wiConfig: 'import-talk-contacts'
        })
      }
    }
  }
</script>
