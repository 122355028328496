<template>
  <wi-list :key="store" :store="store" :mode="mode" :set-route="false" :load-on-mounted="!config.notLoadOnMounted" :get-params="params" v-on:select="select" :wi-edit-data="wiEditData" :dataOneSelect="dataOneSelect">
    <template slot-scope="props">
      <v-dialog
        v-model="dialog"
        class="wi-list-dialog" scrollable persistent 
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :max-width="config.maxWidth || defaultMaxWidth"
      >
        <div v-if="componentLoader">
          <component :is="componentLoader" :config="props"></component>
        </div>
        <v-card class="wi-list-dialog__card" v-else style="background: #fafafa;">
          <v-toolbar class="wi-list-dialog__toolbar" :dark="$wiDark" dense primary>
            <v-btn v-if="mode == 'select' && props.selected.length > 0" outline color="green" @click="props.select(props.selected)">
              <v-icon large>done</v-icon>
              <span>confirmar ({{props.selected.length}})</span>
            </v-btn>
            <v-toolbar-title v-else>
              <v-icon v-if="props.icon">{{ props.icon }}</v-icon>
              <span> {{ props.title }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="hidden-xs-only hidden-sm-only" style="min-width: 350px;">
              <div class="text-xs-center">
                <wi-list-input :config="props" :solo="true"></wi-list-input>
              </div>
            </div>
            <v-spacer></v-spacer>
            <WiListImports
              v-if="config && config.imports"
              :imports="config.imports"
              @imported="props.refresh"
            ></WiListImports>
            <v-btn
              v-if="button"
              v-prms="{'id': getIdPermission(), mode: 'disabled'}"
              outline
              small
              @click="button.action(props)"
            >
              <span v-text="button.text || 'Adicionar novo'"></span>
            </v-btn>
            <v-btn
              class="hidden-xs-only hidden-sm-only"
              v-prms="{'id': getIdPermission()}" 
              v-if="wiEditData && checkButtonConfig('create')" 
              @click="$WiEditDialog({wiConfig: store, onSubmit: props.refresh, data: wiEditData, redirectOnSubmit: false})"
              outline
              small
            >
              <v-icon>add</v-icon>
              Adicionar Novo
            </v-btn>
            <v-btn
              class="hidden-md-only hidden-lg-only hidden-xl-only"
              v-prms="{'id': getIdPermission()}" 
              v-if="wiEditData && checkButtonConfig('create')" 
              @click="$WiEditDialog({wiConfig: store, onSubmit: props.refresh, data: wiEditData, redirectOnSubmit: false})"
              icon
            >
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn class="hidden-md-only hidden-lg-only hidden-xl-only" icon small @click="props.searchDialogOpen()">
              <v-icon>search</v-icon>
            </v-btn>
            <WiListInputDialog :config="props"></WiListInputDialog>

            <v-btn icon flat :loading="props.loading" @click="props.refresh()">
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-btn icon flat @click="closeList()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="padding: 0;">
            <v-layout row :style="{background: $wiDark ? '#303030 !important' : ''}" >
              <v-flex v-bind="sizeField(config.size)">
                <finances-header v-if="config.showFilterDateToolbar" class="card--flex-toolbar" :config="props" :set-route="false"></finances-header>
                <!-- <component class="card--flex-toolbar" v-if="componentLoaderHeader" :is="componentLoaderHeader" :config="props" :set-route="false"></component> -->
                <br>
                <v-card>
                  <wi-list-data-table v-if="componentLoaderTableRow" :config="props" :row-custom="true" :row-actions="true" :set-route="false" :selected-action-custom="config.components && config.components.list && config.components.list.selectedAction ? true : false">
                    <template slot="table-row" slot-scope="rowData">
                      <component
                        :is="componentLoaderTableRow"
                        :config="props"
                        :props="rowData"
                         :loading="props.loading"
                      ></component>
                    </template>
                    <template slot="table-expand" slot-scope="rowData">
                      <component
                        :is="componentLoaderTableExpand"
                        :config="props"
                        :props="rowData"
                         :loading="props.loading"
                      ></component>
                    </template>
                    <template slot="table-selected-action" slot-scope="data">
                      <component
                        :is="componentLoaderTableSelectedAction"
                        :config="props"
                        :selected="data.selected"
                         :loading="props.loading"
                      ></component>
                    </template>
                  </wi-list-data-table>
                  <wi-list-data-table v-else :config="props" :row-custom="false" :row-actions="true" :set-route="false"></wi-list-data-table>
                </v-card><br><br>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-toolbar class="wi-list-dialog__toolbar" :dark="$wiDark" dense>
            <wi-list-pagination
              :config="props" :set-route="false"
            ></wi-list-pagination>
          </v-toolbar>
        </v-card>
      </v-dialog>
    </template>
  </wi-list>
</template>
<script>
  import WiList from '@/default/component/WiList/WiList'
  import WiListInput from '@/default/component/WiList/WiListInput'
  import WiListDataTable from '@/default/component/WiList/WiListDataTable'
  import WiListPagination from '@/default/component/WiList/WiListPagination'
  import FinancesHeader from '@/modules/finance/pages/Finances/FinancesHeader'
  import WiListImports from './Imports'
  import WiListInputDialog from './WiListInputDialog'
  export default {
    name: 'Listing',
    data () {
      return {
        loadTable: false
      }
    },
    methods: {
      sizeField: function (sizes) {
        var size = sizes ? {} : {xs12: true, sm12: true, md10: true, 'offset-md1': true, ls10: true, 'offset-ls1': true, xl10: true, 'offset-xl1': true}
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      select: function (selected) {
        this.$store.dispatch('WiListDialog/close', {index: this.index, selected: selected})
      },
      closeList: function () {
        this.$store.dispatch('WiListDialog/close', {index: this.index, selected: null})
      },
      getIdPermission: function () {
        if (this.config && this.config.id) {
          return this.config.id['create'] || this.config.id
        }
        return null
      },
      checkButtonConfig: function (action) {
        if (this.config.actions) {
          if (typeof this.config.actions[action] === 'function') {
            return this.config.actions[action](this.form)
          } else {
            return this.config.actions[action]
          }
        }
        return true
      }
    },
    computed: {
      WiListDialog () {
        return this.$store.state.WiListDialog
      },
      store () {
        return this.WiListDialog.lists[this.index].wiConfig
      },
      params () {
        return this.WiListDialog.lists[this.index].params
      },
      mode () {
        return this.WiListDialog.lists[this.index].mode
      },
      dialog () {
        return this.WiListDialog.lists[this.index].active
      },
      button () {
        return this.WiListDialog.lists[this.index].button
      },
      wiEditData () {
        return this.WiListDialog.lists[this.index].wiEditData || {}
      },
      dataOneSelect () {
        return this.WiListDialog.lists[this.index].dataOneSelect || false
      },
      config () {
        return this.$store.state.pages[this.store]
      },
      system () {
        return this.$store.state.system || []
      },
      componentLoader () {
        if (this.config.components && this.config.components.list && typeof this.config.components.list === 'function') {
          return this.config.components.list
        }
        return false
      },
      componentLoaderHeader () {
        if (this.config.components && this.config.components.list && this.config.components.list.header && typeof this.config.components.list.header === 'function') {
          return this.config.components.list.header
        }
        return false
      },
      componentLoaderTableRow () {
        if (this.config.components && this.config.components.list && this.config.components.list.tableRow && typeof this.config.components.list.tableRow === 'function') {
          return this.config.components.list.tableRow
        }
        return false
      },
      componentLoaderTableExpand () {
        if (this.config.components && this.config.components.list && this.config.components.list.tableExpand && typeof this.config.components.list.tableExpand === 'function') {
          return this.config.components.list.tableExpand
        }
        return false
      },
      componentLoaderTableSelectedAction () {
        if (this.config.components && this.config.components.list && this.config.components.list.selectedAction && typeof this.config.components.list.selectedAction === 'function') {
          return this.config.components.list.selectedAction
        }
        return false
      },
      defaultMaxWidth () {
        return window.innerWidth < 1370 ? ((95 - (this.index * 2)) + '%') : '1188px'
      }
    },
    watch: {
      '$route': 'closeList'
    },
    props: {
      'index': {
        default: 0
      }
    },
    components: {
      'WiList': WiList,
      'WiListInput': WiListInput,
      'WiListDataTable': WiListDataTable,
      'WiListPagination': WiListPagination,
      'FinancesHeader': FinancesHeader,
      'WiListImports': WiListImports,
      'WiListInputDialog': WiListInputDialog
    }
  }
</script>
<style>
  .v-btn--bottom.v-btn--absolute.wi_list_modal {
    bottom: 60px !important;
  }
  .v-dialog--scrollable {
    overflow-y: unset !important;
  }
  .wi-list-dialog__card {
    border-radius: 10px;
  }
  .wi-list-dialog__toolbar {
    border-radius: 10px;
  }
</style>
