export const showFormFieldVerification = field => {
  if (field.show_requirement && Object.keys(field.show_requirement).length > 0) {
    return form => {
      let show = true
      for (const key in field.show_requirement) {
        if (!form[key] || form[key] != field.show_requirement[key]) {
          show = false
        }
      }
      return show
    }
  }
  return undefined
}