import { Model } from '@/default/model/Model'

export class Invoice extends Model {
  constructor () {
    super()
    this.list = true
    this.id = 'dc1919bd'
    this.actions = {
      list: true
    }
    this.name = 'invoice'
    this.title = 'Notas Fiscais',
    this.apiUrl = 'finance/invoice'
    this.apiParams = {'with': 'file'}
    this.icon = 'request_quote'
    this.search = {
      placeholder: 'Pesquise número ou código de integração',
      fields: ['id', 'number', 'integration_id']
    }
    this.components.list.tableRow = () => import('@/modules/finance/pages/Invoices/Invoices')
    this.maxWidth = '800px'
    this.size = ['xs12', 'sm12', 'md12', 'ls8', 'xl6']
    this.setFields([
      this.fieldList({
        value: 'number',
        text: 'Número'
      }),
      this.fieldList({
        value: 'amount',
        text: 'Valor'
      }),
      this.fieldList({
        value: 'emited_at',
        text: 'Data de Emissão'
      }),
      this.fieldList({
        value: 'service_date',
        text: 'Data da prestação de serviço'
      })
    ])
  }
}
