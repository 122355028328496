<template>
  <v-card elevation="15">
    <v-toolbar dense color="black" class="daily-report__product-title--text white--text">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span>
        {{ products.quantity }}
      </span>
    </v-toolbar>
    <Products :products="products.products"></Products>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import Products from './ProductsQuantity'
export default {
  name: 'DailyReportProducts',
  props: {
    title: {
      type: String,
      required: true,
      default: '- - -'
    },
    products: {
      required: true,
      default: () => ([])
    }
  },
  components: {
    Products
  }
}
</script>
<style>
  .daily-report__product-title--text {
    color: white;
    background: black;
  }
</style>
