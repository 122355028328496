<template>
  <view-detail apiUrl="business/budget" apiParams="?with=items;items.item:id,name,item_unit_id;items.item.unit:id,code;person:id,name,first_name,cpf_cnpj,rg,state_number,site;person.adresses.city.state:id,code;person.adresses:id,address,number,district,zip_code,complement,type,is_master,person_id,city_id;person.adresses.city:id,name,state_id;person.phones;company:id,name,first_name,cpf_cnpj,rg,state_number,site;company.adresses.city.state:id,code;company.adresses:id,address,number,district,zip_code,complement,type,is_master,person_id,city_id;company.adresses.city:id,name,state_id;company.phones" :notShowToolbar="true">
    <template slot-scope="dados">
      <!-- eslint-disable -->
      <div style="width: 794px; height: 1123px; background: white;">
        <table style="height: 30px;" width="100%">
          <tbody>
            <tr style="">
              <td style="width: 20%; height: 30px; padding: 5px 5px 0 5px; border-style: solid; border-width: 1px;">
                <img :src="logo" style="width: 100%;" />
              </td>
              <td style="width: 50%; height: 30px; font-size: 12px; text-align: center; padding: 0px !important; border-style: solid; border-width: 1px;">
                <p>
                  <strong><span v-if="dados.content.company && dados.content.company.first_name">{{dados.content.company.first_name}}</span></strong><br />
                  <span v-if="dados.content && dados.content.company && dados.content.company.adresses && dados.content.company.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.company.adresses">
                    <span v-if="address.is_master">{{address.address + ', ' + address.number}}</span><br>
                    <span>{{address.city.name + '-' + address.city.state.code}}  CEP {{address.zip_code}}</span><br />
                    <span>
                      <span>CNPJ: </span>
                      <span v-if="dados.content.company.cpf_cnpj">{{dados.content.company.cpf_cnpj || '- - -'}}</span>
                    </span><br />
                    <span v-if="dados.content.company.phones && dados.content.company.phones.length > 0">
                      <span>Telefone(s): </span>
                      <span v-for="(contact, contatoKey) in dados.content.company.phones" :key="contatoKey">
                        <span v-if="contatoKey"> / </span>
                        <span v-if="contact.type == 'Celular'">{{contact.contact | mask('(##) # ####-####') || '- - -'}}</span>
                        <span v-if="contact.type == 'Telefone'">{{contact.contact | mask('(##) ####-####') || '- - -'}}</span>
                      </span>
                    </span><br />
                    <span v-if="dados.content.company.site">Site: {{dados.content.company.site}}</span>
                  </span>
                </p>
              </td>
              <td style="width: 30% !important; height: 30px; vertical-align: top; text-align: center; font-size: 12px; padding: 0 !important; border-style: solid; border-width: 1px;">
                <table style="height: 72px; top: 0;" width="100%">
                  <tbody>
                    <tr>
                      <td style="width: 100%; border-style: solid; border-width: 1px;">
                        N°: {{dados.content.id}}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 100%; border-style: solid; border-width: 1px;">
                        Código Externo: {{dados.content.external_code || '- - -'}}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 100%; border-style: solid; border-width: 1px;">
                        <span v-text="config.date_start || 'Emissão'"></span>: {{dados.content.date_start_format}}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 100%; border-style: solid; border-width: 1px;">
                        <span v-text="config.dateName"></span>: <span v-text="dados.content[config.dateField]"></span>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 100%; border-style: solid; border-width: 1px;">
                        Responsável: {{dados.content.collaborator.name}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 0px 2px;">
          <div style="width: 100%; background: lightgray; padding: 5px; text-align: center;"><strong>Dados do cliente:</strong></div>
        </div>
        <table width="100%">
          <tbody>
            <tr style="height: 20px; font-size: 12px; ">
              <td colspan="3" style="width: 60%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">Nome/Razão Social: </label>
                <span>{{dados.content.person.first_name}}</span>
              </td>
              <td style="width: 40%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <strong v-if="dados.content && dados.content.category">
                  {{ dados.content.category.name }}
                </strong>
              </td>
            </tr>
            <tr style="height: 20px; font-size: 12px; ">
              <td colspan="3" style="width: 70%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">Endereço: </label>
                <span v-if="dados.content && dados.content.person && dados.content.person.adresses && dados.content.person.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.person.adresses">
                  <span v-if="enderecoKey"> / </span>
                  <span v-if="dados.content.person.adresses.length == 1 || address.is_master">
                    <span>{{address.address}} </span>
                    <span v-if="address.number">{{', ' + address.number}}</span>
                  </span>
                </span>
                <div v-else>- - -</div>
              </td>
              <td style="width: 30%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">Bairro: </label>
                <span v-if="dados.content && dados.content.person && dados.content.person.adresses && dados.content.person.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.person.adresses">
                  <span v-if="enderecoKey"> / </span>
                  <span v-if="dados.content.person.adresses.length == 1 || address.is_master">{{address.district}}</span>
                </span>
                <div v-else>- - -</div>
              </td>
            </tr>
            <tr style="height: 20px; font-size: 12px; ">
              <td style="width: 40%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">Cidade: </label>
                <span v-if="dados.content && dados.content.person && dados.content.person.adresses && dados.content.person.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.person.adresses">
                  <span v-if="enderecoKey"> / </span>
                  <span v-if="dados.content.person.adresses.length == 1 || address.is_master">{{address.city.name + ' - ' + address.city.state.code}}</span>
                </span>
                <span v-else>- - -</span>
              </td>
              <td style="width: 10%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">UF: </label>
                <span v-if="dados.content && dados.content.person && dados.content.person.adresses && dados.content.person.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.person.adresses">
                  <span v-if="enderecoKey"> / </span>
                  <span v-if="dados.content.person.adresses.length == 1 || address.is_master">{{address.city.state.code || '- - -'}}</span>
                </span>
                <span v-else>- - -</span>
              </td>
              <td style="width: 15%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">CEP: </label>
                <span v-if="dados.content && dados.content.person && dados.content.person.adresses && dados.content.person.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.person.adresses">
                  <span v-if="enderecoKey"> / </span>
                  <span v-if="dados.content.person.adresses.length == 1 || address.is_master">{{address.zip_code || '- - -'}}</span>
                </span>
                <span v-else>- - -</span>
              </td>
              <td style="width: 35%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">Complemento: </label>
                <span v-if="dados.content && dados.content.person && dados.content.person.adresses && dados.content.person.adresses.length > 0" v-for="(address, enderecoKey) in dados.content.person.adresses">
                  <span v-if="enderecoKey"> / </span>
                  <span v-if="dados.content.person.adresses.length == 1 || address.is_master">{{address.complement || '- - -'}}</span>
                </span>
                <span v-else>- - -</span>
              </td>
            </tr>
            <tr style="height: 20px; font-size: 12px; ">
              <td colspan="2" style="width: 50%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">Telefones: </label>
                <span v-if="dados.content.person.phones && dados.content.person.phones.length > 0">
                  <span v-for="(contact, contatoKey) in dados.content.person.phones" :key="contatoKey">
                    <span v-if="contact.type == 'Celular'">{{contact.contact | mask('(##) # ####-####') || '- - -'}}</span>
                    <span v-if="contact.type == 'Telefone'">{{contact.contact | mask('(##) ####-####') || '- - -'}}</span>
                  </span>
                </span>
                <span v-else style="padding-left: 3px;">- - -</span>
              </td>
              <td style="width: 25%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">CPF/CNPJ: </label>
                <span v-if="dados.content.person">{{dados.content.person.cpf_cnpj || '- - -'}}</span>
              </td>
              <td style="width: 25%; height: 20px; border-style: solid; border-width: 1px; padding-left: 5px;">
                <label style="color: gray;">RG/IE: </label>
                <span>{{dados.content.person.rg || '- - -'}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="dados.content.description">
          <div style="padding: 0px 2px;">
            <div style="width: 100%; background: lightgray; padding: 5px; text-align: center;"><strong>Descrição: </strong></div>
          </div>
          <div style="width: 100%; padding: 5px;">
            <div v-html="dados.content.description"></div>
          </div>
        </div>
        <div style="padding: 0px 2px;">
          <div style="width: 100%; background: lightgray; padding: 5px; text-align: center;">
            <strong>Dados dos 
              <span v-if="!config.hideProducts">Produtos e</span> 
              Serviços:
            </strong>
          </div>
        </div>
        <div style="padding: 5px 0">
          <table width="100%">
            <tbody>
              <tr style="height: 20px; font-size: 12px; text-align: left;">
                <th style="width: 5%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>COD.</span>
                </th>
                <th style="width: 5%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>Qtd.</span>
                </th>
                <th style="width: 5%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>Unid.</span>
                </th>
                <th style="width: 30%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span><span v-if="!config.hideProducts">Produto/</span> Serviço</span>
                </th>
                <th style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>Desc.</span>
                </th>
                <th style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>Val. Unit.</span>
                </th>
                <th style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>Desconto</span>
                </th>
                <th style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid;">
                  <span>Val. Total</span>
                </th>
              </tr>
              <tr v-for="(item, keyItem) in dados.content.items" style="height: 20px; font-size: 11px;">
                <td style="width: 5%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.item.id}}</span>
                </td>
                <td style="width: 5%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.quantity}}</span>
                </td>
                <td style="width: 5%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.item.unit.code}}</span>
                </td>
                <td style="width: 30%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.item.name}}</span>
                </td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span v-if="item.category && item.category.name">
                    <span>{{ item.category.name }}</span>
                    <span v-if="item.description"> - </span>
                  </span>
                  <span>{{ item.description }}</span>
                </td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.amount_unit | money}}</span>
                </td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.amount_discount | money}}</span>
                </td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-bottom: 1px solid lightgray;">
                  <span>{{item.amount_total | money}}</span>
                </td>
              </tr>
              <tr style="height: 20px; font-size: 11px;">
                <td style="width: 5%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;">
                  <strong>Total:</strong>
                </td>
                <td colspan="2" style="width: 5%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;">
                  <strong>{{dados.content.sum_quantity_unit}}</strong>
                </td>
                <td style="width: 30%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;"></td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;"></td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;">
                  <strong>{{dados.content.sum_amount_unit | money}}</strong>
                </td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;">
                  <strong>{{dados.content.sum_discount_unit | money}}</strong>
                </td>
                <td style="width: 15%; height: 10px; padding-left: 5px; border-top: 1px solid; border-bottom: 1px solid;">
                  <strong>{{dados.content.sum_amount_total | money}}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="padding: 0px 2px;">
          <table style="height: 30px; padding: 5px; background-color: lightgray;" width="100%">
            <tbody>
              <tr style="">
                <td style="width: 60%; height: 30px; font-size: 12px; vertical-align: top; text-align: left; padding: 5px 0 0 10px; background-color: white;">
                  <strong>Observações</strong>: <br>
                  {{dados.content.observation}}
                </td>
                <td style="width: 40%; padding: 0; margin: 0; height: 30px; vertical-align: top; text-align: right; font-size: 12px; padding: 0 !important;">
                  <table width="100%" style="border-spacing: 0px 2px 2px 2px;">
                    <tbody>
                      <tr>
                        <td style="width: 60%; padding-right: 5px;">
                          <strong>Soma:</strong>
                        </td>
                        <td style="width: 40%; padding-right: 5px; border-bottom: 5px solid lightgray; background-color: white;">
                          {{dados.content.sum_amount_total | money}}
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 60%; padding-right: 5px;">
                          <strong>Desconto:</strong>
                        </td>
                        <td style="width: 40%; padding-right: 5px; border-bottom: 5px solid lightgray; background-color: white;">
                          {{dados.content.amount_discount | money}}
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 60%; padding-right: 5px;">
                          <strong v-text="config.amount_freight || 'Frete'"></strong>:
                        </td>
                        <td style="width: 40%; padding-right: 5px; border-bottom: 5px solid lightgray; background-color: white;">
                          {{dados.content.amount_freight | money}}
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 60%; padding-right: 5px;">
                          <strong>Total:</strong>
                        </td>
                        <td style="width: 40%; padding-right: 5px; border-bottom: 5px solid lightgray; background-color: white;">
                          {{dados.content.total_liquid | money}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="dados.content.information" style="padding: 0px 2px; margin-top: 8px;">
          <div style="width: 100%; background: lightgray; padding: 5px; text-align: center;"><strong>Forma de Pagamento:</strong></div>
        </div>
        <div v-if="dados.content.information" style="width: 100%; padding: 5px; text-align: center;">
          <div style="width: 100%; padding: 5px;">
            <div v-html="dados.content.information"></div>
          </div>
        </div>
        <div>
          <v-container fluid>
            <v-layout row wrap>
              <v-flex xs12 sm12 md8 lg8 offset-md2 offset-lg2>
                <div style="width: 100%; text-align: center; border-top-style: solid; border-width: 1px; margin-top: 30px;">
                  <span>{{dados.content.person.first_name}}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>
    </template>
  </view-detail>
</template>
<script>
  export default {
    name: 'NegocioReport',
    data () {
      return {}
    },
    computed: {
      logo () {
        const url = this.$Auth.user().account
        return url.print_image
      },
      config () {
        // eslint-disable-next-line
        const business = this.$route.params.business
        switch (business) {
          case 'budget':
            return {
              type: 1,
              store: business,
              module: 'sales',
              dateField: 'date_validity_format',
              dateName: 'Validade'
            }
          case 'sale':
            return {
              type: 4,
              store: business,
              module: 'sales',
              dateField: 'date_end_format',
              dateName: 'Entrega'
            }
          case 'purchase':
            return {
              type: 3,
              store: business,
              module: 'purchases',
              dateField: 'date_end_format',
              dateName: 'Entrega'
            }
          case 'quotation':
            return {
              type: 2,
              store: business,
              module: 'purchases',
              dateField: 'date_validity_format',
              dateName: 'Validade'
            }
          case 'order':
            return {
              type: 5,
              store: business,
              module: 'service-desk',
              dateField: 'date_end_format',
              dateName: 'Entrega'
            }
          case 'process':
            return {
              type: 7,
              store: business,
              module: 'juridical',
              date_end: 'Previsão',
              amount_freight: 'Valor Adicional',
              hideProducts: true,
              date_start: 'Entrevista',
              dateField: 'date_end_format',
              dateName: 'Previsão'
            }
        }
        return {}
      }
    }
  }
</script>
<style>
  p {
    margin-bottom: 0px !important;
  }
</style>
