<template>
  <WiView
    index="host-detail-dialog"
    title="Detalhes do Dispositivo"
    apiUrl="networks/device"
    store="devices"
    apiParams="?with=childs;childs.childs;childs.childs.parent;childs.hosts;childs.childs.hosts"
  >
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh }">
      <v-btn outline small color="white" @click="updateDeviceHostsList(content, refresh)">
        Atualizar Dispositivo
      </v-btn>
    </template>
    <template slot-scope="{ content }">
      <v-container>
        <v-layout row class="pb-3">
          <v-flex class="pl-2 pr-2">
            <v-card>
              <v-card-text class="device__header">
                <div v-if="content.producer.logo" class="device__header-logo">
                  <img
                    :src="`static/network/producers/logos/${content.producer.logo}`"
                    :alt="content.producer.name"
                    style="max-width: 150px;"
                  />
                </div>
                <div class="device__header-info">
                  <div>
                    <v-subheader style="padding: 0 0 10px 0; height: unset;">
                      {{ content.type.name }} / {{ content.producer.name }} 
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-subheader style="padding: 10px 0 0 0; height: unset;">
                      {{ content.network_names }}
                    </v-subheader>
                    <div class="device__header-name">
                      <h2>{{ content.nome_disp }}</h2>
                      <v-subheader style="padding: 0; height: unset; font-size: 18px;">
                        IP: {{ content.ip_disp }}
                      </v-subheader>
                    </div>
                  </div>
                  <div class="device__cards">
                    <v-card class="device__statistic-card" color="blue">
                      <div class="device__statistic-card-value">
                        {{ content.hosts_count | number }}
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon small color="white">person</v-icon>
                        <span> Total de clientes</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="100"
                          color="white"
                          height="5"
                        ></v-progress-linear>
                      </div>
                    </v-card>
                    <v-card class="device__statistic-card" :color="getColor(content.offline_percentage, 'red')">
                      <div class="device__statistic-card-value">
                        {{ content.offline_percentage }}% ({{ content.hosts_offline_count | number }})
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon small color="white">power_settings_new</v-icon>
                        <span> Offline</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="content.offline_percentage"
                          color="white"
                          height="5"
                        ></v-progress-linear>
                      </div>
                    </v-card>
                    <v-card class="device__statistic-card" :color="getColor(content.signal_is_not_ok_percentage, 'orange')">
                      <div class="device__statistic-card-value">
                        {{ content.signal_is_not_ok_percentage }}% ({{ content.hosts_signal_is_not_ok_count | number }})
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon v-if="content.hosts_signal_is_not_ok_count == 0" small color="white">thumb_up</v-icon>
                        <v-icon v-else small color="white">thumb_down</v-icon>
                        <span> Sinal Ruim</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="content.signal_is_not_ok_percentage"
                          color="white"
                          height="5"
                          rounded
                        ></v-progress-linear>
                      </div>
                    </v-card>
                    <v-card class="device__statistic-card" :color="getColor(content.hosts_best_signal, 'green')">
                      <div class="device__statistic-card-value">
                        {{ content.hosts_best_signal || '- - -' }}
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon small color="white">done</v-icon>
                        <span> Melhor sinal</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="100"
                          color="white"
                          height="5"
                        ></v-progress-linear>
                      </div>
                    </v-card>
                  </div>
                  <div class="device__cards">
                    <v-card class="device__statistic-card" :color="getColor(content.tickets_opened_count, '#81c2f6')" @click="openTicketsList()">
                      <div class="device__statistic-card-value">
                        {{ content.tickets_opened_count | number }}
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon small color="white">person</v-icon>
                        <span> Chamados em aberto</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="100"
                          color="white"
                          height="5"
                        ></v-progress-linear>
                      </div>
                    </v-card>
                    <v-card class="device__statistic-card" :color="getColor(content.mttr, '#f77d74')">
                      <div class="device__statistic-card-value">
                        {{ content.mttr | number }}
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon small color="white">timer</v-icon>
                        <span> MTTR (últimas 48h)</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="content.availability"
                          color="white"
                          height="5"
                        ></v-progress-linear>
                      </div>
                    </v-card>
                    <v-card class="device__statistic-card" :color="getColor((content.mtbf - 48), '#ffc064')">
                      <div class="device__statistic-card-value">
                        {{ content.mtbf | number }}
                      </div>
                      <div class="device__statistic-card-title">
                        <v-icon v-if="content.hosts_signal_is_not_ok_count == 0" small color="white">thumb_up</v-icon>
                        <v-icon v-else small color="white">manage_history</v-icon>
                        <span> MTBF (últimas 48h)</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="content.availability"
                          color="white"
                          height="5"
                          rounded
                        ></v-progress-linear>
                      </div>
                    </v-card>
                    <v-card class="device__statistic-card" color="#8de690" style="color: green !important;">
                      <div class="device__statistic-card-value">
                        {{ content.availability || '- - -' }}%
                      </div>
                      <div class="device__statistic-card-title" style="color: green;">
                        <v-icon small color="green">new_releases</v-icon>
                        <span> Availability</span>
                      </div>
                      <div class="device__statistic-card-progress">
                        <v-progress-linear
                          :value="content.availability"
                          color="white"
                          height="5"
                        ></v-progress-linear>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 sm12 md12 lg7 class="pl-2 pr-2">
            <v-card>
              <div style="padding: 10px; display: flex; justify-content: space-between; font-weight: bold;">
                <div style="text-align: left; width: 25%;">Interface</div>
                <div style="text-align: center; width: 25%;">Offline</div>
                <div style="text-align: center; width: 25%;">Sinal Ruim</div>
                <div style="text-align: center; width: 25%;">Melhor sinal</div>
              </div>
              <v-treeview
                :open.sync="open"
                :active.sync="active"
                :items="sortItems(content.childs)"
                activatable
                open-on-click
                item-key="id"
                return-object
                selected-color="orange"
                item-children="childs"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon>
                    {{ item.type.icon }}
                  </v-icon>
                </template>
                <template v-slot:label="{ item, selected }">
                  <label class="device__childs device__child-label" :class="{ 'device__childs--selected': selected }" :style="{ textDecoration: item.hosts_count < 1 ? 'line-through' : 'bold', color: item.hosts_count < 1 ? '#b9b9b9 !important' : 'black' }">
                    <div class="device__child-label-item" style="text-align: left;">
                      <span> {{ item.type.name }} </span>
                      <span> {{ item.name }} </span>
                      <span> ({{ item.hosts_count }}) </span>
                    </div>
                   
                    <div class="device__child-label-item" :style="{ color: getColor(item.hosts_offline_count, 'red') }">
                      <span> {{ item.offline_percentage }}% </span>
                      <span> ({{ item.hosts_offline_count }})</span>
                    </div>
                    <div class="device__child-label-item" :style="{ color: getColor(item.hosts_signal_is_not_ok_count, 'orange') }">
                      <span> {{ item.signal_is_not_ok_percentage }}% </span>
                      <span> ({{ item.hosts_signal_is_not_ok_count }})</span>
                    </div>
                    <div class="device__child-label-item green--text">
                      <span> {{ item.hosts_best_signal || '- - -' }} </span>
                    </div>
                    
                  </label>
                  <v-divider></v-divider>
                </template>
              </v-treeview>
            </v-card>
          </v-flex>
          <v-dialog v-model="dialog" max-width="500px">
            <v-layout row>
              <v-flex xs12 sm12 md12 lg12 d-flex text-xs-center>
                <v-scroll-y-transition mode="out-in">
                  <v-card v-if="selected">
                    <v-toolbar dense flat color="transparent">
                      <v-toolbar-title>
                        <span>Detalhes da interface</span>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="active = []">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-subheader style="justify-content: center;text-align: center; padding: 0 0 10px 0; height: unset;">
                        {{ content.type.name }} / {{ content.producer.name }} 
                      </v-subheader>
                      <h2>{{ content.nome_disp }}</h2>
                      <v-subheader style="justify-content: center;text-align: center; padding: 0; height: unset;">
                        IP: {{ content.ip_disp }}
                      </v-subheader>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-avatar>
                        <v-icon v-if="selected.type && selected.type.icon" x-large>
                          {{ selected.type.icon }}
                        </v-icon>  
                      </v-avatar>
                      <h3 class="headline">
                        <label v-if="selected.parent">
                          <span> {{ selected.parent.type.name }} </span>
                          <span> {{ selected.parent.name }} </span>/
                        </label>
                        <label>
                          <span> {{ selected.type.name }} </span>
                          <span> {{ selected.name }} </span>
                        </label>
                      </h3>
                      <h4>
                        <span v-if="selected.hosts && selected.hosts.length > 0">
                          <span>Melhor sinal: </span>
                          <span v-html="getHostsSignalDescription(selected.hosts)"></span>
                          <span> com sinal ruim ou offline</span>
                        </span>
                      </h4>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                      <h2>Clientes: {{ selected.hosts_count | number }}</h2>
                      <h2>
                        <span class="device__child-label-item red--text">
                          <span>Offline: </span>
                          <span> {{ selected.offline_percentage }}% </span>
                          <span> ({{ selected.hosts_offline_count }})</span>
                        </span>
                      </h2>
                      <h2>
                        <span class="device__child-label-item orange--text">
                          <span>Sinal Ruim: </span>
                          <span> {{ selected.signal_is_not_ok_percentage }}% </span>
                          <span> ({{ selected.hosts_signal_is_not_ok_count }})</span>
                        </span>
                      </h2>
                      <h2>
                        <span class="device__child-label-item green--text">
                          <span>Melhor sinal: </span>
                          <span> {{ selected.hosts_best_signal || '- - -' }} </span>
                        </span>
                      </h2>
                      <v-btn outline @click="hostsDialog = true">
                        <v-icon>router</v-icon>
                        <span> Listar Equipamentos/Clientes</span>
                      </v-btn>
                    </v-card-text>
                    <!-- 
                    <v-data-table :headers="headers" :items="selected.hosts" class="elevation-1">
                      <template v-slot:items="{ item }">
                        <td>{{ item.host.mac_address }}</td>
                        <td>{{ item.host.signal }}</td>
                        <td>{{ item.host.fat }}</td>
                      </template>
                    </v-data-table> 
                    -->
                    <DeviceHostsDialog
                      v-model="hostsDialog"
                      :device="content"
                      :child="selected"
                    ></DeviceHostsDialog>
                  </v-card>
                </v-scroll-y-transition>
              </v-flex>
            </v-layout>
          </v-dialog>
        </v-layout>
      </v-container>
    </template>
  </WiView>
</template>
<script>
import DeviceHostsDialog from './DeviceHostsDialog'
export default {
  name: 'Device',
  data: () => ({
    hostsDialog: false,
    tree: [],
    active: [],
    open: []
  }),
  computed: {
    selected () {
      return this.active[0]
    },
    dialog: {
      get () {
        return !!this.selected
      },
      set (value) {
        if (!value) {
          this.active = []
        }
      }
    }
  },
  methods: {
    formatItems: function (childs) {
      return childs?.map(child => ({
        children: child.childs,
        ...child
      })) || []
    },
    getHostsSignalDescription: function (hosts) {
      let minSignal = -100;
      let signalIsNotOkPercent = 0;
      hosts.forEach(host => {
        if ((typeof host.signal == 'number') && host.signal < 0 && host.signal > minSignal) {
          minSignal = host.signal
        }
        if (host.signal_is_ok === false) {
          signalIsNotOkPercent++
        }
      })
      signalIsNotOkPercent = (signalIsNotOkPercent / hosts.length) * 100
      // number filter
      signalIsNotOkPercent = signalIsNotOkPercent.toFixed(2)
      const percentColor = signalIsNotOkPercent > 0 ? ( signalIsNotOkPercent > 5 ? 'red' : 'orange' ) : 'green'
      return `<span style="color:green;">${minSignal} dBm</span> | <span style="color:${percentColor};">${signalIsNotOkPercent}%</span>`
    },
    updateDeviceHostsList: function (content, refresh) {
      this.$WiApiGet({
        uri: `network/device/${content.id}/update-hosts`,
        config: {
          msgAsk: true,
          msgSucess: true,
          msgError: true,
          msgLoad: true
        },
        callback: ({ success }) => {
          if (success && refresh) {
            refresh()
          }
        }
      })
    },
    sortItems: function (childs) {
      return childs?.map(child => ({
        children: this.sortItems(child.childs),
        ...child
      })) || []
    },
    getColor: function (value, color) {
      return value == 0 ? 'green' : color
    },
    openTicketsList: function () {
      this.$WiListDialog({
        wiConfig: 'ticket-list',
        filter: {
          device: this.$route.params.id,
          stage: 'opened'
        }
      })
    }
  },
  components: {
    DeviceHostsDialog
  }
}
</script>
<style scoped>
  .device__childs {
    cursor: pointer;
    color: #000;
  }
  .device__childs--selected {
    color: orange;
  }
  .device__child-label {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0 10px 0 0;
  }
  .device__child-label-statistics {
    display: flex;
    gap: 20px;
  }
  .device__child-label-item  {
    width: 25%;
    text-align: center;
  }
  .device__header {
    display: flex;
    gap: 20px;
    justify-content: left;
    width: 100%;
  }
  .device__header-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 180px;
  }
  .device__header-info {
    flex: 1;
  }
  .device__cards {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 10px 0 0 0;
    align-self: stretch;
  }
  .device__statistic-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white !important;
    flex: 1;
  }
  .device__cards .device__statistic-card-value {
    font-size: 24px;
    font-weight: bold;
  }
  .device__cards .device__statistic-card-title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .device__statistic-card-progress {
    width: 100%;
  }
  .device__statistic-card-progress .v-progress-linear  {
    margin: 0 !important;
  }
  .device__header-name {
    display: flex;
    gap: 20px;
  }
  /* media query to change flex-direction in mobile */
  @media (max-width: 958px) {
    .device__header {
      flex-direction: column;
      gap: 10px;
    }
    .device__header-logo {
      max-width: 100%;
      align-items: center;
    }
    .device__header-info {
      width: 100%;
    }
    .device__cards {
      flex-direction: column;
      gap: 10px;
    }
    .device__statistic-card {
      flex: 1;
    }
    .device__header-name {
      flex-direction: column;
      gap: 5px;
    }
  }
</style>