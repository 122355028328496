<template>
  <v-card class="elevation-17">
    <v-card-title v-if="data.grades && data.grades.length > 0" dense flat style="padding-top: 0; padding-bottom: 0;">
      <h3>{{config.groupName}} do(a) {{config.idTitle}}</h3>
      <v-spacer></v-spacer>
      <v-menu right transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small outline color="primary" ripple>
            <v-icon>shopping_cart</v-icon>
            <span>Planos de Pagamento</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile :disabled="data.stage != 1 || data.paid" @click="$WiEditDialog({wiConfig: 'sale-open', onSubmit: refresh, data: {is_template: true, business_id: data.id, business: {title: data.title}}, redirectOnSubmit: false})">
            <v-list-tile-title>Criar Plano de Pagamento</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push({name: 'sales-list', params: {page: 'sale'}, query: {business_id: data.id, is_template: true}})">
            <v-list-tile-title>Ver Planos de Pagamento</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-btn :disabled="data.stage != 1 || data.paid" small outline color="green" @click="$WiEditDialog({wiConfig: config.store + '-grade', onSubmit: refresh, data: {business_id: data.id}, redirectOnSubmit: false})">
        <v-icon>add</v-icon>
        <span>Criar {{config.groupName}}</span>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <div v-if="data.grades && data.grades.length > 0">
      <v-data-table dense :headers="headersFinances" :items="data.grades" class="elevation-0" hide-actions >
        <template v-slot:headers="props">
          <tr style="height: 40px;">
            <th class="hidden-xs-only hidden-sm-only" v-for="header in props.headers" :key="header.text" style="text-align: center !important; margin: 0 !important; padding: 0px !important;">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr>
            <td class="justify-center layout px-0" style="margin-top: 0px;">
              <v-btn :disabled="data.stage != 1 || data.paid" @click="$WiEditDialog({wiConfig: config.store + '-grade', onSubmit: refresh, data: props.item, redirectOnSubmit: false})" icon class="mx-0">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn :disabled="data.stage != 1 || data.paid" @click="$WiApiDelete({uri: 'graduations/register/grade', id: props.item.id, callback: refresh, config: {msgLoad: true, msgSucess: true, msgError: true, msgAsk: true}})" icon class="mx-0">
                <v-icon color="red">delete</v-icon>
              </v-btn>
            </td>
            <td class="justify-center">
              <strong>{{props.item.college.name}}</strong>
            </td> 
            <td class="justify-center">
              <strong>{{props.item.course.name}}</strong>
            </td>  
            <td class="justify-center">
              <strong>{{props.item.period}}</strong>
            </td>
            <td class="justify-center">
              {{props.item.semester || ' - '}}
            </td>
            <td class="justify-center">
              {{props.item.year || ' - '}}
            </td>
            <td class="justify-center" :style="{color: !props.item.count_customers ? 'red': ''}">
              {{props.item.count_customers || ' 0 '}}
            </td>
            <td class="justify-center">
              <!-- <v-btn small outline color="primary" @click="$WiListDialog({wiConfig: config.store + '-grade-person', filter: {where: 'group_id,' + props.item.id}, wiEditData: {group_id: props.item.id}})">
                Alunos/Clientes
              </v-btn> -->
              <v-menu bottom left>
                <v-btn slot="activator" icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
                <v-list>
                  <v-list-tile @click="$WiListDialog({wiConfig: config.store + '-grade-person', filter: {where: 'group_id,' + props.item.id}, wiEditData: {group_id: props.item.id}})">
                    <v-list-tile-avatar>
                      <v-avatar>
                        <v-icon color="grey lighten-1">groups</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-title> 
                      Alunos/Clientes
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile @click="$router.push({name: 'finance-list', params: { page: 'finance-in-list' }, query: {group_id: props.item.id, enable: true}})">
                    <v-list-tile-avatar>
                      <v-avatar>
                        <v-icon color="blue">request_quote</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Faturas desta turma</v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile @click="$router.push({name: 'finance-list', params: { page: 'finance-in-list' }, query: {group_id: props.item.id, enable: true, paid: true}})">
                    <v-list-tile-avatar>
                      <v-avatar>
                        <v-icon color="green">request_quote</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Faturas pagas</v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile @click="$router.push({name: 'finance-list', params: { page: 'finance-in-list' }, query: {group_id: props.item.id, enable: true, unpaid: true}})">
                    <v-list-tile-avatar>
                      <v-avatar>
                        <v-icon color="orange">request_quote</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Faturas não pagas</v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile @click="$router.push({name: 'finance-list', params: { page: 'finance-in-list' }, query: {group_id: props.item.id, enable: true, expired: true}})">
                    <v-list-tile-avatar>
                      <v-avatar>
                        <v-icon color="red">request_quote</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Faturas em atraso</v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-else style="text-align: center; padding: 20px;">
      <h3>Nenhum(a) {{config.groupName}} encontrada.</h3>
      <v-btn :disabled="data.stage != 1" outline small color="primary" @click="$WiEditDialog({wiConfig: config.store + '-grade', onSubmit: refresh, data: {business_id: data.id}, redirectOnSubmit: false})">
        <v-icon>add</v-icon>
        <span>Criar {{config.groupName}}</span>
      </v-btn>
      <v-menu right transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small outline color="primary" ripple>
            <v-icon>shopping_cart</v-icon>
            <span>Planos de Pagamento</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile :disabled="data.stage != 1 || data.paid" @click="$WiEditDialog({wiConfig: 'sale-open', data: {is_template: true, business_id: data.id, business: {title: data.title}}})">
            <v-list-tile-title>Criar Plano de Pagamento</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push({name: 'sales-list', params: {page: 'sale'}, query: {business_id: data.id, is_template: true}})">
            <v-list-tile-title>Ver Planos de Pagamento</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
  </v-card>     
</template>
<script>
  export default {
    name: 'BusinessGroups',
    data () {
      return {
        headersFinances: [
          { align: 'center', value: 'id' },
          { align: 'center', text: 'Faculdade', value: 'college_id', desc: 'Nome da Faculdade' },
          { align: 'center', text: 'Curso', value: 'course_id', desc: 'Nome da disciplina/curso' },
          { align: 'center', text: 'Período do dia', value: 'period', desc: 'Período do dia' },
          { align: 'center', text: 'Semestre', value: 'semester', desc: 'Semestre da Turma' },
          { align: 'center', text: 'Ano', value: 'year', desc: 'Ano da Turma' },
          { align: 'center', text: 'Alunos', value: 'count_customers', desc: 'Ano da Turma' },
          { align: 'center', text: 'Listar', value: 'count_customers', desc: 'Alunos desta Turma' }
        ],
        pagination: {
          sortBy: 'id'
        },
        filters: this.$options.filters
      }
    },
    methods: {
      parseParamsToFinanceModal: function (businessData) {
        return {
          business_id: businessData.id,
          person_id: businessData.person_id,
          amount_initial: businessData.total_liquid,
          show_amount_initial: this.$options.filters.money(businessData.total_liquid),
          status: 4,
          parcel: 1
        }
      }
    },
    props: {
      'config': {
        default: {}
      },
      'data': {
        default: {}
      },
      'refresh': {
        required: true
      }
    }
  }
</script>
<style>
p {
  margin-bottom: 0px !important;
}
.justify-center {
  text-align: center !important;
}
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
</style>
