<template>
  <div>
    <v-card-title class="header-title">
      <span>ID: </span>
      <strong class="ml-1"> #{{item.id}}</strong>
      <v-spacer></v-spacer>
      <span v-if="item.stage == 1">
        <strong
          v-if="showExpiredBadge"
          class="expired-badge"
        >
          EXPIRADO
        </strong>
      </span>
    </v-card-title>

    <template v-if="item.external_code">
      <v-divider></v-divider>
      <v-card-title class="header-title">
        <span>
          <span>Cod. Proposta: </span>
          <strong> {{item.external_code | stringlength(20)}}</strong>
        </span>
      </v-card-title>
    </template>
  </div>
</template>

<script>
import { BUSINESS_TYPES } from '../config/businessConfig'

export default {
  name: 'BusinessCardHeader',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true }
  },

  computed: {
    showExpiredBadge() {
      const { type } = this.config
      const isBudgetOrQuotation = type === BUSINESS_TYPES.BUDGET || type === BUSINESS_TYPES.QUOTATION
      const isPurchaseOrSale = type === BUSINESS_TYPES.PURCHASE || type === BUSINESS_TYPES.SALE

      return (isBudgetOrQuotation && this.item.date_validity_days < 0) ||
             (isPurchaseOrSale && this.item.date_end_days < 0)
    }
  }
}
</script>

<style scoped>
.header-title {
  padding: 5px;
}
.expired-badge {
  color: white;
  padding: 2px 5px;
  background: #f59898;
  border: 2px solid #dc3545;
  border-radius: 5px;
  font-size: 12px;
}
</style> 