import { PERMISSION_INTERNET_REGISTRATION } from '@/default/constants/permissions'

export default {
  id: PERMISSION_INTERNET_REGISTRATION,
  icon: 'description',
  title: 'Cadastro',
  children: [
    {
      id: PERMISSION_INTERNET_REGISTRATION,
      icon: 'price_change',
      title: 'Promoções',
      route: {
        name: 'internet-list',
        params: {
          page: 'internet-contract-promotion'
        }
      }
    },
    {
      id: PERMISSION_INTERNET_REGISTRATION,
      icon: 'local_grocery_store',
      title: 'Pacotes',
      route: {
        name: 'disponivel-em-breve'
      }
    },
    {
      id: PERMISSION_INTERNET_REGISTRATION,
      icon: 'tune',
      title: 'Planos de navegação',
      route: {
        name: 'disponivel-em-breve'
      }
    }
  ]
}
