import Provider from '@/modules/register/config/Person/Person?url=Provider'
import { PersonFilters } from './PersonFilters'

var provider = Provider({name: 'Fornecedor', field: 'provider'})

provider.id = {
  id: '232e1dba',
  create: '7cabaf9a',
  update: 'd9c2f43a',
  delete: '2d595773'
}
provider.title = {
  list: 'Fornecedores',
  edit: {
    insert: 'Novo Fornecedor',
    update: 'Editar Fornecedor'
  }
}
provider.icon = 'local_atm'
provider.apiUrl = 'register/person/provider'
provider.urlDetails.name = 'provider'
provider['filters'] = new PersonFilters()

export default provider
