<template>
  <div>
    <input type="hidden" v-model="text" id="CopyTextToClipboardInput">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon small flat :color="color" @click="copyText()" style="margin: 0;">
          <v-icon small>file_copy</v-icon>
        </v-btn>
      </template>
      <span>Clique para copiar</span>
    </v-tooltip>
    <v-snackbar v-model="snackbar" top>
      <span v-if="title">{{title}} </span>
      <span v-else>"{{snackbarText}}": </span>
      <span style="padding-left: 5px;"> Copiado para área de transferência</span>
      <v-btn color="pink" flat @click="snackbar = false" >
        OK
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
  export default {
    name: 'WiCopyTextTo',
    data () {
      return {
        snackbar: false,
        snackbarText: ''
      }
    },
    methods: {
      copyText: function () {
        var copyText = document.getElementById('CopyTextToClipboardInput')
        copyText.setAttribute('type', 'text')
        copyText.value = this.text
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        document.execCommand('copy')
        copyText.setAttribute('type', 'hidden')
        this.snackbarText = copyText.value
        this.snackbar = true
      }
    },
    props: ['text', 'title', 'color']
  }
</script>
