import { BusinessExport } from "@/modules/business/config/BusinessExport"


export class BudgetExportFilters extends BusinessExport {
  constructor () {
    super()
    this.id = '10b505c9'
    this.apiUrl = 'sales/budget-export'
    this.fieldsEdit.status_id.apiUrl = 'sales/config/status/budget'
    this.fieldsEdit.category_id.apiUrl = 'sales/config/category/budget'
  }
}
