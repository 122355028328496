import { Model } from '@/default/model/Model'

export class TalkCampaignChannel extends Model {
  constructor () {
    super()
    this.list = true
    this.id = 'b49f9043'
    this.name = 'talk-campaign-channel'
    this.actions = {
      list: true,
      insert: false,
      update: false,
      delete: true
    }
    this.title = {
      list: 'Canais da Campanha',
      edit: {
        insert: 'Adicionar canal'
      }
    }
    this.apiUrl = 'talks/campaign-channel'
    this.icon = 'message'
    this.params = {
      limit: 10,
      with: 'channel'
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do canal',
      fields: ['channel.name']
    }
    this.fieldsSelected = {
      itemName: 'Canais'
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '800px'
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkCampaigns/TalkCampaignChannels')
    this.setFields([
      this.fieldList({
        value: 'channel.id',
        text: 'ID',
        sortable: false,
        align: 'center'
      }),
      this.fieldList({
        value: 'channel.type_id',
        text: 'Tipo',
        sortable: false,
        align: 'center'
      }),
      this.fieldList({
        value: 'channel.name',
        text: 'Nome',
        sortable: false
      }),
      this.fieldList({
        value: 'channel.recipient',
        text: 'Número',
        sortable: false
      }),
      this.fieldList({
        value: 'channel.status.name',
        text: 'Status',
        sortable: false,
        align: 'center'
      })
    ])
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe o nome e a configuração de identificação.'
      }),
      this.fieldWiListDialog({
        value: 'talk_channel_id',
        text: 'Canal de Atendimento',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-channel',
        show: 'channel.name',
        icon: 'smart_toy'
      })
    ])
  }
}
