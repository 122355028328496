import router from '@/modules/clinic/routes'
import config from '@/modules/clinic/config'
import menu from '@/modules/clinic/menu'

export default {
  id: '85352c3c-1321-4cba-b009-d5c4dc909159',
  name: 'clinic',
  title: 'Consultório',
  description: 'Gestão de Consultório médico',
  icon: 'wises-technology-module-clinic.png',
  iconSystem: 'wises-technology-system-sale.png',
  iconProfile: 'profile_service_desk.png',
  color: '#58ADC0',
  menu,
  router,
  config
}
