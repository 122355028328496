<template>
  <div>
    <v-dialog v-model="active" scrollable persistent max-width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card style="background: #fafafa;">
        <v-toolbar dense primary>
          <v-toolbar-title class="hidden-xs-only hidden-sm-only" style="padding-right: 30px;">
            <v-icon>invert_colors</v-icon>
            Ping através do servidor
          </v-toolbar-title>
          <v-text-field style="color: white;" @keyup.enter="socketPing()" v-model="ipAddress"   color="white"   placeholder="Digite um IP ou domínio válido." solo hide-details single-line :autofocus="true" dark></v-text-field>
          <v-btn :disabled="pingIsNotAvaiable()" outline @click="socketPing()">
            <v-icon>play_circle_outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="runPing" icon flat @click="closeDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="min-height: 400px;">
          <div v-if="pings && pings.length > 0">
            <v-list dense one-line class="elevation-17" style="padding: 0 5px 0 0;">
              <template v-for="(ping, pingKey) in pings">
                <v-divider :key="pingKey + '-divider'"></v-divider>
                <v-list-tile avatar :key="pingKey">
                  <v-list-tile-avatar>
                    <v-icon v-if="ping.status" color="green" large>done</v-icon>
                    <v-icon v-else color="red" large>close</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <div v-if="ping.status">
                        <span>Resposta de <strong style="color: green;">{{ping.ip}}</strong>, </span>
                        <span> com tempo <strong :style="{color: getColorForLatence(ping.latence)}">{{ping.latence}}</strong></span>
                        <span> às {{ping.time}}: <strong>OK.</strong></span>
                      </div>
                      <div v-else>
                        Esgotado o tempo limite do pedido para {{ipAddress}} <span> às {{ping.time}}.</span>
                      </div>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <strong style="font-size: 30px;"></strong>
                  </v-list-tile-action>
                </v-list-tile>
              </template>
            </v-list>
            <div class="hidden-md-only hidden-lg-only hidden-xl-only">
              <br>
              <span v-if="pingsLastAverange && pingsLastAverange.send">
                <span>Enviados: <strong>{{pingsLastAverange.send}}</strong>,</span>
                <span>Recebidos: <strong>{{pingsLastAverange.up}}</strong>,</span>
                <span>Perdidos: <strong>{{pingsLastAverange.down}}</strong>,</span>
                <span>Média: <strong :style="{color: getColorForLatence(pingsLastAverange.latence)}">{{pingsLastAverange.latence}}</strong></span>
                <span> e <strong>{{pingsLastAverange.down_percentage}}</strong> de perda.</span>
              </span>
              <span v-else>Informe um endereço de IP ou domínio para iniciar.</span>
            </div>
          </div>
          <div v-else>
            <v-list dense one-line class="elevation-17">
              <template>
                <v-subheader>Para pingar um dispositivo siga os passos abaixo.</v-subheader>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar style="font-size: 30px;"><strong>1</strong></v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Verifique se o servidor está online no canto inferior direito da tela.</v-list-tile-title>
                    <v-list-tile-sub-title></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar style="font-size: 30px;"><strong>2</strong></v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Digite um IP ou domínio válido no campo acima.</v-list-tile-title>
                    <v-list-tile-sub-title></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar style="font-size: 30px;"><strong>3</strong></v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Pressione "enter" ou clique no botão ao lado do campo de texto.</v-list-tile-title>
                    <v-list-tile-sub-title></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar style="font-size: 30px;"><strong>4</strong></v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Se tudo estiver correto você verá o resultado do ping na tela.</v-list-tile-title>
                    <v-list-tile-sub-title></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar style="font-size: 18px;"><strong>Obs:</strong></v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Enquanto o teste de ping estiver em execução você não poderá fechar esta janela.</v-list-tile-title>
                    <v-list-tile-sub-title></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
              </template>
            </v-list>
          </div>
        </v-card-text>
        <v-toolbar class="elevation-17" dense style="width: 100%; z-index: 9999 !important; box-shadow: 10px 10px 5px grey;">
          <div class="hidden-xs-only hidden-sm-only">
            <span v-if="pingsLastAverange && pingsLastAverange.send">
              <span>Enviados: <strong>{{pingsLastAverange.send}}</strong>,</span>
              <span>Recebidos: <strong>{{pingsLastAverange.up}}</strong>,</span>
              <span>Perdidos: <strong>{{pingsLastAverange.down}}</strong>,</span>
              <span>Média: <strong :style="{color: getColorForLatence(pingsLastAverange.latence)}">{{pingsLastAverange.latence}}</strong></span>
              <span> e <strong>{{pingsLastAverange.down_percentage}}</strong> de perda.</span>
            </span>
            <span v-else>Informe um endereço de IP ou domínio para iniciar.</span>
          </div>
          <v-spacer></v-spacer>
          <span style="padding-right:10px;">Status do servidor:</span> 
          <label 
          style="font-size: 14px; color: white; padding: 2px 5px;" 
          :style="{background: server.status == 'online' ? 'green' : (server.status == 'offline' ? 'red' : 'grey')}"
          >
            {{server.status}}
          </label>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import NetworkServerMixin from '@/modules/network/pages/NetworkServer/Mixins/NetworkServerMixin'
  export default {
    name: 'PingDialog',
    data () {
      return {
        dialog: false,
        ipAddress: '',
        pings: [],
        pingsLastAverange: {},
        runPing: false
      }
    },
    methods: {
      socketPing: function () {
        if (!this.pingIsNotAvaiable()) {
          this.pings = []
          this.runPing = true
          this.socket.get({
            url: 'network-server-ping-ip-address',
            params: { id: this.networkServerId, ip: this.ipAddress, count: 7 }
          })
          this.socket.subscribe({
            url: 'network-server-ping-ip-address-' + this.networkServerId,
            callback: response => {
              console.log(response)
              if (response === 'close') {
                this.socket.unsubscribe({ url: 'network-server-ping-ip-address-' + this.networkServerId })
                console.log('unsubscribe')
                this.runPing = false
              } else {
                this.pings.push(response.ping)
                this.pingsLastAverange = response.averange
                this.runPing = true
              }
            }
          })
        }
      },
      pingIsNotAvaiable: function () {
        return this.ipAddress.length < 7 || this.runPing || this.server.status !== 'online'
      },
      getColorForLatence: function (latence) {
        var latenceValue = parseInt(latence.split(' ')[0])
        if (latenceValue > 90 && latenceValue < 150) {
          return 'orange'
        }
        if (latenceValue >= 150) {
          return 'red'
        }
        return 'green'
      },
      closeDialog: function () {
        this.socket.unsubscribe({ url: 'network-server-ping-ip-address-' + this.networkServerId })
        this.$emit('active', false)
      }
    },
    mounted () {
      this.socketConnect()
      this.socketOnConnect(this.networkServerId)
      this.socketOnDisconect()
      this.checkNetworkServerStatusSubscribe(this.networkServerId)
      if (this.hostToPing) {
        this.ipAddress = this.hostToPing
        setTimeout(() => {
          this.socketPing()
        }, 1000)
      }
    },
    mixins: [NetworkServerMixin],
    props: {
      active: {
        type: Boolean
      },
      networkServerId: {},
      hostToPing: {
        type: String
      }
    }
  }
</script>