import { Model } from '@/default/model/Model'
import { PERMISSION_FORM_ITEM_UPDATE } from '@/default/constants/permissions'

export class FormFieldOption extends Model {
  constructor () {
    super()
    this.list = true
    this.id = PERMISSION_FORM_ITEM_UPDATE
    this.name = 'form-field-option'
    this.title = {
      list: 'Opções do Formulário',
      edit: {
        insert: 'Nova opção do Formulário',
        update: 'Editar opção do Formulário'
      }
    }
    this.params = {
      limit: 10,
      order: 'order,asc'
    }
    // this.maxWidth = '800px'
    this.icon = 'border_color'
    this.apiUrl = 'forms/form-field-option'
    this.components.list.tableRow = () => import('@/modules/forms/pages/Forms/FormFieldOptions')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    type: this.fieldList({
      value: 'type_id',
      text: 'Tipo'
    }),
    label: this.fieldList({
      value: 'label',
      text: 'Título'
    }),
    required: this.fieldList({
      value: 'value',
      text: 'Valor'
    }),
    placeholder: this.fieldList({
      value: 'active',
      text: 'Ativo'
    }),
    options: this.fieldList({
      value: 'id',
      text: 'Opções'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o campo a qual esta opção se refere. Informe um título.'
    }),
    form_id: this.fieldSelectApi({
      value: 'form_field_id',
      text: 'Campo do Formulário',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      disabled: (form) => { return form.id != null },
      api: {
        url: 'forms/form-field',
        params: {
          where: 'type_id,3'
        }
      },
      show: 'label'
    }),
    label: this.fieldText({
      value: 'label',
      text: 'Título do Campo',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    value: this.fieldText({
      value: 'value',
      text: 'Valor do Campo',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    type_id: this.fieldSelectApi({
      value: 'type_id',
      text: 'Tipo da Opção',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      disabled: true,
      api: {
        url: 'forms/field/option/type'
      },
      show: 'title',
      icon: 'icon'
    }),
    order: this.fieldNumber({
      value: 'order',
      text: 'Ordem do campo',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    })
  }
}
