import {
  PERMISSION_FORM,
  PERMISSION_FORM_ITEM
} from '@/default/constants/permissions'

export default [
  {
    id: PERMISSION_FORM,
    icon: 'dynamic_feed',
    title: 'Formulários',
    route: {
      name: 'forms-list',
      params: {
        page: 'forms'
      }
    }
  },
  {
    id: PERMISSION_FORM_ITEM,
    icon: 'receipt_long',
    title: 'Items de Formulário',
    route: {
      name: 'forms-list',
      params: {
        page: 'form-items'
      }
    }
  }
]