<template>
  <v-container>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-btn icon @click="clearEmpresa()">
              <v-icon>navigate_before</v-icon>
            </v-btn>
            <v-toolbar-title>Por favor, selecione sua empresa</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list two-line>
            <template v-for="(empresa, empresaKey) in empresas">
              <v-divider inset :key="empresaKey"></v-divider>
              <v-list-tile  :key="empresaKey" avatar @click="selectEmpresa(empresa.id)">
                <v-list-tile-avatar>
                  <v-icon x-large>store</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title v-if="empresa.nome_empresa" v-html="empresa.nome_empresa"></v-list-tile-title>
                  <v-list-tile-sub-title v-if="empresa.nome_empresa" v-html="empresa.nome_empresa"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import {Api} from '@/default/service/Api'
  export default {
    name: 'Selecionar-Empresa',
    data () {
      return {}
    },
    created: function () {
      if (!localStorage.getItem('auth') || !JSON.parse(localStorage.getItem('auth')).EmpresaLista) {
        this.clearEmpresa()
      }
    },
    computed: {
      url () {
        return this.$store.state.url
      },
      empresas () {
        return localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).EmpresaLista : []
      }
    },
    methods: {
      selectEmpresa: function (empresa) {
        this.$swal({
          title: 'Processando...',
          text: 'Por favor aguarde.',
          onOpen: () => { this.$swal.showLoading() }
        })
        Api.post({url: this.url.base + 'v1/login/usuario-login'}, {IDEmpresa: empresa})
        .then((resp) => {
          if (!resp.data.EmpresaLista) {
            localStorage.setItem('auth', JSON.stringify(resp.data))
            this.$store.commit('profile', resp.data.Usuario)
            this.$router.push({ name: 'main' })
            this.$swal.close()
          }
        })
        .catch(function (error) {
          console.log(error)
          Vue.swal(
            'Oops...',
            'Empresa não existe ou é inválida!',
            'error'
            )
        })
      },
      clearEmpresa: function () {
        this.$router.push({name: 'login'})
        localStorage.removeItem('auth')
        this.$store.commit('profile', {})
      }
    }
  }
</script>