<template>
  <div v-if="complement">
    <v-card-text>
      <v-layout row wrap>
        <v-flex v-if="signal" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
          <DiagnosticSignal
            :signal="signal"
          ></DiagnosticSignal>
        </v-flex>
        <v-flex v-if="ticketsIndirect" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
          <DiagnosticDevice
            :device="device"
          ></DiagnosticDevice>
        </v-flex>
        <v-flex v-if="ticketsIndirect" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
          <DiagnosticTickets
            title="Chamado(s) diretos"
            :count="ticketsDirect.count"
            :tickets="ticketsDirect.tickets"
          ></DiagnosticTickets>
        </v-flex>
        <v-flex v-if="ticketsIndirect" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
          <DiagnosticTickets
            title="Chamado(s) indiretos"
            :count="ticketsIndirect.count"
            :tickets="ticketsIndirect.tickets"
          ></DiagnosticTickets>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>
<script>
  import DiagnosticTickets from './DiagnosticTickets'
  import DiagnosticSignal from './DiagnosticSignal'
  import DiagnosticDevice from './DiagnosticDevice'
  export default {
    name: 'DiagnosticItems',
    computed: {
      signal () {
        return this.complement?.signal
      },
      ticketsDirect () {
        return this.complement?.tickets?.device
      },
      ticketsIndirect () {
        return this.complement?.tickets?.network
      },
      device () {
        return this.complement?.device
      }
    },
    props: {
      complement: {
        required: true,
        default: {}
      },
      status: {
        required: true,
        default: () => ({})
      },
      business: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      DiagnosticTickets,
      DiagnosticSignal,
      DiagnosticDevice
    }
  }
</script>
