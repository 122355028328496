<template>
<div>
  <view-detail title="Detalhes do servidor" apiUrl="servidor/dados" store="servidores">
    <template slot-scope="data">
      <div style="padding: 20px !important;">
        <v-card class="elevation-17" :class="{'grey lighten-1': data.content.ativo_servidor != 'ativo'}" value="teste1">
          <v-divider></v-divider>
          <network-server-header :data="data" :status="server.status" :information="server.information" :interval="interval" :update="getSocketInformation"></network-server-header>
          <v-divider></v-divider>
          <network-server-info :data="data" :status="server.status" :information="server.information"></network-server-info>
          <v-divider></v-divider>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12>
              <v-btn :disabled="server.status !== 'online'" @click="pingDialogAction(true)">
                <v-icon color="blue">invert_colors</v-icon>
                Pingar deste Servidor
              </v-btn>
              <v-btn v-prms="{'id': '0525eb1a'}" :disabled="server.status !== 'online'" @click="networkServerReboot()">
                <v-icon color="red">settings_power</v-icon>
                Reiniciar Servidor
              </v-btn>
              <div v-if="$Auth.user().id == 1">
                <v-btn :disabled="server.status !== 'online'" @click="getListDevices()">
                  <v-icon>dns</v-icon>
                  Listar Dispositivos
                </v-btn>
                <v-btn :disabled="server.status !== 'online'" @click="getListDevicesUp()">
                  <v-icon>dns</v-icon>
                  Listar Dispositivos Up
                </v-btn>
                <v-btn :disabled="server.status !== 'online'" @click="getListDevicesDown()">
                  <v-icon>dns</v-icon>
                  Listar Dispositivos Down
                </v-btn>
                <v-btn :disabled="server.status !== 'online'" @click="getListQueue()">
                  <v-icon>list</v-icon>
                  Listar Fila de envio
                </v-btn>
                <v-btn v-prms="{'id': 'e9dd3efb'}" :disabled="server.status !== 'online'" @click="restartPingToDevice()">
                  <v-icon>dns</v-icon>
                  Reiniciar Ping
                </v-btn>
                <v-btn v-prms="{'id': '35fdef42'}" :disabled="server.status !== 'online'" @click="updateApplication()">
                  <v-icon>dns</v-icon>
                  Atualizar
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
        <ping-dialog v-if="pingDialog" :network-server-id="$route.params.id" :active="pingDialog" v-on:active="pingDialogAction"></ping-dialog>
      </div>
      <div style="padding: 20px !important;">
        <v-card class="elevation-17" value="teste1">
          <ul>
            <li v-for="(log, logKey) in server.information.logs" :key="logKey">
              <span>{{log.created_at}}</span> - 
              <span>{{log.type}}</span> - 
              <strong>{{log.message}}</strong>
            </li>
          </ul>
        </v-card>
      </div>
    </template>
  </view-detail>
</div>
</template>
<script>
  import NetworkServerMixin from '@/modules/network/pages/NetworkServer/Mixins/NetworkServerMixin'
  import NetworkServerHeader from '@/modules/network/pages/NetworkServer/NetworkServerHeader/NetworkServerHeader'
  import NetworkServerInfo from '@/modules/network/pages/NetworkServer/NetworkServerInfo/NetworkServerInfo'
  import PingDialog from '@/modules/network/pages/NetworkServer/Dialogs/PingDialog'
  export default {
    name: 'NetworkServer',
    data () {
      return {
        networkServerId: this.$route.params.id,
        getMixin: null,
        pingDialog: false,
        interval: {
          interval: null,
          update: 15,
          status: false,
          count: {
            value: 15,
            interval: null,
            porcentage: this.getIntervalCountPorcentage
          }
        }
      }
    },
    methods: {
      pingDialogAction: function (active) {
        this.pingDialog = active
      },
      networkServerStatusChanged: function () {
        if (this.server.status === 'online') {
          this.getSocketInformation()
          this.startNetworkServerInformation()
        } else {
          this.stopNetworkServerInformation()
        }
      },
      getSocketInformation: function () {
        this.socket.get({
          url: 'network-server-information',
          params: {id: this.$route.params.id, execute: 'information'}
        })
        this.socket.subscribe({
          url: 'network-server-information-' + this.$route.params.id,
          callback: response => {
            if (response) {
              this.server.information = response
              this.socket.unsubscribe({url: 'network-server-information-' + this.$route.params.id})
            }
          }
        })
      },
      startNetworkServerInformation: function () {
        this.interval.interval = setInterval(() => {
          this.getSocketInformation()
          this.interval.count.value = 15
        }, 15000)
        this.interval.status = true
      },
      stopNetworkServerInformation: function () {
        clearInterval(this.interval.interval)
        clearInterval(this.interval.countInterval)
        this.interval.status = false
        this.interval.count.value = 15
      },
      getIntervalCountPorcentage: function () {
        return (this.interval.count.value * 100) / this.interval.update
      },
      getListDevices: function () {
        this.socket.get({
          url: 'network-server-devices',
          params: {id: this.$route.params.id, execute: 'downDevices'}
        })
        this.socket.subscribe({
          url: 'network-server-devices-' + this.$route.params.id,
          callback: response => {
            console.log(response)
            if (response) {
              this.socket.unsubscribe({url: 'network-server-devices-' + this.$route.params.id})
            }
          }
        })
      },
      getListDevicesUp: function () {
        this.socket.get({
          url: 'network-server-devices-up',
          params: {id: this.$route.params.id, execute: 'downDevices'}
        })
        this.socket.subscribe({
          url: 'network-server-devices-up-' + this.$route.params.id,
          callback: response => {
            console.log(response)
            if (response) {
              this.socket.unsubscribe({url: 'network-server-devices-up-' + this.$route.params.id})
            }
          }
        })
      },
      getListDevicesDown: function () {
        this.socket.get({
          url: 'network-server-devices-down',
          params: {id: this.$route.params.id, execute: 'downDevices'}
        })
        this.socket.subscribe({
          url: 'network-server-devices-down-' + this.$route.params.id,
          callback: response => {
            console.log(response)
            if (response) {
              this.socket.unsubscribe({url: 'network-server-devices-down-' + this.$route.params.id})
            }
          }
        })
      },
      networkServerReboot: function () {
        this.$swal({
          title: 'Deseja realmente reiniciar este servidor?',
          text: 'Caso ocorra alguma falha será necessário acessar localmente.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Reiniciar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.$swal({
              title: 'Reiniciando...',
              text: 'Aguarde até que a ação seja finalizada.',
              onOpen: () => { this.$swal.showLoading() }
            })
            this.socket.get({
              url: 'network-server-reboot',
              params: {id: this.$route.params.id, execute: 'reboot'}
            })
            this.socket.subscribe({
              url: 'network-server-reboot-' + this.$route.params.id,
              callback: response => {
                console.log(response)
                if (response) {
                  this.socket.unsubscribe({url: 'network-server-reboot-' + this.$route.params.id})
                  if (response.status) {
                    this.$swal({
                      type: 'success',
                      title: 'Tudo certo!',
                      text: 'Reiniciado com sucesso.'
                    })
                  } else {
                    this.$swal({
                      type: 'error',
                      title: 'Oops... Algo deu errado!',
                      text: 'Tente novamente ou contacte o suporte.'
                    })
                  }
                }
              }
            })
          }
        })
      },
      getListQueue: function () {
        this.socket.get({
          url: 'network-server-queue',
          params: {id: this.$route.params.id, execute: 'queue'}
        })
        this.socket.subscribe({
          url: 'network-server-queue-' + this.$route.params.id,
          callback: response => {
            console.log(response)
            if (response) {
              this.socket.unsubscribe({url: 'network-server-queue-' + this.$route.params.id})
            }
          }
        })
      },
      restartPingToDevice: function () {
        var device = {
          id: 5350,
          name: 'Hello World!',
          ip: '187.120.76.20'
        }
        this.$swal({
          title: 'Reiniciar o monitoramento deste dispositivo?',
          text: 'Seu status ficará como disponível e em seguida será analisado novamente.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Reiniciar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.$swal({
              title: 'Reiniciando...',
              text: 'Aguarde até que a ação seja finalizada.',
              onOpen: () => { this.$swal.showLoading() }
            })
            this.socket.get({
              url: 'network-server-restart-ping',
              params: {id: this.$route.params.id, execute: 'restart-ping', device: device}
            })
            this.socket.subscribe({
              url: 'network-server-restart-ping-' + this.$route.params.id,
              callback: response => {
                console.log(response)
                if (response) {
                  this.socket.unsubscribe({url: 'network-server-restart-ping-' + this.$route.params.id})
                  if (response.status) {
                    this.$swal({
                      type: 'success',
                      title: 'Tudo certo!',
                      text: 'Reiniciado com sucesso.'
                    })
                  } else {
                    this.$swal({
                      type: 'error',
                      title: 'Oops... Algo deu errado!',
                      text: 'Tente novamente ou contacte o suporte.'
                    })
                  }
                }
              }
            })
          }
        })
      },
      updateApplication: function () {
        this.$swal({
          title: 'Reiniciar o monitoramento deste dispositivo?',
          text: 'Seu status ficará como disponível e em seguida será analisado novamente.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Reiniciar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.$swal({
              title: 'Reiniciando...',
              text: 'Aguarde até que a ação seja finalizada.',
              onOpen: () => { this.$swal.showLoading() }
            })
            this.socket.get({
              url: 'network-server-update-application',
              params: {id: this.$route.params.id, execute: 'update-application'}
            })
            this.socket.subscribe({
              url: 'network-server-update-application-' + this.$route.params.id,
              callback: response => {
                console.log(response)
                if (response) {
                  this.socket.unsubscribe({url: 'network-server-update-application-' + this.$route.params.id})
                  if (response.status) {
                    this.$swal({
                      type: 'success',
                      title: 'Tudo certo!',
                      text: 'Reiniciado com sucesso.'
                    })
                  } else {
                    this.$swal({
                      type: 'error',
                      title: 'Oops... Algo deu errado!',
                      text: 'Tente novamente ou contacte o suporte.'
                    })
                  }
                }
              }
            })
          }
        })
      }
    },
    mounted () {
      this.socketConnect()
      this.socketOnConnect(this.$route.params.id)
      this.socketOnDisconect()
      this.checkNetworkServerStatusSubscribe(this.$route.params.id)
      this.interval.count.interval = setInterval(() => {
        this.interval.count.value--
      }, 1000)
    },
    beforeDestroy () {
      this.stopNetworkServerInformation()
      this.socket.disconnect()
    },
    mixins: [NetworkServerMixin],
    components: {
      NetworkServerHeader,
      NetworkServerInfo,
      PingDialog
    }
  }
</script>