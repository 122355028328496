<template>
  <div style="margin-bottom: 17px;">
    <v-card style="border-radius: 5px;" >
      <v-toolbar dense card style="border-radius: 5px; text-align: center !important;" :color="!$wiDark ? 'white' : 'dark'">
        <div class="hidden-xs-only hidden-sm-only" style="max-width: 200px;">
          <h4 v-if="config.config.showFilterDateToolbar.title">{{config.config.showFilterDateToolbar.title}} {{date | date}}</h4>
          <h4 v-else>Exibindo movimentações de {{date | date}}</h4>
        </div>
        <v-spacer></v-spacer>
        <div v-if="period" style="text-align: center !important;">
          <div class="v-date-picker-header theme--light" style="max-width: 230px;">
            <!-- <button type="button" class="v-btn v-btn--icon theme--light">
              <div class="v-btn__content">
                <i aria-hidden="true" class="v-icon material-icons theme--light" :style="{color: dark ? 'white' : 'black'}">chevron_left</i>
              </div>
            </button> -->
            <v-dialog ref="dialog" v-model="modal" persistent lazy full-width width="290px" >
              <template v-slot:activator="{ on }">
                <div v-on="on" class="v-date-picker-header__value">
                  <button type="button" :style="{color: $wiDark ? 'white' : 'black'}" style="border-width: 1px; border-style: solid; padding: 2px 10px;">
                    {{date | date}}
                  </button>
                </div>
              </template>
              <v-date-picker v-model="date" locale="pt-br" :type="period" color="primary" scrollable>
                <v-spacer></v-spacer>
                <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                <v-btn flat color="primary" @click="changePeriodFilter(date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- <button @click="dateAdd()" type="button" class="v-btn v-btn--icon theme--light">
              <div class="v-btn__content">
                <i aria-hidden="true" class="v-icon material-icons theme--light" :style="{color: dark ? 'white' : 'black'}">chevron_right</i>
              </div>
            </button> -->
          </div>
        </div>
        <div v-else>
          <span style="border-width: 1px; border-style: solid; padding: 2px 10px;" v-if="config.config.showFilterDateToolbar.title">{{config.config.showFilterDateToolbar.title}}</span>
          <span style="border-width: 1px; border-style: solid; padding: 2px 10px;" v-else>Todas as movimentações</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn v-if="period == 'date'" :disabled="date == new Date().toISOString().slice(0, 10)" @click="backToToday()" outline small>
          <v-icon>event</v-icon>
          <span>Voltar para Hoje</span>
        </v-btn>
        <div v-if="!config.config.showFilterDateToolbar.noChangePeriod" class="hidden-xs-only hidden-sm-only" style="max-width: 200px;">
          <v-select item-text="title" item-value="id" hide-details flat :items="items" solo placeholder="Exibindo..." v-model="period"></v-select>
        </div>
      </v-toolbar>
    </v-card>
  </div>
</template>
<script>
  export default {
    name: 'WiListDateToolbar',
    data () {
      return {
        saveDate: this.config && this.config.params && this.config.params.date ? this.config.params.date : new Date().toISOString().substr(0, 7),
        savePeriod: this.config && this.config.params && this.config.params.period ? this.config.params.period : 'month',
        modal: false,
        items: [
          {id: 'month', title: 'Filtrar por Mês'},
          {id: 'date', title: 'Filtrar por Dia'}
        ],
        countDateAdd: 1,
        countDateRemove: 1
      }
    },
    methods: {
      changePeriodFilter: function (period) {
        this.modal = false
        this.date = period
        this.config.setParams({
          period: this.period,
          date: period
        }, true)
        if (this.setRoute) {
          this.$router.push({query: this.config.params})
        }
        this.$forceUpdate()
      },
      dateAdd: function () {
        this.date = this.addDaysInDate(this.date, 1)
        this.config.setParams({
          date: this.date
        }, true)
      },
      addDaysInDate: function (date, days) {
        var result = new Date(date)
        result.setDate(result.getDate() + (days + 1))
        return this.dateFormat(result)
      },
      removeDaysInDate: function (date, days) {
        var result = new Date(date)
        result.setDate(result.getDate() - (days - 1))
        return this.dateFormat(result)
      },
      setInitPeriod: function () {
        if (this.config?.config?.showFilterDateToolbar?.period) {
          this.period = this.config.config.showFilterDateToolbar.period
        }
      },
      setInitDateAndPeriod: function () {
        this.config.setParams({
          period: this.period,
          date: this.date
        }, false)
      },
      dateFormat: function (date) {
        var d = new Date(date)
        var month = '' + (d.getMonth() + 1)
        var day = '' + d.getDate()
        var year = d.getFullYear()
        if (month.length < 2) {
          month = '0' + month
        }
        if (day.length < 2) {
          day = '0' + day
        }
        return [year, month, day].join('-')
      },
      updateParams: function () {
        setTimeout(() => {
          this.date = this.config.params.date
          this.period = this.config.params.period
        }, 200)
      },
      backToToday: function () {
        this.date = new Date().toISOString().slice(0, 10)
        this.config.setParams({
          period: this.period,
          date: this.date
        }, true)
      }
    },
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      currentPage () {
        return this.$route.params ? this.$route.params.page : null
      },
      date: {
        get () {
          return this.saveDate
        },
        set (newValue) {
          this.saveDate = newValue
          this.config.setParams({
            date: newValue
          })
          this.$forceUpdate()
        }
      },
      period: {
        get () {
          return this.savePeriod
        },
        set (newValue) {
          this.savePeriod = newValue
          this.config.setParams({
            period: newValue
          })
          this.$forceUpdate()
        }
      }
    },
    mounted: function () {
      this.setInitPeriod()
      this.setInitDateAndPeriod()
      this.updateParams()
    },
    props: {
      'config': {
        type: Object,
        required: true
      },
      'setRoute': {
        type: Boolean,
        default: false
      }
    }
  }
</script>