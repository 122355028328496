import {Model} from '@/default/model/Model'

export class ImportCustomerFromImanager extends Model {
  constructor () {
    super()
    this.id = '549b7548'
    this.list = true
    this.title = 'Importar Cliente do Imanager'
    this.icon = 'download'
    this.apiUrl = 'register/import/customer/i-manager/by-document'
    this.maxWidth = '700px'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = [
    this.fieldSubHeader({
      text: 'Informe se o cliente é PF ou PJ e insira o CPF/CNPJ.'
    }),
    this.fieldSelect({
      value: 'type',
      text: 'Pessoa Física ou Jurídica',
      placeholder: 'Pessoa Física ou Jurídica.',
      size: ['xs12', 'sm12', 'md6', 'lg6', 'xl6'],
      options: [
        {value: 'Fisica', text: 'Pessoa Física'},
        {value: 'Juridica', text: 'Pessoa Jurídica'}
      ],
      required: true
    }),
    this.fieldText({
      value: 'document',
      text: 'CPF',
      placeholder: '###.###.###-##',
      mask: '###.###.###-##',
      size: ['xs12', 'sm12', 'md6', 'lg6', 'xl6'],
      required: true,
      showField: form => (!form.type || form.type == 'Fisica')
    }),
    this.fieldText({
      value: 'document',
      text: 'CNPJ',
      placeholder: '##.###.###/####-##',
      mask: '##.###.###/####-##',
      size: ['xs12', 'sm12', 'md6', 'lg6', 'xl6'],
      required: true,
      showField: form => (form.type == 'Juridica')
    })
  ]
}
