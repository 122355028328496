<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <v-btn v-if="mode == 'button' && !$vuetify.breakpoint.xsOnly" v-on="on" outline small v-prms="{ id: imports.permission }">
        <v-icon>upload</v-icon>
        <span style="padding-left: 5px;"> {{ imports.title }}</span>
      </v-btn>
      <span v-on="on">
        <v-tooltip v-if="mode == 'icon' || $vuetify.breakpoint.xsOnly" bottom lazy>
          <template v-slot:activator="{ teste }">
            <v-btn v-on="teste" icon class="ml-0 mr-0" v-prms="{ id: imports.permission }">
              <v-icon :color="$vuetify.breakpoint.xsOnly ? 'black' : 'white'">upload</v-icon>
            </v-btn>
          </template>
          <span>
            {{ imports.title }}
          </span>
        </v-tooltip>
      </span>
    </template>
    <v-card class="diagnostic-model">
      <v-toolbar dense class="diagnostic-model__toolbar">
        <v-toolbar-title>
          <v-icon class="mr-1">upload</v-icon>
          <span> {{ imports.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="wi-list-imports__origins">
        <h3>Selecione de onde você irá importar</h3>
        <v-list two dense flat>
          <template v-for="(origin, originKey) in imports.origins">
            <div :key="originKey">
              <v-divider></v-divider>
              <v-list-tile
                avatar flat
                v-prms="{ id: origin.permission, mode: 'disabled' }"
                @click="runImport(origin)"
              >
                <v-list-tile-avatar tile>
                  <v-img :src="origin.image" :alt="origin.title" width="50px" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong style="font-size:20px;" v-html="origin.title"></strong>
                    <span v-if="origin.disabled" style="font-size:20px;"> (Desativado)</span>
                  </v-list-tile-title>
                  <v-list-tile-sub-title class="hidden-xs-only hidden-sm-only">
                    <span style="font-size: 14px; color: gray;">
                      {{ origin.description }}
                    </span>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
          </template>
          <v-divider></v-divider>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'WiListExports',
    data: () => ({
      dialog: false,
    }),
    methods: {
      runImport: function (origin) {
        console.log(origin)
        this.$WiEditDialog({
          wiConfig: origin.wiConfig,
          onSubmit: (response) => {
            this.$emit('imported', response)
            if (origin.onImported) {
              origin.onImported({
                response,
                import: origin,
                imports: this.imports,
                router: this.$router,
                route: this.$route
              })
            }
          },
          redirectOnSubmit: false
        })
      }
    },
    props: {
      mode: {
        default: 'button'
      },
      imports: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .diagnostic-model {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-model__enable-button {
    cursor: pointer;
  }
  .diagnostic-model__toolbar {
    z-index: 999;
  }

  .diagnostic-model__model {
    text-align: left;
    font-size: 15px;
    color: gray;
  }
  .diagnostic-model__not-found {
    margin: 50px 0;
  }

  .wi-list-imports__origins {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .wi-list-imports__origins h3 {
    padding-bottom: 20px;
  }
</style>
