export default {
  icon: 'text_fields',
  title: 'Informação Complementar',
  apiUrl: 'register/person/info',
  maxWidth: '400px',
  fields: [
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      required: true,
      list: true,
      edit: false
    },
    {
      value: 'description',
      text: 'Descrição',
      counter: 15,
      type: 'text',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'information',
      text: 'Informação Complementar',
      counter: 100,
      type: 'textarea',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      required: true,
      list: true,
      edit: true
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}