import { ContractProduct, ContractService } from '@/modules/business/config/Contract/ContractItem'

export class InternetContractProduct extends ContractProduct {
  constructor () {
    super()
    this.apiUrl = 'internet/contract/product'
  }
}

export class InternetContractService extends ContractService {
  constructor () {
    super()
    this.apiUrl = 'internet/contract/service'
  }
}

export class InternetContractPlan extends InternetContractService {
  constructor () {
    super()
    this.title = {
      edit: {
        insert: 'Selecionar plano de navegação',
        update: 'Editar plano de navegação'
      }
    }
    this.fieldsEdit.itemId.filter['host_config_exist'] = true
    this.fieldsEdit.description.size = ['xs12', 'sm12', 'md8', 'lg8']
    delete this.fieldsEdit.quantity
    this.fieldsFixed.push({
      value: 'is_master',
      data: true
    })
    this.fieldsFixed.push({
      value: 'quantity',
      data: 1
    })
    this.clearFields()
    this.setFields(this.fieldsEdit)
  }
}
