<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="55%" >
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark v-on="on" class="hidden-xs-only hidden-sm-only">Ativar Servidor</v-btn>
    </template>
    <v-card>
      <v-toolbar dense>
        <v-toolbar-title>
          <v-icon>settings</v-icon>
          <strong class="ml-2">Instalar Novo Servidor de Monitoramento</strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="padding: 0;">
        <v-container style="padding: 0;">
          <v-layout row wrap>
            <v-flex xs12>
              <v-list dense one-line>
                <template>
                  <v-subheader>Passos para ativar um servidor de monitoramento</v-subheader>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>1</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Tenha um servidor/computador com sistema operacional Windows 7 ou superior.</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>2</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Verifique se seu servidor Windows possui arquitetura x86 ou x64.</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>3</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <span> Faça Download do módulo de monitoramento x86 ou x64. </span>
                        <a :href="getFileLink('x86')">Download x86</a> ou 
                        <a :href="getFileLink('x64')">Download x64</a>.
                      </v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>4</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Extraia a pasta "Connect-View-Server" do arquivo baixado para a pasta C:/ de seu servidor Windows.</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>5</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Acesse a pasta Connect-View-Server em C:/ e execute o arquivo "Connect View Server".</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>6</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Selecione o servidor que será instalado no Connect View e clique sobre ele.</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>7</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Copie o Token de ativação e cole no módulo de monitoramento em execução em seu servidor Windows.</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar style="font-size: 30px;"><strong>8</strong></v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Se o sistema não reportar nenhum erro estará tudo pronto. Caso contrário, entre em contato com o suporte.</v-list-tile-title>
                      <v-list-tile-sub-title></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-list><br><br>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DialogInstalarServidor',
    data: () => ({
      dialog: false
    }),
    methods: {
      getFileLink: function (type) {
        const URL = this.$store.state.url.baseDefault
        const URI = `frontend/system/server/download/${type}/`
        const FileName = `Connect-View-Server-${type}.zip`
        const token = 'jFROlULQOelhihlWAxp8ckux5hPDfrOFyhTZ2fqHARoi0Q50Dk'
        return `${URL}${URI}${FileName}?token=${token}`
      }
    }
  }
</script>
