import { callApi } from '@/default/service/Api'
import { Notifications } from '@/default/service/Notifications'

export default {
  namespaced: true,
  state: {
    total: 0,
    current_page: 1,
    last_page: 0,
    notifications: [],
    loading: false
  },
  mutations: {
    SET_TOTAL (state, data) {
      state.total = data
    },
    SET_CURRENT_PAGE (state, data) {
      state.current_page = data
    },
    SET_LAST_PAGE (state, data) {
      state.last_page = data
    },
    SET_NOTIFICATIONS (state, notifications) {
      state.notifications = Notifications.format(notifications)
    },
    ADD_NOTIFICATIONS (state, notifications) {
      notifications.map(notification => {
        state.notifications.push(Notifications.format(notification))
      })
    },
    ADD_NOTIFICATION (state, notification) {
      state.notifications.unshift(Notifications.format(notification))
      state.total = state.total + 1
    },
    SET_LOADING (state, data) {
      state.loading = data
    }
  },
  actions: {
    getNotifications: ({ commit }) => {
      commit('SET_LOADING', true)
      callApi.get({
        uri: 'system/notification',
        params: {},
        sucess: response => {
          commit('SET_NOTIFICATIONS', response.data.data)
          commit('SET_TOTAL', response.data.total)
          commit('SET_CURRENT_PAGE', response.data.current_page)
          commit('SET_LAST_PAGE', response.data.last_page)
          commit('SET_LOADING', false)
        },
        error: () => {
          commit('SET_LOADING', false)
        }
      })
    },
    paginateNotifications: ({ state, commit }) => {
      commit('SET_LOADING', true)
      callApi.get({
        uri: 'system/notification',
        params: {'page': state.current_page + 1},
        sucess: response => {
          commit('ADD_NOTIFICATIONS', response.data.data)
          commit('SET_TOTAL', response.data.total)
          commit('SET_CURRENT_PAGE', response.data.current_page)
          commit('SET_LAST_PAGE', response.data.last_page)
          commit('SET_LOADING', false)
        },
        error: () => {
          commit('SET_LOADING', false)
        }
      })
    },
    readAllNotifications: ({ commit }) => {
      commit('SET_LOADING', true)
      callApi.put({
        uri: 'system/notification/read',
        id: 'all',
        sucess: () => {
          commit('SET_NOTIFICATIONS', [])
          commit('SET_TOTAL', 0)
          commit('SET_CURRENT_PAGE', 1)
          commit('SET_LAST_PAGE', 1)
          commit('SET_LOADING', false)
        },
        error: () => {
          commit('SET_LOADING', false)
        }
      })
    }
  }
}