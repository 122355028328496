import axios from 'axios'

export class Axios {
  #instance;

  constructor ({ baseURL }) {
    this.#instance = axios.create({
      baseURL: baseURL,
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  get instance () {
    return this.#instance
  }
}
