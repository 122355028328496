import { Model } from '@/default/model/Model'

export class FinancePayment extends Model {
  constructor () {
    super()
    this.list = true
    this.id = 'dc1919bd'
    this.actions = {
      list: true,
      edit: false
    }
    this.name = 'finance-payment'
    this.title = {
      list: 'Recebimentos',
      edit: {
        insert: 'Novo Recebimento',
        update: 'Editar Recebimento'
      }
    }
    this.apiUrl = 'finance/payment'
    this.icon = 'credit_card'
    this.search = {
      placeholder: 'Pesquise ID, Código de integração',
      fields: ['id', 'integration_order', 'integration_payment']
    }
    this.components.list.tableRow = () => import('@/modules/finance/pages/Finances/FinancesPayments')
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.setFields([
      this.fieldSwitch({
        value: 'make_fees',
        text: 'Adicionar Multa/Júros. Multa de 1% sobre o valor total + 0,01% de júros a cada dia após o vencimento.',
        size: ['xs12', 'sm12', 'md5', 'lg5']
      }),
      this.fieldSwitch({
        value: 'set_new_validity',
        text: 'Definir nova data de validade manualmente. Caso não marque esta opção, será definido para próximo dia útil.',
        size: ['xs12', 'sm12', 'md5', 'lg5']
      }),
      this.fieldDate({
        value: 'date_validity',
        text: 'Nova data de vencimento',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        required: form => { return form.set_new_validity },
        disabled: form => { return !form.set_new_validity }
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'amount',
        text: 'Valor'
      }),
      this.fieldList({
        value: 'date_validity',
        text: 'Validade'
      }),
      this.fieldList({
        value: 'emitted_at',
        text: 'Emissão'
      }),
      this.fieldList({
        value: 'payded_at',
        text: 'Pag./Cancel.'
      }),
      this.fieldList({
        value: 'status.name',
        text: 'Status'
      }),
      this.fieldList({
        value: 'via',
        text: 'Via'
      }),
      this.fieldList({
        value: 'integration_order',
        text: 'Order'
      }),
      this.fieldList({
        value: 'integration_payment',
        text: 'Payment'
      }),
      this.fieldList({
        value: 'id',
        text: 'Opções'
      })
    ])
  }
}
