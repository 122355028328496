import { Model } from '@/default/model/Model'

export class NetworkUser extends Model {
  constructor () {
    super()
    this.list = false
    this.id = '31f8eef9'
    this.actions = {
      create: true,
      update: true,
      delete: false
    }
    this.name = 'network-user'
    this.title = {
      list: 'Usuários de rede',
      edit: {
        insert: 'Novo usuário de rede',
        update: 'Editar usuário de rede'
      }
    }
    this.params = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'vertical_split'
    this.apiUrl = 'networks/network/user'
    this.maxWidth = '598px'
    this.setFields(this.fieldsEdit)
  }
  fieldsEdit = {
    subheader1: this.fieldSubHeader({
      text: 'Insira os dados do usuário de rede.',
      sortable: false
    }),
    username: this.fieldText({
      value: 'username',
      text: 'Usuário',
      required: true,
      placeholder: 'Insira o usuário PPPoE',
      size: ['xs12', 'sm12', 'md4', 'lg4']
      // rules: [this.rules.required(), this.rules.minLength(8), this.rules.maxLength(100)]
    }),
    password: this.fieldText({
      value: 'password',
      text: 'Senha',
      required: true,
      placeholder: 'Insira a senha PPPoE',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      rules: [this.rules.required(), this.rules.minLength(8), this.rules.maxLength(100)]
    }),
    status_id: this.fieldSelectApi ({
      value: 'status_id',
      text: 'Status',
      show: 'name',
      api: {
        url: 'networks/network/user/status'
      },
      size: ['xs12', 'sm12', 'md4', 'lg4'],
    }),
    network_user_config_id: this.fieldSelectApi({
      value: 'network_user_config_id',
      text: 'Plano de navegação*',
      required: false,
      disabled: form => (!form.hostpostMode),
      size: form => {
        if (!form.ip_address && form.set_ip_address) {
          return [ 'xs12', 'sm12', 'md4', 'lg4' ]
        } else if (form.ip_address || form.set_ip_address) {
          return [ 'xs12', 'sm12', 'md6', 'lg6' ]
        }
        return [ 'xs12', 'sm12', 'md8', 'lg8' ]
      },
      api: {
        url: 'networks/network/user/config',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    setIPAddress: this.fieldSwitch({
      value: 'set_ip_address',
      text: 'Definir IP Fixo',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      showField: (form) => { return !form.ip_address }
    }),
    ip_address: this.fieldText({
      value: 'ip_address',
      text: 'IP Fixo',
      required: true,
      placeholder: 'Insira o IP fixo',
      size: form => {
        if (!form.ip_address && form.set_ip_address) {
          return ['xs12', 'sm12', 'md4', 'lg4']
        }
        return ['xs12', 'sm12', 'md6', 'lg6']
      },
      rules: [this.rules.required(), this.rules.minLength(8), this.rules.maxLength(100)],
      showField: (form) => { return form.ip_address || form.set_ip_address }
    }),
    subheader2: this.fieldSubHeader({
      text: 'Observações adicionais.',
      sortable: false
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações adicionais'
    })
  }
}
