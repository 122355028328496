<template>
  <v-card v-if="grupo">
    <v-card-title>
      <strong>Copiar dispositovos de "{{grupo.nome_grupo}}"</strong>
      <!-- <v-btn color="primary" @click="grupos.push({servidor_dados_id: grupo.id, ativo_disp: 'ativo', mode: 'edit'})"><v-icon>add</v-icon> Criar dispositivo Manualmente</v-btn> -->
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <view-detail title="Grupos" apiUrl="disp/criar-importar"  :notGetID="true" :notShowToolbar="true">
        <template slot-scope="dados">
          <v-form v-model="validGrupos">
            <v-data-table dense :headers="headers" :items="grupos" hide-actions>
              <template slot="items" slot-scope="props">
                <tr v-if="grupos[props.index].mode == 'edit'" :style="{background: grupos[props.index].id ? '#b0fcb0' : '#ffedce'}">
                  <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" autofocus v-model="grupos[props.index].nome_disp" :placeholder="headers[0].text" single-line required :rules="[v => !!v || 'Campo obrigatório']"></v-text-field></td>
                  <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" v-model="grupos[props.index].sigla_disp" :placeholder="headers[1].text" single-line></v-text-field></td>
                  <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" v-model="grupos[props.index].ip_disp" counter="15" placeholder="###.###.###.###" single-line required :rules="[v => !!v || 'Obrigatório']"></v-text-field></td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].grupo_dados_id" :items="dados.content.grupos" placeholder="Grupo Principal" item-text="nome_sigla_grupo" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" autocomplete ></v-autocomplete>
                  </td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].disp_tipo_id" :items="dados.content.tipos" placeholder="Tipo de dispositiv" item-text="nome_tipo" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" autocomplete ></v-autocomplete>
                  </td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].equipamento_dados_id" :items="dados.content.equipamentos" placeholder="Modelo de Equipamento" item-text="nome_equipamento_fabricante" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" ></v-autocomplete>
                  </td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].ativo_disp" :items="[{value: 'ativo', text: 'Sim'}, {value: 'inativo', text: 'Não'}]" placeholder="Monitorado?" required :rules="[v => !!v || 'Campo obrigatório']"></v-autocomplete>
                  </td>
                  <td class="justify-center layout px-0" style="padding: 10px 8px 0 8px;">
                    <v-btn v-if="!grupos[props.index].id" :disabled="!grupos[props.index].nome_disp || !grupos[props.index].ip_disp || !grupos[props.index].grupo_dados_id" @click="postGroup(props.index, grupos[props.index])" flat icon color="green">
                      <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn v-else :disabled="!grupos[props.index].nome_disp || !grupos[props.index].ip_disp || !grupos[props.index].grupo_dados_id" @click="updateGroup(props.index, grupos[props.index])" flat icon color="green">
                      <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-btn v-if="!grupos[props.index].id" :disabled="!grupos[props.index].nome_disp || !grupos[props.index].ip_disp || !grupos[props.index].grupo_dados_id" @click="removeItem(props.index)" flat icon color="green">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-else :style="{background: grupos[props.index].id ? '#b0fcb0' : '#ffedce'}">
                  <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].nome_disp}}</td>
                  <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].sigla_disp}}</td>
                  <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].ip_disp}}</td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].grupo_dados_id" :items="dados.content.grupos" placeholder="Selecione o Grupo" item-text="nome_sigla_grupo" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" autocomplete ></v-autocomplete>
                  </td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].disp_tipo_id" :items="dados.content.tipos" placeholder="Tipo de dispositiv" item-text="nome_tipo" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" autocomplete ></v-autocomplete>
                  </td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].equipamento_dados_id" :items="dados.content.equipamentos" placeholder="Modelo de Equipamento" item-text="nome_equipamento_fabricante" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" autocomplete ></v-autocomplete>
                  </td>
                  <td class="text-xs-left" style="padding: 0 8px;">
                    <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].ativo_disp" :items="[{value: 'ativo', text: 'Sim'}, {value: 'inativo', text: 'Não'}]" placeholder="Monitorado?" required :rules="[v => !!v || 'Campo obrigatório']"></v-autocomplete>
                  </td>
                  <td class="justify-center layout px-0" style="padding: 10px 8px 0 8px;">
                    <v-btn v-if="!grupos[props.index].id" :disabled="!grupos[props.index].nome_disp || !grupos[props.index].ip_disp || !grupos[props.index].grupo_dados_id" @click="postGroup(props.index, grupos[props.index])" flat icon color="green">
                      <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn v-else :disabled="!grupos[props.index].nome_disp || !grupos[props.index].ip_disp || !grupos[props.index].grupo_dados_id" @click="updateGroup(props.index, grupos[props.index])" flat icon color="green">
                      <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-btn v-if="grupos[props.index].mode != 'edit'" @click="grupos[props.index].mode = 'edit'; $forceUpdate()" flat icon color="green">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-form>
        </template>
      </view-detail>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot name="action"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'GrupoInfo',
    data () {
      return {
        validGrupos: false,
        headers: [
        { text: 'Nome Dispositivo', align: 'left', sortable: true, value: 'nome_grupo', width: '50px' },
        { text: 'Sigla', align: 'left', value: 'sigla_grupo', width: '30px' },
        { text: 'IP', align: 'left', value: 'ip_disp', width: '50px' },
        { text: 'Grupo', align: 'left', value: 'grupo_dados_id', width: '100px' },
        { text: 'Tipo', align: 'left', value: 'disp_tipo_id', width: '100px' },
        { text: 'Equipamento', align: 'left', value: 'equipamento_dados_id', width: '100px' },
        { text: 'Monitorável?', align: 'left', value: 'ativo_disp', width: '50px' },
        { text: 'Ações', width: '80px' }
        ],
        grupos: [],
        saving: null
      }
    },
    mounted: function () {
      this.loadApi()
    },
    methods: {
      loadApi: function () {
        callApi.get({uri: 'grupo/disp-conexao/' + this.grupo.id, params: {join: 'dispositivos'}, sucess: this.getGroups, msgError: true})
      },
      getGroups: function (data) {
        console.log(data.data)
        this.grupos = data.data.dispositivos.filter((g) => {
          delete g.id
          g['mode'] = 'edit'
          return g
        })
        console.log(this.grupos)
      },
      removeItem: function (index) {
        console.log(index)
        delete this.grupos.splice(index, 1)
        this.$forceUpdate()
      },
      filterGroups (item) {
        return item.filter((t) => {
          if (t.funcao_tipo === 2) {
            return t
          }
        })
      },
      postGroup: function (index, group) {
        if (group) {
          this.saving = index
          callApi.post({
            uri: 'disp/dados',
            data: group,
            sucess: (data) => {
              this.grupos[index] = data.data
              this.saving = null
              this.$forceUpdate()
            },
            msgLoad: false,
            msgSucess: false,
            msgError: true
          })
        }
      },
      updateGroup: function (index, group) {
        if (group) {
          this.saving = index
          callApi.put({
            uri: 'disp/dados',
            data: group,
            id: group.id,
            sucess: (data) => {
              this.grupos[index] = data.data
              this.saving = null
              this.$forceUpdate()
            },
            msgLoad: true,
            msgSucess: true,
            msgError: true
          })
        }
      }
    },
    components: {
      ViewDetail
    },
    props: ['grupo']
  }
</script>
<style>

.xlsx-button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px 8px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
  transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
  position: relative;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color: #367da6 !important;
  border-color: #367da6 !important;

  padding: 0 16px;


}

</style>