<template>
  <v-card-text v-if="actions.length > 0">
    <v-menu offset-y>
      <template v-slot:activator="{ on }"> 
        <v-btn v-on="on" :disabled="status.name != 'closed'" block outline color="primary">
          <v-icon>more_vert</v-icon>
          <span>Opções</span>
        </v-btn>
      </template>
      <v-list>
        <template v-for="(action, actionKey) in selectedActions">
          <v-divider v-if="actionKey" :key="`${actionKey}-divider`"></v-divider>
          <v-list-tile :key="`${actionKey}-tile`" @click="executeAction(action)">
            <v-list-tile-action>
              <v-icon large v-text="action.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="action.title"></v-list-tile-title>
              <v-list-tile-sub-title v-text="action.description"></v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-menu>
  </v-card-text>
</template>
<script>
  import ActionsMixin from './ActionsMixin'
  export default {
    name: 'DiagnosticActions',
    data: () => ({
      actionIds: [
        '59c0e518-8122-45ad-9811-273f8804b354',
        '90d2de5e-4d55-4a38-8585-a53087f2f8cc',
        '493c8559-4cdc-4848-a874-b4020d778943'
      ]
    }),
    mixins: [ActionsMixin],
    props: {
      actions: {
        required: true,
        default: () => ([])
      },
      status: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
