var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-detail',{attrs:{"index":"diagnostic","title":"Detalhes do Diagnóstico","apiUrl":"networks/test-management-vtal","apiParams":"?with=finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;host;host.active_devices","store":"diagnostic"},scopedSlots:_vm._u([{key:"toolbarItensBefore",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
var loading = ref.loading;
return [(content)?_c('BusinessActions',{attrs:{"business":content,"refresh":refresh,"loading":loading}}):_vm._e()]}},{key:"default",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessInfo',{attrs:{"info":_vm.configuration,"data":content}})],1),(content.host)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessHosts',{attrs:{"business":content,"hosts":[{host: content.host}]},on:{"onSubmit":refresh}})],1):_vm._e(),(content.category.form && content.category.form.id)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessForm',{attrs:{"refresh":refresh,"reply":content.reply,"businessId":content.id,"form":content.category.form,"replyEditor":content.reply_editor}})],1):_vm._e(),(content.observation)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessObservation',{attrs:{"observation":content.observation}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }