import { DateTime } from '@/default/plugins/Utils/DateTime'
import { Axios } from '@/default/plugins/Axios/Axios'
import url from '@/default/store/modules/url/url'
import { Auth } from '@/default/service/Auth'

export class WiApiService extends Axios {
  #uri;

  constructor () {
    const uriBase = 'v1/'
    super({ baseURL: url.state.base + uriBase })
    this.requestInterceptor()
    this.responseInterceptor()
  }

  get uri () {
    return this.#uri
  }

  set uri (uri) {
    this.#uri = uri
  }

  requestInterceptor () {
    this.instance.interceptors.request.use(request => {
      if (Auth.check()) {
        request.headers.Authorization = 'Bearer ' + Auth.token()
        request.headers.Hash = Auth.hash()
      }
      return request
    },
    error => {
      return Promise.reject(error)
    })
  }

  responseInterceptor () {
    this.instance.interceptors.response.use(response => {
      return response
    },
    async error => {
      const statusCode = error?.response?.status
      this.responseStatusCodeActions(statusCode)
      return Promise.reject(error)
    })
  }

  responseStatusCodeActions (statusCode) {
    switch (statusCode) {
      case 401:
        Auth.logout()
        break
      case 403:
        Auth.logout()
        break
      case 404:
        console.warn('Desculpe, este conteúdo não foi encontrado!')
        break
    }
  }

  list (params = {}) {
    return this.instance.get(this.#uri, { params })
      .then(response => (this.getResponse(true, response?.data)))
      .catch(error => {
        return this.getResponse(false, error)
      })
  }

  findById (id, params = {}) {
    return this.instance.get(`${this.#uri}/${id}`, { params })
      .then(response => (this.getResponse(true, response?.data)))
      .catch(error => {
        return this.getResponse(false, error)
      })
  }

  create (data) {
    return this.instance.post(this.#uri, data)
      .then(response => (this.getResponse(true, response?.data)))
      .catch(error => {
        return this.getResponse(false, error)
      })
  }

  update (id, data) {
    return this.instance.put(`${this.#uri}/${id}`, data)
      .then(response => (this.getResponse(true, response?.data)))
      .catch(error => {
        console.log(error.response)
        return this.getResponse(false, error)
      })
  }

  delete (id) {
    return this.instance.delete(`${this.#uri}/${id}`)
      .then(response => (this.getResponse(true, response?.data)))
      .catch(error => {
        console.log(error.response)
        return this.getResponse(false, error)
      })
  }

  getResponse (status, response) {
    return {
        status,
        response: status ? response : this.getResponseErrors(response)
    }
  }

  getResponseErrors (error) {
    if (error?.response) {
      const response = error.response
      switch (response.status) {
        case 404:
          return this.createMessage(null, 'Erro ao buscar informações.')
        case 422:
          return response.data
        case 429:
          return this.createMessage(null, this.create429Message(response))
        case 500:
          return this.createMessage(response?.data, 'Erro interno do servidor.')
        default:
          return false
      }
    }
    return false
  }

  createMessage (response, falbackMessage) {
    return {
      errors: {
        message: [response?.message || falbackMessage]
      }
    }
  }

  create429Message (response) {
    if (response?.headers) {
      console.log(response.headers)
      console.log(new Date(response.headers['x-ratelimit-reset'] * 1000))
      return `
      Você enviou um volume muito grande de solicitações.
      Tente novamente depois de ${DateTime.time(response.headers['x-ratelimit-reset'] * 1000)}.
      `
    }
  }
}
