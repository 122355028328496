<template>
  <v-card>
    <v-stepper v-model="stage" class="pb-4">
      <v-stepper-header>
        <v-stepper-step :complete="stage > 1" step="1">
          Leds
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stage > 2" step="2">
          Conexões
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          Confirmar
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <HostModelConfig
            v-if="stage == 1"
            :host="host"
            :model="model"
            name="leds"
            :config="model.leds"
            @valid="setValid"
          ></HostModelConfig>
        </v-stepper-content>
        <v-stepper-content step="2">
          <HostModelConfig
            v-if="stage == 2"
            :host="host"
            :model="model"
            name="connections"
            :config="model.connections"
            @valid="setValid"
          ></HostModelConfig>
        </v-stepper-content>
        <v-stepper-content step="3">
          <HostModelConfigSuccess
            v-if="stage == 3"
            @save="saveConfig()"
          ></HostModelConfigSuccess>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions class="elevation-20">
      <v-btn outline block color="red" class="white--text" @click="cancel()">
        <v-icon>close</v-icon>
        <span> Cancelar</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outline block color="orange" class="white--text" :disabled="stage == 1" @click="previous()">
        <v-icon>arrow_back</v-icon>
        <span> Voltar</span>
      </v-btn>
      <v-btn block color="green" class="white--text" :disabled="!valid[this.stage]" @click="next()">
        <v-icon>done</v-icon>
        <span> Próximo</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import HostModelConfig from './HostModelConfig'
  import HostModelConfigSuccess from './HostModelConfigSuccess'
  export default {
    name: 'ProducerModelConfigDialog',
    data: () => ({
      stage: 1,
      valid: {
        1: false,
        2: false,
        3: false
      },
      config: {}
    }),
    methods: {
      setValid: function ({ name, data }) {
        this.config[name] = data
        this.valid[this.stage] = true
      },
      next: function () {
        this.stage++
      },
      previous: function () {
        this.stage--
      },
      saveConfig: function () {
        this.$WiApiPut({
          uri: 'network/host',
          id: this.host.id,
          data: this.config,
          callback: response => {
            this.onSaved(response)
          },
          config: {
            msgLoad: {
              title: `Salvando configuração do equipamento`,
              text: 'Por favor aguarde...'
            },
            msgSucess: {
              title: 'Informações armazenadas com sucesso.',
              text: 'Sua tela será atualizada em instantes...'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja salvar?`,
              text: 'Para redefinir você precisará de acesso adm...'
            }
          }
        })
      },
      onSaved: function (response) {
        if (response.sucess) {
          console.log(response)
          this.$emit('onSaved', response)
          this.stage = 1
          this.valid = {
            1: false,
            2: false,
            3: false
          }
          this.config = {}
        }        
      },
      cancel: function () {
        this.$emit('cancel')
      }
    },
    props: {
      model: {
        required: true,
        default: () => ({})
      },
      host: {
        required: true,
        default: () => ({})
      },
      mode: {
        type: String,
        default: 'show'
      }
    },
    components: {
      HostModelConfig,
      HostModelConfigSuccess
    }
  }
</script>
<style scoped>

</style>
