<template>
  <view-detail title="Servidor" :routeReturn="{'name': 'servidor', params: {id: $route.params.id}}" :apiUrl="'importar/the-dude'" :notGetID="true">
    <template slot-scope="dados"> 
      <v-layout row style="padding: 20px;">
        <v-flex xs12 sm12 md12 lg12 >
          <v-card>37 9 9834-1330
            <v-card-text v-if="tela">
              <center>
                <h2>Importar dados do The Dude para o server {{dados.content.nome_servidor}}</h2>
              </center>
              <v-divider style="margin-top:20px;"></v-divider>
              <div style="padding: 20px;">
                <h3 style="text-align: center;">Por favor, exporte um arquivo CSV com a lista de todos os dispositivos do The dude, abra-o, cole seu conteúdo na área abaixo.</h3>
                <v-text-field v-model="conteudo" label="Cole o conteúdo do arquivo CSV aqui." textarea></v-text-field>
                <v-btn @click="lerArquivo(dados.content.id, dados.content.grupos)" style="width: 100%;">Ler Conteúdo Agora</v-btn>
                <!-- <v-text-field type="file" @change="teste(this)" ></v-text-field> -->
              </div>
            </v-card-text>
            <v-card-text v-if="!tela">
              <h2 style="width: 100%;">Total: {{dispositivos.length}}</h2>
              <v-form v-model="valid">{{valid}}
                <v-data-table dense :headers="headers" :items="dispositivos" item-key="nome_disp" hide-actions class="elevation-1">
                  <template slot="items" slot-scope="props">
                    <tr @click="props.expanded = !props.expanded" style="cursor: pointer;">
                      <td class="text-xs-left">{{ props.item.nome_disp.substring(0, 35) }}</td>
                      <td class="text-xs-left">{{ props.item.ip_disp }}</td>
                      <td class="text-xs-left">{{ props.item.grupo }}</td>
                      <td class="text-xs-left">{{ props.item.nome_grupo }}</td>
                      <!-- <td>
                        <v-select :change="setGrupo(props.index)" required autocomplete v-model="gruposSelecionados[props.index]" :items="dados.content.grupos" placeholder="Selecione o Grupo deste dispositivo" class="input-group--focused" item-value="id" item-text="nome_grupo" ></v-select>
                      </td> -->
                    <!-- <td>
                      <v-select :change="setEquipamento(props.index)" autocomplete :items="dados.content.equipamentos" v-model="equipamentosSelecionados[props.index]" label="Selecione o Equipamento" class="input-group--focused" item-value="id" item-text="nome_equipamento" ></v-select>
                    </td>
                    <td>
                      <v-select :change="setTipo(props.index)" autocomplete :items="dados.content['disp-tipo']" v-model="tiposSelecionados[props.index]" label="Selecione o Equipamento" class="input-group--focused" item-value="id" item-text="nome_tipo" ></v-select>
                    </td> -->
                  </tr>
                </template>
                <template slot="expand" slot-scope="props">
                  <v-card flat>
                    <v-card-text style="padding-left: 50px;">
                      <ul>
                        <li>Nome completo: {{ props.item.nome_disp }}</li>
                        <li>Observações: {{ props.item.obs_disp }}</li>
                        <li>MAC: {{ props.item.mac_disp }}</li>
                        <li>Ativo?: <span v-if="props.item.ativo_disp == '1'">Ativo</span><span v-else>Inativo</span></li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </v-form>
            <v-btn @click="enviaDispositivos()" style="width: 100%;">Enviar arquivos para o servidor</v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </template>
</view-detail>
</template>
<script>
  import {Api} from '@/default/service/Api'
  import ViewDetail from '@/default/components/ViewDetail'
  export default {
    data () {
      return {
        valid: false,
        gruposSelecionados: [],
        equipamentosSelecionados: [],
        tiposSelecionados: [],
        tela: true,
        conteudo: '',
        headers: [
        { text: 'Nome', align: 'left', sortable: true, value: 'nome_disp', width: '200px' },
        { text: 'IP', align: 'left', value: 'ip_disp', width: '100px' },
        { text: 'Observações', align: 'left', value: 'grupo', width: '200px' },
        { text: 'Grupo', align: 'left', value: 'nome_grupo', sortable: true }
        ],
        dispositivos: [],
        dispositivosAtivos: [],
        dispositivosInativos: []
      }
    },
    methods: {
      teste: function (evt) {
        console.log(evt.target.result.split('\n'))
      },
      lerArquivo: function (servidorDadosId, grupos) {
        var fileArr = this.conteudo.split('\n')
        var conteudoLocal = []
        for (var i = 1; i < fileArr.length; i++) {
          var campo = fileArr[i].split(',')
          var grupoDadosId = null
          conteudoLocal.push({
            servidor_dados_id: servidorDadosId,
            nome_disp: campo[1],
            ip_disp: campo[2],
            mac_disp: campo[3],
            ativo_disp: campo[0] === 'ativo' || campo[0] === 'inativo' ? 1 : 0,
            obs_disp: campo[5] + ' || ' + campo[4] + ' || ' + campo[0] + ' || ' + campo[6] + ' || ' + campo[7],
            grupo: campo[5],
            grupo_dados_id: grupoDadosId,
            nome_grupo: null
          })
        }

        console.log(conteudoLocal)

        for (var o = 0; o < conteudoLocal.length; o++) {
          for (var ii = 0; ii < grupos.length; ii++) {
            console.log(grupos[ii].nome_grupo + ' == ' + conteudoLocal[o].grupo)
            if (grupos[ii].nome_grupo === conteudoLocal[o].grupo) {
              conteudoLocal[o].grupo_dados_id = grupos[ii].id
              conteudoLocal[o].nome_grupo = grupos[ii].nome_grupo
            }
          }
        }
        console.log(conteudoLocal)
        this.dispositivos = conteudoLocal
        if (this.dispositivos.length > 0) {
          this.tela = false
        }
      },
      setGrupo: function (index) {
        this.dispositivos[index]['grupo_dados_id'] = this.gruposSelecionados[index]
      },
      enviaDispositivos: function () {
        console.log(JSON.stringify({multiple: this.dispositivos}))
        Api.post({url: this.url + 'disp/dados/'}, {multiple: this.dispositivos})
        .then((data) => {
          if (data.status === 201) {
            this.$swal({
              type: 'success',
              title: 'Tudo certo!',
              text: 'Concluído com sucesso.'
            })
          } else {
            this.$swal({
              type: 'error',
              title: 'Oops... Algo deu errado!',
              text: 'Tente novamente ou contacte o suporte.'
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
      }
    },
    computed: {
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      }
    },
    components: {
      ViewDetail
    }
  }
</script>