import { Model } from '@/default/model/Model'

export class PersonFunction extends Model {
  constructor (field, name) {
    super()
    this.id = '93dd92fc'
    this.title = 'Editar perfis/papeis do ' + name
    this.apiUrl = 'register/person/' + field
    this.icon = 'recent_actors'
    this.setFields([
      this.fieldSubHeader({
        text: 'Defina abaixo os papeis/perfis deste(a) ' + name + ' dentro do sistema.'
      }),
      this.fieldSelect({
        value: 'customer',
        text: 'Este(a) ' + name + ' também é um cliente?',
        placeholder: 'Este(a) ' + name + ' também é um cliente?',
        size: ['xs12', 'sm2', 'md3', 'lg3'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'customer'
        }
      }),
      this.fieldSelect({
        value: 'provider',
        text: 'Este(a) ' + name + ' também é um fornecedor?',
        placeholder: 'Este(a) ' + name + ' também é um fornecedor?',
        size: ['xs12', 'sm2', 'md3', 'lg3'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'provider'
        }
      }),
      this.fieldSelect({
        value: 'collaborator',
        text: 'Este(a) ' + name + ' também é um colaborador?',
        placeholder: 'Este(a) ' + name + ' também é um colaborador?',
        size: ['xs12', 'sm2', 'md3', 'lg3'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: (form) => {
          return form.type === 'Fisica' && field !== 'collaborator'
        }
      }),
      this.fieldSelect({
        value: 'prospect',
        text: 'Este(a) ' + name + ' também é um prospect?',
        placeholder: 'Este(a) ' + name + ' também é um prospect?',
        size: ['xs12', 'sm2', 'md3', 'lg3'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'prospect'
        }
      }),
      this.fieldSelect({
        value: 'company',
        text: 'Este(a) ' + name + ' também é uma Empresa (Matriz ou Filial?',
        placeholder: 'Este(a) ' + name + ' também é uma Empresa (Matriz ou Filial?',
        size: ['xs12', 'sm2', 'md3', 'lg3'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'company'
        }
      })
    ])
  }
}
