<template>
  <div style="padding-bottom: 10px;">
    <Host
      :host="host"
      :showConfig="status.code == 'user-action'"
      @onSelectModel="onSelectModel"
      @onSaveConfig="onSaveConfig"
    ></Host>
  </div>
</template>
<script>
  import Host from '@/modules/network/pages/Host'
  export default {
    name: 'HostCheckModel',
    methods: {
      onSelectModel: function () {
        this.$emit('onSelectModel', null)
      },
      onSaveConfig: function () {
        this.$emit('onSaveConfig', null)
      }
    },
    props: {
      host: {
        required: true,
        default: {}
      },
      status: {
        required: true,
        default: () => ({})
      },
      stage: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      Host
    }
  }
</script>
