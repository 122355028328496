<template>
  <v-timeline dense>
    <v-timeline-item :color="getFieldStatus('customer').color" :icon="getFieldStatus('customer').icon" small fill-dot>
      <DiagnosticSearchCustomer
        :data="getCustomer(diagnostic.customer)"
        @selected="setDiagnostic"
      ></DiagnosticSearchCustomer>
    </v-timeline-item>
    <v-timeline-item v-if="diagnostic.customer && diagnostic.customer.id" :color="getFieldStatus('contract').color" :icon="getFieldStatus('contract').icon" small fill-dot>
      <DiagnosticListDialog
        title="Contrato"
        icon="description"
        placeholder="Selecione um contrato"
        :data="getContract(diagnostic.contract)"
        wiConfig="contract"
        selected="contract"
        @selected="setDiagnostic"
        :filter="{ person_id: diagnostic.customer.id, orWhere: 'stage,2;stage,1' }"
        :openInMounted="true"
      ></DiagnosticListDialog>
    </v-timeline-item>
    <v-timeline-item v-if="diagnostic.customer && diagnostic.customer.id && diagnostic.contract && diagnostic.contract.id" :color="getFieldStatus('host').color" :icon="getFieldStatus('host').icon" small fill-dot>
      <DiagnosticListDialog
        title="Dispositivo"
        icon="router"
        placeholder="Selecione um Dispositivo"
        :data="getHost(diagnostic.host)"
        wiConfig="host"
        selected="host"
        @selected="setDiagnostic"
        :filter="{ contract_id: diagnostic.contract.id }"
        :openInMounted="true"
      ></DiagnosticListDialog>
    </v-timeline-item>
    <v-timeline-item v-if="diagnostic.customer && diagnostic.customer.id && diagnostic.contract && diagnostic.contract.id && diagnostic.host && diagnostic.host.id" :color="getFieldStatus('category').color" :icon="getFieldStatus('category').icon" small fill-dot>
      <DiagnosticListDialog
        title="Categoria"
        icon="category"
        placeholder="Selecione uma categoria"
        :data="getCategory(diagnostic.category)"
        wiConfig="config-category-diagnostic"
        selected="category"
        @selected="setDiagnostic"
        :filter="{ where: 'active,1' }"
        :openInMounted="true"
      ></DiagnosticListDialog>
    </v-timeline-item>
    <v-timeline-item v-if="diagnostic.customer && diagnostic.customer.id && diagnostic.contract && diagnostic.contract.id && diagnostic.host && diagnostic.host.id && diagnostic.category && diagnostic.category.id" :color="getFieldStatus('observation').color" :icon="getFieldStatus('observation').icon" small fill-dot>
      <v-card>
        <v-textarea
          v-model="diagnostic.observation"
          label="Insira a descição..."
          hide-details
          single-line
          solo
        ></v-textarea>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
  import DiagnosticSearchCustomer from './DiagnosticSearchCustomer'
  import DiagnosticListDialog from './DiagnosticListDialog'
  export default {
    name: 'DiagnosticCreateTimeline',
    data: () => ({
      diagnostic: {}
    }),
    computed: {
      isValid () {
        return !!this.diagnostic.customer && !!this.diagnostic.contract && !!this.diagnostic.host && !!this.diagnostic.category && !!this.diagnostic.observation
      }
    },
    methods: {
      getFieldStatus: function (field) {
        if (this.diagnostic[field]) {
          return { icon: 'done', color: 'green' }
        }
        return { icon: 'radio_button_unchecked', color: 'orange' }
      },
      getCustomer: function (customer) {
        return customer?.first_name ?? null
      },
      getContract: function (contract) {
        return contract?.address?.address_full ?? null
      },
      getHost: function (host) {
        return host?.description ?? null
      },
      getCategory: function (category) {
        return category?.name ?? null
      },
      setDiagnostic: function ({ field, data }) {
        this.diagnostic[field] = data
        this.updateProcess(this.diagnostic)
        this.$forceUpdate()
      },
      updateProcess: function (diagnostic) {
        const isValid = !!diagnostic.customer && !!diagnostic.contract && !!diagnostic.host && !!diagnostic.category && !!diagnostic.observation
        this.$emit('updateProcess', {
          data: diagnostic,
          isValid
        })
      }
    },
    watch: {
      'diagnostic.observation': function () {
        this.updateProcess(this.diagnostic)
      }
    },
    components: {
      DiagnosticSearchCustomer,
      DiagnosticListDialog
    }
  }
</script>