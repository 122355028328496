export default {
  SET_REPORT_DATA (state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
  },
  SET_REPORT_LOADING (state, loading) {
    state.loading = loading
  },
  SET_DATE (state, date) {
    state.date = date
  }
}
