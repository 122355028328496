<template>
  <div>
    <slot name="toolbar" :content="detail" :refresh="loadDetail" :loading="loading" :error="error"></slot>
    <v-toolbar dense :color="!dark ? 'primary' : 'dark'" v-if="!notShowToolbar" style="color: white;">
      <v-btn v-if="!notReturnIcon" icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span v-if="title && !fieldTitle">
          {{title}}
        </span>
        <span v-if="!title && fieldTitle && detail">
          {{detail[fieldTitle]}}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="toolbarItensBefore" :content="detail" :refresh="loadDetail" :loading="loading"></slot>
      <v-btn v-prms="{'id': wiConfig.id && wiConfig.id.update ? wiConfig.id.update : wiConfig.id}" class="hidden-xs-only hidden-sm-only" v-if="store" :disabled="loading" icon @click="$WiEditDialog({wiConfig: store, onSubmit: loadDetail, data: detail})">
        <v-icon style="color: white;">edit</v-icon>
      </v-btn>
      <v-btn :loading="loading" icon @click="loadDetail()">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
      <slot name="toolbarItensAfter" :content="detail" :refresh="loadDetail" :loading="loading"></slot>
    </v-toolbar>
    <div v-if="loading && notLoad">
      <v-progress-linear :indeterminate="true" style="margin: 0 !important;"></v-progress-linear>
    </div>
    <loading :loading="loading && (!notLoad || !detail)">
      <div v-if="detail && !error">
        <slot :content="detail" :refresh="loadDetail" :loading="loading"></slot>
      </div>
      <div v-if="error" style="width: 100%; text-align: center; padding-top: 50px;">
        <h1 style="padding-bottom: 20px;">Erro ao buscar informações. <br>Tente novamente ou contacte o suporte. </h1>
        <v-btn @click="loadDetail()" color="primary"><v-icon>refresh</v-icon> <span style="padding-left: 5px;">Tentar novamente</span></v-btn>
        <v-btn @click="$router.go(-1)"><v-icon>navigate_before</v-icon> Voltar</v-btn>
      </div>
    </loading>
  </div>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import Loading from '@/default/components/Loading'
  export default {
    name: 'WiView',
    data () {
      return {
        loading: true,
        detail: null,
        error: false
      }
    },
    watch: {
      '$route': 'loadView'
    },
    computed: {
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      dark () {
        return this.$wiDark || false
      },
      wiConfig () {
        return this.$store.state.pages[this.store] || {}
      }
    },
    created: function () {
      this.loadDetail()
    },
    methods: {
      loadView: function (router) {
        if (!this.defaultRoute || router.name === this.defaultRoute) {
          this.loadDetail()
        }
      },
      loadDetail: function (config) {
        this.loading = true
        var uri = ''
        var apiParams = (config && config.apiParams) ? config.apiParams : this.apiParams || ''
        var apiUrl = (config && config.apiUrl) ? config.apiUrl : this.apiUrl
        if (this.notGetID) {
          uri = apiUrl
        } else {
          if (this.$route.params && (this.$route.params.id > 0 || this.$route.params.id != '')) {
            uri = apiUrl + '/' + this.$route.params.id
          }
        }
        const urlParams = new URLSearchParams(apiParams);
        const params = Object.fromEntries(urlParams);
        callApi.get({
          uri,
          params,
          sucess: (data) => {
            this.error = false
            this.loading = false
            this.detail = data.data
            this.$emit('action', false)
            this.$emit('afterLoad', {content: data.data, refresh: this.loadDetail})
          },
          error: (error) => {
            this.error = true
            this.loading = false
            var title = 'Erro ao buscar o item de ID: ' + this.$route.params.id + '.'
            if (this.notGetID) {
              title = 'Erro ao buscar informações.'
            }
            this.$WiMakeError({error: error, id: 101, title: title})
          } 
        })
        // .then((data) => {
        //   this.error = false
        //   this.loading = false
        //   this.detail = data.data
        //   this.$emit('action', false)
        // })
        // .catch((error) => {
        //   this.error = true
        //   this.loading = false
        //   var title = 'Erro ao buscar o item de ID: ' + this.$route.params.id + '.'
        //   if (this.notGetID) {
        //     title = 'Erro ao buscar informações.'
        //   }
        //   this.$WiMakeError({error: error, id: 101, title: title})
        // })
      },
      // loadDetail: function (config) {
      //   this.loading = true
      //   var url = ''
      //   var apiParams = (config && config.apiParams) ? config.apiParams : this.apiParams || ''
      //   var apiUrl = (config && config.apiUrl) ? config.apiUrl : this.apiUrl
      //   if (this.notGetID) {
      //     url = this.url + apiUrl + (apiParams || '')
      //   } else {
      //     if (this.$route.params && this.$route.params.id) {
      //       url = this.url + apiUrl + '/' + this.$route.params.id + (apiParams || '')
      //     }
      //   }
      //   Api.get({url: url})
      //   .then((data) => {
      //     this.$emit('afterLoad', {content: data.data, refresh: this.loadDetail})
      //     this.error = false
      //     this.loading = false
      //     this.detail = data.data
      //     this.$emit('action', false)
      //   })
      //   .catch((error) => {
      //     this.error = true
      //     this.loading = false
      //     var title = 'Erro ao buscar o item de ID: ' + this.$route.params.id + '.'
      //     if (this.notGetID) {
      //       title = 'Erro ao buscar informações.'
      //     }
      //     this.$WiMakeError({error: error, id: 101, title: title})
      //   })
      // }
    },
    components: {
      loading: Loading
    },
    props: ['apiUrl', 'apiParams', 'routeReturn', 'title', 'notGetID', 'notShowToolbar', 'defaultRoute', 'store', 'fieldTitle', 'notLoad', 'notReturnIcon']
  }
</script>