import { Model } from '@/default/model/Model'

export class TicketJustify extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '4f1be58b'
    this.name = 'ticket-justify'
    this.title = {
      list: 'Motivos do Chamado',
      edit: {
        insert: 'Novo motivo',
        update: 'Editar motivo'
      }
    }
    this.apiUrl = 'service-desk/ticket/justify'
    this.icon = 'bolt'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquisar pelo motivo do chamado',
      fields: ['nome_just']
    }
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls8', 'offset-xl8']
    this.maxWidth = '800px'
    this.setFields([
      this.fieldText({
        value: 'nome_just',
        text: 'Justificativa'
      }),
      this.fieldTextArea({
        value: 'obs_just',
        text: 'Observação'
      }),
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'nome_just',
        text: 'Justificativa'
      }),
      this.fieldList({
        value: 'obs_just',
        text: 'Observações'
      })
    ])
  }
}