import { BusinessSimple } from '@/modules/business/config/BusinessSimple'
import {
  PERMISSION_TEST_MANAGEMENT_VTAL_IN,
  PERMISSION_TEST_MANAGEMENT_VTAL_IN_CREATE,
  PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE,
  PERMISSION_TEST_MANAGEMENT_VTAL_IN_DELETE
} from '@/default/constants/permissions'

export class Diagnostic extends BusinessSimple {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_TEST_MANAGEMENT_VTAL_IN,
      create: PERMISSION_TEST_MANAGEMENT_VTAL_IN_CREATE,
      update: PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE,
      delete: PERMISSION_TEST_MANAGEMENT_VTAL_IN_DELETE
    }
    this.name = 'diagnostic'
    this.title = {
      list: 'Diagnósticos',
      edit: {
        insert: 'Novo Diagnóstico',
        update: 'Atualizar Diagnóstico'
      }
    }
    this.icon = 'bug_report'
    this.apiUrl = 'networks/test-management-vtal'
    this.urlDetails.name = 'diagnostic'
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/TestManagements/Diagnostics/Diagnostics')
    
    this.fields = []
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
    this.fieldsEdit.person.size = ['xs12', 'sm12', 'md4', 'lg4']
    this.fieldsEdit.collaborator.size = ['xs12', 'sm12', 'md3', 'lg3']
    this.fieldsEdit.dateStart.text = 'Data de Referência'
    // this.fieldsEdit.dateEnd.text = 'Data de execução'
    this.fieldsEdit.subHeader1.text = 'Informe os dados deste diagn.'
    this.fieldsEdit.status.apiUrl = 'networks/config/status/test-management-vtal'
    this.fieldsEdit.category.apiUrl = 'networks/config/category/test-management-vtal'
    this.fieldsEdit.category.disabled = form => !!form.id
  }
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    personName: this.fieldList({
      value: 'person.name',
      text: 'Nome do Cliente'
    }),
    status: this.fieldList({
      value: 'business_status_id',
      text: 'Status/Etapa',
      align: 'center'
    }),
    dateStart: this.fieldList({
      value: 'date_start',
      text: 'Data de Referência'
    }),
    collaborator: this.fieldList({
      value: 'business_category_id',
      text: 'Categoria/Tipo',
      align: 'center'
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      sortable: false
    })
  }
  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste contrato.'
    }),
    company: this.fieldsEdit.company,
    dateStart: this.fieldsEdit.dateStart,
    person: this.fieldsEdit.person,
    contract: this.fieldWiListDialog({
      value: 'business_id',
      text: 'Contrato do cliente',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      wiConfig: 'contract',
      show: 'contract.integration_code',
      icon: 'person_outline',
      // showField: (form) => (!!form.person_id),
      filter: form => ({
        where: 'person_id,' + form.person_id
      }),
      disabled: form => !!form.id
    }),
    host: this.fieldWiListDialog({
      value: 'host_id',
      text: 'Equipamento do cliente',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      wiConfig: 'host',
      show: 'host.identification',
      icon: 'router',
      // showField: (form) => (!!form.person_id),
      filter: form => ({
        contract_id: form.business_id
      }),
      disabled: form => !!form.id
    }),
    category: this.fieldsEdit.category,
    status: this.fieldsEdit.status,
    collaborator: this.fieldsEdit.collaborator,
    subHeader2: this.fieldSubHeader({
      text: 'Descreva a solicitação abaixo.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Descrição da solicitação',
      placeholder: 'Ex: Cliente se encontra sem conexão...',
      required: true,
      disabled: form => !!form.id
    })
  }
}

export class DiagnosticOpened extends Diagnostic {
  constructor () {
    super()
    this.title.list = 'Diagnósticos Em Aberto'
    this.params.stage = 'open'
  }
}
export class DiagnosticClosed extends Diagnostic {
  constructor () {
    super()
    this.title.list = 'Histórico de Diagnósticos'
    this.params.stage = 'close'
  }
}
export class DiagnosticCanceled extends Diagnostic {
  constructor () {
    super()
    this.title.list = 'Diagnósticos Canceladas'
    this.params.stage = 'cancel'
  }
}
