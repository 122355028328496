<template>
  <v-card>
    <v-card-text v-if="weekdays && weekdays.id" style="text-align: center;">
      <h2>
        <v-icon>schedule</v-icon>
        <span> Horário de Funcionamento</span>
      </h2>
      <br>
      <br>
      <v-flex xs12 sm10 offset-sm1>
        <table style="width: 100%; font-size: 18px;">
          <thead>
            <tr>
              <th></th>
              <th>Dia da semana</th>
              <th>Horários</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(day, dayIndex) in formattedWeekdays"
              :key="`${dayIndex}-day`"
              style="background: rgb(209, 209, 209);  margin: 10px 0 !important;"
              :style="{
                'background': $wiDark ? '#212121' : 'rgb(240, 240, 240)',
              }"
            >
              <td>
                <v-btn icon @click="addDayHours(day)" :disabled="dayHaveFreeHours(day)">
                  <v-icon color="green">add</v-icon>
                </v-btn>
              </td>
              <td>
                <span>
                  {{ getDayName(day.day_of_week_id) }}
                </span>
              </td>
              <td>
                <div v-if="day.hours && day.hours.length > 0" style="padding: 7px 0;">
                  <div v-for="(hour, hourIndex) in day.hours" :key="`${hourIndex}-hour`">
                    <v-divider v-if="!!hourIndex"></v-divider>
                    <span>
                      <v-icon color="green">start</v-icon>
                      <span style="margin-left: 10px;">{{ hour.start_time }}</span>
                    </span>
                    <span style="margin-left: 30px;">
                      <v-icon color="orange">logout</v-icon>
                      <span style="margin-left: 10px;">{{ hour.end_time }}</span>
                    </span>
                    <span style="margin-left: 30px;">
                      <v-btn v-if="hour.id" icon @click="editDay(hour)">
                        <v-icon color="blue">edit</v-icon>
                      </v-btn>
                      <v-btn v-if="hour.id" icon @click="deleteDay(hour)">
                        <v-icon color="red">delete</v-icon>
                      </v-btn>
                    </span>
                  </div>
                </div>
                <div v-else style="padding: 7px 0;">
                  <span>
                    <v-icon color="gray">event_busy</v-icon>
                    <span style="margin-left: 10px;">
                      Sem expediente
                    </span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-flex>
      <div v-if="weekdays && weekdays.id">
        <br>
        <v-btn outline small @click="clearWeekdays()">
          <v-icon>close</v-icon>
          <span> Não definir horário de funcionamento</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text v-else style="text-align: center;">
      <v-btn outline @click="createDayHour()">
        <v-icon left>schedule</v-icon>
        <span> Definir horário de funcionamento</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'WiWeekdays',
    data: () => ({
      formattedWeekdays: []
    }),
    methods: {
      getDayName: function (dayId) {
        switch (dayId) {
          case 1:
            return 'Segunda-feira'
          case 2:
            return 'Terça-feira'
          case 3:
            return 'Quarta-feira'
          case 4:
            return 'Quinta-feira'
          case 5:
            return 'Sexta-feira'
          case 6:
            return 'Sábado'
          case 7:
            return 'Domingo'
        }
      },
      formatWeekdays: function (weekdays) {
        this.formattedWeekdays = [
          {
            day_of_week_id: 1,
            hours: []
          },
          {
            day_of_week_id: 2,
            hours: []
          },
          {
            day_of_week_id: 3,
            hours: []
          },
          {
            day_of_week_id: 4,
            hours: []
          },
          {
            day_of_week_id: 5,
            hours: []
          },
          {
            day_of_week_id: 6,
            hours: []
          },
          {
            day_of_week_id: 7,
            hours: []
          }
        ]
        weekdays.hours.forEach(day => {
          const dayIndex = this.formattedWeekdays.findIndex(formattedDay => formattedDay.day_of_week_id === day.day_of_week_id)
          const hour = {
            id: day.id,
            day_of_week_id: day.day_of_week_id,
            start_time: day.start_time,
            end_time: day.end_time
          }
          if (dayIndex !== -1) {
            this.formattedWeekdays[dayIndex].hours.push(hour)
          } else {
            this.formattedWeekdays.push({
              day_of_week_id: day.day_of_week_id,
              hours: [ hour ]
            })
          }
        })
      },
      createDayHour: function () {
        this.$WiEditDialog({
          wiConfig: 'weekday-hour',
          data: {
            ...this.data
          },
          redirectOnSubmit: false,
          onSubmit: () => {
            this.$emit('refresh')
          }
        })
      },
      addDayHours: function (day) {
        this.$WiEditDialog({
          wiConfig: 'weekday-hour',
          data: {
            weekday_id: this.weekdays.id,
            ...this.data,
            ...day
          },
          redirectOnSubmit: false,
          onSubmit: () => {
            this.$emit('refresh')
          }
        })
      },
      editDay: function (day) {
        this.$WiEditDialog({
          wiConfig: 'weekday-hour',
          data: day,
          redirectOnSubmit: false,
          onSubmit: () => {
            this.$emit('refresh')
          }
        })
      },
      deleteDay: function (day) {
        this.$WiApiDelete({
          uri: 'register/weekday/hour',
          id: day.id,
          callback: () => {
            this.$emit('refresh')
          }
        })
      },
      clearWeekdays: function () {
        this.$WiApiDelete({
          uri: 'register/weekday',
          id: this.weekdays.id,
          callback: () => {
            this.$emit('refresh')
          }
        })
      },
      dayHaveFreeHours: function (day) {
        return day.hours.filter(hour => (hour.start_time == '00:00:00' && hour.end_time == '23:59:00')).length > 0
      },
    },
    mounted: function () {
      this.formatWeekdays(this.weekdays)
    },
    props: {
      weekdays: {
        default: {}
      },
      data: {
        default: {}
      }
    }
  }
</script>