// Well
import { Well, WellOpened, WellClosed, WellCanceled } from './Well/Well'
import { WellLayout } from './Well/WellLayout'

// Common
import Event from '@/modules/sales/config/Common/Event'

// Configurations
import { WellStatus } from '@/modules/wells/config/Config/Status'
import { WellCategory } from '@/modules/wells/config/Config/Category'
import { LayoutVariable } from '@/modules/wells/config/Config/LayoutVariable'

// Registers
import Customer from '@/modules/sales/config/Register/Customer'
import Collaborator from '@/modules/sales/config/Register/Collaborator'

// Forms
import { Form } from '@/modules/forms/config/Form/Form'
import { FormField } from '@/modules/forms/config/Form/FormField'
import { FormFieldOption } from '@/modules/forms/config/Form/FormFieldOption'
import { FormReply } from '@/modules/forms/config/Form/FormReply'
import { BusinessLocation } from '@/modules/business/config/BusinessLocation'

export default {
  'well': new Well(),
  'well-open': new WellOpened(),
  'well-close': new WellClosed(),
  'well-cancel': new WellCanceled(),
  'well-layout': new WellLayout(),
  'well-location': new BusinessLocation(),
  'well-event': Event.sale,

  'config-status-well': new WellStatus(),
  'config-category-well': new WellCategory(),
  'config-layout-variable-well': new LayoutVariable(),

  'wells-register-customer': Customer,
  'wells-register-collaborator': Collaborator,

  'well-config-form': new Form(),
  'well-config-form-field': new FormField(),
  'well-config-form-reply': new FormReply(),
  'well-config-form-field-option': new FormFieldOption()
}
