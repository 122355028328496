<template>
  <div>
    <div>
      <div v-if="hasTasks">
        <v-card flat color="transparent">
          <v-card-title class="py-1 px-0">
            <v-icon>done</v-icon>
            <span style="padding-left: 10px;">
              <strong>Tarefas</strong>
            </span>
            <v-spacer></v-spacer>
            <v-btn outline small color="green" class="my-0 mx-0">
              <v-icon>add</v-icon>
              <span>Novo</span>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0" style="display: flex; flex-direction: column; gap: 15px;">
            <v-card v-for="task in tasks" :key="task.id">
              <v-list-tile class="talk-contact-tasks__list-tile">
                <v-list-tile-action style="min-width: 30px;">
                  <v-checkbox
                    color="primary"
                  ></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title style="display: flex; justify-content: left;">
                    <span class="mr-1">{{ task.date }} - </span>
                    <strong :style="{ color: task.type_info.color }">
                      {{ task.type_info.title }}
                    </strong>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title style="display: flex; justify-content: space-between;">
                    <strong> {{ task.description }}</strong>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action style="min-width: 25px">
                  <v-btn icon small>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-card>
          </v-card-text>
        </v-card>
      </div>
      <v-card-text v-else>
        <h3>Criar tarefa/agendamento</h3>
        <v-btn outline small color="green">
          nova tarefa/agendamento
        </v-btn>
      </v-card-text>
    </div>
  </div>       
</template>
<script>
  export default {
    name: 'TalkContactTasks',
    computed: {
      person () {
        const person = this.contact?.person?.person || this.contact?.person
        return person
      },
      isCustomer () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'customer') > -1
      },
      isProspect () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'prospect') > -1
      },
      hasTasks () {
        return this.talk?.assets?.tasks?.length > 0
      },
      tasks () {
        return this.talk?.assets?.tasks || []
      }
    },
    props: ['talk', 'contact']
  }
</script>
<style scoped>
  .talk-contact-tasks__list-tile {
    padding: 0 0px !important;
  }
  .talk-contact-tasks__list-tile >>> .v-list__tile {
    padding: 0 5px !important;
  }
</style>