<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-orders__enable-button elevation-2">
        <v-card-text style="text-align: center;">
          <h1>{{orders.length}}</h1>
          <strong>Ordem(s)</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-orders">
      <v-toolbar dense class="diagnostic-orders__toolbar">
        <v-toolbar-title>
          Ordem(s)
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="orders.length > 0">
        <h4>
          <span>{{orders.length}} </span>
          <span> Ordem(s) encontrada(s).</span>
        </h4>
        <br>
        <v-layout row wrap>
          <v-flex xs6 sm6 md3 lg3 xl3 style="padding-right: 10px;" v-for="(order, orderKey) in orders" :key="orderKey">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-orders__orders">
                <img src="/static/icons/monitor/workers_96.png" width="50px">
                <h4>ID: {{order.id}}</h4>
                <h4>Previsão: {{order.promisseDate | moment('DD/MM/YY HH:mm')}}</h4>
                <h4>Documento: {{order.associatedDocument}}</h4>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-orders__not-found">
        <h2>Nenhuma Ordem encontrada.☹️ </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DiagnosticOrders',
    data: () => ({
      dialog: false,
    }),
    props: {
      orders: {
        required: true,
        default: () => ([])
      }
    }
  }
</script>
<style scoped>
  .diagnostic-orders {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-orders__enable-button {
    cursor: pointer;
  }
  .diagnostic-orders__toolbar {
    z-index: 999;
  }

  .diagnostic-orders__orders {
    text-align: center;
  }
  .diagnostic-orders__not-found {
    margin: 50px 0;
  }
</style>
