export default {
  id: {
    id: 'dc24170b',
    create: 'd40a2a3e',
    update: '734a4cf7',
    delete: '7073218a'
  },
  title: {
    edit: {
      create: 'Nova senha de Usuário',
      update: 'Editar senha de Usuário'
    }
  },
  actions: {
    create: false,
    update: true,
    delete: false
  },
  icon: 'portrait',
  apiUrl: 'system/user',
  maxWidth: '580px',
  fields: [
    {
      value: 'password',
      text: 'Por favor, informe a nova senha.',
      type: 'password',
      required: true,
      list: false,
      edit: true,
      rules: [
        value => value?.length >= 8 || 'Mínimo de 8 caracteres.',
        value => value?.length <= 20 || 'Máximo de 20 caracteres.',
        v => !!v || 'Campo obrigatório'
      ]
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
