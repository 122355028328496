import { BusinessSimple } from '@/modules/business/config/BusinessSimple'
import {
  PERMISSION_GUARANTEE,
  PERMISSION_GUARANTEE_CREATE,
  PERMISSION_GUARANTEE_UPDATE,
  PERMISSION_GUARANTEE_DELETE
} from '@/default/constants/permissions'

export class Guarantee extends BusinessSimple {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_GUARANTEE,
      create: PERMISSION_GUARANTEE_CREATE,
      update: PERMISSION_GUARANTEE_UPDATE,
      delete: PERMISSION_GUARANTEE_DELETE
    }
    this.name = 'guarantee'
    this.title = {
      list: 'Termos de Garantia',
      edit: {
        insert: 'Novo Termo de Garantia',
        update: 'Editar Termo de Garantia'
      }
    }
    this.icon = 'privacy_tip'
    this.apiUrl = 'saless/guarantee'
    this.urlDetails.name = 'guarantee'
    this.fieldsEdit.dateStart.text = 'Início da garantia'
    this.fieldsEdit.dateEnd.text = 'Termino da garantia'
    this.fieldsEdit.subHeader1.text = 'Informe os dados básicos deste termo de garantia.'
    this.fieldsEdit.status.apiUrl = 'sales/config/status/guarantee'
    this.fieldsEdit.category.apiUrl = 'sales/config/category/guarantee'
  }
}

export class GuaranteeOpened extends Guarantee {
  constructor () {
    super()
    this.title.list = 'Garantias Em Análise'
    this.params.stage = 'open'
  }
}
export class GuaranteeClosed extends Guarantee {
  constructor () {
    super()
    this.title.list = 'Garantias Vigentes'
    this.params.stage = 'close'
  }
}
export class GuaranteeCanceled extends Guarantee {
  constructor () {
    super()
    this.title.list = 'Garantias Canceladas'
    this.params.stage = 'cancel'
  }
}



