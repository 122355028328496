export default {
  id: 'e90e45c8',
  actions: {
    create: false,
    update: true,
    delete: false
  },
  list: false,
  title: `Desligar Recorrencia`,
  icon: 'power_settings_new',
  apiUrl: 'finance/recurrence/disable',
  fields: [
    {
      text: 'Atenção! Ao desligar uma recorrência, as transações serão apagadas de acordo com a configuração a seguir:',
      type: 'subheader',
      edit: true,
      style: { height: '50px !important', background: 'red', color: 'white' }
    },
    {
      value: 'delete_option',
      text: 'Opções de remoção.',
      align: 'center',
      type: 'select',
      list: false,
      edit: true,
      required: true,
      options: [
        { value: 'not-delete', text: 'Não apagar transações' },
        { value: 'delete-unpaid-finances', text: 'Apagar transações pendentes' },
        { value: 'delete-next-unpaid-finances', text: 'Apagar transações pendentes futuras' },
        { value: 'delete-all-finances', text: 'Apagar todas as transações' }
      ],
      showField: form => (form.id),
      defaultOption: 'not-delete'
    },
    {
      text: `
        <ul>
          <li class="list-item">
            <strong>Não apagar transações - </strong>
            <span>Esta opção não apaga nenhuma transação. É útil para desligar a recorrência sem apagar os registros.</span>
          </li>
          <li class="list-item">
              <strong>Apagar transações pendentes - </strong>
              <span>Esta opção apaga todas as transações que ainda não foram concluídas ou pagas. É útil para limpar registros que não foram processados.</span>
          </li>
          <li class="list-item">
              <strong>Apagar transações pendentes futuras - </strong>
              <span>Esta opção remove todas as transações que estão programadas para o futuro e ainda não foram pagas. É útil para ajustar registros que ainda estão pendentes e serão gerados no futuro.</span>
          </li>
          <li class="list-item">
              <strong>Apagar todas as transações - </strong>
              <span>Esta opção apaga todas as transações, independentemente de seu status ou data. É útil para realizar uma limpeza completa no sistema.</span>
          </li>
        </ul>
      `,
      type: 'subheader',
      edit: true,
      style: { height: '288px !important', background: 'grey', color: 'white' }
    }
  ],
  notLoadOnMounted: false,
  maxWidth: '500px',
}
