import { BusinessItemProduct, BusinessItemService } from '@/modules/business/config/BusinessItem'
import { PERMISSION_CONTRACT_UPDATE } from '@/default/constants/permissions'

export class ContractProduct extends BusinessItemProduct {
  constructor () {
    super()
    this.id = PERMISSION_CONTRACT_UPDATE
    this.title = {
      edit: {
        insert: 'Incluir Produto ao Contrato',
        update: 'Editar Produto ao Contrato'
      }
    }
  }
}

export class ContractService extends BusinessItemService {
  constructor () {
    super()
    this.id = PERMISSION_CONTRACT_UPDATE
    this.title = {
      edit: {
        insert: 'Incluir Serviço ao Contrato',
        update: 'Editar Serviço ao Contrato'
      }
    }
  }
}
