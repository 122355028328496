export default {
  id: {
    id: '5dc282ab',
    create: '2c491b4c',
    update: '1a825af1'
  },
  title: 'Chamados Responsável',
  apiUrl: 'service-desk/ticket',
  actions: {
    create: true,
    delete: false,
    update: false
  },
  urlDetails: 'chamado',
  paramsFixed: {
    where: 'pessoa_responsavel_id,' + localStorage.getItem('colaborador_id')
  },
  returnUrl: {name: 'list', params: {page: 'chamados'}},
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'assunto_chamado', text: 'Assunto', counter: 100, placeholder: 'Informe o assunto em no máximo 100 caracteres. Ex: Verificar Dispositivos', type: 'text', required: true, list: true, edit: true, showField: (form, configuracao) => { return configuracao.Configuracao[2].val_text_config.informar_assunto }},
    {value: 'nome_categoria', text: 'Tipo de Ocorrência', type: 'text', list: true, edit: false},
    {value: 'nome_status', text: 'Status', type: 'text', list: true, edit: false},
    {value: 'nome_responsavel', text: 'Responsável', type: 'text', list: true, edit: false},
    {value: 'created_at', text: 'Criação', type: 'date', list: true, edit: false},
    {text: 'Informe se este chamado será vinculado a um cliente ou a um fornecedor.', type: 'subheader', edit: true},
    {value: 'cli_ou_for', text: 'Vincular este chamado a um Cliente ou a um Fornecedor*', placeholder: 'Informe se este chamado será vinculado a um cliente ou a um fornecedor', type: 'select', required: true, size: ['xs12', 'sm12', 'md12', 'lg12'], options: [{value: 'cli', text: 'Vincular a um Cliente'}, {value: 'for', text: 'Vincular a um Fornecedor'}], list: false, edit: true, showField: (form) => { return !form.cli_ou_for }},
    {value: 'cli_ou_for', text: 'Cliente ou Fornecedor*', type: 'select', required: true, size: ['xs12', 'sm4', 'md4', 'lg3'], options: [{value: 'cli', text: 'Vincular a um Cliente'}, {value: 'for', text: 'Vincular a um Fornecedor'}], list: false, edit: true, showField: (form) => { return form.cli_ou_for }},
    {value: 'pessoa_dados_id', text: 'Cliente Vinculado*', autocomplete: true, required: true, size: ['xs12', 'sm8', 'md8', 'lg9'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'pessoa/cliente', apiParams: {fields: 'id,nome_pessoa'}, show: 'nome_pessoa', showField: (form) => { if (form.cli_ou_for === 'cli') { return true } else { return false } }},
    {value: 'pessoa_dados_id', text: 'Fornecedor Vinculado*', autocomplete: true, required: true, size: ['xs12', 'sm8', 'md8', 'lg9'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'pessoa/fornecedor', apiParams: {fields: 'id,nome_pessoa'}, show: 'nome_pessoa', showField: (form) => { if (form.cli_ou_for === 'for') { return true } else { return false } }},
    {text: 'Descreva o que precisa ser feito ou o que precisa ser resolvido.', type: 'subheader', edit: true},
    {value: 'desc_chamado', text: 'Descrição/Solicitação*', placeholder: 'Por favor, descreva este chamado de forma detalhada.', type: 'textarea', required: true, list: false, edit: {insert: true, update: false}},
    {text: 'Selecione a equipe, Colaborador (Opcional), Impacto, Tipo de Ocorrência e o canal por onde o chamado foi realizado.', type: 'subheader', edit: true},
    {value: 'chamado_categoria_id', text: 'Tipo de Ocorrência*', placeholder: 'Você pode selecionar um Tipo de Ocorrência ou sub-ocorrência', size: ['xs12', 'sm6', 'md4', 'lg4'], required: true, fields: false, type: 'list-ticket-category', autocomplete: true, list: false, edit: true, apiUrl: 'chamado/ocorrencia', apiParams: {fields: 'id,nome_categoria_grupo_previsao', order: 'nome_categoria_grupo_previsao'}, show: 'nome_categoria_grupo_previsao'},
    {value: 'definir_previsao', text: 'Definir Previsão do Chamado*', type: 'select', required: true, size: ['xs12', 'sm4', 'md3', 'lg3'], options: [{value: 'serv_prev', text: 'Usar previsão da Ocorrência'}, {value: 'def_prev', text: 'Definir Previsão Manualmente'}], list: false, edit: true},
    {value: 'previsao_chamado', text: 'Previsão de duração', type: 'text', mask: '##:##:##', size: ['xs12', 'sm3', 'md2', 'lg2'], list: false, edit: true, disabled: (form) => { return form.definir_previsao === 'serv_prev' || !form.definir_previsao }},
    {value: 'equipe_dados_id', text: 'Equipe Responsável*', placeholder: 'Todos os membros desta equipe serão notificados sobre este chamado', autocomplete: true, required: true, size: ['xs12', 'sm6', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'equipe/dados', apiParams: {fields: 'id,nome_equipe', where: 'ativa_equipe,1'}, show: 'nome_equipe'},
    {value: 'pessoa_responsavel_id', text: 'Colaborador Responsável', placeholder: '(Opcional) Quem irá ser responsabilizado', autocomplete: true, required: false, size: ['xs12', 'sm6', 'md4', 'lg4'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'pessoa/colaborador', apiParams: {fields: 'id,nome_pessoa'}, show: 'nome_pessoa'},
    {value: 'chamado_impacto_id', text: 'Impacto*', placeholder: 'Baixo, Médio, Alto ou Crítico', autocomplete: true, required: true, fields: false, size: ['xs12', 'sm6', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/impacto', show: 'nome_impacto'},
    {value: 'chamado_urgencia_id', text: 'Urgência*', placeholder: 'Baixa, Média, Alta ou Urgente', autocomplete: true, required: false, fields: false, size: ['xs12', 'sm6', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/urgencia', show: 'nome_urgencia'},
    {value: 'chamado_canal_id', text: 'Canal', placeholder: 'Por onde este chamado foi iniciado', size: ['xs12', 'sm6', 'md2', 'lg2'], type: 'db-autocomplete', autocomplete: true, list: false, edit: true, apiUrl: 'chamado/canal', show: 'nome_canal'},
    {value: 'grupos', text: 'Grupos de Exibição', multiple: true, autocomplete: true, required: true, type: 'db-autocomplete', list: false, edit: true, apiUrl: 'grupo/dados', apiParams: (form, configuracao) => { return {where: 'grupo_tipo_id,' + configuracao.Configuracao[2].val_text_config.grupo_tipo_id, fields: 'grupo_dados.id,nome_grupo', order: 'nome_grupo,asc'} }, show: 'nome_grupo', showField: (form, configuracao) => { return configuracao.Configuracao[2].val_text_config.exibir_grupo }}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
