export default [
  {
    id: 'c4f8c247',
    icon: 'assignment_turned_in',
    title: 'Tarefas/Agendamentos',
    children: [
      {id: 'c4f8c247', icon: 'select_all', title: 'Tarefas/Agendamentos', route: {name: 'projects-list', params: {page: 'task'}}},
      {id: 'c4f8c247', icon: 'done_outline', title: 'Histórico de Tarefas', route: {name: 'juridical-list', params: {page: 'process-close'}}},
      {id: 'c4f8c247', icon: 'close', title: 'Tarefas Canceladas', route: {name: 'juridical-list', params: {page: 'process-cancel'}}}
    ]
  },
  {
    id: '4d8dd50b',
    icon: 'ballot',
    title: 'Gestão de Projetos',
    children: [
      {id: '4d8dd50b', icon: 'view_week', title: 'Etapas do Projetos', route: {name: 'process-of-juridical', params: {business: 'budget'}}},
      {id: '4d8dd50b', icon: 'select_all', title: 'Projetos Em Aberto', route: {name: 'juridical-list', params: {page: 'budget-open'}}},
      {id: '4d8dd50b', icon: 'done_outline', title: 'Projetos Concluídos', route: {name: 'juridical-list', params: {page: 'budget-close'}}},
      {id: '4d8dd50b', icon: 'close', title: 'Projetos Cancelados', route: {name: 'juridical-list', params: {page: 'budget-cancel'}}}
    ]
  },
  {
    id: '22841c3a',
    icon: 'bar_chart',
    title: 'Relatórios',
    children: [
      {id: '07a49058', icon: 'people_outline', title: 'Projetos por cliente', route: {name: 'processes-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
      {id: 'fe457dce', icon: 'assignment_ind', title: 'Projetos por Colaborador', route: {name: 'processes-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}}
    ]
  },
  {
    id: 'a84515b9',
    icon: 'description',
    title: 'Cadastro',
    children: [
      {id: 'a84515b9', icon: 'room_service', title: 'Cadastro de Serviços', route: {name: 'juridical-list', params: {page: 'juridical-register-service'}}},
      {id: 'a84515b9', icon: 'assignment_ind', title: 'Colaboradores', route: {name: 'juridical-list', params: {page: 'juridical-register-collaborator'}}, selected: false, permission: ['admin']},
      {id: 'a84515b9', icon: 'people_outline', title: 'Clientes', route: {name: 'juridical-list', params: {page: 'juridical-register-customer'}}, selected: false}
    ]
  },
  {
    id: '441db883',
    icon: 'settings',
    title: 'Configurações',
    children: [
      {id: '441db883', icon: 'code', title: 'Status/Etapas do Processo', route: {name: 'juridical-list', params: {page: 'config-status-process'}}},
      {id: '441db883', icon: 'category', title: 'Categorias do Processo', route: {name: 'juridical-list', params: {page: 'config-category-process'}}},
      {id: '441db883', icon: 'code', title: 'Status/Etapas do Orçamento', route: {name: 'juridical-list', params: {page: 'config-status-budget'}}},
      {id: '441db883', icon: 'category', title: 'Categorias do Orçamento', route: {name: 'juridical-list', params: {page: 'config-category-budget'}}}
    ]
  }
]
