import { BusinessFinance } from '@/modules/shared/config/Business/BusinessFinance'

export class FinanceReceive extends BusinessFinance {
  constructor() {
    super()
    this.id = 'b41dcfec'
    this.actions = {
      update: true
    }
    this.title = 'Receber'
  }
}