<template>
  <v-card>
    <slot
      v-if="$slots.toolbar"
      name="toolbar"
      :data="data"
      :config="config"
      :refresh="getData"
      :loading="loading"
      :nextPage="nextPage"
      :hasNextPage="hasNextPage"
    ></slot>
    <WiViewListToolbar
      v-if="toolbar"
      :data="data"
      :config="config"
      @onRefresh="onRefresh"
      @onClose="onClose"
      :nextPage="nextPage"
      :hasNextPage="hasNextPage"
      :loadingPaginate="loadingPaginate"
      v-bind="{
        ...toolbar
      }"
    ></WiViewListToolbar>
    <v-card-text class="wi-view__content">
      <WiLoading :loading="loading" :message="loadingConfig.message">
        <slot
          v-if="data"
          :data="data"
          :config="config"
          :refresh="getData"
          :loading="loading"
          :nextPage="nextPage"
          :hasNextPage="hasNextPage"
          :loadingPaginate="loadingPaginate"
        ></slot>
        <WiViewListError
          v-if="error"
          @on-refresh="onRefresh"
        ></WiViewListError>
      </WiLoading>
    </v-card-text>
  </v-card>
</template>
<script>
  import WiLoading from '@/default/component/WiLoading/WiLoading'
  import WiViewListToolbar from './components/WiViewListToolbar'
  import WiViewListError from './components/WiViewListError'
  export default {
    name: 'WiView',
    data () {
      return {
        page: null,
        data: [],
        config: {},
        error: false,
        loading: false,
        subscriber: null,
        loadingPaginate: false
      }
    },
    computed: {
      formatedServiceParams () {
        return {
          ...this.serviceParams,
          page: this.page
        }
      },
      hasNextPage () {
        return this.config && this.config.last_page > this.config.current_page
      }
    },
    methods: {
      getData: async function () {
        this.page = 1
        this.loading = true
        this.onListing()
        const service = new this.Service()
        const { status, response } = await service.list(this.serviceParams)
        if (status) {
          this.makeSuccess(response)
        } else {
          this.makeError(response)
        }
      },
      nextPage: function () {
        if (this.config && this.config.current_page) {
          this.page = this.config.current_page + 1
          this.paginate()
        }
      },
      paginate: async function () {
        this.loadingPaginate = true
        this.onListing()
        const service = new this.Service()
        const { status, response } = await service.list(this.formatedServiceParams)
        if (status) {
          this.makeSuccessPaginate(response)
        } else {
          this.makeError(response)
        }
      },
      onListing: function () {
        this.$emit('onListing')
      },
      onListed: function () {
        this.$emit('onListed', {
          data: this.data,
          config: this.config
        })
      },
      onError: function (error) {
        this.$emit('onError', error)
      },
      makeSuccess: function (response) {
        this.data = response.data
        delete response.data
        this.config = response
        this.loading = false
        this.loadingPaginate = false
        this.onListed()
      },
      makeSuccessPaginate: function (response) {
        response.data.map(item => {
          this.data.push(item)
        })
        delete response.data
        this.config = response
        this.loading = false
        this.loadingPaginate = false
        this.onListed()
      },
      makeError: function (error) {
        this.error = true
        this.loading = false
        this.loadingPaginate = false
        this.onError(error)
        this.$WiMakeError({
          id: 101,
          error: error,
          title: 'Erro ao buscar informações.'
        })
      },
      subscribe: function () {
        if (this.Socket && !this.subscriber) {
          this.subscriber = new this.Socket(this.socketParams)
          this.subscriber.onEventSubscribe(this.onCreated, this.onUpdated)
        }
      },
      onCreated: function (item) {
        this.data.unshift(item)
        this.$forceUpdate()
      },
      onUpdated: function (item) {
        console.log('onUpdated', item)
        const index = this.data.findIndex(dataItem => (dataItem.id == item.id))
        if (index > -1) {
          this.data.splice(index, 1, item)
        } else {
          this.onCreated(item)
        }
        this.$forceUpdate()
      },
      unSubscribe: function () {
        if (this.Socket && this.subscriber) {
          this.subscriber.onEventUnsubscribe()
          this.subscriber = null
        }
      },
      onRefresh: function () {
        this.getData()
        this.$emit('onRefresh')
      },
      onClose: function () {
        this.$emit('onClose')
      }
    },
    mounted: function () {
      this.getData()
      this.subscribe()
    },
    destroyed: function () {
      this.unSubscribe()
      console.log('destroyed')
    },
    components: {
      WiLoading,
      WiViewListError,
      WiViewListToolbar
    },
    props: {
      Service: {
        required: true
      },
      serviceParams: {
        default: () => ({})
      },
      Socket: {
        required: true
      },
      socketParams: {
        default: () => ({})
      },
      toolbar: {
        default: () => ({
          icon: 'list',
          title: '',
          refresh: true,
          close: true
        })
      },
      loadingConfig: {
        default: () => ({
          type: 'circle',
          message: null
        })
      }
    }
  }
</script>

<style scoped>
  .wi-view__content {
    padding: 0 !important;
  }
  .wi-view-list__error-message { 
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }
  .wi-view-list__error-message h1 { 
    padding-bottom: 20px;
  }
</style>
