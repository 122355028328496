<template>
  <div>
    <WiGoogleMaps
      :markers="markers"
      @markerClick="markerClick"
    ></WiGoogleMaps>
    <HostDetailDialog
      v-model="selectedHostDialog"
      :host="selectedHost"
      @refresh="refresh"
    ></HostDetailDialog>
  </div>
</template>

<script>
  import WiGoogleMaps from '@/default/component/WiGoogleMaps'
  import HostDetailDialog from '@/modules/network/pages/Host/HostDetail/HostDetailDialog'
  export default {
    name: 'DeviceHostsDialogMap',
    data: () => ({
      selectedHost: null,
      selectedHostDialog: false
    }),
    computed: {
      markers () {
        return this.hosts.map(host => ({
          ...host,
          pinColor: host.online ? ( host.signal_is_ok ? '#008000': '#FFA500' ) : '#FF0000',
          latitude: host?.contracts[0]?.address?.latitude,
          longitude: host?.contracts[0]?.address?.longitude,
          title: host?.contracts[0]?.person?.name,
          label: host.signal ? `Sinal: ${host.signal}` : 'Offline',
          iconUrl: host.online ? ( host.signal_is_ok ? 'https://master.wnsystems.app:2087/assets/img/icons/maps/grupo-100.png': 'https://master.wnsystems.app:2087/assets/img/icons/maps/grupo-110.png' ) : 'https://master.wnsystems.app:2087/assets/img/icons/maps/grupo-020.png',
          circle: !host.online || !host.signal_is_ok
        }))
      }
    },
    methods: {
      markerClick: function ({ data }) {
        this.selectedHost = data
        this.selectedHostDialog = true
      },
      refresh: function () {
        this.$emit('refresh')
      }
    },
    props: {
      hosts: {
        default: () => ([])
      }
    },
    components: {
      WiGoogleMaps,
      HostDetailDialog
    }
  }
</script>