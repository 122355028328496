import state from '@/default/store/modules/style/state'

export default {
  namespaced: true,
  state,
  mutations: {
    SET_DARK (state, data) {
      state.dark = data
    }
  }
}
