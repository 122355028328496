export default [
  {
    id: 'e2a51798',
    icon: 'gavel',
    title: 'Gestão de Processos',
    children: [
      {id: 'e2a51798', icon: 'view_week', title: 'Etapas do Processo', route: {name: 'process-of-juridical', params: {business: 'process'}}},
      {id: 'e2a51798', icon: 'select_all', title: 'Processos Em Aberto', route: {name: 'juridical-list', params: {page: 'process-open'}}},
      {id: 'e2a51798', icon: 'done_outline', title: 'Histórico de Processos', route: {name: 'juridical-list', params: {page: 'process-close'}}},
      {id: 'e2a51798', icon: 'close', title: 'Processos Canceladas', route: {name: 'juridical-list', params: {page: 'process-cancel'}}}
    ]
  },
  {
    id: '053bec35',
    icon: 'shop',
    title: 'Gestão de Orçamentos',
    children: [
      {id: '053bec35', icon: 'view_week', title: 'Etapas de Orçamento', route: {name: 'process-of-juridical', params: {business: 'budget'}}},
      {id: '053bec35', icon: 'select_all', title: 'Orçamentos Em Aberto', route: {name: 'juridical-list', params: {page: 'budget-open'}}},
      {id: '053bec35', icon: 'done_outline', title: 'Orçamentos Aprovados', route: {name: 'juridical-list', params: {page: 'budget-close'}}},
      {id: '053bec35', icon: 'close', title: 'Orçamentos Rejeitados', route: {name: 'juridical-list', params: {page: 'budget-cancel'}}}
    ]
  },
  // {id: 'vnds', icon: 'local_activity', title: 'Gestão de Contratos', route: {name: 'disponivel-em-breve'}},
  {
    id: 'eb8d1de4',
    icon: 'bar_chart',
    title: 'Relatórios',
    children: [
      {id: '7da883c0', icon: 'local_atm', title: 'Honorários por Colaborador', route: {name: 'honorary-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
      {id: '7da883c0', icon: 'local_atm', title: 'Pagamentos por Cliente', route: {name: 'payments-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
      {id: 'e19b2845', icon: 'people_outline', title: 'Processos por cliente', route: {name: 'processes-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
      // {id: '39da381b', icon: 'people_outline', title: 'Orçamentos por cliente', route: {name: 'budgets-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
      {id: '2378cb8b', icon: 'assignment_ind', title: 'Processos por Colaborador', route: {name: 'processes-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}}
      // {id: '7da223c0', icon: 'assignment_ind', title: 'Orçamentos por Colaborador', route: {name: 'budgets-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}}
    ]
  },
  {
    id: '0A335551',
    icon: 'description',
    title: 'Cadastro',
    children: [
      {id: '0A335551', icon: 'room_service', title: 'Cadastro de Serviços', route: {name: 'juridical-list', params: {page: 'juridical-register-service'}}},
      {id: '0A335551', icon: 'assignment_ind', title: 'Colaboradores', route: {name: 'juridical-list', params: {page: 'juridical-register-collaborator'}}, selected: false, permission: ['admin']},
      {id: '0A335551', icon: 'people_outline', title: 'Clientes', route: {name: 'juridical-list', params: {page: 'juridical-register-customer'}}, selected: false}
    ]
  },
  {
    id: 'f8d7e42d',
    icon: 'settings',
    title: 'Configurações',
    children: [
      {id: 'f8d7e42d', icon: 'code', title: 'Status/Etapas do Processo', route: {name: 'juridical-list', params: {page: 'config-status-process'}}},
      {id: 'f8d7e42d', icon: 'category', title: 'Categorias do Processo', route: {name: 'juridical-list', params: {page: 'config-category-process'}}},
      {id: 'f8d7e42d', icon: 'code', title: 'Status/Etapas do Orçamento', route: {name: 'juridical-list', params: {page: 'config-status-budget'}}},
      {id: 'f8d7e42d', icon: 'category', title: 'Categorias do Orçamento', route: {name: 'juridical-list', params: {page: 'config-category-budget'}}}
    ]
  }
]
