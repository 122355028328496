<template>
  <div>
    <div v-if="selectedOperator">
      <v-progress-linear
        :indeterminate="true"
        v-if="loadings && loadings.paginate"
        style="margin: 1px 0 0 0 !important;"
      ></v-progress-linear>
      <TalksList type="by-operator"></TalksList>
    </div>
    <div v-else-if="loadings.operators">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <div>
          <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.queue'"></h2>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="operators.length > 0" class="talks-list" :style="{height: pageHeight}" style="padding: 10px 0 !important; overflow-y: scroll;">
        <v-list dense two-line style="padding: 0;">
          <template v-for="(operator, operatorKey) in operators">
            <v-divider v-if="operatorKey" :key="operatorKey" inset></v-divider>
            <v-list-tile v-ripple avatar :key="operatorKey + '-new'" @click="operatorAction(operator)">
              <v-list-tile-avatar style="cursor: pointer;">
                <v-avatar>
                  <v-img v-if="operator && operator.file"
                    :src="operator.file.url"
                    lazy-src="static/icons/not-found.png"
                  ></v-img>
                  <v-img v-else src="static/icons/user_icon_1.png"/>
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content style="cursor: pointer;">
                <v-list-tile-title>
                  <strong>
                    {{ operator.name | stringlength(25) }}
                  </strong>
                </v-list-tile-title>
                <v-list-tile-title>
                  <div style="display: flex; gap: 1px; justify-content: start; align-items: center;">
                    <span style="color: grey;">Concluídos: </span>
                    <strong style="color: green; padding: 0 3px; font-size: 15px;">
                      {{ operator.talks_closed }}
                    </strong>
                    <v-icon color="green" small>done</v-icon>
                  </div>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="operator.talks_waiting > 0" style="text-align: center; min-width: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <strong style="color: orange; font-size: 18px;">
                  {{ operator.talks_waiting }}
                </strong>
                <v-icon style="font-size: 21px;" color="orange">notification_important</v-icon>
              </v-list-tile-action>
              <v-list-tile-action style="text-align: center; min-width: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <strong style="font-size: 18px;">
                  {{ operator.talks_opened }}
                </strong>
                <v-icon style="font-size: 21px;">message</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list>
      </div>
      <div v-else class="talks-list__not-message">
        <div class="talks-list__not-message-content">
          <img
            src="static/background/whatsapp-not-found.png?url=temp1"
            style="max-width: 150px;" alt=""
          />
          <div
            class="talks-list__not-message-text"
            v-t="'modules.talks.pages.talkView.talks.messages.notFound.groups'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import TalksList from '@/modules/talk/pages/TalkView/Talks/TalksList'
  export default {
    name: 'TalksOperators',
    data: () => ({
      operatorsLoading: false,
      operatorsLoadingPaginate: false,
      pageHeight: innerHeight,
      talkDialog: false,
      currentTalkId: null
    }),
    computed: {
      ...mapState('Talks', ['selectedOperator', 'loadings', 'operators']),
    },
    methods: {
      ...mapMutations('Talks', ['SELECTED_OPERATOR']),
      ...mapActions('Talks', ['paginateTalks', 'getOperators']),
      setInnerHeight: function (heigth) {
        this.pageHeight = ((heigth || innerHeight) - 145) + 'px'
      },
      updateView: function () {
        setInterval(() => {
          this.$forceUpdate()
        }, 78000)
      },
      operatorAction: function (operator) {
        this.SELECTED_OPERATOR(operator)
        this.paginateTalks()
      }
    },
    mounted: function () {
      this.getOperators()
      window.addEventListener('resize', () => {
        this.setInnerHeight(innerHeight)
      }, true)
      this.setInnerHeight(innerHeight)
      this.updateView()
    },
    components: {
      TalksList
    }
  }
</script>

<style>
  .talks-unseen-messages-count {
    padding: 0 5px;
    background: #009600;
    color: white;
    border-radius: 20px;
    margin-right: 5px;
  }
  .talks-list .v-list__tile {
    padding: 0 5px 0 5px !important;
  }
  .talks-list .v-list__tile__avatar {
    min-width: 50px !important;
  }
  .talks-list__not-message {
    align-items: center;
  }
  .talks-list__not-message-content {
    padding: 50px 20px;
    text-align: -webkit-center;
    font-size: 20px;
  }
  .talks-list__not-message img {
    width: 100%;
  }
  .talks-list__not-message-text {
    padding-bottom: 20px;
  }
  .talks__unseen-messages--unread {
    color: #009600 !important;
  }
</style>