import router from '@/modules/finance/router'
import config from '@/modules/finance/config'
import menu from '@/modules/finance/menu'

export default {
  id: 'bc2dcdd9-8571-4515-9917-8c1f871ca822',
  name: 'finance',
  title: 'Financeiro',
  icon: 'wises-technology-module-finance.png',
  iconSystem: 'wises-technology-system-finance.png',
  iconProfile: 'profile_service_desk.png?url=123',
  color: '#1e843b',
  description: 'Módulo de Gestão Financeira',
  // routeDefault: { name: 'dashboard-finance' },
  permission: ['admin', 'write', 'read'],
  menu,
  router,
  config
}
