import { Model } from '@/default/model/Model'
import {
  PERMISSION_TALKS_CAMPAIGN_EXPORT,
  PERMISSION_TALKS_CAMPAIGN_EXPORT_XLSX
} from '@/default/constants/permissions'

export class TalkCampaign extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'cdf8059b',
      create: 'ffb26038',
      update: 'c59c7cd2',
      delete: 'ebbd93cf'
    }
    this.name = 'talk-campaign'
    this.actions = {
      list: true,
      create: true,
      update: true,
      delete: false
    }
    this.title = {
      list: 'Campanhas',
      edit: {
        insert: 'Criar nova campanha',
        update: 'Editar campanha'
      }
    }
    this.apiUrl = 'talks/campaign'
    this.icon = 'campaign'
    this.params = {
      limit: 10,
      with: 'channels'
    }
    this.search = {
      placeholder: 'Pesquise pelo nome da campanha',
      fields: ['name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '1000px'
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkCampaigns')
    this.exports = {
      permission: PERMISSION_TALKS_CAMPAIGN_EXPORT,
      title: 'Exportar Campanhas',
      origins: {
        excel: {
          title: 'Exportar para o Excel',
          permission: PERMISSION_TALKS_CAMPAIGN_EXPORT_XLSX,
          description: 'Exportar campanhas para o Excel.',
          image: '/static/icons/excel_icon.png',
          wiConfig: 'talk-campaign-export'
        }
      }
    }

    this.listFields = {
      id: this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      name: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      status: this.fieldList({
        value: 'status_id',
        text: 'Status',
        align: 'center'
      }),
      statistics: this.fieldList({
        value: 'statistics',
        text: 'Estatísticas',
        align: 'center',
        sortable: false
      }),
      action: this.fieldList({
        value: 'action',
        text: 'Ações',
        sortable: false,
        align: 'center'
      })
    }
    this.editFields = {
      header1: this.fieldSubHeader({
        text: 'Informe o nome e texto da mensagem.'
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        placeholder: 'Ex: Campanha de Natal 20xx',
        required: true,
        size: ['xs12', 'sm12', 'md7', 'lg7'],
      }),
      type_id: this.fieldSelect({
        value: 'type_id',
        text: 'Tipo',
        options: [
          { value: 1, text: 'Texto simples' },
          { value: 2, text: 'Chatbot', disabled: true },
          { value: 3, text: 'Imagem' },
          { value: 4, text: 'Áudio' },
        ],
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        defaultOption: 1
      }),
      // talk_option: this.fieldSelect({
      //   value: 'talk_option',
      //   text: 'Atendimento',
      //   options: [
      //     { value: 1, text: 'Encerrar atendimento' }
      //     // { value: 2, text: 'Adicionar atendimento na fila' }
      //   ],
      //   required: true,
      //   size: ['xs12', 'sm12', 'md2', 'lg2'],
      // }),
      is_active: this.fieldSwitch({
        value: 'is_active',
        text: 'Ativo?',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        disabled: (form) => { return !form.id || ['not-started', 'finished', 'paused', 'error', 'stoped'].indexOf(form?.status?.code) == -1 }
      }),
      // start_at: this.fieldDateTime({
      //   value: 'start_at',
      //   text: 'Iniciar em',
      //   required: true,
      //   size: ['xs12', 'sm12', 'md3', 'lg3'],
      //   showField: false
      // }),
      header5: this.fieldSubHeader({
        text: 'Defina o tamanho e tempo entre lotes de mensagens.',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      header7: this.fieldSubHeader({
        text: 'Defina o tempo entre mensagens.',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      size_batch: this.fieldSelect({
        value: 'size_batch',
        text: 'Tamanho do lote',
        options: [
          { value: 5, text: '5 Mensagens' },
          { value: 10, text: '10 Mensagens' },
          { value: 20, text: '20 Mensagens' },
          { value: 35, text: '35 Mensagens' },
          { value: 50, text: '50 Mensagens' },
          { value: 70, text: '70 Mensagens' },
          { value: 100, text: '100 Mensagens' },
        ],
        defaultOption: 10,
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
      }),
      wait_batche: this.fieldSelect({
        value: 'wait_batche',
        text: 'Tempo entre lotes',
        options: [
          { value: 180, text: '3 minutos' },
          { value: 300, text: '5 minutos' },
          { value: 600, text: '10 minutos' },
          { value: 900, text: '15 minutos' },
          { value: 1200, text: '20 minutos' },
          { value: 1800, text: '30 minutos' },
          { value: 3600, text: '1 hora' },
        ],
        defaultOption: 300,
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
      }),
      wait_min: this.fieldSelect({
        value: 'wait_min',
        text: 'Tempo mínimo entre mensagens',
        options: [
          { value: 30, text: '30 segundos' },
          { value: 68, text: '1 minuto' },
          { value: 120, text: '2 minutos' },
          { value: 180, text: '3 minutos' },
          { value: 300, text: '5 minutos' },
          { value: 600, text: '10 minutos' },
          { value: 900, text: '15 minutos' },
          { value: 1200, text: '20 minutos' },
          { value: 1800, text: '30 minutos' },
          { value: 3600, text: '1 hora' },
        ],
        defaultOption: 68,
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
      }),
      wait_max: this.fieldSelect({
        value: 'wait_max',
        text: 'Tempo máximo entre mensagens',
        options: [
          { value: 70, text: '1 minuto' },
          { value: 80, text: '1 minuto e meio' },
          { value: 120, text: '2 minutos' },
          { value: 180, text: '3 minutos' },
          { value: 300, text: '5 minutos' },
          { value: 600, text: '10 minutos' },
          { value: 900, text: '15 minutos' },
          { value: 1200, text: '20 minutos' },
          { value: 1800, text: '30 minutos' },
          { value: 3600, text: '1 hora' },
        ],
        defaultOption: 120,
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
      }),
      header2: this.fieldSubHeader({
        text: 'Selecione os canais de atendimento que serão utilizados.',
        showField: form => !form.id
      }),
      talk_channels: this.fieldSelectApi({
        value: 'talk_channels',
        text: 'Canais de atendimento',
        required: true,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        api: {
          url: 'talk/channel',
          params: {
            fields: 'id,uuid,name,type_id,status_id',
          }
        },
        show: 'name',
        multiple: true,
        color: 'status_color',
        icon: 'status_icon',
        showField: form => !form.id
      }),
      header3: this.fieldSubHeader({
        text: 'Selecione as tags que serão utilizadas e as que não serão utilizadas.'
      }),
      tags_included: this.fieldSelectApi({
        value: 'tags_included',
        text: 'Tags inclusas*',
        // required: form => !form.id,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        api: {
          url: 'register/tag',
          params: {
            fields: 'id,name,color,icon',
          }
        },
        show: 'name',
        color: 'color',
        icon: 'icon',
        multiple: true,
        disabled: form => !!form.id
      }),
      tags_not_included: this.fieldSelectApi({
        value: 'tags_not_included',
        text: 'Tags não inclusas',
        required: false,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        api: {
          url: 'register/tag',
          params: {
            fields: 'id,name,color,icon',
          }
        },
        show: 'name',
        color: 'color',
        icon: 'icon',
        multiple: true,
        disabled: form => !!form.id
      }),
      header4: this.fieldSubHeader({
        text: 'Informe 1 ou mais mensagens para serem enviadas.',
        showField: (model) => {
          return model.type_id === 1 || model.type_id === 3
        }
      }),
      file_id: this.fieldWiFileDialog({
        value: 'file_id',
        text: form => form.type_id === 3 ? 'Imagem' : 'Áudio',
        placeholder: 'Selecione a planilha',
        required: true,
        data: {
          is_public: false
        },
        filter: {
          is_public: false
        },
        showField: (model) => {
          return model.type_id === 3 || model.type_id === 4
        }
      }),
      headerVariables: this.fieldSubHeader({
        text: 'Variáveis: {{name}}, {{phone}}, {{email}}, {{firstName}}, {{fullName}}',
        showField: (model) => {
          return model.type_id === 1 || model.type_id === 3
        }
      }),
      message_1: this.fieldTextArea({
        value: 'message_1',
        text: 'Mensagem 1',
        placeholder: 'Modelo de mensagem 1',
        required: form => form.type_id === 1,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        showField: (model) => {
          return model.type_id === 1 || model.type_id === 3
        }
      }),
      message_2: this.fieldTextArea({
        value: 'message_2',
        text: 'Mensagem 2',
        placeholder: 'Modelo de mensagem 2',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        showField: (model) => {
          return model.type_id === 1 || model.type_id === 3
        }
      }),
      message_3: this.fieldTextArea({
        value: 'message_3',
        text: 'Mensagem 3',
        placeholder: 'Modelo de mensagem 3',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        showField: (model) => {
          return model.type_id === 1 || model.type_id === 3
        }
      }),
      message_4: this.fieldTextArea({
        value: 'message_4',
        text: 'Mensagem 4',
        placeholder: 'Modelo de mensagem 4',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        showField: (model) => {
          return model.type_id === 1 || model.type_id === 3
        }
      }),
      headerChatbot: this.fieldSubHeader({
        text: 'Quando o contato responder, atender com um chatbot?',
        size: (form) => form.id ? ['xs12', 'sm12', 'md12', 'lg12'] : ['xs12', 'sm12', 'md9', 'lg9'],
      }),
      reply_as_chatbot: this.fieldSwitch({
        value: 'reply_as_chatbot',
        text: 'Responder com um chatbot',
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: (form) => { return !form.id }
      }),
      chatBotId: this.fieldWiListDialog({
        value: 'talk_menu_id',
        text: 'Chatbot',
        required: false,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        wiConfig: 'talk-menu',
        show: 'menu.name',
        icon: 'smart_toy',
        disabled: (form) => { return !form.reply_as_chatbot || !!form.id }
      })
    }
    this.setFields(this.listFields)
    this.setFields(this.editFields)
  }
}
