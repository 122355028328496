import { PERMISSION_WELL } from '@/default/constants/permissions'

export default {
  id: PERMISSION_WELL,
  icon: 'waves',
  title: 'Gestão de Poços',
  children: [
    // {
    //   id: PERMISSION_WELL,
    //   icon: 'view_week',
    //   title: 'Processo de Poços Tubulares',
    //   route: {
    //     name: 'process-of-wells',
    //     params: {
    //       business: 'well'
    //     }
    //   }
    // },
    {
      id: PERMISSION_WELL,
      icon: 'select_all',
      title: 'Poços Tubulares Em Aberto',
      route: {
        name: 'wells-list',
        params: {
          page: 'well-open'
        }
      }
    },
    {
      id: PERMISSION_WELL,
      icon: 'done_outline',
      title: 'Histórico de Poços Tubulares',
      route: {
        name: 'wells-list',
        params: {
          page: 'well-close'
        }
      }
    },
    {
      id: PERMISSION_WELL,
      icon: 'close',
      title: 'Poços Tubulares Canceladas',
      route: {
        name: 'wells-list',
        params: {
          page: 'well-cancel'
        }
      }
    }
  ]
}
