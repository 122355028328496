<template>
  <v-card class="business-address__not-selected" flat>
    <v-card-text class="business-address__not-selected-content">
      <span style="font-size: 16px;">
        <span>Adicione ou selecione um </span>
        <strong style="text-transform: lowercase;">
          {{ title || 'endereço' }}.
        </strong>
      </span>
      <br/>
      <v-btn @click="searchAddress()" outline>
        <v-icon>search</v-icon>
        <span> Buscar Endereço</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
  import BusinessAddressMixin from './BusinessAddressMixin'
  export default {
    name: 'BusinessAddressNotSelected',
    mixins: [BusinessAddressMixin]
  }
</script>
<style scoped>
  .business-address__not-selected {
    text-align: center !important;
    padding: 12px !important;
  }
  .business-address__not-selected-content {
    padding: 14px !important;
    border-style: dashed;
    border-width: 3px;
    border-color: rgb(174, 174, 174);
  }
</style>