import { Filters } from '@/default/model/Filters'

export class PostFilters extends Filters {
  constructor () {
    super()
    this.id = ''
    this.title = 'Filtrar Publicações'
    this.maxWidth = '500px'
    this.fields = {
      site_id: this.fieldSelectApi({
        value: 'site_id',
        text: 'Site',
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        api: {
          url: 'sites/site',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      }),
      status_id: this.fieldSelect ({
        value: 'status_id',
        text: 'Status',
        placeholder: 'Selecione um status',
        options: [
          {value: 1, text: 'Rascunho'},
          {value: 2, text: 'Publicado'},
          {value: 3, text: 'Cancelado'}
        ],
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      visibility_id: this.fieldSelect ({
        value: 'visibility_id',
        text: 'Visibilidade',
        placeholder: 'Selecione uma visibilidade',
        options: [
          {value: 1, text: 'Público'},
          {value: 2, text: 'Privado'},
          {value: 3, text: 'Protegido'}
        ],
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      content_type_id: this.fieldSelect ({
        value: 'content_type_id',
        text: 'Tipo de Conteúdo',
        placeholder: 'Selecione um tipo de conteúdo',
        options: [
          {value: 1, text: 'Texto Formatado'},
          {value: 2, text: 'Texto Simples'},
          {value: 3, text: 'Vídeo YouTube'},
          {value: 4, text: 'Vídeo Vimeo'}
        ],
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      })
    }
  }
}
