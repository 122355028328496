import { BusinessLayout } from '@/modules/business/config/BusinessLayout'
import { PERMISSION_WELL_UPDATE } from '@/default/constants/permissions'

export class WellLayout extends BusinessLayout {
  constructor () {
    super()
    this.id = PERMISSION_WELL_UPDATE
    this.name = 'well'
    this.title = 'Editar documento'
    this.icon = 'waves'
    this.apiUrl = 'wells/well'
  }
}
