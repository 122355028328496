<template>
  <div v-if="currentSystemIsValidToShow()">
    <div v-if="showRelationsipsInformation">
      <v-card class="elevation-17">
        <v-expansion-panel style="box-shadow: unset;">
          <v-expansion-panel-content class="business-relationships">
            <template v-slot:header>
              <BusinessRelationshipsTitle
                :business="business"
                :businessActions="businessActions"
              />
            </template>
            <v-card v-if="business.business_id && business.business" class="elevation-0" color="green lighten-5">
              <v-divider></v-divider>
              <v-card-title dense flat style="padding: 10px 16px;">
                <strong>
                  Criado a partir de:
                </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <v-icon large :style="{color: business.business.info.color || '' }">
                      {{ business.business.info.icon }}
                    </v-icon>
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <span>
                      {{ business.business.info.title }} 
                    </span>
                    <strong>
                      #{{ business.business.id }}
                    </strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    Em {{ business.created_at | moment('DD/MM/YYYY HH:mm') }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn outline small link :to="{
                    name: business.business.info.name,
                    params: {
                      id: business.business.id
                    }
                  }">
                    <v-icon>arrow_right_alt</v-icon>
                    <span> Acessar</span>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-card>
            <v-card v-if="business.stage == 2 && businessActions && businessActions.length > 0" class="elevation-0">
              <v-divider></v-divider>
              <v-card-text style="text-align: center;">
                <v-btn
                  v-for="(action, actionIndex) in businessActions"
                  outline
                  :key="actionIndex"
                  @click="$WiEditDialog({
                    wiConfig: action.wiConfig,
                    onSubmit: refresh,
                    data: getCurrentBusiness(business)
                  })"
                >
                  <v-icon>{{ action.icon }}</v-icon>
                  <span style="padding-left: 5px;">Criar {{ action.title }} </span>  
                  <span class="hidden-xs-only hidden-sm-only">
                    <span style="padding-left: 3px;"> a partir deste </span>
                    <span> {{ business.info.title }}</span>
                  </span>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card v-if="business.businesses && business.businesses.length > 0" class="elevation-0" color="blue lighten-5">
              <v-divider></v-divider>
              <v-card-title dense flat style="padding: 10px 16px;">
                <strong>
                  Este <span style="text-transform: lowercase;">{{ business.info.title }}</span> deu origem a:
                </strong>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-divider></v-divider>
              <div v-for="(business, businessIndex) in business.businesses" :key="businessIndex">
                <v-list-tile avatar>
                  <v-list-tile-avatar>
                    <v-avatar>
                      <v-icon large :style="{color: business.info.color || '' }">
                        {{ business.info.icon }}
                      </v-icon>
                    </v-avatar>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <span>
                        {{ business.info.title }} 
                      </span>
                      <strong>
                        #{{ business.id }}
                      </strong>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      Em {{ business.created_at | moment('DD/MM/YYYY HH:mm') }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn outline small link :to="{
                      name: business.info.name,
                      params: {
                        id: business.id
                      }
                    }">
                      <v-icon>arrow_right_alt</v-icon>
                      <span> Acessar</span>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider></v-divider>
              </div>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-card>
    </div>
  </div>
</template>

<script>
  import url from '@/default/store/modules/url/url'
  import { PERMISSION_WELLS_MODULE } from '@/default/constants/permissions'
  import BusinessRelationshipsTitle from './BusinessRelationshipsTitle'
  export default {
    name: 'BusinessRelationships',
    computed: {
      businessActions() {
        const actions = [
          {
            icon: 'border_color',
            title: 'Contrato',
            titlePlural: 'Contratos',
            wiConfig: 'contract',
            createdFromTheBusiness: [
              'budget'
            ]
          },
          {
            icon: 'local_grocery_store',
            title: 'Venda',
            titlePlural: 'Vendas',
            wiConfig: 'sale',
            createdFromTheBusiness: [
              'budget'
            ]
          },
          {
            icon: 'phonelink_setup',
            title: 'Ordem de Serviço',
            titlePlural: 'Ordens de Serviço',
            wiConfig: 'order',
            createdFromTheBusiness: [
              'contract',
              'well',
              'budget'
            ]
          },
          {
            icon: 'waves',
            title: 'Poço',
            titlePlural: 'Poços',
            wiConfig: 'well',
            createdFromTheBusiness: [
              'contract',
            ]
          },
          {
            icon: 'privacy_tip',
            title: 'Termo de garantia',
            titlePlural: 'Termos de garantia',
            wiConfig: 'guarantee',
            createdFromTheBusiness: [
              'contract'
            ]
          }
        ]
        return actions.filter(action => {
          return action.createdFromTheBusiness.includes(this.business.info.name)
        })
      },
      showRelationsipsInformation () {
        return this.business?.business || this.business?.businesses?.length > 0 || this.businessActions.length > 0
      },
      PERMISSION_WELLS_MODULE () {
        return PERMISSION_WELLS_MODULE
      }
    },
    methods: {
      getCurrentBusiness: function (business) {
        business['business_id'] = business.id
        business['copy_business'] = 'd711a3c2-3809-4ef2-9052-e34fb11ac908'
        delete business.business_status_id
        delete business.business_category_id
        delete business.description
        delete business.id
        delete business.uuid
        delete business.paid
        return business
      },
      currentSystemIsValidToShow: function () {
        const currentSystem = url?.state?.system
        return currentSystem == 'sis-pocos' || currentSystem == 'wise-control'
      }
    },
    mounted: function () {
      this.currentSystemIsValidToShow()
    },
    props: {
      'business': {
        default: {}
      },
      'refresh': {
        default: () => {}
      },
      'loading': {
        default: false
      }
    },
    components: {
      BusinessRelationshipsTitle
    }
  }
</script>

<style sciped>
  .business-relationships > .v-expansion-panel__header {
    padding: 0 24px 0 0;
  }
</style>