<template>
  <v-card class="elevation-10">
    <v-card-title>
      <h3>Filtros</h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-select
        v-model="filters.impact_ids"
        :items="ticketImpacts"
        item-text="name"
        item-value="id"
        label="Filtrar por Impacto do chamado"
        placeholder="Selecione um ou mais itens"
        multiple
        clear-icon
        chips
        autofocus
        :menu-props="{
          closeOnContentClick: true
        }"
        prepend-inner-icon="filter_list"
      ></v-select>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outline
        small
        width="300px"
        @click="filter()"
        :disabled="Object.keys(filters).length < 1"
      >
        <v-icon>filter_list</v-icon>
        <span> Aplicar Filtros</span>
      </v-btn>
      <v-btn
        outline
        small
        color="red"
        @click="clearFilters()"
      >
        <v-icon>close</v-icon>
        <span> Limpar Filtros</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  const FILTER_CACHE_INDEX = '0ed50b78-5e45-4b2a-a8b5-e745b142c8de'
  export default {
    name: 'TicketsByNetworkTypeFilters',
    data: () => ({
      filters: {}
    }),
    methods: {
      filter: function () {
        this.$emit('selected', this.filters)
        setTimeout(() => {
          this.refresh()
        }, 100)
        this.saveFiltersInCache()
      },
      saveFiltersInCache: function () {
        this.$Storage.set(FILTER_CACHE_INDEX, this.filters)
      },
      setFiltersByCache: function () {
        const filterCached = this.$Storage.get(FILTER_CACHE_INDEX)
        if (filterCached) {
          this.filters = filterCached
        } else {
          this.filters = {}
        }
      },
      clearFilters: function () {
        this.filters = {}
        this.filter()
      }
    },
    created: function () {
      this.setFiltersByCache()
    },
    props: {
      ticketImpacts: {
        type: Array,
        default: () => ([])
      },
      refresh: {
        type: Function,
        required: true
      }
    }
  }
</script>
