<template>
  <WiView
    index="host-detail-dialog"
    title="Monitoramento de OLTs"
    apiUrl="network/device"
    :notLoad="true"
    :notGetID="true"
    @afterLoad="afterLoad"
    apiParams="?type_id=2&limit=100&order=nome_disp,asc&withCount=tickets_opened"
  >
    <template slot="toolbarItensBefore">
      <span v-if="socket.status">Ao vivo</span>
      <span v-else>Não conectado</span>
      <span 
        v-if="socket.status"
        class="olts__live-status-label"
      ></span>
      <span 
        v-else
        class="olts__disconected-status-label"
      ></span>
    </template>
    <template slot-scope="{ content }">
      <v-container fluid fill-height class="py-1 px-1">
        <v-layout row wrap>
          <v-flex
            class="px-2 py-2"
            xs12 sm6 md3 lg2 xl1
            v-for="(device, keyDevice) in content.data"  
            :key="keyDevice"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-card
                  v-on="on"
                  :style="{'background-image': 'url(' + background + ')'}"
                  style="color: white; border-radius: 5px; cursor: pointer;"
                  
                  :color="device.status.color"
                >
                  <v-card-text class="device__data px-1 py-1">
                    <v-layout column align-center>
                      <div style="display: flex; align-items: center; justify-content: center; height: 45px; padding: 5px; background: transparent; width: 100%; max-width: 110px; text-align: center; border-radius: 5px">
                        <img
                          v-if="device.producer.logo"
                          style="max-width: 120px; max-height: 45px;"
                          :src="`static/network/producers/logos/${device.producer.logo}`"
                          alt=""
                        />
                        <v-icon v-else x-large color="white">
                          router
                        </v-icon>
                      </div>
                      <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                        <strong style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 170px;" :style="{ fontSize: $vuetify.breakpoint.name == 'xl' ? '10px' : '14px' }">
                          {{ device.nome_disp }}
                        </strong>
                        <span>
                          {{ device.status.name }}
                        </span>
                      </div>
                    </v-layout>
                  </v-card-text>
                  <v-progress-linear color="white" style="padding: 0 0 5px 0; margin: 0;" height="2" :value="100"></v-progress-linear>
                </v-card>
              </template>
              <v-list>
                <v-list-tile @click="$WiEditDialog({ wiConfig: 'devices', data: device })">
                  <v-list-tile-avatar>
                    <v-icon color="orange">edit</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Editar OLT
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile :disabled="device.status.id == 100" @click="$WiListDialog({wiConfig: 'device-event', filter: { device_id: device.id, status_id: '0' }})">
                  <v-list-tile-avatar>
                    <v-icon :color="device.status.id == 100 ? 'grey' : 'red'">keyboard_double_arrow_down</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Incidentes não resolvidos
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile @click="$WiListDialog({wiConfig: 'ticket-opened', filter: {device: device.id}})">
                  <v-list-tile-avatar>
                    <v-icon color="#a59d19">message</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Chamados em aberto
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile link :to="{ name: 'device', params: { id: device.id } }">
                  <v-list-tile-avatar>
                    <v-icon>info</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Detalhes da OLT
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile link :to="{ name: 'grupo-mapa-dispositivos', params: { id: device.grupo_dados_id } }">
                  <v-list-tile-avatar>
                    <v-icon color="#367da6">device_hub</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Exibir OLT na topologia
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile link :to="{ name: 'network-list', params: { page: 'host' }, query: { device_id: device.id } }">
                  <v-list-tile-avatar>
                    <v-icon color="green">router</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Equipamentos/clientes na OLT
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
        <div>
          <v-snackbar
            bottom absolute right :timeout="20000"
            v-for="(snack, snaskIndex) in snaks"
            :key="snaskIndex" :color="snack.color"
            v-model="snack.active"
          >
            {{ snack.text }}
          </v-snackbar>
        </div>
      </v-container>
    </template>
  </WiView>
</template>
<script>
  export default {
    name: 'OLTsDashboard',
    data: () => ({
      snaks: [],
      devices: [],
      background: `static/background/background-gradiente-1.png`,
      socket: {
        url: null,
        status: false
      },
      interval: null,
      refresh: null
    }),
    methods: {
      openTicketsList: function () {
        this.$WiListDialog({
          wiConfig: 'ticket-list',
          filter: {
            device: this.$route.params.id,
            stage: 'opened'
          }
        })
      },
      startInterval: function () {
        this.interval = setInterval(() => {
          this.refresh()
        }, 240000)
      },
      stopInterval: function () {
        clearInterval(this.interval)
      },
      socketConnect: function () {
        this.socket.url = this.$Auth.account().id + '.device.olt'
        this.socket.status = window?.Echo?.connector?.socket?.id ? true : false
        window.Echo.connector.socket.on('connect', () => {
          this.socket.status = true
        })
        window.Echo.connector.socket.on('disconnect', () => {
          this.socket.status = false
        })
      },
      subscribeOnNetworkStatusChange: function () {
        window.Echo.private(this.socket.url)
        .listen('.status.changed', (response) => {
          setTimeout(() => {
            this.refresh()
            this.snaks.push({
              active: true,
              text: response.up || response.down || response.ticket || response.device,
              color: response.up ? 'green' : response.down ? 'red' : response.ticket || response.device ? 'blue' : 'grey'
            })
          }, 100)
        })
      },
      unsubscribeOnNetworkStatusChange: function () {
        window.Echo.leave(this.socket.url)
      },
      afterLoad: function ({ content, refresh }) {
        this.devices = content.data
        this.refresh = refresh
      }
    },
    mounted () {
      this.socketConnect()
      this.startInterval()
      this.subscribeOnNetworkStatusChange()
    },
    beforeDestroy () {
      this.stopInterval()
      this.unsubscribeOnNetworkStatusChange()
    }
  }
</script>
<style scoped>
  .olts__disconected-status-label {
    width: 20px;
    height: 20px;
    background: rgb(170, 3, 3);
    border-radius: 100%;
    margin: 0 10px;
  }
  .olts__live-status-label {
    width: 20px;
    height: 20px;
    background: rgb(3, 170, 3);
    border-radius: 100%;
    margin: 0 10px;
    animation: pulsee 1s infinite;
  }
  @keyframes pulsee {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 255, 55, 0.637);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
    }
  }
</style>