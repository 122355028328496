<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        {{ label }}
      </span>
    </template>
    <span v-text="label"></span>
  </v-tooltip>
</template>
<script>
  export default {
    name: 'FormReplyListReplyText',
    props: {
      label: {
        required: true
      }
    }
  }
</script>
<style scoped>
.form-reply-list-reply-message {
  border-style: solid;
  border-width: 1px;
  padding: 10px 16px;
}
</style>
