export default {
  id: {
    id: '44e3b5cf',
    create: 'b8508be5',
    update: 'a47fa4a9',
    delete: '790eaec7'
  },
  list: true,
  title: {
    list: 'Transferências entre Estoque',
    edit: {
      insert: 'Nova transferência de Estoque',
      update: 'Editando transferência de Estoque'
    }
  },
  icon: 'import_export',
  search: {
    placeholder: 'Pesquise pelo Produto ou Descrição'
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  components: {
    list: {
      tableRow: () => import('@/modules/stock/pages/Stock/StockTransfers')
    }
  },
  apiUrl: 'stock/transfer',
  fields: [
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      list: true
    },
    {
      value: 'stock_out.name',
      text: 'Estoque de Retirada',
      type: 'text',
      list: true
    },
    {
      value: 'stock_in.name',
      text: 'Estoque de Entrada',
      type: 'text',
      list: true
    },
    {
      value: 'item.name',
      text: 'Nome do Produto',
      type: 'text',
      list: true,
      search: true
    },
    {
      value: 'quantity',
      text: 'Qtd. Transferida',
      type: 'int',
      list: true
    },
    {
      value: 'description',
      text: 'Descrição',
      type: 'text',
      list: true,
      search: true
    },
    {
      text: 'Selecione o Estoque de retirada e o de entrada.',
      type: 'subheader',
      edit: {
        insert: true
      }
    },
    {
      value: 'stock_out_id',
      text: 'Estoque de Retirada*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      edit: {
        insert: true
      },
      apiUrl: 'stock',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    },
    {
      value: 'stock_in_id',
      text: 'Estoque de Entrada*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      edit: {
        insert: true
      },
      apiUrl: 'stock',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    },
    {
      value: 'item_id',
      text: 'Produto*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      edit: {
        insert: true
      },
      apiUrl: 'register/product',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    },
    {
      text: 'Quantidade Transferida, data e descrição.',
      type: 'subheader',
      edit: {
        insert: true
      }
    },
    {
      text: 'Data e descrição da transferência.',
      type: 'subheader',
      edit: {
        update: true
      }
    },
    {
      value: 'quantity',
      text: 'Quantidade transferida',
      type: 'int',
      edit: {
        insert: true
      },
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4']
    },
    {
      value: 'date_competence',
      text: 'Data da Transferência',
      type: 'date',
      edit: true,
      required: true,
      size: (form) => { return form.id ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md4', 'lg4'] }
    },
    {
      value: 'description',
      text: 'Descrição',
      type: 'text',
      edit: true,
      size: (form) => { return form.id ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md4', 'lg4'] }
    },
    {
      value: 'observation',
      text: 'Observações',
      placeholder: 'Observações desta Transferência',
      type: 'textarea',
      edit: true,
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }
  ],
  loading_list: false
}
