<template>
  <div>
    <div v-if="isCustomer || isProvider">
      <div v-if="hasTickets">
        <v-card flat color="transparent">
          <v-card-title class="py-1 px-0">
            <v-icon>attach_money</v-icon>
            <span style="padding-left: 10px;">
              <strong>Chamados</strong>
            </span>
            <v-spacer></v-spacer>
            <v-btn outline small color="green" class="my-0 mx-0" @click="addTicket()">
              <v-icon>add</v-icon>
              <span>Novo</span>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0" style="display: flex; flex-direction: column; gap: 15px;">
            <v-card v-for="(ticket, ticketIndex) in tickets" :key="ticketIndex">
              <v-card-title style="padding: 5px 0 0 0;">
                <span style="padding: 0px 5px;">
                  CÓD: <strong> #{{ ticket.id}}</strong>
                </span>
                <v-spacer></v-spacer>
                <span v-if="ticket.sla" style="padding: 0px 5px;">
                  SLA: <strong :style="{padding: '0px 3px', background: ticket.sla > 99 ? '#c90000' : (ticket.sla > 85) ? '#e59100' : 'green', color: 'white'}">{{ ticket.sla }}% </strong>
                </span>
                <v-progress-linear
                  v-if="ticket.sla"
                  height="3"
                  :color="ticket.sla > 99 ? '#c90000' : (ticket.sla > 85) ? '#e59100' : 'green'"
                  v-model="ticket.sla"
                  style="margin: 5px 0 0 0 !important;"
                ></v-progress-linear>
              </v-card-title>
              <v-divider v-if="ticket.assunto_chamado"></v-divider>
              <v-card-title v-if="ticket.assunto_chamado" style="padding: 5px 0 0 0;">
                <span style="padding: 0px 5px;">
                  <strong> {{ ticket.assunto_chamado }}</strong>
                </span>
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense style="padding: 0;">
                <v-list-tile class="talk-dawer__tile" style="padding: 5px 0 !important;">
                  <v-list-tile-avatar v-if="ticket.status" style="padding: 0; min-width: 0;">
                    <v-icon v-if="ticket.status" size="30" :color="ticket.status.color">
                      {{ ticket.status.icon }}
                    </v-icon>
                    <v-icon v-else>confirmation_number</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-if="ticket.status">
                    <v-list-tile-sub-title style="color: gray;">Status:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.status.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-avatar v-if="ticket.category" style="padding: 0; min-width: 0;">
                    <v-icon size="30" :color="ticket.category.color">
                      {{ ticket.category.icon }}
                    </v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-if="ticket.category">
                    <v-list-tile-sub-title style="color: gray;">Categoria:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.category.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile class="talk-dawer__tile" avatar>
                  <!-- <v-list-tile-avatar style="padding: 0; min-width: 5px;">
                    <img src="static/icons/monitor/management_96.png" alt="Equipe" style="max-heiht: 30px;">
                  </v-list-tile-avatar> -->
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="color: gray;">Equipe:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.team.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-avatar v-if="ticket.responsible" style="padding: 0; min-width: 0;">
                    <img v-if="ticket.responsible.file" :src="ticket.responsible.file.url" alt="Responsável" style="max-height: 30px;max-width: 30px;">
                    <img v-else src="static/icons/monitor/lecturer_96.png" alt="Responsável" style="max-height: 30px;">
                  </v-list-tile-avatar>
                  <v-list-tile-content v-if="ticket.responsible">
                    <v-list-tile-sub-title style="color: gray;">Responsável:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.responsible.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider v-if="ticket.last_note"></v-divider>
                <v-list-tile v-if="ticket.last_note">
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="color: gray;">
                      Última anotação:
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title style="color: gray;">
                      {{ ticket.last_note.description_time }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile>
                  <v-btn ripple small block outline style="margin: 0 10px 0 0;" @click="sendTicketResume(ticket)">
                    <v-icon>send</v-icon>
                    <span style="padding-left: 5px;">Enviar resumo</span>
                  </v-btn>
                  <v-btn ripple small icon style="margin: 0 10px 0 0;" link :to="{name: 'ticket', params: { id: ticket.id }}">
                    <v-icon>open_in_new</v-icon>
                  </v-btn>
                  <v-btn ripple small icon style="margin: 0 0 0 10px;" @click="addTicketNote(ticket)">
                    <v-icon>message</v-icon>
                  </v-btn>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-card-text>
        </v-card>
      </div>
      <v-card-text v-else>
        <h3>Nenhum chamado em<br>execução encontrado.</h3>
        <img src="static/icons/monitor/add_tag_96.png" alt="">
        <p class="mb-2">Clique no botão abaixo para<br>adicionar um novo chamado.</p>
        <v-btn outline small color="green" @click="addTicket()">
          <v-icon>add</v-icon>
          <span> novo chamado/ticket</span>
        </v-btn>
      </v-card-text>
    </div>
  </div>       
</template>
<script>
  import { mapMutations } from 'vuex'
  export default {
    name: 'TalkContactTickets',
    data () {
      return {
        dialog: false,
        profileImageDialog: false,
        memberSearch: ''
      }
    },
    computed: {
      isCustomer () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'customer') > -1
      },
      isProvider () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'provider') > -1
      },
      hasTickets () {
        return this.talk?.assets?.tickets?.length > 0
      },
      tickets () {
        return this.talk?.assets?.tickets || []
      },
      person () {
        const person = this.contact?.person?.person || this.contact?.person
        return person
      }
    },
    methods: {
      ...mapMutations('Talk', ['SET_MESSAGE_TEXT', 'SET_MESSAGE_EDITOR_DIALOG']),
      addTicketNote: function (ticket) {
        this.$WiEditDialog({
          wiConfig: 'chamados-anotacao',
          onSubmit: () => {
            this.refresh()
          },
          data: {
            chamado_dados_id: ticket.id
          }
        })
      },
      addTicket: function () {
        let data = {
          person_id: this.person?.id,
          person_type: this.person?.profiles[0].name,
          cli_ou_for: this.person ? this.person?.profiles[0].name == 'customer' ? 'cli' : 'for' : null,
          person: this.person || null,
          team_id: this.team?.id,
          team: this.team || null
        }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            person_responsible_id: this.person.id,
            responsible: this.person,
            responsavel: this.person,
          }
        }
        this.$WiEditDialog({
          wiConfig: 'chamados-todos',
          onSubmit: () => {
            this.refresh()
          },
          data,
          redirectOnSubmit: false
        })
      },
      sendTicketResume: function (ticket) {
        let message = `Atualização do chamado *#${ticket.id}* \n\n`;
        
        if (ticket.assunto_chamado) {
          message += `*Assunto:* ${ticket.assunto_chamado} \n\n`;
        }
        if (ticket.status) {
          message += `*Status:* ${ticket.status.name} \n`;
        }
        if (ticket.category) {
          message += `*Categoria:* ${ticket.category.name} \n`;
        }
        if (ticket.team) {
          message += `*Equipe:* ${ticket.team.name} \n`;
        }
        if (ticket.responsible) {
          message += `*Responsável:* ${ticket.responsible.name} \n\n`;
        }
        if (ticket.last_note) {
          message += `*Última anotação:* ${ticket.last_note.description_time} \n\n`;
        }
   
        // message += `*Link:* ${process.env.VUE_APP_URL}/chamados/${ticket.id} \n`;
        this.SET_MESSAGE_TEXT(message)
        this.SET_MESSAGE_EDITOR_DIALOG(true)
      },
      showAllTickets: function () {
        let query = {
          person_id: this.person?.id || null,
        }
        if (this.team) {
          query = {
            team: this.team?.id,
          }
        }
        if (this.person?.profiles[0].name == "collaborator") {
          query = {
            person_responsible_id: this.person?.id,
          }
        }
        // return ({
        //   name: 'service-desk-list',
        //   params: {
        //     page: 'ticket-opened'
        //   },
        //   query
        // })
        this.$WiListDialog({
          wiConfig: 'ticket-opened',
          filter: query,
          redirectOnSubmit: false,
          wiEditData: query
        })
      }
    },
    props: ['talk', 'contact', 'refresh']
  }
</script>
<style scoped>
  .talk-contact-budgets__list-tile {
    padding: 0 0px !important;
  }
  .talk-contact-budgets__list-tile >>> .v-list__tile {
    padding: 0 5px !important;
  }
</style>