import { Model } from '@/default/model/Model'
import {
  PERMISSION_CONTRACT,
  PERMISSION_CONTRACT_CREATE,
  PERMISSION_CONTRACT_UPDATE,
  PERMISSION_CONTRACT_DELETE
} from '@/default/constants/permissions'

export class BusinessPromotion extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_CONTRACT,
      create: PERMISSION_CONTRACT_CREATE,
      update: PERMISSION_CONTRACT_UPDATE,
      delete: PERMISSION_CONTRACT_DELETE
    }
    this.name = 'business-promotion'
    this.title = {
      list: 'Promoções',
      edit: {
        insert: 'Nova Promoção',
        update: 'Editar Promoção'
      }
    }
    this.apiUrl = 'sales/sale/promotion'
    this.params = {
      limit: 10
    }
    this.icon = 'code'
    this.components.list.tableRow = () => import('@/modules/business/pages/Businesses/BusinessPromotions')
    this.maxWidth = '800px'
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome'
    }),
    type: this.fieldList({
      value: 'type_id',
      text: 'Tipo de Promoção'
    }),
    value: this.fieldList({
      value: 'value',
      text: 'Valor'
    }),
    status: this.fieldList({
      value: 'status',
      text: 'Status'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, tipo e o valor da promoção.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da promoção',
      size: ['xs12', 'sm12', 'md7', 'lg7']
    }),
    type_id: this.fieldSelectApi({
      value: 'type_id',
      text: 'Tipo da Promoção',
      placeholder: 'Selecione o tipo da promoção',
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      required: true,
      disabled: (form) => (form.id > 0),
      api: {
        url: 'saless/contract/promotion/type',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    valueFixed: this.fieldMoney({
      value: 'value',
      text: 'Valor do desconto',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      showField: (form) => { return (form.type_id == 100 || form.type_id == 300) || !form.type_id }
    }),
    valuePercentage: this.fieldNumber({
      value: 'value',
      text: 'Porcentagem',
      min: 1,
      max: 100,
      step: '.01',
      prefix: '%',
      suffix: '%',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return (form.type_id == 200 || form.type_id == 400) }
    }),
    invoiceStart: this.fieldNumber({
      value: 'invoice_number_start',
      text: 'Parcela inicial',
      min: 0,
      max: 10000,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      disabled: (form) => (form.id > 0)
    }),
    invoiceEnd: this.fieldNumber({
      value: 'invoice_number_end',
      text: 'Parcela final',
      min: 0,
      max: 10000,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      disabled: (form) => (form.id > 0)
    }),
    status: this.fieldSwitch({
      value: 'status',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição/observação da promoção'
    })
  }
}
