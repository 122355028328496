<template>
  <div style="width: 100%;">
    <emoji-picker
      style="width: 100%;"
    ></emoji-picker>
  </div>
</template>

<script>
  export default {
    name: 'EmojiMenu',
    data: () => ({
      eventListener: null
    }),
    methods: {
      onEmojiSelected (emoji) {
        this.$emit('onSelect', emoji)
      },
      initEmojiPicker () {
        setTimeout(() => {
          const emojiPickerElement = document.querySelector('emoji-picker')
          this.eventListener = emojiPickerElement
          if (emojiPickerElement) {
            emojiPickerElement.addEventListener('emoji-click', (event) => {
              this.onEmojiSelected(event.detail.unicode)
            })
          }
        }, 100);
      },
      destroyEmojiPicker () {
        setTimeout(() => {
          if (this.eventListener) {
            this.eventListener.removeEventListener('emoji-click', (event) => {
              console.log(event)
            })
          }
        }, 100);
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.initEmojiPicker()
      })
    }
  }
</script>