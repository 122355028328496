import {Model} from '@/default/model/Model'

export class BusinessLayoutVariable extends Model {
  constructor () {
    super()
    this.name = 'layout-variable'
    this.title = 'Variáveis disponíveis'
    this.icon = 'spellcheck'
    this.apiUrl = 'business/layout/variable'
    this.actions = {
      list: true
    }
    this.setFields(this.fieldsList)
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome',
      sortable: false
    }),
    variable: this.fieldList({
      value: 'variable',
      text: 'Variável',
      sortable: false
    }),
    description: this.fieldList({
      value: 'description',
      text: 'Descrição',
      sortable: false
    })
  }
}
