import Business from '@/modules/shared/pages/Business/Business'
import BusinessPrint from '@/modules/shared/pages/Business/BusinessPrint'
import BusinessOfProcess from '@/modules/shared/pages/Businesses/BusinessOfProcess'

import BusinessDashboard from '@/modules/shared/pages/BusinessDashboard/BusinessDashboard'

import BusinessByPersonReport from '@/modules/shared/pages/BusinessReport/BusinessByPersonReport'
import BusinessComissionByPersonReport from '@/modules/shared/pages/BusinessReport/BusinessComissionByPersonReport'

export default [
  {
    path: 'process/:id',
    name: 'process',
    component: Business,
    meta: {
      id: 'e2a51798',
      auth: true,
      title: 'Detalhes do processo',
      icon: 'gavel'
    }
  },
  {
    path: 'budget/:id',
    name: 'juridic-budget',
    component: Business,
    meta: {
      id: '053bec35',
      auth: true,
      title: 'Detalhes do Orçamento',
      icon: 'shop'
    }
  },
  {
    path: 'business-print',
    name: 'business-print',
    component: BusinessPrint,
    meta: {
      id: 'e80a117e-895f-48be-8c1a-47fd93f5b814',
      auth: true,
      title: 'Impressão',
      icon: 'print'
    }
  },
  {
    path: 'dashboard-juridic',
    name: 'dashboard-juridic',
    component: BusinessDashboard,
    meta: {
      id: 'e80a117e-895f-48be-8c1a-47fd93f5b814',
      auth: true,
      title: 'Dashboard Jurídico',
      icon: 'dashboard',
      moduleName: 'juridical'
    }
  },
  {
    path: 'process-of-juridical/:business',
    name: 'process-of-juridical',
    component: BusinessOfProcess,
    meta: {
      id: 'e80a117e-895f-48be-8c1a-47fd93f5b814',
      auth: true,
      title: 'Jurídico',
      icon: 'gavel',
      touch: true
    }
  },
  {
    path: 'report-processes-by-customer',
    name: 'processes-by-customer',
    component: BusinessByPersonReport,
    meta: {
      id: 'e19b2845',
      auth: true,
      title: 'Relatório de Processos por Cliente',
      icon: 'bar_chart',
      touch: true,
      business: 'process',
      person: 'customer'
    }
  },
  {
    path: 'report-honorary-by-collaborator',
    name: 'honorary-by-collaborator',
    component: BusinessComissionByPersonReport,
    meta: {
      id: '7da883c0',
      auth: true,
      title: 'Relatório de Honorários por Colaborador',
      icon: 'local_atm',
      touch: true,
      business: 'process',
      person: 'collaborator'
    }
  },
  {
    path: 'report-payments-by-customer',
    name: 'payments-by-customer',
    component: BusinessComissionByPersonReport,
    meta: {
      id: '7da883c0',
      auth: true,
      title: 'Relatório de Pagamentos por Cliente',
      icon: 'local_atm',
      touch: true,
      business: 'process',
      person: 'customer'
    }
  },
  {
    path: 'juridic-report-budgets-by-customer',
    name: 'juridic-budgets-by-customer',
    component: BusinessByPersonReport,
    meta: {
      id: '39da381b',
      auth: true,
      title: 'Relatório de Orçamentos por Cliente',
      icon: 'bar_chart',
      touch: true,
      business: 'budget',
      person: 'customer'
    }
  },
  {
    path: 'report-processes-by-collaborator',
    name: 'processes-by-collaborator',
    component: BusinessByPersonReport,
    meta: {
      id: '2378cb8b',
      auth: true,
      title: 'Relatório de Processos por Colaborador',
      icon: 'bar_chart',
      touch: true,
      business: 'process',
      person: 'collaborator'
    }
  },
  {
    path: 'juridic-report-budgets-by-collaborator',
    name: 'juridic-budgets-by-collaborator',
    component: BusinessByPersonReport,
    meta: {
      id: '7da223c0',
      auth: true,
      title: 'Relatório de Vendas por Colaborador',
      icon: 'bar_chart',
      touch: true,
      business: 'budget',
      person: 'collaborator'
    }
  }
]
