<template>
	<v-toolbar dense :color="color" style="border-radius: 10px;">
    <v-icon large color="white">{{icon}}</v-icon>
    <h3 style="color: white; padding-left: 10px;">{{title}}</h3>
    <v-spacer></v-spacer>
    <h3 style="color: white;">{{amount | money}}</h3>
  </v-toolbar>
</template>
<script>
  export default {
    data: () => ({}),
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      }
    },
    props: {
      'icon': {
        type: String,
        default: 'add'
      },
      'color': {
        type: String,
        default: 'blue'
      },
      'title': {
        type: String,
        required: true
      },
      'amount': {
        default: 0
      }
    }
  }
</script>