<template>
  <div>
    <v-container fluid grid-list-md style="padding-top: 8px;">
      <v-layout row wrap>
        <!-- eslint-disable -->
        <v-flex v-if="grupos && grupos.length > 0" d-flex xs6 sm4 md3 lg2 v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex">
          <v-card v-if="grupo.status.cod_status == '#100'" @click="$emit('action', grupo)" :style="{'background': grupo.status.cor_status, 'background-image': 'url(' + background + ')', color: 'white', 'border-radius': '5px', cursor: 'pointer'}">
            <p class="text-xs-right" style="font-size: 14px; padding: 5px 5px 0 5px; margin-bottom: 0; min-height: 40px;">{{grupo.nome_grupo}}</p>
            <p style="padding: 0 0 0 5px; margin-bottom: 0;">
              <span style="font-size: 20px;">100%</span>
              <span style="text-align: right; padding-left: 8px;">
                <span>{{grupo.status.nome_status}}</span>
              </span>
            </p>
            <v-progress-linear color="white" style="margin: 0 0 5px 0;" height="2" :value="100"></v-progress-linear>
          </v-card>
          <v-card v-if="grupo.status.cod_status == '#110'" @click="$emit('action', grupo)" :style="{'background': grupo.status.cor_status, 'background-image': 'url(' + background + ')', color: 'white', 'border-radius': '5px', cursor: 'pointer'}">
            <p class="text-xs-right" style="font-size: 14px; padding: 5px 5px 0 5px; margin-bottom: 0; min-height: 40px;">{{grupo.nome_grupo}}</p>
            <p style="padding: 0 0 0 5px; margin-bottom: 0;">
              <span style="font-size: 20px;"></span>
              <span style="text-align: right; padding-left: 8px;">
                <span>{{grupo.status.nome_status}}</span>
              </span>
            </p>
            <v-progress-linear color="white" style="margin: 0 0 5px 0;" height="2" :value="100"></v-progress-linear>
          </v-card>
          <v-card v-if="grupo.status.cod_status == '#020'" @click="$emit('action', grupo)" :style="{'background': grupo.status.cor_status, 'background-image': 'url(' + background + ')', color: 'white', 'border-radius': '5px', cursor: 'pointer'}">
            <p class="text-xs-right" style="font-size: 14px; padding: 5px 5px 0 5px; margin-bottom: 0; min-height: 40px;">{{grupo.nome_grupo}}</p>
            <p style="padding: 0 0 0 5px; margin-bottom: 0;">
              <span style="font-size: 20px;">{{calcPorc(grupo)}}%</span>
              <span style="text-align: right; padding-left: 8px;">
                <span>{{grupo.status.nome_status}}</span>
              </span>
            </p>
            <v-progress-linear color="white" style="margin: 0 0 5px 0;" height="2" :value="calcPorc(grupo)"></v-progress-linear>
          </v-card>
          <v-card v-if="grupo.status.cod_status == '#030' || grupo.status.cod_status == '#031'" @click="$emit('action', grupo)" :style="{'background': grupo.status.cor_status, 'background-image': 'url(' + background + ')', color: 'white', 'border-radius': '5px', cursor: 'pointer'}">
            <p class="text-xs-right" style="font-size: 14px; padding: 5px 5px 0 5px; margin-bottom: 0; min-height: 40px;">{{grupo.nome_grupo}}</p>
            <p style="padding: 0 0 0 5px; margin-bottom: 0;">
              <span style="font-size: 20px;">{{calcPorc(grupo)}}%</span>
              <span style="text-align: right; padding-left: 8px;">
                <span>{{grupo.status.nome_status}}</span>
              </span>
            </p>
            <v-progress-linear color="white" style="margin: 0 0 5px 0;" height="2" :value="calcPorc(grupo)"></v-progress-linear>
          </v-card>
          <v-card v-if="grupo.status.cod_status == '#010'" @click="$emit('action', grupo)" :style="{'background': grupo.status.cor_status, 'background-image': 'url(' + background + ')', color: 'white', 'border-radius': '5px', cursor: 'pointer'}">
            <p class="text-xs-right" style="font-size: 14px; padding: 5px 5px 0 5px; margin-bottom: 0; min-height: 40px;">{{grupo.nome_grupo}}</p>
            <p style="padding: 0 0 0 5px; margin-bottom: 0;">
              <span style="font-size: 20px;"></span>
              <span style="text-align: right; padding-left: 8px;">
                <span>{{grupo.status.nome_status}}</span>
              </span>
            </p>
            <v-progress-linear color="white" style="margin: 0 0 5px 0;" height="2" :value="0"></v-progress-linear>
          </v-card>
          <v-card v-if="grupo.status.cod_status == '#000'" @click="$emit('action', grupo)" :style="{'background': grupo.status.cor_status, 'background-image': 'url(' + background + ')', color: 'white', 'border-radius': '5px', cursor: 'pointer'}">
            <p class="text-xs-right" style="font-size: 14px; padding: 5px 5px 0 5px; margin-bottom: 0; min-height: 40px;">{{grupo.nome_grupo}}</p>
            <p style="padding: 0 0 0 5px; margin-bottom: 0;">
              <span style="font-size: 20px;"></span>
              <span style="text-align: right; padding-left: 8px;">
                <span>{{grupo.status.nome_status}}</span>
              </span>
            </p>
            <v-progress-linear color="white" style="margin: 0 0 5px 0;" height="2" :value="0"></v-progress-linear>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'GruposRealTime',
    data () {
      return {
        background: 'static/background/background-gradiente-1.png'
      }
    },
    methods: {
      calcPorc: function (grupo) {
        var number = (grupo.num_disp_down * 100) / grupo.num_disp
        return number ? (number.toFixed(0) > 0 ? number.toFixed(0) : 1) : 0
      }
    },
    components: {},
    props: ['grupos']
  }
</script>