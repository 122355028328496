<template>
  <view-detail title="Chamados Por Rede e Ocorrência" apiUrl="service-desk/report/tickets-by-group" :apiParams="params" :notGetID="true" :notShowToolbar="false">
    <template slot-scope="dados">
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px 20px 0px 10px;">
          <v-layout row wrap>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.data_inicial" type="datetime-local" label="Data Inicial"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.data_final" type="datetime-local" label="Data Final"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-btn :disabled="!filter.data_inicial && !filter.data_final" @click="setFilter()" style="width: 100% !important;">Filtrar</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <download-excel v-if="dados.content && dados.content.data && dados.content.data.length > 0" class="btn btn-default" :data="dados.content.data" :fields="makeXLSFields()" worksheet="Chamados Por Rede e Ocorrência" name="Relatório.xls">
                <v-btn color="teal darken-1" style="color: white; width: 100% !important;">Exportar/Excel</v-btn>
              </download-excel>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="dados.content && dados.content.info && dados.content.info.nome_categoria" xs12 sm12 md12 lg12 style="text-align: center; padding: 0px 10px 20px 10px;">
          Filtrando por: <strong>{{dados.content.info.nome_categoria_grupo}}</strong>
        </v-flex>
        <v-flex v-if="filter.data_inicial && filter.data_final" xs12 sm12 md12 lg12 style="padding: 20px;">
          <v-data-table v-model="table" hide-actions no-data-text="Nenhum resultado encontrado para este filtro." no-results-text="Nenhum resultado encontrado para este filtro." :pagination.sync="pagination" :headers="headers" :items="dados.content.data" class="elevation-1">
            <template v-slot:items="props">
              <td>{{ props.item.id_network }}</td>
              <td>{{ props.item.name_network_up }}</td>
              <td>{{ props.item.name_network }}</td>
              <td>
                <strong style="font-size: 18px;">{{ props.item.count_tickets_network }}</strong>
              </td>
              <td>{{ props.item.id_category }}</td>
              <td>{{ props.item.name_category }}</td>
              <td>
                <strong style="font-size: 18px;">{{ props.item.count_tickets_category }}</strong>
              </td>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex v-else xs12 sm12 md12 lg12 style="padding: 20px;">
          <h3>Por favor, informe acima o intervalo de tempo em que deseja gerar o relatório.</h3>
        </v-flex>
      </v-layout>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  export default {
    name: 'ChamadosPorTipoDeOcorrencia',
    data () {
      return {
        table: [],
        headers: [
        { text: 'ID Rede', value: 'id_network' },
        { text: 'Rede acima', value: 'name_network_up' },
        { text: 'Rede', value: 'name_network' },
        { text: 'N° de Chamados Rede', value: 'count_tickets_network' },
        { text: 'ID Ocorrência', value: 'id_category' },
        { text: 'Ocorrência', value: 'name_category' },
        { text: 'N° de Chamados Ocorrência', value: 'count_tickets_category' }
        ],
        options: {
          chart: {
            id: 'Chamados Por Tipo de Ocorrência'
          },
          labels: []
        },
        series: [],
        pagination: {
          sortBy: 'ativa_categoria,num_chamados',
          rowsPerPage: -1,
          descending: true
        },
        params: '',
        filter: {
          data_inicial: new Date().toISOString().substr(0, 10) + 'T00:00',
          data_final: new Date().toISOString().substr(0, 10) + 'T23:59',
          ocorrencia_id: null
        }
      }
    },
    methods: {
      setSeries: function (data) {
        var r = []
        var categories = []
        data.filter((item) => {
          r.push(item.num_chamados)
          categories.push(item.nome_categoria)
        })
        this.options.labels = []
        this.options.labels = categories
        return r
      },
      setParams: function (route) {
        if (route.query) {
          this.params = '?'
          if (route.query.data_inicial) {
            this.params += 'data_inicial=' + route.query.data_inicial + '&'
          }
          if (route.query.data_final) {
            this.params += 'data_final=' + route.query.data_final + '&'
          }
          if (route.query.ocorrencia_id) {
            this.params += 'chamado_categoria_id=' + route.query.ocorrencia_id
          }
        } else {
          this.params = ''
        }
      },
      setFilter: function () {
        var query = {}
        if (this.filter.data_inicial) {
          query['data_inicial'] = this.filter.data_inicial
        }
        if (this.filter.data_final) {
          query['data_final'] = this.filter.data_final
        }
        if (this.filter.ocorrencia_id) {
          query['ocorrencia_id'] = this.filter.ocorrencia_id
        }
        this.$router.push({query: query})
      },
      makeXLSFields: function () {
        var fields = {}
        this.headers.filter((item) => {
          if (item.text && item.value) {
            fields[item.text] = item.value
          }
        })
        return fields
      }
    },
    created: function () {
      this.setParams({query: this.filter})
    },
    watch: {
      '$route': 'setParams'
    },
    computed: {
      auth () {
        return this.$store.state.auth || {}
      }
    },
    components: {
      ViewDetail
    }
  }
</script>
