<template>
  <div>
    <!-- eslint-disable -->
    <view-detail notShowToolbar="true" notGetID="true" apiUrl="service-desk/dashboard" :apiParams="api.params">
      <template slot-scope="props">
        <v-container style="padding: 10px;" fluid grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 style="padding: 5px 5px 10px 5px;">
              <v-card>
                <v-list dense style="max-height: none !important;">
                  <v-list-tile v-ripple avatar>
                    <v-list-tile-avatar size="45" @click="$router.go(-1)">
                      <v-btn icon ripple>
                        <v-icon>arrow_back</v-icon>
                      </v-btn>
                    </v-list-tile-avatar>
                    <v-list-tile-avatar size="45" @click="props.refresh()" style="cursor: pointer;">
                      <img src="static/systems/modules/wises-technology-module-service-desk.png" alt="Dashboard Service Desk" >
                    </v-list-tile-avatar>
                    <v-list-tile-content @click="props.refresh()" style="cursor: pointer;">
                      <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                      <v-list-tile-title style="font-size:18px;">
                        <span>Service Desk</span>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action @click="props.refresh()" style="min-width: 1px !important; cursor: pointer;">
                      <v-btn flat icon color="green">
                        <v-icon>refresh</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 style="font-size: 12px;">
              <span>*Dados obtidos em {{ props.content.cached_at | moment('DD/MM/YY à\\s HH:mm:ss') }}.</span>
              <span v-if="props.content.cache_expire_in >= 0"> Novos dados disponíveis em ({{ props.content.cached_end_at | moment('DD/MM/YY à\\s HH:mm:ss') }}).</span>
              <span v-else @click="props.refresh()" style="cursor: pointer;"> Clique aqui para obter novos dados.</span>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4 style="padding: 5px 5px 10px 5px;">
              <tickets-by-status :data="props.content.status" :limit="5" v-on:action="openDialog"></tickets-by-status>
            </v-flex>
            <v-flex v-prms="{id: '4aa0482a'}" xs12 sm12 md4 lg4 style="padding: 5px 5px 10px 5px;">
              <tickets-by-event-collaborator :data="props.content.collaborator_events" :limit="5" v-on:action="openDialog"></tickets-by-event-collaborator>
            </v-flex>
            <!-- <v-flex v-if="props.content && props.content.execution_per_time" v-prms="{id: '4aa0482a'}" xs12 sm12 md4 lg4 style="padding: 5px 5px 10px 5px;">
              <DashboardTicketsByExecutionPerTime
                :data="props.content.execution_per_time"
                :limit="5"
                v-on:action="openDialog"
              ></DashboardTicketsByExecutionPerTime>
            </v-flex> -->
            <v-flex v-if="props.content && props.content.duration_per_time" v-prms="{id: '4aa0482a'}" xs12 sm12 md4 lg4 style="padding: 5px 5px 10px 5px;">
              <DashboardTicketsByDurationPerTime
                :data="props.content.duration_per_time"
                :limit="5"
                v-on:action="openDialog"
              ></DashboardTicketsByDurationPerTime>
            </v-flex>
            <v-flex v-prms="{id: '4aa0482a'}" xs12 sm12 md12 lg12 style="padding: 5px 5px 10px 5px;">
              <v-card>
                <grupos-real-time v-if="props.content.networks" :grupos="props.content.networks" v-on:action="setNetwork"></grupos-real-time>
                <!-- <v-card-text style="padding: 0 16px;">
                  <h4 style="cursor: pointer;">
                    <v-icon style="height: 15px;"></v-icon>
                    <span>Minha Rede</span>
                    <v-btn v-if="api.params" outline small primary @click="setNetwork(false)">Voltar</v-btn>
                  </h4>
                </v-card-text> -->
                <span>{{setRefresh(props.refresh)}}</span>
              </v-card>
            </v-flex>
            <v-flex v-prms="{id: '4aa0482a'}" xs12 sm12 md12 lg12 style="padding: 5px 5px 10px 5px;">
              <tickets-by-teams :data="props.content.teams" :limit="5" v-on:action="openDialog"></tickets-by-teams>
            </v-flex>
            <v-flex v-prms="{id: '4aa0482a'}" xs12 sm12 md6 lg6 style="padding: 5px 5px 10px 5px;">
              <v-card>
                <v-card-text>
                  <h4 style="cursor: pointer;">
                    <v-icon style="height: 15px;"></v-icon>
                    <span>Chamados por Impacto</span>
                  </h4>
                  <div v-if="props.content.impact && props.content.impact.length > 0">
                    <apexchart width="100%" type="donut" :series="setSeries(props.content.impact, 'impacto')" :options="options['impacto']"></apexchart>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex v-prms="{id: '4aa0482a'}" xs12 sm12 md6 lg6 style="padding: 5px 5px 10px 5px;">
              <v-card>
                <v-card-text>
                  <h4 style="cursor: pointer;">
                    <v-icon style="height: 15px;"></v-icon>
                    <span>Chamados por Prioridade</span>
                  </h4>
                  <div v-if="props.content.urgency && props.content.urgency.length > 0">
                    <apexchart width="100%" type="donut" :series="setSeries(props.content.urgency, 'urgencia')" :options="options['urgencia']"></apexchart>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        <v-dialog v-model="dialog.active" scrollable max-width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
          <v-card>
            <v-toolbar dense primary>
              <v-toolbar-title>
                Detalhes
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon flat @click="dialog.active = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <tickets-by-status v-if="dialog.name == 'tickets-by-status'" :data="props.content.status"></tickets-by-status>
              <tickets-by-event-collaborator v-if="dialog.name == 'tickets-by-event-collaborator'" :data="props.content.collaborator_events"></tickets-by-event-collaborator>
              <tickets-by-teams v-if="dialog.name == 'tickets-by-teams'" :data="props.content.teams"></tickets-by-teams>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </view-detail>
    <v-snackbar v-model="snackbars.up.active" color="success" bottom right :timeout="20000">
      {{ snackbars.up.text }}
      <v-btn color="black" flat @click="snackbars.up.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbars.down.active" color="error" bottom right :timeout="20000">
      {{ snackbars.down.text }}
      <v-btn color="black" flat @click="snackbars.down.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbars.ticket.active" color="orange" bottom right :timeout="20000">
      {{ snackbars.ticket.text }}
      <v-btn color="black" flat @click="snackbars.ticket.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbars.device.active" color="blue" bottom right :timeout="20000">
      {{ snackbars.device.text }}
      <v-btn color="black" flat @click="snackbars.device.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
  import DashboardTicketsByDurationPerTime from '@/modules/service-desk/pages/Dashboard/DashboardTicketsByDurationPerTime'
  // import DashboardTicketsByExecutionPerTime from '@/modules/service-desk/pages/Dashboard/DashboardTicketsByExecutionPerTime'
  import TicketsByStatus from '@/modules/service-desk/pages/Dashboard/DashboardTicketsByStatus'
  import TicketsByEventCollaborator from '@/modules/service-desk/pages/Dashboard/DashboardTicketsByEventCollaborator'
  import TicketsByTeams from '@/modules/service-desk/pages/Dashboard/DashboardTicketsByTeams'
  import GruposRealTime from '@/modules/network/components/GruposRealTime'
  export default {
    name: 'ServiceDeskDashboard',
    data () {
      return {
        socket: {
          socket: null,
          status: window.Echo.connector.socket.connected
        },
        intervals: {
          update30s: null,
          update2_5m: null,
          update10m: null
        },
        refresh: null,
        api: {
          params: this.$route.query.network ? '?network_id=' + this.$route.query.network : '',
          show: true
        },
        networkId: this.$route.query.network || null,
        options: {},
        series: [],
        dialog: {
          active: false,
          name: null
        },
        snackbars: {
          up: {
            active: false,
            text: ''
          },
          down: {
            active: false,
            text: ''
          },
          ticket: {
            active: false,
            text: ''
          },
          device: {
            active: false,
            text: ''
          }
        },
        cacheExpiredAt: 0
      }
    },
    methods: {
      networkConnect: function () {
        window.Echo.connector.socket.on('connect', () => {
          this.socket.status = true
          clearInterval(this.intervals.update30s)
          setTimeout(() => {
            console.log('refresh')
            this.refresh()
          }, 1000)
        })
        window.Echo.connector.socket.on('disconnect', () => {
          this.socket.status = false
          this.socketOnDisconect()
        })
      },
      subscribeOnNetworkStatusChange: function () {
        window.Echo.private(this.$Auth.account().id + '.network.' + (this.networkId || 1))
        .listen('.status.changed', (response) => {
          setTimeout(() => {
            this.refresh()
          }, 1000)
          if (response.up) {
            this.snackbars.up.active = false
            this.snackbars.up.text = response.up
            this.snackbars.up.active = true
            // this.$makeSystemNotification('Dispositivos disponíveis', response.up, 'https://master.view.wises.tech/static/network/grupo/grupo-100.png')
          }
          if (response.down) {
            this.snackbars.down.active = false
            this.snackbars.down.text = response.down
            this.snackbars.down.active = true
            this.$makeSystemNotification('Dispositivos indisponíveis', response.down, 'https://master.view.wises.tech/static/network/grupo/grupo-020.png')
          }
          if (response.ticket) {
            this.snackbars.ticket.active = false
            this.snackbars.ticket.text = response.ticket
            this.snackbars.ticket.active = true
            // this.$makeSystemNotification('Chamado vinculado', response.ticket, 'https://master.view.wises.tech/static/systems/connect_view.png')
          }
          if (response.device) {
            this.snackbars.device.active = false
            this.snackbars.device.text = response.device
            this.snackbars.device.active = true
            // this.$makeSystemNotification('Dispositivo alterado', response.device, 'https://master.view.wises.tech/static/systems/connect_view.png')
          }
          clearInterval(this.intervals.update30s)
        })
      },
      unsubscribeOnNetworkStatusChange: function () {
        window.Echo.leave(this.$Auth.account().id + '.network.' + (this.networkId || 1))
      },
      socketOnDisconect: function () {
        // this.startIntervals()
        // this.$WiMakeError({error: {}, id: 107, title: 'Parece que você não está conectado ao servidor.', message: 'Não se preocupe, você consinuará sendo atualizado, somente não receberá notificações.'})
      },
      setNetwork: function (network) {
        if (network) {
          this.api.params = '?network_id=' + network.id
          this.networkId = network.id
          if (this.$route.query.network != network.id) {
            this.$router.push({name: 'service-desk-dashboard', query: {network: network.id}})
          }
        } else {
          this.api.params = null
          this.networkId = network.id
          if (Object.keys(this.$route.query).length != 0) {
            this.$router.push({name: 'service-desk-dashboard'})
          }
        }
        // setTimeout(() => {
        //   this.refresh()
        // }, 500)
        this.$forceUpdate()
      },
      setRefresh: function (refresh) {
        this.refresh = refresh
      },
      openDialog: function (dialogName) {
        this.dialog.name = dialogName
        this.dialog.active = true
      },
      setSeries: function (data, label) {
        var r = []
        var categories = []
        data.filter((item) => {
          r.push(item.total)
          categories.push(item['nome_' + label])
        })
        this.options[label] = {
          chart: {
            id: 'Chamados Por Impacto'
          },
          labels: []
        }
        this.options[label].labels = categories
        return r
      }
    },
    computed: {
      isConnected () {
        var status = window.Echo.connector.socket.connected
        return status
      }
    },
    created: function () {
      this.networkConnect()
      // this.startIntervals()
      this.subscribeOnNetworkStatusChange()
    },
    beforeDestroy: function () {
      // this.stopIntervals()
      this.unsubscribeOnNetworkStatusChange()
    },
    watch: {
      '$route' () {
        if (this.$route?.query?.network) {
          this.setNetwork({ id: this.$route.query.network })
        } else {
          this.setNetwork(false)
        }
      }
    },
    components: {
      TicketsByStatus,
      TicketsByEventCollaborator,
      TicketsByTeams,
      GruposRealTime,
      // DashboardTicketsByExecutionPerTime,
      DashboardTicketsByDurationPerTime
    }
  }
</script>