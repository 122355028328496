<template>
  <div>
    <v-toolbar dense :color="!dark ? 'primary' : 'dark'" style="color: white;">
      <v-btn  icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        Indisponibilidades
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :disabled="loading" icon @click="load({reset: false})"><v-icon style="color: white;">refresh</v-icon></v-btn>
    </v-toolbar>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <div style="width: 100%; text-align: center;">
            <span style="color: gray;">Exibir somente:</span><br>
            <v-btn-toggle v-model="status_indi" class="transparent">
              <v-btn value="0" >
                <v-icon color="red">arrow_downward</v-icon>
                Indisponíveis
              </v-btn>
              <v-btn value="1" >
                <v-icon color="green">arrow_upward</v-icon>
                Resolvidos
              </v-btn>
              <v-btn :value="false" >
                <v-icon color="primary">select_all</v-icon>
                Todos
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-flex>
        <v-flex sm12 v-if="!totalItens > 0 && !loading">
          <br>
          <v-alert :value="true" type="warning" >
            Nenhuma insidponibilidade encontrada!
          </v-alert>
        </v-flex>
        <v-flex sm12 v-if="!totalItens > 0 && loading">
          <div center style="width: 100%; text-align:center;">
            <br><br>
            <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
            <h1>Carregando...</h1>
          </div>
        </v-flex>
        <v-flex sm12 v-show="totalItens > 0">
          <div style="width: 100%; text-align: center;">
            <h3>{{totalItens}} indisponibilidades encontradas. Selecione para vincular a um chamado.</h3><br>
          </div>
          <v-data-table v-model="selected" :headers="headers" :items="content.data" :expand="expand" :pagination.sync="loadPagination" :loading="loading" select-all item-key="id" class="elevation-1" :rows-per-page-items="[5, 10, 50, 200]" :total-items="totalItens" rows-per-page-text="Itens por página">
            <template v-slot:headers="props">
              <tr class="device-events__header">
                <th>
                  <v-checkbox
                    :input-value="props.all"
                    :indeterminate="props.indeterminate"
                    color="orange"
                    hide-details
                    @click.stop="toggleAll(content.data)"
                  ></v-checkbox>
                </th>
                <th>
                  <span style="padding-left: 10px;">Status</span>
                </th>
                <!-- eslint-disable -->
                <th v-for="header in props.headers" :key="header.text" v-if="header.text" :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click="changeSort(header.value)">
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
            <template v-slot:items="props">
              <tr class="device-events__row" :active="props.selected" :style="{color: props.item.status_indi ? 'green' : (props.item.num_chamados > 0 ? 'blue' : 'red')}" style="cursor: pointer;">
                <td class="text-xs-center" @click="props.selected = !props.selected">
                  <v-checkbox :input-value="props.selected" color="orange" hide-details ></v-checkbox>
                </td>
                <td class="text-xs-center" @click="props.expanded = !props.expanded">
                  <v-icon :style="{color: props.item.status_indi ? 'green' : (props.item.num_chamados > 0 ? 'blue' : 'red')}">{{props.item.status_indi ? 'arrow_upward' : 'arrow_downward'}}</v-icon>
                </td>
                <td class="text-xs-center" @click="props.expanded = !props.expanded">
                  <strong>{{ props.item.desc_indi }}</strong>
                </td>
                <td class="text-xs-center" @click="props.expanded = !props.expanded">
                  <strong>{{ props.item.nome_disp }}</strong>
                </td>
                <td class="text-xs-center" @click="props.expanded = !props.expanded">
                  <span>{{ props.item.nome_grupo }}</span>
                </td>
                <!-- <td class="text-xs-center" @click="props.expanded = !props.expanded">{{ props.item.ip_disp }}</td> -->
                <td class="text-xs-center" @click="props.expanded = !props.expanded">{{ props.item.duracao_indi }}</td>
                <td class="text-xs-center" @click="props.expanded = !props.expanded">
                  {{ props.item.down_indi_format }}<br>
                  {{ props.item.up_indi_format || 'Atualmente' }}
                </td>
                <td class="text-center" @click="props.expanded = !props.expanded">
                  <center>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-img
                            width="25"
                            :src="'static/icons/monitor/' + props.item.type_info.icon + '.png'"
                          ></v-img>
                        </div>
                      </template>
                      <span>Evento criado a partir de um {{props.item.type_info.title}}.</span>
                    </v-tooltip>
                  </center>
                </td>
                <td class="text-xs-center" @click="props.expanded = !props.expanded">
                  <v-icon v-if="props.expanded">expand_less</v-icon>
                  <v-icon v-if="!props.expanded">expand_more</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:expand="props">
              <v-card flat>
                <v-divider></v-divider>
                <v-card-text style="text-align: left; font-size: 16px;">                  
                  <strong>Hierarquia: </strong>
                  <span v-for="(grupo, keyGrupo) in props.item.grupos">
                    <strong>{{keyGrupo ? '  <<  ' : ''}}</strong>
                    <span>{{ grupo.nome_grupo }}</span>
                  </span><br>
                  <strong>IP: </strong><span>{{ props.item.ip_disp }}</span><br>
                  <strong>Servidor: </strong><span>{{ props.item.network_server_name }}</span><br>
                  <strong v-if="props.item.num_chamados > 0">Indisponibilidade vinculada a {{props.item.num_chamados}} Chamado(s).</strong>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn @click="pingDialogAction(true, props.item.network_server_id, props.item.ip_disp)" outline ripple>
                    <v-icon color="blue lighten-1">invert_colors</v-icon>
                    <span>Pingar dispositivo</span>
                  </v-btn>
                  <v-btn @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: props.item.grupo_disp_id}})" outline ripple>
                    <v-icon color="blue lighten-1">device_hub</v-icon>
                    <span>Exibir topologia do dispositivo</span>
                  </v-btn>
                  <v-btn @click="$router.push({name: 'google-maps-grupos', params: {id: props.item.grupo_disp_id}})" outline ripple>
                    <v-icon color="blue lighten-1">maps</v-icon>
                    <span>Exibir dispositivo no mapa</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-divider></v-divider>
            </template>
            <template slot="pageText" slot-scope="props">
              Exibindo {{ props.pageStart }} até {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <v-footer v-if="selected && selected.length > 0" inset app class="elevation-24" :color="!dark ? 'primary' : 'dark'" style="height: 50px; color: white;">
      <div style="padding-left: 10px; padding-right: 10px;">
        <h3><strong>{{selected.length}}</strong> itens selecionados</h3>
      </div>
      <v-spacer></v-spacer>
      <div style="padding-left: 10px; padding-right: 10px;">
        <v-btn v-prms="{'id': '2c491b4c', mode: 'disabled'}" small outline color="white" @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: load, data: {indisponibilidades: selected, desc_chamado: TextoChamado(selected)}})" class="hidden-xs-only hidden-sm-only">
          <v-icon>add</v-icon>
          <span>Criar Chamado</span>
        </v-btn>
        <v-btn v-prms="{'id': '1a825af1', mode: 'disabled'}" small outline color="white" @click="dialogListChamados = true" class="hidden-xs-only hidden-sm-only">
          <v-icon>library_add</v-icon>
          <span style="padding-left: 5px;">Vincular a um Chamado</span>
        </v-btn>
        <div class="hidden-md-only hidden-lg-only hidden-xl-only">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn small outline color="white" v-on="on" >
                Opções
              </v-btn>
            </template>
            <v-list>
              <v-list-tile v-prms="{'id': '2c491b4c', mode: 'disabled'}" @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: load, data: {indisponibilidades: selected, desc_chamado: TextoChamado(selected)}})" >
                <v-list-tile-avatar>
                  <v-icon large>add</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>Criar Chamado</v-list-tile-title>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile v-prms="{'id': '1a825af1', mode: 'disabled'}" @click="dialogListChamados = true" >
                <v-list-tile-avatar>
                  <v-icon large>library_add</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>Vincular a um Chamado</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-footer>
    <div v-if="dialogListChamados">
      <v-dialog v-model="dialogListChamados" scrollable max-width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-toolbar dense><strong>Selecione um chamado</strong><v-spacer></v-spacer><v-btn icon @click="dialogListChamados = false"><v-icon>close</v-icon></v-btn></v-toolbar>
          <v-divider></v-divider>
          <v-card-text style="padding: 0;">
            <chamados mode="component" pageComponent="todos" v-on:action="selectChamado"></chamados>
          </v-card-text>
          <v-divider></v-divider>
          <v-toolbar dense>
            <strong style="padding-right: 5px;"> {{selected.length}} </strong> indisponibilidades selecionadas
          </v-toolbar>
        </v-card>
      </v-dialog>
    </div>
    <ping-dialog v-if="pingDialog.active" :network-server-id="pingDialog.server" :host-to-ping="pingDialog.host" :active="pingDialog.active" v-on:active="pingDialogAction"></ping-dialog>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import Chamados from '@/modules/service-desk/pages/Chamados/Chamados'
  import PingDialog from '@/modules/network/pages/NetworkServer/Dialogs/PingDialog'
  export default {
    name: 'DeviceEvents',
    data () {
      return {
        pingDialog: {
          active: false,
          host: null,
          server: null
        },
        dialogListChamados: false,
        content: {},
        params: {},
        pagination: {
          sortBy: 'id'
        },
        totalItens: 0,
        selected: [],
        headers: [
          { align: 'center' },
          { text: 'Descrição', align: 'left', value: 'desc_indi' },
          { text: 'Dispositivo', align: 'left', value: 'nome_disp' },
          { text: 'Rede Principal', align: 'left', value: 'nome_disp' },
          // { text: 'IP', value: 'ip_disp' },
          { text: 'Duração', value: 'duracao_indi' },
          { text: 'Início/Fim', value: 'down_indi_format' },
          { text: 'Tipo', align: 'left', value: 'type' },
          { text: '', value: 'network_server_status' }
        ],
        expand: false,
        loading: false,
        toggle_multiple: this.$route.query.status_indi === 0 || this.$route.query.status_indi === 1 ? '' + this.$route.query.status_indi + '' : false
      }
    },
    methods: {
      pingDialogAction: function (active = false, server = null, host = null) {
        this.pingDialog.host = host
        this.pingDialog.server = server
        this.pingDialog.active = active
      },
      pingIsAvaiable: function (device) {
        return device.network_server_status && device.network_server_last_interaction_in_minutes < 5
      },
      load: function ({reset}) {
        if (reset) {
          this.content = {}
        }
        var params = {}
        if (this.params) {
          for (var param in this.params) {
            params[param] = this.params[param]
          }
        }
        if (this.toggle_multiple) {
          params['orWhere'] = 'status_indi,' + this.toggle_multiple
        }
        this.loading = true
        callApi.get({uri: 'disp/indisponivilidade', params: params, sucess: this.loadSucess, error: this.loadError})
      },
      loadSucess: function (data) {
        this.totalItens = data.data.total
        this.content = data.data
        this.loading = false
      },
      loadError: function (error) {
        this.loading = false
        this.$WiMakeError({error: error, id: 106, title: 'Oops... Erro ao buscar indisponibilidades.'})
      },
      toggleAll (dados) {
        if (this.selected.length) {
          this.selected = []
        } else {
          this.selected = dados.slice()
        }
      },
      changeSort (column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      },
      setRoute: function () {
        var query = ''
        var complement = ''
        var count = 0
        for (var param in this.$route.query) {
          if (param !== 'status_indi') {
            if (count) {
              query += ';'
              complement += ','
            }
            query += param + ',' + this.$route.query[param]
            complement += param
            count++
          }
        }
        this.params['where'] = query
        this.params['complement'] = complement
        this.load({reset: true})
      },
      TextoChamado: function (selected) {
        var text = ''
        selected.filter((item, key) => {
          if (key) {
            text += ', \n'
          }
          text += item.nome_disp
          text += ' em ' + item.grupos[0].nome_grupo + ' / ' + item.grupos[1].nome_grupo
          text += item.status_indi > 0 ? ' está (Disponível)' : ' está (Indisponível)'
        })
        return text
      },
      selectChamado: function (chamado) {
        this.$swal({
          title: 'Deseja realmente vincular ao chamado #' + chamado.id + '?',
          text: 'Esta ação só poderá ser desfeita manualmente!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Vincular!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            var indis = []
            this.selected.filter((indi) => {
              indis.push({chamado_dados_id: chamado.id, disp_indisponibilidade_id: indi.id, disp_dados_id: indi.disp_dados_id})
            })
            callApi.post({
              uri: 'chamado/indisponibilidade',
              data: {chamado_dados_id: chamado.id, multiple: indis},
              msgLoad: {title: 'Vinculando, por favor aguarde.'},
              sucess: () => {
                this.load({reset: false})
                this.dialogListChamados = false
              },
              msgSucess: {title: 'Indisponibilidades Vinculadas!'},
              error: (error) => {
                this.$WiMakeError({error: error, id: 206, title: 'Oops, Erro ao vincular indisponibilidades.'})
              },
              msgError: true
            })
          }
        })
      }
    },
    computed: {
      loadPagination: {
        get: function () {
          return this.pagination
        },
        set: function (newValue) {
          var order = newValue.descending ? ',desc' : ',asc'
          this.pagination = newValue
          this.params['order'] = newValue.sortBy !== null ? newValue.sortBy + order : 'id,desc'
          this.params['page'] = newValue.page
          this.params['limit'] = newValue.rowsPerPage
          this.load({reset: false})
        }
      },
      pages () {
        if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
          return 0
        }
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.auth || {}
      },
      status_indi: {
        get: function () {
          return this.toggle_multiple
        },
        set: function (value) {
          this.toggle_multiple = value
          this.load({reset: false})
        }
      }
    },
    created: function () {
      var query = ''
      var complement = ''
      var count = 0
      for (var param in this.$route.query) {
        if (param !== 'status_indi') {
          if (count) {
            query += ';'
            complement += ','
          }
          query += param + ',' + this.$route.query[param]
          complement += param
          count++
        }
      }
      this.params['where'] = query
      this.params['complement'] = complement
      this.pagination.descending = true
    },
    watch: {
      '$route': 'setRoute'
    },
    components: {
      Chamados,
      PingDialog
    }
  }
</script>
<style>
  @media only screen and (max-width: 960px) {
    .container.grid-list-xl .layout .flex {
      padding: 0px !important;
    }
  }
  .permission_disabled {
    color: rgba(0,0,0,.26)!important;
  }
  .device-events__row td {
    padding: 0 10px !important;
  }
  .device-events__header th {
    padding: 0 10px !important;
  }
  .device-events__row[active="true"] {
    background: rgb(255, 208, 120);
  }
</style>
