<template>
  <v-card>
    <!-- eslint-disable -->
    <v-list dense style="max-height: none !important;">
      <v-list-tile v-ripple avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <h4 @click="$emit('action', 'tickets-by-teams')" style="cursor: pointer;">
              <v-icon style="height: 15px;"></v-icon>
              <span>Chamados por Equipe</span>
            </h4>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action style="text-align: center; padding: 0 5px;">
          <v-list-tile-sub-title style="font-size:12px;">
            <span>Chamados</span>
          </v-list-tile-sub-title>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile @click="$router.push({name: 'service-desk-list', params: {page: 'ticket-opened'}, query: {team: team.id}})" v-ripple avatar v-for="(team, teamKey) in data" :key="teamKey" v-if="!limit || teamKey < limit">
        <v-list-tile-avatar size="45">
          <v-avatar >
            <img src="static/icons/monitor/management_96.png" alt="Equipe Responsável">
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title style="font-size:18px;">
            <span>{{team.name}}</span>
          </v-list-tile-title>
          <v-list-tile-sub-title style="font-size: 12px;">
            <span>{{team.description}}</span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action style="padding: 0 5px;">
          <v-list-tile-title style="text-align: center; font-size:24px;">
            <span>{{team.tickets}}</span>
          </v-list-tile-title>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-card>    
</template>
<script>
  export default {
    name: 'ServiceDeskDashboardTicketsByTeams',
    props: ['data', 'limit']
  }
</script>