<template>
  <div>
    <v-toolbar dense>
      <v-toolbar-title>
        <v-btn flat @click="$router.go(-1)" icon>
          <v-icon color="gray darken-2">arrow_back</v-icon>
        </v-btn>
        <span v-if="dados && dados.nome_grupo">{{dados.nome_grupo}}</span>
        <span style="padding-left: 10px;">
          <span v-if="editMode" class="edit_mode">Modo de edição</span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-prms="{'id': '3c8cd4ef'}"  class="hidden-sm-and-down">
        <v-btn v-if="guposEditados.length > 0" flat @click="updateApi()"><v-icon color="gray darken-2">save</v-icon><span style="padding-left: 5px;">Salvar {{guposEditados.length}} Modificações</span></v-btn>
        <v-btn v-if="!editMode" flat @click="enableEditMode()"><v-icon color="gray darken-2">edit</v-icon><span style="padding-left: 5px;">Editar Mapa</span></v-btn>
        <v-btn v-else flat @click="enableEditMode()"><v-icon color="gray darken-2">close</v-icon> Fechar Edição</v-btn>
      </v-toolbar-items>
      <v-btn v-if="!editMode" flat @click="$router.push({name: 'grupo-mapa-grupos', params: {id: $route.params.id} })" class="hidden-sm-and-down"><v-icon color="gray darken-2">show_chart</v-icon><span style="padding-left: 5px;">Topologia</span></v-btn>
      <v-btn v-if="!editMode" icon @click="$router.push({name: 'grupo-mapa-grupos', params: {id: $route.params.id} })" class="hidden-md-and-up"><v-icon color="gray darken-2">show_chart</v-icon></v-btn>
      <v-btn @click="loadApi()" icon :disabled="editMode"><v-icon color="gray darken-2">refresh</v-icon></v-btn>
    </v-toolbar>
    <v-card style="padding: 10px 10px 10px 10px;">
      <div class="google-map" :id="MapName" :style="{height: (innerHeight - 130) + 'px'}"></div>
    </v-card>
    <v-dialog v-model="grupoInfo" scrollable max-width="400px">
      <grupo-info :grupo="grupoDetalhes">
        <span slot="action">
          <v-btn icon @click="grupoInfo = false"><v-icon>close</v-icon></v-btn>
        </span>
      </grupo-info>
    </v-dialog>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import GoogleMapsStyleDark from '@/default/components/GoogleMaps/GoogleMapsStyleDark'
  import GoogleMapsStyleLight from '@/default/components/GoogleMaps/GoogleMapsStyleLight'
  import GrupoInfo from '@/modules/network/pages/Grupo/GrupoInfo'
  // eslint-disable-next-line
  export default {
    name: 'Google_Maps_Markers_v0-0-1',
    data () {
      return {
        apiError: false,
        dados: {},
        editMode: false,
        MapName: 'GoogleMaps-' + this.mapName || 'GoogleMapsMapaDefault',
        mapLatLngZoom: {},
        map: null,
        markers: [],
        polylines: [],
        countExec: 0,
        grupoInfo: false,
        grupoDetalhes: {},
        idsEditados: [],
        guposEditados: [],
        innerHeight: window.innerHeight,
        interval: null
      }
    },
    methods: {
      loadApi: function () {
        callApi.get({uri: this.apiUrl, params: this.apiParams || null, sucess: this.loadSucess, error: this.loadError})
      },
      loadSucess: function (data) {
        this.apiError = false
        this.dados = data.data
        this.clearMarkersPolyline()
        this.mapLatLngZoom['lat'] = data.data.latitude_grupo || -20.1467258
        this.mapLatLngZoom['lng'] = data.data.longitude_grupo || -45.1213433
        this.mapLatLngZoom['zoom'] = data.data.zoom_mapa || 12
        this.loadGoogleMaps()
        if (data.data.grupos.length > 0) {
          this.createMarkers(data.data.grupos)
          this.createPolyline(data.data.conexoes)
        }
        this.countExec++
        this.$swal.close()
      },
      loadError: function (error) {
        if (!this.apiError) {
          this.$WiMakeError({error: error, id: 108, title: 'Oops... Erro ao buscar informações do mapa.'})
        }
        this.apiError = true
      },
      createMarkers: function (grupos) {
        grupos.forEach((grupo) => {
          const icon = grupo.num_disp > 0 ? '/assets/img/icons/maps/grupo' + grupo.status.cod_status.replace('#', '-') + '.png' : '/assets/img/icons/maps/grupo-000.png'
          // eslint-disable-next-line
          const marker = new google.maps.Marker({
            // eslint-disable-next-line
            position: new google.maps.LatLng(grupo.latitude_grupo || -20.1467258, grupo.longitude_grupo || -45.1213433),
            map: this.map,
            title: grupo.nome_grupo,
            label: grupo.nome_grupo,
            draggable: false,
            // eslint-disable-next-line
            icon: { url: this.url + icon, scaledSize: new google.maps.Size(50, 50), origin: new google.maps.Point(0,0), anchor: new google.maps.Point(25, 50) // anchor
            }
          })
          if (grupo.status === 0) {
            // eslint-disable-next-line
            marker.setAnimation(google.maps.Animation.BOUNCE)
          }
          if (this.countExec < 2) {
            // eslint-disable-next-line
            marker.setAnimation(google.maps.Animation.DROP)
          }
          marker.addListener('click', () => {
            this.markerClick(grupo, marker)
          })
          marker.addListener('dblclick', () => {
            console.log('clicou')
            if (!this.editMode) {
              if (grupo.grupo_tipo_id < 4) {
                this.$router.push({ name: 'google-maps-grupos', params: {id: grupo.id} })
              } else {
                this.$router.push({ name: 'grupo-mapa-dispositivos', params: {id: grupo.id} })
              }
            }
          })
          marker.addListener('dragend', () => {
            var g = grupo
            g.latitude_grupo = marker.getPosition().lat()
            g.longitude_grupo = marker.getPosition().lng()
            this.markerDragEnd(g)
          })
          this.markers.push({marker: marker, grupo: grupo})
        })
      },
      createPolyline: function (conexoes) {
        conexoes.forEach((conexao) => {
          // eslint-disable-next-line
          const flightPath = new google.maps.Polyline({
            path: [
            {lat: conexao.to_latitude, lng: conexao.to_longitude},
            {lat: conexao.from_latitude, lng: conexao.from_longitude}
            ],
            geodesic: true,
            strokeColor: '#ffd600',
            strokeOpacity: 1.0,
            strokeWeight: 4
          })
          flightPath.setMap(this.map)
          this.polylines.push(flightPath)
        })
      },
      loadGoogleMaps: function () {
        if (this.countExec < 1) {
          const element = document.getElementById(this.MapName)
          const options = {
            zoom: this.mapLatLngZoom.zoom,
            // eslint-disable-next-line
            center: new google.maps.LatLng(this.mapLatLngZoom.lat, this.mapLatLngZoom.lng),
            mapTypeId: 'satellite',
            mapTypeControlOptions: {
              mapTypeIds: ['roadmap', 'satellite', 'terrain', 'style_dark', 'style_light']
            }
          }
          // eslint-disable-next-line
          this.map = new google.maps.Map(element, options)
          this.map.addListener('dragend', () => {
            if (this.editMode) {
              var grupo = this.dados
              grupo.latitude_grupo = this.map.center.lat()
              grupo.longitude_grupo = this.map.center.lng()
              grupo.zoom_mapa = this.map.zoom
              console.log(grupo)
              console.log(this.map.center)
              this.markerDragEnd(grupo)
            }
            console.log(this.map.zoom)
          })
          // eslint-disable-next-line
          var styleDark = new google.maps.StyledMapType(GoogleMapsStyleDark.style, {name: 'CView Dark'})
          // eslint-disable-next-line
          var styleLight = new google.maps.StyledMapType(GoogleMapsStyleLight.style, {name: 'CView Ligth'})
          this.map.mapTypes.set('style_dark', styleDark)
          this.map.mapTypes.set('style_light', styleLight)
        }
      },
      markerClick: function (grupo, marker) {
        console.log(marker)
        if (!this.editMode) {
          this.grupoDetalhes = grupo
          this.grupoInfo = true
        }
      },
      markerDragEnd: function (grupo) {
        if (this.guposEditados.length > 0) {
          var index = this.idsEditados.indexOf(grupo.id)
          console.log(index)
          if (index > -1) {
            this.guposEditados[index] = grupo
          } else {
            this.idsEditados.push(grupo.id)
            this.guposEditados.push(grupo)
          }
        } else {
          this.idsEditados.push(grupo.id)
          this.guposEditados.push(grupo)
        }
        console.log(grupo)
      },
      loading: function (title, text) {
        this.$swal({
          title: title || 'Carregando...',
          text: text || 'Aguarde até que a ação seja finalizada.',
          onOpen: () => {
            this.$swal.showLoading()
          }
        })
      },
      clearMarkersPolyline: function () {
        this.markers.forEach((m, k) => {
          this.markers[k].marker.setMap(null)
        })
        this.polylines.forEach((p, k) => {
          this.polylines[k].setMap(null)
        })
      },
      enableEditMode: function () {
        this.editMode = !this.editMode
        if (this.editMode) {
          this.markers.forEach((m, k) => {
            this.markers[k].marker.setOptions({draggable: true})
          })
        } else {
          this.markers.forEach((m, k) => {
            this.markers[k].marker.setOptions({draggable: false})
          })
          this.guposEditados = []
          this.idsEditados = []
        }
      },
      changeRouter: function (dados) {
        console.log(dados)
        this.loading()
        this.countExec = 0
        this.loadApi()
      },
      updateApi: function () {
        if (this.guposEditados.length > 0) {
          callApi.put({
            uri: 'grupo/dados',
            id: 'multiple',
            data: this.guposEditados,
            sucess: () => {
              this.apiError = false
              this.countExec = 0
              this.loadApi()
              this.guposEditados = []
              this.idsEditados = []
              this.editMode = false
            },
            error: (error) => {
              this.$WiMakeError({error: error, id: 308, title: 'Oops... Erro ao atualizar este mapa.'})
            },
            msgLoad: true,
            msgSucess: true
          })
        }
      },
      getPosition: function () {
        // console.log(this.markers[0].getPosition().lat() + ' - ' + this.markers[0].getPosition().lng())
        console.log(this.map.center.lat())
      },
      refreshLoad: function () {
        if (!this.editMode) {
          this.loadApi()
        }
      },
      refresh: function () {
        this.interval = setInterval(() => {
          this.refreshLoad()
        }, 15000)
      }
    },
    created: function () {
      this.loading()
      this.countExec = 0
      this.loadApi()
      console.log(window.innerHeight)
      this.refresh()
    },
    beforeDestroy: function () {
      clearInterval(this.interval)
      console.log('parando o interval')
    },
    watch: {
      '$route': 'changeRouter'
    },
    computed: {
      url () {
        const url = this.$store.state.url
        return url.baseDefault
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    props: ['mapName', 'apiUrl', 'apiParams', 'timeRefresh'],
    components: {
      GrupoInfo
    }
  }
</script>
<style scoped>
.google-map {
  width: 100%;
  margin: 0 auto;
  background: gray;
}
.edit_mode {
  border-style: solid; border-width: 1px; border-color: orange; border-radius: 10px; color: gray; background: yellow; padding: 1px 10px 1px 5px;
}
</style>