function stock (params, title) {
  return {
    id: {
      id: '9f35f528',
      create: 'f39401b4',
      update: 'cb0de42e',
      delete: 'd227e21a'
    },
    list: true,
    title: {
      list: 'Estoques ' + title,
      edit: {
        insert: 'Adicionando Novo Estoque',
        update: 'Editando Estoque'
      }
    },
    params: params,
    icon: 'my_location',
    search: {
      placeholder: 'Pesquise pelo ID ou nome do Estoque',
      fields: ['id', 'name', 'responsible.name']
    },
    size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
    components: {
      list: {
        tableRow: () => import('@/modules/stock/pages/Stock/Stocks')
      }
    },
    apiUrl: 'stock',
    fields: [
      {
        text: 'Informe a empresa responsável, nome, e se é o estoque principal.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'id',
        text: 'ID',
        type: 'int',
        list: true,
        search: true
      },
      {
        value: 'company_id',
        text: 'Empresa Matriz ou Filial',
        type: 'db-autocomplete',
        autocomplete: true,
        required: true,
        list: false,
        edit: true,
        apiUrl: 'register/person/company',
        apiParams: {
          fields: 'id,name',
          order: 'name,asc'
        },
        show: 'name',
        size: ['xs12', 'sm12', 'md3', 'lg3']
      },
      {
        value: 'name',
        text: 'Nome do Estoque',
        type: 'text',
        list: true,
        edit: true,
        search: true,
        required: true,
        size: form => { return form.type == 2 ? ['xs12', 'sm12', 'md7', 'lg7'] : ['xs12', 'sm12', 'md3', 'lg3'] }
      },
      {
        value: 'id',
        text: 'Opções',
        list: true
      },
      {
        value: 'type',
        text: 'Tipo de estoque',
        align: 'center',
        type: 'select',
        edit: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        options: [
          {value: 1, text: 'Estoque Físico'},
          {value: 2, text: 'Estoque virtual'},
          {value: 3, text: 'Estoque do Colaborador'},
          {value: 4, text: 'Estoque do Cliente'}
        ]
      },
      {
        value: 'is_master',
        text: 'Estoque principal?',
        align: 'center',
        type: 'select',
        edit: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        options: [{value: true, text: 'Sim'}, {value: false, text: 'Não'}],
        showField: (form) => { return form.type == 1 }
      },
      {
        value: 'public',
        text: 'Opções avançadas',
        required: false,
        size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
        type: 'switch',
        edit: true,
        showField: (form) => { return form.type == 1 }
      },
      {
        value: 'responsible_id',
        text: 'Colaborador Responsável',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        type: 'db-autocomplete',
        edit: true,
        apiUrl: 'register/person/collaborator',
        apiParams: { fields: 'id,name', order: 'name,asc' },
        show: 'name',
        showField: (form) => { return form.type == 3 }
      },
      {
        value: 'responsible_id',
        text: 'Cliente Responsável',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        type: 'db-autocomplete',
        edit: true,
        apiUrl: 'register/person/customer',
        apiParams: { fields: 'id,name', order: 'name,asc' },
        show: 'name',
        showField: (form) => { return form.type == 4 }
      },
      {
        text: 'Dados de localização do estoque.',
        type: 'subheader',
        edit: true,
        showField: (form) => { return form.public }
      },
      {value: 'zip_code', text: 'CEP', type: 'text', size: ['xs12', 'sm12', 'md2', 'lg2'], mask: '#####-###', required: true, edit: true, showField: (form) => { return form.public }},
      {value: 'address', text: 'Endereço', type: 'text', size: ['xs12', 'sm12', 'md8', 'lg8'], required: true, edit: true, showField: (form) => { return form.public }},
      {value: 'number', text: 'Número', type: 'text', size: ['xs12', 'sm12', 'md2', 'lg2'], required: true, edit: true, showField: (form) => { return form.public }},
      {value: 'district', text: 'Bairro', type: 'text', size: ['xs12', 'sm12', 'md4', 'lg4'], required: true, edit: true, showField: (form) => { return form.public }},
      {value: 'complement', text: 'Complemento', type: 'text', size: ['xs12', 'sm12', 'md3', 'lg3'], edit: true, showField: (form) => { return form.public }},
      {value: 'city_id', text: 'Cidade', required: true, type: 'db-autocomplete', size: ['xs12', 'sm12', 'md5', 'lg5'], edit: true, autocomplete: true, apiUrl: 'register/city', apiParams: {limit: 10000, order: 'name,asc', fields: 'id,name,state_id'}, show: 'name', showField: (form) => { return form.public }},
      {
        text: 'Descrição opcional deste estoque.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'description',
        text: 'Descrição',
        type: 'textarea',
        edit: true
      }
    ]
  }
}

export default {
  stock: stock({}, 'Individuais'),
  physical: stock({ where: 'type,1' }, 'Físicos'),
  virtual: stock({ where: 'type,2' }, 'Virtuais'),
  collaborator: stock({ where: 'type,3' }, 'de Colaboradores'),
  customer: stock({ where: 'type,4' }, 'de Clientes')
}
