<template>
  <v-card >
    <v-list class="diagnostic-list-dialog" dense>
      <v-list-tile avatar @click="openListDialog(false)">
        <v-list-tile-avatar>
          <v-icon large>{{ icon }}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title> {{ title }} </v-list-tile-sub-title>
          <v-list-tile-title>
            <strong v-if="data">
              {{ data | stringlength(100) }}
            </strong>
            <span v-else>
              {{ placeholder }}
            </span>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action style="padding-top: 0 !important; padding-bottom: 0 !important;">
          <v-btn icon>
            <v-icon>{{data ? 'edit' : 'search'}}</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    name: 'DiagnosticListDialog',
    methods: {
      mountedOpenDialog: function () {
        if (this.openInMounted) {
          setTimeout(() => {
            this.openListDialog(true)
          }, 500)
        }
      },
      openListDialog: function (dataOneSelect) {
        this.$WiListDialog({
          wiConfig: this.wiConfig,
          callback: this.calback,
          filter: this.filter, 
          mode: 'select',
          dataOneSelect
        })
      },
      calback: function (data) {
        if (data) {
          this.clearData()
          setTimeout(() => {
            this.$emit('selected', {
              field: this.selected,
              data
            })
          }, 100)
        }
      },
      clearData: function () {
        this.$emit('selected', { field: this.selected, data: null })
      }
    },
    mounted: function () {
      this.mountedOpenDialog()
    },
    beforeDestroy: function () {
      // this.clearData()
    },
    props: {
      title: {
        type: String,
        required: true
      },
      placeholder: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        default: 'category'
      },
      data: {
        type: String,
        default: null
      },
      filter: {
        type: Object,
        default: () => ({})
      },
      wiConfig: {
        type: String,
        required: true
      },
      selected: {
        type: String,
        required: true
      },
      openInMounted: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped>
  .diagnostic-list-dialog {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>