<template>
  <div>
    <v-toolbar v-if="person.negatived_count && person.negatived_count > 0" dense flat color="black" class="white--text">
      <v-toolbar-title>
        Negativado
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small outline color="white" @click="listFinancesNegatived()">
        <v-icon>list</v-icon>
        <span> Listar</span>
      </v-btn>
    </v-toolbar>
    <v-layout row wrap text-xs-center text-sm-center text-md-left text-lg-left style="padding: 15px 0 15px 0">
      <v-flex v-if="person.file && person.file.url" @click="setPersonProfileImage()" xs12 sm12 md2 lg2 style="text-align: center !important; padding: 0px 20px 0 20px;">
        <img style="width: 100%; cursor: pointer; max-width: 180px;" :src="person.file.url" alt="Perfil da Pessoa">
      </v-flex>
      <v-flex v-else-if="person.status && person.type === 'Fisica'" @click="setPersonProfileImage()" xs12 sm12 md2 lg2 style="text-align: center !important; padding: 0px 20px 0 20px;">
        <img v-if="person.image" style="width: 100%; cursor: pointer; max-width: 120px;" :src="'static/profile/' + person.sex + '-' + person.status + '.png'" alt="Perfil da Pessoa">
        <img v-else style="width: 100%; cursor: pointer; max-width: 120px;" :src="'static/profile/' + person.sex + '-' + person.status + '.png'" alt="Perfil da Pessoa">
      </v-flex>
      <v-flex v-else-if="person.status" @click="setPersonProfileImage()" xs12 sm12 md2 lg2 style="padding: 0px 20px 0 20px; text-align: center !important;">
        <img v-if="person.status == 'active'" style="width: 100%; cursor: pointer; max-width: 120px;" src="static/profile/juridica-ativo.png" alt="Perfil da Pessoa">
        <img v-else style="width: 100%; cursor: pointer; max-width: 120px;" src="static/profile/juridica-inativo.png" alt="Perfil da Pessoa">
      </v-flex>
      <v-flex v-if="!person.status && !person.file" @click="setPersonProfileImage()" xs12 sm12 md2 lg2 style="padding: 0px 20px 0 20px; text-align: center !important;">
        <img v-if="person.type === 'Fisica'" style="width: 100%; cursor: pointer; max-width: 120px;" :src="'static/profile/' + person.sex + '-active.png'" alt="Perfil da Pessoa">
        <img v-else style="width: 100%; cursor: pointer; max-width: 120px;" src="static/profile/juridica-ativo.png" alt="Perfil da Pessoa">
      </v-flex>
      <v-flex  xs12 sm12 md5 lg5 text-xs-center text-md-left style="margin-top: -2px;">
        <v-layout text-xs-center text-md-left row wrap style="padding: 0px 15px 5px 15px;">
          <v-flex xs12 text-xs-center text-md-left>
            <h3 class="headline">
              <span v-if="person.name">{{ person.name }}</span>
            </h3>
            <div class="blue--text" v-if="person.first_name">
              ({{person.first_name}})
              <span v-if="person.commission == 1" class="person-detail__commission">Comissão</span>
            </div>
            <div class="blue--text subheading font-weight-bold">
              <label v-if="person && person.equipes" style="font-size: 15px !important;">{{person.equipes}}</label>
            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout text-xs-center text-md-left row wrap v-if="person.type === 'Fisica'" style="padding: 5px 15px 0px 15px;">
          <v-flex xs12 text-xs-center text-md-left>
            <strong>{{$WiLangText('register.page.PersonDetail.cpf', 'CPF')}}: </strong> {{person.cpf_cnpj || '- - -'}}
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Nascimento: </strong> {{ person.birthday_format || '- - -' }} 
            <span v-if="person.ages">({{ person.ages }} anos)</span>
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>{{$WiLangText('register.page.PersonDetail.rg', 'RG')}}: </strong> {{person.rg || '- - -'}}
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Cadastro: </strong> {{ person.created_at | moment('DD/MM/YYYY HH:mm') }}
          </v-flex>
          <v-flex v-if="person.integration_code" xs12 text-xs-center text-md-left>
            <strong>Integração: </strong> {{ person.integration_code || '- - -' }}
          </v-flex>
          <v-flex v-if="person.customersCount" xs12 text-xs-center text-md-left>
            <strong>Clientes: </strong> {{ person.customersCount }}
            <v-btn small outline @click="listCollaboratorCustomers()">
              <v-icon>list</v-icon>
              <span>Ver</span>
            </v-btn>
            <v-btn
              v-if="$WiCheckPermission('734a4cf7')"
              small
              outline
              @click="migrateCollaboratorCustomers()"
            >
              <v-icon>sync</v-icon>
              <span>Migrar</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout text-xs-center text-md-left row wrap v-else style="padding: 5px 15px 15px 15px;">
          <v-flex xs12 text-xs-center text-md-left>
            <strong>{{$WiLangText('register.page.PersonDetail.cnpj', 'CNPJ')}}: </strong> {{person.cpf_cnpj || '- - -'}}
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Aniversário: </strong> {{ person.birthday_format || '- - -' }} 
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Insc. Estadual: </strong> {{person.state_number || '- - -'}}
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Insc. Municipal: </strong> {{person.city_number || '- - -'}}
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Site: </strong> <a target="_blank" :href="person.site">{{person.site || '- - -'}}</a>
          </v-flex>
          <v-flex xs12 text-xs-center text-md-left>
            <strong>Cadastro: </strong> {{ person.created_at | moment('DD/MM/YYYY HH:mm') }}
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="person.type === 'Fisica'" xs12 sm12 md5 lg5 text-xs-center text-md-left style="margin-top: -5px;">
        <v-layout text-xs-center text-md-left row wrap style="padding: 5px 15px 0px 15px;">
          <v-flex v-if="person.mothers_name" xs12 text-xs-center text-md-left>
            <strong>Nome da mãe: </strong> {{ person.mothers_name }}
          </v-flex>
          <v-flex v-if="person.fathers_name" xs12 text-xs-center text-md-left>
            <strong>Nome do pai: </strong> {{ person.fathers_name }}
          </v-flex>
          <v-flex v-if="person.companions_name" xs12 text-xs-center text-md-left>
            <strong>Acompanhante/Responsável: </strong> {{ person.companions_name }} 
          </v-flex>
          <v-flex v-if="person.profession" xs12 text-xs-center text-md-left>
            <strong>Profissão: </strong> {{ person.profession }}
          </v-flex>
          <v-flex v-if="person.marital_status" xs12 text-xs-center text-md-left>
            <strong>Estado Civil: </strong> {{ person.marital_status }}
          </v-flex>
          <v-flex v-if="person.education" xs12 text-xs-center text-md-left>
            <strong>Escolaridade: </strong> {{ person.education }} 
          </v-flex>
          <v-flex v-if="person.naturalness" xs12 text-xs-center text-md-left>
            <strong>Naturalidade: </strong> {{ person.naturalness }}
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    name: 'PessoaInfo',
    data () {
      return {
        store: null
      }
    },
    methods: {
      listFinancesNegatived: function () {
        this.$WiListDialog({
          wiConfig: 'finance-in-list',
          filter: {
            where: 'negatived,1',
            person_id: this.person.id
          },
          wiEditData: {
            person_id: this.person.id
          }
        })
      },
      listCollaboratorCustomers: function () {
        this.$WiListDialog({
          wiConfig: 'customer',
          filter: {
            where: 'responsible_id,' + this.person.id
          }
        })
      },
      migrateCollaboratorCustomers: function () {
        this.$WiEditDialog({
          wiConfig: 'collaborator-responsible-migrate',
          data: {
            responsible_id_origin: this.person.id
          },
          onSubmit: this.refresh
        })
      },
      setPersonProfileImage () {
        this.$WiEditDialog({
          wiConfig: 'person-profile-image',
          data: {
            id: this.person.id,
            name: this.person.name,
            file: this.person.file,
            file_id: this.person.file_id,
            first_name: this.person.first_name
          },
          onSubmit: this.refresh
        })
      }
    },
    created: function () {
      this.store = this.$route.name
    },
    props: ['person', 'refresh']
  }
</script>
<style>
  .person-detail__commission {
    padding: 2px 10px;
    color: red;
    border-style: solid;
    border-color: red;
    border-width: 1px;
  }
</style>