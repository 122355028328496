<template>
  <view-detail title="Tipos de Rede/Hierarquia" apiUrl="network/type" apiParams="?limit=1000&order=nivel_tipo,asc&withCount=networks" :notGetID="true">
    <template slot-scope="{ content, refresh }">
      <!-- eslint-disable -->
      <v-container align="center">
        <v-layout row wrap>
          <v-flex xs12 sm6 md6 lg6 d-flex style="padding: 10px;">
            <v-card>
              <AppBar @refresh="refresh"></AppBar>
              <v-divider></v-divider>
              <List
                :networkTypes="content.data || []"
                :selected="selected"
                @select="select"
              ></List>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 md6 lg6 d-flex text-xs-center style="padding: 10px;">
            <v-scroll-y-transition mode="out-in">
              <div
                v-if="!selected"
                class="title grey--text text--lighten-1 font-weight-light"
                style="align-self: center;"
                v-text="'Selecione um tipo de rede...'"
              ></div>
              <Selected
                v-else
                :key="selected.id"
                :networkType="selected"
                @refresh="refresh"
                @clear="selected = null"
              ></Selected>
            </v-scroll-y-transition>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import AppBar from './AppBar'
  import List from './List'
  import Selected from './Selected'
  export default {
    name: 'NetworkTypes',
    data: () => ({
      selected: null
    }),
    methods: {
      select: function (type) {
        this.selected = type
      }
    },
    components: {
      List,
      AppBar,
      Selected
    }
  }
</script>