import mutations from './mutations'
import systemConfig from '@/default/config'
import system from '@/default/store/modules/system/system'

var state = {}
system.state.modules.filter((mod) => {
  state = Object.assign(systemConfig, mod.config)
})

export default {
  namespaced: true,
  state,
  mutations
}
