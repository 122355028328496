<template>
  <wi-view index="task" title="Calendário" apiUrl="system/calendar" :notShowToolbar="true" :notGetID="true" v-on:afterLoad="setCalendar">
    <template>
      <WiCalendarToolbar></WiCalendarToolbar>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12>
            <v-card class="elevation-5">
              <v-sheet :height="(innerHeight - 145)">
                <v-calendar ref="calendar" :value="currentDate" :type="period" color="primary" locale="pt-br" @click:date="createNewTask">
                  <template v-slot:day="{ date }">
                    <WiCalendarEvents :eventDate="date"></WiCalendarEvents>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-card>
          </v-flex>
          <!-- 
          <v-flex xs12 sm12 md4 lg4 class="calendar__options">
            <v-card class="elevation-5">
              <v-sheet height="500">
                <WiCalendarShowOptions></WiCalendarShowOptions>
              </v-sheet>
            </v-card>
          </v-flex> 
          -->
        </v-layout>
      </v-container>
    </template>
  </wi-view>
</template>
<script>
  import WiCalendarEvents from '@/default/component/WiCalendar/WiCalendarEvents'
  import WiCalendarToolbar from '@/default/component/WiCalendar/WiCalendarToolbar'
  // import WiCalendarShowOptions from '@/default/component/WiCalendar/WiCalendarShowOptions'
  export default {
    name: 'WiCalendar',
    data: () => ({
      innerHeight: window.innerHeight,
      calendar: {}
    }),
    computed: {
      period () {
        return this.$store.state.WiCalendar.period
      },
      currentDate () {
        return this.$store.state.WiCalendar.currentDate
      }
    },
    methods: {
      setCalendar: function (data) {
        this.calendar = data
        this.$store.dispatch('WiCalendar/setContent', {
          currentDate: data.content.current_date,
          currentDateTitle: data.content.current_date_title,
          events: data.content.events || []
        })
      },
      createNewTask: function (date) {
        this.$WiEditDialog({
          wiConfig: 'task',
          data: {
            date: date.date
          },
          onSubmit: () => {
            this.$store.dispatch('WiCalendar/getContent', {currentDate: this.currentDate, action: 'change'})
          },
          redirectOnSubmit: false
        })
      }
    },
    components: {
      // WiCalendarShowOptions,
      WiCalendarToolbar,
      WiCalendarEvents
    }
  }
</script>
<style scoped>
  .calendar__options {
    padding-left: 10px;
  }
  .calendar__calendar {
    padding-right: 10px;
  }
</style>