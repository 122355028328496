import { PERMISSION_GUARANTEE } from '@/default/constants/permissions'

export default {
  id: PERMISSION_GUARANTEE,
  icon: 'privacy_tip',
  title: 'Gestão de Garantia',
  children: [
    // {
    //   id: PERMISSION_GUARANTEE,
    //   icon: 'view_week',
    //   title: 'Processo de Garantia',
    //   route: {
    //     name: 'process-of-wells',
    //     params: {
    //       business: 'guarantee'
    //     }
    //   }
    // },
    {
      id: PERMISSION_GUARANTEE,
      icon: 'select_all',
      title: 'Garantias Em Aberto',
      route: {
        name: 'wells-list',
        params: {
          page: 'guarantee-open'
        }
      }
    },
    {
      id: PERMISSION_GUARANTEE,
      icon: 'done_outline',
      title: 'Garantias Encerradas',
      route: {
        name: 'wells-list',
        params: {
          page: 'guarantee-close'
        }
      }
    },
    {
      id: PERMISSION_GUARANTEE,
      icon: 'close',
      title: 'Garantias Canceladas',
      route: {
        name: 'wells-list',
        params: {
          page: 'guarantee-cancel'
        }
      }
    }
  ]
}
