<template>
  <div>
    <google-maps 
    mapName="Grupos" 
    :apiUrl="'grupo/grupo-conexao/' + $route.params.id" 
    :apiParams="{join: 'grupos,conexoes'}"
    ></google-maps>
  </div>
</template>
<script>
  import GoogleMaps from '@/default/components/GoogleMaps/GoogleMapsMarkers'
  export default {
    name: 'Google',
    data () {
      return {

      }
    },
    components: {
      GoogleMaps
    }
  }
</script>