<template>
  <v-dialog v-model="dialog" persistent scrollable :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar dense class="diagnostic-model__toolbar">
        <v-toolbar-title>
          <span v-if="mode == 'select'"> Selecionar modelo </span>
          <strong v-if="host.producer"> {{ host.producer.name }} - </strong>
          <strong> {{ model.name }} </strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="mode == 'show'" small outline color="green" @click="configDialog = true">
          Definir status
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <ProducerModel
          v-bind="{ ...$props }"
          @onSelectModel="onSelectModel"
        ></ProducerModel>
      </v-card-text>
      <v-card-actions v-if="mode == 'select'" class="elevation-20">
        <v-btn outline block color="red" class="white--text" @click="dialog = false">
          <v-icon>close</v-icon>
          <span> Cancelar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn block color="green" class="white--text" @click="selectModel()">
          <v-icon>done</v-icon>
          <span> Selecionar este modelo</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <ProducerModelConfigDialog
      v-if="mode == 'show'"
      v-model="configDialog"
      :model="model"
      :host="host"
    ></ProducerModelConfigDialog>
  </v-dialog>
</template>
<script>
  import ProducerModel from './'
  import ProducerModelConfigDialog from '../ProducerModelConfig/Dialog'
  export default {
    name: 'ProducerModelDialog',
    data: () => ({
      configDialog: false
    }),
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (input) {
          this.$emit('input', input)
        }
      }
    },
    methods: {
      selectModel: function () {
        const selectedModel = {
          producer_model_id: this.model.id
        }
        this.$WiApiPut({
          uri: 'network/host',
          id: this.host.id,
          data: selectedModel,
          callback: response => {
            this.onSelectModel(response)
          },
          config: {
            msgLoad: {
              title: `Definindo modelo do equipamento`,
              text: 'Por favor aguarde...'
            },
            msgSucess: {
              title: 'Solicitação enviada com sucesso.',
              text: 'Sua tela será atualizada em instantes...'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja selecionar este modelo?`,
              text: 'Para redefinir você precisará de acesso adm...'
            }
          }
        })
      },
      onSelectModel: function (response) {
        this.$emit('onSelectModel', response)
        this.dialog = false        
      }
    },
    props: {
      value: {
        required: true
      },
      model: {
        required: true,
        default: () => ({})
      },
      host: {
        required: true,
        default: () => ({})
      },
      mode: {
        type: String,
        default: 'show'
      }
    },
    components: {
      ProducerModel,
      ProducerModelConfigDialog
    }
  }
</script>
<style scoped>
  .producer-model__carousel {
    max-width: 300px;
  }
  .diagnostic-model {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-model__enable-button {
    cursor: pointer;
  }
  .diagnostic-model__toolbar {
    z-index: 999;
  }

  .diagnostic-model__model {
    text-align: left;
    font-size: 15px;
    color: gray;
  }
  .diagnostic-model__not-found {
    margin: 50px 0;
  }
  .diagnostic-model__title {
    color: red;
  }
  .diagnostic-model__title--signal_is_ok {
    color: green;
  }
</style>
