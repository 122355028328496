<template>
  <div v-if="itemResult">
    <v-card-text>
      <v-layout row wrap>
        <v-flex v-if="itemResult.orders" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
          <DiagnosticOrders
            :orders="itemResult.orders"
          ></DiagnosticOrders>
        </v-flex>
        <v-flex v-if="itemResult.massiveIncidents" xs6 sm6 md3 lg3 xl3 d-flex style="padding: 0 5px;">
          <div class="elevation-2">
            <DiagnosticMassives
              :massives="itemResult.massiveIncidents"
            ></DiagnosticMassives>
          </div>
        </v-flex>
        <v-flex v-if="itemResult.wlanConfigurations" xs6 sm6 md3 lg3 xl3 d-flex style="padding: 0 5px;">
          <DiagnosticWlans
            :wlans="itemResult.wlanConfigurations"
            :actions="actions"
            :status="status"
          ></DiagnosticWlans>
        </v-flex>
        <v-flex v-if="itemResult.services" xs6 sm6 md3 lg3 xl3 d-flex style="padding-left: 5px;">
          <div class="elevation-2">
            <DiagnosticServices
              :services="itemResult.services"
            ></DiagnosticServices>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>
<script>
  import DiagnosticWlans from './DiagnosticWlans'
  import DiagnosticOrders from './DiagnosticOrders'
  import DiagnosticServices from './DiagnosticServices'
  import DiagnosticMassives from './DiagnosticMassives'
  export default {
    name: 'DiagnosticItems',
    computed: {
      itemResult () {
        return this.complement?.result
      }
    },
    props: {
      complement: {
        required: true,
        default: {}
      },
      actions: {
        required: true,
        default: () => ([])
      },
      status: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      DiagnosticWlans,
      DiagnosticOrders,
      DiagnosticServices,
      DiagnosticMassives
    }
  }
</script>
