import { Model } from '@/default/model/Model'

export class Bank extends Model {
  constructor () {
    super()
    this.id = 'f92db47e'
    this.actions = {
      update: true
    }
    this.title = 'Bancos'
    this.icon = 'account_balance'

    this.apiUrl = 'finance/bank'
    this.apiParams = {
      fields: 'id,name,logo_url',
      order: 'name,asc'
    }

    this.setFields(this.fieldsList)
    // this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome do Banco'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados do banco.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do Banco',
      placeholder: 'Digite o nome do banco',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true
    }),
    logo_url: this.fieldText({
      value: 'logo_url',
      text: 'URL do Logo',
      placeholder: 'Digite a URL do logotipo do banco',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: false
    })
  }
} 