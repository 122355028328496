import { BusinessStatus } from '@/modules/business/config/BusinessStatus'
import { PERMISSION_WELLS_CONFIGURATION } from '@/default/constants/permissions'

export class WellStatus extends BusinessStatus {
  constructor() {
    super()
    this.id = PERMISSION_WELLS_CONFIGURATION
    this.title.list = 'Etapas do Poço'
    this.apiUrl = 'wells/config/status/well'
  }
}
