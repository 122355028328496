export default {
  namespaced: true,
  state: {
    dialogs: [
      // {
      //   wiConfig: null,
      //   data: {},
      //   active: false,
      //   onClose: null,
      //   onSubmit: null
      //   redirectOnSubmit : true
      // }
    ]
  },
  mutations: {
    dialogs (state, data) {
      state.dialogs = data
    }
  },
  actions: {
    open: function ({ state, commit }, config) {
      var dialogs = state.dialogs
      const index = dialogs.length
      config['index'] = index
      config['active'] = false
      dialogs.push(config)
      commit('dialogs', dialogs)
      setTimeout(() => {
        dialogs[index].active = true
        commit('dialogs', dialogs)
      }, 50)
    },
    close: function ({ dispatch }, close) {
      dispatch('callEvent', {index: close.index, eventName: 'onClose', callback: close.callback})
      dispatch('reset', close)
    },
    submit: function ({ dispatch }, submit) {
      dispatch('callEvent', {index: submit.index, eventName: 'onSubmit', callback: submit.callback})
      dispatch('reset', submit)
    },
    reset: function ({ state, commit }, reset) {
      var dialogs = state.dialogs
      dialogs[reset.index].active = false
      commit('dialogs', dialogs)
      setTimeout(() => {
        dialogs.splice(reset.index, 1)
        commit('dialogs', dialogs)
      }, 200)
    },
    callEvent: function ({ state }, {index, eventName, callback}) {
      var dialog = state.dialogs[index]
      if (dialog[eventName]) {
        if (typeof state[eventName] === 'object') {
          dialog[eventName].filter((f) => {
            f(callback)
          })
        } else {
          dialog[eventName](callback)
        }
      }
    }
  }
}
