<template>
  <v-list dense style="max-height: none !important; cursor: pointer;">
    <v-menu v-model="menu" :close-on-content-click="true" :max-width="275"  style="max-width: 275px;">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <ModuleItem :module="selectedModule" :menu="true"></ModuleItem>
        </div>
      </template>
      <v-card>
        <ListModules/>
      </v-card>
    </v-menu>
  </v-list>
</template>
<script>
  import { mapState } from 'vuex'
  import ModuleItem from '@/default/pages/MainNew/components/DrawerLeft/ModuleItem'
  import ListModules from '@/default/pages/MainNew/components/DrawerLeft/ListModules'
  export default {
    name: 'LeftDrawerListModulesMenu',
    data () {
      return {
        menu: false
      }
    },
    computed: {
      ...mapState('Pages/Main', ['selectedModule'])
    },
    components: {
      ModuleItem,
      ListModules
    }
  }
</script>
