import FinanceFields from '@/modules/finance/config/Finance/Finance/FinanceFields'
import { FinanceOutFilters } from '@/modules/finance/config/Finance/Finance/FinanceFilters'
import { PERMISSION_FINANCE_OUT_EXPORT_XLSX } from '@/default/constants/permissions'
export default {
  id: {
    id: 'c840b593',
    create: '4247438c',
    update: '5cdf28f1',
    delete: 'a7a7d858'
  },
  list: true,
  title: {
    list: 'Contas a Pagar',
    edit: {
      insert: 'Nova conta a pagar',
      update: 'Atualizar conta a pagar'
    }
  },
  icon: 'publish',
  apiUrl: 'finance/out',
  apiParams: {
    order: 'date_receive,desc'
  },
  search: {
    placeholder: 'Pesquise ID, descrição, nota fiscal ou pessoa envolvida',
    fields: ['id', 'description', 'invoice', 'person.name']
  },
  fields: FinanceFields,
  fieldsFixed: [
    {value: 'in_out', data: 2},
    {value: 'hide_in_out', data: true}
  ],
  notLoadOnMounted: false,
  showFilterDateToolbar: true,
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  components: {
    list: {
      header: () => import('@/modules/finance/pages/Finances/FinancesHeader'),
      tableRow: () => import('@/modules/finance/pages/Finances/Finances')
    }
  },
  filters: new FinanceOutFilters(),
  exports: {
    permission: PERMISSION_FINANCE_OUT_EXPORT_XLSX,
    title: 'Exportar Despesas',
    origins: {
      excel: {
        title: 'Exportar para o Excel',
        permission: PERMISSION_FINANCE_OUT_EXPORT_XLSX,
        description: 'Exportar despesas para o Excel.',
        image: '/static/icons/excel_icon.png',
        wiConfig: 'finance-out-export'
      }
    }
  }
}
