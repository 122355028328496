<template>
  <view-detail title="Configurações do Chamado" :apiUrl="'configuracoes'">
    <template slot-scope="dados"><br>
      <v-layout row>
        <v-flex xs12 sm8 offset-sm2><span></span>
          <v-form v-model="valid">
            <v-card>
              <v-list two-line subheader>
                <v-subheader>Definições Gerais</v-subheader>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Informar Assunto/Descrição</v-list-tile-title>
                    <v-list-tile-sub-title>Se ativo você poderá informar o assunto/descrição do chamado</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-switch v-model="dados.content.val_text_config.informar_assunto" :label="dados.content.val_text_config.informar_assunto ? 'Ativo' : 'Inativo'" input-value="1"></v-switch>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card>
            <v-card style="margin: 10px 0 10px 0;">
              <v-list subheader two-line>
                <v-subheader>
                  <span>Integração com Módulo </span> 
                  <strong style="padding-left: 5px;"> Network</strong>
                </v-subheader>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Sempre vincular um novo chamado a um grupo</v-list-tile-title>
                    <v-list-tile-sub-title>Todo chamado aberto terá de estar vinculado a um ou mais grupos</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-switch v-model="dados.content.val_text_config.exibir_grupo" :label="dados.content.val_text_config.exibir_grupo ? 'Ativo' : 'Inativo'" input-value="1"></v-switch>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider v-if="dados.content.val_text_config.exibir_grupo"></v-divider>
                <v-list-tile v-if="dados.content.val_text_config.exibir_grupo">
                  <v-list-tile-content>
                    <v-list-tile-title>Selecione o tipo de grupo</v-list-tile-title>
                    <v-list-tile-sub-title>Esta opção é obrigatória</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <db-autocomplete apiUrl="grupo/tipo" formField="grupo_tipo_id" :form="dados.content.val_text_config" show="nome_tipo" label="Tipo de Grupo" :disabled="!dados.content.val_text_config.exibir_grupo" :rules="[v => !!v || 'Campo obrigatório']" required ></db-autocomplete>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card>
          <v-btn :disabled="!valid" color="primary" @click="update(dados.content.val_text_config)">Salvar</v-btn>
          <v-btn @click="dados.content.val_text_config = {nivelamento: true}; $forceUpdate()">Restaurar Padrão</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </template>
</view-detail>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import ViewDetail from '@/default/components/ViewDetail'
  import DbAutocomplete from '@/default/components/DBAutocomplete'
  export default {
    name: 'Configuracoes_do_chamado',
    data () {
      return {
        form: {},
        valid: false
      }
    },
    methods: {
      update: function (form) {
        callApi.put({
          uri: 'configuracoes',
          data: {val_text_config: form},
          id: this.$route.params.id,
          msgLoad: 'Salvando Configurações',
          msgSucess: 'Configurações armazenadas com sucesso!',
          sucess: (data) => {
            if (localStorage.getItem('autheyJ0eXAiOi')) {
              var array = JSON.parse(localStorage.getItem('autheyJ0eXAiOi'))
              if (array.Configuracao && array.Configuracao[2]) {
                array.Configuracao[2] = data.data
              }
              localStorage.setItem('autheyJ0eXAiOi', JSON.stringify(array))
              this.$store.commit('auth', array)
            }
          }
        })
      }
    },
    components: {
      ViewDetail,
      DbAutocomplete
    }
  }
</script>