import Vue from 'vue'
import routers from '@/router'
import i18n from '@/lang/i18n'
import Router from 'vue-router'
import routersMain from '@/router/main'
import Teste from '@/default/pages/Teste'
import Login from '@/default/pages/Login'
import List from '@/default/components/List'
import { Auth } from '@/default/service/Auth'
import Main from '@/default/pages/MainNew/Main'
import Dashboard from '@/default/pages/Dashboard'
import Modules from '@/default/pages/Main/Modules'
import Indisponivel from '@/default/pages/Indisponivel'
import Calendar from '@/default/pages/Calendar/Calendar'
import Configuracoes from '@/default/pages/Configuracoes'
import UserProfile from '@/default/pages/User/UserProfile'
import system from '@/default/store/modules/system/system'
import ListNew from '@/default/component/WiList/WiListPage'
import ErrorTest from '@/default/pages/ErrorTest/ErrorTest'
import SelecionarEmpresa from '@/default/pages/SelecionarEmpresa'
import BusinessPrint from '@/modules/shared/pages/Business/BusinessPrint'
import ChatView from '@/default/pages/ChatView'
import ChatMessages from '@/default/pages/ChatView/ChatMessages'

Vue.use(Router)

var main = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      title: i18n.t('pages.dashboard.title'),
      icon: 'dashboard'
    }
  },
  {
    path: 'list/:page',
    name: 'list',
    component: List,
    meta: {
      auth: true,
      title: i18n.t('pages.list.title'),
      icon: 'view_list',
      touch: true
    }
  },
  {
    path: 'listing/:page',
    name: 'listing',
    component: ListNew,
    meta: {
      auth: true,
      title: i18n.t('pages.list.title'),
      icon: 'view_list',
      touch: true
    }
  },
  {
    path: 'configuracoes',
    name: 'configuracoes',
    component: Configuracoes,
    meta: {
      auth: true,
      title: i18n.t('pages.config.title')
    }
  },
  {
    path: 'user-profile/:id',
    name: 'user-profile',
    component: UserProfile,
    meta: {
      id: '1ac37773',
      auth: true,
      title: i18n.t('pages.userProfile.title'),
      icon: 'lock_open'
    }
  },
  {
    path: 'calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      auth: true,
      title: i18n.t('pages.calendar.title'),
      icon: 'event'
    }
  },
  {
    path: 'disponivel-em-breve',
    name: 'disponivel-em-breve',
    component: Indisponivel,
    meta: {
      auth: true,
      title: i18n.t('pages.schedule.title'),
      icon: 'schedule'
    }
  },
  {
    path: 'error-test',
    name: 'error-test',
    component: ErrorTest,
    meta: {
      auth: true,
      title: 'Teste de Erro'
    }
  },
  {
    path: 'teste',
    name: 'teste',
    component: Teste,
    meta: {
      auth: true,
      title: 'Teste'
    }
  },
  {
    name: 'chat-view',
    path: 'chat-view',
    component: ChatView,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Conversa/Chat',
      icon: 'message',
      touch: true
    },
    children: [
      {
        name: 'chat-view-messages',
        path: 'messages/:id',
        component: ChatMessages,
        meta: {
          id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
          auth: true,
          title: 'Conversa/Chat',
          icon: 'message',
          touch: true
        }
      }
    ]
  },
  {
    name: 'chat-view-messages-page',
    path: 'messages-page/:id',
    component: ChatMessages,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Conversa/Chat',
      icon: 'message',
      touch: true
    }
  }
]

main = main.concat(routersMain)
system.state.modules.filter((mod) => {
  mod.router.push({
    path: 'list/:page',
    name: mod.name + '-list',
    component: ListNew,
    meta: {
      id: mod.id,
      auth: true,
      title: i18n.t('pages.list.title'),
      icon: 'view_list',
      touch: true,
      module: mod.name,
      color: mod.color
    }
  })
  main = main.concat({
    path: mod.name,
    name: mod.name,
    redirect: '/dashboard',
    component: Modules,
    meta: {
      id: mod.id,
      auth: true,
      module: mod.name,
      color: mod.color
    },
    children: mod.router
  })
})

var routes = [
  {
    path: '*',
    redirect: '/main/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
      title: i18n.t('pages.login.title')
    }
  },
  {
    path: '/business-print/:business/:id',
    name: 'business-print',
    component: BusinessPrint,
    meta: {
      auth: false,
      title: 'Report'
    }
  },
  {
    path: '/selecionar-empresa',
    name: 'selecionar-empresa',
    component: SelecionarEmpresa,
    meta: {
      auth: false,
      title: 'Selecione a Empresa'
    }
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    redirect: '/dashboard',
    meta: {
      auth: true
    },
    children: main
  }
]

var r = routes.concat(routers)

const router = new Router({
  routes: r
})

var setTitle = function (to) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + system.state.name || i18n.t('messages.loading.title')
    document.getElementById('favicon').href = 'static/systems/' + system.state.icon
    document.getElementById('shortcuticon').href = 'static/systems/' + system.state.icon
    document.getElementById('apple-touch-icon1').href = 'static/systems/' + system.state.icon
    document.getElementById('apple-touch-icon2').href = 'static/systems/' + system.state.icon
    document.getElementById('apple-touch-icon3').href = 'static/systems/' + system.state.icon
    document.getElementById('apple-touch-icon4').href = 'static/systems/' + system.state.icon
  }
}

// var prms = function (to) {
//   var u = JSON.parse(atob(localStorage.getItem('type') || '{}'))
//   if (to.meta.id) {
//     if (u && u.permissions) {
//       if (to.meta.permission && to.meta.permission(to, u)) {
//         return true
//       } else {
//         var status = true
//         to.matched.filter(route => {
//           if (route.meta.id && u.permissions.indexOf(route.meta.id) < 0) {
//             status = false
//           }
//         })
//         return status
//       }
//     }
//     return false
//   }
//   return true
// }

const permissionIsValid = to => {
  if (to && to.meta && to.meta.id) {
    return Auth.checkPermission(to.meta.id)
  }
  return true
}

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (Auth.check()) {
      if (permissionIsValid(to)) {
        setTitle(to)
        next()
      } else {
        Vue.swal({
          type: 'info',
          title: 'Desculpe!',
          text: 'Em nossos registros consta que você não tem permissão para acessar este recurso!'
        })
        if (!from.name) {
          router.push({name: 'main'})
        }
      }
    } else {
      router.push({name: 'login'})
      localStorage.clear()
    }
  } else {
    setTitle(to)
    next()
  }
})
export default router
