import { BusinessCategory } from '@/modules/business/config/BusinessCategory'
import { PERMISSION_WELLS_CONFIGURATION } from '@/default/constants/permissions'

export class WellCategory extends BusinessCategory {
  constructor() {
    super()
    this.id = PERMISSION_WELLS_CONFIGURATION
    this.title.list = 'Categorias de Poços'
    this.apiUrl = 'wells/config/category/well'
  }
}
