import { DateTime } from '@/default/plugins/Utils/DateTime'
import { PERMISSION_REGISTER_CHANNEL } from '@/default/constants/permissions'

export default {
  id: '549b7548',
  icon: 'people',
  title: 'Gestão de Clientes',
  children: [
    {
      id: '549b7548',
      icon: 'people_outline',
      title: 'Clientes',
      route: {
        name: 'register-list',
        params: {
          page: 'customer'
        }
      }
    },
    {
      id: '2498ebe2',
      icon: 'perm_identity',
      title: 'Prospects',
      route: {
        name: 'register-list',
        params: {
          page: 'prospect'
        }
      }
    },
    {
      id: PERMISSION_REGISTER_CHANNEL,
      icon: 'equalizer',
      title: 'Clientes por canais de entrada',
      route: {
        name: 'channel-statistics-by-customer',
        query: {
          date_field: 'created_at',
          date_start: DateTime.date(),
          date_end: DateTime.date()
        }
      }
    },
    {
      id: PERMISSION_REGISTER_CHANNEL,
      icon: 'add_circle_outline',
      title: 'Canais de entrada',
      route: {
        name: 'register-list',
        params: {
          page: 'channel'
        }
      }
    }
  ]
}
