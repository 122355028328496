<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-massives__enable-button">
        <v-card-text style="text-align: center;">
          <h1>{{massives.length}}</h1>
          <strong>Massivo(s)</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-massives">
      <v-toolbar dense class="diagnostic-massives__toolbar">
        <v-toolbar-title>
          Massivo(s)
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="massives.length > 0">
        <h4>
          <span>{{massives.length}} </span>
          <span> Massivo(s) encontrado(s).</span>
        </h4>
        <br>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg6 xl6 style="padding-right: 10px;" v-for="(massive, massiveKey) in massives" :key="massiveKey">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-massives__massives">
                <img src="/static/icons/monitor/problema_expirado.png" width="50px">
                <h4>ID: {{massive.id}}</h4>
                <h4>Previsão: {{massive.promisseDate | moment('DD/MM/YY HH:mm')}}</h4>
                <h4>Tipo: {{massive.type}}</h4>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-massives__not-found">
        <h2>Nenhuma Massivo encontrada.☹️ </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DiagnosticMassives',
    data: () => ({
      dialog: false,
    }),
    props: {
      massives: {
        required: true,
        default: () => ([])
      }
    }
  }
</script>
<style scoped>
  .diagnostic-massives {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-massives__enable-button {
    cursor: pointer;
  }
  .diagnostic-massives__toolbar {
    z-index: 999;
  }

  .diagnostic-massives__massives {
    text-align: center;
  }
  .diagnostic-massives__not-found {
    margin: 50px 0;
  }
</style>
