import { BusinessFinance } from '@/modules/shared/config/Business/BusinessFinance'

class OrderFinance extends BusinessFinance {
  constructor () {
    super()
    this.id = 'b41dcfec'
    this.name = 'order-finance'
    this.title = 'Receber parcela da Ordem de Serviço'
    this.apiUrl = 'finance'
    this.icon = 'receipt'
    // Fields
    this.fields[1].text = 'Data de Recebimento'
  }
}

export default new OrderFinance()
