<template>
  <v-list-tile :style="{background: background + ' !important'}">
    <v-list-tile-avatar>
      <img v-if="module.image" :src="'static/systems/modules/' + module.image" :alt="module.title">
      <v-icon v-if="module.icon">{{module.icon}}</v-icon>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title :style="{'color': module.status ? 'green' : 'red', 'text-decoration': module.status ? '' : 'line-through'}">{{ module.title }}</v-list-tile-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-list-tile-sub-title v-on="on">{{ module.description }}</v-list-tile-sub-title>
        </template>
        <span>{{ module.description }}</span>
      </v-tooltip>
    </v-list-tile-content>
    <v-list-tile-action>
      <user-profile-modules-button :rule="module" :refresh="refresh"></user-profile-modules-button>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
  import UserProfileModulesButton from '@/default/pages/User/UserProfileModulesButton'
  export default {
    name: 'UserProfileModule',
    data () {
      return {}
    },
    methods: {},
    props: {
      module: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function
      },
      background: {
        type: String,
        default: '#FFF'
      }
    },
    components: {
      UserProfileModulesButton
    }
  }
</script>
