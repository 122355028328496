import { PERMISSION_FORMS_MODULE } from '@/default/constants/permissions'
import router from '@/modules/forms/router'
import config from '@/modules/forms/config'
import menu from '@/modules/forms/menu'

export default {
  id: PERMISSION_FORMS_MODULE + '8',
  name: 'forms',
  title: 'Formulário',
  description: 'Gestão de coleta de dados',
  icon: 'wises-technology-module-sites.png',
  iconSystem: 'wises-technology-system-sites.png',
  iconProfile: 'profile_service_desk.png',
  color: '#90CAF9',
  menu,
  router,
  config
}
