<template>
  <div>
    <v-toolbar dense :color="!$wiDark ? 'primary' : 'dark'" style="color: white;">
      <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title v-if="execution && execution.process">
        {{ execution.process.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <label v-if="!loading && execution.status" class="process-toolbar__status" :style="{background: execution.status.color}">
        <v-icon color="white">{{execution.status.icon}}</v-icon>
        <label> {{execution.status.name}} </label>
      </label>
      <v-btn small outline color="white" @click="stageFlowchartDialog = true">
        <v-icon>account_tree</v-icon>
        <span style="padding-left: 5px;"> Fluxo de etapas</span>
      </v-btn>
      <v-btn :loading="loading" icon @click="refreshExecution()">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading || stageLoading" :indeterminate="true" style="margin: 0 !important;"></v-progress-linear>
    <StageFlowchartDialog
      v-if="execution && execution.process_id"
      v-model="stageFlowchartDialog"
      :loading="loading"
      :processId="execution.process_id"
    ></StageFlowchartDialog>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import StageFlowchartDialog from '@/modules/service-desk/pages/Process/StageFlowchartDialog'
  export default {
    name: 'ProcessTollbar',
    data: () => ({
      stageFlowchartDialog: false
    }),
    computed: {
      ...mapState('ProcessExecution', ['execution', 'loading']),
      ...mapGetters('ProcessExecution', ['stageLoading'])
    },
    methods: {
      test: function () {
        // uuid
      },
      ...mapActions('ProcessExecution', ['refreshExecution'])
    },
    components: {
      StageFlowchartDialog
    }
  }
</script>

<style scoped>
  .process-toolbar__status {
    font-size: 17px;
    color: white;
    padding: 0px 30px;
    border-style: dashed;
    border-color: white;
    border-width: 1px;
  }
</style>