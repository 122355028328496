<template>
  <div style="padding-right: 30px;">
    <DiagnosticCreateTimeline
      v-if="status && status.code != 'closed' && status.code != 'canceled'"
      @updateProcess="updateProcess"
    ></DiagnosticCreateTimeline>
    <DiagnosticCreateData
      v-else
      :data="data"
    ></DiagnosticCreateData>
  </div>
</template>
<script>
import DiagnosticCreateTimeline from './DiagnosticCreateTimeline'
import DiagnosticCreateData from './DiagnosticCreateData'
export default {
  methods: {
    updateProcess: function (updateProcess) {
      this.$emit('updateProcess', updateProcess)
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    DiagnosticCreateTimeline,
    DiagnosticCreateData
  }
}
</script>