<template>
  <v-card flat elevation="17" color="#FAFAFA">
    <v-toolbar dense flat color="#FAFAFA">
      <v-icon>attach_file</v-icon>
      <v-toolbar-title>
        <span v-if="content && content.files && content.files.length > 0">
          {{content.files.length}} 
        </span>
        <span> Arquivo(s)</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card-text :style="{'min-height': '350px', 'max-height': '350px'}" style="padding: 0 0 20px 0; overflow-y: scroll;">
      <div v-if="content && content.files && content.files.length > 0">
        <v-layout row wrap>
          <v-flex v-for="(file, fileKey) in content.files" :key="fileKey" xs12 sm12 md6 lg6 style="padding: 10px;">
            <v-dialog
              v-if="file.file.extension == 'png' || file.file.extension == 'jpg'"
              v-model="file['dialog']"
              max-width="598"
              scrollable
            >
              <template v-slot:activator="{ on }">
                <v-card v-on="on" style="cursor: pointer;">
                  <WiFileListItem :file="file.file"></WiFileListItem>
                </v-card>
              </template>
              <v-card>
                <v-toolbar dense>
                  <span>
                    <strong>{{ file.file.name | stringlength(20) }}</strong> - {{ file.created_at | moment('DD/MM/YY HH:mm') }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn outline small link target="_blank" :href="file.file.url">
                    <v-icon>open_in_new</v-icon>
                    <span>Abrir</span>
                  </v-btn>
                  <v-btn icon flat="flat" @click="file['dialog'] = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text style="max-height: 570px !important;">
                  <img
                    :src="file.file.url"
                    aspectRatio="1"
                    style="width: 100%; max-height: auto !important;"
                  />
                  <div v-if="file.file.description" v-html="file.file.description"></div>
                </v-card-text>
                <v-divider></v-divider>
              </v-card>
            </v-dialog>
            <v-card v-else link target="_blank" :href="file.url">
              <a target="_blank" :href="file.file.url">
                <WiFileListItem :file="file.file"></WiFileListItem>
              </a>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
      <div v-else style="width: 100%; padding: 30px 0; text-align: center;">
        <h3>Nenhum arquivo encontrado.</h3>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import WiFileListItem from '@/default/component/WiFile/WiFileListItem'
  export default {
    name: 'OrderRecordFiles',
    data () {
      return {
        fileDialog: false,
        maxHeigth: (window.innerHeight - 378) + 'px'
      }
    },
    methods: {},
    props: ['content'],
    components: {
      WiFileListItem
    }
  }
</script>

