import Vue from 'vue'
import store from '@/default/store'
import {Error} from '@/default/service/Error'
import {callApi} from '@/default/service/Api'
import {Utils} from '@/default/service/Utils'
import {Lang} from '@/default/service/Lang'

Vue.mixin({
  methods: {
    $WiView: function (index) {
      return store.state.WiView.views[index]
    },
    $WiDialog: function (config) {
      store.dispatch('WiDialog/open', config)
    },
    $WiFileDialog: function (config) {
      store.dispatch('WiFileDialog/open', config)
    },
    $WiEventsDialog: function (config) {
      store.dispatch('WiEventsDialogs/open', config)
    },
    $WiFileListDialog: function (config) {
      store.dispatch('WiFileListDialog/open', config)
    },
    $WiRemediesDialog: function (config) {
      store.dispatch('WiRemediesDialog/open', config)
    },
    $WiEditDialog: function (config) {
      store.dispatch('WiEditDialog/open', config)
    },
    $WiListDialog: function (config) {
      store.dispatch('WiListDialog/open', config)
    },
    $WiMakeError: function ({error, id, title, message, login}) {
      Error.make({error: error, id: id, title: title, message: message, login})
    },
    $makeSystemNotification: function (title, body, icon) {
      var notification = new Notification(title, {
        body: body,
        icon: icon
      })
      notification.onclick = function () {
        window.open(window.location.href)
      }
    },
    $WiCheckPermission: function (permissionId) {
      return this.$store.state.User?.profile?.permissions?.includes(permissionId)
    },
    $WiHelpDialog: function (config) {
      store.dispatch('WiHelpDialog/open', config)
    },
    $UUID: function () {
      var dt = new Date().getTime()
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    },
    $WiApiGet: function ({uri, params, callback, config = {}}) {
      callApi.get({
        uri: uri,
        params: params || {},
        sucess: (response) => {
          if (callback) {
            // eslint-disable-next-line
            callback({success: response.data})
          }
        },
        error: (error) => {
          console.log(error)
          if (callback) {
            // eslint-disable-next-line
            callback({error: error})
          }
        },
        msgLoad: config.msgLoad,
        msgSucess: config.msgSucess,
        msgError: config.msgError,
        msgAsk: config.msgAsk
      })
    },
    $WiApiPost: function ({uri, data, callback, config = {}}) {
      callApi.post({
        uri: uri,
        data: data || {},
        sucess: (response) => {
          if (callback) {
            // eslint-disable-next-line
            callback({sucess: response.data})
          }
        },
        error: (error) => {
          console.log(error)
          if (callback) {
            // eslint-disable-next-line
            callback({error: error})
          }
        },
        msgLoad: config.msgLoad,
        msgSucess: config.msgSucess,
        msgError: config.msgError,
        msgNotFound: config.msgNotFound,
        msgAsk: config.msgAsk
      })
    },
    $WiApiPut: function ({ uri, id, data, callback, config }) {
      callApi.put({
        uri: uri,
        id: id,
        data: data || {},
        sucess: (response) => {
          if (callback) {
            // eslint-disable-next-line
            callback({sucess: response.data})
          }
        },
        error: (error) => {
          console.log(error)
          if (callback) {
            // eslint-disable-next-line
            callback({ error: error })
          }
        },
        msgLoad: config ? config.msgLoad : false,
        msgSucess: config ? config.msgSucess : false,
        msgError: config ? config.msgError : false,
        msgAsk: config ? config.msgAsk : false
      })
    },
    $WiApiDelete: function ({ uri, id, callback, config }) {
      callApi.delete({
        uri: uri,
        id: id,
        sucess: (response) => {
          if (callback) {
            // eslint-disable-next-line
            callback({sucess: response.data})
          }
        },
        error: (error) => {
          console.log(error)
          if (callback) {
            // eslint-disable-next-line
            callback({ error: error })
          }
        },
        msgLoad: config ? config.msgLoad : true,
        msgSucess: config ? config.msgSucess : true,
        msgError: config ? config.msgError : true,
        msgAsk: config ? config.msgAsk : true
      })
    },
    $WiSecondsColor: function ({ seconds, config }) {
      var color = ''
      if (seconds && (!config || !config.disable)) {
        if (seconds > 86400) {
          color = 'blue'
        } else if (seconds > 0) {
          color = 'orange'
        } else {
          color = 'red'
        }
      }
      return color
    },
    $WiLangText: function (textIndex, text) {
      return Lang.langText(textIndex, text)
    },
    $WiDebounce: function debounce(func, delay = 1000) {
      let timeoutId;
      console.log('1')
      return (...args) => {
        console.log('2')
        if (timeoutId) {
          console.log('3')
          clearTimeout(timeoutId);
        }
        console.log('4')
        timeoutId = setTimeout(() => {
          console.log('5')
          func.apply(this, args);
        }, delay);
      };
    },
    $WiColorContrast: function (corHexadecimal) {
      // Converte a cor hexadecimal para RGB
      const r = parseInt(corHexadecimal.slice(1, 3), 16);
      const g = parseInt(corHexadecimal.slice(3, 5), 16);
      const b = parseInt(corHexadecimal.slice(5, 7), 16);

      // Calcula o valor de luminosidade usando a fórmula YIQ
      const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

      // Define um valor de limiar para determinar a cor do texto
      const limiar = 128;

      // Verifica se a luminosidade é maior que o limiar
      if (luminosidade > limiar) {
          // Se a cor de fundo for clara, retorna cor escura
          return "#000000"; // Cor preta
      } else {
          // Se a cor de fundo for escura, retorna cor clara
          return "#FFFFFF"; // Cor branca
      }
  }
  },
  computed: {
    $wiDark: {
      get () {
        return store.state.Pages.Main.dark || false
      },
      set () {}
    },
    $wiUser: {
      get () {
        return store.state.User || {}
      }
    },
    $wiCurrentDate () {
      return Utils.currentDate()
    }
  }
})

export default {}
