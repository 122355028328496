import { Filters } from '@/default/model/Filters'

export class TalkFilters extends Filters {
  constructor () {
    super()

    this.id = ''
    this.title = 'Filtrar Atendimentos'
    this.fields = {
      status_id: this.fieldSelect ({
        value: 'status',
        text: 'Status',
        placeholder: 'Selecione um status',
        options: [
          { value: '1', text: 'Em Aberto - Chatbot' },
          { value: '2', text: 'Em atendimento' },
          { value: '3', text: 'Encerrado' },
          { value: '4', text: 'Cancelado' }
        ],
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      talk_channel_id: this.fieldSelectApi ({
        value: 'talk_channel_id',
        text: 'Canal de atendimento',
        show: 'name',
        placeholder: 'Selecione um canal',
        api: {
          url: 'talk/channel',
          params: {
            order: 'name,asc'
          }
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      talk_contact_id: this.fieldWiListDialog({
        value: 'talk_contact_id',
        text: 'Contato',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-contact',
        show: 'name',
        icon: 'person_outline'
      }),
      collaborator_id: this.fieldWiListDialog({
        value: 'operator_id',
        text: 'Operador',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'collaborator.first_name',
        icon: 'assignment_ind'
      })
    }
  }
}
