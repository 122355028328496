<template>
  <v-dialog v-model="active" scrollable max-width="580px">
    <v-card>
      <v-card-title style="padding: 0 15px 0 15px;">
        <strong>Localizações do chamado</strong>
        <v-spacer></v-spacer>
        <v-btn flat icon color="green" @click="info.refresh()">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px; padding: 0;">
        <wi-view v-if="active" notShowToolbar="true" :apiUrl="'service-desk/ticket/location'" :apiParams="'?where=ticket_id,' + ticket" notGetID="true" v-on:afterLoad="dataLoaded">
          <template slot-scope="dados"><span style="display: none;">{{info = dados}}</span>
            <div class="google-map" id="ticket-locations-google-map" style="height: 300px"></div>
          </template>
        </wi-view>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" flat @click.native="$emit('close')">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DialogTicketStatusLog',
    data () {
      return {
        info: null,
        map: null,
        markers: []
      }
    },
    methods: {
      dataLoaded: function (data) {
        this.info = data
        setTimeout(() => {
          this.createGoogleMaps(data.content.data)
          this.createMarkers(data.content.data)
        }, 500)
      },
      createGoogleMaps: function (locations) {
        var location = locations[0] || {latitude: -20.1467258, longitude: -45.1213433}
        const element = document.getElementById('ticket-locations-google-map')
        const options = {
          zoom: 14,
          // eslint-disable-next-line
          center: new google.maps.LatLng(location.latitude, location.longitude),
          mapTypeId: 'satellite',
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite', 'terrain']
          }
        }
        // eslint-disable-next-line
        this.map = new google.maps.Map(element, options)
        console.log('maps')
      },
      createMarkers: function (locations) {
        locations.forEach((location) => {
          // eslint-disable-next-line
          const marker = new google.maps.Marker({
            // eslint-disable-next-line
            position: new google.maps.LatLng(location.latitude || -20.1467258, location.longitude|| -45.1213433),
            map: this.map,
            title: location.description,
            label: location.description,
            draggable: false,
            // eslint-disable-next-line
            icon: {
              url: this.url + '/assets/img/icons/maps/grupo-020.png',
              // eslint-disable-next-line
              scaledSize: new google.maps.Size(50, 50),
              // eslint-disable-next-line
              origin: new google.maps.Point(0,0),
              // eslint-disable-next-line
              anchor: new google.maps.Point(25, 50)
            }
          })
          this.markers.push({marker: marker, location: location})
        })
        this.googleMapsCenter()
        console.log('maps')
      },
      googleMapsCenter: function () {
        // eslint-disable-next-line
        var bounds = new google.maps.LatLngBounds()
        this.markers.filter(marker => {
          bounds.extend(marker.marker.getPosition())
        })
        this.map.setCenter(bounds.getCenter())
        this.map.fitBounds(bounds)
      }
    },
    computed: {
      url () {
        const url = this.$store.state.url
        return url.baseDefault
      }
    },
    props: ['ticket', 'active']
  }
</script>