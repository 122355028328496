import { Business } from '@/modules/shared/config/Business/Business'

class Process extends Business {
  constructor (listTitle, stage, fieldDate) {
    super()
    this.list = true
    this.id = {
      id: 'e2a51798',
      create: '44f4ac80',
      update: 'db3ace23',
      delete: '57157ade'
    }
    this.name = 'process'
    this.title = {
      list: listTitle,
      edit: {
        insert: 'Novo Processo',
        update: 'Editar Processo'
      }
    }
    this.apiUrl = 'juridical/process'
    this.params['stage'] = stage
    this.urlDetails = { name: 'process', params: {} }
    this.icon = 'gavel'
    // Fields
    this.fields.map(field => {
      return field
    })
    // this.fields[0].text = 'Informe os dados básicos deste Processo.'
    // this.fields[2].text = 'Cliente'
    // this.fields[2].wiConfig = 'customer'
    // this.fields[4].apiUrl = 'juridical/config/status/process'
    // this.fields[5].apiUrl = 'juridical/config/category/process'
    // this.fields[5].text = 'Categoria/Tipo de Processo'
    // this.fields[7].text = 'Data da Entrevista'
    // this.fields[8].text = 'Previsão de Conclusão'
    // this.fields[9].text = 'Valor Adicional'
    // this.fields[12] = {}
    // this.fields[13].text = 'Descreva abaixo a entrevista com o cliente'
    // this.fields[15].text = 'Este campo pode ser usado para registros financeiras'
    // this.fields[18].text = 'Observações do Processo'

    // this.fields[23].value = fieldDate.value
    // this.fields[23].text = fieldDate.text
    // this.fields[24] = {
    //   value: 'date_end',
    //   text: 'Previsão de Conclusão',
    //   list: true
    // }
    this.fields[0].text = 'Informe os dados básicos deste Processo.'
    this.fields[2].text = 'Cliente'
    this.fields[2].wiConfig = 'customer'
    this.fields[4].apiUrl = 'juridical/config/status/process'
    this.fields[5].apiUrl = 'juridical/config/category/process'
    this.fields[5].text = 'Categoria/Tipo de Processo'
    this.fields[7].text = 'Data da Entrevista'
    this.fields[8].text = 'Previsão de Conclusão'
    this.fields[9].text = 'Valor Adicional'
    this.fields[14].text = 'Descreva abaixo a entrevista com o cliente'
    this.fields[16].text = 'Este campo pode ser usado para registros financeiras'
    this.fields[19].text = 'Observações do Processo'

    this.fields[24].value = fieldDate.value
    this.fields[24].text = fieldDate.text
    this.fields[25] = {
      value: 'date_end',
      text: 'Previsão de Conclusão',
      list: true
    }
  }
}

export default {
  open: new Process('Processos Em Aberto', 'open', { value: 'opened_at', text: 'Data de Criação' }),
  close: new Process('Histórico de Processos', 'close', { value: 'closed_at', text: 'Data de Conclusão' }),
  cancel: new Process('Processos Cancelados', 'cancel', { value: 'canceled_at', text: 'Data de Cancelamento' })
}
