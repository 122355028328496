import { Model } from '@/default/model/Model'

export class PersonFile extends Model {
  constructor () {
    super()
    this.id = 'bec249c8-3ddc-4113-8dd5-e558fd30baa8'
    this.title = 'Inserir arquivo'
    this.apiUrl = 'register/person/file'
    this.icon = 'image'
    this.maxWidth = '480px'
    this.setFields([
      this.fieldSubHeader({
        text: 'Selecione o arquivo que deseja inserir.'
      }),
      this.fieldWiFileDialog({
        value: 'file_id',
        text: 'Arquivo',
        placeholder: 'Selecione o arquivo',
        required: true,
        data: {
          is_public: false
        },
        filter: {
          is_public: false
        }
      }),
      this.fieldSubHeader({
        text: 'Descreva o arquivo inserido.'
      }),
      this.fieldText({
        value: 'description',
        text: 'Descrição',
        counter: 100,
        required: false
      })
    ])
  }
}

