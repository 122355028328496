<template>
  <WiCalendar></WiCalendar>           
</template>
<script>
  import WiCalendar from '@/default/component/WiCalendar/WiCalendar'
  export default {
    name: 'Calendar',
    data: () => ({}),
    computed: {},
    methods: {},
    components: {
      WiCalendar
    }
  }
</script>
