<template>
  <div style="max-width: 100%;">
    <div style="max-width: 100%;">
      <v-list dense two-line>
        <v-list-tile :disabled="disabled" v-ripple avatar @click="dialog = true" style="border-style: solid; border-color: gray; border-width: 1px; height: 45px !important; padding-left: 5px !important; padding-right: 5px !important;" class="asdasdasd">
          <v-list-tile-avatar>
            <v-icon large>{{icon}}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Ícone</v-list-tile-sub-title>
            <v-list-tile-title v-if="icon">
              <span>{{ icon | stringlength(10) }}</span>
            </v-list-tile-title>
            <v-list-tile-title v-else>
              <span>{{ 'Selecione...' | stringlength(10) }}</span>
            </v-list-tile-title>
            <v-input style="display: none;" :required="required" :rules="rules" v-model="setIcon"></v-input>
          </v-list-tile-content>
          <v-list-tile-action style="min-width: unset !important;">
            <v-btn flat icon color="green" @click="dialog = true">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="85%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card style="background: #fafafa;">
        <v-toolbar dense primary>
          <v-toolbar-title>
            Google Icons
          </v-toolbar-title>
          <v-spacer></v-spacer>
            <div class="hidden-xs-only hidden-sm-only" style="min-width: 450px;">
              <div class="text-xs-center">
                <v-text-field 
                  style="color: white; width: 100%" 
                  v-model="search"  
                  color="white"  
                  :append-icon="search != '' ? 'close' : 'search'"  
                  placeholder="Pesquise o nome do ícone (English)"
                  solo   
                  @click:append="() => { if(search != '') { search = '' } }" 
                  hide-details  
                  single-line
                  :autofocus="true"
                  dark>
                </v-text-field>
              </div>
            </div>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="closeDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="dialog">
          <v-container fluid style="padding: 0 !important;">
            <v-layout row wrap>
              <v-flex xs12 style="text-align: center !important; padding-bottom: 20px;">
                <h3>Clique sobre o ícone que desejar</h3>
              </v-flex>
              <v-flex xs6 sm4 md3 lg2 v-for="(thisIcon, iconKey) in filteredList" :key="iconKey">
                <v-card v-if="showAll || iconKey < 18" style="margin: 10px; cursor: pointer;" @click="select(thisIcon)" :color="icon == thisIcon ? 'orange' : ''">
                  <v-card-text style="text-align: center !important;">
                    <v-icon x-large color="black">{{thisIcon}}</v-icon>
                    <br>
                    <v-tooltip bottom>
                      <template slot="activator">
                        <span>{{ thisIcon | stringlength(18) }}</span>
                      </template>
                      <span>{{ thisIcon }}</span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex v-if="!showAll" xs12 style="text-align: center !important; padding-top: 20px; padding-bottom: 10px;">
                <v-btn outline large color="green" @click="showAll = true">
                  <v-icon>done_outline</v-icon>
                  <span> Exibir todos</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import {Icons} from '@/default/component/WiGoogleIcons/WiGoogleIcons'
  export default {
    data: () => ({
      dialog: false,
      showAll: false,
      search: ''
    }),
    methods: {
      select: function (icon) {
        this.$emit('select', {icon: icon, field: this.field})
        this.dialog = false
      },
      closeDialog: function () {
        this.dialog = false
      }
    },
    computed: {
      icons () {
        return Icons.get()
      },
      filteredList () {
        return this.icons.filter(thisIcon => {
          return thisIcon.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      setIcon: {
        get () {
          return this.icon
        },
        set () {}
      }
    },
    props: {
      'icon': {
        type: String
      },
      'field': {
        type: String
      },
      'disabled': {
        required: false,
        type: Boolean,
        default: false
      },
      'required': {
        required: false,
        type: Boolean,
        default: false
      },
      'rules': {
        required: false,
        type: Array
      }
    }
  }
</script>
<style scoped>
.asdasdasd >>> a {
  height: 45px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
}
</style>