export default {
  id: {
    id: '7517de8a',
    create: 'b248147d',
    update: '3c8cd4ef',
    delete: '101ce7ab'
  },
  list: true,
  title: {
    list: 'Redes',
    edit: {
      insert: 'Criando nova rede',
      update: 'Editando Rede'
    }
  },
  icon: 'show_chart',
  search: {
    placeholder: 'Pesquise ID, nome, sigla ou tipo de rede',
    fields: ['id', 'nome_grupo', 'sigla_grupo', 'type.nome_tipo']
  },
  apiUrl: 'network',
  params: {
    limit: 10
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  fieldsSelected: {
    itemName: 'rede(s)'
  },
  fields: [
    {
      text: 'Dados básicos desta rede. Nome, Sigla e tipo.',
      type: 'subheader',
      edit: true
    },
    {value: 'id', text: 'ID', list: true},
    {value: 'nome_grupo', counter: 100, text: 'Nome da rede', size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'text', required: true, list: true, edit: true},
    {value: 'sigla_grupo', counter: 30, text: 'Sigla da rede', size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'text', required: false, list: true, edit: true},
    {value: 'type.name', text: 'Tipo da Rede', type: 'text', list: true},
    {value: 'network.name', text: 'Rede Pai', list: true},
    {value: 'grupo_tipo_id', text: 'Tipo desta Rede', size: ['xs12', 'sm12', 'md3', 'lg3'], autocomplete: true, required: false, type: 'dbselect', list: false, edit: true, instore: 'grupos', outstore: 'grupo-tipos', show: 'nome_tipo'},
    {value: 'grupo_dados_id', text: 'Rede Pai', size: ['xs12', 'sm12', 'md3', 'lg3'], autocomplete: true, required: false, type: 'dbselect', list: false, edit: true, instore: 'grupos', outstore: 'grupo-pai', show: 'nome_grupo', fields: false},
    {value: 'active', text: 'Ativo?', size: ['xs12', 'sm12', 'md1', 'lg1'], autocomplete: true, required: false, type: 'switch', list: false, edit: true, instore: 'grupos', outstore: 'grupo-pai', show: 'nome_grupo', fields: false},
    {
      text: 'Observações desta rede.',
      type: 'subheader',
      edit: true
    },
    {value: 'obs_grupo', text: 'Observações', type: 'textarea', list: false, edit: true}
  ]
}
