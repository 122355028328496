export default {
  id: {
    id: '7517de8a',
    create: 'b248147d',
    update: '3c8cd4ef',
    delete: '101ce7ab'
  },
  title: 'Grupos',
  apiUrl: 'grupo/dados',
  // urlDetails: 'grupo-mapa-dispositivos',
  // returnUrl: {name: 'list', params: {page: 'mapas'}},
  fields: [
    {value: 'nome_grupo', counter: 100, text: 'Nome da estação', size: ['xs12', 'sm3', 'md3', 'lg3'], type: 'text', required: true, list: true, edit: true},
    {value: 'sigla_grupo', counter: 30, text: 'Sigla', size: ['xs12', 'sm3', 'md2', 'lg2'], type: 'text', required: false, list: true, edit: true},
    {value: 'nome_tipo', text: 'Tipo', type: 'text', list: true, edit: false},
    {value: 'grupo_pai', counter: 100, text: 'Grupo Pai', type: 'text', list: true, edit: false},
    {value: 'grupo_tipo_id', text: 'Tipo deste Grupo', autocomplete: true, required: false, type: 'dbselect', list: false, edit: true, instore: 'grupos', outstore: 'grupo-tipos', show: 'nome_tipo'},
    {value: 'grupo_dados_id', text: 'Grupo Pai', autocomplete: true, required: false, type: 'dbselect', list: false, edit: true, instore: 'grupos', outstore: 'grupo-pai', show: 'nome_grupo', fields: false},
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    // eslint-disable-next-line
    {value: 'teste', text: 'Disp/Sub-grupos', type: 'link', align: 'center', list: true, edit: false, links: [
      { icon: 'dns', action: (r, d) => { r.push({ name: 'grupo-mapa-dispositivos', params: {id: d.id} }) }, show: () => { return true } },
      { icon: 'device_hub', action: (r, d) => { r.push({ name: 'grupo-mapa-grupos', params: {id: d.id} }) }, show: (d) => { if (d.sub_grupos > 0) { return true } else { return false } } }
    ]},
    {value: 'obs_grupo', text: 'Observações', type: 'textarea', list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
