export default {
  methods: {
    sendProcessStage: function () {
      this.$WiApiPost({
        uri: `processes/execution/${this.execution.id}/stage/${this.stage.id}/${this.stage.stage.type.code}`,
        data: this.data,
        callback: (response) => {
          if (response?.sucess) {
            this.$emit('refresh', response)
            this.$emit('updateProcess', {
              isValid: false,
              data: {}
            })
          }
        },
        config: { msgLoad: true, msgSucess: false, msgError: true, msgAsk: false }
      })
    },
    refresh: function () {
      this.$emit('refresh')
    }
  }
}
