<template>
  <div>
    <view-detail title="Equipe/Departamento" apiUrl="register/team" store="team">
      <template slot-scope="dados">
        <br>
        <v-layout row>
          <v-flex xs12  sm10 offset-sm1>
            <v-card style="margin-bottom: 20px;">
              <v-card-text style="text-align: center;">
                <v-avatar size="100" tile style="border-color: gray; border-width: 1px; border-style: solid;">
                  <img src="static/icons/monitor/management_96.png" alt="Equipe Responsável">
                </v-avatar>
                <h1>{{dados.content.name}}</h1>
                <span v-if="dados.content.person && dados.content.person.name">
                  <v-divider></v-divider>
                  <strong>Líder:</strong>
                  {{dados.content.person.name}}<br>
                </span>
                <span v-if="dados.content.description">
                  <v-divider></v-divider>
                  {{dados.content.description}}
                </span>
              </v-card-text>
            </v-card>
            <WiWeekdays
              style="margin-bottom: 20px;"
              :weekdays="dados.content.weekday || {}"
              :data="{
                team_id: dados.content.id
              }"
              @refresh="dados.refresh"
            ></WiWeekdays>
            <div class="hidden-xs-only hidden-sm-only">
              <v-divider></v-divider>
              <v-card style="margin-top: 20px;">
                <v-expansion-panel v-model="panelMembers">
                  <v-expansion-panel-content dark>
                    <template v-if="panelMembers !== 0" v-slot:header>
                      <div>
                        <v-icon>people</v-icon>
                        <strong style="font-size: 18px;">Membros da equipe <span>( {{dados.content.members_count}} )</span></strong>
                      </div>
                    </template>
                    <div>
                      <wi-list-component v-if="panelMembers == 0" store="team-person" :params="{where: 'team_id,' + dados.content.id}" :new-data="{team_id: dados.content.id}"></wi-list-component>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card>
              <v-divider></v-divider>
              <v-card v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}" style="margin-top: 20px;">
                <v-expansion-panel v-model="panelNetworks">
                  <v-expansion-panel-content dark>
                    <template v-if="panelNetworks !== 0" v-slot:header>
                      <div>
                        <v-icon>show_chart</v-icon>
                        <strong style="font-size: 18px;">Redes Permitidas <span>( {{dados.content.networks_count}} )</span></strong>
                      </div>
                    </template>
                    <div>
                      <wi-list-component v-if="panelNetworks == 0" store="team-network" :params="{where: 'team_id,' + dados.content.id}" :new-data="{team_id: dados.content.id}"></wi-list-component>
                    </div>
                    <div v-prms="{'id': '130350fc'}" style="width: 100%; text-align: center;">
                      <br>
                      <dialog-select-group :refresh="dados.refresh"></dialog-select-group>
                      <br>
                      <br>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
        <br>
        <br>
        <br>
      </template>
    </view-detail>
  </div>
</template>

<script>
  import WiWeekdays from '@/default/component/WiWeekdays'
  import DialogSelectGroup from '@/modules/network/components/DialogSelectGroup'
  export default {
    name: 'Team',
    data () {
      return {
        panelMembers: null,
        panelNetworks: null
      }
    },
    computed: {
      system () {
        return this.$store.state.system || []
      }
    },
    methods: {
      getDayName: function (dayId) {
        switch (dayId) {
          case 1:
            return 'Segunda-feira'
          case 2:
            return 'Terça-feira'
          case 3:
            return 'Quarta-feira'
          case 4:
            return 'Quinta-feira'
          case 5:
            return 'Sexta-feira'
          case 6:
            return 'Sábado'
          case 7:
            return 'Domingo'
        }
      }
    },
    components: {
      WiWeekdays,
      DialogSelectGroup
    }
  }
</script>