<template>
  <v-list dense v-if="selectedModule && selectedModule.menu">
    <template v-for="(item, itemKey) in selectedModule.menu">
      <div v-if="item.children && item.children.length > 0" :key="itemKey">
        <v-list-group
          v-if="checkPermission(item.id)"
          :class="checkMenuDevices(item)"
          v-model="item.model"
          no-action
          :append-icon="drawerLeft.isMini ? '' : $vuetify.icons.expand"
          v-ripple
        >
          <v-divider></v-divider>
          <v-list-tile slot="activator" style="cursor: pointer;">
            <v-list-tile-action style="min-width: 32px;">
              <v-tooltip v-if="drawerLeft.isMini" right>
                <span slot="activator">
                  <v-icon>{{ item.icon }}</v-icon>
                </span>
                <span style="font-size: 12px;">
                  {{ item.title }}
                </span>
              </v-tooltip>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                <span>{{ item.title }}</span>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <div v-for="(child, childKey) in item.children" :key="childKey">
            <v-list-tile
              v-if="checkPermission(child.id)"
              :class="checkMenuDevices(child)"
              :style="{background: $wiDark ? '#515151' : '#e5e5e5'}"
              @click="goRoute(child.route || false)"
              style="cursor: pointer;"
            >
              <template>
                <v-list-tile-action style="min-width: 32px;" v-if="child.icon">
                  <v-tooltip v-if="drawerLeft.isMini" right>
                    <span slot="activator">
                      <v-icon>{{ child.icon }}</v-icon>
                    </span>
                    <span >{{ child.title }}</span>
                  </v-tooltip>
                  <v-icon v-else>{{ child.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <span style="font-size: 12px;">{{ child.title }}</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </template>
            </v-list-tile>
          </div>
        </v-list-group>
      </div>
      <div v-else :key="itemKey">
        <div
          v-if="checkPermission(item.id)"
          :class="checkMenuDevices(item)"
        >
          <v-list-tile
            @click="goRoute(item.route || false)"
            :key="itemKey"
            v-ripple
          >
            <v-list-tile-action style="min-width: 32px;">
              <v-tooltip v-if="drawerLeft.isMini" right>
                <span slot="activator">
                  <v-icon>{{ item.icon }}</v-icon>
                </span>
                <span>{{ item.title }}</span>
              </v-tooltip>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                <span style="font-size: 12px;">{{ item.title }}</span>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </div>
    </template>
  </v-list>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  export default {
    name: 'LeftDrawerListMenu',
    data () {
      return {}
    },
    computed: {
      ...mapState('Pages/Main', ['selectedModule', 'dark', 'drawerLeft']),
      ...mapGetters('User', ['checkPermission'])
    },
    methods: {
      checkMenuDevices: function (menu) {
        var deviceClass = {}
        if (menu && menu.hideOnDevices) {
          menu.hideOnDevices.filter(device => {
            if (device === 'mobile') {
              deviceClass['hidden-xs-only'] = true
              deviceClass['hidden-sm-only'] = true
            }
            if (device === 'desktop') {
              deviceClass['hidden-md-only'] = true
              deviceClass['hidden-lg-only'] = true
              deviceClass['hidden-xl-only'] = true
            }
          })
        }
        return deviceClass
      },
      goRoute: function (route) {
        if (route) {
          this.$router.push(route)
        }
      }
    }
  }
</script>

<style>
  .list__group__items--no-action .list__tile {
    padding-left: 16px !important;
  }
  .list {
    padding: 0 !important;
  }
  .application .theme--dark.navigation-drawer, .theme--dark .navigation-drawer {
    background: #212121 !important;
  }
  .v-list__group__items--no-action .v-list__tile {
    padding-left: 16px !important;
  }
</style>