<template>
  <div>
    <v-divider></v-divider>
    <v-card-title class="value-title">
      <v-icon color="white">attach_money</v-icon>
      <label><strong>Valor:</strong></label>
      <v-spacer></v-spacer>
      <strong>{{ item.total_liquid | money }}</strong>
    </v-card-title>
  </div>
</template>

<script>
export default {
  name: 'BusinessCardValue',
  props: {
    item: { type: Object, required: true }
  }
}
</script>

<style scoped>
.value-title {
  padding: 5px;
  background: #0080009c;
  color: white;
}
</style> 