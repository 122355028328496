import { render, staticRenderFns } from "./DiagnosticStatus.vue?vue&type=template&id=e21d3eaa&scoped=true&"
import script from "./DiagnosticStatus.vue?vue&type=script&lang=js&"
export * from "./DiagnosticStatus.vue?vue&type=script&lang=js&"
import style0 from "./DiagnosticStatus.vue?vue&type=style&index=0&id=e21d3eaa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e21d3eaa",
  null
  
)

export default component.exports