var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"1px"}},[_c('WNViewList',{attrs:{"wiConfig":"well","toolbar":{
      icon: 'map',
      title: 'Poços por Georreferenciamento',
      color: 'primary',
      refresh: true,
      close: false
    },"serviceParams":{
      limit: 1000,
      whereNotNull: 'address_id',
      fields: 'id,uuid,person_id,business_category_id,business_status_id,address_id,latitude,longitude,location_name',
      with: 'status:id,name,icon,color;category:id,name,icon,color,form_id'
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var data = ref.data;
    var refresh = ref.refresh;
    var addNewItem = ref.addNewItem;
return [_c('v-card',{staticStyle:{"margin":"10px"}},[(data && data.data && data.data.length > 0)?_c('div',[_c('WiGoogleMaps',{attrs:{"defaultMapMode":"hybrid","markers":_vm.formatMakers(data.data)},on:{"markerClick":_vm.markerClick}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"75%","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.selectedWellDialog),callback:function ($$v) {_vm.selectedWellDialog=$$v},expression:"selectedWellDialog"}},[(_vm.selectedWellDialog && _vm.selectedWell && _vm.selectedWell.id)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_c('v-icon',[_vm._v("waves")]),_c('span',[_vm._v("Detalhes do Poço")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedWellDialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-content',{staticStyle:{"height":"500px","overflow-y":"scroll"}},[_c('Well',{attrs:{"well-id":_vm.selectedWell.id}})],1)],1):_vm._e()],1)],1):_c('WNNotFoundMessage',{on:{"onAdd":addNewItem,"onRefresh":refresh}})],1)]}}])},[_c('template',{slot:"toolbarActionsBefore"},[_c('v-btn',{attrs:{"icon":_vm.$vuetify.breakpoint.xsOnly,"small":!_vm.$vuetify.breakpoint.xsOnly,"outline":!_vm.$vuetify.breakpoint.xsOnly,"color":!_vm.$vuetify.breakpoint.xsOnly ? 'white' : ''},on:{"click":function($event){return _vm.$WiListDialog({wiConfig: 'well'})}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("list")]),(!_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v("Listar todos os poços")]):_vm._e()],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }