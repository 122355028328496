import env from '@/default/store/modules/url/url'
import lang from '@/lang/lang'

export class Lang {
  static langText (textId, text, data) {
    return this.getReturnText(textId, data) || text
  }
  static getReturnText (textId, data) {
    const currentLang = this.getCurrentLang()
    if (currentLang) {
      return this.getLangSelected(textId, currentLang, data)
    }
    return null
  }
  static getLangSelected (textId, currentLang, data) {
    var langSelected = lang[currentLang]
    if (langSelected) {
      return this.getTextSelected(langSelected, textId, data)
    }
    return null
  }
  static getTextSelected (langSelected, textId, data) {
    var textSelected = langSelected.content[textId]
    if (textSelected) {
      return this.checkTextSelectedType(textSelected, data)
    }
    return null
  }
  static checkTextSelectedType (textSelected, data) {
    if (typeof textSelected === 'function') {
      return textSelected(data)
    }
    return textSelected
  }
  static getCurrentLang () {
    const currentLang = env.state.lang // localStorage.getItem('current-lang')
    return currentLang
  }
}
