<template>
	<v-navigation-drawer app color="blue-grey darken-4" temporary right v-model="drawerRight.active" :touchless="touch" width="360">
    
    <v-toolbar dense>
      <v-toolbar-title style="margin-left: 0px;">
        <v-icon>notifications</v-icon>
        <span v-if="total > 0"> {{total}} </span>
        <span v-if="total == 1"> {{ $tc('components.drawerRight.title', total) }}}</span>
        <span v-else> {{ $tc('components.drawerRight.title', total) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="getNotifications()">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn icon @click="readAllNotifications()">
        <v-icon>clear_all</v-icon>
      </v-btn>
      <v-btn icon @click="TOUGGLE_DRAWER_RIGHT_ACTIVE()">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" style="margin: 0px !important;" indeterminate></v-progress-linear>
  
    <v-list v-if="system.versionApi != system.version" three-line>
      <template>
        <v-list-tile avatar ripple @click="loadUpdate()">
          <v-list-tile-content>
            <v-list-tile-sub-title class="text--primary">
              <strong
                v-t="'components.drawerRight.messages.newUpdateAvailable'"
              ></strong>
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text style="text-align: right;">{{system.lastUpdate}}</v-list-tile-action-text>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider></v-divider>
      </template>
    </v-list>

    <Notifications></Notifications>

  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import Notifications from '@/default/pages/MainNew/components/DrawerRight/Notifications'
  export default {
    name: 'RightDrawer',
    computed: {
      ...mapState('Pages/Main', ['drawerRight', 'selectedModule']),
      ...mapState('Pages/Main/Notifications', ['total', 'loading']),
      touch () {
        return this.$route.meta.touch || false
      },
      system () {
        return this.$store.state.system || []
      }
    },
    methods: {
      ...mapMutations('Pages/Main', ['TOUGGLE_DRAWER_RIGHT_ACTIVE']),
      ...mapActions('Pages/Main/Notifications', ['getNotifications', 'readAllNotifications']),
      loadUpdate: function () {
        if (this.system.type === 'mobile') {
          window.open(this.system.mobile_link, '_system')
        } else {
          location.reload()
        }
      },
      checkIfNotificationSelected: function () {
        if (this.$route.query && this.$route.query.notification) {
          this.TOUGGLE_DRAWER_RIGHT_ACTIVE()
        }
      }
    },
    mounted: function () {
      this.checkIfNotificationSelected()
    },
    components: {
      Notifications
    }
  }
</script>