import Well from '@/modules/wells/pages/Well'
import WellsMap from '@/modules/wells/pages/WellsMap'
import Contract from '@/modules/sales/pages/Contract'
import Guarantee from '@/modules/sales/pages/Guarantee'
import FormReplyList from '@/modules/forms/pages/FormReplyList'
import FormReplyEdit from '@/modules/forms/pages/FormReplyEdit'
import BusinessDashboard from '@/modules/shared/pages/BusinessDashboard/BusinessDashboard'
import {
  PERMISSION_WELL,
  PERMISSION_WELL_UPDATE,
  PERMISSION_CONTRACT,
  PERMISSION_CONTRACT_UPDATE,
  PERMISSION_GUARANTEE,
  PERMISSION_GUARANTEE_UPDATE
} from '@/default/constants/permissions'

export default [
  {
    path: 'well/:id',
    name: 'well',
    component: Well,
    meta: {
      id: PERMISSION_WELL,
      auth: true,
      title: 'Detalhes do Poço',
      icon: 'waves',
      permission: PERMISSION_WELL_UPDATE,
      config: {
        code: 32,
        name: 'well',
        title: 'Poço Tubular',
        icon: 'waves',
        permission: {
          update: PERMISSION_WELL_UPDATE
        }
      }
    }
  },
  {
    path: 'wells-geolocation',
    name: 'wells-geolocation',
    component: WellsMap,
    meta: {
      id: PERMISSION_WELL,
      auth: true,
      title: 'Poços por Georreferenciamento',
      icon: 'waves',
      permission: PERMISSION_WELL_UPDATE
    }
  },
  {
    path: 'contract/:id',
    name: 'contract',
    component: Contract,
    meta: {
      id: PERMISSION_CONTRACT,
      auth: true,
      title: 'Detalhes do Contrato',
      icon: 'border_color',
      permission: PERMISSION_CONTRACT_UPDATE,
      config: {
        code: 31,
        name: 'contract',
        title: 'Contrato',
        icon: 'border_color',
        permission: {
          update: PERMISSION_CONTRACT_UPDATE
        }
      }
    }
  },
  {
    path: 'guarantee/:id',
    name: 'guarantee',
    component: Guarantee,
    meta: {
      id: PERMISSION_GUARANTEE,
      auth: true,
      title: 'Detalhes do Termo de Garantia',
      icon: 'privacy_tip',
      permission: PERMISSION_GUARANTEE_UPDATE,
      config: {
        code: 33,
        name: 'guarantee',
        title: 'Termo de Garantia',
        icon: 'privacy_tip',
        permission: {
          update: PERMISSION_GUARANTEE_UPDATE
        }
      }
    }
  },
  {
    path: 'form-reply-list/:id',
    name: 'form-reply-list',
    component: FormReplyList,
    meta: {
      auth: true,
      title: 'form-reply-list',
      icon: 'privacy_tip'
    }
  },
  {
    path: 'form-reply-edit/:id',
    name: 'form-reply-edit',
    component: FormReplyEdit,
    meta: {
      auth: true,
      title: 'form-reply-edit',
      icon: 'privacy_tip'
    }
  },
  {
    path: 'dashboard-wells',
    name: 'dashboard-wells',
    component: BusinessDashboard,
    meta: {
      id: PERMISSION_WELL,
      auth: true,
      title: 'Dashboard de Poços Tubulares',
      icon: 'dashboard',
      moduleName: 'wells'
    }
  }
]
