export default {
  id: '31f8eef9',
  title: 'Tipo do Dispositivo',
  apiUrl: 'disp/tipo',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_tipo', counter: 100, text: 'Nome', type: 'text', required: true, list: true, edit: true},
    {value: 'obs_tipo', text: 'Observações', type: 'textarea', list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
