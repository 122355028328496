<template>
  <div class="ranking-podium">
    <!-- Fotos e Nomes -->
    <div class="winners-info">
      <!-- 2º Lugar -->
      <div v-if="sortedCollaborators[1]" class="winner second">
        <div class="photo-container">
          <v-avatar size="60" class="elevation-8">
            <img v-if="sortedCollaborators[1].file" :src="sortedCollaborators[1].file.url" :alt="sortedCollaborators[1].name">
            <v-icon v-else size="40" color="grey darken-1">person</v-icon>
          </v-avatar>
        </div>
        <div class="winner-details">
          <div class="name">{{ sortedCollaborators[1].name }}</div>
          <div class="amount">{{ formatCurrency(sortedCollaborators[1].amount) }}</div>
        </div>
      </div>

      <!-- 1º Lugar -->
      <div v-if="sortedCollaborators[0]" class="winner first">
        <div class="photo-container">
          <v-avatar size="80" class="elevation-10">
            <img v-if="sortedCollaborators[0].file" :src="sortedCollaborators[0].file.url" :alt="sortedCollaborators[0].name">
            <v-icon v-else size="50" color="grey darken-1">person</v-icon>
          </v-avatar>
        </div>
        <div class="winner-details">
          <div class="name">{{ sortedCollaborators[0].name }}</div>
          <div class="amount">{{ formatCurrency(sortedCollaborators[0].amount) }}</div>
        </div>
      </div>

      <!-- 3º Lugar -->
      <div v-if="sortedCollaborators[2]" class="winner third">
        <div class="photo-container">
          <v-avatar size="50" class="elevation-5">
            <img v-if="sortedCollaborators[2].file" :src="sortedCollaborators[2].file.url" :alt="sortedCollaborators[2].name">
            <v-icon v-else size="35" color="grey darken-1">person</v-icon>
          </v-avatar>
        </div>
        <div class="winner-details">
          <div class="name">{{ sortedCollaborators[2].name }}</div>
          <div class="amount">{{ formatCurrency(sortedCollaborators[2].amount) }}</div>
        </div>
      </div>
    </div>

    <!-- Pódio -->
    <div class="podium">
      <!-- 2º Lugar -->
      <div class="position second-position">
        <div class="position-number">2°</div>
      </div>
      
      <!-- 1º Lugar -->
      <div class="position first-position">
        <div class="position-number">1°</div>
      </div>
      
      <!-- 3º Lugar -->
      <div class="position third-position">
        <div class="position-number">3°</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RankingPodium',
  props: {
    collaborators: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedCollaborators() {
      return [...this.collaborators]
        .sort((a, b) => b.amount - a.amount)
        .slice(0, 3)
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)
    }
  }
}
</script>

<style scoped>
.ranking-podium {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
}

.winners-info {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
  gap: 20px;
}

.winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.winner-details {
  margin-top: 10px;
}

.name {
  font-weight: bold;
  font-size: 14px;
}

.amount {
  font-size: 12px;
  color: #666;
}

.first {
  order: 2;
  margin-top: -30px;
}

.second {
  order: 1;
}

.third {
  order: 3;
}

.podium {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  /* height: 200px; */
}

.position {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
  width: 100px;
}

.position-number {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.first-position {
  background: linear-gradient(to bottom, #ffd700, #ffc800);
  height: 150px;
}

.second-position {
  background: linear-gradient(to bottom, #c0c0c0, #a8a8a8);
  height: 120px;
}

.third-position {
  background: linear-gradient(to bottom, #cd7f32, #b87333);
  height: 90px;
}

.photo-container {
  background: white;
  border-radius: 50%;
  padding: 3px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
</style>
