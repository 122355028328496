<template>
  <v-dialog v-model="dialog.active" scrollable persistent :max-width="(88 - (index * 2)) + '%'" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar dense primary>
        <v-toolbar-title>
          <v-icon>attachment</v-icon>
          <span class="ml-2">Lista de Anexos/Arquivos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outline small @click="addFile()">
          <v-icon>add</v-icon>
          <span>Adicionar arquivo</span>
        </v-btn>
        <v-btn icon flat @click="refresh()">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn icon flat @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="dialog.active" style="padding: 0 !important;" :style="{'min-height': (400 - (index * 10)) + 'px'}">
        <WiFileList v-if="showList" :props="dialog"></WiFileList>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import WiFileList from './WiFileList'
  export default {
    name: 'WiFileListDialog',
    data: () => ({
      showList: true
    }),
    methods: {
      close: function () {
        this.$store.dispatch('WiFileListDialog/close', this.index)
        this.$forceUpdate()
      },
      addFile: function () {
        this.$WiFileDialog({
          info: this.dialog?.info,
          onSuccess: () => {
            this.refresh()
          }
        })
      },
      refresh: function () {
        this.showList = false
        this.$forceUpdate()
        setTimeout(() => {
          this.showList = true
          this.$forceUpdate()
        }, 100);
      }
    },
    computed: {
      dialog () {
        var WiFileListDialog = this.$store.state.WiFileListDialog
        const dialog = WiFileListDialog.dialogs[this.index]
        return {
          ...dialog,
          index: `wi-file-list-dialog-${this.index}`,
          api: {
            url: dialog?.api?.url || 'system/file',
            params: dialog?.api?.filter ? `?${new URLSearchParams(dialog.api.filter).toString()}` : null,
          },
          permission: dialog.permission || null
        }
      }
    },
    props: {
      'index': {
        default: 0
      }
    },
    components: {
      WiFileList
    }
  }
</script>