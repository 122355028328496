<template>
  <v-list two-line subheader>
    <ProducerModelSelectSubHeader
      v-if="!host.producer_model_id || host.producer_model_id == 1"
      :host="host"
      class="hidden-xs-only hidden-sm-only"
      @onSelectModel="onSelectModel"
    ></ProducerModelSelectSubHeader>
    <ProducerModelConfigSubHeader
      v-if="host.producer_model_id && showConfig"
      :model="host.producer_model"
      :host="host"
      @onSaved="onSaveConfig"
    ></ProducerModelConfigSubHeader>
    <v-list-tile avatar class="mb-3 mt-3">
      <HostSignalDialog :host="host">
        <v-list-tile-avatar style="cursor: pointer;">
          <v-icon class="green white--text" v-if="host.online">
            router
          </v-icon>
          <v-icon class="red white--text" v-else> router </v-icon>
        </v-list-tile-avatar>
      </HostSignalDialog>
      <v-list-tile-content>
        <v-list-tile-title>
          <span v-if="host.mac_address">
            <strong> MAC: </strong>
            <span>{{ host.mac_address }}</span>
          </span>
          <span v-if="host.ip_address">
            <strong> IP: </strong>
            <span>{{ host.ip_address }}</span>
            <span
              class="ml-2 blue--text"
              style="cursor: pointer;"
              @click="pingDialogAction(true, getNetworkServerId(host), host.ip_address)"
            >(Pingar)</span>
          </span>
          <span v-if="host.serial">
            <strong> Serial: </strong>
            <span>{{ host.serial }}</span>
          </span>
          <span v-if="host.external_code">
            <strong> Código Externo: </strong>
            <span>{{ host.external_code }}</span>
          </span>
        </v-list-tile-title>
        <v-list-tile-sub-title>
          <span v-if="host.technology && host.technology_id > 1">
            <strong> Tecnologia: </strong>
            <span>{{ host.technology.name }}</span>
          </span>
          <span v-if="host.technology_type && host.technology_type_id > 1">
            <strong> Tipo: </strong>
            <span>{{ host.technology_type.name }}</span>
          </span>
        </v-list-tile-sub-title>
        <v-list-tile-sub-title>
          <span v-if="host.producer && host.producer_id > 1">
            <strong> Fabricante: </strong>
            <span>{{ host.producer.name }}</span>
          </span>
          <span v-if="host.producer_model && host.producer_model_id > 1">
            <strong> Modelo: </strong>
            <span>{{ host.producer_model.name }}</span>
          </span>
        </v-list-tile-sub-title>
        <v-list-tile-sub-title>
          <span v-if="host.leds_text">
            <strong> LEDs: </strong>
            <span>{{ host.leds_text }}</span>
          </span>
        </v-list-tile-sub-title>
      </v-list-tile-content>
      <HostProducerModelDialog
        v-if="host.producer_model_id && host.producer_model_id != 1"
        v-model="producerModelDialog"
        :model="host.producer_model"
        :host="host"
        mode="show"
      ></HostProducerModelDialog>
      <v-btn v-if="host.producer_model_id && host.producer_model_id != 1" outline color="green" @click="producerModelDialog = true">
        <v-icon>edit</v-icon>
        <span> Detalhes do equipamento</span>
      </v-btn>
      <!-- <v-btn
        v-if="$WiCheckPermission(PERMISSION_INTERNET_MODULE)"
        v-prms="{ id: permissionContractUpdate }"
        @click="confiance()"
        outline
      >
        LIBERAR
      </v-btn> -->
      <v-btn v-prms="{ id: permissionContractUpdate }" icon @click="$WiEditDialog({ wiConfig: 'host', data: host, onSubmit: onSaveConfig })">
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn icon @click="updateHostSignal()">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-list-tile>
    <v-divider></v-divider>
    <ping-dialog
      v-if="pingDialog.active"
      :network-server-id="pingDialog.server"
      :host-to-ping="pingDialog.host"
      :active="pingDialog.active"
      v-on:active="pingDialogAction"
    ></ping-dialog>
  </v-list>
</template>
<script>
  import { PERMISSION_CONTRACT_UPDATE, PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'
  import PingDialog from '@/modules/network/pages/NetworkServer/Dialogs/PingDialog'
  import ProducerModelSelectSubHeader from './ProducerModel/SelectSubHeader'
  import ProducerModelConfigSubHeader from './ProducerModelConfig/SubHeader'
  import HostProducerModelDialog from './ProducerModel/Dialog'
  import HostSignalDialog from '@/modules/network/pages/Host/HostSignalDialog'
  export default {
    name: 'Host',
    data: () => ({
      producerModelDialog: false,
      pingDialog: {
        active: false,
        host: null,
        server: null
      }
    }),
    computed: {
      PERMISSION_INTERNET_MODULE () {
        return PERMISSION_INTERNET_MODULE
      },
      permissionContractUpdate () {
        return PERMISSION_CONTRACT_UPDATE
      }
    },
    methods: {
      onSelectModel: function (selectedModel) {
        this.$emit('onSelectModel', selectedModel)
      },
      onSaveConfig: function (savedConfig) {
        this.$emit('onSaveConfig', savedConfig)
      },
      pingDialogAction: function (active = false, server = null, host = null) {
        this.pingDialog.host = host
        this.pingDialog.server = server
        this.pingDialog.active = active
      },
      getNetworkServerId: function(host) {
        if (host?.active_devices && host?.active_devices?.length > 0) {
          return host?.active_devices[0]?.network_server.id
        }
        return null
      },
      updateHostSignal: function () {
        this.$WiApiPost({
          uri: `network/host/${this.host.id}/information`,
          data: {
            host_id: this.host.id
          },
          callback: () => this.$emit('refresh'),
          config: {
            msgLoad: {
              title: `Verificando potência...`,
              text: 'Pode demorar alguns segundos.'
            },
            msgSucess: {
              title: 'Tudo certo!',
              text: 'Informações atualizadas com sucesso!'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja realizar nova leitura?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          }
        })
      },
      confiance: function () {
        this.$WiApiPut({
          uri: `network/host/${this.host.id}`,
          data: {},
          callback: () => this.$emit('refresh'),
          config: {
            msgLoad: {
              title: `Liberando cliente...`,
              text: 'Pode demorar alguns segundos.'
            },
            msgSucess: {
              title: 'Tudo certo!',
              text: 'Cliente liberado com sucesso!'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja liberar este cliente?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          }
        })
      }
    },
    props: {
      host: {
        required: true,
        default: {}
      },
      showConfig: {
        type: Boolean,
        default: false
      }
    },
    components: {
      PingDialog,
      HostSignalDialog,
      HostProducerModelDialog,
      ProducerModelSelectSubHeader,
      ProducerModelConfigSubHeader,
    }
  }
</script>
