<template>
  <WiViewList
    :Service="service"
    :service-params="serviceParams"
    :Socket="socket"
    :socket-params="socketParams"
    :toolbar="{
      title,
      icon: 'message'
    }"
    :loadingConfig="{
      message: 'Buscando eventos/notas...'
    }"
    @onClose="onClose"
  >
    <template slot-scope="{ data, hasNextPage, nextPage, loadingPaginate }">
      <div style="padding: 0 10px 0 10px;">
        <AddEvent
          :business="business"
        ></AddEvent>
        <div v-if="data && data.length > 0">
          <v-timeline :dense="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
            <WiEventsEvent
              v-for="(event, eventKey) in data"
              :key="eventKey"
              :event="event"
              :business="business"
            ></WiEventsEvent>
          </v-timeline>
          <div class="wi-events__paginate">
            <v-btn :disabled="!hasNextPage" :loading="loadingPaginate" @click="nextPage()">
              Buscar mais
            </v-btn>
          </div>
        </div>
        <div v-else style="text-align: center; padding: 20px;">
          <v-alert :value="true" type="warning" >
            <span>Nenhum evento/nota até o momento.</span>
          </v-alert>
        </div>
      </div>
    </template>
  </WiViewList>
</template>
<script>
  import WiViewList from '@/default/component/WiViewList'
  import WiEventsEvent from './components/Event'
  import AddEvent from './components/AddEvent'
  export default {
    methods: {
      onClose: function () {
        this.$emit('onClose')
      }
    },
    props: {
      title: {
        required: true
      },
      service: {
        required: true
      },
      serviceParams: {
        default: {}
      },
      socket: {
        default: null
      },
      socketParams: {
        default: {}
      },
      business: {
        type: Object,
        required: true
      }
    },
    components: {
      AddEvent,
      WiViewList,
      WiEventsEvent
    }
  }
</script>
<style>
  .v-timeline-item__inner-dot .primary {
    background-color: white !important;
  }
  .wi-events__paginate {
    width: 100% !important;
    text-align: center;
    margin-bottom: 20px;
  }
</style>
