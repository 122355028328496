<template>
  <v-card>
    <!-- eslint-disable -->
    <v-list dense style="max-height: none !important;">
      <v-list-tile v-ripple avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <h4 @click="$emit('action', 'tickets-by-status')" style="cursor: pointer;">
              <v-icon style="height: 15px;"></v-icon>
              <span>Chamados por Status</span>
            </h4>
          </v-list-tile-title>
        </v-list-tile-content>
        <!-- <v-list-tile-action style="text-align: center; padding: 0 5px;min-width: 112px;">
          <v-list-tile-sub-title style="font-size:12px;">
            <span>Meus<br>Chamados</span>
          </v-list-tile-sub-title>
        </v-list-tile-action> -->
        <v-list-tile-action v-prms="{id: '4aa0482a'}" style="text-align: center; padding: 0 5px;">
          <v-list-tile-sub-title style="font-size:12px;">
            <span>Todos os<br>Chamados</span>
          </v-list-tile-sub-title>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile @click="$router.push({name: 'service-desk-list', params: {page: 'ticket-opened'}, query: {status: status.id}})" v-ripple avatar v-for="(status, statusKey) in data" :key="statusKey" v-if="!limit || statusKey < limit">
        <v-list-tile-avatar size="45">
          <v-btn icon ripple>
            <v-icon x-large :color="status.cor_status">{{status.icone_status}}</v-icon>
          </v-btn>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title style="font-size:18px;">
            <span>{{status.nome_status}}</span>
          </v-list-tile-title>
          <v-list-tile-sub-title style="font-size: 12px;">Status</v-list-tile-sub-title>
        </v-list-tile-content>
        <!-- <v-list-tile-action style="padding: 0 5px;">
          <v-list-tile-title style="text-align: center; font-size:24px;">
            <span>{{status.num_chamados_solicitante}}</span>
          </v-list-tile-title>
        </v-list-tile-action>
        <v-list-tile-action style="padding: 0 5px;">
          <v-list-tile-title style="text-align: center; font-size:24px;">
            <span>{{status.num_chamados_responsavel}}</span>
          </v-list-tile-title>
        </v-list-tile-action> -->
        <v-list-tile-action v-prms="{id: '4aa0482a'}" style="padding: 0 5px;">
          <v-list-tile-title style="text-align: center; font-size:24px;">
            <span>{{status.num_chamados_total}}</span>
          </v-list-tile-title>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-card>     
</template>
<script>
  export default {
    name: 'ServiceDeskDashboardTicketsByStatus',
    props: ['data', 'limit']
  }
</script>