export default {
  id: 'ntwk',
  title: 'Mapa',
  apiUrl: 'mapa/dados',
  urlDetails: 'mapa',
  returnUrl: {name: 'list', params: {page: 'mapas'}},
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_mapa', counter: 30, text: 'Nome', type: 'text', required: true, list: true, edit: true},
    {value: 'tipo_mapa', text: 'Tipo deste Mapa', type: 'select', required: true, options: [{value: 1, text: 'Mapa de Dispositivos'}, {value: 2, text: 'Mapa de Grupos'}], list: false, edit: true},
    {value: 'obs_mapa', text: 'Observações', type: 'textarea', list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
