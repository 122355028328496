<template>
  <v-list subheader two-line dense :class="{'grey lighten-1': data.content.ativo_servidor != 'ativo'}">
    <v-list-tile>
      <v-list-tile-avatar >
        <v-avatar>
          <v-icon x-large>
            cloud_queue 
          </v-icon>
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title style="color:#1C8ADB; font-size: 18px;">
          <strong>
            <span>{{ data.content.nome_servidor }}</span>
            <span v-if="data.content.ativo_servidor != 'ativo'" style="color: #FFF;"> (Desativado) </span>
          </strong>
        </v-list-tile-title>
        <v-list-tile-sub-title>
          <label 
          style="font-size: 14px; color: white; padding: 2px 5px;" 
          :style="{background: status == 'online' ? 'green' : (status == 'offline' ? 'red' : 'grey')}"
          >
            Status: {{status}}
          </label>
          <span style="padding-left: 15px;">Versão atual: {{data.content.application.version}}</span>
          <span v-if="information.version" style="padding-left: 15px;">Versão em execução: {{information.version}}</span>
        </v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-progress-circular large v-if="interval.status" color="primary" :value="interval.count.porcentage()">
          {{interval.count.value}}
        </v-progress-circular>
        <v-icon v-else color="red" x-large>warning</v-icon>
      </v-list-tile-action>
      <v-list-tile-action>
        <v-btn :disabled="status !== 'online'" icon @click="update()">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider v-prms="{'id': '529e4c78'}"></v-divider>
    <v-list-tile v-if="data.content.ativo_servidor == 'ativo'" v-prms="{'id': '529e4c78'}">
      <v-list-tile-avatar >
        <v-avatar>
          <v-icon x-large>
            vpn_key 
          </v-icon>
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Token de ativação
        </v-list-tile-title>
        <v-list-tile-sub-title>
          <input style="width: 100%;" type="text" v-model="data.content.token_servidor">
        </v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <copy-text-to-clipboard v-if="data.content.token_servidor" title="Token de ativação" :text="data.content.token_servidor"></copy-text-to-clipboard>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>
<script>
  import CopyTextToClipboard from '@/default/components/CopyTextToClipboard/CopyTextToClipboard'
  export default {
    name: 'NetworkServerHeader',
    data () {
      return {}
    },
    methods: {},
    props: {
      data: {
        type: Object
      },
      status: {
        type: String
      },
      interval: {
        type: Object
      },
      update: {
        type: Function
      },
      information: {
        type: Object
      }
    },
    components: {
      CopyTextToClipboard
    }
  }
</script>