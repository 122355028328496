export default {
  id: '31f8eef9',
  title: 'Tipo de Grupo',
  apiUrl: 'grupo/tipo',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_tipo', counter: 30, text: 'Nome do Tipo', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: true, list: true, edit: true},
    {value: 'nivel_tipo', text: 'Nível', size: ['xs12', 'sm3', 'md2', 'lg2'], type: 'int', required: false, list: false, edit: true},
    {value: 'funcao_tipo', text: 'Função', size: ['xs12', 'sm3', 'md3', 'lg3'], type: 'select', options: [{value: 1, text: 'Grupo de Sub-grupos'}, {value: 2, text: 'Grupo de Dispositivos'}], required: false, list: false, edit: true},
    {value: 'icone_tipo', text: 'Ícone', size: ['xs12', 'sm3', 'md4', 'lg4'], type: 'wi-google-icons', required: false, list: false, edit: true},
    {text: 'O campo de Observações pode ser usado para descrever este tipo de grupo.', type: 'subheader', edit: true},
    {value: 'obs_tipo', text: 'Observações do Tipo', type: 'textarea', required: false, list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
