<template>
  <div v-if="complement">
    <v-card-text>
      <v-layout v-if="complement.model" row wrap>
        <v-flex xs12 d-flex style="padding-right: 5px;">
          <ProducerModel
            :model="complement.model"
          ></ProducerModel>
        </v-flex>
      </v-layout>
      <v-layout v-else row wrap>
        <v-flex xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;" v-for="(action, actionKey) in actions" :key="actionKey">
          <ProducerModels
            :action="action"
          ></ProducerModels>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>
<script>
  import ProducerModels from './ProducerModels'
  import ProducerModel from './ProducerModel'
  export default {
    name: 'DiagnosticItems',
    computed: {
      signal () {
        return this.complement?.signal
      }
    },
    props: {
      complement: {
        required: true,
        default: {}
      },
      status: {
        required: true,
        default: () => ({})
      },
      actions: {
        required: true,
        default: () => ([])
      }
    },
    components: {
      ProducerModels,
      ProducerModel
    }
  }
</script>
