<template>
  <div style="width: 100%;">
    <div v-if="icon">
      <div class="hidden-xs-only hidden-sm-only">
        <v-autocomplete
          v-model="form[formField]"
          :items="content"
          :label="label" 
          :placeholder="loading ? 'Buscando, aguarde...' : getPlaceholder"
          :item-text="show"
          :disabled="loading || disabled || error"
          :item-value="value || 'id'"
          :value-comparator="comparator"
          :loading="loading"
          :required="required"
          :rules="rules"
          :autocomplete="false"
          :multiple="multiple"
          :return-object="object"
          :clearable="multiple || clearable"
          @change="onChange($event)"
          :small-chips="multiple"
          :deletable-chips="multiple"
        >
          <div slot="item" slot-scope="props">
            <span v-if="icon" style="padding-right: 10px;">
              <v-icon :style="{color: props.item[color] || 'red'}">{{props.item[icon]}}</v-icon>
            </span>
            <span>{{props.item[show]}}</span>
          </div>
          <div slot="selection" slot-scope="props">
            <span v-if="icon" style="padding: 0 5px 0 10px;">
              <v-icon :style="{color: props.item[color] || 'red'}">{{props.item[icon]}}</v-icon>
            </span>
            <span>{{props.item[show]}}</span>
          </div>
        </v-autocomplete>
      </div>
      <div class="hidden-md-only hidden-lg-only hidden-xl-only">
        <v-select 
          dense
          v-model="form[formField]"
          :items="content"
          :label="label" 
          :placeholder="loading ? 'Buscando, aguarde...' : getPlaceholder"
          :item-text="show"
          :disabled="loading || disabled || error"
          :item-value="value || 'id'"
          :value-comparator="comparator"
          :loading="loading"
          :required="required"
          :rules="rules"
          :multiple="multiple"
          :return-object="object"
          :clearable="multiple || clearable"
          @change="onChange($event)"
        >
          <div slot="selection" slot-scope="props">
            <span v-if="icon" style="padding-right: 10px;">
              <v-icon :style="{color: props.item[color] || 'red'}">{{props.item[icon]}}</v-icon>
            </span>
            <span>{{props.item[show]}}</span><br>
          </div>
        </v-select>
      </div>
    </div>
    <div v-else>
      <div class="hidden-xs-only hidden-sm-only">
        <v-autocomplete
          v-model="form[formField]"
          :items="content"
          :label="label" 
          :placeholder="loading ? 'Buscando, aguarde...' : getPlaceholder"
          :item-text="show"
          :disabled="loading || disabled || error"
          :item-value="value || 'id'"
          :value-comparator="comparator"
          :loading="loading"
          :required="required"
          :rules="rules"
          :autocomplete="false"
          :multiple="multiple"
          :return-object="object"
          :clearable="multiple || clearable"
          @change="onChange($event)"
          :small-chips="multiple"
          :deletable-chips="multiple"
        ></v-autocomplete>
      </div>
      <div class="hidden-md-only hidden-lg-only hidden-xl-only">
        <v-select 
          dense
          v-model="form[formField]"
          :items="content"
          :label="label" 
          :placeholder="loading ? 'Buscando, aguarde...' : getPlaceholder"
          :item-text="show"
          :disabled="loading || disabled || error"
          :item-value="value || 'id'"
          :value-comparator="comparator"
          :loading="loading"
          :required="required"
          :rules="rules"
          :multiple="multiple"
          :return-object="object"
          :clearable="multiple || clearable"
          @change="onChange($event)"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'DBAutocompleteV1',
    data () {
      return {
        content: [],
        loading: false,
        error: false
      }
    },
    computed: {
      getPlaceholder () {
        var p = this.placeholder || this.label
        return p
      }
    },
    beforeDestroy: function () {},
    props: ['apiUrl', 'apiParams', 'form', 'formField', 'value', 'show', 'label', 'placeholder', 'disabled', 'required', 'rules', 'autocomplete', 'multiple', 'clearable', 'object', 'icon', 'color', 'comparator', 'change'],
    mounted: function () {
      this.load()
    },
    methods: {
      load: function () {
        this.loading = true
        var params = this.apiParams || {}
        if (!params.limit) { params['limit'] = 10000 }
        callApi.get({uri: this.apiUrl, params: params || '', sucess: this.loadSucess, error: this.loadError})
      },
      loadSucess: function (data) {
        this.content = data.data.data
        this.loading = false
        if (!this.form[this.formField] && this.content.length === 1 && !this.multiple) {
          this.form[this.formField] = this.content[0].id
        }
      },
      loadError: function (error) {
        this.error = true
        this.loading = false
        console.log(error)
      },
      setField: function ({ field, data }) {
        this.form[field] = data
        this.$forceUpdate()
      },
      onChange: function () {
        this.$forceUpdate()
        if (this.change) {
          this.change({
            form: this.form,
            selected: this.form[this.formField],
            items: this.content,
            setField: this.setField
          })
        }
        this.$forceUpdate()
      }
    }
  }
</script>