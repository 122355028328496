import Vue from 'vue'

export class DateTimeCurrent {
  static format (dateTime, currentFalback = '- - -') {
    const dateTimeFormated = {
      current: currentFalback,
      dateTime: 'Não definido'
    }
    if (dateTime) {
      const date = Vue.moment(dateTime)
      const today = Vue.moment().startOf('day')
      const days = Math.round(Vue.moment.duration(today - date).asDays())
      if (days > 1) {
        dateTimeFormated.current = Vue.moment(dateTime).format('DD/MM/YY HH:mm')
      } else {
        dateTimeFormated.current = Vue.moment(dateTime).fromNow()
      }
      dateTimeFormated.dateTime = Vue.moment(dateTime).format('DD/MM/YY HH:mm:ss')
    }
    return dateTimeFormated
  }
}