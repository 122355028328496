import {Model} from '@/default/model/Model'

export class BusinessLayout extends Model {
  constructor () {
    super()
    this.actions = {
      update: true
    }
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    description: this.fieldTinyEditor({
      value: 'description',
      text: 'Layout'
    })
  }
}
