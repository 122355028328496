<template>
  <div>
    <v-card v-if="promotion" class="elevation-17">
      <v-toolbar dense flat color="transparent" class="business-internet-plan__toolbar">
        <v-toolbar-title>
          <v-icon color="primary">calculate</v-icon>
          <span> Promoção aplicada</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="searchPromotion()" :disabled="business.stage != 1" outline small>
          <v-icon>edit</v-icon>
          <span> Mudar Promoção</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="pt-0 pb-0 pl-0 pr-0">
        <v-list two-line subheader>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon class="blue white--text">price_change</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <strong>{{ promotion.name }}</strong>
                <span> - Valor: </span>
                <strong v-if="promotion.type.code == 'fixed-value-discount' || promotion.type.code == 'fixed-value-addition'">
                  {{ promotion.value | money }}
                </strong>
                <strong v-else>
                  {{ promotion.value }}%
                </strong>
              </v-list-tile-title>
              <v-list-tile-sub-title>
                <span v-if="promotion.type.code == 'fixed-value-discount'">
                  <span>Desconto de {{ promotion.value | money }} </span>
                  <span v-if="promotion.invoice_number_start > 0">
                      após a {{ promotion.invoice_number_start }} fatura 
                  </span>
                  <span v-if="promotion.invoice_number_end > 0">
                      até a {{ promotion.invoice_number_end }} fatura 
                  </span>
                </span>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card v-else class="elevation-17 promotion__not-selected" flat>
      <v-card-text class="promotion__not-selected-content">
        <h4>Adicione ou selecione uma promoção.</h4>
        <v-btn :disabled="business.stage != 1" @click="searchPromotion()" outline>
          <v-icon>search</v-icon>
          <span> Buscar Promoção</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  export default {
    name: 'BusinessPromotion',
    methods: {
      searchPromotion: function () {
        this.$WiListDialog({
          wiConfig: 'internet-contract-promotion',
          filter: {
            where: 'status,1'
          },
          mode: 'select',
          callback: this.selectPromotion
        })
      },
      selectPromotion: function (promotion) {
        if (promotion) {
          const data = {
            id: this.business.id,
            promotion_id: promotion.id
          }
          this.$WiApiPut({
            uri: 'business/business',
            id: this.business.id,
            data,
            config: {
              msgLoad: true,
              msgSucess: true
            },
            callback: this.onSubmit
          })
        }
      },
      onSubmit: function () {
        this.$emit('onSubmit')
      }
    },
    props: {
      promotion: {
        type: Object,
        default: null
      },
      business: {
        type: Object,
        required: true,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .promotion__not-selected {
    text-align: center !important;
    padding: 15px !important;
  }
  .promotion__not-selected-content {
    padding: 14px !important;
    border-style: dashed;
    border-width: 1px;
    border-color: gray;
  }
</style>
