import Vue from 'vue'
import { callApi } from '@/default/service/Api'
import { Storage } from '@/default/service/Storage'
import { Auth } from '@/default/service/Auth'
import { Notifications } from '@/default/service/Notifications'
import { DateTime } from '@/default/plugins/Utils/DateTime'

Vue.prototype.$Api = callApi
Vue.prototype.$Storage = Storage
Vue.prototype.$Auth = Auth
Vue.prototype.$Notifications = Notifications
Vue.prototype.$DateTime = DateTime

export default {}
