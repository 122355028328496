export default {
  methods: {
    searchAddress: function () {
      this.$WiListDialog({
        wiConfig: 'address',
        filter: {
          where: 'person_id,' + this.person.id
        },
        wiEditData: {
          person_id: this.person.id
        },
        mode: 'select',
        callback: this.selectAddress
      })
    },
    selectAddress: function (address) {
      if (address) {
        const data = {
          id: this.business.id
        }
        data[this.field] = address.id
        this.$WiApiPut({
          uri: 'business/business',
          id: this.business.id,
          data,
          config: {
            msgLoad: true,
            msgSucess: true
          },
          callback: this.onSubmit
        })
      }
    },
    onSubmit: function (response) {
      this.$emit('onSubmit', response)
    }
  },
  props: {
    title: {
      default: null
    },
    field: {
      default: 'address_id'
    },
    business: {
      type: Object,
      required: true,
      default: () => ({})
    },
    person: {
      type: Object,
      required: true,
      default: () => ({})
    },
    address: {
      type: Object,
      required: true,
      default: () => ({})
    }
  }
}
