import { Model } from '@/default/model/Model'
import { PERMISSION_CONTRACT_UPDATE } from '@/default/constants/permissions'

export class ContractPayDay extends Model {
  constructor () {
    super()
    this.id = PERMISSION_CONTRACT_UPDATE
    this.actions = {
      update: true
    }
    this.title = 'Alterar dia de vencimento'
    this.icon = 'event_repeat'
    this.apiUrl = 'internet/contract/pay-day'
    this.maxWidth = '400px'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    message: this.fieldMessage({
      text: 'Não é possível alterar a data de vencimento caso exista um ou mais boletos emitidos que permitam pagamento em uma data futura. Essa regra existe para evitar pagamentos duplicados.'
    }),
    subHeader1: this.fieldSubHeader({
      text: 'Informe o novo dia de vencimento'
    }),
    invoicePayDay: this.fieldNumber({
      value: 'invoice_pay_day',
      text: 'Novo dia de vencimento',
      size: ['xs12', 'sm10', 'md8', 'lg6', 'xl4', 'offset-sm1', 'offset-md2', 'offset-lg3', 'offset-xl4'],
      min: 1,
      max: 31
    })
  }
}
