import Business from '@/modules/shared/pages/Business/Business'
import BusinessPrint from '@/modules/shared/pages/Business/BusinessPrint'
import BusinessOfProcess from '@/modules/shared/pages/Businesses/BusinessOfProcess'

import BusinessDashboard from '@/modules/shared/pages/BusinessDashboard/BusinessDashboard'

export default [
  {
    path: 'purchase/:id',
    name: 'purchase',
    component: Business,
    meta: {
      id: '1b7857f9',
      auth: true,
      title: 'Detalhes da compra',
      icon: 'local_grocery_store'
    }
  },
  {
    path: 'quotation/:id',
    name: 'quotation',
    component: Business,
    meta: {
      id: '5bfa5b87',
      auth: true,
      title: 'Detalhes da Cotação',
      icon: 'shop'
    }
  },
  {
    path: 'business-print',
    name: 'business-print',
    component: BusinessPrint,
    meta: {
      id: '2f2b5a92-c209-4f37-beb7-b70ce2059075',
      auth: true,
      title: 'Impressão',
      icon: 'print'
    }
  },
  {
    path: 'dashboard-purchases',
    name: 'dashboard-purchases',
    component: BusinessDashboard,
    meta: {
      id: '2f2b5a92-c209-4f37-beb7-b70ce2059075',
      auth: true,
      title: 'Dashboard de Compras',
      icon: 'dashboard',
      moduleName: 'purchases'
    }
  },
  {
    path: 'process-of-purchases/:business',
    name: 'process-of-purchases',
    component: BusinessOfProcess,
    meta: {
      id: '2f2b5a92-c209-4f37-beb7-b70ce2059075',
      auth: true,
      title: 'Compras',
      icon: 'local_shipping',
      touch: true
    }
  }
]
