import { Model } from '@/default/model/Model'

export class CollaboratorResponsibleMigrate extends Model {
  constructor() {
    super()
    this.id = '734a4cf7'
    this.list = true
    this.title = 'Migrar clientes'
    this.icon = 'sync'
    this.apiUrl = 'register/person/collaborator/migrate-responsible'
    this.maxWidth = '800px'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'ATENÇÃO! Você está prestes a migrar todos os clientes de um colaborador para outro.',
      style: {
        color: 'red',
        fontSize: '18px'
      }
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Informe o colaborador de destino.'
    }),
    responsible_id_destiny: this.fieldWiListDialog({
      value: 'responsible_id_destiny',
      text: 'Colaborador de destino',
      required: true,
      wiConfig: 'collaborator',
      show: 'person.first_name',
      icon: 'person_outline',
      disabled: form => !form.responsible_id_origin
    })
  }
}