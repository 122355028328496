import { Model } from '@/default/model/Model'

export class NetworkType extends Model {
  constructor () {
    super()
    this.list = false
    this.id = '31f8eef9'
    this.name = 'network-type'
    this.title = {
      list: 'Tipos de Rede',
      edit: {
        insert: 'Novo Tipo de Rede',
        update: 'Editar Tipo de Rede'
      }
    }
    this.params = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'vertical_split'
    this.apiUrl = 'network/type'
    this.setFields(this.fieldsEdit)
  }
  fieldsEdit = {
    subheader1: this.fieldSubHeader({
      text: 'Insira o nome, nível e ícone, selecione a função.',
      sortable: false
    }),
    nome_tipo: this.fieldText({
      value: 'nome_tipo',
      text: 'Nome do tipo de rede',
      size: ['xs12', 'sm3', 'md3', 'lg3'],
      required: true
    }),
    nivel_tipo: this.fieldNumber({
      value: 'nivel_tipo',
      text: 'Nível',
      size: ['xs12', 'sm3', 'md2', 'lg2'],
      required: true
    }),
    icone_tipo: this.fieldWiGoogleIcon({
      value: 'icone_tipo',
      text: 'Ícone',
      size: ['xs12', 'sm3', 'md4', 'lg4']
    }),
    funcao_tipo: this.fieldSelect({
      value: 'funcao_tipo',
      text: 'Função',
      size: ['xs12', 'sm3', 'md3', 'lg3'],
      options: [
        { value: 1, text: 'Grupo de Sub-grupos' },
        { value: 2, text: 'Grupo de Dispositivos' }
      ],
      required: true
    }),
    subheader2: this.fieldSubHeader({
      text: 'O campo de Observações pode ser usado para descrever este tipo de grupo.',
      sortable: false
    }),
    obs_tipo: this.fieldTextArea({
      value: 'obs_tipo',
      text: 'Observações do Tipo de rede',
      sortable: false
    })
  }
}
