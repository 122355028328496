export class DateTime {
  static date (setDate = null) {
    var date = this.getNewDate(setDate)
    let month = date.getMonth() + 1
    if (month.toString().length < 2) {
      month = '0' + month
    }
    let day = date.getDate()
    if (day.toString().length < 2) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day
  }

  static time (setTime = null) {
    var time = this.getNewDate(setTime)
    let hours = time.getHours()
    if (hours.toString().length < 2) {
      hours = '0' + hours
    }
    let minutes = time.getMinutes()
    if (minutes.toString().length < 2) {
      minutes = '0' + minutes
    }
    let seconds = time.getSeconds()
    if (seconds.toString().length < 2) {
      seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
  }

  static dateTime () {
    return this.date() + ' ' + this.time()
  }

  static getNewDate (date) {
    if (date) {
      return new Date(date)
    } else {
      return new Date()
    }
  }
}
