<template>
  <v-subheader style="margin-bottom: 20px; height: 30px !important;" :style="{ background: $wiDark ? '#212121' : '#ECEFF1' }">
    <span style="padding: 5px;">
      {{ text }}
    </span>
  </v-subheader>
</template>
<script>
  export default {
    name: 'FilterFieldSubHeader',
    props: {
      prependIcon: {
        default: null
      },
      appendIcon: {
        default: null
      },
      text: {
        required: true
      }
    }
  }
</script>