import router from '@/modules/sites/router'
import config from '@/modules/sites/config'
import menu from '@/modules/sites/menu'

export default {
  id: '1901d7c9-8a7d-414f-b044-50d3725a38e5',
  name: 'sites',
  title: 'Sites',
  description: 'Gestão de Sites Web',
  icon: 'wises-technology-module-sites.png',
  iconSystem: 'wises-technology-system-sites.png',
  iconProfile: 'profile_service_desk.png',
  color: '#90CAF9',
  menu,
  router,
  config
}
