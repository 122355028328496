<template>
  <v-card class="elevation-6 ma-2 rounded-lg" style="border-radius: 16px;">
    <v-card-text class="pa-6">
      <!-- Cabeçalho com Título e Ícone -->
      <div class="d-flex align-center mb-2">
        <div class="icon-wrapper mr-4" :class="color + '--text'">
          <v-icon size="36">{{ icon }}</v-icon>
        </div>
        <div>
          <h3 class="text-h5 font-weight-bold grey--darken-3">
            {{ title }}
          </h3>
          <h2 class="text-h5 font-weight-bold grey--darken-3">
            {{ current_value | money }}
          </h2>
        </div>
      </div>
      
      <!-- Informações Comparativas -->
      <div class="comparison-section px-2 py-1 rounded-lg">
        <div class="d-flex justify-space-between align-center">
          <div>
            <div class="caption grey--text text--darken-1">
              Período Anterior
            </div>
            <div class="subtitle-1 font-weight-medium">
              {{ previous_value | money }}
            </div>
          </div>
          <div class="variation-info d-flex align-center">
            <v-chip
              small
              :color="percentage_variation >= 0 ? 'success' : 'error'"
              class="variation-chip px-2"
              text-color="white"
              label style="border-radius: 5px;"
            >
              <v-icon small left class="mr-1">
                {{ percentage_variation >= 0 ? 'arrow_upward' : 'arrow_downward' }}
              </v-icon>
              <span class="font-weight-medium">{{ Math.abs(percentage_variation) }}%</span>
            </v-chip>
            
            <div 
              class="variation-value ml-2 caption"
              :class="percentage_variation >= 0 ? 'success--text' : 'error--text'"
            >
              {{ absolute_variation | money }}
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DRECard',
  props: {
    title: String,
    icon: String,
    color: String,
    current_value: Number,
    previous_value: Number,
    percentage_variation: Number,
    absolute_variation: Number
  }
}
</script>

<style scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.04);
}

.comparison-section {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.v-card {
  transition: transform 0.2s ease-in-out;
}

.v-card:hover {
  transform: translateY(-4px);
}

.variation-chip {
  min-width: 80px;
  height: 24px !important;
}

.variation-info {
  transition: all 0.2s ease;
}

.variation-value {
  font-weight: 500;
}

/* Opcional: adicionar hover effect */
.variation-info:hover {
  transform: scale(1.02);
}
</style> 
