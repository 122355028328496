import { Model } from '@/default/model/Model'

export class PostCategory extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1d91353e'
    this.name = 'post-category'
    this.title = {
      list: 'Categorias',
      edit: {
        insert: 'Novo Categorias',
        update: 'Editar Categorias'
      }
    }
    this.apiUrl = 'sites/post/category'
    this.icon = 'category'
    this.search = {
      placeholder: 'Pesquise pelo nome da categoria',
      fields: ['name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']

    this.maxWidth = '800px'

    this.components.list.tableRow = () => import('@/modules/sites/pages/Posts/PostCategories')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe nome, domínio e o repositório deste site.'
      }),
      this.fieldText({
        value: 'name',
        text: 'Nome',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      this.fieldText({
        value: 'slug',
        text: 'Slug (URI)',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'slug',
        text: 'Slug (URI)',
      })
    ])
  }
}
