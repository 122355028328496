const event = (id) => {
  return {
    id: id,
    title: 'Adicionar Nota ',
    icon: 'message',
    apiUrl: 'finance/event',
    params: {
      order: 'id,desc'
    },
    fields: [
      {text: 'Descreva sua nota de forma que fique clara aos outros usuários.', type: 'subheader', edit: true},
      {value: 'description', text: 'Insira aqui sua nota.*', type: 'editor', required: true, edit: true}
    ],
    fieldsFixed: [
      {
        value: 'type', data: 2
      }
    ]
  }
}

export default {
  in: event('dc1919bd'),
  out: event('5cdf28f1')
}
