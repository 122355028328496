import { Lang } from '@/default/service/Lang'

export class MoneyFilter {
  constructor (value) {
    this.value = value
    this.digits = 2
  }
  format () {
    if (this.value) {
      return this.getFormatedMoney(this.value)
    } else if (this.value === 0) {
      return this.getFormatedMoneyZero()
    } else {
      return this.value
    }
  }
  getFormatedMoney (value) {
    if (this.moneyIsFloat(value)) {
      return this.getFormatedMoneyFloat(value)
    } else {
      return this.getFormatedMoneyInteger(value)
    }
  }
  getFormatedMoneyFloat (value) {
    value = this.getValueCentsDigitsLimited(value)
    value = value.toLocaleString('pt-BR')
    if (value.toString().split(',')[1].length === 1) {
      value = value + '0'
    }
    return Lang.langText('system.currency.simbol', 'R$') + ' ' + value
  }
  getFormatedMoneyInteger (value) {
    return Lang.langText('system.currency.simbol', 'R$') + ' ' + value.toLocaleString('pt-BR') + ',00'
  }
  getFormatedMoneyZero () {
    return Lang.langText('system.currency.simbol', 'R$') + ' 0,00'
  }
  moneyIsFloat (value) {
    return ((value.toString().split('.').length - 1) > 0 && value.toString().split('.')[1].substring(0, this.digits) > 0)
  }
  getValueCentsDigitsLimited (value) {
    let valueArray = value.toString().split('.')
    return parseFloat(valueArray[0] + '.' + valueArray[1].substring(0, this.digits))
  }
}
