export default {
  namespaced: true,
  state: {
    views: {}
  },
  mutations: {
    create (state, config) {
      state.views[config.index] = config.data
    },
    delete (state, index) {
      delete state.views[index]
    }
  },
  actions: {
    create: function ({ commit }, config) {
      commit('create', config)
    },
    delete: function ({ commit }, index) {
      commit('delete', index)
    },
    refresh: function ({ state }, index) {
      state.views[index].refresh()
    }
  }
}
