<template>
  <div v-if="person">
    <v-divider></v-divider>
    <v-list v-if="isContact" class="talk-toolbar__title" two dense flat style="padding: 0px !important;">
      <v-list-tile
        avatar flat link
        :to="{
          name: person.profiles[0].name,
          params: {
            id: person.id
          }
        }"
        class="talk-toolbar__title-text"
        style="padding: 0 5px !important; padding: 0px;"
      >
        <v-list-tile-avatar style="min-width: 45px;padding: 0px;">
          <v-avatar size="40">
            <img
              v-if="person && person.file && person.file.url"
              :src="person.file.url"
            />
            <img v-else src="static/icons/user_icon_1.png">
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>
            <span style="font-size: 12px;">
              <span v-if="personTitle">
                <strong>
                  {{ personTitle }} 
                </strong>
                <span> no </span> 
              </span>
              <span v-else>
                Contato do 
              </span>
              <span>
                {{ person.profiles[0].title }}:
              </span>
            </span>
          </v-list-tile-sub-title>
          <v-list-tile-title>
            <span style="font-size:20px;">
              <span>
                <span class="hidden-xs-only hidden-sm-only">
                  {{ person.name | stringlength(28) }}
                </span>
                <span class="hidden-md-only hidden-lg-only hidden-xl-only">
                  {{ person.name | stringlength(20) }}
                </span>
              </span>
            </span>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <div v-else class="my-1 primary white--text">
      <span
        v-for="(profile, profileIndex) in profiles"
        :key="profileIndex"  class="white--text ma-1"
        style="display: flex; justify-content: center; align-items: center; cursor: pointer;"
        @click="goToProfile(profile)"
      >
        <v-icon color="white">
          {{ profile.icon }}
        </v-icon>
        <span style="font-size: 14px; padding-left: 5px;">
          {{ profile.title }}
        </span>
      </span>
    </div>
  </div>     
</template>
<script>
  export default {
    name: 'TalkContactProfiles',
    computed: {
      isContact () {
        return this.contact?.person &&
          this.contact?.person?.profiles?.findIndex(p => p.name == 'contact') > -1
      },
      person () {
        const person = this.contact?.person?.person || this.contact?.person
        if (person) {
          return person
        }
        return null
      },
      profiles () {
        return this.person?.profiles || []
      },
      personTitle () {
        if (this.contact?.person?.person_title) {
          return this.contact.person.person_title
        }
        return null
      }
    },
    methods: {
      goToProfile: function (profile) {
        if (profile?.name) {
          this.$router.push({
            name: profile.name,
            params: {
              id: this.person.id
            }
          })
        }
      }
    },
    props: ['contact']
  }
</script>