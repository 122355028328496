<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-device__enable-button elevation-2">
        <v-card-text style="text-align: center;">
          <h3>{{device.nome_disp}}</h3>
          <v-divider></v-divider>
          <strong>Dispositivo</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-device">
      <v-toolbar dense class="diagnostic-device__toolbar">
        <v-toolbar-title>
          Dispositivo
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="device">
        <h4>
          <span> Dispositivo conectado</span>
        </h4>
        <br>
        <v-layout row wrap>
          <v-flex xs12 sm12 style="padding-right: 10px;">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-device__device">
                <img src="/static/icons/monitor/grupo_icon_map_96.png" width="100px">
                <h2>Dispositivo: {{device.nome_disp || 'Não informado'}}</h2>
                <h4>IP: {{device.ip_disp || 'Não informado'}}</h4>
                <h4>Rede: {{device.network_names || 'Não informado'}}</h4>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-device__not-found">
        <h2>Nenhum dispositivo encontrado.☹️ </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DiagnosticDevice',
    data: () => ({
      dialog: false,
    }),
    props: {
      device: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .diagnostic-device {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-device__enable-button {
    cursor: pointer;
  }
  .diagnostic-device__toolbar {
    z-index: 999;
  }

  .diagnostic-device__device {
    text-align: center;
  }
  .diagnostic-device__not-found {
    margin: 50px 0;
  }
</style>
