<template>
  <!-- <TinyEditor 
    v-if="field && field.value"
    :apiKey="apiKey"
    v-model="contentEditor"
    :init="config"
  ></TinyEditor> -->
  <div>
    <textarea
      id="mytextarea"
      v-model="contentEditor"
      @onchange="test"
    ></textarea>
  </div>
</template>
<script>
  import { PERMISSION_CLINIC_MODULE } from '@/default/constants/permissions'
  // import TinyEditor from '@tinymce/tinymce-vue'
  export default {
    name: 'WiEditTinyMCE',
    data () {
      return {
        textAreaUUID: null,
        apiKey: '1pwn5rw26syn27rz79wgwnapcgc98crz45y7ec41lhi2hare',
        config: {
          height: 500,
          menubar: true,
          readonly : 1,
          content_css: 'document',
          content_style: 'body { margin: 1rem auto; max-width: 900px; }',
          fullpage_default_font_family: "'Verdana', Geneva, Sans-serif;",
          language: 'pt_BR',
          plugins: [
            'advlist autolink lists link image charmap',
            'searchreplace visualblocks code fullscreen',
            'print preview anchor insertdatetime media textcolor',
            'paste code wordcount table hr noneditable pagebreak toc'
          ],
          toolbar: `undo redo | fontselect | fontsizeselect | bold italic 
          | alignleft aligncenter alignright alignjustify | forecolor backcolor 
          | bullist numlist outdent indent | customalign | fullscreen
          | variables | remedies`,
          setup: (editor) => {
            // console.log(editor, "The TinyMCE rich text editor has been initialized.")
            editor.on("change", (e) => {
              // console.log(e?.level?.content, "change");
              this.contentEditor = e?.level?.content
            })
            editor.on("keyup", (e) => {
              // console.log(e?.srcElement?.innerHTML, "keyup");
              this.contentEditor = e?.srcElement?.innerHTML
            })
            // editor.on("input", (e) => {
            //   console.log(e?.originalTarget?.innerHTML, "input");
            // })
            // editor.on("cut", (e) => {
            //   console.log(e?.target?.innerHTML, "cut");
            // })
            // editor.on("paste", (e) => {
            //   console.log(e?.target?.innerHTML, "paste");
            // })
            if (this.$WiCheckPermission(PERMISSION_CLINIC_MODULE)) {
              editor.ui.registry.addButton('remedies', {
                type: 'button',
                text: 'Buscar medicamento',
                icon: 'browse',
                onAction: () => {
                  this.$WiRemediesDialog({
                    onSelect: remedy => {
                      editor.insertContent(remedy.name)
                    }
                  })
                }
              })
            }
            editor.ui.registry.addButton('variables', {
              type: 'button',
              text: 'Variáveis',
              icon: 'code-sample',
              onAction: () => {
                this.$WiListDialog({
                  wiConfig: 'business-layout-variables',
                  callback: selected => {
                    if (selected?.name) {
                      editor.insertContent(selected.variable)
                    }
                  },
                  mode: 'select'
                })
              }
            })
          }
        }
      }
    },
    computed: {
      contentEditor: {
        get () {
          return this.form[this.field.value]
        },
        set (value) {
          let data = {}
          data[this.field.value] = value
          this.$emit('select', data)
        }
      }
    },
    methods: {
      formatRemedyName: function (remedy) {
        if (remedy.name.length < 40) {
          let remedyName = `<span style="font-family: 'courier new', courier, monospace;"><strong>${remedy.name}   `
          for (let index = 0; index < (40 - remedy.name.length); index++) {
            remedyName += '_'
          }
          remedyName += '</strong></span>'
          return remedyName
        }
        return `<span style="font-family: 'courier new', courier, monospace;"><strong>${remedy.name}   __   </strong></span>`
      },
      test: function () {
      }
    },
    created: function () {
      this.textAreaUUID = this.$UUID()
    },
    mounted: function () {
      const textArea_id = 'mytextarea'

      window.tinymce.init({
        selector: '#mytextarea',
        license_key: 'gpl',
        ...this.config
      });

      if (window?.tinymce?.editors?.length > 0) {
        window.tinymce.execCommand('mceFocus', true, textArea_id );       
        window.tinymce.execCommand('mceRemoveEditor',true, textArea_id);        
        window.tinymce.execCommand('mceAddEditor',true, textArea_id);
      }
      const branding = document.querySelector('.tox-statusbar__branding')
      if (branding) {
        setTimeout(() => {
          branding.classList.add('remove-branding_editor-1')
        }, 1000);
      }
 
    },
    destroyed: function () {
      window.tinymce.remove()
    },
    props: ['form', 'field'],
    components: {
      // TinyEditor
    }
  }
</script>
<style>
.tox-promotion {
  display: none !important;
}
.tox-statusbar__branding {
  display: none !important;
}
</style>