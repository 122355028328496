import { Form } from './Form/Form'
import { FormField } from './Form/FormField'
import { FormReply } from './Form/FormReply'
import { FormFieldOption } from './Form/FormFieldOption'
import { FormReplyContentHistory } from './Form/FormReplyContentHistory'

export default {
  'form': new Form(),
  'form-field': new FormField(),
  'form-reply': new FormReply(),
  'form-field-option': new FormFieldOption(),
  'form-field-content-history': new FormReplyContentHistory(),
}
