const parcels = () => {
  var parcels = []
  for (var parcel = 1; parcel <= 60; parcel++) {
    parcels.push({ value: parcel, text: parcel + 'x' })
  }
  return parcels
}

const finance = (id, title, name) => {
  return {
    id: id,
    title: {
      edit: {
        create: 'Inserir Recebimento(s)',
        update: 'Editar Recebimento'
      }
    },
    actions: {
      create: true,
      update: true
    },
    icon: 'get_app',
    apiUrl: 'sales/' + name + '/finance',
    apiParams: {
      order: 'number_this'
    },
    fields: [
      {
        text: 'Informações básicas desta transação financeira.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'date_validity',
        text: 'Primeiro vencimento',
        type: 'date',
        edit: true,
        required: true,
        size: ['xs12', 'sm12', 'md2', 'ls2']
      },
      {
        value: 'show_amount_initial',
        text: 'Valor Total',
        type: 'text',
        disabled: true,
        edit: {
          insert: true
        },
        size: ['xs12', 'sm12', 'md2', 'lg2']
      },
      {
        value: 'number_total',
        text: 'Número de parcelas',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        type: 'select',
        options: parcels(),
        edit: {
          insert: true
        }
      },
      {
        value: 'description',
        text: 'Descrição',
        type: 'text',
        list: true,
        edit: true,
        search: true,
        size: (form) => { return form.id ? ['xs12', 'sm12', 'md10', 'lg10'] : ['xs12', 'sm12', 'md5', 'lg5'] }
      },
      {
        value: 'finance_form_payment_id',
        text: 'Forma de Pagamento',
        type: 'db-autocomplete',
        autocomplete: true,
        align: 'center',
        required: true,
        edit: true,
        apiUrl: 'finance/form-payment',
        apiParams: { fields: 'id,name', order: 'name,asc' },
        show: 'name',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      },
      {
        value: 'finance_account_id',
        text: 'Conta Financeira',
        type: 'db-autocomplete',
        autocomplete: true,
        align: 'center',
        required: true,
        edit: true,
        apiUrl: 'finance/account',
        apiParams: { fields: 'id,name', order: 'name,asc' },
        show: 'name',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      },
      {
        value: 'observation',
        text: 'Observações',
        type: 'textarea',
        edit: true
      }
    ],
    fieldsFixed: [
      {value: 'in_out', data: 1}
    ]
  }
}

export default {
  sale: finance('b41dcfec', 'Venda', 'sale'),
  budget: finance('d3c48579', 'Orçamento', 'budget')
}
