import { Model } from '@/default/model/Model'
import { PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'

export class FinanceSendEmail extends Model {
  constructor () {
    super()
    this.id = PERMISSION_INTERNET_MODULE
    this.actions = {
      create: true
    }
    this.title = 'Enviar fatura por e-mail'
    this.icon = 'email'
    this.apiUrl = 'finance/send/email'
    this.maxWidth = '400px'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    message: this.fieldMessage({
      text: 'Você pode enviar a fatura para o e-mail cadastrado no perfil do cliente ou para um outro e-mail'
    }),
    changeEmail: this.fieldSwitch({
      value: 'change_email',
      text: 'Enviar para outro e-mail',
      size: ['xs12']
    }),
    email: this.fieldText({
      value: 'email',
      text: 'Insira o outro e-mail',
      size: ['xs12'],
      disabled: (form) => {
        console.log(form.change_email);
        return !form.change_email
      }
    })
  }
}
