<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <template v-slot:activator="{ on }">
      <div v-on="on" class="wi-image-dialog__activator">
        <v-img :src="src" lazy-src="static/icons/not-found.png" :alt="title" :aspect-ratio="aspectRatio">
          <template v-slot:placeholder>
            <v-layout fill-height align-center justify-center ma-0>
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-layout>
          </template>
        </v-img>
      </div>
    </template>
    <v-card >
      <v-toolbar dense flat color="transparent">
        <v-icon large>image</v-icon>
        <v-toolbar-title v-if="title">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip v-if="download" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon target="_blank" link :href="`${download}&d=true`">
              <v-icon>download</v-icon>
            </v-btn>
          </template>
          <span>Fazer download</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon link target="_blank" :href="src">
              <v-icon>open_in_new</v-icon>
            </v-btn>
          </template>
          <span>Abrir imagem em uma nova aba</span>
        </v-tooltip>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-img
        :src="src" :alt="title" contain 
        style="max-height: calc(100vh - 148px)"
        lazy-src="static/icons/not-found.png">
        <template v-slot:placeholder>
          <v-layout fill-height align-center justify-center ma-0>
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-layout>
        </template>
      </v-img>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'WiImageDialog',
    data: () => ({
      dialog: false
    }),
    props: {
      src: {
        type: String,
        required: true
      },
      download: {
        type: String,
        required: false
      },
      title: {
        default: null
      },
      aspectRatio: {
        default: undefined
      }
    }
  }
</script>
<style scoped>
  .wi-image-dialog__activator {
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 4px;
  }
</style>