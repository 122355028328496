<template>
  <v-toolbar dense fixed app :dark="$wiDark">
    <v-toolbar-side-icon class="hidden-lg-only hidden-xl-only" @click.stop="TOUGGLE_DRAWER_LEFT_ACTIVE()"></v-toolbar-side-icon>
    <v-list class="app-bar__company-title" two dense flat>
      <v-list-tile avatar flat class="app-bar__company-title-text">
        <v-list-tile-avatar tile>
          <img
            :src="account.logo"
            :alt="account.description"
            style="width: 100%; max-width: 50px;"
          />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <span style="font-size:20px;" v-html="account.name"></span>
          </v-list-tile-title>
          <v-list-tile-sub-title class="hidden-xs-only hidden-sm-only">
            <span style="font-size: 14px; color: gray;">
              {{ account.description }}
            </span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-spacer></v-spacer>
      <v-btn 
        outline 
        small 
        class="hidden-xs-only hidden-sm-only"
        @click="$WiHelpDialog({
          title: 'Por favor, descreva abaixo sua solicitação.'
        })"
      >
        <span v-t="'components.appBar.suggestImprovement.label'"></span>
      </v-btn>
    <v-toolbar-items>
      <v-btn
        v-if="$WiCheckPermission('8f5150h9-fec4-45f0-ba83-ef2d904f954b')"
        icon v-ripple @click="$router.push({ name: 'chat-view' })"
      >
        <v-badge right overlap offset-x="22" offset-y="24">
          <template v-slot:badge>
            <span v-if="unreadsChats">
              <span v-if="unreadsChats < 10">{{unreadsChats}}</span>
              <span v-else>9+</span>
            </span>
          </template>
          <v-icon>
            forum
          </v-icon>
        </v-badge>
      </v-btn>
      <v-btn v-if="$WiCheckPermission('c4f8c247') && $WiCheckPermission('85352c3c-1321-4cba-b009-d5c4dc909159')" icon link :to="{name: 'clinic-calendar'}" class="hidden-xs-only hidden-sm-only">
        <v-icon>event</v-icon>
      </v-btn>
      <v-btn v-else-if="$WiCheckPermission('c4f8c247')" icon link :to="{name: 'orders-calendar'}" class="hidden-xs-only hidden-sm-only">
        <v-icon>event</v-icon>
      </v-btn>
      <v-btn v-if="$WiCheckPermission('123eab35-8984-4551-bd15-5b054b2ac8bf')" icon v-ripple @click="TOUGGLE_DRAWER_TALKS_ACTIVE()" class="hidden-xs-only hidden-sm-only">
        <v-badge right overlap offset-x="22" offset-y="24">
          <template v-slot:badge>
            <span v-if="unreadsTalks">
              <span v-if="unreadsTalks < 10">{{unreadsTalks}}</span>
              <span v-else>9+</span>
            </span>
          </template>
          <v-icon v-if="unreadsTalks">
            chat
          </v-icon>
          <v-icon v-else>
            chat_bubble_outline
          </v-icon>
        </v-badge>
      </v-btn>
      <v-btn v-if="system.versionApi != system.version" :loading="loading" icon v-ripple @click="TOUGGLE_DRAWER_RIGHT_ACTIVE()">
        <v-icon color="red">
          warning
        </v-icon>
      </v-btn>
      <v-btn v-else icon v-ripple :loading="loading" @click="TOUGGLE_DRAWER_RIGHT_ACTIVE()">
        <v-badge right overlap offset-x="22" offset-y="24">
          <template v-slot:badge>
            <span v-if="total">
              <span v-if="total < 10">{{total}}</span>
              <span v-else>9+</span>
            </span>
          </template>
          <v-icon v-if="total">
            notifications
          </v-icon>
          <v-icon v-else>
            notifications_none
          </v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'AppBar',
    computed: {
      ...mapGetters('Chats', ['unreadsChats']),
      ...mapState('Pages/Main', ['rigthDrawer']),
      ...mapGetters('Talks', ['unreadsQueue', 'unreadsMyTalks']),
      ...mapState('Pages/Main/Notifications', ['total', 'loading']),
      unreadsTalks () {
        return this.unreadsQueue + this.unreadsMyTalks
      },
      system () {
        return this.$store.state.system || []
      },
      user () {
        return this.$store.state.User || []
      },
      account () {
        return this.$store.state.User.account
      }
    },
    methods: {
      ...mapMutations('Pages/Main', ['TOUGGLE_DRAWER_LEFT_ACTIVE', 'TOUGGLE_DRAWER_RIGHT_ACTIVE', 'TOUGGLE_DRAWER_TALKS_ACTIVE'])
    }
  }
</script>
<style scoped>
  .app-bar__company-title {
    background: transparent !important;
    padding: 0px;
  }
  .app-bar__company-title-text >>> .v-list__tile {
    padding: 0px !important;
  }
</style>