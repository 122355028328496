<template>
<v-layout align-center justify-center>
  <v-flex xs12 sm8 md5 lg4>
    <v-card flat>
      <v-card-text>
        <img src="/static/icons/monitor/todo_list_96.png" alt="">
        <h1>Tudo certo!</h1>
        <h2>Deseja salvar os dados inseridos?</h2>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="white--text"
          color="green"
          block
          tile
          @click="save()"
        >salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</v-layout>
</template>

<script>
  export default {
    name: 'HostModelConfigSuccess',
    methods: {
      save: function () {
        this.$emit('save')
      }
    }
  }
</script>

<style>

</style>