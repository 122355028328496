<template>
	<v-layout wrap row style="padding: 0px 0 8px 0;">
		<v-flex xs12 sm5 style="padding-right: 8px; padding-left: 0px;">
			<v-card flat>
				<v-list two-line subheader>
					<v-subheader>
						<strong>Telefones <span v-if="person.phones" style="padding-left: 5px;">({{person.phones.length}})</span></strong>
            <v-spacer></v-spacer>
						<v-btn 
              v-prms="{'id': personConfig.id.update, 'notCheck': person.id == user.id}" 
              small 
              outline 
              color="green" 
              @click="$WiEditDialog({wiConfig: 'cell', onSubmit: refresh, data: {person_id: person.id, desativa_permissao: person.id == user.id}})"
            >
							<v-icon>add</v-icon>
              <span>{{$WiLangText('register.page.PersonContactAddress.btn1', 'Celular')}}</span>
						</v-btn>
            <v-btn 
              v-prms="{'id': personConfig.id.update, 'notCheck': person.id == user.id}" 
              small 
              outline 
              color="green" 
              @click="$WiEditDialog({wiConfig: 'phone', onSubmit: refresh, data: {person_id: person.id, desativa_permissao: person.id == user.id}})"
            >
              <v-icon>add</v-icon>
              <span>Fixo</span>
            </v-btn>
					</v-subheader>
					<div v-for="(contato, keyContato) in person.phones" :key="keyContato + '-contato'" >
						<v-divider inset></v-divider>
						<v-list-tile avatar>
							<v-list-tile-avatar>
								<v-icon v-if="contato.type === 'Celular' && contato.is_whatsapp" class="green white--text">perm_phone_msg</v-icon>
                <v-icon v-if="contato.type === 'Telefone'" class="blue white--text">phone</v-icon>
								<v-icon v-if="contato.type === 'Celular' && !contato.is_whatsapp" class="blue white--text">smartphone</v-icon>
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title v-if="contato.type === 'Celular'">
									<div :style="{'font-weight': contato.is_master ? 'bold' : ''}">
                    +<span v-text="contato.country_code"></span> 
                    <span v-if="contato.country_code == 55"> {{ contato.contact | mask('(##) # ####-####') }}</span>
                    <span v-if="contato.country_code == 351"> {{ contato.contact | mask('### ### ###') }}</span>
                    <span v-if="contato.country_code == 1"> {{ contato.contact | mask('(###) ###-####') }}</span>
                  </div>
								</v-list-tile-title>
                <v-list-tile-title v-if="contato.type === 'Telefone'">
                  <div :style="{'font-weight': contato.is_master ? 'bold' : ''}">
                    +<span v-text="contato.country_code"></span> 
                    <span v-if="contato.country_code == 55"> {{ contato.contact | mask('(##) ####-####') }}</span>
                    <span v-if="contato.country_code == 351"> {{ contato.contact | mask('### ### ###') }}</span>
                    <span v-if="contato.country_code == 1"> {{ contato.contact | mask('(###) ###-####') }}</span>
                  </div>
                </v-list-tile-title>
								<v-list-tile-sub-title><strong>Descrição:</strong>: {{ contato.description || '- - -' }}</v-list-tile-sub-title>
                <v-list-tile-sub-title><strong>Operadora:</strong>: {{ contato.operator || '- - -' }}</v-list-tile-sub-title>
							</v-list-tile-content>
							<v-list-tile-action>
								<v-btn 
                  v-if="contato.type === 'Celular'" 
                  icon 
                  ripple 
                  v-prms="{'id': personConfig.id.update, 'notCheck': person.id === user.id}" 
                  @click="$WiEditDialog({wiConfig: 'cell', onSubmit: refresh, data: Object.assign(contato, {desativa_permissao: person.id == user.id})})"
                >
                  <v-icon color="grey lighten-1">edit</v-icon>
                </v-btn>
                <v-btn 
                  v-if="contato.type === 'Telefone'" 
                  icon 
                  ripple 
                  v-prms="{'id': personConfig.id.update, 'notCheck': person.id === user.id}" 
                  @click="$WiEditDialog({wiConfig: 'phone', onSubmit: refresh, data: Object.assign(contato, {desativa_permissao: person.id == user.id})})"
                >
									<v-icon color="grey lighten-1">edit</v-icon>
								</v-btn>
								<db-delete 
                  v-if="contato.type === 'Celular'" 
                  v-prms="{'id': personConfig.id.update, 'notCheck': person.id === user.id}" 
                  v-on:action="refresh" 
                  store="cell" 
                  :id="contato.id"
                ></db-delete>
								<db-delete 
                  v-if="contato.type === 'Telefone'"
                  v-prms="{'id': personConfig.id.update, 'notCheck': person.id === user.id}" 
                  v-on:action="refresh"
                  store="phone"
                  :id="contato.id"
                ></db-delete>
							</v-list-tile-action>
						</v-list-tile>
					</div>
          <div v-for="(talkContact, keyTalkContact) in person.talk_contacts" :key="keyTalkContact + '-talk-contact'" >
						<v-divider inset></v-divider>
						<v-list-tile avatar>
							<v-list-tile-avatar>
                <v-img v-if="talkContact.file && talkContact.file.url" :src="talkContact.file.url"></v-img>
								<v-icon v-else class="green white--text">perm_phone_msg</v-icon>
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title>
									<div>
                    <span>
                      <span v-if="talkContact.contact">
                        <span v-if="talkContact.contact.toString().length == 10">
                          <strong>{{ (talkContact.contact) | mask('(##) ####-####') }}</strong>
                        </span>
                        <span v-else-if="talkContact.contact.toString().length == 11">
                          <strong>{{ (talkContact.contact) | mask('(##) # ####-####') }}</strong>
                        </span>
                        <span v-else>
                          {{ (talkContact.contact) }}
                        </span>
                      </span>
                      <span v-else-if="talkContact.number">
                        <span v-if="talkContact.number.toString().length == 12">
                          <strong>{{ (talkContact.number) | mask('+ ## (##) ####-####') }}</strong>
                        </span>
                        <span v-else-if="talkContact.number.toString().length == 13">
                          <strong>{{ (talkContact.number) | mask('+ ## (##) # ####-####') }}</strong>
                        </span>
                        <span v-else>
                          {{ (talkContact.contact || talkContact.number) }}
                        </span>
                      </span>
                      <span v-else>- - -</span>
                    </span>
                  </div>
								</v-list-tile-title>
								<v-list-tile-sub-title><strong>Descrição:</strong>: {{ talkContact.description || '- - -' }}</v-list-tile-sub-title>
                <!-- <v-list-tile-sub-title><strong>Operadora:</strong>: {{ talkContact.operator || '- - -' }}</v-list-tile-sub-title> -->
							</v-list-tile-content>
							<v-list-tile-action>
                <v-btn icon @click="unsetTalkContactPerson(talkContact)">
                  <v-icon color="pink">backspace</v-icon>
                </v-btn>
							</v-list-tile-action>
						</v-list-tile>
					</div>
					<v-divider ></v-divider>
				</v-list>
			</v-card>
		</v-flex>
		<v-flex xs12 sm7 style="padding-right: 0px; padding-left: 8px;">
			<v-card flat>
				<v-list two-line subheader>
					<v-subheader>
						<strong>{{$WiLangText('register.page.PersonContactAddress.addressTitle', 'Endereços')}} <span v-if="person.adresses" style="padding-left: 5px;">({{person.adresses.length}})</span></strong>
						<v-spacer></v-spacer>
            <PersonAddressFindByCep
              :personName="personName"
              :person="person"
              :refresh="refresh"
            ></PersonAddressFindByCep>
            <!-- 
            <v-btn 
              v-prms="{'id': personConfig.id.update, 'notCheck': person.id == user.id}" 
              small 
              outline 
              color="green" 
              dark 
              @click="$WiEditDialog({
                wiConfig: 'address',
                onSubmit: refresh,
                data: {person_id: person.id}
              })"
            >
              <v-icon>add</v-icon>
              <span>{{$WiLangText('register.page.PersonContactAddress.btn2', 'Novo Endereço')}}</span>
            </v-btn> 
            -->
					</v-subheader>
					<div v-for="(logradouro, keyLogradouro) in person.adresses" :key="keyLogradouro + '-contato'" >
						<v-divider inset></v-divider>
						<v-list-tile avatar>
							<v-list-tile-avatar>
								<v-icon class="blue white--text">location_on</v-icon>
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title>
                  <div :style="{'font-weight': logradouro.is_master ? 'bold' : ''}">
                    <span v-if="logradouro.address"> {{ logradouro.address }}, </span>
                    <span v-if="logradouro.number"> {{ logradouro.number }} </span>
                    <span v-if="logradouro.complement"> - {{ logradouro.complement }}</span>
                  </div>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <span v-if="logradouro.district"> {{ logradouro.district }}, </span>
                  <span v-if="logradouro.zip_code"> {{ logradouro.zip_code }} </span>
                  <span v-if="logradouro.city && logradouro.city.name"> {{ logradouro.city.name }} <span v-if="logradouro.city && logradouro.city.state">/ {{ logradouro.city.state.code }}</span></span>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title><strong>Descrição</strong>: {{logradouro.description || '- - -'}} </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn 
                  icon 
                  ripple 
                  v-prms="{'id': personConfig.id.update, 'notCheck': person.id === user.id}" 
                  @click="$WiEditDialog({wiConfig: 'address', onSubmit: refresh, data: Object.assign(logradouro, {desativa_permissao: person.id == user.id})})">
                   <v-icon color="grey lighten-1">edit</v-icon>
                 </v-btn>
                 <db-delete 
                  v-prms="{'id': personConfig.id.update, 'notCheck': person.id === user.id}" 
                  v-on:action="refresh" 
                  store="address" 
                  :id="logradouro.id"
                 ></db-delete>
               </v-list-tile-action>
             </v-list-tile>
           </div>
           <v-divider></v-divider>
         </v-list>
       </v-card>
     </v-flex>
  </v-layout>
</template>
<script>
  import PersonAddressFindByCep from './PersonAddressFindByCEP'
  export default {
    name: 'PersonContactAddress',
    data () {
      return {}
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      personConfig () {
        return this.$store.state.pages[this.personName] || {}
      }
      // wiConfig () {
      //   return this.$store.state.pages[this.$route.name] || {}
      // }
    },
    methods: {
      unsetTalkContactPerson: function (talkContact) {
        this.$WiApiPut({
          uri: 'talk/contact',
          id: talkContact.id,
          data: {
            person_id: null
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true,
            msgAsk: true
          },
          callback: () => {
            if (this.refresh) {
              this.refresh()
            }
          }
        })
      }
    },
    components: {
      PersonAddressFindByCep
    },
    props: {
      personName: {
        type: String
      },
      person: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    }
  }
</script>