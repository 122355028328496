import { Model } from '@/default/model/Model'

export class BusinessAgreement extends Model {
  constructor () {
    super()
    this.list = true
    this.title = {
      list: 'Convênios',
      edit: {
        insert: 'Novo Convênio',
        update: 'Editar Convênio'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome do convênio',
      fields: ['id', 'name', 'slug']
    }
    this.params = {
      limit: 10
    }
    this.apiUrl = 'sales/budget/agreement'
    this.icon = 'handshake'

    this.maxWidth = '500px'
    
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls2', 'offset-xl2']

    this.components.list.tableRow = () => import('@/modules/business/pages/Agreements')

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome do Convênio'
    }),
    slug: this.fieldList({
      value: 'slug',
      text: 'Identificador'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, identificador e ícone deste convênio.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do Convênio',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone do Convênio',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Descreva os detalhes deste convênio.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Descreva os detalhes deste convênio...'
    })
  }
} 