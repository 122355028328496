import FinanceCategoryFields from '@/modules/finance/config/Finance/FinanceCategory/FinanceCategoryFields'

export default {
  id: '1b113a91',
  list: true,
  title: {
    list: 'Categorias de Despesas',
    edit: {
      insert: 'Nova Categoria de Despesa',
      update: 'Atualizar Categoria de Despesa'
    }
  },
  icon: 'text_rotate_up',
  apiUrl: 'finance/category/out',
  maxWidth: '800px',
  fields: FinanceCategoryFields
}
