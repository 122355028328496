import Register from '@/modules/register'
import ServiceDesk from '@/modules/service-desk'
import Talk from '@/modules/talk'
import Sites from '@/modules/sites'
import Stock from '@/modules/stock'
import Sales from '@/modules/sales'
import Finance from '@/modules/finance'
// import Graduations from '@/modules/graduations'
import Projects from '@/modules/projects'
import Purchases from '@/modules/purchases'
// import Clinic from '@/modules/clinic'
import Wells from '@/modules/wells'
// import Juridical from '@/modules/juridical'
import Forms from '@/modules/forms'


export default {
  name: 'SisPoços',
  nameHtml: 'Sis<strong>Poços</strong>',
  icon: 'sis_pocos.png',
  version: '5.2.15',
  lastUpdate: '18/08/2020',
  color: '#367da6',
  type: 'web', // web or mobile
  mobile_link: 'https://play.google.com/store/apps/details?id=connectview.insights.com',
  modules: [
    Wells,
    Sales,
    Finance,
    Talk,
    Sites,
    ServiceDesk,
    Stock,
    Projects,
    Purchases,
    Register,
    Forms
  ]
}
