<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-toolbar color="transparent" dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="dialog">
          <v-list-tile avatar>
            <v-list-tile-avatar style="padding-right: 10px;">
              <v-avatar>
                <v-icon large color="black">
                  {{ stage.type.icon || 'add' }}
                </v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title style="font-size: 16px; font-">
                <strong>{{ stage.name }}</strong>
              </v-list-tile-title>
              <v-list-tile-sub-title> {{ stage.type.name }} </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="orange" outline @click="editStage()">
          <v-icon>edit</v-icon>
          <span> Editar</span>
        </v-btn>
        <v-btn v-if="stage && stage.results" color="green" outline @click="stageResults()">
          <v-icon>fact_check</v-icon>
          <span> Resultados ({{ stage.results.length }})</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'ProcessStageDialog',
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('refresh')
          this.$emit('input', value)
        }
      }
    },
    methods: {
      editStage: function () {
        this.$WiEditDialog({
          wiConfig: 'process-stage',
          data: this.stage,
          onSubmit: this.closeDialog
        })
        this.dialog = false
      },
      stageResults: function () {
        this.$WiListDialog({
          wiConfig: 'process-stage-result',
          filter: {
            where: 'process_stage_id,' + this.stage.id,
            order: 'order,asc'
          },
          wiEditData: {
            type_id: this.stage.type_id,
            process_stage_id: this.stage.id,
            process_id: this.stage.process_id
          }
        })
      },
      closeDialog: function () {
        this.dialog = false
      }
    },
    props: {
      value: {
        required: true
      },
      stage: {
        type: Object,
        required: true
      }
    }
  }
</script>
