<template>
  <div class="wi-view-list-error">
    <h1>
      <span>Erro ao buscar informações. </span><br>
      <span>Tente novamente ou contacte o suporte.</span>
    </h1>
    <v-btn @click="refreshEvent()" color="primary">
      <v-icon>refresh</v-icon>
      <span> Tentar novamente</span>
    </v-btn>
    <v-btn @click="$router.go(-1)">
      <v-icon>navigate_before</v-icon>
      <span> Voltar</span>
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'WiViewListError',
    methods: {
      refreshEvent: function () {
        this.$emit('onRefresh')
      }
    }
  }
</script>
<style scoped>
  .wi-view-list-error { 
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }
  .wi-view-list-error h1 { 
    padding-bottom: 20px;
  }
</style>