<template>
  <v-menu class="medical-record-categories" right transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-list>
      <template v-for="(category, categoryKey) in categories">
        <v-divider v-if="categoryKey" :key="'divider-' + categoryKey"></v-divider>
        <v-list-tile :key="categoryKey" @click="categoryAction(category)">
          <v-list-tile-avatar>
            <v-icon :color="category.color">{{category.icon}}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>{{category.name}}</v-list-tile-title>
        </v-list-tile>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
  export default {
    name: 'MedicalRecordCategories',
    methods: {
      categoryAction: function (category) {
        console.log(category.type_info.name);
        this.$WiEditDialog({
          wiConfig: category.type_info.name,
          onSubmit: this.refresh,
          data: {
            business_category_id: category.id,
            person_id: this.patient.id,
            description: category.layout,
            task_id: this.$route.query.task_id
          },
          redirectOnSubmit: false
        })
      }
    },
    props: ['categories', 'refresh', 'patient']
  }
</script>
<style scoped>
  .talk-menu__btn {
    margin: 0px !important;
  }
</style>