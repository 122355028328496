<template>
  <div>
    <slot
      :mode="mode"
      :wi-edit-data="wiEditData"
      :select="select"
      :selected="selected"
      :set-selected="setSelected"
      :title="getPageTitle()"
      :icon="getPageIcon()"
      :store="store"
      :set-store="setStore"
      :config="config"
      :searchDialog="searchDialog"
      :searchDialogClose="() => searchDialog = false"
      :searchDialogOpen="() => searchDialog = true"
      :table-header="getTableHeader()" 
      :table-items="response.data || []"
      :information="response.information"
      :params="params"
      :set-params="setParams"
      :total-items="response.total"
      :to="response.to"
      :from="response.from"
      :last-page="response.last_page"
      :refresh="getApiData"
      :clear-items="clearResponse"
      :loading="loading" 
      :search-load="getApiDataToSearch" 
      :search-clear="searchClear" 
      :search="search"
      :open-edit-modal="openEditModal"
    ></slot>
    <div v-if="editModal">
      <wi-list-edit-modal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="getApiData"></wi-list-edit-modal>
    </div>
  </div>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import WiListEditModal from '@/default/component/WiList/WiListEditModal'
  export default {
    name: 'WiList',
    data () {
      return {
        selected: [],
        loading: false,
        params: {
          page: 1,
          order: 'created_at,desc',
          limit: 10
        },
        response: [],
        search: {
          run: false,
          query: '',
          params: '',
          placeholder: this.config && this.config.search && this.config.search.placeholder ? this.config.search.placeholder : 'Digite aqui a sua pesquisa...'
        },
        searchDialog: false,
        editModal: false,
        modalForm: {},
        modalStore: ''
      }
    },
    methods: {
      setSelected: function (selected) {
        this.selected = selected
      },
      getApiData: function () {
        this.loading = true
        var params = this.params
        callApi.get({
          uri: this.config.apiUrl && this.config.apiUrl.list ? this.config.apiUrl.list : this.config.apiUrl,
          params: params || '',
          sucess: (response) => {
            this.response = response.data
            this.loading = false
            this.checkDataOneSelect()
          },
          error: (error) => {
            this.$WiMakeError({error: error, id: 103, title: 'Erro ao buscar informações.'})
            this.loading = false
          }
        })
      },
      getTableHeader: function () {
        var items = [{text: 'Ações', sortable: false, align: 'center'}]
        this.config.fields.filter((field) => {
          if (field.list) {
            field['sortable'] = (field.value.split('.').length === 1) ? field.sortable : false
            items.push(field)
          }
        })
        return items
      },
      getSearchFields: function () {
        var searchFields = []
        if (this.config && this.config.search && this.config.search.fields) {
          searchFields = this.config.search.fields
        } else {
          this.config.fields.filter((field) => {
            if (field.search) {
              searchFields.push(field.value)
            }
          })
        }
        return searchFields
      },
      getApiDataToSearch: function (query) {
        var searchParam = ''
        this.getSearchFields().filter((field, fieldKey) => {
          if (fieldKey) {
            searchParam += ';'
          }
          searchParam += field + ',' + query
        })
        this.params['orWhere'] = searchParam
        this.params['page'] = 1
        this.getApiData()
        this.search.run = true
        this.searchDialog = false
      },
      searchClear: function () {
        this.search.query = ''
        delete this.params.orWhere
        this.search.run = false
        this.getApiData()
      },
      clearResponse: function () {
        this.response = {}
      },
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      },
      setStore: function (store) {
        this.store = store
      },
      getPageTitle: function () {
        return this.config.title && this.config.title.list ? this.config.title.list : this.config.title
      },
      getPageIcon: function () {
        return this.config.icon && this.config.icon.list ? this.config.icon.list : this.config.icon
      },
      setParams: function (params, refresh) {
        for (var paramKey in params) {
          this.params[paramKey] = params[paramKey]
        }
        if (refresh) {
          this.getApiData()
        }
      },
      select: function (data) {
        this.$emit('select', data)
      },
      checkDataOneSelect: function () {
        const data = this.response.data
        if (this.dataOneSelect && data.length == 1 && data[0]) {
          this.$emit('select', data[0])
        }
      }
    },
    computed: {
      config () {
        const page = this.$store.state.pages[this.store] || false
        this.setParams(page.params)
        return page || {}
      }
    },
    mounted: function () {
      if (this.page && this.page.apiParams) {
        this.setParams(this.page.apiParams)
      }
      if (this.getParams) {
        this.setParams(this.getParams)
      }
      if (this.setRoute) {
        this.setParams(this.$route.query, false)
      }
      if (this.loadOnMounted) {
        this.getApiData()
      }
    },
    props: {
      'store': {
        type: String,
        required: true
      },
      'mode': {
        type: String,
        default: 'component'
      },
      'loadOnMounted': {
        type: Boolean,
        default: true
      },
      'dataOneSelect': {
        type: Boolean,
        default: false
      },
      'setRoute': {
        type: Boolean,
        default: false
      },
      'getParams': {
        type: Object,
        default: null
      },
      'wiEditData': {}
    },
    components: {
      WiListEditModal
    }
  }
</script>