<template>
  <div>
    <Toolbar></Toolbar>
    <v-container v-if="stages && stages.length > 0" style="padding-top: 0px !important;">
      <v-slide-y-transition>
        <v-layout v-if="execution && execution.status && execution.status.code == 'closed'" row wrap style="padding-top: 20px !important;">
          <v-flex xs12>
            <v-card class="elevation-24">
              <v-card-text style="text-align: center;">
                <img src="/static/icons/monitor/ok_96.png" width="98px">
                <h1>{{execution.process.name}}<br>concluído com sucesso!</h1>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outline block color="green" @click="restartExecution(execution.process_id)">
                  <v-icon>restart_alt</v-icon>
                  <span> Reiniciar processo</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-card-text></v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-slide-y-transition>
      <TimeLine></TimeLine>
    </v-container>
    <v-container v-else-if="loading" style="padding-top: 25px !important; text-align: center;">
      <v-card flat color="transparent">
        <v-card-text>
          <v-progress-circular indeterminate :size="80" :width="7" color="blue"></v-progress-circular>
          <h1 style="padding-top: 20px; padding-bottom: 20px;">
            Buscando execução do processo. <br>Por favor, aguarde... 
          </h1>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-else style="padding-top: 25px !important; text-align: center;">
      <v-card flat color="transparent">
        <v-card-text>
          <h1 style="padding-bottom: 20px;">
            Execução de processo não encontrada. <br>Tente novamente ou contacte o suporte. 
          </h1>
          <v-btn @click="loadExecution($route)" color="primary">
            <v-icon>refresh</v-icon>
            <span style="padding-left: 5px;">Tentar novamente</span>
          </v-btn>
          <v-btn @click="$router.go(-1)">
            <v-icon>navigate_before</v-icon> 
            <span style="padding-left: 5px;">Voltar</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import processMixin from './processMixin'
  import TimeLine from './TimeLine'
  import Toolbar from './Toolbar'
  export default {
    name: 'ProcessExecution',
    computed: {
      ...mapState('ProcessExecution', ['execution', 'stages', 'loading'])
    },
    methods: {
      ...mapActions('ProcessExecution', ['getExecution', 'subscribeExecution', 'unSubscribeExecution', 'closeExecution']),
      loadExecution: function (route) {
        const executionId = route.params.id
        this.getExecution(executionId)
        this.unSubscribeExecution(executionId)
        this.subscribeExecution(executionId)
      }
    },
    created: function () {
      this.loadExecution(this.$route)
    },
    destroyed: function () {
      this.closeExecution()
    },
    watch: {
      $route (route) {
        this.loadExecution(route)
      }
    },
    mixins: [
      processMixin
    ],
    components: {
      Toolbar,
      TimeLine
    }
  }
</script>