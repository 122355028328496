<template>
  <div>
    <!-- Person Info -->
    <template v-if="item.person">
      <v-divider></v-divider>
      <v-card-title class="detail-title">
        <v-icon style="color:#1C8ADB">person</v-icon>
        <span>{{item.person.name | stringlength(27)}}</span>
      </v-card-title>
    </template>

    <!-- Category Info -->
    <template v-if="item.category">
      <v-divider></v-divider>
      <v-card-title class="detail-title">
        <v-icon 
          :style="{color: item.category.color || '#000'}"
          :color="item.category.icon"
        >
          {{item.category.icon}}
        </v-icon>
        <span>{{item.category.name | stringlength(27)}}</span>
      </v-card-title>
    </template>

    <!-- Collaborator Info -->
    <template v-if="item.collaborator">
      <v-divider></v-divider>
      <v-card-title class="detail-title">
        <v-img
          v-if="hasCollaboratorImage"
          :src="item.collaborator.file.url"
          class="collaborator-image"
        ></v-img>
        <v-icon v-else color="primary">support_agent</v-icon>
        <span class="pl-2">{{item.collaborator.name | stringlength(27)}}</span>
      </v-card-title>
    </template>

    <!-- Quantity Info -->
    <template v-if="showQuantityInfo">
      <v-divider></v-divider>
      <v-card-title class="detail-title">
        <v-icon>list</v-icon>
        <label><strong>Produtos/Serviços</strong>:</label>
        <v-spacer></v-spacer>
        <strong>{{item.sum_quantity_unit}}</strong>
      </v-card-title>
    </template>

    <!-- Attachments Info -->
    <template v-if="item.count_attachment">
      <v-card-text class="pa-0">
        <v-card-title class="detail-title">
          <v-icon>attachment</v-icon>
          <span>{{item.count_attachment}}</span>
        </v-card-title>
        <v-divider></v-divider>
      </v-card-text>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BusinessCardDetails',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true }
  },

  computed: {
    hasCollaboratorImage() {
      return this.item.collaborator.file && this.item.collaborator.file.url
    },

    showQuantityInfo() {
      return this.$Auth.user().account.id === '490fba83-2a5e-4b11-935a-754b25000880' && 
             this.item.sum_quantity_unit
    }
  }
}
</script>

<style scoped>
.detail-title {
  padding: 5px;
}
.collaborator-image {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  max-width: 22px;
  max-height: 22px;
}
</style> 