export default {
  id: {
    id: '5d0302b3',
    create: '4f085fa0',
    update: '1ac37773',
    delete: '140e42c7'
  },
  list: true,
  title: {
    list: 'Perfis de Usuário',
    edit: {
      insert: 'Novo perfil de usuário',
      update: 'Renomear perfil de usuário'
    }
  },
  icon: 'lock_open',
  apiUrl: 'system/user/profile',
  urlDetails: {name: 'user-profile', params: {}},
  paramsFixed: {
    where: 'id,>,1'
  },
  config: {
    new: true,
    edit: (form) => {
      return form.id !== 1
    },
    update: (form) => {
      return form.id !== 1
    },
    delete: (form) => {
      return form.id !== 1
    }
  },
  search: {
    placeholder: 'Pesquise pelo nome do perfil de usuário',
    fields: ['name']
  },
  maxWidth: '400px',
  fields: [
    {
      value: 'name',
      text: 'Nome do perfil de usuário',
      placeholder: 'Insira o nome do perfil de usuário',
      size: [ 'xs12', 'sm12', 'md12', 'lg12' ],
      type: 'text',
      required: true,
      list: true,
      edit: true,
      search: true
    },
    {
      value: 'count_users',
      text: 'Usuários',
      list: true
    }
  ]
}
