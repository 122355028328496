import { Model } from '@/default/model/Model'

export class BusinessItemProvider extends Model {
  constructor () {
    super()
    this.list = false
    this.title = 'Associar Item ao Fornecedor'
    this.params = {
      limit: 10
    }
    this.icon = 'list'
    this.maxWidth = '500px'
    this.setFields(this.fieldsEdit)
  }
  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o Produto para açociação com o Fornecedor.'
    }),
    itemId: this.fieldWiListDialog({
      value: 'item_id',
      text: 'Produto',
      required: true,
      wiConfig: 'product',
      show: 'item.name'
    })
  }
}
