import { PERMISSION_CONTRACT } from '@/default/constants/permissions'

export default {
  id: PERMISSION_CONTRACT,
  icon: 'border_color',
  title: 'Gestão de Contratos',
  children: [
    // {
    //   id: PERMISSION_CONTRACT,
    //   icon: 'view_week',
    //   title: 'Etapas de Contrato',
    //   route: {
    //     name: 'process-of-wells',
    //     params: {
    //       business: 'contract'
    //     }
    //   }
    // },
    {
      id: PERMISSION_CONTRACT,
      icon: 'select_all',
      title: 'Contratos Em Aberto',
      route: {
        name: 'wells-list',
        params: {
          page: 'contract-open'
        }
      }
    },
    {
      id: PERMISSION_CONTRACT,
      icon: 'done_outline',
      title: 'Contratos Vigentes',
      route: {
        name: 'wells-list',
        params: {
          page: 'contract-close'
        }
      }
    },
    {
      id: PERMISSION_CONTRACT,
      icon: 'close',
      title: 'Contratos Cancelados',
      route: {
        name: 'wells-list',
        params: {
          page: 'contract-cancel'
        }
      }
    }
  ]
}
