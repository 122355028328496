<template>
	<viewdetail title="Configurações" :routeReturn="{'name': 'monitor'}" apiUrl="configuracoes" :notGetID="true">
    <template slot-scope="dados">
      <v-flex style="margin: auto;padding-top: 20px;" xs11 sm11 md6 lg6>
        <v-data-table :headers="headers" :items="dados.content.data" hide-actions class="elevation-1">
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.desc_config }}</td>
            <td class="text-xs-left">{{ secToMinuts(props.item.val_int_config) }}</td>
            <div align="right" v-if="props.item.id == 1">
            <td class="text-xs-right"><v-btn flat @click="openEditModal('tempo-expirado',props.item)">
              <v-icon color="cyan">edit</v-icon>
            </v-btn></td>
            </div>
            <div align="right" v-if="props.item.id == 2">
            <td class="text-xs-right"><v-btn flat @click="openEditModal('tempo-expirado-verificando',props.item)">
              <v-icon color="cyan">edit</v-icon>
            </v-btn></td>
            </div> 
            <div align="right" v-if="props.item.id == 3">
            <td class="text-xs-right">
              <v-btn flat @click="openEditModal('tempo-retorno', props.item)">
                <v-icon color="cyan">edit</v-icon>
              </v-btn></td>
            </div> 
          </template>
        </v-data-table>
      </v-flex>
      <div v-if="editModal">
        <editmodal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="dados.refresh"></editmodal>
      </div>
    </template>
  </viewdetail>
</template>
<script>
  import EditModal from '@/default/components/Modal/EditModal'
  import viewDetail from '@/default/components/ViewDetail'

  export default {
    name: 'Configuracoes',
    data () {
      return {
        editModal: false,
        modalForm: {},
        modalStore: 'configuracoes',
        headers: [
        { text: 'Propriedade', padding: '10px', value: 'propriedade' },
        { text: 'Valor', value: 'value' },
        { text: '', value: 'botão_editar' }
        ]
      }
    },
    methods: {
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      },
      secToMinuts: function (seconds) {
        return (seconds / 60) + ' Minutos'
      }
    },
    components: {
      editmodal: EditModal,
      viewdetail: viewDetail
    }
  }
</script>