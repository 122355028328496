import { Model } from '@/default/model/Model'
import {
  PERMISSION_REGISTER_ACCOUNT,
  PERMISSION_REGISTER_ACCOUNT_CREATE,
  PERMISSION_REGISTER_ACCOUNT_UPDATE,
  PERMISSION_REGISTER_ACCOUNT_DELETE
} from '@/default/constants/permissions'

export class Account extends Model {
  constructor() {
    super()
    this.id = {
      id: PERMISSION_REGISTER_ACCOUNT,
      create: PERMISSION_REGISTER_ACCOUNT_CREATE,
      update: PERMISSION_REGISTER_ACCOUNT_UPDATE,
      delete: PERMISSION_REGISTER_ACCOUNT_DELETE
    }
    this.actions = {
      list: true,
      create: true,
      update: true
    }
    this.list = true
    this.title = {
      list: 'Contas',
      edit: {
        insert: 'Nova Conta',
        update: 'Editar Conta'
      }
    }
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pelo nome',
      fields: ['name']
    }
    this.icon = 'add_circle_outline'
    this.apiUrl = 'system/account'
    this.maxWidth = '800px'
    // this.components.list.tableRow = () => import('@/modules/register/pages/Channels')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)

    // this.fieldsFixed = [
    //   { value: 'data', data: [] }
    // ]
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome'
    }),
    description: this.fieldList({
      value: 'description',
      text: 'Descrição'
    }),
    status: this.fieldList({
      value: 'status_text',
      text: 'Status',
      sortable: false
    }),
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Informe os dados desta conta.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do canal',
      size: ['xs12', 'sm12', 'md5', 'lg5', 'xl5'],
      required: true
    }),
    codeActivation: this.fieldSelect({
      value: 'code_activation',
      text: 'Sistema',
      placeholder: 'Selecione um sistema',
      size: ['xs12', 'sm12', 'md4', 'lg4', 'xl4'],
      required: true,
      options: [
        {
          value: 'wise-control',
          text: 'Wise Control'
        },
        {
          value: 'connect-view',
          text: 'Connect View'
        },
        {
          value: 'sis-pocos',
          text: 'SisPoços'
        },
        {
          value: 'wise-message',
          text: 'Wise Message'
        }
      ],
      disabled: form => (!!form.id)
    }),
    status: this.fieldSelect({
      value: 'status',
      text: 'Status',
      placeholder: 'Selecione um status',
      size: ['xs12', 'sm12', 'md3', 'lg3', 'xl3'],
      required: true,
      options: [
        {
          value: 1,
          text: 'Ativo'
        },
        {
          value: 2,
          text: 'Teste Beta'
        },
        {
          value: 3,
          text: 'Bloqueado'
        },
        {
          value: 4,
          text: 'Cancelado'
        },
        {
          value: 5,
          text: 'Suspenso'
        }
      ]
    }),
    slug: this.fieldText({
      value: 'slug',
      text: 'Slug',
      size: ['xs12', 'sm12', 'md3', 'lg3', 'xl3'],
      required: true,
      disabled: form => (!!form.id)
    }),
    description: this.fieldText({
      value: 'description',
      text: 'Descrição da conta',
      placeholder: 'Aparece debaixo do nome',
      required: true,
      size: ['xs12', 'sm12', 'md9', 'lg9', 'xl9']
    }),
    logo: this.fieldText({
      value: 'logo',
      text: 'Logo da empresa (formato quadrado)',
      placeholder: 'Selecione uma logo quadrada',
      size: ['xs12', 'sm12', 'md6', 'lg6', 'xl6'],
      required: true,
      showField: form => (!form.id),
    }),
    print_image: this.fieldText({
      value: 'print_image',
      text: 'Imagem para impressão de documentos',
      placeholder: 'Selecione uma logo da empresa',
      size: ['xs12', 'sm12', 'md6', 'lg6', 'xl6'],
      required: true,
      showField: form => (!form.id),
    })
  }
}