import { Model } from '@/default/model/Model'
import { BudgetFilters } from '@/modules/sales/config/Budget/BudgetFilters'
import {
  PERMISSION_BUDGET_EXPORT_BUDGETS,
  PERMISSION_BUDGET_EXPORT_BUDGETS_TO_XLSX
} from '@/default/constants/permissions'

const parcelQuantity = []
for (let i = 1; i <= 120; i++) {
  parcelQuantity.push({ value: i, text: `${i} vez${i > 1 ? 'es' : ''}` })
}

export class BudgetCred extends Model {
  constructor () {
    super()
    this.id = {
      id: '10b505c9',
      create: 'b9fba2ea',
      update: 'd3c48579',
      delete: '55bf11f3'
    }
    this.title = {
      list: 'Propostas',
      edit: {
        create: 'Nova Proposta',
        update: 'Editar Proposta'
      }
    }
    this.params = {
      limit: 10,
      with: 'agreement:id,name,icon,color'
    }
    this.icon = 'shop'
    this.apiUrl = 'sales/budget'
    this.maxWidth = '800px'
    this.search = {
      placeholder: 'Pesquise ID, cliente, status ou categoria',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'category.name', 'external_code']
    }

    this.urlDetails = { name: 'budget', params: {} }
    this.filters = new BudgetFilters()
    this.components = {
      list: {
        tableRow: () => import('@/modules/sales/pages/Budgets/BusinessesCred')
      }
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.exports = {
      permission: PERMISSION_BUDGET_EXPORT_BUDGETS,
      title: 'Exportar Orçamentos',
      origins: {
        excel: {
          title: 'Exportar para o Excel',
          permission: PERMISSION_BUDGET_EXPORT_BUDGETS_TO_XLSX,
          description: 'Exportar orçamentos para o Excel.',
          image: '/static/icons/excel_icon.png',
          wiConfig: 'budget-export-filters'
        }
      }
    }
    
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    personName: this.fieldList({
      value: 'person.name',
      text: 'Nome do Cliente'
    }),
    status: this.fieldList({
      value: 'business_status_id',
      text: 'Status/Etapa',
      align: 'center'
    }),
    dateStart: this.fieldList({
      value: 'date_start',
      text: 'Data de Emissão'
    }),
    agreement: this.fieldList({
      value: 'business_agreement_id',
      text: 'Convênio'
    }),
    externalCode: this.fieldList({
      value: 'external_code',
      text: 'Código Proposta'
    }),
    collaborator: this.fieldList({
      value: 'collaborator_id',
      text: 'Colaborador/Categoria'
    }),
    totalLiquid: this.fieldList({
      value: 'total_liquid',
      text: 'Valor',
      sortable: true
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos desta proposta.',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    personType: this.fieldSelect({
      value: 'person_profile',
      text: 'Perfil',
      options: [
        {value: 'customer', text: 'Cliente'},
        {value: 'prospect', text: 'Prospect'}
      ],
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      defaultOption: 'customer',
      showField: (form) => { return !form.id }
    }),
    personCustomer: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Cliente',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      wiConfig: 'customer',
      show: 'person.first_name',
      icon: 'person_outline',
      disabled: form => !!form.id,
      showField: (form) => {
        return !form.id && form.person_profile === 'customer'
      }
    }),
    personProspect: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Prospect',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      wiConfig: 'prospect',
      show: 'person.first_name',
      icon: 'person_outline',
      disabled: form => !!form.id,
      showField: (form) => {
        return !form.id && form.person_profile === 'prospect'
      }
    }),

    collaborator: this.fieldSelectApi({
      value: 'collaborator_id',
      text: 'Colaborador Responsável',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      api: {
        url: 'register/person/collaborator',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name',
      showField: (form) => { return !form.id }
    }),
    status: this.fieldSelectApi({
      value: 'business_status_id',
      text: 'Status/Etapa*',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'sales/config/status/budget',
        params: {fields: 'id,name,icon,color', order: 'order,asc;name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color',
      disabled: (form) => { return form.paid && form.stage === 2 }
    }),
    category: this.fieldSelectApi({
      value: 'business_category_id',
      text: 'Categoria',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'sales/config/category/budget',
        params: {fields: 'id,name,icon,color', order: 'name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color'
    }),
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data de Emissão',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    totalLiquid: this.fieldMoney({
      value: 'amount_additional',
      text: 'Valor',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    externalCode: this.fieldText({
      value: 'external_code',
      text: 'Código Proposta',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    advanced_view: this.fieldSwitch({
      value: 'advanced_view',
      text: 'Opções avançadas',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Informações complementares: Prazo de pagamento, comissões, etc.'
    }),
    agreementId: this.fieldSelectApi({
      value: 'business_agreement_id',
      text: 'Convênio',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'sales/budget/agreement',
        params: {fields: 'id,name,icon,color', order: 'name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color',
    }),
    installmentAmount: this.fieldMoney({
      value: 'amount_installment',
      text: 'Valor da Parcela',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    installmentQuantity: this.fieldSelect({
      value: 'quantity_installment',
      text: 'Quantidade de Parcelas',
      placeholder: 'Ex: 12 vezes',
      options: parcelQuantity,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Opções avançadas',
      showField: (form) => { return form.advanced_view }
    }),
    // opções avançadas, date_end, date_validity, company_id, collaborator_id
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data de Entrega',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    dateValidity: this.fieldDate({
      value: 'date_validity',
      text: 'Data de Validade',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    percentageCollaborator: this.fieldNumber({
      value: 'percentage_collaborator',
      text: 'Comissão',
      placeholder: 'Ex: 10%',
      min: 1,
      max: 100,
      step: '.01',
      prefix: '%',
      suffix: '%',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor de Desconto',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    company: this.fieldSelectApi({
      value: 'company_id',
      text: 'Empresa',
      api: {
        url: 'register/person/company',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name',
      size: form => !form.id ? ['xs12', 'sm12', 'md12', 'lg12'] : ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => { return form.advanced_view }
    }),
    collaboratorAdvanced: this.fieldSelectApi({
      value: 'collaborator_id',
      text: 'Colaborador Responsável',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'register/person/collaborator',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name',
      showField: (form) => { return !!form.id && form.advanced_view }
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para adicionar observações...'
    })
  }
}

export class BudgetCredOpened extends BudgetCred {
  constructor () {
    super()
    this.title.list = 'Propostas Em Aberto'
    this.params['stage'] = 'open'
  }
}

export class BudgetCredClosed extends BudgetCred {
  constructor () {
    super()
    this.title.list = 'Propostas Aprovadas'
    this.params['stage'] = 'close'
  }
}

export class BudgetCredCanceled extends BudgetCred {
  constructor () {
    super()
    this.title.list = 'Propostas Rejeitadas'
    this.params['stage'] = 'cancel'
  }
}
