<template>
  <div>
    <VueRecordAudio
      v-if="!loading"
      mode="press"
      @result="onAudioResult"
    />
    <v-btn v-else icon small disabled :loading="loading">
      <v-icon>mic</v-icon>
    </v-btn>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import {callApi} from '@/default/service/Api'
  import { DEBUG } from '@/default/constants/environments'
  export default {
    name: 'TalkFooterAudio',
    data: () => ({
      loading: false
    }),
    computed: {
      ...mapState('Talk', ['talk']),
      debug: () => DEBUG
    },
    methods: {
      onAudioResult (data) {
        this.loading = true
        this.send(this.getFileName(), data, () => {
          this.loading = false
        }, () => {
          this.loading = false
        })
      },
      send: function (fieldName, file, load, error, progress, abort) {
        let formData = new FormData()
        console.log(progress)
        console.log(abort)
        var infos = {type: file.type, size: file.size}
        infos = Object.assign(infos, {talk_id: this.$route.params.id, talkFileType: 4, audioMessage: true})
        formData.append('files[0]', file, fieldName)
        formData.append('infos[0]', JSON.stringify(infos))
        callApi.post({
          uri: 'system/file',
          msgLoad: false,
          data: formData,
          headers: {headers: {'Content-Type': 'multipart/form-data'}},
          sucess: (data) => {
            if (data.status === 201 && data.data.status === 'success') {
              load(data.data.data[0].id)
            } else {
              error(this.$t('messages.error.text'))
            }
          },
          error: (err) => {
            console.log(err)
            error(this.$t('messages.error.text'))
          },
          msgSucess: false,
          msgError: true
        })
      },
      getFileName: function () {
        let date = new Date()
        return 'wise-control-audio-' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '_' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds() + '_' + Math.random().toString().replace('.', '')
      }
    }
  }
</script>
<style scoped>
  .vue-audio-recorder {
    width: 25px !important;
    height: 25px !important;
    margin: 0 !important;
    margin-top: 5px;
  }
</style>
