import { Model } from '@/default/model/Model'

export class TalkFlow extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '123eab35-8984-4551-bd15-5b054b2ac8bf'
    this.name = 'talk-flow'
    this.actions = {
      list: true
    }
    this.title = 'Chatbots'
    this.apiUrl = 'talks/chatbot'
    this.icon = 'smart_toy'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do chatbot',
      fields: ['name']
    }
    this.size = ['xs12', 'sm12', 'md6', 'ls4', 'xl4', 'offset-md3', 'offset-ls4', 'offset-xl4']
    // this.components.list.tableRow = () => import('@/modules/service-desk/pages/Ticket/Network/TicketDevices')
    this.setFields([
      this.fieldList({
        value: 'name',
        text: 'Nome'
      })
    ])
  }
}

