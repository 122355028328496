import TicketLocation from '@/modules/service-desk/config/Ticket/TicketLocation'

class TicketLocations extends TicketLocation {
  constructor () {
    super()
    this.actions = {
      insert: false,
      update: true,
      delete: true
    }
  }
}
export default new TicketLocations()
