<template>
  <view-detail title="Criar/Importar Grupos" :apiUrl="'grupo/criar-importar'" apiParams="?limit=1000" :notGetID="true">
    <template slot-scope="dados">
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <v-layout row style="padding: 20px;">
              <v-flex xs12 sm8 md6 lg6 offset-sm2 offset-md3 offset-lg3 text-xs-center style="padding: 10px;">
                <v-card>
                  <v-card-text>
                    <v-form v-model="validTipo">
                      <h2>Qual tipo de grupo você deseja criar/importar?</h2>
                      <v-autocomplete v-model="tipo" return-object :items="dados.content.tipos" placeholder="Selecione o tipo de grupo que você deseja criar/importar" item-text="nome_tipo" item-value="id" required :rules="[v => !!v || 'Campo obrigatório']" autocomplete ></v-autocomplete>
                    </v-form>
                  </v-card-text>
                </v-card>
                <v-scroll-y-transition mode="out-in">
                  <div v-if="!tipo" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center; margin-top: 50px;" > Selecione um tipo de grupo acima para continuar... </div>
                  <v-card v-else :key="tipo.id" style="margin-top: 20px;">
                    <v-card-text>
                      <v-avatar>
                        <v-icon color="blue darken-2" x-large>{{tipo.icone_tipo}}</v-icon> 
                      </v-avatar>
                      <h3 class="headline mb-2">
                        <strong>{{ tipo.nome_tipo }}</strong>
                      </h3>
                      <div class="gray--text subheading ">{{ tipo.obs_tipo }}</div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions text-xs-center style="text-align: center;">
                      <v-btn :disabled="!validTipo" color="primary" depressed @click="step++" style="width: 100%;">
                        Começar a criar/importar {{tipo.nome_plural}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-scroll-y-transition>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="2">
          <v-layout row style="padding: 20px;">
            <v-flex xs12 sm12 md12 lg12 >
              <v-card v-if="tipo">
                <v-card-title style="text-align: center;">
                  <h2 style="width: 100%; padding-bottom: 20px;">Insira {{tipo.nome_plural}} manualmente ou importe de uma planilha.</h2>
                  <div style="width: 100%; text-align: center;">
                    <v-btn color="primary" @click="grupos.push({grupo_tipo_id: tipo.id, mode: 'edit'})"><v-icon>add</v-icon> Criar {{tipo.nome_tipo}} Manualmente</v-btn>
                    <v-btn color="primary" @click="dialogPlanilha = true"><v-icon>import_export</v-icon> Importar {{tipo.nome_plural}} do Excel</v-btn><br>
                    <v-btn v-if="grupos.length > 1 && checkSaved()" :disabled="!validGrupos" color="green" @click="postAllGroup()" style="color: white;"><v-icon color="white">save</v-icon> Enviar Todos os registros não salvos</v-btn>
                    <v-dialog v-model="dialogPlanilha" width="700" >
                      <v-card>
                        <v-card-title class="headline" primary-title> Importar {{tipo.nome_plural}} do Excel </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="text-align: center;">
                          Para importar de uma planilha do Excel faça o <a href="static/planilhas/planilha-de-importacao-de-grupos-connect-view.xlsx">Download da planilha modelo clicando aqui</a>, insira os dados e faça upload clicando no botão abaixo. <br>
                          <strong>Obs: Não mude o nome das colunas da planilha, isso irá gerar erros na importação.</strong><br><br>
                          <div style="width: 100%; padding: 10px; border-style: solid; border-width: 2px; border-color: black;">
                            <img src="static/planilhas/imagem-planilha-importacao-grupos.JPG" alt="" style="width: 100%;">
                          </div><br>
                          <vue-xlsx-table @on-select-file="handleSelectedFile">
                            <span>
                              <v-icon color="white">import_export</v-icon> Importar {{tipo.nome_plural}} do Excel
                            </span>
                          </vue-xlsx-table>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" flat @click="dialogPlanilha = false" >
                            Cancelar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="grupos && grupos.length > 0" style="padding: 0;">
                  <v-form v-model="validGrupos">
                    <v-data-table dense :headers="headers" :items="grupos" hide-actions>
                      <template slot="items" slot-scope="props">
                        <tr v-if="grupos[props.index].mode == 'edit'" :style="{background: grupos[props.index].id ? '#b0fcb0' : '#ffedce'}">
                          <td class="text-xs-left" style="padding: 0 8px;"><v-text-field  :loading="saving == props.index" autofocus v-model="grupos[props.index].nome_grupo" :placeholder="headers[0].text" single-line required :rules="[v => !!v || 'Campo obrigatório']"></v-text-field></td>
                          <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" v-model="grupos[props.index].sigla_grupo" :placeholder="headers[1].text" single-line required :rules="[v => !!v || 'Obrigatório']"></v-text-field></td>
                          <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" v-model="grupos[props.index].latitude_grupo" :placeholder="headers[2].text" single-line></v-text-field></td>
                          <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" v-model="grupos[props.index].longitude_grupo" :placeholder="headers[3].text" single-line></v-text-field></td>
                          <td class="text-xs-left" style="padding: 0 8px;">
                            <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].grupo_dados_id" :items="filterGroups(dados.content.grupos)" placeholder="Selecione o Grupo" item-text="nome_grupo" item-value="id" :required="tipo.nivel_tipo > 0" :rules="tipo.nivel_tipo > 0 ? [v => !!v || 'Campo obrigatório'] : []" autocomplete ></v-autocomplete>
                          </td>
                          <td class="text-xs-left" style="padding: 0 8px;"><v-text-field :loading="saving == props.index" v-model="grupos[props.index].obs_grupo" :placeholder="headers[5].text" single-line></v-text-field></td>
                          <td class="justify-center layout px-0" style="padding: 10px 8px 0 8px;">
                            <v-btn v-if="!grupos[props.index].id" :disabled="!grupos[props.index].nome_grupo || !grupos[props.index].sigla_grupo || !grupos[props.index].grupo_dados_id" @click="postGroup(props.index, grupos[props.index])" flat icon color="green">
                              <v-icon>save</v-icon>
                            </v-btn>
                            <v-btn v-else :disabled="!grupos[props.index].nome_grupo || !grupos[props.index].sigla_grupo || !grupos[props.index].grupo_dados_id" @click="updateGroup(props.index, grupos[props.index])" flat icon color="green">
                              <v-icon>refresh</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr v-else :style="{background: grupos[props.index].id ? '#b0fcb0' : '#ffedce'}">
                          <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].nome_grupo}}</td>
                          <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].sigla_grupo}}</td>
                          <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].latitude_grupo}}</td>
                          <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].longitude_grupo}}</td>
                          <td class="text-xs-left" style="padding: 0 8px;">
                            <v-autocomplete :loading="saving == props.index" v-model="grupos[props.index].grupo_dados_id" :items="filterGroups(dados.content.grupos)" placeholder="Selecione o Grupo" item-text="nome_grupo" item-value="id" :required="tipo.nivel_tipo > 0" :rules="tipo.nivel_tipo > 0 ? [v => !!v || 'Campo obrigatório'] : []" autocomplete ></v-autocomplete>
                          </td>
                          <td class="text-xs-left" style="padding: 0 8px;">{{grupos[props.index].obs_grupo}}</td>
                          <td class="justify-center layout px-0" style="padding: 10px 8px 0 8px;">
                            <v-btn v-if="!grupos[props.index].id" :disabled="!grupos[props.index].nome_grupo || !grupos[props.index].sigla_grupo || !grupos[props.index].grupo_dados_id" @click="postGroup(props.index, grupos[props.index])" flat icon color="green">
                              <v-icon>save</v-icon>
                            </v-btn>
                            <v-btn v-else :disabled="!grupos[props.index].nome_grupo || !grupos[props.index].sigla_grupo || !grupos[props.index].grupo_dados_id" @click="updateGroup(props.index, grupos[props.index])" flat icon color="green">
                              <v-icon>refresh</v-icon>
                            </v-btn>
                            <v-btn v-if="grupos[props.index].mode != 'edit'" @click="grupos[props.index].mode = 'edit'; $forceUpdate()" flat icon color="green">
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn :disabled="step === 1" flat @click="step--; grupos = []">
                    Alterar Tipo de Grupo
                  </v-btn>
                  <v-spacer></v-spacer>
                  <strong v-if="grupos.length > 0">Total: {{grupos.length}}</strong>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-window-item>
      </v-window>
    </template>
  </view-detail>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import ViewDetail from '@/default/components/ViewDetail'
  export default {
    data () {
      return {
        dataTable: null,
        dataTableMode: 'edit',
        dialogPlanilha: false,
        tipo: null,
        validTipo: false,
        validGrupos: false,
        saving: null,
        headers: [
        { text: 'Nome Grupo', align: 'left', sortable: true, value: 'nome_grupo', width: '200px' },
        { text: 'Sigla', align: 'left', value: 'sigla_grupo', width: '80px' },
        { text: 'Latitude', align: 'left', value: 'latitude_grupo', width: '80px' },
        { text: 'Longitude', align: 'left', value: 'longitude_grupo', width: '80px' },
        { text: 'Grupo', align: 'left', value: 'grupo_dados_id', width: '200px' },
        { text: 'Observações', align: 'left', value: 'obs_grupo', width: '100px' },
        { text: 'Ações', width: '80px' }
        ],
        grupos: [],
        step: 1
      }
    },
    methods: {
      postAllGroup: function () {
        if (this.grupos) {
          callApi.post({
            uri: 'grupo/dados',
            data: {multiple: this.grupos.filter((grupo) => {
              if (!grupo.id) {
                return grupo
              }
            })},
            sucess: (data) => {
              var salvos = []
              salvos = this.grupos.filter((grupo) => {
                if (grupo.id) {
                  return grupo
                }
              })
              data.data.filter((grupo) => {
                salvos.push(grupo)
              })
              this.grupos = []
              this.grupos = salvos
              console.log(this.grupos)
            },
            msgLoad: true,
            msgSucess: true,
            msgError: true
          })
        }
      },
      postGroup: function (index, group) {
        if (group) {
          this.saving = index
          callApi.post({
            uri: 'grupo/dados',
            data: group,
            sucess: (data) => {
              this.grupos[index] = data.data
              this.grupos.push({grupo_tipo_id: this.tipo.id, mode: 'edit'})
              this.saving = null
              this.$forceUpdate()
            },
            msgLoad: false,
            msgSucess: false,
            msgError: true
          })
        }
      },
      updateGroup: function (index, group) {
        if (group) {
          this.saving = index
          callApi.put({
            uri: 'grupo/dados',
            data: group,
            id: group.id,
            sucess: (data) => {
              this.grupos[index] = data.data
              this.saving = null
              this.$forceUpdate()
            },
            msgLoad: true,
            msgSucess: true,
            msgError: true
          })
        }
      },
      filterGroups (item) {
        if (this.tipo && this.tipo.nivel_tipo > -1) {
          return item.filter((t) => {
            if (t.nivel_tipo === this.tipo.nivel_tipo - 1) {
              return t
            }
          })
        } else {
          return []
        }
      },
      checkSaved: function () {
        var naoSalvo = this.grupos.filter((grupo) => {
          if (!grupo.id) {
            return grupo
          }
        })
        console.log(naoSalvo)
        if (naoSalvo.length > 0) {
          return true
        } else {
          return false
        }
      },
      handleSelectedFile (convertedData) {
        if (convertedData && convertedData.body) {
          this.dataTableMode = 'table'
          convertedData.body.filter((item) => {
            this.grupos.push({
              nome_grupo: item.nome,
              sigla_grupo: item.sigla_codigo,
              latitude_grupo: item.latitude,
              longitude_grupo: item.longitude,
              obs_grupo: item.observacoes,
              grupo_tipo_id: this.tipo.id,
              mode: 'not_edit'
            })
          })
          this.dialogPlanilha = false
        } else {
          this.$swal({
            type: 'error',
            title: 'Oops... Erro ao ler este arquivo!',
            text: 'Ele pode estar corrompido ou fora do padrão determinado. Tente novamente ou contacte o suporte.'
          })
        }
      }
    },
    components: {
      ViewDetail
    }
  }
</script>
<style>

.xlsx-button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px 8px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
  transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
  position: relative;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color: #367da6 !important;
  border-color: #367da6 !important;

  padding: 0 16px;


}

</style>