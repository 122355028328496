var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentSystemIsValidToShow())?_c('div',[(_vm.showRelationsipsInformation)?_c('div',[_c('v-card',{staticClass:"elevation-17"},[_c('v-expansion-panel',{staticStyle:{"box-shadow":"unset"}},[_c('v-expansion-panel-content',{staticClass:"business-relationships",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('BusinessRelationshipsTitle',{attrs:{"business":_vm.business,"businessActions":_vm.businessActions}})]},proxy:true}],null,false,2666566204)},[(_vm.business.business_id && _vm.business.business)?_c('v-card',{staticClass:"elevation-0",attrs:{"color":"green lighten-5"}},[_c('v-divider'),_c('v-card-title',{staticStyle:{"padding":"10px 16px"},attrs:{"dense":"","flat":""}},[_c('strong',[_vm._v(" Criado a partir de: ")])]),_c('v-divider'),_c('v-list-tile',{attrs:{"avatar":""}},[_c('v-list-tile-avatar',[_c('v-avatar',[_c('v-icon',{style:({color: _vm.business.business.info.color || '' }),attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.business.business.info.icon)+" ")])],1)],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('span',[_vm._v(" "+_vm._s(_vm.business.business.info.title)+" ")]),_c('strong',[_vm._v(" #"+_vm._s(_vm.business.business.id)+" ")])]),_c('v-list-tile-sub-title',[_vm._v(" Em "+_vm._s(_vm._f("moment")(_vm.business.created_at,'DD/MM/YYYY HH:mm'))+" ")])],1),_c('v-list-tile-action',[_c('v-btn',{attrs:{"outline":"","small":"","link":"","to":{
                  name: _vm.business.business.info.name,
                  params: {
                    id: _vm.business.business.id
                  }
                }}},[_c('v-icon',[_vm._v("arrow_right_alt")]),_c('span',[_vm._v(" Acessar")])],1)],1)],1)],1):_vm._e(),(_vm.business.stage == 2 && _vm.businessActions && _vm.businessActions.length > 0)?_c('v-card',{staticClass:"elevation-0"},[_c('v-divider'),_c('v-card-text',{staticStyle:{"text-align":"center"}},_vm._l((_vm.businessActions),function(action,actionIndex){return _c('v-btn',{key:actionIndex,attrs:{"outline":""},on:{"click":function($event){_vm.$WiEditDialog({
                  wiConfig: action.wiConfig,
                  onSubmit: _vm.refresh,
                  data: _vm.getCurrentBusiness(_vm.business)
                })}}},[_c('v-icon',[_vm._v(_vm._s(action.icon))]),_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v("Criar "+_vm._s(action.title)+" ")]),_c('span',{staticClass:"hidden-xs-only hidden-sm-only"},[_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(" a partir deste ")]),_c('span',[_vm._v(" "+_vm._s(_vm.business.info.title))])])],1)}),1)],1):_vm._e(),(_vm.business.businesses && _vm.business.businesses.length > 0)?_c('v-card',{staticClass:"elevation-0",attrs:{"color":"blue lighten-5"}},[_c('v-divider'),_c('v-card-title',{staticStyle:{"padding":"10px 16px"},attrs:{"dense":"","flat":""}},[_c('strong',[_vm._v(" Este "),_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(_vm.business.info.title))]),_vm._v(" deu origem a: ")]),_c('v-spacer')],1),_c('v-divider'),_vm._l((_vm.business.businesses),function(business,businessIndex){return _c('div',{key:businessIndex},[_c('v-list-tile',{attrs:{"avatar":""}},[_c('v-list-tile-avatar',[_c('v-avatar',[_c('v-icon',{style:({color: business.info.color || '' }),attrs:{"large":""}},[_vm._v(" "+_vm._s(business.info.icon)+" ")])],1)],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('span',[_vm._v(" "+_vm._s(business.info.title)+" ")]),_c('strong',[_vm._v(" #"+_vm._s(business.id)+" ")])]),_c('v-list-tile-sub-title',[_vm._v(" Em "+_vm._s(_vm._f("moment")(business.created_at,'DD/MM/YYYY HH:mm'))+" ")])],1),_c('v-list-tile-action',[_c('v-btn',{attrs:{"outline":"","small":"","link":"","to":{
                    name: business.info.name,
                    params: {
                      id: business.id
                    }
                  }}},[_c('v-icon',[_vm._v("arrow_right_alt")]),_c('span',[_vm._v(" Acessar")])],1)],1)],1),_c('v-divider')],1)})],2):_vm._e()],1)],1)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }