import url from '@/default/store/modules/url/url'
import { Auth } from '@/default/service/Auth'
import Echo from 'laravel-echo'

export class WiSocket {
  channelName = null;
  createdEventName = null;
  updatedEventName = null;

  connection () {
    if (Auth.check() && !window.Echo) {
      window.Echo = this.createNewConnection()
    }
    return window.Echo
  }

  createNewConnection () {
    return new Echo({
      broadcaster: 'socket.io',
      host: url.state.echo.host + ':' + url.state.echo.port,
      reconnection: true,
      auth: {
        headers: this.getConnectionHeaders()
      }
    })
  }

  getConnectionHeaders () {
    return {
      'Authorization': 'Bearer ' + Auth.token(),
      'Hash': Auth.hash(),
      'X-Tenant': Auth.account().id,
      'Accept': 'application/json'
    }
  }

  getChannel () {
    return Auth.account().id + '.' + this.channelName
  }

  onEventSubscribe (onCreated, onUpdated) {
    if (this.channelName) {
      const connection = this.connection()
      .private(this.getChannel())
      this.onEventCreated(connection, onCreated)
      this.onEventUpdated(connection, onUpdated)
    }
  }

  onEventUnsubscribe () {
    if (this.channelName) {
      this.connection()
      .leave(this.getChannel())
    }
  }

  onEventCreated (connection, onCreated) {
    if (this.createdEventName) {
      connection.listen('.' + this.createdEventName, (response) => {
        if (onCreated) onCreated(response) 
      })
    }
  }

  onEventUpdated (connection, onUpdated) {
    if (this.updatedEventName) {
      connection.listen('.' + this.updatedEventName, (response) => {
        if (onUpdated) onUpdated(response) 
      })
    }
  }
}