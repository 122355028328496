<template>
  <v-card v-if="vcardData && vcardData.length > 0"  class="elevation-5 wi-vcard">
    <v-list dense two-line>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <img v-if="false" :src="item.avatar">
          <img src="static/icons/user_icon_1.png" alt="">
        </v-list-tile-avatar>
        <v-list-tile-content>
          <div v-for="(vcardItem, vcardItemKey) in vcardData" :key="vcardItemKey">
            <!-- <v-list-tile-title v-if="vcardItem[0] == 'fn'" v-html="vcardItem[3]" class="wi-vcard__name"></v-list-tile-title> -->
            <v-list-tile-title v-if="vcardItem.FN" v-html="vcardItem.FN" class="wi-vcard__name"></v-list-tile-title>
            <v-list-tile-sub-title v-if="vcardItem.waid" v-html="vcardItem.waid" class="wi-vcard__tell"></v-list-tile-sub-title>
          </div>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>code</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title style="padding: 0 15px;">
                <strong> Código do contato</strong>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <span v-html="vcardTest(vcard)"></span>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <!-- <v-divider></v-divider>
    <v-card-actions v-if="vcardData[0].waid">
      <v-btn small outline block @click="addPhoneToContacts()">Adicionar aos contatos</v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
  import 'ical.js/build/ical.js'
  export default {
    data: () => ({
      vcardData: [],
      dialog: false
    }),
    methods: {
      vcardTest: function (vcard) {
        return vcard.replace(/\n/g, '<br>')
      },
      addPhoneToContacts: function () {
        let name = null
        let phone = null
        this.vcardData[1].map(vcardItem => {
          if (vcardItem[0] == 'tel' || vcardItem[0] == 'item1.tel') {
            phone = vcardItem[3]
          }
          if (vcardItem[0] == 'fn') {
            name = vcardItem[3]
          }
        })
        if (phone) {
          this.$WiEditDialog({
            wiConfig: 'talk-contact',
            data: {
              name: name || null,
              type: 1,
              number: phone.replace(/\D/g,'')
            },
            redirectOnSubmit: false
          })
        }
      },
      formatVcard: function (texto) {
        var vCards = texto.split('END:VCARD');

        // Remove entradas vazias resultantes da divisão
        vCards = vCards.filter(function (vCard) {
          return vCard.trim() !== '';
        });

        // Objeto JSON para armazenar as informações vCard
        var json = [];

        // Itera sobre cada entrada vCard e converte para JSON
        vCards.forEach(function (vCard) {
          // Divide a entrada vCard em linhas
          var linhas = vCard.trim().split('\n');

          // Objeto JSON para armazenar os atributos vCard
          var vCardJSON = {};

          // Itera sobre cada linha e adiciona ao objeto JSON
          linhas.forEach(function (linha) {
            // Divide cada linha usando dois-pontos como delimitador
            var partes = linha.split(':');

            // Remove espaços em branco ao redor dos valores
            var chave = partes[0].trim();
            var valor = partes[1] ? partes[1].trim() : '';

            // Se a chave for "item1.TEL" e contiver "waid=", adiciona ao objeto JSON
            if (chave === "item1.TEL" && valor.includes("waid=")) {
              var waid = valor.split("waid=")[1];
              vCardJSON.waid = waid;
            }

            if (!vCardJSON.waid) {
              var waid1 = chave.split("waid=")[1];
              vCardJSON.waid = waid1;
            }

            // Adiciona o par chave-valor ao objeto JSON
            vCardJSON[chave] = valor;
          });

          // Adiciona o objeto JSON resultante ao array JSON
          json.push(vCardJSON);
        });

        return json;
      }
    },
    mounted: function () {
      // this.vcardData = window.ICAL.parse(this.vcard)
      this.vcardData = this.formatVcard(this.vcard)
    },
    props: {
      vcard: {
        type: String,
        required: true
      }
    }
  }
</script>
<style scoped>
.wi-vcard {
  margin-top: 10px;
  margin-bottom: 10px;
}
.wi-vcard__name {
  font-weight: bold;
}
.wi-vcard__tell {
  font-weight: bold;
}
</style>
