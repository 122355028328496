import { Socket } from '@/default/service/Socket'

export default {
  data () {
    return {
      socket: null,
      server: {
        status: 'disconnected',
        information: {}
      }
    }
  },
  methods: {
    socketConnect: function () {
      this.socket = new Socket()
      this.socket.connect()
    },
    socketOnConnect: function (networkServerId) {
      this.socket.subscribe({
        url: 'connect',
        callback: () => {
          this.checkNetworkServerStatusNow(networkServerId)
        }
      })
    },
    socketOnDisconect: function () {
      this.socket.subscribe({
        url: 'disconnect',
        callback: () => {
          this.server.status = 'disconnected'
        }
      })
    },
    checkNetworkServerStatusNow: function (networkServerId) {
      this.socket.get({
        url: 'network-server-status',
        params: { id: networkServerId },
        callback: response => {
          this.networkServerStatusChange(response.status)
        }
      })
    },
    checkNetworkServerStatusSubscribe: function (networkServerId) {
      this.socket.subscribe({
        url: 'network-server-status-' + networkServerId,
        callback: response => {
          this.networkServerStatusChange(response.status)
        }
      })
    },
    networkServerStatusChange: function (status) {
      this.server.status = status
      if (this.networkServerStatusChanged) {
        this.networkServerStatusChanged()
      }
    }
  },
  beforeDestroy () {
    this.socket.disconnect()
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  }
}
