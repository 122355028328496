<template style="max-width: 100%;">
  <div style="max-width: 100%;">
    <div style="max-width: 100%;">
      <v-list v-if="data" dense two-line>
        <v-list-tile :disabled="disabled" avatar @click="dialog = true" style="border-style: solid; border-color: gray; border-width: 1px; height: 45px !important;" class="asdasdasd">
          <v-list-tile-avatar>
            <v-icon large :color="data.cor_categoria || 'primary'">{{data.icone_categoria || 'category'}}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Tipo de Ocorrência</v-list-tile-sub-title>
            <v-list-tile-title v-html="data.nome_categoria_grupo_previsao ? data.nome_categoria_grupo_previsao : (data.nome_categoria || 'Nenhum Tipo de Ocorrência Selecionado')"></v-list-tile-title>
            <v-input style="display: none;" :required="required" :rules="rules" v-model="chamado_categoria_id"></v-input>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn flat icon color="green" @click="dialog = true">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>
    <!-- <div style="background: white; border-style: solid; border-color: gray; border-width: 1px; height: 45px !important; padding: 0 20px;" class="asdasdasd">
        <v-input :required="required" :rules="rules" :loading="true" v-model="chamado_categoria_id" append-icon="edit" prepend-icon="category" class="asdasdasd" @click:append="() => { dialog = true }">
          <span>{{data.nome_categoria_grupo_previsao ? data.nome_categoria_grupo_previsao : (data.nome_categoria || 'Selecione o tipo de Ocorrência')}}</span>
        </v-input>
    </div> -->
    <v-dialog v-model="dialog" return-value="dialog" persistent scrollable max-width="75%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-toolbar dense>
          <v-icon large >category</v-icon>
          <v-toolbar-title>
            <strong>Selecionar Tipo de Ocorrência</strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="dialog" style="padding: 0; min-height: 300px;">
          <view-detail title="Tipos de Ocorrência" apiUrl="chamado/ocorrencia" :apiParams="'?where=ativa_categoria,1&whereNull=chamado_categoria_id&order=nome_categoria,asc'" :notGetID="true" :not-show-toolbar="true">
            <template slot-scope="dados">
              <v-container align="center" style="padding: 0;">
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 lg12 d-flex style="padding: 10px; margin: 20px 0;">
                    <v-treeview :items="dados.content.data" :load-children="fetchUsers" transition item-text="nome_categoria" open-on-click>
                      <span slot="label" slot-scope="{ item }" :style="{color: item.ativa_categoria == 'inativo' ? '#e0e0e0' : 'black'}">
                        {{item.nome_categoria}} <span v-if="item.previsao_categoria && item.previsao_categoria != '00:00:00'" style="color: #b0bec5;">[{{item.previsao_categoria}}]</span> <span v-if="item.ativa_categoria < 1">(Inativo)</span>
                      </span>
                      <div slot="prepend" slot-scope="{ item }">
                        <v-btn v-if="item.funcao_categoria > 0 || selectableInAnyCategory" outline :disabled="ticketFunction !== 0 && item.funcao_categoria != ticketFunction && item.funcao_categoria != 1" color="green" small @click="setSelected(item)">
                          <v-icon small>done</v-icon>
                        </v-btn>
                        <v-icon medium :style="{'color': item.ativa_categoria == 'inativo' ? '#e0e0e0' : item.cor_categoria}">{{item.icone_categoria || 'category'}}</v-icon> 
                      </div>
                    </v-treeview>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>
          </view-detail>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'OcorrenciaDialog',
    data () {
      return {
        dialog: false,
        chamado_categoria_id: null
      }
    },
    methods: {
      async fetchUsers (item) {
        return new Promise((resolve, reject) => {
          callApi.get({
            uri: 'chamado/ocorrencia',
            params: {limit: 1000, order: 'nome_categoria,asc', where: 'ativa_categoria,1;chamado_categoria_id,' + item.id},
            sucess: data => {
              resolve(data.data.data)
              var itens = data.data.data.filter(function (i) {
                if (i.sub_grupos > 0) {
                  i['children'] = []
                }
                return i
              })
              item.children.push(...itens)
            },
            error: err => {
              reject(err)
              return err
            }
          })
        })
      },
      setSelected: function (selected) {
        this.chamado_categoria_id = selected.id
        this.$emit('select', {
          chamado_categoria_id: selected.id,
          nome_categoria: selected.nome_categoria,
          nome_categoria_grupo_previsao: selected.nome_categoria_grupo_previsao,
          icone_categoria: selected.icone_categoria,
          cor_categoria: selected.cor_categoria,
          chamado_impacto_id: selected.chamado_impacto_id,
          chamado_urgencia_id: selected.chamado_urgencia_id,
          previsao_chamado: selected.previsao_categoria,
          prevision: selected.previsao_categoria,
          definir_previsao: 'serv_prev'
        })
        this.dialog = false
        this.$forceUpdate()
      }
    },
    created: function () {
      this.chamado_categoria_id = this.data.chamado_categoria_id
    },
    props: {
      'data': {
        required: true,
        type: Object
      },
      'form': {
        required: false,
        type: Object
      },
      'ticketFunction': {
        required: false,
        type: Number,
        default: 0
      },
      'disabled': {
        required: false,
        type: Boolean,
        default: false
      },
      'required': {
        required: false,
        type: Boolean,
        default: false
      },
      'rules': {
        required: false,
        type: Array
      },
      'selectableInAnyCategory': {
        required: false,
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped>
  .asdasdasd >>> a {
    height: 45px !important;
  }
</style>
