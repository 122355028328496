<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md8 lg8>
      <ticket-header-person :dados="dados"></ticket-header-person>
    </v-flex>
    <v-flex xs12 sm12 md4 lg4>
      <ticket-header-status :dados="dados"></ticket-header-status>
    </v-flex>
  </v-layout>
</template>
<script>
  import TicketHeaderPerson from '@/modules/service-desk/pages/Ticket/Info/Header/TicketHeaderPerson'
  import TicketHeaderStatus from '@/modules/service-desk/pages/Ticket/Info/Header/TicketHeaderStatus'
  export default {
    name: 'TicketHeader',
    data () {
      return {}
    },
    methods: {},
    props: ['dados'],
    components: {
      TicketHeaderPerson,
      TicketHeaderStatus
    }
  }
</script>
