<template style="max-width: 100%;">
  <div style="max-width: 100%;">
    <div style="max-width: 100%;">
      <v-list v-if="data" dense two-line>
        <v-list-tile :disabled="disabled" v-ripple avatar @click="dialog = true" style="border-style: solid; border-color: gray; border-width: 1px; height: 45px !important;" class="asdasdasd">
          <v-list-tile-avatar>
            <v-icon large :color="data.color || 'primary'">{{data.icon || 'category'}}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Categoria Financeira</v-list-tile-sub-title>
            <v-list-tile-title v-html="data.name ? data.name : (data.name || 'Nenhum Tipo de Ocorrência Selecionado')"></v-list-tile-title>
            <v-input style="display: none;" :required="required" :rules="rules" v-model="finance_category_id"></v-input>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn flat icon color="green" @click="dialog = true">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>
    <v-dialog v-model="dialog" return-value="dialog" persistent scrollable max-width="75%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-toolbar dense>
          <v-icon large >category</v-icon>
          <v-toolbar-title>
            <strong>Selecionar Tipo de Ocorrência</strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="dialog" style="padding: 0; min-height: 300px;">
          <view-detail title="Tipos de Ocorrência" apiUrl="finance/category/in" :apiParams="'?whereNull=finance_category_id&order=active,desc;name,asc'" :notGetID="true" :not-show-toolbar="true">
            <template slot-scope="dados">
              <v-container align="center" style="padding: 0;">
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 lg12 d-flex style="padding: 10px; margin: 20px 0;">
                    <v-treeview :items="checkChildrenCount(dados.content.data)" :load-children="fetchUsers" transition item-text="name" open-on-click>
                      <span slot="label" slot-scope="{ item }" :style="{color: !item.active ? '#e0e0e0' : 'black'}">
                        {{item.name}} <span v-if="item.active < 1">(Inativo)</span>
                      </span>
                      <div slot="prepend" slot-scope="{ item }">
                        <v-btn outline color="green" small @click="setSelected(item)">
                          <v-icon small>done</v-icon>
                        </v-btn>
                        <v-icon medium :style="{'color': !item.active ? '#e0e0e0' : item.color}">{{item.icon || 'category'}}</v-icon> 
                      </div>
                    </v-treeview>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>
          </view-detail>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'OcorrenciaDialog',
    data () {
      return {
        dialog: false,
        finance_category_id: null
      }
    },
    methods: {
      async fetchUsers (item) {
        return new Promise((resolve, reject) => {
          callApi.get({
            uri: 'finance/category/in',
            params: {limit: 1000, order: 'name,asc', where: 'finance_category_id,' + item.id},
            sucess: data => {
              resolve(data.data.data)
              var itens = data.data.data.filter(function (i) {
                if (i.children_count > 0) {
                  i['children'] = []
                } else {
                  delete i.children
                }
                return i
              })
              item.children.push(...itens)
            },
            error: err => {
              reject(err)
              return err
            }
          })
        })
      },
      checkChildrenCount: function (categories) {
        var items = categories.filter(function (i) {
          if (i.children_count > 0) {
            i['children'] = []
          } else {
            delete i.children
          }
          return i
        })
        return items
      },
      setSelected: function (selected) {
        // this.finance_category_id = selected.id
        // this.$emit('select', {
        //   finance_category_id: selected.id,
        //   nome_categoria: selected.nome_categoria,
        //   nome_categoria_grupo_previsao: selected.nome_categoria_grupo_previsao,
        //   icone_categoria: selected.icone_categoria,
        //   cor_categoria: selected.cor_categoria,
        //   chamado_impacto_id: selected.chamado_impacto_id,
        //   chamado_urgencia_id: selected.chamado_urgencia_id,
        //   previsao_chamado: selected.previsao_categoria,
        //   definir_previsao: 'serv_prev'
        // })
        console.log(selected)
        this.dialog = false
        this.$forceUpdate()
      }
    },
    created: function () {
      this.finance_category_id = this.data.finance_category_id
    },
    props: {
      'data': {
        required: true,
        type: Object
      },
      'form': {
        required: false,
        type: Object
      },
      'ticketFunction': {
        required: false,
        type: Number,
        default: 0
      },
      'disabled': {
        required: false,
        type: Boolean,
        default: false
      },
      'required': {
        required: false,
        type: Boolean,
        default: false
      },
      'rules': {
        required: false,
        type: Array
      }
    },
    components: {
      ViewDetail
    }
  }
</script>
<style scoped>
.asdasdasd >>> a {
  height: 45px !important;
}
</style>
