<template>
  <v-dialog v-model="dialog" max-width="580" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn v-if="filtered" class="filter-by-period__card-action hidden-xs-only hidden-sm-only" color="yellow" small outline v-on="on">
        <span>Filtrando período</span>
      </v-btn>
      <v-btn v-else class="filter-by-period__card-action hidden-xs-only hidden-sm-only" small outline v-on="on">
        <span>Filtrar por período</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense>
        <v-icon>event</v-icon>
        <v-toolbar-title>Filtro por período de tempo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 800px; text-align: center;">
        <v-form v-model="filterIsValid" ref="filterByPeriod">
          <v-toolbar-title>Selecione o tipo de data e o intervalo de tempo</v-toolbar-title>
          <v-layout row wrap class="filter-by-period__content">
            <v-flex xs12 sm12 md12 lg12 style="padding: 0px 10px 0px 20px;">
              <v-select
                v-model="filters.period"
                :items="periods"
                label="Selecione a data"
                :rules="[v => !!v || 'Campo obrigatório!']"
                required
                outline
                block
              ></v-select>
            </v-flex>
            <!-- <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-switch
                class="filter-by-period__datetime"
                v-model="filters.datetime"
                label="Filtrar data e hora"
              ></v-switch>
            </v-flex> -->
            <v-flex xs12 sm12 md6 lg6 style="padding: 0px 10px 0px 20px;">
              <v-text-field
                v-model="filters.dateStart"
                label="Data Inicial"
                :rules="[v => !!v || 'Campo obrigatório!']"
                required
                type="datetime-local"
                outline
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 style="padding: 0px 10px 0px 20px;">
              <v-text-field
                v-model="filters.dateEnd"
                label="Data Final"
                :rules="[v => !!v || 'Campo obrigatório!']"
                required
                type="datetime-local"
                outline
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-toolbar dense>
        <v-spacer></v-spacer>
        <v-btn block outline color="red" class="filter-by-period__action-btn" @click="clearFilterPeriod()" :disabled="!filtered">
          <v-icon>close</v-icon>
          <span>Limpar filtros</span>
        </v-btn>
        <v-btn block outline color="green" class="filter-by-period__action-btn" :disabled="!filterIsValid" @click="setSelectedPeriod()">
          <v-icon>filter_list</v-icon>
          <span>Filtrar</span>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'FilterByPeriod',
    data: () => ({
      dialog: false,
      refresh: null,
      filtered: false,
      filterIsValid: false,
      filters: {},
      periods: [
        {
          value: 'last_event_time',
          text: 'Útima interação'
        },
        {
          value: 'opened_at',
          text: 'Data de abertura'
        },
        {
          value: 'closed_at',
          text: 'Data de encerramento'
        },
        {
          value: 'canceled_at',
          text: 'Data de cancelamento'
        },
        {
          value: 'competence_at',
          text: 'Data de Competência'
        }
      ]
    }),
    computed: {
      filterIsVali () {
        return (this.filters.period && this.filters.dateStart && this.filters.dateEnd) || false
      }
    },
    methods: {
      setSelectedPeriod: function () {
        this.$Storage.set('filter-by-period', this.filters)
        this.sendSelectedPeriodToTickets(true)
        this.closeDialog()
      },
      getSelectedPeriod: function () {
        let filters = this.$Storage.get('filter-by-period')
        this.filters = filters || {}
      },
      sendSelectedPeriodToTickets: function (refresh) {
        if (this.filterIsVali) {
          this.$emit('selectPeriod', {
            selectedPeriod: this.filters,
            refresh: refresh
          })
          this.filtered = true
        }
      },
      clearFilterPeriod: function () {
        this.filters = {}
        this.$refs.filterByPeriod.reset()
        this.$emit('selectPeriod', { selectedPeriod: null, refresh: true })
        this.$Storage.remove('filter-by-period')
        this.filtered = false
        this.closeDialog()
      },
      closeDialog: function () {
        this.dialog = false
      }
    },
    created: function () {
      this.getSelectedPeriod()
      this.sendSelectedPeriodToTickets(false)
    }
  }
</script>

<style>
  .filter-by-period__select .v-input__slot {
    margin-bottom: 0 !important;
  }
  .filter-by-period__select-selected-item {
    text-overflow: ellipsis;
    max-width: 30px;
    overflow: hidden;
  }
  .filter-by-period__select-selected-item-name {
    padding-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .filter-by-period__table-td {
    padding: 0 5px !important;
  }
  .filter-by-period__table-tickets {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .filter-by-period__content {
    margin-top: 25px;
    margin-bottom: 5px;
  }
  .filter-by-period__action-btn {
    margin: 5px !important;
  }
  .v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
</style>