<template>
  <v-layout align-center justify-center row wrap>
    <v-flex xs12 sm12 style="padding-right: 10px;">
      <v-card class="elevation-10 pb-4">
        <ProducerModelItem
          v-if="model.leds"
          :host="host"
          :model="model"
          name="leds"
          :config="model.leds"
          title="LEDs"
          icon="brightness_high"
          class="mb-3"
        ></ProducerModelItem>
        <v-divider v-if="model.connections"></v-divider>
        <ProducerModelItem
          v-if="model.connections"
          :host="host"
          :model="model"
          name="connections"
          :config="model.connections"
          title="Conexões"
          icon="settings_input_component"
          class="mb-3"
        ></ProducerModelItem>
        <v-divider v-if="model.buttons"></v-divider>
        <ProducerModelItem
          v-if="model.buttons"
          :host="host"
          :model="model"
          name="buttons"
          :config="model.buttons"
          title="Botões"
          icon="touch_app"
        ></ProducerModelItem>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  import ProducerModelItem from './Item'
  export default {
    name: 'ProducerModel',
    props: {
      model: {
        required: true,
        default: () => ({})
      },
      host: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      ProducerModelItem
    }
  }
</script>
<style scoped>
  .producer-model__carousel {
    max-width: 300px;
  }
  .diagnostic-model {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-model__enable-button {
    cursor: pointer;
  }
  .diagnostic-model__toolbar {
    z-index: 999;
  }

  .diagnostic-model__model {
    text-align: left;
    font-size: 15px;
    color: gray;
  }
  .diagnostic-model__not-found {
    margin: 50px 0;
  }
  .diagnostic-model__title {
    color: red;
  }
  .diagnostic-model__title--signal_is_ok {
    color: green;
  }
</style>
