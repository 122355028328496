import { Model } from '@/default/model/Model'

export class AccountBancoInter extends Model {
  constructor () {
    super()
    this.id = 'c80ca89f'
    this.actions = {
      update: true
    }
    this.title = 'Configuração Banco Inter'
    this.icon = 'settings_suggest'

    this.apiUrl = 'finance/account'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados de integração encontrados no Internet Banking.'
    }),
    integration_username: this.fieldText({
      value: 'integration_username',
      text: 'ClientId',
      placeholder: 'Insira o Cliente ID',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    integration_access_token: this.fieldText({
      value: 'integration_access_token',
      text: 'ClientSecret',
      placeholder: 'Insira o Cliente Secret',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    integration_public_token: this.fieldPassword({
      value: 'integration_public_token',
      text: 'Senha da aplicação',
      placeholder: 'Obs: Não é a senha do internet banking',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Informe o conteúdo do arquivo API_Certificado.crt'
    }),
    integration_cert_crt: this.fieldTextArea({
      value: 'integration_cert_crt',
      text: 'Inter API_Certificado.crt',
      required: true
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Informe o conteúdo do arquivo Inter API_Chave.key'
    }),
    integration_cert_key: this.fieldTextArea({
      value: 'integration_cert_key',
      text: 'Inter API_Chave.key',
      required: true
    })
  }
}
