import { callApi } from '@/default/service/Api'

export default {
  namespaced: true,
  state: {
    loading: false,
    period: 'month',
    currentDate: '',
    currentDateTitle: '',
    events: []
  },
  mutations: {
    loading (state, data) {
      state.loading = data
    },
    period (state, data) {
      state.period = data
    },
    currentDate (state, data) {
      state.currentDate = data
    },
    currentDateTitle (state, data) {
      state.currentDateTitle = data
    },
    events (state, data) {
      state.events = data
    }
  },
  actions: {
    setContent: function ({ commit }, { period, currentDate, currentDateTitle, events }) {
      commit('period', period)
      commit('currentDate', currentDate)
      commit('currentDateTitle', currentDateTitle)
      commit('events', events)
    },
    getContent: function ({ state, commit, dispatch }, { currentDate, action }) {
      commit('loading', true)
      callApi.get({
        uri: 'system/calendar',
        params: {currentDate: currentDate || state.currentDate, action: action},
        sucess: (response) => {
          dispatch('setContent', {
            period: response.data.period,
            currentDate: response.data.current_date,
            currentDateTitle: response.data.current_date_title,
            events: response.data.events || []
          })
          commit('loading', false)
        }
      })
    }
  }
}
