import i18n from '@/lang/i18n'
import menu from '@/modules/talk/menu'
import router from '@/modules/talk/routes'
import config from '@/modules/talk/config'

export default {
  id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
  name: 'talk',
  title: i18n.t('modules.talks.title'),
  description: i18n.t('modules.talks.description'),
  icon: 'wises-technology-module-talk.png?v=1',
  iconSystem: 'wises-technology-system-talk.png?v=1',
  iconProfile: 'profile_service_desk.png?v=1',
  color: '#06A34D',
  menu,
  router,
  config
}
