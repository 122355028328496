import { Model } from '@/default/model/Model'

export class TalkChannelPerson extends Model {
  constructor () {
    super()
    this.list = true
    this.list = true
    this.id = {
      id: 'b49f9043',
      create: 'f2ef3194',
      update: 'dd919a8b',
      delete: '911cbaaa'
    }
    this.name = 'talk-channel-person'
    this.title = {
      list: 'Usuários do Canal',
      edit: {
        insert: 'Criar usuário do canal',
        update: 'Editar usuário do canal'
      }
    }
    this.apiUrl = 'talks/channel-person'
    this.icon = 'message'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do usuário',
      fields: ['person_id', 'talk_channel_id', 'person.name']
    }
    this.maxWidth = '800px'
    // this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    // this.components.list.tableRow = () => import('@/modules/talk/pages/Talks/TalksTable')
    this.setFields([
      this.fieldList({
        value: 'person.name',
        text: 'Nome'
      }),
      this.fieldSubHeader({
        text: 'Selecione o(s) colaborador(es) que terá acesso ao canal de atendimento.'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Colaborador',
        show: 'person.name',
        icon: 'person',
        wiConfig: 'collaborator'
      })
    ])
  }
}
