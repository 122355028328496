<template>
  <v-dialog v-model="dialog" persistent max-width="90%">
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card>
      <v-toolbar dense flat :color="!$wiDark ? '#FAFAFA': '#424242'">
        <v-icon v-text="attendance.category.icon"></v-icon>
        <v-toolbar-title>
          <span v-text="attendance.category.name"></span>
          <span> - </span>
          <span v-text="attendance.person.name"></span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outline small @click="printContent()">
          <v-icon>print</v-icon>
          <span>Imprimir</span>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn> 
      </v-toolbar>
      <v-card-text v-if="dialog">
        <TinyEditor 
          :apiKey="apiKey"
          :initial-value="attendance.description"
          :init="config"
          :disabled="true"
        ></TinyEditor>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import TinyEditor from '@tinymce/tinymce-vue'
  export default {
    name: 'MedicalRecordAttendanceDialog',
    data: () => ({
      dialog: false,
      apiKey: '1pwn5rw26syn27rz79wgwnapcgc98crz45y7ec41lhi2hare',
      config: {
        height: window.innerHeight - 170,
        menubar: false,
        readonly : true,
        content_css: 'document',
        content_style: 'body { margin: 1rem auto; max-width: 900px; }',
        language: 'pt_BR',
        plugins: ['print'],
        toolbar: []
      }
    }),
    methods: {
      printContent: function () {
        console.log('Hello World!')
        // eslint-disable-next-line
        tinymce.activeEditor.execCommand('mcePrint');
      }
    },
    props: ['attendance'],
    components: {
      TinyEditor
    }
  }
</script>