<template>
  <div>
    <v-btn @click="exportBudgets">Exportar</v-btn>
  </div>
</template>
<script>
  export default {
    name: 'ExportSalesReport',
    methods: {
      exportBudgets: function () {
        console.log('budget-export-filters')
        this.$WiEditDialog({
          wiConfig: 'budget-export-filters'
        })
      }
    }
  }
</script>
