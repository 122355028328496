<template>
  <v-list-tile avatar>
    <v-list-tile-avatar style="min-width: 40px !important;">
      <v-avatar>
        <v-icon v-if="field.icon" large :color="field.color" style="padding-bottom: 2px; padding-right: 2px;">
          {{field.icon}}
        </v-icon>
        <div v-else>
          <v-icon v-if="field.reply && field.reply.content" large :color="field.color" style="padding-bottom: 2px; padding-right: 2px;">
            check_box
          </v-icon>
          <v-icon v-else large :color="field.color" style="padding-bottom: 2px; padding-right: 2px;">
            check_box_outline_blank
          </v-icon>
        </div>
      </v-avatar>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-sub-title style="color: rgba(0,0,0,.54);">
        <FormReplyListReplyText :label="field.label"></FormReplyListReplyText>
      </v-list-tile-sub-title>
      <v-list-tile-title>
        <strong v-if="field.reply && field.reply.content"> Sim </strong>
        <strong v-else> Não </strong>
      </v-list-tile-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
<script>
  import FormReplyListReplyText from './FormReplyListReplyText'
  export default {
    name: 'FormReplyListReplyCheckbox',
    props: {
      field: {
        type: Object,
        required: true
      }
    },
    components: {
      FormReplyListReplyText
    }
  }
</script>