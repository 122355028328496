<template>
  <div>
    <v-list subheader one-line dense :style="{background: $wiDark ? '' : 'white'}">
      <v-list-tile avatar>
        <v-list-tile-avatar style="padding-right: 10px;">
          <v-avatar>
            <v-img src="static/icons/user_icon_1.png" style="max-width: 40px; max-height: 40px;" alt="avatar"/>
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title v-if="before">{{action}} </v-list-tile-sub-title>
          <v-list-tile-title style="font-size: 16px;">
            <strong>{{event.person.name}}</strong>
          </v-list-tile-title>
          <v-list-tile-sub-title v-if="!before">{{action}} </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-list-tile-action-text>{{ event.instant_formated }}</v-list-tile-action-text>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <v-divider></v-divider>
  </div>
</template>
<script>
  export default {
    name: 'WiEventsEventPerson',
    props: {
      event: {
        required: true,
        default: {}
      },
      action: {
        default: 'Executou: '
      },
      before: {
        default: false
      }
    }
  }
</script>
