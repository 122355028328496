import { Model } from '@/default/model/Model'

export default class PostAuthorFollower extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1230c290'
    this.name = 'post-author-follower'
    this.title = {
      list: 'Seguidores',
      edit: {
        insert: 'Novo Seguidor',
        update: 'Editar Seguidor'
      }
    }
    this.apiUrl = 'sites/author/follower'
    this.icon = 'receipt_long'
    this.search = {
      placeholder: 'Pesquise pelo nome do seguidor ou autor',
      fields: ['follower.name', 'author.name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.setFields([
      this.fieldSubHeader({
        text: 'Selecione o autor e em seguida o seguidor.'
      }),
      this.fieldSelectApi({
        value: 'author_id',
        text: 'Autor',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'sites/author',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      }),
      this.fieldSelectApi({
        value: 'follower_id',
        text: 'Cliente/Seguidor',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'sites/customer',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'author.name',
        text: 'Autor'
      }),
      this.fieldList({
        value: 'follower.name',
        text: 'Seguidor'
      })
    ])
  }
}
