<template>
  <v-card
    outlined
    elevation="17"
    class="medical-record-businesses"
    :color="!$wiDark ? '#FAFAFA': '#424242'"
  >
    <v-toolbar dense flat :color="!$wiDark ? '#FAFAFA': '#424242'">
      <v-icon>person_search</v-icon>
      <v-toolbar-title>
        <span v-if="content && content.businesses && content.businesses.length > 0">{{content.businesses.length}} </span>
        <span> Orden(s) de serviço</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- 
      <v-btn v-if="content && content.businesses && content.businesses.length > 0" outline small>
        <v-icon>filter_list</v-icon>
        <span>Filtrar</span>
      </v-btn> 
      -->
      <v-btn small outline :disabled="loading" @click="addOrder()">
        <v-icon>add</v-icon>
        <span>Nova ordem</span>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card-text :style="{'min-height': '350px', 'max-height': '350px'}" style="padding: 0 10px 0px 30px; overflow-y: scroll;">
      <v-timeline v-if="content && content.businesses && content.businesses.length > 0" dense>
        <v-timeline-item :color="$wiDark ? 'black': 'white'" v-for="(order, orderKey) in content.businesses" :key="orderKey" large>
          <template v-slot:icon>
            <v-avatar :style="{ background: order.category.color }">
              <v-icon :color="$WiColorContrast(order.category.color)">
                {{ order.category.icon }}
              </v-icon>
            </v-avatar>
          </template>
          <v-card :color="$wiDark ? 'black': ''">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>
                  <strong> #{{ order.id }} - </strong>
                  <span> {{ order.date_start | date('DD/MM/YYYY HH:mm') }} </span>
                </v-list-tile-sub-title>
                <v-list-tile-title>
                  <strong v-text="order.category.name"></strong>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn icon ripple @click="$WiEditDialog({
                    wiConfig: order.info.name,
                    onSubmit: refresh,
                    data: order,
                    redirectOnSubmit: false
                  })
                ">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn icon ripple @click="$WiDialog(wiDialogs.files(order.info.name, order.id))">
                  <v-badge right overlap offset-x="22" offset-y="24">
                    <template v-slot:badge>
                      <span v-if="order.count_files">
                        <span v-if="order.count_files < 10">{{order.count_files}}</span>
                        <span v-else>9+</span>
                      </span>
                    </template>
                    <v-icon>
                      attach_file
                    </v-icon>
                  </v-badge>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn icon ripple @click="$WiDialog(wiDialogs.events(order.info.name, order.id))">
                  <v-badge right overlap offset-x="22" offset-y="24">
                    <template v-slot:badge>
                      <span v-if="order.count_notes">
                        <span v-if="order.count_notes < 10">{{order.count_notes}}</span>
                        <span v-else>9+</span>
                      </span>
                    </template>
                    <v-icon>
                      message
                    </v-icon>
                  </v-badge>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn icon ripple link :to="{ name: 'order', params: { id: order.id }}">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <div v-else style="width: 100%; padding: 30px 0; text-align: center;">
        <h3>Nenhum atendimento encontrado.</h3>
        <v-btn small outline :disabled="loading" @click="addOrder()">
          <v-icon>add</v-icon>
          <span>Nova ordem</span>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'WellOrders',
    data () {
      return {
        maxHeigth: (window.innerHeight - 378) + 'px',
        wiDialogs: {
          files: (businessName, businessId) => {
            return {
              title: 'Arquivos/Anexos',
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: businessName + '-fileList',
                  api: {
                    url: 'sales/sale/file',
                    params: '?order=id,desc&where=business_id,' + businessId
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Arquivo',
                action: () => {
                  this.$WiDialog({
                    title: 'Arquivos/Anexos ',
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        business_id: businessId
                      }
                    },
                    onClose: () => {
                      this.$WiView(businessName + '-fileList').refresh()
                      this.refresh()
                    }
                  })
                }
              }
            }
          },
          events: (businessName, businessId) => {
            return {
              title: 'Anotações/Eventos ',
              icon: 'message',
              component: {
                content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                props: {
                  index: businessName + '-event',
                  api: {
                    url: 'sales/sale/event',
                    params: '?order=id,desc&where=business_id,' + businessId
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Nota',
                action: () => {
                  this.$WiEditDialog({
                    wiConfig: businessName + '-event',
                    onSubmit: () => {
                      this.$WiView(businessName + '-event').refresh()
                      this.refresh()
                    },
                    data: {business_id: businessId}
                  })
                }
              }
            }
          }
        }
      }
    },
    computed: {
      businesses () {
        return this.content.businesses.filter(order => ( order.type === 11 ))
      }
    },
    methods: {
      addOrder: function () {
        this.$WiEditDialog({
          wiConfig: 'order',
          onSubmit: this.refresh,
          data: {
            person_id: this.content.person_id,
            person: this.content.person,
            well: this.content,
            business_id: this.content.id,
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01',
            date_end: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01',
          },
          redirectOnSubmit: false
        })
      }
    },
    props: ['content', 'refresh', 'loading']
  }
</script>
<style scoped>
  .medical-record-businesses .v-list__tile__action, .v-list__tile__avatar {
    min-width: 40px !important;
  }
</style>