<template>
  <v-card class="form-reply-list__not-reply" flat>
    <v-card-text class="form-reply-list__not-reply-content">
      <h3>{{form.name}}</h3>
      <h4>Insira os dados aqui</h4>
      <v-btn @click="createFormReply()" outline :loading="loading">
        <v-icon>add</v-icon>
        <span> Inserir dados</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
  import FormReplyMixin from './FormReplyMixin'
  export default {
    name: 'FormReplyList',
    mixins: [FormReplyMixin]
  }
</script>
<style scoped>
  .form-reply-list__not-reply {
    text-align: center !important;
    padding: 15px !important;
  }
  .form-reply-list__not-reply-content {
    padding: 20px !important;
    border-style: dashed;
    border-width: 1px;
    border-color: gray;
  }
</style>