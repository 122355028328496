import { BusinessPerson } from '@/modules/shared/config/Business/BusinessPerson'

class Person extends BusinessPerson {
  constructor () {
    super()
    this.id = '18baa289'
    this.name = 'order-persons'
    this.apiUrl = 'service-desk/order/person'
    this.title = {
      list: 'Colaboradores',
      edit: {
        insert: 'Adicionar Colaborador',
        update: 'Atualizar Colaborador'
      }
    }
    this.icon = 'people'
    // Fields
    // this.fields[1].text = 'Informe a data de recebimento desta parcela.'
    this.fields[11].options[1].text = this.fields[11].options[1].text + ' aos recebimentos da ordem.'
  }
}

export default new Person()
