<template>
  <div v-if="model.id != 1">
    <v-subheader class="mb-3 orange--text">
      <h2>Por favor, defina o status atual do equipamento.</h2>
      <v-spacer></v-spacer>
      <v-btn class="producer-model-subheader__button" outline color="orange" @click="configDialog = true">
        <v-icon>edit</v-icon>
        <span class="pl-2"> Definir Status</span>
      </v-btn>
      <ProducerModelConfigDialog
        v-model="configDialog"
        :model="model"
        :host="host"
        @onSaved="onSaved"
      ></ProducerModelConfigDialog>
    </v-subheader>
    <v-divider></v-divider>
  </div>
</template>
<script>
  import ProducerModelConfigDialog from './Dialog'
  export default {
    name: 'ProducerModelConfigSubHeader',
    data: () => ({
      configDialog: false
    }),
    methods: {
      onSaved: function (response) {
        this.$emit('onSaved', response)  
      }
    },
    props: {
      model: {
        required: true,
        default: () => ({})
      },
      host: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      ProducerModelConfigDialog
    }
  }
</script>
<style scoped>
  .producer-model-subheader__button {
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
    }
  }
</style>