<template>
  <v-list class="diagnostic-list-dialog" dense>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large>code</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> ID/Código: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data">
            {{ data.id }}
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-btn :disabled="!data.id" ripple small outline link :to="{name: 'diagnostic', params: { id: data.id }}">
          <v-icon style="padding: 0 5px;">open_in_new</v-icon>
          <span style="padding: 0 5px;"> Acessar Diagnóstico</span>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large>person</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> Cliente: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data && data.customer">
            {{ data.customer.name }}
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large>description</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> Contrato: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data && data.contract">
            {{ data.contract.address_full }}
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large>dns</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> Equipamento: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data && data.host">
            {{ data.host.description }}
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large>category</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> Categoria: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data && data.category">
            {{ data.category.name }}
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon large>description</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title> Descrição: </v-list-tile-sub-title>
        <v-list-tile-title>
          <strong v-if="data && data.observation">
            {{ data.observation }}
          </strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>
<script>
export default {
  name: 'DiagnosticCreateData',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>