import { Model } from '@/default/model/Model'

class Task extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'c4f8c247',
      create: '1924fe51',
      update: '324e9d87',
      delete: '22e2e801'
    }
    this.name = 'task'
    this.title = {
      list: 'Tarefas/Agendamentos',
      edit: {
        insert: 'Criar nova Tarefa/Agendamento',
        update: 'Editar Tarefa/Agendamento'
      }
    }
    this.urlDetails = {name: 'task', params: {}}
    this.apiUrl = 'tasks/task'
    this.icon = 'assignment_turned_in'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pela descrição ou pessoas envolvidas',
      fields: ['description', 'person.name', 'creator.name', 'responsible.name', 'person.first_name', 'creator.first_name', 'responsible.first_name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/projects/pages/Tasks/Tasks')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe os dados básicos '
      }),
      this.fieldText({
        value: 'description',
        text: 'Descrição',
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        required: true
      }),
      this.fieldSelectApi({
        value: 'type',
        text: 'Tipo ',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        api: {
          url: 'tasks/task/type'
        },
        show: 'title',
        selectValue: 'code',
        icon: 'icon',
        color: 'color',
        disabled: (form) => { return !!form.id }
      }),
      this.fieldDate({
        value: 'date',
        text: 'Data',
        required: false,
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldTime({
        value: 'time',
        text: 'Horário',
        required: false,
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      this.fieldWiListDialog({
        value: 'responsible_id',
        text: 'Responsável',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'responsible.first_name',
        icon: 'person_outline'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Cliente',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline'
      }),
      this.fieldSubHeader({
        text: 'Observações ou anotações '
      }),
      this.fieldEditor({
        value: 'observation',
        text: 'Observações',
        placeholder: 'Ex: Como deve ser executado(a).'
      }),
      this.fieldList({
        value: 'status',
        text: 'Concluir'
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição'
      }),
      this.fieldList({
        value: 'date',
        text: 'Data/Horário'
      }),
      this.fieldList({
        value: 'person_id',
        text: 'Cliente'
      }),
      this.fieldList({
        value: 'creator_id',
        text: 'Criador/Responsável'
      }),
      this.fieldList({
        value: 'type',
        text: 'Ação'
      })
    ])
  }
}
export default new Task()
