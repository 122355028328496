var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WiViewList',{attrs:{"Service":_vm.service,"service-params":_vm.serviceParams,"Socket":_vm.socket,"socket-params":_vm.socketParams,"toolbar":{
    title: _vm.title,
    icon: 'message'
  },"loadingConfig":{
    message: 'Buscando eventos/notas...'
  }},on:{"onClose":_vm.onClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var data = ref.data;
  var hasNextPage = ref.hasNextPage;
  var nextPage = ref.nextPage;
  var loadingPaginate = ref.loadingPaginate;
return [_c('div',{staticStyle:{"padding":"0 10px 0 10px"}},[_c('AddEvent',{attrs:{"business":_vm.business}}),(data && data.length > 0)?_c('div',[_c('v-timeline',{attrs:{"dense":_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'}},_vm._l((data),function(event,eventKey){return _c('WiEventsEvent',{key:eventKey,attrs:{"event":event,"business":_vm.business}})}),1),_c('div',{staticClass:"wi-events__paginate"},[_c('v-btn',{attrs:{"disabled":!hasNextPage,"loading":loadingPaginate},on:{"click":function($event){return nextPage()}}},[_vm._v(" Buscar mais ")])],1)],1):_c('div',{staticStyle:{"text-align":"center","padding":"20px"}},[_c('v-alert',{attrs:{"value":true,"type":"warning"}},[_c('span',[_vm._v("Nenhum evento/nota até o momento.")])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }