export default {
  id: 'cb59faf8',
  title: 'Tipo do Dispositivo',
  apiUrl: 'disp/conexao',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'dashes', text: 'Detalhe visual', type: 'select', size: ['xs12', 'sm6', 'md6', 'lg6'], list: false, edit: true, options: [{value: null, text: 'Contínuo (Padrão)'}, {value: '[2]', text: 'Pontilhado 1'}, {value: '[5]', text: 'Pontilhado 2'}, {value: '[10]', text: 'Pontilhado 3'}, {value: '[15]', text: 'Pontilhado 4'}, {value: '[20]', text: 'Pontilhado 5'}]},
    {value: 'desc_conexao', counter: 30, text: 'Descrição', size: ['xs12', 'sm6', 'md6', 'lg6'], type: 'text', required: false, list: true, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
