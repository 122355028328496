<template>
  <v-btn 
    icon flat color="red"
    @click="apagar()"
    :disabled="disabled"
  >
    <v-icon v-if="icon">{{ icon }}</v-icon>
    <v-icon v-else>delete</v-icon>
  </v-btn>
</template>

<script>
  import {Api} from '@/default/service/Api'
  export default {
    name: 'DBDelete',
    data () {
      return {}
    },
    computed: {
      config () {
        var store = this.$store.state.pages[this.store] || {}
        return store
      },
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      }
    },
    methods: {
      getConfigUri: function (type) {
        return this.config.apiUrl && this.config.apiUrl.edit ? (typeof this.config.apiUrl.edit === 'object' ? this.config.apiUrl.edit[type] : this.config.apiUrl.edit) : this.config.apiUrl
      },
      apagar: function () {
        this.$swal({
          title: 'Deseja realmente apagar?',
          text: 'Essa ação não poderá ser desfeita!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Apagar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.$swal({
              title: 'Apagando...',
              text: 'Aguarde até que a ação seja finalizada.',
              onOpen: () => { this.$swal.showLoading() }
            })
            var uri = this.getConfigUri('delete')
            Api.delete({url: this.url + uri + '/' + this.id})
            .then(() => {
              this.$swal({
                type: 'success',
                title: 'Tudo certo!',
                text: 'Concluído com sucesso.'
              })
              this.$emit('action', false)
              this.$emit('refresh', false)
            })
            .catch((error) => {
              this.$WiMakeError({error: error, id: 411, title: 'Erro ao apagar este item.'})
            })
          }
        })
      }
    },
    props: ['disabled', 'action', 'store', 'id', 'icon']
  }
</script>