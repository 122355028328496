import { Filters } from '@/default/model/Filters'

export class DeviceFilters extends Filters {
  constructor () {
    super()
    this.id = ''
    this.title = 'Filtrar Dispositivos'
    this.fields = {
      ip_address: this.fieldText ({
        value: 'ip_address',
        text: 'IP Address',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      active: this.fieldSelect ({
        value: 'active',
        text: 'Monitoramento Ativo',
        placeholder: 'Selecione uma opção',
        options: [
          { value: 'active', text: 'Somente Ativos' },
          { value: 'inactive', text: 'Somente Inativos' }
        ],
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      status_id: this.fieldSelect ({
        value: 'status_id',
        text: 'Status (Online/Offline)',
        placeholder: 'Selecione uma opção',
        options: [
          { value: 'online', text: 'Somente Online' },
          { value: 'offline', text: 'Somente Offline' }
        ],
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      type_id: this.fieldSelectApi ({
        value: 'type_id',
        text: 'Tipo de Dispositivo',
        show: 'name',
        api: {
          url: 'networks/device/type'
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      technology_id: this.fieldSelectApi ({
        value: 'producer_id',
        text: 'Fabricante',
        show: 'name',
        api: {
          url: 'networks/producer'
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      network_type_id: this.fieldSelectApi ({
        value: 'network_type_id',
        text: 'Tipo de Rede',
        show: 'nome_tipo',
        api: {
          url: 'grupo/tipo',
          params: {
            order: 'nome_tipo,asc'
          }
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      network_id: this.fieldSelectApi ({
        value: 'network_id',
        text: 'Rede',
        show: 'nome_grupo',
        api: {
          url: 'grupo/dados',
          params: filters => ({
            network_type_id: filters.network_type_id,
            order: 'nome_grupo,asc'
          })
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        showField: filters => !!filters.network_type_id
      })
    }
  }
}
