export default {
  id: 'cf5da7df',
  title: 'Transferir Chamado',
  apiUrl: 'service-desk/ticket',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Selecione a baixo o(a) novo responsável por este chamado.', type: 'subheader', edit: true},
    {value: 'person_responsible_id', text: 'Novo Responsável', autocomplete: true, required: false, type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/person/collaborator', apiParams: {fields: 'id,name'}, show: 'name'}
  ],
  fieldsFixed: [
    {
      value: 'transferir_chamado', data: true
    }
  ]
}
