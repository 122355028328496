<template>
  <v-list flat dense>
    <v-divider></v-divider>
    <template v-for="(module, moduleKey) in modules">
      <div :key="moduleKey">
        <ModuleItem :module="module"></ModuleItem>
      </div>
    </template>
  </v-list>
</template>
<script>
  import { mapState } from 'vuex'
  import ModuleItem from '@/default/pages/MainNew/components/DrawerLeft/ModuleItem'
  export default {
    name: 'LeftDrawerListModules',
    computed: {
      ...mapState('Pages/Main', ['selectedModule']),
      modules () {
        return this.$store.state.system.modules || []
      }
    },
    components: {
      ModuleItem
    }
  }
</script>
