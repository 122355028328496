export class Notifications {
  static format (notification) {
    if (Array.isArray(notification)) {
      return this.formatNotifications(notification)
    } else {
      return this.formatNotification(notification)
    }
  }
  static formatNotification (notification) {
    return {
      ...notification,
      ...this.getType(notification.data.type)
    }
  }
  static formatNotifications (notifications) {
    return notifications.map(notification => {
      return this.formatNotification(notification)
    })
  }
  static desktopNotification (notification) {
    notification = this.formatNotification(notification)
    new Notification(notification.data.creator.name, {
      body: notification.text(notification),
      icon: notification.data.creator.image || 'static/icons/user_icon_1.png'
    }).onclick = function () {
      let hrefUrl = ''
      if (notification?.href) {
        hrefUrl = notification.href(notification)
      } else {
        hrefUrl = window.location.href
      }
      window.location.href = hrefUrl + '?notification=' + notification.id
    }
  }
  static getType (type) {
    let types = {
      'test': {
         text: () => {
          return ` criou uma notificação de teste. Favor ignorá-la.`
        },
        route: () => {
          return {name: 'dashboard'}
        }
      },
      'ticket.created': {
         text: (notification) => {
          return ` criou o chamado #${notification.data.ticket_id} ao qual você está envolvido.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.created.responsible': {
         text: (notification) => {
          return ` criou o chamado #${notification.data.ticket_id} e colocou você como responsável.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.created.team': {
         text: (notification) => {
          return ` criou o chamado #${notification.data.ticket_id} para sua equipe.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.changed': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} em que você está envolvido.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.changed.creator': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} em que você é o solicitante.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.changed.responsible': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} ao qual você é o responsável.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.changed.old-responsible': {
         text: (notification) => {
          return ` transferiu o chamado #${notification.data.ticket_id} ao qual você era o responsável.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.changed.follower': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} que você está seguindo.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.changed.team': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} vinculado a sua equipe.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.responsible': {
         text: (notification) => {
          return ` colocou você como responsável em no chamado #${notification.data.ticket_id}.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.follower': {
         text: (notification) => {
          return ` colocou você como seguidor no chamado #${notification.data.ticket_id}.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'ticket.team': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} de sua equipe.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/service-desk/ticket/${notification.data.ticket_id}`
        }
      },
      'talk.message': {
         text: (notification) => {
          let text = ` Enviou uma nova mensagem no WhatsApp.`
          if (notification?.data?.message?.text) {
            text = ` ${notification?.data?.message?.text || 'Enviou uma mensagem no WhatsApp.'}`
          }
          return text
        },
        route: (notification) => {
          return {name: 'talk-view-messages', params: {id: notification.data.talk_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/talk/talk-view/messages/${notification.data.talk_id}`
        }
      },
      'talk.group.message': {
         text: (notification) => {
          let text = ` Nova mensagem no grupo.`
          if (notification?.data?.message?.text) {
            text = ` ${notification?.data?.message?.text || 'Nova mensagem no grupo.'}`
          }
          return text
        },
        route: (notification) => {
          return {name: 'talk-view-messages', params: {id: notification.data.talk_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/talk/talk-view/messages/${notification.data.talk_id}`
        }
      },
      'talk.changed.transfer': {
         text: () => {
          return ` Transferiu um atendimento para você.`
        },
        route: (notification) => {
          return {name: 'talk-view-messages', params: {id: notification.data.talk_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/talk/talk-view/messages/${notification.data.talk_id}`
        }
      },
      'chat.message': {
         text: (notification) => {
          let text = ` Enviou uma nova mensagem no WhatsApp.`
          if (notification?.data?.message?.text) {
            text = ` ${notification?.data?.message?.text || 'Enviou uma mensagem no WhatsApp.'}`
          }
          return text
        },
        route: (notification) => {
          return {name: 'chat-view-messages', params: {id: notification.data.chat_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/chat-view/messages/${notification.data.chat_id}`
        }
      },
      'chat.group.message': {
         text: (notification) => {
          let text = ` Nova mensagem no grupo.`
          if (notification?.data?.message?.text) {
            text = ` ${notification?.data?.message?.text || 'Nova mensagem no grupo.'}`
          }
          return text
        },
        route: (notification) => {
          return {name: 'chat-view-messages', params: {id: notification.data.chat_id}}
        },
        href: (notification) => {
          let pageBaseUrl = this.getCurrentUrl()
          return `${pageBaseUrl}/#/main/chat-view/messages/${notification.data.chat_id}`
        }
      }
    }
    return types[type]
  }
  static getCurrentUrl () {
    let pageBaseUrl = window.location.href
    if (pageBaseUrl.includes('?')) {
      pageBaseUrl = pageBaseUrl.split('?')[0]
    }
    if (pageBaseUrl.includes('#')) {
      pageBaseUrl = pageBaseUrl.split('#')[0]
    }
    pageBaseUrl = pageBaseUrl.replace(/\/$/, '')
    return pageBaseUrl
  }
  static notificationSound () {
    let sound = new Audio('/static/sounds/wnsystem-notification-1.mp3')
    sound.play()
  }
}