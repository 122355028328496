export default {
  id: {
    id: 'dc24170b',
    create: 'd40a2a3e',
    update: '734a4cf7',
    delete: '7073218a'
  },
  title: {
    list: 'Usuários',
    edit: {
      create: 'Novo Usuário',
      update: 'Editar Usuário'
    }
  },
  icon: 'portrait',
  apiUrl: 'system/user',
  search: {
    placeholder: 'Pesquise pelo ID ou nome do Colaborador',
    fields: ['person.id', 'person.name']
  },
  noData: {
    text: 'Este perfil ainda não tem nenhum colaborador/usuário.'
  },
  params: {
    limit: 5
  },
  maxWidth: '800px',
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  fieldsSelected: {
    itemName: 'usuário(s)'
  },
  fields: [
    {
      value: 'person_id',
      text: 'Colaborador*',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      type: 'wi-list-dialog',
      edit: {
        insert: true,
        update: false
      },
      wiConfig: 'collaborator',
      show: 'person.first_name',
      icon: 'person_outline',
      showField: (form) => {
        return !form.notShowCollaboratorField
      }
    },
    {value: 'person.first_name', text: 'Colaborador', list: true},
    {
      value: 'login',
      text: 'Login/Email',
      placeholder: 'Será usado para acessar o sistema. Não pode ser utilizado por nenhum outro funcionário.',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      type: 'email',
      required: true,
      rules: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Insira um e-mail válido...'
        },
        v => !!v || 'Campo obrigatório'
      ],
      edit: true,
      list: true
    },
    {value: 'person.teams', text: 'Equipes/Departamentos', list: true},
    {
      value: 'user_profile_id',
      text: 'Perfil de Usuário',
      required: true,
      type: 'db-autocomplete',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      edit: true,
      apiUrl: 'system/user/profile',
      apiParams: {limit: 100},
      show: 'name'
    },
    {
      value: 'password',
      text: 'Senha',
      type: 'password',
      placeholder: 'No mínimo 8 caracteres, incluíndo letras e números. Será usada para acessar o sistema.',
      size: (form) => {
        return !form.notShowCollaboratorField ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md12', 'lg12']
      },
      required: true,
      edit: {insert: true, update: false},
      rules: [
        value => value?.length >= 8 || 'Mínimo de 8 caracteres.',
        value => value?.length <= 20 || 'Máximo de 20 caracteres.',
        v => !!v || 'Campo obrigatório'
      ]
    }
  ]
}
