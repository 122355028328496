<template>
  <p style="padding: 0 20px;">
    <strong style="font-size: 16px;">
      <v-icon v-if="field.icon" small :color="field.color" style="padding-bottom: 2px; padding-right: 2px;">
        {{field.icon}}
      </v-icon>
      <span>{{field.label}}: </span>
    </strong>
    <br>
    <br>
    <span v-if="field.reply">
      <div v-html="field.reply.content"></div>
    </span>
    <span v-else>Não informado</span>
  </p>
</template>
<script>
  export default {
    name: 'FormReplyListReplyEditor',
    props: {
      field: {
        type: Object,
        required: true
      }
    }
  }
</script>
