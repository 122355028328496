<template>
  <div>
    <view-detail title="Perfil de acesso" apiUrl="system/user/profile" store="user-profile" :notLoad="true">
      <template slot-scope="dados">
        <br>
        <v-layout row wrap>
          <v-flex xs12 sm12 md10 lg10 offset-md1 offset-lg1 style="padding: 0 10px;">
            <v-card style="margin-bottom: 20px;">
              <v-card-text style="text-align: center;">
                <v-avatar size="100" tile style="border-color: gray; border-width: 1px; border-style: solid;">
                  <img src="static/icons/monitor/management_96.png" alt="Equipe Responsável">
                </v-avatar>
                <h1>{{dados.content.name}}</h1>
                <h3>Usuários: {{dados.content.count_users}}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md10 lg10 offset-md1 offset-lg1 style="padding: 20px 10px;">
            <v-card v-prms="{'id': 'bec249c8-3ddc-4113-8dd5-e558fd30baa8'}" style="margin-top: 20px;">
              <v-expansion-panel v-model="panelRules">
                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>
                      <v-icon>lock_open</v-icon>
                      <strong style="font-size: 18px;">Regras/Permissões <span></span></strong>
                    </div>
                  </template>
                  <v-card>
                    <user-profile-modules :modules="dados.content.module_rules" :refresh="dados.refresh"></user-profile-modules>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
            <v-card v-prms="{'id': 'bec249c8-3ddc-4113-8dd5-e558fd30baa8'}" style="margin-top: 20px;">
              <v-expansion-panel v-model="panelUsers">
                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>
                      <v-icon>assignment_ind</v-icon>
                      <strong style="font-size: 18px;">Colaboradores/Usuários <span></span></strong>
                    </div>
                  </template>
                  <v-card>
                    <wi-list-component v-if="panelUsers != null" store="user" :params="{where: 'user_profile_id,' + dados.content.id}" :new-data="{user_profile_id: dados.content.id, set_person: true}"></wi-list-component>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </v-flex>
          
          <!-- <v-flex v-if="dados.content.id > 1" xs12 sm12 md8 lg8 offset-md2 offset-lg2>
            <div>
              <v-layout row wrap>
                <v-flex v-for="(mod, KeyMod) in system.modules" xs12 sm4 md4 lg4 style="padding: 10px 10px;" :key="KeyMod">
                  <v-card>
                    <div style="width: 100%; text-align: center; padding: 10px;">
                      <img :src="'static/systems/modules/' + mod.icon" alt="Connect Controll" style="width: 50px;">
                      <h4>
                        <span v-html="mod.name"></span>
                      </h4>
                      <v-switch :change="changeModule(dados.content)" v-model="dados.content.modules[mod.id].active" class="pl-3 mt-0" label="Permitido" color="primary"></v-switch>
                    </div>
                    <v-divider></v-divider>
                    <v-list v-if="dados.content.modules[mod.id].active" dense>
                      <v-list-tile>
                        <v-list-tile-content>Leitura:</v-list-tile-content>
                        <v-list-tile-content class="align-end">
                          <v-switch disabled :change="dados.content.modules[mod.id]['read'] = true" v-model="dados.content.modules[mod.id]['read']" class="pl-3 mt-0" label="Permitido" :value="true" color="primary"></v-switch>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>Gravação:</v-list-tile-content>
                        <v-list-tile-content class="align-end">
                         <v-switch :disabled="dados.content.type == 'read'" :change="changeModule(dados.content)" v-model="dados.content.modules[mod.id]['write']" class="pl-3 mt-0" label="Permitido" color="primary"></v-switch>
                       </v-list-tile-content>
                     </v-list-tile>
                   </v-list>
                 </v-card>
               </v-flex>
               <v-flex xs12 sm12 md12 lg12>
                <br>
                <v-btn color="primary" @click="openEditModal('user-profile', dados.content)" style="width: 100%;">Salvar</v-btn>
              </v-flex>
            </v-layout>
            </div>
          </v-flex> -->
      </v-layout>
    </template>
  </view-detail>
</div>
</template>

<script>
  import UserProfileModules from '@/default/pages/User/UserProfileModules'
  export default {
    name: 'UserProfile',
    data () {
      return {
        panelRules: null,
        panelUsers: null
      }
    },
    methods: {
      changeModule: function () {}
    },
    computed: {
      system () {
        return this.$store.state.system || []
      },
      user () {
        return this.$store.state.user || {}
      }
    },
    components: {
      UserProfileModules
    }
  }
</script>