<template>
  <v-card>
    <v-subheader>
      {{ networkType.nome_tipo }} - Detalhes
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template slot="activator">
          <v-btn @click="editNetworkType()" icon ripple>
            <v-icon color="orange lighten-1">edit</v-icon>
          </v-btn>
        </template>
        <span>Editar este tipo de grupo</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template slot="activator">
          <db-delete @refresh="refresh" store="grupo-tipos" :id="networkType.id"></db-delete>
        </template>
        <span>Excluir este tipo de grupo</span>
      </v-tooltip>
    </v-subheader>
    <v-divider></v-divider>
    <v-card-text style="padding: 0;">
      <v-avatar>
        <v-icon color="blue darken-2" x-large>
          {{ networkType.icone_tipo }}
        </v-icon> 
      </v-avatar>
      <h3 class="headline mb-2">
        <strong>{{ networkType.nome_tipo }}</strong>
      </h3>
      <div class="gray--text subheading font-weight-bold">{{ networkType.obs_tipo }}</div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text v-if="networkType">
      <h1>{{ networkType.networks_count }}</h1>
      <h2 class="grey--text">Redes</h2>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text v-if="networkType && networkType.networks_count > 0">
      <v-layout row wrap>
        <v-flex xs12>
          <v-tooltip bottom>
            <template slot="activator">
              <v-btn color="blue lighten-1" outline @click="listNetworks()" ripple>
                <v-icon color="blue lighten-1">list</v-icon>
                Listar redes do tipo {{ networkType.nome_tipo }}
              </v-btn>
            </template>
            <span>Listar redes do tipo {{ networkType.nome_tipo }}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12>
          <v-tooltip bottom>
            <template slot="activator">
              <v-btn color="green lighten-1" outline @click="createNetwork()" ripple>
                <v-icon color="green lighten-1">add</v-icon>
                Criar rede do tipo {{ networkType.nome_tipo }}
              </v-btn>
            </template>
            <span>Criar rede do tipo {{ networkType.nome_tipo }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-card-text>
    <h4 v-else style="padding-top: 20px;">
      {{networkType.nome_tipo}} ainda não tem nenhuma rede.<br>
      <v-tooltip bottom>
        <template slot="activator">
          <v-btn color="green lighten-1" outline @click="createNetwork()" ripple>
            <v-icon color="green lighten-1">add</v-icon>
            Criar rede do tipo {{ networkType.nome_tipo }}
          </v-btn>
        </template>
        <span>Criar rede do tipo {{ networkType.nome_tipo }}</span>
      </v-tooltip>
    </h4>
  </v-card>
</template>
<script>
  export default {
    name: 'NetworkTypeSelected',
    data: () => ({
      editModal: false,
      configModal: null,
      modalForm: {},
      modalStore: 'grupos-sub',
      config: {
        title: 'Novo Grupo',
        apiUrl: 'grupo/dados',
        fields: [
          {value: 'nome_grupo', counter: 100, text: 'Nome da estação', size: ['xs12', 'sm3', 'md5', 'lg5'], type: 'text', required: true, list: true, edit: true},
          {value: 'latitude_grupo', counter: 15, text: 'Latitude da estação', size: ['xs12', 'sm3', 'md2', 'lg2'], type: 'text', required: false, list: false, edit: true},
          {value: 'longitude_grupo', counter: 15, text: 'Longitude da estação', size: ['xs12', 'sm3', 'md2', 'lg2'], type: 'text', required: false, list: false, edit: true},
          {value: 'grupo_dados_id', text: 'Grupo pai', size: ['xs12', 'sm3', 'md3', 'lg3'], required: true, type: 'db-autocomplete', list: false, edit: true, apiUrl: 'grupo/dados', apiParams: {}, show: 'nome_grupo'},
          {text: 'O campo de Observações pode ser usado para descrever este grupo.', type: 'subheader', edit: true},
          {value: 'obs_grupo', text: 'Observações', type: 'textarea', list: false, edit: true}
        ],
        loading_edit: false,
        loading_list: false,
        loading_view: false
      }
    }),
    methods: {
      refresh: function () {
        this.$emit('refresh')
        this.$emit('clear')
      },
      listNetworks: function () {
        this.$WiListDialog({
          wiConfig: 'networks',
          filter: {
            where: 'grupo_tipo_id,' + this.networkType.id
          },
          wiEditData: {
            grupo_tipo_id: this.networkType.id
          }
        })
      },
      createNetwork: function () {
        this.$WiEditDialog({
          wiConfig: 'networks',
          data: {
            grupo_tipo_id: this.networkType.id
          },
          onSubmit: this.refresh
        })
      },
      editNetworkType: function () {
        this.$WiEditDialog({
          wiConfig: 'network-type',
          data: this.networkType,
          onSubmit: this.refresh
        })
      }
    },
    props: {
      networkType: {
        type: Object,
        required: true
      }
    }
  }
</script>