import Vue from 'vue'
import {Interceptors} from '@/default/service/Interceptors'
import url from '@/default/store/modules/url/url'

export class callApi {
  static get ({uri, params, sucess, error, msgLoad, msgSucess, msgError}) {
    if (params) {
      var p = params
      var count = 0
      params = '?'
      for (var key in p) {
        if (p[key]) {
          if (count) {
            params += '&'
          }
          params += key + '=' + p[key]
          count++
        }
      }
    }
    if (msgLoad) {
      Vue.swal({
        title: msgLoad.title || 'Carregando...',
        text: msgLoad.text || 'Aguarde até que a ação seja finalizada.',
        onOpen: () => { Vue.swal.showLoading() }
      })
    }
    let startTime = new Date()
    Api.get({url: url.state.base + url.state.version + url.state.group + uri + params})
    .then((data) => {
      this.analytics(startTime, 'GET', uri, params)
      if (sucess) {
        sucess(data)
      }
      if (msgSucess) {
        Vue.swal({
          type: 'success',
          title: msgSucess.title || 'Tudo certo!',
          text: msgSucess.text || 'Concluído com sucesso.'
        })
      } else if (msgLoad) {
        Vue.swal.close()
      }
    })
    .catch((err) => {
      this.analytics(startTime, 'GET', uri, params)
      if (error) {
        error(err)
      }
      if (msgError) {
        Vue.swal({
          type: 'error',
          title: msgError.title || 'Oops... Algo deu errado!',
          text: msgError.text || 'Tente novamente ou contacte o suporte.'
        })
      } else if (msgLoad) {
        Vue.swal.close()
      }
    })
  }
  static post ({uri, data, headers, sucess, error, msgLoad, msgSucess, msgError, msgAsk, msgNotFound}) {
    var action = () => {
      this.loadMessage(msgLoad)
      let startTime = new Date()
      Api.post({url: url.state.base + url.state.version + url.state.group + uri}, data, headers)
      .then((data) => {
        this.analytics(startTime, 'POST', uri, '')
        if (sucess) {
          sucess(data)
        }
        if (msgSucess) {
          Vue.swal({
            type: 'success',
            title: msgSucess.title || 'Tudo certo!',
            text: msgSucess.text || 'Concluído com sucesso.'
          })
        } else if (msgLoad) {
          Vue.swal.close()
        }
      })
      .catch((err) => {
        this.analytics(startTime, 'POST', uri, '')
        console.log(err)
        if (error) {
          error(err)
        }
        if (err?.response?.status === 404 && msgNotFound) {
          Vue.swal({
            type: 'warning',
            title: msgNotFound.title || 'Item não encontrado!',
            text: msgNotFound.text || 'Você pode tentar novamente.'
          })
        } else if (msgError) {
          Vue.swal({
            type: 'error',
            title: msgError.title || 'Oops... Algo deu errado!',
            text: msgError.text || 'Tente novamente ou contacte o suporte.'
          })
        } else if (msgLoad) {
          Vue.swal.close()
        }
      })
    }
    if (msgAsk) {
      this.askMessage(msgAsk || {}, action)
    } else {
      action()
    }
  }
  static put ({uri, data, id, sucess, error, msgLoad, msgSucess, msgError, msgAsk}) {
    var action = () => {
      this.loadMessage(msgLoad)
      let uriId = id ? '/' + id : ''
      let startTime = new Date()
      return Api.put({url: url.state.base + url.state.version + url.state.group + uri + uriId}, data)
      .then((data) => {
        this.analytics(startTime, 'PUT', uri, '')
        if (sucess) {
          sucess(data)
        }
        if (msgSucess) {
          Vue.swal({
            type: 'success',
            title: msgSucess.title || 'Tudo certo!',
            text: msgSucess.text || 'Concluído com sucesso.'
          })
        } else if (msgLoad) {
          Vue.swal.close()
        }
      })
      .catch((err) => {
        this.analytics(startTime, 'PUT', uri, '')
        console.log(err)
        if (error) {
          error(err)
        }
        if (msgError) {
          Vue.swal({
            type: 'error',
            title: msgError.title || 'Oops... Algo deu errado!',
            text: msgError.text || 'Tente novamente ou contacte o suporte.'
          })
        } else if (msgLoad) {
          Vue.swal.close()
        }
      })
    }
    if (msgAsk) {
      this.askMessage(msgAsk || {}, action)
    } else {
      return action()
    }
  }
  static delete ({uri, id, data, sucess, error, msgLoad, msgSucess, msgError, msgAsk}) {
    var action = () => {
      this.loadMessage(msgLoad)
      let startTime = new Date()
      Api.delete({ url: url.state.base + url.state.version + url.state.group + uri + '/' + id }, data)
      .then((response) => {
        this.analytics(startTime, 'DELETE', uri, '')
        if (sucess) {
          sucess(response)
        }
        this.successMessage(msgSucess)
      })
      .catch((err) => {
        this.analytics(startTime, 'DELETE', uri, '')
        console.log(err)
        if (error) {
          error(err)
        }
        this.errorMessage(msgError)
      })
    }
    if (msgAsk === false) {
      action()
    } else {
      this.askMessage(
        msgAsk || {
          title: 'Deseja realmente apagar?',
          text: 'Essa ação não poderá ser desfeita!'
        },
        action
      )
    }
  }
  static loadMessage (loadConfig) {
    if (loadConfig) {
      Vue.swal({
        title: loadConfig.title || 'Carregando...',
        text: loadConfig.text || 'Aguarde até que a ação seja finalizada.',
        onOpen: () => { Vue.swal.showLoading() }
      })
    }
  }
  static successMessage (successConfig) {
    if (successConfig) {
      Vue.swal({
        type: 'success',
        title: successConfig.title || 'Tudo certo!',
        text: successConfig.text || 'Concluído com sucesso.'
      })
    }
  }
  static errorMessage (errorConfig) {
    if (errorConfig) {
      Vue.swal({
        type: 'error',
        title: errorConfig.title || 'Oops... Algo deu errado!',
        text: errorConfig.text || 'Tente novamente ou contacte o suporte.'
      })
    }
  }
  static askMessage (askConfig, callback) {
    Vue.swal({
      title: askConfig.title || 'Deseja realmente fazer isso?',
      text: askConfig.text || 'Esta ação pode ser irreversível!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim! Sei o que estou fazendo',
      cancelButtonText: 'Cancelar'
    })
    .then((response) => {
      if (response.value) {
        if (callback) {
          callback()
        }
      }
    })
  }
  static analytics (startTime, method, uri, params) {
    let endTime = new Date()
    let time = (endTime - startTime) - 115
    const formatedUri = this.formatUri(uri)
    Vue.$ga.time(method, formatedUri, time, params)
    Vue.$ga.page('api/v1/' + formatedUri)
  }
  static formatUri(uri) {
    const uriPaths = uri?.split('/')
    const lastPath = uriPaths[uriPaths?.length - 1]
    if (!isNaN(lastPath)) {
      return uri.replace(`/${lastPath}`, '/{id}')
    }
    return uri
  }
}

export class Api {
  static get (config, message) {
    if (message) {
      Vue.swal({
        title: 'Carregando...',
        text: 'Aguarde até que a ação seja finalizada.',
        onOpen: () => { Vue.swal.showLoading() }
      })
      return Interceptors.Axios(config).get()
    } else {
      return Interceptors.Axios(config).get()
    }
  }
  static post (config, data, message) {
    if (message) {
      // Vue.swal({
      //   title: 'Carregando...',
      //   text: 'Aguarde até que a ação seja finalizada.',
      //   onOpen: () => { Vue.swal.showLoading() }
      // })
      return Interceptors.Axios(config).post(config.url, data)
    } else {
      return Interceptors.Axios(config).post(config.url, data)
    }
  }
  static put (config, data, message) {
    if (message) {
      Vue.swal({
        title: 'Carregando...',
        text: 'Aguarde até que a ação seja finalizada.',
        onOpen: () => { Vue.swal.showLoading() }
      })
      return Interceptors.Axios(config).put(config.url, data)
    } else {
      return Interceptors.Axios(config).put(config.url, data)
    }
  }
  static delete (config, data, message) {
    if (message) {
      Vue.swal({
        title: 'Deseja realmente apagar?',
        text: 'Essa ação não poderá ser desfeita!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Apagar!',
        cancelButtonText: 'Cancelar'
      })
      .then((response) => {
        if (response.value) {
          Vue.swal({
            title: 'Carregando...',
            text: 'Aguarde até que a ação seja finalizada.',
            onOpen: () => { Vue.swal.showLoading() }
          })
          return Interceptors.Axios(config).delete(config.url, {data: data})
        }
      })
    } else {
      return Interceptors.Axios(config).delete(config.url, {data: data})
    }
  }
  static auth (login, action) {
    return Interceptors.Axios({url: url.state.base + url.state.version + url.state.group + 'auth/login'})
    .post(url.state.base + url.state.version + url.state.group + 'auth/login', { login: login.login_usuario, password: login.password })
    .then((resp) => {
      if (resp.status === 202) {
        if (resp.data && resp.data.token) {
          localStorage.setItem('f777bbf0-ef5d-45db-b77a-dd7802f92824', resp.data.token)
        }
        if (action) {
          action(resp.data)
        }
      }
    })
  }
  static logOut () {
    return true
  }
}
