<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="92%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card v-if="dialog" class="host-detail">
      <Toolbar
        :child="child"
        :device="device"
        :hostsCount="hosts.length"
        @close="close"
        @refresh="refresh"
        @touggleView="touggleView"
        @checkSignal="checkSignal"
        @searchContracts="searchContracts"
      ></Toolbar>
      <v-card-text class="pb-5">
        <WiView
          index="host-detail-dialog"
          title="Detalhes do equipamento"
          apiUrl="network/host"
          :apiParams="`?device_id=${device.id}${childParams}&limit=1000&with=contracts`"
          :notGetID="true"
          :notShowToolbar="true"
          v-on:afterLoad="setHosts"
        >
          <template>
            <v-card class="elevation-20">
              <Table
                v-if="view == 'list'"
                :hosts="hosts"
                @refresh="refresh"
              ></Table>
              <Map
                v-if="view == 'map'"
                :hosts="hosts"
                @refresh="refresh"
              ></Map>
            </v-card>
          </template>
        </WiView>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import { Auth } from '@/default/service/Auth'
  import Toolbar from './Toolbar'
  import Table from './Table'
  import Map from './Map'
  export default {
    name: 'DeviceHostsDialog',
    data: () => ({
      view: 'list',
      hosts: [],
      refresh: () => {},
      hostsContractUpdateId: null,
      hostsCheckUpdateId: null
    }),
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      childParams () {
        if (this.child?.type?.code && this.child?.id) {
          return `&device_child_${this.child.type.code}_active_id=${this.child.id}`
        }
        return ''
      }
    },
    methods: {
      setHosts: function ({ content, refresh }) {
        this.hosts = content?.data || []
        this.refresh = refresh || []
      },
      close: function () {
        this.view = 'list'
        this.dialog = false
      },
      touggleView: function (view) {
        this.refresh()
        this.view = view
      },
      searchContracts: function () {
        callApi.post({
          uri: 'network/hosts-contracts-update',
          data: {
            device_id: this.device.id,
            device_child_id: this.child.id
          },
          msgAsk: {
            title: 'Deseja realmente atualizar as informações de contrato?',
            text: 'O sistema irá buscar, criar ou atualizar os contratos destes equipamentos.'
          },
          msgSucess: {
            title: 'Atualização solicitada com sucesso!',
            text: 'Os dados serão atualizados na tela em instantes.'
          },
          sucess: response => {
            if (response?.data?.id) {
              this.hostsContractUpdateId = response.data.id
              this.hostsContractsUpdateSubscriber()
            }
          }
        })
      },
      checkSignal: function () {
        callApi.post({
          uri: 'network/hosts-check-update',
          data: {
            device_id: this.device.id,
            device_child_id: this.child.id
          },
          msgAsk: {
            title: 'Deseja realmente buscar o sinal?',
            text: 'O sistema irá buscar e atualizar os dados destes equipamentos.'
          },
          msgSucess: {
            title: 'Atualização solicitada com sucesso!',
            text: 'Os dados serão atualizados na tela em instantes.'
          },
          sucess: response => {
            if (response?.data?.id) {
              this.hostsCheckUpdateId = response.data.id
              this.hostsCheckUpdateSubscriber()
            }
          }
        })
      },
      updateHost: function (hostData) {
        const hostIndex = this.hosts.findIndex(host => host.id == hostData.id)
        if (hostIndex > -1) {
          this.hosts.splice(hostIndex, 1, hostData)
        } else {
          this.hosts.push(hostData)
        }
        this.$forceUpdate()
      },
      hostsContractsUpdateSubscriber: function () {
        window.Echo.private(`${Auth.account().id}.hosts-contract-update.${this.hostsContractUpdateId}`)
        .listen('.update', (host) => {
          this.updateHost({ ...host, contractUpdated: true })
        })
      },
      hostsCheckUpdateSubscriber: function () {
        window.Echo.private(`${Auth.account().id}.hosts-check-update.${this.hostsCheckUpdateId}`)
        .listen('.update', () => {
          this.refresh()
        })
      },
      hostsUpdateUnsubscriber: function () {
        window.Echo.leave(`${Auth.account().id}.hosts-contract-update.${this.hostsContractUpdateId}`)
        window.Echo.leave(`${Auth.account().id}.hosts-check-update.${this.hostsUpdateId}`)
      }
    },
    beforeDestroy: function () {
      this.hostsUpdateUnsubscriber()
    },
    props: {
      value: {
        required: true
      },
      device: {
        required: true
      },
      child: {
        default: () => ({})
      }
    },
    components: {
      Toolbar,
      Table,
      Map
    }
  }
</script>
