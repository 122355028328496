import { MovementPerson } from './Movement'
import {
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_CREATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_DELETE
} from '@/default/constants/permissions'

export class MovementRemovalCollaborator extends MovementPerson {
  constructor () {
    super()

    this.urlDetails.name = 'product-removal-collaborator'

    // Change Fields
    this.editPerson.text = 'Colaborador'
    this.editPerson.wiConfig = 'collaborator'
    this.editStatus.apiUrl = 'stocks/config/status/product-removal-collaborator'
    this.editCategory.apiUrl = 'stocks/config/category/product-removal-collaborator'
    this.listPerson.text = 'Colaborador'
  }

  name = 'product-removal-collaborator';
  id = {
    id: PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR,
    create: PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_CREATE,
    update: PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE,
    delete: PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_DELETE
  };
  title = {
    list: 'Retirada do Colaborador',
    edit: {
      insert: 'Nova Retirada do Colaborador',
      update: 'Editar Retirada do Colaborador'
    }
  };
  apiUrl = 'stocks/product-removal/collaborator';
  icon = 'group_add';

}
