<template>
  <view-detail :title="title" :apiUrl="apiUrl" :apiParams="apiParams" :notGetID="true" :notShowToolbar="false">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn v-if="filters != {}" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="$router.push({query: {}})" :disabled="dados.loading">
        <v-icon>close</v-icon>
        <span>Limpar Filtro</span>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px 20px 0px 10px;">
          <v-layout row wrap>
            <v-flex v-if="config.dates.field" xs12 sm12 md3 lg3 style="padding: 0px 5px;">
              <v-select v-model="filters.date_field" :items="config.dates.field.items" :label="config.dates.field.text || 'Selecione o campo'" block solo></v-select>
            </v-flex>
            <v-flex v-if="config.dates && config.dates.start" xs12 sm12 md3 lg3 style="padding: 0px 5px;">
              <v-text-field block solo v-model="filters.date_start" :type="config.dates.start.type || 'date'" :label="config.dates.start.text || 'Data Inicial'"></v-text-field>
            </v-flex>
            <v-flex v-if="config.dates && config.dates.end" xs12 sm12 md3 lg3 style="padding: 0px 5px;">
              <v-text-field block solo v-model="filters.date_end" :min="filters.date_start" :type="config.dates.start.type || 'date'" :label="config.dates.end.text || 'Data Final'"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md1 lg1 style="text-align: center; padding: 0px 5px;">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                <template v-slot:activator="{ on }">
                  <v-btn icon large v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <download-excel
                      v-if="dados.content && dados.content.data && dados.content.data.length > 0"
                      class="btn btn-default"
                      :data="makeXLSData(dados.content.data)"
                      :fields="makeXLSFields()"
                      :worksheet="title"
                      :name="'Relatório-' + $DateTime.date() + '.xls'"
                    >
                      <v-list-tile style="cursor: pointer;">
                        <v-list-tile-avatar>
                          <img src="/static/icons/excel_icon.png" alt="Exportar para Excel">
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>Exportar/Excel</v-list-tile-title>
                          <v-list-tile-sub-title>Criar planilha com o conteúdo</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </download-excel>
                    <v-divider v-if="config.externalExportToPDF"></v-divider>

                      <v-list-tile
                        v-if="
                          dados.content && dados.content.data && dados.content.data.length > 0 &&
                          config.externalExportToPDF && config.externalExportToPDF
                        "
                        style="cursor: pointer;"
                        @click="onExportToPDF(config.filters)"
                      >
                        <v-list-tile-avatar>
                          <img src="/static/icons/pdf_icon.png" alt="Exportar para PDF">
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>Exportar/PDF</v-list-tile-title>
                          <v-list-tile-sub-title>Criar documento com o conteúdo</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                    <v-divider v-if="config.filters"></v-divider>
                    <v-list-tile v-if="config.filters" @click="$WiHelpDialog({title: 'Função disponível em breve. Enquanto isso, informe abaixo alguma sugestão, dúvida ou reclamação.'})">
                      <v-list-tile-avatar>
                        <v-icon x-large>filter_list</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>Mais Filtros</v-list-tile-title>
                        <v-list-tile-sub-title>Mais opções para refinar o resultado</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm12 md2 lg2 style="padding: 0px 5px;">
              <v-btn :disabled="!filters.date_field || !filters.date_start || !filters.date_end" @click="setFilter()" color="primary" block>
                <v-icon>search</v-icon> 
                <span style="padding-left: 5px;">Buscar</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="dados.content && dados.content.information && dados.content.information.filters"
          xs12 sm12 md12 lg12 class="mb-3"
          style="text-align: center; padding: 0px 10px 0px 10px;"
        >
          <span>
            Relatório emitido em <strong>{{dados.content.information.report_generate_date}}</strong>
          </span><br>
          <span v-for="(filter, filterKey) in dados.content.information.filters" :key="filterKey">
            Filtrando por {{filter.name}}: <strong>{{filter.description}}</strong><br>
          </span>
        </v-flex>
        <v-flex v-if="config.statistics" xs12 sm12 md12 lg12 style="padding: 0 15px 20px 15px;">
          <slot name="statistics" :statistics="dados.content.statistics" :refresh="dados.refresh"></slot>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 style="padding: 0 20px 50px 20px;">
          <slot name="before-content" :content="dados.content" :refresh="dados.refresh"></slot>
          <v-card class="elevation-10" style="border-radius: 10px;">
            <v-data-table
              disable-initial-sort
              :rows-per-page-items="[25, 5, 10, 50, 100, 200, {text: 'Todos', value: -1}]"
              no-data-text="Nenhum resultado encontrado para este filtro."
              no-results-text="Nenhum resultado encontrado para este filtro."
              rows-per-page-text="Itens por página"
              :headers="config.fields"
              :items="dados.content.data"
              :expand="expand"
              :class="{'elevation-10': !expand}"
              style="border-radius: 10px;"
            >
              <template v-slot:items="props">
                <slot :item="props.item" :props="props" :refresh="dados.refresh"></slot>
              </template>
              <template v-slot:expand="props">
                <slot name="expand" :item="props.item" :props="props" :refresh="dados.refresh"></slot>
              </template>
              <template slot="pageText" slot-scope="props">
                Exibindo {{ props.pageStart }} até {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-card>
          <slot name="after-content" :content="dados.content" :refresh="dados.refresh"></slot>
        </v-flex>
      </v-layout>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  export default {
    name: 'WiReport',
    data () {
      return {
        menu: false,
        filters: {},
        show: false,
        expand: false,
        apiUrl: '',
        apiParams: ''
      }
    },
    methods: {
      setFilter: function () {
        this.$router.push({query: this.filters})
      },
      changeRoute: function () {
        this.show = false
        this.setFilters()
        this.show = true
      },
      setFilters: function () {
        this.filters = {}
        this.makeFilterByProps()
        this.makeFilterByRouter()
        this.setFilterInApiParams()
        this.$forceUpdate()
      },
      makeFilterByProps: function () {
        if (this.api && this.api.params) {
          Object.assign(this.filters, this.api.params)
        }
      },
      makeFilterByRouter: function () {
        if (this.$route && this.$route.query) {
          Object.assign(this.filters, this.$route.query)
        }
      },
      setFilterInApiParams: function () {
        this.apiParams = ''
        var isNotFirstParams = false
        for (var key in this.filters) {
          if (isNotFirstParams) {
            this.apiParams += '&'
          } else {
            this.apiParams = '?'
          }
          if (this.filters[key] && this.filters[key] !== '') {
            this.apiParams += key + '=' + this.filters[key]
          }
          isNotFirstParams = true
        }
      },
      makeXLSFields: function () {
        var fields = {}
        this.config.fields.filter((item) => {
          if (item.text && item.value) {
            fields[item.text] = item.value
          }
        })
        return fields
      },
      makeXLSData: function (data) {
        return data.map(item => {
          if (this.xlsExportFormat) {
            return this.xlsExportFormat(item)
          }
          return item
        })
      },
      onExportToPDF: function () {
        this.$emit('onExportToPDF', this.filters)
      }
    },
    created: function () {
      this.apiUrl = this.api.url
      this.changeRoute(this.$route)
    },
    props: {
      title: {
        type: String,
        required: true
      },
      config: {
        required: true
      },
      api: {
        required: true
      },
      xlsExportFormat: {
        type: Function
      }
    },
    watch: {
      '$route': 'changeRoute'
    },
    components: {
      ViewDetail
    }
  }
</script>
<style>
th {
  padding: 0 8px !important;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
.selected_search_action .v-toolbar__content {
  height: 56px !important;
}
.v-datatable .v-input--selection-controls {
  max-width: 0;
}
</style>