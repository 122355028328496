<template>
  <div>
    <v-list v-if="notifications.length > 0" three-line>
      <template v-for="(notification, notificationKey) in notifications">
        <Notification :key="notificationKey" :notification="notification"></Notification>
        <v-divider :key="'nd-' + notificationKey"></v-divider>
      </template>
    </v-list>
    <div v-else>
      <div class="notifications__no-data">
        <v-icon x-large>notifications</v-icon>
        <h2 v-t="'components.drawerRight.messages.noMessages'"></h2>
      </div>
    </div>
    <div class="notifications__more">
      <v-btn block :disabled="current_page >= last_page" :loading="loading" @click="paginateNotifications()">
        <v-icon>history</v-icon>
        <span v-t="'components.drawerRight.loadMore.label'"></span>
      </v-btn>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import Notification from '@/default/pages/MainNew/components/DrawerRight/Notification'
  export default {
    name: 'Notifications',
    computed: {
      ...mapState('Pages/Main/Notifications', ['notifications', 'current_page', 'last_page', 'loading']),
    },
    methods: {
      ...mapMutations('Pages/Main/Notifications', ['ADD_NOTIFICATION']),
      ...mapActions('Pages/Main/Notifications', ['getNotifications', 'paginateNotifications']),
      goRoute: function (route) {
        if (route) {
          this.drawer = false
          this.$router.push(route)
        }
      }
    },
    mounted: function () {
      this.getNotifications()
    },
    components: {
      Notification
    }
  }
</script>
<style scope>
  .notifications__no-data {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
  .notifications__more {
    width: 100%;
    text-align: center;
    padding: 0 10px;
  }
</style>