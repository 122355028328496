<template>
  <WiView title="Detalhes do processo" apiUrl="processes/process" apiParams="?with=stages;stages.results;stages.results.result_stage:id,type_id,order" :notLoad="true">
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <v-btn outline small color="white" :loading="loading" @click="listStage(content)">
        <v-icon color="white">list</v-icon>
        <span> Listar Etapas</span>
      </v-btn>
      <v-btn outline small color="white" :loading="loading" @click="addStage(content, refresh)">
        <v-icon color="white">add</v-icon>
        <span> Adicionar Etapa</span>
      </v-btn>
    </template>
    <template slot-scope="{ content, refresh, loading }">
      <div style="padding: 10px 10px 0 10px;">
        <ProcessStagesFlowcharts
          :loading="loading"
          :process="content"
          @selectStage="selectStage"
        ></ProcessStagesFlowcharts>
        <ProcessStageDialog
          v-model="processStageDialog"
          :stage="processStage"
          @refresh="refresh"
        ></ProcessStageDialog>
      </div>
    </template>
  </WiView>
</template>
<script>
  import ProcessStageDialog from './ProcessStageDialog'
  import ProcessStagesFlowcharts from './ProcessStagesFlowcharts'
  export default {
    data: () => ({
      processStageDialog: false,
      processStage: {}
    }),
    methods: {
      selectStage: function (processStage) {
        if (processStage?.id) {
          this.openProcessStage(processStage)
        }
      },
      openProcessStage: function (processStage) {
        setTimeout(() => {
          this.processStage = processStage
          this.processStageDialog = true
        }, 10)
      },
      addStage: function (process, refresh) {
        this.$WiEditDialog({
          wiConfig: 'process-stage',
          data: {
            process_id: process.id 
          },
          redirectOnSubmit: false,
          onSubmit: refresh
        })
      },
      listStage: function (process) {
        this.$WiListDialog({
          wiConfig: 'process-stage',
          filter: {
            process_id: process.id,
            order: 'order,asc',
            withCount: 'results'
          },
          wiEditData: {
            process_id: process.id
          },
          redirectOnSubmit: false
        })
      }
    },
    components: {
      ProcessStageDialog,
      ProcessStagesFlowcharts
    }
  }
</script>
<style>
/* @import './vis/dist/vis.css'; */

canvas {
  background-position: center; 
  /*background-image: url('static/background/mapas/torre-master.jpg'); */
  background-size: 100%;
}

</style>
