export default {
  id: '4f1be58b',
  list: true,
  title: 'Tipos de Ocorrência',
  apiUrl: 'chamado/ocorrencia',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_categoria', text: 'Nome do Tipo de Ocorrência*', type: 'text', size: ['xs12', 'sm6', 'md3', 'lg3'], required: true, list: true, edit: true},
    {value: 'nome_categoria_acima', text: 'Grupo Acima', type: 'int', list: true, edit: false},
    {value: 'previsao_categoria', text: 'Previsão da Ocorrência*', type: 'text', mask: '##:##:##', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true},
    {value: 'cor_categoria', text: 'Cor do Tipo de Ocorrência', type: 'color', size: ['xs12', 'sm6', 'md2', 'lg2'], required: false, list: false, edit: true},
    {value: 'icone_categoria', text: 'Ícone do Tipo de Ocorrência', type: 'wi-google-icons', size: ['xs12', 'sm6', 'md3', 'lg3'], required: false, list: false, edit: true},
    {value: 'ativa_categoria', text: 'Está Ativo?', type: 'select', size: ['xs12', 'sm6', 'md2', 'lg2'], required: false, options: [{value: 'ativo', text: 'Sim'}, {value: 'inativo', text: 'Não'}], list: false, edit: true},
    {value: 'chamado_categoria_id', text: 'Categoria Acima', autocomplete: true, required: false, size: ['xs12', 'sm6', 'md7', 'lg7'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/ocorrencia', apiParams: {fields: 'id,nome_categoria'}, show: 'nome_categoria'},
    {value: 'chamado_urgencia_id', text: 'Urgência', autocomplete: true, required: true, size: ['xs12', 'sm6', 'md2', 'lg2'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/urgencia', apiParams: {fields: 'id,nome_urgencia'}, show: 'nome_urgencia'},
    {value: 'chamado_impacto_id', text: 'Impacto', autocomplete: true, required: true, size: ['xs12', 'sm6', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/impacto', apiParams: {fields: 'id,nome_impacto'}, show: 'nome_impacto'},
    {value: 'funcao_categoria', text: 'Função da Ocorrência', type: 'select', required: true, size: ['xs12', 'sm6', 'md8', 'lg8'], options: [{value: '0', text: 'Não selecionável'}, {value: 1, text: 'Selecionável na Abertura e Encerramento'}, {value: 2, text: 'Selecionável Somente na Abertura'}, {value: 3, text: 'Selecionável Somente no Encerramento'}], list: false, edit: true},
    {value: 'externo_categoria', text: 'Categoria disponível para área do cliente', type: 'select', size: ['xs12', 'sm6', 'md4', 'lg4'], options: [{value: 0, text: 'Não, não selecionável na área do cliente'}, {value: 1, text: 'Sim, selecionável na área do cliente'}], list: false, edit: true},
    {value: 'obs_categoria', text: 'Observações', type: 'textarea', required: false, list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
