<template>
	<view-detail apiUrl="finance/report/dashboard" :notShowToolbar="true" :notGetID="true">
    <template slot-scope="props">
      <v-container style="padding: 10px;" fluid grid-list-md>
        <v-layout row wrap>
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-card>
              <v-list dense style="max-height: none !important;">
                <v-list-tile v-ripple avatar>
                  <v-list-tile-avatar size="45">
                    <v-btn icon ripple @click="$router.go(-1)">
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </v-list-tile-avatar>
                  <v-list-tile-avatar size="45">
                    <img src="static/systems/modules/wises-technology-module-finance.png" alt="Connect Controll" >
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                    <v-list-tile-title style="font-size:18px;">
                      <span>Financeiro</span>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action style="min-width: 1px !important;">
                    <v-btn flat icon color="green" @click="props.refresh()">
                      <v-icon>refresh</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
          <v-flex sm12 lg6 style="padding: 0 5px 10px 5px;">
            <v-card class="mx-auto text-xs-center" color="green" dark style="max-height: 150px !important;">
              <v-card-text style="padding-bottom: 0;">
                <h4>Últimas 5 Receitas</h4>
              </v-card-text>
              <v-card-text class="v-sheet--offset" style="padding-bottom: 0;">
                <v-sheet color="rgba(0, 0, 0, .12)" style="max-height: 130px !important;">
                  <v-sparkline :value="props.content.finance_last.in" auto-draw color="rgba(255, 255, 255, .7)" height="80" padding="22" stroke-linecap="round" smooth  style="max-height: 130px !important;">
                    <template v-slot:label="item">
                      {{ parseFloat(item.value) | money }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm12 lg6 style="padding: 0 5px 10px 5px;">
            <v-card class="mx-auto text-xs-center" color="#C62828" dark style="max-height: 150px !important;">
              <v-card-text style="padding-bottom: 0;">
                <h4>Últimas 5 Despesas</h4>
              </v-card-text>
              <v-card-text class="v-sheet--offset" style="padding-bottom: 0;">
                <v-sheet color="rgba(0, 0, 0, .12)" style="max-height: 130px !important;">
                  <v-sparkline :value="props.content.finance_last.out" auto-draw color="rgba(255, 255, 255, .7)" height="80" padding="22" stroke-linecap="round" smooth  style="max-height: 130px !important;">
                    <template v-slot:label="item">
                      {{ parseFloat(item.value) | money }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 style="padding: 0 5px 5px 5px;" v-if="props.content.finance_accounts">
            <v-card>
              <v-card-title>
                <h4>Contas</h4>
                <v-spacer></v-spacer>
                <h4>{{props.content.finance_accounts.balance_current | money}}</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="padding: 0;">
                <v-list two-line style="padding: 0;">
                  <template v-for="(account, accountIndex) in props.content.finance_accounts.list">
                    <v-list-tile avatar :key="'account-' + accountIndex">
                      <v-list-tile-avatar>
                        <img :src="account.bank.logo_url">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-html="account.name"></v-list-tile-title>
                        <v-list-tile-sub-title v-html="account.bank.name"></v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-list-tile-action-text>{{ account.balance_current | money }}</v-list-tile-action-text>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider :key="accountIndex"></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md8 lg8 style="padding: 5px">
            <v-layout row wrap>
              <v-flex xs12 sm12 md6 lg6 style="padding: 0 5px 5px 5px;">
                <v-card>
                  <v-card-title>
                    <v-icon color="#4AB858" style="height: 15px;">get_app</v-icon>
                    <h4 style="padding-left: 5px;">A receber HOJE</h4>
                    <v-spacer></v-spacer>
                    <strong class="text_paid">{{props.content.finance_unpaid_validity_today.in.count}}</strong>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="text-align: center;">
                    <h2 style="color: #4AB858;">{{props.content.finance_unpaid_validity_today.in.amount | money}}</h2>
                    <v-btn small v-if="props.content.finance_unpaid_validity_today.in.amount > 0" color="#4AB858" outline @click="$router.push({name: 'listing', params: {page: 'finance-in-unpaid'}, query: {period: 'date', date: props.content.today}})">A Receber Hoje</v-btn>
                    <v-btn small v-else color="#4AB858" outline @click="$router.push({name: 'listing', params: {page: 'finance-in-unpaid'}})">Contas a Receber</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 style="padding: 0 5px 5px 5px;">
                <v-card>
                  <v-card-title>
                    <v-icon color="#F43E61" style="height: 15px;">publish</v-icon>
                    <h4 style="padding-left: 5px;">A pagar HOJE</h4>
                    <v-spacer></v-spacer>
                    <strong class="text_unpaid">{{props.content.finance_unpaid_validity_today.out.count}}</strong>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="text-align: center;">
                    <h2 style="color: #F43E61;">{{props.content.finance_unpaid_validity_today.out.amount | money}}</h2>
                    <v-btn small v-if="props.content.finance_unpaid_validity_today.out.amount > 0" color="#F43E61" outline @click="$router.push({name: 'listing', params: {page: 'finance-out-unpaid'}, query: {period: 'date', date: props.content.today}})">A Pagar Hoje</v-btn>
                    <v-btn small v-else color="#F43E61" outline @click="$router.push({name: 'listing', params: {page: 'finance-out-unpaid'}})">Contas a Pagar</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 style="padding: 5px;">
                <v-card>
                  <v-card-title>
                    <h4>Recebimentos em atraso</h4>
                    <v-spacer></v-spacer>
                    <strong class="text_paid">{{props.content.finance_unpaid_validity_expired.in.count}}</strong>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="text-align: center;">
                    <h2 style="color: #4AB858;">{{props.content.finance_unpaid_validity_expired.in.amount | money}}</h2>
                    <v-btn small :disabled="props.content.finance_unpaid_validity_expired.in.amount < 1" color="#4AB858" outline @click="$router.push({name: 'finance-list', params: {page: 'finance-in-list'}, query: {expired: true}})">em atraso</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 style="padding: 5px;">
                <v-card>
                  <v-card-title>
                    <h4>Pagamentos em atraso</h4>
                    <v-spacer></v-spacer>
                    <strong class="text_unpaid">{{props.content.finance_unpaid_validity_expired.out.count}}</strong>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="text-align: center;">
                    <h2 style="color: #F43E61;">{{props.content.finance_unpaid_validity_expired.out.amount | money}}</h2>
                    <v-btn small :disabled="props.content.finance_unpaid_validity_expired.out.amount < 1" color="#F43E61" outline @click="$router.push({name: 'finance-list', params: {page: 'finance-out-list'}, query: {expired: true}})">em atraso</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  export default {
    name: 'FinanceDashboard',
    data: () => ({
      value: [ 200, 675, 310, 200, 1075.35 ]
    }),
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      }
    }
  }
</script>
<style>
.v-sheet--offset {
  top: -44px;
  position: relative;
}
.text_paid {
  color: #4AB858;
}
.text_unpaid {
  color: #F43E61;
}
</style>