export default {
  id: '4f1be58b',
  list: true,
  title: {
    list: 'Status/Etapas de Diagnóstico',
    edit: {
      create: 'Nova Status/Etapas de Diagnóstico',
      update: 'Editar Status/Etapas de Diagnóstico'
    }
  },
  icon: 'code',
  apiUrl: 'networks/config/status/test-management-vtal',
  fields: [
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      list: true,
      edit: false,
      search: true
    },
    {
      value: 'name',
      text: 'Nome do Status*',
      type: 'text',
      size: ['xs12', 'sm6', 'md6', 'lg7'],
      required: true,
      list: true,
      edit: true,
      search: true
    },
    {
      value: 'stage',
      text: 'Tipo de Status',
      placeholder: 'Como este status irá influenciar o negócio.',
      type: 'select',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [{
        value: 1,
        text: 'Em Andamento'
      }, {
        value: 2,
        text: 'Concluído'
      }, {
        value: 3,
        text: 'Cancelado'
      }],
      edit: {
        create: true
      }
    },
    {
      value: 'active',
      text: 'Está ativo?',
      required: false,
      size: ['xs12', 'sm12', 'md3', 'lg2'],
      type: 'switch',
      list: false,
      edit: true
    },
    {
      value: 'color',
      text: 'Cor do Status',
      type: 'color',
      size: ['xs12', 'sm6', 'md3', 'lg3'],
      required: false,
      list: false,
      edit: true
    },
    {
      value: 'icon',
      text: 'Ícone do Status',
      type: 'wi-google-icons',
      size: ['xs12', 'sm6', 'md3', 'lg4'],
      required: false,
      list: false,
      edit: true
    },
    {
      value: 'order',
      text: 'Ordem do Status',
      type: 'int',
      size: ['xs12', 'sm6', 'md3', 'lg3'],
      required: false,
      list: false,
      edit: true
    },
    {
      value: 'set_deadline',
      text: 'Exigir data limite',
      required: false,
      size: ['xs12', 'sm12', 'md3', 'lg2'],
      type: 'switch',
      list: false,
      edit: true
    },
    {
      value: 'description',
      text: 'Observações',
      type: 'textarea',
      required: false,
      list: true,
      edit: true
    }
  ],
  fieldsFixed: [
    {
      value: 'type', data: 'test-management-vtal'
    }
  ]
}
