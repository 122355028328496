<template>
  <v-content :style="background">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md5 lg4 xl3>
          <v-card class="elevation-20" style="max-width:600px;">
            <v-card-title style="padding-bottom: 0;">
              <v-container fluid style="padding:0px !important;">
                <v-layout align-center justify-center>
                  <img :src="'static/systems/' + system.icon" alt="" style="width:150px;">
                </v-layout>
                <v-layout align-center justify-center>
                  <span v-html="system.nameHtml" style="font-size: 40px;"></span>
                </v-layout>
              </v-container>
            </v-card-title>
            <v-card-text style="padding-bottom: 0px;">
              <v-container style="padding: 0 16px 0 16px;">
                <v-layout row wrap>
                  <v-flex xs12 sm12 md10 lg10 offset-md1 offset-lg1>
                    <v-container style="padding: 0 !important;">
                      <v-layout align-center justify-center>
                        <v-form v-model="valid" ref="form" style="width: 100%;">
                          <v-text-field
                            solo
                            @keyup.enter="onSubmit()"
                            :label="$t('pages.login.username.label')"
                            prepend-icon="person"
                            v-model="login.login_usuario"
                            :rules="[v => !!v || $t('messages.requiredField', { field: $t('pages.login.username.label') })]"
                            required
                            :placeholder="$t('pages.login.username.placeholder')"
                            :autofocus="true"
                          ></v-text-field>
                          <v-text-field
                            solo
                            @keyup.enter="onSubmit()"
                            required
                            :rules="[v => !!v || $t('messages.requiredField', { field: $t('pages.login.username.label') })]"
                            prepend-icon="lock"
                            v-model="login.password"
                            name="password"
                            label="Password"
                            :placeholder="$t('pages.login.password.placeholder')"
                            type="password"
                          ></v-text-field>
                        </v-form>
                      </v-layout>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 sm12 md10 lg10 offset-md1 offset-lg1>
                    <v-container style="padding: 0 !important;">
                      <v-layout align-center justify-center>
                        <v-btn
                          color="primary"
                          @click="onSubmit"
                          :disabled="!valid"
                          style="width: 100%;"
                          v-t="'pages.login.submit.label'"
                        ></v-btn>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions style="text-align: center;">
              <span style="width: 100%; text-align: center; font-size: 12px; color: gray;">
                <label class="center-align">© 
                  <span id="year">{{ new Date().getFullYear() }} </span> 
                  <span>Copyright </span> 
                  <a target="_blank" href="https://wnewer.com" style="text-decoration:none;">
                  <span> WNewer Technology </span>
                  </a> 
                  <span
                    style="text-transform: lowercase;"
                    v-t="'pages.login.copyright.text'"
                  ></span>
                </label>
              </span>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script>
  import {Api} from '@/default/service/Api'
  export default {
    name: 'Login',
    data () {
      return {
        login: {
          login_usuario: '',
          password: ''
        },
        valid: true
      }
    },
    methods: {
      onSubmit: function () {
        if (this.$refs.form.validate()) {
          this.$swal({
            title: this.$t('pages.login.messages.loading.title'),
            text: this.$t('pages.login.messages.loading.text'),
            onOpen: () => { this.$swal.showLoading() }
          })
          Api.auth(this.login, () => {
            if (this.user.account && this.user.account.length > 0) {
              this.$router.push({ name: 'selecionar-empresa' })
            } else {
              this.$router.push({ name: 'main' })
              location.reload()
            }
            this.$swal.close()
          })
          .then(() => {})
          .catch((error) => {
            let title = this.$t('pages.login.messages.error.title')
            let message = this.$t('pages.login.messages.error.text')
            if (error?.response?.data?.code == 'account-not-active') {
              title = this.$t('pages.login.messages.accountNotActive.title')
              message = this.$t('pages.login.messages.accountNotActive.text')
            }
            this.$WiMakeError({
              error: error,
              id: 200,
              title,
              message,
              login: this.login.login_usuario
            })
            this.login.password = ''
          })
        }
      }
    },
    computed: {
      user () {
        return this.$store.state.user || {colaborador: {nome_pessoa: 'Carregando...'}}
      },
      system () {
        return this.$store.state.system || {}
      },
      background () {
        const backgroundImage = document?.wise_env?.loginBackground || 'background-login-new.jpg'
        return {
          'background-color': '#2196f3',
          'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(static/background/${backgroundImage})`,
          'background-repeat': 'not-repeat',
          'background-size': 'cover'
        }
      }
    }
  }
</script>
