import ClinicRegisterCollaborator from '@/modules/register/config/Person/Collaborator?url=CancelSale'

ClinicRegisterCollaborator.id = {
  id: 'd897f3f3',
  create: 'd897f3f3',
  update: 'd897f3f3',
  delete: 'd897f3f3'
}

export default ClinicRegisterCollaborator
