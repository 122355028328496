<template>
  <v-dialog v-model="dialog" max-width="1100" scrollable persistent>
    <v-card>
      <v-toolbar v-if="task && task.id" dense class="white--text" :color="(loadedTask && loadedTask.id) ? loadedTask.status_info.color : task.status_info.color">
        <v-icon large color="white">{{(loadedTask && loadedTask.id) ? loadedTask.status_info.icon : task.status_info.icon}}</v-icon>
        <v-toolbar-title>
          <strong>Detalhes do Agendamento</strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="loadedTask && loadedTask.id" outline small color="white" @click="editTask()">
          <v-icon>event</v-icon>
          <span>Editar Agendamento</span>
        </v-btn>
        <v-btn v-if="loadedTask && loadedTask.id" outline small color="white" @click="editCustomer()">
          <v-icon>perm_identity</v-icon>
          <span>Editar Paciente</span>
        </v-btn>
        <v-btn v-if="loadedTask && loadedTask.id && $Auth.checkPermission('015b8d97')" outline small color="white" link :to="{ name: 'medical-record', params: { id: loadedTask.person_id } }">
          <v-icon>assignment</v-icon>
          <span>Prontuário</span>
        </v-btn>
        <v-btn v-if="loadedRefresh" icon @click="loadedRefresh()">
          <v-icon color="white">refresh</v-icon>
        </v-btn>
        <v-btn icon @click="closeDialog()">
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 800px; z-index: 999;">
        <wi-view 
          v-if="task && task.id" 
          index="task" 
          :apiUrl="'tasks/task/' + task.id" 
          apiParams="?with=customer.phones;customer.adresses;transfers.transfer;businesess.business.items"
          notShowToolbar="true"
          :notReturnIcon="true"
          :notGetID="true"
          store="task-clinic-opened"
          v-on:afterLoad="setLoadedTask"
        >
          <template slot-scope="{ content, refresh }">
            <Task :task="content" :refresh="refresh" style="z-index: 0;"></Task>
          </template>
        </wi-view>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import Task from '@/modules/projects/pages/Task/Task'
  export default {
    name: 'TaskSale',
    data: () => ({
      loadedTask: {},
      loadedRefresh: null
    }),
    computed: {
      dialog: {
        get () {
          return this.active
        },
        set (active) {
          this.$emit('active', active)
        }
      }
    },
    methods: {
      setLoadedTask: function ({ content, refresh }) {
        this.loadedTask = content
        this.loadedRefresh = refresh
      },
      editTask: function () {
        this.$WiEditDialog({
          wiConfig: 'task-clinic',
          data: this.loadedTask,
          onSubmit: () => {
            this.loadedRefresh()
            this.refresh()
          },
          redirectOnSubmit: false
        })
      },
      editCustomer: function () {
        this.$WiEditDialog({
          wiConfig: 'patient',
          data: this.loadedTask.customer,
          onSubmit: () => {
            this.loadedRefresh()
          },
          redirectOnSubmit: false
        })
      },
      closeDialog: function () {
        this.loadedTask = {}
        this.loadedRefresh = null
        this.dialog = false
      }
    },
    props: {
      active: {
        type: Boolean,
        required: true
      },
      task: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      Task
    }
  }
</script>
