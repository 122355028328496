<template>
 
      <apexchart
        width="100%"
        height="300"
        type="bar"
        :series="series"
        :options="chartOptions"
      ></apexchart>

</template>

<script>
export default {
  name: 'PeriodColumnChart',
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 298,
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if (opts.seriesIndex === 0) {
            return 'R$ ' + val.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
          }
          return val
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        type: 'category',
        categories: [],
        labels: {
          rotate: -45,
          trim: true,
          maxHeight: 70,
          style: {
            fontSize: '12px'
          },
          show: true
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        }
      },
      yaxis: [
        {
          title: {
            text: 'Valor Total (R$)'
          },
          labels: {
            formatter: (val) => {
              return 'R$ ' + val.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
            }
          }
        },
        {
          opposite: true,
          title: {
            text: 'Número de Propostas'
          }
        }
      ],
      colors: ['#2E93fA', '#66DA26'],
      tooltip: {
        y: [{
          formatter: (val) => {
            return 'R$ ' + val.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
          }
        }, {
          formatter: (val) => {
            return val + ' propostas'
          }
        }]
      }
    }
  }),
  methods: {
    processChartData() {
      const sortedCollaborators = [...this.collaborators].sort((a, b) => b.amount - a.amount)

      const categories = sortedCollaborators.map(c => c.name)
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: categories
        }
      }

      this.series = [
        {
          name: 'Valor Total',
          data: sortedCollaborators.map(c => Number(c.amount))
        },
        {
          name: 'Quantidade',
          data: sortedCollaborators.map(c => c.count)
        }
      ]
    }
  },
  mounted() {
    this.processChartData()
  },
  props: {
    collaborators: {
      required: true,
      type: Array
    }
  }
}
</script>