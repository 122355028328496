import Customer from '@/modules/graduations/config/Customer/Customer'
import Comission from '@/modules/graduations/config/Customer/Comission'
import Graduation from '@/modules/graduations/config/Graduation/Graduation'
import Grade from '@/modules/graduations/config/Grade/Grade'
import GradePerson from '@/modules/graduations/config/Grade/GradePerson'

// Common
import Event from '@/modules/graduations/config/Common/Event'
import Item from '@/modules/graduations/config/Common/Item'
import Person from '@/modules/graduations/config/Common/Person'
import Finances from '@/modules/graduations/config/Common/Finance'
import FinanceReceive from '@/modules/graduations/config/Common/FinanceReceive'

// Configurations
import Status from '@/modules/graduations/config/Config/Status'
import Category from '@/modules/graduations/config/Config/Category'

import { Course } from '@/modules/graduations/config/register/Course'
import { College } from '@/modules/graduations/config/register/College'

export default {
  'graduations-register-customer': Customer,
  'graduations-register-comission': Comission,
  'graduation': Graduation.graduation,
  'graduation-open': Graduation.open,
  'graduation-close': Graduation.close,
  'graduation-cancel': Graduation.cancel,
  'graduation-grade': Grade,
  'graduation-grade-person': GradePerson,
  'graduation-event': Event.graduation,
  'graduation-item-product': Item.product.graduation,
  'graduation-item-service': Item.service.graduation,
  'graduation-person': Person,
  'graduation-finances': Finances.graduation,
  'graduation-finance': FinanceReceive,

  'config-status-graduation': Status.graduation,
  'config-category-graduation': Category.graduation,

  'graduation-register-course': new Course(),
  'graduation-register-college': new College()
}
