const event = (id, name, title) => {
  return {
    id: id,
    title: 'Adicionar Nota ' + title,
    icon: 'message',
    apiUrl: 'purchases/' + name + '/event',
    params: {
      order: 'id,desc'
    },
    fields: [
      {text: 'Descreva sua nota de forma que fique clara aos outros usuários.', type: 'subheader', edit: true},
      {value: 'description', text: 'Insira aqui sua nota.*', type: 'editor', required: true, edit: true}
    ],
    fieldsFixed: [
      {
        value: 'type', data: 2
      }
    ]
  }
}

export default {
  purchase: event('b41dcfec', 'purchase', 'à Compra'),
  quotation: event('d3c48579', 'quotation', 'à Cotação')
}
