import i18n from '@/lang/i18n'
import ChannelMenu from './ChannelMenu'

export default [
  {
    id: 'ca5fa4b4',
    icon: 'supervised_user_circle',
    title: i18n.t('modules.registers.menu.teams.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'team'
      }
    },
    hideOnDevices: ['mobile']
  },
  {
    id: 'dc24170b',
    icon: 'assignment_ind',
    title: i18n.t('modules.registers.menu.collaborators.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'collaborator'
      }
    }
  },
  {
    id: '549b7548',
    icon: 'people_outline',
    title: i18n.t('modules.registers.menu.customers.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'customer'
      }
    }
  },
  {
    id: '232e1dba',
    icon: 'local_atm',
    title: i18n.t('modules.registers.menu.providers.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'provider'
      }
    }
  },
  {
    id: '2498ebe2',
    icon: 'perm_identity',
    title: i18n.t('modules.registers.menu.prospects.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'prospect'
      }
    }
  },
  {
    id: '8f5922e4',
    icon: 'business',
    title: i18n.t('modules.registers.menu.companies.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'company'
      }
    }
  },
  ChannelMenu,
  {
    id: '5d0302b3',
    icon: 'lock_open',
    title: i18n.t('modules.registers.menu.userProfiles.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'user-profile'
      }
    },
    hideOnDevices: ['mobile']
  }
  // {id: 'pssa', icon: 'accessibility', title: 'Prospects', route: {name: 'listing', params: {page: 'prospect'}}, selected: false},
  // {id: 'pssa', icon: 'local_shipping', title: 'Transportadoras', route: {name: 'listing', params: {page: 'transportadora'}}, selected: false}
  // {id: 'pssa', icon: 'class', title: 'Seguimento/Ramo', route: {name: 'listing', params: {page: 'seguimento'}}, selected: false},
]

// {
//   team: {
//     title: 'Equipes/Departamentos'
//   },
//   collaborators: {
//     title: 'Colaboradores'
//   },
//   customers: {
//     title: 'Clientes'
//   },
//   providers: {
//     title: 'Fornecedores'
//   },
//   prospects: {
//     title: 'Prospects'
//   },
//   companies: {
//     title: 'Empresas (Matriz e Filiais)'
//   },
//   channels: {
//     title: 'Canais de Atendimento'
//   },
//   userProfiles: {
//     title: 'Perfis de Usuários'
//   }
// }