export default {
  SET_EXECUTION (state, execution) {
    delete execution.stages
    state.execution = execution
  },
  SET_STAGES (state, stages) {
    state.stages = stages
  },
  SET_STAGE (state, stage) {
    const key = state.stages.findIndex(s => (s.id == stage.id))
    if (key > -1) {
      state.stages.splice(key, 1, stage)
    } else {
      state.stages.push(stage)
    }
  },
  TOUGGLE_LOADING (state) {
    state.loading = !state.loading
  }
}
