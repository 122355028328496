<template>
  <div style="z-index: 0;">
    <v-divider></v-divider>
    <div style="margin: 5px 16px;">
      <v-text-field
        v-model="memberSearch"
        outline
        small
        dense
        single-line
        hide-details
        clear-icon
        :placeholder="$t('modules.talks.pages.talkView.talk.labels.memberSearchPlaceholder')"
      ></v-text-field>
    </div>
    <v-divider></v-divider>
    <v-list v-if="members && members.length > 0" dense style="overflow-y: auto; max-height: calc(100vh - 371px); padding-bottom: 100px;">
      <template v-for="(contact, contactKey) in members">
        <v-divider v-if="contactKey" :key="contactKey" inset></v-divider>
        <v-list-tile :key="contactKey + '-new'" avatar>
          <v-list-tile-avatar>
            <img v-if="contact.file && contact.file.url" :src="contact.file.url">
            <img v-else src="static/icons/user_icon_1.png">
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <img src="static/icons/monitor/whatsapp.png" style="width: 10px;">
              <strong> {{ contact.name }} </strong>
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <span v-if="contact.number && contact.number.toString().length == 12">
                <strong>{{ contact.number | mask('+## (##) ####-####') }}</strong>
              </span>
              <span v-else-if="contact.number && contact.number.toString().length == 13">
                <strong>{{ contact.number | mask('+## (##) # ####-####') }}</strong>
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn icon @click="startTalkByMember(contact)">
              <v-icon>message</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </template>
    </v-list>
    <div v-else>
      <v-divider></v-divider>
      <h2 style="padding: 20px;">
        <span
          v-t="'modules.talks.pages.talkView.talk.labels.noMembersMessage'"
        ></span>
        <br>
        <span> "{{ memberSearch }}"</span>
      </h2>
    </div>
  </div>
</template>
<script>
  import talkViewMixin from '../../talkViewMixin'
  export default {
    name: 'TalkContactMemberList',
    data () {
      return {
        items: [],
        showData: false,
        memberSearch: ''
      }
    },
    computed: {
      members () {
        const members = this.contact?.current_members || []
        if (this.memberSearch) {
          return members.filter(member => {
            return (member?.name && typeof member.name == 'string') &&
              member.name.toLowerCase().includes(
                this.memberSearch.toLowerCase()
              )
          })
        } else {
          return members || []
        }
      }
    },
    methods: {
      startTalkByMember: function (contact) {
        this.$swal({
          title: `Iniciar conversa com ${contact.name}?`,
          text: 'Ao clicar em "Sim, iniciar!", você será redirecionado para a conversa com este contato.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, iniciar!',
          cancelButtonText: 'Cancelar'
        }).then((response) => {
          if (response.value) {
            this.openTalkContact(contact)
          }
        })
      }
    },
    props: ['contact'],
    mixins: [talkViewMixin]
  }
</script>