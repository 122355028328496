import { BusinessSimple } from '@/modules/business/config/BusinessSimple'
import {
  PERMISSION_ORDER,
  PERMISSION_ORDER_CREATE,
  PERMISSION_ORDER_UPDATE,
  PERMISSION_ORDER_DELETE
} from '@/default/constants/permissions'

export class Order extends BusinessSimple {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_ORDER,
      create: PERMISSION_ORDER_CREATE,
      update: PERMISSION_ORDER_UPDATE,
      delete: PERMISSION_ORDER_DELETE
    }
    this.name = 'order'
    this.title = {
      list: 'Ordens',
      edit: {
        insert: 'Nova Ordem de Serviço',
        update: 'Editar Ordem de Serviço'
      }
    }
    this.icon = 'border_color'
    this.apiUrl = 'service-desk/order'
    this.urlDetails.name = 'order'
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Orders')
    
    this.fields = []
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
    this.fieldsEdit.person.size = ['xs12', 'sm12', 'md4', 'lg4']
    this.fieldsEdit.collaborator.size = ['xs12', 'sm12', 'md3', 'lg3']
    this.fieldsEdit.dateStart.text = 'Data de Referência'
    // this.fieldsEdit.dateEnd.text = 'Data de execução'
    this.fieldsEdit.subHeader1.text = 'Informe os dados básicos desta ordem de serviço.'
    this.fieldsEdit.status.apiUrl = 'sales/config/status/order'
    this.fieldsEdit.category.apiUrl = 'sales/config/category/order'
    this.fieldsEdit.category.disabled = form => !!form.id
  }
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    personName: this.fieldList({
      value: 'person.name',
      text: 'Nome do Cliente'
    }),
    status: this.fieldList({
      value: 'business_status_id',
      text: 'Status/Etapa',
      align: 'center'
    }),
    dateStart: this.fieldList({
      value: 'date_start',
      text: 'Data de Referência'
    }),
    collaborator: this.fieldList({
      value: 'business_category_id',
      text: 'Categoria/Tipo',
      align: 'center'
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      sortable: false
    })
  }
  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste contrato.'
    }),
    company: this.fieldsEdit.company,
    vTalTicketType: this.fieldSelect({
      value: 'integration_code',
      text: 'Tipo de Trouble Ticket*',
      placeholder: 'Como este status irá influenciar o negócio.',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true,
      options: [
        {
          value: 'SuporteAtivacao',
          text: 'Suporte Ativação'
        },
        {
          value: 'ChamadoTecnico',
          text: 'Chamado Técnico'
        }
      ],
      disabled: form => !!form.id
    }),
    person: this.fieldsEdit.person,
    dateStart: this.fieldsEdit.dateStart,
    contract: this.fieldWiListDialog({
      value: 'business_id',
      text: 'Contrato do cliente',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      wiConfig: 'contract',
      show: 'contract.integration_code',
      icon: 'person_outline',
      // showField: (form) => (!!form.person_id),
      filter: form => ({
        where: 'person_id,' + form.person_id
      }),
      disabled: form => !!form.id
    }),
    category: this.fieldsEdit.category,
    status: this.fieldsEdit.status,
    collaborator: this.fieldsEdit.collaborator,
    subHeader2: this.fieldSubHeader({
      text: 'Descreva a solicitação abaixo.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição da solicitação',
      placeholder: 'Ex: Cliente se encontra sem conexão...',
      required: true,
      disabled: form => !!form.id
    })
  }
}

export class OrderOpened extends Order {
  constructor () {
    super()
    this.title.list = 'Ordens em aberto'
    this.params.stage = 'open'
  }
}
export class OrderClosed extends Order {
  constructor () {
    super()
    this.title.list = 'Histórico de ordens'
    this.params.stage = 'close'
  }
}
export class OrderCanceled extends Order {
  constructor () {
    super()
    this.title.list = 'Ordens canceladas'
    this.params.stage = 'cancel'
  }
}

// class Order extends BusinessSimple {
//   constructor (listTitle, stage, fieldDate) {
//     super()
//     this.list = true
//     this.id = {
//       id: '74cc44a0',
//       create: '8e45510b',
//       update: '18baa289',
//       delete: 'e2dd4c31'
//     }
//     this.name = 'order'
//     this.title = {
//       list: listTitle,
//       edit: {
//         insert: 'Nova Ordem de Serviço',
//         update: this.getLangText('service-desk.order.title.edit.update')
//       }
//     }
//     this.apiUrl = 'service-desk/order'
//     this.params['stage'] = stage
//     this.urlDetails = { name: 'order', params: {} }
//     this.icon = 'shopping_cart'
    
//     // Fields
//     this.fields[2].text = 'Cliente'
//     this.fields[2].wiConfig = 'customer'
//     this.fields[4].apiUrl = 'sales/config/status/order'
//     this.fields[5].apiUrl = 'sales/config/category/order'
//     this.fields[5].text = 'Categoria/Ocorrência'
//     this.fields[8].text = 'Data de Execução'
//     this.fields[24].value = fieldDate.value
//     this.fields[24].text = fieldDate.text
//     this.fields[25] = {
//       value: 'date_end',
//       text: 'Data de Execução',
//       list: true
//     }
//   }
// }

// export default {
//   open: new Order('Ordens Em Aberto', 'open', { value: 'opened_at', text: 'Data de Criação' }),
//   close: new Order('Histórico de Ordens', 'close', { value: 'closed_at', text: 'Data de Realização' }),
//   cancel: new Order('Ordens Canceladas', 'cancel', { value: 'canceled_at', text: 'Data de Cancelamento' })
// }
