import { Model } from '@/default/model/Model'
import { TalkFilters } from '@/modules/talk/config/Talk/TalkFilters'

export class Talk extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'f55197d7',
      create: 'a20283e8',
      update: '48fac53d',
      delete: 'f12a2220'
    }
    this.actions = {
      list: true,
      create: true,
      update: false,
      delete: false
    }
    this.name = 'talk'
    this.title = {
      list: 'Atendimentos',
      edit: {
        insert: 'Criar nova conversa/chat',
        update: 'Editar conversa/chat'
      }
    }
    this.urlDetails = { name: 'talk-view-messages', params: {} }
    this.apiUrl = 'talk/talk'
    this.icon = 'message'
    this.params = {
      limit: 10,
      with: 'campaign:id,name,status_id',
    }
    this.search = {
      placeholder: 'Pesquise pelo Protocolo nome ou número do contato',
      fields: ['protocol', 'contact.name', 'contact.number', 'contact.contact']
    }
    this.maxWidth = '900px'
    this.filters = new TalkFilters()
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/talk/pages/Talks/TalksTable')
    this.setFields([
      // this.fieldList({
      //   value: 'protocol',
      //   text: 'Protocolo'
      // }),
      this.fieldList({
        value: 'status',
        text: 'Status'
      }),
      // this.fieldList({
      //   value: 'file_id',
      //   text: 'Imagem'
      // }),
      this.fieldList({
        value: 'contact.name',
        text: 'Contato'
      }),
      this.fieldList({
        value: 'operator_id',
        text: 'Operador'
      }),
      this.fieldList({
        value: 'talk_channel_id',
        text: 'Canal de atendimento'
      }),
      // this.fieldList({
      //   value: 'last_event_at',
      //   text: 'Última interação'
      // }),
      // this.fieldList({
      //   value: 'time_total',
      //   text: 'Duração'
      // }),
      this.fieldList({
        value: 'opened_at',
        text: 'Início/Término'
      }),
      // this.fieldList({
      //   value: 'closed_at',
      //   text: 'Conclusão'
      // })
      this.fieldSubHeader({
        text: 'Confirme o canal e o contato para iniciar uma conversa/chat.'
      }),
      this.fieldSelectApi({
        value: 'talk_channel_id',
        text: 'Canal de atendimento',
        show: 'name_complete',
        api: {
          url: 'talk/channel',
          params: {
            fields: 'id,uuid,name,type_id,status_id',
          }
        },
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        color: 'status_color',
        icon: 'status_icon',
        required: true
      }),
      this.fieldWiListDialog({
        value: 'talk_contact_id',
        text: 'Contato',
        show: 'contact.name',
        icon: 'person',
        wiConfig: 'talk-contact-all',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      }),
      // this.fieldSubHeader({
      //   text: 'Mensagem que será enviada ao iniciar a conversa.'
      // }),
      // this.fieldTextArea({
      //   value: 'message',
      //   text: 'Mensagem',
      //   placeholder: 'Digite a mensagem que será enviada ao iniciar a conversa.',
      //   size: ['xs12', 'sm12', 'md12', 'lg12']
      // }),
    ])
  }
}
export class TalkOpened extends Talk {
  constructor () {
    super()
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: false
    }
    this.title.list = 'Em Andamento'
    this.params['where'] = 'status,<,3'
  }
}
export class TalkClosed extends Talk {
  constructor () {
    super()
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: false
    }
    this.title.list = 'Histórico de Atendimentos'
    this.params['where'] = 'status,3'
  }
}
export class TalkCanceled extends Talk {
  constructor () {
    super()
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: false
    }
    this.title.list = 'Atendimentos Cancelados'
    this.params['where'] = 'status,4'
  }
}
