export default {
  countProductCollaborator (state) {
    return state.productCollaborator.length
  },
  countProductsEntry (state) {
    return state.productsEntry.length
  },
  countProductsRemoval (state) {
    return state.productsRemoval.length
  },
  countProductsSale (state) {
    return state.productsSale.length
  }
}
