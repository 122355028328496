import { Auth } from '@/default/service/Auth'
import { callApi } from '@/default/service/Api'
import { PERMISSION_TEST_MANAGEMENT_VTAL_IN } from '@/default/constants/permissions'

export default {
  startExecution ({ state, commit, dispatch }, execution) {
    if (state.execution.id != execution.id) {
      dispatch('closeExecution')
      dispatch('subscribeExecution', execution.id)
    }
    commit('SET_STAGES', execution.stages)
    delete execution.stages
    commit('SET_EXECUTION', execution)
  },
  getExecution ({ commit, dispatch }, executionId) {
    commit('TOUGGLE_LOADING')
    callApi.get({
      uri: `processes/execution/${executionId}`,
      params: {},
      sucess: response => {
        dispatch('startExecution', response.data)
        commit('TOUGGLE_LOADING')
      },
      error: () => {
        commit('TOUGGLE_LOADING')
      }
    })
  },
  refreshExecution ({ state, dispatch }) {
    dispatch('getExecution', state.execution.id)
  },
  subscribeExecution ({ commit }, executionId) {
    if (Auth.checkPermission(PERMISSION_TEST_MANAGEMENT_VTAL_IN)) {
      window.Echo.private(`${Auth.account().id}.process-execution.${executionId}`)
      .listen('.updated', execution => {
        commit('SET_EXECUTION', execution)
      })
      .listen('.stage.created', stage => {
        commit('SET_STAGE', stage)
      })
      .listen('.stage.updated', stage => {
        commit('SET_STAGE', stage)
      })
    }
  },
  unSubscribeExecution: function ({ state }) {
    if (state?.execution?.id) {
      window.Echo.leave(`${Auth.account().id}.process-execution.${state.execution.id}`)
    }
  },
  closeExecution: function ({ commit, dispatch }) {
    commit('TOUGGLE_LOADING')
    dispatch('unSubscribeExecution')
    commit('SET_STAGES', [])
    commit('SET_EXECUTION', {})
    commit('TOUGGLE_LOADING')
  }
}
