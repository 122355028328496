<template>
  <div class="event-order-vtal">
    <DiagnosticStatus
      :status="event.status_info"
      :complement="event.complement"
    ></DiagnosticStatus>
    <v-layout v-if="event.complement" row wrap>
      <v-flex v-if="event.complement.type" xs12 sm12 md6 lg6 xl6>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>settings</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Tipo: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 16px;">
              <strong>{{event.complement.type}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.status" xs12 sm12 md6 lg6 xl6>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>adjust</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Status: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 16px;">
              <strong>{{event.complement.status}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.issue && event.complement.issue.code" xs12 sm12 md12 lg12 xl12>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>event</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              <span>{{event.complement.issue.action}} </span> 
              <span>{{event.complement.issue.code}} </span> 
              <span> - {{event.complement.issue.updateDate | moment('DD/MM/YY HH:mm')}}</span>
            </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{event.complement.issue.observation}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action v-if="event.actions && event.actions.length > 0">
            <v-list-tile-action-text v-if="event.complement.issue.sended" style="border-style: solid; border-color: green; border-width: 1px; border-radius: 2px; padding: 0 5px; color: green; text-align: center;">
              Agendado em<br>
              {{ event.complement.issue.sended_at | moment('DD/MM/YY HH:mm') }}
            </v-list-tile-action-text>
            <v-btn v-else outline small color="orange" @click="appointment(event.actions[0])">
              agendar
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.description" xs12 sm12 md12 lg12 xl12>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>description</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Descrição: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{event.complement.description}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.workOrders" xs12 sm12 md12 lg12 xl12>
        <div v-for="(workOrder, workOrderKey) in event.complement.workOrders" :key="workOrderKey">
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon large>manage_accounts</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Ordem de serviço (código {{workOrder.code}}) </v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 16px;">
                <strong>{{workOrder.type}} </strong>
                <span> | </span>
                <strong>{{workOrder.status}} </strong>
                <span> | </span>
                <strong>{{workOrder.date | moment('DD/MM/YY HH:mm')}} </strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </v-flex>
      <v-flex v-if="event.complement.issueCode" xs12 sm12 md6 lg6 xl6>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>qr_code</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Código da solicitação: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{event.complement.issueCode}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.workOrderId" xs12 sm12 md6 lg6 xl6>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>qr_code</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Código da ordem de serviço: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{event.complement.workOrderId}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.appointmentStart" xs12 sm12 md6 lg6 xl6>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>date_range</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Data Inicial: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{event.complement.appointmentStart | moment('DD/MM/YY HH:mm')}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.appointmentFinish" xs12 sm12 md6 lg6 xl6>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>event_available</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Data Final: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{event.complement.appointmentFinish | moment('DD/MM/YY HH:mm')}}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.troubleTicket" xs12 sm12 md6 lg6 xl6>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>format_shapes</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Tipo de Trouble Ticket: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{ event.complement.troubleTicket.type || ' - - - ' }}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.troubleTicket && event.complement.troubleTicket.problem" xs12 sm12 md6 lg6 xl6>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>bug_report</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Tipo de Problema: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{ event.complement.troubleTicket.problem.type }}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-flex>
      <v-flex v-if="event.complement.troubleTicket && event.complement.troubleTicket.problem" xs12 sm12 md12 lg12 xl12>
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large>description</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Descrição do Problema: </v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 14px;">
              <strong>{{ event.complement.troubleTicket.problem.description || ' - - - ' }}</strong>
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-dialog v-model="troubleTicketDialog" scrollable width="500">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>code</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-toolbar dense>
                  <v-toolbar-title>
                    <v-icon>code</v-icon>
                    <span> Trouble Ticket Request</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="troubleTicketDialog = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <code style="width: 100% !important;">{{event.complement.troubleTicket}}</code>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-list-tile-action>
        </v-list-tile>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  import DiagnosticStatus from './DiagnosticStatus'
  export default {
    name: 'WiEventsOrderVTal',
    data: () => ({
      troubleTicketDialog: false
    }),
    methods: {
      appointment: function (action) {
        this.$WiEditDialog({
          wiConfig: 'vtal-trouble-ticket-appointment',
          data: {
            ...action.content
          },
          redirectOnSubmit: false
        })
      }
    },
    props: {
      event: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      DiagnosticStatus
    }
  }
</script>
<style scoped>
  .event-order-vtal__content {
    text-align: left !important;
    padding: 20px;
  }
</style>