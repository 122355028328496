<template>
  <kanban 
    :title="config.title" 
    :store="config.store"
    :store-stage="config.storeStage"
    :api-get-url="config.getUrl" 
    :api-put-url="config.putUrl"
    field-reference-in-item="business_status_id"
    :message-alert-title="config.alertTitle"
    :message-alert-text="config.alertText"
    :router-click="config.routerClick"
  >
    <template slot="items" slot-scope="item">
      <business-card 
        :item="item.item"
        :config="config"
        :refresh="item.refresh"
        :wi-dialogs="wiDialogs"
        @click:router="goRouter"
        @open:talk="listTalkContactsByPerson"
      />
    </template>
  </kanban>
</template>

<script>
import kanban from '@/default/component/WiKanban/WiKanban'
import talkViewMixin from '@/modules/talk/pages/TalkView/talkViewMixin'
import BusinessCard from './components/BusinessCard.vue'
import { getBusinessConfig } from './config/businessConfig'
import { getWiDialogs } from './config/wiDialogs'

export default {
  name: 'BusinessOfProcess',
  components: {
    kanban,
    BusinessCard
  },
  mixins: [talkViewMixin],
  
  data() {
    return {
      wiDialogs: getWiDialogs(this)
    }
  },

  computed: {
    config() {
      const business = this.$route.params.business
      return getBusinessConfig(business)
    }
  },
  
  methods: {
    goRouter(item) {
      const router = this.config.routerClick
      router.params['id'] = item.id
      this.$router.push(router)
    },
    
    listTalkContactsByPerson(business) {
      this.$WiListDialog({
        wiConfig: 'talk-contact-all',
        mode: 'select',
        callback: (contact) => {
          this.openTalkContact(contact)
        },
        filter: {
          person_id: business.person_id,
          order: 'talk_contacts.last_event_at,desc;talk_contacts.name,asc'
        }
      })
    }
  }
}
</script>