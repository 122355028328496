<template>
  <v-card class="elevation-6 ma-2" style="border-radius: 16px;">
    <!-- Cabeçalho da Tabela -->
    <v-card-title class="table-header pa-6">
      <div class="d-flex align-center">
        <div class="icon-wrapper mr-4">
          <v-icon size="32" :color="title === 'Receitas' ? 'success' : 'error'">
            {{ title === 'Receitas' ? 'trending_up' : 'trending_down' }}
          </v-icon>
        </div>
        <div>
          <h3 class="text-h5 font-weight-bold grey--darken-3">{{ title }}</h3>
          <span class="subtitle-2 grey--text">{{ allCategories.length }} categorias</span>
        </div>
      </div>
    </v-card-title>

    <v-divider></v-divider>

    <!-- Conteúdo condicional -->
    <template v-if="allCategories.length > 0">
      <!-- Tabela original -->
      <v-data-table
        :headers="headers"
        :items="allCategories"
        disable-pagination
        disable-sort
        hide-default-footer
        disable-initial-sort
        hide-actions
        hide-default-header
        hide-header
        dense
        class="custom-table"
      >

        <template v-slot:headers="{ headers }">
          <tr class="row-hover positive-row" style="height: 35px;">
            <td v-for="header in headers" :key="header.value">
              <strong v-html="header.text"></strong>
            </td>
          </tr>
        </template>

        <!-- Clulas personalizadas -->
        <template v-slot:items="{ item }">
          <tr :class="getRowClass(item)">
            <td>
              <div class="d-flex align-right">
                <span>
                  <v-icon
                    v-if="item.icon"
                    :color="item.color"
                    small
                    class="mr-2 hidden-xs-only hidden-sm-only"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <strong>
                  {{ item.name }}
                </strong>
                </span>
              </div>
            </td>
            <td class="text-right">
              <strong class="grey--text">
                {{ item.previous_value | money }}
              </strong>
            </td>
            <td class="text-right">
              <v-icon v-if="parseFloat(item.previous_value) != parseFloat(item.value)" small class="mr-2">
                {{ parseFloat(item.previous_value) > parseFloat(item.value) ? 'arrow_downward' : 'arrow_upward' }}
              </v-icon>
              <v-icon v-else small class="mr-2">
                drag_handle
              </v-icon>
              <strong :class="getValueColor(item.value) + '--text'">
                {{ item.value | money }}
              </strong>
            </td>
          </tr>
        </template>

        <!-- Rodapé com Total -->
        <template v-slot:footer>
          <tr class="total-row">
            <td class="text-h6">
              <strong>Total</strong>
            </td>
            <td class="text-right text-h6">
              <strong :class="getValueColor(previousTotal) + '--text'">
                {{ previousTotal | money }}
              </strong>
            </td>
            <td class="text-right text-h6">
              <strong :class="getValueColor(currentTotal) + '--text'">
                {{ currentTotal | money }}
              </strong>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template v-else>
      <div class="d-flex flex-column align-center justify-center fill-height py-4">
        <img 
          src="/static/icons/monitor/evidence_96.png" 
          width="30px"
          class="mb-3"
        />
        <h3 class="text-subtitle-1 grey--text text--darken-1 text-center">
          Nenhum dado foi encontrado para o período solicitado
        </h3>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'DRETable',
  props: {
    title: String,
    categories: Array,
    previousCategories: Array,
    previousTotal: Number,
    currentTotal: Number,
    currentPeriodStart: String,
    currentPeriodEnd: String,
    previousPeriodStart: String,
    previousPeriodEnd: String
  },
  computed: {
    allCategories() {
      return this.categories.map(category => {
        const previousCategory = this.previousCategories.find(pc => pc.name === category.name)
        return {
          ...category,
          previous_value: previousCategory ? previousCategory.value : 0
        }
      })
    },
    headers() {
      const previousPeriod = this.$moment(this.previousPeriodStart).format('DD/MM/YY') + ' a ' + this.$moment(this.previousPeriodEnd).format('DD/MM/YY')
      const currentPeriod = this.$moment(this.currentPeriodStart).format('DD/MM/YY') + ' a ' + this.$moment(this.currentPeriodEnd).format('DD/MM/YY')
      const headers =  [
        { 
          text: 'Categoria',
          value: 'name',
          class: 'text-subtitle-1 font-weight-bold'
        },
        { 
            text: 'Período anterior<br><span class="grey--text" style="font-size: 12px;">' + previousPeriod + '</span>',
            value: 'previous_value',
            align: 'right',
            class: 'text-subtitle-1 font-weight-bold'
        },
        { 
          text: 'Período atual<br><span class="grey--text" style="font-size: 12px;">' + currentPeriod + '</span>',
          value: 'value',
          align: 'right',
          class: 'text-subtitle-1 font-weight-bold'
        }
      ]   
      console.log(headers)
      return headers
    }
  },
  methods: {
    getValueColor() {
      return this.title === 'Receitas' ? 'success' : 'error'
    },
    getRowClass(item) {
      return {
        'row-hover': true,
        'positive-row': item.value > 0,
        'negative-row': item.value < 0
      }
    }
  }
}
</script>

<style scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.04);
}

.table-header {
  background: linear-gradient(to right, rgba(0,0,0,0.02), transparent);
}

.custom-table {
  background-color: transparent !important;
}

.custom-table >>> .v-data-table__wrapper {
  padding: 0 16px;
}

.row-hover:hover {
  background-color: rgba(0,0,0,0.02) !important;
  transition: background-color 0.2s ease;
}

.positive-row {
  border-left: 3px solid transparent;
}

.negative-row {
  border-left: 3px solid transparent;
}

.positive-row:hover {
  border-left-color: var(--v-success-base);
}

.negative-row:hover {
  border-left-color: var(--v-error-base);
}

.total-row {
  background-color: rgba(0,0,0,0.02);
  border-top: 1px solid rgba(0,0,0,0.12);
}

/* Ajustes para espaçamento e alinhamento */
.v-data-table >>> tbody tr:hover {
  background: transparent !important;
}

.v-data-table >>> td {
  padding: 8px 16px !important;
  height: 48px !important;
}

.row-hover >>> td {
  padding: 8px 10px !important;
  height: 35px !important;
}

.v-data-table >>> th {
  padding: 12px 16px !important;
  font-weight: 600 !important;
}

.empty-state-message {
  color: rgba(0, 0, 0, 0.6);
  padding: 24px;
}
</style> 