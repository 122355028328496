<template>
  <wi-report v-if="config" :title="config.title" :config="config" :api="{url: config.api.url, params: config.api.params}">
    <template slot="statistics" slot-scope="props">
      <v-layout row wrap>
        <v-flex v-for="(statistic, statisticKey) in config.statistics" :key="statisticKey" xs12 sm12 md3 lg3 style="padding: 0px 5px;">
          <v-card :color="statistic.color">
            <v-card-text>
              <strong style="color: white;">{{statistic.title}}</strong>
              <strong style="color: white;" :style="{'font-size': statistic.size || '16px'}">{{props.statistics[statistic.field]}}</strong>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template slot-scope="props">
      <tr>
        <td style="text-align: center;"><strong>{{ props.item.name }}</strong></td>
        <td style="text-align: center;">{{ props.item.first_name }}</td>
        <td style="text-align: center;">{{ props.item.entry_date }}</td>
        <td style="text-align: center;">
          <strong v-if="props.item.count" style="font-size: 18px;">{{ props.item.count }}</strong>
          <strong v-else>0</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.sum_amount">{{ props.item.sum_amount | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.sum_amount">{{ props.item.sum_amount_paid | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <strong v-if="props.item.sum_amount">{{ props.item.sum_amount_unpaid | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                Opções
              </v-btn>
            </template>
            <v-list>
              <!-- <v-list-tile v-if="props.item.count_opened > 0" @click="$router.push({name: 'finance-list', params: {page: 'finance-in'}, query: {where: person.id + ',' + props.item.id}})">
                <v-list-tile-title>Receitas ({{props.item.count_opened}})</v-list-tile-title>
              </v-list-tile>
              <v-list-tile v-if="props.item.count_closed > 0" @click="$router.push({name: 'finance-list', params: {page: 'finance-out'}, query: {where: person.id + ',' + props.item.id}})">
                <v-list-tile-title>Despesas ({{props.item.count_closed}})</v-list-tile-title>
              </v-list-tile> -->
              <v-list-tile @click="$router.push({name: person.name, params: {id: props.item.id}})">
                <v-list-tile-title>Perfil do {{person.title}}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="goToRouterByFinancesByFilter(props.item)">
                <v-list-tile-title>Ver {{in_out.title}}(s)</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>    
        </td>
      </tr>
    </template>
  </wi-report>
</template>
<script>
  import WiReport from '@/default/component/WiReport/WiReport'
  export default {
    name: 'FinanceByPersonReport',
    data () {
      return {
        config: null,
        makeConfigCount: 0
      }
    },
    computed: {
      person: function () {
        var person = {}
        switch (this.$route.meta.person) {
          case 'customer':
            person = {
              id: 'person_id',
              name: 'customer',
              title: 'Cliente',
              first_name: 'Razão Social'
            }
            break
          case 'provider':
            person = {
              id: 'person_id',
              name: 'provider',
              title: 'Fornecedor',
              first_name: 'Razão Social'
            }
            break
          case 'collaborator':
            person = {
              id: 'collaborator_id',
              name: 'collaborator',
              title: 'Colaborador',
              first_name: 'Nome Completo'
            }
            break
        }
        return person
      },
      in_out: function () {
        var inOut = {}
        switch (this.$route.meta.in_out) {
          case 1:
            inOut = {
              name: 'in',
              title: 'Receita',
              titles: 'Receitas',
              action: 'Recebido'
            }
            break
          case 2:
            inOut = {
              name: 'out',
              title: 'Despesa',
              titles: 'Despesas',
              action: 'Pago'
            }
            break
        }
        return inOut
      }
    },
    methods: {
      makeConfig: function () {
        this.config = null
        var config = {
          title: this.in_out.titles + ' por ' + this.person.title,
          api: {
            url: 'finance/report/finance-' + this.in_out.name + '-by-' + this.person.name,
            params: {}
          },
          statistics: [
            {field: 'count_total', title: 'Total de ' + this.in_out.titles + ': ', color: 'orange', size: '16px'},
            {field: 'sum_amount_total', title: 'Valor total: ', color: 'blue', size: '16px'},
            {field: 'sum_amount_paid_total', title: 'Valor pago: ', color: 'green', size: '16px'},
            {field: 'sum_amount_unpaid_total', title: 'Valor não pago: ', color: 'red', size: '16px'}
          ],
          dates: {
            field: {
              text: 'Data de filtro',
              items: [
                {value: 'date_competence', text: 'Data de Competência'},
                {value: 'date_validity', text: 'Data de Validade'},
                {value: 'date_receive', text: 'Data de Recebimento'},
                {value: 'created_at', text: 'Data de Criação'}
              ]
            },
            start: {
              type: 'date',
              text: 'Data Inicial'
            },
            end: {
              type: 'date',
              text: 'Data Final'
            }
          },
          filters: {
            text: 'Filtros',
            fields: []
          },
          fields: [
            { text: this.person.title, value: 'name', align: 'center', sortable: false },
            { text: this.person.first_name, value: 'first_name', align: 'center', sortable: false },
            { text: 'Data Cadastro', value: 'entry_date', align: 'center', sortable: false },
            { text: 'N° de ' + this.in_out.titles, value: 'count', align: 'center' },
            { text: 'Valor', value: 'sum_amount', align: 'center' },
            { text: this.in_out.action, value: 'sum_amount_paid', align: 'center' },
            { text: 'Não ' + this.in_out.action, value: 'sum_amount_unpaid', align: 'center' },
            { sortable: false }
          ]
        }
        if (this.makeConfigCount) {
          setTimeout(() => {
            this.config = config
          }, 500)
        } else {
          this.config = config
        }
        this.makeConfigCount++
      },
      goToRouterByFinancesByFilter: function (person) {
        let route = {
          name: 'finance-list',
          params: {page: 'finance-' + this.in_out.name + '-list'},
          query: {
            'person_id': person.id,
            ...this.$route.query
          }
        }
        this.$router.push(route)
      }
    },
    mounted: function () {
      this.makeConfig()
    },
    watch: {
      '$route': 'makeConfig'
    },
    components: {
      WiReport
    }
  }
</script>
