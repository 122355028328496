<template>
<div style="margin-top: 1px;">
  <WNViewList
    wiConfig="well"
    :toolbar="{
      icon: 'map',
      title: 'Poços por Georreferenciamento',
      color: 'primary',
      refresh: true,
      close: false
    }"
    :serviceParams="{
      limit: 1000,
      whereNotNull: 'address_id',
      fields: 'id,uuid,person_id,business_category_id,business_status_id,address_id,latitude,longitude,location_name',
      with: 'status:id,name,icon,color;category:id,name,icon,color,form_id'
    }"
  >
    <template slot="toolbarActionsBefore">
      <v-btn
        :icon="$vuetify.breakpoint.xsOnly"
        :small="!$vuetify.breakpoint.xsOnly"
        :outline="!$vuetify.breakpoint.xsOnly"
        :color="!$vuetify.breakpoint.xsOnly ? 'white' : ''"
        @click="$WiListDialog({wiConfig: 'well'})"
      >
        <v-icon color="white">list</v-icon>
        <span v-if="!$vuetify.breakpoint.xsOnly">Listar todos os poços</span>
      </v-btn>
    </template>
    <template slot-scope="{ data, refresh, addNewItem }">
      <v-card style="margin: 10px;">
        <div v-if="data && data.data && data.data.length > 0">
          <WiGoogleMaps
            defaultMapMode="hybrid"
            :markers="formatMakers(data.data)"
            @markerClick="markerClick"
          ></WiGoogleMaps>
          <v-dialog
            v-model="selectedWellDialog"
            scrollable
            max-width="75%"
            :fullscreen="$vuetify.breakpoint.xsOnly"
          >
            <v-card v-if="selectedWellDialog && selectedWell && selectedWell.id">
              <v-toolbar dense>
                <v-toolbar-title>
                  <v-icon>waves</v-icon>
                  <span>Detalhes do Poço</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectedWellDialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-content style="height: 500px; overflow-y: scroll;">
                <Well :well-id="selectedWell.id" />
              </v-card-content>
            </v-card>
          </v-dialog>
        </div>
        <WNNotFoundMessage
          v-else
          @onAdd="addNewItem"
          @onRefresh="refresh"
        />
      </v-card>
    </template>
  </WNViewList>

</div>
</template>

<script>

  import Well from '@/modules/wells/pages/Well'
  import WNViewList from '@/default/component/WNViewList'
  import WiGoogleMaps from '@/default/component/WiGoogleMaps'
  import WNNotFoundMessage from '@/default/component/WNNotFoundMessage'
  export default {
    name: 'WellsMap',
    data: () => ({
      wells: [],
      selectedWell: null,
      selectedWellDialog: false
    }),
    methods: {
      formatMakers: function (wells) {
        const wellsFormated = wells.filter(well => !!well?.address?.latitude).map( well => ({
          ...well,
          pinColor: well.category.color.replace('#', ''),
          latitude: well?.latitude || well?.address?.latitude,
          longitude: well?.longitude || well?.address?.longitude,
          title: `${well?.category?.name} de ${well?.person?.name}`,
          label: well?.location_name
        }))
        return wellsFormated
      },
      markerClick: function ({ data }) {
        this.selectedWell = data
        this.selectedWellDialog = true
      },
      refresh: function () {
        this.$emit('refresh')
      }
    },
    props: {
      hosts: {
        default: () => ([])
      }
    },
    components: {
      Well,
      WNViewList,
      WiGoogleMaps,
      WNNotFoundMessage
    }
  }
</script>
<style scoped>
  .wi-view-list-toolbar .v-toolbar {
    z-index: 0 !important;
  }
  .wi-view-list-toolbar .v-progress-linear {
    margin: 0 !important;
  }
</style>