<template>
  <div>
    <div>
      <v-select
      v-model="form[id]"
      :items="content"
      :label="label" 
      :placeholder="getPlaceholder"
      :item-text="show"
      :disabled="disabled"
      item-value="id"
      :loading="loadingSelect"
      :required="required"
      :rules="rules"
      :autocomplete="autocomplete"
      :multiple="multiple"
      ></v-select>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'DBSelect',
    computed: {
      content () {
        if (this.$store.state.data[this.outstore + '_list']) {
          return this.$store.state.data[this.outstore + '_list'].data
        } else {
          return []
        }
      },
      loadingSelect () {
        return this.$store.state.pages[this.outstore].loading_list || false
      },
      getPlaceholder () {
        var p = this.placeholder || this.label
        return p
      }
    },
    beforeDestroy: function () {
      console.log('destruindo o select')
    },
    props: ['instore', 'outstore', 'params', 'form', 'id', 'show', 'label', 'placeholder', 'unselected', 'disabled', 'required', 'rules', 'autocomplete', 'table', 'fields', 'multiple'],
    mounted: function () {
      this.load()
    },
    methods: {
      setSelectedData: function (selected) {
        if (selected && this.instore) {
          this.$store.commit('dataF', {name: this.instore + '_edit', key: this.id, data: selected})
        }
      },
      load: function () {
        var params = this.params ? this.params : {}
        var fields = this.fields == null ? 'id,' + this.show : this.fields ? this.fields : false
        if (!params || !params.limit) {
          params['limit'] = 1000
        }
        // var f = this.fields == null || this.fields ? true : false
        // console.log(f)

        if (fields) {
          params['fields'] = fields
        }

        this.$store.dispatch('getArray', {name: this.outstore, params: params})
      }
    }
  }
</script>
