import FinanceCategoryFields from '@/modules/finance/config/Finance/FinanceCategory/FinanceCategoryFields'

export default {
  id: '1b113a91',
  list: true,
  title: {
    list: 'Categorias de Receitas',
    edit: {
      insert: 'Nova Categoria de Receita',
      update: 'Atualizar Categoria de Receita'
    }
  },
  icon: 'text_rotation_down',
  apiUrl: 'finance/category/in',
  maxWidth: '800px',
  fields: FinanceCategoryFields
}
