export default {
  id: 'bea42b38',
  title: 'Alterar Código/Ticket Externo',
  apiUrl: 'service-desk/ticket',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Informe o Código/Ticket Externo. Para remove-lo basta apagar e clicar em atualizar.', type: 'subheader', edit: true},
    {value: 'external_code', text: 'Código/Ticket Externo', type: 'text', required: true, edit: true}
  ],
  fieldsFixed: [
    {
      value: 'change_ticket_external_code', data: true
    }
  ]
}
