var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-list',{attrs:{"two-line":"","subheader":"","dense":""}},[_c('v-subheader',[_c('strong',[_c('span',[_vm._v("Informações Complementares ")]),(_vm.person.infos)?_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v(" ("+_vm._s(_vm.person.infos.length)+") ")]):_vm._e()]),_c('v-spacer'),_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': _vm.wiConfig.id.update, 'notCheck': _vm.person.id == _vm.user.id}),expression:"{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"}],attrs:{"small":"","outline":"","color":"green"},on:{"click":function($event){return _vm.$WiEditDialog({
                wiConfig: 'info',
                onSubmit: _vm.refresh,
                data: {
                  person_id: _vm.$route.params.id
                },
                redirectOnSubmit: false
              })}}},[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Informação")])],1)],1),(_vm.person.infos.length > 0)?_c('div',_vm._l((_vm.person.infos),function(info,keyInfo){return _c('div',{key:keyInfo + '-info'},[_c('v-divider',{attrs:{"inset":""}}),_c('v-list-tile',{attrs:{"avatar":""}},[_c('v-list-tile-avatar',[_c('v-icon',{staticClass:"orange white--text"},[_vm._v("info")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('strong',[_vm._v(" "+_vm._s(info.information))])]),_c('v-list-tile-sub-title',[_vm._v(" "+_vm._s(info.description)+" ")])],1),_c('v-list-tile-action',[_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': _vm.wiConfig.id.update, 'notCheck': _vm.person.id == _vm.user.id}),expression:"{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"}],attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.$WiEditDialog({wiConfig: 'info', onSubmit: _vm.refresh, data: info})}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("edit")])],1),_c('db-delete',{directives:[{name:"prms",rawName:"v-prms",value:({'id': _vm.wiConfig.id.update, 'notCheck': _vm.person.id == _vm.user.id}),expression:"{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"}],attrs:{"store":"info","id":info.id},on:{"action":_vm.refresh}})],1)],1)],1)}),0):_c('div',[_c('v-divider'),_c('div',{staticStyle:{"margin":"20px 0","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","gap":"20px"}},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("Nenhuma informação no momento")])]),_c('div',{staticClass:"description"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" Adicione informações para complementar as informações do cadastro. ")])]),_c('div',{staticClass:"action"},[_c('v-btn',{attrs:{"small":"","outline":"","color":"green"},on:{"click":function($event){return _vm.$WiEditDialog({
                    wiConfig: 'info',
                    onSubmit: _vm.refresh,
                    data: {
                      person_id: _vm.$route.params.id
                    },
                    redirectOnSubmit: false
                  })}}},[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Adicionar informação")])],1)],1)])],1),_c('v-divider')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }