<template>
  <div>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-card-actions class="action-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              flat
              v-bind="attrs"
              v-on="on"
              @click="openEditDialog"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              flat
              v-bind="attrs"
              v-on="on"
              @click="openEventsDialog"
            >
              <v-icon>timeline</v-icon>
            </v-btn>
          </template>
          <span>Histórico de Eventos</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              flat
              v-bind="attrs"
              v-on="on"
              @click="openFilesDialog"
            >
              <v-icon>attachment</v-icon>
            </v-btn>
          </template>
          <span>Arquivos Anexados</span>
        </v-tooltip>
        
        <v-spacer></v-spacer>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-prms="{ 'id': 'f55197d7' }"
              icon
              flat
              v-bind="attrs"
              v-on="on"
              @click="$emit('open:talk', item)"
            >
              <v-icon color="#1e9e23">message</v-icon>
            </v-btn>
          </template>
          <span>Abrir Chat</span>
        </v-tooltip>
      </v-card-actions>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'BusinessCardActions',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true },
    refresh: { type: Function, required: true },
    wiDialogs: { type: Object, required: true }
  },

  methods: {
    openEditDialog() {
      this.$WiEditDialog({
        wiConfig: `${this.config.store}-open`,
        onSubmit: this.refresh,
        data: this.item
      })
    },

    openEventsDialog() {
      this.$WiDialog(this.wiDialogs.events(this.item))
    },

    openFilesDialog() {
      this.$WiDialog(this.wiDialogs.files(this.item))
    }
  }
}
</script>

<style scoped>
.action-buttons {
  padding: 0px 5px;
}
</style> 