import WellMenu from './WellMenu'
import ContractMenu from './ContractMenu'
import GuaranteeMenu from './GuaranteeMenu'
import ConfigMenu from './ConfigMenu'
import RegisterMenu from './RegisterMenu'
import { PERMISSION_WELL } from '@/default/constants/permissions'

export default [
  {
    id: PERMISSION_WELL,
    icon: 'dashboard',
    title: 'Dashboard de Poços Tubulares',
    route: {
      name: 'dashboard-wells'
    }
  },
  {
    id: PERMISSION_WELL,
    icon: 'map',
    title: 'Poços por Georreferenciamento',
    route: {
      name: 'wells-geolocation'
    }
  },
  WellMenu,
  ContractMenu,
  GuaranteeMenu,
  RegisterMenu,
  ConfigMenu
]