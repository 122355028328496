<template>
  <v-menu v-model="menu" class="talk-menu" right transition="slide-y-transition" lazy>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="talk-menu__btn" icon ripple>
        <v-icon>message</v-icon>
      </v-btn>
    </template>
    <v-list v-if="menu">
      <v-divider></v-divider>
      <div style="color: grey; padding: 5px 10px;">Selecione a categoria:</div>
      <v-divider></v-divider>
      <template v-for="(category, categoryKey) in categories">
        <v-divider
          v-if="categoryKey"
          :key="`category-divider-${categoryKey}`"
        ></v-divider>
        <v-list-tile :key="`category-${categoryKey}`" @click="listMessagesByCategory(category.id)">
          <v-list-tile-avatar>
            <v-icon :color="category.color">{{ category.icon }}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>
            <span>{{ category.name }} </span>
          </v-list-tile-title>
          <v-list-tile-action v-if="category.message_templates_count != 'none'">
            <strong style="width: 40px; text-align: end;">
              <v-icon small color="grey">message</v-icon>
              {{ category.message_templates_count }}
            </strong>
          </v-list-tile-action>
        </v-list-tile>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
  export default {
    name: 'TalkMessageCategoryMenu',
    data: () => ({
      menu: false
    }),
    computed: {
      categories() {
        return this.talk.assets.messageCategories
      }
    },
    methods: {
      listMessagesByCategory: function (categoryId) {
        this.$WiListDialog({
          wiConfig: 'talk-message-template',
          callback: selected => {
            this.talkMessageTemplateId = selected.id
            this.message = selected.text_with_variables || selected.text || ''
            this.$emit('messageSelected', {
              message: this.message,
              messageTemplateId: this.talkMessageTemplateId
            })
          },
          filter: {
            talk_contact_id: this.talk.talk_contact_id,
            talk_channel_id: this.talk.talk_channel_id,
            talk_category_id: categoryId
          }
        })
      }
    },
    props: ['talk']
  }
</script>
<style scoped>
  .talk-menu__btn {
    margin: 0px !important;
  }
</style>