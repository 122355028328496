<template>
  <div>
    <v-toolbar dense>
      <v-btn flat @click="$router.go(-1)" icon>
        <v-icon color="gray darken-2">arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="hidden-xs-only hidden-sm-only">
          <span style="padding: 2px 5px; color: white;" :style="{background: socket.status ? 'green' : 'red'}">
            <v-icon v-if="socket.status" color="white">check_circle_outline</v-icon>
            <v-icon v-else color="white">highlight_off</v-icon>
            <span>{{socket.status ? ' Online ' : ' Offline '}}</span>
          </span>
          <span v-if="dados && dados.nome_grupo" style="padding-left: 10px;">
            {{dados.nome_grupo}}
          </span>
        </span>
        <span class="hidden-lg-only hidden-xl-only" v-if="dados && dados.nome_grupo" style="padding: 2px 5px; color: white;" :style="{background: socket.status ? 'green' : 'red'}">
          <v-icon v-if="socket.status" color="white">check_circle_outline</v-icon>
          <v-icon v-else color="white">highlight_off</v-icon>
          {{dados.nome_grupo}}
        </span>
        <span style="padding-left: 10px;">
          <span v-if="editMode" class="edit_mode">Modo de edição</span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn flat @click="$WiListDialog({wiConfig: 'devices', filter: {network_id: dados.id}})">
          <v-icon>dns</v-icon>
          <span>Dispositivos</span>
        </v-btn>
        <v-btn v-prms="{'id': '3c8cd4ef'}" v-if="alteracoes.length > 0" flat @click="updateApi()"><v-icon color="gray darken-2">save</v-icon><span style="padding-left: 5px;">Salvar {{alteracoes.length}} Modificações</span></v-btn>
        <v-btn v-prms="{'id': '3c8cd4ef'}" v-if="!editMode" flat @click="enableEditMode()"><v-icon color="gray darken-2">edit</v-icon><span style="padding-left: 5px;">Editar</span></v-btn>
        <v-btn v-prms="{'id': '3c8cd4ef'}" v-else flat @click="enableEditMode()"><v-icon color="gray darken-2">close</v-icon> Fechar Edição</v-btn>
        <!-- <v-btn flat @click="organization()">Organizar</v-btn> -->
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile v-prms="{'id': '3c8cd4ef'}" @click="organization()">
              <v-list-tile-title>Organizar</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!-- <v-btn v-if="configMapa.tipo == 'dispositivo'" flat @click="openListModal('dispositivos-nao-grupo', {grupo_id: $route.params.id})">Adicionar</v-btn> -->
      </v-toolbar-items>
      <v-btn v-if="this.configMapa.tipo == 'grupo' && !editMode" flat @click="$router.push({name: 'google-maps-grupos', params: {id: $route.params.id} })" class="hidden-sm-and-down"><v-icon color="gray darken-2">map</v-icon><span class="hidden-sm-and-down">Mapa</span></v-btn>
      <v-btn v-if="this.configMapa.tipo == 'grupo' && !editMode" icon @click="$router.push({name: 'google-maps-grupos', params: {id: $route.params.id} })" class="hidden-md-and-up"><v-icon color="gray darken-2">map</v-icon></v-btn>
      <v-btn @click="getData()" icon><v-icon color="gray darken-2">refresh</v-icon></v-btn>
    </v-toolbar>
    <div style="padding: 10px 10px 0 10px;">
      <v-card id="mynetwork" :style="{height: (innerHeight - 130) + 'px'}" style="background-size: 100%; blue; padding: 5px; width: 100%; background-image: url('static/background/mapas/grid.png'); background-repeat: repeat; background-size: 400px 400px;"></v-card>
    </div>
    <v-snackbar v-model="snackbars.up.active" color="success" bottom right :timeout="20000">
      {{ snackbars.up.text }}
      <v-btn color="black" flat @click="snackbars.up.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbars.down.active" color="error" bottom right :timeout="20000">
      {{ snackbars.down.text }}
      <v-btn color="black" flat @click="snackbars.down.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbars.ticket.active" color="orange" bottom right :timeout="20000">
      {{ snackbars.ticket.text }}
      <v-btn color="black" flat @click="snackbars.ticket.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbars.device.active" color="blue" bottom right :timeout="20000">
      {{ snackbars.device.text }}
      <v-btn color="black" flat @click="snackbars.device.active = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
  import vis from 'vis'
  // eslint-disable-next-line
  import {Api} from '@/default/service/Api'
  // eslint-disable-next-line
  import {callApi} from '@/default/service/Api'
  export default {
    data () {
      return {
        socket: {
          socket: null,
          status: window.Echo.connector.socket.connected
        },
        intervals: {
          update30s: null,
          update2_5m: null,
          update10m: null
        },
        snackbars: {
          up: {
            active: false,
            text: ''
          },
          down: {
            active: false,
            text: ''
          },
          ticket: {
            active: false,
            text: ''
          },
          device: {
            active: false,
            text: ''
          }
        },
        networkId: null,
        apiError: false,
        alteracoes: [],
        detalhesMapa: {
          nome: null
        },
        innerHeight: window.innerHeight,
        interval: null,
        intervalRefreshPage: null,
        configMapa: this.$route.meta.config,
        dados: {},
        editModal: false,
        listModal: false,
        grupo_dados_id: null,
        modalForm: {},
        editModalStore: 'configuracoes',
        listModalStore: 'devices',
        listModalParams: null,
        modalApagarAoIniciar: false,
        editMode: false,
        dispositivos: [],
        conexoes: [],
        network: null,
        options: {
          locale: 'pt-br',
          clickToUse: false,
          physics: {
            enabled: false
          },
          configure: {
            enabled: false
          },
          interaction: {
            dragNodes: false,
            dragView: true,
            hideEdgesOnDrag: false,
            hideNodesOnDrag: false,
            hover: true,
            hoverConnectedEdges: true,
            keyboard: {
              enabled: true,
              speed: {x: 10, y: 10, zoom: 0.02},
              bindToWindow: true
            },
            multiselect: true,
            navigationButtons: true,
            selectable: true,
            selectConnectedEdges: true,
            tooltipDelay: 300,
            zoomView: true
          },
          nodes: {
            borderWidth: 0,
            size: 20,
            shadow: true,
            color: {
              border: '#FFF',
              background: '#FFF',
              highlight: '#848484',
              hover: {
                border: '#2B7CE9',
                background: '#D2E5FF'
              }
            },
            shapeProperties: {
              useBorderWithImage: false,
              interpolation: false,
              borderRadius: 20
            },
            font: {size: 14, color: 'black', face: 'roboto', strokeWidth: 3, strokeColor: 'white'}
          },
          edges: {
            shadow: true,
            color: {
              color: '#000000',
              highlight: '#848484',
              hover: 'gray',
              inherit: 'from',
              opacity: 1.0
            },
            smooth: {
              enabled: false
            },
            arrows: {
              to: {enabled: false, scaleFactor: 1, type: 'arrow'},
              middle: {enabled: false, scaleFactor: 1, type: 'arrow'},
              from: {enabled: false, scaleFactor: 1, type: 'arrow'}
            },
            arrowStrikethrough: true
          },
          manipulation: {
            enabled: false,
            initiallyActive: true,
            addEdge: (data, callback) => {
              console.log(callback)
              this.$WiEditDialog({wiConfig: this.configMapa.edgeEditModal, onSubmit: this.getData, data: {to: data.to, from: data.from}})
            },
            editEdge: (data, callback) => {
              console.log(callback)
              data['desc_conexao'] = data.label
              this.$WiEditDialog({wiConfig: this.configMapa.edgeEditModal, onSubmit: this.getData, data: data})
            },
            deleteEdge: (data, callback) => {
              console.log(callback)
              this.modalApagarAoIniciar = true
              this.$WiEditDialog({wiConfig: this.configMapa.edgeEditModal, onSubmit: this.getData, data: {id: data.edges[0]}})
            },
            addNode: (data, callback) => {
              console.log(callback)
              if (this.configMapa.tipo === 'dispositivo') {
                this.$WiEditDialog({wiConfig: 'devices', onSubmit: this.getData, data: { grupo_dados_id: parseInt(this.$route.params.id), x: data.x, y: data.y }})
              }
              if (this.configMapa.tipo === 'grupo') {
                alert('Opção em manutenção.\nEla estará disponível em breve juntamente com novas funcionalidades.')
                // this.$WiEditDialog({wiConfig: 'grupos', onSubmit: this.getData, data: { grupo_dados_id: parseInt(this.$route.params.id) }})
              }
            },
            editNode: (data, callback) => {
              console.log(callback)
              if (this.configMapa.tipo === 'dispositivo') {
                this.$WiEditDialog({wiConfig: 'devices', onSubmit: this.getData, data: data})
              }
              if (this.configMapa.tipo === 'grupo') {
                alert('Opção em manutenção.\nEla estará disponível em breve juntamente com novas funcionalidades.')
                // this.$WiEditDialog({wiConfig: 'grupos', onSubmit: this.getData, data: data})
              }
            },
            deleteNode: (data, callback) => {
              console.log(callback)
              this.removerDispositivo(data.nodes[0])
              // this.modalApagarAoIniciar = true
              // this.$WiEditDialog({wiConfig: 'devices', onSubmit: this.getData, data: {id: data.nodes[0]}})
            }
          }
        }
      }
    },
    created: function () {
      this.networkConnect()
      this.getData()
      if (this.$route.query && this.$route.query.grupo) {
        this.grupo_dados_id = this.$route.query.grupo
      }
      this.startIntervals()
    },
    beforeDestroy: function () {
      this.stopIntervals()
      this.unsubscribeOnNetworkStatusChange()
    },
    watch: {
      '$route': 'changeRouter'
    },
    computed: {
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      auth () {
        return this.$store.state.auth || {}
      },
      isConnected () {
        var status = window.Echo.connector.socket.connected
        console.log(status)
        return status
      }
    },
    methods: {
      networkConnect: function () {
        window.Echo.connector.socket.on('connect', () => {
          this.socket.status = true
          console.log('Network Connect!')
          clearInterval(this.intervals.update30s)
          this.getData(true)
        })
        window.Echo.connector.socket.on('disconnect', () => {
          this.socket.status = false
          this.socketOnDisconect()
          console.log('Network Disconnect!')
        })
      },
      subscribeOnNetworkStatusChange: function () {
        window.Echo.private(this.$Auth.account().id + '.network.' + this.networkId)
        .listen('.status.changed', (response) => {
          console.log(response)
          this.getData(true)
          if (response.up) {
            this.snackbars.up.active = false
            this.snackbars.up.text = response.up
            this.snackbars.up.active = true
            // this.$makeSystemNotification('Dispositivos disponíveis', response.up, 'https://master.view.wises.tech/static/network/grupo/grupo-100.png')
          }
          if (response.down) {
            this.snackbars.down.active = false
            this.snackbars.down.text = response.down
            this.snackbars.down.active = true
            this.$makeSystemNotification('Dispositivos indisponíveis', response.down, 'https://master.view.wises.tech/static/network/grupo/grupo-020.png')
          }
          if (response.ticket) {
            this.snackbars.ticket.active = false
            this.snackbars.ticket.text = response.ticket
            this.snackbars.ticket.active = true
            // this.$makeSystemNotification('Chamado vinculado', response.ticket, 'https://master.view.wises.tech/static/systems/connect_view.png')
          }
          if (response.device) {
            this.snackbars.device.active = false
            this.snackbars.device.text = response.device
            this.snackbars.device.active = true
            // this.$makeSystemNotification('Dispositivo alterado', response.device, 'https://master.view.wises.tech/static/systems/connect_view.png')
          }
          clearInterval(this.intervals.update30s)
        })
      },
      unsubscribeOnNetworkStatusChange: function () {
        window.Echo.leave(this.$Auth.account().id + '.network.' + this.networkId)
      },
      socketOnDisconect: function () {
        this.startIntervals()
        // this.$WiMakeError({error: {}, id: 107, title: 'Parece que você não está conectado ao servidor.', message: 'Não se preocupe, você consinuará sendo atualizado, somente não receberá notificações.'})
      },
      changeRouter: function (dados) {
        this.loading('Carregando...', 'Por favor aguarde.')
        this.getData(dados)
      },
      startIntervals: function () {
        this.stopIntervals()
        console.log('start interval')
        this.intervals.update2_5m = setInterval(() => {
          this.getData(true)
          if (this.$route.query && this.$route.query.grupo) {
            this.grupo_dados_id = this.$route.query.grupo
          }
        }, 150000)
        this.intervals.update10m = setInterval(() => {
          location.reload()
        }, 600000)
        if (!this.socket.status) {
          this.intervals.update30s = setInterval(() => {
            this.getData(true)
            if (this.$route.query && this.$route.query.grupo) {
              this.grupo_dados_id = this.$route.query.grupo
            }
          }, 30000)
        }
      },
      stopIntervals: function () {
        clearInterval(this.intervals.update2_5m)
        clearInterval(this.intervals.update10m)
        clearInterval(this.intervals.update30s)
        console.log('intervals stoped')
      },
      loading: function (title, text) {
        this.$swal({
          title: title || 'Carregando...',
          text: text || 'Aguarde até que a ação seja finalizada.',
          onOpen: () => {
            this.$swal.showLoading()
          }
        })
      },
      getData: function (loading) {
        this.configMapa = this.$route.meta.config
        if (!loading) {
          this.loading('Carregando...', 'Por favor aguarde.')
        }
        Api.get({url: this.url + this.configMapa.getUrl + this.$route.params.id + this.configMapa.getUrlParams})
        .then((data) => {
          if (this.networkId !== data.data.id) {
            this.unsubscribeOnNetworkStatusChange()
            this.networkId = data.data.id
            this.subscribeOnNetworkStatusChange()
          }
          this.apiError = false
          if (this.configMapa.tipo === 'grupo') {
            this.dispositivos = []
            data.data[this.configMapa.campoDispositivoName].filter((item) => {
              item['image'] = 'static/network/grupo/grupo-000.png'
              if (item.status && item.status.cod_status) {
                item['image'] = 'static/network/grupo/grupo' + item.status.cod_status.replace('#', '-') + '.png'
              }
              item['dashes'] = true
              item['title'] = '<div><strong>Nome: </strong> ' + item.nome_grupo + ' <br><strong>Status: </strong>  <span style="color: ' + item.status.cor_status + ';">' + item.status.nome_status + '</span>  <br><strong>Observações: </strong> ' + item.obs_grupo ? item.obs_grupo : 'asd' + ' <br></div>'
              this.dispositivos.push(item)
            })
          }
          if (this.configMapa.tipo === 'dispositivo') {
            this.dispositivos = data.data[this.configMapa.campoDispositivoName]
            this.dispositivos.filter((item, key) => {
              this.dispositivos[key]['image'] = 'static/network/disp/' + item.equipamento_fabricante_id + item.status.cod_status.replace('#', '-') + '.png'
              this.dispositivos[key]['title'] = '<div><strong>Nome: </strong> ' + item.nome_disp + ' <br><strong>Status: </strong>  <span style="color: ' + item.status.cor_status + ';">' + item.status.nome_status + '</span>  <br><strong>Observações: </strong> ' + item.obs_grupo || '' + ' <br></div>'
            })
          }
          this.dados = data.data
          this.dados['dispositivos'] = data.data[this.configMapa.campoDispositivoName]
          this.conexoes = data.data.conexoes
          this.loadMapa()
          this.$swal.close()
        })
        .catch((error) => {
          if (!this.apiError) {
            this.$WiMakeError({error: error, id: 107, title: 'Oops... Erro ao buscar redes e dispositivos.'})
          }
          this.apiError = true
        })
      },
      insertData: function (url, data, action) {
        this.loading('Salvando...', 'Por favor aguarde.')
        Api.post({url: this.url + url}, data)
        .then((response) => {
          this.apiError = false
          if (action) {
            action(response)
          } else {
            this.getData()
          }
          this.$swal.close()
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 207, title: 'Oops... Erro ao salvar.'})
        })
      },
      updateData: function (url, id, data, action) {
        console.log(url)
        this.loading('Salvando...', 'Por favor aguarde.')
        Api.put({url: this.url + url + '/' + id}, data)
        .then((response) => {
          this.apiError = false
          if (action) {
            action(response)
          } else {
            this.getData()
          }
          this.$swal.close()
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 307, title: 'Oops... Erro ao atualizar.'})
        })
      },
      removerDispositivo: function (dispositivo) {
        console.log(dispositivo)
        console.log(this.dispositivos)
        var disp = this.dispositivos.filter((disp) => {
          if (disp.id === dispositivo) { return disp }
        })
        console.log(disp[0])
        if (dispositivo && disp[0]) {
          this.$swal({
            title: 'Deseja apagar este dispositivos para sempre?',
            text: 'Esta ação não poderá ser desfeita manualmente!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apagar para sempre!',
            cancelButtonText: 'Agora não'
          })
          .then((response) => {
            if (response.value) {
              this.loading('Apagando...', 'Por favor aguarde.')
              Api.delete({url: this.url + 'disp/dados/' + disp[0].id})
              .then((response) => {
                this.apiError = false
                console.log(response)
                this.$swal.close()
                this.getData()
              })
              .catch((error) => {
                this.$WiMakeError({error: error, id: 407, title: 'Oops... Erro ao apagar.'})
              })
            }
          })
        }
      },
      loadMapa: function () {
        var container = document.getElementById('mynetwork')
        var data = {
          nodes: this.dispositivos,
          edges: this.conexoes
        }
        var options = this.options
        console.log(container);
        this.network = new vis.Network(container, data, options)
        var position = {}
        this.network.on('dragging', function (params) {
          position = params.pointer.canvas
        })
        this.network.on('dragEnd', (params) => {
          if (params.nodes.length > 0) {
            var id = null
            this.dispositivos.filter((data) => {
              if (data.id === params.nodes[0]) {
                id = data[this.configMapa.updateIDName]
              }
            })
            this.alteracoes.push({id: id, x: position.x, y: position.y})
            console.log(this.alteracoes)
            // this.updateData(this.configMapa.updateUrl, id, position)
          }
        })
        this.network.on('doubleClick', (data) => {
          var selecionado = {}
          if (this.configMapa.tipo === 'dispositivo') {
            console.log(data)
            selecionado = this.dispositivos.filter((disp) => {
              if (disp.id === data.nodes[0]) { return disp }
            })
            console.log(selecionado[0])
            this.$WiEditDialog({wiConfig: 'devices', onSubmit: this.getData, data: selecionado[0]})
          }
          if (this.configMapa.tipo === 'grupo') {
            selecionado = this.dispositivos.filter((disp) => {
              if (disp.id === data.nodes[0]) { return disp }
            })[0]
            if (selecionado.sub_grupos > 0) {
              this.$router.push({ name: 'grupo-mapa-grupos', params: {id: selecionado.id} })
            } else {
              this.$router.push({ name: 'grupo-mapa-dispositivos', params: {id: selecionado.id} })
            }
          }
        })
      },
      enableEditMode: function () {
        if (this.$vuetify.breakpoint.name !== 'xs') {
          if (this.editMode) {
            this.options.manipulation.enabled = false
            this.options.interaction.dragNodes = false
            this.editMode = false
            this.loadMapa()
            this.startIntervals()
          } else {
            this.options.manipulation.enabled = true
            this.options.interaction.dragNodes = true
            this.editMode = true
            this.loadMapa()
            this.stopIntervals()
          }
        }
      },
      adicionarTeste: function () {
        this.selectInsertModal = true
      },
      organization: function () {
        this.$swal({
          title: 'Quantos dispositivo por linha?',
          input: 'number',
          inputValue: 10,
          inputAttributes: {
            autocapitalize: 'off',
            inputAttributes: {
              min: 5,
              max: 30
            }
          },
          showCancelButton: true,
          confirmButtonText: 'Organizar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            var porX = 110 * (login - 1)
            var porY = 0
            var count = 0
            this.dados.dispositivos.filter((dispositivo, index) => {
              this.dados.dispositivos[index].x = count
              this.dados.dispositivos[index].y = porY
              this.dados.dispositivos[index].id = dispositivo[this.configMapa.updateIDName]
              if (count < porX) { count = count + 210 } else { count = 0; porY = porY + 80 }
            })
            this.loadMapa()
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: 'Deseja salvar esta alteração?',
              text: 'Essa ação só poderá ser desfeita manualmente!',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim, Salvar!',
              cancelButtonText: 'Agora não'
            })
            .then((response) => {
              if (response.value) {
                console.log(this.dispositivos)
                console.log(this.dados.dispositivos)
                this.updateData(this.configMapa.updateUrl, 'multiple', this.dados.dispositivos)
              }
            })
          }
        })
      },
      updateApi: function () {
        if (this.alteracoes.length > 0) {
          callApi.put({
            uri: this.configMapa.updateUrl,
            id: 'multiple',
            data: this.alteracoes,
            sucess: () => {
              this.alteracoes = []
              this.enableEditMode()
              this.loading('Carregando...', 'Por favor aguarde.')
              this.getData(true)
            },
            msgLoad: true,
            msgSucess: true,
            msgError: true
          })
        }
      }
    }
  }
</script>
<style>
/* @import './vis/dist/vis.css'; */

.edit_mode {
  border-style: solid; border-width: 1px; border-color: orange; border-radius: 10px; color: gray; background: yellow; padding: 1px 10px 1px 5px;
}

canvas {
  background-position: center; 
  /*background-image: url('static/background/mapas/torre-master.jpg'); */
  background-size: 100%;
}

</style>
