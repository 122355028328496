<template>
  <v-layout row>
    <v-flex class="form-reply-list__row" xs12 sm12 md6 lg6 xl6>
      <v-card class="elevation-10">
        <v-list subheader one-line dense :style="{background: $wiDark ? '' : 'white'}">
          <v-list-tile avatar>
            <v-list-tile-avatar style="padding-right: 10px;">
              <v-avatar>
                <v-img src="static/icons/user_icon_1.png" style="max-width: 40px; max-height: 40px;" alt="avatar"/>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Criador: </v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 16px;">
                <strong>{{reply.person.name}}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex class="form-reply-list__row" xs12 sm12 md6 lg6 xl6>
      <v-card class="elevation-10">
        <v-list subheader one-line dense :style="{background: $wiDark ? '' : 'white'}">
          <v-list-tile avatar>
            <v-list-tile-avatar style="padding-right: 10px;">
              <v-avatar>
                <v-icon large :color="reply.status.color">{{reply.status.icon}}</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Status: </v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 16px;">
                <strong>{{reply.status.title}}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex class="form-reply-list__row" xs12 sm12 md6 lg6 xl6>
      <v-card class="elevation-10">
        <v-list subheader one-line dense :style="{background: $wiDark ? '' : 'white'}">
          <v-list-tile avatar>
            <v-list-tile-avatar style="padding-right: 10px;">
              <v-avatar>
                <v-icon large color="green">query_builder</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Data: </v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 16px;">
                <strong>{{ reply.date_time | moment('DD/MM/YY HH:mm') }}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    name: 'FormReplyListHeader',
    props: {
      reply: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style scoped>
  .form-reply-list__row {
    padding: 5px;
  }
</style>