<template>
  <v-dialog v-model="active" scrollable max-width="580px">
    <v-card>
      <v-card-title style="padding: 0 15px 0 15px;">
        <strong>Histórico de equipes responsáveis</strong>
        <v-spacer></v-spacer>
        <v-btn flat icon color="green" @click="info.refresh()">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px; padding: 0;">
        <wi-view v-if="active" notShowToolbar="true" :apiUrl="'service-desk/ticket/team'" :apiParams="'?where=ticket_id,' + ticket.id + '&order=created_at,desc'" notGetID="true">
          <template slot-scope="dados"><span style="display: none;">{{info = dados}}</span>
            <v-list two-line>
              <template v-for="(team, teamKey) in dados.content.data">
                <v-list-tile avatar :key="'tile-' + teamKey">
                  <v-list-tile-avatar>
                    <v-avatar>
                      <img src="static/icons/monitor/management_96.png" alt="Equipe Responsável">
                    </v-avatar>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <strong v-text="team.team.name"></strong>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <span v-if="team.start_date">
                        <span>Início: </span>
                        <strong> {{team.start_date | moment('DD/MM/YY HH:mm:ss')}}</strong>
                      </span>
                      <span v-if="team.stop_date">
                        <span> Término: </span>
                        <strong>{{team.stop_date | moment('DD/MM/YY HH:mm:ss')}}</strong>
                      </span>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <span>Tempo como responsável: </span>
                      <strong v-text="team.duration_time_hours"></strong>
                      <span> Tempo de Trabalho: </span>
                      <strong v-text="team.working_time_hours"></strong>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider :key="teamKey"></v-divider>
              </template>
            </v-list>
          </template>
        </wi-view>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" flat @click.native="$emit('close')">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'TicketTeamsDialog',
    data () {
      return {
        info: null
      }
    },
    props: ['ticket', 'active']
  }
</script>