<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>
<script>
  export default {
    name: 'App'
  }
</script>

<style>
@media print {
  div.wi-print-footer__template {
    position: fixed;
    bottom: 0;
  }
}
</style>
