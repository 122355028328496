<template>
  <div>
    <div v-if="isCustomer || isProspect">
      <div v-if="budgets.length > 0">
        <v-card flat color="transparent">
          <v-card-title class="py-1 px-0">
            <v-icon>attach_money</v-icon>
            <span style="padding-left: 10px;">
              <strong v-t="'modules.talks.pages.talkView.talk.contact.budgets.title'"></strong>
            </span>
            <v-spacer></v-spacer>
            <v-menu bottom offset-y lazy>
              <template v-slot:activator="{ on }">
                <v-btn outline small color="green" class="my-0 mx-0" v-on="on">
                  <v-icon>add</v-icon>
                  <span v-t="'modules.talks.pages.talkView.talk.contact.budgets.new'"></span>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile @click="addBudgetSimple()">
                  <v-list-tile-avatar>
                    <v-icon>add</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.quickAdd'"></v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile @click="addBudgetByTemplate()">
                  <v-list-tile-avatar>
                    <v-icon>web</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.fromTemplate'"></v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile @click="addBudget()">
                  <v-list-tile-avatar>
                    <v-icon>add</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.fromScratch'"></v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0" style="display: flex; flex-direction: column; gap: 5px;">
            <v-menu v-for="budget in budgets" :key="budget.id" bottom offset-y lazy>
              <template v-slot:activator="{ on }">
                <v-card v-on="on" style="cursor: pointer; padding: 2px 0;">
                  <v-list-tile class="talk-contact-budgets__list-tile">
                    <v-list-tile-content>
                      <v-list-tile-sub-title style="display: flex; justify-content: space-between;">
                        <span class="mr-1">#{{ budget.id }} </span>
                        <span :style="{ color: budget.status.color }">
                          {{ budget.status.name }} 
                        </span>
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title style="display: flex; justify-content: space-between;">
                        <strong :style="{ color: budget.category.color }">
                          {{ budget.category.name }}
                        </strong>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider v-if="budget.percentage_collaborator"></v-divider>
                  <div v-if="budget.percentage_collaborator" class="talk-contact-budgets__list-tile">
                    <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px 5px 0 5px;">
                      <span v-t="'modules.talks.pages.talkView.talk.contact.budgets.labels.commission'"></span>
                      <strong style="font-size: 14px;">
                        <span>
                          {{ budget.percentage_collaborator | float }}%
                        </span>
                        <span v-if="budget.comission_collaborator" class="grey--text">
                          ({{ budget.comission_collaborator | money }})
                        </span>
                      </strong>
                    </div>
                  </div>
                  <v-divider v-if="budget.information"></v-divider>
                  <div v-if="budget.information" class="talk-contact-budgets__list-tile">
                    <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px 5px 0 5px;">
                      <span v-t="'modules.talks.pages.talkView.talk.contact.budgets.labels.payment'"></span>
                      <strong style="font-size: 14px;">
                        {{ budget.information }}
                      </strong>
                    </div>
                  </div>
                  <v-divider v-if="budget.external_code"></v-divider>
                  <div v-if="budget.external_code" class="talk-contact-budgets__list-tile">
                    <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px 5px 0 5px;">
                      <span v-t="'modules.talks.pages.talkView.talk.contact.budgets.labels.externalCode'"></span>
                      <strong style="font-size: 14px;">
                        {{ budget.external_code }}
                      </strong>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div class="talk-contact-budgets__list-tile">
                    <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px 5px 0 5px;">
                      <span>Valor total: </span>
                      <strong style="font-size: 16px;">
                        {{ budget.total_liquid | money }}
                      </strong>
                    </div>
                  </div>
                </v-card>
              </template>
              <v-list>
                <v-list-tile @click="editBudgetSimple(budget)">
                  <v-list-tile-avatar>
                    <v-icon>edit</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.quickEdit'"></v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile link :to="{name: 'budget', params: { id: budget.id }}">
                  <v-list-tile-avatar>
                    <v-icon>open_in_new</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.details'"></v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile @click="showBudgetEvents(budget)">
                  <v-list-tile-avatar>
                    <v-icon>message</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.notes'"></v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile @click="showBudgetFiles(budget)">
                  <v-list-tile-avatar>
                    <v-icon>attachment</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.files'"></v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-card-text>
        </v-card>
      </div>
      <v-card-text v-else>
        <h3 v-t="'modules.talks.pages.talkView.talk.contact.budgets.noBudgets.title'"></h3>
        <img src="static/icons/monitor/new_copy_96.png" alt="">
        <p v-t="'modules.talks.pages.talkView.talk.contact.budgets.noBudgets.subtitle'"></p>
        <v-menu lazy>
          <template v-slot:activator="{ on }">
            <v-btn outline small color="green" v-on="on">
              <v-icon>add</v-icon>
              <span v-t="'modules.talks.pages.talkView.talk.contact.budgets.noBudgets.button'"></span>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile @click="addBudgetSimple()">
              <v-list-tile-avatar>
                <v-icon>add</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.quickAdd'"></v-list-tile-title>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile @click="addBudgetByTemplate()">
              <v-list-tile-avatar>
                <v-icon>web</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.fromTemplate'"></v-list-tile-title>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile @click="addBudget()">
              <v-list-tile-avatar>
                <v-icon>add</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title v-t="'modules.talks.pages.talkView.talk.contact.budgets.buttons.fromScratch'"></v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-card-text>
    </div>
  </div>       
</template>
<script>
  export default {
    name: 'TalkContactBudgets',
    data () {
      return {
        dialog: false,
        profileImageDialog: false,
        memberSearch: ''
      }
    },
    computed: {
      person () {
        const person = this.contact?.person?.person || this.contact?.person
        return person
      },
      isCustomer () {
        return this.person &&
          this.person.profiles.findIndex(p => p.name == 'customer') > -1
      },
      isProspect () {
        return this.person &&
          this.person?.profiles?.findIndex(p => p.name == 'prospect') > -1
      },
      hasBudgets () {
        return this.talk?.assets?.budgets?.length > 0
      },
      budgets () {
        return this.talk?.assets?.budgets || []
      }
    },
    methods: {
      addBudget: function () {
        let data = {
            person_id: this.person.id,
            person_type: this.person.type,
            person: this.person
          }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            collaborator_id: this.person.id,
            person_type: this.person.type,
            collaborator: this.person
          }
        }
        this.$WiEditDialog({
          wiConfig: 'budget-open',
          onSubmit: () => {
            this.refresh()
          },
          data,
          redirectOnSubmit: false
        })
      },
      addBudgetSimple: function () {
        let data = {
            person_id: this.person.id,
            person_type: this.person.type,
            person: this.person,
            date_start: this.$moment().format('YYYY-MM-DD'),
            date_end: this.$moment().format('YYYY-MM-DD'),
            // date_validity = now + 15 days
            date_validity: this.$moment().add(15, 'days').format('YYYY-MM-DD')
          }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            collaborator_id: this.person.id,
            person_type: this.person.type,
            collaborator: this.person
          }
        }
        this.$WiEditDialog({
          wiConfig: 'budget-simple',
          onSubmit: () => {
            this.refresh()
          },
          data,
          redirectOnSubmit: false
        })
      },
      addBudgetByTemplate: function () {
        let data = {
            person_id: this.person.id,
            person_type: this.person.type,
            person: this.person
          }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            collaborator_id: this.person.id,
            person_type: this.person.type,
            collaborator: this.person
          }
        }
        this.$WiListDialog({
          wiConfig: 'budget-template',
          onSubmit: () => {
            this.refresh()
          },
          data,
          redirectOnSubmit: false,
          wiEditData: data
        })
      },
      showBudgetEvents: function (budget) {
        this.$WiDialog({
            title: 'Anotações/Eventos ',
            icon: 'message',
            component: {
              content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
              props: {
                index: 'budget-event',
                api: {
                  url: 'sales/sale/event',
                  params: '?order=id,desc&where=business_id,' + budget.id
                }
              }
            },
            button: {
              icon: 'add',
              text: 'Adicionar Notasss',
              action: () => {
                this.$WiEditDialog({
                  wiConfig: 'budget-event',
                  onSubmit: () => {
                    this.$WiView('budget-event').refresh()
                  },
                  data: {business_id: budget.id}
                })
              }
            }
          })
      },
      showBudgetFiles: function (budget) {
        this.$WiDialog({
          title: 'Arquivos/Anexos',
          icon: 'attachment',
          component: {
            content: () => import('@/default/component/WiFile/WiFileList'),
            props: {
              index: 'budget-fileList',
              api: {
                url: 'sales/sale/file',
                params: '?order=id,desc&where=business_id,' + budget.id
              }
            }
          },
          button: {
            icon: 'add',
            text: 'Adicionar Arquivo',
            action: () => {
              this.$WiDialog({
                title: 'Arquivos/Anexos ',
                icon: 'attachment',
                component: {
                  content: () => import('@/default/component/WiFile/WiFile'),
                  props: {
                    business_id: budget.id
                  }
                },
                onClose: () => {
                  this.$WiView('budget-fileList').refresh()
                }
              })
            }
          }
        })
      },
      editBudgetSimple: function (budget) {
        const {
          id,
          business_status_id,
          business_category_id,
          date_start,
          date_end,
          date_validity,
          amount_additional,
          observation,
          percentage_collaborator,
          information,
          external_code,
          person_id,
          advanced_view,
          business_agreement_id,
          amount_installment,
          quantity_installment,
          amount_discount
        } = budget
        this.$WiEditDialog({
          wiConfig: 'budget-simple',
          onSubmit: () => {
            this.refresh()
          },
          data: {
            id,
            business_status_id,
            business_category_id,
            date_start,
            date_end,
            date_validity,
            amount_additional,
            observation,
            percentage_collaborator,
            information,
            external_code,
            person_id,
            advanced_view,
            business_agreement_id,
            amount_installment,
            quantity_installment,
            amount_discount
          },
          redirectOnSubmit: false
        })
      },
      viewBudget: function (budget) {
        this.$WiDialog({
          title: 'Detalhes do Orçamento/Oferta',
          icon: 'attachment',
          component: {
            content: () => import('@/default/component/WiFile/WiFileList'),
            props: {
              index: 'budget-fileList',
              api: {
                url: 'sales/sale/file',
                params: '?order=id,desc&where=business_id,' + budget.id
              }
            }
          },
          button: {
            icon: 'add',
            text: 'Adicionar Arquivo',
            action: () => {
              this.$WiDialog({
                title: 'Arquivos/Anexos ',
                icon: 'attachment',
                component: {
                  content: () => import('@/default/component/WiFile/WiFile'),
                  props: {
                    business_id: budget.id
                  }
                },
                onClose: () => {
                  this.$WiView('budget-fileList').refresh()
                }
              })
            }
          }
        })
      }
    },
    props: ['talk', 'contact', 'refresh']
  }
</script>
<style scoped>
  .talk-contact-budgets__list-tile {
    padding: 0 0px !important;
  }
  .talk-contact-budgets__list-tile >>> .v-list__tile {
    padding: 0 5px !important;
  }
</style>