<template>
  <v-list subheader two-line dense :style="{background: dark ? '#212121' : '#FAFAFA'}">
    <v-list-tile>
      <v-list-tile-avatar v-if="dados.content.icone_categoria">
        <v-avatar>
          <v-icon large :color="dados.content.cor_categoria || '#000'">
            {{dados.content.icone_categoria}}
          </v-icon>
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title style="color:#1C8ADB; font-size: 18px;">
          <strong>
            <v-icon style="color:#1C8ADB">person</v-icon>
            <span>{{dados.content.nome_pessoa}}</span>
          </strong>
        </v-list-tile-title>
        <v-list-tile-sub-title v-prms="{id: 'a08f73b0', mode: 'disabled'}" @click="changeTicketCategory(dados)" style="cursor: pointer;">
          <v-tooltip bottom>
            <label slot="activator" style="font-size: 14px; cursor: pointer;">
              <span v-if="dados.content.nome_categoria_grupos" style="padding: 2px 10px 2px 10px; border-radius: 5px; color: white;" :style="{'background': dados.content.cor_categoria}">
                <span>{{dados.content.nome_categoria_grupos}}</span>
                <v-icon small v-prms="{id: 'a08f73b0'}">edit</v-icon>
              </span>
              <span v-else style="padding: 2px 10px 2px 10px; border-radius: 5px; color:white ; background-color: #000">
                <span>Não Definido</span>
                <v-icon>edit</v-icon>
              </span>
              <span v-if="dados.content.obs_categoria" style="padding-left: 3px;">
                <br><span>{{dados.content.obs_categoria}}</span>
              </span>
            </label>
            <span>Alterar Tipo de Ocorrência</span>
          </v-tooltip>
        </v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>
<script>
  export default {
    name: 'TicketHeaderPerson',
    data () {
      return {}
    },
    methods: {
      changeTicketCategory: function (dados) {
        if (dados.content.chamado_status_id !== 2 && dados.content.chamado_status_id !== 3) {
          this.$WiEditDialog({
            wiConfig: 'chamados-ocorrencia',
            onSubmit: dados.refresh,
            data: {
              id: dados.content.id,
              chamado_categoria_id: dados.content.chamado_categoria_id,
              nome_categoria_grupo_previsao: dados.content.nome_categoria_grupos,
              nome_categoria: dados.content.nome_categoria,
              icone_categoria: dados.content.icone_categoria,
              cor_categoria: dados.content.cor_categoria
            }
          })
        }
      }
    },
    props: ['dados'],
    computed: {
      style () {
        return this.$store.state.style || {}
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.user || {}
      }
    }
  }
</script>
