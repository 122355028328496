<template>
  <v-container>
    <v-layout wrap>
      <v-flex style="padding: 0 10px 0 10px;">
        <h1>
          <span>Por favor aguarde, esta opção estará disponível em breve...</span>
        </h1>
        <h3>Use o menu lateral para acessar todas as demais opções.</h3>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    name: 'Indisponível',
    data () {
      return {}
    },
    methods: {}
  }
</script>
