<template>
  <view-detail index="guarantee" title="Detalhes do Termo de Garantia" apiUrl="saless/guarantee" apiParams="?with=events;finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code" store="well">
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
    </template>
    <template slot-scope="{ content, refresh }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessAddress
              :business="content"
              :person="content.person || {}"
              :address="content.address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessAddress from '@/modules/business/pages/Business/BusinessAddress'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  export default {
    name: 'Guarantee',
    methods: {
      setGuaranteeLabelsText: function () {
        this.labels.category.text = 'Categoria/Tipo de Garantia:'
        this.labels.dateStart.text = 'Início da Garantia:'
        this.labels.dateEnd.text = 'Término da Garantia:'
        this.labels.duration.text = 'Duração da Garantia:'
      }
    },
    created: function () {
      this.setGuaranteeLabelsText()
    },
    components: {
      BusinessInfo,
      BusinessForm,
      BusinessActions,
      BusinessAddress,
      BusinessObservation
    },
    mixins: [BusinessMixin]
  }
</script>
