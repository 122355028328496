<template>
	<v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn v-prms="{'id': personConfig.id.update, 'notCheck': person.id == user.id}" small outline color="green" dark v-on="on">
          <v-icon>add</v-icon>
          <span>Novo Endereço</span>
        </v-btn>
      </template>
      <v-card>
        <v-card-text v-if="dialog">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <loading :loading="loading">
                  <div style="width: 100%;">
                    <h3 style="text-align: center !important;">
                      Para facilitar seu cadastro, insira aqui o CEP do novo endereço. Iremos buscar no banco de dados dos Correios o endereço correspondente.
                    </h3>
                  </div>
                  <br><br>
                  <v-text-field 
                    v-model="cep" 
                    :autofocus="true" 
                    prepend-inner-icon="place" 
                    placeholder="Insira aqui o CEP do novo endereço" 
                    mask="#####-###" 
                    solo 
                    label="CEP do endereço*" 
                    required 
                    style="width: 100%;"
                    @keyup.enter="findByCEP()"
                  >
                  </v-text-field>
                  <v-btn 
                    @click="findByCEP()"
                    :disabled="!cep"
                    color="primary"
                    style="width: 100%; margin-left: 0px !important;"
                  >
                    <v-icon>search</v-icon>
                    <span>Buscar</span>
                  </v-btn>
                  <v-btn 
                    @click="openDialog()"
                    color="green"
                    style="width: 100%; margin-left: 0px !important; color: white;"
                  >
                    <v-icon>place</v-icon>
                    <span>Adicionar sem o CEP</span>
                  </v-btn>
                </loading>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  import axios from 'axios'
  import Loading from '@/default/components/Loading'
  export default {
    name: 'PersonAddressFindByCEP',
    data () {
      return {
        dialog: false,
        loading: false,
        cep: ''
      }
    },
    methods: {
      findByCEP: function () {
        console.log(this.cep)
        this.loading = true
        axios.get('https://viacep.com.br/ws/' + this.cep + '/json/')
        .then(response => {
          if (response.data.erro) {
            this.$swal({
              type: 'warning',
              title: 'O CEP "' + this.cep + '" não foi localizado no banco de dados dos Correios.',
              text: 'Por favor, insira os dados do endereço manualmente.'
            })
          }
          this.$WiEditDialog({
            wiConfig: 'address',
            onSubmit: this.refresh,
            data: {
              person_id: this.person.id,
              address: response.data.logradouro,
              number: null,
              zip_code: response.data.cep,
              iso: parseInt(response.data.ibge),
              complement: response.data.complemento,
              district: response.data.bairro
            }
          })
          this.loading = false
          this.dialog = false
          this.cep = ''
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
      },
      openDialog: function () {
        this.$WiEditDialog({
          wiConfig: 'address',
          onSubmit: this.refresh,
          data: {person_id: this.person.id}
        })
        this.loading = false
        this.dialog = false
        this.cep = ''
      }
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      personConfig () {
        return this.$store.state.pages[this.personName] || {}
      }
    },
    props: {
      personName: {
        type: String
      },
      person: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      Loading
    }
  }
</script>