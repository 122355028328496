<template>
  <div>
    <v-list v-if="products.length > 0">
      <template v-for="(product, productKey) in products">
        <v-divider v-if="productKey" :key="productKey + '-divider'"></v-divider>
        <v-list-tile :key="productKey" :class="{ 'order-product__product--no-stock': product.quantity == 0 }">
          <v-list-tile-content>
            <v-list-tile-title>
              <strong>{{product.item.name}}</strong>
            </v-list-tile-title>
            <v-list-tile-sub-title v-if="product.item.description">
              <span v-text="product.item.description"></span>
            </v-list-tile-sub-title>
            <v-list-tile-sub-title>
              <span>Valor: </span>
              <strong> {{ product.item.amount_sale | money }} </strong>
              <span>Total: </span>
              <strong> {{ (product.item.amount_sale * product.quantity) | money }} </strong>
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-avatar tile size="50">
            <strong class="product__quantity"> {{ product.quantity }} </strong>
          </v-list-tile-avatar>
        </v-list-tile>
      </template>
    </v-list>
    <v-card v-else>
      <v-card-text>
        <h2>Nenhum produto encontrado.</h2>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'ProductsQuantity',
  methods: {
    getFirstImage: function (product) {
      return product?.item?.images[0]?.file?.url
    }
  },
  props: {
    products: {
      type: Array,
      required: true,
      default: () => ([])
    }
  }
}
</script>
<style scoped>
  .order-product__product--added {
    background: rgb(245, 232, 209);
  }
  .order-product__product-added-message {
    background: orange;
    color: black;
    padding: 5px;
  }
  .order-product__product--no-stock {
    background: rgb(243, 243, 243);
  }
  .order-product__product-no-stock-message {
    background: rgb(141, 141, 141);
    color: black;
    padding: 5px;
  }
  .product__quantity {
    font-size: 20px;
  }
</style>
