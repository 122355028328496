// import { PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE } from '@/default/constants/permissions'

export default {
  // id: PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE,
  title: 'Adicionar Nota',
  icon: 'message',
  apiUrl: 'networks/test-management-vtal/event',
  params: {
    order: 'id,desc'
  },
  fields: [
    {
      text: 'Descreva sua nota de forma que fique clara aos outros usuários.',
      type: 'subheader', 
      edit: true
    },
    {
      value: 'description',
      text: 'Insira aqui sua nota.*',
      type: 'editor',
      required: true,
      edit: true
    }
  ],
  fieldsFixed: [
    {
      value: 'type', data: 2
    }
  ]
}
