var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"medical-record-attendances",attrs:{"outlined":"","elevation":"17","color":!_vm.$wiDark ? '#FAFAFA': '#424242'}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":!_vm.$wiDark ? '#FAFAFA': '#424242'}},[_c('v-icon',[_vm._v("person_search")]),_c('v-toolbar-title',[(_vm.content && _vm.content.attendances && _vm.content.attendances.length > 0)?_c('span',[_vm._v(_vm._s(_vm.content.attendances.length)+" ")]):_vm._e(),_c('span',[_vm._v(" Atendimento(s)")])]),_c('v-spacer'),(_vm.content && _vm.content.attendances && _vm.content.attendances.length > 0)?_c('MedicalRecordCategories',{attrs:{"patient":_vm.content.patient,"categories":_vm.content.categories,"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outline":"","disabled":_vm.loading}},on),[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Novo Atendimento")])],1)]}}],null,false,931817386)}):_vm._e()],1),_c('v-divider'),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"0 10px 0px 30px","overflow-y":"scroll"},style:({'min-height': _vm.maxHeigth, 'max-height': _vm.maxHeigth})},[(_vm.content && _vm.content.attendances && _vm.content.attendances.length > 0)?_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.content.attendances),function(attendance,attendanceKey){return _c('v-timeline-item',{key:attendanceKey,attrs:{"color":_vm.$wiDark ? 'black': 'white',"large":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',[(attendance.type == 10)?_c('img',{attrs:{"src":"/static/icons/monitor/system_task_96.png"}}):_vm._e(),(attendance.type == 11)?_c('img',{attrs:{"src":"/static/icons/monitor/pass_fail_96.png"}}):_vm._e(),(attendance.type == 12)?_c('img',{attrs:{"src":"/static/icons/monitor/pills_96.png"}}):_vm._e(),(attendance.type == 14)?_c('img',{attrs:{"src":"/static/icons/monitor/microscope_96.png"}}):_vm._e(),(attendance.type == 15)?_c('img',{attrs:{"src":"/static/icons/monitor/good_notes_96.png"}}):_vm._e()])]},proxy:true}],null,true)},[_c('v-card',{attrs:{"color":_vm.$wiDark ? 'black': ''}},[_c('v-list-tile',[_c('v-list-tile-content',[_c('v-list-tile-sub-title',{domProps:{"textContent":_vm._s(attendance.opened_at_format)}}),_c('v-list-tile-title',[_c('strong',{domProps:{"textContent":_vm._s(attendance.category.name)}})])],1),_c('v-list-tile-action',[_c('v-btn',{attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.$WiEditDialog({
                  wiConfig: attendance.info.name,
                  onSubmit: _vm.refresh,
                  data: attendance,
                  redirectOnSubmit: false
                })}}},[_c('v-icon',[_vm._v("edit")])],1)],1),_c('v-list-tile-action',[_c('v-btn',{attrs:{"icon":"","ripple":""},on:{"click":function($event){_vm.$WiDialog(_vm.wiDialogs.files(attendance.info.name, attendance.id))}}},[_c('v-badge',{attrs:{"right":"","overlap":"","offset-x":"22","offset-y":"24"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(attendance.files_count)?_c('span',[(attendance.files_count < 10)?_c('span',[_vm._v(_vm._s(attendance.files_count))]):_c('span',[_vm._v("9+")])]):_vm._e()]},proxy:true}],null,true)},[_c('v-icon',[_vm._v(" attach_file ")])],1)],1)],1),_c('v-list-tile-action',[_c('v-btn',{attrs:{"icon":"","ripple":""},on:{"click":function($event){_vm.$WiDialog(_vm.wiDialogs.events(attendance.info.name, attendance.id))}}},[_c('v-badge',{attrs:{"right":"","overlap":"","offset-x":"22","offset-y":"24"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(attendance.notes_count)?_c('span',[(attendance.notes_count < 10)?_c('span',[_vm._v(_vm._s(attendance.notes_count))]):_c('span',[_vm._v("9+")])]):_vm._e()]},proxy:true}],null,true)},[_c('v-icon',[_vm._v(" message ")])],1)],1)],1),_c('v-list-tile-action',[_c('MedicalRecordAttendanceDialog',{attrs:{"attendance":attendance},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","ripple":""}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)})],1)],1)],1)],1)}),1):_c('div',{staticStyle:{"width":"100%","padding":"30px 0","text-align":"center"}},[_c('h3',[_vm._v("Nenhum atendimento encontrado.")]),(_vm.content)?_c('MedicalRecordCategories',{attrs:{"patient":_vm.content.patient,"categories":_vm.content.categories,"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outline":"","disabled":_vm.loading}},on),[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Novo Atendimento")])],1)]}}],null,false,931817386)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }