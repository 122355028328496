import {
  PERMISSION_FORM_UPDATE
} from '@/default/constants/permissions'
import Form from '@/modules/forms/pages/Form'

export default [
  {
    path: 'form/:id',
    name: 'form',
    component: Form,
    meta: {
      id: PERMISSION_FORM_UPDATE,
      auth: true,
      title: 'Detalhes do Formulário',
      icon: 'phonelink_setup'
    }
  }
]
