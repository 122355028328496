<template>
  <v-layout row>
    <v-flex class="form-reply-list__row" xs12 sm12 md12 lg12 xl12>
      <v-toolbar dense flat color="transparent" class="business-internet-plan__toolbar">
        <v-toolbar-title>
          <v-icon color="primary">speed</v-icon>
          <span> Plano de Navegação</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="searchInternetPlan()" disabled outline small>
          <v-icon>edit</v-icon>
          <span> Mudar Plano</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </v-flex>
  </v-layout>
</template>
<script>
  import ContractInternetPlanMixin from './ContractInternetPlanMixin'
  export default {
    name: 'ContractInternetPlanTitle',
    mixins: [ContractInternetPlanMixin]
  }
</script>
<style scoped>
  .business-internet-plan__toolbar {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .business-internet-plan__toolbar >>> .v-toolbar__content {
    padding: 0px !important;
  }
</style>