<template>
  <view-detail title="Minha Rede" apiUrl="topologia/dados" :notGetID="true" :notShowToolbar="selectDevicesToNetworkCalback != null">
    <template slot-scope="dados">
      <v-container align="center">
        <v-layout row wrap>
          <v-flex xs12 sm7 md7 lg7 d-flex style="padding: 10px;">
            <v-card>
              <v-treeview :open-all="true" :search="search" :filter="filter" :open.sync="open" :items="dados.content" :load-children="fetchUsers"  open-on-click transition item-text="nome_sigla_grupo">
                <span @click="setSelected(item)" slot="label" slot-scope="{ item }" :style="{color: selected && selected.id == item.id ? 'orange' : 'gray'}">
                  {{item.nome_grupo}} <span v-if="item && item.sigla_grupo" :style="{color: selected && selected.id == item.id ? 'orange' : '#a8a8a8'}">({{item.sigla_grupo}})</span>
                </span>
                <v-icon medium slot="prepend" slot-scope="{ item }" :style="{'color': item.status.cor_status}">{{item.icone_tipo || 'device_hub'}}</v-icon> 
              </v-treeview>
            </v-card>
          </v-flex>
          <v-flex xs12 sm5 md5 lg5 d-flex text-xs-center style="padding: 10px;">
            <v-scroll-y-transition mode="out-in">
              <div v-if="!selected" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center;" > Selecione um grupo... </div>
              <v-card v-else :key="selected.id" >
                <v-card-text>
                  <v-avatar>
                    <v-icon v-if="selected.icone_tipo" :style="{'color': selected.status.cor_status}" x-large>{{selected.icone_tipo}}</v-icon> 
                    <v-icon v-else x-large>device_hub</v-icon> 
                  </v-avatar>
                  <h3 class="headline">
                    {{ selected.nome_grupo }}
                  </h3>
                  <div v-if="selected.sigla_grupo" class="gray--text subheading font-weight-bold" style="color: gray;">({{ selected.sigla_grupo }})</div>
                  <div v-if="selected.obs_grupo" class="gray--text" style="color: gray;">{{ selected.obs_grupo }}</div>
                  <div class="gray--text"><strong>{{ selected.nome_tipo }}</strong></div>
                  <v-divider></v-divider>
                  <span  :style="{'color': selected.status.cor_status}">{{selected.status.nome_status}}</span><br>
                  <v-btn small block outline :style="{'color': selected.status.cor_status}" @click="openIndisponibilidades(selected)">
                    <v-icon>arrow_downward</v-icon>
                    <span class="ml-1">Listar Indisponibilidades</span>
                  </v-btn>
                  <v-btn
                    v-if="selectDevicesToNetworkCalback"
                    small
                    block
                    outline
                    :style="{'color': selected.status.cor_status}"
                    @click="selectDevicesToNetworkCalback({ network_id: selected.id, count_devices: selected.num_disp })"
                  >
                    <v-icon>select_all</v-icon>
                    <span class="ml-1">Adicionar todos os {{ selected.num_disp | number }} dispositivos</span>
                  </v-btn>
                  <v-btn v-if="selectDevicesToNetworkCalback" small block outline :style="{'color': selected.status.cor_status}" @click="$WiListDialog({wiConfig: 'devices', callback: selectDevicesToNetworkCalback, mode: 'select', filter: {network_id: selected.id}})">
                    <v-icon>dns</v-icon>
                    <span class="ml-1">Listar Dispositivos</span>
                  </v-btn>
                  <v-btn v-else small block outline :style="{'color': selected.status.cor_status}" @click="$WiListDialog({wiConfig: 'devices', filter: {network_id: selected.id}})">
                    <v-icon>dns</v-icon>
                    <span class="ml-1">Listar Dispositivos</span>
                  </v-btn>
                  <v-btn
                    v-if="selected.status.cod_status == '#031' || selected.status.cod_status == '#030'"
                    small
                    outline
                    block
                    :color="selected.status.cor_status"
                    @click="$WiListDialog({wiConfig: 'ticket-opened', filter: {network: selected.id}})"
                  >
                    <v-icon>message</v-icon>
                    <span class="ml-1">Chamados Vinculados</span>
                  </v-btn><br>
                  <v-tooltip v-if="selected.funcao_tipo == 1" v-prms="{'id': 'b248147d'}" bottom>
                    <template slot="activator">
                      <v-btn @click="openEditModal('grupos-sub', {grupo_dados_id: selected.id}, 'nivel_tipo,' + (selected.nivel_tipo + 1), 'Adicionando Sub-grupo em ' + selected.nome_grupo)" icon ripple>
                        <v-icon color="green lighten-1">add</v-icon>
                      </v-btn>
                    </template>
                    <span>Criar Sub-grupo</span>
                  </v-tooltip>
                  <v-tooltip v-else v-prms="{'id': 'a2ee509c'}" bottom>
                    <template slot="activator">
                      <v-btn @click="$WiEditDialog({wiConfig: 'devices', data: {grupo_dados_id: selected.id}, onSubmit: dados.refresh})" icon ripple>
                        <v-icon color="green lighten-1">add</v-icon>
                      </v-btn>
                    </template>
                    <span>Criar Novo Dispositivo para {{selected.nome_grupo}}</span>
                  </v-tooltip>
                  <v-tooltip v-if="selected.funcao_tipo == 1" bottom>
                    <template slot="activator">
                      <v-btn @click="$router.push({name: 'google-maps-grupos', params: {id: selected.id}})" icon ripple>
                        <v-icon color="brown lighten-1">map</v-icon>
                      </v-btn>
                    </template>
                    <span>Abrir grupo no mapa</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="selected.funcao_tipo == 1">
                    <template slot="activator">
                      <v-btn @click="$router.push({name: 'grupo-mapa-grupos', params: {id: selected.id}})" icon ripple>
                        <v-icon color="blue lighten-1">device_hub</v-icon>
                      </v-btn>
                    </template>
                    <span>Abrir topologia desta Rede</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="selected.funcao_tipo == 2">
                    <template slot="activator">
                      <v-btn @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: selected.id}})" icon ripple>
                        <v-icon color="blue lighten-1">dns</v-icon>
                      </v-btn>
                    </template>
                    <span>Exibir dispositivos desta Rede</span>
                  </v-tooltip>
                  <v-tooltip v-prms="{'id': '3c8cd4ef'}" bottom>
                    <template slot="activator">
                      <v-btn @click="openEditModal('grupos-sub', selected, 'nivel_tipo,' + selected.nivel_tipo, 'Editando Grupo');" icon ripple>
                        <v-icon color="orange lighten-1">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar esta Rede</span>
                  </v-tooltip>
                  <v-tooltip v-prms="{'id': '101ce7ab'}" bottom>
                    <template slot="activator">
                      <db-delete v-on:action="dados.refresh" store="grupos" :id="selected.id"></db-delete>
                    </template>
                    <span>Apagar esta Rede</span>
                  </v-tooltip>
                  <v-tooltip v-prms="{'id': '45391db0'}" bottom>
                    <template slot="activator">
                      <v-btn @click="$WiEditDialog({wiConfig: 'network-move', onSubmit: dados.refresh, data: {id: selected.id, grupo_dados_id: selected.grupo_dados_id}});" icon ripple>
                        <v-icon>compare_arrows</v-icon>
                      </v-btn>
                    </template>
                    <span>Mover esta rede</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="selected.funcao_tipo == 2 && selected.num_disp > 0" v-prms="{'id': 'a2ee509c'}">
                    <template slot="activator">
                      <v-btn @click="copiar_dispositivos = true" icon ripple>
                        <v-icon color="black lighten-1">file_copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copiar dispositivos desta Rede</span>
                  </v-tooltip>
                </v-card-text>
                <v-divider></v-divider>
                <v-list dense subheader>
                  <v-list-tile v-if="selected.funcao_tipo == 1" avatar>
                    <v-list-tile-avatar>
                      <v-icon color="orange darken-2">device_hub</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title style="font-size: 15px;">Total de Sub-grupos:</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <span style="font-size: 25px;">{{ selected.sub_grupos }}</span>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider v-if="selected.funcao_tipo == 1"></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar>
                      <v-icon color="blue" medium>dns</v-icon> 
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title style="font-size: 15px;">Total de Dispositivos:</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <span style="font-size: 25px;">{{ selected.num_disp }}</span>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar>
                      <v-icon color="red" medium>dns</v-icon> 
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title style="font-size: 15px;">Disp. Indisponíveis:</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <span style="font-size: 25px;">{{ selected.num_disp_down }}</span>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile avatar>
                    <v-list-tile-avatar>
                      <v-icon color="green" medium>dns</v-icon> 
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title style="font-size: 15px;">Disp. Disponíveis:</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <span style="font-size: 25px;">{{ selected.num_disp_up }}</span>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                </v-list>
              </v-card>
            </v-scroll-y-transition>
          </v-flex>
        </v-layout>
      </v-container>
      <div v-if="editModal">
        <edit-modal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="dados.refresh" :local-config="configModal"></edit-modal>
      </div>
      <v-dialog v-model="copiar_dispositivos" scrollable>
        <grupos-topologia-copiar-dispositivo-dialog v-if="copiar_dispositivos" :grupo="selected">
          <span slot="action">
            <v-btn color="red darken-1" flat @click="copiar_dispositivos = false">Fechar</v-btn>
          </span>
        </grupos-topologia-copiar-dispositivo-dialog>
      </v-dialog>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import EditModal from '@/default/components/Modal/EditModal'
  import DbDelete from '@/default/components/DBDelete'
  import {callApi} from '@/default/service/Api'
  import GruposTopologiaCopiarDispositivoDialog from '@/modules/network/pages/Grupo/GruposTopologiaCopiarDispositivoDialog'
  export default {
    data: () => ({
      items: [],
      selected: null,
      editModal: false,
      configModal: null,
      modalForm: {},
      modalStore: 'grupos-sub',
      copiar_dispositivos: false,
      open: [],
      search: null,
      caseSensitive: false,
      config: {
        title: 'Novo Grupo',
        apiUrl: 'grupo/dados',
        fields: [
        {value: 'nome_grupo', counter: 100, text: 'Nome da estação', size: ['xs12', 'sm12', 'md4', 'lg4'], type: 'text', required: true, list: true, edit: true},
        {value: 'sigla_grupo', counter: 30, text: 'Sigla', size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'text', required: false, list: true, edit: true},
        {value: 'active', text: 'Está ativo?', size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'switch', required: false, list: true, edit: true},
        {value: 'latitude_grupo', counter: 15, text: 'Latitude da estação', size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'text', required: false, list: false, edit: true},
        {value: 'longitude_grupo', counter: 15, text: 'Longitude da estação', size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'text', required: false, list: false, edit: true},
        {value: 'grupo_tipo_id', text: 'Tipo deste Grupo', size: ['xs12', 'sm12', 'md4', 'lg4'], required: true, type: 'db-autocomplete', list: false, edit: true, apiUrl: 'grupo/tipo', apiParams: {}, show: 'nome_tipo'},
        {text: 'O campo de Observações pode ser usado para descrever esta Rede.', type: 'subheader', edit: true},
        {value: 'obs_grupo', text: 'Observações', type: 'textarea', list: false, edit: true}
        ],
        loading_edit: false,
        loading_list: false,
        loading_view: false
      }
    }),
    methods: {
      async fetchUsers (item) {
        return new Promise((resolve, reject) => {
          callApi.get({
            uri: 'grupo/dados',
            params: {limit: 1000, order: 'grupo_tipo_id,asc;nome_grupo,asc', where: 'grupo_dados_id,' + item.id + ';active,1'},
            sucess: data => {
              resolve(data.data.data)
              var itens = data.data.data.filter(function (i) {
                if (i.sub_grupos > 0) {
                  i['children'] = []
                }
                return i
              })
              item.children.push(...itens)
            },
            error: err => {
              reject(err)
              return err
            }
          })
        })
      },
      setSelected: function (id) {
        if (id) {
          this.selected = id
        }
      },
      openEditModal: function (store, dados, where, title) {
        this.modalForm = dados
        this.modalStore = store
        this.configModal = this.config
        this.configModal.title = title
        this.configModal.fields[5].apiParams['where'] = where
        this.editModal = true
      },
      openEditModalDisp: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.configModal = null
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
        this.selected = null
      },
      openIndisponibilidades: function (grupo) {
        var query = {grupo_dados_id: grupo.id}
        if (grupo.num_disp_down > 0) {
          query['status_indi'] = 0
          query['status_disp'] = 0
          query['ativo_disp'] = 1
        }
        this.$router.push({name: 'indisponibilidades', query: query})
      }
    },
    components: {
      ViewDetail,
      EditModal,
      DbDelete,
      GruposTopologiaCopiarDispositivoDialog
    },
    computed: {
      filter () {
        return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    props: ['selectDevicesToNetworkCalback']
  }
</script>