import { Model } from '@/default/model/Model'
import { PERMISSION_FORM_REPLY } from '@/default/constants/permissions'

export class FormReplyContentHistory extends Model {
  constructor () {
    super()
    this.list = true
    this.actions = {
      list: true,
      edit: false
    }
    this.id = PERMISSION_FORM_REPLY
    this.name = 'form-reply-content-history'
    this.title = 'Histórico de alterações'
    this.icon = 'history'
    this.params = {
      limit: 10,
      order: 'created_at,desc',
      with: 'reply_content;reply_content.field;person;business'
    }
    this.search = {
      placeholder: 'Pesquise pelo conteúdo',
      fields: ['id', 'data']
    }
    this.apiUrl = 'forms/form/reply/content/history'
    this.components.list.tableRow = () => import('@/modules/forms/pages/Forms/FormReplyContentHistory')
    this.setFields(this.fieldsList)
  }

  fieldsList = {
    form_reply_content_id: this.fieldList({
      value: 'form_reply_content_id',
      text: 'Nome do campo',
      sortable: false
    }),
    content: this.fieldList({
      value: 'data',
      text: 'Conteúdo',
      sortable: false
    }),
    person_id: this.fieldList({
      value: 'person_id',
      text: 'Usuário'
    }),
    business_id: this.fieldList({
      value: 'business_id',
      text: 'Referência'
    }),
    created_at: this.fieldList({
      value: 'created_at',
      text: 'Data/hora da alteração'
    })
  }

}
