export default {
  id: '4f1be58b',
  list: true,
  title: 'Status do chamado',
  apiUrl: 'chamado/status',
  actions: {
    create: true,
    update: true,
    delete: (form) => { return form.id !== 1 && form.id !== 2 && form.id !== 3 }
  },
  components: {
    list: {
      tableRow: () => import('@/modules/service-desk/pages/Status')
    }
  },
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: false},
    {value: 'nome_status', text: 'Nome do Status*', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: true, list: true, edit: true, search: true, align: 'center', sortable: true},
    {value: 'tipo_status', text: 'Contar SLA?*', placeholder: 'Influenciar no SLA', type: 'select', required: true, size: ['xs12', 'sm12', 'md2', 'lg2'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: true, edit: true, align: 'center', sortable: false},
    {value: 'exigir_data', text: 'Exigir data limite?*', placeholder: 'O usuário informará uma data', type: 'select', required: true, size: ['xs12', 'sm12', 'md2', 'lg2'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: true, edit: true, disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    {value: 'get_confirmation', text: 'Confirmar Ocorrência?*', placeholder: 'O usuário terá de confirmar ao colocar neste status', type: 'select', required: true, size: ['xs12', 'sm12', 'md2', 'lg2'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: true, edit: true, disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    {value: 'cor_status', text: 'Cor do Status', type: 'color', size: ['xs12', 'sm6', 'md2', 'lg2'], required: false, list: false, edit: true, align: 'center', sortable: false},
    {value: 'stage', text: 'Tipo de Status', type: 'select', required: true, size: ['xs12', 'sm12', 'md2', 'lg2'], options: [{value: 1, text: 'Em andamento'}, {value: 2, text: 'Encerrado'}, {value: 3, text: 'Cancelado'}], list: false, edit: true, disabled: (form) => { return form.id < 4 }, align: 'center', sortable: false},
    {value: 'icone_status', text: 'Ícone do Status', type: 'wi-google-icons', size: ['xs12', 'sm6', 'md2', 'lg2'], required: false, list: false, edit: true, align: 'center', sortable: false},
    {value: 'ordem_status', text: 'Ordem do Status', type: 'int', size: ['xs12', 'sm6', 'md2', 'lg2'], required: false, list: true, edit: true, align: 'center', sortable: false},
    {value: 'chamado_status_id', text: 'Status Dependente', placeholder: 'Depende deste Status para ser selecionável', autocomplete: true, required: false, size: ['xs12', 'sm6', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/status', apiParams: {fields: 'id,nome_status'}, show: 'nome_status', disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    {value: 'show_status', text: 'Será exibido se o perfil do usuário for:', autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'system/user/profile', apiParams: {where: 'id,>,1', fields: 'id,name'}, show: 'name', align: 'center', sortable: false},
    // {value: 'hide_status', text: 'Não será exibido se o perfil do usuário for:', disabled: true, autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'usuario/permissao', apiParams: {fields: 'id,nome_permissao'}, show: 'nome_permissao'},
    // {value: 'config_status', text: 'Conigurações do status', autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md4', 'lg4'], type: 'select', options: [{value: 'nao_ela_100', text: 'Não exibir se o SLA for maior que 100%'}], list: false, edit: true},
    {value: 'obs_status', text: 'Observações', type: 'textarea', required: false, list: false, edit: true, align: 'center', sortable: false}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
