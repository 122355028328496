import {Model} from '@/default/model/Model'

export class Business extends Model {
  constructor () {
    super()
    this.list = true
    this.params = {
      limit: 10
    }
    this.icon = 'shopping_cart'
    this.search = {
      placeholder: 'Pesquise ID, Razão Social, Status ou Nota Fiscal',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
    }
    this.components.list.tableRow = () => import('@/modules/shared/pages/Businesses/Businesses')
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    personType: this.fieldList({
      value: 'person.type',
      text: 'PF/PJ'
    }),
    personName: this.fieldList({
      value: 'person.name',
      text: 'Nome do Cliente'
    }),
    status: this.fieldList({
      value: 'business_status_id',
      text: 'Status/Etapa',
      align: 'center'
    }),
    externalCode: this.fieldList({
      value: 'external_code',
      text: 'Código Externo'
    }),
    dateStart: this.fieldList({
      value: 'date_start',
      text: 'Data de Emissão'
    }),
    dateEnd: this.fieldList({
      value: 'date_end',
      text: 'Data de Entrega'
    }),
    collaborator: this.fieldList({
      value: 'collaborator_id',
      text: 'Colaborador/Categoria'
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      sortable: false
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste negócio.'
    }),
    company: this.fieldSelectApi({
      value: 'company_id',
      text: 'Empresa (Matriz ou Filial)',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      disabled: (form) => { return form.id != null },
      api: {
        url: 'register/person/company',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    person: this.fieldWiListDialog({
      value: 'person_id',
      text: '',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      wiConfig: '',
      show: 'person.first_name',
      icon: 'person_outline',
      disabled: form => !!form.id
    }),
    externalCode: this.fieldText({
      value: 'external_code',
      text: 'Código Externo',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    status: this.fieldSelectApi({
      value: 'business_status_id',
      text: 'Status/Etapa*',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      api: {
        url: '',
        params: {
          fields: 'id,name',
          where: 'active,1',
          order: 'order,asc'
        }
      },
      show: 'name',
      disabled: (form) => { return form.paid && form.stage === 2 }
    }),
    category: this.fieldSelectApi({
      value: 'business_category_id',
      text: 'Categoria',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      api: {
        url: '',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name'
    }),
    invoice: this.fieldText({
      value: 'invoice',
      text: 'N° Nota Fiscal',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data de Emissão',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data de Entrega',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    amountFreight: this.fieldMoney({
      value: 'amount_freight',
      text: 'Valor Frete/Deslocamento',
      size: ['xs12', 'sm4', 'md2', 'lg2']
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor de Desconto',
      size: ['xs12', 'sm4', 'md2', 'lg2']
    }),
    collaborator: this.fieldSelectApi({
      value: 'collaborator_id',
      text: 'Colaborador Responsável',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      api: {
        url: 'register/person/collaborator',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name'
    }),
    isTemplate: this.fieldSwitch({
      value: 'is_template',
      text: 'É uma template',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return !form.id }
    }),
    makeStock: this.fieldSwitch({
      value: 'make_stock',
      text: 'Integrar com Estoque',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      disabled: (form) => { return form.id && form.stage != 1 },
      showField: () => {
        return this.permissionIsAvaiable('b0ce8f81-78ff-4d79-a9f3-d7415343d2e5')
      }
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Descreva abaixo os detalhes.'
    }),
    description: this.fieldEditor({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Ex: Prazos, detalhes importanes, etc.'
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Descreva abaixo as formas/condições de pagamento.'
    }),
    information: this.fieldEditor({
      value: 'information',
      text: 'Forma/Condições de Pagamento',
      placeholder: 'Ex: Parcelado em 3x.'
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para adicionar observações...'
    })
  }
}
