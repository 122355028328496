<template>
	<v-navigation-drawer app color="blue-grey darken-4" floating temporary right v-model="drawerTalks.active" :touchless="touch" width="360">
    <TalksLists :drawer="true"></TalksLists>
    <v-footer dense absolute height="49" :color="primaryColor" class="pa-3 elevation-24">
      <v-spacer></v-spacer>
      <v-btn outline color="white" small link :to="{name: 'talk-view'}">Todas as conversas</v-btn>
      <v-spacer></v-spacer>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import TalksLists from '@/modules/talk/pages/TalkView/Talks/TalksLists'
  export default {
    name: 'DrawerTalks',
    computed: {
      ...mapState('Pages/Main', ['drawerTalks']),
      touch () {
        return this.$route.meta.touch || false
      },
      system () {
        return this.$store.state.system || []
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapMutations('Pages/Main', ['TOUGGLE_DRAWER_TALKS_ACTIVE'])
    },
    components: {
      TalksLists
    }
  }
</script>