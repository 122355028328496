const category = (name, title) => {
  return {
    id: 'a02e979e',
    list: true,
    title: {
      list: 'Categorias de ' + title,
      edit: {
        create: 'Nova categoria de ' + title,
        update: 'Editar categoria de ' + title
      }
    },
    icon: 'category',
    apiUrl: 'purchases/config/category/' + name,
    fields: [
      {
        value: 'id',
        text: 'ID',
        type: 'int',
        list: true,
        edit: false,
        search: true
      },
      {
        value: 'name',
        text: 'Nome da Categoria',
        type: 'text',
        size: [
          'xs12',
          'sm6',
          'md6',
          'lg6'
        ],
        required: true,
        list: true,
        edit: true,
        search: true
      },
      {
        value: 'color',
        text: 'Cor da Categoria',
        type: 'color',
        size: [
          'xs12',
          'sm3',
          'md3',
          'lg3'
        ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'icon',
        text: 'Ícone da Categoria',
        type: 'wi-google-icons',
        size: [
          'xs12',
          'sm3',
          'md3',
          'lg3'
        ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'description',
        text: 'Observações',
        type: 'textarea',
        required: false,
        list: false,
        edit: true
      }
    ]
  }
}

export default {
  purchase: category('purchase', 'Compra'),
  quotation: category('quotation', 'Cotação')
}
