export default {
  methods: {
    searchInternetPlan: function () {
      this.$WiEditDialog({
        wiConfig: 'internet-contract-plan',
        data: {
          business_id: this.business.id
        },
        onSubmit: this.onSubmit,
        redirectOnSubmit: false
      })
    },
    selectInternetPlan: function (internetPlan) {
      if (internetPlan?.id) {
        this.$WiApiPut({
          uri: 'business/business',
          id: this.business.id,
          data: {
            item_id: internetPlan.id
          },
          config: {
            msgLoad: true,
            msgSucess: true
          },
          callback: this.onSubmit
        })
      }
    },
    onSubmit: function (response) {
      this.$emit('onSubmit', response)
    }
  },
  props: {
    business: {
      type: Object,
      required: true,
      default: () => ({})
    },
    plan: {
      type: Object,
      default: () => ({})
    }
  }
}
