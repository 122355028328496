<template>
  <div>
    <!-- eslint-disable -->
    <v-card flat style="background: #fafafa;">
      <v-toolbar v-if="pesquisas.length < 1" dark :color="$wiDark ? 'dark' : 'primary'" flat extended center-align style="padding:0px 10px 0 10px; background-image: url('static/background/background-blue.png');">
        <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          Chamados
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="hidden-xs-only hidden-sm-only" flat @click="$router.push({ name: 'chamados', params: { page: 'solicitante' } })">
                <v-icon>person_outline</v-icon>
              </v-btn>
            </template>
            <span>Exibir apenas chamados em que sou o Solicitante</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="hidden-xs-only hidden-sm-only" flat @click="$router.push({ name: 'chamados', params: { page: 'responsavel' } })">
                <v-icon>person</v-icon>
              </v-btn>
            </template>
            <span>Exibir apenas chamados em que sou o Responsável</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="hidden-xs-only hidden-sm-only" flat @click="$router.push({ name: 'chamados', params: { page: 'equipe' } })">
                <v-icon>people_outline</v-icon>
              </v-btn>
            </template>
            <span>Exibir chamados vinculados somente às minhas Equipes</span>
          </v-tooltip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <FilterByStatus
          class="hidden-xs-only hidden-sm-only"
          v-on:selectStatus="setSelectedStatus"
        ></FilterByStatus>
        <FilterByPeriod
          class="hidden-xs-only hidden-sm-only"
          v-on:selectPeriod="setSelectedPeriod"
        ></FilterByPeriod>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="load({reset: false})">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn v-prms="{'id': '2c491b4c'}" @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: load, data: {}})" color="white" class="black--text hidden-xs-only hidden-sm-only"><v-icon>add</v-icon>Adicionar Novo</v-btn>
      </v-toolbar>
      <v-toolbar v-else dark :color="$wiDark ? 'dark' : 'primary'" flat extended center-align style="padding:0px 10px 0 10px; background-image: url('static/background/background-blue.png');">
        <v-flex xs12 sm12 md2 lg2 style="padding: 0 10px 0 10px;">
          <div style="width: 100px;">
            <span v-if="totalItens" style="font-size: 20px;">({{totalItens}})</span>
            <v-btn icon flat color="white" @click="load({reset: false})">
              <v-icon>refresh</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md10 lg10 style="padding: 0 10px 0 10px;">
          <v-card :color="$wiDark ? 'dark' : 'white'" style="min-height: 42px;">
            <span><v-icon :color="$wiDark ? 'white' : 'black'" style="padding: 0 0 0 15px">filter_list</v-icon><span :style="{color: $wiDark ? 'white' : 'black'}">({{totalItens}})</span></span>
            <span v-for="(pesquisa, pesquisaKey) in pesquisas">
              <v-chip v-if="pesquisa && pesquisa.search != ''" :color="$wiDark ? 'white' : 'black'" :text-color="$wiDark ? 'black' : 'white'" close @input="unsetOneSearch(pesquisaKey, pesquisa.index)" >
                <strong>{{ pesquisa.search }}</strong>&nbsp;
                <span>({{ pesquisa.text }})</span>
              </v-chip>
            </span>
          </v-card>
        </v-flex>
      </v-toolbar>
      <v-layout row pb-2 :style="{background: $wiDark ? '#303030 !important' : ''}">
        <v-flex xs12 sm12 md12 ls12 xl12 class="area_datatable">
          <v-card class="card--flex-toolbar">
            <v-data-table :pagination.sync="loadPagination" disable-initial-sort :total-items="totalItens" :headers="headers" :items="content.data" class="elevation-1" :loading="loading" :hide-actions="loading" :dark="$wiDark" :rows-per-page-items="[50, 5, 10, 100, 200]" rows-per-page-text="Ítens por página" >
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <template slot="headerCell" slot-scope="props">
                <v-tooltip bottom>
                  <span slot="activator">
                    {{ props.header.text }}
                  </span>
                  <span>
                    Clique para ordenar por {{ props.header.text }}
                  </span>
                </v-tooltip>
              </template>
              <template slot="no-data">
                <tr v-if="pesquisas.length > 0">
                  <td v-for="filter in headers" class="text-xs-center" style="height: 0px !important; align-items: center; justify-content: center; align-content: center;">
                    <v-edit-dialog v-if="!filter.noSearch" lazy>
                      <div :style="{width: filter.width}">
                        <v-tooltip bottom>
                          <span slot="activator">
                            <v-btn small icon :disabled="filter.noSearch">
                              <v-icon>filter_list</v-icon>
                            </v-btn>
                          </span>
                          <span>
                            Clique para pesquisar por {{ filter.text }}
                          </span>
                        </v-tooltip>
                      </div>
                      <div slot="input" class="mt-3 title">Pesquisar por {{filter.text }}</div>
                      <v-text-field @keyup.enter="setOneSearch(filter.value, filter)" v-model="filter.search" slot="input" label="Digite e pressione Enter" single-line counter autofocus ></v-text-field>
                    </v-edit-dialog>
                    <v-btn v-else small icon :disabled="filter.noSearch">
                      <v-icon>filter_list</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td :colspan="headers.length">
                    <v-alert v-if="pesquisas.length > 0" :value="true" color="error" icon="warning">
                      Nenhum resultado encontrado para sua busca, por favor, tente novamente.
                    </v-alert>
                    <div v-else>
                      <span v-if="loading">
                        Buscando informações, por favor aguarde.
                      </span>
                      <span v-else>
                        Nenhuma informação encontrada no momento.
                      </span>
                    </div>
                  </td>
                </tr>
              </template>
              <template slot="items" slot-scope="props">
                <tr v-if="!props.index">
                  <td v-for="filter in headers" class="text-xs-center" style="height: 0px !important; padding: 0 10px !important;" >
                    <v-edit-dialog v-if="!filter.noSearch" lazy>
                      <div :style="{width: filter.width}">
                        <v-tooltip bottom>
                          <span slot="activator">
                            <v-btn small icon :disabled="filter.noSearch">
                              <v-icon>filter_list</v-icon>
                            </v-btn>
                          </span>
                          <span>
                            Clique para pesquisar/filtrar por {{ filter.text }}
                          </span>
                        </v-tooltip>
                      </div>
                      <div slot="input" class="mt-3 title">Pesquisar por {{filter.text }}</div>
                      <v-text-field @keyup.enter="setOneSearch(filter.value, filter)" v-model="filter.search" slot="input" label="Digite e pressione Enter" single-line counter autofocus ></v-text-field>
                    </v-edit-dialog>
                    <v-btn v-else small icon :disabled="filter.noSearch">
                      <v-icon>filter_list</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="text-xs-center" style="padding: 0px !important;">
                    <v-tooltip v-if="mode != 'component'" bottom>
                      <span slot="activator">
                        <v-icon outlined v-ripple  :color="$wiDark ? 'white' : 'blue'" @click="detailAction(props.item)" style="cursor: pointer;">info</v-icon>
                        <router-link :to="{name: 'chamado', params: {id: props.item.id}}" target="_blank" style="text-decoration: none;">
                          <v-icon outlined v-ripple  :color="$wiDark ? 'white' : 'blue'">open_in_new</v-icon>
                        </router-link>
                      </span>
                      <span>
                        Detalhes deste item
                      </span>
                    </v-tooltip>
                    <v-tooltip v-else bottom>
                      <span slot="activator">
                        <v-btn flat icon outline :color="$wiDark ? 'white' : 'green'" @click="$emit('action', props.item)">
                          <v-icon large outlined>done</v-icon>
                        </v-btn>
                      </span>
                      <span>
                        Selecionar este item
                      </span>
                    </v-tooltip><br>
                    <strong>#{{ props.item.id }}</strong>
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">
                    <v-icon large :style="{color: props.item.cor_status}">{{props.item.icone_status}}</v-icon><br>
                    <strong v-text="props.item.nome_status"></strong>
                    <span v-if="props.item.deadline_formated"> até <br>
                      <span :style="{color: $WiSecondsColor({seconds: props.item.deadline_seconds})}" v-text="props.item.deadline_formated"></span>
                    </span>
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">
                    <textarea v-if="props.item.grupos" rows="4" cols="20" textarea v-model="props.item.grupos"></textarea>
                    <span v-else>Nenhum grupo vinculado.</span>
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important; wid">
                    <v-icon :style="{color: props.item.cor_categoria}">{{props.item.icone_categoria}}</v-icon><br>
                    {{ props.item.nome_categoria_grupos }}
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">
                    <textarea rows="4" cols="20" textarea v-model="props.item.desc_chamado_format" ></textarea>
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">
                    <textarea :style="{color: props.item.last_event_seconds > 7200 && props.item.sla_is_enable ? (props.item.last_event_seconds > 14400 && props.item.sla_is_enable ? '#b71c1c' : '#e59100') : ''}" style="font-weight: bold;" v-if="props.item.last_note" rows="4" cols="20" textarea v-model="props.item.last_note.description_time" ></textarea>
                    <textarea :style="{color: props.item.last_event_seconds > 7200 && props.item.sla_is_enable ? (props.item.last_event_seconds > 14400 && props.item.sla_is_enable ? '#b71c1c' : '#e59100') : ''}" style="font-weight: bold;" v-else rows="4" cols="20" textarea v-model="props.item.desc_chamado_format" ></textarea>
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">
                    {{ props.item.nome_equipe }}
                  </td>
                  <!-- <td class="text-xs-center" style="padding: 0 10px !important;">{{ props.item.nome_responsavel || 'Não Definido' }}</td> -->
                  <!-- <td class="text-xs-center" style="padding: 0 10px !important;">
                    <span>{{ props.item.sla }}%<br>
                      <v-progress-circular :size="30" :width="15" :rotate="360" :value="props.item.sla" :color="props.item.sla > 90 ? 'red' : props.item.sla > 70 ? 'orange' : 'green'" >
                        <span style="color: black;">sla</span>
                      </v-progress-circular>
                    </span>
                  </td> -->
                  <!-- <td class="text-xs-center" style="padding: 0 10px !important;">
                    <v-avatar >
                      <img v-if="props.item.chamado_impacto_id == 1" src="static/icons/monitor/retornou.png" alt="Impacto">
                      <img v-if="props.item.chamado_impacto_id == 2" src="static/icons/monitor/problema.png" alt="Impacto">
                      <img v-if="props.item.chamado_impacto_id == 3" src="static/icons/monitor/problema_v2.png" alt="Impacto">
                      <img v-if="props.item.chamado_impacto_id == 4" src="static/icons/monitor/problema_expirado.png" alt="Impacto">
                    </v-avatar><br>
                    {{ props.item.nome_impacto }}
                  </td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">{{ props.item.duracao }}</td>
                  <td class="text-xs-center" style="padding: 0 10px !important;">{{ props.item.previsao_chamado }}</td>-->
                  <!-- <td class="text-xs-center" style="padding: 0 10px !important;">{{ props.item.time_abertura_format }}</td> -->
                  <!-- <td class="text-xs-center" style="padding: 0 10px !important;"><span style="font-size: 20px;">{{ props.item.device_count }}</span></td> -->
                  <!-- <td class="text-xs-center" style="padding: 0 10px !important;">{{ props.item.nome_criador }}</td> -->
                </tr>
              </template>
              <template slot="pageText" slot-scope="props">
                Exibindo {{ props.pageStart }} até {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-card>
          <br><br><br><br>
        </v-flex>
      </v-layout>
    </v-card>
    <!-- <v-btn v-if="auth.Permissao.tipo_permissao != 'read'" @click="dialogSelecionarCategoria = true" color="green" class="white--text hidden-md-only hidden-lg-only hidden-xl-only" fab fixed bottom right>
      <v-icon>add</v-icon>
    </v-btn> -->
    <v-btn v-prms="{'id': '2c491b4c'}" @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: load, data: {}})" color="green" class="white--text hidden-md-only hidden-lg-only hidden-xl-only" fab fixed bottom right>
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog v-model="dialogSelecionarCategoria" scrollable max-width="500px">
      <v-card>
        <v-card-title style="padding: 15px 15px 15px 15px;">
          <strong>Para abrir um novo chamado selecione uma opção</strong>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="dialogSelecionarCategoria" style="height: 500px; padding: 0;">
          <view-detail notShowToolbar="true" :apiUrl="'chamado/ocorrencia'" apiParams="?fields=id,nome_categoria,nome_categoria_grupo,previsao_categoria,chamado_categoria_id,icone_categoria,cor_categoria&order=nome_categoria_grupo&limit=1000" notGetID="true">
            <template slot-scope="dados">
              <v-expansion-panel popout>
                <v-expansion-panel-content v-for="(grupo, grupoKey) in dados.content.data" :key="grupoKey" v-if="!grupo.chamado_categoria_id">
                  <div slot="header">
                    <v-icon medium style="padding-right: 6px;">{{grupo.icone_categoria}}</v-icon>
                    <strong style="font-size: 18px;">{{grupo.nome_categoria_grupo}}</strong>
                  </div>
                  <v-card>
                    <v-card-text style="padding: 0;">
                      <v-list dark one-line>
                        <v-divider></v-divider>
                        <template v-for="(categoria, categoriaKey) in dados.content.data" v-if="categoria.chamado_categoria_id == grupo.id || categoria.id == grupo.id">
                          <v-list-tile :key="categoriaKey" avatar ripple @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: props.refresh, data: {chamado_categoria_id: categoria.id}})" >
                            <v-list-tile-content>
                              <v-list-tile-title>
                                <v-icon medium style="padding-right: 6px;">{{grupo.icone_categoria}}</v-icon>
                                {{categoria.nome_categoria_grupo}}
                              </v-list-tile-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <v-list-tile-action-text>{{categoria.previsao_categoria}}</v-list-tile-action-text>
                              <v-icon medium style="padding-right: 6px;">timer</v-icon>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </view-detail>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click.native="dialogSelecionarCategoria = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {callApi} from '@/default/service/Api'
  import ViewDetail from '@/default/components/ViewDetail'
  import FilterByStatus from './FilterByStatus'
  import FilterByPeriod from './FilterByPeriod'
  export default {
    data () {
      return {
        page: this.mode === 'component' ? this.pageComponent : (this.$route.params.page || null),
        loading: false,
        headers: [
        {index: 0, align: 'center', text: 'Código', value: 'id', width: '40px', search: '', loadSearch: false},
        {index: 1, align: 'center', text: 'Status', value: 'nome_status', search: '', loadSearch: false, width: '120px'},
        {index: 2, align: 'center', text: 'Redes(s)', value: 'grupos', search: '', loadSearch: false, width: '140px'},
        {index: 3, align: 'center', text: 'Ocorrência', value: 'nome_categoria_grupos', search: '', loadSearch: false, width: '200px'},
        {index: 4, align: 'center', text: 'Solicitação', value: 'desc_chamado', sortable: false, noSearch: true, loadSearch: false, width: '140px'},
        {index: 5, align: 'center', text: 'Última Interação', value: 'ultima_anotacao', sortable: false, noSearch: true, loadSearch: false, width: '140px'},
        {index: 6, align: 'center', text: 'Equipe', value: 'nome_equipe', search: '', loadSearch: false, width: '100px'},
        // {index: 7, align: 'center', text: 'Responsável', value: 'nome_responsavel', search: '', loadSearch: false},
        // {index: 7, align: 'center', text: 'SLA', value: 'sla', search: '', loadSearch: false},
        // {index: 7, align: 'center', text: 'Impacto', value: 'nome_impacto', search: '', loadSearch: false},
        // {index: 9, align: 'center', text: 'Horas Gastas', value: 'duracao', search: '', loadSearch: false},
        // {index: 10, align: 'center', text: 'Previsão', value: 'previsao_chamado', search: '', loadSearch: false},
        // {index: 8, align: 'center', text: 'Abertura', value: 'time_abertura_format', search: '', loadSearch: false},
        // {index: 9, align: 'center', text: 'Dispositivos', value: 'device_count', search: '', loadSearch: false}
        // {index: 12, align: 'center', text: 'Solicitante', value: 'nome_criador', search: '', loadSearch: false}
        ],
        pesquisas: [],
        params: {},
        content: {},
        pagination: {},
        totalItens: 0,
        dialogSelecionarCategoria: false,
        editModal: false,
        modalForm: {},
        modalStore: 'chamados-todos'
      }
    },
    methods: {
      load: function ({reset}) {
        if (reset) { this.content = {} }
        var params = {}
        if (this.config && this.config.paramsFixed) {
          for (var fixedParam in this.config.paramsFixed) {
            params[fixedParam] = this.config.paramsFixed[fixedParam]
          }
        }
        if (this.params) {
          for (var param in this.params) {
            params[param] = this.params[param]
          }
        }
        this.loading = true
        callApi.get({uri: 'service-desk/ticket/filter/' + this.page, params: params, sucess: this.loadSucess, error: this.loadError})
      },
      loadSucess: function (data) {
        this.totalItens = data.data.total
        this.content = data.data
        this.loading = false
        this.$route.meta.title = 'Chamados (' + this.totalItens + ')'
      },
      loadError: function (error) {
        this.loading = false
        this.$WiMakeError({error: error, id: 104, title: 'Erro ao buscar chamados'})
      },
      setOneSearch: function (id, header) {
        if (header.search !== '') {
          var getHeader = {index: header.index, text: header.text, value: header.value, search: header.search, loadSearch: header.loadSearch}
          var jaPesquisado = false
          this.pesquisas.filter((p, k) => {
            if (p.value === id) {
              jaPesquisado = true
              this.pesquisas[k] = getHeader
            }
          })
          if (!jaPesquisado) {
            this.pesquisas.push(getHeader)
          }
          this.$forceUpdate()
        }
        this.params.page = 1
        this.pagination = {descending: true, sortBy: 'id', page: 1, rowsPerPage: this.params.limit || 50}
        this.setWhere()
      },
      unsetOneSearch: function (index, headerIndex) {
        this.pesquisas.splice(index, 1)
        this.headers[headerIndex].search = ''
        this.setWhere()
      },
      setWhere: function () {
        delete this.params['whereLike']
        var createWhere = ''
        this.pesquisas.filter((item, key) => {
          if (key) { createWhere += ';' }
          createWhere += item.value + ',' + item.search
        })
        this.params['whereLike'] = createWhere
        this.load({reset: false})
      },
      detailAction: function (row) {
        this.$router.push({name: 'chamado', params: {id: row.id}})
      },
      setPage: function (route) {
        this.page = route.params.page
        this.load({reset: true})
      },
      setSelectedStatus: function ({selectedStatusId, refresh}) {
        if (selectedStatusId && selectedStatusId.length > 0) {
          this.params['status'] = selectedStatusId.toString()
        } else {
          if (this.params['status']) {
            delete this.params.status
          }
        }
        if (refresh) {
          this.load({reset: true})
        }
      },
      setSelectedPeriod: function ({selectedPeriod, refresh}) {
        if (selectedPeriod && selectedPeriod.period && selectedPeriod.dateStart && selectedPeriod.dateEnd) {
          this.params['period'] = selectedPeriod.period
          this.params['dateStart'] = selectedPeriod.dateStart
          this.params['dateEnd'] = selectedPeriod.dateEnd
        } else {
          if (this.params['period']) {
            delete this.params.period
          }
          if (this.params['dateStart']) {
            delete this.params.dateStart
          }
          if (this.params['dateEnd']) {
            delete this.params.dateEnd
          }
        }
        if (refresh) {
          this.load({ reset: true })
        }
      }
    },
    computed: {
      loadPagination: {
        get: function () {
          return this.pagination
        },
        set: function (newValue) {
          this.pagination = newValue
          this.params['page'] = newValue.page
          this.params['limit'] = newValue.rowsPerPage
          if (newValue.sortBy) {
            let order = newValue.descending ? ',desc' : ',asc'
            this.params['order'] = newValue.sortBy + order
          } else {
            this.params['order'] = 'id,desc'
          }
          this.load({reset: false})
        }
      },
      config () {
        const page = this.$store.state.pages['chamados-todos'] || false
        if (!page) {
          // eslint-disable-next-line
          this.$router.push({ name: 'main' })
        }
        return page || {}
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    mounted: function () {
      this.pagination.descending = true
    },
    components: {
      ViewDetail,
      FilterByStatus,
      FilterByPeriod
    },
    watch: {
      '$route': 'setPage'
    },
    props: ['mode', 'pageComponent']
  }
</script>
<style>
.card--flex-toolbar {
  margin-top: -56px;
}
@media only screen and (min-width: 960px) {
  .area_datatable {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>