<template>
  <div>
    <v-subheader style="padding: 0;" class="hidden-xs-only hidden-sm-only">
      <v-spacer></v-spacer>
      <span v-if="listTags.length > 0 && !$vuetify.breakpoint.xsOnly">
        <span>Tags: </span>
        <span v-for="(tag, tagKey) in listTags" :key="tagKey">
          <v-chip
            v-if="tagKey == 0"
            small
            :color="tag.color"
            close
            @input="removeTag(tag)"
            class="ma-1 elevation-1"
          >
            <span :style="{ color: getTextColoe(tag.color) }">
              {{ tag.name }}
            </span>
          </v-chip>
        </span>
      </span>
      <v-menu v-if="listTags.length > 0" offset-y lazy>
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on">
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-actions>
            <v-icon>label</v-icon>
            <div style="padding: 5px 10px; color: gray;">
              <span>
                Lista de tags:
              </span>
            </div>
            <v-spacer></v-spacer>
            <v-btn outline small @click="addTag()">
              <v-icon>add</v-icon>
              <span v-t="'modules.talks.pages.talkView.talk.labels.addTag'">
        
              </span>
            </v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-text>
            <v-chip
              v-for="(tag, tagKey) in listTags"
              :key="tagKey"
              close
              :color="tag.color"
              @input="removeTag(tag)"
              class="ma-1 elevation-1"
            >
              <span :style="{ color: getTextColoe(tag.color) }">
                <span>
                  {{ tag.name }}
                </span>
              </span>
            </v-chip>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              outline
              small
              block
              @click="removeAllTags()"
            >
              <span>
                Remover todas as tags
              </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn
        v-if="talk.contact && talk.contact.type == 1 && !$vuetify.breakpoint.xsOnly"
        :icon="listTags.length > 0"
        :outline="listTags.length == 0"
        small
        @click="addTag()"
      >
        <v-icon>add</v-icon>
        <span
          v-if="listTags.length == 0"
          v-t="'modules.talks.pages.talkView.talk.labels.addTag'"
        ></span>
      </v-btn>
    </v-subheader>
    <v-dialog v-model="setPersonProfiledialog" max-width="350px" lazy>
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-icon>label</v-icon>
          <strong class="pl-2">
            Adicionar tags
          </strong>
          <v-spacer></v-spacer>
          <v-btn icon @click="setPersonProfiledialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="text-align: center;">
          <h2 class="pb-3">
            Para adicionar tags defina um perfil para o contato.
          </h2>
          <TalkContactSelectProfile
            
            :refresh="refresh"
            :contact="talk.contact"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import TalkContactSelectProfile from './TalkContact/TalkContactSelectProfile'
  export default {
    name: 'TalkToolbarCustomerProvider',
    data: () => ({
      drawerRight: false,
      setPersonProfiledialog: false
    }),
    computed: {
      ...mapState('Talk', ['talk']),
      listTags () {
        if (this.talk?.assets?.tags) {
          return this.talk?.assets?.tags
        }
        return []
      },
    },
    methods: {
      ...mapActions('Talk', ['getTalk']),
      getTextColoe: function (corHexadecimal) {
          // Converte a cor hexadecimal para RGB
          const r = parseInt(corHexadecimal.slice(1, 3), 16);
          const g = parseInt(corHexadecimal.slice(3, 5), 16);
          const b = parseInt(corHexadecimal.slice(5, 7), 16);

          // Calcula o valor de luminosidade usando a fórmula YIQ
          const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

          // Define um valor de limiar para determinar a cor do texto
          const limiar = 128;

          // Verifica se a luminosidade é maior que o limiar
          if (luminosidade > limiar) {
              // Se a cor de fundo for clara, retorna cor escura
              return "#000000"; // Cor preta
          } else {
              // Se a cor de fundo for escura, retorna cor clara
              return "#FFFFFF"; // Cor branca
          }
      },
      addTag: function () {
        if (this.talk?.contact?.person_id) {
          this.$WiListDialog({
            wiConfig: 'tag',
            callback: (tags) => {
              this.$WiApiPost({
                uri: 'register/person/tag',
                data: {
                  multiple: Array.isArray(tags) ? tags.map(tag => ({
                    talk_contact_id: this.talk.talk_contact_id,
                    tag_id: tag.id
                  })) : [{
                    talk_contact_id: this.talk.talk_contact_id,
                    tag_id: tags.id
                  }]
                },
                config: {
                  msgLoad: {
                    title: `Adicionando tag(s)`,
                    text: `Aguarde enquanto as tag(s) são adicionadas.`
                  },
                  msgSucess: {
                    title: 'Tag(s) adicionadas com sucesso!',
                    text: 'As tag(s) foram adicionadas com sucesso.'
                  },
                  msgError: {
                    title: 'Erro ao adicionar tag(s)',
                    text: 'Tente novamente ou contacte o suporte.'
                  }
                },
                callback: () => {
                  this.getTalk(this.talk.id)
                }
              })
            },
            mode: 'select'
          })
        } else {
          this.setPersonProfiledialog = true
        }
      },
      removeTag: function (tag) {
        this.$WiApiDelete({
          uri: `register/person/tag`,
          id: tag.person_tag_id,
          config: {
            msgLoad: {
              title: `Removendo tag`,
              text: `Aguarde enquanto a tag é removida.`
            },
            msgSucess: {
              title: 'Tag removida com sucesso!',
              text: 'A tag foi removida com sucesso.'
            },
            msgError: {
              title: 'Erro ao remover tag',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: false
          },
          callback: () => {
            this.getTalk(this.talk.id)
          }
        })
      },
      removeAllTags: function () {
        this.$WiApiPost({
          uri: `register/person/tag/clear`,
          data: {
            talk_contact_id: this.talk.talk_contact_id
          },
          config: {
            msgLoad: {
              title: `Removendo tag(s)`,
              text: `Aguarde enquanto a(s) tag(s) são removidas.`
            },
            msgSucess: {
              title: 'Tag(s) removida(s) com sucesso!',
              text: 'A(s) tag(s) foram removidas com sucesso.'
            },
            msgError: {
              title: 'Erro ao remover tag(s)',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja remover todas as tags?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          },
          callback: () => {
            this.getTalk(this.talk.id)
          }
        })
      }
    },
    props: ['refresh'],
    components: {
      TalkContactSelectProfile
    }
  }
</script>