export default [
  {
    value: 'id',
    text: 'ID',
    type: 'int',
    list: true,
    edit: false,
    search: true
  },
  {
    value: 'name',
    text: 'Nome da Categoria',
    type: 'text',
    size: [ 'xs12', 'sm6', 'md6', 'lg6' ],
    required: true,
    list: true,
    edit: true,
    search: true
  },
  {
    value: 'color',
    text: 'Cor da Categoria',
    type: 'color',
    size: [ 'xs12', 'sm3', 'md2', 'lg2' ],
    required: false,
    list: false,
    edit: true
  },
  {
    value: 'icon',
    text: 'Ícone da Categoria',
    type: 'wi-google-icons',
    size: [ 'xs12', 'sm3', 'md4', 'lg4' ],
    required: false,
    list: false,
    edit: true
  },
  {
    value: 'description',
    text: 'Observações',
    type: 'textarea',
    required: false,
    list: false,
    edit: true
  }
]
