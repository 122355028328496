<template>
  <div id="module">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    name: 'Module'
  }
</script>
