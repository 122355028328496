import Vue from 'vue'
import store from '@/default/store'
import axios from 'axios'

export class Error {
  static make (params) {
    var config = {
      idWeb: null,
      idApi: null,
      title: params.title || null,
      message: params.message,
      errorData: null,
      login: params.login || null
    }
    if (params.error.response) {
      switch (params.error.response.status) {
        case 401:
          config = this.error401(params, config)
          break
        case 402:
          config = this.error402(params, config)
          break
        case 403:
          config = this.error403(params, config)
          break
        case 404:
          config = this.error404(params, config)
          break
        case 409:
          config = this.error409(params, config)
          break
        case 422:
          config = this.error422(params, config)
          break
        case 500:
          config = this.error500(params, config)
          break
        default:
          config = this.error500(params, config)
          break
      }
    } else {
      config['idWeb'] = 2000 + params.id
      config['idApi'] = params.error.message
      config['errorData'] = params.error.stack
    }
    this.execute(config)
  }
  static execute (config) {
    this.showAlert(config)
    if (config.idWeb > 2000 && !store.state.url.debug) {
      this.sendLogToSlack(config)
      this.sendLogToLogger(config)
    }
  }
  static sendLogToSlack (config) {
    axios.post(
      'https://hooks.slack.com/services/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',
      JSON.stringify({
        text: this.createSlackMessage(config)
      })
    )
    if (Vue.prototype.$Sentry) {
      Vue.prototype.$Sentry.captureException(config)
    }
  }
  static sendLogToLogger (config) {
    if (!config.message) {
      config.message = config.idApi
    }
    // axios.post(store.state.system.log, this.createLogMessage(config))
  }
  static createSlackMessage (config) {
    let user = null
    let accountName = ''
    if (store.state.User && store.state.User.profile && store.state.User.profile.id) {
      user = store.state.User
      accountName = '- ' + user.account.name
    }
    var messageData = '*' + store.state.system.name + '* ' + accountName + ' : \n\n'
    messageData += '*ID Web*: ' + config.idWeb + '\n'
    messageData += '*ID API*: ' + config.idApi + '\n'
    messageData += '*Message*: ' + config.message + '\n'
    messageData += '*Error Data*: `' + JSON.stringify(config.errorData) + '`\n'
    messageData += '*Local URL*: ' + window.location.href + '\n'
    messageData += '*Request URL*: ' + config.requestURL + '\n'
    if (user && user.profile && user.profile.id) {
      let user = store.state.User
      messageData += '*User ID*: ' + user.id + '\n'
      messageData += '*User Name*: ' + user.name + '\n'
      messageData += '*User Profile Id*: ' + user.profile.id + '\n'
      messageData += '*User Profile Name*: ' + user.profile.name + '\n'
      messageData += '*User IP*: ' + user.last_ip + '\n'
      messageData += '*Version*: ' + user.version + '\n'
    } else {
      messageData += '*User Name*: ' + config.login + '\n'
    }
    messageData += '*Local Time*: ' + new Date().toLocaleString()

    return messageData
  }
  static createLogMessage (config) {
    let user = null
    let accountName = ''
    if (store.state.User && store.state.User.profile && store.state.User.profile.id) {
      user = store.state.User
      accountName = ' ' + user.account.name
    }
    let logMessage = {
      title: store.state.system.name + ' - ' + accountName,
      id_web: config.idWeb,
      id_api: config.idApi,
      message: config.message,
      error_data: config.errorData,
      local_url: window.location.href,
      request_url: config.requestURL || config?.errorData?.response,
      source: window.location.hostname,
      url_host: window.location.hostname,
      from_frontend: "true"
    }
    if (user && user.profile && user.profile.id) {
      let user = store.state.User
      logMessage['person_id'] = user.id
      logMessage['person_uuid'] = user.uuid
      logMessage['person_name'] = user.name
      logMessage['user_profile_id'] = user.profile.id
      logMessage['user_profile_name'] = user.profile.name
      logMessage['account_id'] = user.account.id
      logMessage['account_name'] = user.account.name
      logMessage['remote_addr'] = user.last_ip
      logMessage['version'] = user.version
    } else {
      logMessage['user_name'] = config.login
    }
    logMessage['local_time'] = new Date().toLocaleString()

    return logMessage
  }
  static showAlert (config) {
    Vue.swal({
      type: config.idWeb < 2000 ? 'warning' : 'error',
      title: config.title || 'Oops... Algo deu errado!',
      html: this.createAlertMessage(config)
    })
  }
  static createAlertMessage (config) {
    var text = ''
    text += config.message || ' Tente novamente ou contacte o suporte.'
    text += config.idWeb ? '<br>COD1: ' + config.idWeb : ''
    text += config.idApi ? ', COD2: ' + config.idApi : ''
    text += '<br>' + new Date().toLocaleString()
    return text
  }
  static error422MessageGenerator (errorResponse) {
    var errorMessage = ''
    if (errorResponse && errorResponse.data && errorResponse.data.errors) {
      errorMessage = '<ol>'
      for (var errorData in errorResponse.data.errors) {
        errorResponse.data.errors[errorData].filter((err) => {
          errorMessage += '<li>' + err + '</li>'
        })
      }
      errorMessage += '</ol>'
      return errorMessage
    } else {
      return null
    }
  }
  static error401 (params, config) {
    config['idWeb'] = 1000 + params.id
    config['idApi'] = params.error.response.status
    config['errorData'] = params.error.response.data
    return config
  }
  static error402 (params, config) {
    config['idWeb'] = 1000 + params.id
    return config
  }
  static error403 (params, config) {
    config['idWeb'] = 1000 + params.id
    config['idApi'] = params.error.response.status
    return config
  }
  static error404 (params, config) {
    config['idWeb'] = 1000 + params.id
    config['idApi'] = params.error.response.status
    return config
  }
  static error409 (params, config) {
    config['idWeb'] = 1000 + params.id
    config['idApi'] = params.error.response.status
    config['message'] = 'Este item possui um vínculo com outro item do sistema. Você pode desvincular ou contactar o suporte.'
    return config
  }
  static error422 (params, config) {
    config['idWeb'] = 1000 + params.id
    config['idApi'] = params.error.response.status
    config['message'] = this.error422MessageGenerator(params.error.response)
    return config
  }
  static error500 (params, config) {
    config['idWeb'] = 2000 + (params.id || 0)
    config['idApi'] = params.error.response.status
    if (params.error.response.data && params.error.response.data.trace) {
      var data = params.error.response.data
      delete data.trace
      config['errorData'] = data
    } else {
      config['errorData'] = params.error.response.data
    }
    config['requestURL'] = params.error.response.config.url || null
    return config
  }
  static codesDocs () {
    const codes = [
      {id: 1000, description: 'Erros programados. Erros causados por utilização errada.'},
      {id: 2000, description: 'Erros inesperados. Erros causados por falhas não programadas.'},
      {id: 100, description: 'Erros ao enviar um GET para a API.'},
      {id: 200, description: 'Erros ao enviar um POST para a API.'},
      {id: 300, description: 'Erros ao enviar um PUT para a API.'},
      {id: 400, description: 'Erros ao enviar um DELETE para a API.'},
      {id: 0, description: 'Login'},
      {id: 1, description: 'Component View Detail'},
      {id: 2, description: 'Component WiEditDialog'},
      {id: 3, description: 'Component WiList'},
      {id: 4, description: 'Component Chamados'},
      {id: 5, description: 'Actions to Store'},
      {id: 6, description: 'Component Network Device Indisponibilidades'},
      {id: 7, description: 'Component Mapa Topologia de rede'},
      {id: 8, description: 'Component Google Maps'},
      {id: 9, description: 'Component ChamadosKanbanV2'},
      {id: 10, description: 'Component Kanban'},
      {id: 11, description: 'Component DB Delete'}
    ]
    return codes
  }
}
