import {
  PERMISSION_PURCHASES_IMPORT_PURCHASE,
  PERMISSION_PURCHASES_IMPORT_PURCHASE_TO_XML
} from '@/default/constants/permissions'

var purchase = (name, title) => {
  var date = {
    open: {
      value: 'opened_at',
      text: 'Data de Criação',
      list: true
    },
    close: {
      value: 'closed_at',
      text: 'Data de Realização',
      list: true
    },
    cancel: {
      value: 'canceled_at',
      text: 'Data de Cancelamento',
      list: true
    }
  }
  var config = {
    id: {
      id: 'fbded5ac',
      create: '22982ab2',
      update: 'b41dcfec',
      delete: '80f80378'
    },
    name: 'purchase',
    list: true,
    title: {
      list: title,
      edit: {
        insert: 'Nova Compra',
        update: 'Editar Compra'
      }
    },
    apiUrl: 'purchases/purchase',
    urlDetails: {name: 'purchase', params: {}},
    params: {
      limit: 10
    },
    icon: 'shopping_cart',
    search: {
      placeholder: 'Pesquise ID, Razão Social, Status ou Nota Fiscal',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
    },
    imports: {
      permission: PERMISSION_PURCHASES_IMPORT_PURCHASE,
      title: 'Importar',
      origins: {
        xml: {
          title: 'Importar XML',
          permission: PERMISSION_PURCHASES_IMPORT_PURCHASE_TO_XML,
          description: 'Baixe o modelo de planilha e importe para o sistema.',
          image: '/static/icons/xml_icon.png',
          wiConfig: 'import-purchase-xml',
          onImported: ({ response: { data }, router }) => {
            console.log(data)
            router.push({ name: 'purchase', params: { id: data.id } })
          }
        }
      }
    },
    components: {
      list: {
        tableRow: () => import('@/modules/shared/pages/Businesses/Businesses')
      }
    },
    size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
    fields: [
      {
        value: 'id',
        text: 'ID',
        list: true
      },
      {
        value: 'person.name',
        text: 'Nome do Fornecedor',
        list: true
      },
      {
        value: 'status.name',
        text: 'Status da Compra',
        list: true
      },
      date[name],
      {
        value: 'date_end',
        text: 'Data de Entrega',
        list: true
      },
      {
        text: 'Informe os dados básicos desta compra.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'company_id',
        text: 'Empresa (Matriz ou Filial)*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        type: 'db-autocomplete',
        disabled: (form) => { return form.id != null },
        edit: true,
        apiUrl: 'register/person/company',
        apiParams: {
          fields: 'id,name',
          order: 'name,asc'
        },
        show: 'name'
      },
      {
        value: 'person_id',
        text: 'Fornecedor*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        type: 'wi-list-dialog',
        edit: true,
        wiConfig: 'provider',
        show: 'person.first_name',
        icon: 'person_outline'
      },
      {
        value: 'external_code',
        text: 'Código Externo',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'text',
        list: true,
        edit: true
      },
      {
        value: 'business_status_id',
        text: 'Status/Etapa*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        type: 'db-autocomplete',
        list: false,
        edit: true,
        apiUrl: 'purchases/config/status/purchase',
        apiParams: { fields: 'id,name', where: 'active,1', order: 'order,asc' },
        show: 'name',
        disabled: (form) => { return form.paid && form.stage === 2 }
      },
      {
        value: 'business_category_id',
        text: 'Categoria da Compra*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        type: 'db-autocomplete',
        list: false,
        edit: true,
        apiUrl: 'purchases/config/category/purchase',
        apiParams: {
          fields: 'id,name',
          order: 'name,asc'
        },
        show: 'name'
      },
      {
        value: 'invoice',
        text: 'N° Nota Fiscal',
        required: false,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'text',
        list: true,
        edit: true,
        search: true
      },
      {
        value: 'date_start',
        text: 'Data de Emissão',
        required: false,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'date',
        list: false,
        edit: true,
        search: false
      },
      {
        value: 'date_end',
        text: 'Data de Entrega',
        required: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'date',
        list: false,
        edit: true,
        search: false
      },
      {
        value: 'amount_freight',
        text: 'Valor Frete',
        type: 'money',
        prefix: 'R$',
        size: ['xs12', 'sm4', 'md2', 'lg2'],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'amount_discount',
        text: 'Valor de Desconto',
        type: 'money',
        prefix: 'R$',
        size: [
          'xs12',
          'sm4',
          'md2',
          'lg2'
        ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'collaborator_id',
        text: 'Colaborador Responsável',
        autocomplete: true,
        required: true,
        size: [
          'xs12',
          'sm12',
          'md6',
          'lg6'
        ],
        type: 'db-autocomplete',
        list: false,
        edit: true,
        apiUrl: 'register/person/collaborator',
        apiParams: {
          fields: 'id,name',
          order: 'name,asc'
        },
        show: 'name'
      },
      {
        value: 'make_stock',
        text: 'Integrar com Estoque',
        required: false,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'switch',
        list: false,
        edit: true
      },
      {
        text: 'Descreva abaixo os detalhes desta compra.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'description',
        text: 'Descrição da Compra',
        placeholder: 'Ex: Prazos, detalhes importanes, etc.',
        type: 'editor',
        list: false,
        edit: true
      },
      {
        text: 'Descreva abaixo as formas/condições de pagamento desta compra.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'information',
        text: 'Forma/Condições de Pagamento',
        placeholder: 'Ex: Parcelado em 3x.',
        type: 'editor',
        list: false,
        edit: true
      },
      {
        text: 'O campo abaixo pode ser usado para registrar observações sobre este compra.',
        type: 'subheader',
        edit: true
      },
      {
        value: 'observation',
        text: 'Observações da Compra',
        placeholder: 'Ex: Negociação iniciada em...',
        type: 'textarea',
        list: false,
        edit: true
      },
      {
        value: 'options',
        text: 'Opções',
        list: true,
        sortable: false
      }
    ]
  }
  if (name) {
    config.params['stage'] = name
  }
  return config
}

export default {
  open: purchase('open', 'Compras Em Aberto'),
  close: purchase('close', 'Histórico de Compras'),
  cancel: purchase('cancel', 'Compras Canceladas')
}
