<template>
  <div>
    <WNCalendar
      title="Mensagens agendadas"
      addButtonText="Novo agendamento"
      defaultCalendarView="dayGridMonth"
      @addEvent="addEvent"
      @getEvents="getEvents"
      @dateClick="dateClick"
      @eventDrop="eventDrop"
      @eventClick="eventClick"
    />
  </div>
</template>
<script>
  import WNCalendar from '@/default/component/WNCalendar/WNCalendar'
  export default {
    name: 'TalkScheduleCalendar',
    data: () => ({
      refresh: null,
      orderDialog: false
    }),
    methods: {
      getEvents({ fetchInfo, successCallback, errorCallback, refresh, setEvents }) {
        this.refresh = refresh
        this.$Api.get({
          uri: 'talks/schedule/by-period',
          params: {
            date_start: fetchInfo.startStr,
            date_end: fetchInfo.endStr
          },
          sucess: ({ data }) => {
            if (data?.events?.length > 0) {
              const events = data.events
              setEvents(events)
              successCallback(this.formatCalendarEvents(events))
              this.$forceUpdate()
            } else {
              setEvents([])
              successCallback([])
            }
          },
          error: (error) => {
            errorCallback(error)
          }
        })
      },
      formatCalendarEvents: function (events) {
        let eventsFormated = [];
        events.map(event => {
          let date = event.date_time
          let title = event.contact.name
          // if (event.time_start) {
          //   date = event.date_start + ' ' + event.time_start
          // }
          eventsFormated.push({
            id: event.id,
            title: title,
            date: date,
            color: event.status.color
          })
        })
        return eventsFormated
      },
      dateClick: function (date) {
        this.createNewOrder(date.dateStr)
      },
      eventClick: function (event) {
        this.$WiEditDialog({
          wiConfig: 'talk-schedule',
          data: event,
          onSubmit: () => {
            this.refresh()
          },
          redirectOnSubmit: false
        })
      },
      eventDrop: function ({ event, newDate}) {
        this.$WiApiPut({
          uri: `talks/schedule/${event.id}`,
          data: {
            id: event.id,
            date_time: newDate
          },
          callback: () => {
            this.refresh()
          }
        })
      },
      addEvent: function () {
        this.createNewOrder()
      },
      createNewOrder: function (startDate = null) {
        if (startDate) {
          const dates = startDate.split('T')
          if (dates.length > 1) {
            const time = dates[1].split('-')[0]
            startDate = dates[0] + ' ' + time
          } else {
            startDate = startDate + ' 08:00:00'
          }
        }
        this.$WiEditDialog({
          wiConfig: 'talk-schedule',
          data: {
            date_time: startDate
          },
          onSubmit: () => {
            this.refresh()
          },
          redirectOnSubmit: false
        })
      },
      whathRoute: function () {
        if (this.$route.name == 'talk-schedules-calendar') {
          this.orderDialog = true
        } else {
          this.refresh()
          this.orderDialog = false
        }
      }
    },
    mounted: function () {
      this.whathRoute()
    },
    components: {
      WNCalendar
    },
    watch: {
      $route: function () {
        this.whathRoute()
      }
    }
  }
</script>