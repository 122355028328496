<template>
  <div>
    <v-card v-if="status && status.code != 'closed' && status.code != 'canceled'" flat>
      <v-card-text style="text-align: center;">
        <h3>Por favor selecione uma opção</h3>
      </v-card-text>
      <v-card-actions style="padding-bottom: 25px;">
        <v-spacer></v-spacer>
        <v-btn block outline large color="green" @click="selectCategory()">
          <v-icon>category</v-icon>
          <span> Selecionar agora </span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-list v-else class="diagnostic-set-category" dense>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon large v-if="data.icon" :color="data.color">
            {{ data.icon }}
          </v-icon>
          <v-icon large v-else>military_tech</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title> Nova Categoria: </v-list-tile-sub-title>
          <v-list-tile-title>
            <strong v-if="data.name">
              {{ data.name }}
            </strong>
            <strong v-else>
              Não definido
            </strong>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </div>
</template>
<script>
  export default {
    name: 'FormReply',
    methods: {
      updateProcess: function (response) {
        if (response?.id) {
          this.$emit('updateProcess', {
            data: {
              category_id: response.id
            },
            isValid: !!response.id,
            autoSendProcessStage: true
          })
        }
      },
      selectCategory: async function () {
        const categoriesFilter = this.stage?.stage?.config_category_ids
        const filters = {}
        console.log(categoriesFilter);
        // if (categoriesFilter && categoriesFilter.length > 0) {
          filters.ids = categoriesFilter
        // }
        this.createReplyLoading = true
        this.$WiListDialog({
          wiConfig: 'config-category-diagnostic',
          callback: this.updateProcess,
          mode: 'select',
          filter: filters
        })
        this.createReplyLoading = false
      },
      selected: function (selected) {
        this.updateProcess({
          category_id: selected.id
        })
      }
    },
    props: {
      status: {
        type: Object,
        default: () => ({})
      },
      stage: {
        type: Object,
        default: () => ({})
      },
      data: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
<style scoped>
  .diagnostic-set-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>
