<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-progress-circular
          v-if="status.code == 'opened'"
          :indeterminate="true"
          :size="30"
          :width="3"
          color="white"
        ></v-progress-circular>
        <v-avatar v-else>
          <v-icon color="white" large>
            {{ status.icon }}
          </v-icon>
        </v-avatar>
      </div>
    </template>
    <span v-text="status.name"></span>
  </v-tooltip>
</template>
<script>
  export default {
    name: 'ProcessStatus',
    props: {
      status: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
