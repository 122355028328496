<template>
  <v-card class="business-address__selected" flat>
    <v-card-text class="business-address__selected-content">
      <v-list two-line subheader>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon class="blue white--text">location_on</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <div :style="{'font-weight': address.is_master ? 'bold' : ''}">
                <span v-if="address.address"> {{ address.address }}, </span>
                <span v-if="address.number"> {{ address.number }} </span>
                <span v-if="address.complement"> - {{ address.complement }}</span>
              </div>
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <span v-if="address.district"> {{ address.district }}, </span>
              <span v-if="address.zip_code"> {{ address.zip_code }} </span>
              <span v-if="address.city && address.city.name"> {{ address.city.name }} <span v-if="address.city && address.city.state">/ {{ address.city.state.code }}</span></span>
            </v-list-tile-sub-title>
            <v-list-tile-sub-title><strong>Descrição</strong>: {{address.description || '- - -'}} </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" ripple outline small @click="editBusinessLocation()">
                  <v-icon>my_location</v-icon>
                  <span> Localização </span>
                </v-btn>
              </template>
              <span>Editar localização</span>
            </v-tooltip>
          </v-list-tile-action>
          <v-list-tile-action>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" ripple icon @click="openInGoogleMaps()">
                  <v-icon>map</v-icon>
                </v-btn>
              </template>
              <span>Abrir endereço no Google Maps</span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
  import { OpenInGoogleMaps } from '@/default/plugins/Utils/OpenInGoogleMaps'
  import BusinessAddressMixin from './BusinessAddressMixin'
  export default {
    name: 'BusinessAddressSelected',
    methods: {
      openInGoogleMaps: function () {
        OpenInGoogleMaps.open(this.address)
      },
      editBusinessLocation: function () {
        const { id, latitude, longitude, location_name } = this.business
        this.$WiEditDialog({
          wiConfig: 'well-location',
          data: { id, latitude, longitude, location_name },
          onSubmit: (response) => {
            this.$emit('onSubmit', response)
          }
        })
      }
    },
    mixins: [BusinessAddressMixin]
  }
</script>
<style scoped>
  .business-address__selected {
    text-align: center !important;
    padding: 0px !important;
  }
  .business-address__selected-content {
    padding: 0px 0 1px 0 !important;
  }
</style>