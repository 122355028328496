<template>
  <WiView
    index="host-detail-dialog"
    :title="title"
    :apiUrl="apiUrl"
    :notLoad="true"
    :notGetID="true"
    @afterLoad="afterLoad"
    apiParams="?limit=100&whereNull=grupo_dados_id&join=grupos&order=nome_disp,asc"
  >
    <template slot="toolbarItensBefore">
      <span v-if="socket.status">Ao vivo</span>
      <span v-else>Não conectado</span>
      <span 
        v-if="socket.status"
        class="networks__live-status-label"
      ></span>
      <span 
        v-else
        class="networks__disconected-status-label"
      ></span>
    </template>
    <template>
      <v-container fluid fill-height class="py-1 px-1">
        <v-layout row wrap>
          <div style="width: 100%;">
            <GruposRealTime
              v-if="networks.length > 0"
              style="width: 100%;"
              :grupos="networks"
              @action="setNetwork"
            ></GruposRealTime>
            <div v-else style="width: 100%;">
              <!-- Mensagem dizendo que nenhuma rede foi encontrada e subtitulo dizendo para tentar novamente. centralizada -->
              <v-layout row wrap align-center justify-center>
                <v-flex xs12>
                  <v-card class="text-xs-center">
                    <v-card-title class="headline">Nenhuma rede encontrada</v-card-title>
                    <v-card-text>
                      <span>Tente novamente mais tarde</span>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </v-layout>
        <div>
          <v-snackbar
            bottom absolute right :timeout="20000"
            v-for="(snack, snaskIndex) in snaks"
            :key="snaskIndex" :color="snack.color"
            v-model="snack.active"
          >
            {{ snack.text }}
          </v-snackbar>
        </div>
      </v-container>
    </template>
  </WiView>
</template>
<script>
  import GruposRealTime from '@/modules/network/components/GruposRealTime'
  export default {
    name: 'OLTsDashboard',
    data: () => ({
      snaks: [],
      devices: [],
      refresh: null,
      networks: [],
      title: 'Minha Rede',
      apiUrl: 'topologia/dados',
      background: `static/background/background-gradiente-1.png`,
      socket: {
        url: null,
        status: false
      },
      interval: null,
      networkId: 1
    }),
    methods: {
      startInterval: function () {
        this.interval = setInterval(() => {
          this.refresh()
        }, 120000)
      },
      stopInterval: function () {
        clearInterval(this.interval)
      },
      socketConnect: function () {
        this.socket.status = window?.Echo?.connector?.socket?.id ? true : false
        window.Echo.connector.socket.on('connect', () => {
          this.socket.status = true
        })
        window.Echo.connector.socket.on('disconnect', () => {
          this.socket.status = false
        })
      },
      setSocketUrl: function () {
        this.socket.url = `${this.$Auth.account().id}.network.${(this.networkId)}`
      },
      subscribeOnNetworkStatusChange: function () {
        window.Echo.private(this.socket.url)
        .listen('.status.changed', (response) => {
          setTimeout(() => {
            this.refresh()
            this.snaks.push({
              active: true,
              text: response.up || response.down || response.ticket || response.device,
              color: response.up ? 'green' : response.down ? 'red' : response.ticket || response.device ? 'blue' : 'grey'
            })
          }, 100)
        })
      },
      unsubscribeOnNetworkStatusChange: function () {
        window.Echo.leave(this.socket.url)
      },
      afterLoad: function ({ content, refresh }) {
        this.refresh = refresh
        if (this.$route?.query?.network_id) {
          this.networks = content.grupos
        } else {
          this.networks = content
        }
      },
      setNetwork: function (network) {
        if (network && network.funcao_tipo == 1) {
          this.apiUrl = `grupo/grupo-conexao/${network.id}`
          setTimeout(() => {
            this.$router.push({
              name: 'networks-dashboard',
              query: {
                network_id: network.id,
                network_name: network.nome_grupo
              }
            })
            this.title = network.nome_grupo
          }, 100);
        } else {
          this.$router.push({
            name: 'grupo-mapa-dispositivos',
            params: {
              id: network.id
            }
          })
        }
      },
      setApiUrl: function () {
        this.unsubscribeOnNetworkStatusChange()
        if (this.$route?.query?.network_id) {
          this.apiUrl = `grupo/grupo-conexao/${this.$route.query.network_id}`
          this.networkId = this.$route.query.network_id
        } else {
          this.apiUrl = 'topologia/dados'
          this.networkId = 1
        }
        console.log(this.apiUrl)
        this.setSocketUrl()
        this.subscribeOnNetworkStatusChange()
        if (this.$route?.query?.network_name) {
          this.title = this.$route.query.network_name
        } else {
          this.title = 'Minha Rede'
        }
      },
      openTicketsList: function () {
        this.$WiListDialog({
          wiConfig: 'ticket-list',
          filter: {
            device: this.$route.params.id,
            stage: 'opened'
          }
        })
      }
    },
    created () {
      this.setApiUrl()
    },
    mounted () {
      this.setSocketUrl()
      this.socketConnect()
      this.startInterval()
      this.subscribeOnNetworkStatusChange()
    },
    beforeDestroy () {
      this.stopInterval()
      this.unsubscribeOnNetworkStatusChange()
    },
    watch: {
      $route: function (route) {
        console.log(route)
        this.setApiUrl()
      }
    },
    components: {
      GruposRealTime
    }
  }
</script>
<style scoped>
  .networks__disconected-status-label {
    width: 20px;
    height: 20px;
    background: rgb(170, 3, 3);
    border-radius: 100%;
    margin: 0 10px;
  }
  .networks__live-status-label {
    width: 20px;
    height: 20px;
    background: rgb(3, 170, 3);
    border-radius: 100%;
    margin: 0 10px;
    animation: pulsee 1s infinite;
  }
  @keyframes pulsee {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 255, 55, 0.637);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
    }
  }
</style>