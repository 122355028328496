<template>
  <div style="width: 100%; max-height: 500px;">
    <div v-if="montaMapa(lat, lng)">
      <!-- <v-btn @click="mostraURL()">URL</v-btn>  -->
      <iframe :src="montaMapa(lat, lng)" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>
    <div v-else>
      <v-alert style="margin:25px;" :value="true" color="yellow" >
        <v-icon color="black">info</v-icon>
        <span style="color: black;"> Para visualizar o mapa é necessário cadastrar a longitude e latitude do mesmo.</span>
      </v-alert>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        url: ''
      }
    },
    methods: {
      mostraURL: function () {
        console.log(this.url)
      },
      montaMapa: function (lat, lng) {
        if ((lat === null) || (lng === null)) {
          return false
        } else {
          this.url = 'https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d14982.41054353936!2d' + lng + '!3d' + lat + '!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-BR!2sbr!4v1531493250294'
          return this.url
        }
      }
    },
    props: ['lat', 'lng']
  }
</script>