import { FinanceExport } from './FinanceExport'
import { PERMISSION_FINANCE_EXPORT_IN_XLSX } from '@/default/constants/permissions'

export class FinanceInExport extends FinanceExport {
  constructor () {
    super()
    this.id = PERMISSION_FINANCE_EXPORT_IN_XLSX
    this.title = 'Exportar Receitas'
    this.apiUrl = 'finance/export/in'

    // Sobrescrevendo configuração da categoria para mostrar apenas categorias de receita
    this.fieldsEdit.finance_category_id.api = {
      url: 'finance/category/in',
      params: {
        fields: 'id,name',
        order: 'name,asc'
      }
    }

    // Removendo opção de fornecedor do tipo de pessoa
    this.fieldsEdit.person_type.options = [
      { value: 'customer', text: 'Cliente' },
      { value: 'collaborator', text: 'Colaborador' }
    ]

    this.setFields(this.fieldsEdit)
  }
} 