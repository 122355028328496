<template>
  <v-alert style="margin: 0;" :value="true" :color="color">
    <h2 v-if="message" v-html="message"></h2>
    <span v-else>
      <h2>Mensagem não definida!</h2>
    </span>
  </v-alert>
</template>
<script>
export default {
  name: 'ShowMessage',
  computed: {
    color () {
      return this.stage?.stage?.config?.color
    },
    message () {
      return this.stage?.stage?.config?.message
    }
  },
  methods: {
    updateProcess: function (updateProcess) {
      this.$emit('updateProcess', updateProcess)
    }
  },
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
    stage: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style scoped>
  .diagnostic-set-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>