var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WiView',{attrs:{"title":"Detalhes do processo","apiUrl":"processes/process","apiParams":"?with=stages;stages.results;stages.results.result_stage:id,type_id,order","notLoad":true},scopedSlots:_vm._u([{key:"toolbarItensBefore",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
var loading = ref.loading;
return [_c('v-btn',{attrs:{"outline":"","small":"","color":"white","loading":loading},on:{"click":function($event){return _vm.listStage(content)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("list")]),_c('span',[_vm._v(" Listar Etapas")])],1),_c('v-btn',{attrs:{"outline":"","small":"","color":"white","loading":loading},on:{"click":function($event){return _vm.addStage(content, refresh)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("add")]),_c('span',[_vm._v(" Adicionar Etapa")])],1)]}},{key:"default",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
var loading = ref.loading;
return [_c('div',{staticStyle:{"padding":"10px 10px 0 10px"}},[_c('ProcessStagesFlowcharts',{attrs:{"loading":loading,"process":content},on:{"selectStage":_vm.selectStage}}),_c('ProcessStageDialog',{attrs:{"stage":_vm.processStage},on:{"refresh":refresh},model:{value:(_vm.processStageDialog),callback:function ($$v) {_vm.processStageDialog=$$v},expression:"processStageDialog"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }