const category = (name, title) => {
  return {
    id: 'a02e979e',
    list: true,
    title: {
      list: 'Status/Etapas da ' + title,
      edit: {
        create: 'Nova Status/Etapas da ' + title,
        update: 'Editar Status/Etapas da ' + title
      }
    },
    icon: 'code',
    apiUrl: 'clinic/config/status/' + name,
    fields: [
      {value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: true},
      {value: 'name', text: 'Nome do Status*', type: 'text', size: ['xs12', 'sm6', 'md6', 'lg7'], required: true, list: true, edit: true, search: true},
      {value: 'stage', text: 'Tipo de Status', placeholder: 'Como este status irá influenciar o negócio.', type: 'select', required: true, size: ['xs12', 'sm12', 'md3', 'lg3'], options: [{value: 1, text: 'Em Andamento'}, {value: 2, text: 'Concluído'}, {value: 3, text: 'Cancelado'}], edit: {create: true}},
      {
        value: 'active',
        text: 'Está ativo?',
        required: false,
        size: ['xs12', 'sm12', 'md3', 'lg2'],
        type: 'switch',
        list: false,
        edit: true
      },
      {value: 'color', text: 'Cor do Status', type: 'color', size: ['xs12', 'sm6', 'md3', 'lg3'], required: false, list: false, edit: true},
      {value: 'icon', text: 'Ícone do Status', type: 'wi-google-icons', size: ['xs12', 'sm6', 'md3', 'lg4'], required: false, list: false, edit: true},
      {value: 'order', text: 'Ordem do Status', type: 'int', size: ['xs12', 'sm6', 'md3', 'lg3'], required: false, list: false, edit: true},
      {
        value: 'set_deadline',
        text: 'Exigir data limite',
        required: false,
        size: ['xs12', 'sm12', 'md3', 'lg2'],
        type: 'switch',
        list: false,
        edit: true
      },
      {value: 'description', text: 'Observações', type: 'textarea', required: false, list: true, edit: true}
    ]
  }
}

export default {
  productTransferIn: category('product-transfer-in', 'Transferência para o estoque'),
  productTransferInCollaborator: category('product-transfer-in-collaborator', 'Transferência para o colaborador'),
  productTransferInCustomer: category('product-transfer-in-customer', 'Transferência para o cliente'),
  productTransferOut: category('product-transfer-out', 'Transferência do estoque'),
  productTransferOutCollaborator: category('product-transfer-out-collaborator', 'Transferência do estoque do colaborador'),
  productTransferOutCustomer: category('product-transfer-out-customer', 'Transferência do cliente'),

  productEntry: category('product-entry', 'Movimentação de Entrada'),
  productEntryCollaborator: category('product-entry-collaborator', 'Movimentação para o colaborador'),
  productEntryCustomer: category('product-entry-customer', 'Movimentação para o cliente'),
  productRemoval: category('product-removal', 'Movimentação do estoque'),
  productRemovalCollaborator: category('product-removal-collaborator', 'Movimentação do estoque do colaborador'),
  productRemovalCustomer: category('product-removal-customer', 'Movimentação do cliente')
}
