import { BusinessExport } from "@/modules/business/config/BusinessExport"


export class SaleExportFilters extends BusinessExport {
  constructor () {
    super()
    this.id = 'fbded5ac'
    this.apiUrl = 'sales/sale-export'
    this.fieldsEdit.status_id.apiUrl = 'sales/config/status/sale' 
    this.fieldsEdit.category_id.apiUrl = 'sales/config/category/sale'
  }
}
