<template>
  <view-detail index="diagnostic" title="Detalhes do Diagnóstico" apiUrl="networks/test-management-vtal" apiParams="?with=finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;host;host.active_devices" store="diagnostic">
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
    </template>
    <template slot-scope="{ content, refresh }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="content.host" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessHosts
              :business="content"
              :hosts="[{host: content.host}]"
              @onSubmit="refresh"
            ></BusinessHosts>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessHosts from '@/modules/business/pages/Business/BusinessHosts'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  export default {
    name: 'Contract',
    methods: {
      setContractLabelsText: function () {
        this.labels.category.text = 'Categoria/Tipo de Diagnóstico:'
        this.labels.dateStart.text = 'Início do Diagnóstico:'
        this.labels.dateEnd.text = 'Término do Diagnóstico:'
        this.labels.duration.text = 'Duração do Diagnóstico:'
      }
    },
    created: function () {
      this.setContractLabelsText()
    },
    components: {
      BusinessInfo,
      BusinessForm,
      BusinessHosts,
      BusinessActions,
      BusinessObservation
    },
    mixins: [BusinessMixin]
  }
</script>
