import GraduationsComission from '@/modules/register/config/Person/Customer?url=GraduationsComission'

GraduationsComission.title = {
  list: 'Comissão de Formatura',
  edit: {
    insert: 'Novo Alunos/cliente',
    update: 'Editar Alunos/cliente'
  }
}
GraduationsComission.icon = 'person'
GraduationsComission.apiUrl = 'graduations/register/commission'
GraduationsComission.urlDetails.name = 'customer'
GraduationsComission.fields[25].size = ['xs12', 'sm12', 'md5', 'lg5']
GraduationsComission.fields.splice(26, 0, {
  value: 'commission',
  text: 'É da comissão de formatura?',
  placeholder: 'Comissão de formatura',
  type: 'select',
  size: ['xs12', 'sm12', 'md3', 'lg3'],
  options: [
    {value: 1, text: 'Sim'},
    {value: -1, text: 'Não'}
  ],
  edit: true,
  showField: (form) => {
    return form.type === 'Fisica' || form.type === 'Juridica'
  }
})

export default GraduationsComission
