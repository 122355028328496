import menu from '@/modules/network/menu'
import router from '@/modules/network/router'
import config from '@/modules/network/config'

export default {
  id: 'd237fbcd-2a0d-42eb-beef-409d29813ad3',
  name: 'network',
  title: 'Redes',
  description: 'Módulo Gestão de Redes',
  icon: 'wises-technology-module-network.png',
  iconSystem: 'wise-network-system-network.png',
  color: '#367da6',
  menu,
  router,
  config
}
