import Vue from 'vue'
// import store from '@/default/store'

Vue.directive('prms', {
  inserted: function (el, { value }, { context }) {
    var checkPermission = context.$store.getters['User/checkPermission']
    if (checkPermission && !value.notCheck) {
      var status = true
      if (!value.id || checkPermission(value.id)) {
        status = false
      }
      if (status) {
        if (value.mode === 'disabled') {
          el.disabled = true
          el.setAttribute('style', '-webkit-box-shadow: none !important; box-shadow: none !important; pointer-events: none;')
          el.classList.add('permission_disabled')
        } else {
          el.classList.add('permission_hide')
          el.style.display = 'none'
          el.disabled = true
        }
      }
    }
  },
  watch: {
    value: function () {
      this.$forceUpdate()
    }
  }
})
Vue.directive('wi-mac-address', {
  // inserted: function (el, binding, vnode) {
  //   formatMacAddress(el, binding, vnode)
  // },
  // update: function (el, binding, vnode) {
  //   formatMacAddress(el, binding, vnode)
  // },
  // bind: function (el, binding, vnode) {
  //   formatMacAddress(el, binding, vnode)
  //   el.value = 'asdasdas'
  //   binding.value = 'asdasdas'
  //   vnode.value = 'asdasdas'
  // }
  update(el, binding) {
    var event = new Event("input", { bubbles: true });
    let maxChars = binding.value || 5;
    if (el?.value?.length > maxChars) {
      el.value = el.value.substr(0, maxChars);
      el.dispatchEvent(event);
    }
    formatMacAddress(el, binding)
  }
})

const formatMacAddress = (el, binding) => {
  console.log(el, el.value, binding.value);
}

export default {}
