<template>
  <v-card class="elevation-17" value="teste1">
    <ticket-header :dados="dados"></ticket-header>
    <v-divider></v-divider>
    <v-layout row wrap>
      <v-flex xs12 sm12 md4 lg4>
        <v-list subheader two-line style="padding: 0 5px 0 0;">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/fingerprint_96.png" alt="Código/Ticket Externo">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Código/ID:</v-list-tile-sub-title>
              <v-list-tile-title>
                <strong>#{{dados.content.id}}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="dados.content.id">
              <copy-text-to-clipboard :text="'#' + dados.content.id"></copy-text-to-clipboard>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar :style="{background: !dados.content.person_responsible_id ? '#bf360c !important' : ''}" :color="!dados.content.person_responsible_id ? '#CECECE' : ''">
            <v-list-tile-avatar>
              <v-avatar>
                <img v-if="dados.content.person_responsible_id" src="static/icons/monitor/lecturer_96.png" alt="Responsável">
                <img v-else src="static/icons/monitor/lecturer_96.png" alt="Responsável">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content v-if="!dados.content.person_responsible_id">
              <v-list-tile-title>Responsável não definido</v-list-tile-title>
              <v-list-tile-sub-title>Clique para definir um responsável</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-content v-else>
              <v-list-tile-sub-title>Responsável:</v-list-tile-sub-title>
              <v-list-tile-title>{{dados.content.nome_responsavel}}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-menu :disabled="dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3" offset-y bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat v-prms="{'id': 'cf5da7df'}" :disabled="ticketIsClosed(dados.content)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">edit</v-icon>
                      </template>
                      <span>Alterar Responsável.</span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile v-if="dados.content.person_responsible_id != User.id" @click="alterarChamado(dados.refresh, 'assumir', {'assumir_chamado': true})">
                    <v-list-tile-avatar>
                      <v-icon>person</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Assumir Este Chamado</v-list-tile-title>
                  </v-list-tile>
                  <v-divider v-if="dados.content.person_responsible_id != User.id"></v-divider>
                  <v-list-tile @click="$WiEditDialog({wiConfig: 'chamados-transferir', onSubmit: dados.refresh, data: {id: dados.content.id, chamado_transferir: true}})">
                    <v-list-tile-avatar>
                      <v-icon>transform</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Transferir Chamado</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <v-menu v-if="dados.content.person_responsible_id" offset-y bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">info</v-icon>
                      </template>
                      <span>Informações do responsável.</span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="$router.push({name: 'collaborator', params: {id: dados.content.person_responsible_id}})">
                    <v-list-tile-avatar>
                      <v-icon>person</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Perfil de {{dados.content.nome_responsavel}}</v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile @click="ticketResponsibleDialog = true">
                    <v-list-tile-avatar>
                      <v-icon>format_list_bulleted</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Ver histórico de responsáveis</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <TicketResponsiblesDialog :ticket="dados.content" :active="ticketResponsibleDialog" v-on:close="() => { ticketResponsibleDialog = false }"></TicketResponsiblesDialog>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar >
                <img src="static/icons/monitor/management_96.png" alt="Equipe Responsável">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Equipe Responsável:</v-list-tile-sub-title>
              <v-list-tile-title>{{dados.content.nome_equipe}}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="$WiEditDialog({wiConfig: 'ticket-team', onSubmit: dados.refresh, data: {id: dados.content.id, team_id: dados.content.team_id}})" v-prms="{'id': '2f84111b'}" :disabled="ticketIsClosed(dados.content)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Alterar Equipe</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="$router.push({name: 'team', params: {id: dados.content.team_id}})">
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>Informações da Equipe</span>
              </v-tooltip> -->

              <v-menu v-if="dados.content.team_id" offset-y bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">info</v-icon>
                      </template>
                      <span>Informações da equipe.</span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="$router.push({name: 'team', params: {id: dados.content.team_id}})">
                    <v-list-tile-avatar>
                      <v-icon>people</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Informações da Equipe</v-list-tile-title>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile @click="ticketTeamDialog = true">
                    <v-list-tile-avatar>
                      <v-icon>format_list_bulleted</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Ver histórico de equipes</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <TicketTeamsDialog
                :ticket="dados.content"
                :active="ticketTeamDialog"
                v-on:close="() => { ticketTeamDialog = false }"
              ></TicketTeamsDialog>

            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar >
                <img v-if="dados.content.sexo_criador == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="Solicitante">
                <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="Solicitante">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Solicitante:</v-list-tile-sub-title>
              <v-list-tile-title>{{dados.content.nome_criador}}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="$router.push({name: 'collaborator', params: {id: dados.content.person_creator_id}})">
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>Informações do solicitante</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon large color="orange">bolt</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Motivo:</v-list-tile-sub-title>
              <v-list-tile-title>
                <strong v-if="dados.content.justify">
                  {{dados.content.justify.name}}
                </strong>
                <span v-else>- - -</span>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on" icon flat @click="changeJustify(dados.content, dados.refresh)"
                    v-prms="{'id': '081b9bc8'}" :disabled="ticketIsClosed(dados.content)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Alterar Motivo</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider class="hidden-md-only hidden-lg-only hidden-xl-only"></v-divider>
          <v-divider></v-divider>    
          <v-list-tile avatar v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}">
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/marker-96.png" alt="Localizações">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Localizações:</v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 20px;">
                <span v-text="dados.content.location_count"></span>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="ticketLocationsAction(dados)">
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>Ver localizações deste chamado</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="dialogLocationsMap = true">
                    <v-icon>map</v-icon>
                  </v-btn>
                </template>
                <span>Ver localizações no mapa</span>
              </v-tooltip>
              <dialog-locations-map :ticket="dados.content.id" :active="dialogLocationsMap" v-on:close="() => { dialogLocationsMap = false }"></dialog-locations-map>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}" class="hidden-md-only hidden-lg-only hidden-xl-only"></v-divider>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4>
        <v-list subheader two-line style="padding: 0 5px 0 5px;">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon v-if="dados.content.icone_impacto < 1" large :color="dados.content.cor_impacto">filter_none</v-icon>
              <v-icon v-else-if="dados.content.icone_impacto > 9" large :color="dados.content.cor_impacto">filter_9_plus</v-icon>
              <v-icon v-else large :color="dados.content.cor_impacto">filter_{{dados.content.icone_impacto}}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                <span>Impacto </span>
                <span
                  v-if="dados.content.host_count && dados.content.host_count > 0"
                >real/</span>
                <span>estimado:</span>
              </v-list-tile-sub-title>
              <v-list-tile-title
                v-if="dados.content.host_count && dados.content.host_count > 0"
              >
                <strong :style="{ color: dados.content.host_count >= 100 ? 'red' : 'green' }"> {{dados.content.host_count | number}} clientes</strong>
              </v-list-tile-title>
              <v-list-tile-title>
                <strong> {{dados.content.nome_impacto}} </strong>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn 
                    v-on="on"
                    icon
                    flat
                    @click="$WiEditDialog({
                      wiConfig: 'ticket-impact-change',
                      onSubmit: dados.refresh,
                      data: {
                        id: dados.content.id,
                        chamado_impacto_id: dados.content.chamado_impacto_id
                      }
                    })"
                    v-prms="{'id': 'd499d9f9'}"
                    :disabled="ticketIsClosed(dados.content)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Alterar Impacto</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    flat
                    @click="$WiListDialog({ wiConfig: 'ticket-host', filter: { ticket_id: dados.content.id } })"
                  >
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>Listar clientes impactados</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>   
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/schedule_96.png" alt="Aberto em:">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Aberto em:</v-list-tile-sub-title>
              <v-list-tile-title>
                <strong>{{dados.content.time_abertura_format}}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>    
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/chat_room_96.png" alt="Aberto em:">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Última Interação:</v-list-tile-sub-title>
              <v-list-tile-title>
                <strong>{{dados.content.last_event_time_format}}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>    
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar >
                <v-icon v-if="dados.content.icone_urgencia < 1" large :color="dados.content.cor_urgencia">filter_none</v-icon>
                <v-icon v-else-if="dados.content.icone_urgencia > 9" large :color="dados.content.cor_urgencia">filter_9_plus</v-icon>
                <v-icon v-else large :color="dados.content.cor_urgencia">filter_{{dados.content.icone_urgencia}}</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Urgência:</v-list-tile-sub-title>
              <v-list-tile-title><strong>{{dados.content.nome_urgencia}}</strong></v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="$WiEditDialog({wiConfig: 'ticket-urgency-change', onSubmit: dados.refresh, data: {id: dados.content.id, chamado_urgencia_id: dados.content.chamado_urgencia_id}})" v-prms="{'id': '081b9bc8'}" :disabled="ticketIsClosed(dados.content)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Alterar Urgência</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
          <ticket-viewed :viewed-count="dados.content.view_count" :ticket="dados.content.id" :refresh="dados.refresh"></ticket-viewed>
          <v-divider></v-divider>    
          <ticket-network v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}" :dados="dados" :showInList="true" :networkCount="dados.content.network_count" :isNetwork="true"></ticket-network>
          <v-dialog v-model="dialogTicketDescription" width="500">
            <v-card>
              <v-toolbar dense>
                <v-icon>map</v-icon>
                <strong>Solicitação deste chamado</strong>
                <v-spacer></v-spacer>
                <v-btn icon flat @click="dialogTicketDescription = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text v-html="dados.content.desc_chamado"></v-card-text>
            </v-card>
          </v-dialog>
          <v-divider v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}" class="hidden-md-only hidden-lg-only hidden-xl-only"></v-divider>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4>
        <v-list subheader two-line style="padding: 0 0 0 5px;">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/timer_96.png" alt="Duração/Previsão:">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Duração/Previsão:</v-list-tile-sub-title>
              <v-list-tile-title>
                <strong>
                  <span v-if="dados.content.duration_exec">{{dados.content.duration_exec}}</span>/
                  <span v-if="dados.content.prevision">{{dados.content.prevision}}</span>
                </strong>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="$WiEditDialog({wiConfig: 'chamados-previsao', onSubmit: dados.refresh, data: {id: dados.content.id, alter_previsao_chamado: true, prevision: dados.content.prevision}})" v-prms="{'id': 'e27dc397'}" :disabled="ticketIsClosed(dados.content)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Alterar Previsão.</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar >
                <v-progress-circular :size="75" :width="15" :rotate="-90" :value="dados.content.sla" :color="dados.content.sla > 90 ? 'red' : dados.content.sla > 70 ? 'orange' : 'green'" > SLA </v-progress-circular>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <strong style="font-size: 30px; padding-left: 10px;" :style="{color: dados.content.sla > 90 ? 'red' : dados.content.sla > 70 ? 'orange' : 'green'}">{{dados.content.sla}}%</strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/dog_tag_96.png" alt="Código/Ticket Externo">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Código/Ticket Externo:</v-list-tile-sub-title>
              <v-list-tile-title>
                <strong>{{dados.content.external_code || '---'}}</strong>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat @click="$WiEditDialog({wiConfig: 'ticket-external-code', onSubmit: dados.refresh, data: {id: dados.content.id, external_code: dados.content.external_code}})" v-prms="{'id': 'bea42b38'}" :disabled="ticketIsClosed(dados.content)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar Código/Ticket Externo</span>
              </v-tooltip>
              <copy-text-to-clipboard v-if="dados.content.external_code" :text="dados.content.external_code"></copy-text-to-clipboard>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>    
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-avatar>
                <img src="static/icons/monitor/work_96.png" alt="Interfaces:">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Interfaces:</v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 20px;">
                <span v-if="dados.content.device_child_count">{{dados.content.device_child_count}}</span>
                <span v-else> 0 </span>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    flat
                    @click="$WiListDialog({
                      wiConfig: 'ticket-device-child',
                      filter: {where: 'ticket_id,' + dados.content.id},
                      wiEditData: {
                        ticket_id: dados.content.id,
                        selectDevice: true
                      }
                    })"
                  >
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>Listar Interfaces vinculadas a este chamado</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile><v-divider></v-divider>
          <dialog-anexos :numAnexos="dados.content.file_count" :chamado="dados.content.id" :refresh="dados.refresh"></dialog-anexos>
          <v-divider></v-divider>
          <ticket-network v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}" :dados="dados" :showInList="true" :networkCount="dados.content.device_count" :isNetwork="false"></ticket-network>
        </v-list>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
  import CopyTextToClipboard from '@/default/components/CopyTextToClipboard/CopyTextToClipboard'
  import {callApi} from '@/default/service/Api'
  import TicketHeader from '@/modules/service-desk/pages/Ticket/Info/Header/TicketHeader'
  // import DialogSeguidores from '@/modules/service-desk/pages/Ticket/Fallowers/TicketFallowers'
  import TicketViewed from '@/modules/service-desk/pages/Ticket/Viewed/TicketViewed'
  import DialogAnexos from '@/modules/service-desk/pages/Ticket/Annexes/TicketAnnexes'
  import TicketNetwork from '@/modules/service-desk/pages/Ticket/Network/ChamadoNetwork'
  import DialogLocationsMap from '@/modules/service-desk/pages/Ticket/Locations/DialogLocationsMap'
  import TicketResponsiblesDialog from '@/modules/service-desk/pages/Ticket/Responsibles/TicketResponsiblesDialog'
  import TicketTeamsDialog from '@/modules/service-desk/pages/Ticket/Teams/TicketTeamsDialog'
  export default {
    data () {
      return {
        dialogLocationsMap: false,
        dialogTicketDescription: false,
        ticketResponsibleDialog: false,
        ticketTeamDialog: false
      }
    },
    methods: {
      ticketIsClosed: function (ticket) {
        return ticket.chamado_status_id === 2 || ticket.chamado_status_id === 3
      },
      alterarChamado: function (refresh, message, alteracao) {
        this.$swal({ title: 'Deseja realmente ' + message + ' este chamado?', text: 'Essa ação não poderá ser desfeita!', type: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Sim, asumir!', cancelButtonText: 'Cancelar' })
        .then((response) => {
          if (response.value) {
            this.$swal({title: 'Carregando...', text: 'Aguarde até que a ação seja finalizada.', onOpen: () => { this.$swal.showLoading() }})
            callApi.put({
              uri: `service-desk/ticket/${this.dados.content.id}/take-on`,
              id: null,
              data: alteracao,
              sucess: (data) => {
                if (data.status === 201) {
                  this.$swal({ type: 'success', title: 'Tudo certo!', text: 'Concluído com sucesso.' })
                  refresh()
                } else {
                  this.$swal({ type: 'error', title: 'Oops... Algo deu errado!', text: 'Tente novamente ou contacte o suporte.' })
                }
              },
              error: (err) => {
                console.log(err)
              }
            })
          }
        })
      },
      ticketLocationsAction: function () {
        this.$WiListDialog({
          wiConfig: 'ticket-locations',
          button: {
            text: 'Adicionar Localização',
            action: (props) => {
              console.log(props)
              this.$WiEditDialog({
                wiConfig: 'ticket-location',
                data: {ticket_id: this.dados.content.id},
                onSubmit: () => {
                  props.refresh()
                  this.dados.refresh()
                },
                redirectOnSubmit: false
              })
            }
          },
          filter: {where: 'ticket_id,' + this.dados.content.id}
        })
      },
      changeJustify: function (ticket, refresh) {
        this.$WiListDialog({
          wiConfig: 'ticket-justify',
          callback: (selected) => {
            console.log(selected)
            this.$WiApiPut({
              uri: 'service-desk/ticket',
              id: ticket.id,
              data: {
                id: ticket.id,
                chamado_just_id: selected.id
              },
              config: {
                msgLoad: true,
                msgSucess: true,
                msgError: true,
                msgAsk: {
                  title: `Deseja realmente alterar o motivo deste chamado?`
                }
              },
              callback: () => {
                refresh(this.$route.params.id)
              }
            })
          },
          mode: 'select'
        })
        console.log(ticket)
      }
    },
    props: ['dados'],
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      User () {
        return this.$store.state.User || {}
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    components: {
      CopyTextToClipboard,
      TicketHeader,
      // DialogSeguidores,
      TicketViewed,
      TicketNetwork,
      DialogAnexos,
      DialogLocationsMap,
      TicketResponsiblesDialog,
      TicketTeamsDialog
    }
  }
</script>
