<template>
  <view-detail
    index="contract"
    title="Detalhes do Contrato"
    apiUrl="saless/contract"
    apiParams="?with=status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;invoice_address;item.item.host_config;finance_account:id,name;finance_form_payment:id,name;promotion:id,type_id,name,value,date_start,date_end,invoice_number_start,invoice_number_end;network_users"
    store="internet-contract"
  >
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
    </template>
    <template slot-scope="{ content, refresh }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex xs12 sm12 style="padding-bottom: 20px;">
            <ContractInternetPlan
              :business="content"
              :plan="content.item"
              :person="content.person || {}"
              :diagnostic="true"
              @onSubmit="refresh"
            ></ContractInternetPlan>
          </v-flex>
          <v-flex v-if="content" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessPromotion
              :business="content"
              :promotion="content.promotion"
              @onSubmit="refresh"
            ></BusinessPromotion>
          </v-flex>
          <v-flex v-if="content && content.item" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessItems
              :business="content"
              wiConfigId="sale"
              :onRefresh="refresh"
            ></BusinessItems>
          </v-flex>
          <v-flex v-if="content && content.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessFinanceRecurrent
              :business="content"
            ></BusinessFinanceRecurrent>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" class="pr-2" xs12 sm12 md6 lg6 xl6 style="padding-bottom: 20px;">
            <BusinessAddress
              title="Endereço de Instalação"
              field="address_id"
              :business="content"
              :person="content.person || {}"
              :address="content.address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" class="pl-2" xs12 sm12 md6 lg6 xl6 style="padding-bottom: 20px;">
            <BusinessAddress
              title="Endereço de Cobrança"
              field="invoice_address_id"
              :business="content"
              :person="content.person || {}"
              :address="content.invoice_address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex v-if="(content.hosts && content.hosts.length > 0) || $WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 style="padding-bottom: 20px;">
            <BusinessHosts
              :business="content"
              :hosts="content.hosts"
              :diagnostic="true"
              @onSubmit="refresh"
            ></BusinessHosts>
          </v-flex>
          <v-flex v-if="(content.network_users && content.network_users.length > 0) || $WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 style="padding-bottom: 20px;">
            <BusinessNetworkUsers
              :business="content"
              :networkUsers="content.network_users"
              :diagnostic="true"
              @onSubmit="refresh"
            ></BusinessNetworkUsers>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>

  import { PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'
  import { BUSINESS_INVOICE_TYPE_ID_RECURRENT } from '@/modules/business/constants/BusinessConstants'
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import ContractInternetPlan from './ContractInternetPlan'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessItems from '@/modules/business/pages/Business/BusinessItems'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessAddress from '@/modules/business/pages/Business/BusinessAddress'
  import BusinessHosts from '@/modules/business/pages/Business/BusinessHosts'
  import BusinessPromotion from '@/modules/business/pages/Business/BusinessPromotion'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  import BusinessNetworkUsers from '@/modules/business/pages/Business/BusinessNetworkUsers'
  import BusinessFinanceRecurrent from '@/modules/business/pages/Business/BusinessFinanceRecurrent'
  export default {
    name: 'Contract',
    computed: {
      PERMISSION_INTERNET_MODULE () {
        return PERMISSION_INTERNET_MODULE
      },
      BUSINESS_INVOICE_TYPE_ID_RECURRENT () {
        return BUSINESS_INVOICE_TYPE_ID_RECURRENT
      }
    },
    methods: {
      setContractLabelsText: function () {
        this.labels.category.text = 'Categoria/Tipo de Contrato:'
        this.labels.dateStart.text = 'Início do Contrato:'
        this.labels.dateEnd.text = 'Término do Contrato:'
        this.labels.duration.text = 'Duração do Contrato:'
      }
    },
    created: function () {
      this.setContractLabelsText()
    },
    components: {
      BusinessInfo,
      BusinessForm,
      BusinessHosts,
      BusinessItems,
      BusinessActions,
      BusinessAddress,
      BusinessPromotion,
      BusinessObservation,
      ContractInternetPlan,
      BusinessNetworkUsers,
      BusinessFinanceRecurrent
    },
    mixins: [BusinessMixin]
  }
</script>
