import { Model } from '@/default/model/Model'

export class BusinessItemCategory extends Model {
  constructor () {
    super()
    this.list = true
    this.id = 'b9a915a3'
    this.title = {
      list: 'Categoria de itens',
      edit: {
        insert: 'Novo Categoria de itens',
        update: 'Editar Categoria de itens'
      }
    }
    this.params = {
      limit: 10
    }
    this.apiUrl = 'sales/sale/item-category'
    this.icon = 'category'
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome da Categoria'
    }),
    observation: this.fieldList({
      value: 'observation',
      text: 'Observações'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, cor e ícone desta categoria.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Categoria',
      size: ['xs12', 'sm12', 'md5', 'lg5']
    }),
    typeId: this.fieldSelect({
      value: 'type_id',
      text: 'Tipo de Categoria',
      placeholder: 'Como esta categoria irá se comportar.',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true,
      options: [
        {
          value: 1,
          text: 'Orçamento'
        },
        {
          value: 4,
          text: 'Venda'
        }
      ],
      edit: {
        create: true
      }
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor da Categoria',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Use este campo para registrar alguma observação interna.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    })
  }
}
