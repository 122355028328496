import { Model } from '@/default/model/Model'
import {
  PERMISSION_REGISTER_CHANNEL,
  PERMISSION_REGISTER_CHANNEL_CREATE,
  PERMISSION_REGISTER_CHANNEL_UPDATE,
  PERMISSION_REGISTER_CHANNEL_DELETE
} from '@/default/constants/permissions'

export class Channel extends Model {
  constructor() {
    super()
    this.id = {
      id: PERMISSION_REGISTER_CHANNEL,
      create: PERMISSION_REGISTER_CHANNEL_CREATE,
      update: PERMISSION_REGISTER_CHANNEL_UPDATE,
      delete: PERMISSION_REGISTER_CHANNEL_DELETE
    }
    this.list = true
    this.title = {
      list: 'Canais',
      edit: {
        insert: 'Novo Canal',
        update: 'Editar Canal'
      }
    }
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome do canal',
      fields: ['id', 'name']
    }
    this.icon = 'add_circle_outline'
    this.apiUrl = 'register/channel'
    this.maxWidth = '1000px'
    this.components.list.tableRow = () => import('@/modules/register/pages/Channels')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome'
    }),
    type: this.fieldList({
      value: 'type',
      text: 'Tipo'
    }),
    description: this.fieldList({
      value: 'description',
      text: 'Descrição'
    }),
    active: this.fieldList({
      value: 'active',
      text: 'Status'
    })
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Informe os dados deste canal.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do canal',
      size: ['xs12', 'sm12', 'md7', 'lg7', 'xl7'],
      required: true
    }),
    type: this.fieldSelectApi({
      value: 'type_id',
      text: 'Tipo de Canal',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3', 'xl3'],
      disabled: (form) => { return form.id != null },
      api: {
        url: 'register/channel/type',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo',
      size: ['xs12', 'sm12', 'md2', 'lg2', 'xl2']
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Observações do Canal',
      placeholder: 'Ex: Use este campo para descrever o canal...'
    })
  }
}