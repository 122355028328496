import { Model } from '@/default/model/Model'

export class Tag extends Model {
  constructor() {
    super()
    this.id = '123eab35-8984-4551-bd15-5b054b2ac8bf'
    this.list = true
    this.title = {
      list: 'Tags',
      edit: {
        insert: 'Nova tag',
        update: 'Editar tag'
      }
    }
    this.params = {
      limit: 10,
      withCount: 'persons;talkContacts'
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome da tag',
      fields: ['id', 'name']
    }
    this.fieldsSelected = {
      itemName: ' tag(s)'
    }
    this.icon = 'bookmarks'
    this.apiUrl = 'register/tag'
    this.maxWidth = '850px'
    this.components.list.tableRow = () => import('@/modules/register/pages/Tags')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'tag',
      text: 'Tag'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome'
    }),
    profilesCount: this.fieldList({
      value: 'persons_count',
      text: 'Perfis',
      sortable: false,
      align: 'center'
    }),
    contactsCount: this.fieldList({
      value: 'talk_contacts_count',
      text: 'Contatos',
      sortable: false,
      align: 'center'
    }),
    description: this.fieldList({
      value: 'description',
      text: 'Descrição',
      sortable: false
    })
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Informe os dados da tag.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da tag',
      size: ['xs12', 'sm12', 'md12', 'lg4', 'xl4'],
      required: true
    }),
    order: this.fieldNumber({
      value: 'order',
      text: 'Ordem',
      size: ['xs12', 'sm12', 'md12', 'lg1', 'xl1']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm12', 'md12', 'lg1', 'xl1']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone',
      size: ['xs12', 'sm12', 'md12', 'lg4', 'xl4']
    }),
    show_in_kanban: this.fieldSwitch({
      value: 'show_in_kanban',
      text: 'Exibir no Kanban',
      size: ['xs12', 'sm12', 'md12', 'lg2']
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Observações da tag',
      placeholder: 'Ex: Use este campo para descrever a tag...'
    })
  }
}