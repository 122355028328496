import dashboards from '@/modules/service-desk/menu/MenuDashboards'
import tickets from '@/modules/service-desk/menu/MenuTickets'
import orders from '@/modules/service-desk/menu/MenuOrders'
import testManagements from '@/modules/service-desk/menu/MenuTestManagements'
import reports from '@/modules/service-desk/menu/MenuReports'
import registers from '@/modules/service-desk/menu/MenuRegisters'
import configs from '@/modules/service-desk/menu/MenuConfigs'

export default [
  {
    id: '183d07f3',
    icon: 'dashboard',
    title: 'Dashboards',
    children: dashboards
  },
  {
    id: '5dc282ab',
    icon: 'message',
    title: 'Chamados',
    children: tickets
  },
  {
    id: '74cc44a0',
    icon: 'phonelink_setup',
    title: 'Ordens de Serviço',
    children: orders
  },
  {
    id: '74cc44a0',
    icon: 'bug_report',
    title: 'Testes e Diagnósticos',
    children: testManagements
  },
  {
    id: 'baa9c987',
    icon: 'bar_chart',
    title: 'Relatórios',
    children: reports,
    hideOnDevices: ['mobile']
  },
  {
    id: 'a02e979e',
    icon: 'description',
    title: 'Cadastro',
    children: registers
  },
  {
    id: '4f1be58b',
    icon: 'settings_applications',
    title: 'Cadastro/Configurações',
    children: configs,
    hideOnDevices: ['mobile']
  }
]
