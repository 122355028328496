<template>
  <div>
    <v-select
      :prepend-icon="field['prepend-icon']"
      :append-icon="field['append-icon']"
      :items="field.options"
      v-model="model"
      :label="formatLabel(field)"
      :placeholder="field.placeholder || field.text"
      :rules="field.required ? validField(field) : []"
      :required="typeof field.required == 'function' ? field.required(form) : field.required"
      item-text="text"
      item-value="value"
      @change="onChange($event)"
      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
      :multiple="field.multiple"
      :return-object="field.object"
      :autocomplete="field.autocomplete"
    ></v-select>
  </div>
</template>

<script>
import WiEditFieldsMixin from './WiEditFieldsMixin'
export default {
  name: 'WiEditFieldSelect',
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setDefaultOption: function () {
      if (this.field.defaultOption && (this.model === undefined || this.model === null)) {
        this.model = this.field.defaultOption
      }
    },
    onChange: function (value) {
      this.$emit('onChange', value)
    }
  },
  mounted: function () {
    this.setDefaultOption()
  },
  props: {
    field: {
      required: true,
    },
    value: {},
    form: {
      required: true,
    }
  },
  mixins: [WiEditFieldsMixin]
}
</script>