<template>
  <div>
    <!-- eslint-disable -->
    <view-detail title="Chamados por Tipo de Rede" apiUrl="service-desk/dashboard/tickes-by-network-type" :apiParams="'?' + apiParams" :notLoad="true">
      <template slot-scope="{ content, refresh }">
        <v-container class="dashboard" fluid grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 style="padding: 5px 5px 10px 5px;">
              <v-card>
                <v-toolbar dense color="transparent" style="max-height: none !important;">
                  <v-list dense style="padding-top: 0px !important;">
                  <v-list-tile avatar style="padding-top: 0px !important;">
                    <v-list-tile-avatar size="45" style="cursor: pointer;">
                      <img src="static/systems/modules/wises-technology-module-service-desk.png" alt="Dashboard Service Desk" >
                    </v-list-tile-avatar>
                    <v-list-tile-content style="cursor: pointer;">
                      <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                      <v-list-tile-title style="font-size:18px;">
                        <span>Service Desk</span>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                    </v-list-tile-action>
                  </v-list-tile>
                  </v-list>
                  <v-spacer></v-spacer>
                  <div v-if="content && content.networks && content.networks.length > 0">
                    <v-btn v-if="!expanded" outline color="green" small style="min-width: 120px;" @click="setExpanded(true)">
                      <v-icon>expand_more</v-icon>
                      <span>Expandir</span>
                    </v-btn>
                    <v-btn v-else outline color="green" small style="min-width: 120px;" @click="setExpanded(false)">
                      <v-icon>expand_less</v-icon>
                      <span>Retrair</span>
                    </v-btn>
                  </div>
                  <v-btn outline color="blue" small style="min-width: 120px;" @click="showFilters = !showFilters">
                    <v-icon>filter_list</v-icon>
                    <span>Filtrar</span>
                    <span v-if="filtersCount" style="padding-left: 5px;">
                      ({{filtersCount}})
                    </span>
                  </v-btn>
                  <v-btn outline color="orange" small link :to="{ name: 'chamados', params: { page: 'todos-encerrado' } }">
                    <v-icon>history</v-icon>
                    <span>Histórico</span>
                  </v-btn>
                </v-toolbar>
              </v-card>
            </v-flex>
            <v-scroll-y-transition mode="in-out">
              <v-flex v-if="showFilters" xs12 sm12 md12 lg12 style="padding: 5px 5px 10px 5px;">
                <TicketsByNetworkTypeFilters
                  :ticketImpacts="content.impacts"
                  :refresh="refresh"
                  @selected="setFilters"
                ></TicketsByNetworkTypeFilters>
              </v-flex>
            </v-scroll-y-transition>
            <v-flex xs12 sm12 md12 lg12 style="padding: 5px 5px 10px 5px;">
              <v-card v-if="content && content.networks && content.networks.length > 0" class="elevation-10">
                <TicketsByNetworkTypeContent
                  :expanded="expanded"
                  :networks="content.networks"
                ></TicketsByNetworkTypeContent>
              </v-card>
              <v-card v-else class="elevation-10">
                <v-card-text class="text-center center-align align-center">
                  <h2>Nenhum chamado encontrado.</h2>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </view-detail>
  </div>
</template>

<script>
  import TicketsByNetworkTypeMixin from './TicketsByNetworkTypeMixin'
  import TicketsByNetworkTypeFiltersMixin from './TicketsByNetworkTypeFiltersMixin'
  import TicketsByNetworkTypeFilters from './TicketsByNetworkTypeFilters'
  import TicketsByNetworkTypeContent from './TicketsByNetworkTypeContent'
  export default {
    name: 'TicketsByNetworkTypeDashboard',
    data: () => ({
      expanded: false,
      showFilters: false,
      filters: {},
      apiParams: ''
    }),
    computed: {
      filtersCount () {
        return Object.keys(this.filters).length
      }
    },
    created: function () {
      this.getFiltersByStorage()
      this.getExpandedByStorage()
    },
    mixins: [TicketsByNetworkTypeMixin, TicketsByNetworkTypeFiltersMixin],
    components: {
      TicketsByNetworkTypeFilters,
      TicketsByNetworkTypeContent
    }
  }
</script>

<style scoped>
  .dashboard {
    padding: 10px;
    margin-bottom: 50px;
  }
  .dashboard__network {
    margin-bottom: 2px;
    border-style: solid unset;
    border-width: 2px;
    border-color: rgb(112, 112, 112) !important;
    background: rgb(112, 112, 112) !important;;
    color: white !important;
  }
  .dashboard__network-title {
    padding: 0 5px;
    font-size: 20px;
    font-weight: bold;
  }
  .dashboard__network-description {
    padding-right: 20px;
    font-size: 18px;
    text-align: right;
  }
  .dashboard__network-content {
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
  }
  .v-expansion-panel__header__icon .v-icon {
    color: white !important;
  }
</style>
