export default {
  loading: false,
  customer: null,
  customers: [],
  collaborator: null,
  productCustomer: [],
  productCollaborator: [],
  productsEntry: [],
  productsRemoval: [],
  productsSale: [],
  date: null,
  financesAccountBalance: 0,
  financesToday: [],
  financesTodayTotal: 0,
  financesPaidToday: 0,
  financesUnpaidToday: 0,
  financeSaleToday: 0,
  financesOpenedTotal: [],
}
