import { BusinessAgreement } from '@/modules/business/config/BusinessAgreement'

class SalesAgreement extends BusinessAgreement {
  constructor() {
    super()
    this.id = 'a02e979e'
  }
}

export class BudgetAgreement extends SalesAgreement {
  constructor() {
    super()
    this.title.list = 'Convênios de Proposta'
  }
} 