<template>
  <v-expansion-panel :value="setExpansionPanel()" expand>
    <v-expansion-panel-content class="dashboard__network" v-ripple v-for="(network, networkKey) in networks" :key="networkKey">
      <template v-slot:header>
        <div class="dashboard__network-title">
          <span>{{ network.name }}</span>
        </div>
        <v-spacer></v-spacer>
        <div class="dashboard__network-description">
          <span>{{network.tickets_opened_in_progress.length}} chamado</span>
          <span v-if="network.tickets_opened_in_progress.length - 1">s</span> 
          <span> encontrado</span>
          <span v-if="network.tickets_opened_in_progress.length - 1">s</span>
        </div>
      </template>
      <v-divider></v-divider>
      <v-card class="dashboard__network-content">
        <div>
          <v-divider></v-divider>
          <TicketsByNetworkTypeTable :tickets="network.tickets_opened_in_progress"/>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import TicketsByNetworkTypeTable from './TicketsByNetworkTypeTable'
  export default {
    name: 'TicketsByNetworkTypeContent',
    methods: {
      setExpansionPanel: function () {
        return this.networks.map(() => (this.expanded))
      }
    },
    props: {
      networks: {
        type: Array,
        required: true
      },
      expanded: {
        type: Boolean,
        default: false
      }
    },
    components: {
      TicketsByNetworkTypeTable
    }
  }
</script>

<style scoped>
  .dashboard {
    padding: 10px;
    margin-bottom: 50px;
  }
  .dashboard__network {
    margin-bottom: 2px;
    border-style: solid unset;
    border-width: 2px;
    border-color: rgb(112, 112, 112) !important;
    background: rgb(112, 112, 112) !important;;
    color: white !important;
  }
  .dashboard__network-title {
    padding: 0 5px;
    font-size: 20px;
    font-weight: bold;
  }
  .dashboard__network-description {
    padding-right: 20px;
    font-size: 18px;
    text-align: right;
  }
  .dashboard__network-content {
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
  }
  .v-expansion-panel__header__icon .v-icon {
    color: white !important;
  }
</style>
