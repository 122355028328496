<template>
  <div class="wi-edit-message-field">
    <v-dialog v-model="dialog" width="300" persistent>
      <template v-slot:activator="{ on }">
        <v-btn :color="field.color || 'primary'" outline large block v-on="on">
          <v-icon>calculate</v-icon>
          <span> {{ field.text || 'Calcular Margem' }}</span>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <v-icon>calculate</v-icon>
            <span> {{ field.text || 'Calcular Margem' }}</span>  
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="padding-bottom: 0;">
          <v-layout>
            <v-flex xs12 sm12 md12 lg12>
              <WiEditFieldMoney 
                v-model="fields.amountCost" 
                label="Valor de custo"
                :required="true"
                :outline="true"
                placeholder="0,00"
              ></WiEditFieldMoney>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
              <v-text-field
                v-model="fields.marginPercent"
                label="Margem (%)"
                outline
                dense
                type="number"
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout >
            <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
              <v-text-field
                v-model="fields.quantity"
                label="Quantidade"
                outline
                dense
                type="number"
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm12 md12 lg12>
              <WiEditFieldMoney 
                v-model="fields.amountDiscount" 
                label="Desconto (Opcional)"
                :required="false"
                :outline="true"
                placeholder="0,00"
              ></WiEditFieldMoney>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="padding-top: 0;">
          <div style="display: flex; flex-direction: column;">
            <div class="asdasd">
              <strong>Valor unitário: </strong>
              <span style="font-size: 20px; color: green;"> 
                {{ amountUnitAddMargin | money }}
              </span>
            </div>
            <v-divider v-if="fields.quantity && fields.amountDiscount"></v-divider>
            <div v-if="fields.quantity && fields.amountDiscount" class="asdasd">
              <strong>Valor total sem desconto: </strong>
              <span> {{ amountTotalSubtractDiscount | money }}</span>
            </div>
            <v-divider v-if="fields.quantity"></v-divider>
            <div v-if="fields.quantity" class="asdasd">
              <strong>Valor total: </strong>
              <span> {{ amountUnit | money }}</span>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" block @click="updateMargin()">
            Atualizar margem
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import WiEditFieldMoney from './WiEditFieldMoney.vue'

  export default {
    name: 'WiEditFieldAmountMargin',
    data: () => ({
      dialog: false,
      fields: {
        quantity: 0,
        amountCost: 0,
        marginPercent: 0,
        amountDiscount: 0
      }
    }),
    computed: {
      amountUnitAddMargin: function () {
        return this.fields.amountCost + (this.fields.amountCost * this.fields.marginPercent / 100)
      },
      amountTotalSubtractDiscount: function () {
        return this.amountUnitAddMargin * this.fields.quantity
      },
      amountUnit: function () {
        return this.amountTotalSubtractDiscount - this.fields.amountDiscount
      }
    },
    methods: {
      updateMargin: function () {
        this.$emit('onChange', {
          [this.field.config.fields.quantity]: this.fields.quantity,
          [this.field.config.fields.amountCost]: this.fields.amountCost,
          [this.field.config.fields.marginPercent]: this.fields.marginPercent,
          [this.field.config.fields.amountDiscount]: this.fields.amountDiscount,
          [this.field.config.result]: this.amountUnitAddMargin
        })
        this.dialog = false
      }
    },
    mounted: function () {
      // Implementar
      this.fields = {
        quantity: this.form[this.field.config.fields.quantity] || 0,
        amountCost: this.form[this.field.config.fields.amountCost] || 0,
        marginPercent: this.form[this.field.config.fields.marginPercent] || 0,
        amountDiscount: this.form[this.field.config.fields.amountDiscount] || 0
      }
    },
    props: ['form', 'field'],
    watch: {
      dialog: function () {
        console.log('dialog', this.field.config.fields.amountCost, this.form[this.field.config.fields.amountCost])
        this.fields = {
          quantity: this.form[this.field.config.fields.quantity] || 0,
          amountCost: this.form[this.field.config.fields.amountCost] || 0,
          marginPercent: this.form[this.field.config.fields.marginPercent] || 0,
          amountDiscount: this.form[this.field.config.fields.amountDiscount] || 0
        }
        console.log(this.form, this.fields)
      }
    },
    components: {
      WiEditFieldMoney
    }
  }
</script>
<style scoped>
  .asdasd {
    display: flex;
    justify-content: space-between;
  }
  .asdasd strong {
    width: 50%;
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    font-size: 14px;
  }
  .asdasd span {
    width: 50%;
    display: flex;
    justify-content: right;
    align-content: center;
    align-items: center;
    font-size: 16px;
  }
</style>