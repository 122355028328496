import { Model } from '@/default/model/Model'

export class TalkMenu extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: '37c54bf1',
      create: 'f89fe1b3',
      update: 'dddf7a44',
      delete: 'fb07a1b0'
    }
    this.name = 'menu'
    this.title = {
      list: 'Chatbots',
      edit: {
        insert: 'Criar Chatbot',
        update: 'Editar Chatbot'
      }
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do chatbot',
      fields: ['name']
    }
    this.apiUrl = 'talks/menu'
    this.apiParams = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'smart_toy'
    this.redirectOnSubmit = false

    this.maxWidth = '800px'

    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkMenus/TalkMenus')

    this.fieldsEdit = {
      header1: this.fieldSubHeader({
        text: 'Informe nome, tipo de Chatbot e selecione o robô.'
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        required: true,
        size: form => form.type == 8 ? ['xs12', 'sm12', 'md3', 'lg3'] : ['xs12', 'sm12', 'md6', 'lg6']
      }),
      type: this.fieldSelect({
        value: 'type',
        text: 'Tipo de Chatbot',
        options: [
          {
            value: 8,
            text: 'Chatbot'
          },
          // {
          //   value: 2,
          //   text: 'Texto + Opções'

          // },
          // {
          //   value: 3,
          //   text: 'Texto + Encerrar atendimento'  
          // },
          {
            value: 4,
            text: 'Texto + Encaminhar para equipe'
          },
          {
            value: 5,
            text: 'Mensagem de saudação'
          }
        ],
        size: form => form.type == 8 ? ['xs12', 'sm12', 'md3', 'lg3'] : ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      }),
      integration_id: this.fieldWiListDialog({
        value: 'integration_id',
        text: 'Chatbot',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-flow',
        show: 'integration.name',
        showField: (form) => (form.type == 8),
        callbackFields: [
          {
            get: 'external_code',
            set: 'external_code'
          },
          {
            get: 'name',
            set: 'integration_name'
          }
        ]
      }),
      // external_code: this.fieldSelectApi({
      //   value: 'external_code',
      //   text: 'Chatbot',
      //   size: ['xs12', 'sm12', 'md4', 'lg4'],
      //   api: {
      //     url: 'talks/chatbot'
      //   },
      //   show: 'name',
      //   showField: (form) => (form.type == 8)
      // }),
      header3: this.fieldSubHeader({
        text: 'Informe a equipe/Departamento que receberá o atendimento.',
        showField: (form) => (form.type == 4)
      }),
      team_id: this.fieldWiListDialog({
        value: 'team_id',
        text: 'Equipe/Departamento',
        required: true,
        showField: (form) => (form.type == 4),
        wiConfig: 'team',
        show: 'team.name',
        icon: 'persons'
      }),
      header2: this.fieldSubHeader({
        text: 'Informe a mensagem que será exibida no menu.',
        showField: (form) => (form.type == 5 || form.type == 4)
      }),
      message: this.fieldTextArea({
        value: 'message',
        text: 'Texto/Mensagem',
        required: true,
        showField: (form) => (form.type == 5 || form.type == 4)
      })
    }
    this.fieldsList = {
      id: this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      name: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      type: this.fieldList({
        value: 'type',
        text: 'Tipo',
        align: 'center'
      })
    }
    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }
}
