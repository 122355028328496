<template>
  <div :style="{background: dark ? '' : '#F2F2F2'}" style="padding: 0 10px 0 10px;">
    <v-timeline :dense="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
      <v-timeline-item v-for="(int, intKey) in dados.content.interacao" :key="intKey" large :color="tipos[int.tipo_interacao].color">
        <template v-slot:icon>
          <v-avatar>
            <img v-if="int.tipo_interacao == 0" src="static/icons/monitor/flying_stork_with_bundle_96.png">
            <img v-if="int.tipo_interacao == 1" src="static/icons/monitor/chat_room_96.png">
            <img v-if="int.tipo_interacao == 2" src="static/icons/monitor/data_pending_96.png">
            <img v-if="int.tipo_interacao == 3" src="static/icons/monitor/work_96.png">
            <img v-if="int.tipo_interacao == 4" src="static/icons/monitor/up_down_arrow_96.png">
            <img v-if="int.tipo_interacao == 5" src="static/icons/monitor/sand_timer_96.png">
            <img v-if="int.tipo_interacao == 6" src="static/icons/monitor/transfer_96.png">
            <img v-if="int.tipo_interacao == 7" src="static/icons/monitor/dog_tag_96.png">
            <img v-if="int.tipo_interacao == 8" src="static/icons/monitor/management_96.png">
            <img v-if="int.tipo_interacao == 9" src="static/icons/monitor/problema_expirado.png">
            <img v-if="int.tipo_interacao == 10" src="static/icons/monitor/retornou.png">
          </v-avatar>
        </template>
        <template v-slot:opposite>
          <span class="headline font-weight-bold blue--text">{{tipos[int.tipo_interacao].title}}</span>
        </template>
        <div v-if="int.tipo_interacao == 0">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar >
                    <img v-if="int.sexo_pessoa == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
                    <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Criou este chamado</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text>
              <div>
                <strong>Responsável: </strong> {{int.new_interacao.nome_responsavel || 'Não definido'}}<br>
                <strong>Previsão: </strong> {{int.new_interacao.previsao_chamado || 'Não definido'}}<br>
                <strong>Ocorrência: </strong> {{int.new_interacao.nome_categoria_grupos || 'Não definido'}}<br>
                <strong>Descrição/Solicitação: </strong> <span v-html="int.new_interacao.desc_chamado"></span><br>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 1">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img v-if="int.sexo_pessoa == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
                    <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Informou: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-html="int.desc_interacao"></v-card-text>
          </v-card>
        </div>
        <div v-else-if="int.tipo_interacao == 2">
          <v-card class="elevation-2">
            <v-list subheader two-line dense style="padding-bottom: 0;" :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar >
                    <img v-if="int.sexo_pessoa == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
                    <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou o status:</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <v-icon large v-if="int.old_interacao && int.old_interacao.icone_status" :color="int.old_interacao.cor_status">{{int.old_interacao.icone_status}}</v-icon>
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title>Status Anterior:</v-list-tile-sub-title>
                  <v-list-tile-sub-title v-if="int.old_interacao && int.old_interacao.agendamento_log" style="text-align: left;"><strong> até {{int.old_interacao.agendamento_log}} </strong></v-list-tile-sub-title>
                  <v-list-tile-title style="font-size: 16px;">
                    <span v-if="int.old_interacao">{{int.old_interacao.nome_status}}</span>
                    <span v-else>Não registrado</span>
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/chevron_rigth_96.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title style="text-align: right;">Novo Status:</v-list-tile-sub-title>
                  <v-list-tile-sub-title v-if="int.new_interacao && int.new_interacao.agendamento_log" style="text-align: right;"><strong> até {{int.new_interacao.agendamento_log}} </strong></v-list-tile-sub-title>
                  <v-list-tile-title v-if="int.new_interacao" style="font-size: 16px; text-align: right;">
                    <span v-if="int.new_interacao">{{int.new_interacao.nome_status}}</span>
                    <span v-else>Não registrado</span>
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-avatar>
                  <v-avatar>
                    <v-icon large v-if="int.new_interacao && int.new_interacao.icone_status" :color="int.new_interacao.cor_status">{{int.new_interacao.icone_status}}</v-icon>
                  </v-avatar>
                </v-list-tile-avatar>
              </v-list-tile>
            </v-list>
          </v-card>
        </div>
        <div v-else-if="int.tipo_interacao == 3">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar >
                    <img v-if="int.sexo_pessoa == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
                    <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Assumiu este chamado.</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-card>
        </div>
        <div v-else-if="int.tipo_interacao == 4">
          <v-card class="elevation-2">
            <v-list subheader one-line dense style="padding-bottom: 0;" :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img v-if="int.sexo_pessoa == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
                    <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Transferiu este chamado:</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/chevron_rigth_96.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-sub-title style="text-align: right;">Novo(a) Responsável:</v-list-tile-sub-title>
                  <v-list-tile-title v-if="int.new_interacao" style="font-size: 16px; text-align: right;">
                    <span v-if="int.new_interacao && int.new_interacao.nome_pessoa">{{int.new_interacao.nome_pessoa}}</span>
                    <span v-else>Não registrado</span>
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img v-if="int.new_interacao && int.new_interacao.sexo_pessoa == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
                    <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
              </v-list-tile>
            </v-list>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 5">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou a previsão: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-if="int.new_interacao">{{int.new_interacao.name_person}} Alterou a previsão deste chamado para <strong style="font-size: 20px;">{{int.new_interacao.previsao_chamado}}</strong>.</v-card-text>
            <v-card-text v-else>{{int.desc_interacao}}</v-card-text>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 6">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou o tipo de ocorrência: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-if="int.new_interacao">{{int.new_interacao.name_person}} Alterou o Tipo de Ocorrência deste chamado para:<br>
              <strong style="padding: 2px 10px 2px 10px; border-radius: 5px; color: white;" :style="{'background-color': int.new_interacao.cor_categoria}">{{int.new_interacao.nome_categoria}}</strong>.
            </v-card-text>
            <v-card-text v-else>{{int.desc_interacao}}</v-card-text>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 7">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou a previsão: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-if="int.new_interacao">{{int.new_interacao.name_person}} Alterou o Código/Ticket Externo deste chamado para <strong style="font-size: 16px;">{{int.new_interacao.external_code}}</strong>.</v-card-text>
            <v-card-text v-else>{{int.desc_interacao}}</v-card-text>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 8">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou a equipe: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-if="int.new_interacao">{{int.new_interacao.name_person}} Alterou a equipe responsável por este chamado para <strong style="font-size: 16px;">{{int.new_interacao.equipe_nome}}</strong>.</v-card-text>
            <v-card-text v-else>{{int.desc_interacao}}</v-card-text>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 9">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou o impacto: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-if="int.new_interacao">{{int.new_interacao.name_person}} Alterou o Impacto deste chamado para <strong style="font-size: 16px;">{{int.new_interacao.nome_impacto}}</strong>.</v-card-text>
            <v-card-text v-else>{{int.desc_interacao}}</v-card-text>
          </v-card>
        </div>
        <div v-if="int.tipo_interacao == 10">
          <v-card class="elevation-2" :style="{background: dark ? '#212121' : ''}">
            <v-list subheader one-line dense :style="{background: dark ? '#212121' : ''}">
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-avatar>
                    <img src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
                  </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title style="font-size: 16px;">{{int.name_person}}</v-list-tile-title>
                  <v-list-tile-sub-title>Alterou a urgência: </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>{{ int.momento_interacao_format }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
            <v-card-text v-if="int.new_interacao">{{int.new_interacao.name_person}} Alterou a Urgência deste chamado para <strong style="font-size: 16px;">{{int.new_interacao.nome_urgencia}}</strong>.</v-card-text>
            <v-card-text v-else>{{int.desc_interacao}}</v-card-text>
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        form: {},
        tipos: [
        {index: 0, title: 'Criação', color: ''},
        {index: 1, title: 'Anotação', color: ''},
        {index: 2, title: 'Status', color: ''},
        {index: 3, title: 'Assumir Chamado', color: ''},
        {index: 4, title: 'transferência', color: ''},
        {index: 5, title: 'Previsão', color: ''},
        {index: 6, title: 'Tipo de Ocorrência', color: ''},
        {index: 7, title: 'Código/Tícket Externo', color: ''},
        {index: 8, title: 'Equipe Responsável', color: ''},
        {index: 9, title: 'Alteração de Impacto', color: ''},
        {index: 10, title: 'Alteração da Urgência', color: ''},
        {title: 'Alteração da Justificativa', color: ''}
        ]
      }
    },
    methods: {

    },
    props: ['dados'],
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      }
    }
  }
</script>
