var quotation = (title, name) => {
  var date = {
    open: {
      value: 'opened_at',
      text: 'Data de Criação',
      list: true
    },
    close: {
      value: 'closed_at',
      text: 'Data de Realização',
      list: true
    },
    cancel: {
      value: 'canceled_at',
      text: 'Data de Cancelamento',
      list: true
    }
  }
  var config = {
    id: {
      id: '10b505c9',
      create: 'b9fba2ea',
      update: 'd3c48579',
      delete: '55bf11f3'
    },
    list: true,
    title: {
      list: 'Cotações ' + title,
      edit: {
        create: 'Nova Cotação',
        update: 'Editar Cotação'
      }
    },
    icon: 'shop',
    apiUrl: 'purchases/quotation',
    params: {
      limit: 10
    },
    search: {
      placeholder: 'Pesquise ID, Razão Social, Status ou código externo',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'external_code']
    },
    urlDetails: { name: 'quotation', params: {} },
    components: {
      list: {
        tableRow: () => import('@/modules/shared/pages/Businesses/Businesses')
      }
    },
    size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
    fields: [
      { value: 'id', text: 'ID', type: 'int', list: true },
      { value: 'person.name', text: 'Nome do Fornecedor', type: 'text', list: true },
      { value: 'status.name', text: 'Status do Orcamento', type: 'text', list: true },
      date[name],
      {
        value: 'date_validity',
        text: 'Data de Validade',
        list: true
      },
      { text: 'Informe os dados básicos desta cotação.', type: 'subheader', edit: true },
      {
        value: 'company_id',
        text: 'Empresa (Matriz ou Filial)*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        type: 'db-autocomplete',
        disabled: (form) => { return form.id != null },
        edit: true,
        apiUrl: 'register/person/company',
        apiParams: {
          fields: 'id,name',
          order: 'name,asc'
        },
        show: 'name'
      },
      {
        value: 'person_id',
        text: 'Fornecedor*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        type: 'wi-list-dialog',
        edit: true,
        wiConfig: 'provider',
        show: 'person.first_name',
        icon: 'person_outline'
      },
      {
        value: 'external_code',
        text: 'Código Externo',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'text',
        list: true,
        edit: true
      },
      { value: 'business_status_id', text: 'Status da Cotação*', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', edit: true, apiUrl: 'purchases/config/status/quotation', apiParams: { fields: 'id,name', where: 'active,1', order: 'order,asc' }, show: 'name' },
      { value: 'business_category_id', text: 'Categoria da Cotação*', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', edit: true, apiUrl: 'purchases/config/category/quotation', apiParams: { fields: 'id,name', order: 'name,asc' }, show: 'name' },
      { value: 'date_start', text: 'Data de Emissão', required: false, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'date', edit: true, search: false },
      { value: 'date_end', text: 'Data de Entrega', required: false, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'date', edit: true, search: false },
      { value: 'date_validity', text: 'Data de Validade*', required: true, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'date', list: true, edit: true },
      { value: 'amount_freight', text: 'Valor Frete', type: 'money', prefix: 'R$', size: ['xs12', 'sm4', 'md2', 'lg2'], required: false, edit: true },
      { value: 'amount_discount', text: 'Valor de Desconto', type: 'money', prefix: 'R$', size: ['xs12', 'sm4', 'md2', 'lg2'], required: false, edit: true },
      { value: 'collaborator_id', text: 'Colaborador Responsável', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], type: 'db-autocomplete', edit: true, apiUrl: 'register/person/collaborator', apiParams: { fields: 'id,name', order: 'name,asc' }, show: 'name' },
      { value: 'integration', text: 'Código interno', required: false, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'text', edit: true },
      { text: 'Descreva abaixo os detalhes desta cotação.', type: 'subheader', edit: true },
      { value: 'description', text: 'Descrição da Cotação', placeholder: 'Ex: Prazos, detalhes importanes, etc.', type: 'editor', edit: true },
      { text: 'Descreva abaixo as formas/condições de pagamento desta cotação.', type: 'subheader', edit: true },
      { value: 'information', text: 'Forma/Condições de Pagamento', placeholder: 'Ex: Parcelado em 3x.', type: 'editor', edit: true },
      { text: 'O campo abaixo pode ser usado para registrar observações sobre esta cotação.', type: 'subheader', edit: true },
      { value: 'observation', text: 'Observações da Cotação', placeholder: 'Ex: Negociação iniciada em...', type: 'textarea', edit: true },
      {
        value: 'options',
        text: 'Opções',
        list: true,
        sortable: false
      }
    ]
  }
  if (name) {
    config.params['stage'] = name
  }
  return config
}

export default {
  open: quotation('Em Aberto', 'open'),
  close: quotation('Aprovadas', 'close'),
  cancel: quotation('Rejeitadas', 'cancel')
}
