<template>
  <view-detail
    index="well"
    title="Detalhes do Poço"
    :apiUrl="!wellId ? 'wells/well' : 'wells/well/' + wellId"
    apiParams="?with=events;finances;status:id,name,icon,color;category:id,name,icon,color,form_id;files;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;business:id,type,created_at;businesses;file"
    store="well"
    :notGetID="wellId && wellId > 0"
    :notShowToolbar="wellId && wellId > 0"
  >
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
    </template>
    <template slot-scope="{ content, refresh, loading }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessRelationships
              :business="content"
              :refresh="refresh"
              :loading="loading"
            ></BusinessRelationships>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessAddress
              :business="content"
              :person="content.person || {}"
              :address="content.address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 style="padding-bottom: 20px; padding-right: 10px;">
            <WellOrders
              :content="content"
              :refresh="refresh"
              :loading="loading"
            ></WellOrders>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 style="padding-bottom: 20px; padding-left: 10px;">
            <WellOrderRecordFiles
              :content="content"
              :refresh="refresh"
              :loading="loading"
            ></WellOrderRecordFiles>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import WellOrders from './WellOrders'
  import WellOrderRecordFiles from './WellOrderRecordFiles'
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessAddress from '@/modules/business/pages/Business/BusinessAddress'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  import BusinessRelationships from '@/modules/business/pages/Business/BusinessRelationships'
  export default {
    name: 'Well',
    methods: {
      setWellLabelsText: function () {
        this.labels.category.text = 'Categoria/Tipo de Poço:'
        this.labels.dateStart.text = 'Início Perfuração:'
        this.labels.dateEnd.text = 'Término Perfuração:'
        this.labels.duration.text = 'Duração da Perfuração:'
      }
    },
    created: function () {
      this.setWellLabelsText()
    },
    props: {
      wellId: {
        type: Number,
        default: 0
      }
    },
    components: {
      WellOrders,
      BusinessInfo,
      BusinessForm,
      BusinessActions,
      BusinessAddress,
      BusinessObservation,
      WellOrderRecordFiles,
      BusinessRelationships
    },
    mixins: [BusinessMixin]
  }
</script>
