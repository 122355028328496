<template>
  <v-card outlined elevation="17" class="medical-record-attendances" :color="!$wiDark ? '#FAFAFA': '#424242'">
    <v-toolbar dense flat :color="!$wiDark ? '#FAFAFA': '#424242'">
      <v-icon>person_search</v-icon>
      <v-toolbar-title>
        <span v-if="content && content.attendances && content.attendances.length > 0">{{content.attendances.length}} </span>
        <span> Atendimento(s)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- 
      <v-btn v-if="content && content.attendances && content.attendances.length > 0" outline small>
        <v-icon>filter_list</v-icon>
        <span>Filtrar</span>
      </v-btn> 
      -->
      <MedicalRecordCategories v-if="content && content.attendances && content.attendances.length > 0" :patient="content.patient" :categories="content.categories" :refresh="refresh">
        <template slot-scope="{ on }">
          <v-btn v-on="on" small outline :disabled="loading">
            <v-icon>add</v-icon>
            <span>Novo Atendimento</span>
          </v-btn>
        </template>
      </MedicalRecordCategories>
    </v-toolbar>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card-text :style="{'min-height': maxHeigth, 'max-height': maxHeigth}" style="padding: 0 10px 0px 30px; overflow-y: scroll;">
      <v-timeline v-if="content && content.attendances && content.attendances.length > 0" dense>
        <v-timeline-item :color="$wiDark ? 'black': 'white'" v-for="(attendance, attendanceKey) in content.attendances" :key="attendanceKey" large>
          <template v-slot:icon>
            <v-avatar>
              <img v-if="attendance.type == 10" src="/static/icons/monitor/system_task_96.png">
              <img v-if="attendance.type == 11" src="/static/icons/monitor/pass_fail_96.png">
              <img v-if="attendance.type == 12" src="/static/icons/monitor/pills_96.png">
              <img v-if="attendance.type == 14" src="/static/icons/monitor/microscope_96.png">
              <img v-if="attendance.type == 15" src="/static/icons/monitor/good_notes_96.png">
            </v-avatar>
          </template>
          <v-card :color="$wiDark ? 'black': ''">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title v-text="attendance.opened_at_format"></v-list-tile-sub-title>
                <v-list-tile-title>
                  <strong v-text="attendance.category.name"></strong>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn icon ripple @click="$WiEditDialog({
                    wiConfig: attendance.info.name,
                    onSubmit: refresh,
                    data: attendance,
                    redirectOnSubmit: false
                  })
                ">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn icon ripple @click="$WiDialog(wiDialogs.files(attendance.info.name, attendance.id))">
                  <v-badge right overlap offset-x="22" offset-y="24">
                    <template v-slot:badge>
                      <span v-if="attendance.files_count">
                        <span v-if="attendance.files_count < 10">{{attendance.files_count}}</span>
                        <span v-else>9+</span>
                      </span>
                    </template>
                    <v-icon>
                      attach_file
                    </v-icon>
                  </v-badge>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn icon ripple @click="$WiDialog(wiDialogs.events(attendance.info.name, attendance.id))">
                  <v-badge right overlap offset-x="22" offset-y="24">
                    <template v-slot:badge>
                      <span v-if="attendance.notes_count">
                        <span v-if="attendance.notes_count < 10">{{attendance.notes_count}}</span>
                        <span v-else>9+</span>
                      </span>
                    </template>
                    <v-icon>
                      message
                    </v-icon>
                  </v-badge>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <MedicalRecordAttendanceDialog :attendance="attendance">
                  <template slot-scope="{ on }">
                    <v-btn icon ripple v-on="on">
                      <v-icon>visibility</v-icon>
                    </v-btn>
                  </template>
                </MedicalRecordAttendanceDialog>
              </v-list-tile-action>
            </v-list-tile>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <div v-else style="width: 100%; padding: 30px 0; text-align: center;">
        <h3>Nenhum atendimento encontrado.</h3>
        <MedicalRecordCategories v-if="content" :patient="content.patient" :categories="content.categories" :refresh="refresh">
          <template slot-scope="{ on }">
            <v-btn v-on="on" small outline :disabled="loading">
              <v-icon>add</v-icon>
              <span>Novo Atendimento</span>
            </v-btn>
          </template>
        </MedicalRecordCategories>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import MedicalRecordCategories from '@/modules/clinic/pages/MedicalRecord/MedicalRecordCategories'
  import MedicalRecordAttendanceDialog from '@/modules/clinic/pages/MedicalRecord/MedicalRecordAttendanceDialog'
  export default {
    name: 'MedicalRecordAttendances',
    data () {
      return {
        maxHeigth: (window.innerHeight - 378) + 'px',
        wiDialogs: {
          files: (businessName, businessId) => {
            return {
              title: 'Arquivos/Anexos',
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: businessName + '-fileList',
                  api: {
                    url: 'clinic/' + businessName + '/file',
                    params: '?order=id,desc&where=business_id,' + businessId
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Arquivo',
                action: () => {
                  this.$WiDialog({
                    title: 'Arquivos/Anexos ',
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        business_id: businessId
                      }
                    },
                    onClose: () => {
                      this.$WiView(businessName + '-fileList').refresh()
                      this.refresh()
                    }
                  })
                }
              }
            }
          },
          events: (businessName, businessId) => {
            return {
              title: 'Anotações/Eventos ',
              icon: 'message',
              component: {
                content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                props: {
                  index: businessName + '-event',
                  api: {
                    url: 'clinic/' + businessName + '/event',
                    params: '?order=id,desc&where=business_id,' + businessId
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Nota',
                action: () => {
                  this.$WiEditDialog({
                    wiConfig: businessName + '-event',
                    onSubmit: () => {
                      this.$WiView(businessName + '-event').refresh()
                      this.refresh()
                    },
                    data: {business_id: businessId}
                  })
                }
              }
            }
          }
        }
      }
    },
    computed: {
      attendances () {
        return this.content.attendances.filter(attendance => ( attendance.type === 11 ))
      }
    },
    methods: {},
    props: ['content', 'refresh', 'loading'],
    components: {
      MedicalRecordCategories,
      MedicalRecordAttendanceDialog
    }
  }
</script>
<style scoped>
  .medical-record-attendances .v-list__tile__action, .v-list__tile__avatar {
    min-width: 40px !important;
  }
</style>