<template>
  <WiView
    index="form"
    title="Detalhes do Formulário"
    apiUrl="forms/form"
    apiParams="?with=fields;fields.options"
    store="form"
  >
    <template slot-scope="{ content, loading }">
      <v-container fluid style="overflow: auto">
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            {{ content }}
            {{ loading }}
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </WiView>
</template>
<script>
  export default {
    name: 'Form'
  }
</script>
