import Site from '@/modules/sites/config/Site/Site'

import Post, { PostPublic, PostPrivate } from '@/modules/sites/config/Post/Post'
import { PostFile } from '@/modules/sites/config/Post/PostFile'
import { PostCategory } from '@/modules/sites/config/Post/PostCategory'
import PostAuthorFollower from '@/modules/sites/config/Post/PostAuthorFallower'

import Customer from '@/modules/sites/config/Register/Customer'
import Author from '@/modules/sites/config/Register/Author'

export default {
  'site': new Site(),
  'post': new Post(),
  'post-public': new PostPublic(),
  'post-private': new PostPrivate(),
  'post-file': new PostFile(),
  'post-category': new PostCategory(),
  'author-follower': new PostAuthorFollower(),
  'sites-customer': Customer,
  'author': Author
}
