export default {
  id: 'a5243554',
  title: 'Nova Anotação',
  icon: 'message',
  apiUrl: 'service-desk/ticket/event',
  fields: [
    {text: 'Descreva sua anotação de forma que fique clara aos outros usuários.', type: 'subheader', edit: true},
    {value: 'desc_interacao', text: 'Insira aqui sua anotação.*', type: 'editor', required: true, list: false, edit: true}
  ],
  fieldsFixed: [
    {
      value: 'tipo_interacao', data: 1
    }
  ]
}
