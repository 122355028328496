import { Lang } from '@/default/service/Lang'

export default {
  title: {
    edit: {
      create: 'Novo Telefone Fixo',
      update: 'Editar Telefone Fixo'
    }
  },
  icon: 'phone',
  apiUrl: 'register/person/phone',
  fields: [
    {value: 'id', text: 'ID', type: 'int', required: true, list: true, edit: false},
    {value: 'country_code', text: 'Código do País?', required: true, size: ['xs12', 'sm6', 'md3', 'lg3'], type: 'select', options: [ {value: 55, text: 'Brasil'}, {value: 351, text: 'Portugal'}, {value: 1, text: 'Estados Unidos da América'} ], list: false, edit: true},
    {
      value: 'contact',
      counter: 15,
      text: 'Número',
      mask: Lang.langText('register.config.person.phone.fields.contact.mask', '(##) ####-####'),
      type: 'text',
      size: ['xs12', 'sm6', 'md3', 'lg3'],
      required: true,
      list: true,
      edit: true
    },
    {value: 'operator', text: 'Operadora', type: 'text', size: ['xs12', 'sm6', 'md2', 'lg2'], list: true, edit: true},
    {value: 'is_master', text: 'Principal?', required: false, size: ['xs12', 'sm6', 'md2', 'lg2'], type: 'select', options: [ {value: false, text: 'Não'}, {value: true, text: 'Sim'} ], list: false, edit: true},
    {value: 'is_whatsapp', text: 'É WhatsApp?', required: false, size: ['xs12', 'sm6', 'md2', 'lg2'], type: 'select', options: [ {value: false, text: 'Não'}, {value: true, text: 'Sim'} ], list: false, edit: true},
    {value: 'description', text: 'Descrição', type: 'text', list: true, edit: true}
  ],
  fieldsFixed: [
    {
      value: 'type', data: 'Telefone'
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
