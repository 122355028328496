import Company from '@/modules/register/config/Person/Person?url=Company'

var company = Company({name: 'Empresa', field: 'company'})

company.id = {
  id: '8f5922e4',
  create: '9fca5e4d',
  update: '8323fee1',
  delete: 'ea94319d'
}
company.title = {
  list: 'Minhas Empresas',
  edit: {
    insert: 'Nova Empresa',
    update: 'Editar Empresa'
  }
}
company.icon = 'business'
company.apiUrl = 'register/person/company'
company.urlDetails.name = 'company'

export default company
