import { Contract } from '@/modules/business/config/Contract/Contract'

export class ContractSales extends Contract {
  constructor () {
    super()
    this.apiUrl = 'saless/contract'
    this.components.list.tableRow = () => import('@/modules/sales/pages/Contracts')
    this.fieldsEdit.status.apiUrl = 'sales/config/status/contract'
    this.fieldsEdit.category.apiUrl = 'sales/config/category/contract'
  }
}

export class ContractOpened extends ContractSales {
  constructor () {
    super()
    this.title.list = 'Contratos Em Análise'
    this.params.stage = 'open'
  }
}
export class ContractClosed extends ContractSales {
  constructor () {
    super()
    this.title.list = 'Contratos Vigentes'
    this.params.stage = 'close'
  }
}
export class ContractCanceled extends ContractSales {
  constructor () {
    super()
    this.title.list = 'Contratos Cancelados'
    this.params.stage = 'cancel'
  }
}
