import { Model } from '@/default/model/Model'
import { PERMISSION_ORDER_UPDATE } from '@/default/constants/permissions'

export default class VTalTroubleTicketAppointment extends Model {
  constructor () {
    super()
    this.id = PERMISSION_ORDER_UPDATE
    this.name = 'vtal-trouble-ticket-appointment'
    this.title = 'Agendar visita técnica'
    this.apiUrl = 'service-desk/order-vtal/event/action'
    this.icon = 'event'

    this.fields = []

    this.maxWidth = '800px'

    this.setFields([
      this.fieldSubHeader({
        text: 'Informe o intervalo de datas em que a visita técnica pode ser executada.'
      }),
      this.fieldDateTime({
        value: 'appointmentStart',
        text: 'Data inicial',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      }),
      this.fieldDateTime({
        value: 'appointmentFinish',
        text: 'Data final',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      })
    ])
  }
}
