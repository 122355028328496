import ptBr from '@/lang/pt-br/pt-br'
import ptPt from '@/lang/pt-pt/pt-pt'

export default {
  'pt-br': {
    id: '',
    name: 'pt-br',
    title: 'Português Brasil',
    content: ptBr
  },
  'pt-pt': {
    id: '',
    name: 'pt-pt',
    title: 'Português Portugal',
    content: ptPt
  }
}
