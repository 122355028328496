<template>
  <div>
    <v-card-text class="wi-not-found-message">
      <img
        :src="imageUrl"
        max-width="300"
      />
      <h1 class="wi-not-found-message__title">
        {{ title || 'Nenhum resultado encontrado' }}
      </h1>
      <br/>
      <h3 class="wi-not-found-message__subtitle">
        {{ subtitle || 'Você pode adicionar um novo item ou mudar seus filtros' }}
      </h3>
      <br/>
      <div v-if="showAddButton || showRefreshButton">
        <v-btn v-if="showAddButton" @click="onAdd()" outline small color="green">
          <v-icon>add</v-icon>
          <span>Adicionar</span>
        </v-btn>
        <v-btn v-if="showRefreshButton" @click="onRefresh()" outline small>
          <v-icon>refresh</v-icon>
          <span>Buscar novamente</span>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'WNNotFoundMessage',
  methods: {
    onAdd () {
      this.$emit('onAdd')
    },
    onRefresh () {
      this.$emit('onRefresh')
    }
  },
  props: {
    imageUrl: {
      type: String,
      default: 'static/background/location-not-found-illustration.png'
    },
    title: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    subtitle: {
      type: String,
      default: 'Você pode adicionar um novo item ou mudar seus filtros'
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    showRefreshButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
  .wi-not-found-message {
    padding: 40px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .wi-not-found-message img {
    max-width: 300px;
    margin: 20px 0;
  }
  .wi-not-found-message__title {
    font-size: 24px;
    font-weight: 500;
  }
  .wi-not-found-message__subtitle {
    font-size: 16px;
    font-weight: 400;
  }
  .wi-not-found-message__button {
    margin-top: 20px;
  }
</style>