<template>
  <wi-view index="task" title="Detalhes do Agendamento" apiUrl="tasks/task" apiParams="?with=customer.phones;customer.adresses;transfers.transfer;businesess.business.items" store="task-sales-opened">
    <template slot-scope="{ content, refresh }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <Task :task="content" :refresh="refresh"></Task>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </wi-view>
</template>
<script>
  import Task from '@/modules/projects/pages/Task/Task'
  export default {
    name: 'TaskSale',
    data: () => ({}),
    components: {
      Task
    }
  }
</script>
