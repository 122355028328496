export default {
  data () {
    return {}
  },
  methods: {
    onChange: function () {
      this.$forceUpdate()
    },
    isRequired: function (field) {
      if (typeof field.required === 'function') {
        return field.required(this.form)
      } else {
        return field.required
      }
    },
    changed: function (field, value, index, valueField) {
      if (value === undefined) {
        this.checkboxs[index] = valueField
      }
      this.form[field] = !value
    },
    viewField: function (field) {
      if (field.edit && (field.edit.insert || field.edit.update)) {
        if (this.form.id) {
          if (field.edit.update) {
            return true
          } else {
            return false
          }
        } else {
          if (field.edit.insert) {
            return true
          } else {
            return false
          }
        }
      } else {
        return field.edit
      }
    },
    validField: function (field) {
      return field.rules || [v => !!v || 'Campo obrigatório']
    },
    sizeField: function (sizes) {
      var size = {}
      sizes = sizes ? (typeof sizes === 'function' ? sizes(this.form) : sizes) : ['xs12', 'sm12', 'md12', 'lg12']
      for (var i in sizes) {
        size[sizes[i]] = true
      }
      return size
    },
    ActiveInactive: function () {
      this.form = {}
      this.$refs.wiEditDialog.reset()
      Object.assign(this.form, this.setform)
      if (this.config.fieldsFixed) {
        this.config.fieldsFixed.forEach((fixed) => {
          this.form[fixed.value] = fixed.data
        })
      }
    },
    formatLabel: function ({ required = false, text = 'Campo' }) {
      if (required) {
        return `${text}*`
      }
      return text
    }
  }
}
