import GruposTopologia from '@/modules/network/pages/Grupo/GruposTopologia'

import Servidor from '@/modules/network/pages/Servidor/Servidor'
import Servidores from '@/modules/network/pages/Servidor/Servidores'
// import Historico from '@/modules/network/pages/Historico/Historico'
import Mapa from '@/modules/network/pages/Mapa/MapaV2'
import ImportacaoTheDude from '@/modules/network/pages/Importacao/ImportacaoTheDude'
import GoogleMapsGrupos from '@/modules/network/pages/GoogleMaps/GoogleMapsGrupos'
// import MobileChamados from '@/modules/network/pages/Mobile/MobileChamados'
import MobileGrupo from '@/modules/network/pages/Mobile/MobileGrupo'
import MobileDisp from '@/modules/network/pages/Mobile/MobileDisp'
import MobileSobre from '@/modules/network/pages/Mobile/MobileSobre'
import NetworkTypes from '@/modules/network/pages/NetworkTypes'
import GrupoInserirImportar from '@/modules/network/pages/Grupo/GrupoInserirImportar'
import DispInserirImportar from '@/modules/network/pages/Device/DispInserirImportar'
import DispIndisponibilidades from '@/modules/network/pages/DeviceEvents'

import Device from '@/modules/network/pages/Device'
import NetworkServer from '@/modules/network/pages/NetworkServer/NetworkServer'

import DeviceLeaseRemove from '@/modules/network/pages/Tolls/DeviceLeaseRemove/DeviceLeaseRemove'

import OLTsDashboard from '@/modules/network/pages/Dashboards/OLTsDashboard'
import NetworksDashboard from '@/modules/network/pages/Dashboards/NetworksDashboard'

export default [
  {
    path: 'my-networks',
    name: 'minha-rede',
    component: GruposTopologia,
    meta: {
      id: '7517de8a',
      auth: true,
      title: 'Minhas Redes',
      icon: 'show_chart'
    }
  },
  {
    path: 'network-servers',
    name: 'servidores',
    component: Servidores,
    meta: {
      id: '83d002ee',
      auth: true,
      title: 'Servidores de monitoramento',
      icon: 'cloud_queue'
    }
  },
  {
    path: 'servidor/:id',
    name: 'servidor',
    component: Servidor,
    meta: {
      id: '83d002ee',
      auth: true,
      title: 'Servidor',
      icon: 'cloud_queue'
    }
  },
  {
    path: 'device/:id',
    name: 'device',
    component: Device,
    meta: {
      id: 'cb59faf8',
      auth: true,
      title: 'Detalhes do Dispositivo',
      icon: 'dns'
    }
  },
  {
    path: 'network-server/:id',
    name: 'network-server',
    component: NetworkServer,
    meta: {
      id: '83d002ee',
      auth: true,
      title: 'Servidor de monitoramento',
      icon: 'cloud_queue'
    }
  },
  {
    path: 'device-events',
    name: 'indisponibilidades',
    component: DispIndisponibilidades,
    meta: {
      auth: true,
      title: 'Eventos dos dispositivos',
      icon: 'arrow_downward',
      touch: true
    }
  },
  // {
  //   path: 'historico/:id',
  //   name: 'historico',
  //   component: Historico,
  //   meta: {
  //     auth: true,
  //     title: 'Historico'
  //   }
  // },
  {
    path: 'mapa/:id',
    name: 'mapa',
    component: Mapa,
    meta: {
      auth: true,
      title: 'Mapa'
    }
  },
  {
    path: 'importacao-the-dude/:id',
    name: 'importacao-the-dude',
    component: ImportacaoTheDude,
    meta: {
      id: '31f8eef9',
      auth: true,
      title: 'Importar do The Dude'
    }
  },
  {
    path: 'network-topology-map/:id',
    name: 'google-maps-grupos',
    component: GoogleMapsGrupos,
    meta: {
      id: '7517de8a',
      auth: true,
      title: 'Topologia de rede no Google Maps',
      icon: 'streetview'
    }
  },
  // {
  //   path: 'mobile-chamados',
  //   name: 'mobile-chamados',
  //   component: MobileChamados,
  //   meta: {
  //     auth: true,
  //     title: 'Mobile Chamados'
  //   }
  // },
  {
    path: 'mobile-grupo',
    name: 'mobile-grupo',
    component: MobileGrupo,
    meta: {
      id: '7517de8a',
      auth: true,
      title: 'Mobile Grupo'
    }
  },
  {
    path: 'mobile-disp',
    name: 'mobile-disp',
    component: MobileDisp,
    meta: {
      auth: true,
      title: 'Mobile Dispositivos'
    }
  },
  {
    path: 'mobile-sobre',
    name: 'mobile-sobre',
    component: MobileSobre,
    meta: {
      auth: true,
      title: 'Mobile Sobre'
    }
  },
  {
    path: 'network-hierarchy',
    name: 'hierarquia-de-rede',
    component: NetworkTypes,
    meta: {
      id: '31f8eef9',
      auth: true,
      title: 'Hierarquia de Rede',
      icon: 'vertical_split'
    }
  },
  {
    path: 'grupos-inserir-importar',
    name: 'grupos-inserir-importar',
    component: GrupoInserirImportar,
    meta: {
      id: '31f8eef9',
      auth: true,
      title: 'Importar ou Inserir Grupos',
      icon: 'import_export'
    }
  },
  {
    path: 'dispositivos-inserir-importar',
    name: 'dispositivos-inserir-importar',
    component: DispInserirImportar,
    meta: {
      id: '31f8eef9',
      auth: true,
      title: 'Importar ou Inserir Dispositivos',
      icon: 'import_export'
    }
  },
  {
    path: 'device-lease-remove',
    name: 'device-lease-remove',
    component: DeviceLeaseRemove,
    meta: {
      id: '7ed9eff2',
      auth: true,
      title: 'Dispositivo Remover Lease',
      icon: 'filter_1'
    }
  },
  {
    path: 'network-topology-devices/:id',
    name: 'grupo-mapa-dispositivos',
    component: Mapa,
    meta: {
      id: 'cb59faf8',
      auth: true,
      title: 'Topologia de Dispositivos',
      icon: 'show_chart',
      config: {
        tipo: 'dispositivo',
        getUrl: 'grupo/disp-conexao/',
        getUrlParams: '?join=dispositivos,conexoes',
        updateUrl: 'grupo/disp',
        updateModalNode: '',
        updateIDName: 'grupo_disp_id',
        campoDispositivoName: 'dispositivos',
        edgeEditModal: 'dispositivos-conexao',
        routeReturn: { name: 'list', params: { page: 'grupos' } }
      }
    }
  },
  {
    path: 'network-topology/:id',
    name: 'grupo-mapa-grupos',
    icon: 'show_chart',
    component: Mapa,
    meta: {
      id: '7517de8a',
      auth: true,
      title: 'Topologia de Rede',
      config: {
        tipo: 'grupo',
        getUrl: 'grupo/grupo-conexao/',
        getUrlParams: '?join=grupos,conexoes',
        updateUrl: 'grupo/dados',
        updateModalNode: '',
        updateIDName: 'id',
        campoDispositivoName: 'grupos',
        edgeEditModal: 'grupo-conexao',
        routeReturn: { name: 'list', params: { page: 'grupos' } }
      }
    }
  },
  {
    path: 'olt-dashboard',
    name: 'olt-dashboard',
    component: OLTsDashboard,
    meta: {
      id: 'cb59faf8',
      auth: true,
      title: 'Dashboard OLTs',
      icon: 'dns'
    }
  },
  {
    path: 'networks-dashboard',
    name: 'networks-dashboard',
    component: NetworksDashboard,
    meta: {
      id: 'cb59faf8',
      auth: true,
      title: 'Dashboard OLTs',
      icon: 'dns'
    }
  }
]
