import { Model } from '@/default/model/Model'
import {
  PERMISSION_FORM,
  PERMISSION_FORM_CREATE,
  PERMISSION_FORM_UPDATE,
  PERMISSION_FORM_DELETE
} from '@/default/constants/permissions'

export class ProcessStageResult extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_FORM,
      create: PERMISSION_FORM_CREATE,
      update: PERMISSION_FORM_UPDATE,
      delete: PERMISSION_FORM_DELETE
    }
    this.name = 'process-stage-result'
    this.title = {
      list: 'Resultados da Etapa',
      edit: {
        insert: 'Novo Resultado',
        update: 'Editar Resultado'
      }
    }
    this.params = {
      limit: 10,
      order: 'order,asc',
      with: 'result_stage:id,name,type_id,order'
    }
    this.icon = 'account_tree'
    this.maxWidth = '1000px'
    this.apiUrl = 'processes/stage-result'
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Processes/ProcessStageResults')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    type_id: this.fieldList({
      value: 'type_id',
      text: 'Resultado'
    }),
    result_stage_id: this.fieldList({
      value: 'result_stage_id',
      text: 'Etapa que será executada'
    }),
    level: this.fieldList({
      value: 'level',
      text: 'Nível',
      sort: false
    }),
    order: this.fieldList({
      value: 'order',
      text: 'Prioridade'
    }),
    count: this.fieldList({
      value: 'count',
      text: 'N° Execuções'
    })
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Selecione o resultado e a etapa que será executada.'
    }),
    result_id: this.fieldSelectApi({
      value: 'result_id',
      text: 'Resultado',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (!form.id && form.type_id != 11 && form.type_id != 12),
      api: {
        url: 'processes/stage-result/type',
        params: form => ({
          process_stage_id: form.process_stage_id
        })
      },
      show: 'name',
      icon: 'icon',
      color: 'color'
    }),
    DiagnosticHostTechnologyIdResultId: this.fieldSelectApi({
      value: 'result_id',
      text: 'Resultado',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (!form.id && form.type_id == 12),
      api: {
        url: 'network/host/technology',
        params: form => ({
          process_stage_id: form.process_stage_id
        })
      },
      show: 'name',
      icon: 'icon',
      color: 'color'
    }),
    DiagnosticCategoryIdResultId: this.fieldSelectApi({
      value: 'result_id',
      text: 'Resultado',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (!form.id && form.type_id == 11),
      api: {
        url: 'networks/config/category/test-management-vtal',
        params: form => ({
          process_stage_id: form.process_stage_id
        })
      },
      show: 'name',
      icon: 'icon',
      color: 'color'
    }),
    result_stage_id: this.fieldWiListDialog({
      value: 'result_stage_id',
      text: 'Etapa que será executada',
      required: true,
      size: ['xs12', 'sm12', 'md9', 'lg9'],
      wiConfig: 'process-stage',
      filter: form => ({
        process_id: form.process_id,
        withCount: 'results'
      }),
      wiEditData: form => ({
        process_id: form.process_id
      }),
      show: 'result_stage.name',
      icon: 'account_tree'
    }),
    order: this.fieldNumber({
      value: 'order',
      text: 'Prioridade do resultado',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true
    })
  }
}
