<template>
  <div>
    <viewdetail title="Servidor" :routeReturn="{'name': 'list', 'params': {'page': 'servidores'}}" :apiUrl="'servidor/dados'" :apiParams="'?join=servidor_cidade'">
      <template slot-scope="props"> 
        <v-layout row>
          <v-flex xs12 sm8 md6 lg6 offset lg3 style="margin:auto;">
            <v-card>
              <center>
                <br>
                <v-avatar :size="100" class="grey lighten-4">
                  <img src="/static/icons/perfil_servidor/servidor_teste1.png" alt="avatar">
                </v-avatar>
                <h2>{{props.content.nome_servidor}}</h2>
              </center>
              <v-divider style="margin-top:20px;"></v-divider>
              <v-btn style="margin-right:10px;margin-top:-20px;"  color="blue darken-5" fab small @click="openEditModal('servidores', props.content)">
                <v-icon small color="white">edit</v-icon>
              </v-btn>
              <v-btn color="cyan" fab small style="margin-right:10px;margin-top:-20px;" icon @click="$router.push({name:'historico'})">
                <v-icon small color="white">history</v-icon>
              </v-btn>
              <v-btn color="cyan" fab small style="margin-right:10px;margin-top:-20px;" icon @click="$router.push({name:'importacao-the-dude', params: {id: props.content.id}})">
                <v-icon small color="white">history</v-icon>
              </v-btn>
              <v-card-text style="padding: 0;">
                <v-tabs v-model="ativo" icons-and-text centered  slot="extension" grow  color="dark">
                  <v-tabs-slider color="blue"></v-tabs-slider>
                  <v-tab href="#tab-1">
                    <span>Informações</span>
                    <v-icon>info</v-icon>
                  </v-tab>
                  <!-- <v-tab href="#tab-2">
                    <span>Cidades</span>
                    <v-icon>location_city</v-icon>
                  </v-tab>
                  <v-tab href="#tab-3">
                    <span>Mapa</span>
                    <v-icon>map</v-icon>
                  </v-tab> -->
                  <v-tab-item id="tab-1" style="min-height: 200px;">
                    <div v-if="ativo=='tab-1'">
                      <tab-informacoes :dados="props.content"></tab-informacoes>
                    </div>
                  </v-tab-item> 
                  <v-tab-item id="tab-2" style="min-height: 200px;">
                    <div v-if="ativo=='tab-2'">
                      <v-flex xs12 sm12 lg12 >
                        <v-toolbar dense color="blue darken-4">
                          <v-fab-transition>
                          </v-fab-transition>
                          <h3 style="padding-left: 20px; color:white;" >Vincule cidades à este servidor</h3>
                          <v-spacer></v-spacer>
                          <v-btn style="margin-right:25px;" color="white" fab small @click="openEditModal('servidor-cidade', {servidor_dados_id: props.content.id})">
                            <v-icon small color="blue darken-4">add</v-icon>
                          </v-btn>
                        </v-toolbar>
                      </v-flex>
                      <tab-cidade :dados="props"></tab-cidade>
                    </div>
                  </v-tab-item>
                  <v-tab-item id="tab-3" style="min-height: 200px;">
                    <div v-if="ativo=='tab-3'">
                      <div v-if="props.content.latitude_servidor || props.content.longitude_servidor"></div>
                      <tab-mapa :lat="props.content.latitude_servidor" :lng="props.content.longitude_servidor"></tab-mapa>
                    </div>                  
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <div v-if="editModal">
          <editmodal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="props.refresh"></editmodal>
        </div>
      </template>
    </viewdetail>
  </div>
</template>
<script>
  import EditModal from '@/default/components/Modal/EditModal'
  import viewDetail from '@/default/components/ViewDetail'
  import Informacoes from '@/modules/network/pages/Servidor/ServidorInformacoes'
  import Mapa from '@/modules/network/pages/Servidor/ServidorMapa'
  import Cidade from '@/modules/network/pages/Servidor/ServidorCidade'

  export default {
    name: 'Servidor',
    data () {
      return {
        ativo: 'tab-1',
        editModal: false,
        modalForm: {},
        modalStore: 'servidor',
        url: ''
      }
    },
    methods: {
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      },
      openURL: function (url) {
        window.open(url, '_blank')
      }
    },
    components: {
      editmodal: EditModal,
      'tab-cidade': Cidade,
      'tab-mapa': Mapa,
      'tab-informacoes': Informacoes,
      viewdetail: viewDetail
    }
  }
</script>
<style type="text/css">
.conteudo_card{
  margin-left: 2%;
}
</style>