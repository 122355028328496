export default [
  {
    id: 'c4f8c247',
    icon: 'event_seat',
    title: 'Sala de Espera',
    route: {name: 'clinic-list', params: { page: 'task-clinic' }}
  },
  {
    id: 'c4f8c247',
    icon: 'event',
    title: 'Agenda',
    route: { name: 'clinic-calendar' }
  },
  {
    id: '015b8d97',
    icon: 'assignment',
    title: 'Prontuários',
    route: {name: 'clinic-list', params: { page: 'medical-records' }}
  },
  {
    id: '549b7548',
    icon: 'people',
    title: 'Pacientes',
    route: {name: 'clinic-list', params: { page: 'patient' }}
  },
  {
    id: 'c4f8c247',
    icon: 'fact_check',
    title: 'Todos os Agendamentos',
    children: [
      {id: 'c4f8c247', icon: 'event', title: 'Agendamentos Em Aberto', route: {name: 'clinic-list', params: {page: 'task-clinic-opened'}}},
      {id: 'c4f8c247', icon: 'event_available', title: 'Histórico de Agendamentos', route: {name: 'clinic-list', params: {page: 'task-clinic-closed'}}},
      {id: 'c4f8c247', icon: 'event_busy', title: 'Desistências', route: {name: 'clinic-list', params: {page: 'task-clinic-canceled'}}}
    ]
  },
  {
    id: '0f5529e5',
    icon: 'settings',
    title: 'Configurações',
    children: [
      {id: '0f5529e5', icon: 'category', title: 'Categorias de Evolução', route: {name: 'clinic-list', params: {page: 'config-category-evolution'}}},
      {id: '0f5529e5', icon: 'category', title: 'Categorias de Anamnese', route: {name: 'clinic-list', params: {page: 'config-category-anamnesis'}}},
      {id: '0f5529e5', icon: 'category', title: 'Categorias de Receituário', route: {name: 'clinic-list', params: {page: 'config-category-prescription'}}},
      {id: '0f5529e5', icon: 'category', title: 'Categorias de Solicitação de Exame', route: {name: 'clinic-list', params: {page: 'config-category-examRequest'}}},
      {id: '0f5529e5', icon: 'category', title: 'Categorias de Atestado', route: {name: 'clinic-list', params: {page: 'config-category-attested'}}},
      {id: '0f5529e5', icon: 'code', title: 'Status/Etapas da Evolução', route: {name: 'clinic-list', params: {page: 'config-status-evolution'}}},
      {id: '0f5529e5', icon: 'code', title: 'Status/Etapas da Anamnese', route: {name: 'clinic-list', params: {page: 'config-status-anamnesis'}}},
      {id: '0f5529e5', icon: 'code', title: 'Status/Etapas do Receituário', route: {name: 'clinic-list', params: {page: 'config-status-prescription'}}},
      {id: '0f5529e5', icon: 'code', title: 'Status/Etapas da Solicitação de Exame', route: {name: 'clinic-list', params: {page: 'config-status-exam-request'}}},
      {id: '0f5529e5', icon: 'code', title: 'Status/Etapas do Atestado', route: {name: 'clinic-list', params: {page: 'config-status-attested'}}},
    ]
  },
  // {id: 'vnds', icon: 'local_activity', title: 'Gestão de Contratos', route: {name: 'disponivel-em-breve'}},
  // {
  //   id: '7bea0895',
  //   icon: 'bar_chart',
  //   title: 'Relatórios',
  //   children: [
  //     {id: '1b4f745c', icon: 'people_outline', title: 'Vendas por cliente', route: {name: 'clinic-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
  //     {id: '919bb8fa', icon: 'people_outline', title: 'Orçamentos por cliente', route: {name: 'budgets-by-customer', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
  //     {id: 'dab5ce52', icon: 'assignment_ind', title: 'Vendas por Vendedor', route: {name: 'clinic-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}},
  //     {id: 'bffe8c55', icon: 'assignment_ind', title: 'Orçamentos por Vendedor', route: {name: 'budgets-by-collaborator', query: { date_field: 'date_start', date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01' }}}
  //   ]
  // },
  {
    id: 'd897f3f3',
    icon: 'description',
    title: 'Cadastro',
    children: [
      {id: 'd897f3f3', icon: 'assignment_ind', title: 'Médicos e Secretária(o)s', route: {name: 'clinic-list', params: {page: 'clinic-register-collaborator'}}, selected: false, permission: ['admin']},
      {id: '549b7548', icon: 'people', title: 'Pacientes', route: {name: 'clinic-list', params: { page: 'patient' }}}
    ]
  }
]
