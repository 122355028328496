export default {
  id: '2f84111b',
  title: 'Transferir Chamado para outra equipe',
  apiUrl: 'service-desk/ticket',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Selecione a nova equipe responsável por este chamado.', type: 'subheader', edit: true},
    {value: 'team_id', text: 'Novo Equipe Responsável', autocomplete: true, required: true, type: 'db-autocomplete', edit: true, apiUrl: 'register/team', apiParams: {fields: 'id,name'}, show: 'name'}
  ],
  fieldsFixed: [
    {
      value: 'change_ticket_team', data: true
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
