<template>
  <div>
    <v-data-table :headers="headers" :items="hostsFormated" dense disable-initial-sort :rows-per-page-items="[10, 5, 50, 100, 200]" rows-per-page-text="Ítens por página">
      <template v-slot:items="{ item }">
        <tr :class="{'table__row--updated': item.updated}">
          <td class="justify-center">
            <v-btn icon @click="selectHost(item)">
              <v-icon color="primary">visibility</v-icon>
            </v-btn>
          </td>
          <td class="justify-center">
            <strong>
              <span v-if="item.serial">{{ item.serial }}</span>
              <br v-if="item.serial && item.mac_address">
              <span v-if="item.mac_address">{{ item.mac_address }}</span>
            </strong>
          </td>
          <td class="justify-center" style="cursor: pointer; font-size: 23px;">
            <HostSignalDialog :host="item">
              <span v-if="item.online">
                <strong :style="{color: item.signal_is_ok ? 'green' : 'orange'}">
                  {{ item.signal || '- - -' }}
                </strong>
              </span>
              <span v-else>
                <strong v-if="item.complement && item.complement.status" style="font-size: 15px; color: orange;">
                  <span v-if="item.complement.status == 1 || item.complement.status == 'undefined'" style="font-size: 18px; color: red;">
                    Offline
                  </span>
                  <span v-else-if="item.complement.status == 2 || item.complement.status == 'LOS'">
                    Link Loss
                  </span>
                  <span v-else-if="item.complement.status == 3 || item.complement.status == 'DyingGasp'">
                    DiyngGasp
                  </span>
                  <span v-else style="font-size: 18px; color: red;">
                    Offline
                  </span>
                </strong>
                <span v-else style="font-size: 18px; color: red;">
                  Offline
                </span>
              </span>
            </HostSignalDialog>
          </td>
          <td class="justify-center">
            <strong>{{ item.producer.name || '- - -' }}</strong><br>
            <span>{{ item.producer_model.name || '- - -' }}</span>
          </td>
          <td class="justify-center">
            <strong v-if="item.customer_name">
              {{ item.customer_name }}
            </strong>
            <span v-else> - - - </span>
          </td>
          <td class="justify-center">
            <span v-if="item.customer_address">
              {{ item.customer_address }}
            </span>
            <span v-else> - - - </span>
          </td>
          <td class="justify-center">
            <v-tooltip v-if="item.checked_at" bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <span>{{ item.checked_at.current }}</span>
                </div>
              </template>
              <span>{{ item.checked_at.dateTime }}</span>
            </v-tooltip>
            <span v-else> - - - </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <HostDetailDialog
      v-model="hostDetailDialog"
      :host="selectedHost"
      @refresh="refresh"
    ></HostDetailDialog>
  </div>
</template>
<script>
  import { DateTimeCurrent } from '@/default/plugins/Utils/DateTimeCurrent'
  import HostSignalDialog from '@/modules/network/pages/Host/HostSignalDialog'
  import HostDetailDialog from '@/modules/network/pages/Host/HostDetail/HostDetailDialog'
  export default {
    name: 'DeviceHostsDialogTable',
    data: () => ({
      selectedHost: null,
      hostDetailDialog: false,
      headers: [
        { text: 'Opções', align: 'center', value: 'id', sortable: false },
        { text: 'Serial/MAC', align: 'center', value: 'mac_address' },
        { text: 'Sinal', align: 'center', value: 'signal' },
        { text: 'Fabricante/Modelo', align: 'center', value: 'producer_id' },
        { text: 'Cliente', align: 'center', value: 'customer_name' },
        { text: 'Endereço', align: 'center', value: 'customer_address' },
        { text: 'Verificação', align: 'center', value: 'checked_at' }
      ]
    }),
    computed: {
      hostsFormated () {
        return this.hosts.map(host => ({
          ...host,
          checked_at: DateTimeCurrent.format(host.checked_at),
          customer_name: host?.contracts[0]?.person?.name || null,
          customer_address: host?.contracts[0]?.address_full || null
        }))
      }
    },
    methods: {
      refresh: function () {
        this.$emit('refresh')
      },
      selectHost: function (host) {
        this.selectedHost = host
        this.hostDetailDialog = true
      }
    },
    props: {
      hosts: {
        default: () => ([])
      }
    },
    components: {
      HostSignalDialog,
      HostDetailDialog
    }
  }
</script>
<style scoped>
  .table__row--updated {
    background: rgb(187, 252, 187);
  }
</style>