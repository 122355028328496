<template>
  <v-card class="elevation-17">
    <div v-if="business && business.items && business.items.length > 0">
      <v-data-table
        dense
        :headers="selectedHeaders"
        :items="business.items"
        class="elevation-0"
        hide-actions
      >
        <template v-slot:headers="props">
          <tr style="height: 40px;">
            <th
              v-for="header in props.headers"
              class="hidden-xs-only hidden-sm-only"
              :key="header.text"
              style="text-align: left;margin: 0 !important; padding: 0px !important;"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <td class="justify-center layout px-0" style="margin-top: 0px;">
            <v-btn
              v-if="props.item.item.type == 1" 
              :disabled="business.stage != 1"
              @click="$WiEditDialog({
                wiConfig: wiConfigId + '-item-product',
                onSubmit: onRefresh,
                data: props.item,
                redirectOnSubmit: false
              })"
              icon
              class="mx-0"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn 
              v-if="props.item.item.type == 2"
              :disabled="business.stage != 1" 
              @click="$WiEditDialog({
                wiConfig: wiConfigId + '-item-service',
                onSubmit: onRefresh,
                data: props.item,
                redirectOnSubmit: false
              })"
              icon
              class="mx-0"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <db-delete
              v-if="!!onRefresh"
              :disabled="business.stage != 1"
              class="mx-0"
              @action="onRefresh"
              :store="wiConfigId + '-item-service'"
              :id="props.item.id"
            ></db-delete>
          </td>
          <td style="padding: 0 !important; font-size: 11px;">
            {{ props.item.item.name || '- - -' }}
          </td>
          <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 11px;">
            {{ props.item.description || '- - -' }}
          </td>
          <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important;">
            {{ props.item.quantity | float }}
          </td>
          <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important;">
            {{ props.item.item.unit.code || '- - -' }}
          </td>
          <td v-if="showAmounts" class="hidden-xs-only hidden-sm-only" style="padding: 0 !important;">
            {{ props.item.amount_unit | money }}
          </td>
          <td v-if="showAmounts" class="hidden-xs-only hidden-sm-only" style="padding: 0 !important;">
            {{ props.item.amount_discount | money }}
          </td>
          <td v-if="showAmounts" style="padding: 0 !important;">
            {{ props.item.amount_total | money }}
          </td>
        </template>
        <template v-slot:footer>
          <tr  v-if="showAmounts" class="hidden-xs-only hidden-sm-only" style="height: 40px;">
            <td class="justify-center layout px-0" style="margin-top: 0px;"></td>
            <td style="padding: 0 !important; font-size: 11px;"></td>
            <td style="padding: 0 !important; font-size: 11px;"></td>
            <td style="padding: 0 !important;">
              <strong>
                {{ business.sum_quantity_unit | float }}
              </strong>
            </td>
            <td style="padding: 0 !important; font-size: 11px;"></td>
            <td v-if="showAmounts" style="padding: 0 !important;">
              <strong>
                {{ business.sum_amount_unit | money }}
              </strong>
            </td>
            <td v-if="showAmounts" style="padding: 0 !important;">
              <strong>
                {{ business.sum_discount_unit | money }}
              </strong>
            </td>
            <td v-if="showAmounts" style="padding: 0 !important;">
              <strong>
                {{ business.sum_amount_total | money }}
              </strong>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-layout row wrap style="padding-top: 15px; padding-bottom: 15px;">
        <v-flex xs12 sm12 md7 lg7>
          <div style="width: 100%; text-align: center;">
            <div style="text-align: center;">
              <v-btn
                :disabled="business.stage != 1"
                outline small
                color="primary"
                @click="$WiEditDialog({
                  wiConfig: wiConfigId + '-item-product',
                  onSubmit: onRefresh,
                  data: {
                    business_id: business.id
                  },
                  redirectOnSubmit: false
                })"
              >
                <v-icon>add</v-icon>
                <span>Adicionar Produto</span>
              </v-btn>
              <v-btn
                :disabled="business.stage != 1"
                outline small
                color="primary"
                @click="$WiEditDialog({
                  wiConfig: wiConfigId + '-item-service',
                  onSubmit: onRefresh,
                  data: {
                    business_id: business.id
                  },
                  redirectOnSubmit: false
                })"
              >
                <v-icon>add</v-icon>
                <span>Adicionar Serviço</span>
              </v-btn>
            </div>
          </div>
        </v-flex>
        <v-flex v-if="showAmounts" xs12 sm12 md5 lg5>
          <table style="width: 100%;">
            <tr>
              <td style="padding: 10px 0px; text-align: left; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <strong style="padding: 10px;">
                  Valor Total:
                </strong>
              </td>
              <td style="padding: 10px 0px; text-align: right; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <span style="padding: 10px; font-size: 16px;">
                  {{business.sum_amount_total | money}}
                </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 10px 0px; text-align: left; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 10px;">
                  Desconto:
                </strong>
              </td>
              <td style="padding: 10px 0px; text-align: right; border-bottom: solid 1px lightgray !important;">
                <span style="padding: 10px; font-size: 16px;">
                  {{business.amount_discount | money}}
                </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 10px 0px; text-align: left; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 10px;">
                  Frete:
                </strong>
              </td>
              <td style="padding: 10px 0px; text-align: right; border-bottom: solid 1px lightgray !important;">
                <span style="padding: 10px; font-size: 16px;">
                  {{business.amount_freight | money}}
                </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 10px 0px; text-align: left; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 10px; font-size: 20px;">
                  Total líquido:
                </strong>
              </td>
              <td style="padding: 10px 0px; text-align: right; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 10px; font-size: 20px;">
                  {{business.total_liquid | money}}
                </strong>
              </td>
            </tr>
          </table>
        </v-flex>
        <v-flex v-else xs12 sm12 md5 lg5>
          <table style="width: 100%;">
            <tr>
              <td style="padding: 10px 0px; text-align: left; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <strong style="padding: 10px; font-size: 20px;">
                  Quantidade total:
                </strong>
              </td>
              <td style="padding: 10px 0px; text-align: right; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <strong style="padding: 10px; font-size: 20px;">
                  {{ business.sum_quantity_unit | float }} 
                  <span v-if="business.sum_quantity_unit > 1"> itens</span>
                  <span v-else> item</span>
                </strong>
              </td>
            </tr>
          </table>
        </v-flex>
      </v-layout>
    </div>
    <div v-else style="text-align: center; padding: 20px;">
      <h3>Nenhum Produto ou Serviço vinculados.</h3>
      <v-btn
        :disabled="business.stage != 1"
        outline small
        color="primary"
        @click="$WiEditDialog({
          wiConfig: wiConfigId + '-item-product',
          onSubmit: onRefresh,
          data: {
            business_id: business.id
          },
          redirectOnSubmit: false
        })"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Produto</span>
      </v-btn>
      <v-btn
        :disabled="business.stage != 1"
        outline small
        color="primary"
        @click="$WiEditDialog({
          wiConfig: wiConfigId + '-item-service',
          onSubmit: onRefresh,
          data: {
            business_id: business.id
          },
          redirectOnSubmit: false
        })"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Serviço</span>
      </v-btn>
    </div>
  </v-card>   
</template>
<script>
  export default {
    name: 'BusinessItems',
    data () {
      return {
        headers: [
          { text: '', value: 'id', sortable: false },
          { text: 'Produto/Serviço', value: 'nome_produto' },
          { text: 'Detalhes', value: 'desc_negocio_produto' },
          { text: 'Qtd.', value: 'qtd_produto' },
          { text: 'Unid.', value: 'code' },
          { text: 'Val. Unit.', value: 'valor_unit' },
          { text: 'Desconto', value: 'valor_desc' },
          { text: 'Val. Total', value: 'valor_total' }
        ],
        filters: this.$options.filters
      }
    },
    computed: {
      selectedHeaders () {
        const amountFields = ['valor_unit', 'valor_desc', 'valor_total']
        return this.headers.filter(
          header => (amountFields.indexOf(header.value) === -1 || this.showAmounts)
        )
      }
    },
    props: {
      'wiConfigId': {
        default: ''
      },
      'business': {
        default: {}
      },
      'onRefresh': {
        required: true
      },
      'showAmounts': {
        default: true
      }
    }
  }
</script>
<style>
p {
  margin-bottom: 0px !important;
}
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
</style>
