<template>
  <v-container fluid style="padding: 0;">
    <v-layout row>
      <div class="talks-area" style="width: 352px;min-width: 352px;" d-flex xs12 sm12 md4 lg3 xl2>
        <v-card tile :style="{height: pageHeight}">
          <TalksLists></TalksLists>
        </v-card>
      </div>
      <div class="talk-selected-area hidden-xs-only hidden-sm-only">
        <v-card tile style="width: 100%;">
          <router-view v-if="$route.params.id"></router-view>
          <div v-else style="width: 100%; text-align: center; margin: auto; max-width: 400px;">
            <img src="static/background/talks-not-selected-1.png?url=temp2" alt="" style="width: 100%">
            <br>
            <span
              style="font-size: 20px;"
              v-t="'modules.talks.pages.talkView.talk.noTalkSelectedText'"
            ></span>
          </div>
        </v-card>
      </div>
    </v-layout>
  </v-container>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import TalksLists from '@/modules/talk/pages/TalkView/Talks/TalksLists'
  export default {
    name: 'Talks',
    data () {
      return {
        tabs: 1,
        pageHeight: innerHeight
      }
    },
    computed: {
      ...mapState('Talks', ['talks', 'loadings']),
      ...mapGetters('Talks', ['unreadsQueue', 'unreadsMyTalks', 'unreadsOthers']),
      user () {
        return this.$store.state.user
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapActions('Talks', ['getTalks']),
      newTalk: function () {
        this.$WiListDialog({
          wiConfig: 'talk-contact',
          mode: 'select',
          callback: (contact) => {
            if (contact) {
              this.$WiEditDialog({
                wiConfig: 'talk',
                data: {
                  status: 2,
                  talk_contact_id: contact.id,
                  talk_channel_id: contact.talk_channel_id,
                  operator_id: this.$Auth.user().id,
                  responsible_id: this.$Auth.user().id,
                  contact: contact,
                  channel: contact.channel || {}
                },
                onSubmit: () => {
                  this.getTalks()
                  // this.$router.push({name: 'talk-view-messages', params: {id: response.sucess.id}})
                }
              })
              // this.$WiApiPost({
              //   uri: 'talk/talk',
              //   data: {
              //     status: 2,
              //     talk_contact_id: contact.id,
              //     talk_channel_id: contact.talk_channel_id,
              //     operator_id: this.$Auth.user().id,
              //     responsible_id: this.$Auth.user().id
              //   },
              //   config: {msgLoad: true, msgSucess: true},
              //   callback: (response) => {
              //     this.$router.push({name: 'talk-view-messages', params: {id: response.sucess.id}})
              //     this.getTalks()
              //   }
              // })
            }
          },
          params: {order: 'created_at,desc'},
          filter: {order: 'created_at,desc'}
        })
      },
      setInnerHeight: function (heigth) {
        this.pageHeight = ((heigth || innerHeight) - 49) + 'px'
      }
    },
    mounted: function () {
      window.addEventListener('resize', () => {
        this.setInnerHeight(innerHeight)
      }, true)
      this.setInnerHeight(innerHeight)
    },
    components: {
      TalksLists
    }
  }
</script>
<style>
  .talks__toolbar .v-toolbar__extension {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .talks__toolbar-tabs {
    color: white;
  }
  .talks__toolbar-tabs .v-tabs__wrapper {
    color: white;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .talks__toolbar-tabs .v-tabs__item {
    color: white !important;
  }
  /* media query to set width 100% in sm and xs and set width 352px in other sizes */
  .talks-area {
    width: 352px;
  }
  @media (max-width: 960px) {
    .talks-area {
      width: 100% !important;
    }
  }
  .talk-selected-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
  }
</style>