<template>
  <v-container fluid grid-list-md style="padding-top: 0;">
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg12>
        <h3>Estrutura</h3>
        <v-treeview :items="items"></v-treeview>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            id: 2,
            name: 'Início'
          },
          {
            id: 3,
            name: 'Minha Rede',
            children: [
              {
                id: 5,
                name: 'Grupos',
                children: [
                  {
                    id: 7,
                    name: 'Google Maps'
                  },
                  {
                    id: 8,
                    name: 'Detalhes do grupo'
                  },
                  {
                    id: 9,
                    name: 'Topologia'
                  }
                ]
              },
              {
                id: 6,
                name: 'Dispositivos',
                children: [
                  {
                    id: 10,
                    name: 'Topologia'
                  },
                  {
                    id: 11,
                    name: 'Detalhes do Dispositivo'
                  }
                ]
              }
            ]
          },
          {
            id: 4,
            name: 'Meus Chamados',
            children: [
              {
                id: 12,
                name: 'Detalhes do Chamado'
              }
            ]
          }
        ]
      }
    }
  }
</script>