<template>
  <div style="padding: 0px 20px 20px 20px;">
    <talk-message
    v-for="(message, messageKey) in getMessagesOld"
    :key="messageKey + '-old'"
    :message="message"
    v-on:reply="replyMessage"
    v-on:share="shareMessage"
    v-on:showReply="showReplyMessage"
    ></talk-message>
    <talk-message
    v-for="(message, messageKey) in getMessages"
    :key="messageKey + '-area'"
    :message="message"
    v-on:reply="replyMessage"
    v-on:share="shareMessage"
    v-on:showReply="showReplyMessage"
    ></talk-message>
    <talk-message
    v-for="(message, messageKey) in newMessages"
    :key="messageKey + '-new'"
    :message="message"
    v-on:reply="replyMessage"
    v-on:share="shareMessage"
    v-on:showReply="showReplyMessage"
    ></talk-message>
    <v-dialog v-model="showReplyMessageDialog">
      <v-card>
        <v-toolbar dense>
          <v-spacer></v-spacer>
          <v-btn icon @click="showReplyMessageDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="padding: 20px;">
          <talk-message v-if="showReplyMessageContent" :message="showReplyMessageContent" class="talk-message__message-balloon-text-reply"></talk-message>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>        
</template>
<script>
  import TalkMessage from '@/modules/talk/pages/Talk/TalkMessage'
  export default {
    name: 'TalkMessages',
    data () {
      return {
        showReplyMessageContent: {},
        showReplyMessageDialog: false
      }
    },
    methods: {
      openFile: function (file) {
        window.open(file.url)
      },
      checkIfTextIsYouTubeVideoUrl: function (url) {
        var isYouTubeUrl = false
        if (url !== undefined || url !== '') {
          // eslint-disable-next-line
          url = url + ''
          // eslint-disable-next-line
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
          var match = url.match(regExp)
          if (match && match[2].length === 11) {
            isYouTubeUrl = true
          }
        }
        return isYouTubeUrl
      },
      checkIfTextIsUrl: function (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$', 'i')
        return !!pattern.test(str)
      },
      replyMessage: function (message) {
        this.$emit('reply', message)
      },
      showReplyMessage: function (message) {
        console.log(message)
        this.showReplyMessageContent = message
        this.showReplyMessageDialog = true
      },
      shareMessage: function (message) {
        this.$emit('share', message)
      }
    },
    computed: {
      getMessages () {
        var messages = this.messages
        messages = messages.slice().reverse()
        return messages
      },
      getMessagesOld () {
        var paginateMessages = this.paginateMessages
        paginateMessages = paginateMessages.slice().reverse()
        return paginateMessages
      }
    },
    props: ['messages', 'newMessages', 'paginateMessages'],
    components: {
      TalkMessage
    }
  }
</script>

<style scoped>
  .talk-message__message {
    width: 100%;
    margin: 30px 5px;
  }
  .talk-message__message--from-me {
    text-align: left;
  }
  .talk-message__message-balloon {
    margin: 0px 60px;
    min-height: 50px;
    background: #FFF;
    border-radius: 0px 15px 15px 15px;;
  }
  .talk-message__message-balloon--from-me {
    background: #D4FCC8 !important;
    border-radius: 15px 0px 15px 15px;
  }
  .talk-message__message-balloon-text {
    padding: 5px 10px;
    color: #000;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    word-wrap: break-word;
  }
  .talk-message__message-balloon-info {
    width: 100% !important;
    text-align: right;
    font-size: 12px;
    padding: 0px 10px;
    color: gray;
  }
  .talk-message__message-avatar img {
    /* max-width: 25% !important; */
    background: #FFF;
  }
  .talk-message__message-balloon-contact {
    width: 100% !important;
    font-weight: bold;
    padding: 3px 10px;
    font-size: 12px;
  }
  .talk-message__message-balloon-contact--from-me {
    text-align: right;
  }
  .talk-message__message-balloon-shared {
    width: 100% !important;
    padding: 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .talk-message__message-balloon-text-reply {
    width: 70%;
    background: #c9c9c977;
    border-radius: 5px;
    padding: 0px 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }
</style>
