var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"talk-contact__profile-info"},[_c('TalkContactPhoto',{attrs:{"contact":_vm.contact,"refresh":_vm.refresh}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-evenly"}},[_c('span',[_c('h3',[(_vm.contact.person)?_c('span',[_vm._v(" "+_vm._s(_vm.contact.person.name)+" ")]):_vm._e(),(_vm.contact.person)?_c('br'):_vm._e(),_c('span',{staticStyle:{"color":"gray"},domProps:{"innerHTML":_vm._s(_vm.contact.name)}}),(_vm.contact.checked == 3)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","small":""}},on),[_vm._v("do_not_disturb_on")])]}}],null,false,3412842240)},[_c('span',[_vm._v(" Contato inválido ")])]):_vm._e(),(_vm.contact.checked == 2)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green","small":""}},on),[_vm._v("verified")])]}}],null,false,831305409)},[_c('span',[_vm._v(" Contato validado ")])]):_vm._e(),(_vm.contact.checked == 1 || !_vm.contact.checked)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":""}},on),[_vm._v("help")])]}}],null,false,2528864388)},[_c('span',[_vm._v(" Contato não validado ")])]):_vm._e(),(_vm.contact.type == 2)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.refreshContact()}}},on),[_vm._v("refresh")])]}}],null,false,3340058816)},[_c('span',[_vm._v(" Atualizar dados do contato ")])]):_vm._e()],1)]),(_vm.contact.type == 1)?_c('h3',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","font-size":"14px"}},[(_vm.contact.number.toString().length == 12)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("mask")(_vm.contact.number,'+## (##) ####-####'))+" ")])]):(_vm.contact.number.toString().length == 13)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("mask")(_vm.contact.number,'+## (##) # ####-####'))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.contact.number)+" ")]),_c('wi-copy-to',{attrs:{"title":"Número","color":"primary","text":_vm.contact.number}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.refreshContact()}}},on),[_vm._v("refresh")])]}}],null,false,3340058816)},[_c('span',[_vm._v(" Atualizar dados do contato ")])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }