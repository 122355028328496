export default {
  list: true,
  title: 'Seguimentos',
  icon: 'class',
  apiUrl: 'register/person/segment',
  fields: [
    {value: 'anexo', text: 'Anexo', type: 'text', size: ['xs12', 'sm2', 'md2', 'lg2'], required: false, list: true, edit: true},
    {value: 'codigo', text: 'Código', type: 'text', size: ['xs12', 'sm6', 'md6', 'lg6'], required: false, list: true, edit: true},
    {value: 'aliquota', text: 'Aliquota', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: false, list: true, edit: true},
    {value: 'descricao', text: 'Descricao', type: 'text', size: ['xs12', 'sm12', 'md12', 'lg12'], required: true, list: true, edit: true},
    {value: 'obs_pessoa', text: 'Observações', type: 'textarea', required: false, list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
