<template>
  <div class="form-reply-list-reply-message" :style="{color: field.color, 'border-color': field.color}">
    <span v-html="field.description || field.label"></span>
  </div>
</template>
<script>
  export default {
    name: 'FormReplyListReplyMessage',
    props: {
      field: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style scoped>
.form-reply-list-reply-message {
  border-style: solid;
  border-width: 1px;
  padding: 10px 16px;
}
</style>
