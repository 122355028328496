import { FinancePayment } from '@/modules/finance/config/Finance/Finance/FinancePayment'

export class FinancePaymentCreate extends FinancePayment {
  constructor () {
    super()
    this.title = 'Criar 2° via de recebimento'
    delete this.actions
  }

}
