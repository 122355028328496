import { PERMISSION_WELLS_MODULE } from '@/default/constants/permissions'
import router from '@/modules/wells/routes'
import config from '@/modules/wells/config'
import menu from '@/modules/wells/menu'

export default {
  id: PERMISSION_WELLS_MODULE,
  name: 'wells',
  title: 'Poços',
  description: 'Gestão de Poços Tubulares',
  icon: 'wises-technology-module-wells-2.png',
  iconSystem: 'wises-technology-system-sale.png',
  iconProfile: 'profile_service_desk.png',
  // routeDefault: { name: 'dashboard-sales' },
  color: '#367da6',
  menu,
  router,
  config
}
