
<template>
  <div>
    <div v-if="contact.type == 1 && !contact.person_id">
      <v-divider></v-divider>
      <div class="my-3">
        <h3 class="mb-3">Selecione o perfil deste contato:</h3>
        <div class="talk-contact-profile__profiles-buttons">
          <v-menu v-for="profile in profiles" :key="profile.id" lazy>
            <template v-slot:activator="{ on }">
              <v-btn
                :style="{margin: '5px'}"
                v-on="on" outline small
                :color="profile.color"
              >
                <v-icon>{{ profile.icon }}</v-icon>
                <span class="ml-1"> {{ profile.name }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile @click="createProfile(profile.id)">
                <v-list-tile-avatar>
                  <v-icon color="green">add</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    Novo {{ profile.name }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile @click="selectProfile(profile.id)">
                <v-list-tile-avatar>
                  <v-icon color="orange">search</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ profile.name }} existente
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
  </div>   
</template>
<script>
  import talkViewMixin from '../../talkViewMixin'
  export default {
    name: 'TalkContactSelectProfile',
    data: () => ({
      profiles: [
        { id: 'customer', name: 'Cliente', icon: 'person' },
        { id: 'prospect', name: 'Lead/prospect', icon: 'star' },
        { id: 'provider', name: 'Fornecedor', icon: 'business' },
        { id: 'collaborator', name: 'Funcionário', icon: 'people' },
        { id: 'contact-business', name: 'Contato de negócios', icon: 'business_center', color: 'primary' }
      ]
    }),
    methods: {
      editContact: function () {
        this.$WiEditDialog({
          wiConfig: 'talk-contact',
          data: {
            id: this.contact.id,
            talk_channel_id: this.contact.talk_channel_id,
            name: this.contact.name,
            type: this.contact.type,
            person_type: this.contact.person_type,
            person: this.contact.person,
            observation: this.contact.observation
          },
          onSubmit: () => {
            this.refresh(this.$route.params.id)
          },
          redirectOnSubmit: false
        })
      },
      saveTalkContact: function (person) {
        this.$WiApiPut({
          uri: `talk/contact/${this.contact.id}`,
          data: {
            id: this.contact.id,
            person_id: person.id
          },
          callback: () => {
            this.refresh(this.$route.params.id)
          }
        })
      },
      createProfile: function (profile) {
        switch (profile) {
          case 'customer':
            this.createCustomer()
            break
          case 'prospect':
            this.createProspect()
            break
          case 'provider':
            this.createProvider()
            break
          case 'collaborator':
            this.createCollaborator()
            break
          case 'contact-business':
            this.createContactBusiness()
            break
        }
      },
      selectProfile: function (profile) {
        switch (profile) {
          case 'customer':
            this.selectCustomer()
            break
          case 'prospect':
            this.selectProspect()
            break
          case 'provider':
            this.selectProvider()
            break
          case 'collaborator':
            this.selectCollaborator()
            break
          case 'contact-business':
            this.selectContactBusiness()
            break
        }
      },
      createCustomer: function () {
        this.$WiEditDialog({
          wiConfig: 'customer',
          data: {
            type: 'Fisica',
            name: this.contact.name,
            first_name: this.contact.name,
          },
          onSubmit: (response) => {
            if (response?.status == 201 && response?.data?.id) {
              this.saveTalkContact(response.data)
            }
          },
          redirectOnSubmit: false
        })
      },
      createProspect: function () {
        this.$WiEditDialog({
          wiConfig: 'prospect',
          data: {
            type: 'Fisica',
            name: this.contact.name,
            first_name: this.contact.name,
          },
          onSubmit: (response) => {
            if (response?.status == 201 && response?.data?.id) {
              this.saveTalkContact(response.data)
            }
          },
          redirectOnSubmit: false
        })
      },
      createProvider: function () {
        this.$WiEditDialog({
          wiConfig: 'provider',
          data: {
            type: 'Juridica',
            name: this.contact.name,
            first_name: this.contact.name,
          },
          onSubmit: (response) => {
            if (response?.status == 201 && response?.data?.id) {
              this.saveTalkContact(response.data)
            }
          },
          redirectOnSubmit: false
        })
      },
      createCollaborator: function () {
        this.$WiEditDialog({
          wiConfig: 'collaborator',
          data: {
            type: 'Fisica',
            name: this.contact.name,
            first_name: this.contact.name,
          },
          onSubmit: (response) => {
            if (response?.status == 201 && response?.data?.id) {
              this.saveTalkContact(response.data)
            }
          },
          redirectOnSubmit: false
        })
      },
      createContactBusiness: function () {
        this.$WiEditDialog({
          wiConfig: 'contact',
          data: {
            type: 'Fisica',
            name: this.contact.name,
            first_name: this.contact.name,
          },
          onSubmit: (response) => {
            if (response?.status == 201 && response?.data?.id) {
              this.saveTalkContact(response.data)
            }
          },
          redirectOnSubmit: false
        })
      },
      selectCustomer: function () {
        this.$WiListDialog({
          wiConfig: 'customer',
          mode: 'select',
          callback: (response) => {
            this.saveTalkContact(response)
          }
        })
      },
      selectProspect: function () {
        this.$WiListDialog({
          wiConfig: 'prospect',
          mode: 'select',
          callback: (response) => {
            this.saveTalkContact(response)
          }
        })
      },
      selectProvider: function () {
        this.$WiListDialog({
          wiConfig: 'provider',
          mode: 'select',
          callback: (response) => {
            this.saveTalkContact(response)
          }
        })
      },
      selectCollaborator: function () {
        this.$WiListDialog({
          wiConfig: 'collaborator',
          mode: 'select',
          callback: (response) => {
            this.saveTalkContact(response)
          }
        })
      },
      selectContactBusiness: function () {
        this.$WiListDialog({
          wiConfig: 'contact',
          mode: 'select',
          callback: (response) => {
            this.saveTalkContact(response)
          }
        })
      }
    },
    props: ['contact', 'refresh'],
    mixins: [talkViewMixin]
  }
</script>
<style scoped>
  .talk-contact-profile__profiles-buttons {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
</style>