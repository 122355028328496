<template>
  <v-card elevation="15">
    <h3 class="daily-report__product-title">{{ title }}</h3>
    <v-divider></v-divider>
    <h2 class="text-center" style="text-align: center;">{{ finance | money }}</h2>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
export default {
  name: 'DailyReportFinance',
  props: {
    title: {
      type: String,
      required: true,
      default: '- - -'
    },
    finance: {
      required: true,
      default: 0
    }
  }
}
</script>
<style scoped>
  .daily-report__product-title {
    text-align: center;
    color: white;
    background: black;
    padding: 5px 0;
  }
</style>
