import FinanceFields from './FinanceRecurrenceFields'

const financeRecurrence = (in_out) => {
  const inOutTitle = in_out === 1 ? 'Receita' : 'Despesa'
  return {
    id: {
      id: '40ec1d35',
      create: '87035054',
      update: '82b8e3b9',
      delete: 'e90e45c8'
    },
    actions: {
      create: true,
      update: true,
      delete: false
    },
    list: true,
    title: {
      list: `${inOutTitle}s Recorrentes`,	
      edit: {
        insert: `Inserir ${inOutTitle} Recorrente`,
        update: `Atualizar ${inOutTitle} Recorrente`
      }
    },
    icon: 'downloading',
    apiUrl: 'finance/recurrence',
    apiParams: {
      order: 'created_at,desc',
      in_out
    },
    search: {
      placeholder: 'Pesquise ID, descrição ou pessoa envolvida',
      fields: ['id', 'description', 'person.name']
    },
    fields: FinanceFields,
    fieldsFixed: [
      { value: 'in_out', data: in_out }
    ],
    notLoadOnMounted: false,
    size: [ 'xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1' ],
    components: {
      list: {
        tableRow: () => import('@/modules/finance/pages/FinanceRecurrence/FinanceRecurrence')
      }
    }
  }
}

export default {
  in: financeRecurrence(1),
  out: financeRecurrence(2)
}
