<template>
  <v-container>
    <v-layout wrap>
      <v-flex style="padding: 0 10px 0 10px; text-align: center;">
        <div style="width: 100%; text-align: center; margin: 20px 0;">
          <img
            class="elevation-10"
            src="static/background/talk-channelslogos.jpg"
            style="width: 100%; max-width: 500px;border-radius: 10px;"
          />
        </div>
        <h1>
          <span>Centralize seu atendimento em um único lugar.</span>
        </h1>
        <h3 class="grey--text mb-3">Conecte quantos canais de atendimento desejar.</h3>
        <!-- list -->
        <v-btn
          outline
          large
          color="primary"
          link
          :to="{
            name: 'talk-list',
            params: {
              page: 'talk-channel'
            }
          }"
        >
          <v-icon>play_for_work</v-icon>
          <span> Acessar meus canais de atendimento</span>
        </v-btn>
        <h2 class="orange--text mt-3">Para adicionar novos canais de atendimento entre em contato com nosso suporte técnico.</h2>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    name: 'Indisponível',
    data () {
      return {}
    },
    methods: {}
  }
</script>
