export default {
  id: {
    id: 'cb59faf8',
    create: 'a2ee509c',
    update: '742b2ad5',
    delete: 'eab778c7'
  },
  list: true,
  title: 'Dispositivo',
  apiUrl: 'disp/dados',
  fields: [
    {text: 'Dados básicos deste dispositivo. Nome, IP, se será monitorado e de onde será monitorado.', type: 'subheader', edit: true},
    {value: 'nome_disp', text: 'Nome', placeholder: 'Insira a identificação deste dispositivo', type: 'text', size: ['xs12', 'sm12', 'md4', 'lg4'], required: true, list: true, edit: true, search: true},
    {value: 'sigla_disp', text: 'Sigla', placeholder: 'Insira a sigla deste dispositivo', type: 'text', size: ['xs12', 'sm12', 'md2', 'lg2'], required: false, list: false, edit: true},
    {value: 'ip_disp', counter: 15, text: 'Endereço IP', placeholder: '###.###.###.###', type: 'text', size: ['xs12', 'sm12', 'md2', 'lg2'], required: true, list: true, edit: true, search: true},
    {value: 'mac_disp', counter: 17, text: 'MAC Address', placeholder: '##:##:##:##:##:##', type: 'text', size: ['xs12', 'sm12', 'md2', 'lg2'], required: true, list: true, edit: true, search: true},
    {value: 'ativo_disp', text: 'Será monitorado?', placeholder: 'Sim ou não', type: 'select', size: ['xs12', 'sm12', 'md2', 'lg2'], list: false, edit: true, options: [{value: 'active', text: 'Sim'}, {value: 'inactive', text: 'Não'}]},
    {value: 'nome_tipo', text: 'Tipo', list: true, edit: false},
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {text: 'Servidor de Monitoramento, Grupo principal, Modelo, Tipo e Servidor de monitoramento.', type: 'subheader', edit: true},
    {value: 'servidor_dados_id', text: 'Servidor de Monitoramento', placeholder: 'De onde será monitorado', required: true, type: 'db-autocomplete', size: ['xs12', 'sm12', 'md6', 'lg6'], list: false, edit: true, apiUrl: 'servidor/dados', apiParams: {fields: 'id,nome_servidor', order: 'nome_servidor,asc'}, show: 'nome_servidor'},
    {value: 'grupo_dados_id', text: 'Grupo Principal', required: true, type: 'db-autocomplete', size: ['xs12', 'sm12', 'md6', 'lg6'], list: false, edit: true, apiUrl: 'grupo/dados', apiParams: {where: 'funcao_tipo,2', order: 'nome_grupo,asc'}, show: 'nome_sigla_grupo'},
    {value: 'equipamento_dados_id', text: 'Modelo/Equipamento', type: 'db-autocomplete', size: ['xs12', 'sm12', 'md6', 'lg6'], list: false, edit: true, apiUrl: 'equipamento/dados', apiParams: {order: 'nome_equipamento_fabricante,asc'}, show: 'nome_equipamento_fabricante'},
    {value: 'disp_tipo_id', text: 'Tipo', type: 'db-autocomplete', size: ['xs12', 'sm12', 'md6', 'lg6'], list: false, edit: true, apiUrl: 'disp/tipo', apiParams: {order: 'nome_tipo,asc'}, show: 'nome_tipo'},
    // {value: 'grupos', text: 'Grupos de Exibição', multiple: true, autocomplete: true, required: true, type: 'dbselect', list: false, edit: {insert: true, update: false}, instore: 'dispositivos', outstore: 'grupos', show: 'nome_grupo', fields: false},
    {text: 'O campo de Observações pode ser usado para descrever este dispositivo.', type: 'subheader', edit: true},
    {value: 'obs_disp', text: 'Observações', type: 'textarea', list: false, edit: true}
  ],
  fieldsFixed: [
    {
      value: 'ultimo_alter_tipo', data: 1
    },
    {
      value: 'ultimo_alter_tipo_nome', data: 'usuario'
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
