// Budget
import Budget from '@/modules/juridical/config/Budget/Budget'

// Process
import Process from '@/modules/juridical/config/Process/Process'

// Common
import Event from '@/modules/juridical/config/Common/Event'
import Item from '@/modules/juridical/config/Common/Item'
import Person from '@/modules/juridical/config/Common/Person'
import Finances from '@/modules/juridical/config/Common/Finance'
import FinanceReceive from '@/modules/juridical/config/Common/FinanceReceive'

// Configurations
import Status from '@/modules/juridical/config/Config/Status'
import Category from '@/modules/juridical/config/Config/Category'

// Registers
import Product from '@/modules/juridical/config/Register/Product'
import Service from '@/modules/juridical/config/Register/Service'
import Customer from '@/modules/juridical/config/Register/Customer'
import Collaborator from '@/modules/juridical/config/Register/Collaborator'

import {Business} from '@/modules/shared/config/Business/Business'

export default {
  'process': Process.process,
  'process-open': Process.open,
  'process-close': Process.close,
  'process-cancel': Process.cancel,
  'process-event': Event.sale,
  'process-item-product': Item.product.sale,
  'process-item-service': Item.service.sale,
  'process-person': Person,
  'process-finances': Finances.sale,
  'process-finance': FinanceReceive,

  'budget': Budget.budget,
  'budget-open': Budget.open,
  'budget-close': Budget.close,
  'budget-cancel': Budget.cancel,
  'budget-event': Event.budget,
  'budget-item-product': Item.product.budget,
  'budget-item-service': Item.service.budget,
  'budget-finances': Finances.budget,
  'budget-finance': FinanceReceive,

  'config-status-process': Status.process,
  'config-category-process': Category.process,
  'config-status-budget': Status.budget,
  'config-category-budget': Category.budget,

  'juridical-register-product': Product,
  'juridical-register-service': Service,
  'juridical-register-customer': Customer,
  'juridical-register-collaborator': Collaborator,

  'business': new Business()
}
