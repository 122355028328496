<template>
  <div v-if="drawerRightActive">
    <v-navigation-drawer
      v-model="drawerRightActive"
      absolute
      temporary
      right
      fixed
      floating
      
      stateless
      :width="$vuetify.breakpoint.xsOnly ? 380 : 450"
    >
    <div>
      <v-toolbar flat extended dense tabs color="primary" class="elevation-5 white--text talks__drawer-right">
        <v-toolbar-title>
          <v-icon color="white">info_outline</v-icon>
          <span> Itens relacionados</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="getTalkData()">
          <v-icon color="white">refresh</v-icon>
        </v-btn>
        <v-btn icon @click="TOUGGLE_DRAWER_RIGHT()">
          <v-icon color="white">close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            grow
            color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab v-if="$WiCheckPermission('5dc282ab')" class="white--text">
              <span>Chamados</span>
              <!-- <v-icon>phone</v-icon> -->
            </v-tab>

            <v-tab v-if="$WiCheckPermission('10b505c9') && personType != 'provider' && !team" class="white--text">
              <span>Orçamentos</span>
              <!-- <v-icon>favorite</v-icon> -->
            </v-tab>

            <!-- <v-tab class="white--text">
              <span>Faturas</span>
            </v-tab> -->
          </v-tabs>
        </template>
      </v-toolbar>
      <v-divider></v-divider>
    <v-tabs-items v-if="!loading" v-model="tabs" class="#dddddd">
      <v-tab-item v-if="$WiCheckPermission('5dc282ab')">
        <div v-if="talk.tickets && talk.tickets.length > 0" :style="{height: pageHeight}" style="padding: 10px 0 !important; overflow-y: scroll;">
          <v-subheader color="gray">
            <span v-if="person && person.profiles">
              Chamados em aberto do <strong>{{ person.profiles[0].title }}</strong>
            </span>
            <span v-if="team">
              Chamados em aberto de <strong>{{ team.name }}</strong>
            </span>
            <!-- <span v-if=""></span> -->
            <v-spacer></v-spacer>
            <v-btn
              small
              outline
              @click="addTicket()"
            >Adicionar chamado</v-btn>
          </v-subheader>
          <v-divider></v-divider>
          <div>
            <v-card v-for="(ticket, ticketIndex) in talk.tickets" :key="ticketIndex" class="elevation-20" style="margin: 15px 10px;">
              <v-card-title style="padding: 5px 0 0 0;">
                <span style="padding: 0px 5px;">
                  CÓD: <strong> #{{ ticket.id}}</strong>
                </span>
                <v-spacer></v-spacer>
                <span v-if="ticket.sla" style="padding: 0px 5px;">
                  SLA: <strong :style="{padding: '0px 3px', background: ticket.sla > 99 ? '#c90000' : (ticket.sla > 85) ? '#e59100' : 'green', color: 'white'}">{{ ticket.sla }}% </strong>
                </span>
                <v-progress-linear
                  v-if="ticket.sla"
                  height="3"
                  :color="ticket.sla > 99 ? '#c90000' : (ticket.sla > 85) ? '#e59100' : 'green'"
                  v-model="ticket.sla"
                  style="margin: 5px 0 0 0 !important;"
                ></v-progress-linear>
              </v-card-title>
              <v-divider v-if="ticket.assunto_chamado"></v-divider>
              <v-card-title v-if="ticket.assunto_chamado" style="padding: 5px 0 0 0;">
                <span style="padding: 0px 5px;">
                  <strong> {{ ticket.assunto_chamado }}</strong>
                </span>
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense style="padding: 0;">
                <v-list-tile class="talk-dawer__tile" style="padding: 5px 0 !important;">
                  <v-list-tile-avatar v-if="ticket.status" style="padding: 0; min-width: 0;">
                    <v-icon v-if="ticket.status" size="30" :color="ticket.status.color">
                      {{ ticket.status.icon }}
                    </v-icon>
                    <v-icon v-else>confirmation_number</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-if="ticket.status">
                    <v-list-tile-sub-title style="color: gray;">Status:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.status.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-avatar v-if="ticket.category" style="padding: 0; min-width: 0;">
                    <v-icon size="30" :color="ticket.category.color">
                      {{ ticket.category.icon }}
                    </v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-if="ticket.category">
                    <v-list-tile-sub-title style="color: gray;">Categoria:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.category.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile class="talk-dawer__tile" avatar>
                  <v-list-tile-avatar style="padding: 0; min-width: 0;">
                    <img src="static/icons/monitor/management_96.png" alt="Equipe" style="max-heiht: 30px;">
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="color: gray;">Equipe:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.team.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-avatar v-if="ticket.responsible" style="padding: 0; min-width: 0;">
                    <img v-if="ticket.responsible.file" :src="ticket.responsible.file.url" alt="Responsável" style="max-heiht: 30px;">
                    <img v-else src="static/icons/monitor/lecturer_96.png" alt="Responsável" style="max-heiht: 30px;">
                  </v-list-tile-avatar>
                  <v-list-tile-content v-if="ticket.responsible">
                    <v-list-tile-sub-title style="color: gray;">Responsável:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ ticket.responsible.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider v-if="ticket.last_note"></v-divider>
                <v-list-tile v-if="ticket.last_note">
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="color: gray;">
                      Última anotação:
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title style="color: gray;">
                      {{ ticket.last_note.description_time }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile>
                  <v-btn ripple small block outline style="margin: 0 10px 0 0;" @click="sendTicketResume(ticket)">
                    <v-icon>send</v-icon>
                    <span style="padding-left: 5px;">Enviar resumo</span>
                  </v-btn>
                  <v-btn ripple small icon style="margin: 0 10px 0 0;" link :to="{name: 'ticket', params: { id: ticket.id }}">
                    <v-icon>open_in_new</v-icon>
                  </v-btn>
                  <v-btn ripple small icon style="margin: 0 0 0 10px;" @click="addTicketNote(ticket)">
                    <v-icon>message</v-icon>
                  </v-btn>
                </v-list-tile>
              </v-list>
            </v-card>
            <div v-if="talk.tickets && talk.tickets.length >= 5" style="margin: 10px;padding: 20px 0;">
              <v-btn
                block
                outline
                @click="showAllTickets()"
              >Ver todos os chamados</v-btn>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <div class="talk-drawer-right__empty-message">
              <h2>Nenhum chamado em<br>execução encontrado.</h2>
              <img src="static/icons/monitor/add_tag_96.png" alt="">
              <p class="mb-5">Clique no botão abaixo para<br>adicionar um novo chamado.</p>
              <v-btn block outline @click="addTicket()">Adicionar chamado</v-btn>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item v-if="$WiCheckPermission('10b505c9')" style="margin: 0 10px 10px 10px; padding: 0 0 20px 0;">
        <div v-if="talk.budgets && talk.budgets.length > 0">
          <v-subheader color="gray">
            Orçamentos em aberto do {{ person.profiles[0].title }}
            <v-spacer></v-spacer>
            
            <v-menu offset-y lazy>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" small outline>Adicionar Orçamento</v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="addBudgetByTemplate()">
                    <v-list-tile-title>Criar a partir de um modelo</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="addBudget()">
                    <v-list-tile-title>Criar novo orçamento do zero</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
          </v-subheader>
          <v-divider></v-divider>
          <div>
            <v-card v-for="(budget, budgetIndex) in talk.budgets" :key="budgetIndex" color="white" class="elevation-20 white" style="margin: 15px 0px;">
              <v-card-title style="padding: 5px 0 0 0;">
                <span style="padding: 0px 5px;">
                  CÓD: <strong> #{{ budget.id}}</strong>
                </span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-divider v-if="budget.assunto_chamado"></v-divider>
              <v-card-title v-if="budget.assunto_chamado" style="padding: 5px 0 0 0;">
                <span style="padding: 0px 5px;">
                  <strong> {{ budget.assunto_chamado }}</strong>
                </span>
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense style="padding: 0;">
                <v-list-tile class="talk-dawer__tile" style="padding: 5px 0 !important;">
                  <v-list-tile-avatar style="padding: 0; min-width: 0;">
                    <v-icon v-if="budget.status" size="30" :color="budget.status.color">
                      {{ budget.status.icon }}
                    </v-icon>
                    <v-icon v-else>confirmation_number</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="color: gray;">Status:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ budget.status.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-avatar style="padding: 0; min-width: 0;">
                    <v-icon size="30" :color="budget.category.color">
                      {{ budget.category.icon }}
                    </v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="color: gray;">Categoria:</v-list-tile-sub-title>
                    <v-list-tile-title>
                      <strong>{{ budget.category.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile>
                  <v-btn ripple small block outline style="margin: 0 10px 0 0;" link :to="{name: 'budget', params: { id: budget.id }}">
                    <v-icon>open_in_new</v-icon>
                    <span style="padding-left: 5px;">Detalhes</span>
                  </v-btn>
                  <v-btn @click="$WiEditDialog({wiConfig: 'budget-open', onSubmit: () => { getTalk(talk.id) }, data: budget})" icon flat style="margin: 0px 5px;">
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn @click="showBudgetEvents(budget)" icon flat style="margin: 0px 5px;">
                    <v-icon>message</v-icon>
                  </v-btn>
                  <v-btn @click="showBudgetFiles(budget)" icon flat style="margin: 0px 5px;">
                    <v-icon>attachment</v-icon>
                  </v-btn>
                </v-list-tile>
              </v-list>
            </v-card>
            <div v-if="talk.budgets && talk.budgets.length >= 5" style="margin: 10px;padding: 20px 0;">
              <v-btn
                block
                outline
                link
                :to="{name: 'sales-list', params: { page: 'budget-open' }}"
              >Ver todos os orçamentos em aberto</v-btn>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <div class="talk-drawer-right__empty-message">
              <h2>Nenhum orçamento encontrado para este cliente.</h2>
              <img src="static/icons/monitor/new_copy_96.png" alt="">
              <p>Clique no botão abaixo para adicionar um novo orçamento.</p>
              <v-menu offset-y lazy>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" block outline>Adicionar Orçamento</v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="addBudgetByTemplate()">
                    <v-list-tile-title>Criar a partir de um modelo</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="addBudget()">
                    <v-list-tile-title>Criar novo orçamento do zero</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>asda sd assd asd as</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <div v-else>
      <div>
        <h2 class="talk-drawer-right__empty-message">Buscando itens relacionados...</h2>
        <v-card-text class="text-center" style="text-align: center; margin-bottom: 100px;">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      </div>
    </div>
  </div>

      <v-list class="pt-0" dense>
        <v-divider></v-divider>

        
      </v-list>
    </v-navigation-drawer>

  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  export default {
    name: 'TalkDrawerRight',
    data: () => ({
      drawer: false,
      loading: false,
      tabs: 0,
      talk: {},
      pageHeight: innerHeight
    }),
    computed: {
      ...mapState('Talk', {drawerRight: 'drawerRight', talkData: 'talk'}),
      drawerRightActive: {
        get () {
          return this.drawerRight
        },
        set (value) {
          this.$store.commit('Talk/SET_DRAWER_RIGHT', value)
        }
      },
      person () {
        if (this.talk?.contact?.person?.person) {
          return this.talk.contact.person.person
        } else if (this.talk?.contact?.person) {
          return this.talk.contact.person
        }
        return null
      },
      team () {
        if (this.talk?.contact?.team) {
          return this.talk.contact.team
        }
        return null
      },
      personTitle () {
        if (this.talk?.contact?.person?.person_title) {
          return this.talk.contact.person.person_title
        }
        return null
      },
      personType () {
        if (this.talk?.contact?.person?.profiles[0]?.name) {
          return this.talk.contact.person.profiles[0].name
        }
        return null
      }
    },
    methods: {
      ...mapMutations('Talk', ['TOUGGLE_DRAWER_RIGHT', 'SET_MESSAGE_EDITOR_DIALOG', 'SET_MESSAGE_TEXT']),
      ...mapActions('Talk', ['getTalk']),
      getTalkData: function () {
        this.loading = true
        this.$WiApiGet({
          uri: `talks/talk/${this.talkData.id}/related`,
          callback: ({success}) => {
            this.talk = success
            this.loading = false
          }
        })
      },
      addTicketNote: function (ticket) {
        this.$WiEditDialog({
          wiConfig: 'chamados-anotacao',
          onSubmit: () => {
            this.getTalk(this.talk.id)
          },
          data: {
            chamado_dados_id: ticket.id
          }
        })
      },
      addBudget: function () {
        let data = {
            person_id: this.person.id,
            person_type: this.person.type,
            person: this.person
          }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            collaborator_id: this.person.id,
            person_type: this.person.type,
            collaborator: this.person
          }
        }
        this.$WiEditDialog({
          wiConfig: 'budget-open',
          onSubmit: () => {
            this.getTalk(this.talkId)
          },
          data,
          redirectOnSubmit: false
        })
      },
      addBudgetByTemplate: function () {
        let data = {
            person_id: this.person.id,
            person_type: this.person.type,
            person: this.person
          }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            collaborator_id: this.person.id,
            person_type: this.person.type,
            collaborator: this.person
          }
        }
        this.$WiListDialog({
          wiConfig: 'budget-template',
          onSubmit: () => {
            this.getTalk(this.talkId)
          },
          data,
          redirectOnSubmit: false,
          wiEditData: data
        })
      },
      addTicket: function () {
        let data = {
          person_id: this.person?.id,
          person_type: this.person?.profiles[0].name,
          cli_ou_for: this.person ? this.person?.profiles[0].name == 'customer' ? 'cli' : 'for' : null,
          person: this.person || null,
          team_id: this.team?.id,
          team: this.team || null
        }
        if (this.person?.profiles[0].name == "collaborator") {
          data = {
            person_responsible_id: this.person.id,
            responsible: this.person,
            responsavel: this.person,
          }
        }
        this.$WiEditDialog({
          wiConfig: 'chamados-todos',
          onSubmit: () => {
            this.getTalk(this.talk.id)
          },
          data,
          redirectOnSubmit: false
        })
      },
      sendTicketResume: function (ticket) {
        let message = `Atualização do chamado *#${ticket.id}* \n\n`;
        
        if (ticket.assunto_chamado) {
          message += `*Assunto:* ${ticket.assunto_chamado} \n\n`;
        }
        if (ticket.status) {
          message += `*Status:* ${ticket.status.name} \n`;
        }
        if (ticket.category) {
          message += `*Categoria:* ${ticket.category.name} \n`;
        }
        if (ticket.team) {
          message += `*Equipe:* ${ticket.team.name} \n`;
        }
        if (ticket.responsible) {
          message += `*Responsável:* ${ticket.responsible.name} \n\n`;
        }
        if (ticket.last_note) {
          message += `*Última anotação:* ${ticket.last_note.description_time} \n\n`;
        }
   
        // message += `*Link:* ${process.env.VUE_APP_URL}/chamados/${ticket.id} \n`;
        this.SET_MESSAGE_TEXT(message)
        this.SET_MESSAGE_EDITOR_DIALOG(true)
      },
      showAllTickets: function () {
        let query = {
          person_id: this.person?.id || null,
        }
        if (this.team) {
          query = {
            team: this.team?.id,
          }
        }
        if (this.person?.profiles[0].name == "collaborator") {
          query = {
            person_responsible_id: this.person?.id,
          }
        }
        // return ({
        //   name: 'service-desk-list',
        //   params: {
        //     page: 'ticket-opened'
        //   },
        //   query
        // })
        this.$WiListDialog({
          wiConfig: 'ticket-opened',
          filter: query,
          redirectOnSubmit: false,
          wiEditData: query
        })
      },
      getShowAllBudgetsRoute: function () {
        let query = {
          person_id: this.person?.id || null,
        }
        if (this.person?.profiles[0].name == "collaborator") {
          query = {
            collaborator_id: this.person?.id,
          }
        }
        return ({
          name: 'sales-list',
          params: {
            page: 'budget-open'
          },
          query
        })
      },
      showBudgetEvents: function (budget) {
        this.$WiDialog({
            title: 'Anotações/Eventos ',
            icon: 'message',
            component: {
              content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
              props: {
                index: 'budget-event',
                api: {
                  url: 'sales/sale/event',
                  params: '?order=id,desc&where=business_id,' + budget.id
                }
              }
            },
            button: {
              icon: 'add',
              text: 'Adicionar Notasss',
              action: () => {
                this.$WiEditDialog({
                  wiConfig: 'budget-event',
                  onSubmit: () => {
                    this.$WiView('budget-event').refresh()
                  },
                  data: {business_id: budget.id}
                })
              }
            }
          })
      },
      showBudgetFiles: function (budget) {
        this.$WiDialog({
            title: 'Arquivos/Anexos',
            icon: 'attachment',
            component: {
              content: () => import('@/default/component/WiFile/WiFileList'),
              props: {
                index: 'budget-fileList',
                api: {
                  url: 'sales/sale/file',
                  params: '?order=id,desc&where=business_id,' + budget.id
                }
              }
            },
            button: {
              icon: 'add',
              text: 'Adicionar Arquivo',
              action: () => {
                this.$WiDialog({
                  title: 'Arquivos/Anexos ',
                  icon: 'attachment',
                  component: {
                    content: () => import('@/default/component/WiFile/WiFile'),
                    props: {
                      business_id: budget.id
                    }
                  },
                  onClose: () => {
                    this.$WiView('budget-fileList').refresh()
                  }
                })
              }
            }
          })
      },
      setInnerHeight: function (heigth) {
        this.pageHeight = ((heigth || innerHeight) - 152) + 'px'
      },
      updateView: function () {
        setInterval(() => {
          this.$forceUpdate()
        }, 78000)
      }
    },
    mounted: function () {
      window.addEventListener('resize', () => {
        this.setInnerHeight(innerHeight)
      }, true)
      this.setInnerHeight(innerHeight)
      this.updateView()
      this.getTalkData()
    }
  }
</script>
<style>
  .talk-toolbar__title {
    background: transparent !important;
    padding: 0 0 0 10px;
  }
  .talk-toolbar__title-text >>> .v-list__tile {
    padding: 0px !important;
  }

  .talks__drawer-right .v-toolbar__extension {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .talk-dawer__tile .v-list__tile {
    padding: 0 5px !important;
  }
  .talk-drawer-right__empty-message {
    margin: 1em 0;
    padding: 1em;
    text-align: center;
  }
  .talk-drawer-right__empty-message p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  }
</style>