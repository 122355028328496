import { Model } from '@/default/model/Model'

class Grade extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: '4d8hd50b',
      create: 'e578be37',
      update: '4d8brfa0',
      delete: '83wd7827'
    }
    this.name = 'grade'
    this.title = {
      list: 'Turmas/Classes',
      edit: {
        insert: 'Criar nova Turma/Classe',
        update: 'Editar Turma/Classe'
      }
    }
    this.apiUrl = 'graduations/grade'
    this.icon = 'assignment_turned_in'
    this.redirectOnSubmit = false
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe todos os dados desta turma.'
      }),
      this.fieldSelectApi({
        value: 'college_id',
        text: 'Faculdade/Colégio',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'graduations/register/college'
        },
        show: 'name'
      }),
      this.fieldSelectApi({
        value: 'course_id',
        text: 'Curso/Disciplina',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'graduations/register/course'
        },
        show: 'name'
      }),
      this.fieldNumber({
        value: 'year',
        text: 'Ano',
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldSelect({
        value: 'semester',
        text: 'Semestre',
        options: [
          {value: '1° Semestre', text: '1° Semestre'},
          {value: '2° Semestre', text: '2° Semestre'}
        ],
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldSelect({
        value: 'period',
        text: 'Período/Turno',
        options: [
          {value: 'Manhã', text: 'Manhã'},
          {value: 'Tarde', text: 'Tarde'},
          {value: 'Noite', text: 'Noite'}
        ],
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldSubHeader({
        text: 'Observações ou anotações '
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações',
        placeholder: 'Ex: Como deve ser executado(a).'
      })
    ])
  }
}
export default new Grade()
