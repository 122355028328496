<template>
  <view-detail title="Chamados Por Tipo de Ocorrência" apiUrl="service-desk/report/chamados-por-ocorrencia" :apiParams="params" :notGetID="true" :notShowToolbar="false">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn v-if="$route.query && $route.query.ocorrencia_id" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="$router.push({name: 'chamados-por-ocorrencia'})" :disabled="dados.loading">
        <v-icon>close</v-icon>
        <span>Limpar Filtro</span>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px 20px 0px 10px;">
          <v-layout row wrap>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.data_inicial" type="datetime-local" label="Data Inicial"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.data_final" type="datetime-local" label="Data Final"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-btn :disabled="!filter.data_inicial && !filter.data_final" @click="setFilter()" style="width: 100% !important;">Filtrar</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <download-excel v-if="dados.content && dados.content.data && dados.content.data.length > 0" class="btn btn-default" :data="dados.content.data" :fields="makeXLSFields()" worksheet="Chamados Por Tipo de Ocorrência" name="Relatório.xls">
                <v-btn color="teal darken-1" style="color: white; width: 100% !important;">Exportar/Excel</v-btn>
              </download-excel>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="dados.content && dados.content.info && dados.content.info.nome_categoria" xs12 sm12 md12 lg12 style="text-align: center; padding: 0px 10px 20px 10px;">
          Filtrando por: <strong>{{dados.content.info.nome_categoria_grupo}}</strong>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 style="padding: 0px 10px 20px 20px;">
          <v-data-table v-model="table" hide-actions :pagination.sync="pagination" :headers="headers" :items="dados.content.data" class="elevation-1">
            <template v-slot:items="props">
              <td>
                <strong :style="{color: props.item.ativa_categoria ? 'black' : '#e0e0e0'}">{{ props.item.nome_categoria }}</strong>
              </td>
              <td>
                <strong style="font-size: 18px;">{{ props.item.num_chamados }}</strong>
              </td>
              <td>
                <v-btn :disabled="props.item.num_sub_categorias < 1" @click="filter.ocorrencia_id = props.item.id; setFilter()" icon><v-icon color="blue">info</v-icon></v-btn>
              </td>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 style="padding: 0px 10px 20px 20px;">
          <v-card>
            <div v-if="dados.content && dados.content.data && dados.content.data.length > 0"></div>
            <apexchart width="100%" type="donut" :options="options" :series="setSeries(dados.content.data)"></apexchart>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  export default {
    name: 'ChamadosPorTipoDeOcorrencia',
    data () {
      return {
        table: [],
        headers: [
        { text: 'Ocorrência', value: 'nome_categoria' },
        { text: 'N° de Chamados', value: 'num_chamados' },
        { value: 'num_sub_categorias' }
        ],
        options: {
          chart: {
            id: 'Chamados Por Tipo de Ocorrência'
          },
          labels: []
        },
        series: [],
        pagination: {
          sortBy: 'ativa_categoria,num_chamados',
          rowsPerPage: -1,
          descending: true
        },
        params: '',
        filter: {
          data_inicial: null,
          data_final: null,
          ocorrencia_id: null
        }
      }
    },
    methods: {
      setSeries: function (data) {
        var r = []
        var categories = []
        data.filter((item) => {
          r.push(item.num_chamados)
          categories.push(item.nome_categoria)
        })
        this.options.labels = []
        this.options.labels = categories
        return r
      },
      setParams: function (route) {
        if (route.query) {
          this.params = '?'
          if (route.query.data_inicial) {
            this.params += 'data_inicial=' + route.query.data_inicial + '&'
          }
          if (route.query.data_final) {
            this.params += 'data_final=' + route.query.data_final + '&'
          }
          if (route.query.ocorrencia_id) {
            this.params += 'chamado_categoria_id=' + route.query.ocorrencia_id
          }
        } else {
          this.params = ''
        }
      },
      setFilter: function () {
        var query = {}
        if (this.filter.data_inicial) {
          query['data_inicial'] = this.filter.data_inicial
        }
        if (this.filter.data_final) {
          query['data_final'] = this.filter.data_final
        }
        if (this.filter.ocorrencia_id) {
          query['ocorrencia_id'] = this.filter.ocorrencia_id
        }
        this.$router.push({name: 'chamados-por-ocorrencia', query: query})
      },
      makeXLSFields: function () {
        var fields = {}
        this.headers.filter((item) => {
          if (item.text && item.value) {
            fields[item.text] = item.value
          }
        })
        return fields
      }
    },
    watch: {
      '$route': 'setParams'
    },
    computed: {
      auth () {
        return this.$store.state.auth || {}
      }
    },
    components: {
      ViewDetail
    }
  }
</script>
