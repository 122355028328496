<template>
  <div style="background: white !important;">
    <!-- eslint-disable -->
    <v-toolbar dense :color="!dark ? 'primary' : 'dark'" style="color: white;">
      <v-btn  icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        Chamados
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="hidden-xs-only hidden-sm-only"
        small
        outline
        color="white"
        v-prms="{'id': '2c491b4c'}"
        @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: load, data: {}})"
        :disabled="loading"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Novo</span>
      </v-btn>
      <v-btn :disabled="loading" icon @click="load()"><v-icon style="color: white;">refresh</v-icon></v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true" style="margin: unset; position: absolute;"></v-progress-linear>
    <v-flex sm12 v-if="listStatus.length < 1 && !loading">
      <br>
      <div style="padding: 20px;">
        <v-alert :value="true" type="warning" >
          Nenhum chamado encontrado!
        </v-alert>
      </div>
    </v-flex>
    <v-flex sm12 v-if="listStatus.length < 1 && loading">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <h1>Carregando...</h1>
      </div>
    </v-flex>
    <div v-if="listStatus.length > 0" style="overflow-x: scroll !important; overflow-y: hidden !important; padding: 5px 5px;">
      <div :style="{width: (listStatus.length * 301) + 'px'}">
        <div class="box" v-for="(i, iIndex) in listStatus" :key="iIndex" v-if="i.stage === 1 && ($Auth.checkPermission('4aa0482a') || i.show_status.length == 0 || i.show_status.indexOf(User.profile.id) > -1)">
          <div style="padding: 5px; max-width: 300px !important; margin: unset;">
            <div class="elevation-0">
              <v-toolbar dense class="elevation-10 toolbar_status" style="padding: 0px !important;">
                <v-btn icon @click="editTicketStatus(i)">
                  <v-icon :color="i.cor_status">{{i.icone_status}}</v-icon>
                </v-btn>
                <strong style="font-size: 14px;">{{i.nome_status}}</strong>
                <v-spacer></v-spacer>
                <span style="font-size: 16px;">{{i.itens_count}}</span>
                <span v-if="i.itens_acima_sla" style="background: #c90000; margin-left: 5px; padding: 0px 3px; font-size: 16px; color: white;">{{i.itens_acima_sla}}</span>
                <span v-if="i.itens_atrasados" style="background: #c90000; margin-left: 5px; padding: 0px 3px; font-size: 16px; color: white;">{{i.itens_atrasados}}</span>
              </v-toolbar>
              <v-divider></v-divider>
              <div :style="{height: (innerHeight - 199) + 'px'}" style="overflow-y: auto; padding: 5px; background: #DFE1E6;">
                <Container @drop="onDrop(iIndex, $event)" group-name="col" :get-child-payload="(index) => { return i.itens[index] }" drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="{className: 'cards-drop-preview', animationDuration: '150', showOnTop: true}">            
                  <Draggable v-for="(block, lIndex) in i.itens" :key="lIndex">
                    <v-card
                      style="margin-bottom: 5px; cursor: grab;"
                      @click="$router.push({name: 'chamado', params: {id: block.id}})"
                      class="elevation-5"
                    >
                      <v-card-title style="padding: 5px 0 0 0;">
                        <span style="padding: 0px 5px;">
                          CÓD: <strong> #{{ block.id}}</strong>
                        </span>
                        <v-spacer></v-spacer>
                        <span v-if="block.sla" style="padding: 0px 5px;">
                          SLA: <strong :style="{padding: '0px 3px', background: block.sla > 99 ? '#c90000' : (block.sla > 85) ? '#e59100' : 'green', color: 'white'}">{{ block.sla }}% </strong>
                        </span>
                        <v-progress-linear
                          v-if="block.sla"
                          height="3"
                          :color="block.sla > 99 ? '#c90000' : (block.sla > 85) ? '#e59100' : 'green'"
                          v-model="block.sla"
                          style="margin: 5px 0 0 0 !important;"
                        ></v-progress-linear>
                      </v-card-title>
                      <v-divider v-if="block.agendamento_log_format"></v-divider>
                      <v-card-title v-if="block.agendamento_log_format" style="padding: 5px;">
                        <span>
                          <strong :style="{padding: '0px 3px', background: block.agendamento_log_dias < 0 ? '#c90000' : (block.agendamento_log_dias == 0) ? '#e59100' : 'blue', color: 'white'}">{{ block.agendamento_log_format }}</strong>
                        </span>
                        <v-spacer></v-spacer>
                        <span>
                          <strong v-if="block.agendamento_log_dias < 0" style="padding: 0px 3px; color: #c90000;">
                            {{ block.agendamento_log_dias * -1 }} dia(s) atrás
                          </strong>
                          <strong v-else-if="block.agendamento_log_dias == 0" style="padding: 0px 3px; color: #e59100;">
                            Hoje
                          </strong>
                          <strong v-else style="padding: 0px 3px; color: blue;">
                            Em {{ block.agendamento_log_dias }} dia(s)
                          </strong>
                        </span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="padding: 0px;">
                        <div v-if="block.assunto_chamado" style="padding: 5px; min-width: 270px;">
                          <v-icon style="color:#1C8ADB">chevron_right</v-icon>
                          <span>{{block.assunto_chamado}}</span>
                        </div>
                        <v-divider v-if="block.assunto_chamado"></v-divider>
                        <div style="padding: 5px; min-width: 270px;">
                          <v-icon style="color:#1C8ADB">person</v-icon>
                          <span>{{block.nome_pessoa}}</span>
                        </div>
                        <v-divider></v-divider>
                        <div style="padding: 5px;">
                          <v-icon :color="block.cor_categoria">{{block.icone_categoria}}</v-icon>
                          <span style="padding-bottom: 10px;">{{block.nome_categoria_grupos}}</span>
                        </div>
                        <v-divider></v-divider>
                        <div v-if="block.grupos">
                          <div style="padding: 5px;">{{block.grupos}}</div>
                          <v-divider></v-divider>
                        </div>
                        <div v-if="block.num_anexos">
                          <v-card-title style="padding: 5px;">
                            <v-icon>attachment</v-icon>
                            <span>{{block.num_anexos}}</span>
                          </v-card-title>
                          <v-divider></v-divider>
                        </div>
                      </v-card-text>
                    </v-card>
                  </Draggable>
                </Container>
              </div>
              <v-footer style="background: #DFE1E6; min-height: 5px !important; height: 5px !important;"></v-footer>
            </div>
          </div>
        </div>
        <div class="box" v-for="(i, iIndex) in listStatus" :key="iIndex" v-if="i.stage == 2 || i.stage == 3">
          <div style="padding: 5px; max-width: 300px !important; margin: unset;">
            <div class="elevation-0">
              <v-toolbar dense class="elevation-10 toolbar_status" style="padding: 0px !important;">
                <v-btn icon>
                  <v-icon :color="i.cor_status">{{i.icone_status}}</v-icon>
                </v-btn>
                <strong style="font-size: 14px;">{{i.nome_status}}</strong>
              </v-toolbar>
              <v-divider></v-divider>
              <div :style="{height: (innerHeight - 199) + 'px'}" style="overflow-y: auto; padding: 5px; background: #DFE1E6;">
                <Container @drop="onDrop(iIndex, $event)" group-name="col" :get-child-payload="(index) => { return i.itens[index] }" drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="{className: 'cards-drop-preview', animationDuration: '150', showOnTop: true}">            
                  <Draggable v-for="(block, lIndex) in i.itens" :key="lIndex">
                    <v-card style="margin-bottom: 5px; cursor: grab;" @click="$router.push({name: 'chamado', params: {id: block.id}})">
                      <v-card-title style="padding: 5px;">
                        <span>
                          CÓD: <strong> #{{ block.id}}</strong>
                        </span>
                        <v-spacer></v-spacer>
                        <span style="padding-left: 5px;">
                          <strong>Por favor aguarde...</strong>
                        </span>
                      </v-card-title>
                    </v-card>
                  </Draggable>
                </Container>
              </div>
              <v-footer style="background: #DFE1E6; min-height: 5px !important; height: 5px !important;"></v-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-if="agendarDialog.active" v-model="agendarDialog.active" persistent scrollable max-width="400">
      <v-card>
        <v-card-title class="headline" style="text-align: center;">Informar data limite</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3 style="text-align: center;">Por favor, informe até quando o chamado ficará neste Status.</h3>
          <div style="padding-top: 20px;">
            <v-text-field type="datetime-local" v-model="agendarDialog.DateTime" required></v-text-field>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click.native="cancelDialog()">Cancelar</v-btn>
          <v-btn :disabled="agendarDialog.DateTime == null" color="primary darken-1" @click.native="alterarChamado(agendarDialog)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-prms="{'id': '2c491b4c'}"
      @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: load, data: {}})"
      :disabled="loading"
      color="green"
      class="white--text hidden-md-only hidden-lg-only hidden-xl-only elevation-17"
      fab
      fixed
      bottom
      right
    >
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import {Container, Draggable} from 'vue-smooth-dnd'
  import {applyDrag} from '@/default/components/utils/helpers'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'ChamadosKanbanV2',
    data () {
      return {
        innerHeight: window.innerHeight,
        listStatus: [],
        loading: false,
        agendarDialog: {
          active: false,
          DateTime: null,
          Status: null,
          Id: null
        }
      }
    },
    methods: {
      onDrop (collection, dropResult) {
        var itensUpdate = []
        this.listStatus[collection].itens = applyDrag(this.listStatus[collection].itens, dropResult).filter((item, key) => {
          itensUpdate.push({id: item.id, prioridade: key})
          return item
        })
        this.$forceUpdate()
        if (dropResult.addedIndex !== null && dropResult.payload.chamado_status_id !== this.listStatus[collection].id) {
          this.agendarDialog = { id: dropResult.payload.id, active: false, status: this.listStatus[collection], DateTime: null, prioridade: itensUpdate }
          if (this.listStatus[collection].get_confirmation === 'sim') {
            this.$WiEditDialog({
              wiConfig: 'chamados-concluido',
              onSubmit: this.load,
              data: {
                id: dropResult.payload.id,
                chamado_categoria_id: dropResult.payload.sub_category_count < 1 ? dropResult.payload.chamado_categoria_id : null,
                chamado_status_id: this.listStatus[collection].id,
                prioridade: itensUpdate.prioridade,
                nome_categoria_grupo_previsao: dropResult.payload.nome_categoria_grupos,
                nome_categoria: dropResult.payload.nome_categoria,
                icone_categoria: dropResult.payload.icone_categoria,
                cor_categoria: dropResult.payload.cor_categoria,
                sub_category_count: dropResult.payload.sub_category_count
              }
            })
          } else {
            if (this.listStatus[collection].exigir_data === 'sim') {
              this.agendarDialog.active = true
            } else {
              this.$swal({title: 'Deseja realmente alterar o status deste chamado?', text: 'Essa ação não poderá ser desfeita!', type: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Sim, alterar!', cancelButtonText: 'Cancelar'})
              .then((response) => {
                if (response.value) {
                  this.alterarChamado(this.agendarDialog)
                } else {
                  this.load()
                }
              })
            }
          }
        }
        if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
          this.alterarPrioridade(itensUpdate)
        }
      },
      load: function () {
        this.loading = true
        callApi.get({
          uri: 'chamado/status-chamado',
          params: {},
          sucess: (data) => {
            this.listStatus = data.data
            // data.data.data[0].chamado_status.filter((c) => {
            //   this.listStatus.push(c)
            // })
            // this.listStatus = this.listStatus.filter((s) => {
            //   s['itens'] = []
            //   data.data.data.filter((i) => {
            //     if (s.id === i.chamado_status_id) {
            //       s.itens.push(i)
            //     }
            //   })
            //   return s
            // })
            this.$forceUpdate()
            this.loading = false
          },
          error: (err) => {
            console.log(err)
            this.loading = false
            this.$WiMakeError({error: err, id: 109})
          }
        })
      },
      alterarChamado: function (config) {
        this.loading = true
        var enviar = {id: config.id, alterar_status: true, chamado_status_id: config.status.id}
        if (config.DateTime) {
          var v = config.DateTime + ''
          enviar['agendamento_log'] = v.replace('T', ' ')
        }
        callApi.put({
          uri: 'chamado/dados',
          id: config.id,
          data: enviar,
          sucess: (data) => {
            if (data.status === 201) {
              this.agendarDialog.active = false
              this.loading = false
              if (config.prioridade) {
                this.alterarPrioridade(config.prioridade)
              } else {
                this.load()
              }
            } else {
              this.$swal({ type: 'error', title: 'Oops... Algo deu errado!', text: 'Tente novamente ou contacte o suporte.' })
            }
          },
          error: (err) => {
            this.loading = false
            console.log(err)
          }
        })
      },
      alterarPrioridade: function (itens) {
        this.loading = true
        callApi.put({
          uri: 'chamado/dados',
          id: 'multiple',
          data: itens,
          sucess: () => {
            this.load()
          },
          error: (err) => {
            this.loading = false
            console.log(err)
          }
        })
      },
      cancelDialog: function () {
        this.load()
        this.agendarDialog.active = false
      },
      editTicketStatus: function (status) {
        this.$WiEditDialog({
          wiConfig: 'status-do-chamado',
          callback: this.load,
          data: status
        })
      }
    },
    created: function () {
      this.load()
    },
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      User () {
        return this.$store.state.User || {}
      }
    },
    components: {
      Container,
      Draggable
    }
  }
</script>
<style>
.toolbar_status .v-toolbar__content {
  padding: 0px 10px;
}
.box {
  display: inline-block;
  width: 300px;
  height: 100px;
}
.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, .125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}
.draggable-item-horizontal {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  text-align: center;
  /* width : 200px; */
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, .125);
  margin-right: 4px;
  cursor: default;
}
.dragging {
  background-color: yellow;
}
.card-scene {
  padding: 50px;
  /* background-color: #fff; */
}
.card-container {
  width: 320px;
  padding: 10px;
  margin: 5px;
  margin-right: 45px;
  background-color: #f3f3f3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}
.card {
  margin: 5px;
  /* border: 1px solid #ccc; */
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
  padding: 10px;
}
.card-column-header {
  font-size: 18px;
}
.column-drag-handle {
  cursor: move;
  padding: 5px;
}
.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg)
}
.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg)
}
.opacity-ghost {
  transition: all .18s ease;
  opacity: 0.8;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}
.opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.0);
}
.form-demo {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex
}
.form {
  flex: 3;
  /* width: 500px; */
  /* background-color: #f3f3f3; */
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 6px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08), 0px 3px 3px rgba(0, 0, 0, 0.08);
}
.form-fields-panel {
  flex: 1;
  margin-right: 50px;
}
.form-ghost {
  transition: 0.18s ease;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
}
.form-ghost-drop {
  box-shadow: 0 0 2px 5px rgba(0, 0, 0, 0.0);
}
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}
.cards-drop-preview {
  background-color: #b3b5b7;
  border: 1px dashed #585859;
  margin: 5px 45px 5px 5px;
  width: 100%;
}
</style>
