import { Model } from '@/default/model/Model'

export class ProductProfile extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: '549b7548',
      create: 'ba7aae1c',
      update: '1bced885',
      delete: 'd99b53a2'
    }
    this.title = {
      list: 'Perfis de produtos',
      edit: {
        insert: 'Novo perfil de produto',
        update: 'Editando perfil de produto'
      }
    }
    this.icon = 'shopping_cart'
    this.apiUrl = 'register/product/profile'
    this.search = {
      placeholder: 'Pesquise ID ou nome do perfil de produtos',
      fields: ['id', 'name']
    }
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe o nome e os itens característicoss deste perfil.'
      }),
      this.fieldText({
        value: 'name',
        text: 'Código Externo',
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldSelect({
        value: 'variables',
        text: 'Items característicos',
        size: ['xs12', 'sm12', 'md8', 'lg8'],
        multiple: true,
        options: [
          {value: 1, text: 'Característica genérica'},
          {value: 2, text: 'Marca'},
          {value: 3, text: 'Tamanho'},
          {value: 4, text: 'Cor'}
        ]
      }),
      this.fieldSubHeader({
        text: 'O campo abaixo pode ser usado para descrever o perfil.'
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações da Venda',
        placeholder: 'Ex: Negociação iniciada em...'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição'
      })
    ])
  }
}
