<template>
  <v-card class="elevation-17">
    <v-toolbar dense flat color="transparent" class="business-address__toolbar">
      <v-toolbar-title>
        <v-icon color="primary">attach_money</v-icon>
        <span> Detalhes financeiros</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outline small @click="$WiEditDialog({ wiConfig: 'internet-contract-pay-day', data: { id: business.id, invoice_pay_day: business.invoice_pay_day} })">
        <v-icon>event_repeat</v-icon>
        <span class="pl-1">Alterar Dia de Vencimento</span>
      </v-btn>
      <v-btn outline small :to="{ name: 'finance-list', params: {page: 'finance-list'}, query: { contract_id: business.id } }">
        <v-icon>payments</v-icon>
        <span class="pl-1">Listar Pagamentos</span>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list two-line subheader>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon class="blue white--text">attach_money</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <span>Dia de Vencimento: </span>
            <strong>{{ business.invoice_pay_day }}</strong>
            <span> | </span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on" ripple>
                  <span>Fidelidade: </span>
                  <strong> {{ business.fidelity }} </strong>
                </span>
              </template>
              <span>{{ business.fidelity_end_date | moment('DD/MM/y') }}</span>
            </v-tooltip>
          </v-list-tile-title>
          <v-list-tile-sub-title>
            <span v-if="business.finance_account">
              <span>Conta: </span>
              <strong>{{ business.finance_account.name || 'Não definida' }}</strong>
            </span>
            <span> | </span>
            <span v-if="business.finance_form_payment">
              <span>Forma de Pagamento: </span>
              <strong>{{ business.finance_form_payment.name || 'Não definida' }}</strong>
            </span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    name: 'BusinessRecurrent',
    props: {
      business: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style scoped>
  .business-observation__title {
    color: gray;
  }
</style>
