<template>
  <v-card flat>
    <v-alert v-if="stage.stage && stage.stage.config" style="margin: 0;" :value="true" :color="stage.stage.config.color">
      <span v-if="stage.stage.config && stage.stage.config.message">
        <h1 v-html="stage.stage.config.message"></h1>
      </span>
      <span v-else>
        <h2>Mensagem não definida!</h2>
      </span>
      <span v-if="stage.stage.config && stage.stage.config.description">
        <h3 v-html="stage.stage.config.description"></h3>
      </span>
    </v-alert>
    <v-card-actions v-if="status && status.code != 'closed' && status.code != 'canceled'">
      <v-spacer></v-spacer>
      <v-btn :outline="decision != 'yes'" block color="green" :class="{'white--text': decision == 'yes'}" @click="updateProcess('yes')">
        <span> Sim </span>
        <v-icon>thumb_up_off_alt</v-icon>
      </v-btn>
      <v-btn :outline="decision != 'no'" block color="red" :class="{'white--text': decision == 'no'}" @click="updateProcess('no')">
        <span> Não </span>
        <v-icon>thumb_down_off_alt</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text v-else-if="data && data.decision">
      <h1 v-if="data.decision == 'yes'"> Sim </h1>
      <h1 v-if="data.decision == 'no'"> Não </h1>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'Decision',
  data: () => ({
    decision: null
  }),
  methods: {
    updateProcess: function (decision) {
      this.decision = decision
      this.$emit('updateProcess', {
        isValid: true,
        data: {
          decision: this.decision
        }
      })
    }
  },
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
    stage: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style scoped>
  .diagnostic-set-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>