import { Model } from '@/default/model/Model'
import {
  PERMISSION_REGISTER_MODULE,
  PERMISSION_INTERNET_MODULE,
  PERMISSION_REGISTER_IMPORT_SERVICES,
  PERMISSION_REGISTER_IMPORT_SERVICES_FROM_XLSX
} from '@/default/constants/permissions'

export class Service extends Model {
  constructor () {
    super()
    this.list = true
    this.id = PERMISSION_REGISTER_MODULE
    this.name = 'service'
    this.title = {
      list: 'Serviços',
      edit: {
        create: 'Novo Serviço',
        update: 'Atualizar Serviço'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome do serviço',
      fields: ['id', 'name']
    }
    this.params = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'room_service'
    this.apiUrl = 'register/service'
    this.maxWidth = '800px'
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/register/pages/Items/Items')

    this.imports = {
      permission: PERMISSION_REGISTER_IMPORT_SERVICES,
      title: 'Importar',
      origins: {
        imanager: {
          title: 'Importar do Excel',
          permission: PERMISSION_REGISTER_IMPORT_SERVICES_FROM_XLSX,
          description: 'Baixe o modelo de planilha e importe para o sistema.',
          image: '/static/icons/excel_icon.png',
          wiConfig: 'import-service-excel'
        }
      }
    }

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID',
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome',
      align: 'center',
    }),
    unitCode: this.fieldList({
      value: 'unit.full_name',
      text: 'Unidade de medida',
      align: 'center',
    }),
    amountSale: this.fieldList({
      value: 'amount_sale',
      text: 'Preço de Venda',
      align: 'center',
    }),
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste serviço.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome',
      counter: 100,
      search: true,
      placeholder: 'Informe o nome em no máximo 100 caracteres.',
      type: 'text',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true,
    }),
    hostConfigId: this.fieldSelectApi({
      value: 'host_config_id',
      text: 'Plano de navegação*',
      autocomplete: true,
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      type: 'db-autocomplete',
      api: {
        url: 'network/host/config',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name',
      showField: () => this.permissionIsAvaiable(PERMISSION_INTERNET_MODULE)
    }),
    itemUnitId: this.fieldSelectApi({
      value: 'item_unit_id',
      text: 'Unidade de medida*',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      api: {
        url: 'register/product/unit',
        params: {
          fields: 'id,name,code',
          order: 'code,asc'
        }
      },
      show: 'full_name'
    }),
    // active
    active: this.fieldSwitch({
      value: 'active',
      text: 'Ativo',
      defaultValue: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    amountCost: this.fieldMoney({
      value: 'amount_cost',
      text: 'Preço de Custo',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    }),
    margin: this.fieldNumber({
      value: 'margin',
      text: 'Margem de Lucro (%)',
      placeholder: 'Ex: 10 (10%)',
      step: '.01',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    }),
    amountSale: this.fieldMoney({
      value: 'amount_sale',
      text: 'Preço de Venda',
      size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
    }),
    subHeader2: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações sobre este serviço.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Observações do serviço',
      placeholder: 'Por favor, descreva este chamado de forma detalhada.'
    })
  }
}

export default {
  id: PERMISSION_REGISTER_MODULE,
  name: 'service',
  list: true,
  title: {
    list: 'Serviços',
    edit: {
      create: 'Novo Serviço',
      update: 'Atualizar Serviço'
    }
  },
  icon: 'room_service',
  apiUrl: 'register/service',
  imports: {
    permission: PERMISSION_REGISTER_IMPORT_SERVICES,
    title: 'Importar Serviços',
    origins: {
      imanager: {
        title: 'Importar do Excel',
        permission: PERMISSION_REGISTER_IMPORT_SERVICES_FROM_XLSX,
        description: 'Baixe o modelo de planilha e importe para o sistema.',
        image: '/static/icons/excel_icon.png',
        wiConfig: 'import-service-excel'
      }
    }
  },
  fields: [
    {
      value: 'id',
      text: 'ID',
      search: true,
      type: 'int',
      list: true,
      edit: false
    },
    {
      text: 'Informe os dados básicos deste serviço.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'name',
      text: 'Nome',
      counter: 100,
      search: true,
      placeholder: 'Informe o nome em no máximo 100 caracteres.',
      type: 'text',
      size: [
        'xs12',
        'sm5',
        'md5',
        'lg5'
      ],
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'unit.code',
      text: 'Unidade de medida',
      search: true,
      type: 'text',
      list: true,
      edit: false
    },
    {
      value: 'host_config_id',
      text: 'Plano de navegação*',
      autocomplete: true,
      size: [
        'xs12',
        'sm4',
        'md4',
        'lg4'
      ],
      type: 'db-autocomplete',
      list: false,
      edit: true,
      apiUrl: 'network/host/config',
      apiParams: {
        fields: 'id,name',
        order: 'name,asc'
      },
      show: 'name'
    },
    {
      value: 'item_unit_id',
      text: 'Unidade de medida*',
      autocomplete: true,
      size: [
        'xs12',
        'sm3',
        'md3',
        'lg3'
      ],
      type: 'db-autocomplete',
      list: false,
      edit: true,
      apiUrl: 'register/product/unit',
      apiParams: {
        fields: 'id,code',
        order: 'code,asc'
      },
      show: 'code'
    },
    {
      text: 'O campo abaixo pode ser usado para registrar observações sobre este serviço.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'description',
      text: 'Observações do serviço',
      placeholder: 'Por favor, descreva este chamado de forma detalhada.',
      type: 'textarea',
      list: false,
      edit: true
    }
  ]
}
