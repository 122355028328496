import {Model} from '@/default/model/Model'

export class BusinessCategory extends Model {
  constructor () {
    super()
    this.list = true
    this.title = {
      list: 'Categorias',
      edit: {
        insert: 'Nova Categoria',
        update: 'Editar Categoria'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome da categoria',
      fields: ['id', 'name']
    }
    this.params = {
      limit: 10,
      with: 'form:id,name,type_id'
    }
    this.icon = 'category'
    
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls2', 'offset-xl2']

    this.components.list.tableRow = () => import('@/modules/business/pages/Businesses/BusinessCategories')

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome da categoria'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, cor e ícone desta categoria.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Categoria',
      size: ['xs12', 'sm6', 'md3', 'lg3']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm3', 'md1', 'lg1']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm3', 'md4', 'lg4']
    }),
    form: this.fieldWiListDialog({
      value: 'form_id',
      text: 'Formulário Complementar',
      required: false,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      wiConfig: 'service-desk-config-form',
      show: 'form.name',
      icon: 'list_alt'
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Crie um layout de descrição para esta categoria.'
    }),
    layout: this.fieldTinyEditor({
      value: 'layout',
      text: 'Layout desta categoria',
      placeholder: 'Crie a estrutura da categoria.'
    })
  }
}
