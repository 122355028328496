<template>
  <div class="add-event">
    <v-menu v-if="validActions.length > 1" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn outline v-on="on">
          <v-icon>add</v-icon>
          <span> Adicionar Novo</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile v-for="(action, actionKey) in validActions" :key="actionKey" @click="executeAction(action)">
          <v-list-tile-avatar>
            <v-icon>{{action.icon}}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>
            <span>{{ action.name }}</span>
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-btn v-else outline v-for="(action, actionKey) in validActions" :key="actionKey" @click="executeAction(action)">
      <v-icon>{{action.icon}}</v-icon>
      <span> {{action.name}}</span>
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'WiEventsAddEvent',
    data: () => ({
      actions: [
        {
          code: 'note',
          name: 'Adicionar nota',
          icon: 'message',
          valid: () => (true)
        },
        {
          code: 'diagnostic-vtal',
          name: 'Adicionar Diagnóstico',
          icon: 'search',
          valid: business => (business?.info?.code === 30)
        }
      ]
    }),
    computed: {
      validActions () {
        return this.actions.filter(action => action.valid(this.business))
      }
    },
    methods: {
      executeAction: function (action) {
        switch (action.code) {
          case 'note':
            this.addNote()
            break;
          case 'diagnostic-vtal':
            this.addDiagnosticVtal()
            break;
        }
      },
      addNote: function () {
        this.$WiEditDialog({
          wiConfig: 'diagnostic-event',
          data: {
            business_id: this.business.id
          }
        })
      },
      addDiagnosticVtal: function () {
        this.$WiApiPost({
          uri: 'networks/test-management-vtal/event/diagnostic-vtal',
          data: {
            business_id: this.business.id
          },
          config: {
            msgLoad: {
              title: `Criando dianóstico...`,
              text: 'Não irá demorar mais que alguns segundos.'
            },
            msgSucess: {
              title: 'Solicitação enviada com sucesso.',
              text: 'O resultado da solicitação irá aparecer na timeline.'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja criar um novo Diagnóstico?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          }
        })
      }
    },
    props: {
      business: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style scoped>
  .add-event {
    width: 100%;
    text-align: center;
  }
</style>