import { PersonFilters } from './PersonFilters'
import Prospect from '@/modules/register/config/Person/Person?url=Prospect'

var prospect = Prospect({name: 'Prospect', field: 'prospect'})

prospect.id = {
  id: '2498ebe2',
  create: '38754ccc',
  update: '78d05bfa',
  delete: 'a709a139'
}
prospect.title = {
  list: 'Prospects',
  edit: {
    insert: 'Novo Prospect',
    update: 'Editar Prospect'
  }
}
prospect.icon = 'perm_identity'
prospect.apiUrl = 'register/person/prospect'
prospect.urlDetails.name = 'prospect'
prospect['filters'] = new PersonFilters()

export default prospect
