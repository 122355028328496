<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <span color="red lighten-2" dark v-on="on" style="font-size: 14px; color: gray; cursor: pointer;">
        <span v-t="'components.versionDialog.versionLabel'"></span> {{ system.version }}
      </span>
    </template>
    <v-card>
      <v-toolbar dense flat color="transparent">
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center center-text" style="text-align: center;">
        <div class="version-dialog__logo">
          <v-img :src="'static/systems/' + system.icon" :alt="system.name" position="center" width="100px" />
        </div>
        <div class="version-dialog__system" v-html="system.nameHtml"></div>
        <br>
        <h2>
          <span v-t="'components.versionDialog.versionLabel'"></span>: {{ system.version }}
        </h2>
        <h3>
          <span v-t="'components.versionDialog.versionDateLabel'"></span> {{ system.lastUpdate }}
        </h3>
      </v-card-text>
      <v-card-actions class="version-dialog__footer">
        <v-spacer></v-spacer>
        <label class="center-align">© 
          <span id="year">{{ new Date().getFullYear() }} </span> 
          <span>Copyright </span> 
          <a target="_blank" href="https://wnewer.com" style="text-decoration:none;">
          <span> WNewer Technology </span>
          </a> 
          <span style="text-transform: lowercase;" v-t="'pages.login.copyright.text'"></span>
        </label>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'VersionDialog',
    data: () => ({
      dialog: false
    }),
    computed: {
      system () {
        return this.$store.state.system || []
      }
    }
  }
</script>
<style scoped>
  .version-dialog__logo {
    text-align: center;
    width: 100%;
    padding-left: 135px;
  }
  .version-dialog__system {
    font-size: 28px;
  }
  .version-dialog__footer {
    text-align: center !important;
    font-size: 12px;
    padding-top: 70px;
  }
</style>