import router from '@/modules/projects/routes'
import config from '@/modules/projects/config'
import menu from '@/modules/projects/menu'

export default {
  id: '0bae9ce3-400f-4518-94a0-15d1b3bdf088',
  name: 'projects',
  title: 'Projects',
  description: 'Gestão de Projetos e Tarefas',
  icon: 'wises-technology-module-projects.png',
  iconSystem: 'wises-technology-system-projects.png',
  iconProfile: 'profile_service_desk.png',
  color: '#00b271',
  menu,
  router,
  config
}
