<template>
  <v-scroll-y-transition mode="out-in">
    <div v-if="!selected" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center;" > Selecione um grupo... </div>
    <v-card v-else :key="selected.id" >
      <v-card-text style="padding: 16px 0px;">
        <v-avatar>
          <v-icon v-if="selected.icon" :style="{'color': !selected.active ? '#e0e0e0' : selected.color}" x-large>{{selected.icon}}</v-icon> 
          <v-icon v-else x-large :style="{'color': !selected.active ? '#e0e0e0' : selected.color}">device_hub</v-icon> 
        </v-avatar>
        <h3 class="headline">
          {{ selected.name }}
        </h3>
        <div v-if="selected.active" class="gray--text subheading font-weight-bold" style="color: gray;">
          <span>{{selected.active}}</span>
        </div>
        <v-divider v-if="selected.description"></v-divider>
        <div v-if="selected.description" class="gray--text" style="color: gray;">{{ selected.description }}</div>
        <v-divider></v-divider>
        <v-tooltip v-if="selected.id && selected.active" v-prms="{'id': 'svdk'}" bottom>
          <template slot="activator">
            <v-btn @click="openEditModal('finance-in-categories', {finance_category_id: selected.id})" icon ripple>
              <v-icon color="green lighten-1">add</v-icon>
            </v-btn>
          </template>
          <span>Criar Sub-ocorrência</span>
        </v-tooltip>
        <v-tooltip v-if="selected.id" v-prms="{'id': 'svdk'}" bottom>
          <template slot="activator">
            <v-btn @click="openEditModal('finance-in-categories', selected)" icon ripple>
              <v-icon color="orange lighten-1">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar este tipo de ocorrência</span>
        </v-tooltip>
        <v-tooltip v-if="selected.id" v-prms="{'id': 'svdk'}" bottom>
          <template slot="activator">
            <db-delete v-on:action="refresh" store="finance-in-categories" :id="selected.id"></db-delete>
          </template>
          <span>Apagar este tipo de ocorrência</span>
        </v-tooltip>
        <v-divider></v-divider>
      </v-card-text>
      <div v-if="editModal">
        <edit-modal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="refresh"></edit-modal>
      </div>
    </v-card>
  </v-scroll-y-transition>
</template>
<script>
  export default {
    name: 'CategorySelect',
    data () {
      return {
        editModal: false,
        modalForm: {},
        modalStore: null
      }
    },
    methods: {
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      }
    },
    props: {
      'selected': {
        required: true,
        type: Object
      },
      'refresh': {
        required: true
      }
    }
  }
</script>