<template>
  <v-timeline-item large transition="slide-y-reverse-transition" style="background: white !important;">
    <template v-slot:icon>
      <v-avatar color="white" style="background-color: white !important;">
        <v-img :src="'static/icons/monitor/' + event.type_info.icon + '.png'"/>
      </v-avatar>
    </template>
    <template v-slot:opposite>
      <span class="headline font-weight-bold blue--text">
        {{event.type_info.title}}
      </span>
    </template>
    <v-card class="elevation-20" :style="{background: $wiDark ? '' : 'white'}">
      <EventPerson
        :event="event"
        :action="event.type_info.action"
      ></EventPerson>
      <keep-alive>
        <component
          :is="eventComponent(event)"
          :event="event"
          :business="business"
        ></component>
      </keep-alive>
    </v-card>
  </v-timeline-item>
</template>
<script>
  import EventPerson from './EventPerson'
  import EventDefault from './EventDefault'
  import EventDiagnostic from './EventDiagnostic'
  import EventDiagnosticVTal from './EventDiagnosticVTal'
  import EventOrderVTal from './EventOrderVTal'
  import EventSetHostProducerModel from './EventSetHostProducerModel'
  export default {
    name: 'WiEventsEvent',
    methods: {
      eventComponent: function (event) {
        switch (event.type) {
          case 20:
          case 21:
          case 22:
          case 23:
          case 24:
          case 25:
          case 27:
            return EventDiagnosticVTal
          case 28:
          case 29:
          case 30:
          case 31:
            return EventOrderVTal
          case 32:
            return EventDiagnostic
          case 35:
            return EventSetHostProducerModel
          default:
            return EventDefault
        }
      }
    },
    props: {
      event: {
        required: true,
        default: () => ({})
      },
      business: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      EventPerson
    }
  }
</script>
<style>
  .v-timeline-item__inner-dot .primary {
    background-color: white !important;
  }
  .wi-events__paginate {
    width: 100% !important;
    text-align: center;
  }
</style>
