export default [
  {
    value: 'id',
    text: 'ID',
    list: true,
    search: true
  },
  {
    value: 'in_out',
    text: 'Tipo',
    list: true,
    align: 'center',
  },
  {
    value: 'date_start',
    text: 'Primeira fatura',
    list: true
  },
  {
    value: 'amount_total',
    text: 'Valor.',
    list: true
  },
  {
    value: 'frequency_id',
    text: 'Recorrência',
    align: 'left',
    type: 'select',
    list: true
  },
  // {
  //   value: 'generate_period',
  //   text: 'Período de geração',
  //   align: 'center',
  //   type: 'select',
  //   list: true
  // },
  {
    value: 'description',
    text: 'Descrição',
    type: 'text',
    list: true
  },
  {
    value: 'finance_form_payment_id',
    text: 'Forma Pgmt.',
    type: 'db-autocomplete',
    autocomplete: true,
    align: 'center',
    list: true
  },
  {
    value: 'finance_account_id',
    text: 'Conta',
    type: 'text',
    list: true
  },
  // {
  //   value: 'id',
  //   text: 'Opções',
  //   list: true,
  //   align: 'center',
  //   sortable: false
  // },
  {
    text: 'Informações básicas desta transação recorrente.',
    type: 'subheader',
    edit: true
  },
  {
    value: 'date_start',
    text: 'Primeira fatura',
    type: 'date',
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md2', 'ls2'],
    disabled: (form) => { return !!form.id }
  },
  {
    value: 'amount_initial',
    text: 'Valor/Subtotal',
    type: 'money',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'lg2']
  },
  {
    value: 'frequency_id',
    text: 'Recorrência',
    align: 'left',
    type: 'select',
    list: false,
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md2', 'lg2'],
    options: [
      { value: 1, text: 'Mensal' },
      { value: 5, text: 'Anual' }
    ],
    disabled: (form) => { return !!form.id },
    defaultOption: 1
  },
  {
    value: 'generate_period',
    text: 'Período de recorrência a frente',
    align: 'center',
    type: 'select',
    list: false,
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    options: [
      { value: 12, text: '12 meses' },
      { value: 24, text: '24 meses' }
    ],
    disabled: (form) => { return !!form.id },
    defaultOption: 12,
    showField: (form) => { return form.frequency_id === 1 }
  },
  {
    value: 'generate_period',
    text: 'Período de recorrência a frente',
    align: 'center',
    type: 'select',
    list: false,
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    options: [
      { value: 2, text: '2 anos' },
      { value: 3, text: '3 anos' },
      { value: 4, text: '4 anos' },
      { value: 5, text: '5 anos' }
    ],
    disabled: (form) => { return !!form.id },
    defaultOption: 2,
    showField: (form) => { return form.frequency_id === 5 }
  },
  {
    value: 'finance_account_id',
    text: 'Conta Financeira',
    type: 'db-autocomplete',
    autocomplete: true,
    required: true,
    list: false,
    edit: true,
    apiUrl: 'finance/account',
    apiParams: {
      fields: 'id,name',
      order: 'name,asc'
    },
    show: 'name',
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    disabled: (form) => { return !!form.id }
  },
  {
    text: 'Informações de identificação da transação financeira.',
    type: 'subheader',
    edit: true
  },
  {
    value: 'description',
    text: 'Descrição',
    type: 'text',
    list: false,
    edit: true,
    search: true,
    align: 'center',
    sortable: false,
    size: (form) => { return form.id ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md5', 'lg5'] }
  },
  {
    value: 'finance_form_payment_id',
    text: 'Forma Pgmt.',
    type: 'db-autocomplete',
    autocomplete: true,
    align: 'center',
    list: false,
    edit: true,
    apiUrl: 'finance/form-payment',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: ['xs12', 'sm12', 'md2', 'lg2']
  },
  {
    value: 'finance_category_id',
    text: 'Categoria de Receita',
    type: 'db-autocomplete',
    autocomplete: true,
    list: false,
    edit: true,
    apiUrl: 'finance/category/in',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    showField: (form) => { return form.in_out === 1 }
  },
  {
    value: 'finance_category_id',
    text: 'Categoria de Despesa',
    type: 'db-autocomplete',
    autocomplete: true,
    list: false,
    edit: true,
    apiUrl: 'finance/category/out',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    showField: (form) => { return form.in_out === 2 }
  },
  {
    value: 'update_option',
    text: 'Opções de atualização.',
    align: 'center',
    type: 'select',
    list: false,
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    options: [
      { value: 'update-unpaid-finances', text: 'Atualizar transações pendentes' },
      { value: 'update-next-unpaid-finances', text: 'Atualizar transações pendentes futuras' },
      { value: 'update-all-finances', text: 'Atualizar todas as transações' }
    ],
    showField: form => (form.id),
    defaultOption: 'update-next-unpaid-finances'
  },
  {
    value: 'finance_account_id',
    text: 'Conta',
    type: 'text',
    list: false,
    edit: false,
    search: true,
    size: (form) => { return form.in_out ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md3', 'lg3'] }
  },
  {
    value: 'advanced',
    text: 'Opções avançadas.',
    size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
    type: 'switch',
    edit: true
  },
  {
    text: 'Valores e datas complementares.',
    type: 'subheader',
    edit: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    showField: (form) => { return form.advanced }
  },
  {
    text: 'Vincular a um Centro de Custo, Cliente, Fornecedor ou Colaborador.',
    type: 'subheader',
    edit: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'amount_discount',
    text: 'Desconto',
    type: 'money',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'ls2', 'xl2'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'amount_addition',
    text: 'Acréscimo',
    type: 'money',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'ls2', 'xl2'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'finance_cost_center_id',
    text: 'Centro de Custo',
    type: 'db-autocomplete',
    autocomplete: true,
    list: false,
    edit: true,
    apiUrl: 'finance/cost-center',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'person_make',
    text: 'Vincular Fornecedor, Cliente/Colaborador.',
    size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
    type: 'switch',
    edit: true,
    showField: (form) => { return form.advanced }
  },
  {
    text: 'Vincular esta transação a um Cliente, Fornecedor ou a um Colaborador.',
    type: 'subheader',
    edit: true,
    showField: (form) => { return form.advanced && form.person_make }
  },
  {
    value: 'person_type',
    text: 'Cliente, Fornecedor ou Colaborador',
    align: 'center',
    type: 'select',
    edit: true,
    required: true,
    size: (form) => { return form.person_type ? ['xs12', 'sm12', 'md4', 'lg4'] : ['xs12', 'sm12', 'md12', 'lg12'] },
    options: [{value: 'customer', text: 'Vincular a um Cliente'}, {value: 'provider', text: 'Vincular a um Fornecedor'}, {value: 'collaborator', text: 'Vincular a um Colaborador'}],
    showField: (form) => { return form.advanced && form.person_make }
  },
  {
    value: 'person_id',
    text: 'Cliente*',
    autocomplete: true,
    required: true,
    size: ['xs12', 'sm12', 'md8', 'lg8'],
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'customer',
    show: 'person.first_name',
    icon: 'person_outline',
    showField: (form) => { return form.advanced && form.person_make && form.person_type === 'customer' }
  },
  {
    value: 'person_id',
    text: 'Fornecedor*',
    autocomplete: true,
    required: true,
    size: ['xs12', 'sm12', 'md8', 'lg8'],
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'provider',
    show: 'person.first_name',
    icon: 'person_outline',
    showField: (form) => { return form.advanced && form.person_make && form.person_type === 'provider' }
  },
  {
    value: 'person_id',
    text: 'Colaborador*',
    autocomplete: true,
    required: true,
    size: ['xs12', 'sm12', 'md8', 'lg8'],
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'collaborator',
    show: 'person.first_name',
    icon: 'person_outline',
    showField: (form) => { return form.advanced && form.person_make && form.person_type === 'collaborator' }
  },
  // {
  //   text: 'Informe se deseja repetir esta transação.',
  //   type: 'subheader',
  //   edit: {
  //     insert: true
  //   }
  // },
  // {
  //   value: 'repeat',
  //   text: 'Repetir transação?',
  //   required: false,
  //   size: (form) => { return form.repeat ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md12', 'lg12'] },
  //   type: 'select',
  //   options: [{value: 0, text: 'Não, transação acontece apenas uma vez.'}, {value: 1, text: 'Repetir transação por um número de vezes'}],
  //   edit: {
  //     insert: true
  //   },
  //   showField: (form) => { return form.advanced }
  // },
  // {
  //   value: 'number_total',
  //   text: 'Quantidade de repetições',
  //   type: 'int',
  //   edit: {
  //     insert: true
  //   },
  //   size: ['xs12', 'sm12', 'md3', 'lg3'],
  //   showField: (form) => { return form.advanced && form.repeat && form.repeat === 1 }
  // },
  // {
  //   value: 'number_period',
  //   text: 'Intervalo entre repetições',
  //   type: 'select',
  //   options: [{value: 'month', text: 'Repetir por mês'}, {value: 'days', text: 'Repetir por dia'}, {value: 'week', text: 'Repetir por semana.'}, {value: 'year', text: 'Repetir por ano.'}],
  //   edit: {
  //     insert: true
  //   },
  //   size: ['xs12', 'sm12', 'md3', 'lg3'],
  //   showField: (form) => { return form.advanced && form.repeat && form.repeat === 1 }
  // },
  {
    value: 'observation',
    text: 'Observações',
    type: 'textarea',
    edit: true
  }
]
