<template>
  <div class="diagnostic">
    <DiagnosticStatus
      :status="event.status_info"
      :complement="event.complement"
    ></DiagnosticStatus>
    <DiagnosticMessages
      :complement="event.complement"
    ></DiagnosticMessages>
    <DiagnosticHintOperations
      :complement="event.complement"
    ></DiagnosticHintOperations>
    <DiagnosticItems
      :complement="event.complement"
      :actions="event.actions"
      :status="event.status_info"
    ></DiagnosticItems>
    <DiagnosticActions
      :actions="event.actions"
      :status="event.status_info"
    ></DiagnosticActions>
  </div>
</template>
<script>
  import DiagnosticItems from './DiagnosticItems'
  import DiagnosticStatus from './DiagnosticStatus'
  import DiagnosticActions from './DiagnosticActions'
  import DiagnosticMessages from './DiagnosticMessages'
  import DiagnosticHintOperations from './DiagnosticHintOperations'
  export default {
    name: 'WiEventsDiagnostic',
    props: {
      event: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      DiagnosticItems,
      DiagnosticStatus,
      DiagnosticActions,
      DiagnosticMessages,
      DiagnosticHintOperations
    }
  }
</script>
