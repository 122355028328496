<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="85%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <template v-slot:activator="{ on }">
        <v-list-tile v-on="on" avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <img src="static/icons/monitor/anexo_96.png" alt="Arquivos Anexados">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Arquivos Anexados</v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 20px;">
              <span v-text="numAnexos"></span>
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon color="black">info</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </template>
      <v-card>
        <v-toolbar dense>
          <v-icon>attachment</v-icon>
          <strong>Arquivos Anexados <span v-if="numAnexos">({{numAnexos}})</span></strong>
          <v-spacer></v-spacer>
          <v-btn v-prms="{'id': '1fa1954c'}" small outline @click="dialogNewFile = true">
            <v-icon>add</v-icon>
            <span>Novo Anexo</span>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="min-height: 300px; padding: 0;">
          <view-detail v-if="dialog" title="Seguidores" apiUrl="chamado/anexo" :apiParams="'?where=chamado_dados_id,' + chamado + '&limit=100&order=id,desc'" notGetID="true" notReturnIcon="true" notShowToolbar="true">
            <template slot-scope="dados" style="min-height: 300px;">
              <div v-if="dados.content && dados.content.data.length < 1" style="text-align: center; padding: 20px;">
                <v-alert :value="true" type="warning" >
                  <span>Nenhum arquivo anexado até o momento.</span>
                </v-alert>
              </div>
              <v-layout v-else row wrap>
                <v-flex v-for="(file, key) in dados.content.data" xs12 sm3 md2 lg2 style="padding: 5px;" :key="key">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">      
                      <v-card v-on="on" style="cursor: pointer;">
                        <v-card-text style="text-align: center !important; padding: 10px 0px 0px 0px !important;">
                          <img v-if="file.file.type_name.split('/')[0] == 'image'" :src="file.file.url" alt="" style="max-height: 50px; min-height: 50px;">
                          <div v-else-if="file.file.type_name.split('/')[0] == 'application'">
                            <img v-if="file.file.type_name.split('/')[1] == 'pdf'" src="/static/icons/file_types/pdf_96.png" alt="" style="max-height: 50px;">
                            <img v-else-if="file.file.type_name.split('/')[1] == 'csv'" src="/static/icons/file_types/csv_96.png" alt="" style="max-height: 50px;">
                            <img v-else-if="file.file.type_name.split('/')[1] == 'xls'" src="/static/icons/file_types/xls_96.png" alt="" style="max-height: 50px;">
                            <img v-else-if="file.file.type_name.split('/')[1] == 'xlsx'" src="/static/icons/file_types/xls_96.png" alt="" style="max-height: 50px;">
                            <img v-else-if="file.file.type_name.split('/')[1] == 'mp4'" src="/static/icons/file_types/video_96.png" alt="" style="max-height: 50px;">
                            <img v-else src="/static/icons/file_types/arquivo_96.png" alt="" style="max-height: 50px;">
                          </div>
                          <img v-else src="/static/icons/file_types/arquivo_96.png" alt="" style="max-height: 50px;">
                          <v-list dense style="padding: 0px !important;">
                            <v-list-tile style="padding: 0px !important;">
                              <v-list-tile-content style="padding: 0px !important;">
                                <v-list-tile-title style="text-align: center; padding: 0px !important;">
                                  {{ file.file.name }}
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </template>
                    <v-list>
                      <v-list-tile @click="openFile(file)">
                        <v-list-tile-action>
                          <v-icon color="blue">open_in_new</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-title>Abrir</v-list-tile-title>
                      </v-list-tile>
                      <v-divider></v-divider>
                      <v-list-tile v-prms="{'id': '1fa1954c'}" @click="deleteFile(file, dados.refresh)">
                        <v-list-tile-action>
                          <v-icon color="red">delete</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-title>Apagar</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-dialog v-if="dialogNewFile" v-model="dialogNewFile" scrollable persistent max-width="75%" :fullscreen="$vuetify.breakpoint.xsOnly">
                <v-card>
                  <v-toolbar dense>
                    <v-icon>attach_file</v-icon>
                    <strong>Upload de Arquivo</strong>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogNewFile = false; dados.refresh()">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text style="height: 390px; padding: 0;">
                    <arquivos :parceData="{chamado_dados_id: chamado}"></arquivos>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
          </view-detail>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click.native="closeThisDialog()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import Arquivos from '@/default/components/Arquivos/Arquivos'
  import {callApi} from '@/default/service/Api'
  export default {
    data () {
      return {
        dialog: false,
        dialogNewFile: false
      }
    },
    methods: {
      openFile: function (file) {
        window.open(file.file.url)
      },
      deleteFile: function (file, refresh) {
        callApi.delete({
          uri: 'chamado/anexo',
          id: file.id,
          msgLoad: true,
          msgSucess: true,
          msgError: true,
          sucess: () => {
            refresh()
          },
          error: (err) => {
            console.log(err)
          }
        })
      },
      closeThisDialog: function () {
        this.refresh()
        this.dialog = false
      }
    },
    components: {
      ViewDetail,
      Arquivos
    },
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      profile () {
        return JSON.parse(localStorage.getItem('autheyJ0eXAiOi')) || {}
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    props: ['chamado', 'numAnexos', 'refresh']
  }
</script>
<style>
  .v-dialog__container {
    display: none !important;
  }
</style>
