<template>
  <v-card-text v-if="showHintOperations" flat style="padding-bottom: 0px;">
    <div class="elevation-2">
      <v-subheader>
        <h3>Hints/Ações</h3>
      </v-subheader>
      <div v-for="(hintOperation, hintOperationKey) in hintOperations" :key="hintOperationKey">
        <v-divider></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <v-icon large color="orange">build</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <span style="font-size: 14px;">Tipo de ação: {{ hintOperation.hintType }} </span>
            <strong style="font-size: 14px;">
              {{ hintOperation.type || ' - - - ' }}
            </strong>
          </v-list-tile-content>
        </v-list-tile>
      </div>
    </div>
  </v-card-text>
</template>
<script>
  export default {
    name: 'DiagnosticHintOperations',
    computed: {
      showHintOperations () {
        return this.complement?.hintOperations?.actions?.action?.length > 0
      },
      hintOperations () {
        return this.complement?.hintOperations?.actions?.action || []
      }
    },
    props: {
      complement: {
        required: true,
        default: () => ({})
      }
    }
  }
</script>
