import { Auth } from '@/default/service/Auth'
import { callApi } from '@/default/service/Api'

export default {
  namespaced: true,
  state: {
    chat: {},
    messages: [],
    paginate: {},
    messageText: '',
    replyMessage: null,
    lastMessage: {},
    page: 1,
    fileDialog: {
      active: false,
      fileType: null
    },
    loadings: {
      load: false,
      paginate: false
    },
    drawerRight: false,
    messageEditorDialog: false,
    showContactInfo: false,
    isOpen: false
  },
  getters: {
    messagesOrdered (state) {
      return state.messages.slice().reverse().sort(function(a, b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.external_time) - new Date(b.external_time)
      })
    }
  },
  mutations: {
    SET_CHAT (state, chat) {
      if (chat.id == state.chat.id && (!chat?.assets && state?.chat?.assets)) {
        chat['assets'] = state.chat.assets
      }
      if (
        !chat?.contact?.current_members?.length > 0 &&
        state?.chat?.contact?.current_members?.length > 0
      ) {
        chat.contact['current_members'] = state.chat.contact.current_members
      }
      state.chat = chat
      state.isOpen = true
    },
    SET_MESSAGE_TEXT (state, messageText) {
      state.messageText = messageText
      setTimeout(() => {
        state.messageText = ''
      }, 1000);
    },
    SET_MESSAGES (state, messages) {
      state.messages = messages
      state.lastMessage = messages[0]
    },
    SET_PAGINATE (state, paginate) {
      state.paginate = paginate
    },
    SET_REPLY_MESSAGE (state, replyMessage) {
      state.replyMessage = replyMessage
    },
    SET_FILE_DIALOG (state, { active, fileType }) {
      state.fileDialog.active = active
      state.fileDialog.fileType = fileType
    },
    UPDATE_MESSAGE (state, { key, message }) {
      state.messages.splice(key, 1, message)
    },
    ADD_MESSAGE (state, message) {
      state.messages.unshift(message)
      state.lastMessage = message
    },
    ADD_MESSAGE_IN_END (state, message) {
      state.messages.push(message)
    },
    SET_PAGE (state, page) {
      state.page = page
    },
    SET_DRAWER_RIGHT (state, drawerRight) {
      state.drawerRight = drawerRight
    },
    SET_MESSAGE_EDITOR_DIALOG (state, messageEditorDialog) {
      state.messageEditorDialog = messageEditorDialog
    },
    INCREMENT_PAGE (state) {
      state.page = state.page + 1
    },
    TOUGGLE_LOADING (state, loading) {
      state.loadings[loading] = !state.loadings[loading]
    },
    TOUGGLE_DRAWER_RIGHT(state) {
      state.drawerRight = !state.drawerRight
    },
    SET_SHOW_CONTACT_INFO(state, showContactInfo) {
      state.showContactInfo = showContactInfo
    },
    TOUGGLE_SHOW_CONTACT_INFO(state) {
      state.showContactInfo = !state.showContactInfo
    },
    SET_IS_OPEN(state, isOpen) {
      state.isOpen = isOpen
    }
  },
  actions: {
    getChat ({ commit, dispatch }, chatUuid) {
      commit('TOUGGLE_LOADING', 'load')
      dispatch('resetChat')
      callApi.get({
        uri: `chats/chat/${chatUuid}/detail`,
        params: {
          // add: 'messages',
          // with: withA.join(';')
        },
        sucess: response => {
          commit('SET_MESSAGES', response.data.messages.data)
          delete response.data.messages.data
          commit('SET_PAGINATE', response.data.messages)
          delete response.data.messages
          commit('SET_CHAT', response.data)
          commit('TOUGGLE_LOADING', 'load')
        },
        error: () => {
          commit('SET_CHAT', {})
          commit('TOUGGLE_LOADING', 'load')
        }
      })
    },
    resetChat ({ commit }) {
      commit('SET_MESSAGES', [])
      commit('SET_PAGINATE', {})
      commit('SET_REPLY_MESSAGE', null)
      commit('SET_PAGE', 1)
      // commit('SET_CHAT', {})
      commit('SET_IS_OPEN', false)
    },
    openFileDialog ({ commit }, fileType) {
      commit('SET_FILE_DIALOG', {
        active: true,
        fileType: fileType
      })
    },
    setMessage ({ state, commit }, { chatMessage, insertInEnd }) {
      if (chatMessage.person_id != Auth.user().id) {
        chatMessage['from_me'] = false
      } else {
        chatMessage['from_me'] = true
      }
      const key = state.messages.findIndex(message => message.uuid == chatMessage.uuid)
      if (key > -1) {
        commit('UPDATE_MESSAGE', {key: key, message: chatMessage})
      } else {
        if (insertInEnd) {
          commit('ADD_MESSAGE_IN_END', chatMessage)
        } else {
          commit('ADD_MESSAGE', chatMessage)
        }
      }
    },
    makeMessages ({ dispatch }, messages) {
      messages.map(message => {
        dispatch('setMessage', { chatMessage: message, insertInEnd: true })
      })
    },
    sendMessage ({ dispatch }, chatMessage) {
      callApi.post({
        uri: 'chats/chat-message',
        data: chatMessage,
        sucess: response => {
          dispatch('setMessage', {
            chatMessage: {
              ...response.data,
              'person': Auth.user()
            }
          })
        },
        error: () => {
          // commit('TOUGGLE_LOADING', 'load')
        }
      })
    },
    paginateMessages ({ state, commit, dispatch }) {
      commit('TOUGGLE_LOADING', 'paginate')
      callApi.get({
        uri: 'chats/chat-message',
        params: {
          page: state.page,
          order: 'chat_messages.created_at,desc;chat_messages.id,desc',
          where: `chat_messages.chat_id,${state.chat.id}`,
          limit: 20,
          join: 'chats'
        },
        sucess: (response) => {
          dispatch('makeMessages', response.data.data)
          delete response.data.data
          commit('SET_PAGINATE', response.data)
          commit('TOUGGLE_LOADING', 'paginate')
          commit('INCREMENT_PAGE')
        },
        error: () => {
          commit('TOUGGLE_LOADING', 'paginate')
        }
      })
    },
    unseenMessages: function ({ commit, rootState }, chatUuid) {
      let chatSelected = null
      let chatIndex = null
      rootState.Chats.chats.find((chat, indexChat) => {
        if (chat.chat.uuid == chatUuid) {
          chatSelected = chat
          chatIndex = indexChat
        }
      })
      if (chatSelected && chatSelected.unread_messages > 0) {
        chatSelected['unread_messages'] = 0
        commit('Chats/UPDATE_CHAT', { key: chatIndex, chat: chatSelected }, { root: true })
        callApi.post({
          uri: 'chats/chat-unread-messages/' + chatUuid,
          data: {}
        })
      }
      
    },
    subscribeOnChat ({ state, commit, dispatch }, chatUuid) {
      window.Echo.private(Auth.account().id + '.chat.' + chatUuid)
      .listen('.change', (response) => {
        if (state.chat.id === response.id) {
          commit('SET_CHAT', response)
        }
      })
      .listen('.message.new', (response) => {
        if (state.chat.id === response.chat_id) {
          dispatch('setMessage', { chatMessage: response })
        }
      })
      .listen('.message.change', (response) => {
        if (state.chat.id === response.chat_id) {
          dispatch('setMessage', { chatMessage: response })
          setTimeout(() => {
            dispatch('setMessage', { chatMessage: response })
          }, 1000)
        }
      })
    },
    unsubscribeOnChat (root, chatUuid) {
      window.Echo.leave(Auth.account().id + '.chat.' + chatUuid)
    }
  }
}
