<template>
  <div>
    <div class="google-map" :id="id" :style="{height: (innerHeight - 150) + 'px'}"></div>
  </div>
</template>

<script>
  export default {
    name: 'WiGoogleMaps',
    data: () => ({
      map: null,
      innerHeight: window.innerHeight
    }),
    methods: {
      createGoogleMaps: function () {
        const element = document.getElementById(this.id)
        const options = {
          zoom: 12,
          center: new window.google.maps.LatLng(-20.1467258, -45.1213433),
          mapTypeId: this.defaultMapMode || 'roadmap',
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain']
          }
        }
        this.map = new window.google.maps.Map(element, options)
      },
      createGoogleMapsMarkers: function () {
        const bounds = new window.google.maps.LatLngBounds();
        this.markers.forEach(marker => {
          if (marker.latitude && marker.longitude) {

            let icon = null
            if (marker.iconUrl) {
              icon = {
                url: marker.iconUrl,
                scaledSize: new window.google.maps.Size(50, 50),
                origin: new window.google.maps.Point(0,0),
                anchor: new window.google.maps.Point(25, 50)
              }
            }
            
            if (marker.circle) {
              new window.google.maps.Circle({
                strokeColor: marker.pinColor,
                strokeOpacity: 0.2,
                strokeWeight: 2,
                fillColor: marker.pinColor,
                fillOpacity: 0.1,
                map: this.map,
                center: { lat: marker.latitude, lng: marker.longitude },
                radius: 300,
              });
            }
            
            const createdMarker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(
                marker.latitude,
                marker.longitude
              ),
              map: this.map,
              title: marker.title,
              label: marker.label,
              draggable: false,
              icon: icon
              // icon: new window.google.maps.MarkerImage(
              //   'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + (marker.pinColor || '367DA6'),
              //   new window.google.maps.Size(50, 50),
              //   new window.google.maps.Point(0,0),
              //   new window.google.maps.Point(25, 50)
              // ),
              // shadow: new window.google.maps.MarkerImage(
              //   'https://chart.apis.google.com/chart?chst=d_map_pin_shadow',
              //   new window.google.maps.Size(40, 37),
              //   new window.google.maps.Point(0, 0),
              //   new window.google.maps.Point(12, 35)
              // )
            })
            bounds.extend(createdMarker.position);
            createdMarker.addListener('click', () => {
              this.$emit('markerClick', {
                marker: createdMarker,
                data: marker
              })
            })
            
          }
        })
        this.map.fitBounds(bounds);
      }
    },
    mounted: function () {
      this.createGoogleMaps()
      this.createGoogleMapsMarkers()
    },
    props: {
      id: {
        type: String,
        default: 'wi-google-maps'
      },
      markers: {
        type: Array,
        default: () => ([])
      },
      defaultMapMode: {
        type: String,
        default: 'roadmap'
      }
    }
  }
</script>
