import { Model } from '@/default/model/Model'

export class BusinessFinanceEdit extends Model {
  constructor() {
    super()
    this.icon = 'shopping_cart'
    this.redirectOnSubmit = true
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe os dados da parcela abaixo.'
      }),
      this.fieldDate({
        value: 'date_validity',
        text: 'Data de Vencimento',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'ls3']
      }),
      this.fieldMoney({
        value: 'amount_initial',
        text: 'Valor da parcela',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldText({
        value: 'description',
        text: 'Descrição da parcela',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      this.fieldSelectApi({
        value: 'finance_form_payment_id',
        text: 'Forma de Pagamento',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'finance/form-payment',
          params: { fields: 'id,name', order: 'name,asc' }
        },
        show: 'name'
      }),
      this.fieldSelectApi({
        value: 'finance_account_id',
        text: 'Conta Financeira',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        api: {
          url: 'finance/account',
          params: { fields: 'id,name', order: 'name,asc' }
        },
        show: 'name',
        disabled: form => { return form.disable_finance_account }
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações da parcela',
        placeholder: 'Ex: Parcela referente...'
      })
    ])
  }
}