
import { TalkMenu } from '@/modules/talk/config/Talk/TalkMenu'
import { TalkFlow } from '@/modules/talk/config/Talk/TalkFlow'
import { TalkCampaign } from '@/modules/talk/config/Talk/TalkCampaign'
import { TalkChannelPerson } from '@/modules/talk/config/Talk/TalkChannelPerson'
import { ImportTalkContacts } from '@/modules/talk/config/Talk/ImportTalkContacts'
import TalkOperatorTransfer from '@/modules/talk/config/Talk/TalkOperatorTransfer'
import { TalkCampaignChannel } from '@/modules/talk/config/Talk/TalkCampaignChannel'
import { TalkMessageTemplate } from '@/modules/talk/config/Talk/TalkMessageTemplate'
import { TalkChannelTemplate } from '@/modules/talk/config/Talk/TalkChannelTemplate'
import { TalkMessageTemplateCategory } from '@/modules/talk/config/Talk/TalkCategory'
import { TalkChannel, TalkChannelSelect } from '@/modules/talk/config/Talk/TalkChannel'
import {
  Talk,
  TalkOpened,
  TalkClosed,
  TalkCanceled
} from '@/modules/talk/config/Talk/Talk'
import {
  TalkContact,
  TalkContactGroup,
  TalkContactAll,
  TalkContactAllMultiple
} from '@/modules/talk/config/Talk/TalkContact'
import { TalkSchedule } from './Talk/TalkSchedule'
import { TalkCampaignExport } from './Talk/TalkCampaignExport'

export default {
  'talk': new Talk(),
  'talk-menu': new TalkMenu(),
  'talk-flow': new TalkFlow(),
  'talk-opened': new TalkOpened(),
  'talk-closed': new TalkClosed(),
  'talk-canceled': new TalkCanceled(),
  'talk-channel': new TalkChannel(),
  'talk-contact': new TalkContact(),
  'talk-campaign': new TalkCampaign(),
  'talk-schedule': new TalkSchedule(),
  'talk-group': new TalkContactGroup(),
  'talk-contact-all': new TalkContactAll(),
  'talk-operator-transfer': TalkOperatorTransfer,
  'talk-channel-person': new TalkChannelPerson(),
  'talk-channel-select': new TalkChannelSelect(),
  'import-talk-contacts': new ImportTalkContacts(),
  'talk-campaign-export': new TalkCampaignExport(),
  'talk-message-template': new TalkMessageTemplate(),
  'talk-campaign-channel': new TalkCampaignChannel(),
  'talk-channel-template': new TalkChannelTemplate(),
  'talk-contact-all-multiple': new TalkContactAllMultiple(),
  'talk-message-template-category': new TalkMessageTemplateCategory()
}
