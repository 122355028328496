var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('view-detail',{attrs:{"title":"Cadastro","apiUrl":_vm.apiUrl,"apiParams":'?with=phones;contacts;adresses;infos;users;users.profile;files;talk_contacts;talk_contacts.file',"store":_vm.store},scopedSlots:_vm._u([{key:"toolbarItensBefore",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
var loading = ref.loading;
return [_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': '93dd92fc'}),expression:"{'id': '93dd92fc'}"}],staticClass:"hidden-xs-only hidden-sm-only",attrs:{"small":"","outline":"","color":"white","disabled":loading},on:{"click":function($event){return _vm.$WiEditDialog({wiConfig: _vm.store + '-function', onSubmit: refresh, data: content})}}},[_c('v-icon',[_vm._v("recent_actors")]),_c('span',[_vm._v("Funções/Papeis")])],1),(_vm.portal && _vm.$route.name == 'customer' && content && content.access_area && content.email && content.uuid)?_c('v-btn',{staticClass:"hidden-xs-only hidden-sm-only",attrs:{"loading":_vm.portalLoading,"small":"","outline":"","color":"white","disabled":loading},on:{"click":function($event){return _vm.accessPortal(content)}}},[_c('v-icon',[_vm._v("launch")]),_c('span',[_c('span',[_vm._v("Acessar ")]),(_vm.$WiCheckPermission('85352c3c-1321-4cba-b009-d5c4dc909159'))?_c('span',[_vm._v(" área do paciente ")]):(_vm.$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088'))?_c('span',[_vm._v(" área do formando ")]):_c('span',[_vm._v(" área do cliente ")])])],1):_vm._e()]}},{key:"default",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
return [_c('v-container',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"elevation-17"},[_c('PersonInfo',{attrs:{"person":content,"refresh":refresh}})],1)],1)],1),(content && content.groups)?_c('PersonGroups',{attrs:{"person":content}}):_vm._e(),(content && content.statistics)?_c('PersonStatistics',{attrs:{"person":content}}):_vm._e(),_c('PersonTabs',{attrs:{"person":content,"refresh":refresh}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }