import {
  PERMISSION_STOCK_EXPORT_STOCK_PRODUCTS
} from '@/default/constants/permissions'

export default {
  id: PERMISSION_STOCK_EXPORT_STOCK_PRODUCTS,
  list: true,
  title: 'Exportar Produtos em Estoque',
  icon: 'move_to_inbox',
  apiUrl: 'stocks/product/export/to-xlsx',
  maxWidth: '400px',
  fields: [
    {
      text: 'Filtre por estoque.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'stock_id',
      text: 'Unidade de Estoque*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      edit: {
        insert: true
      },
      apiUrl: 'stock',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name'
    }
  ]
}
