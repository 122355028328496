<template>
  <view-detail title="Chamados Encerrados" apiUrl="service-desk/reports/tickets-list" :apiParams="params" :notGetID="true" :notShowToolbar="false">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn v-if="$route.query" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="$router.push({name: 'tickets-by-network-statistics'})" :disabled="dados.loading">
        <v-icon>close</v-icon>
        <span>Limpar Filtro</span>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <!-- eslint-disable -->
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px 20px 0px 10px;">
          <v-layout row wrap>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.start_date" type="datetime-local" label="Data Inicial"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.end_date" type="datetime-local" label="Data Final"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2 lg2 style="padding: 0px 10px 0px 20px;">
              <v-select v-model="filter.ticket_impact" :items="dados.content.information.impact" item-text="nome_impacto" item-value="id" label="Impacto" solo ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md2 lg2 style="padding: 0px 10px 0px 20px;">
              <v-btn :disabled="!filter.start_date && !filter.end_date" @click="setFilter()" style="width: 100% !important;">Filtrar</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md2 lg2 style="padding: 0px 10px 0px 20px;">
              <download-excel v-if="dados.content && dados.content.data && dados.content.data.length > 0" class="btn btn-default" :data="dados.content.data" :fields="makeXLSFields()" worksheet="Chamados Encerrados" name="Relatório.xls">
                <v-btn color="teal darken-1" style="color: white; width: 100% !important;">Exportar/Excel</v-btn>
              </download-excel>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="dados.content && dados.content.information && dados.content.information.filters" xs12 sm12 md12 lg12 style="text-align: center; padding: 0px 10px 0px 10px;">
          <span>Relatório emitido em <strong>{{dados.content.information.report_generate_date}}</strong></span><br>
          <span v-for="(filter, filterKey) in dados.content.information.filters">
            Filtrando por {{filter.name}}: <strong>{{filter.description}}</strong><br>
          </span>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px;">
          <div style="width: 100%; text-align: center; padding-bottom: 20px; font-size: 18px;">
            <span v-html="dados.content.information.message"></span>
          </div>
          <tickets-list-dialog :dialog="ticketsListDialog" url="service-desk/reports/tickets-by-network-list" :params="ticketsListDialogParams">
            <v-btn icon flat @click="ticketsListDialog = false"><v-icon>close</v-icon></v-btn>
          </tickets-list-dialog>
          <v-data-table hide-actions no-data-text="Nenhum resultado encontrado para este filtro." no-results-text="Nenhum resultado encontrado para este filtro." :pagination.sync="pagination" :headers="headers" :items="dados.content.data" class="elevation-1">
            <template v-slot:items="props">
              <tr>
                <td><strong style="font-size: 18px; text-align: center;">{{ props.item.id }}</strong></td>
                <td><span style="text-align: center;">{{ props.item.time_abertura }}</span></td>
                <td><span style="text-align: center;">{{ props.item.time_close }}</span></td>
                <td><span style="text-align: center;">{{ props.item.duration }}</span></td>
                <td><span style="text-align: center;">{{ props.item.duration_previst }}</span></td>
                <td>
                  <textarea v-if="props.item.desc_chamado" rows="2" cols="20" textarea v-model="props.item.desc_chamado" ></textarea>
                </td>
                <td>
                  <textarea v-if="props.item.last_anotation" rows="2" cols="20" textarea v-model="props.item.last_anotation" ></textarea>
                </td>
                <td><span style="text-align: center;">{{ props.item.region }}</span></td>
                <td><span style="text-align: center;">{{ props.item.cities }}</span></td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon class="mx-0" @click="$router.push({name: 'chamado', params: {id: props.item.id}})">
                        <v-icon color="blue">message</v-icon>
                      </v-btn>
                    </template>
                    <span>Detalhes do chamado</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import TicketsListDialog from '@/modules/Report/pages/Chamados/TicketsListDialog'
  export default {
    name: 'TicketsByNetworkStatistics',
    data () {
      return {
        ticketsListDialog: false,
        ticketsListDialogParams: '',
        tableMode: 'execution',
        table: [],
        headers: [
        { text: 'ID', value: 'id', align: 'center' },
        { text: 'Abertura', value: 'time_abertura', align: 'center' },
        { text: 'Encerramento', value: 'time_close', align: 'center' },
        { text: 'Duração', value: 'duration', align: 'center' },
        { text: 'Previsão', value: 'duration_previst', align: 'center' },
        { text: 'Anotação inicial', value: 'desc_chamado', align: 'center' },
        { text: 'Anotação final', value: 'last_anotation', align: 'center' },
        { text: 'Regional', value: 'region', align: 'center' },
        { text: 'Cidade', value: 'cities', align: 'center' },
        {}
        ],
        options: {
          chart: {
            id: 'Chamados Por Tipo de Ocorrência'
          },
          labels: []
        },
        series: [],
        pagination: {
          sortBy: 'ativa_categoria,num_chamados',
          rowsPerPage: -1,
          descending: true
        },
        params: '',
        filter: {
          start_date: null,
          end_date: null,
          network_id: null,
          ticket_category_id: null
        }
      }
    },
    methods: {
      openTicketsListDialog: function (networkId) {
        var route = this.$route
        var params = '?'
        if (route.query.ticket_impact) {
          params += 'ticket_impact=' + route.query.ticket_impact + '&'
        }
        if (route.query.start_date) {
          params += 'start_date=' + route.query.start_date + '&'
        }
        if (route.query.end_date) {
          params += 'end_date=' + route.query.end_date + '&'
        }
        if (route.query.ticket_category_id) {
          params += 'ticket_category_id=' + route.query.ticket_category_id + '&'
        }
        params += 'network_id=' + networkId
        this.ticketsListDialogParams = params
        this.ticketsListDialog = true
      },
      tableRowClick: function (row, check) {
        if (this.checkIfRowClick(row, check)) {
          this.filter.network_id = row.id
          this.setFilter()
        }
      },
      checkIfRowClick: function (row, check) {
        return row[check] > 0
      },
      getInformation: function (item) {
        var routeQuery = this.$route.query
        routeQuery['network_id'] = item.id
        this.$router.push({
          query: routeQuery
        })
      },
      getCategory: function (item) {
        var routeQuery = this.$route.query
        routeQuery['network_id'] = item.id
        this.$router.push({
          name: 'tickets-by-category-statistics',
          query: routeQuery
        })
      },
      setSeries: function (data) {
        var r = []
        var categories = []
        data.filter((item) => {
          r.push(item.tickets_count)
          categories.push(item.name)
        })
        this.options.labels = []
        this.options.labels = categories
        return r
      },
      setParams: function (route) {
        if (route.query) {
          this.params = '?'
          if (route.query.ticket_impact) {
            this.filter.ticket_impact = route.query.ticket_impact
            this.params += 'ticket_impact=' + route.query.ticket_impact + '&'
          } else {
            this.filter.ticket_impact = null
          }
          if (route.query.start_date) {
            this.filter.start_date = route.query.start_date
            this.params += 'start_date=' + route.query.start_date + '&'
          } else {
            this.filter.start_date = null
          }
          if (route.query.end_date) {
            this.filter.end_date = route.query.end_date
            this.params += 'end_date=' + route.query.end_date + '&'
          } else {
            this.filter.end_date = null
          }
          if (route.query.network_id) {
            this.filter.network_id = route.query.network_id
            this.params += 'network_id=' + route.query.network_id + '&'
          } else {
            this.filter.network_id = null
          }
          if (route.query.ticket_category_id) {
            this.filter.ticket_category_id = route.query.ticket_category_id
            this.params += 'ticket_category_id=' + route.query.ticket_category_id + '&'
          } else {
            this.filter.ticket_category_id = null
          }
        } else {
          this.params = ''
        }
      },
      setFilter: function () {
        var query = {}
        if (this.filter.ticket_impact) {
          query['ticket_impact'] = this.filter.ticket_impact
        }
        if (this.filter.start_date) {
          query['start_date'] = this.filter.start_date
        }
        if (this.filter.end_date) {
          query['end_date'] = this.filter.end_date
        }
        if (this.filter.network_id) {
          query['network_id'] = this.filter.network_id
        }
        if (this.filter.ticket_category_id) {
          query['ticket_category_id'] = this.filter.ticket_category_id
        }
        this.$router.push({query: query})
      },
      makeXLSFields: function () {
        var fields = {}
        this.headers.filter((item) => {
          if (item.text && item.value) {
            fields[item.text] = item.value
          }
        })
        return fields
      }
    },
    created: function () {
      this.setParams({query: this.$route.query})
    },
    watch: {
      '$route': 'setParams'
    },
    computed: {
      auth () {
        return this.$store.state.auth || {}
      }
    },
    components: {
      ViewDetail,
      TicketsListDialog
    }
  }
</script>
<style>
th {
  padding: 0 8px !important;
}
</style>