import { BusinessCategory } from '@/modules/business/config/BusinessCategory'

class SalesCategory extends BusinessCategory {
  constructor() {
    super()
    this.id = 'a02e979e'
  }
}

export class SaleCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Venda'
    this.apiUrl = 'sales/config/category/sale'
  }
}

export class BudgetCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Orçamento'
    this.apiUrl = 'sales/config/category/budget'
  }
}

export class ContractCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Contrato'
    this.apiUrl = 'sales/config/category/contract'
  }
}

export class GuaranteeCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Termo de Garantia'
    this.apiUrl = 'sales/config/category/guarantee'
  }
}
