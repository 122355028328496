import { BusinessSimple } from '@/modules/business/config/BusinessSimple'
import {
  PERMISSION_CONTRACT,
  PERMISSION_CONTRACT_CREATE,
  PERMISSION_CONTRACT_UPDATE,
  PERMISSION_CONTRACT_DELETE,
  PERMISSION_INTERNET_MODULE
} from '@/default/constants/permissions'
import {
  BUSINESS_INVOICE_TYPE_ID_RECURRENT,
} from '@/modules/business/constants/BusinessConstants'

export class Contract extends BusinessSimple {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_CONTRACT,
      create: PERMISSION_CONTRACT_CREATE,
      update: PERMISSION_CONTRACT_UPDATE,
      delete: PERMISSION_CONTRACT_DELETE
    }
    this.name = 'contract'
    this.title = {
      list: 'Contratos',
      edit: {
        insert: 'Novo Contrato',
        update: 'Editar Contrato'
      }
    }
    this.icon = 'border_color'
    this.apiUrl = 'saless/contract'
    this.urlDetails.name = this.permissionIsAvaiable(PERMISSION_INTERNET_MODULE) ? 'internet-contract' : 'contract'
    this.components.list.tableRow = () => import('@/modules/sales/pages/Contracts')
    const fieldsEdit = this.fieldsEdit
    this.fieldsEdit.dateStart.text = 'Início do contrato'
    this.fieldsEdit.dateEnd.text = 'Termino do contrato'
    this.fieldsEdit.subHeader1.text = 'Informe os dados básicos deste contrato.'
    this.fieldsEdit.status.apiUrl = 'sales/config/status/contract'
    this.fieldsEdit.category.apiUrl = 'sales/config/category/contract'
    this.fieldsEdit.externalCode = this.fieldDate({
      value: 'invoice_first_date',
      text: 'Data primeiro pagamento',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: form => form.invoice_type_id == BUSINESS_INVOICE_TYPE_ID_RECURRENT
    })
    this.clearFields()
    this.setFields(this.fieldsList)
    this.setFields(fieldsEdit)
  }
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    personName: this.fieldList({
      value: 'person.name',
      text: 'Nome do Cliente'
    }),
    status: this.fieldList({
      value: 'business_status_id',
      text: 'Status/Etapa',
      align: 'center'
    }),
    collaborator: this.fieldList({
      value: 'business_category_id',
      text: 'Categoria/Tipo',
      align: 'center'
    }),
    address: this.fieldList({
      value: 'address_id',
      text: 'Endereço',
      sortable: false
    }),
    external_code: this.fieldList({
      value: 'external_code',
      text: 'Código externo',
      sortable: true
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      sortable: false
    })
  }
}

export class ContractOpened extends Contract {
  constructor () {
    super()
    this.title.list = 'Contratos Em Análise'
    this.params.stage = 'open'
  }
}
export class ContractClosed extends Contract {
  constructor () {
    super()
    this.title.list = 'Contratos Vigentes'
    this.params.stage = 'close'
  }
}
export class ContractCanceled extends Contract {
  constructor () {
    super()
    this.title.list = 'Contratos Cancelados'
    this.params.stage = 'cancel'
  }
}
