<template>
  <div>
    <v-toolbar v-if="title" dense>
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small outline color="green" @click="$WiEditDialog({wiConfig: addEditModalStore, onSubmit: refresh, data: addEditModalParams})">
        <v-icon>add</v-icon>
        <span>{{addTextButton}}</span>
      </v-btn>
    </v-toolbar>
    <v-list two-line v-if="pessoas && pessoas.length > 0">
      <template v-for="(pessoa, indexPessoa) in pessoas">
        <v-list-tile avatar :key="indexPessoa">
          <v-list-tile-avatar>
            <v-avatar>
              <img v-if="pessoa.person.sex == 'Feminino'" src="static/icons/monitor/female-worker-96.png" alt="avatar">
              <img v-else src="static/icons/monitor/verificando_e_verificandoexpirado.png" alt="avatar">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title style="font-size: 16px;">{{pessoa.person.name}}</v-list-tile-title>
            <v-list-tile-sub-title>{{pessoa[listSubTitle]}}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text>{{ pessoa.created_at_format }}</v-list-tile-action-text>
            <db-delete v-if="addEditModalStore" v-prms="{'id': 'd4d1b9c7'}" v-on:action="refresh" :store="addEditModalStore" :id="pessoa.id"></db-delete>
          </v-list-tile-action>
          <v-list-tile-action v-if="slotAction">
            <slot name="action" :pessoa="pessoa"></slot>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="indexPessoa + '-divider'"></v-divider>
      </template>
    </v-list>
    <div style="text-align: center; padding: 20px;" v-else>
      <v-alert :value="true" type="warning" >
        <span v-if="clearMessage">{{clearMessage}}</span>
        <span v-else>Nenhuma informação encontrada.</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
  import DbDelete from '@/default/components/DBDelete'
  export default {
    name: 'Equipe',
    data () {
      return {
        editModal: false,
        modalForm: {},
        modalStore: 'pessoa'
      }
    },
    components: {
      DbDelete
    },
    props: ['pessoas', 'refresh', 'title', 'listSubTitle', 'addTextButton', 'addEditModalStore', 'addEditModalParams', 'slotAction', 'clearMessage']
  }
</script>