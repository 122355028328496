import MedicalRecord from '@/modules/clinic/pages/MedicalRecord/MedicalRecord'
import CalendarNew from '@/default/pages/Calendar/CalendarNew'
import Person from '@/modules/register/pages/Person/Person'

export default [
  {
    path: 'medical-record/:id',
    name: 'medical-record',
    component: MedicalRecord,
    meta: {
      id: '015b8d97',
      auth: true,
      title: 'Prontuário',
      icon: 'settings_accessibility'
    }
  },
  {
    path: 'patient/:id',
    name: 'patient',
    component: Person,
    meta: {
      id: '549b7548',
      auth: true,
      title: 'Detalhes do Paciente',
      icon: 'people'
    }
  },
  {
    path: 'clinic-calendar',
    name: 'clinic-calendar',
    component: CalendarNew,
    meta: {
      id: 'c4f8c247',
      auth: true,
      title: 'Agenda',
      icon: 'event'
    }
  }
]
