import { Model } from '@/default/model/Model'

export class ImportTalkContacts extends Model {
  constructor () {
    super()
    this.id = 'cdf8059b'
    this.actions = {
      create: true
    }
    this.title = 'Importar contatos'
    this.apiUrl = 'talks/contacts/import'
    this.icon = 'upload'
    this.maxWidth = '400px'
    this.setFields([
      this.fieldSubHeader({
        text: 'Faça upload do arquivo xlsx com os contatos.'
      }),
      this.fieldWiFileDialog({
        value: 'file_id',
        text: 'Arquivo XLSX',
        placeholder: 'Selecione a planilha',
        required: true,
        data: {
          is_public: false
        },
        filter: {
          is_public: false
        }
      })
    ])
  }
}

