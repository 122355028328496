<template>
	<div class="wi-loading">
		<slot v-if="!loading"></slot>
    <div v-else center class="wi-loading__loading" :style="{ 'min-height': `${minHeight} !important` }">
      <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
      <h1 v-if="message" v-html="message" class="mt-5"></h1>
      <h1 v-else>Carregando...</h1>
      <h3 v-if="subMessage" v-html="subMessage"></h3>
    </div>
	</div>
</template>

<script>
  export default {
    name: 'WiLoading',
    props: {
      loading: {
        type: Boolean,
        required: true
      },
      message: {
        default: null
      },
      subMessage: {
        default: null
      },
      minHeight: {
        default: '500px'
      }
    }
  }
</script>

<style scoped>
  .wi-loading__loading {
    width: 100%;
    text-align:center;
    /* min-height: 500px; */
    padding-top: 80px;
  }
</style>