<template>
  <view-detail
    index="contract"
    title="Detalhes do Contrato"
    apiUrl="saless/contract"
    apiParams="?with=events;finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;invoice_address;item.item.host_config;business:id,type,created_at;businesses:id,type,business_id,created_at;file"
    store="contract"
  >
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
    </template>
    <template slot-scope="{ content, refresh, loading }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessRelationships
              :business="content"
              :refresh="refresh"
              :loading="loading"
            ></BusinessRelationships>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" class="pr-2" xs12 sm12 md6 lg6 xl6 style="padding-bottom: 20px;">
            <BusinessAddress
              title="Endereço de Instalação"
              field="address_id"
              :business="content"
              :person="content.person || {}"
              :address="content.address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" class="pl-2" xs12 sm12 md6 lg6 xl6 style="padding-bottom: 20px;">
            <BusinessAddress
              title="Endereço de Cobrança"
              field="invoice_address_id"
              :business="content"
              :person="content.person || {}"
              :address="content.invoice_address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex v-if="$WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 style="padding-bottom: 20px;">
            <ContractInternetPlan
              :business="content"
              :plan="content.item"
              :person="content.person || {}"
              :diagnostic="true"
              @onSubmit="refresh"
            ></ContractInternetPlan>
          </v-flex>
          <v-flex v-if="(content.hosts && content.hosts.length > 0) || $WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 style="padding-bottom: 20px;">
            <BusinessHosts
              :business="content"
              :hosts="content.hosts"
              :diagnostic="true"
              @onSubmit="refresh"
            ></BusinessHosts>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
          <v-flex v-if="!$WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessItems
              :business="content"
              wiConfigId="sale"
              :onRefresh="refresh"
            ></BusinessItems>
          </v-flex>
          <v-flex v-if="!$WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessFinance
              :business="content"
              wiConfig="sale"
              :refresh="refresh"
            ></BusinessFinance>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import { PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import ContractInternetPlan from './ContractInternetPlan'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessAddress from '@/modules/business/pages/Business/BusinessAddress'
  import BusinessHosts from '@/modules/business/pages/Business/BusinessHosts'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  import BusinessItems from '@/modules/business/pages/Business/BusinessItems'
  import BusinessFinance from '@/modules/business/pages/Business/BusinessFinance'
  import BusinessRelationships from '@/modules/business/pages/Business/BusinessRelationships'
  export default {
    name: 'Contract',
    computed: {
      PERMISSION_INTERNET_MODULE () {
        return PERMISSION_INTERNET_MODULE
      }
    },
    methods: {
      setContractLabelsText: function () {
        this.labels.category.text = 'Categoria/Tipo de Contrato:'
        this.labels.dateStart.text = 'Início do Contrato:'
        this.labels.dateEnd.text = 'Término do Contrato:'
        this.labels.duration.text = 'Duração do Contrato:'
      }
    },
    created: function () {
      this.setContractLabelsText()
    },
    components: {
      BusinessInfo,
      BusinessForm,
      BusinessActions,
      BusinessAddress,
      BusinessHosts,
      BusinessItems,
      BusinessFinance,
      BusinessObservation,
      ContractInternetPlan,
      BusinessRelationships
    },
    mixins: [BusinessMixin]
  }
</script>
