export default {
  computed: {
    selectedActions () {
      return this.actions.filter(action => (this.actionIds.indexOf(action.id) > -1))
    }
  },
  methods: {
    executeAction: function (action) {
      switch (action.name) {
        case 'HGW_WIFI_ENABLE':
          this.executeActionPost(action)
          break
        case 'HGW_WIFI_DISABLE':
          this.executeActionPost(action)
          break
        case 'HGW_WIFI_SET_PASSWD':
          this.executeHGWWifiSetPasswd(action)
          break
        case 'HGW_WIFI_CONFIGURATION':
          this.executeHGWWifiConfiguration(action)
          break
        case 'GPON_ONT_RESET':
          this.executeActionPost(action)
          break
        default:
          break;
      }
    },
    executeActionPost: function (action) {
      this.$WiApiPost({
        uri: 'networks/test-management-vtal/event/action',
        data: action.content,
        config: {
          msgLoad: {
            title: `Executando ação (${action.title})...`,
            text: action.description
          },
          msgSucess: {
            title: 'Solicitação enviada com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja "${action.title}"?`,
            text: action.description
          }
        }
      })
    },
    executeHGWWifiSetPasswd: function (action) {
      this.$WiEditDialog({
        wiConfig: 'diagnostic-action-hgw-wifi-set-passwd',
        data: {
          ...action.content
        },
        redirectOnSubmit: false
      })
    },
    executeHGWWifiConfiguration: function (action) {
      this.$WiEditDialog({
        wiConfig: 'diagnostic-action-hgw-wifi-configuration',
        data: {
          ...action.content
        },
        redirectOnSubmit: false
      })
    }
  }
}
