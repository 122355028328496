export default [
  {
    id: '5dc282ab',
    icon: 'all_inbox',
    title: 'Todos os Chamados',
    route: { name: 'chamados', params: { page: 'todos' } }
  },
  {
    id: '5dc282ab',
    icon: 'view_week',
    title: 'Todos os Chamados Por Status',
    route: { name: 'chamados-kanban' }
  },
  // {
  //   id: '5dc282ab',
  //   icon: 'ballot',
  //   title: 'Meus Chamados',
  //   route: {name: 'chamados', params: { page: 'meus-chamados' }}
  // },
  // {
  //   id: '5dc282ab',
  //   icon: 'supervised_user_circle',
  //   title: 'Chamados da(s) Equipe(s)',
  //   route: { name: 'chamados', params: { page: 'equipe' } }
  // },
  {
    id: '5dc282ab',
    icon: 'done_outline',
    title: 'Chamados Encerrados',
    route: { name: 'chamados', params: { page: 'todos-encerrado' } }
  },
  {
    id: '5dc282ab',
    icon: 'close',
    title: 'Chamados Cancelados',
    route: { name: 'chamados', params: { page: 'todos-cancelado' } }
  }
]
