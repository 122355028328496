export default [
  {
    id: '74cc44a0',
    icon: 'event',
    title: 'Calendário de Ordens de Serviço',
    route: {
      name: 'orders-calendar'
    }
  },
  {
    id: '74cc44a0',
    icon: 'view_week',
    title: 'Processo de Ordens de Serviço',
    route: {
      name: 'process-of-service-desk',
      params: {
        business: 'order'
      }
    }
  },
  {
    id: '74cc44a0',
    icon: 'select_all',
    title: 'Ordens de Serviço Em Aberto',
    route: {
      name: 'service-desk-list',
      params: {
        page: 'order-open'
      }
    }
  },
  {
    id: '74cc44a0',
    icon: 'done_outline',
    title: 'Histórico de Ordens de Serviço',
    route: {
      name: 'service-desk-list',
      params: {
        page: 'order-close'
      }
    }
  }
  // {
  //   id: '74cc44a0',
  //   icon: 'close',
  //   title: 'Ordens de Serviço Canceladas',
  //   route: {
  //     name: 'service-desk-list',
  //     params: {
  //       page: 'order-cancel'
  //     }
  //   }
  // }
]