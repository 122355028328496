import { PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'
import router from '@/modules/internet/routes'
import config from '@/modules/internet/config'
import menu from '@/modules/internet/menu'

export default {
  id: PERMISSION_INTERNET_MODULE,
  name: 'internet',
  title: 'Provedor de Internet',
  description: 'Gestão de Provedores de Internet',
  icon: 'wnewer-technology-module-internet.png',
  iconSystem: 'wises-technology-system-sale.png',
  iconProfile: 'profile_service_desk.png',
  color: '#367da6',
  menu,
  router,
  config
}
