<template>
  <div>
    <div v-if="loadings.load">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <div v-if="type == 'queue'">
          <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.queue'"></h2>
        </div>
        <div v-if="type == 'my-talks'">
          <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.myTalks'"></h2>
        </div>
        <div v-if="type == 'others'">
          <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.others'"></h2>
        </div>
        <div v-if="type == 'groups'">
          <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.groups'"></h2>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="talks.length > 0" class="talks-list" :style="{height: pageHeight}" style="padding: 10px 0 !important; overflow-y: scroll;">
        <v-list dense three-line style="padding: 0;">
          <template v-for="(talk, talkKey) in talks">
            <v-divider v-if="talkKey" :key="talkKey" inset></v-divider>
            <v-list-tile
              v-ripple avatar 
              :key="talkKey + '-new'"
              :style="{background: getTalkStatusBackground(talk)}"
            >
              <v-list-tile-avatar @click="talkAction(talk)" style="cursor: pointer;">
                <v-badge overlap right bottom offset-x="22" offset-y="24" color="transparent">
                  <template v-slot:badge>
                    <img v-if="talk.channel.type.code == 'wpp'" src="static/icons/monitor/whatsapp.png">
                    <img v-if="talk.channel.type.code == 'instagram'" src="static/icons/monitor/instagram.png" style="width: 20px; height: 20px;">
                    <img v-if="talk.channel.type.code == 'facebook'" src="static/icons/monitor/facebook.png" style="border-radius: 5px; width: 18px; height: 18px;">
                    <img v-if="talk.channel.type.code == 'email'" src="static/icons/monitor/email.png" style="width: 20px; height: 20px;">
                    <img v-if="talk.channel.type.code == 'sms'" src="static/icons/monitor/sms.png" style="width: 20px; height: 20px;">
                  </template>
                  <v-avatar>
                    <v-img v-if="talk.contact && talk.contact.file"
                      :src="talk.contact.file.url"
                      lazy-src="static/icons/not-found.png"
                    ></v-img>
                    <v-img v-else-if="talk.contact.type == 1" src="static/icons/user_icon_1.png"/>
                    <v-img v-else src="static/icons/monitor/user-groups-96.png"/>
                  </v-avatar>
                </v-badge>
              </v-list-tile-avatar>

              <v-list-tile-content @click="talkAction(talk)" style="cursor: pointer;">
                <v-list-tile-title>
                  <!-- <img v-if="talk.channel.type.code == 'wpp'" src="static/icons/monitor/whatsapp.png" style="width: 10px;"> -->
                  <v-icon v-if="talk.contact.type == 2" small>groups</v-icon>
                  <strong v-if="talk.contact.person"> {{ talk.contact.person.name | stringlength(25) }} </strong>
                  <strong v-else> {{ talk.contact.name | stringlength(25) }} </strong>
                  <!-- <v-icon v-if="talk.muted" color="grey lighten-1" small>volume_off</v-icon> -->
                  <!-- {{ talk.contact.status_id }} -->
                  <v-tooltip v-if="talk.contact.checked == 3" bottom lazy>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on" color="red" small
                        style="font-size: 14px;"
                      >do_not_disturb_on</v-icon>
                    </template>
                    <span>
                      Contato inválido
                    </span>
                  </v-tooltip>
                </v-list-tile-title>
                <v-list-tile-title>
                  <!-- <span v-text="talk.protocol"></span> -->

                  <v-tooltip bottom lazy>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" small tile style="margin: 0 1px 0 0; border-radius: 5px; padding: 2px 4px; font-size: 11px;" :style="{background: talk.channel.color}" :color="talk.channel.color">
                        <span :style="{color: $WiColorContrast(talk.channel.color)}">
                          {{ talk.channel.name | stringlength(10) }}
                        </span>
                      </span>
                    </template>
                    <span>
                      <span v-t="'modules.talks.pages.talkView.talks.labels.channel'"></span>: 
                      <span> {{ talk.channel.name }}</span>
                    </span>
                  </v-tooltip>
                  <v-tooltip v-if="talk.team" bottom lazy>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" small tile style="margin: 0 0 0 1px; border-radius: 5px; padding: 2px 4px; font-size: 11px;" :style="{background: talk.team.color}" :color="talk.team.color">
                        <span :style="{color: $WiColorContrast(talk.team.color)}">
                          {{ talk.team.name | stringlength(10) }}
                        </span>
                      </span>
                    </template>
                    <span>
                      <span
                        v-t="'modules.talks.pages.talkView.talks.labels.team'"
                      ></span>: 
                      <span> {{ talk.team.name }}</span>
                    </span>
                  </v-tooltip>
                  <!-- <span v-if="talk.team" small tile style="margin: 0 0 0 1px; border-radius: 5px; padding: 3px 5px;" :style="{background: talk.team.color}" :color="talk.team.color">
                    <span :style="{color: $WiColorContrast(talk.team.color)}">
                      {{ talk.team.name | stringlength(10) }}
                    </span>
                  </span> -->
                  <!-- <strong :style="{color: talk.channel.type.color}">{{ talk.channel.name | stringlength(12) }}</strong> |  -->
                  <!-- <span v-text="talk.time_total"></span> -->
                  <!-- <v-icon v-if="talk.muted" color="grey lighten-1" small>volume_off</v-icon> -->
                </v-list-tile-title>
                <!-- <v-list-tile-title v-if="talk.team">
                  <span small tile style="margin: 0px 4px; border-radius: 5px; padding: 3px 5px;" :style="{background: talk.team.color}" :color="talk.team.color">
                    <span :style="{color: $WiColorContrast(talk.team.color)}">
                      {{ talk.team.name | stringlength(10) }}
                    </span>
                  </span>
                </v-list-tile-title> -->
                <v-list-tile-title>
                  <span v-if="talk.status == 2">
                    <span v-if="talk.operator && talk.operator.name">At: </span> 
                    <strong v-if="talk.operator && talk.operator.name">
                      {{ talk.operator.name | stringlength(22) }}
                    </strong>
                    <strong
                      v-else
                      style="color: rgb(223, 145, 0)"
                      v-t="'modules.talks.pages.talkView.talks.labels.awaiting'"
                    ></strong>
                  </span>
                  <strong
                    v-if="talk.status == 1"
                    style="color: rgb(1, 153, 190)"
                    v-t="'modules.talks.pages.talkView.talks.labels.chatbot'"
                  ></strong>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text>
                  <span>
                    {{ getExternalTimeFormated(talk.last_event_at) }}
                  </span>
                </v-list-tile-action-text>
                <span>
                  <span v-text="talk.time_total" style="color: grey; padding-right: 5px;"></span>
                  <strong
                    v-if="parseInt(talk.unseen_messages) && parseInt(talk.unseen_messages) != 0 && talk.id != $route.params.id"
                    v-text="talk.unseen_messages"
                    class="talks-unseen-messages-count elevation-1"
                  ></strong>
                </span>
                <span>
                  <v-tooltip v-if="talk.is_waiting_response" bottom lazy>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on" color="orange" small
                        style="font-size: 14px;"
                      >notification_important</v-icon>
                    </template>
                    <span>
                      Aguardando resposta
                    </span>
                  </v-tooltip>
                  <TalkMenu
                    :talk="talk"
                    color="grey lighten-1"
                    :small="true"
                    :refresh="getTalks"
                  ></TalkMenu>
                </span>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list>
        <div style="width: 100%; text-align: center; padding: 10px;">
          <v-btn @click="paginateTalks(type)" outline block :loading="loadings.paginate">
            <v-icon>refresh</v-icon>
            <span v-t="'modules.talks.pages.talkView.talks.labels.loadMore'"></span>
          </v-btn>
        </div>
      </div>
      <div v-else class="talks-list__not-message">
        <div class="talks-list__not-message-content">
          <img v-if="type == 'queue'" src="static/icons/talks-queue-clear.png" alt="">
          <img v-if="type == 'my-talks'" src="static/icons/talks-my-talks-clear-transparent.png" alt="">
          <img v-if="type == 'others'" src="static/background/whatsapp-not-found.png?url=temp1" style="max-width: 150px;" alt="">
          <img v-if="type == 'groups'" src="static/background/whatsapp-not-found.png?url=temp1" style="max-width: 150px;" alt="">
          <div v-if="type == 'queue'" class="talks-list__not-message-text" v-t="'modules.talks.pages.talkView.talks.messages.notFound.queue'">
          </div>
          <div v-if="type == 'my-talks'" class="talks-list__not-message-text" v-t="'modules.talks.pages.talkView.talks.messages.notFound.myTalks'">
          </div>
          <div v-if="type == 'others'" class="talks-list__not-message-text" v-t="'modules.talks.pages.talkView.talks.messages.notFound.others'">
          </div>
          <div v-if="type == 'groups'" class="talks-list__not-message-text" v-t="'modules.talks.pages.talkView.talks.messages.notFound.groups'">
          </div>
          <!-- <v-btn v-if="type != 'queue'" @click="newTalk()" outline color="primary">
            <v-icon>add</v-icon> 
            <span>Iniciar Novo Atendimento</span>
          </v-btn> -->
        </div>
      </div>
    </div>
    <v-dialog v-model="talkDialog" scrollable max-width="70%" persistent lazy :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card style="min-height: 350px; border-radius: 5px; border-radius: 5px;">
        <Talk
          v-if="talkDialog && currentTalkId"
          :setTalkId="currentTalkId"
          mode="dialog"
          @onClose="onCloseTalkDialog()"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import Talk from '@/modules/talk/pages/TalkView/Talk/Talk'
  import TalkMenu from '@/modules/talk/pages/TalkView/Talk/TalkMenu'
  export default {
    name: 'TalksList',
    data: () => ({
      pageHeight: innerHeight,
      talkDialog: false,
      currentTalkId: null
    }),
    computed: {
      ...mapState('Talks', ['loadings']),
      ...mapGetters('Talks', ['myTalks', 'queue', 'others', 'groups', 'byOperator']),
      talks () {
        let talks = []
        switch (this.type) {
          case 'my-talks':
            talks = this.myTalks
            break
          case 'queue':
            talks = this.queue
            break
          case 'others':
            talks = this.others
            break
          case 'groups':
            talks = this.groups
            break
          case 'by-operator':
            talks = this.byOperator
            break
        }
        return talks
      }
    },
    methods: {
      ...mapActions('Talks', ['paginateTalks', 'getTalks']),
      talkChangeNotification: function (talk) {
        if (parseInt(talk.id) !== this.$route.params.id && parseInt(talk.last_message_direction) === 1 && !talk.muted) {
          this.$makeSystemNotification(
            'Nova mensagem:',
            talk.contact.name,
            talk.contact.file ? talk.contact.file.url : 'static/icons/monitor/whatsapp.png'
          )
        }
      },
      talkAction: function (talk) {
        if (this.$route.name === 'talks' || this.$route.name === 'talk-messages' || this.$route.name === 'talk' || this.$route.name === 'talk-view' || this.$route.name === 'talk-view-messages' || this.$route.name === 'talk-view-messages-page') {
          this.openTalkPage(talk)
        } else {
          this.openTalkDialog(talk)
        }
      },
      openTalkPage: function (talk) {
        const breackPoint = this.$vuetify.breakpoint.name
        let routeName = 'talk-view-messages'
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          routeName = 'talk-view-messages-page'
        }
        this.$router.push({
          name: routeName,
          params: {id: talk.id},
          query: {previousTalkId: this.$route.params.id}
        })
      },
      openTalkDialog: function (talk) {
        this.currentTalkId = talk.id
        this.talkDialog = true
      },
      onCloseTalkDialog: function () {
        this.talkDialog = false
        this.currentTalkId = null
      },
      getTalkStatusBackground: function (talk) {
        var background = ''
        if (talk.is_waiting_response) {
          background = '#dfd2b2'
        }
        if (parseInt(talk.status) === 3) {
          background = '#b2dfdb'
        }
        if (parseInt(talk.status) === 4) {
          background = '#ffccbc'
        }
        return background
      },
      getExternalTimeFormated: function (time) {
        if (time) {
          const date = this.$moment(time)
          const today = this.$moment().startOf('day')
          const days = Math.round(this.$moment.duration(today - date).asDays())
          if (days > 1) {
            return this.$moment(time).format('DD/MM HH:mm')
          }
          return this.$moment(time).fromNow()
        }
        return 'Agora'
      },
      setInnerHeight: function (heigth) {
        this.pageHeight = ((heigth || innerHeight) - 145) + 'px'
      },
      updateView: function () {
        setInterval(() => {
          this.$forceUpdate()
        }, 78000)
      }
    },
    mounted: function () {
      window.addEventListener('resize', () => {
        this.setInnerHeight(innerHeight)
      }, true)
      this.setInnerHeight(innerHeight)
      this.updateView()
    },
    props: ['type'],
    components: {
      Talk,
      TalkMenu
    }
  }
</script>

<style>
  .talks-unseen-messages-count {
    padding: 0 5px;
    background: #009600;
    color: white;
    border-radius: 20px;
    margin-right: 5px;
  }
  .talks-list .v-list__tile {
    padding: 0 5px 0 5px !important;
  }
  .talks-list .v-list__tile__avatar {
    min-width: 50px !important;
  }
  .talks-list__not-message {
    align-items: center;
  }
  .talks-list__not-message-content {
    padding: 50px 20px;
    text-align: -webkit-center;
    font-size: 20px;
  }
  .talks-list__not-message img {
    width: 100%;
  }
  .talks-list__not-message-text {
    padding-bottom: 20px;
  }
  .talks__unseen-messages--unread {
    color: #009600 !important;
  }
</style>