import { Model } from '@/default/model/Model'

class GradePerson extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '4d8brfa0'
    this.name = 'grade'
    this.title = {
      list: 'Alunos/Clientes da Turmas',
      edit: {
        insert: 'Adicionar Aluno/Cliente à Turmas',
        update: 'Editar Aluno/Cliente da Turmas'
      }
    }
    this.urlDetails.name = 'customer'
    this.urlDetails.params = {id: 'person_id'}
    this.apiUrl = 'graduations/grade/person'
    this.apiParams = {with: 'person.phones;person.adresses'}
    this.icon = 'assignment_turned_in'
    this.search = {
      placeholder: 'Pesquise pelo nome, CPF ou E-mail',
      fields: ['person.name', 'person.first_name', 'person.cpf_cnpj', 'person.email']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl12']
    this.redirectOnSubmit = false
    this.components.list.tableRow = () => import('@/modules/graduations/pages/Graduations/GraduationPersons')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe todos os dados desta turma.'
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Aluno/Cliente',
        required: true,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'person.id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'person.name',
        text: 'Aluno/Cliente',
        align: 'center'
      }),
      this.fieldList({
        value: 'person.cpf_cnpj',
        text: 'CPF'
      }),
      // this.fieldList({
      //   value: 'person.email',
      //   text: 'E-mail'
      // }),
      this.fieldList({
        value: 'person.prones',
        text: 'Telefones',
        sortable: false
      }),
      this.fieldList({
        value: 'person.adresses',
        text: 'Endereços',
        sortable: false
      }),
      this.fieldList({
        value: 'person.status',
        text: 'Status'
      })
    ])
  }
}
export default new GradePerson()
