import { Model } from '@/default/model/Model'
import { PERMISSION_CONTRACT_UPDATE } from '@/default/constants/permissions'

export class ContractInternetPlan extends Model {
  constructor () {
    super()
    this.id = PERMISSION_CONTRACT_UPDATE
    this.actions = {
      update: true
    }
    this.title = 'Definir plano de navegação'
    this.icon = 'currency_exchange'
    this.apiUrl = 'internet/contract/plan'
    this.maxWidth = '588px'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    message: this.fieldMessage({
      text: 'Não é possível alterar o plano de navegação caso exista um ou mais boletos emitidos que permitam pagamento em uma data futura. Essa regra existe para evitar pagamentos duplicados.',
      showField: form => !!form.id
    }),
    subHeader1: this.fieldSubHeader({
      text: 'Informe o plano de navegação'
    }),
    planId: this.fieldWiListDialog({
      value: 'plan_id',
      text: 'Plano de navegação',
      required: true,
      wiConfig: 'internet-plan',
      show: 'item.name',
      icon: 'currency_exchange',
      callbackFields: [
        {
          get: 'amount_sale',
          set: 'amount_unit'
        }
      ],
      filter: {
        whereNotNull: 'host_config_id'
      }
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Se desejar, altere o valor ou aplique um desconto.',
      showField: form => !!form.plan_id
    }),
    amountUnit: this.fieldMoney({
      value: 'amount_unit',
      text: 'Valor Unitário',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true,
      showField: form => !!form.plan_id
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor Desconto',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: false,
      showField: form => !!form.plan_id
    })
  }
}