export default {
  id: {
    id: 'ca5fa4b4',
    create: '898df748',
    update: '758b9a0e',
    delete: '7a057f09'
  },
  list: true,
  title: {
    list: 'Equipes/Departamentos',
    edit: {
      create: 'Nova Equipe/Departamento',
      update: 'Atualizar Equipe/Departamento'
    }
  },
  icon: 'supervised_user_circle',
  apiUrl: 'register/team',
  urlDetails: {name: 'team', params: {}},
  returnUrl: {name: 'list', params: {page: 'team'}},
  fields: [
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      list: true,
      edit: false
    },
    {
      value: 'name',
      text: 'Nome Equipe/Departamento',
      type: 'text',
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'members_count',
      text: 'Membros',
      type: 'int',
      list: true,
      edit: false
    },
    // {value: 'pessoa_dados_id', text: 'Líder/Responsável', autocomplete: true, required: true, size: ['xs12', 'sm4', 'md4', 'lg4'], type: 'db-select', list: false, edit: true, apiUrl: 'pessoa/colaborador', apiParams: {fields: 'id,nome_pessoa'}, show: 'nome_pessoa'},
    {
      value: 'active',
      text: 'Status da Equipe/Departamento',
      type: 'select',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [
        {
          value: 'active',
          text: 'Ativo'
        }, {
          value: 'inactive',
          text: 'Inativo'
        }
      ],
      required: false,
      list: false,
      edit: true
    },
    {
      value: 'color',
      text: 'Cor da equipe',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'color',
      required: false,
      list: false,
      edit: true
    },
    {
      value: 'talk_use',
      text: 'Usar no Atendimento',
      required: false,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'switch',
      list: false,
      edit: true
    },
    // subheader
    {
      value: 'header1',
      text: 'Configure a mensagem de fora do horário de atendimento.',
      type: 'subheader',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      list: false,
      edit: true,
      showField: (form) => form.talk_use
    },
    {
      value: 'talk_away_message',
      text: 'Mensagem de fora do horário de atendimento',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'textarea',
      required: true,
      list: false,
      edit: true,
      showField: (form) => form.talk_use
    },
    // subheader
    {
      value: 'header2',
      text: 'Observações sobre a equipe/departamento.',
      type: 'subheader',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      list: false,
      edit: true
    },
    {
      value: 'description',
      text: 'Observações',
      type: 'textarea',
      required: false,
      list: false,
      edit: true
    }
  ]
}
