<template>
  <v-layout row wrap>
    <v-flex class="task__areas" xs12 sm12 md3 lg3 xl3>
      <v-card class="elevation-17 task__info--left">
        <v-list-tile class="task__info-tile" avatar>
          <v-list-tile-avatar class="task__info-avatar">
            <v-avatar>
              <img src="/static/icons/monitor/date_start_96.png">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Data/Horário</v-list-tile-sub-title>
            <v-list-tile-title>
              <strong class="task__info-text">
                {{task.date_formated}} {{task.time}}
              </strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-card>
    </v-flex>
    <v-flex class="task__areas" xs12 sm12 md3 lg3 xl3>
      <v-card class="elevation-17 task__info--left task__info--right">
        <v-list-tile class="task__info-tile" avatar>
          <v-list-tile-avatar class="task__info-avatar">
            <v-avatar>
              <v-icon :color="task.type_info.color" large>{{task.type_info.icon}}</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              <span v-if="$Auth.checkPermission('85352c3c-1321-4cba-b009-d5c4dc909159')">Procedimento</span>
              <span v-else>Tipo</span>
            </v-list-tile-sub-title>
            <v-list-tile-title>
              <strong class="task__info-text">
                {{task.type_info.title}}
              </strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-card>
    </v-flex>
    <v-flex class="task__areas" xs12 sm12 md3 lg3 xl3>
      <v-card class="elevation-17 task__info--left task__info--right">
        <v-list-tile class="task__info-tile" avatar>
          <v-list-tile-avatar class="task__info-avatar">
            <v-avatar>
              <v-icon :color="task.status_info.color" large>{{task.status_info.icon}}</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Status/Etapa</v-list-tile-sub-title>
            <v-list-tile-title>
              <strong class="task__info-text">
                {{task.status_info.title}}
              </strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-card>
    </v-flex>
    <v-flex class="task__areas" xs12 sm12 md3 lg3 xl3>
      <v-card class="elevation-17 task__info--right">
        <v-list-tile class="task__info-tile" avatar>
          <v-list-tile-avatar class="task__info-avatar">
            <v-avatar>
              <img src="/static/icons/monitor/note_96.png">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Descrição</v-list-tile-sub-title>
            <v-list-tile-title>
              <strong class="task__info-text">
                {{task.description}}
              </strong>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-card>
    </v-flex>
    <v-flex v-if="task.observation" class="task__areas" xs12 sm12 md12 lg12 xl12>
      <v-card class="elevation-17">
        <v-card-text>
          <p v-html="task.observation"></p>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex class="task__areas" xs12 sm12 md12 lg12 xl12>
      <v-card class="elevation-17">
        <PersonInfo :person="task.customer"></PersonInfo>
      </v-card>
    </v-flex>
    <v-flex v-if="task.customer && (task.customer.phones || task.customer.adresses)" class="task__areas" xs12 sm12 md12 lg12 xl12>
      <v-card class="elevation-17">
        <PersonContactAddress personName="customer" :person="task.customer" wi-config="customer" :refresh="refresh"></PersonContactAddress>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import PersonInfo from '@/modules/register/pages/Person/PersonInfo'
import PersonContactAddress from '@/modules/register/pages/Person/PersonTabs/PersonContactAddress'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    refresh: {
      type: Function,
      required: true
    }
  },
  components: {
    PersonInfo,
    PersonContactAddress
  }
}
</script>

<style scoped>
  .task__areas {
    margin-bottom: 20px;
  }
  .task__info-number {
    font-size: 18px;
  }
  .task__info-text {
    font-size: 14px;
  }
  .task__info--left {
    margin-right: 5px;
  }
  .task__info--right {
    margin-left: 5px;
  }
  .task__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .task__info-avatar {
    min-width: 50px !important;
  }
  .task__row--bottom {
    margin-bottom: 10px;
  }
  .task__row--top {
    margin-top: 10px;
  }
</style>