import FinanceFields from '@/modules/finance/config/Finance/Finance/FinanceFields'
import { FinanceFilters } from '@/modules/finance/config/Finance/Finance/FinanceFilters'

export default {
  id: 'b8330e5f',
  actions: {
    create: false,
    update: false,
    delete: false
  },
  list: true,
  title: {
    list: 'Extrato/Movimentação',
    edit: {
      insert: 'Nova Movimentação',
      update: 'Editar Movimentação'
    }
  },
  icon: 'receipt',
  apiUrl: 'finance',
  apiParams: {
    order: 'date_receive,desc',
    with: 'ticket'
  },
  params: {
    limit: 20
  },
  search: {
    placeholder: 'Pesquise ID, descrição, nota fiscal ou pessoa envolvida',
    fields: ['id', 'description', 'invoice', 'person.name']
  },
  fields: FinanceFields,
  showFilterDateToolbar: true,
  notLoadOnMounted: false,
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  components: {
    list: {
      header: () => import('@/modules/finance/pages/Finances/FinancesHeader'),
      tableRow: () => import('@/modules/finance/pages/Finances/Finances')
    }
  },
  filters: new FinanceFilters()
}
