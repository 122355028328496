<template>
  <div>
    <template v-for="(event, eventIndex) in eventsMap[eventDate]">
      <v-menu v-if="event.type" :key="eventIndex" v-model="event.open" full-width offset-x>
        <template v-slot:activator="{ on }">
          <v-card v-ripple class="my-event" :color="event.type.color" v-on="on">
            <v-icon small v-text="event.type.icon">edit</v-icon>
            <span v-html="event.description"></span>
          </v-card>
        </template>
        <WiCalendarEvent :event="event"></WiCalendarEvent>
      </v-menu>
    </template>
  </div>
</template>
<script>
  import WiCalendarEvent from '@/default/component/WiCalendar/WiCalendarEvent'
  export default {
    name: 'WiCalendarEvents',
    data: () => ({}),
    computed: {
      eventsMap () {
        const map = {}
        this.events.forEach(e => (map[e.date] = map[e.date] || []).push(e))
        return map
      },
      events () {
        return this.$store.state.WiCalendar.events
      }
    },
    methods: {},
    props: ['eventDate'],
    components: {
      WiCalendarEvent
    }
  }
</script>
<style scoped>
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }
  .calendar__options {
    padding-left: 10px;
  }
  .calendar__calendar {
    padding-right: 10px;
  }
</style>