<template>
  <view-detail title="Servidores" apiUrl="servidor/dados" apiParams="?limit=100&order=ativo_servidor,desc;nome_servidor,asc" notGetID="true">
    <template slot-scope="props"> 
      <v-container fluid grid-list-md style="padding-top: 8px;">
        <v-layout row wrap>
          <v-flex v-prms="{'id': 'e578c9c7'}" xs12 text-xs-center text-sm-center text-md-center text-ls-center>
            <v-btn color="green" dark @click="$WiEditDialog({wiConfig: 'servidores', onSubmit: props.refresh, data: {}})">Criar Servidor</v-btn>
            <dialog-instalar-servidor v-prms="{'id': '1f0fe15b'}"></dialog-instalar-servidor>
          </v-flex>
          <v-flex xs6 sm6 md3 lg2 text-xs-center  v-for="(servidor, servidorKey) in props.content.data" :key="servidorKey">
            <v-card v-if="servidor.ativo_servidor == 'ativo' && servidor.ultimo_log_seg > 300" color="red darken-4" style="background-image: url('static/background/background-gradiente-1.png')">
              <v-card-text class="pointer" style="padding: 5px !important;" @click="$router.push({name: 'network-server', params: {id: servidor.id}})">
                <v-icon :color="servidor.status ? 'white' : 'black'" x-large>cloud_download</v-icon><br>
                <strong style="color: white;">{{servidor.nome_servidor}}</strong>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{(servidor.count_devices || 'Sem ') + ' dispositivo(s)'}}</span>
                <v-divider></v-divider>
                <span v-if="servidor.ip_interacao" style="color: white; font-size: 10px;">
                  IP: {{ servidor.ip_interacao }}
                </span>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{servidor.ultimo_log || 'Sem Registros'}}</span>
              </v-card-text>
            </v-card>
            <v-card v-else-if="servidor.ativo_servidor == 'ativo' && servidor.ultimo_log_seg < 300 && servidor.ultimo_log_seg > 240" color="yellow accent-4" style="background-image: url('static/background/background-gradiente-1.png')">
              <v-card-text class="pointer" style="padding: 5px !important;" @click="$router.push({name: 'network-server', params: {id: servidor.id}})">
                <v-icon :color="servidor.status ? 'white' : 'black'" x-large>cloud_upload</v-icon><br>
                <strong style="color: white;">{{servidor.nome_servidor}}</strong>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{(servidor.count_devices || 'Sem ') + ' dispositivo(s)'}}</span>
                <v-divider></v-divider>
                <span v-if="servidor.ip_interacao" style="color: white; font-size: 10px;">
                  IP: {{ servidor.ip_interacao }}
                </span>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{servidor.ultimo_log || 'Sem Registros'}}</span>
              </v-card-text>
            </v-card>
            <v-card v-else-if="servidor.ativo_servidor == 'ativo' && servidor.ultimo_log_seg != null" color="green accent-4" style="background-image: url('static/background/background-gradiente-1.png')">
              <v-card-text class="pointer" style="padding: 5px !important;" @click="$router.push({name: 'network-server', params: {id: servidor.id}})">
                <v-icon :color="servidor.status ? 'white' : 'black'" x-large>cloud_done</v-icon><br>
                <strong style="color: white;">{{servidor.nome_servidor}}</strong>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{(servidor.count_devices || 'Sem ') + ' dispositivo(s)'}}</span>
                <v-divider></v-divider>
                <span v-if="servidor.ip_interacao" style="color: white; font-size: 10px;">
                  IP: {{ servidor.ip_interacao }}
                </span>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{servidor.ultimo_log || 'Sem Registros'}}</span>
              </v-card-text>
            </v-card>
            <v-card v-else color="grey lighten-1" style="background-image: url('static/background/background-gradiente-1.png')">
              <v-card-text class="pointer" style="padding: 5px !important;" @click="$router.push({name: 'network-server', params: {id: servidor.id}})">
                <v-icon :color="servidor.status ? 'white' : 'black'" x-large>cloud_off</v-icon><br>
                <strong style="color: white;">{{servidor.nome_servidor}}</strong>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{(servidor.count_devices || 'Sem ') + ' dispositivo(s)'}}</span>
                <v-divider></v-divider>
                <span v-if="servidor.ip_interacao" style="color: white; font-size: 10px;">
                  IP: {{ servidor.ip_interacao }}
                </span>
                <v-divider></v-divider>
                <span style="color: white; font-size: 10px;">{{servidor.ultimo_log || 'Sem Registros'}}</span>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import DialogInstalarServidor from '@/modules/network/pages/Servidor/DialogInstalarServidor'
  export default {
    components: {
      ViewDetail,
      DialogInstalarServidor
    }
  }
</script>
<style>
  .pointer {
    cursor: pointer !important;
  }
</style>