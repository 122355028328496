<template>
  <wi-view index="talk" title="Conversas/Chats" apiUrl="talk/talk" apiParams="?add=messages" :notShowToolbar="true" v-on:afterLoad="afterLoad">
    <template slot-scope="data">
      <div v-if="talk">
        <v-toolbar dense flat class="elevation-5 theme--light v-navigation-drawer" :color="!$wiDark ? 'primary' : 'dark'">
          <v-list class="app-bar__company-title" two dense flat style="padding-top: 0px;">
            <v-list-tile avatar flat class="app-bar__company-title-text">
              <v-list-tile-avatar>
                <v-avatar size="40">
                  <img v-if="talk.contact && talk.contact.file && talk.contact.file.url" :src="talk.contact.file.url">
                  <img v-else-if="talk.contact.type == 1" src="static/icons/user_icon_1.png">
                  <img v-else src="static/icons/monitor/user-groups-96.png">
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  <span v-if="talk.contact" style="color: white; font-size:20px;" v-text="talk.contact.name"></span>
                  <span v-else style="color: white; font-size:20px;">Nome não definido</span>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <span style="font-size: 12px; color: white;">
                    Útima interação: {{ talk.contact.last_event_at_formated }}
                  </span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-spacer></v-spacer>
          <v-menu v-if="talk.status < 3" right transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon ripple>
                <v-icon color="white">more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile @click="$WiApiPut({uri: 'talk/talk', id: talk.id, data: {muted: !talk.muted}})">
                <v-list-tile-avatar>
                  <v-icon v-if="talk.muted">volume_up</v-icon>
                  <v-icon v-else>volume_off</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>
                  <span v-if="talk.muted">Reativar</span>
                  <span v-else>Silenciar</span>
                    Notificações
                </v-list-tile-title>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile v-if="talk.status < 3" @click="$WiApiPut({uri: 'talk/talk', id: talk.id, data: {status: 3}})">
                <v-list-tile-avatar>
                  <v-icon color="green">done</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>Concluir Atendimento</v-list-tile-title>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile v-if="talk.status < 3" @click="$WiApiPut({uri: 'talk/talk', id: talk.id, data: {status: 4}})">
                <v-list-tile-avatar>
                  <v-icon color="red">close</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>Cancelar Atendimento</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-btn icon ripple @click="refreshView(data.refresh)" style="color: white;">
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-btn icon ripple @click="$router.push({name: 'talks'})" style="color: white;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-content style="padding:0 !important;">
          <v-container id="container" fluid :style="{'background-image': $wiDark ? 'url(\'static/background/background-whatsapp-dark.jpg?url=123\')' : 'url(\'static/background/background-whatsapp.jpg?url=123\')', height: (innerHeight - 145) + 'px'}" style="margin-bottom: 45px; padding:0 !important; overflow-y: scroll; background-repeat: repeat; transform rotate(180deg); direction rtl">
            <div style="width: 100%; text-align: center; padding-top: 20px;">
              <v-btn @click="paginateMessages(talk.messages)" :disabled="!messagePaginate.next">
                <v-icon>refresh</v-icon>
                <span>Carregar Mais</span>
              </v-btn>
            </div>
            <talk-messages v-if="talk.messages" :messages="talk.messages.data" :new-messages="messages" :paginate-messages="messagePaginate.messages" v-on:reply="setReplyMessage" v-on:share="shareMessage"></talk-messages>
            <div v-if="talk.status < 3">
              <v-footer v-if="replyMessage" absolute height="70" class="pa-3" style="margin-bottom: 45px !important;">
                <div class="talk-reply-message">
                  <span>{{replyMessage.text | stringlength(180)}}</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon ripple @click="replyMessage = null">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-footer>
              <v-footer v-if="!talk.operator_id" absolute height="65" class="pa-3">
                <div style="width: 100%; text-align: center;">
                  <span v-if="talk.status == 1 && (talk.contact && talk.contact.type == 1)">
                    <span v-text="talk.contact.name + ' '"></span> está no menu.Você pode interromper o menu e iniciar o atendimento
                  </span>
                  <span v-if="talk.status == 2 || (talk.contact && talk.contact.type == 2)">Está aguardando para conversar.</span>
                  <v-btn @click="$WiApiPost({uri: 'talk/talk/' + talk.id + '/operator', config: {msgAsk: true, msgLoad: true, msgSucess: true, msgError: true}})" color="primary">Iniciar Atendimento</v-btn>
                </div>
              </v-footer>
              <v-footer v-else-if="talk.operator_id == $wiUser.id || talk.responsible_id == $wiUser.id" absolute height="45" class="pa-3">
                <textarea v-model="message" class="talk-input" type="text" placeholder="Digite uma mensagem" @keydown="inputHandler" rows="1"></textarea>
                <v-btn :disabled="!message" icon ripple @click="prepareMessage(data.content, message)">
                  <v-icon>send</v-icon>
                </v-btn>
                <v-speed-dial v-model="buttonNewFile" direction="top" transition="slide-y-reverse-transition" :absolute="false">
                  <template v-slot:activator>
                    <v-btn icon ripple>
                      <v-icon v-if="!buttonNewFile">attachment</v-icon>
                      <v-icon v-else>close</v-icon>
                    </v-btn>
                  </template>
                  <!-- Ex: chat, image, video, audio, document, vcard, location -->
                  <v-btn fab dark small color="purple" @click="openFileDialog({fileType: 2})">
                    <v-icon x-large>insert_photo</v-icon>
                  </v-btn>
                  <v-btn fab dark small color="red" @click="openFileDialog({fileType: 3})">
                    <v-icon x-large>play_circle_filled</v-icon>
                  </v-btn>
                  <v-btn fab dark small color="blue" @click="openFileDialog({fileType: 5})">
                    <v-icon x-large>insert_drive_file</v-icon>
                  </v-btn>
                </v-speed-dial>
              </v-footer>
              <v-footer v-else absolute height="65" class="pa-3">
                <span>
                  Conversando com <span v-text="talk.operator.name"> - </span>
                  <v-btn @click="$WiApiPost({uri: 'talk/talk/' + talk.id + '/operator', config: {msgAsk: true, msgLoad: true, msgSucess: true, msgError: true}})" color="primary">Assumir Atendimento</v-btn>
                </span>
              </v-footer>
            </div>
            <v-dialog v-if="dialogNewFile" v-model="dialogNewFile" scrollable persistent max-width="75%" :fullscreen="$vuetify.breakpoint.xsOnly">
              <v-card>
                <v-toolbar dense>
                  <strong>Upload de Arquivo</strong>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="dialogNewFile = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text style="height: 300px; padding: 0;">
                  <arquivos :parceData="{talk_id: talk.id, talkFileType: talkFileType}"></arquivos>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-container>
        </v-content>
      </div>
    </template>
  </wi-view>
</template>
<script>
  import TalkMessages from '@/modules/talk/pages/Talk/TalkMessages'
  import Arquivos from '@/default/components/Arquivos/Arquivos'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'Talk',
    data () {
      return {
        test: null,
        message: '',
        buttonNewFile: false,
        dialogNewFile: false,
        talkFileType: 1,
        innerHeight: window.innerHeight,
        socket: {
          status: false
        },
        talk: null,
        messages: [],
        messagesTest: [],
        replyMessage: null,
        messagePaginate: {
          uri: 'talk/message',
          page: null,
          loading: false,
          messages: [],
          next: true
        }
      }
    },
    methods: {
      subscribeOnTalk: function (talkId, local) {
        console.log('Subscribe to ' + talkId, local)
        window.Echo.private(this.$Auth.account().id + '.talk.' + talkId)
        .listen('.change', (response) => {
          this.updateTalk(response)
        })
        .listen('.message.new', (response) => {
          console.log('message.new');
          this.messagesTest.push(response)
          if (response && response.from_me) {
            this.updateMessage(response)
          } else {
            this.makeMessageInView(response)
          }
        })
        .listen('.message.change', (response) => {
          console.log('message.change');
          this.updateMessage(response)
        })
      },
      unsubscribeOnTalk: function (talkId) {
        window.Echo.leave(this.$Auth.account().id + '.talk.' + talkId)
        console.log('Unsubscribe to ' + talkId)
      },
      talkUnseenMessages: function () {
        var previousTalkId = this.$route.query.previousTalkId
        if (previousTalkId) {
          callApi.post({
            uri: 'talk/talk-unseen-messages/' + previousTalkId,
            data: {}
          })
        }
      },
      prepareMessage: function (talk, messageText) {
        if (messageText && messageText !== '') {
          var message = this.createMessage(talk, messageText)
          this.makeMessageInView(message)
          this.sendMessage(message)
          this.clearMessageInput()
        }
      },
      createMessage: function (talk, messageText) {
        var message = {
          'talk_id': talk.id,
          'text': messageText,
          'text_html': messageText,
          'uuid': this.$UUID(),
          'from_me': true,
          'type': 1,
          'talk_message_id': this.replyMessage ? this.replyMessage.id : null,
          'message': this.replyMessage || null
        }
        return message
      },
      sendMessage: function (message) {
        this.$WiApiPost({uri: 'talk/message', data: message})
      },
      makeMessageInView: function (talkMessage) {
        console.log('makeMessageInView', talkMessage)
        this.messages.push(talkMessage)
        console.log(this.messages);
        this.$forceUpdate()
        this.scrollToEnd(0)
      },
      clearMessageInput: function () {
        this.message = ''
        this.replyMessage = null
      },
      updateMessage: function (talkMessageChanged) {
        console.log('updateMessage', talkMessageChanged)
        console.log('updateMessage', this.messages)
        var key = this.messages.findIndex(talk => talk.uuid == talkMessageChanged.uuid)
        // this.messages.filter((talk, talkKey) => {
        //   if (talk.uuid == talkMessageChanged.uuid) {
        //     key = talkKey
        //   }
        // })
        console.log(key);
        if (key != null && key > -1) {
          this.messages.splice(key, 1, talkMessageChanged)
        } else {
          this.makeMessageInView(talkMessageChanged)
        }
        this.$forceUpdate()
      },
      openFileDialog: function (config) {
        this.talkFileType = config.fileType
        this.dialogNewFile = true
      },
      scrollToEnd: function (timeout) {
        setTimeout(() => {
          var container = this.$el.querySelector('#container')
          container.scrollTop = container.scrollHeight
        }, timeout)
      },
      updateTalk: function (talk) {
        for (var talkIndex in this.talk) {
          if (talkIndex !== 'messages') {
            this.talk[talkIndex] = talk[talkIndex]
          }
        }
      },
      resetVariables: function () {
        console.log('resetVariables');
        this.messages = []
        this.messagePaginate.messages = []
        this.messagePaginate.page = null
        this.messagePaginate.next = true
      },
      talkChange: function () {
        this.resetVariables()
        this.unsubscribeOnTalk(this.$route.query.previousTalkId)
        this.subscribeOnTalk(this.$route.params.id, 'talkChange')
        this.talkUnseenMessages()
      },
      refreshView: function (refresh) {
        this.resetVariables()
        this.$forceUpdate()
        refresh()
      },
      inputHandler (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault()
          this.prepareMessage({id: this.$route.params.id}, this.message)
        }
      },
      setReplyMessage: function (message) {
        this.replyMessage = message
      },
      shareMessage: function (message) {
        this.$WiListDialog({
          wiConfig: 'talk-contact',
          mode: 'select',
          callback: (contacts) => {
            this.checkIfNumberOfSelectedContactsIsMoreThanFive(message, contacts)
          },
          params: {order: 'created_at,desc'},
          filter: {order: 'created_at,desc'}
        })
      },
      checkIfNumberOfSelectedContactsIsMoreThanFive: function (message, contacts) {
        if (Array.isArray(contacts) && contacts.length > 5) {
          this.$swal({
            title: 'Este canal não permite o envio para mais de 5 contatos.',
            text: 'Por favor, envie apenas para 5 contatos.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok, selecionar!',
            cancelButtonText: 'Cancelar'
          })
          .then((response) => {
            if (response.value) {
              this.shareMessage(message)
            }
          })
        } else {
          this.prepareShareMessages(message, contacts)
        }
      },
      prepareShareMessages: function (message, contacts) {
        if (message && contacts) {
          var toShared = {
            'talk_message_id': message.id,
            'contacts': contacts
          }
          if (!Array.isArray(contacts)) {
            toShared.contacts = [contacts]
          }
          this.sendShareMessages(toShared)
        }
      },
      sendShareMessages: function (toShared) {
        callApi.post({
          uri: 'talk/message/share',
          data: toShared,
          msgLoad: true,
          sucess: (response) => {
            console.log(response)
          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      afterLoad: function (data) {
        this.talk = data.content
        this.resetVariables()
        this.scrollToEnd(500)
        this.checkIfNextPaginateExist(this.talk.messages)
      },
      paginateMessages: function () {
        var page = 2
        if (this.messagePaginate.page) {
          page = this.messagePaginate.page + 1
        }
        this.messagePaginate.loading = true
        callApi.get({
          uri: this.messagePaginate.uri,
          params: {page: page, order: 'id,desc', where: 'conversation_id,' + this.talk.talk_contact_id, limit: 10},
          sucess: (response) => {
            if (response && response.data && response.data.data && response.data.data.length > 0) {
              this.messagePaginate.page = response.data.current_page
              this.messagePaginate.messages = this.messagePaginate.messages.concat(response.data.data)
              this.checkIfNextPaginateExist(response.data)
            }
            this.messagePaginate.loading = false
          },
          error: (error) => {
            this.$WiMakeError({error: error, id: 103, title: 'Erro ao buscar informações.'})
            this.messagePaginate.loading = false
          },
          msgLoad: true,
          msgSuccess: true
        })
      },
      checkIfNextPaginateExist: function (config) {
        if (config) {
          this.messagePaginate.next = config.next_page_url || false
        }
      }
    },
    mounted: function () {
      this.subscribeOnTalk(this.$route.params.id, 'mounted')
    },
    beforeDestroy () {
      this.talkUnseenMessages()
      this.unsubscribeOnTalk(this.$route.params.id)
    },
    watch: {
      '$route': 'talkChange'
    },
    components: {
      Arquivos,
      TalkMessages
    }
  }
</script>
<style scoped>
  .talk-input {
    min-height: 40px !important;
    padding: 0;
    background: white;
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
  }
  .talk-reply-message {
    padding: 5px;
    background: white;
    border-radius: 5px;
  }
</style>
