export function getWiDialogs(context) {
  return {
    files: (business) => ({
      title: 'Arquivos/Anexos',
      icon: 'attachment',
      component: {
        content: () => import('@/default/component/WiFile/WiFileList'),
        props: {
          index: context.config.store + '-fileList',
          api: {
            url: context.config.module + '/' + context.config.store + '/file',
            params: '?order=id,desc&where=business_id,' + business.id
          }
        }
      },
      button: {
        icon: 'add',
        text: 'Adicionar Arquivo',
        action: () => {
          context.$WiDialog({
            title: 'Arquivos/Anexos ',
            icon: 'attachment',
            component: {
              content: () => import('@/default/component/WiFile/WiFile'),
              props: {
                business_id: business.id
              }
            },
            onClose: () => {
              context.$WiView(context.config.store + '-fileList').refresh()
            }
          })
        }
      }
    }),

    events: (business) => ({
      title: 'Anotações/Eventos ',
      icon: 'message',
      component: {
        content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
        props: {
          index: context.config.store + '-event',
          api: {
            url: context.config.module + '/' + context.config.store + '/event',
            params: '?order=id,desc&where=business_id,' + business.id
          }
        }
      },
      button: {
        icon: 'add',
        text: 'Adicionar Nota',
        action: () => {
          context.$WiEditDialog({
            wiConfig: context.config.store + '-event',
            onSubmit: () => {
              context.$WiView(context.config.store + '-event').refresh()
            },
            data: {business_id: business.id}
          })
        }
      }
    }),

    finances: (business, refresh) => ({
      title: 'Financeiro/Parcelas ',
      icon: 'message',
      component: {
        content: () => import('@/modules/sales/pages/Business/Finance/BusinessFinance'),
        props: {
          config: context.config,
          data: business,
          refresh: refresh
        }
      }
    })
  }
} 