<template>
  <div class="wi-edit__wi-list-dialog">
    <v-list dense two-line>
      <v-list-tile
        :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
        avatar class="wi-edit__wi-list-dialog-item asdasdasd"
        :class="{'wi-edit__wi-list-dialog-item__selected': form[field.value]}"
      >
        <v-list-tile-avatar @click="WiListDialogAction(field)" class="wi-edit__wi-list-dialog-item-avatar">
          <v-icon large :color="field.icon_color || 'primary'">{{field.icon || 'category'}}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content @click="WiListDialogAction(field)">
          <v-list-tile-sub-title>
            <span>
              {{typeof field.text == 'function' ? field.text(form) : field.text}}
            </span>
          </v-list-tile-sub-title>
          <v-list-tile-title>
            <strong v-if="form[field.value]">
              {{ wiListDialogCalbackShowField(field) | stringlength(32) }}
            </strong>
            <strong v-else>
              {{ field.placeholder || 'Clique para selecionar' }}
            </strong>
          </v-list-tile-title>
          <v-input
            style="display: none;"
            v-model="form[field.value]"
            :rules="isRequired(field) ? validField(field) : []"
            :required="isRequired(field)"
          ></v-input>
        </v-list-tile-content>
        <v-list-tile-action 
          @click="WiListDialogAction(field)"
          class="wi-edit__wi-list-dialog-item-action"
          style="padding-top: 0 !important; padding-bottom: 0 !important;"
        >
          <v-icon>{{form[field.value] ? 'edit' : 'search'}}</v-icon>
          <!-- <v-icon v-if="form[field.value]">close</v-icon> -->
        </v-list-tile-action>
        <v-list-tile-action
          v-if="form[field.value]"
          @click="clearField()"
          class="wi-edit__wi-list-dialog-item-clear"
          style="padding-top: 0 !important; padding-bottom: 0 !important;"
        >
          <v-icon>close</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </div>
</template>
<script>
  import WiEditFieldsMixin from '@/default/component/WiEdit/Fields/WiEditFieldsMixin'
  export default {
    name: 'WiEditWiListDialog',
    props: ['form', 'field'],
    methods: {
      getFilter: function () {
        if (this.field?.filter) {
          if (typeof this.field.filter === 'function') {
            const filter = this.field.filter(this.form)
            return filter
          }
          return this.field.filter
        }
        return false
      },
      getWiEditData: function () {
        if (this.field?.wiEditData) {
          if (typeof this.field.wiEditData === 'function') {
            const wiEditData = this.field.wiEditData(this.form)
            return wiEditData
          }
          return this.field.wiEditData
        }
        return false
      },
      wiListDialogCallback: function (selected) {
        if (selected) {
          this.$emit('select', selected)
          this.$forceUpdate()
          this.$nextTick()
          if (this.field.onChange) {
            this.field.onChange({form: this.form, data: selected})
          }
          this.$forceUpdate()
          this.$nextTick()
        }
      },
      wiListDialogCalbackFields: function (field) {
        let fields = [
          {get: 'id', set: field.value},
          {get: field.show, set: field.show}
        ]
        if (field.callbackFields) {
          fields = fields.concat(field.callbackFields)
        }
        this.$forceUpdate()
        return fields
      },
      wiListDialogCalbackShowField: function (field) {
        if (field.show && field.show.split('.').length > 1) {
          var show = field.show.split('.')
          return this.form && this.form[show[0]] ? this.form[show[0]][show[1]] : 'Clique para selecionar'
        } else {
          return this.form[field.show] || 'Clique para selecionar'
        }
      },
      WiListDialogAction: function (field) {
        this.$WiListDialog({
          wiConfig: field.wiConfig,
          callback: this.wiListDialogCallback,
          callbackFields: this.wiListDialogCalbackFields(field),
          filter: this.getFilter() || {}, 
          mode: 'select',
          wiEditData: this.getWiEditData() || {}
        })
      },
      clearField: function () {
        const fieldClear = {}
        fieldClear[this.field.value] = null
        this.wiListDialogCallback(fieldClear)
      }
    },
    mixins: [WiEditFieldsMixin]
  }
</script>

<style scoped>
  .asdasdasd >>> div {
    height: 45px !important;
  }
  .wi-edit__wi-list-dialog-item-avatar {
    min-width: 43px !important;
  }
  .wi-edit__wi-list-dialog-item >>> .v-list__tile {
    padding: 5px !important;
  }
  .wi-edit__wi-list-dialog-item-action {
    min-width: 25px !important;
  }
  .wi-edit__wi-list-dialog-item-clear {
    min-width: 25px !important;
  }
  .wi-edit__wi-list-dialog {
    max-width: 100%;
    padding-bottom: 12px;
  }
  .wi-edit__wi-list-dialog-item {
    border-style: dotted !important;
    border-color: gray !important;
    border-width: 1px !important;
    border-radius: 5px;
    height: 45px !important;
    cursor: pointer;
  }
  .wi-edit__wi-list-dialog-item__selected {
    border-style: solid !important;
  }
</style>
