<template>
  <div v-if="status && status.code != 'closed' && status.code != 'canceled'">
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn :disabled="!isValid" block outline color="green" @click="sendProcessStage()">
        <span> Próximo </span>
        <v-icon>send</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
  import StageMixin from './StageMixin'
  export default {
    name: 'StageFooter',
    props: {
      isValid: {
        type: Boolean,
        default: false
      },
      data: {
        required: true
      },
      execution: {
        type: Object,
        required: true
      },
      stage: {
        type: Object,
        required: true
      },
      status: {
        type: Object,
        default: () => ({})
      }
    },
    mixins: [
      StageMixin
    ]
  }
</script>
