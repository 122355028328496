import { Model } from '@/default/model/Model'
import { LogFilters } from './LogFilters'

export class Log extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1d91353e'
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: false
    }
    this.name = 'log'
    this.title = 'Logs'
    this.apiUrl = 'system/log'
    this.icon = 'search'
    this.search = {
      placeholder: 'Pesquise pelo ID do item ou IP',
      fields: ['data_id']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/default/pages/Common/Logs/Logs')
    this.filters = new LogFilters()
    this.listFields = [
      this.fieldList({
        value: 'id',
        text: 'ID',
        align: 'left'
      }),
      this.fieldList({
        value: 'type_id',
        text: 'Tipo'
      }),
      this.fieldList({
        value: 'data_id',
        text: 'ID do Item'
      }),
      this.fieldList({
        value: 'action_id',
        text: 'Ação'
      }),
      this.fieldList({
        value: 'origin_id',
        text: 'Origem'
      }),
      this.fieldList({
        value: 'user_name',
        text: 'Usuário'
      }),
      this.fieldList({
        value: 'ip_address',
        text: 'IP Address'
      }),
      this.fieldList({
        value: 'created_at',
        text: 'Data/Hora'
      }),
    ]
    this.setFields(this.listFields)
  }
}
