export default {
  'system.version': 'Versão',
  'system.currency.name': 'Euro',
  'system.currency.simbol': '€',
  'register.config.person.fields.list.cpf_cnpj.text': 'NIF',
  'register.config.person.fields.edit.subheader1.text': ({name}) => { return 'Informações básicas deste(a) ' + name + '.' },
  'register.config.person.fields.edit.subheader2.text': ({name}) => { return 'Primeiro, selecione se este(a) ' + name + ' é pessoa Singular ou Coletiva' },
  'register.config.person.fields.edit.type.text': 'Pessoa Singular ou Coletiva',
  'register.config.person.fields.edit.type.placeholder': 'Pessoa Singular ou Coletiva.',
  'register.config.person.fields.edit.type.option1': 'Pessoa Singular',
  'register.config.person.fields.edit.type.option2': 'Pessoa Coletiva',
  'register.config.person.fields.edit.cpf_cnpj1.text': 'NIF',
  'register.config.person.fields.edit.cpf_cnpj1.placeholder': '### ### ###',
  'register.config.person.fields.edit.cpf_cnpj1.mask': '### ### ###',
  'register.config.person.fields.edit.rg.text': 'Cartão Cidadão',
  'register.config.person.fields.edit.rg.placeholder': 'Insira o Cartão Cidadão',
  'register.config.person.fields.edit.cpf_cnpj2.text': 'NIF',
  'register.config.person.fields.edit.cpf_cnpj2.placeholder': '### ### ###',
  'register.config.person.fields.edit.cpf_cnpj2.mask': '### ### ###',
  'register.config.person.cell.title.edit.create': 'Novo Telemóvel',
  'register.config.person.cell.title.edit.update': 'Editar Telemóvel',
  'register.config.person.cell.fields.contact.mask': '### ### ###',
  'register.config.person.phone.fields.contact.mask': '### ### ###',
  'register.config.person.address.title.edit.create': 'Nova Morada',
  'register.config.person.address.title.edit.update': 'Editar Morada',
  'register.config.person.address.fields.zip_code.text': 'Código Postal',
  'register.config.person.address.fields.zip_code.mask': '####-###',
  'register.config.person.address.fields.district.text': 'Freguesia',
  'register.config.person.address.fields.city_id.text': 'Cidade',
  'register.page.PersonDetail.cpf': 'NIF',
  'register.page.PersonDetail.rg': 'Cartão Cidadão',
  'register.page.PersonDetail.cnpj': 'NIF',
  'register.page.PersonContactAddress.btn1': 'Telemóvel',
  'register.page.PersonContactAddress.addressTitle': 'Moradas',
  'register.page.PersonContactAddress.btn2': 'Nova Morada',
  'register.page.Person.tab1': 'Telefones/Moradas'
}
