import { Model } from '@/default/model/Model'
import {
  PERMISSION_PURCHASES_IMPORT_PURCHASE_TO_XML
} from '@/default/constants/permissions'

export class ImportPurchaseXML extends Model {
  constructor () {
    super()
    this.id = PERMISSION_PURCHASES_IMPORT_PURCHASE_TO_XML
    this.list = true
    this.title = 'Importar Compra em XML'
    this.icon = 'upload'
    this.apiUrl = 'purchases/purchase/import-by-xml'
    this.maxWidth = '420px'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = [
    this.fieldSubHeader({
      text: 'Faça upload do arquivo XML da compra.',
      style: { color: 'black' }
    }),
    this.fieldWiFileDialog({
      value: 'file_id',
      text: 'Arquivo XML',
      placeholder: 'Selecione a compra em XML',
      required: true,
      data: {
        is_public: false
      },
      filter: {
        is_public: false
      }
    })
  ]
}
