import { Model } from '@/default/model/Model'

export class ProducerModel extends Model {
  constructor () {
    super()
    this.list = true
    this.actions = {
      list: true,
      edit: false
    }
    this.title = 'Modelos de Equipamento'
    this.apiUrl = 'network/host/producer/model'
    this.params = {
      limit: 100
    }
    this.icon = 'router'
    this.components.list.tableRow = () => import('@/modules/network/pages/Producers/ProducerModels')
    this.setFields(this.fieldsList)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID',
      sortable: false
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome',
      sortable: false
    }),
    image: this.fieldList({
      value: 'image',
      text: 'Imagem',
      sortable: false
    })
  }
}
