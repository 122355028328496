<template>
  <v-dialog
    v-model="emojiSelectorDialog"
    max-width="350"
    :close-on-content-click="false"
    :nudge-width="500"
    :offset-x="100"
    lazy
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon small ripple
        v-on="on"
        style="margin: 3px 8px;"
        @click="emojiSelectorDialog = false"
      >
        <v-icon>mood</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title primary-title style="padding: 0 15px;">
        <v-icon>edit</v-icon>
        <strong class="ml-2" v-t="'components.emojiPicker.title'"></strong>
        <v-spacer></v-spacer>
        <v-btn icon @click="() => { emojiSelectorDialog = false }">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="emojiSelectorDialog">
        <EmojiMenu @onSelect="onEmojiSelected" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import EmojiMenu from './EmojiMenu'
  export default {
    name: 'EmojiMenuDialog',
    data: () => ({
      emojiSelectorDialog: false
    }),
    methods: {
      onEmojiSelected (emoji) {
        this.$emit('onSelect', emoji)
      },
    },
    components: {
      EmojiMenu
    }
  }
</script>