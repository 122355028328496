import { Auth } from '@/default/service/Auth'
import { callApi } from '@/default/service/Api'
import { Notifications } from '@/default/service/Notifications'

export default {
  namespaced: true,
  state: {
    chats: [],
    page: 1,
    loadings: {
      load: false,
      paginate: false
    }
  },
  getters: {
    myChats (state) {
      return state.chats
    },
    unreadsChats (state, getters) {
      return getters.myChats.filter(chat => chat.unread_messages > 0).length
    }
  },
  mutations: {
    SET_CHATS (state, chats) {
      state.chats = chats
      state.page = 1
    },
    ADD_CHAT (state, chat) {
      state.chats.unshift(chat)
    },
    ADD_CHAT_TO_END (state, chat) {
      state.chats.push(chat)
    },
    UPDATE_CHAT (state, {key, chat}) {
      state.chats[key].unseen_messages = chat.unseen_messages
      if (state.chats[key].chat?.last_message_at !== chat?.chat?.last_message_at) {
        state.chats.splice(key, 1)
        state.chats.unshift(chat)
      } else {
        state.chats.splice(key, 1, chat)
      }
    },
    INCREMENT_PAGE (state) {
      state.page = state.page + 1
    },
    TOUGGLE_LOADING (state, loading) {
      state.loadings[loading] = !state.loadings[loading]
    }
  },
  actions: {
    setChat ({ state, commit, dispatch }, { action, chat }) {
      const key = state.chats.findIndex(t => (t.chat.uuid == chat.chat.uuid))
      if (key > -1) {
        dispatch('checkNotificationOnUpdateChat', {key: key, chat: chat})
        commit('UPDATE_CHAT', { key: key, chat: chat })
      } else {
        if (action === 'end') {
          commit('ADD_CHAT_TO_END', chat)
        } else {
          commit('ADD_CHAT', chat)
          dispatch('checkNotification', chat)
        }
      }
    },
    getChats ({ commit }) {
      commit('TOUGGLE_LOADING', 'load')
      callApi.get({
        uri: 'chats/chat-start',
        params: {
          order: 'last_message_at,desc',
          limit: 20
        },
        sucess: ({ data }) => {
          if (data.chats && data.chats.length > 0) {
            commit('SET_CHATS', data.chats)
          } else {
            commit('SET_CHATS', [])
          }
          commit('TOUGGLE_LOADING', 'load')
        },
        error: () => {
          commit('TOUGGLE_LOADING', 'load')
        }
      })
    },
    subscribeOnChats () {
      if (Auth.checkPermission('8f5150h9-fec4-45f0-ba83-ef2d904f954b')) {
        // window.Echo.private(Auth.account().id + '.talks')
        // .listen('.new', (chat) => {
        //   dispatch('setChat', { chat: chat })
        // })
        // .listen('.change', (chat) => {
        //   dispatch('setChat', { chat: chat })
        // })
      }
    },
    paginateChats ({ state, commit, dispatch }) {
      commit('TOUGGLE_LOADING', 'paginate')
      callApi.get({
        uri: 'chats/chat-person',
        params: {
          order: 'last_message_at,desc',
          limit: 20,
          page: state.page,
        },
        sucess: response => {
          response.data.data.map(chat => {
            dispatch('setChat', { action: 'end', chat: chat })
          })
          commit('INCREMENT_PAGE')
          commit('TOUGGLE_LOADING', 'paginate')
        },
        error: () => {
          commit('TOUGGLE_LOADING', 'paginate')
        }
      })
    },
    checkNotification ({ dispatch }, chat) {
      if (
        chat?.chat?.last_message &&
        (
          chat?.chat?.last_message?.person_id != Auth.user().id
        )
      ) {
        if (
          Auth.checkPermission('8f5150h9-fec4-45f0-ba83-ef2d904f954b')
        ) {
          dispatch('generateNotification', chat)
        }
      }
    },
    checkNotificationOnUpdateChat ({ state, dispatch, rootState }, { chat, key }) {
      if (
        (chat.chat.uuid != rootState.Chat.chat.uuid || !rootState.Chat.isOpen) &&
        state.chats[key].chat.last_message_at !== chat.chat.last_message_at
      ) {
        dispatch('checkNotification', chat)
      }
    },
    generateNotification (root, chat) {
      let notificationType = "chat.message"
      let chatName = chat?.person?.name
      let defaultImage = '/static/icons/user_icon_1.png'
      if (chat.chat.type_id === 2) {
        chatName = `${chat?.chat?.name}`
        notificationType = "chat.group.message"
        defaultImage = '/static/icons/group_icon_1.jpg'
      }
      let notification = {
        "id": chat.chat.uuid,
        "data":{
          "chat_id": chat.chat.uuid,
          "creator": {
            ...chat.person,
            name: `Chat: ${chatName}`,
            image: chat.chat.image ? chat.chat.image.url : defaultImage
          },
          "message": {
            ...chat.chat.last_message
          },
          "type": notificationType
        },
        "read_at": null,
        "created_at": chat.chat.last_message_at
      }
      // console.log(notification, 'notification')
      notification = Notifications.format(notification)
      Notifications.desktopNotification(notification)
    }
  }
}