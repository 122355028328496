import { Model } from '@/default/model/Model'

export class DeviceEvent extends Model {
  constructor () {
    super()
    this.list = true
    this.id = 'cb59faf8'
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: false
    }
    this.title = 'Indisponibilidades'
    this.apiUrl = 'networks/device/event'
    this.params = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'event'
    this.search = {
      placeholder: 'Pesquise pela descrição do evento.',
      fields: ['id', 'desc_indi']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/network/pages/Devices/DeviceEvents')
    // this.maxWidth = '800px'
    this.setFields(this.fieldsList)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    status: this.fieldList({
      value: 'status_indi',
      text: 'status',
      align: 'center'
    }),
    desc: this.fieldList({
      value: 'desc_indi',
      text: 'Descrição',
      sortable: false
    }),
    duration: this.fieldList({
      value: 'duracao_indi',
      text: 'Duração'
    }),
    down_indi: this.fieldList({
      value: 'down_indi',
      text: 'Início'
    }),
    up_indi: this.fieldList({
      value: 'up_indi',
      text: 'Fim'
    }),
    type: this.fieldList({
      value: 'type',
      text: 'Tipo'
    })
  }
}
