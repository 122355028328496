<template>
  <TinyEditor 
    v-if="showEditor"
    :apiKey="apiKey"
    :initial-value="model"
    :init="config"
  ></TinyEditor>
</template>
<script>
  import TinyEditor from '@tinymce/tinymce-vue'
  export default {
    name: 'WiTinyEditor',
    data: () => ({
      showEditor: false,
      apiKey: '1pwn5rw26syn27rz79wgwnapcgc98crz45y7ec41lhi2hare',
      config: {
        height: window.innerHeight - 170,
        menubar: false,
        readonly : true,
        content_css: 'document',
        content_style: 'body { margin: 1rem auto; max-width: 900px; }',
        language: 'pt_BR',
        plugins: ['print'],
        toolbar: []
      }
    }),
    watch: {
      '$route': 'loadView'
    },
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    mounted: function () {
      this.showEditor = true
      this.$forceUpdate()
    },
    components: {
      TinyEditor
    },
    props: {
      value: {
        required: true
      }
    }
  }
</script>