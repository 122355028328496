<template>
  <wi-view index="talk-channel" title="Importar Contatos do WhatsApp" apiUrl="talks/channel/contacts" v-on:afterLoad="receiveContacts">
    <template>
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg6 style="padding-bottom: 10px; padding-right: 5px;">
            <v-toolbar dense class="elevation-17">
              <div>
                <span>Vincular todos os contatos ao usuário atual:</span> 
                <br>
                <strong>{{this.$Auth.user().name}}</strong>
              </div>
              <v-switch
                v-model="setCurrentUser"
                :label="setCurrentUser ? ' Sim ' : ' Não '"
                hide-details
                single-line
                style="padding-left: 20px;"
              ></v-switch>
            </v-toolbar>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 style="padding-bottom: 10px; padding-left: 5px;">
            <v-toolbar dense class="elevation-17">
              <v-spacer></v-spacer>
              <div style="text-align: center;">
                <span>Total de contatos </span> 
                <br>
                <strong>{{contactsCount | number}}</strong>
              </div>
              <v-spacer></v-spacer>
              <div style="text-align: center;">
                <span>Contatos importados </span> 
                <br>
                <strong>{{contactsImportedCount | number}}</strong>
              </div>
              <v-spacer></v-spacer>
              <div style="text-align: center;">
                <span>Contatos não importados </span> 
                <br>
                <strong>{{contactsNotImportedCount | number}}</strong>
              </div>
            </v-toolbar>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17">
              <v-data-table 
                dense 
                :headers="headers" 
                :items="contacts"
                :rows-per-page-items="[8, 10, 5, 25, 50, { 'text': 'Todos', 'value': -1 }]"
                item-key="uuid"
              >
                <template slot="items" slot-scope="props">
                  <tr :class="{'contact__conflict' : props.item.status == 'conflict', 'contact__imported' : props.item.status == 'imported', 'contact__error' : props.item.status == 'error'}">
                    <td class="talk-imported text-xs-center">
                      <span v-if="props.item.external_code.length == 17">
                        <strong>{{ formatNumber(props.item.external_code) | mask('+## (##) ####-####') }}</strong>
                      </span>
                      <span v-else-if="props.item.external_code.length == 18">
                        <strong>{{ formatNumber(props.item.external_code) | mask('+## (##) # ####-####') }}</strong>
                      </span>
                      <span v-else>
                        {{ formatNumber(props.item.external_code) }}
                      </span>
                    </td>
                    <td class="talk-imported text-xs-center">
                      <strong>{{ props.item.name }}</strong>
                    </td>
                    <td class="talk-imported text-xs-center">
                      <div v-if="props.item.profiles && props.item.profiles.length > 0">
                        <span v-for="(profile, profileKey) in props.item.profiles" :key="profileKey">
                          <span v-if="profileKey">, </span>
                          <span v-if="profile == 'customer'">Cliente</span>
                          <span v-if="profile == 'contact'">Contato</span>
                          <span v-if="profile == 'provider'">Fornecedor</span>
                          <span v-if="profile == 'collaborator'">Colaborador</span>
                        </span>
                      </div>
                      <div v-else>
                        Não definido
                      </div>
                    </td>
                    <td class="talk-imported text-xs-center">
                      <span v-if="props.item.status == 'imported'">Importado</span>
                      <span v-else-if="props.item.status == 'conflict'">Conflito</span>
                      <span v-else-if="props.item.status == 'error'">Erro</span>
                      <span v-else>Não importado</span>
                    </td>
                    <td class="talk-imported text-xs-center">
                      <v-btn 
                        small
                        outline
                        color="blue"
                        :loading="props.item.loading"
                        @click="editContact(props.item)"
                        :disabled="props.item.status == 'conflict' || props.item.status == 'imported'" 
                      >Editar
                      </v-btn>
                      <v-btn 
                        small 
                        outline 
                        color="green" 
                        :loading="props.item.loading"
                        :disabled="!props.item.external_code || props.item.external_code == '' || !props.item.name || props.item.name == '' || props.item.profiles.length < 1" 
                        @click="saveContact(props.item)"
                      >Importar
                      </v-btn>
                      <v-btn 
                        small 
                        outline 
                        color="red" 
                        :loading="props.item.loading" 
                        :disabled="props.item.status == 'conflict' || props.item.status == 'imported'" 
                        @click="removeContact(props.item)"
                      >Remover</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-dialog v-model="dialog" max-width="450" scrollable persistent>
                <v-card>
                  <v-card-title v-if="edit && edit.uuid"  :class="{'contact__conflict' : edit.status == 'conflict', 'contact__imported' : edit.status == 'imported', 'contact__error' : edit.status == 'error'}">
                    <h3>
                      Editar Contato  
                      <span v-if="edit.external_code.length == 17">
                        <span>{{ formatNumber(edit.external_code) | mask('+## (##) ####-####') }}</span>
                      </span>
                      <span v-else-if="edit.external_code.length == 18">
                        <span>{{ formatNumber(edit.external_code) | mask('+## (##) # ####-####') }}</span>
                      </span>
                      <span v-else>
                        {{ formatNumber(edit.external_code) }}
                      </span>
                    </h3>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text v-if="edit && edit.uuid">
                    <v-form v-model="editValid">{{editValid}}
                      <v-text-field
                        v-model="edit.name"
                        label="Nome do contato"
                        outline
                        required
                        :rules="[value => !!value || 'Campo obrigatório.']"
                      ></v-text-field>
                      <v-select
                        :items="personProfiles"
                        v-model="edit.profiles"
                        label="Perfil/Perfis"
                        outline
                        required
                        :rules="[value => !!value.length || 'Selecione no mínimo 1 perfil.']"
                        multiple
                      ></v-select>
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small outline @click="dialog = false; edit = {}" >
                      Cancelar
                    </v-btn>
                    <v-btn color="red darken-1" small outline @click="removeContact(edit)" >
                      remover
                    </v-btn>
                    <v-btn :disabled="!editValid" color="blue darken-1" small outline @click="updateContact({ save: false })" >
                      Atualizar
                    </v-btn>
                    <v-btn :disabled="!editValid" color="green darken-1" small outline @click="updateContact({ save: true })" >
                      Atualizar e Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </wi-view>
</template>
<script>
  export default {
    name: 'TalkChannelWhatsAppImportContacts',
    data: () => ({
      edit: {},
      editValid: false,
      setCurrentUser: true,
      dialog: false,
      contacts: [],
      currentContacts: [],
      personProfiles: [
        {value: 'customer', text: 'Cliente'},
        {value: 'contact', text: 'Contato'},
        {value: 'provider', text: 'Fornecedor'},
        {value: 'collaborator', text: 'Colaborador'}
      ],
      headers: [
        { text: 'WhatsApp', align: 'center', sortable: true, value: 'id' },
        { text: 'Nome', align: 'center', value: 'name' },
        { text: 'Perfil/Perfis', align: 'center', value: 'profiles' },
        { text: 'Status', align: 'center', value: 'status' },
        { text: 'Ações', align: 'center', value: 'id', sortable: false }
      ]
    }),
    computed: {
      contactsCount () {
        return this.contacts.length
      },
      contactsImportedCount () {
        return this.contacts.filter(contact => (contact.status == 'imported' || contact.status == 'conflict')).length
      },
      contactsNotImportedCount () {
        return this.contacts.filter(contact => (contact.status != 'imported' && contact.status != 'conflict')).length
      }
    },
    methods: {
      formatNumber: function (number) {
        return number.replace('@c.us', '')
      },
      receiveContacts: function ({ content }) {
        this.contacts = []
        content.data.map(contact => {
          if (contact.type == 'chat') {
            this.contacts.push({
              uuid: this.$UUID(),
              profiles: ['customer'],
              name: contact.name,
              external_code: contact.id
            })
          }
        })
        this.checkCurrentContacts()
      },
      editContact: function (contact) {
        this.edit = {
          ...contact
        }
        this.dialog = true
      },
      updateContact: function ({save}) {
        if (this.edit && this.edit.uuid) {
          const index = this.contacts.findIndex(contact => (contact.uuid === this.edit.uuid))
          console.log(index)
          this.contacts.splice(index, 1, this.edit);
          this.$forceUpdate()
        }
        if (save) {
          console.log('Save contact!');
          const index = this.contacts.findIndex(contact => (contact.uuid === this.edit.uuid))
          this.saveContact(this.contacts[index])
        }
        this.edit = {}
        this.dialog = false
        this.$forceUpdate()
        console.log(this.edit);
      },
      removeContact: function ({ uuid }) {
        if (uuid) {
          const index = this.contacts.findIndex(contact => (contact.uuid === uuid))
          this.contacts.splice(index, 1)
          this.edit = {}
          this.dialog = false
          this.$forceUpdate()
        }
      },
      saveContact: function (contact) {
        const contactToImport = {
          ...contact,
          responsible_id: this.setCurrentUser ? this.$Auth.user().id : null,
          number: this.formatNumber(contact.external_code),
          talk_channel_id: this.$route.params.id,
          type: 1
        }
        this.setContactLoading(contact.uuid, true)
        this.$WiApiPost({
          uri: 'talks/channel/import-contact',
          data: contactToImport,
          callback: (response => {
            console.log(response);
            if (response?.sucess, response?.sucess?.id) {
              console.log(response.sucess)
              this.setContactStatus(response.sucess.id, 'imported')
            } else if (response.error) {
              if (response?.error?.response?.status == 409) {
                this.setContactStatus(contact.uuid, 'conflict');
                this.$swal({
                  type: 'warning',
                  title: 'Este contato já existe!',
                  text: 'Este contato do WhatsApp já existe no sistema.'
                })
              } else {
                this.setContactStatus(contact.uuid, 'error');
                this.$swal({
                  type: 'error',
                  title: 'Erro ao importar contato!',
                  text: 'Tente novamente ou contact o suporte.'
                })
              }
            }
            this.setContactLoading(contact.uuid, false)
          })
        })
      },
      setContactStatus: function (contactUuid, status) {
        const index = this.contacts.findIndex(contact => (contactUuid === contact.uuid))
        const contact = this.contacts.find(contact => (contactUuid === contact.uuid))
        this.contacts.splice(index, 1, {
          ...contact,
          status: status
        })
        this.$forceUpdate()
      },
      setContactLoading: function (contactUuid, loading) {
        const index = this.contacts.findIndex(contact => (contactUuid === contact.uuid))
        const contact = this.contacts.find(contact => (contactUuid === contact.uuid))
        console.log(index)
        this.contacts.splice(index, 1, {
          ...contact,
          loading: loading
        })
        console.log(this.contacts.find(contact => (contactUuid === contact.uuid)));
        this.$forceUpdate()
      },
      getCurrentContacts: function () {
        this.$WiApiGet({uri: `talks/contacts`, params: {talk_channel_id: this.$route.params.id}, callback: response => {
          if (response.success) {
            this.currentContacts = response.success
            this.checkCurrentContacts()
          }
        }})
      },
      checkCurrentContacts: function () {
        this.currentContacts.map(currentContact => {
          let index = this.contacts.findIndex(contact => (contact.external_code == currentContact.external_code));
          if (index > -1) {
            this.setContactStatus(this.contacts[index].uuid, 'imported')
          }
        })
      }
    },
    mounted: function () {
      this.getCurrentContacts()
    }
  }
</script>
<style scoped>
  .contact__conflict {
    background: rgba(255, 170, 0, 0.541);
  }
  .contact__imported {
    background: rgb(94 181 94 / 46%);
  }
  .contact__error {
    background: rgba(156, 1, 1, 0.5);
  }
  .talk-imported {
    padding: 0 5px !important;
  }
</style>
