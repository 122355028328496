import { BusinessExport } from "@/modules/business/config/BusinessExport"

export class OrderExportFilter extends BusinessExport {
  constructor () {
    super()
    this.id = 'fbded5ac'
    this.apiUrl = 'service-desk/order-export'
    this.fieldsEdit.status_id.apiUrl = 'service-desk/config/status/order'
    this.fieldsEdit.category_id.apiUrl = 'service-desk/config/category/order'
  }
}
