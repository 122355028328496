import Finance from '@/modules/finance/config/Finance/Finance/Finance'
import FinanceIn from '@/modules/finance/config/Finance/Finance/FinanceIn'
import FinanceInList from '@/modules/finance/config/Finance/Finance/FinanceInList'
import FinanceInUnpaid from '@/modules/finance/config/Finance/Finance/FinanceInUnpaid'
import FinanceOut from '@/modules/finance/config/Finance/Finance/FinanceOut'
import FinanceOutList from '@/modules/finance/config/Finance/Finance/FinanceOutList'
import FinanceOutUnpaid from '@/modules/finance/config/Finance/Finance/FinanceOutUnpaid'
import FinanceAccount from '@/modules/finance/config/Accounts/FinanceAccount'
import FinanceRecurrence from '@/modules/finance/config/Finance/FinanceRecurrence/FinanceRecurrence'
import financeRecurrenceRemove from '@/modules/finance/config/Finance/FinanceRecurrence/financeRecurrenceRemove'
import { AccountBancoInter } from '@/modules/finance/config/Accounts/Banks/AccountBancoInter'
import { FinancePayment } from '@/modules/finance/config/Finance/Finance/FinancePayment'
import { FinancePaymentCreate } from '@/modules/finance/config/Finance/Finance/FinancePaymentCreate'
import { FinanceSendEmail } from '@/modules/finance/config/Finance/Finance/FinanceSendEmail'
import FinanceCategoryIn from '@/modules/finance/config/Finance/FinanceCategory/FinanceCategoryIn'
import FinanceCategoryOut from '@/modules/finance/config/Finance/FinanceCategory/FinanceCategoryOut'
import Event from '@/modules/finance/config/Finance/Common/Event'
import FinanceList from './Finance/Finance/FinanceList'
import { Invoice } from './Invoice/Invoice'
import { FinanceOutExport } from './Finance/Finance/FinanceOutExport'
import { FinanceInExport } from './Finance/Finance/FinanceInExport'
import { Bank } from './Accounts/Banks/Bank'

export default {
  'finances': Finance,
  'finance-list': FinanceList,
  'finance-accounts': FinanceAccount,
  'finance-payment': new FinancePayment(),
  'finance-payment-create': new FinancePaymentCreate(),
  'finance-accounts-banco-inter': new AccountBancoInter(),
  'finance-send-email': new FinanceSendEmail(),
  'finance-in': FinanceIn,
  'finance-in-list': FinanceInList,
  'finance-in-unpaid': FinanceInUnpaid,
  'finance-in-recurrence': FinanceRecurrence.in,
  'finance-recurrence-remove': financeRecurrenceRemove,
  'finance-out': FinanceOut,
  'finance-out-list': FinanceOutList,
  'finance-out-unpaid': FinanceOutUnpaid,
  'finance-out-recurrence': FinanceRecurrence.out,
  'finance-in-categories': FinanceCategoryIn,
  'finance-out-categories': FinanceCategoryOut,
  'finance-event': Event.in,
  'invoice': new Invoice(),
  'finance-out-export': new FinanceOutExport(),
  'finance-in-export': new FinanceInExport(),
  'bank': new Bank()
}
