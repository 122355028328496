import Register from '@/modules/register'
import ServiceDesk from '@/modules/service-desk'
import Talk from '@/modules/talk'
import Network from '@/modules/network'
import Projects from '@/modules/projects'
import Forms from '@/modules/forms'
import Sales from '@/modules/sales'

export default {
  name: 'Connect View',
  nameHtml: 'Connect <strong>View</strong>',
  icon: 'connect_view.png',
  version: '5.2.25',
  lastUpdate: '04/03/2020',
  color: '#138024',
  type: 'web', // web or mobile
  mobile_link: 'https://play.google.com/store/apps/details?id=connectview.insights.com',
  modules: [
    ServiceDesk,
    Network,
    Talk,
    Register,
    Projects,
    Sales,
    Forms
  ]
}
