const category = (name, title) => {
  return {
    id: 'a02e979e',
    list: true,
    title: {
      list: 'Categorias de ' + title,
      edit: {
        create: 'Nova categoria de ' + title,
        update: 'Editar categoria de ' + title
      }
    },
    icon: 'category',
    apiUrl: 'stocks/config/category/' + name,
    fields: [
      {
        value: 'id',
        text: 'ID',
        type: 'int',
        list: true,
        edit: false,
        search: true
      },
      {
        value: 'name',
        text: 'Nome da Categoria',
        type: 'text',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        required: true,
        list: true,
        edit: true,
        search: true
      },
      {
        value: 'color',
        text: 'Cor da Categoria',
        type: 'color',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'icon',
        text: 'Ícone da Categoria',
        type: 'wi-google-icons',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        required: false,
        list: false,
        edit: true
      },
      {
        value: 'layout',
        text: 'layout desta categoria',
        placeholder: 'Crie a estrutura da categoria.',
        type: 'tiny-editor',
        list: false,
        edit: true
      },
      {
        value: 'description',
        text: 'Observações',
        type: 'textarea',
        required: false,
        list: false,
        edit: true
      }
    ]
  }
}

export default {
  productTransferIn: category('product-transfer-in', 'Transferência para o estoque'),
  productTransferInCollaborator: category('product-transfer-in-collaborator', 'Transferência para o colaborador'),
  productTransferInCustomer: category('product-transfer-in-customer', 'Transferência para o cliente'),
  productTransferOut: category('product-transfer-out', 'Transferência do estoque'),
  productTransferOutCollaborator: category('product-transfer-out-collaborator', 'Transferência do estoque do colaborador'),
  productTransferOutCustomer: category('product-transfer-out-customer', 'Transferência do cliente'),

  productEntry: category('product-entry', 'Movimentação de entrada'),
  productEntryCollaborator: category('product-entry-collaborator', 'Movimentação para o colaborador'),
  productEntryCustomer: category('product-entry-customer', 'Movimentação para o cliente'),
  productRemoval: category('product-removal', 'Movimentação de retirada'),
  productRemovalCollaborator: category('product-removal-collaborator', 'Movimentação do estoque do colaborador'),
  productRemovalCustomer: category('product-removal-customer', 'Movimentação do cliente')
}
