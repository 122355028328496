import { MovementPerson } from './Movement'
import {
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_CREATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_DELETE
} from '@/default/constants/permissions'

export class MovementEntryCustomer extends MovementPerson {
  constructor () {
    super()

    this.urlDetails.name = 'product-entry-customer'

    // Change Fields
    this.editPerson.text = 'Cliente'
    this.editPerson.wiConfig = 'customer'
    this.editStatus.apiUrl = 'stocks/config/status/product-entry-customer'
    this.editCategory.apiUrl = 'stocks/config/category/product-entry-customer'
    this.listPerson.text = 'Cliente'
  }

  name = 'product-entry-customer';
  id = {
    id: PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER,
    create: PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_CREATE,
    update: PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE,
    delete: PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_DELETE
  };
  title = {
    list: 'Entrada p/ Cliente',
    edit: {
      insert: 'Nova Entrada p/ Cliente',
      update: 'Editar Entrada p/ Cliente'
    }
  };
  apiUrl = 'stocks/product-entry/customer';
  icon = 'group_add';

}
