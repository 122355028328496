<template>
  <v-card class="business-internet-plan__selected" flat>
    <v-card-text class="business-internet-plan__selected-content">
      <v-list two-line subheader>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon class="blue white--text">speed</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <strong>{{ item.name }}</strong>
              <span> - Mensalidade: </span>
              <strong>{{ plan.amount_total | money }}</strong>
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <span>
                <v-icon small color="green">download</v-icon>
                <span>Download: </span>
                <strong>{{ item.host_config.download_formated }}</strong>
                e
                <v-icon small color="orange">upload</v-icon>
                <span>Upload: </span>
                <strong>{{ item.host_config.upload_formated }}</strong>
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
  import ContractInternetPlanMixin from './ContractInternetPlanMixin'
  export default {
    name: 'ContractInternetPlanSelected',
    computed: {
      item () {
        return this.plan?.item || {}
      }
    },
    mixins: [ContractInternetPlanMixin]
  }
</script>
<style scoped>
  .business-internet-plan__selected {
    text-align: center !important;
    padding: 0px !important;
  }
  .business-internet-plan__selected-content {
    padding: 0px !important;
  }
</style>