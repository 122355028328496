<template>
  <v-dialog v-model="dialog" persistent max-width="90%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card>
      <v-toolbar dense flat :color="!$wiDark ? '#FFFFFF': '#212121'">
        <v-icon v-text="business.info.icon"></v-icon>
        <v-toolbar-title>
          <span v-text="business.info.title"></span>
          <span> - </span>
          <span v-text="business.person.name"></span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-prms="{'id': updatePermission}" :disabled="disableEditButtons" class="hidden-xs-only hidden-sm-only" outline small @click="editLayout()">
          <v-icon>edit</v-icon>
          <span>Editar</span>
        </v-btn>
        <v-btn v-prms="{'id': updatePermission}" :disabled="disableEditButtons" class="hidden-xs-only hidden-sm-only" outline small @click="recreateLayout()">
          <v-icon>refresh</v-icon>
          <span>Recriar</span>
        </v-btn>
        <v-btn outline small @click="printLayout()" class="hidden-xs-only hidden-sm-only">
          <v-icon>print</v-icon>
          <span>Imprimir</span>
        </v-btn>
        <v-btn
          v-if="business.file && business.file.url"
          outline
          small
          link
          target="_blank"
          :href="business.file.url"
          class="hidden-xs-only hidden-sm-only"
        >
          <v-icon>download</v-icon>
          <span>download</span>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn> 
      </v-toolbar>
      <v-card-text v-if="dialog">
        <div v-show="!$vuetify.breakpoint.xsOnly">
          <WiTinyEditor 
            v-if="business.description"
            v-model="business.description"
          ></WiTinyEditor>
        </div>
        <div v-if="$vuetify.breakpoint.xsOnly">
          <v-btn v-prms="{'id': updatePermission}" block :disabled="disableEditButtons" outline small @click="recreateLayout()">
            <v-icon>refresh</v-icon>
            <span>Recriar</span>
          </v-btn>
          <br/>
          <v-btn block outline small @click="printLayout()">
            <v-icon>print</v-icon>
            <span>Imprimir</span>
          </v-btn>
          <br/>
          <v-btn
            v-if="business.file && business.file.url"
            outline
            small
            link block
            target="_blank"
            :href="business.file.url"
          >
            <v-icon>download</v-icon>
            <span>download</span>
          </v-btn>  
        </div>
      </v-card-text>
    </v-card>
    <BusinessLayoutAlertDialog
      v-model="acceptRecreateLayoutDialog"
      :business="business"
      :refresh="refresh"
      @recreate="recreatingDocument"
      @recreated="recreatedDocument"
    ></BusinessLayoutAlertDialog>
  </v-dialog>
</template>
<script>
  import WiTinyEditor from '@/default/component/WiTinyEditor'
  import BusinessLayoutAlertDialog from './BusinessLayoutAlertDialog'
  export default {
    name: 'BusinessLayoutDialog',
    data: () => ({
      apiKey: '1pwn5rw26syn27rz79wgwnapcgc98crz45y7ec41lhi2hare',
      config: {
        height: window.innerHeight - 170,
        menubar: false,
        readonly : true,
        content_css: 'document',
        content_style: 'body { margin: 1rem auto; max-width: 900px; }',
        language: 'pt_BR',
        plugins: ['print'],
        toolbar: []
      },
      acceptRecreateLayoutDialog: false
    }),
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      disableEditButtons () {
        return this.loading || this.business.stage == 2 || this.business.stage == 3
      },
      updatePermission () {
        return this.$route.meta.config?.permission?.update
      }
    },
    methods: {
      printLayout: function () {
        // eslint-disable-next-line
        tinymce.activeEditor.execCommand('mcePrint');
      },
      editLayout: function () {
        const typeName = this.$route?.meta?.config?.name
        this.$WiEditDialog({
          wiConfig: `${typeName}-layout`,
          onSubmit: this.refresh,
          data: {
            id: this.business.id,
            description: this.business.description
          }
        })
        this.dialog = false
      },
      recreateLayout: function () {
        this.acceptRecreateLayoutDialog = true
      },
      recreatingDocument: function () {
        this.dialog = false
        console.log('creatingDocument')
      },
      recreatedDocument: function () {
        this.dialog = true
        console.log('createdDocument')
      }
    },
    props: {
      value: {
        required: true
      },
      business: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      WiTinyEditor,
      BusinessLayoutAlertDialog
    }
  }
</script>