<template>
  <v-toolbar v-if="!isMini" flat extended style="background-image: url('static/background/background-blue.png'); color: white;" :dark="$wiDark" :color="$wiDark ? 'dark' : 'primary'">
    <v-list two><br>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <img v-if="user && user.image" :src="user.image" alt="Usuário">
          <img v-else src="static/icons/user_icon_1.png" alt="Usuário">
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="white--text">
            {{ user.name | stringlength(15) }}
          </v-list-tile-title>
          <v-list-tile-sub-title v-if="user.profile && user.profile.name" class="white--text">
            {{ user.profile.name | stringlength(17) }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action style="min-width: 1px !important;">
          <v-menu v-model="userMenu" :close-on-content-click="true" :nudge-width="100" offset-x >
            <v-btn style="margin: 0 !important;" slot="activator" icon >
              <v-icon class="white--text">more_vert</v-icon>
            </v-btn>
            <v-card>
              <v-subheader
                v-t="'components.drawerLeft.userProfile.menu.subHeader'"
              ></v-subheader>
              <v-divider></v-divider>
              <v-list style="padding: 0 !important;">
                <v-list-tile @click="$router.push({name: 'collaborator', params: {id: user.id}})">
                  <v-list-tile-avatar><v-icon>perm_identity</v-icon></v-list-tile-avatar>
                  <v-list-tile-title
                    v-t="'components.drawerLeft.userProfile.menu.userProfile.label'"
                  ></v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="$Auth.confirmLogout()">
                  <v-list-tile-avatar><v-icon>exit_to_app</v-icon></v-list-tile-avatar>
                  <v-list-tile-title
                    v-t="'components.drawerLeft.userProfile.menu.logout.label'"
                  ></v-list-tile-title>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-action>
                    <v-switch @change="touggleDark()" v-model="$wiDark"></v-switch>
                  </v-list-tile-action>
                  <v-list-tile-title style="text-transform: capitalize;">
                    <span
                      v-if="$wiDark"
                      v-t="'components.drawerLeft.userProfile.menu.darkMode.disable'"
                    ></span>
                    <span
                      v-else
                      v-t="'components.drawerLeft.userProfile.menu.darkMode.enable'"
                    ></span>
                    <span
                      style="padding-left: 5px;"
                      v-t="'components.drawerLeft.userProfile.menu.darkMode.label'"
                    ></span>
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider v-if="$WiCheckPermission('45c9f8ed')"></v-divider>
                <v-list-tile v-if="$WiCheckPermission('45c9f8ed')" link :to="{
                    name: 'register-list',
                    params: {
                      page: 'log'
                    }
                  }"
                >
                  <v-list-tile-avatar>
                    <v-icon color="primary">search</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Logs do sistema
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
              </v-list>
              <v-card-actions>
                <v-btn
                  flat
                  @click="userMenu = false"
                  v-t="'components.drawerLeft.userProfile.menu.exit.label'"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-list-tile-action>
        <v-list-tile-action class="hidden-md-and-up" style="min-width: 1px !important; display: none;">
          <v-btn style="margin: 0 !important;" icon @click="drawer = false">
            <v-icon color="white">chevron_left</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-toolbar>
</template>
<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'ToolbarProfile',
    data () {
      return {
        userMenu: false
      }
    },
    computed: {
      isMini () {
        return this.$store.state.Pages.Main.drawerLeft.isMini
      },
      user () {
        return this.$store.state.User
      }
    },
    methods: {
      ...mapActions('Pages/Main', ['touggleDark'])
    }
  }
</script>
