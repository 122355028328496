<template>
  <v-layout align-center justify-center row wrap>
    <v-flex sm5>
      <WiImageDialog :src="config.image" title="Detalhes do equipamento"></WiImageDialog>
    </v-flex>
    <v-flex sm7>
      <v-card-text>
        <v-divider></v-divider>
        <div dense three-line v-for="(item, itemKey) in config.items" :key="itemKey">
          <v-divider v-if="itemKey"></v-divider>
          <v-toolbar dense flat color="transparent" style="padding: 0 !important; height: 48px !important;">
            <v-icon
              :color="itemIconColor(item)"
              :class="itemClass(item)"
              v-text="itemIcon(item)"
              large
            ></v-icon>
            <div style="padding-left: 10px;font-size: 18px; min-width: 150px; text-align: left;">
              <strong>{{ item.number }} - {{ item.name }}: </strong>
            </div>
            <v-radio-group
              v-model="itemConfig[item.code]"
              :rules="[v => !!v || 'obrigatório']"
              @change="itemChange"
              single-line
              hide-details
              required
              row
            >
              <v-radio
                v-for="option in item.options"
                :key="option.code"
                :label="option.name"
                :value="option"
                color="#000"
              ></v-radio>
            </v-radio-group>
            <v-spacer></v-spacer>
            <v-btn v-if="!item.show" icon @click="() => { item.show = true; $forceUpdate() }">
              <v-icon>help_outline</v-icon>
            </v-btn>
            <v-btn v-else icon @click="() => { item.show = false; $forceUpdate() }">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-scroll-y-transition mode="out-in">
            <div v-if="item.show" style="text-align: left; padding-left: 24px; padding-bottom: 5px; color: gray;">
              <v-divider></v-divider>
              <span>{{ item.description }}</span>
            </div>
          </v-scroll-y-transition>
        </div>
        <v-divider></v-divider>
      </v-card-text>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    name: 'HostModelConfig',
    data: () => ({
      valid: false,
      itemConfig: {}
    }),
    methods: {
      itemIconColor: function (item) {
        if (this.itemConfig[item.code]) {
          return this.itemConfig[item.code].color
        }
        return 'gray'
      },
      itemIcon: function (item) {
        if (this.itemConfig[item.code]) {
          return this.itemConfig[item.code].icon
        }
        return 'horizontal_rule'
      },
      itemClass: function (item) {
        if (this.itemConfig[item.code]) {
          return [ `config__item-status--${this.itemConfig[item.code].code}` ]
        }
        return {}
      },
      itemChange: function () {
        this.valid = Object.keys(this.itemConfig).length == this.config.items.length
        this.checkValid()
      },
      checkValid: function () {
        if (this.valid) {
          let config = {
            name: this.name,
            data: []
          }
          for (const key in this.itemConfig) {
            const status = this.itemConfig[key]
            const { code, name } = this.config.items.find(item => item.code == key)
            config.data.push({
              code,
              name,
              status: {
                code: status.code,
                name: status.name
              }
            })
          }
          this.$emit('valid', config)
        }
      }
    },
    props: {
      name: {
        type: String,
        required: true
      },
      model: {
        required: true
      },
      host: {
        required: true
      },
      config: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style scoped>
  .config__item-status--flashing {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
</style>
