const fields = {
  service: [
    { value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: true },
    { text: 'Selecione o Serviço e informe a quantidade e valores.', type: 'subheader', edit: true },
    { value: 'item_id', text: 'Serviço*', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md12', 'lg12'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/service', apiParams: { fields: 'id,name', order: 'name,asc' }, show: 'name' },
    { value: 'description', text: 'Descrição do Serviço', size: ['xs12', 'sm6', 'md6', 'lg6'], type: 'text', list: false, edit: true },
    { value: 'quantity', text: 'Quantidade', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, type: 'int', list: false, edit: true },
    { value: 'amount_unit', text: 'Valor Unitário', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { value: 'amount_discount', text: 'Valor Desconto', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { text: 'Use este campo para registrar alguma observação interna.', type: 'subheader', edit: true },
    { value: 'observation', text: 'Observações do Serviço', type: 'textarea', list: false, edit: true }
  ],
  product: [
    { value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: true },
    { text: 'Selecione o Produto e informe a quantidade e valores.', type: 'subheader', edit: true },
    {
      value: 'stock_id',
      text: 'Estoque*',
      autocomplete: true,
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      type: 'db-autocomplete',
      list: false,
      edit: { insert: true, update: true },
      apiUrl: 'stock',
      apiParams: { fields: 'id,name', order: 'name,asc' },
      show: 'name'
    },
    {
      value: 'item_id',
      text: 'Produto',
      size: ['xs12', 'sm12', 'md9', 'lg9'],
      type: 'wi-list-dialog',
      edit: true,
      wiConfig: 'product',
      show: 'item.name',
      icon: 'person_outline',
      callbackFields: [
        {get: 'amount_sale', set: 'amount_unit'}
      ]
    },
    { value: 'description', text: 'Descrição do Produto', size: ['xs12', 'sm6', 'md6', 'lg6'], type: 'text', list: false, edit: true },
    { value: 'quantity', text: 'Quantidade', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, type: 'int', list: false, edit: true },
    { value: 'amount_unit', text: 'Valor Unitário', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { value: 'amount_discount', text: 'Valor Desconto', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { text: 'Use este campo para registrar alguma observação interna.', type: 'subheader', edit: true },
    { value: 'observation', text: 'Observações do Produto', type: 'textarea', list: false, edit: true }
  ]
}

const item = (id, name, item, title) => {
  return {
    id: id,
    title: {
      edit: {
        create: 'Incluir ' + title,
        update: 'Editar ' + title
      }
    },
    apiUrl: 'purchases/' + name + '/item',
    fields: fields[item],
    fieldsFixed: [
      {
        value: 'amount_total', data: 0
      }
    ]
  }
}

export default {
  product: {
    purchase: item('b41dcfec', 'purchase', 'product', 'Produto a Compra'),
    quotation: item('d3c48579', 'quotation', 'product', 'Produto à Cotação')
  },
  service: {
    purchase: item('b41dcfec', 'purchase', 'service', 'Serviço a Compra'),
    quotation: item('d3c48579', 'quotation', 'service', 'Serviço à Cotação')
  }
}
