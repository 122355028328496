import { Business } from '@/modules/shared/config/Business/Business'

class Graduation extends Business {
  constructor (listTitle, stage) {
    super()
    this.list = true
    this.id = {
      id: '4d8hd50b',
      create: 'e578be37',
      update: '4d8brfa0',
      delete: '83wd7827'
    }
    this.name = 'graduation'
    this.title = {
      list: listTitle,
      edit: {
        insert: 'Nova Formatura',
        update: 'Editar Formatura'
      }
    }
    this.apiUrl = 'graduations/graduation'
    if (stage) {
      this.params['stage'] = stage
    }
    this.urlDetails = { name: 'graduation', params: {} }
    this.icon = 'school'
    this.search = {
      placeholder: 'Pesquise pela Descrição ou Status/Etapa',
      fields: ['id', 'title', 'status.name']
    }
    this.components.list.tableRow = () => import('@/modules/graduations/pages/Graduations/Graduations')
    // Fields
    this.fields[0].text = 'Informe os dados básicos desta Formatura.'
    this.fields[2].text = 'Aluno/Cliente Responsável'
    this.fields[2].wiConfig = 'customer'
    this.fields[4].apiUrl = 'graduations/config/status/graduation'
    this.fields[5].apiUrl = 'graduations/config/category/graduation'
    this.fields[5].text = 'Categoria/Tipo de Formatura'
    this.fields[7].text = 'Data da Contratação'
    this.fields[8].text = 'Previsão de Conclusão'
    this.fields[9].text = 'Valor Adicional'
    this.fields[11].size = ['xs12', 'sm12', 'md8', 'lg8']
    this.fields[12] = {}
    this.fields[13] = {}
    this.fields[14].text = 'Descreva abaixo como será este projeto de formatura'
    this.fields[16].text = 'Este campo pode ser usado para registros financeiros'
    this.fields[19].text = 'Observações do Projeto de formatura'

    this.fields = this.fields.splice(0, 18)

    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'title',
        text: 'Descrição'
      }),
      this.fieldList({
        value: 'business_status_id',
        text: 'Status/Etapa'
      }),
      this.fieldList({
        value: 'date_start',
        text: 'Data de Início'
      }),
      this.fieldList({
        value: 'date_end',
        text: 'Data de Término'
      }),
      this.fieldList({
        value: 'id',
        text: 'Opções'
      })
    ])

    this.fields.splice(1, 0,
      this.fieldWiListDialog({
        value: 'finance_account_id',
        text: 'Conta Bancária/Financeira da Formatura',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'finance-accounts',
        show: 'finance_account.name',
        icon: 'account_balance',
        disabled: (form) => { console.log(form); return form.id != null }
      })
    )

    this.fields.splice(1, 0,
      this.fieldText({
        value: 'title',
        text: 'Nome/Descrição do projeto',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      })
    )

  }
}

export default {
  graduation: new Graduation('Projetos de Formatura', null, { value: 'opened_at', text: 'Data da Contratação' }),
  open: new Graduation('Formaturas Em Aberto', 'open', { value: 'opened_at', text: 'Data da Contratação' }),
  close: new Graduation('Histórico de Formaturas', 'close', { value: 'closed_at', text: 'Previsão de Conclusão' }),
  cancel: new Graduation('Formaturas Canceladas', 'cancel', { value: 'canceled_at', text: 'Data de Cancelamento' })
}
