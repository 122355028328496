export default {
  id: {
    id: 'dc24170b',
    create: 'd40a2a3e',
    update: '734a4cf7',
    delete: '7073218a'
  },
  list: true,
  title: 'Colaboradores',
  icon: 'assignment_ind',
  search: {
    placeholder: 'Pesquise ID, Nome, CPF ou RG',
    fields: ['id', 'name', 'first_name', 'cpf_cnpj', 'email', 'rg', 'reference']
  },
  apiUrl: 'register/person/collaborator',
  apiParams: {with: 'phones:id,person_id,contact,country_code,type'},
  urlDetails: {name: 'collaborator', params: {}},
  components: {
    list: {
      tableRow: () => import('@/modules/register/pages/Collaborators')
    }
  },
  fields: [
    {text: 'Informações básicas deste cliente.', type: 'subheader', edit: true},
    {value: 'id', text: 'ID', list: true, edit: false, search: true},
    {value: 'name', text: 'Nome', placeholder: 'Nome como será chamado', type: 'text', size: ['xs12', 'sm12', 'md4', 'lg4'], required: true, list: true, edit: true, search: true},
    {value: 'first_name', text: 'Nome Completo', placeholder: 'Ex: Pedro Henrique da Silva', type: 'text', size: ['xs12', 'sm7', 'md5', 'lg5'], required: true, list: false, edit: true},
    // {value: 'first_name', text: 'Nome Completo', type: 'text', list: true, edit: false, search: true},
    {value: 'cpf_cnpj', text: 'CPF', placeholder: '###.###.###-##', mask: '###.###.###-##', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true},
    {value: 'phones', text: 'Telefone(s)', type: 'text', list: true, edit: false, search: false, sortable: false},
    {value: 'rg', text: 'RG', placeholder: 'Insira o RG', type: 'text', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true},
    {value: 'birthday', text: 'Data de Nascimento', type: 'date', size: ['xs12', 'sm2', 'md3', 'lg3'], required: false, list: false, edit: true},
    {value: 'sex', text: 'Sexo', type: 'select', size: ['xs12', 'sm2', 'md2', 'lg2'], options: [{value: 'Feminino', text: 'Feminino'}, {value: 'Masculino', text: 'Masculino'}, {value: 'Outro', text: 'Outro'}], required: false, list: false, edit: true},
    // {value: 'email_pessoa', text: 'E-mail', type: 'text', size: ['xs12', 'sm4', 'md4', 'lg4'], required: false, list: false, edit: true},
    // {value: 'senha_pessoa', text: 'Senha de Acesso', type: 'password', size: ['xs12', 'sm3', 'md3', 'lg3'], required: false, list: false, edit: true},
    // {value: 'altera_senha', text: 'Alterar Senha', type: 'select', size: ['xs12', 'sm2', 'md2', 'lg2'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], required: false, list: false, edit: true},
    // {value: 'site', text: 'Site', placeholder: 'URL. Ex: https://google.com', type: 'text', size: ['xs12', 'sm8', 'md8', 'lg8'], list: false, edit: true},
    {
      value: 'status',
      text: 'Status',
      placeholder: 'Ativo/Inativo',
      type: 'select',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      options: [
        {value: 'active', text: 'Está Ativo'},
        {value: 'inactive', text: 'Está Inativo'}
      ],
      required: false,
      list: true,
      edit: true,
      default: 'active'
    },
    {
      value: 'additional_info',
      text: 'Informações avançadas do(a) ' + name,
      type: 'select',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' }
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica'
      }
    },
    {
      text: 'Dados avançados do(a) ' + name,
      type: 'subheader',
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'mothers_name',
      text: 'Nome da mãe',
      placeholder: 'Insira o nome da mãe',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'fathers_name',
      text: 'Nome do pai',
      placeholder: 'Insira o nome do pai',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'spouses_name',
      text: 'Nome do cônjuge',
      placeholder: 'Insira o nome do cônjuge',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'companions_name',
      text: 'Responsável ou acompanhante',
      placeholder: 'Insira o nome do responsável ou acompanhante',
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'marital_status',
      text: 'Estado civil',
      placeholder: 'Selecione o estado civil',
      type: 'select',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [
        {value: 'Casado(a)', text: 'Casado(a)'},
        {value: 'Divorciado(a)', text: 'Divorciado(a)'},
        {value: 'Separado(a)', text: 'Separado(a)'},
        {value: 'Solteiro(a)', text: 'Solteiro(a)'},
        {value: 'União estável', text: 'União estável'},
        {value: 'Viúvo(a)', text: 'Viúvo(a)'},
      ],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'profession',
      text: 'Profissão',
      placeholder: 'Insira a Profissão',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'blood_group',
      text: 'Grupo sanguíneo',
      placeholder: 'Insira o grupo sanguíneo',
      type: 'select',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      edit: true,
      options: [
        {value: 'Grupo A', text: 'Grupo A'},
        {value: 'Grupo B', text: 'Grupo B'},
        {value: 'Grupo AB', text: 'Grupo AB'},
        {value: 'Grupo O', text: 'Grupo O'},
      ],
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'education',
      text: 'Escolaridade',
      placeholder: 'Insira a Escolaridade',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'naturalness',
      text: 'Naturalidade',
      placeholder: 'Insira a Naturalidade',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'religion',
      text: 'Religião',
      placeholder: 'Insira a Religião',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {
      value: 'ethnicity',
      text: 'Etnia',
      placeholder: 'Insira a etnia',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      edit: true,
      showField: (form) => {
        return form.type === 'Fisica' && form.additional_info
      }
    },
    {text: 'Observações deste Colaborador', type: 'subheader', edit: true, size: ['xs12']},
    {value: 'observation', text: 'Observações', type: 'textarea', required: false, list: false, edit: true}
    // eslint-disable-next-line
    // {value: 'teste', text: 'Detalhes', type: 'link', align: 'center', list: true, edit: false, links: [
    //   {icon: 'info', action: (route, dados) => { route.push({ name: 'p', params: {person: 'collaborator', id: dados.id} }) }, show: (d) => { return true }}
    // ]}
  ],
  fieldsFixed: [
    {
      value: 'type', data: 'Fisica'
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
