import {
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_ENTRY,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_REMOVAL,
  PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER
} from '@/default/constants/permissions'

export default [
  {
    id: 'b0ce8f81-78ff-4d79-a9f3-d7415343d2e5',
    icon: 'dashboard',
    title: 'Dashboard de Estoque',
    route: {
      name: 'dashboard-stock'
    }
  },
  {
    id: '9f35f528',
    icon: 'my_location',
    title: 'Unidades de Estoque',
    route: {
      name: 'stock-list',
      params: {
        page: 'stock'
      }
    },
    children: [
      {
        id: '9f35f528',
        icon: 'location_on',
        title: 'Estoques Físicos',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-physical'
          }
        }
      },
      {
        id: '9f35f528',
        icon: 'perm_identity',
        title: 'Estoques de Colaboradores',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-collaborator'
          }
        }
      },
      {
        id: '9f35f528',
        icon: 'groups',
        title: 'Estoques de Clientes',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-customer'
          }
        }
      },
      {
        id: '9f35f528',
        icon: 'cloud_queue',
        title: 'Estoques Virtuais',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-virtual'
          }
        }
      }
    ]
  },
  {
    id: '8b84d7d5',
    icon: 'swap_horiz',
    title: 'Movimentações No Estoque',
    children: [
      {
        id: PERMISSION_STOCK_PRODUCT_ENTRY,
        icon: 'file_download',
        title: 'Entrada de Produtos',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-entry'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR,
        icon: 'social_distance',
        title: 'Entrada para Colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-entry-collaborator'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER,
        icon: 'group_add',
        title: 'Entrada para Cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-entry-customer'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_REMOVAL,
        icon: 'file_upload',
        title: 'Retirada de Produtos',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-removal'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR,
        icon: 'social_distance',
        title: 'Retirada do Colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-removal-collaborator'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER,
        icon: 'group_remove',
        title: 'Retirada do Cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-removal-customer'
          }
        }
      },
      {
        id: '8b84d7d5',
        icon: 'swap_horiz',
        title: 'Movimentações No Estoque',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-movement'
          }
        }
      }
    ]
  },
  {
    id: '44e3b5cf',
    icon: 'import_export',
    title: 'Transferências de Estoque',
    children: [
      {
        id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN,
        icon: 'file_download',
        title: 'Transferência para o estoque',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-transfer-in'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR,
        icon: 'social_distance',
        title: 'Transfer para o colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-transfer-in-collaborator'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER,
        icon: 'group_add',
        title: 'Transfer para o cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-transfer-in-customer'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT,
        icon: 'file_upload',
        title: 'Transferência do estoque',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-transfer-out'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR,
        icon: 'social_distance',
        title: 'Transfer a partir do colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-transfer-out-collaborator'
          }
        }
      },
      {
        id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER,
        icon: 'group_remove',
        title: 'Transfer a partir do cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'product-transfer-out-customer'
          }
        }
      },
      {
        id: '44e3b5cf',
        icon: 'import_export',
        title: 'Registros de Transferências',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-transfer'
          }
        }
      }
    ]
  },
  // {id: '44e3b5cf', icon: 'import_export', title: 'Transferência Entre Estoques', route: {name: 'stock-list', params: {page: 'stock-transfer'}}},
  {
    id: 'b7454443',
    icon: 'move_to_inbox',
    title: 'Produtos Em Estoque',
    route: {
      name: 'stock-list',
      params: {
        page: 'stock-product'
      }
    }
  },
  {
    id: 'aeebefc2',
    icon: 'settings',
    title: 'Configurações',
    children: [
      // {
      //   id: 'aeebefc2',
      //   icon: 'category',
      //   title: 'Categorias de Transferência para o estoque',
      //   route: {
      //     name: 'stock-list',
      //     params: {
      //       page: 'category-product-transfer-in'
      //     }
      //   }
      // },
      {
        id: 'aeebefc2',
        icon: 'category',
        title: 'Categorias de Transferência para o colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'category-product-transfer-in-collaborator'
          }
        }
      },
      {
        id: 'aeebefc2',
        icon: 'category',
        title: 'Categorias de Transferência para o cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'category-product-transfer-in-customer'
          }
        }
      },
      // {
      //   id: 'aeebefc2',
      //   icon: 'category',
      //   title: 'Categorias de Transferência do estoque',
      //   route: {
      //     name: 'stock-list',
      //     params: {
      //       page: 'category-product-transfer-out'
      //     }
      //   }
      // },
      {
        id: 'aeebefc2',
        icon: 'category',
        title: 'Categorias de Transferência do estoque do colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'category-product-transfer-out-collaborator'
          }
        }
      },
      {
        id: 'aeebefc2',
        icon: 'category',
        title: 'Categorias de Transferência do cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'category-product-transfer-out-customer'
          }
        }
      },
      // {
      //   id: 'aeebefc2',
      //   icon: 'code',
      //   title: 'Status/Etapas Transferência para o estoque',
      //   route: {
      //     name: 'stock-list',
      //     params: {
      //       page: 'status-product-transfer-in'
      //     }
      //   }
      // },
      {
        id: 'aeebefc2',
        icon: 'code',
        title: 'Status/Etapas Transferência para o colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'status-product-transfer-in-collaborator'
          }
        }
      },
      {
        id: 'aeebefc2',
        icon: 'code',
        title: 'Status/Etapas Transferência para o cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'status-product-transfer-in-customer'
          }
        }
      },
      // {
      //   id: 'aeebefc2',
      //   icon: 'code',
      //   title: 'Status/Etapas Transferência do estoque',
      //   route: {
      //     name: 'stock-list',
      //     params: {
      //       page: 'status-product-transfer-out'
      //     }
      //   }
      // },
      {
        id: 'aeebefc2',
        icon: 'code',
        title: 'Status/Etapas Transferência do estoque do colaborador',
        route: {
          name: 'stock-list',
          params: {
            page: 'status-product-transfer-out-collaborator'
          }
        }
      },
      {
        id: 'aeebefc2',
        icon: 'code',
        title: 'Status/Etapas Transferência do cliente',
        route: {
          name: 'stock-list',
          params: {
            page: 'status-product-transfer-out-customer'
          }
        }
      }
    ]
  },
  {
    id: 'e42ea891',
    icon: 'description',
    title: 'Cadastro',
    children: [{
        id: 'e42ea891',
        icon: 'local_grocery_store',
        title: 'Cadastro de Produtos',
        route: {
          name: 'stock-list',
          params: {
            page: 'stock-register-product'
          }
        }
      },
      {
        id: 'e42ea891',
        icon: 'assignment_ind',
        title: 'Colaboradores',
        route: {
          name: 'stock-list',
          params: {
            page: 'collaborator'
          }
        }
      },
      {
        id: 'e42ea891',
        icon: 'local_atm',
        title: 'Fornecedores',
        route: {
          name: 'stock-list',
          params: {
            page: 'provider'
          }
        }
      },
      {
        id: 'e42ea891',
        icon: 'business',
        title: 'Empresas (Matriz e Filiais)',
        route: {
          name: 'stock-list',
          params: {
            page: 'company'
          }
        }
      }
    ]
  }
]