import CustomerMenu from './CustomerMenu'
import ContractMenu from './ContractMenu'
import ReportsMenu from './ReportsMenu'
import ConfigMenu from './ConfigMenu'
import RegisterMenu from './RegisterMenu'

export default [
  // {
  //   id: PERMISSION_WELL,
  //   icon: 'dashboard',
  //   title: 'Dashboard de Poços Tubulares',
  //   route: {
  //     name: 'dashboard-wells'
  //   }
  // },
  CustomerMenu,
  ContractMenu,
  ReportsMenu,
  RegisterMenu,
  ConfigMenu
]