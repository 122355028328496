import { MovementPerson } from './Movement'
import {
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_CREATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_DELETE
} from '@/default/constants/permissions'

export class MovementRemovalCustomer extends MovementPerson {
  constructor () {
    super()

    this.urlDetails.name = 'product-removal-customer'

    // Change Fields
    this.editPerson.text = 'Cliente'
    this.editPerson.wiConfig = 'customer'
    this.editStatus.apiUrl = 'stocks/config/status/product-removal-customer'
    this.editCategory.apiUrl = 'stocks/config/category/product-removal-customer'
    this.listPerson.text = 'Cliente'
  }

  name = 'product-removal-customer';
  id = {
    id: PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER,
    create: PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_CREATE,
    update: PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE,
    delete: PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_DELETE
  };
  title = {
    list: 'Retirada do Cliente',
    edit: {
      insert: 'Nova Retirada do Cliente',
      update: 'Editar Retirada do Cliente'
    }
  };
  apiUrl = 'stocks/product-removal/customer';
  icon = 'group_add';

}
