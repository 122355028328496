<template>
  <view-detail title="Tipos de Ocorrência" apiUrl="chamado/ocorrencia" apiParams="?whereNull=chamado_categoria_id&order=ativa_categoria,desc;nome_categoria,asc" :notGetID="true">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn
        v-prms="{'id': '4f1be58b'}"
        class="hidden-xs-only hidden-sm-only" 
        small 
        outline 
        color="white" 
        @click="$WiEditDialog({wiConfig: 'tipos-de-ocorrencia', onSubmit: dados.refresh, data: {}})" 
        :disabled="dados.loading"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Tipo de Ocorrência</span>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <!-- <div>
        <ocorrencias-dialog v-on:select="select" :data="oco_data" :form="form"></ocorrencias-dialog>
      </div> -->
      <v-container align="center">
        <v-layout row wrap>
          <v-flex xs12 sm7 md7 lg7 d-flex style="padding: 10px;">
            <v-card>
              <v-treeview :items="dados.content.data" :load-children="fetchUsers" transition item-text="nome_categoria" open-on-click>
                <span @click="setSelected(item)" slot="label" slot-scope="{ item }" :style="{color: item.ativa_categoria == 'inativo' ? '#e0e0e0' : 'black'}">
                  {{item.nome_categoria}} <span v-if="item.ativa_categoria < 1">(Inativo)</span>
                </span>
                <v-icon medium slot="prepend" slot-scope="{ item }" :style="{'color': item.ativa_categoria == 'inativo' ? '#e0e0e0' : item.cor_categoria}">{{item.icone_categoria || 'category'}}</v-icon> 
              </v-treeview>
            </v-card>
          </v-flex>
          <v-flex xs12 sm5 md5 lg5 d-flex text-xs-center style="padding: 10px;">
            <v-scroll-y-transition mode="out-in">
              <div v-if="!selected" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center;" > Selecione um grupo... </div>
              <v-card v-else :key="selected.id" >
                <v-card-text style="padding: 16px 0px;">
                  <v-avatar>
                    <v-icon v-if="selected.icone_categoria" :style="{'color': selected.ativa_categoria == 'inativo' ? '#e0e0e0' : selected.cor_categoria}" x-large>{{selected.icone_categoria}}</v-icon> 
                    <v-icon v-else x-large :style="{'color': selected.ativa_categoria == 'inativo' ? '#e0e0e0' : selected.cor_categoria}">device_hub</v-icon> 
                  </v-avatar>
                  <h3 class="headline">
                    {{ selected.nome_categoria }}
                  </h3>
                  <div v-if="selected.ativa_categoria && selected.funcao_categoria > 0" class="gray--text subheading font-weight-bold" style="color: gray;">
                    <strong style="font-size: 16px;">{{selected.previsao_categoria}}</strong>
                  </div>
                  <div v-if="selected.ativa_categoria" class="gray--text subheading font-weight-bold" style="color: gray;">
                    <span>{{selected.ativa_categoria}}</span>
                  </div>
                  <div class="gray--text subheading font-weight-bold" style="color: gray;">
                    <span v-if="selected.funcao_categoria == 0">(Não selecionável)</span>
                    <span v-if="selected.funcao_categoria == 1">(Selecionável na Abertura e Encerramento)</span>
                    <span v-if="selected.funcao_categoria == 2">(Selecionável Somente na Abertura)</span>
                    <span v-if="selected.funcao_categoria == 3">(Selecionável Somente no Encerramento)</span>
                  </div>
                  <v-divider v-if="selected.obs_categoria"></v-divider>
                  <div v-if="selected.obs_categoria" class="gray--text" style="color: gray;">{{ selected.obs_categoria }}</div>
                  <v-divider></v-divider>
                  <v-tooltip v-if="selected.id && selected.ativa_categoria == 'ativo'" v-prms="{'id': '4f1be58b'}" bottom>
                    <template slot="activator">
                      <v-btn @click="$WiEditDialog({wiConfig: 'tipos-de-ocorrencia', onSubmit: dados.refresh, data: {chamado_categoria_id: selected.id}})" icon ripple>
                        <v-icon color="green lighten-1">add</v-icon>
                      </v-btn>
                    </template>
                    <span>Criar Sub-ocorrência</span>
                  </v-tooltip>
                  <v-tooltip v-if="selected.id" v-prms="{'id': '4f1be58b'}" bottom>
                    <template slot="activator">
                      <v-btn @click="$WiEditDialog({wiConfig: 'tipos-de-ocorrencia', onSubmit: dados.refresh, data: selected})" icon ripple>
                        <v-icon color="orange lighten-1">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar este tipo de ocorrência</span>
                  </v-tooltip>
                  <v-tooltip v-if="selected.id" v-prms="{'id': '4f1be58b'}" bottom>
                    <template slot="activator">
                      <db-delete v-on:action="dados.refresh" store="tipos-de-ocorrencia" :id="selected.id"></db-delete>
                    </template>
                    <span>Apagar este tipo de ocorrência</span>
                  </v-tooltip>
                  <v-tooltip v-if="selected.id && selected.ativa_categoria == 'ativo' && (selected.funcao_categoria == 1 || selected.funcao_categoria == 2)" v-prms="{'id': '4f1be58b'}" bottom>
                    <template slot="activator">
                      <v-btn @click="$WiEditDialog({wiConfig: 'chamados-todos', onSubmit: dados.refresh, data: {chamado_categoria_id: selected.id}})" icon ripple>
                        <v-icon color="blue lighten-1">message</v-icon>
                      </v-btn>
                    </template>
                    <span>Criar chamado para este tipo de ocorrência</span>
                  </v-tooltip>
                  <v-divider></v-divider>
                  <v-list subheader dense two-line style="padding: 0 5px 0 5px;">
                    <v-list-tile avatar>
                      <v-list-tile-avatar>
                        <v-avatar >
                          <img v-if="selected.chamado_impacto_id == 1" src="static/icons/monitor/retornou.png" alt="Impacto">
                          <img v-if="selected.chamado_impacto_id == 2" src="static/icons/monitor/problema.png" alt="Impacto">
                          <img v-if="selected.chamado_impacto_id == 3" src="static/icons/monitor/problema_v2.png" alt="Impacto">
                          <img v-if="selected.chamado_impacto_id == 4" src="static/icons/monitor/problema_expirado.png" alt="Impacto">
                        </v-avatar>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-sub-title>
                          <strong>Impacto:</strong>
                        </v-list-tile-sub-title>
                        <v-list-tile-title><strong>{{selected.nome_impacto}}</strong></v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>    
                    <v-list-tile avatar>
                      <v-list-tile-avatar>
                        <v-avatar >
                          <img v-if="selected.chamado_urgencia_id == 1" src="static/icons/monitor/retornou.png" alt="Urgência">
                          <img v-if="selected.chamado_urgencia_id == 2" src="static/icons/monitor/problema.png" alt="Urgência">
                          <img v-if="selected.chamado_urgencia_id == 3" src="static/icons/monitor/problema_v2.png" alt="Urgência">
                          <img v-if="selected.chamado_urgencia_id == 4" src="static/icons/monitor/problema_expirado.png" alt="Urgência">
                        </v-avatar>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-sub-title>
                          <strong>Urgência:</strong>
                        </v-list-tile-sub-title>
                        <v-list-tile-title><strong>{{selected.nome_urgencia}}</strong></v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-scroll-y-transition>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import DbDelete from '@/default/components/DBDelete'
  import {callApi} from '@/default/service/Api'
  export default {
    data: () => ({
      form: {},
      items: [],
      selected: null,
      editModal: false,
      configModal: null,
      modalForm: {},
      modalStore: 'grupos-sub',
      open: [],
      search: null,
      caseSensitive: false,
      oco_data: {}
    }),
    methods: {
      select: function (data) {
        console.log(data)
        console.log(this.form)
        this.oco_data = data
      },
      async fetchUsers (item) {
        console.log(item)
        return new Promise((resolve, reject) => {
          callApi.get({
            uri: 'chamado/ocorrencia',
            params: {limit: 1000, order: 'nome_categoria,asc', where: 'chamado_categoria_id,' + item.id},
            sucess: data => {
              resolve(data.data.data)
              var itens = data.data.data.filter(function (i) {
                if (i.sub_grupos > 0) {
                  i['children'] = []
                }
                return i
              })
              item.children.push(...itens)
            },
            error: err => {
              reject(err)
              return err
            }
          })
        })
      },
      setSelected: function (id) {
        if (id) {
          this.selected = id
        }
      },
      openIndisponibilidades: function (grupo) {
        var query = {grupo_dados_id: grupo.id}
        if (grupo.num_disp_down > 0) {
          query['status_indi'] = 0
          query['status_disp'] = 0
          query['ativo_disp'] = 1
        }
        this.$router.push({name: 'indisponibilidades', query: query})
      }
    },
    components: {
      ViewDetail,
      DbDelete
    },
    computed: {
      filter () {
        return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
      },
      auth () {
        return this.$store.state.auth || {}
      }
    }
  }
</script>