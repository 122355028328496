<template>
  <wi-report
    v-if="config"
    :title="config.title"
    :config="config"
    :api="{url: config.api.url, params: config.api.params}"
    :xlsExportFormat="xlsExportFormat"
    @onExportToPDF="onExportToPDF"
  >
    <template slot="statistics" slot-scope="props">
      <v-layout row wrap>
        <v-flex v-for="(statistic, statisticKey) in config.statistics" :key="statisticKey" xs12 sm12 md2 lg2 style="padding: 0px 5px;">
          <v-card flat class="elevation-10" style="border-radius: 10px;" :color="statistic.color">
            <v-card-text style="display: flex; flex-direction: row; gap: 5px;">
              <div v-if="statistic.icon" style="display: flex; align-items: center;">
                <v-icon color="white" large>
                  {{ statistic.icon }}
                </v-icon>
              </div>
              <div style="display: flex; flex-direction: column;">
                <strong style="color: white; font-size: 14px;">
                  {{statistic.title}}
                </strong>
                <strong
                  v-if="statistic.type === 'money'"
                  style="color: white;"
                  :style="{'font-size': statistic.size || '16px'}"
                >
                  {{ props.statistics[statistic.field] | money }}
                </strong>
                <strong v-else style="color: white;" :style="{'font-size': statistic.size || '16px'}">
                  {{ props.statistics[statistic.field] | number }}
                </strong>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template slot-scope="{ item }">
      <tr>
        <td style="text-align: center; padding: 0 5px !important;">
          <strong>{{ item.id }}</strong>
          <br>
          <span>{{ item.external_code || '- - -' }}</span>
        </td>
        <td style="text-align: center; padding: 0 5px !important;">
          <!-- <span>{{ item.person_id }} - </span> -->
          <strong>{{ item.person.first_name }}</strong>
        </td>
        <td style="text-align: center; padding: 0 5px !important;">
          <span v-if="item.date">
            {{ item.date | moment('DD/MM/y') }}
          </span>
          <span v-else>Indefinido</span>
        </td>
        <!-- <td style="text-align: center;">
          <span v-if="item && item.category && item.category.name">
            {{ item.category.name }}
          </span><br>
          <span v-if="item && item.collaborator && item.collaborator.name">
            {{ item.collaborator.name }}
          </span>
        </td> -->
        <td style="text-align: center; padding: 0 5px !important;">
          <span v-if="item && item.amount_products_total">
            {{ item.amount_products_total | money }}
          </span>
          <span v-else>R$ 0,00</span>
        </td>
        <td style="text-align: center; padding: 0 5px !important;">
          <span v-if="item && item.amount_services_total">
            {{ item.amount_services_total | money }}
          </span>
          <span v-else>R$ 0,00</span>
        </td>
        <td style="text-align: center; padding: 0 5px !important;">
          <strong v-if="item.amount_freight">{{ item.amount_freight | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center; padding: 0 5px !important;">
          <strong v-if="item.total_liquid">{{ item.total_liquid | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center; padding: 0 5px !important;">
          <v-btn
            outline small color="primary" link
            :to="{ name: business.name, params: { id: item.id } }"
          >
            Detalhes
          </v-btn>
        </td>
      </tr>
    </template>
  </wi-report>
</template>
<script>
  import WiReport from '@/default/component/WiReport/WiReport'
  export default {
    name: 'BusinessByPeriodReport',
    data () {
      return {
        config: null,
        makeConfigCount: 0
      }
    },
    computed: {
      person: function () {
        var person = {}
        switch (this.$route.meta.person) {
          case 'customer':
            person = {
              id: 'person_id',
              name: 'customer',
              title: 'Cliente',
              first_name: 'Razão Social'
            }
            break
          case 'provider':
            person = {
              id: 'person_id',
              name: 'provider',
              title: 'Fornecedor',
              first_name: 'Razão Social'
            }
            break
          case 'collaborator':
            person = {
              id: 'collaborator_id',
              name: 'collaborator',
              title: 'Colaborador',
              first_name: 'Nome Completo'
            }
            break
        }
        return person
      },
      business: function () {
        var business = {}
        switch (this.$route.meta.business) {
          case 'budget':
            business = {
              module: 'sales',
              name: 'budget',
              names: 'budgets',
              title: 'Orçamento',
              titles: 'Orçamentos'
            }
            break
          case 'quotation':
            business = {
              module: 'purchases',
              name: 'quotation',
              names: 'quotations',
              title: 'Cotação',
              titles: 'Cotações'
            }
            break
          case 'purchase':
            business = {
              module: 'purchase',
              name: 'purchase',
              names: 'purchases',
              title: 'Compra',
              titles: 'Compras'
            }
            break
          case 'sale':
            business = {
              module: 'saless',
              name: 'sale',
              names: 'sales',
              title: 'Venda',
              titles: 'Vendas'
            }
            break
          case 'order':
            business = {
              module: 'service-desk',
              name: 'order',
              names: 'orders',
              title: 'Ordem',
              titles: 'Ordens'
            }
            break
          case 'process':
            business = {
              module: 'juridical',
              name: 'process',
              names: 'processs',
              title: 'Processo',
              titles: 'Processos'
            }
            break
        }
        return business
      }
    },
    methods: {
      makeConfig: function () {
        this.config = null
        var config = {
          title: this.business.titles + ' concluídas por período',
          api: {
            url: `${this.business.module}/${this.business.name}/report/by-period`,
            params: {}
          },
          statistics: [
            { field: 'count', title: 'Total de ' + this.business.titles + ': ', color: 'primary', size: '22px', type: 'number'},
            { field: 'amount_products_total', title: 'Total de produtos: ', color: 'green', size: '18px', type: 'money'},
            { field: 'amount_services_total', title: 'Total de serviços: ', color: 'green', size: '18px', type: 'money'},
            { field: 'amount_freight_total', title: 'Total de frete: ', color: 'orange', size: '18px', type: 'money'},
            { field: 'amount_total', title: 'Valor total: ', color: 'blue', size: '18px', type: 'money'}
          ],
          dates: {
            field: {
              text: 'Data de filtro',
              items: [
                { value: 'date_start', text: 'Data de Lançamento' },
                { value: 'date_end', text: 'Data de Entrega' },
                { value: 'opened_at', text: 'Data de Criação' },
                { value: 'closed_at', text: 'Data de Conclusão' }
              ]
            },
            start: {
              type: 'date',
              text: 'Data Inicial'
            },
            end: {
              type: 'date',
              text: 'Data Final'
            }
          },
          filters: {
            text: 'Filtros',
            fields: []
          },
          fields: [
            { text: 'ID/Cód. externo', value: 'id', align: 'center', sortable: false },
            // { text: '', value: 'external_code', align: 'center', sortable: true },
            { text: `${this.person.title}`, value: `${this.person.name}`, align: 'center', sortable: false },
            { text: 'Data', value: 'date', align: 'center' },
            // { text: 'Categoria/Colaborador', value: 'business_category_id', align: 'center', sortable: false },
            { text: 'Valor de produtos', value: 'amount_products_total', align: 'center' },
            { text: 'Valor de serviços', value: 'amount_services_total', align: 'center' },
            { text: 'Valor de frete', value: 'amount_freight', align: 'center' },
            { text: 'Valor total', value: 'total_liquid', align: 'center' },
            { sortable: false }
          ],
          externalExportToPDF: true
        }
        if (this.makeConfigCount) {
          setTimeout(() => {
            this.config = config
          }, 500)
        } else {
          this.config = config
        }
        this.makeConfigCount++
      },
      xlsExportFormat: function (data) {
        const xlsxData = {
          ...data,
          id: `${data.id} / ${data.external_code}`,
          [`${this.person.name}`]: data.person.first_name,
          date: data.date,
          amount_products_total: this.$options.filters.money(data.amount_products_total),
          amount_services_total: this.$options.filters.money(data.amount_services_total),
          amount_freight: this.$options.filters.money(data.amount_freight),
          total_liquid: this.$options.filters.money(data.total_liquid),
        }
        console.log(xlsxData)
        return xlsxData
      },
      onExportToPDF: function (data) {
        console.log(data)
        this.$WiApiPost({
          uri: `${this.business.module}/${this.business.name}/report/by-period/export-pdf`,
          data,
          config: {
            msgLoad: {
              title: `Exportando relatório...`,
              text: 'Não irá demorar mais que alguns segundos.'
            },
            msgSucess: {
              title: 'Relatório exportado com sucesso.',
              text: 'O resultado da solicitação irá aparecer na timeline.'
            },
            msgError: {
              title: 'Erro ao exportar relatório',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja exportar o relatório?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          },
          callback: (response) => {
            console.log(response)
            this.processExportedFile(response)
          }
        })
      },
      processExportedFile: function (response) {
        if (
          response && response.sucess &&
          response.sucess.data && response.sucess.data.file_url
        ) {
          window.open(response.sucess.data.file_url, '_blank')
        } else {
          setTimeout(() => {
            this.$swal({
              title: 'Nenhum dado encontrado para exportar',
              text: 'Por favor, verifique os filtros e tente novamente.',
              type: 'warning'
            })
          }, 1000);
        }
      }
    },
    mounted: function () {
      this.makeConfig()
    },
    watch: {
      '$route': 'makeConfig'
    },
    components: {
      WiReport
    }
  }
</script>
