<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="85%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <template v-slot:activator="{ on }">
        <v-list-tile v-on="on" avatar>
          <v-list-tile-avatar>
            <v-avatar>
              <img src="static/icons/monitor/binoculars_96.png" alt="Visualizaram">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Visualizaram</v-list-tile-sub-title>
            <v-list-tile-title style="font-size: 20px;">{{viewedCount}}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon color="black">info</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </template>
      <v-card>
        <v-toolbar dense>
          <v-icon large>visibility</v-icon>
          <strong style="padding-left: 10px;">Visualizaram</strong>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="height: 300px; padding: 0;">
          <view-detail v-if="dialog" title="Seguidores" apiUrl="service-desk/ticket/viewed" :apiParams="'?where=chamado_dados_id,' + ticket + '&order=id,desc'" notGetID="true" notReturnIcon="true" notShowToolbar="true">
            <template slot-scope="dados" style="min-height: 300px;">
              <pessoa-list :pessoas="dados.content.data" :refresh="dados.refresh" listSubTitle="date_time_formated" clearMessage="Nenhum colaborador visualizou este chamado até o momento."></pessoa-list>
            </template>
          </view-detail>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click.native="closeThisDialog()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import PessoaList from '@/modules/register/components/PessoaList'
  export default {
    data () {
      return {
        dialog: false
      }
    },
    methods: {
      closeThisDialog: function () {
        this.refresh()
        this.dialog = false
      }
    },
    components: {
      ViewDetail,
      PessoaList
    },
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      }
    },
    props: ['ticket', 'viewedCount', 'refresh']
  }
</script>
<style>
  .v-dialog__container {
    display: none !important;
  }
</style>
