import {
  SERVICE_DESK_DASHBOARD_TICKETS_BY_NETWORK_TYPE_EXPANDED_CACHED
} from '@/default/constants/localStorage'

export default {
  methods: {
    setExpanded: function (expanded) {
      this.expanded = expanded
      this.saveExpandedInStorage(expanded)
    },
    getExpandedByStorage: function () {
      const expanded = this.$Storage.get(SERVICE_DESK_DASHBOARD_TICKETS_BY_NETWORK_TYPE_EXPANDED_CACHED)
      this.expanded = typeof expanded === 'boolean' ? expanded : false
    },
    saveExpandedInStorage: function (expanded) {
      this.$Storage.set(SERVICE_DESK_DASHBOARD_TICKETS_BY_NETWORK_TYPE_EXPANDED_CACHED, expanded)
    }
  }
}
