<template>
  <div v-if="config && config.search">
    <v-text-field 
      id="wiListInput"
      style="color: white; width: 100%" 
      @keyup.enter="config.searchLoad(config.search.query)"
      @keyup.esc="config.searchClear()"
      v-model="config.search.query"  
      color="white"  
      :append-icon="config.search.run ? 'close' : 'search'"  
      :placeholder="placeholder"
      :solo="solo"   
      @click:append="() => { if(config.search.run) { config.searchClear() } else { config.searchLoad(config.search.query) }  }" 
      hide-details  
      single-line
      autofocus
      :dark="!$wiDark"
      class="wi-list-input__text-field"
    ></v-text-field>
</div>
</template>
<script>
  export default {
    name: 'WiListImput',
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      placeholder () {
        return this.config.config && this.config.config.search && this.config.config.search.placeholder ? this.config.config.search.placeholder : 'Digite aqui a sua pesquisa...'
      }
    },
    props: ['config', 'solo', 'autofocus']
  }
</script>

<style scoped>
  .wi-list-input__text-field {
    border-radius: 10px;
  }
</style>