<template>
  <v-layout row wrap>
    <v-flex v-for="(mod, KeyMod) in modules" xs12 sm12 md12 lg12 style="padding: 10px 0;" :key="KeyMod">
      <v-card>
        <v-list one-line style="padding: 0 !important;">
          <user-profile-module :module="mod" :refresh="refresh"></user-profile-module>
          <v-divider></v-divider>
          <v-list v-if="mod.status && mod.children && mod.children.length > 0" one-line style="padding: 0 0 0 40px !important;">
            <div v-for="(subMod, subModKey) in mod.children" :key="subModKey + 'sub'">
              <v-divider></v-divider>
              <user-profile-module :module="subMod" :refresh="refresh" background="#ECEFF1"></user-profile-module>
              <div v-if="subMod.status && subMod.children && subMod.children.length > 0">
                <div v-for="(subSubMod, subSubModKey) in subMod.children" :key="subSubModKey + 'subSub'" style="padding: 0 0 0 40px !important;">
                  <v-divider></v-divider>
                  <user-profile-module :module="subSubMod" :refresh="refresh" background="#CFD8DC"></user-profile-module>
                </div>
              </div>
            </div>
          </v-list>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import UserProfileModule from '@/default/pages/User/UserProfileModule'
  // import UserProfileModulesButton from '@/default/pages/User/UserProfileModulesButton'
  export default {
    name: 'UserProfileModules',
    data () {
      return {}
    },
    methods: {},
    props: {
      modules: {
        type: Array,
        required: true
      },
      refresh: {
        type: Function
      }
    },
    components: {
      UserProfileModule,
      // UserProfileModulesButton
    }
  }
</script>