import { Model } from '@/default/model/Model'

export class PersonProfileImage extends Model {
  constructor () {
    super()
    this.id = '734a4cf7'
    this.actions = {
      update: true
    }
    this.title = 'Alterar foto de perfil'
    this.apiUrl = 'register/person/collaborator'
    this.icon = 'image'
    this.maxWidth = '480px'
    this.setFields([
      this.fieldSubHeader({
        text: 'Selecione uma imagem quadrada para ser a foto de perfil.'
      }),
      this.fieldWiFileDialog({
        value: 'file_id',
        text: 'Imagem',
        placeholder: 'Selecione a imagem',
        required: true,
        data: {
          is_public: false
        },
        filter: {
          is_public: false
        }
      })
    ])
  }
}

