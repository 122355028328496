export default {
  id: '31f8eef9',
  list: true,
  title: 'Equipamentos',
  apiUrl: 'equipamento/dados',
  fields: [
    {value: 'id', text: 'ID', type: 'int', list: true, edit: false},
    {value: 'nome_equipamento_fabricante', text: 'Nome/Fabricante', type: 'text', list: false, edit: false},
    {value: 'nome_equipamento', text: 'Modelo/Equipamento', type: 'text', required: true, list: true, edit: true},
    {value: 'nome_fabricante', text: 'Fabricante', type: 'text', required: true, list: true, edit: false},
    {value: 'equipamento_fabricante_id', text: 'Fabricante', required: true, type: 'dbselect', list: false, edit: true, instore: 'equipamentos', outstore: 'fabricantes', show: 'nome_fabricante'},
    {value: 'desc_equipamento', text: 'Descrição', type: 'textarea', list: false, edit: true}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
