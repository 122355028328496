<template>
  <v-list  subheader>
    <template v-for="(type, typeKey) in networkTypes" style="background: #EFF0F1;">
      <v-list-tile :key="typeKey" :style="{'margin-left': type.nivel_tipo * 30 + 'px', background: selected && selected.id == type.id ? '#fcef7b' : '#EFF0F1'}" style="background: #EFF0F1;" avatar @click="select(type)">
        <v-list-tile-avatar>
          <v-icon large color="blue darken-2">{{type.icone_tipo}}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>
            Nível: {{type.nivel_tipo}}
          </v-list-tile-sub-title>
          <v-list-tile-title style="font-size: 15px;">
            <strong>
              {{type.nome_tipo}}
            </strong>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-list-tile-action-text>Redes</v-list-tile-action-text>
          <strong style="font-size: 20px;">{{type.networks_count}}</strong>
        </v-list-tile-action>
      </v-list-tile>
      <v-divider :key="`${typeKey}-divider`"></v-divider>
    </template>
  </v-list>
</template>
<script>
  export default {
    name: 'NetworkTypesList',
    methods: {
      select: function (type) {
        this.$emit('select', type)
      }
    },
    props: {
      networkTypes: {
        type: Array,
        required: true
      },
      selected: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>