<template>
  <HostSignalDialog :host="host">
    <div class="diagnostic-signal__enable-button elevation-2">
      <v-card-text style="text-align: center;">
        <strong>Sinal do equipamento</strong>
        <v-divider></v-divider>
        <h1 v-if="host.online" class="diagnostic-signal__title" :class="{'diagnostic-signal__title--signal_is_ok': host.signal_is_ok}">
          {{ host.signal || '- - -' }}
        </h1>
        <h3 v-else-if="host.complement && host.complement.status" class="diagnostic-offline__title">
          <span>Offline</span>
          <span class="diagnostic-offline-types__title">
            {{ host.complement.status }}
          </span>
        </h3>
        <h1 v-else class="diagnostic-offline__title">
          Offline
        </h1>
      </v-card-text>
    </div>
  </HostSignalDialog>
</template>
<script>
  import HostSignalDialog from '@/modules/network/pages/Host/HostSignalDialog'
  export default {
    name: 'DiagnosticSignal',
    props: {
      host: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      HostSignalDialog
    }
  }
</script>
<style scoped>
  .diagnostic-signal {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-signal__enable-button {
    cursor: pointer;
  }
  .diagnostic-signal__toolbar {
    z-index: 999;
  }

  .diagnostic-signal__signal {
    text-align: center;
    font-size: 18px;
    padding: 0;
  }
  .diagnostic-signal__not-found {
    margin: 50px 0;
  }
  .diagnostic-signal__title {
    color: orange;
  }
  .diagnostic-signal__title--signal_is_ok {
    color: green;
  }
  .diagnostic-offline__title {
    color: red !important;
    display: flex;
    flex-direction: column;
  }
  .diagnostic-offline-types__title {
    color: orange !important;
  }
</style>
