<template>
  <div>
    <v-footer
      v-if="replyMessage" absolute
      height="auto" class="chat-reply-message"
    >
      <div>
        <strong>Responder: </strong>
        <span>
          {{ replyMessage.text | stringlength(180) }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon ripple @click="replyMessage = null">
        <v-icon>close</v-icon>
      </v-btn>
    </v-footer>
    <v-footer
      absolute height="70" class="pa-0"
      style="border-top: solid 1px lightgrey;"
    >
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="display: flex;">
          <EmojiMenuDialog @onSelect="emojiSelector"></EmojiMenuDialog>
        </div>
      </div>
      <textarea 
        v-model="message" 
        class="chat-input" 
        :class="{'chat-footer__input--dark': $wiDark}" 
        type="text" 
        placeholder="Digite sua mensagem" 
        @keydown="inputHandler" 
        @keyup="onKeyUp"
        rows="3"
        ref="chatFooterTextArea"
        :autofocus="true"
      ></textarea>
      <div style="display: flex; align-items: center; justify-content: center; max-width: 130px; border-left: solid 1px lightgrey; margin-left: 4px;">
        <div>
          <v-btn
            icon ripple small
            :disabled="!message"
            @click="prepareMessage()"
            style="margin: 3px 8px;"
          >
            <v-icon>send</v-icon>
          </v-btn>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; border-left: solid 1px lightgrey;">
          <div style="display: flex; align-items: center; justify-content: center;">
            <v-speed-dial
              direction="top"
              :absolute="false"
              v-model="buttonNewFile"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator>
                <v-btn icon ripple small style="margin: 1px 4px;">
                  <v-icon v-if="!buttonNewFile">
                    attachment
                  </v-icon>
                  <v-icon v-else>
                    close
                  </v-icon>
                </v-btn>
              </template>
              <v-btn fab dark small color="purple" @click="openFileDialog(2)">
                <v-icon x-large>
                  insert_photo
                </v-icon>
              </v-btn>
              <v-btn fab dark small color="red" @click="openFileDialog(3)">
                <v-icon x-large>
                  play_circle_filled
                </v-icon>
              </v-btn>
              <v-btn fab dark small color="blue" @click="openFileDialog(5)">
                <v-icon x-large>
                  insert_drive_file
                </v-icon>
              </v-btn>
            </v-speed-dial>
            <div style="width: 36px; display: flex; align-items: center; justify-content: center;">
              <ChatFooterAudio></ChatFooterAudio>
            </div>
          </div>
        </div>
      </div>
    </v-footer>
    <ChatFileDialog></ChatFileDialog>
  </div>
</template>
<script>
  // import talkViewMixin from '../talkViewMixin'
  import { mapState, mapActions } from 'vuex'
  import { Auth } from '@/default/service/Auth'
  import {callApi} from '@/default/service/Api'
  import ChatFileDialog from './ChatFileDialog'
  import ChatFooterAudio from './ChatFooterAudio'
  import EmojiMenuDialog from '@/modules/talk/pages/TalkView/Talk/EmojiMenuDialog.vue'
  export default {
    name: 'ChatFooter',
    data: () => ({
      message: '',
      buttonNewFile: false,
      emojiSelectorDialog: false
    }),
    computed: {
      ...mapState('Chat', ['chat', 'messageText']),
      replyMessage: {
        get () {
          return this.$store.state.Chat.replyMessage
        },
        set (replyMessage) {
          this.$store.commit('Chat/SET_REPLY_MESSAGE', replyMessage)
        }
      },
      messageEditorDialog: {
        get () {
          return this.$store.state.Chat.messageEditorDialog
        },
        set (messageEditorDialog) {
          this.$store.commit('Chat/SET_MESSAGE_EDITOR_DIALOG', messageEditorDialog)
        }
      }
    },
    methods: {
      ...mapActions('Chat', ['openFileDialog', 'setMessage', 'sendMessage']),
      inputHandler (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault()
          this.prepareMessage(this.message)
        }
      },
      onKeyUp: function () {
        if (this.message == '/m') {
          this.message = ''
          this.openMessageTemplatesList()
        }
      },
      prepareMessage: function () {
        if (this.message && this.message !== '') {
          var message = this.createMessage()
          this.setMessage({ chatMessage: message })
          this.sendMessage(message)
          this.$forceUpdate()
          this.$emit('scroll', 0)
          this.clearMessageInput()
        }
      },
      clearMessageInput: function () {
        this.message = ''
        this.replyMessage = null
      },
      createMessage: function () {
        var message = {
          'chat_id': this.chat.id,
          'text': this.message,
          'text_html': this.message,
          'uuid': this.$UUID(),
          'from_me': true,
          'type_id': 1,
          'chat_message_id': this.replyMessage ? this.replyMessage.id : null,
          'message': this.replyMessage || null,
          'loading': true,
          'ack_id': 0,
          'person': Auth.user(),
          'person_id': Auth.user().id,
          'created_at_formated': 'Agora',
        }
        return message
      },
      onAudioResult (data) {
        this.send('Hello World!', data)
      },
      send: function (fieldName, file, metadata, load, error, progress, abort) {
        let formData = new FormData()
        console.log(progress)
        console.log(abort)
        var infos = {type: file.type, size: file.size}
        infos = Object.assign(infos, {chat_id: this.$route.params.id, chatFileType: 4, audioMessage: true})
        formData.append('files[0]', file, 'hello-world-123.')
        formData.append('infos[0]', JSON.stringify(infos))
        callApi.post({
          uri: 'system/file',
          msgLoad: false,
          data: formData,
          headers: {headers: {'Content-Type': 'multipart/form-data'}},
          sucess: (data) => {
            if (data.status === 201 && data.data.status === 'success') {
              load(data.data.data[0].id)
            } else {
              error(this.$t('messages.error.text'))
            }
          },
          error: (err) => {
            console.log(err)
            error(this.$t('messages.error.text'))
          }
        })
      },
      setAutoFocus: function () {
        if (this.$refs.chatFooterTextArea) {
          this.$refs.chatFooterTextArea.focus()
        }
      },
      emojiSelector: function (emoji) {
        if (emoji) {
          this.message += emoji
        }
      },
      setMessageText: function (text) {
        if (text && text != '') {
          this.message = text
        } 
      }
    },
    mounted: function () {
      this.setAutoFocus()
      this.emojiSelector()
    },
    watch: {
      messageText: 'setMessageText'
    },
    components: {
      ChatFileDialog,
      ChatFooterAudio,
      EmojiMenuDialog
    },
    // mixins: [talkViewMixin]
  }
</script>
<style scoped>
  .chat-input {
    min-height: 40px !important;
    padding: 0;
    background: white;
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
  }
  .chat-reply-message {
    margin-bottom: 70px !important;
    border-bottom: 1px solid rgb(194, 194, 194);
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
  }
  .chat-footer__input--dark {
    background-color: #313131 !important;
    color: #fff;
  }
  .vue-audio-recorder {
    width: 45px !important;
    height: 34px !important;
  }
  emoji-picker {
    --emoji-size: 1.5rem;
    --emoji-font-family: "Apple Color Emoji"
  }
  .chat-footer__ai-option-item >>> .v-list__tile:not(.v-list__tile--avatar) {
    height: 28px !important;
  }
</style>