<template>
  <v-data-table
    :headers="headers"
    :items="tickets"
    hide-actions
    disable-initial-sort
  >
    <template v-slot:items="{ item }">
      <tr>
        <td class="text-xs-center" style="padding: 0px !important; min-width: 70px;">
          <v-tooltip bottom>
            <span slot="activator">
              <v-btn icon v-ripple link :to="{ name: 'chamado', params: {id: item.id}} ">
                <v-icon>info</v-icon>
              </v-btn>
            </span>
            <span>
              Detalhes deste chamado
            </span>
          </v-tooltip><br>
          <strong>#{{ item.id }}</strong>
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important;">
          <v-icon small :color="item.category.color"> {{item.category.icon}} </v-icon>
          <strong style="font-size: 15px;" :style="{color: item.category.color}"> {{ item.category.name }} </strong><br>
          {{ formatCategoryParent(item.category.parents, 'name') }}
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important;">
          <v-icon v-if="item.impact.icon < 1" large :color="item.impact.color">filter_none</v-icon>
          <v-icon v-else-if="item.impact.icon > 9" large :color="item.impact.color">filter_9_plus</v-icon>
          <v-icon v-else large :color="item.impact.color">filter_{{ item.impact.icon }}</v-icon>
          <br>
          <span>{{ item.impact.name }}</span>
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important; max-width: 150px;">
          <textarea 
            :style="{color: item.last_event_seconds > 7200 && item.stage == 1 ? (item.last_event_seconds > 14400 && item.stage == 1 ? '#b71c1c' : '#e59100') : ''}"
            style="font-weight: bold; max-width: 140px;"
            rows="3"
            cols="20"
            textarea
            v-model="item.description_time"
          ></textarea>
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important; max-width: 150px;">
          <textarea
            v-if="item.last_note"
            :style="{color: item.last_event_seconds > 7200 && item.stage == 1 ? (item.last_event_seconds > 14400 && item.stage == 1 ? '#b71c1c' : '#e59100') : ''}"
            style="font-weight: bold; max-width: 140px;"
            rows="3"
            cols="20"
            textarea
            v-model="item.last_note.description_time"
          ></textarea>
          <textarea
            v-else
            :style="{color: item.last_event_seconds > 7200 && item.stage == 1 ? (item.last_event_seconds > 14400 && item.stage == 1 ? '#b71c1c' : '#e59100') : ''}"
            style="font-weight: bold; max-width: 140px;"
            rows="3"
            cols="20"
            textarea
            v-model="item.description_time"
          ></textarea>
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important;">
          {{ item.responsible.name }}
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important;">
          {{ item.created_at_formated }}
        </td>
        <td class="text-xs-center" style="padding: 0 10px !important;">
          {{ item.prevision }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: 'TicketsByNetworkTypeTable',
    data: () => ({
      headers: [
        {align: 'center', text: 'Código', value: 'id', width: '40px', search: '', loadSearch: false},
        {align: 'center', text: 'Tipo de Ocorrência', value: 'chamado_categoria_id', search: '', loadSearch: false, width: '250px'},
        {align: 'center', text: 'Impacto', value: 'chamado_impacto_id', search: '', loadSearch: false, width: '100px'},
        {align: 'center', text: 'Solicitação', value: 'description_time', sortable: false, noSearch: true, loadSearch: false, width: '140px'},
        {align: 'center', text: 'Última Interação', value: 'last_note_id', sortable: false, noSearch: true, loadSearch: false, width: '140px'},
        {align: 'center', text: 'Responsável', value: 'person_responsible_id', search: '', loadSearch: false, width: '100px'},
        {align: 'center', text: 'Abertura', value: 'created_at_formated', search: '', loadSearch: false, width: '100px'},
        {align: 'center', text: 'Duração estimada', value: 'prevision', search: '', loadSearch: false, width: '100px'}
      ]
    }),
    methods: {
      formatCategoryParent: function (items, index) {
        var itemsOrdered = new Array;
        for(var i = items.length-1; i >= 0; i--) {
            itemsOrdered.push(items[i])
        }
        return itemsOrdered.map(item => (item[index])).join(' >> ')
      }
    },
    props: {
      tickets: {
        type: Array,
        required: true
      }
    }
  }
</script>
