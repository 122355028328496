<template>
  <v-list subheader two-line style="padding: 0 5px 0 0;">
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/sorting_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Dispositivos Cadastrados:
        </v-list-tile-title>
        <v-list-tile-sub-title>Dispositivos monitoráveis.</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{data.content.count_devices || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/grupo_icon_map_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Dispositivos Monitorados:
        </v-list-tile-title>
        <v-list-tile-sub-title>Dispositivos deste servidor</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{information.device_count || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <v-icon color="green" x-large>get_app</v-icon>
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Dispositivos Disponíveis:
        </v-list-tile-title>
        <v-list-tile-sub-title>Estão pingando...</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{information.device_up || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <v-icon color="red" x-large>publish</v-icon>
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Dispositivos Indisponíveis:
        </v-list-tile-title>
        <v-list-tile-sub-title>Não estão pingando...</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 25px;">{{information.device_down || '0'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>
<script>
  export default {
    name: 'NetworkServerInfoColumn1',
    data () {
      return {}
    },
    methods: {},
    props: {
      data: {
        type: Object
      },
      information: {
        type: Object
      },
      status: {
        type: String
      }
    }
  }
</script>