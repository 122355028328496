<template>
	<v-layout row >
		<v-flex xs12 sm12 >
			<v-card flat>
				<v-list two-line subheader dense>
					<v-subheader>
						<strong>
              <span>Informações Complementares </span>
              <span v-if="person.infos" style="padding-left: 5px;">
                ({{person.infos.length}})
              </span>
            </strong>
            <v-spacer></v-spacer>
						<v-btn
              v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" 
              small 
              outline 
              color="green" 
              @click="$WiEditDialog({
                wiConfig: 'info',
                onSubmit: refresh,
                data: {
                  person_id: $route.params.id
                },
                redirectOnSubmit: false
              })"
            >
							<v-icon>add</v-icon>
              <span>Informação</span>
						</v-btn>
					</v-subheader>
          <div v-if="person.infos.length > 0">
					<div v-for="(info, keyInfo) in person.infos" :key="keyInfo + '-info'" >
						<v-divider inset></v-divider>
						<v-list-tile avatar>
							<v-list-tile-avatar>
								<v-icon class="orange white--text">info</v-icon>
							</v-list-tile-avatar>
							<v-list-tile-content>
                <v-list-tile-title>
                  <strong> {{ info.information }}</strong>
                </v-list-tile-title>
								<v-list-tile-sub-title> {{ info.description }} </v-list-tile-sub-title>
							</v-list-tile-content>
							<v-list-tile-action>
								<v-btn 
                  v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" 
                  icon
                  ripple
                  @click="$WiEditDialog({wiConfig: 'info', onSubmit: refresh, data: info})"
                >
                  <v-icon color="grey lighten-1">edit</v-icon>
                </v-btn>
								<db-delete v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" v-on:action="refresh" store="info" :id="info.id"></db-delete>
							</v-list-tile-action>
						</v-list-tile>
					</div>
          </div>
          <div v-else>
            <v-divider></v-divider>
            <div style="margin: 20px 0; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 20px;">
              <div class="title">
                <h3>Nenhuma informação no momento</h3>
              </div>
              <div class="description">
                <p style="font-size: 14px;">
                  Adicione informações para complementar as informações do cadastro.
                </p>
              </div>
              <div class="action">
                <v-btn
                  small 
                  outline 
                  color="green" 
                  @click="$WiEditDialog({
                    wiConfig: 'info',
                    onSubmit: refresh,
                    data: {
                      person_id: $route.params.id
                    },
                    redirectOnSubmit: false
                  })"
                >
                  <v-icon>add</v-icon>
                  <span>Adicionar informação</span>
                </v-btn>
              </div>
            </div>
          </div>
					<v-divider ></v-divider>
				</v-list>
			</v-card>
		</v-flex>
  </v-layout>
</template>
<script>
  export default {
    name: 'PersonInformation',
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      }
    },
    props: {
      person: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    }
  }
</script>