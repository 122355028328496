<template>
  <view-detail title="Chamados Por Rede e Ocorrência" apiUrl="network/report/network/metrics" :apiParams="params" :notGetID="true" :notShowToolbar="false">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn v-if="$route.query" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="clearFilters()" :disabled="dados.loading">
        <v-icon>close</v-icon>
        <span>Limpar Filtro</span>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <!-- eslint-disable -->
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px 20px 0px 10px;">
          <v-layout row wrap>
            <v-flex xs12 sm12 md3 lg3 style="padding: 0px 10px 0px 20px;">
              <v-text-field v-model="filter.date_start" type="datetime-local" label="Data Inicial"></v-text-field>
              <v-text-field v-model="filter.date_end" type="datetime-local" label="Data Final"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md5 lg5 style="padding: 0px 10px 0px 20px;">
              <label v-if="ticket_impact_ids && ticket_impact_ids.length > 0" style="color: gray; font-size: 11px;">Impacto:</label>
              <v-select v-if="ticket_impact_ids" v-model="ticket_impact_ids" :items="dados.content.impacts" multiple item-text="nome_impacto" item-value="id" label="Filtre por Impacto" solo ></v-select>
              <div>
                <OcorrenciasDialog 
                  v-on:select="onTicketCategorySelect" 
                  :data="categorySelected"
                  :selectable-in-any-category="true"
                ></OcorrenciasDialog>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md2 lg2 style="padding: 0px 10px 0px 20px;">
              <v-btn :disabled="!filter.date_start && !filter.date_end && !filter.ticket_impact_ids" @click="setFilter()" style="width: 100% !important;">Filtrar</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md2 lg2 style="padding: 0px 10px 0px 20px;">
              <download-excel v-if="dados.content && dados.content.data && dados.content.data.length > 0" class="btn btn-default" :data="dados.content.data" :fields="makeXLSFields()" worksheet="Chamados Por Rede e Ocorrência" name="Relatório.xls">
                <v-btn color="teal darken-1" style="color: white; width: 100% !important;">Exportar/Excel</v-btn>
              </download-excel>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex v-if="dados.content && dados.content.information && dados.content.information.filters" xs12 sm12 md12 lg12 style="text-align: center; padding: 0px 10px 0px 10px;">
          <span>Relatório emitido em <strong>{{dados.content.information.report_generate_date}}</strong></span><br>
          <span v-for="(filter, filterKey) in dados.content.information.filters">
            Filtrando por {{filter.name}}: <strong>{{filter.description}}</strong><br>
          </span>
           <div style="width: 100%; text-align: center !important;">
            <v-radio-group v-model="tableMode" row>
              <v-radio label="Indicadores por Duração" value="duration"></v-radio>
              <v-radio label="Indicadores por Execução" value="execution"></v-radio>
            </v-radio-group>
          </div> --
        </v-flex> -->
        <v-flex xs12 sm12 md12 lg12 style="padding: 20px;">
          <!-- <div style="width: 100%; text-align: center; padding-bottom: 20px; font-size: 18px;">
            <span v-html="dados.content.information.message"></span>
          </div>
          <tickets-list-dialog :dialog="ticketsListDialog" url="service-desk/reports/tickets-by-network-list" :params="ticketsListDialogParams">
            <v-btn icon flat @click="ticketsListDialog = false"><v-icon>close</v-icon></v-btn>
          </tickets-list-dialog> -->
          <v-data-table hide-actions no-data-text="Nenhum resultado encontrado para este filtro." no-results-text="Nenhum resultado encontrado para este filtro." :pagination.sync="pagination" :headers="tableMode == 'execution' ? headersExecution : headersDuration" :items="dados.content.data" class="elevation-1">
            <template v-slot:items="props">
              <tr :style="{cursor: checkIfRowClick(props.item, 'networks_children_count') ? 'pointer' : ''}">
              <td @click="tableRowClick(props.item, 'networks_children_count')">
                <span>{{ props.item.name }}</span>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')">
                <strong style="font-size: 18px; text-align: center;">{{ props.item.total_number_of_failures | number }}</strong>
                <v-tooltip top v-if="props.item.total_number_of_failures > 0 && props.item.available_duration == 100">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="red" small>warning</v-icon>
                  </template>
                  <span v-html="'Encontramos ' + props.item.total_number_of_failures + ' chamado(s) para a rede \'' + props.item.name + '\'. Porém, nenhum dispositivo foi vinculado à ele(s) e por isso seu Avaiable é de 100%.'">Informações do responsável</span>
                </v-tooltip>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')">
                <strong style="font-size: 18px; text-align: center;">{{ props.item.devices_count | number }}</strong>
              </td>
              <!-- <td @click="tableRowClick(props.item, 'networks_children_count')">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.sum_time_available | number }}</span>
                </strong>
              </td> -->
              <!-- <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'duration'"><strong style="font-size: 18px; text-align: center;">{{ props.item.tickets_sum_time_duration | number }}</strong></td> -->
              <!-- <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'execution'"><strong style="font-size: 18px; text-align: center;">{{ props.item.tickets_sum_time_execution | number }}</strong></td> -->
              <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'duration'">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.mttr_duration | number }}</span>
                </strong>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'execution'">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.mttr_in_hours | number }}</span>
                </strong>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'duration'">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.mtbf_duration | number }}</span>
                </strong>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'execution'">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.mtbf_in_hours | number }}</span>
                </strong>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'duration'">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.available_duration | number }}%</span>
                </strong>
              </td>
              <td @click="tableRowClick(props.item, 'networks_children_count')" v-if="tableMode == 'execution'">
                <strong style="font-size: 18px; text-align: center;">
                  <span>{{ props.item.availability | number }}%</span>
                </strong>
              </td>
              <td style="padding: 0 !important; color: blue;">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" >
                      <v-icon color="blue">more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- <v-list-tile @click="getCategory(props.item)" avatar>
                      <v-list-tile-avatar>
                        <v-icon>category</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Filtrar por Tipo de Ocorrência</v-list-tile-title>
                    </v-list-tile>
                    <v-divider></v-divider> -->
                    <v-list-tile @click="listTicketsByNetworkStatistics(props.item)" avatar>
                      <v-list-tile-avatar>
                        <v-icon>list</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Listar Chamados</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </td>
              <!-- <td style="padding: 0 !important; color: blue;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon class="mx-0" @click="">
                      <v-icon color="blue">category</v-icon>
                    </v-btn>
                  </template>
                  <span>Filtrar por Tipo de Ocorrência</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon class="mx-0" @click="">
                      <v-icon color="blue">list</v-icon>
                    </v-btn>
                  </template>
                  <span>Listar Chamados</span>
                </v-tooltip>
              </td> -->
              </tr>
            </template>
            <!-- <template v-slot:footer>
              <td><strong style="font-size: 16px; text-align: center; color: gray;">Totais:</strong></td>
              <td><strong style="font-size: 16px; text-align: center; color: gray;">{{dados.content.information.total_ticket_count}}</strong></td>
              <td><strong style="font-size: 16px; text-align: center; color: gray;">{{dados.content.information.total_devices_count}}</strong></td>
              <td v-if="tableMode == 'duration'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'execution'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'duration'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'execution'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'duration'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'execution'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'duration'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td v-if="tableMode == 'execution'"><strong style="font-size: 16px; text-align: center; color: gray;"></strong></td>
              <td style="padding: 0 !important;"></td>
              <td style="padding: 0 !important;"></td>
            </template> -->
          </v-data-table>
        </v-flex>
        <!-- <v-flex v-if="dados.content && dados.content.data && dados.content.data.length > 0" xs12 sm12 md12 lg12 style="padding: 0px 10px 20px 20px;">
          <v-card>
            <v-flex xs12 sm12 md8 lg8 offset-md2 offset-lg2 style="padding: 0px 10px 20px 20px;">
              <apexchart width="100%" type="donut" :options="options" :series="setSeries(dados.content.data)"></apexchart>
            </v-flex>
          </v-card>
        </v-flex> -->
      </v-layout>
    </template>
  </view-detail>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import OcorrenciasDialog from '@/modules/service-desk/pages/Ocorrencias/OcorrenciasDialog'
  // import TicketsListDialog from '@/modules/Report/pages/Chamados/TicketsListDialog'
  export default {
    name: 'TicketsByNetworkStatistics',
    data () {
      return {
        ticketsListDialog: false,
        ticketsListDialogParams: '',
        tableMode: 'execution',
        table: [],
        headersExecution: [
        { text: 'Rede', value: 'name' },
        { text: 'N° Chamados', value: 'total_number_of_failures' },
        { text: 'Dispositivos', value: 'devices_count' },
        // { text: 'Tempo Disponível (h)', value: 'sum_time_available' },
        // { text: 'Tempo de Reparo (h)', value: 'tickets_sum_time_execution', mode: 'execution' },
        { text: 'MTTR (h)', value: 'mttr_in_hours', mode: 'execution' },
        { text: 'MTBF (h)', value: 'mtbf_in_hours', mode: 'execution' },
        { text: 'Available', value: 'availability', mode: 'execution' },
        {}
        ],
        headersDuration: [
        { text: 'Rede', value: 'name' },
        { text: 'N° Chamados', value: 'total_number_of_failures' },
        { text: 'Dispositivos', value: 'devices_count' },
        // { text: 'Tempo Disponível (h)', value: 'sum_time_available' },
        // { text: 'Tempo de Reparo (h)', value: 'tickets_sum_time_duration', mode: 'duration' },
        { text: 'MTTR (h)', value: 'mttr_duration', mode: 'duration' },
        { text: 'MTBF (h)', value: 'mtbf_duration', mode: 'duration' },
        { text: 'Available', value: 'available_duration', mode: 'duration' },
        {}
        ],
        options: {
          chart: {
            id: 'Chamados Por Tipo de Ocorrência'
          },
          labels: []
        },
        series: [],
        pagination: {
          sortBy: 'ativa_categoria,num_chamados',
          rowsPerPage: -1,
          descending: true
        },
        params: '',
        filter: {
          date_start: null,
          date_end: null,
          network_id: null,
          ticket_category_id: null,
          ticket_category_name: null
        },
        categorySelected: {}
      }
    },
    methods: {
      listTicketsByNetworkStatistics: function (network) {
        const ticketsQuery = network.tickets.map(ticketId => (`id,${ticketId}`)).join(';')
        this.$WiListDialog({
          wiConfig: 'ticket-list',
          filter: {
            orWhere: ticketsQuery
          }
        })
      },
      openTicketsListDialog: function (networkId) {
        var route = this.$route
        var params = '?'
        if (route.query.ticket_impact_ids) {
          params += 'ticket_impact_ids=' + route.query.ticket_impact_ids + '&'
        }
        if (route.query.date_start) {
          params += 'date_start=' + route.query.date_start + '&'
        }
        if (route.query.date_end) {
          params += 'date_end=' + route.query.date_end + '&'
        }
        if (route.query.ticket_category_id) {
          params += 'ticket_category_id=' + route.query.ticket_category_id + '&'
        }
        params += 'network_id=' + networkId
        this.ticketsListDialogParams = params
        this.ticketsListDialog = true
      },
      tableRowClick: function (row, check) {
        if (this.checkIfRowClick(row, check)) {
          this.filter.network_id = row.id
          this.setFilter()
        }
      },
      checkIfRowClick: function (row, check) {
        return row[check] > 0
      },
      getInformation: function (item) {
        var routeQuery = this.$route.query
        routeQuery['network_id'] = item.id
        this.$router.push({
          query: routeQuery
        })
      },
      getCategory: function (item) {
        var routeQuery = this.$route.query
        routeQuery['network_id'] = item.id
        this.$router.push({
          name: 'tickets-by-category-statistics',
          query: routeQuery
        })
      },
      setSeries: function (data) {
        var r = []
        var categories = []
        data.filter((item) => {
          r.push(item.total_number_of_failures)
          categories.push(item.name)
        })
        this.options.labels = []
        this.options.labels = categories
        return r
      },
      setParams: function (route) {
        if (route.query) {
          this.params = '?'
          if (route.query.ticket_impact_ids) {
            this.filter.ticket_impact_ids = route.query.ticket_impact_ids
            this.params += 'ticket_impact_ids=' + route.query.ticket_impact_ids + '&'
          } else {
            this.filter.ticket_impact_ids = null
          }
          if (route.query.date_start) {
            this.filter.date_start = route.query.date_start
            this.params += 'date_start=' + route.query.date_start + '&'
          } else {
            this.filter.date_start = null
          }
          if (route.query.date_end) {
            this.filter.date_end = route.query.date_end
            this.params += 'date_end=' + route.query.date_end + '&'
          } else {
            this.filter.date_end = null
          }
          if (route.query.network_id) {
            this.filter.network_id = route.query.network_id
            this.params += 'network_id=' + route.query.network_id + '&'
          } else {
            this.filter.network_id = null
          }
          if (route.query.ticket_category_id) {
            this.filter.ticket_category_id = route.query.ticket_category_id
            this.params += 'ticket_category_id=' + route.query.ticket_category_id + '&'
          } else {
            this.filter.ticket_category_id = null
          }
          if (route.query.ticket_category_name) {
            this.filter.ticket_category_name = route.query.ticket_category_name
            this.params += 'ticket_category_name=' + route.query.ticket_category_name + '&'
          } else {
            this.filter.ticket_category_name = null
          }
          if (route.query.ticket_category_color) {
            this.filter.ticket_category_color = route.query.ticket_category_color
            this.params += 'ticket_category_color=' + route.query.ticket_category_color + '&'
          } else {
            this.filter.ticket_category_color = null
          }
          if (route.query.ticket_category_icon) {
            this.filter.ticket_category_icon = route.query.ticket_category_icon
            this.params += 'ticket_category_icon=' + route.query.ticket_category_icon + '&'
          } else {
            this.filter.ticket_category_icon = null
          }
        } else {
          this.params = ''
        }
      },
      setFilter: function () {
        var query = {}
        if (this.filter.ticket_impact_ids) {
          query['ticket_impact_ids'] = this.filter.ticket_impact_ids
        }
        if (this.filter.date_start) {
          query['date_start'] = this.filter.date_start
        }
        if (this.filter.date_end) {
          query['date_end'] = this.filter.date_end
        }
        if (this.filter.network_id) {
          query['network_id'] = this.filter.network_id
        }
        if (this.filter.ticket_category_id) {
          query['ticket_category_id'] = this.filter.ticket_category_id
        }
        if (this.filter.ticket_category_name) {
          query['ticket_category_name'] = this.filter.ticket_category_name
        }
        if (this.filter.ticket_category_color) {
          query['ticket_category_color'] = this.filter.ticket_category_color
        }
        if (this.filter.ticket_category_icon) {
          query['ticket_category_icon'] = this.filter.ticket_category_icon
        }
        this.$router.push({query: query})
      },
      makeXLSFields: function () {
        var fields = {}
        var headers = []
        if (this.tableMode === 'execution') {
          headers = this.headersExecution
        } else {
          headers = this.headersDuration
        }
        headers.filter((item) => {
          if (item.text && item.value) {
            fields[item.text] = item.value
          }
        })
        return fields
      },
      onTicketCategorySelect: function (category) {
        this.filter.ticket_category_id = category.chamado_categoria_id
        this.filter.ticket_category_name = category.nome_categoria
        this.filter.ticket_category_color = category.cor_categoria
        this.filter.ticket_category_icon = category.icone_categoria
        this.categorySelected = category
      },
      setTicketCategory: function ({ query }) {
        this.categorySelected = {
          chamado_categoria_id: query.ticket_category_id,
          nome_categoria: query.ticket_category_name,
          cor_categoria: query.ticket_category_color,
          icone_categoria: query.ticket_category_icon
        }
      },
      clearFilters: function () {
        const date_start = new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01T00:00:00'
        const date_end = new Date().toISOString().slice(0, 19)
        this.categorySelected = {}
        this.$router.push({
          name: 'tickets-by-network-statistics-v2',
          query: {
            network_id: -1,
            ticket_impact_ids: '1,2,3,4,5,6',
            date_start,
            date_end
          }
        })
      }
    },
    created: function () {
      this.setParams({query: this.$route.query})
      this.setTicketCategory({query: this.$route.query})
    },
    watch: {
      '$route': 'setParams'
    },
    computed: {
      auth () {
        return this.$store.state.auth || {}
      },
      ticket_impact_ids: {
        get () {
          var impactArray = []
          if (this.filter.ticket_impact_ids) {
            var impactString = this.filter.ticket_impact_ids
            impactString = impactString.split(',')
            impactString.filter((impact) => {
              impactArray.push(parseInt(impact))
            })
          }
          return impactArray
        },
        set (value) {
          this.filter.ticket_impact_ids = value ? value.toString() : null
        }
      }
    },
    components: {
      ViewDetail,
      OcorrenciasDialog
      // TicketsListDialog
    }
  }
</script>
<style>
th {
  padding: 0 8px !important;
}
</style>