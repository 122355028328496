<template>
  <v-layout row>
    <v-flex class="form-reply-list__row" xs12 sm12 md12 lg12 xl12>
      <v-toolbar v-if="reply && reply.form" dense flat color="transparent" class="form-reply-list__toolbar">
        <v-toolbar-title>
          <v-icon v-if="reply.form.icon" :color="reply.form.color">{{reply.form.icon}}</v-icon>
          <span> {{reply.form.name}}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="reply && reply.id" @click="updateFormReply()" outline small :loading="loading">
          <v-icon>edit</v-icon>
          <span>Editar </span>
        </v-btn>
        <v-btn v-else @click="createFormReply()" outline small :loading="loading">
          <v-icon>add</v-icon>
          <span> Inserir dados</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <span v-if="reply && reply.form">
        {{reply.form.description}}
      </span>
    </v-flex>
  </v-layout>
</template>
<script>
  import FormReplyMixin from './FormReplyMixin'
  export default {
    name: 'FormReplyListTitle',
    mixins: [FormReplyMixin]
  }
</script>
<style scoped>
  .form-reply-list__toolbar {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .form-reply-list__toolbar >>> .v-toolbar__content {
    padding: 0px !important;
  }
</style>