export default {
  id: 'd499d9f9',
  title: 'Alterar o Impacto do Chamado',
  apiUrl: 'service-desk/ticket',
  actions: {
    delete: false,
    update: true
  },
  maxWidth: '500px',
  fields: [
    {text: 'Selecione o novo Impacto deste chamado.', type: 'subheader', edit: true},
    {value: 'chamado_impacto_id', text: 'Novo Impacto', autocomplete: true, required: true, type: 'db-autocomplete', edit: true, apiUrl: 'chamado/impacto', apiParams: {fields: 'id,nome_impacto'}, show: 'nome_impacto'}
  ],
  fieldsFixed: [
    {
      value: 'change_ticket_impact', data: true
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
