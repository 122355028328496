import { MovementPerson } from './Movement'
import {
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_CREATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE,
  PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_DELETE
} from '@/default/constants/permissions'

export class MovementEntryCollaborator extends MovementPerson {
  constructor () {
    super()

    this.urlDetails.name = 'product-entry-collaborator'

    // Change Fields
    this.editPerson.text = 'Colaborador'
    this.editPerson.wiConfig = 'collaborator'
    this.editStatus.apiUrl = 'stocks/config/status/product-entry-collaborator'
    this.editCategory.apiUrl = 'stocks/config/category/product-entry-collaborator'
    this.listPerson.text = 'Colaborador'
  }

  name = 'product-entry-collaborator';
  id = {
    id: PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR,
    create: PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_CREATE,
    update: PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE,
    delete: PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_DELETE
  };
  title = {
    list: 'Entrada p/ Colaborador',
    edit: {
      insert: 'Nova Entrada p/ Colaborador',
      update: 'Editar Entrada p/ Colaborador'
    }
  };
  apiUrl = 'stocks/product-entry/collaborator';
  icon = 'group_add';

}
