<template>
  <div
    class="wi-edit-message-field"
    :style="{ background: $wiDark ? '#212121' : '#ECEFF1', color: field.color || '#6C6E88', 'border-color': field.color }
  ">
    <div style="text-align: center;">
      <v-btn link :color="field.background" :href="field.fileUrl">
        <v-icon :color="field.color" class="mr-2">download</v-icon>
        <span :style="{ color: field.color }" v-html="field.text"></span>
      </v-btn>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'WiEditFileDownloadField',
    props: ['form', 'field']
  }
</script>
<style scoped>
  .wi-edit-message-field {
    margin-bottom: 20px;
    padding: 5px 20px 5px 20px;
  }
</style>