import Vue from 'vue'
import { MoneyFilter } from '@/default/filters/MoneyFilter'
import { NumberFilter } from '@/default/filters/NumberFilter'

const maskFilter = function (value, mask) {
  if (mask && value) {
    value = value + ''
    var ii = 0
    var r = ''
    value = value.replace(/[^0-9a-zA-Z]+/, '')
    value = value.replace(/[^0-9a-zA-Z]+/, '')
    for (var i = 0; i < mask.length; i++) {
      if (mask.charAt(i, i) === '#') {
        r += value.charAt(ii, ii)
        ii++
      } else {
        r += mask.charAt(i, i)
      }
    }
    return r
  } else {
    return value
  }
}

Vue.filter('mask', maskFilter)

Vue.filter('money', function (value) {
  return new MoneyFilter(value).format()
})

Vue.filter('float', function (value) {
  return new NumberFilter(value).format()
})

Vue.filter('number', function (value) {
  if (value) {
    if ((value.toString().split('.').length - 1) > 0) { if (value.toString().split('.')[1].length === 1) { value = value + '0' } return value.toLocaleString('pt-BR') } else { return value.toLocaleString('pt-BR') }
  } else if (value === 0) {
    return '0'
  } else {
    return value
  }
})

Vue.filter('date', function (value) {
  if (value) {
    var date = value.split('-')
    var dateFormated = ''
    for (var i = date.length - 1; i > -1; i--) {
      dateFormated += date[i]
      if (i) {
        dateFormated += '/'
      }
    }
    return dateFormated
  } else {
    return ' - '
  }
})

Vue.filter('removehtmltags', function (value) {
  if ((!value) || (value === null) || (value === '')) {
    return false
  } else {
    value = value.toString()
    return value.replace(/<[^>]*>/g, ' ')
  }
})

Vue.filter('stringlength', function (value, length) {
  if ((!value) || (value === null) || (value === '')) {
    return ''
  } else {
    value = value.toString()
    if (value.length < length) {
      return value
    } else {
      return value.substring(0, length) + '...'
    }
  }
})

Vue.filter('reverse', function (value) {
  return value.slice().reverse()
})

Vue.filter('phone', function (value) {
  let mask = '(##) # ####-####'
  if (value?.toString()?.length == 10) {
    mask = '(##) ####-####'
  }
  return maskFilter(value, mask)
})

export default {}
