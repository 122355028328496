import { Model } from '@/default/model/Model'

export class BusinessStatus extends Model {
  constructor () {
    super()
    this.list = true
    this.title = {
      list: 'Status/Etapas',
      edit: {
        insert: 'Novo Status/Etapas',
        update: 'Editar Status/Etapas'
      }
    }
    this.params = {
      limit: 10
    }
    this.icon = 'code'
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome do Status'
    }),
    description: this.fieldList({
      value: 'description',
      text: 'Observações'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, tipo, cor e ícone deste status.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do Status',
      size: ['xs12', 'sm6', 'md6', 'lg7']
    }),
    stage: this.fieldSelect({
      value: 'stage',
      text: 'Tipo de Status',
      placeholder: 'Como este status irá influenciar o negócio.',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true,
      options: [
        {
          value: 1,
          text: 'Em Andamento'
        },
        {
          value: 2,
          text: 'Concluído'
        },
        {
          value: 3,
          text: 'Cancelado'
        }
      ],
      edit: {
        create: true
      }
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor da Categoria',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    order: this.fieldNumber({
      value: 'order',
      text: 'Ordem do Status',
      size: ['xs12', 'sm6', 'md3', 'lg3']
    }),
    set_deadline: this.fieldSwitch({
      value: 'set_deadline',
      text: 'Exigir data limite',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    layout: this.fieldTextArea({
      value: 'description',
      text: 'Observações',
      placeholder: 'Use este campo para observações do status.'
    })
  }
}
