import url from '@/default/store/modules/url/url'

export class Socket {
  constructor () {
    this.socket = null
    this.host = url.state.socket ? url.state.socket.host : null
    this.port = url.state.socket ? url.state.socket.port : null
    this.onConnect = null
    this.onDisconnect = null
  }
  setHost (host) {
    this.host = host
  }
  setPort (port) {
    this.port = port
  }
  setOnConnect (onConnect) {
    this.onConnect = onConnect
  }
  setOnDisconnect (onDisconnect) {
    this.onDisconnect = onDisconnect
  }
  connect () {
    this.socket = window.io(this.host + ':' + this.port, {secure: true})
    this.socket.on('connect', () => {
      if (this.onConnect) {
        this.onConnect()
      }
    })
    this.socket.on('disconnect', () => {
      if (this.onDisconnect) {
        this.onDisconnect()
      }
    })
  }
  disconnect () {
    this.socket.disconnect()
  }
  unsubscribe ({url}) {
    this.socket.removeListener(url)
  }
  emit ({url, params}) {
    this.socket.emit(url, params)
  }
  get ({url, params, callback}) {
    this.socket.emit(url, params)
    if (callback) {
      this.socket.on(url, (response) => {
        callback(response)
        this.socket.removeListener(url)
      })
    }
  }
  subscribe ({ url, callback }) {
    this.socket.on(url, (response) => {
      callback(response)
    })
  }
  getSubscribe ({ url, params, callback }) {
    this.socket.emit(url, params)
    this.socket.on(url, (response) => {
      callback(response)
    })
  }
  private (channel) {
    const token = localStorage.getItem('f777bbf0-ef5d-45db-b77a-dd7802f92824') ? localStorage.getItem('f777bbf0-ef5d-45db-b77a-dd7802f92824') : false
    var authHeaders = {}
    if (token) {
      authHeaders = {
        'Authorization': 'Bearer ' + JSON.parse(atob(token)).token,
        'Hash': JSON.parse(atob(token)).hash,
        'IP': JSON.parse(atob(token)).ip,
        'Accept': 'application/json'
      }
    }
    var object = {
      channel: 'private-' + channel,
      name: 'subscribe',
      auth: {
        headers: authHeaders
      }
    }
    this.socket.emit('subscribe', object)
    return {
      listen: (event, callback) => {
        this.socket.on(event, (eventName, response) => {
          callback(response)
        })
      }
    }
  }
}
