<template>
  <div
      id="removePasteFormat" style="padding-bottom: 20px;">
    <vue-editor
      v-model="model"
      :editor-toolbar="customToolbar"
      useCustomImageHandler
      @imageAdded="handleImageAdded"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
export default {
  name: 'WiEditTextEditor',
  data: () => ({
    customToolbar: [
      [
        {
          'header': [false, 1, 2, 3, 4, 5, 6]
        }
      ],
      [
        {
          'size': [ 'small', false, 'large', 'huge' ]
        }
      ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [
        {align: ''},
        {align: 'center'},
        {align: 'right'},
        {align: 'justify'}
      ],
      [
        { 'color': [] },
        { 'background': [] }
      ],
      // [
      //   'video'
      // ],
      [
        {list: 'ordered'},
        {list: 'bullet'}
      ]
    ]
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      console.log('handleImageAdded', file, Editor, cursorLocation, resetUploader)
    },
    onPaste() {
      const x = document.getElementById("removePasteFormat");
      console.log(x);
      x.addEventListener("paste", (e) => {
        e.stopPropagation();
        e.preventDefault();
        let text = e.clipboardData.getData("text/plain");
        // access the clipboard using the api
        if (document.queryCommandSupported("insertText")) {
          document.execCommand("insertText", false, text);
        } else {
          document.execCommand("paste", false, text);
        }
      });
    },
  },
  mounted() {
    // this.onPaste();
},
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    money: {
      type: Object,
      default: () => ({
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      })
    },
    outline: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueEditor
  }
}
</script>

<style scoped>
  .wi-edit-wi-file-dialog {
    border-style: solid; 
    border-color: gray; 
    border-width: 1px; 
    border-radius: 5px; 
    height: 45px !important;
  }
</style>
