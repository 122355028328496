<template>
  <v-subheader>
    Todos os Tipos
    <v-spacer></v-spacer>
    <v-tooltip bottom>
      <template slot="activator">
        <v-btn @click="addNetworkType()" color="green" outline small ripple>
          <v-icon color="green lighten-1">add</v-icon>
          <span>Adicionar</span>
        </v-btn>
      </template>
      <span>Criar novo tipo de rede</span>
    </v-tooltip>
  </v-subheader>
</template>
<script>
  export default {
    name: 'NetworkTypesAppBar',
    methods: {
      refresh: function () {
        this.$emit('refresh')
      },
      addNetworkType: function () {
        this.$WiEditDialog({
          wiConfig: 'network-type',
          onSubmit: this.refresh
        })
      }
    }
  }
</script>