<template>
  <v-card color="gray">
    <v-layout v-if="!data" row wrap>
      <v-flex xs12 sm2 md2 lg2 xl2 d-flex>
        <v-select
          v-model="documentType"
          :items="['CPF', 'CNPJ']"
          label="Solo field"
          solo
          hide-details
          single-line
        ></v-select>
      </v-flex>
      <v-flex xs12 sm8 md8 lg8 xl8 d-flex>
        <v-text-field
          solo
          single-line
          hide-details
          :autofocus="true"
          v-model="document"
          :loading="loading"
          label="CPF ou CNPJ"
          @keyup.enter="searchCustomerByDocument()"
          :placeholder="`Digite o ${documentType} do cliente`"
          :mask="documentType === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm2 md2 lg2 xl2 d-flex class="elevation-3">
        <v-btn flat outline :disabled="!isValid" :loading="loading" @click="searchCustomerByDocument()">
          <v-icon>search</v-icon>
          <span> Buscar</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-list v-else class="diagnostic-list-dialog" dense>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon large>person</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title> Cliente </v-list-tile-sub-title>
          <v-list-tile-title>
            <strong>
              {{ data | stringlength(100) }}
            </strong>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action style="padding-top: 0 !important; padding-bottom: 0 !important;">
          <v-btn icon @click="setSelected(null)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    name: 'DiagnosticSearchCustomer',
    data: () => ({
      loading: false,
      documentType: 'CPF',
      document: ''
    }),
    computed: {
      isValid () {
        const documentSize = this.documentType === 'CPF' ? 11 : 14
        return this.document.length === documentSize
      }
    },
    methods: {
      searchCustomerByDocument: function () {
        if (this.isValid) {
          this.loading = true
          this.$WiApiPost({
            uri: 'register/import/customer/i-manager/by-document',
            data: {
              document: this.document
            },
            callback: this.callback,
            config: {
              msgLoad: false,
              msgSucess: false,
              msgError: {
                title: 'Erro ao buscar cliente no I-Manager',
                text: 'A API do I-Manager retornou um erro inesperado. Tente novamente ou contacte o suporte.'
              }
            }
          })
        }
      },
      callback: function (response) {
        this.loading = false
        if (response?.sucess?.status && response?.sucess?.status !== 'not-found') {
          this.setSelected(response?.sucess?.data)
        } else if (response.sucess?.status === 'not-found') {
          this.$swal({
            title: 'Cliente não encontrado',
            text: 'O cliente não foi encontrado na base de dados do I-Manager.',
            type: 'warning'
          })
        } else {
          this.$swal({
            title: 'Erro ao buscar cliente no I-Manager.',
            text: 'A API do I-Manager retornou um erro inesperado. Tente novamente ou contacte o suporte.',
            type: 'error'
          })
        }
      },
      setSelected: function (customer) {
        this.$emit('selected', { field: 'customer', data: null })
        setTimeout(() => {
          this.$emit('selected', {
            field: 'customer',
            data: customer
          })
          this.$emit('selected', { field: 'contract', data: null })
          this.$emit('selected', { field: 'host', data: null })
          this.$emit('selected', { field: 'category', data: null })
        }, 100)
      }
    },
    props: {
      data: {
        default: null
      }
    }
  }
</script>
<style scoped>
  .diagnostic-list-dialog {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>