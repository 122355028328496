<template>
  <wi-list :key="store" :store="store" :set-route="false" :load-on-mounted="!config.notLoadOnMounted" :get-params="params" :wi-edit-data="newData">
    <template slot-scope="props">
        <div v-if="componentLoader">
          <component :is="componentLoader" :config="props"></component>
        </div>
        <v-card v-else style="background: #fafafa;">
          <v-toolbar dense>
            <v-toolbar-title>
              <v-icon>{{props.icon}}</v-icon>
              <span>{{props.title}}</span>
              <span class="hidden-xs-only hidden-sm-only" v-if="props.totalItems">({{props.totalItems}})</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="hidden-xs-only hidden-sm-only" style="min-width: 450px;">
              <div class="text-xs-center">
                <wi-list-input :config="props" :solo="true"></wi-list-input>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-prms="{'id': getIdPermission(), mode: 'disabled'}"
              outline
              small
              @click="$WiEditDialog({wiConfig: props.store, onSubmit: props.refresh, data: newData})"
            >
              Adicionar Novo
            </v-btn>
            <v-btn icon flat @click="props.refresh()">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="padding: 16px 0 !important;">
            <v-layout row :style="{background: dark ? '#303030 !important' : ''}" >
              <v-flex v-bind="sizeField(config.size)">
                <finances-header v-if="config.showFilterDateToolbar" class="card--flex-toolbar" :config="props" :set-route="false"></finances-header>
                <!-- <component class="card--flex-toolbar" v-if="componentLoaderHeader" :is="componentLoaderHeader" :config="props" :set-route="false"></component> -->
                <v-card>
                  <wi-list-data-table v-if="componentLoaderTableRow" :config="props" :row-custom="true" :row-actions="true" :set-route="false" :selected-action-custom="config.components && config.components.list && config.components.list.selectedAction ? true : false">
                    <template slot="table-row" slot-scope="rowData">
                      <component :is="componentLoaderTableRow" :config="props" :props="rowData"></component>
                    </template>
                    <template slot="table-expand" slot-scope="rowData">
                      <component :is="componentLoaderTableExpand" :config="props" :props="rowData"></component>
                    </template>
                    <template slot="table-selected-action" slot-scope="data">
                      <component :is="componentLoaderTableSelectedAction" :config="props" :selected="data.selected"></component>
                    </template>
                  </wi-list-data-table>
                  <wi-list-data-table v-else :config="props" :row-custom="false" :row-actions="true" :set-route="false"></wi-list-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-toolbar dense>
            <wi-list-pagination :config="props" :set-route="false"></wi-list-pagination>
          </v-toolbar>
        </v-card>
    </template>
  </wi-list>
</template>
<script>
  import WiList from '@/default/component/WiList/WiList'
  import WiListInput from '@/default/component/WiList/WiListInput'
  import WiListDataTable from '@/default/component/WiList/WiListDataTable'
  import WiListPagination from '@/default/component/WiList/WiListPagination'
  import FinancesHeader from '@/modules/finance/pages/Finances/FinancesHeader'
  export default {
    name: 'WiListComponent',
    data () {
      return {
        loadTable: false
      }
    },
    methods: {
      sizeField: function (sizes) {
        var size = sizes ? {} : {xs12: true, sm12: true, md10: true, 'offset-md1': true, ls10: true, 'offset-ls1': true, xl10: true, 'offset-xl1': true}
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      getIdPermission: function () {
        if (this.config && this.config.id) {
          return this.config.id['create'] || this.config.id
        }
        return null
      }
    },
    computed: {
      dialog () {
        return this.$store.state.WiListDialog.active
      },
      config () {
        return this.$store.state.pages[this.store]
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      system () {
        return this.$store.state.system || []
      },
      componentLoader () {
        if (this.config.components && this.config.components.list && typeof this.config.components.list === 'function') {
          return this.config.components.list
        }
        return false
      },
      componentLoaderHeader () {
        if (this.config.components && this.config.components.list && this.config.components.list.header && typeof this.config.components.list.header === 'function') {
          return this.config.components.list.header
        }
        return false
      },
      componentLoaderTableRow () {
        if (this.config.components && this.config.components.list && this.config.components.list.tableRow && typeof this.config.components.list.tableRow === 'function') {
          return this.config.components.list.tableRow
        }
        return false
      },
      componentLoaderTableExpand () {
        if (this.config.components && this.config.components.list && this.config.components.list.tableExpand && typeof this.config.components.list.tableExpand === 'function') {
          return this.config.components.list.tableExpand
        }
        return false
      },
      componentLoaderTableSelectedAction () {
        if (this.config.components && this.config.components.list && this.config.components.list.selectedAction && typeof this.config.components.list.selectedAction === 'function') {
          return this.config.components.list.selectedAction
        }
        return false
      }
    },
    props: ['store', 'params', 'newData'],
    components: {
      'WiList': WiList,
      'WiListInput': WiListInput,
      'WiListDataTable': WiListDataTable,
      'WiListPagination': WiListPagination,
      'FinancesHeader': FinancesHeader
    }
  }
</script>
<style>
  .v-btn--bottom.v-btn--absolute.wi_list_modal {
    bottom: 60px !important;
  }
  .permission_disabled {
    color: rgba(0,0,0,.26)!important;
  }
</style>
