import { Model } from '@/default/model/Model'
import { PostFilters } from './PostFilters'

export default class Post extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1d91353e'
    this.name = 'post'
    this.title = {
      list: 'Publicações',
      edit: {
        insert: 'Nova Publicação',
        update: 'Editar Publicação'
      }
    }
    this.apiUrl = 'sites/post'
    this.apiParams = {
      order: 'date_time,desc;created_at,desc',
      with: 'site;categories'
    }
    this.icon = 'dynamic_feed'
    this.search = {
      placeholder: 'Pesquise pelo título da publicação',
      fields: ['title']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/sites/pages/Posts/Posts')
    this.filters = new PostFilters()
    this.fieldsEdit = {
      header1: this.fieldSubHeader({
        text: 'Informe os dados básicos desta publicação.'
      }),
      title: this.fieldText({
        value: 'title',
        text: 'Título',
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5']
      }),
      date_time: this.fieldDateTime({
        value: 'date_time',
        text: 'Data da publicação',
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      author_id: this.fieldSelectApi({
        value: 'author_id',
        text: 'Autor',
        required: false,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        api: {
          url: 'register/person/collaborator',
          params: {
            fields: 'id,name',
            order: 'name,asc'
          }
        },
        show: 'name'
      }),
      site_id: this.fieldWiListDialog({
        value: 'site_id',
        text: 'Site',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'site',
        show: 'site.name',
        icon: 'rss_feed',
        disabled: form => !!form.id
      }),
      file_id: this.fieldWiFileDialog({
        value: 'file_id',
        text: 'Imagem de destaque da publicação',
        placeholder: 'Selecione uma imagem para a capa da publicação',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        data: {
          is_public: true
        },
        filter: {
          is_public: true
        }
      }),
      description: this.fieldText({
        value: 'description',
        text: 'Descrição',
        size: ['xs12', 'sm12', 'md5', 'lg5']
      }),
      status_id: this.fieldSelect({
        value: 'status_id',
        text: 'Status da Publicação',
        required: true,
        options: [
          {value: 1, text: 'Rascunho'},
          {value: 2, text: 'Publicado'},
          {value: 3, text: 'Cancelado'}
        ],
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      visibility_id: this.fieldSelect({
        value: 'visibility_id',
        text: 'Visibilidade',
        required: true,
        options: [
          {value: 1, text: 'Público'},
          {value: 2, text: 'Privado'},
          {value: 3, text: 'Protegido'}
        ],
        size: ['xs12', 'sm12', 'md2', 'lg2']
      }),
      content_type_id: this.fieldSelect({
        value: 'content_type_id',
        text: 'Tipo de Conteúdo',
        required: true,
        options: [
          {value: 1, text: 'Texto Formatado'},
          {value: 2, text: 'Texto Simples'},
          {value: 3, text: 'Vídeo YouTube'},
          {value: 4, text: 'Vídeo Vimeo'}
        ],
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      header2: this.fieldSubHeader({
        text: 'Insira abaixo o conteúdo da publicação'
      }),
      content1: this.fieldTinyEditor({
        value: 'content',
        text: 'Conteúdo da publicação',
        showField: (form) => {
          return form.content_type_id === 1
        }
      }),
      content2: this.fieldTextArea({
        value: 'content',
        text: 'Conteúdo da publicação',
        placeholder: 'Conteúdo da publicação',
        showField: (form) => {
          return form.content_type_id === 2
        }
      }),
      content3: this.fieldText({
        value: 'content',
        text: 'Insira o código do vídeo do YouTube',
        placeholder: 'Insira o código do vídeo do YouTube',
        showField: (form) => {
          return form.content_type_id === 3
        }
      }),
      content4: this.fieldText({
        value: 'content',
        text: 'Insira o código do vídeo do Vimeo',
        placeholder: 'Insira o código do vídeo do Vimeo',
        showField: (form) => {
          return form.content_type_id === 4
        }
      })
    }
    this.fieldsList = [
      this.fieldList({
        value: 'id',
        text: 'Tumbnail',
        sortable: false
      }),
      this.fieldList({
        value: 'title',
        text: 'Título'
      }),
      this.fieldList({
        value: 'status_id',
        text: 'Status',
        align: 'center'
      }),
      this.fieldList({
        value: 'visibility_id',
        text: 'Visibilidade',
        align: 'center'
      }),
      this.fieldList({
        value: 'date_time',
        text: 'Publicação',
        align: 'center'
      }),
      this.fieldList({
        value: 'id',
        text: 'Opções',
        sortable: false
      })
    ]
    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }
}

class PostVisibility extends Post {
  constructor () {
    super()

    this.clearFields()

    this.fieldsEdit.visibility_id.disabled = true
    this.fieldsEdit.visibility_id.required = false

    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }
}

export class PostPublic extends PostVisibility {
  constructor () {
    super()

    this.title.list = 'Publicações Públicas'

    this.apiParams.visibility_id = 1

    this.fieldsFixed = {
      visibility_id: 1
    }

    this.clearFields()
    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }
}

export class PostPrivate extends PostVisibility {
  constructor () {
    super()

    this.title.list = 'Publicações Protegidas'

    this.apiParams.visibility_id = 2

    this.fieldsFixed = {
      visibility_id: 2
    }

    this.clearFields()
    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }
}
