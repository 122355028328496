<template>
  <div>
    <div v-if="!(dados.content.servidor_cidade.length < 1)">
      <v-layout row>
        <v-flex xs12 sm12 >
          <v-card style="margin-top: 5px; margin-bottom: 20px;">
            <v-list v-for="item in dados.content.servidor_cidade" :key="item.id" header>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{item.nome_cidade}} - {{item.uf}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{item.descricao}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-card-actions>
                  <dbdelete v-on:action="dados.refresh" store="servidor-cidade" :id="item.id"></dbdelete>
                </v-card-actions>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <v-alert style="margin:25px;" :value="true" color="yellow" >
        <v-icon color="black">info</v-icon>
        <span style="color: black;"> Nenhuma cidade vinculada ao servidor até o momento.</span>
      </v-alert>
    </div>
  </div>
</template>
<script>
  import DBDelete from '@/default/components/DBDelete'
  export default {
    data () {
      return {
      }
    },
    props: ['dados'],
    components: {
      dbdelete: DBDelete
    }
  }
</script>