<template>
  <v-card :class="destackStage">
    <StageHeader
      :stage="stage"
      :execution="execution"
      :status="stage.status"
      :openedAt="stage.opened_at"
      :showStageContent="showStageContent"
      @setShowStageContent="setShowStageContent"
    ></StageHeader>
    <v-slide-y-transition>
      <keep-alive v-if="showStageContent">
        <component
          :data="data"
          :stage="stage"
          :is="stageComponent()"
          :status="stage.status"
          :execution="execution"
          @updateProcess="updateProcess"
        ></component>
      </keep-alive>
    </v-slide-y-transition>
    <StageFooter
      :data="data"
      :stage="stage"
      :isValid="isValid"
      :status="stage.status"
      :execution="execution"
      @updateProcess="updateProcess"
    ></StageFooter>
  </v-card>
</template>
<script>
  import Decision from './Decision'
  import FormReply from './FormReply'
  import StageMixin from './StageMixin'
  import StageHeader from './StageHeader'
  import StageFooter from './StageFooter'
  import ShowMessage from './ShowMessage'
  import { mapState, mapActions } from 'vuex'
  import DiagnosticCreate from './DiagnosticCreate'
  import DiagnosticSetStatus from './DiagnosticSetStatus'
  import DiagnosticHostCheck from './DiagnosticHostCheck'
  import DiagnosticShowEvents from './DiagnosticShowEvents'
  import DiagnosticSetCategory from './DiagnosticSetCategory'
  import DiagnosticCheckCategory from './DiagnosticCheckCategory'
  import DiagnosticSelectCategory from './DiagnosticSelectCategory'
  import DiagnosticRecreateDocument from './DiagnosticRecreateDocument'
  import DiagnosticCheckHostTechnology from './DiagnosticCheckHostTechnology'
  export default {
    name: 'Stage',
    data: () => ({
      data: {},
      isValid: false,
      showStageContent: false
    }),
    computed: {
      ...mapState('ProcessExecution', ['execution']),
      destackStage () {
        return {
          'elevation-24': this.stage.id == this.execution.current_stage_id,
          'elevation-5': this.stage.id != this.execution.current_stage_id
        }
      }
    },
    methods: {
      ...mapActions('ProcessExecution', ['refreshExecution']),
      stageComponent: function () {
        switch (this.stage.stage.type.code) {
          case 'decision':
            return Decision
          case 'form-reply':
            return FormReply
          case 'show-message':
            return ShowMessage
          case 'diagnostic-create':
            return DiagnosticCreate
          case 'diagnostic-host-check':
            return DiagnosticHostCheck
          case 'diagnostic-set-status':
            return DiagnosticSetStatus
          case 'diagnostic-show-events':
            console.log('asdasdasd');
            return DiagnosticShowEvents
          case 'diagnostic-set-category':
            return DiagnosticSetCategory
          case 'diagnostic-recreate-document':
            return DiagnosticRecreateDocument
          case 'diagnostic-select-category':
            return DiagnosticSelectCategory
          case 'diagnostic-check-category':
            return DiagnosticCheckCategory
          case 'diagnostic-check-host-technology':
            return DiagnosticCheckHostTechnology
        }
      },
      updateProcess: function ({ data, isValid, autoSendProcessStage = false }) {
        if (data) {
          this.data = data
        }
        this.isValid = isValid
        if (autoSendProcessStage && this.isValid) {
          this.sendProcessStage()
        }
      },
      setShowStageContent: function (showStageContent) {
        this.showStageContent = showStageContent
      },
      setProcessData: function () {
        if (this.stage.data) {
          this.data = this.stage.data
        }
      },
      sheckStageTypeConfig: function () {
        if (this.stage?.status?.code != 'closed' && this.stage?.status?.code != 'canceled') {
          this.setShowStageContent(true)
        }
        // if (this.stage.stage.type.show_content) {
        //   this.setShowStageContent(true)
        // }
      }
    },
    created: function () {
      this.setProcessData()
      this.sheckStageTypeConfig()
    },
    watch: {
      stage () {
        this.setProcessData()
        this.$forceUpdate()
      }
    },
    props: {
      stage: {
        type: Object,
        required: true
      }
    },
    mixins: [
      StageMixin
    ],
    components: {
      StageHeader,
      StageFooter
    }
  }
</script>