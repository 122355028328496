<template>
  <div>
    <wi-list :key="store" :store="store" :set-route="true" :load-on-mounted="!config.notLoadOnMounted">
      <template slot-scope="props">
        <div v-if="componentLoader">
          <component :is="componentLoader" :config="props"></component>
        </div>
        <v-card v-else flat style="background: #fafafa;">
          <v-toolbar
            class="wi-list-page__toolbar"
            flat extended center-align
            :color="!$wiDark ? 'primary' : 'dark'"
            style="color: white;  background-image: url('static/background/background-blue.png');"
          >
            <v-toolbar-title style="font-size: 18px;">
              <v-btn icon ripple class="ml-0 mr-0" @click="$router.go(-1)" style="color: white;">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <span>
                <span v-if="props.title">{{props.title }} </span>
                <span v-if="props.totalItems"> ({{ props.totalItems | number }})</span>
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="hidden-xs-only hidden-sm-only" style="max-width:600px; min-width: 400px;">
              <div class="text-xs-center">
                <wi-list-input :config="props"></wi-list-input>
              </div>
            </div>
            <v-spacer></v-spacer>
            <WiListImports
              v-if="config && config.imports"
              mode="icon"
              :imports="config.imports"
              @imported="props.refresh"
            ></WiListImports>
            <WiListExports
              v-if="config && config.exports"
              mode="icon"
              :imports="config.exports"
              @exported="processExportedFile"
            ></WiListExports>
            <WiListFilters
              v-if="config && config.filters"
              mode="icon"
              :config="config.filters"
              :params="props.params"
              @filter="filters => { props.setParams(filters, true) }"
            ></WiListFilters>

            <v-btn class="hidden-md-only hidden-lg-only hidden-xl-only" icon small @click="props.searchDialogOpen()">
              <v-icon color="white">search</v-icon>
            </v-btn>
            <WiListInputDialog :config="props"></WiListInputDialog>

            <v-btn icon flat color="white" class="ml-0 mr-0" :loading="props.loading" @click="props.refresh()">
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-btn 
              v-prms="{'id': getIdPermission()}"
              v-if="checkButtonConfig('create')" 
              class="white hidden-xs-only hidden-sm-only" 
              style="color: black" 
              @click="$WiEditDialog({wiConfig: props.store, onSubmit: props.refresh, data: {}})"
            >
              <v-icon>add</v-icon> 
              <span> Adicionar Novo</span>
            </v-btn>
            <v-btn 
              v-prms="{'id': getIdPermission()}"
              v-if="checkButtonConfig('create')" 
              icon flat 
              color="white" 
              class="hidden-md-only hidden-lg-only hidden-xl-only" 
              @click="$WiEditDialog({wiConfig: props.store, onSubmit: props.refresh, data: {}})"
            >
              <v-icon color="white">add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-layout row pb-2 :style="{background: $wiDark ? '#303030 !important' : ''}" style="padding: 0 10px;">
            <v-flex v-bind="sizeField(config.size)">
              <!-- <finances-header v-if="config.showFilterDateToolbar" class="card--flex-toolbar" :config="props" :set-route="true"></finances-header> -->
              <WiListDateToolbar v-if="config.showFilterDateToolbar"  class="card--flex-toolbar" :config="props" :set-route="true"></WiListDateToolbar>
              <div :class="{'card--flex-toolbar': !config.showFilterDateToolbar}">
                <component v-if="componentLoaderHeader" :is="componentLoaderHeader" :config="props" :set-route="true"></component>
                <v-card>
                  <wi-list-data-table v-if="componentLoaderTableRow" :config="props" :row-custom="true" :row-actions="true" :set-route="true" :selected-action-custom="config.components && config.components.list && config.components.list.selectedAction ? true : false">
                    <template slot="table-row" slot-scope="rowData">
                      <component :is="componentLoaderTableRow" :config="props" :props="rowData"></component>
                    </template>
                    <template slot="table-expand" slot-scope="rowData">
                      <component :is="componentLoaderTableExpand" :config="props" :props="rowData"></component>
                    </template>
                    <template slot="table-selected-action" slot-scope="data">
                      <component :is="componentLoaderTableSelectedAction" :config="props" :selected="data.selected"></component>
                    </template>
                  </wi-list-data-table>
                  <wi-list-data-table v-else :config="props" :row-custom="false" :row-actions="true" :set-route="true"></wi-list-data-table>
                </v-card><br><br>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
        <v-footer inset app height="47" style="box-shadow: 0 2px 4px 10px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);">
          <wi-list-pagination :config="props" :set-route="true"></wi-list-pagination>
        </v-footer>
      </template>
    </wi-list>
  </div>
</template>
<script>
  import WiListImports from './Imports'
  import WiListExports from './Exports'
  import WiListFilters from './Filters'
  import WiListInputDialog from './WiListInputDialog'
  import WiList from '@/default/component/WiList/WiList'
  import WiListInput from '@/default/component/WiList/WiListInput'
  import WiListDataTable from '@/default/component/WiList/WiListDataTable'
  import WiListPagination from '@/default/component/WiList/WiListPagination'
  import WiListDateToolbar from '@/default/component/WiList/WiListDateToolbar'
  import FinancesHeader from '@/modules/finance/pages/Finances/FinancesHeader'
  export default {
    name: 'Listing',
    data () {
      return {
        loadTable: false,
        store: this.$route.params.page
      }
    },
    methods: {
      checkIfConfigExist: function (config) {
        if (!this.$store.state.pages[config]) {
          this.$router.push({ name: 'main' })
        } else if (!this.config.list) {
          this.$router.push({ name: 'main' })
        } else {
          this.load = true
          return true
        }
        this.load = false
        return false
      },
      checkButtonConfig: function (action) {
        if (this.config.actions) {
          if (typeof this.config.actions[action] === 'function') {
            return this.config.actions[action](this.form)
          } else {
            return this.config.actions[action]
          }
        }
        return true
      },
      setTitle: function () {
        var title = this.config.title && this.config.title.list ? this.config.title.list : this.config.title
        document.title = 'Lista de ' + title + ' - ' + this.system.name
        this.$route.meta['title'] = title
        this.$route.meta['icon'] = this.config.icon && this.config.icon.list ? this.config.icon.list : this.config.icon
      },
      routeParamsPageChange: function (page) {
        this.checkIfConfigExist(page)
        this.store = page
      },
      sizeField: function (sizes) {
        var size = sizes ? {} : {xs12: true, sm12: true, md10: true, 'offset-md1': true, ls10: true, 'offset-ls1': true, xl10: true, 'offset-xl1': true}
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      getIdPermission: function () {
        if (this.config && this.config.id) {
          return this.config.id['create'] || this.config.id
        }
        return null
      },
      processExportedFile: function (response) {
        if (
          response && response.data &&
          response.data.status && response.data.data && response.data.data.file_url
        ) {
          window.open(response.data.data.file_url, '_blank')
        } else {
          setTimeout(() => {
            this.$swal({
              title: 'Nenhum dado encontrado para exportar',
              text: 'Por favor, verifique os filtros e tente novamente.',
              type: 'warning'
            })
          }, 1000);
        }
      }
    },
    computed: {
      config () {
        return this.$store.state.pages[this.store]
      },
      system () {
        return this.$store.state.system || []
      },
      componentLoader () {
        if (this.config.components && this.config.components.list && typeof this.config.components.list === 'function') {
          return this.config.components.list
        }
        return false
      },
      componentLoaderHeader () {
        if (this.config.components && this.config.components.list && this.config.components.list.header && typeof this.config.components.list.header === 'function') {
          return this.config.components.list.header
        }
        return false
      },
      componentLoaderTableRow () {
        if (this.config.components && this.config.components.list && this.config.components.list.tableRow && typeof this.config.components.list.tableRow === 'function') {
          return this.config.components.list.tableRow
        }
        return false
      },
      componentLoaderTableExpand () {
        if (this.config.components && this.config.components.list && this.config.components.list.tableExpand && typeof this.config.components.list.tableExpand === 'function') {
          return this.config.components.list.tableExpand
        }
        return false
      },
      componentLoaderTableSelectedAction () {
        if (this.config.components && this.config.components.list && this.config.components.list.selectedAction && typeof this.config.components.list.selectedAction === 'function') {
          return this.config.components.list.selectedAction
        }
        return false
      }
    },
    mounted: function () {
      this.checkIfConfigExist(this.$route.params.page)
    },
    watch: {
      '$route.params.page': 'routeParamsPageChange'
    },
    components: {
      WiListFilters,
      'WiList': WiList,
      WiListInputDialog,
      'WiListInput': WiListInput,
      'WiListImports': WiListImports,
      'WiListExports': WiListExports,
      'WiListDataTable': WiListDataTable,
      'WiListPagination': WiListPagination,
      'WiListDateToolbar': WiListDateToolbar,
      'FinancesHeader': FinancesHeader
    }
  }
</script>
<style>
.card--flex-toolbar {
  margin-top: -56px;
}
.wi-list-page__toolbar > .v-toolbar__content {
  padding: 0 10px;
}
</style>