import { BusinessFinanceEdit } from '@/modules/shared/config/Business/BusinessFinanceEdit'

export class FinanceEdit extends BusinessFinanceEdit {
  constructor() {
    super()
    this.id = 'b41dcfec'
    this.title = {
      list: '',
      edit: {
        create: 'Criar Parcela',
        update: 'Editar parcela'
      }
    }
    this.apiUrl = 'sales/sale/finance'
    this.fieldsFixed = [
      { value: 'in_out', data: 1 }
    ]
  }
}
