<template>
  <td class="justify-center ">
    <div v-if="config.mode != 'select'" style="min-width: 80px !important;">
      <v-btn
        v-if="checkButtonConfig('update')"
        @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: props.item})" 
        v-prms="{'id': getIdPermission('update', config.config), mode: 'disabled'}" 
        slot="active" 
        icon 
        class="mx-0 wrapper"
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <db-delete 
        v-if="checkButtonConfig('delete')" 
        v-prms="{'id': getIdPermission('delete', config.config), mode: 'disabled'}" 
        class="mx-0 wrapper" 
        v-on:action="config.refresh" 
        :store="config.store" 
        :id="props.item.id"
        :icon="deleteIcon"
      ></db-delete>
    </div>
    <div v-else>
      <v-btn flat icon outline color="green" @click="config.select(props.item)" class="mx-0">
        <v-icon large outlined>done</v-icon>
      </v-btn>
    </div>
  </td>
</template>
<script>
  export default {
    name: 'WiListDataTableActions',
    methods: {
      getIdPermission: function (action, config) {
        if (config && config.id) {
          return config.id[action] || config.id
        }
        return null
      },
      checkButtonConfig: function (action) {
        var active = true
        if (this.config.config.actions) {
          if (typeof this.config.config.actions[action] === 'function') {
            active = this.config.config.actions[action](this.props.item)
          } else {
            active = this.config.config.actions[action]
          }
        }
        return active
      }
    },
    props: {
      config: Object,
      props: Object,
      deleteIcon: String
    }
  }
</script>
<style scoped>
  .permission_disabled >>> * {
    color: #B0B0B0 !important;
  }
</style>