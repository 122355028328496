<template>
  <div>
    <div>
      <v-select
      v-model="form[formField]"
      :items="content"
      :label="label" 
      :placeholder="loading ? 'Buscando, aguarde...' : getPlaceholder"
      :item-text="show"
      :disabled="loading || disabled"
      :item-value="value || 'id'"
      :loading="loading"
      :required="required"
      :rules="rules"
      :autocomplete="autocomplete"
      :multiple="multiple"
      ></v-select>
    </div>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'DBSelect',
    data () {
      return {
        content: [],
        loading: false
      }
    },
    computed: {
      getPlaceholder () {
        var p = this.placeholder || this.label
        return p
      }
    },
    beforeDestroy: function () {},
    props: ['apiUrl', 'apiParams', 'form', 'formField', 'value', 'show', 'label', 'placeholder', 'disabled', 'required', 'rules', 'autocomplete', 'multiple'],
    mounted: function () {
      this.load()
    },
    methods: {
      load: function () {
        var params = this.apiParams || {}
        if (!params.limit) { params['limit'] = 1000 }
        this.loading = true
        callApi.get({uri: this.apiUrl, params: params || '', sucess: this.loadSucess, error: this.loadError})
      },
      loadSucess: function (data) {
        this.content = data.data.data
        this.loading = false
      },
      loadError: function () {
        this.loading = false
      }
    }
  }
</script>