<template>
  <v-list-tile avatar ripple :class="{selected: selected}" :to="notification.route(notification)">
    <v-list-tile-avatar>
      <img v-if="notification.data.creator && notification.data.creator.image" :src="notification.data.creator.image"/>
      <img v-else src="static/icons/user_icon_1.png"/>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-sub-title class="text--primary">
        <strong v-if="notification.data.creator" v-text="notification.data.creator.name"></strong>
        <span v-html="notification.text(notification)"></span>
      </v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-action>
      <v-list-tile-action-text>{{ notification.created_at | moment('DD/MM/YY HH:mm') }}</v-list-tile-action-text>
    </v-list-tile-action>
  </v-list-tile> 
</template>
<script>
  export default {
    name: 'Notification',
    computed: {
      selected () {
        return this.$route.query.notification == this.notification.id
      }
    },
    methods: {
      goRoute: function (route) {
        if (route) {
          this.drawer = false
          this.$router.push(route)
        }
      }
    },
    props: {
      notification: {
        required: true
      }
    }
  }
</script>
<style scoped>
  .selected {
    background: rgb(219, 219, 159);
  }
</style>