<template>
  <div>
    <div v-if="loadings.load">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <h1
          v-t="'modules.talks.pages.talkView.talk.messages.loading.getMessages'"
        ></h1>
      </div>
    </div>
    <div v-else>              
      <div
        class="chat__toolbar-area"
        :style="{
          'background': $wiDark ? '#1C252E' : '#F9FAFB'
        }"
      >
        <v-toolbar dense flat :color="primaryColor" class="white--text elevation-10 chat__toolbar">
          <v-toolbar-title class="chat__toolbar-title" @click="profileDialog = true">
            <v-avatar size="40">
              <img v-if="chat.image && chat.image" :src="chat.image.url">
              <img v-else-if="chat.type_id == 1" src="static/icons/user_icon_1.png" />
              <img v-else-if="chat.type_id == 2" src="static/icons/group_icon_1.jpg" />
            </v-avatar>
            <span class="chat__toolbar-title-text">
              <span class="ml-2" style="font-size: 16px;">{{ chat.name }}</span>
              <span v-if="chat.type_id == 1" class="ml-2" style="font-size: 12px; font-weight: 100;">
                <!-- Online -->
              </span>
              <span v-else-if="chat.type_id == 2" class="ml-2" style="font-size: 12px; font-weight: 100;">
                {{ `${chat.persons.length} membro${chat.persons.length > 1 ? 's' : ''}` }}
              </span>
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon>
            <v-icon color="white">more_vert</v-icon>
          </v-btn> -->
          <v-btn icon @click="getChat(chatUuid)">
            <v-icon color="white">refresh</v-icon>
          </v-btn>
          <v-btn icon @click="closeAction()">
            <v-icon color="white">close</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <div style="
        padding:0 !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
      ">
        <div v-if="chat && chat.id" style="width: 100%;">
          <div
            style="
              padding:0 !important;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: stretch;
              align-content: stretch;
            "
          >
            <v-container
              id="container"
              fluid
              class="chat__message-area"
              :style="{
                'background': $wiDark ? '#1C252E' : '#F9FAFB'
              }"
            >
              <div class="chat__message-area-content">
                <div style="width: 100%; text-align: center; padding-top: 20px;">
                  <v-btn
                    @click="paginateMessages()"
                    :loading="loadings.paginate"
                    :disabled="!paginate.next_page_url"
                  >
                    <v-icon>refresh</v-icon>
                    <span
                      v-t="'modules.talks.pages.talkView.talk.labels.loadMore'"
                    ></span>
                  </v-btn>
                </div>
                <TalkMessage
                  v-for="(message, messageKey) in messagesOrdered"
                  :key="messageKey"
                  :chat="chat"
                  :message="message"
                ></TalkMessage>
              </div>
              <div>
                <ChatFooter
                  v-on:scroll="scrollToEnd"
                  v-on:refresh="() => { getChat(chatUuid) }"
                ></ChatFooter>  
              </div>
            </v-container>
          </div>      
        </div>
        <div v-else style="width: 100%; display: flex; flex-direction: column; justify-content: center;">
          <div style="width: 100%; text-align: center; margin: auto; max-width: 400px;">
            <img src="static/background/whatsapp-not-found.png?url=temp1" alt="" style="width: 100%">
            <br>
            <span
              style="font-size: 20px;"
              v-t="'modules.talks.pages.talkView.talk.notFound'"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <ChatProfileDialog
      :chat="chat"
      :active="profileDialog"
      @onActive="profileDialog = $event"
    ></ChatProfileDialog>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import TalkMessage from '@/default/component/WiMessage'
  import ChatFooter from './ChatFooter'
  import ChatProfileDialog from './ChatProfileDialog'
  export default {
    name: 'ChatMessages',
    data: () => ({
      currentHeight: 0,
      pageHeight: innerHeight,
      drawer: false,
      profileDialog: false
    }),
    computed: {
      ...mapState('Chat', ['chat', 'messages', 'paginate', 'loadings', 'lastMessage']),
      ...mapGetters('Chat', ['messagesOrdered']),
      chatUuid () {
        return this.setChatUuid || this.$route.params.id
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapActions('Chat', ['getChat', 'resetChat', 'subscribeOnChat', 'unsubscribeOnChat', 'paginateMessages', 'unseenMessages']),
      chatChange: function () {
        this.resetChat()
        this.getChat(this.chatUuid)
        this.unsubscribeOnChat(this.$route.query.previousChatId)
        this.subscribeOnChat(this.chatUuid)
      },
      scrollToEnd: function (timeout) {
        setTimeout(() => {
          var container = this.$el.querySelector('#container')
          if (container) {
            container.scrollTop = container?.scrollHeight || 0
          }
        }, timeout)
      },
      onMessageChange: function () {
        this.scrollToEnd(500)
        this.scrollToEnd(1000)
        this.chatUnseenMessages()
        this.$forceUpdate()
      },
      chatUnseenMessages: function () {
        var previousChatId = this.$route.query.previousChatId
        if (previousChatId) {
          this.unseenMessages(previousChatId)
        }
      },
      setCurrentHeight: function (heigth) {
        this.currentHeight = heigth
        this.setInnerHeight()
      },
      setInnerHeight: function () {
        let heightPerson = 46
        let heightDiff = 145
        const breackPoint = this.$vuetify.breakpoint.name
        if (this.mode == 'dialog') {
          if (breackPoint === 'xs' || breackPoint === 'sm') {
            heightDiff = 93
          } else {
            heightDiff = 188
          }
        }
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          heightPerson = 0
        }
        this.pageHeight = (((this.currentHeight || innerHeight) - heightDiff) - heightPerson) + 'px'
      },
      closeAction: function () {
        if (this.mode === 'dialog') {
          this.$emit('onClose')
        } else {
          this.$router.push({ name: 'chat-view' })
        }
      }
    },
    mounted: function () {
      window.addEventListener('resize', () => {
        this.setInnerHeight(innerHeight)
      }, true)
      this.setInnerHeight(innerHeight)
      this.getChat(this.chatUuid)
      this.subscribeOnChat(this.chatUuid)
    },
    beforeDestroy: function () {
      this.unsubscribeOnChat(this.chatUuid || this.chat.uuid)
      this.resetChat()
      this.unseenMessages(this.chatUuid || this.chat.uuid)
    },
    props: {
      setChatUuid: {
        type: Number,
        default: null
      },
      mode: {
        type: String,
        default: 'page'
      }
    },
    watch: {
      '$route': 'chatChange',
      'loadings.load': 'onMessageChange',
      'lastMessage': 'onMessageChange',
      innerHeight: 'setCurrentHeight',
      chat: 'setInnerHeight',
    },
    components: {
      TalkMessage,
      ChatFooter,
      ChatProfileDialog
    }
  }
</script>
  
<style scoped>
  .chat__message-area { 
    margin-bottom: 45px;
    padding: 0 !important;
    overflow-y: scroll;
    background-repeat: repeat;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: calc(100vh - 168px);
  }
  .chat__message-area-content {
    padding: 0px 20px 20px 20px;
  }
  .chat__toolbar-area {
    padding: 10px 10px 0px 10px;
  }
  .chat__toolbar {
    border-radius: 10px !important;
  }
  .chat__toolbar-title {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .chat__toolbar-title-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
