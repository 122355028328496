<template>
  <v-card class="elevation-17 host__info">
    <v-list-tile class="host__info-tile" avatar>
      <v-list-tile-avatar class="host__info-avatar">
        <v-avatar>
          <img :src="`/static/icons/monitor/${icon}`">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>{{ title }}</v-list-tile-sub-title>
        <v-list-tile-title v-html="content || '- - -'"></v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action v-if="action">
        <v-btn icon ripple @click="executeAction()">
          <v-icon>info</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
  </v-card>
</template>
<script>
  export default {
    name: 'HostInfo',
    methods: {
      executeAction: function () {
        this.$emit('action')
      }
    },
    props: {
      title: {
        required: true
      },
      content: {
        default: null
      },
      icon: {
        required: true
      },
      action: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped>
  .host__info {
    margin-left: 5px;
    margin-right: 5px;
  }
  .host__areas {
    margin-bottom: 20px;
  }
  .host__info-number {
    font-size: 18px;
  }
  .host__info-text {
    font-size: 14px;
  }
  .host__info--left {
    margin-right: 5px;
  }
  .host__info--right {
    margin-left: 5px;
  }
  .host__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .host__info-avatar {
    min-width: 50px !important;
  }
  .host__row--bottom {
    margin-bottom: 10px;
  }
  .host__row--top {
    margin-top: 10px;
  }
</style>
