<template>
  <div>
    <v-divider class="hidden-md-only hidden-lg-only hidden-xl-only"></v-divider>
    <v-spacer class="hidden-xs-only hidden-sm-only"></v-spacer>
    <v-list subheader two-line dense :style="{background: dark ? '#212121' : '#FAFAFA'}" style="padding-left: 5px; padding-bottom: 2px; padding-top: 2px;">
      <v-menu v-model="statusMenu" :close-on-content-click="false" :max-width="250"  style="max-width: 250px;">
        <template v-slot:activator="{ on }">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon large :color="dados.content.cor_status || 'orange'">
                {{dados.content.icone_status}}
              </v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title v-if="!dados.content.deadline_formated" style="font-size: 14px;">
                Status:
              </v-list-tile-sub-title>
              <v-list-tile-title style="font-size: 18px;">
                <strong :style="{color: dados.content.cor_status || 'orange'}">
                  <span>{{dados.content.nome_status}}</span>
                </strong>
              </v-list-tile-title>
              <v-list-tile-sub-title v-if="dados.content.deadline_formated" style="font-size: 14px;" :style="{color: $WiSecondsColor({seconds: dados.content.deadline_seconds, config: {disable: dados.content.stage != 1}})}">
                <strong>até {{dados.content.deadline_formated}}</strong>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip v-prms="{'id': 'aeab7aa0'}" bottom>
                <v-btn slot="activator" icon flat v-on="on">
                  <v-icon color="black">edit</v-icon>
                </v-btn>
                <span class="hidden-xs-only hidden-sm-only">
                  <span>Alterar Status Deste Chamado</span>
                </span>
              </v-tooltip>
              <v-tooltip v-prms="{'id': 'aeab7aa0'}" bottom>
                <v-btn slot="activator" icon flat @click="dialogTicketStatusLog = true">
                  <v-icon color="black">info</v-icon>
                </v-btn>
                <dialog-status-log :chamado="dados.content.id" :active="dialogTicketStatusLog" v-on:close="() => { dialogTicketStatusLog = false }"></dialog-status-log>
                <span class="hidden-xs-only hidden-sm-only">
                  <span>Alterar Status Deste Chamado</span>
                </span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
        </template>
        <v-card>
          <v-list>
            <v-divider></v-divider>
            
            <template v-for="(status, statusKey) in dados.content.ticket_status"> 
              <div v-if="isSelectable(status)" :key="statusKey">
                <v-list-tile avatar @click="changeStatus(status)">
                  <v-list-tile-avatar>
                    <v-icon large :color="status.cor_status || 'orange'">{{status.icone_status}}</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="font-size: 14px;">Alterar status para:</v-list-tile-sub-title>
                    <v-list-tile-title style="font-size: 18px;">
                      <strong :style="{color: status.cor_status || 'orange'}">{{status.nome_status}}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </v-card>
      </v-menu>
    </v-list>
    <v-dialog v-model="dialogNetwork" scrollable max-width="80%">
      <v-card>
        <v-toolbar dense persistent scrollable max-width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
          <strong>Selecione uma rede para adicionar.</strong>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="padding: 0; margin: 0;">
          <grupos-topologia v-if="dialogNetwork" :select-devices-to-network-calback="addDevicesToTicket"></grupos-topologia>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="dialogNetwork = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import DialogStatusLog from '@/modules/service-desk/pages/Ticket/StatusLogs/TicketStatusLogs'
  import GruposTopologia from '@/modules/network/pages/Grupo/GruposTopologia'
  export default {
    name: 'TicketHeaderStatus',
    data () {
      return {
        statusMenu: false,
        statusSelected: null,
        dialogNetwork: false,
        dialogTicketStatusLog: false
      }
    },
    methods: {
      changeStatus: function (status) {
        console.log(status)
        this.statusSelected = status
        this.confirmationTicketData(status)
      },
      confirmationTicketData: function (status) {
        if (this.$Auth.checkPermission('d237fbcd-2a0d-42eb-beef-409d29813ad3') && status.get_confirmation === 'sim' && this.dados.content.device_count < 1) {
          this.confirmationDevices()
          return null
        }
        if (status.get_confirmation === 'sim' || status.exigir_data === 'sim') {
          this.confirmationCategoryAndDate(status)
          return null
        }
        this.sendStatusChangesNotConfirmation(status)
      },
      confirmationDevices: function () {
        this.$swal({
          title: 'Por favor, vincule este chamado a no mínimo um dispositivo de rede.',
          text: 'Selecione uma rede e clique em listar dispositivos.',
          type: 'info'
        })
        this.dialogNetwork = true
      },
      confirmationCategoryAndDate: function (status) {
        var ticket = this.dados.content
        var data = {
          id: ticket.id,
          chamado_status_id: status.id,
          get_confirmation: status.get_confirmation === 'sim',
          nome_categoria_grupo_previsao: ticket.nome_categoria_grupos,
          nome_categoria: ticket.nome_categoria,
          icone_categoria: ticket.icone_categoria,
          cor_categoria: ticket.cor_categoria,
          sub_category_count: ticket.sub_category_count,
          require_date_limit: status.exigir_data === 'sim'
        }
        if (this.ticketNotSubCategory()) {
          data['chamado_categoria_id'] = ticket.chamado_categoria_id
        }
        if (data.get_confirmation && data.sub_category_count > 0) {
          data['alter_categoria_chamado'] = 'sim'
        }
        this.$WiEditDialog({
          wiConfig: 'chamados-concluido',
          data: data,
          onSubmit: this.dados.refresh
        })
      },
      sendStatusChangesNotConfirmation: function (status) {
        this.$swal({title: 'Deseja realmente alterar o status deste chamado?', text: 'Essa ação não poderá ser desfeita!', type: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Sim, alterar!', cancelButtonText: 'Cancelar'})
        .then((response) => {
          if (response.value) {
            this.$swal({title: 'Carregando...', text: 'Aguarde até que a ação seja finalizada.', onOpen: () => { this.$swal.showLoading() }})
            callApi.put({
              uri: `service-desk/ticket`,
              id: this.dados.content.id,
              data: {id: this.dados.content.id, chamado_status_id: status.id, alterar_status: true},
              sucess: (data) => {
                if (data.status === 201) {
                  this.$swal({ type: 'success', title: 'Tudo certo!', text: 'Concluído com sucesso.' })
                  this.dados.refresh()
                } else {
                  this.$swal({ type: 'error', title: 'Oops... Algo deu errado!', text: 'Tente novamente ou contacte o suporte.' })
                }
              },
              error: (err) => {
                console.log(err)
              }
            })
          }
        })
      },
      ticketNotSubCategory: function () {
        return this.dados.content.sub_category_count < 1
      },
      isSelectable: function (status) {
        return this.isPermissionStatus(status) && this.statusIsNotCurrent(status) && this.statusIsSelectableToCurrentStatus(status)
      },
      isPermissionStatus: function (status) {
        return status.show_status.length == 0 || status.show_status.indexOf(this.User.profile.id) > -1
      },
      statusIsNotCurrent: function (status) {
        return status.id !== this.dados.content.chamado_status_id
      },
      statusIsSelectableToCurrentStatus: function (status) {
        return !status.chamado_status_id || status.chamado_status_id === this.dados.content.chamado_status_id
      },
      addDevicesToTicket: function (devices) {
        if (devices) {
          this.$swal({
            title: 'Deseja realmente vincular ' + (devices.length && devices.length > 0 ? devices.length : 1) + ' dispositivo(s) a este chamado?',
            text: 'Esta ação só poderá ser desfeita manualmente!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, Vincular!',
            cancelButtonText: 'Cancelar'
          })
          .then((response) => {
            if (response.value) {
              callApi.post({
                uri: 'service-desk/ticket/device',
                data: this.makeSendDevicesToTicket(this.dados.content.id, devices),
                msgLoad: {title: 'Vinculando, por favor aguarde.'},
                sucess: (data) => {
                  console.log(data)
                  this.dados.refresh()
                },
                msgSucess: {title: 'Dispositivos vinculadas com sucesso!'},
                error: (err) => {
                  console.log(err)
                },
                msgError: true
              })
            }
          })
        }
      },
      makeSendDevicesToTicket: function (ticketId, devices) {
        var dataToSend = {ticket_id: ticketId, devices: []}
        if (Array.isArray(devices)) {
          devices.filter((device) => {
            dataToSend.devices.push(device.id)
          })
        } else {
          dataToSend.devices.push(devices.id)
        }
        return dataToSend
      }
    },
    props: ['dados'],
    computed: {
      style () {
        return this.$store.state.style || {}
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      User () {
        return this.$store.state.User || {}
      },
      system () {
        return this.$store.state.system || []
      }
    },
    components: {
      DialogStatusLog,
      GruposTopologia
    }
  }
</script>
