import { Model } from '@/default/model/Model'
import { PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE } from '@/default/constants/permissions'

export default class HGWWifiConfiguration extends Model {
  constructor () {
    super()
    this.id = PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE
    this.name = 'hgw-wifi-configuration'
    this.title = 'Configurar WiFi'
    this.apiUrl = 'networks/test-management-vtal/event/action'
    this.icon = 'settings_remote'

    this.fields = []

    this.maxWidth = '800px'

    this.setFields([
      this.fieldSubHeader({
        text: 'Altere a configuração atual e clique em inserir.'
      }),
      this.fieldSelect({
        value: 'adminStatus',
        text: 'Status da rede WiFi',
        options: [
          {value: true, text: 'Ligado'},
          {value: false, text: 'Desligado'}
        ],
        size: ['xs12', 'sm12', 'md6', 'lg6'],
      }),
      this.fieldText({
        value: 'SSID',
        text: 'SSID',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      }),
      this.fieldSelect({
        value: 'mode',
        text: 'Modo de encriptação',
        options: [
          {value: 'WPAand11i', text: 'WPAand11i'},
          {value: 'WPA2', text: 'WPA2'},
          {value: 'WEP', text: 'WEP'}
        ],
        size: ['xs12', 'sm12', 'md4', 'lg4'],
      }),
      this.fieldSelect({
        value: 'wifiChannel',
        text: 'Canal do rádio',
        options: [
          {value: 'auto', text: 'Auto'},
          {value: 1, text: '1'},
          {value: 2, text: '2'},
          {value: 3, text: '3'},
          {value: 4, text: '4'},
          {value: 5, text: '5'},
          {value: 6, text: '6'},
          {value: 7, text: '7'},
          {value: 8, text: '8'},
          {value: 9, text: '9'},
          {value: 10, text: '10'},
          {value: 11, text: '11'},
          {value: 12, text: '12'}
        ],
        size: ['xs12', 'sm12', 'md4', 'lg4'],
      }),
      this.fieldSelect({
        value: 'broadcastSSID',
        text: 'Exposição do SSID',
        options: [
          {value: true, text: 'Ligado'},
          {value: false, text: 'Desligado'}
        ],
        size: ['xs12', 'sm12', 'md4', 'lg4'],
      })
    ])
  }
}
