import { Model } from '@/default/model/Model'
import { PERMISSION_INTERNET_MODULE, PERMISSION_WELLS_MODULE } from '@/default/constants/permissions'

export class InternetPlan extends Model {
  constructor () {
    super()
    this.list = true
    this.name = 'internet-plan'
    this.title = {
      list: 'Planos de navegação',
      edit: {
        create: 'Novo Serviço',
        update: 'Atualizar Serviço'
      }
    }
    this.params = {
      limit: 10,
      order: 'created_at,desc',
      whereNotNull: 'host_config_id'
    }
    this.search = {
      placeholder: 'Pesquise pelo nome ou ID do plano',
      fields: ['id', 'name']
    }
    this.icon = 'room_service'
    this.apiUrl = 'register/service'
    this.components.list.tableRow = () => import('@/modules/internet/pages/plans')

    this.maxWidth = '750px'

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID',
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome'
    }),
    amountSale: this.fieldList({
      value: 'amount_sale',
      text: 'Valor',
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste serviço.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome',
      counter: 100,
      search: true,
      placeholder: 'Informe o nome em no máximo 100 caracteres.',
      type: 'text',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true,
    }),
    hostConfigId: this.fieldSelectApi({
      value: 'host_config_id',
      text: 'Plano de navegação*',
      autocomplete: true,
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      type: 'db-autocomplete',
      api: {
        url: 'network/host/config',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name',
      showField: () => this.permissionIsAvaiable(PERMISSION_INTERNET_MODULE)
    }),
    amountSale: this.fieldMoney({
      value: 'amount_sale',
      text: 'Preço de Venda',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ]
    }),
    itemUnitId: this.fieldSelectApi({
      value: 'item_unit_id',
      text: 'Unidade de medida*',
      size: [ 'xs12', 'sm12', 'md3', 'lg3' ],
      api: {
        url: 'register/product/unit',
        params: {
          fields: 'id,code',
          order: 'code,asc'
        }
      },
      show: 'code',
      showField: () => this.permissionIsAvaiable(PERMISSION_WELLS_MODULE)
    }),
    subHeader2: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações sobre este serviço.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Observações do serviço',
      placeholder: 'Por favor, descreva este chamado de forma detalhada.'
    })
  }
}
