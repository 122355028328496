<template>
	<div>
		<slot v-if="!loading"></slot>
    <div v-else center style="width: 100%; text-align:center;">
      <br><br>
      <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
      <h1>Carregando...</h1>
    </div>
	</div>
</template>

<script>
  export default {
    name: 'Loading',
    data () {
      return {}
    },
    props: ['loading']
  }
</script>