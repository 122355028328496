import Author from '@/modules/register/config/Person/Person?url=Author'

var author = Author({name: 'Autor', field: 'author'})

author.id = {
  id: '572557ff',
  create: '44c58c79',
  update: '32cfc701',
  delete: 'a1db2f7b'
}
author.title = {
  list: 'Autores',
  edit: {
    insert: 'Novo Autor',
    update: 'Editar Autor'
  }
}
author.icon = 'person'
author.apiUrl = 'register/person/author'
author.urlDetails.name = 'author'

export default author
