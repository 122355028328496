<template>
  <v-card class="business-internet-plan__not-selected" flat>
    <v-card-text class="business-internet-plan__not-selected-content">
      <h4>Adicione um plano de navegação.</h4>
      <v-btn @click="searchInternetPlan()" outline>
        <v-icon>search</v-icon>
        <span> Adicionar Plano</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
  import ContractInternetPlanMixin from './ContractInternetPlanMixin'
  export default {
    name: 'ContractInternetPlanNotSelected',
    mixins: [ContractInternetPlanMixin]
  }
</script>
<style scoped>
  .business-internet-plan__not-selected {
    text-align: center !important;
    padding: 15px !important;
  }
  .business-internet-plan__not-selected-content {
    padding: 14px !important;
    border-style: dashed;
    border-width: 1px;
    border-color: gray;
  }
</style>