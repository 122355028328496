<template>
  <v-card class="elevation-17">
    <v-toolbar dense flat color="transparent" class="business-hosts__toolbar">
      <v-toolbar-title>
        <v-icon color="primary">person</v-icon>
        <span> Usuário(s)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="addNetworkUser()" outline small>
        <v-icon>add</v-icon>
        <span> Adicionar usuário</span>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list v-if="networkUsers && networkUsers.length > 0" two-line subheader>
      <template v-for="(networkUser, networkUserIndex) in networkUsers">
        <v-divider v-if="networkUserIndex" :key="networkUserIndex + '-divider'"></v-divider>
        <v-list two-line subheader :key="networkUserIndex">
          <v-list-tile avatar>
            <v-list-tile-avatar :color="networkUser.status.color">
              <v-icon>person</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <span>Usuário: </span>
                <strong>{{ networkUser.username }}</strong>
              </v-list-tile-title>
              <v-list-tile-sub-title>
                <span>Status atual: </span>
                <strong>
                  {{ networkUser.status.name }}
                </strong>
                <span v-if="networkUser.ip_address"> - IP Fixo: </span>
                <strong>
                  {{ networkUser.ip_address }}
                </strong>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    ripple
                    outline
                    small
                    @click="confiance(networkUser)"
                  >
                    <v-icon>edit</v-icon>
                    <span>Liberar</span>
                  </v-btn>
                </template>
                <span>Liberação em confiança</span>
              </v-tooltip>
            </v-list-tile-action>
            <v-list-tile-action>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" ripple icon @click="editNetworkUser(networkUser)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar usuário</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </template>
    </v-list>
    <v-card-text v-else class="business-hosts__not-selected-content">
      <h4>Adicione um usuário.</h4>
      <v-btn @click="addNetworkUser()" outline>
        <v-icon>add</v-icon>
        <span> Adicionar usuário</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>

  export default {
    name: 'BusinessNetworkUsers',
    methods: {
      addNetworkUser: function () {
        this.$WiEditDialog({
          wiConfig: 'network-user',
          data: {
            business_id: this.business.id
          },
          onSubmit: this.refresh,
          redirectOnSubmit: false
        })
      },
      editNetworkUser: function (networkUser) {
        this.$WiEditDialog({
          wiConfig: 'network-user',
          data: networkUser,
          onSubmit: this.refresh,
          redirectOnSubmit: false
        })
      },
      confiance: function (networkUser) {
        this.$WiApiPut({
          uri: `networks/network/user/${networkUser.id}`,
          data: {
            id: networkUser.id,
            status_id: 1
          },
          config: {
            msgLoad: {
              title: `Executando liberação em confiança...`,
              text: 'Não irá demorar mais que alguns segundos.'
            },
            msgSucess: {
              title: 'Cliente liberado com sucesso!',
              text: 'Reinicie o equipamento para aplicar as alterações.'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja liberar este usuário?`,
              text: 'Esta ação só poderá ser desfeita manualmente.'
            }
          },
          callback: this.refresh
        })
      },
      refresh: function (data) {
        this.$emit('refresh', data)
        this.$emit('onSubmit', data)
      }
    },
    props: {
      business: {
        type: Object,
        required: true
      },
      networkUsers: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
<style scoped>
  .business-hosts__toolbar {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .business-hosts__toolbar >>> .v-toolbar__content {
    padding: 0px !important;
  }
  .business-hosts__not-selected {
    text-align: center !important;
    padding: 15px !important;
  }
  .business-hosts__not-selected-content {
    padding: 14px !important;
    text-align: center;
  }
</style>
