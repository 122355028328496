import {
  SERVICE_DESK_DASHBOARD_TICKETS_BY_NETWORK_TYPE_FILTERS_CACHED as FILTERS_CACHED
} from '@/default/constants/localStorage'

export default {
  methods: {
    setFilters: function (filters) {
      this.filters = filters
      this.setApiParams(filters)
      this.saveFiltersInStorage(filters)
    },
    setApiParams: function (filters) {
      const searchParams = new URLSearchParams()
      const params = filters
      Object.keys(params).forEach(key => searchParams.append(key, params[key]))
      this.apiParams = searchParams.toString()
      this.$forceUpdate()
    },
    getFiltersByStorage: function () {
      let filters = this.$Storage.get(FILTERS_CACHED)
      if (filters && typeof filters === 'object') {
        this.setFilters(filters)
      }
    },
    saveFiltersInStorage: function (filters) {
      this.$Storage.set(FILTERS_CACHED, filters)
    }
  }
}
