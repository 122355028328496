import router from '@/modules/service-desk/routes'
import config from '@/modules/service-desk/config'
import menu from '@/modules/service-desk/menu'

export default {
  id: '93ce590a-82f4-478a-a10f-affed70c981e',
  name: 'service-desk',
  title: 'Service Desk',
  description: 'Módulo Gestão de Chamados',
  icon: 'wises-technology-module-service-desk.png',
  iconSystem: 'wise-network-system-service-desk.png',
  iconProfile: 'profile_service_desk.png',
  color: '#a59d19',
  menu,
  router,
  config
}
