import { Model } from '@/default/model/Model'

export class BusinessItem extends Model {
  constructor () {
    super()
    this.list = false
    this.title = {
      edit: {
        insert: 'Incluir',
        update: 'Editar'
      }
    }
    this.params = {
      limit: 10
    }
    this.icon = 'list'
    this.maxWidth = '1000px'
    this.setFields(this.fieldsEdit)
    this.fieldsFixed = [
      {
        value: 'amount_total', data: 0
      }
    ]
  }
  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o Item, informe a quantidade e valores.'
    }),
    stockId: this.fieldSelectApi({
      value: 'stock_id',
      text: 'Estoque',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      disabled: (form) => (form.id > 0),
      api: {
        url: 'stock',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    itemId: this.fieldWiListDialog({
      value: 'item_id',
      text: 'Item',
      required: true,
      size: ['xs12', 'sm12', 'md8', 'lg8'],
      wiConfig: null,
      show: 'item.name',
      icon: null,
      filter: {},
      // disabled: form => !!form.id,
      callbackFields: [
        {
          get: 'amount_sale',
          set: 'amount_unit'
        }
      ]
    }),
    description: this.fieldText({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Insira a descrição aqui...',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    quantity: this.fieldNumber({
      value: 'quantity',
      text: 'Quantidade',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true,
      step: '.01'
    }),
    amountUnit: this.fieldMoney({
      value: 'amount_unit',
      text: 'Valor Unitário',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor Desconto',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Use este campo para registrar alguma observação interna.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para observações.'
    })
  }
}

export class BusinessItemProduct extends BusinessItem {
  constructor () {
    super()
    this.title = {
      edit: {
        insert: 'Incluir Produto',
        update: 'Editar Produto'
      }
    }
    this.icon = 'inventory_2'
    this.fieldsEdit.itemId.text = 'Produto'
    this.fieldsEdit.itemId.wiConfig = 'product'
    this.fieldsEdit.itemId.icon = 'inventory_2'
    this.fieldsEdit.description.text = 'Descrição do Produto'
  }
}

export class BusinessItemService extends BusinessItem {
  constructor () {
    super()
    this.title = {
      edit: {
        insert: 'Incluir Serviço',
        update: 'Editar Serviço'
      }
    }
    this.icon = 'room_service'
    delete this.fieldsEdit.stockId
    this.fieldsEdit.itemId.size = ['xs12']
    this.fieldsEdit.itemId.text = 'Serviço'
    this.fieldsEdit.itemId.wiConfig = 'service'
    this.fieldsEdit.itemId.icon = 'room_service'
    this.fieldsEdit.description.text = 'Descrição do Serviço'
  }
}
