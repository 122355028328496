import { Model } from '@/default/model/Model'

class TicketLocation extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1a825af1'
    this.name = 'ticket-location'
    this.title = {
      list: 'Localizações chamado',
      edit: {
        insert: 'Anexar localização',
        update: 'Editar localização'
      }
    }
    this.apiUrl = 'service-desk/ticket/location'
    this.icon = 'map'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pela descrição da localização',
      fields: ['description']
    }
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls8', 'offset-xl8']
    this.fieldsSelected = {
      itemName: 'localização(s)'
    }
    this.setFields([
      this.fieldText({
        value: 'description',
        text: 'Descrição',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      this.fieldText({
        value: 'latitude',
        text: 'Latitude',
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldText({
        value: 'longitude',
        text: 'Longitude',
        size: ['xs12', 'sm12', 'md3', 'lg3']
      }),
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição'
      }),
      this.fieldList({
        value: 'latitude',
        text: 'Latitude'
      }),
      this.fieldList({
        value: 'longitude',
        text: 'Longitude'
      })
    ])
  }
}
export default TicketLocation
