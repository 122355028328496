import { Model } from '@/default/model/Model'

export default class TicketHost extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1a825af1'
    this.name = 'ticket-host'
    this.actions = {
      insert: false,
      update: false,
      delete: false
    }
    this.title = 'Clientes impactados'
    this.apiUrl = 'service-desk/ticket/host'
    this.icon = 'dns'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquisa desabilitada...',
      fields: ['id']
    }
    this.redirectOnSubmit = false
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Ticket/Network/TicketHosts')
    this.setFields([
      this.fieldList({
        value: 'host_id',
        text: 'Equipamento'
      }),
      this.fieldList({
        value: 'device_id',
        text: 'Dispositivo'
      }),
      this.fieldList({
        value: 'device_child_id',
        text: 'Interface'
      }),
      this.fieldList({
        value: 'business_id',
        text: 'Contrato'
      }),
      this.fieldList({
        value: 'business_id',
        text: 'Cliente'
      })
    ])
  }
}
