<template>
  <DailyReportPage
    :title="title"
    :finances="finances"
    :products="products"
    @refresh="getData"
  ></DailyReportPage>
</template>

<script>
import { CollaboratorDailyReport } from '@/default/service/SellerAppApi/CollaboratorDailyReport'
import { mapState, mapActions } from 'vuex'
import DailyReportPage from './DailyReport'
export default {
  name: 'DailyReportCollaborator',
  computed: {
    ...mapState('DailyReport', [
      'collaborator',
      'financesAccountBalance',
      'financesToday',
      'productCollaborator',
      'productsEntry',
      'productsRemoval',
      'productsSale',
      'financeSaleToday'
    ]),
    title () {
      return this.collaborator?.name || ''
    },
    finances () {
      return [
        {
          title: 'Recebimentos',
          finance: this.financesToday
        },
        {
          title: 'Saldo em conta',
          finance: this.financesAccountBalance
        }
      ]
    },
    products () {
      return [
        {
          title: 'Vendas',
          products: this.productsSale
        },
        {
          title: 'Reposições',
          products: this.productsEntry
        },
        {
          title: 'Devoluções',
          products: this.productsRemoval
        },
        {
          title: 'Estoque',
          products: this.productCollaborator
        }
      ]
    }
  },
  methods: {
    ...mapActions('DailyReport', ['getReportData']),
    getData: function () {
      this.getReportData({
        service: CollaboratorDailyReport,
        id: this.$route.params.id,
        action: this.action
      })
    },
    action: function () {
      this.$forceUpdate()
    }
  },
  mounted: function () {
    this.getData()
  },
  watch: {
    '$route': 'getData'
  },
  components: {
    DailyReportPage
  }
}
</script>
