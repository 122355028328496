<template>
  <v-card>
    <v-card-text>
      <h4>
        <v-icon style="height: 15px;">event</v-icon>
        <!-- <span v-for="(business, businessKey) in config" :key="businessKey">
          <span v-if="businessKey"> e </span>
          <span> {{ business.title }}</span>
        </span> -->
        <span>{{ title }} nos últimos 12 meses</span>
      </h4>
      <apexchart
        width="100%"
        height="300"
        type="bar"
        :series="series"
        :options="chartOptions"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'PeriodColumnChart',
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 350,
        // events: {
        //   click: (e, chart, opts) => {
        //     console.log('chart', chart);
        //     console.log('opts', opts);
        //     console.log('opts', opts.config.chart);
        //     // console.log(opts.seriesIndex, opts.dataPointIndex);
        //     // console.log(this.config)
        //   }
        // }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Orçamentos, Poços e Contratos'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val
          }
        }
      }
    }
  }),
  computed: {
    title () {
      let title = ''
      this.config.map((config, key) => {
        if (key) {
          if ((key + 1) == this.config.length) {
            title += ' e '
          } else {
            title += ', '
          }
        }
        title += config.title
      })
      return title
    }
  },
  methods: {
    proccessChartConfig: function () {
      this.config.map(config => {
        this.series.push({
          name: config.title,
          data: this.data[config.field].map(data => {
            return data.count
          })
        })
      })
      this.data[this.config[0].field].map(data => {
        this.chartOptions.xaxis.categories.push(
          this.$options.filters.moment(data.first_date, 'MMMM/YY')
        )
      })
      this.chartOptions.chart.configs = this.config
    }
  },
  mounted: function () {
    this.proccessChartConfig()
  },
  props: {
    module: {
      required: true,
      type: Object
    },
    config: {
      required: true,
      type: Array
    },
    data: {
      required: true,
      type: Object
    }
  }
}
</script>