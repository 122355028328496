import { Model } from '@/default/model/Model'

export class BudgetSimple extends Model {
  constructor () {
    super()
    this.id = {
      id: '10b505c9',
      create: 'b9fba2ea',
      update: 'd3c48579',
      delete: '55bf11f3'
    }
    this.title = {
      list: 'Orçamentos',
      edit: {
        create: 'Novo Orçamento',
        update: 'Editar Orçamento'
      }
    }
    this.params = {
      limit: 10
    }
    this.icon = 'shop'
    this.apiUrl = 'sales/budget'
    this.maxWidth = '700px'
    this.search = {
      placeholder: 'Pesquise ID, Razão Social, Status ou Nota Fiscal',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code']
    }
    
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos deste orçamento.'
    }),
    status: this.fieldSelectApi({
      value: 'business_status_id',
      text: 'Status/Etapa*',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'sales/config/status/budget',
        params: {fields: 'id,name,icon,color', order: 'order,asc;name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color',
      disabled: (form) => { return form.paid && form.stage === 2 }
    }),
    category: this.fieldSelectApi({
      value: 'business_category_id',
      text: 'Categoria',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'sales/config/category/budget',
        params: {fields: 'id,name,icon,color', order: 'name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color'
    }),
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data de Emissão',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data de Entrega',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    dateValidity: this.fieldDate({
      value: 'date_validity',
      text: 'Data de Validade',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    totalLiquid: this.fieldMoney({
      value: 'amount_additional',
      text: 'Valor',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Informações complementares: Prazo de pagamento, comissões, etc.'
    }),
    percentageCollaborator: this.fieldNumber({
      value: 'percentage_collaborator',
      text: 'Comissão Colaborador',
      min: 1,
      max: 100,
      step: '.01',
      prefix: '%',
      suffix: '%',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    information: this.fieldText({
      value: 'information',
      text: 'Forma/Condições de Pagamento',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    externalCode: this.fieldText({
      value: 'external_code',
      text: 'Código externo',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para adicionar observações...'
    })
  }
}
