<template>
  <v-alert style="margin: 0;" :value="true" color="yellow">
    <h2 class="black--text">
      Exibir linha do tempo do Diagnóstico {{ diagnosticId }}.
    </h2>
    <v-container style="padding: 10px;" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm12 md6 lg6>
          <v-btn @click="makeShowTimeLine()" block color="" outline class="mt-3">
            <v-icon>timeline</v-icon>
            <span class="ml-2">Exibir Linha do tempo</span>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6>
          <v-btn link :to="{name: 'diagnostic', params: { id: diagnosticId }}" block color="" outline class="mt-3">
            <v-icon>info</v-icon>
            <span class="ml-2">Exibir detalhes do diagnóstico</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-alert>
</template>
<script>
import { TestManagementEventService } from '@/modules/service-desk/services/TestManagement/TestManagementEventService'
import { TestManagementEventSocket } from '@/modules/service-desk/services/TestManagement/TestManagementEventSocket'
export default {
  name: 'DiagnosticShowEvents',
  computed: {
    diagnosticId () {
      return this.data?.diagnostic_id || null
    }
  },
  methods: {
    updateProcess: function (updateProcess) {
      this.$emit('updateProcess', updateProcess)
    },
    makeShowTimeLine: function () {
      if (this.diagnosticId) {
        this.showTimeLine()
      } else {
        setTimeout(() => {
          this.makeShowTimeLine()
          console.log(this.diagnosticId)
        }, 2000)
        console.log(this.diagnosticId)
      }
    },
    showTimeLine: function () {
      this.$WiEventsDialog({
          title: 'Ações/Eventos',
          icon: 'message',
          service: TestManagementEventService,
          serviceParams: {
            where: 'business_id,' + this.diagnosticId
          },
          socket: TestManagementEventSocket,
          socketParams: {
            id: this.diagnosticId
          },
          business: {
            id: this.diagnosticId
          }
        })
    }
  },
  created: function () {
    this.makeShowTimeLine()
  },
  props: {
    data: {
      required: true,
      default: {}
    },
    status: {
      type: Object,
      default: () => ({})
    },
    stage: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style scoped>
  .diagnostic-set-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>