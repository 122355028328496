import { BusinessSimple } from '@/modules/business/config/BusinessSimple'
import {
  PERMISSION_WELL,
  PERMISSION_WELL_CREATE,
  PERMISSION_WELL_UPDATE,
  PERMISSION_WELL_DELETE
} from '@/default/constants/permissions'
import { WellFilters } from './WellFilters'

export class Well extends BusinessSimple {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_WELL,
      create: PERMISSION_WELL_CREATE,
      update: PERMISSION_WELL_UPDATE,
      delete: PERMISSION_WELL_DELETE
    }
    this.name = 'well'
    this.title = {
      list: 'Poços Tubulares',
      edit: {
        insert: 'Novo Poço Tubular',
        update: 'Editar Poço Tubular'
      }
    }
    this.icon = 'waves'
    this.apiUrl = 'wells/well'
    this.urlDetails.name = 'well'

    this.filters = new WellFilters()

    this.clearFields()

    this.fieldsEdit.dateStart.text = 'Início Perfuração'
    this.fieldsEdit.dateEnd.text = 'Termino Perfuração'
    this.fieldsEdit.subHeader1.text = 'Informe os dados básicos deste poço tubular.'
    this.fieldsEdit.status.apiUrl = 'wells/config/status/well'
    this.fieldsEdit.category.apiUrl = 'wells/config/category/well'
    this.fieldsEdit.invoiceTypeId['showField'] = () => false

    this.fieldsList = {
      id: this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      wellAddress: this.fieldList({
        value: 'well_address',
        text: 'Cliente/Endereço',
        sortable: false
      }),
      dateStart: this.fieldList({
        value: 'date_start',
        text: 'Início/Termino'
      }),
      status: this.fieldList({
        value: 'business_status_id',
        text: 'Status/Etapa',
        align: 'center'
      }),
      collaborator: this.fieldList({
        value: 'collaborator_id',
        text: 'Colaborador/Categoria',
        sortable: false
      }),
      options: this.fieldList({
        value: 'options',
        text: 'Opções',
        sortable: false
      })
    }

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)

    this.components.list.tableRow = () => import('@/modules/wells/pages/Wells')
  }
}

export class WellOpened extends Well {
  constructor () {
    super()
    this.title.list = 'Poços em Andamento'
    this.params.stage = 'open'
  }
}
export class WellClosed extends Well {
  constructor () {
    super()
    this.title.list = 'Poços Concluídos'
    this.params.stage = 'close'
  }
}
export class WellCanceled extends Well {
  constructor () {
    super()
    this.title.list = 'Poços Cancelados'
    this.params.stage = 'cancel'
  }
}
