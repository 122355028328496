<template>
  <div :class="
    outline ? ['v-input v-text-field v-text-field--enclosed v-text-field--outline v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light'] : 
    ['v-input v-text-field v-text-field--placeholder v-input--is-label-active v-input--is-dirty theme--light']
  ">
    <div class="v-input__control">
      <div class="v-input__slot">
        <div class="v-text-field__slot">
          <label aria-hidden="true" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">
            {{ label }}
          </label>
          
          <money 
            :aria-label="placeholder || label"
            v-model="model" 
            v-bind="money"
            :disabled="disabled"
            :required="required"
            :rules="rules"
            :placeholder="placeholder"
          ></money>
        </div>
      </div>
      <div class="v-text-field__details">
        <div class="v-messages theme--light">
          <div class="v-messages__wrapper"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WiEditFieldMoney',
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    money: {
      type: Object,
      default: () => ({
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      })
    },
    outline: {
      type: Boolean,
      default: false
    }
  }
}
</script>