<template>
  <view-detail title="Tipos de Ocorrência" apiUrl="finance/category/in" apiParams="?whereNull=finance_category_id&order=active,desc;name,asc" :notGetID="true">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn v-prms="{'id': 'svdk'}" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="openEditModal('finance-in-categories', {})" :disabled="dados.loading">
        <v-icon>add</v-icon>
        <span>Nova Categoria</span>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <v-container align="center">
        <v-layout row wrap>
          <category-dialog v-on:select="select" :data="oco_data" :form="form"></category-dialog>
          <v-flex xs12 sm7 md7 lg7 d-flex style="padding: 10px;">
            <v-card>
              <v-treeview :items="checkChildrenCount(dados.content.data)" :load-children="fetchUsers" transition item-text="name" open-on-click>
                <span @click="setSelected(item)" slot="label" slot-scope="{ item }" :style="{color: !item.active ? '#e0e0e0' : 'black'}">
                  {{item.name}} <span v-if="item.active < 1">(Inativo)</span>
                </span>
                <v-icon medium slot="prepend" slot-scope="{ item }" :style="{'color': !item.active ? '#e0e0e0' : item.color}">{{item.icon || 'category'}}</v-icon> 
              </v-treeview>
            </v-card>
          </v-flex>
          <v-flex xs12 sm5 md5 lg5 d-flex text-xs-center style="padding: 10px;">
            <category-select v-if="selected" :selected="selected" :refresh="dados.refresh" :openEditModal="openEditModal"></category-select>
          </v-flex>
        </v-layout>
      </v-container>
      <div v-if="editModal">
        <edit-modal :store="modalStore" :setform="modalForm" :action="editModal" v-on:action="closeModal" v-on:refresh="dados.refresh"></edit-modal>
      </div>
    </template>
  </view-detail>
</template>
<script>
  import CategorySelect from '@/default/component/Category/CategorySelect'
  import CategoryDialog from '@/default/component/Category/CategoryDialog'
  import {callApi} from '@/default/service/Api'
  export default {
    data: () => ({
      form: {},
      items: [],
      selected: null,
      editModal: false,
      configModal: null,
      modalForm: {},
      modalStore: 'grupos-sub',
      open: [],
      search: null,
      caseSensitive: false,
      oco_data: {}
    }),
    methods: {
      select: function (data) {
        this.oco_data = data
      },
      async fetchUsers (item) {
        return new Promise((resolve, reject) => {
          callApi.get({
            uri: 'finance/category/in',
            params: {limit: 1000, order: 'name,asc', where: 'finance_category_id,' + item.id},
            sucess: data => {
              resolve(data.data.data)
              var itens = data.data.data.filter(function (i) {
                if (i.children_count > 0) {
                  i['children'] = []
                } else {
                  delete i.children
                }
                return i
              })
              item.children.push(...itens)
            },
            error: err => {
              reject(err)
              return err
            }
          })
        })
      },
      checkChildrenCount: function (categories) {
        var items = categories.filter(function (i) {
          if (i.children_count > 0) {
            i['children'] = []
          } else {
            delete i.children
          }
          return i
        })
        return items
      },
      setSelected: function (id) {
        if (id) {
          this.selected = id
        }
      },
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      openEditModalDisp: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.configModal = null
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
        this.selected = null
      }
    },
    components: {
      CategorySelect,
      CategoryDialog
    }
  }
</script>