import { Model } from '@/default/model/Model'

export default class TicketSettings extends Model {
  constructor () {
    super()
    this.id = '1a825af1'
    this.actions = {
      create: false,
      update: true,
      delete: false
    }
    this.name = 'ticket-settings'
    this.title = 'Editar Configurações do chamado'
    this.apiUrl = 'service-desk/ticket'
    this.icon = 'tune'
    this.maxWidth = '800px'
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe abaixo o comportamento deste chamado'
      }),
      this.fieldSwitch({
        value: 'check_dashboards',
        text: 'Este chamado será exibido em Dashboards?',
      }),
      this.fieldSwitch({
        value: 'check_hosts',
        text: 'Este chamado será considerado na análise de equipamentos de clientes?',
      }),
      this.fieldSwitch({
        value: 'check_monitoring',
        text: 'Este chamado irá impactar o status de um dispositivo de rede?',
      }),
      this.fieldSwitch({
        value: 'check_reports',
        text: 'Este chamado será considerado nos relatórios e APIs de relatórios?',
      }),
    ])
  }
}
