<template>
  <v-layout class="person-groups" v-if="$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088') && groups && groups.length > 0" row>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card class="elevation-17">
        <v-list dense two-line style="padding: 0 !important;">
          <template v-for="(group, groupKey) in groups">
            <v-list-tile class="person-statistics__info-tile" style="cursor:pointer;" avatar :key="groupKey">
              <v-list-tile-avatar @click="$router.push({name: 'graduation', params: {id: group.grade.business_id}})" class="person-statistics__info-avatar">
                <v-avatar>
                  <img src="/static/systems/modules/wises-technology-module-graduations.png">
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content @click="$router.push({name: 'graduation', params: {id: group.grade.business_id}})">
                <v-list-tile-sub-title>Projeto:</v-list-tile-sub-title>
                <v-list-tile-title>
                  <strong class="person-statistics__info-text">
                    <span>{{group.grade.business.id}} </span> - 
                    <span> {{group.grade.business.title}}</span>
                  </strong>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-avatar @click="$WiListDialog({wiConfig: 'graduation-grade-person', filter: {where: 'group_id,' + group.grade.id}, wiEditData: {group_id: group.grade.id}})">
                <v-avatar>
                  <img src="/static/icons/classroom_96.png">
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content @click="$WiListDialog({wiConfig: 'graduation-grade-person', filter: {where: 'group_id,' + group.grade.id}, wiEditData: {group_id: group.grade.id}})" style="padding-left: 10px;">
                <v-list-tile-sub-title>Turma:</v-list-tile-sub-title>
                <v-list-tile-title>
                  <strong class="person-statistics__info-text">
                    <span>{{group.grade.college.tag}} - </span>
                    <span>{{group.grade.course.name}} - </span>
                    <span>{{group.grade.period}}/</span>
                    <span>{{group.grade.semester}}/</span>
                    <span>{{group.grade.year}} </span>
                  </strong>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider :key="groupKey + '-divider'"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>     
</template>

<script>
  export default {
    name: 'PersonGroups',
    data () {
      return {}
    },
    computed: {
      groups () {
        return this.person.groups
      }
    },
    props: {
      person: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style>
  .person-groups {
    margin-top: 20px;
  }
  .person-statistics__info-text {
    font-size: 14px;
  }
  .person-statistics__info--left {
    margin-right: 5px;
  }
  .person-statistics__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .person-statistics__info-avatar {
    min-width: 50px !important;
  }
</style>