<template>
  <v-layout row>
    <v-flex class="form-reply-list__row" xs12 sm12 md12 lg12 xl12>
      <v-toolbar dense flat color="transparent" class="business-address__toolbar">
        <v-toolbar-title>
          <v-icon color="primary">map</v-icon>
          <span v-if="!title"> Endereço</span>
          <span v-else> {{ title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="searchAddress()" outline small>
          <v-icon>edit</v-icon>
          <span> Alterar</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </v-flex>
  </v-layout>
</template>
<script>
  import BusinessAddressMixin from './BusinessAddressMixin'
  export default {
    name: 'BusinessAddressTitle',
    mixins: [BusinessAddressMixin]
  }
</script>
<style scoped>
  .business-address__toolbar {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .business-address__toolbar >>> .v-toolbar__content {
    padding: 0px !important;
  }
</style>