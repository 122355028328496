<template>
<div
  style="
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 10px;
  "
>
  <div>
    <v-avatar>
      <v-icon color="primary" large>
        info
      </v-icon>
    </v-avatar>
  </div>
  <div style="font-size: 16px;">
    <span v-if="business.business">
      <span>
        {{ business.info.name === 'sale' || business.info.name === 'order' ? 'Esta ' : 'Este ' }}
      </span>
      <span style="text-transform: lowercase;">
        {{ business.info.title }}
      </span>
      <span>
        foi  
      </span>
      <span>
        {{ business.info.name === 'sale' || business.info.name === 'order' ? ' criada ' : ' criado ' }} 
      </span>
      <span>
        a partir do 
      </span>
      <span style="text-transform: lowercase;">
        {{ business.business.info.title }}
      </span>
      <strong>
        #{{ business.business.id }}
      </strong>
    </span>
    <span v-if="business.businesses && business.businesses.length > 0">
      <span v-if="!business.business">
        <span>
        {{ business.info.name === 'sale' || business.info.name === 'order' ? 'Esta ' : 'Este ' }}
        </span>
        <span>
          {{ business.info.title }}
        </span>
      </span>
      <span v-else> e </span>
      já originou {{ business.businesses.length }} {{ business.businesses.length > 1 ? 'itens' : 'item' }}.
    </span>
    <span v-if="businessActions.length > 0">
      <span>Você pode criar </span>
      <span v-for="(businessAction, businessActionIndex) in businessActions" :key="businessActionIndex">
        <strong>{{ businessAction.titlePlural }}</strong>
        <span v-if="(businessActionIndex + 1) == (businessActions.length - 1)"> ou </span> 
        <span v-else-if="(businessActionIndex + 1) != businessActions.length">, </span> 
      </span>
      <span> a partir </span>
      <span v-if="business.business && business.businesses && business.businesses.length > 0">
        {{ business.info.name === 'sale' || business.info.name === 'order' ? ' dela. ' : ' dele. ' }}  
      </span>
      <span v-else>
        <span>
          {{ business.info.name === 'sale' || business.info.name === 'order' ? ' desta ' : ' deste ' }}
        </span>
        <span style="text-transform: lowercase;">
          {{ business.info.title }}.
        </span>
      </span>
      <span v-if="business.stage != 2">
        Para isso, você deve {{ business.info.name === 'sale' || business.info.name === 'order' ? ' concluí-la' : ' concluí-lo' }}.
      </span>
    </span>
    <br/>
    <strong
      v-if="business.stage == 2 || (business.businesses && business.businesses.length > 0) || business.business"
      class="blue--text"
    > [Clique para mais detalhes]</strong>

  </div>       
</div>       
</template>

<script>
  export default {
    name: 'BusinessRelationshipsTitle',
    props: {
      'business': {
        default: {}
      },
      'businessActions': {
        default: () => ({})
      }
    }
  }
</script>