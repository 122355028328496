<template>
  <div class="diagnostic">
    <DiagnosticStatus
      :status="event.status_info"
      :complement="event.complement"
    ></DiagnosticStatus>
    <DiagnosticItems
      :complement="event.complement"
      :business="business"
      :actions="event.actions"
      :status="event.status_info"
    ></DiagnosticItems>
  </div>
</template>
<script>
  import DiagnosticItems from './DiagnosticItems'
  import DiagnosticStatus from './DiagnosticStatus'
  export default {
    name: 'EventSetHostProducerModel',
    props: {
      event: {
        required: true,
        default: () => ({})
      },
      business: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      DiagnosticItems,
      DiagnosticStatus
    }
  }
</script>
