import { Model } from '@/default/model/Model'
import {
  PERMISSION_FORM,
  PERMISSION_FORM_CREATE,
  PERMISSION_FORM_UPDATE,
  PERMISSION_FORM_DELETE
} from '@/default/constants/permissions'

export class Form extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_FORM,
      create: PERMISSION_FORM_CREATE,
      update: PERMISSION_FORM_UPDATE,
      delete: PERMISSION_FORM_DELETE
    }
    this.name = 'contract'
    this.title = {
      list: 'Formulários',
      edit: {
        insert: 'Novo Formulário',
        update: 'Editar Formulário'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome do formulário',
      fields: ['id', 'name']
    }
    this.params = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'border_color'
    this.apiUrl = 'forms/form'
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl12']
    this.components.list.tableRow = () => import('@/modules/forms/pages/Forms/Forms')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome do formulário'
    }),
    active: this.fieldList({
      value: 'active',
      text: 'Status'
    }),
    fields: this.fieldList({
      value: 'fields',
      text: 'Campos',
      sort: false
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome e descrição do formulário.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Formulário',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm12', 'md1', 'lg1']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),
    externalCode: this.fieldText({
      value: 'external_code',
      text: 'Código Externo',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para descrição do formulário.'
    }),
    observation: this.fieldTextArea({
      value: 'description',
      text: 'Descrição do Formulário',
      placeholder: 'Ex: Formulário de coleta de dados...'
    })
  }
}
