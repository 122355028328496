<template>
  <div>
    <!-- eslint-disable -->
    <v-dialog :dark="dark" v-model="action" return-value="dialog" persistent scrollable max-width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-toolbar dense>
          <v-btn v-if="add && form && form.id && (!config.config || config.config.new)" fab small color="success" bottom left absolute @click.native.stop="newForm()">
            <v-icon>add</v-icon>
          </v-btn>
          <v-icon large v-if="config.icon">{{config.icon && config.icon.edit ? (typeof config.icon.edit == 'object' ? config.icon.edit[form.id ? 'update' : 'insert'] : config.icon.edit) : config.icon}}</v-icon>
          <v-toolbar-title>
            <strong v-if="config.title">{{config.title && config.title.edit ? (typeof config.title.edit == 'object' ? config.title.edit[form.id ? 'update' : 'insert'] : config.title.edit) : config.title}}</strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEdit()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- <div v-if="action && !form.id" style="display: none;">{{$refs.formulario)}}</div> -->
          <v-container style="padding: 0px !important;">
            <div v-if="description" style="width: 100%; text-align: center;">
              <span v-html="description"></span>
            </div>
            <v-form v-model="valid" ref="formulario" autocomplete="off">
              <v-layout row wrap>
                <!-- <code>{{config.config}}</code>
                  <code>{{form}}</code> -->
                  <v-flex v-for="(field, keyField) in config.fields" :key="keyField" v-if="viewField(field) && (!field.showField || field.showField(form, auth))" v-bind="sizeField(field.size)" d-flex style="padding: 0 10px 0 10px;">
                    <div >
                      <div v-if="field.type === 'divider'" style="width: 100%; padding: 0 0 20px 0;">
                        <v-divider></v-divider>
                      </div>
                      <div v-if="field.type === 'subheader'" class="hidden-xs-only hidden-sm-only">
                        <v-subheader style="margin-bottom: 20px; height: 30px !important;" :style="{ background: dark ? '#212121' : '#ECEFF1' }">
                          <span style="padding: 5px;">{{field.text}}</span>
                        </v-subheader>
                      </div>
                      <input v-if="field.type === 'hidden'" v-model="form[field.value]" type="hidden" >
                      <v-text-field 
                      v-if="field.type === 'int'"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :prefix="field.prefix"
                      :suffix="field.suffix"
                      type="number"
                      :step="field.step"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]" 
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form, auth) : field.required"
                      :label="field.text" 
                      :counter="field.counter"  
                      :mask="field.mask" 
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form, auth) : field.disabled"
                      ></v-text-field>

                    <!-- <v-text-field 
                    v-if="field.type === 'money'"
                    :prepend-icon="field['prepend-icon']"
                    :prefix="field.prefix"
                    type="text"
                    :step="field.step"
                    :placeholder="field.placeholder || field.text"
                    v-model.lazy="form[field.value]" 
                    :rules="field.required ? validField(field) : []"
                    :required="field.required"
                    :label="field.text" 
                    :counter="field.counter"  
                    :mask="field.mask" 
                    :disabled="field.disabled"
                    v-money="money"
                    ></v-text-field> -->

                    <div v-if="field.type === 'money'" class="v-input v-text-field v-text-field--placeholder v-input--is-label-active v-input--is-dirty theme--light">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <label aria-hidden="true" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">{{field.text}}<span v-if="field.required">*</span></label>
                            <money 
                            :aria-label="field.placeholder || field.text"
                            v-model="form[field.value]" 
                            v-bind="money"
                            :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                            :required="typeof field.required == 'function' ? field.required(form) : field.required"
                            :rules="field.required ? validField(field) : []"
                            :placeholder="field.placeholder || field.text"
                            ></money>
                          </div>
                        </div>
                        <div class="v-text-field__details">
                          <div class="v-messages theme--light">
                            <div class="v-messages__wrapper"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <v-text-field 
                    v-if="field.type === 'text'"
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="field['append-icon']"
                    :prefix="field.prefix"
                    :placeholder="field.placeholder || field.text"
                    v-model="form[field.value]"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :label="field.text" 
                    :counter="field.counter"
                    :mask="field.mask" 
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    autocomplete="off"
                    ></v-text-field>

                    <v-text-field 
                    v-if="field.type === 'time'"
                    type="time"
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="field['append-icon']"
                    :prefix="field.prefix"
                    :placeholder="field.placeholder || field.text"
                    v-model="form[field.value]"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :label="field.text" 
                    :counter="field.counter"
                    :mask="field.mask" 
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    autocomplete="off"
                    ></v-text-field>

                    <div v-if="field.type === 'color'" >
                      <label aria-hidden="true" class="v-label v-label--active theme--light" style="left: 0px; right: auto; font-size: 12px;">
                        {{field.text}}
                      </label>
                      <input 
                      type="color"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      style="width: 100%; margin-top: 0px; margin-bottom: 3px;" >
                      <hr>
                    </div>

                    <v-text-field 
                    v-if="field.type === 'password'"
                    :placeholder="field.placeholder || field.text"
                    v-model="form[field.value]"
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="typePassword ? 'visibility' : 'visibility_off'"
                    @click:append="() => (typePassword = !typePassword)"
                    :type="typePassword ? 'password' : 'text'"
                    :rules="field.required ? validField(field) : []"
                    :required="field.required"
                    :label="field.text" 
                    :counter="field.counter"
                    min="8"
                    :mask="field.mask" 
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-text-field>
                    <v-text-field 
                    v-if="field.type === 'datetime'"
                    :prefix="field.prefix"
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="field['append-icon']"
                    type="datetime-local"
                    :placeholder="field.text" 
                    v-model="form[field.value]"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :label="field.text" 
                    :counter="field.counter"  
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-text-field>
                    <v-text-field 
                    v-if="field.type === 'date'"
                    :prefix="field.prefix"
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="field['append-icon']"
                    type="date"
                    :placeholder="field.placeholder || field.text"
                    v-model="form[field.value]"
                    :rules="isRequired(field) ? validField(field) : []"
                    :required="isRequired(field)"
                    :label="field.text" 
                    :counter="field.counter"  
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-text-field>
                    <v-textarea
                    outline
                    auto-grow
                    v-if="field.type === 'textarea'"
                    :placeholder="field.placeholder || field.text"
                    v-model="form[field.value]" 
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :label="field.text" 
                    :counter="field.counter" 
                    rows="3"
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-textarea>
                    <div v-if="field.type === 'editor'" style="padding-bottom: 20px;">
                      <vue-editor v-model="form[field.value]" :editor-toolbar="customToolbar" />
                    </div>
                    <v-switch
                    v-if="field.type === 'switch'"
                    :label="field.text"
                    v-model="form[field.value]" 
                    @change="onChange($event)"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-switch>
                    <!-- <v-switch v-model="switch1" :label="`Switch 1: ${switch1.toString()}`"></v-switch> -->
                    <input v-if="field.type === 'checkbox'" type="checkbox" v-model="form[field.value]">
                    <label v-if="field.type === 'checkbox'" for="checkbox">{{ form[field.value] }}</label>
                    <!-- <v-checkbox
                    v-if="field.type === 'checkbox'"
                    :label="field.text"
                    v-model="checkboxs[keyField]"
                    :change="changed(field.value, checkboxs[keyField], keyField, form[field.value])"
                    :required="field.required"
                    :disabled="field.disabled"
                    ></v-checkbox> -->
                    <v-select
                    v-if="field.type === 'select'"
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="field['append-icon']"
                    :items="field.options"
                    v-model="form[field.value]"
                    :label="field.text"
                    :placeholder="field.placeholder || field.text"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    item-text="text"
                    item-value="value"
                    @change="onChange($event)"
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    :multiple="field.multiple"
                    :return-object="field.object"
                    ></v-select>
                    <dbselect 
                    v-if="field.type === 'dbselect'" 
                    :prepend-icon="field['prepend-icon']"
                    :append-icon="field['append-icon']"
                    :instore="field.instore" 
                    :outstore="field.outstore" 
                    :id="field.value" 
                    :form="form" 
                    :show="field.show" 
                    :label="field.text"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :autocomplete="field.autocomplete"
                    :value="field.value"
                    :fields="field.fields"
                    :multiple="field.multiple"
                    :order="field.order"
                    ></dbselect>
                    <db-select 
                    v-if="field.type === 'db-select'" 
                    :apiUrl="field.apiUrl"
                    :apiParams="field.apiParams"
                    :formField="field.value" 
                    :form="form" 
                    :show="field.show" 
                    :label="field.text"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form) : field.required"
                    :autocomplete="field.autocomplete"
                    :multiple="field.multiple"
                    :placeholder="field.placeholder || field.text"
                    ></db-select>
                    <db-autocomplete 
                    v-if="field.type === 'db-autocomplete'" 
                    :apiUrl="field.apiUrl"
                    :apiParams="typeof field.apiParams == 'function' ? field.apiParams(form, auth) : field.apiParams"
                    :formField="field.value" 
                    :form="form" 
                    :show="field.show" 
                    :label="field.text"
                    :rules="field.required ? validField(field) : []"
                    :required="typeof field.required == 'function' ? field.required(form, auth) : field.required"
                    :autocomplete="field.autocomplete"
                    :multiple="field.multiple"
                    :placeholder="field.placeholder || field.text"
                    :disabled="typeof field.disabled == 'function' ? field.disabled(form, auth) : field.disabled"
                    :object="field.object"
                    :icon="field.icon"
                    :color="field.color"
                    ></db-autocomplete>
                    <div v-if="field.type === 'list'">
                      <v-list dense two-line class="listmodal">
                        <v-list-tile avatar>
                          <v-list-tile-avatar>
                            <v-icon>{{ field.icon }}</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ form[field.show] || 'Selecione...' }}</v-list-tile-title>
                            <v-list-tile-sub-title v-if="field.showSecond">form[field.showSecond]</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-btn icon @click="listModal = true">
                              <v-icon>search</v-icon>
                            </v-btn>
                            <list-modal 
                            :action="listModal"
                            :store="field.store"
                            :id="field.value" 
                            :multiple="field.multiple ? getMultipleListModal : false"
                            v-on:action="closeListModal"
                            ></list-modal>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list><br>
                    </div>
                    <div v-if="field.type === 'list-ticket-category'">
                      <ocorrencias-dialog 
                        v-on:select="selectCategory" 
                        :data="form" :form="form" 
                        :ticket-function="field.ticketFunction"
                        :rules="field.required ? validField(field) : []"
                        :required="typeof field.required == 'function' ? field.required(form, auth) : field.required"
                        >
                        </ocorrencias-dialog>
                    </div>
                    <div v-if="field.type === 'wi-list-dialog'">
                      <wi-edit-wi-list-dialog :form="form" :field="field" v-on:select="addToForm"></wi-edit-wi-list-dialog>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container><br><br>
        </v-card-text>
        <v-toolbar v-if="action && store" dense class="elevation-17" style="position: absolute; bottom: 0;width: 100%; z-index: 9999 !important; box-shadow: 10px 10px 5px grey;">
          <small>*Campos obrigatórios</small>
          <v-spacer></v-spacer>
          <v-btn 
            v-prms="{'id': getIdPermission('create'), notCheck: config.disablePermission}" 
            v-if="action && form && !form.id && checkButtonConfig('create')" 
            :disabled="!valid" 
            color="success" 
            @click.native="insert(form)"
          >
            <span style="padding-right: 5px;">Inserir</span>
            <v-icon>check</v-icon>
          </v-btn>
          <v-btn 
            v-prms="{'id': getIdPermission('delete'), notCheck: config.disablePermission}" 
            v-if="action && form && form.id && checkButtonConfig('delete')" 
            color="error" 
            @click.native="apagar(form)"
          >
            <span style="padding-right: 5px;">Apagar</span>
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn 
            v-prms="{'id': getIdPermission('update'), notCheck: config.disablePermission}" 
            v-if="action && form && form.id && checkButtonConfig('update')" 
            :disabled="!valid" 
            color="info" 
            @click.native="update(form)"
          >
            <span style="padding-right: 5px;">Atualizar</span>
            <v-icon>update</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import OcorrenciasDialog from '@/modules/service-desk/pages/Ocorrencias/OcorrenciasDialog'
  import ListModal from '@/default/components/Modal/ListModal'
  import DBSelect from '@/default/components/DBSelect'
  import DbSelect from '@/default/components/DBSelectV2'
  import DbAutocomplete from '@/default/components/DBAutocomplete'
  import {Api} from '@/default/service/Api'
  import {VMoney} from 'v-money'
  import { VueEditor } from 'vue2-editor'
  import WiEditWiListDialog from '@/default/component/WiEdit/Fields/WiEditWiListDialog'
  export default {
    name: 'WiListEditModal',
    data () {
      return {
        valid: false,
        editorTeste: '',
        form: {
          checkbox: false
        },
        checkboxs: [],
        typePassword: true,
        money: {
          required: true,
          prefix: 'R$ ',
          decimal: ',',
          thousands: '.',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        listModal: false,
        customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
        [{ 'color': [] }, { 'background': ['#fff'] }],
        [{list: 'ordered'}, {list: 'bullet'}]
        ]
      }
    },
    created: function () {
      this.form = {}
      Object.assign(this.form, this.setform)
      if (this.config.fieldsFixed) {
        this.config.fieldsFixed.forEach((fixed) => {
          this.form[fixed.value] = fixed.data
        })
      }
      if (this.apagarAoIniciar) {
        this.apagar(this.form)
      }
    },
    computed: {
      config () {
        var storeConfig = this.$store.state.pages[this.store] || {}
        return this.localConfig || storeConfig
      },
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.auth || {}
      }
    },
    props: ['action', 'setform', 'store', 'id', 'add', 'params', 'description', 'apagarAoIniciar', 'localConfig'],
    methods: {
      getIdPermission: function (action) {
        if (this.config && this.config.id) {
          return this.config.id[action] || this.config.id
        }
        return null
      },
      checkButtonConfig: function (action) {
        if (this.config.actions) {
          if (typeof this.config.actions[action] === 'function') {
            return this.config.actions[action](this.form)
          } else {
            return this.config.actions[action]
          }
        }
        return true
      },
      addToForm: function (newData) {
        if (newData) {
          Object.assign(this.form, newData)
          this.$forceUpdate()
        }
      },
      onChange: function () {
        this.$forceUpdate()
      },
      isRequired: function (field) {
        if (typeof field.required === 'function') {
          return field.required(this.form)
        } else {
          return field.required
        }
      },
      changed: function (field, value, index, valueField) {
        // console.log(this.checkboxs)
        // console.log('field: ' + field)
        if (value === undefined) {
          this.checkboxs[index] = valueField
        }
        // console.log(valueField + ' === ' + value)
        // console.log('valueField: ' + valueField)
        this.form[field] = !value
      },
      viewField: function (field) {
        if (field.edit && (field.edit.insert || field.edit.update)) {
          if (this.form.id) {
            if (field.edit.update) {
              return true
            } else {
              return false
            }
          } else {
            if (field.edit.insert) {
              return true
            } else {
              return false
            }
          }
        } else {
          return field.edit
        }
      },
      selectCategory: function (select) {
        Object.assign(this.form, select)
        this.$forceUpdate()
      },
      newForm: function () {
        this.form = {}
        if (this.config.fieldsFixed) {
          this.config.fieldsFixed.forEach((fixed) => {
            this.form[fixed.value] = fixed.data
          })
        }
      },
      validField: function (field) {
        return field.rules || [v => !!v || 'Campo obrigatório']
      },
      sizeField: function (sizes) {
        var size = {}
        sizes = sizes ? (typeof sizes === 'function' ? sizes(this.form) : sizes) : ['xs12', 'sm12', 'md12', 'lg12']
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      closeEdit: function () {
        this.$emit('action', false)
      },
      getMultipleListModal: function (rows) {
        console.log(rows)
      },
      closeListModal: function (row) {
        if (row) {
          Object.assign(this.form, row)
          this.$forceUpdate()
          this.listModal = false
        }
      },
      loading: function (title, text) {
        this.$swal({
          title: title || 'Carregando...',
          text: text || 'Aguarde até que a ação seja finalizada.',
          onOpen: () => { this.$swal.showLoading() }
        })
      },
      success: function (title, text) {
        this.$swal({
          type: 'success',
          title: title || 'Tudo certo!',
          text: text || 'Concluído com sucesso.'
        })
      },
      error: function (title, text) {
        this.$swal({
          type: 'error',
          title: title || 'Oops... Algo deu errado!',
          text: text || 'Tente novamente ou contacte o suporte.'
        })
      },
      getConfigUri: function (type) {
        return this.config.apiUrl && this.config.apiUrl.edit ? (typeof this.config.apiUrl.edit === 'object' ? this.config.apiUrl.edit[type] : this.config.apiUrl.edit) : this.config.apiUrl
      },
      warning: function (title, text) {
        this.$swal({
          type: 'warning',
          title: title || 'Verifique os dados inseridos.',
          html: text || 'Tente novamente ou contacte o suporte.'
        })
      },
      insert: function (data) {
        this.loading('Inserindo...')
        for (var param in this.config.editParams) {
          data[param] = this.config.editParams[param]
        }
        var uri = this.getConfigUri('insert')
        Api.post({url: this.url + uri}, data)
        .then(() => {
          this.$emit('action', true)
          this.$emit('refresh', false)
          this.success()
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 202, title: 'Erro ao inserir informações.'})
        })
      },
      update: function (data) {
        this.loading('Atualizando...')
        var uri = this.getConfigUri('update')
        Api.put({url: this.url + uri + '/' + data.id}, data)
        .then(() => {
          this.$emit('action', true)
          this.$emit('refresh', false)
          this.success()
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 302, title: 'Erro ao atualizar informações.'})
        })
      },
      apagar: function (data) {
        this.$swal({
          title: 'Deseja realmente apagar?',
          text: 'Esta ação não poderá ser desfeita!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Apagar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.loading('Apagando...')
            var uri = this.getConfigUri('delete')
            Api.delete({url: this.url + uri + '/' + data.id})
            .then(() => {
              this.$emit('action', true)
              this.$emit('refresh', false)
              this.success()
            })
            .catch((error) => {
              this.$WiMakeError({error: error, id: 402, title: 'Erro ao apagar informações.'})
            })
          }
        })
      }
    },
    components: {
      dbselect: DBSelect,
      ListModal,
      DbSelect,
      DbAutocomplete,
      VueEditor,
      OcorrenciasDialog,
      WiEditWiListDialog
    },
    directives: {
      money: VMoney
    }
  }
</script>

<style>
.listmodal {
  border-style: solid;
  border-color: gray;
  border-width: 1px;
}
@media only screen and (min-width: 400px) {
  .header_edit_modal {
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>
