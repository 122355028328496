import { Model } from '@/default/model/Model'

export class ItemCode extends Model {
  constructor() {
    super()
    this.list = true
    this.id = 'b9a915a3'
    this.title = {
      list: 'Códigos de Produto',
      edit: {
        insert: 'Novo Código de Item',
        update: 'Editar Código de Item'
      }
    }
    this.params = {
      limit: 10,
      with: 'item'
    }
    this.search = {
      placeholder: 'Pesquise ID ou código do item',
      fields: ['id', 'code']
    }
    this.icon = 'add_circle_outline'
    this.apiUrl = 'register/product/code'
    this.components.list.tableRow = () => import('@/modules/register/pages/Items/ItemsCodes')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id : this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    key: this.fieldList({
      value: 'uuid',
      text: 'Chave/Produto'
    }),
    code: this.fieldList({
      value: 'code',
      text: 'Código'
    }),
    description: this.fieldList({
      value: 'description',
      text: 'Descrição'
    })
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Informe os dados deste código'
    }),
    itemId: this.fieldWiListDialog({
      value: 'item_id',
      text: 'Produto',
      required: true,
      wiConfig: 'product',
      show: 'item.name'
    }),
    description: this.fieldText({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Ex: Use este campo para descrever o código..'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações do Código',
      placeholder: 'Ex: Use este campo para descrever observações do código..'
    })
  }
  
}

export class ItemCodeGenerate extends Model {
  constructor() {
    super()
    this.id = 'b9a915a3'
    this.title = 'Gerar Códigos de Item'
    this.icon = 'add_circle_outline'
    this.apiUrl = 'register/product/code-generate'
    this.maxWidth = '500px'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Informe os dados para gerar códigos de item'
    }),
    itemId: this.fieldWiListDialog({
      value: 'item_id',
      text: 'Produto',
      required: true,
      wiConfig: 'product',
      show: 'item.name'
    }),
    // select  para definir se será gerado por quantidade ou por intervalo, com start e and numeros
    generateType: this.fieldSelect({
      value: 'generate_type',
      text: 'Tipo de Geração',
      options: [
        { value: 'quantity', text: 'Por Quantidade' },
        { value: 'interval', text: 'Por Intervalo' }
      ],
      required: true
    }),
    subHeaderQuantity: this.fieldSubHeader({
      text: 'Informe a quantidade de códigos a serem gerados',
      showField: form => form.generate_type === 'quantity' || !form.generate_type
    }),
    quantity: this.fieldNumber({
      value: 'quantity',
      text: 'Quantidade',
      required: true,
      max: 10,
      rules: [
        value => !!value || 'Campo obrigatório',
        value => value > 0, value => value <= 10 || 'Máximo de 10 códigos'
      ],
      showField: form => form.generate_type === 'quantity' || !form.generate_type
    }),
    subHeaderInterval: this.fieldSubHeader({
      text: 'Informe o início e fim do intervalo de códigos a serem gerados',
      showField: form => form.generate_type === 'interval'
    }),
    start: this.fieldNumber({
      value: 'start',
      text: 'Início',
      required: true,
      max: 999999999,
      rules: [
        value => !!value || 'Campo obrigatório',
        value => value > 0, value => value <= 999999999 || 'Máximo de 999999999'
      ],
      showField: form => form.generate_type === 'interval',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    end: this.fieldNumber({
      value: 'end',
      text: 'Fim',
      required: true,
      max: 999999999,
      rules: [
        value => !!value || 'Campo obrigatório',
        value => value > 0, value => value <= 999999999 || 'Máximo de 999999999'
      ],
      showField: form => form.generate_type === 'interval',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    subHeader1: this.fieldSubHeader({
      text: 'Essa descrição será aplicada a todos os códigos gerados.'
    }),
    description: this.fieldText({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Essa descrição será aplicada a todos os códigos gerados.'
    })
  }
}

export class ItemCodePrint extends Model {
  // Imprimir etiquetas para cada código gerado
  // Terá apenas um editor de html para exibir o HTML das etiquetas
  // O HTML será gerado no backend
  constructor() {
    super()
    this.title = 'Imprimir Etiquetas'
    this.icon = 'print'
    this.apiUrl = 'register/product/code-print'
    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader: this.fieldSubHeader({
      text: 'Etiquetas geradas'
    }),
    layout: this.fieldTinyEditor({
      value: 'layout',
      text: 'Layout desta categoria',
      placeholder: 'Crie a estrutura da categoria.'
    })
  }
}