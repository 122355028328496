import {
  PERMISSION_FORM
} from '@/default/constants/permissions'

export default [
  // {
  //   id: '74cc44a0',
  //   icon: 'view_week',
  //   title: 'Processo de Diagnóstico',
  //   route: {
  //     name: 'process-of-service-desk',
  //     params: {
  //       business: 'test-management-vtal'
  //     }
  //   }
  // },
  {
    id: '74cc44a0',
    icon: 'select_all',
    title: 'Diagnósticos Em Aberto',
    route: {
      name: 'service-desk-list',
      params: {
        page: 'diagnostic-open'
      }
    }
  },
  {
    id: '74cc44a0',
    icon: 'done_outline',
    title: 'Histórico de Diagnósticos',
    route: {
      name: 'service-desk-list',
      params: {
        page: 'diagnostic-close'
      }
    }
  },
  // {
  //   id: '74cc44a0',
  //   icon: 'close',
  //   title: 'Diagnósticos Canceladas',
  //   route: {
  //     name: 'service-desk-list',
  //     params: {
  //       page: 'diagnostic-cancel'
  //     }
  //   }
  // },
  {
    id: '74cc44a0',
    icon: 'account_tree',
    title: 'Processos',
    route: {
      name: 'service-desk-list',
      params: {
        page: 'process'
      }
    }
  },
  {
    id: PERMISSION_FORM,
    icon: 'list_alt',
    title: 'Formulários/Checklists',
    route: {
      name: 'service-desk-list',
      params: {
        page: 'service-desk-config-form'
      }
    }
  }
]