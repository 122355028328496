import Event from '@/modules/sales/config/Common/Event'
import {
  InternetContractProduct,
  InternetContractService,
  InternetContractPlan
} from '@/modules/internet/config/Common/InternetContract'
import {
  ContractInternet,
  ContractOpened,
  ContractClosed,
  ContractBlocked,
  ContractCanceled
} from '@/modules/internet/config/Contract/Contract'
import { ContractPayDay } from '@/modules/internet/config/Contract/ContractPayDay'
import { ContractInternetPlan } from '@/modules/internet/config/Contract/ContractInternetPlan'
import { InternetPlan } from '@/modules/internet/config/Contract/InternetPlan'

import { BusinessPromotion } from '@/modules/business/config/BusinessPromotion'

export default {
  'internet-event': Event.sale,
  'internet-contract': new ContractInternet(),
  'internet-contract-opened': new ContractOpened(),
  'internet-contract-closed': new ContractClosed(),
  'internet-contract-blocked': new ContractBlocked(),
  'internet-contract-canceled': new ContractCanceled(),
  'internet-contract-product': new InternetContractProduct(),
  'internet-contract-service': new InternetContractService(),
  'internet-contract-plan': new InternetContractPlan(),
  'internet-contract-promotion': new BusinessPromotion(),
  'internet-contract-pay-day': new ContractPayDay(),
  'internet-contract-internet-plan': new ContractInternetPlan(),
  'internet-plan': new InternetPlan(),
}
