import { PERMISSION_INTERNET_REPORTS } from '@/default/constants/permissions'

const dateStart = new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'

export default {
  id: PERMISSION_INTERNET_REPORTS,
  icon: 'bar_chart',
  title: 'Relatórios',
  children: [
    {
      id: '1b4f745c',
      icon: 'people_outline',
      title: 'Vendas por cliente',
      route: {
        name: 'sales-by-customer',
        query: {
          date_field: 'date_start',
          date_start: dateStart
        }
      }
    },
    {
      id: '919bb8fa',
      icon: 'people_outline',
      title: 'Orçamentos por cliente',
      route: {
        name: 'budgets-by-customer',
        query: {
          date_field: 'date_start',
          date_start: dateStart
        }
      }
    },
    {
      id: 'dab5ce52',
      icon: 'assignment_ind',
      title: 'Vendas por Vendedor',
      route: {
        name: 'sales-by-collaborator',
        query: {
          date_field: 'date_start',
          date_start: dateStart
        }
      }
    },
    {
      id: 'bffe8c55',
      icon: 'assignment_ind',
      title: 'Orçamentos por Vendedor',
      route: {
        name: 'budgets-by-collaborator',
        query: {
          date_field: 'date_start',
          date_start: dateStart
        }
      }
    }
  ]
}
