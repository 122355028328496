import { Model } from '@/default/model/Model'

export class ChatMembers extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '550a585c'
    this.name = 'chat-members'
    this.actions = {
      list: true,
      create: false,
      update: false,
      delete: true
    }
    this.title = 'Membros do grupo'
    this.apiUrl = 'chats/chat-group-person'
    this.icon = 'group'
    this.maxWidth = '800px'

    this.components.list.tableRow = () => import('@/default/pages/ChatView/ChatGroupMembers')

    this.listFields = {
      name: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      profile: this.fieldList({
        value: 'profile',
        text: 'Perfil'
      })
    }
    this.setFields(this.listFields)
  }
}
