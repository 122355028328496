export default {
  async getReportData ({ state, commit }, { service, id, action }) {
    if (service) {
      const Report = service
      const report = new Report()
      commit('SET_REPORT_LOADING', true)
      const response = await report.findById(id, { date: state.date })
      commit('SET_REPORT_DATA', response)
      commit('SET_REPORT_LOADING', false)
      if (action) {
        action()
      }
    }
  }
}
