import { Model } from '@/default/model/Model'
import {
  PERMISSION_FORM,
  PERMISSION_FORM_CREATE,
  PERMISSION_FORM_UPDATE,
  PERMISSION_FORM_DELETE
} from '@/default/constants/permissions'

export class Process extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_FORM,
      create: PERMISSION_FORM_CREATE,
      update: PERMISSION_FORM_UPDATE,
      delete: PERMISSION_FORM_DELETE
    }
    this.name = 'process'
    this.title = {
      list: 'Processos',
      edit: {
        insert: 'Novo Processo',
        update: 'Editar Processo'
      }
    }
    this.params = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'account_tree'
    this.apiUrl = 'processes/process'
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Processes')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome do processo'
    }),
    count: this.fieldList({
      value: 'count',
      text: 'N° Execuções'
    }),
    active: this.fieldList({
      value: 'active',
      text: 'Status'
    }),
    execute: this.fieldList({
      value: 'execute',
      text: 'Executar',
      sortable: false
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      sortable: false
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome e descrição do processo.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do processo',
      size: form => form.id ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md12', 'lg12'],
      required: true
    }),
    first_stage_id: this.fieldWiListDialog({
      value: 'first_stage_id',
      text: 'Etapa que será executada',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      wiConfig: 'process-stage',
      filter: form => ({
        process_id: form.id
      }),
      wiEditData: form => ({
        process_id: form.id
      }),
      show: 'first_stage.name',
      icon: 'account_tree',
      showField: form => !!form.id
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    externalCode: this.fieldText({
      value: 'external_code',
      text: 'Código Externo',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para descrição do processo.'
    }),
    observation: this.fieldTextArea({
      value: 'description',
      text: 'Descrição do processo',
      placeholder: 'Ex: processo de coleta de dados...'
    })
  }
}
