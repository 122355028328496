<template>
  <v-list-tile avatar>
    <v-list-tile-avatar style="min-width: 40px !important;">
      <v-avatar>
        <v-icon v-if="field.icon" large :color="field.color" style="padding-bottom: 2px; padding-right: 2px;">
          {{field.icon}}
        </v-icon>
      </v-avatar>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-sub-title style="color: rgba(0,0,0,.54);">
        <FormReplyListReplyText :label="field.label"></FormReplyListReplyText>
      </v-list-tile-sub-title>
      <v-list-tile-title>
        <strong v-if="field.reply && field.reply.content">
          <FormReplyListReplyText :label="field.reply.content"></FormReplyListReplyText>
        </strong>
        <span v-else>- - -</span>
      </v-list-tile-title>
    </v-list-tile-content>
    <v-list-tile-action style="padding-top: 2px; padding-bottom: 2px;">
      <wi-copy-to
        v-if="field.reply && field.reply.content"
        :text="field.reply.content"
        :title="field.label"
      ></wi-copy-to>
      <v-tooltip v-if="field.reply" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small @click="openFieldReplyHistory(field.reply)">
            <v-icon color="blue">history</v-icon>
          </v-btn>
        </template>
        <span>Histórico de alterações</span>
      </v-tooltip>
    </v-list-tile-action>
  </v-list-tile>
</template>
<script>
  import FormReplyListReplyText from './FormReplyListReplyText'
  export default {
    name: 'FormReplyListReplyDefault',
    methods: {
      openFieldReplyHistory (fieldReply) {
        this.$emit('openFieldReplyHistory', fieldReply)
      }
    },
    props: {
      field: {
        type: Object,
        required: true
      }
    },
    components: {
      FormReplyListReplyText
    }
  }
</script>