<template>
  <v-dialog
    v-model="dialog.active"
    scrollable
    persistent
    :max-width="(88 - (index * 2)) + '%'"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <WiEvents
      title="Eventos/Notas"
      :service="dialog.service"
      :serviceParams="dialog.serviceParams"
      :socket="dialog.socket"
      :socketParams="dialog.socketParams"
      :business="dialog.business"
      @onClose="close"
    ></WiEvents>
  </v-dialog>
</template>
<script>
  import WiEvents from './index.vue'
  export default {
    name: 'WiEventsDialog',
    methods: {
      close: function () {
        this.$store.dispatch('WiEventsDialogs/close', this.index)
        this.$forceUpdate()
      }
    },
    computed: {
      dialog () {
        var WiEventsDialogs = this.$store.state.WiEventsDialogs
        return WiEventsDialogs.dialogs[this.index]
      }
    },
    props: {
      'index': {
        default: 0
      }
    },
    components: {
      WiEvents
    }
  }
</script>