<template>
  <v-card class="elevation-17">
    <v-container class="business-address">
      <div v-if="address && address.id">
        <BusinessAddressTitle
          :title="title"
          :field="field"
          :business="business"
          :person="person"
          :address="address"
          @onSubmit="onSubmit"
        ></BusinessAddressTitle>
        <BusinessAddressSelected
          :title="title"
          :field="field"
          :business="business"
          :person="person"
          :address="address"
          @onSubmit="onSubmit"
        ></BusinessAddressSelected>
      </div>
      <BusinessAddressNotSelected
        v-else
        :title="title"
        :field="field"
        :business="business"
        :person="person"
        :address="address"
        @onSubmit="onSubmit"
      ></BusinessAddressNotSelected>
    </v-container>
  </v-card>
</template>
<script>
  import BusinessAddressTitle from './BusinessAddressTitle'
  import BusinessAddressSelected from './BusinessAddressSelected'
  import BusinessAddressNotSelected from './BusinessAddressNotSelected'
  export default {
    name: 'BusinessAddress',
    methods: {
      onSubmit: function (response) {
        console.log(response)
        this.$emit('onSubmit', response)
      }
    },
    props: {
      title: {
        default: null
      },
      field: {
        default: 'address_id'
      },
      business: {
        type: Object,
        required: true,
        default: () => ({})
      },
      person: {
        type: Object,
        required: true,
        default: () => ({})
      },
      address: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    components: {
      BusinessAddressTitle,
      BusinessAddressSelected,
      BusinessAddressNotSelected
    }
  }
</script>
<style scoped>
  .business-address {
    padding: 0px !important;
  }
</style>