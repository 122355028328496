<template>
  <kanban 
    title="Tags Kanban" 
    store="tag"
    store-stage="tag"
    api-get-url="register/tags-kanban" 
    api-put-url="register/tags-kanban"
    field-reference-in-item="business_status_id"
    message-alert-title="Deseja realmente alterar a tag deste contato?"
    message-alert-text="Essa ação só poderá ser desfeita manualmente!"
    :router-click="null"
    hideAddButton="true"
    hideReorderButton="true"
  >
  <template slot="items" slot-scope="item">
    <v-card
      style="
        margin-bottom: 5px;
        cursor: grab;
        width: 100% !important;
        width: 270px;
        max-width: 270px;
      "
    >
      <div>
        <v-list style="padding: 0px;">
          <v-list-tile avatar>
            <v-list-tile-avatar style="min-width: 48px;">
              <img v-if="item.item.file" :src="item.item.file.url">
              <img v-else src="static/icons/user_icon_1.png">
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <strong v-if="item.item.person && item.item.person.name">
                  {{ item.item.person.name | stringlength(22) }}
                </strong>
                <strong>
                  {{ item.item.name | stringlength(22) }}
                </strong>
              </v-list-tile-title>
              <v-list-tile-sub-title
                v-if="item.item.opened_talks && item.item.opened_talks.length > 0"
              >
                <strong v-if="item.item.opened_talks[0].status == 1" class="blue--text">Chatbot</strong>
                <strong v-else>
                  <strong v-if="item.item.opened_talks[0].responsible_id">
                    <span>Conversando com </span>
                    <span v-if="item.item.opened_talks[0].responsible">
                      {{ item.item.opened_talks[0].responsible.name | stringlength(22) }}
                    </span>
                    <span v-else>um operador</span>
                  </strong>
                  <strong v-else style="color: orange;">Aguardando atendimento</strong>
                </strong>
              </v-list-tile-sub-title>
              <v-list-tile-sub-title v-else>
                <strong class="green--text">
                  Atendimento finalizado
                </strong>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </div>
      <v-divider></v-divider>
      <v-card-text
        v-if="item.item.tags && tagsFilter(item.item.tags).length > 0"
        style="padding: 5px; text-align: left;"
      >
        <span v-for="(tag, tagIndex) in tagsFilter(item.item.tags)" :key="`tag--${tagIndex}`">
          <v-chip v-if="!tag.show_in_kanban" small :color="tag.color" class="ma-1 elevation-1">
            <span :style="{ color: getTextColoe(tag.color) }">
              {{ tag.name }}
            </span>
          </v-chip>
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text style="padding: 0 5px 5px 5px;">
        <v-btn
          v-if="item.item.opened_talks && item.item.opened_talks.length > 0"
          small
          block
          outline
          color="orange"
          :to="{
            name: 'talk-view-messages',
            params: {
              id: item.item.opened_talks[0].id
            }
          }"
        >
          <v-icon>search</v-icon>
          <span> acessar atendimento</span>
        </v-btn>
        <v-btn
          v-else
          small
          block
          outline
          color="green"
          @click="openTalkContact(item.item)"
        >
          <v-icon>add</v-icon>
          <span> Iniciar novo atendimento</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </template>
</kanban>
</template>
<script>
  import talkViewMixin from '@/modules/talk/pages/TalkView/talkViewMixin'
  import kanban from '@/default/component/WiKanban/WiKanban'
  export default {
    name: 'BusinessOfProcess',
    data: () => ({

    }),
    methods: {
      goRouter: function (item) {
        var router = this.config.routerClick
        router.params['id'] = item.id
        this.$router.push(router)
      },
      getTextColoe: function (corHexadecimal) {
          // Converte a cor hexadecimal para RGB
          const r = parseInt(corHexadecimal.slice(1, 3), 16);
          const g = parseInt(corHexadecimal.slice(3, 5), 16);
          const b = parseInt(corHexadecimal.slice(5, 7), 16);

          // Calcula o valor de luminosidade usando a fórmula YIQ
          const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

          // Define um valor de limiar para determinar a cor do texto
          const limiar = 128;

          // Verifica se a luminosidade é maior que o limiar
          if (luminosidade > limiar) {
              // Se a cor de fundo for clara, retorna cor escura
              return "#000000"; // Cor preta
          } else {
              // Se a cor de fundo for escura, retorna cor clara
              return "#FFFFFF"; // Cor branca
          }
      },
      tagsFilter: function (tags) {
        return tags.filter(tag => !tag.show_in_kanban)
      }
    },
    components: {
      kanban
    },
    mixins: [talkViewMixin]
  }
</script>