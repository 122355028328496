<template>
  <v-dialog v-model="dialog" max-width="500" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn v-if="filtered" class="filter-by-status__card-action hidden-xs-only hidden-sm-only" color="yellow" small outline v-on="on">
        <span>Filtrando por status ({{selected.length}})</span>
      </v-btn>
      <v-btn v-else class="filter-by-status__card-action hidden-xs-only hidden-sm-only" small outline v-on="on">
        <span>Filtrar por status</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense>
        <v-icon>circle</v-icon>
        <v-toolbar-title>Filtro por status</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="refresh()" :disabled="!refresh">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="filter-by-status__content">
        <v-toolbar-title>Selecione 1 ou mais status</v-toolbar-title><br>
        <WiView index="selectTicketStatus" apiUrl="service-desk/ticket/status/by-ticket-number" apiParams="?where=stage,1" v-on:afterLoad="afterLoad" :notShowToolbar="true" :notGetID="true">
          <template slot-scope="{content}">
            <v-divider></v-divider>
            <v-data-table v-model="selected" :headers="header" :items="content" item-key="id" hide-headers hide-actions select-all>
              <template v-slot:items="props">
                <td class="filter-by-status__table-td filter-by-status__table-check">
                  <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
                </td>
                <td class="filter-by-status__table-td">
                  <v-list-tile avatar>
                    <v-list-tile-avatar>
                      <v-icon large :color="props.item.color">{{ props.item.icon }}</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ props.item.name }}</strong>
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </td>
                <td class="text-left">
                  <span class="filter-by-status__table-tickets">
                    {{ props.item.tickets }}
                  </span><br>
                    chamados
                </td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </template>
        </WiView>
      </v-card-text>
      <v-toolbar dense>
        <v-spacer></v-spacer>
        <v-btn block outline color="red" class="filter-by-period__action-btn" @click="clearFilterStatus()" :disabled="selected.length < 1">
          <v-icon>close</v-icon>
          <span>Limpar filtros</span>
        </v-btn>
        <v-btn block outline color="green" class="filter-by-period__action-btn" :disabled="selected.length < 1" @click="setSelectedStatus()">
          <v-icon>filter_list</v-icon>
          <span>Filtrar <span v-if="selected.length > 0"> ({{selected.length}})</span></span>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'FilterByStatus',
    data: () => ({
      dialog: false,
      header: [
        { text: '', value: 'name' },
        { text: 'N° Chamados', value: 'tickets' }
      ],
      selected: [],
      refresh: null,
      filtered: false
    }),
    methods: {
      afterLoad: function (data) {
        this.refresh = data.refresh
      },
      setSelectedStatus: function () {
        this.$Storage.set('filter-by-status', this.selected)
        this.sendSelectedStatusIdsToTickets(true)
        this.closeDialog()
      },
      getSelectedStatus: function () {
        let selected = this.$Storage.get('filter-by-status')
        this.selected = selected || []
      },
      getIdBySelectedStatus: function () {
        return this.selected.map(item => (item.id))
      },
      sendSelectedStatusIdsToTickets: function (refresh) {
        let selectedIds = this.getIdBySelectedStatus()
        if (selectedIds.length > 0) {
          this.$emit('selectStatus', { selectedStatusId: this.getIdBySelectedStatus(), refresh: refresh })
          this.filtered = true
        }
      },
      clearFilterStatus: function () {
        this.selected = []
        this.$emit('selectStatus', { selectedStatusId: null, refresh: true })
        this.$Storage.remove('filter-by-status')
        this.filtered = false
        this.closeDialog()
      },
      closeDialog: function () {
        this.dialog = false
      }
    },
    created: function () {
      this.getSelectedStatus()
      this.sendSelectedStatusIdsToTickets(false)
    }
  }
</script>

<style>
  .filter-by-status__select .v-input__slot {
    margin-bottom: 0 !important;
  }
  .filter-by-status__select-selected-item {
    text-overflow: ellipsis;
    max-width: 30px;
    overflow: hidden;
  }
  .filter-by-status__select-selected-item-name {
    padding-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .filter-by-status__table-td {
    padding: 0 5px !important;
  }
  .filter-by-status__table-tickets {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .column .sortable .active .asc .text-xs-left {
    padding-right: 10px !important;
  }
  .filter-by-status__table-check {
    padding: 0 15px !important;
  }
  .filter-by-period__action-btn {
    margin: 5px !important;
  }
  .filter-by-status__content {
    max-height: 800px !important;
    text-align: center !important;
    padding: 15px 0 50px 0 !important;
  }
  .filter-by-status__content tr[active="true"] {
    background-color: rgba(252, 231, 112, 0.74) !important;
  }
</style>