import { Model } from '@/default/model/Model'
import { PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE } from '@/default/constants/permissions'

export default class HGWWifiSetPasswd extends Model {
  constructor () {
    super()
    this.id = PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE
    this.name = 'hgw-wifi-set-passwd'
    this.title = 'Alterar senha WiFi'
    this.apiUrl = 'networks/test-management-vtal/event/action'
    this.icon = 'wifi_password'

    this.fields = []

    this.maxWidth = '800px'

    this.setFields([
      this.fieldSubHeader({
        text: 'Altere a senha atual e clique em inserir.'
      }),
      this.fieldPassword({
        value: 'passwd',
        text: 'Nova senha',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      }),
      this.fieldSelect({
        value: 'mode',
        text: 'Modo de encriptação',
        options: [
          {value: 'WPAand11i', text: 'WPAand11i'},
          {value: 'WPA2', text: 'WPA2'},
          {value: 'WEP', text: 'WEP'}
        ],
        size: ['xs12', 'sm12', 'md6', 'lg6'],
      })
    ])
  }
}
