<template>
  <wi-report v-if="config" :title="config.title" :config="config" :api="{url: config.api.url, params: config.api.params}">
    <template slot="statistics" slot-scope="props">
      <BusinessComissionByPersonReportDialog :active="dialog" :person="selectedPerson" :refresh="props.refresh" v-on:active="closeDialog"></BusinessComissionByPersonReportDialog>
      <v-layout row wrap>
        <!-- <v-flex v-for="(statistic, statisticKey) in config.statistics" :key="statisticKey" xs12 sm12 md3 lg3 style="padding: 0px 5px;">
          <v-card :color="statistic.color">
            <v-card-text>
              <strong style="color: white;">{{statistic.title}}</strong>
              <strong style="color: white;" :style="{'font-size': statistic.size || '16px'}">{{props.statistics[statistic.field]}}</strong>
            </v-card-text>
          </v-card>
        </v-flex> -->
      </v-layout>
    </template>
    <template slot-scope="props">
      <tr>
        <td style="text-align: center;" :class="{'pointer': props.item.finances && props.item.finances.length > 0}" @click="openDialog(props.item)">
          <strong>{{ props.item.name }}</strong>
        </td>
        <td style="text-align: center;" :class="{'pointer': props.item.finances && props.item.finances.length > 0}" @click="openDialog(props.item)">
          {{ props.item.first_name }}
        </td>
        <td style="text-align: center;" :class="{'pointer': props.item.finances && props.item.finances.length > 0}" @click="openDialog(props.item)">
          <span v-if="props.item.entry_date">{{props.item.created_at}}</span>
          <strong v-else>--/--/--</strong>
        </td>
        <td style="text-align: center;" :class="{'pointer': props.item.finances && props.item.finances.length > 0}" @click="openDialog(props.item)">
          <strong v-if="props.item.finances_count" style="font-size: 18px;">{{ props.item.finances_count }}</strong>
          <strong v-else>0</strong>
        </td>
        <td style="text-align: center;" :class="{'pointer': props.item.finances && props.item.finances.length > 0}" @click="openDialog(props.item)">
          <strong v-if="props.item.finances_sum_amount">{{ props.item.finances_sum_amount | money }}</strong>
          <strong v-else>R$ 0,00</strong>
        </td>
        <td style="text-align: center;">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                Opções
              </v-btn>
            </template>
            <v-list>
              <v-list-tile v-if="props.item.count_opened > 0" @click="$router.push({name: business.module + '-list', params: {page: business.name + '-open'}, query: {where: person.id + ',' + props.item.id}})">
                <v-list-tile-title>{{business.titles}} em Aberto ({{props.item.count_opened}})</v-list-tile-title>
              </v-list-tile>
              <v-list-tile v-if="props.item.count_closed > 0" @click="$router.push({name: business.module + '-list', params: {page: business.name + '-close'}, query: {where: person.id + ',' + props.item.id}})">
                <v-list-tile-title>Histórico de {{business.titles}} ({{props.item.count_closed}})</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="$router.push({name: person.name, params: {id: props.item.id}})">
                <v-list-tile-title>Perfil do {{person.title}}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>    
        </td>
      </tr>
    </template>
    <!-- <template slot="expand" slot-scope="props">
      <v-card dark>
        <v-data-table v-if="props.item.finances && props.item.finances.length > 0" v-model="props['selected-items']" :headers="headers" :items="props.item.finances" item-key="id" select-all class="elevation-1">
          <template v-slot:items="props">
            <td class="text-center">
              <v-checkbox v-model="props.selected" color="white" hide-details></v-checkbox>
            </td>
            <td class="text-center">Processo #{{props.item.business_id}} ({{ props.item.first_name }})</td>
            <td class="text-center">{{ props.item.date_validity_formated }}</td>
            <td class="text-center">{{ props.item.amount | money }}</td>
            <td class="text-center">{{ props.item.number_this }}/{{ props.item.number_total }}</td>
            <td class="text-center">
              <v-btn>Opções</v-btn>
            </td>
          </template>
        </v-data-table>
        <div v-else>
          <h3 class="text-center">Nenhuma parcela para este colaborador</h3>
        </div>
      </v-card>
      
    </template> -->
  </wi-report>
</template>
<script>
  import WiReport from '@/default/component/WiReport/WiReport'
  import BusinessComissionByPersonReportDialog from '@/modules/shared/pages/BusinessReport/BusinessComissionByPersonReportDialog'
  export default {
    name: 'BusinessComissionByPersonReport',
    data () {
      return {
        config: null,
        makeConfigCount: 0,
        dialog: false,
        selectedPerson: null,
        headers: [
          { text: 'Processo (Cliente)', value: 'name', align: 'center' },
          { text: 'Data', value: 'date_validity', align: 'center' },
          { text: 'Valor', value: 'amount', align: 'center' },
          { text: 'Parcela', value: 'number_this', align: 'center' },
          { text: 'Opções', value: 'id', align: 'center' }
        ]
      }
    },
    computed: {
      person: function () {
        var person = {}
        switch (this.$route.meta.person) {
          case 'customer':
            person = {
              id: 'person_id',
              name: 'customer',
              title: 'Cliente',
              first_name: 'Razão Social'
            }
            break
          case 'provider':
            person = {
              id: 'person_id',
              name: 'provider',
              title: 'Fornecedor',
              first_name: 'Razão Social'
            }
            break
          case 'collaborator':
            person = {
              id: 'collaborator_id',
              name: 'collaborator',
              title: 'Colaborador',
              first_name: 'Nome Completo'
            }
            break
        }
        return person
      },
      business: function () {
        var business = {}
        switch (this.$route.meta.business) {
          case 'budget':
            business = {
              module: 'sales',
              name: 'budget',
              names: 'budgets',
              title: 'Orçamento',
              titles: 'Orçamentos'
            }
            break
          case 'quotation':
            business = {
              module: 'purchases',
              name: 'quotation',
              names: 'quotations',
              title: 'Cotação',
              titles: 'Cotações'
            }
            break
          case 'purchase':
            business = {
              module: 'purchase',
              name: 'purchase',
              names: 'purchases',
              title: 'Compra',
              titles: 'Compras'
            }
            break
          case 'sale':
            business = {
              module: 'sales',
              name: 'sale',
              names: 'sales',
              title: 'Venda',
              titles: 'Vendas'
            }
            break
          case 'order':
            business = {
              module: 'service-desk',
              name: 'order',
              names: 'orders',
              title: 'Ordem',
              titles: 'Ordens'
            }
            break
          case 'process':
            business = {
              module: 'juridical',
              name: 'process',
              names: 'process',
              title: 'Processo',
              titles: 'Processos'
            }
            break
        }
        return business
      }
    },
    methods: {
      openDialog: function (person) {
        if (person && person.finances && person.finances.length > 0) {
          this.selectedPerson = person
          this.dialog = true
        }
      },
      closeDialog: function () {
        this.dialog = false
      },
      makeConfig: function () {
        this.config = null
        var config = {
          title: this.business.titles + ' por ' + this.person.title,
          api: {
            url: this.business.module + '/report/' + this.business.names + '-comission-by-' + this.person.name,
            params: {}
          },
          statistics: [
            {field: 'count_total', title: 'Total de ' + this.business.titles + ': ', color: 'primary', size: '16px'},
            {field: 'count_opened_total', title: 'Total em aberto: ', color: 'orange', size: '16px'},
            {field: 'count_closed_total', title: 'Total concluídos: ', color: 'green', size: '16px'},
            {field: 'sum_amount_total', title: 'Valor total: ', color: 'blue', size: '16px'}
          ],
          dates: {
            field: {
              text: 'Data de filtro',
              items: [
                {value: 'date_validity', text: 'Data da Parcela'}
              ]
            },
            start: {
              type: 'date',
              text: 'Data Inicial'
            },
            end: {
              type: 'date',
              text: 'Data Final'
            }
          },
          filters: {
            text: 'Filtros',
            fields: []
          },
          fields: [
            { text: this.person.title, value: 'name', align: 'center', sortable: false },
            { text: this.person.first_name, value: 'first_name', align: 'center', sortable: false },
            { text: 'Data Cadastro', value: 'entry_date', align: 'center', sortable: false },
            { text: 'N° de Parcelas', value: 'finances_count', align: 'center' },
            { text: 'Valor Total', value: 'finances_sum_amount', align: 'center' },
            { sortable: false }
          ]
        }
        if (this.makeConfigCount) {
          setTimeout(() => {
            this.config = config
          }, 500)
        } else {
          this.config = config
        }
        this.makeConfigCount++
      }
    },
    mounted: function () {
      this.makeConfig()
    },
    watch: {
      '$route': 'makeConfig'
    },
    components: {
      WiReport,
      BusinessComissionByPersonReportDialog
    }
  }
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>