export default [{
    id: '800034c4',
    icon: 'space_dashboard',
    title: 'Service Desk',
    route: {
      name: 'service-desk-dashboard'
    }
  },
  {
    id: 'b87a2ece',
    icon: 'leak_add',
    title: 'Chamados por Tipo de Rede',
    route: {
      name: 'tickets-by-network-type-dashboard',
      params: {
        id: 3
      }
    }
  }
]
