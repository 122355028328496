<template>
  <v-text-field 
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    v-model="model"
    :label="text" 
    :mask="mask" 
  ></v-text-field>
</template>
<script>
  export default {
    name: 'FilterFieldText',
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    props: {
      value: {
        required: true
      },
      prependIcon: {
        default: null
      },
      appendIcon: {
        default: null
      },
      placeholder: {
        default: 'Insira aqui...'
      },
      text: {
        required: true
      },
      mask: {
        default: null
      },
    }
  }
</script>