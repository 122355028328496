<template>
  <v-dialog v-model="dialog" persistent scrollable :fullscreen="$vuetify.breakpoint.xsOnly">
    <index
      v-if="dialog"
      v-bind="{ ...$props }"
      @cancel="cancel"
      @onSaved="onSaved"
    ></index>
  </v-dialog>
</template>
<script>
  import index from './index'
  export default {
    name: 'ProducerModelConfigDialog',
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (input) {
          this.$emit('input', input)
        }
      }
    },
    methods: {
      cancel: function () {
        this.$emit('cancel')
        this.dialog = false    
      },
      onSaved: function (response) {
        console.log(response)
        this.$emit('onSaved', response)
        this.dialog = false    
      }
    },
    props: {
      value: {
        required: true
      },
      model: {
        required: true,
        default: () => ({})
      },
      host: {
        required: true,
        default: () => ({})
      },
      mode: {
        type: String,
        default: 'show'
      }
    },
    components: {
      index
    }
  }
</script>
<style scoped>
  .producer-model__carousel {
    max-width: 300px;
  }
  .diagnostic-model {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-model__enable-button {
    cursor: pointer;
  }
  .diagnostic-model__toolbar {
    z-index: 999;
  }

  .diagnostic-model__model {
    text-align: left;
    font-size: 15px;
    color: gray;
  }
  .diagnostic-model__not-found {
    margin: 50px 0;
  }
  .diagnostic-model__title {
    color: red;
  }
  .diagnostic-model__title--signal_is_ok {
    color: green;
  }
</style>
