import { BusinessFilters } from "@/modules/business/config/BusinessFilters"


export class WellFilters extends BusinessFilters {
  constructor () {
    super()
    this.availableFields.status_id.apiUrl = 'wells/config/status/well'
    this.availableFields.category_id.apiUrl = 'wells/config/category/well'

    this.fields = [
      this.availableFields.subHeader1,
      this.availableFields.status_id,
      this.availableFields.category_id,
      this.availableFields.external_code,
      this.availableFields.person_id,
      this.availableFields.collaborator_id,
      this.availableFields.state_id,
      this.availableFields.city_id
    ]
  }
}
