import { Filters } from '@/default/model/Filters'

export class TalkScheduleFilters extends Filters {
  constructor () {
    super()

    this.id = ''
    this.title = 'Filtrar Agendamentos'
    this.maxWidth = '598px'
    this.fields = {
      status_id: this.fieldSelect ({
        value: 'status',
        text: 'Status',
        placeholder: 'Selecione um status',
        options: [
          { value: '1', text: 'Criado' },
          { value: '2', text: 'Agendado' },
          { value: '3', text: 'Enviando' },
          { value: '4', text: 'Enviada' }
        ],
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      talk_channel_id: this.fieldSelectApi ({
        value: 'talk_channel_id',
        text: 'Canal de atendimento',
        show: 'name',
        placeholder: 'Selecione um canal',
        api: {
          url: 'talk/channel',
          params: {
            order: 'name,asc'
          }
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      dateStart: this.fieldDate({
        value: 'date_start',
        text: 'Data de envio - início',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: form => form.date_end
      }),
      dateEnd: this.fieldDate({
        value: 'date_end',
        text: 'Data de envio - término',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        required: form => form.date_start
      }),
      talk_contact_id: this.fieldWiListDialog({
        value: 'talk_contact_id',
        text: 'Contato',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-contact',
        show: 'name',
        icon: 'person_outline'
      }),
      person_id: this.fieldWiListDialog({
        value: 'person_id',
        text: 'Colaborador',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'collaborator.first_name',
        icon: 'assignment_ind'
      })
    }
  }
}
