import {
  PERMISSION_WELLS_CONFIGURATION,
  PERMISSION_FORM
} from '@/default/constants/permissions'

export default {
  id: PERMISSION_WELLS_CONFIGURATION,
  icon: 'settings',
  title: 'Configurações',
  children: [
    {
      id: PERMISSION_WELLS_CONFIGURATION,
      icon: 'code',
      title: 'Status/Etapas de Poços Tubulares',
      route: {
        name: 'wells-list',
        params: {
          page: 'config-status-well'
        }
      }
    },
    {
      id: PERMISSION_WELLS_CONFIGURATION,
      icon: 'code',
      title: 'Status/Etapas de Contratos',
      route: {
        name: 'wells-list',
        params: {
          page: 'config-status-contract'
        }
      }
    },
    {
      id: PERMISSION_WELLS_CONFIGURATION,
      icon: 'code',
      title: 'Status/Etapas de Garantia',
      route: {
        name: 'wells-list',
        params: {
          page: 'config-status-guarantee'
        }
      }
    },
    {
      id: PERMISSION_WELLS_CONFIGURATION,
      icon: 'category',
      title: 'Categorias de Poços Tubulares',
      route: {
        name: 'wells-list',
        params: {
          page: 'config-category-well'
        }
      }
    },
    {
      id: PERMISSION_WELLS_CONFIGURATION,
      icon: 'category',
      title: 'Categorias de Contrato',
      route: {
        name: 'wells-list',
        params: {
          page: 'config-category-contract'
        }
      }
    },
    {
      id: PERMISSION_WELLS_CONFIGURATION,
      icon: 'category',
      title: 'Categorias de Garantia',
      route: {
        name: 'wells-list',
        params: {
          page: 'config-category-guarantee'
        }
      }
    },
    {
      id: PERMISSION_FORM,
      icon: 'list_alt',
      title: 'Dados de Poços Tubulares',
      route: {
        name: 'wells-list',
        params: {
          page: 'well-config-form'
        }
      }
    }
  ]
}
