import FormReplyEditor from '@/modules/forms/config/Form/FormReplyEditor'

export default {
  data: () => ({
    loading: false
  }),
  methods: {
    createWiEditDialog: async function (data) {
      this.loading = true
      const formId = this.form ? this.form.id : this.reply.form.id
      const config = await FormReplyEditor(formId)
      const wiConfig = 'form-' + formId
      this.$store.commit('pages/ADD_CONFIG', {
        name: wiConfig,
        config: config
      })
      this.$WiEditDialog({
        wiConfig: wiConfig,
        data: data,
        redirectOnSubmit: false,
        onSubmit: this.onSubmit
      })
      this.loading = false
    },
    createFormReply: async function () {
      await this.createWiEditDialog({
        ...this.replyEditor
      })
    },
    updateFormReply: async function () {
      await this.createWiEditDialog({
        ...this.replyEditor
      })
    },
    onSubmit: function (response) {
      this.$emit('onSubmit', response)
    }
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    reply: {
      type: Object,
      required: true
    },
    replyEditor: {
      type: Object,
      default: () => ({})
    }
  }
}
