<template>
  <v-flex v-if="business && business.complement && business.complement.length > 0" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
    <v-card class="elevation-17">
      <h3 class="mt-2 mb-2" style="text-align: center;">
        Associar/cadastrar produtos deste fornecedor
      </h3>
      <v-divider></v-divider>
      <div>
        <v-data-table
          dense hide-actions
          class="elevation-0"
          :headers="headers"
          :items="business.complement"
        >
          <template v-slot:headers="props">
            <tr style="height: 40px;">
              <th
                v-for="header in props.headers"
                class="hidden-xs-only hidden-sm-only"
                :key="header.text"
                style="text-align: left;margin: 0 !important; padding: 0px !important;"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template v-slot:items="props">
            <td class="justify-center layout px-0" style="margin-top: 0px;">
              <v-btn outline @click="selectProduct(props.item)" color="primary">
                <v-icon>save</v-icon>
                <span> Cadastrar</span>
              </v-btn>
              <!-- <v-btn icon class="mx-0">
                <v-icon color="red">delete</v-icon>
              </v-btn> -->
            </td>
            <td style="padding: 0 !important; font-size: 11px;">
              {{ props.item.name || '- - -' }}
            </td>
            <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important;">
              {{ props.item.quantity | float }}
            </td>
            <td v-if="showAmounts" class="hidden-xs-only hidden-sm-only" style="padding: 0 !important;">
              {{ props.item.amount_unit | money }}
            </td>
            <td v-if="showAmounts" style="padding: 0 !important;">
              {{ props.item.amount_total | money }}
            </td>
          </template>
        </v-data-table>
        <v-divider></v-divider>
      </div>
    </v-card>
  </v-flex>
</template>
<script>
  export default {
    name: 'BusinessItemsProviders',
    data () {
      return {
        headers: [
          { text: '', value: 'id', sortable: false },
          { text: 'Produto/Serviço', value: 'nome_produto' },
          { text: 'Qtd.', value: 'qtd_produto' },
          { text: 'Val. Unit.', value: 'valor_unit' },
          { text: 'Val. Total', value: 'valor_total' }
        ]
      }
    },
    methods: {
      selectProduct: function (providerProduct) {
        this.$WiListDialog({
          wiConfig: 'product',
          callback: (product) => {
            console.log(product, 'product...')
            this.saveProviderProduct(providerProduct, product)
          },
          mode: 'select',
          wiEditData: {
            ...providerProduct
          },
        })
      },
      saveProviderProduct: function (providerProduct, product) {
        this.$WiApiPost({
          uri: 'purchases/purchase/item-provider',
          data: {
            ...providerProduct,
            business_id: this.business.id,
            item_id: product.id
          },
          callback: () => {
            this.$emit('onRefresh')
          },
          config: {
            msgLoad: {
              title: `Salvando produto...`,
              text: 'Não irá demorar mais que alguns segundos.'
            },
            msgSucess: {
              title: 'Produto salvo com sucesso',
              text: 'O produto foi salvo com sucesso.'
            },
            msgError: {
              title: 'Erro ao salvar produto',
              text: 'Ocorreu um erro ao salvar o produto.'
            },
            msgAsk: {
              title: `Deseja realmente salvar o produto?`,
              text: 'Ao salvar o produto, ele será vinculado a este negócio.'
            }
          }
        })
      }
    },
    props: {
      'wiConfigId': {
        default: ''
      },
      'business': {
        default: {}
      },
      'showAmounts': {
        default: true
      }
    }
  }
</script>
<style>
p {
  margin-bottom: 0px !important;
}
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
</style>
