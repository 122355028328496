import i18n from '@/lang/i18n'
import { DateTime } from '@/default/plugins/Utils/DateTime'
import { PERMISSION_REGISTER_CHANNEL } from '@/default/constants/permissions'

export default {
  id: PERMISSION_REGISTER_CHANNEL,
  icon: 'add_circle_outline',
  title: i18n.t('modules.registers.menu.channels.title'),
  children: [
    {
      id: PERMISSION_REGISTER_CHANNEL,
      icon: 'equalizer',
      title: i18n.t('modules.registers.menu.channels.statistics.title'),
      route: {
        name: 'channel-statistics-by-customer',
        query: {
          date_field: 'created_at',
          date_start: DateTime.date(),
          date_end: DateTime.date()
        }
      }
    },
    {
      id: PERMISSION_REGISTER_CHANNEL,
      icon: 'add_circle_outline',
      title: i18n.t('modules.registers.menu.channels.channels.title'),
      route: {
        name: 'register-list',
        params: {
          page: 'channel'
        }
      }
    }
  ]
}


// channels: {
//   title: 'Aquisição de clientes',
//   statistics: {
//     title: 'Clientes por canais de entrada'
//   },
//   channels: {
//     title: 'Canais de entrada'
//   }
// }