<template>    
  <v-card-text style="text-align: center !important; padding: 10px 0px !important;">
    <div style="min-height: 50px !important; display: contents;">
      <div v-if="file.type_name.split('/')[0] == 'image'">
        <v-img
          :src="file.url"
          lazy-src="static/icons/not-found.png"
          :alt="file.alt_text || ''"
          :aspect-ratio="aspectRatio"
          max-height="57px"
        >
          <template v-slot:placeholder>
            <v-layout fill-height align-center justify-center ma-0>
              <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
            </v-layout>
          </template>
        </v-img>
      </div>
      <div v-else-if="file.type_name.split('/')[0] == 'application'">
        <img v-if="file.type_name.split('/')[1] == 'pdf'" src="/static/icons/file_types/pdf_96.png" alt="" style="max-height: 50px;">
        <img v-else-if="file.type_name.split('/')[1] == 'csv'" src="/static/icons/file_types/csv_96.png" alt="" style="max-height: 50px;">
        <img v-else-if="file.type_name.split('/')[1] == 'xls'" src="/static/icons/file_types/xls_96.png" alt="" style="max-height: 50px;">
        <img v-else-if="file.type_name.split('/')[1] == 'xlsx'" src="/static/icons/file_types/xls_96.png" alt="" style="max-height: 50px;">
        <img v-else-if="file.type_name.split('/')[1] == 'mp4'" src="/static/icons/file_types/video_96.png" alt="" style="max-height: 50px;">
        <img v-else src="/static/icons/file_types/arquivo_96.png" alt="" style="max-height: 50px;">
      </div>
      <img v-else src="/static/icons/file_types/arquivo_96.png" alt="" style="max-height: 50px;">
      <v-list v-if="showFileName" dense style="padding: 0px !important;">
        <v-list-tile style="padding: 0px !important;">
          <v-list-tile-content style="padding: 0px !important;">
            <v-list-tile-title style="text-align: center; padding: 0px !important;">
              {{ file.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </div>
  </v-card-text>
</template>
<script>
  export default {
    name: 'WiFileListItem',
    data: () => ({
      aspectRatio: 16 / 9
    }),
    props: {
      file: {
        required: true
      },
      showFileName: {
        default: true
      }
    }
  }
</script>
