<template>
  <v-container>
    <v-layout wrap>
      <v-flex style="padding: 0 10px 0 10px;">
        <h1>
          <span v-t="'pages.dashboard.welcome'"></span>
          <span v-if="user"> {{user.name}}</span>.
        </h1>
        <h3 v-t="'pages.dashboard.description'"></h3>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    name: 'Dashboard',
    computed: {
      user () {
        return this.$store.state.User
      }
    }
  }
</script>
