<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title primary-title>
        <h2>Tem certeza que deseja recriar o documento?</h2>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="business-layout-alert__content">
          Todas as alterações manuais do documento serão perdidas e ele será recriado com os dados atuais do(a) {{business.info.title}}.
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outline @click="dialog = false">
          <v-icon>close</v-icon>
          <span> Cancelar</span>
        </v-btn>
        <v-btn color="success" outline @click="recreateLayout()">
          <v-icon>done</v-icon>
          <span> Sei o que estou fazendo</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'BusinessLayoutAlertDialog',
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      recreateLayout: function () {
        this.$WiApiPut({
          uri: `business/layout/${this.business.id}/recreate`,
          callback: () => {
            console.log('callback')
            this.refresh()
            this.$emit('recreated')
          },
          config: {
            msgLoad: {
              title: `Recriando documento...`,
              text: `Processando e recriando ${this.business.info.title}`
            },
            msgSucess: {
              title: 'Documento recriado com sucesso!',
              text: 'Você terá acesso ao documento em instantes.'
            },
            msgError: {
              title: 'Erro ao recriar documento',
              text: 'Tente novamente ou contacte o suporte.'
            }
          }
        })
        this.dialog = false
        this.$emit('recreate')
      }
    },
    props: {
      value: {
        required: true
      },
      business: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    }
  }
</script>
<style scoped>
  .business-layout-alert__content {
    font-size: 21px;
  }
</style>
