var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-list',{attrs:{"two-line":"","subheader":"","dense":""}},[_c('v-subheader',[_c('strong',[_c('span',[_vm._v("Informações Complementares ")]),(_vm.person.files)?_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v(" ("+_vm._s(_vm.person.files.length)+") ")]):_vm._e()]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outline":"","color":"green"},on:{"click":function($event){return _vm.$WiEditDialog({
                wiConfig: 'person-file',
                onSubmit: _vm.refresh,
                data: {
                  person_id: _vm.$route.params.id
                },
                redirectOnSubmit: false
              })}}},[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Arquivos")])],1)],1),(_vm.person.files.length > 0)?_c('div',_vm._l((_vm.person.files),function(info,keyInfo){return _c('div',{key:keyInfo + '-info'},[_c('v-divider',{attrs:{"inset":""}}),_c('v-list-tile',{attrs:{"avatar":""}},[_c('v-list-tile-avatar',[(info.file.extension == 'jpeg' || info.file.extension == 'jpg' || info.file.extension == 'png')?_c('v-img',{staticStyle:{"border":"solid 1px lightgrey"},attrs:{"src":info.file.url}}):(info.file.extension == 'pdf')?_c('v-icon',{staticClass:"red white--text"},[_vm._v("picture_as_pdf")]):_c('v-icon',{staticClass:"blue white--text"},[_vm._v("description")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('strong',[_vm._v(" "+_vm._s(info.description || '- - -'))])]),_c('v-list-tile-sub-title',[_vm._v(" "+_vm._s(info.file.name)+"."+_vm._s(info.file.extension)+" ")])],1),_c('v-list-tile-action',[(info.file.url)?_c('v-btn',{staticClass:"px-2",attrs:{"href":info.file.url,"ripple":"","outline":"","target":"_blank","small":""}},[_c('v-icon',[_vm._v("open_in_new")]),_c('span',{staticClass:"ml-1"},[_vm._v("Acessar")])],1):_vm._e()],1),_c('v-list-tile-action',[_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': _vm.wiConfig.id.update, 'notCheck': _vm.person.id == _vm.user.id}),expression:"{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"}],attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.$WiEditDialog({
                      wiConfig: 'person-file',
                      onSubmit: _vm.refresh,
                      data: info,
                      redirectOnSubmit: false
                    })}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("edit")])],1),_c('db-delete',{directives:[{name:"prms",rawName:"v-prms",value:({'id': _vm.wiConfig.id.update, 'notCheck': _vm.person.id == _vm.user.id}),expression:"{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"}],attrs:{"store":"info","id":info.id},on:{"action":_vm.refresh}})],1)],1)],1)}),0):_c('div',[_c('v-divider'),_c('div',{staticStyle:{"margin":"20px 0","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","gap":"20px"}},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("Nenhum arquivo no momento")])]),_c('div',{staticClass:"description"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v("Adicione arquivos para complementar as informações do cadastro.")])]),_c('div',{staticClass:"action"},[_c('v-btn',{attrs:{"small":"","outline":"","color":"green"},on:{"click":function($event){return _vm.$WiEditDialog({
                    wiConfig: 'person-file',
                    onSubmit: _vm.refresh,
                    data: {
                      person_id: _vm.$route.params.id
                    },
                    redirectOnSubmit: false
                  })}}},[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Adicionar arquivo")])],1)],1)])],1),_c('v-divider')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }