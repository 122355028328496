<template>
  <v-container fluid style="padding: unset !important;">
    <v-layout row wrap>
      <v-scroll-y-transition mode="out-in">
        <v-flex v-if="showOptions" class="task__areas" xs12>
          <v-card flat class="text-center">
            <v-btn v-if="$WiCheckPermission(importImanagerPermission)" outline @click="importCustomer()">
              <v-icon>description</v-icon>
              <span v-if="host.contracts && host.contracts[0]">Atualizar dados do I-Manager</span>
              <span v-else>Importar dados do I-Manager</span>
            </v-btn>
            <v-btn outline @click="updateHostSignal()">
              <v-icon>router</v-icon>
              <span>Atualizar dados do equipamento</span>
            </v-btn>
          </v-card>
        </v-flex>
      </v-scroll-y-transition>
      <v-flex class="task__areas mx-1" xs12>
        <v-card
          class="elevation-10 white--text"
          :color="(host.online ? ( host.signal_is_ok ? '#008000': '#FFA500' ) : '#FF0000')"
          style="text-align: left;"
        >
          <v-card-title style="padding: 8px 15px">
            Informações do equipamento
            <v-spacer/>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex class="task__areas" xs12 sm12 md4 lg4 xl4>
        <HostInfo
          title="Serial/MAC/IP"
          :content="macAndIpAddress"
          icon="dog_tag_96.png"
        ></HostInfo>
      </v-flex>
      <v-flex v-if="host.technology" class="task__areas" xs12 sm12 md4 lg4 xl4>
        <HostInfo
          title="Tecnologia/Tipo de tecnologia"
          :content="technologyAndTechnologyType"
          icon="system_task_96.png"
        ></HostInfo>
      </v-flex>
      <v-flex class="task__areas" xs12 sm12 md4 lg4 xl4>
        <HostInfo
          title="Fabricante/Modelo"
          :content="producerAndProducerModel"
          icon="service_96.png"
          action
          @action="producerAndProducerModelAction"
        ></HostInfo>
      </v-flex>
      <v-flex v-if="deviceAndNetworks" class="task__areas" xs12 sm12 md8 lg8 xl8>
        <HostInfo
          title="Dispositivo/Rede"
          :content="deviceAndNetworks"
          icon="load_balancer_96.png"
        ></HostInfo>
      </v-flex>
      <v-flex v-if="childs" class="task__areas" xs12 sm12 md4 lg4 xl4>
        <HostInfo
          title="Placa/Porta"
          :content="childs"
          icon="choose_96.png"
        ></HostInfo>
      </v-flex>
    </v-layout>
    <v-layout v-if="host.contracts && host.contracts[0]" row wrap>
      <v-flex class="task__areas mx-1" xs12>
        <v-card
          class="elevation-10 white--text"
          :color="(host.online ? ( host.signal_is_ok ? '#008000': '#FFA500' ) : '#FF0000')"
          style="text-align: left;"
        >
          <v-card-title style="padding: 8px 15px">
            Informações do contrato
            <v-spacer/>
          </v-card-title>
        </v-card>
      </v-flex>
      <HostContract
        :contract="host.contracts[0]"
      ></HostContract>
    </v-layout>
    <v-layout v-else-if="$WiCheckPermission(importImanagerPermission)" row wrap>
      <v-flex class="task__areas" xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-text style="text-align: center;">
            <h1>Importar cliente do I-Manager</h1>
            <br><br>
            <v-btn block outline @click="importCustomer()">Importar agora</v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <HostProducerModelDialog
      v-if="producerModelDialog && producerAndProducerModel"
      v-model="producerModelDialog"
      :model="host.producer_model"
      :host="host"
    ></HostProducerModelDialog>
  </v-container>
</template>
<script>
  import HostInfo from './HostInfo'
  import HostContract from './HostContract'
  import HostProducerModelDialog from '../ProducerModel/Dialog'
  import { PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER } from '@/default/constants/permissions'
  export default {
    name: 'HostDetail',
    data: () => ({
      producerModelDialog: false
    }),
    computed: {
      macAndIpAddress () {
        if (this.host.serial || this.host.mac_address || this.host.ip_address) {
          return `<strong>${this.host.serial || this.host.mac_address}</strong> / ${this.host.ip_address || '- - -'}`
        }
        return null
      },
      technologyAndTechnologyType () {
        if (this.host.technology_id) {
          return `<strong>${this.host.technology?.name}</strong> / ${this.host.technology_type?.name || '- - -'}`
        }
        return null
      },
      producerAndProducerModel () {
        if (this.host.producer_id) {
          return `<strong>${this.host.producer?.name}</strong> / ${this.host.producer_model?.name || '- - -'}`
        }
        return null
      },
      deviceAndNetworks () {
        if (this.host?.active_devices?.length > 0) {
          const device = this.host?.active_devices[0]
          return `<strong>${ device.name }</strong> / <span>${ device.network_names }</span>`
        }
        return false
      },
      childs () {
        if (this.host?.active_childs?.length > 0) {
          let childs = ''
          this.host?.active_childs.forEach((child, childKey) => {
            if (childKey) {
              childs += ', '
            }
            childs += `<span>${child.type.name}: </span><strong>${child.name}</strong>`
          })
          return childs
        }
        return false
      },
      importImanagerPermission () {
        return PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER
      }
    },
    methods: {
      refresh: function (response) {
        this.$emit('refresh', response)
      },
      producerAndProducerModelAction: function () {
        this.producerModelDialog = true
      },
      importCustomer: function () {
        this.$WiApiPost({
          uri: 'register/import/customer/i-manager/by-mac-address',
          data: {
            mac_address: this.host.mac_address
          },
          callback: this.refresh,
          config: {
            msgAsk: {
              title: 'Importar dados do cliente deste equipamento?',
              text: 'Os dados do cliente serão importados no I-Manager'
            },
            msgLoad: {
              title: 'Importando dados do equipamento...',
              text: 'Isso pode levar alguns segundos. Por favor aguarde.'
            },
            msgError: {
              title: 'Oops... Falha na comunicação com o I-Manager',
              text: 'Isso geralmente acontece quando o I-Manager não responde. Tenta novamente ou contacte o suporte.'
            },
            msgNotFound: {
              title: 'MAC não encontrado.',
              text: 'O MAC não foi encontrado no I-Manager.'
            },
            msgSucess: {
              title: 'Dados do equipamento importados com sucesso!',
              text: 'A importação foi concluída satisfatoriamente.'
            }
          }
        })
      },
      updateHostSignal: function () {
        this.$WiApiPost({
          uri: `network/host/${this.host.id}/information`,
          data: {
            host_id: this.host.id
          },
          callback: this.refresh,
          config: {
            msgLoad: {
              title: `Verificando potência...`,
              text: 'Pode demorar alguns segundos.'
            },
            msgSucess: {
              title: 'Tudo certo!',
              text: 'Informações atualizadas com sucesso!'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja realizar nova leitura?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          }
        })
      }
    },
    props: {
      host: {
        required: true
      },
      showOptions: {
        default: false
      }
    },
    components: {
      HostInfo,
      HostContract,
      HostProducerModelDialog
    }
  }
</script>
<style scoped>
  .task__areas {
    margin-bottom: 20px;
  }
  .task__info-number {
    font-size: 18px;
  }
  .task__info-text {
    font-size: 14px;
  }
  .task__info--left {
    margin-right: 5px;
  }
  .task__info--right {
    margin-left: 5px;
  }
  .task__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .task__info-avatar {
    min-width: 50px !important;
  }
  .task__row--bottom {
    margin-bottom: 10px;
  }
  .task__row--top {
    margin-top: 10px;
  }
</style>
