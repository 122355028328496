export default {
  id: 'e27dc397',
  title: 'Alterar Previsão do Chamado',
  apiUrl: 'service-desk/ticket',
  actions: {
    delete: false,
    update: true
  },
  fields: [
    {text: 'Informe abaixo a nova previsão deste chamado. ATENÇÃO: Esta alteração irá modificar o SLA.', type: 'subheader', edit: true},
    {value: 'prevision', text: 'Nova previsão de duração', type: 'text', mask: '##:##:##', required: true, list: false, edit: true}
  ],
  fieldsFixed: [
    {
      value: 'alter_previsao_chamado', data: true
    }
  ]
}
