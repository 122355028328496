import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Formatter from './formatter'
import ptBr from './locales/pt-BR'
import enGB from './locales/pt-BR'
// import ptBr from './localDefault'

Vue.use(VueI18n)

const currentLocale = localStorage.getItem('locale')

const locale = currentLocale || 'pt-BR'
const formatter = new Formatter({ locale })

// console.log('localDefault', JSON.stringify(ptBr))

export default new VueI18n({
  locale,
  formatter,
  messages: {
    'pt-BR': ptBr,
    'en-US': enGB,
    'en-GB': enGB,
  }
})