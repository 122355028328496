import { Filters } from '@/default/model/Filters'

export class SaleFilters extends Filters {
  constructor () {
    super()
    this.id = ''
    this.title = 'Filtrar Equipamentos'
    this.fields = {
      subHeader1: this.fieldSubHeader({
        text: 'Selecione uma ou mais opções para filtrar.'
      }),
      person_id: this.fieldWiListDialog({
        value: 'person_id',
        text: 'Cliente',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline'
      }),
      collaborator_id: this.fieldWiListDialog({
        value: 'collaborator_id',
        text: 'Colaborador Responsável',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'collaborator.first_name',
        icon: 'assignment_ind'
      }),
      status_id: this.fieldSelectApi({
        value: 'status_id',
        text: 'Status/Etapa',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        api: {
          url: 'sales/config/status/sale',
          params: { fields: 'id,name', where: 'active,1', order: 'order,asc' }
        },
        show: 'name'
      }),
      category_id: this.fieldSelectApi({
        value: 'category_id',
        text: 'Categoria',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        api: {
          url: 'sales/config/category/sale',
          params: {fields: 'id,name', order: 'name,asc'}
        },
        show: 'name'
      }),
      external_code: this.fieldText ({
        value: 'external_code',
        text: 'Código Externo',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      subHeader2: this.fieldSubHeader({
        text: 'Filtrar por Produtos ou Serviços existentes.'
      }),
      product_id: this.fieldWiListDialog({
        value: 'product_id',
        text: 'Produto',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'product',
        show: 'product.name',
        icon: 'local_grocery_store'
      }),
      service_id: this.fieldWiListDialog({
        value: 'service_id',
        text: 'Serviço',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'service',
        show: 'service.name',
        icon: 'room_service'
      }),
      codesId: this.fieldWiListDialog({
        value: 'code_id',
        text: 'Código',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'product-code',
        show: 'code.name',
        icon: 'category'
      })
    }
  }
}
