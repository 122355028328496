const event = (id, name) => {
  return {
    id: id,
    title: 'Adicionar Nota',
    icon: 'message',
    apiUrl: 'sales/' + name + '/event',
    params: {
      order: 'id,desc'
    },
    fields: [
      {text: 'Descreva sua nota de forma que fique clara aos outros usuários.', type: 'subheader', edit: true},
      {value: 'description', text: 'Insira aqui sua nota.*', type: 'editor', required: true, edit: true}
    ],
    fieldsFixed: [
      {
        value: 'type', data: 2
      }
    ]
  }
}

export default {
  sale: event('b41dcfec', 'sale'),
  budget: event('d3c48579', 'budget')
}
