import { Model } from '@/default/model/Model'
import { PERMISSION_FINANCE_EXPORT_XLSX } from '@/default/constants/permissions'

export class FinanceExport extends Model {
  constructor () {
    super()
    this.id = PERMISSION_FINANCE_EXPORT_XLSX
    this.list = true
    this.title = 'Exportar Financeiro'
    this.apiUrl = 'finance/export'
    this.icon = 'upload'
    this.maxWidth = '798px'
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o período'
    }),
    date_field: this.fieldSelect({
      value: 'date_field',
      text: 'Campo de Data*',
      required: true,
      options: [
        { value: 'date_validity', text: 'Data de Vencimento' },
        { value: 'date_receive', text: 'Data de Pagamento' },
        { value: 'date_competence', text: 'Data de Competência' }
      ],
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data Inicial*',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data Final*',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Filtros adicionais'
    }),
    finance_category_id: this.fieldSelectApi({
      value: 'finance_category_id',
      text: 'Categoria Financeira',
      show: 'name',
      api: {
        url: 'finance/category',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    finance_account_id: this.fieldSelectApi({
      value: 'finance_account_id',
      text: 'Conta Financeira',
      show: 'name',
      api: {
        url: 'finance/account',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Filtrar por pessoa'
    }),
    person_type: this.fieldSelect({
      value: 'person_type',
      text: 'Tipo de Pessoa',
      options: [
        { value: 'customer', text: 'Cliente' },
        { value: 'provider', text: 'Fornecedor' },
        { value: 'collaborator', text: 'Colaborador' }
      ],
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    person_id_collaborator: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Colaborador',
      wiConfig: 'collaborator',
      show: 'person.first_name',
      icon: 'person_outline',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => form.person_type && form.person_type === 'collaborator'
    }),
    person_id_customer: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Cliente',
      wiConfig: 'customer',
      show: 'person.first_name',
      icon: 'person_outline',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => form.person_type && form.person_type === 'customer'
    }),
    person_id_provider: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Fornecedor',
      wiConfig: 'provider',
      show: 'person.first_name',
      icon: 'person_outline',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => form.person_type && form.person_type === 'provider'
    })
  }
} 