import { BusinessFinance } from '@/modules/shared/config/Business/BusinessFinance'

class FinanceReceive extends BusinessFinance {
  constructor () {
    super()
    this.id = '4d8brfa0'
    this.name = 'process-finance-paid'
    this.title = 'Receber Parcela do Projeto de Formatura'
    this.icon = 'get_app'

    // Fields
    this.fields[1].text = 'Informe a data de recebimento desta parcela.'
  }
}

export default new FinanceReceive()
