<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="diagnostic-tickets__enable-button elevation-2">
        <v-card-text style="text-align: center;">
          <h1>{{ count }}</h1>
          <v-divider></v-divider>
          <strong>{{ title }}</strong>
        </v-card-text>
      </div>
    </template>
    <v-card class="diagnostic-tickets">
      <v-toolbar dense class="diagnostic-tickets__toolbar">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="count > 0">
        <h4>
          <span>{{count}} </span>
          <span> {{ title }} encontrado(s).</span>
        </h4>
        <br>
        <v-layout row wrap>
          <v-flex xs6 sm6 md3 lg3 xl3 style="padding-right: 10px;" v-for="(ticket, ticketKey) in tickets" :key="ticketKey">
            <v-card class="elevation-10" target="_blank" link :to="{name: 'ticket', params: {id: ticket.id}}">
              <v-card-text class="diagnostic-tickets__tickets">
                <img src="/static/icons/monitor/workers_96.png" width="50px">
                <div>
                  <strong>ID: </strong> {{ticket.id}}
                </div>
                <div>
                  <strong>Abertura: </strong> {{ticket.opened_at | moment('DD/MM/YY HH:mm')}}
                </div>
                <div>
                  <strong>Previsão: </strong> {{ticket.prevision}}
                </div>
                <div>
                  <strong>Status: </strong> {{ticket.status}}
                </div>
                <div>
                  <strong>Ocorrência: </strong> {{ticket.category}}
                </div>
                <div>
                  <strong>Impacto: </strong> {{ticket.impact}}
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-tickets__not-found">
        <h2>Nenhum chamado encontrado. </h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DiagnosticTickets',
    data: () => ({
      dialog: false,
    }),
    props: {
      title: {
        default: 'Chamado(s) '
      },
      tickets: {
        required: true,
        default: () => ([])
      },
      count: {
        required: true,
        default: 0
      }
    }
  }
</script>
<style scoped>
  .diagnostic-tickets {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-tickets__enable-button {
    cursor: pointer;
  }
  .diagnostic-tickets__toolbar {
    z-index: 999;
  }

  .diagnostic-tickets__tickets {
    text-align: center;
    padding: 16px 0px !important;
  }
  .diagnostic-tickets__not-found {
    margin: 50px 0;
  }
</style>
