<template>
  <div>
    <v-toolbar dense flat>
      <v-icon large v-if="icon">{{ icon }}</v-icon>
      <h2 class="pl-3">{{ title }}</h2>
    </v-toolbar>
    <v-divider></v-divider>
    <v-layout align-center justify-center row wrap class="pt-3 pl-3 pr-3">
      <v-flex sm5>
        <WiImageDialog :src="config.image" :title="title"></WiImageDialog>
      </v-flex>
      <v-flex sm7>
        <v-card-text>
          <v-divider></v-divider>
          <div dense three-line v-for="(item, itemKey) in config.items" :key="itemKey">
            <v-divider v-if="itemKey"></v-divider>
            <v-layout align-center justify-center row wrap>
              <v-flex sm2 style="font-size: 18px; min-width: 150px; text-align: left;">
                <strong>{{ item.number }} - {{ item.name }}: </strong>
              </v-flex>
              <v-flex sm9 style="text-align: left;">
                <span style="color: gray;">{{ item.description }}</span>
              </v-flex>
            </v-layout>
          </div>
          <v-divider></v-divider>
        </v-card-text>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  export default {
    name: 'ProducerModelItem',
    props: {
      name: {
        type: String,
        required: true
      },
      model: {
        required: true
      },
      host: {
        required: true
      },
      config: {
        type: Object,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    }
  }
</script>