<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog.active"
      scrollable
      persistent
      :max-width="(88 - (index * 2)) + '%'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            <v-icon medium>medication</v-icon>
            <span>Buscar medicamentos</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search" 
            label="Pesquise um medicamento"
            single-line
            hide-details
            solo
            dense
            small
            dark
            @keyup="getRemediesBySearch()"
            :append-icon="runSearch ? 'close' : 'search'"
            @click:append="() => { if(runSearch) { searchClear() } else { getRemediesBySearch() }  }" 
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn icon flat :loading="loading" @click="getRemedies()">
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-btn icon flat @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="padding: 0 !important;" :style="{'min-height': (400 - (index * 10)) + 'px'}">
          <v-data-table
            :headers="headers"
            :items="remedies"
            class="wi-remedies__table elevation-10"
            item-key="id"
            hide-actions
            :loading="loading"
            :total-items="totalRemedies"
            no-results-text="Nenhum resultado encontrado"
            :rows-per-page-items="[10, 5, 50, 100, 200]" 
            rows-per-page-text="Itens por página"
            disable-initial-sort
            :hide-headers="!totalRemedies"
          >
            <template v-slot:progress>
              <v-progress-linear v-if="loading" color="blue" indeterminate></v-progress-linear>
            </template>
            <template v-slot:items="props">
              <td class="text-left wi-remedies__table_col">
                <v-btn small color="green" outline @click="selectRemedy(props.item)">
                  <v-icon>done</v-icon>
                  <span> Selecionar</span>
                </v-btn>
              </td>
              <td class="text-left wi-remedies__table_col">{{ props.item.id }}</td>
              <td class="text-left wi-remedies__table_col">
                <strong>{{ props.item.name }}</strong>
              </td>
              <td class="text-left wi-remedies__table_col">{{ props.item.description }}</td>
              <td class="text-left wi-remedies__table_col">{{ props.item.active_principle }}</td>
            </template>
            <template v-slot:no-results>
              <v-card-text class="wi-remedies__table-no-data text-center center-align">
                <h2 class="wi-remedies__table-no-data--text">
                  <span v-if="runSearch">Você pesquisou por <strong>"{{ search }}"</strong>. </span>
                  <span>Nenhum resultado foi encontrado.</span>
                </h2>
                <h3 class="wi-remedies__table-no-data--action">
                  Não encotrou o medicamento?
                  <br>
                  <v-btn outline color="green" @click="$WiHelpDialog({ title: 'Por favor, descreva abaixo a sugestão do medicamento.' })">
                    <v-icon>add</v-icon>
                    <span> Sugerir novo medicamento</span>
                  </v-btn>
                </h3>
              </v-card-text>
            </template>
            <template v-slot:no-data>
              <v-card-text class="wi-remedies__table-no-data text-center center-align">
                <h2 class="wi-remedies__table-no-data--text">
                  <span v-if="runSearch">Você pesquisou por <strong>"{{ search }}"</strong>. </span>
                  <span>Nenhum resultado foi encontrado.</span>
                </h2>
                <h3 class="wi-remedies__table-no-data--action">
                  Não encotrou o medicamento?
                  <br>
                  <v-btn outline color="green" @click="$WiHelpDialog({ title: 'Por favor, descreva abaixo a sugestão do medicamento.' })">
                    Sugerir novo medicamento
                  </v-btn>
                </h3>
              </v-card-text>
            </template>
            <template v-slot:pageText="props">
              Exibindo {{ props.pageStart }} até {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-toolbar dense>
          <h3 style="text-align: center;">
            {{ totalRemedies | number }} medicamentos encontrados
          </h3>
          <v-spacer></v-spacer>
          <v-pagination
            :disabled="loading"
            color="primary"
            :length="totalPages"
            v-model="page"
            total-visible="5"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
  import axios from 'axios'
  import { Debounce } from '@/default/plugins/Utils/Debounce'
  export default {
    name: 'WiRemediesDialog',
    data: () => ({
      search: '',
      runSearch: false,
      headers: [
        { text: 'Selecionar', value: 'select', align: 'center', width: '100px', sortable: false },
        { text: 'ID', value: 'id', align: 'left', width: '100px', sortable: false },
        { text: 'Nome Comercial', value: 'name', align: 'left', sortable: false },
        { text: 'Apresentação', value: 'description', align: 'left', sortable: false },
        { text: 'Princípio Ativo', value: 'active_principle', align: 'left', sortable: false }
      ],
      remedies: [],
      loading: false,
      currentPage: 1,
      currentPerPage: 9,
      totalPages: 0,
      totalRemedies: 0,
      debounce: null
    }),
    computed: {
      dialog () {
        var WiRemediesDialog = this.$store.state.WiRemediesDialog
        return WiRemediesDialog.dialogs[this.index]
      },
      page: {
        get () {
          return this.currentPage
        },
        set (page) {
          this.currentPage = page
          this.getRemedies()
        }
      },
      perPage: {
        get () {
          return this.currentPerPage
        },
        set (perPage) {
          this.currentPerPage = perPage
          this.getRemedies()
        }
      }
    },
    methods: {
      close: function () {
        this.$store.dispatch('WiRemediesDialog/close', this.index)
        this.$forceUpdate()
      },
      getRemedies: function () {
        this.loading = true
        axios.get('https://clinic.wisecontrol.app/api/v1/clinic/remedy/', {
          params: {
            search: this.search || null,
            perPage: this.perPage,
            page: this.page
          },
          headers: {
            'X-key-WN-PSK-a5a4b858': '27871d09-abd0-4784-aacf-d40e941ea0e5-31c57fad-e318-4a8f-b789-582d2ba74b88',
            'Accept': 'application/json'
          }
        })
        .then(response => {
          this.loading = false
          this.totalPages = response.data.total_pages
          this.totalRemedies = response.data.total
          this.remedies = response.data.data
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 103, title: 'Erro ao buscar medicamentos.'})
          this.loading = false
        })
      },
      getRemediesBySearch () {
        this.loading = true
        this.runSearch = true
        this.debounce = Debounce.execute(this.debounce, () => {
          this.getRemedies()
        }, 500)
      },
      searchClear: function () {
        this.search = ''
        this.runSearch = false
        this.getRemedies()
      },
      selectRemedy: function (remedy) {
        this.dialog.onSelect(remedy)
        this.close()
      }
    },
    mounted: function () {
      this.getRemedies()
    },
    props: {
      'index': {
        default: 0
      }
    }
  }
</script>
<style scoped>
  .wi-remedies__table .v-datatable__actions {
    bottom: unset !important;
    position: unset !important;
  }
  .wi-remedies__table {
    background: blue !important;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .wi-remedies__table_col {
    padding: 0 8px !important;
  }
  .wi-remedies__table-no-data {
    text-align: center !important;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .wi-remedies__table-no-data--text {
    padding-bottom: 20px;
    font-weight: normal;
  }
  .wi-remedies__table-no-data--action {
    text-align: center;
    font-weight: normal;
  }
</style>