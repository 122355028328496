export default {
  list: true,
  id: '130350fc',
  title: {
    list: 'Redes',
    edit: {
      insert: 'Vinculando nova rede',
      update: 'Editando Rede'
    }
  },
  icon: 'show_chart',
  search: {
    placeholder: 'Pesquise pelo ID, nome ou sigla da rede',
    fields: ['network.id', 'network.nome_grupo', 'network.sigla_grupo']
  },
  noData: {
    text: 'Esta equipe ainda não tem nenhuma rede <br> Adicione uma nova rede clicando nos botões abaixo.'
  },
  apiUrl: 'register/team/network',
  params: {
    limit: 5
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  fieldsSelected: {
    itemName: 'rede(s)'
  },
  fields: [
    {
      text: 'Selecione o grupo que deseja vincular a esta equipe.',
      type: 'subheader',
      edit: true
    },
    {value: 'network.id', text: 'ID', list: true},
    {value: 'network.nome_grupo', text: 'Nome da rede', list: true},
    {value: 'network.sigla_grupo', text: 'Sigla da rede', list: true},
    {value: 'network.type.name', text: 'Tipo da Rede', list: true},
    {value: 'network.network.name', text: 'Rede Pai', list: true},
    {value: 'grupo_dados_id', text: 'Selecione a rede que deseja vincular', autocomplete: true, required: false, type: 'dbselect', list: false, edit: true, instore: 'grupos', outstore: 'grupo-pai', show: 'nome_grupo', fields: false}
  ]
}
