<template>
  <v-dialog v-model="dialog" persistent scrollable :max-width="config.maxWidth || '800px'" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <v-badge class="wi-list-filters__badge" right color="orange" :value="filtersApplyCount" overlap>
        <template v-slot:badge>
          <span> {{ filtersApplyCount }} </span>
        </template>
        <v-btn v-if="mode == 'button'" v-on="on" outline small  :color="color || 'white'">
          <v-icon>filter_list</v-icon>
          <span style="padding-left: 5px;"> Filtrar </span>
        </v-btn>
        <v-btn v-else v-on="on" icon class="ml-0 mr-0">
          <v-icon :color="color || 'white'">filter_list</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card class="diagnostic-model" style="border-radius: 10px;">
      <v-toolbar dense class="diagnostic-model__toolbar" style="border-radius: 10px;">
        <v-toolbar-title>
          <v-icon>filter_list</v-icon>
          <span> {{ config.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="wi-list-filters__content">
        <v-container v-if="dialog" style="padding: 0px !important;">
          <v-form v-model="valid" ref="wiListFiltersDialog" autocomplete="off">
            <v-layout row wrap>
              <!-- eslint-disable -->
              <v-flex
                v-for="(field, keyField) in config.fields"
                :key="keyField"
                v-if="!field.showField || field.showField(filters)"
                v-bind="sizeField(field.size)"
                d-flex style="padding: 0 10px 0 10px;"
              >
                <component
                  v-model="filters[field.value]"
                  :filters="filters"
                  :field="field.value"
                  :is="getFieldComponent(field)"
                  v-bind="{ ...field }"
                ></component>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-toolbar dense class="elevation-17 wi-list-filters__footer" style="border-radius: 10px;">
        <v-spacer></v-spacer>
        <v-btn :disabled="!filtersApplyCount" color="orange" outline @click="clearFilter()">
          <span style="padding-right: 5px;">Limpar Filtros</span>
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn :disabled="!valid || !filtersCount" color="success" @click="filter()">
          <span style="padding-right: 5px;">Filtrar</span>
          <v-icon>check</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>
<script>
  import FilterFieldDate from './Fields/FilterFieldDate'
  import FilterFieldText from './Fields/FilterFieldText'
  import FilterFieldSelect from './Fields/FilterFieldSelect'
  import FilterWiListDialog from './Fields/FilterWiListDialog'
  import FilterFieldSelectApi from './Fields/FilterFieldSelectApi'
  import FilterFieldSubHeader from './Fields/FilterFieldSubHeader'
  export default {
    name: 'WiListFilters',
    data: () => ({
      dialog: false,
      valid: false,
      filters: {},
      filtersApplyCount: 0
    }),
    computed: {
      filtersCount: function () {
        return Object.keys(this.filters)
          ?.filter(
            key => 
              this.filters[key] !== null && 
              this.filters[key] !== undefined && 
              this.filters[key] !== ''
          ).length || 0
      }
    },
    methods: {
      filter: function () {
        this.$emit('filter', this.filters)
        this.setFiltersCount()
        this.$forceUpdate()
        this.dialog = false
      },
      clearFilter: function () {
        const filterCleared = {}
        for (const key in this.config.fields) {
          let field = this.config.fields[key].value
          filterCleared[field] = null
        }
        this.filters = {}
        this.$emit('filter', filterCleared)
        this.setFiltersCount()
        this.$forceUpdate()
        this.dialog = false
      },
      sizeField: function (sizes) {
        var size = {}
        sizes = sizes ? (typeof sizes === 'function' ? sizes(this.filters) : sizes) : ['xs12', 'sm12', 'md12', 'lg12']
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      getFieldComponent: function (field) {
        switch (field.type) {
          case 'subheader':
            return FilterFieldSubHeader
          case 'text':
            return FilterFieldText
          case 'date':
            return FilterFieldDate
          case 'select':
            return FilterFieldSelect
          case 'select-api':
            return FilterFieldSelectApi
          case 'wi-list-dialog':
            return FilterWiListDialog
        }
      },
      setFilters: function () {
        for (const key in this.config.fields) {
          let field = this.config.fields[key].value
          if (this.params[field]) {
            this.filters[field] = this.params[field]
          }
        }
        this.setFiltersCount()
        this.$forceUpdate()
      },
      setFiltersCount: function () {
        this.filtersApplyCount = Object.keys(this.filters)
          ?.filter(
            key => 
              this.filters[key] !== null && 
              this.filters[key] !== undefined && 
              this.filters[key] !== ''
          ).length || 0
      }
    },
    mounted: function () {
      setTimeout(() => {
        this.setFilters()
      }, 500)
    },
    props: {
      mode: {
        default: 'button'
      },
      config: {
        required: true,
        default: () => ({})
      },
      params: {
        default: () => ({})
      },
      color: {
        type: String,
        default: null
      }
    }
  }
</script>
<style scoped>
  .wi-list-filters__badge >>> .v-badge--overlap .v-badge__badge {
    top: 1px !important;
    right: -2px !important;
  }
  .wi-list-filters__content {
    margin-bottom: 30px;
  }
  .wi-list-filters__footer {
    position: absolute; bottom: 0;width: 100%; z-index: 999 !important; box-shadow: 10px 10px 5px grey;
  }
</style>
