<template>
  <v-card class="elevation-17">
    <v-toolbar dense flat color="transparent" class="business-hosts__toolbar">
      <v-toolbar-title>
        <v-icon color="primary">dns</v-icon>
        <span> Dispositivo(s)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list v-if="hosts && hosts.length > 0" two-line subheader>
      <template v-for="(host, hostIndex) in hosts">
        <v-divider v-if="hostIndex" :key="hostIndex + '-divider'"></v-divider>
        <Host
          :host="host.host"
          :key="hostIndex"
          @refresh="refresh"
          @onSelectModel="refresh"
        ></Host>
      </template>
    </v-list>
    <v-card-text v-else class="business-hosts__not-selected-content">
      <h4>Adicione um equipamento.</h4>
      <v-btn @click="addHost()" outline>
        <v-icon>add</v-icon>
        <span> Adicionar equipamento</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
  import Host from '@/modules/network/pages/Host'
  export default {
    name: 'BusinessHosts',
    methods: {
      addHost: function () {
        this.$WiEditDialog({
          wiConfig: 'host',
          data: {
            contract_id: this.business.id
          },
          onSubmit: this.refresh,
          redirectOnSubmit: false
        })
      },
      createDiagnostic: function (host) {
        this.$WiEditDialog({
          wiConfig: 'diagnostic',
          data: {
            business_id: this.business.id,
            business: this.business,
            contract: this.business,
            person_id: this.business.person_id,
            person: this.business.person,
            host_id: host.id,
            host: host,
          },
          onSubmit: (response) => {
            this.$emit('onSubmit', response)
          }
        })
      },
      refresh: function (data) {
        this.$emit('refresh', data)
        this.$emit('onSubmit', data)
      }
    },
    props: {
      business: {
        type: Object,
        required: true
      },
      hosts: {
        type: Array,
        default: () => []
      },
      diagnostic: {
        type: Boolean,
        default: false
      }
    },
    components: {
      Host
    }
  }
</script>
<style scoped>
  .business-hosts__toolbar {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .business-hosts__toolbar >>> .v-toolbar__content {
    padding: 0px !important;
  }
  .business-hosts__not-selected {
    text-align: center !important;
    padding: 15px !important;
  }
  .business-hosts__not-selected-content {
    padding: 14px !important;
    text-align: center;
  }

</style>
