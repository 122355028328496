<template>
  <v-list subheader two-line style="padding: 0 5px 0 0;">
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
            <img src="static/icons/monitor/today_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Início do monitoramento:</v-list-tile-sub-title>
        <v-list-tile-title>
          <strong>{{information.start_date_time_format || '---'}}</strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
            <img src="static/icons/monitor/date_start_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Último Evento (Up ou Down):</v-list-tile-sub-title>
        <v-list-tile-title>
          <strong>{{information.last_device_log || '---'}}</strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/event_time_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Última atualização dos dados:</v-list-tile-sub-title>
        <v-list-tile-title>
          <strong>{{information.last_update || '---'}}</strong>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-avatar>
          <img src="static/icons/monitor/slot_memory_ram_96.png" alt="Código/Ticket Externo">
        </v-avatar>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>
          Memória livre:
        </v-list-tile-title>
        <v-list-tile-sub-title>Memória disponível em MB.</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <strong style="font-size: 20px;">{{information.memory_free || '---'}}</strong>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>
<script>
  export default {
    name: 'NetworkServerInfoColumn3',
    data () {
      return {}
    },
    methods: {},
    props: {
      data: {
        type: Object
      },
      information: {
        type: Object
      },
      status: {
        type: String
      }
    }
  }
</script>