<template>
  <div>
    <v-card v-if="status && status.code != 'closed' && status.code != 'canceled'" flat>
      <v-card-text style="text-align: center;">
        <h3>Por favor responda o formulário.</h3>
      </v-card-text>
      <v-card-actions style="padding-bottom: 25px;">
        <v-spacer></v-spacer>
        <v-btn block outline large color="green" @click="createWiEditDialog()">
          <v-icon>contact_support</v-icon>
          <span> Responder agora </span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <FormReplyList
      v-else
      :form="data.form"
      :reply="data"
      :replyEditor="{}"
      :showHeader="false"
      :showTitle="false"
    ></FormReplyList>
  </div>
</template>
<script>
  import FormReplyEditor from '@/modules/forms/config/Form/FormReplyEditor'
  import FormReplyList from '@/modules/forms/pages/FormReplyList'
  export default {
    name: 'FormReply',
    methods: {
      updateProcess: function (response) {
        this.$emit('updateProcess', {
          data: {
            form_reply_id: response.data.data.id
          },
          isValid: !!response.data.data.id,
          autoSendProcessStage: true
        })
      },
      createWiEditDialog: async function () {
        this.createReplyLoading = true
        const config = await FormReplyEditor(this.stage.stage.config.form_id)
        const wiConfig = 'form-' + this.stage.stage.config.form_id
        this.$store.commit('pages/ADD_CONFIG', {
          name: wiConfig,
          config: config
        })
        this.$WiEditDialog({
          wiConfig: wiConfig,
          data: {},
          redirectOnSubmit: false,
          onSubmit: this.updateProcess
        })
        this.createReplyLoading = false
      }
    },
    props: {
      status: {
        type: Object,
        default: () => ({})
      },
      stage: {
        type: Object,
        default: () => ({})
      },
      data: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      FormReplyList
    }
  }
</script>
<style scoped>
  .diagnostic-set-status {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>