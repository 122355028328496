export default {
  id: {
    id: '7517de8a',
    create: 'b248147d',
    update: '3c8cd4ef',
    delete: '101ce7ab'
  },
  actions: {
    update: true
  },
  title: 'Mover Rede',
  icon: 'compare_arrows',
  apiUrl: 'network',
  maxWidth: '500px',
  fields: [
    {
      text: 'Selecione as opções abaixo para mover esta rede.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'grupo_dados_id',
      text: 'Selecione para qual rede deseja mover.',
      type: 'db-autocomplete',
      edit: true,
      apiUrl: 'network',
      required: true,
      apiParams: {
        order: 'grupo_tipo_id,asc'
      },
      show: 'nome_grupo'
    },
    // {
    //   value: 'change_tickets',
    //   text: 'Alterar Chamados vinculados a esta rede',
    //   size: ['xs12', 'sm12', 'md5', 'lg5'],
    //   type: 'select',
    //   edit: true,
    //   required: true,
    //   options: [
    //     { value: '35cb317a-04f8-4292-93b2-755440b0291d', text: 'Sim, alterar todos os chamados vinculados' }
    //     // { value: 'no', text: 'Não alterar chamados' }
    //   ]
    // }
  ]
}
