export default [
  {
    id: '1d91353e',
    icon: 'web',
    title: 'Sites',
    route: {
      name: 'sites-list',
      params: {
        page: 'site'
      }
    }
  },
  {
    id: '1d91353e',
    icon: 'dynamic_feed',
    title: 'Publicações',
    route: {
      name: 'sites-list',
      params: {
        page: 'post'
      }
    },
    children: [
      {
        id: '1d91353e',
        icon: 'public',
        title: 'Publicações Públicas',
        route: {
          name: 'sites-list',
          params: {
            page: 'post-public'
          }
        }
      },
      {
        id: '1d91353e',
        icon: 'lock',
        title: 'Publicações Privadas',
        route: {
          name: 'sites-list',
          params: {
            page: 'post-private'
          }
        }
      },
      {
        id: '1d91353e',
        icon: 'dynamic_feed',
        title: 'Todas as Publicações',
        route: {
          name: 'sites-list',
          params: {
            page: 'post'
          }
        }
      }
    ]
  },
  {
    id: '1230c290',
    icon: 'receipt_long',
    title: 'Seguidores de Autores',
    route: {
      name: 'sites-list',
      params: {
        page: 'author-follower'
      }
    }
  },
  {
    id: '95f7d3fe',
    icon: 'portrait',
    title: 'Autores',
    route: {
      name: 'sites-list',
      params: {
        page: 'author'
      }
    }
  },
  {
    id: 'a02e979e',
    icon: 'people_outline',
    title: 'Clientes',
    route: {
      name: 'sites-list',
      params: {
        page: 'sites-customer'
      }
    },
    selected: false
  }
]