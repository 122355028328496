<template>
  <div>
    <v-btn
      v-if="config.mode != 'select'"
      v-prms="{'id': getIdPermission('delete', config.config), mode: 'disabled'}"
      icon
      @click="deleteSelected()"
    >
      <v-icon color="red">delete</v-icon>
    </v-btn>    
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'WiListDataTableDeleteSelected',
    data () {
      return {}
    },
    props: {
      'config': {
        type: Object,
        required: true
      }
    },
    computed: {
      selected () {
        return this.config.selected || []
      }
    },
    methods: {
      getIdPermission: function (action, config) {
        if (config && config.id) {
          return config.id[action] || config.id
        }
        return null
      },
      deleteSelected: function () {
        console.log(this.selected)
        var timerInterval = null
        this.$swal.mixin({
          progressSteps: ['1', '2'],
          onClose: () => {
            clearInterval(timerInterval)
          }
        })
        .queue([
          {
            title: 'Você está prestes a apagar ' + this.selected.length + ' registro(s). Deseja continuar?',
            html: 'Para confirmar clique em OK logo abaixo',
            type: 'warning',
            showCancelButton: true,
            timerProgressBar: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancelar',
            preConfirm: (text) => {
              console.log(text)
              // if (text !== 'DELETE') {
              //   var countSeconds = 5
              //   this.$swal.showValidationMessage('Você digitou algo errado. Cancelando em ' + countSeconds + 's.')
              //   timerInterval = setInterval(() => {
              //     countSeconds--
              //     this.$swal.showValidationMessage('Você digitou algo errado. Cancelando em ' + countSeconds + 's.')
              //   }, 1000)
              //   setTimeout(() => {
              //     clearInterval(timerInterval)
              //     this.$swal.close()
              //   }, 5000)
              // }
            },
            onClose: () => {
              clearInterval(timerInterval)
            }
          },
          {
            title: 'Último aviso! Você irá apagar ' + this.selected.length + ' registro(s). Tem certeza disso?',
            html: 'Esta ação nunca mais poderá ser desfeita! O sistema irá registrar sua ação.<b></b>',
            type: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Apagar ' + this.selected.length + ' registros!',
            cancelButtonText: 'Cancelar',
            onBeforeOpen: () => {
              var countSeconds = 20
              this.$swal.getContent().querySelector('b').textContent = ' Cancelando em ' + countSeconds + 's.'
              timerInterval = setInterval(() => {
                countSeconds--
                this.$swal.getContent().querySelector('b').textContent = ' Cancelando em ' + countSeconds + 's.'
              }, 1000)
              setTimeout(() => {
                clearInterval(timerInterval)
                this.$swal.close()
              }, 20000)
            },
            onClose: () => {
              clearInterval(timerInterval)
            }
          }
        ])
        .then((reply) => {
          console.log(reply)
          clearInterval(timerInterval)
          var sendSelected = []
          this.selected.filter((item) => {
            sendSelected.push({id: item.id})
          })
          if (reply.value[0] && reply.value[1]) {
            callApi.delete({uri: this.config.config.apiUrl, id: 'multiple', data: sendSelected, sucess: this.config.refresh, msgLoad: true, msgSucess: true, msgError: true})
          }
        })
      }
    }
  }
</script>
<style scoped>
  .permission_disabled >>> * {
    color: #B0B0B0 !important;
  }
</style>