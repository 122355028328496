import { Model } from '@/default/model/Model'
import {
  PERMISSION_FORM,
  PERMISSION_FORM_CREATE,
  PERMISSION_FORM_UPDATE,
  PERMISSION_FORM_DELETE
} from '@/default/constants/permissions'

export class ProcessStage extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_FORM,
      create: PERMISSION_FORM_CREATE,
      update: PERMISSION_FORM_UPDATE,
      delete: PERMISSION_FORM_DELETE
    }
    this.name = 'process-stage'
    this.title = {
      list: 'Etapas do Processo',
      edit: {
        insert: 'Nova Etapa',
        update: 'Editar Etapa'
      }
    }
    this.search = {
      placeholder: 'Pesquise pelo nome da etapa ou nível',
      fields: ['id', 'name', 'order']
    }
    this.params = {
      limit: 10,
      order: 'order,asc'
    }
    this.icon = 'account_tree'
    this.apiUrl = 'processes/stage'
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Processes/ProcessStages')
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    name: this.fieldList({
      value: 'name',
      text: 'Nome da etapa'
    }),
    type_id: this.fieldList({
      value: 'type_id',
      text: 'Tipo'
    }),
    results_count: this.fieldList({
      value: 'results_count',
      text: 'N° Resultados',
      sortable: false
    }),
    count: this.fieldList({
      value: 'count',
      text: 'N° Execuções'
    }),
    active: this.fieldList({
      value: 'active',
      text: 'Status'
    }),
    results: this.fieldList({
      value: 'results',
      text: 'Resultados',
      sortable: false
    })
  }

  configMessageFields = {
    subHeaderMessage: this.fieldSubHeader({
      text: 'Informe os dados da mensagem que será exibida.',
      showField: form => (form?.type_id == 4)
    }),
    config_message: this.fieldEditor({
      value: 'config_message',
      text: 'Conteúdo da mensagem',
      placeholder: 'Ex: Mensagem que será exibida para o usuário...',
      showField: form => (form?.type_id == 4),
      required: true
    }),
    config_color: this.fieldColor({
      value: 'config_color',
      text: 'Cor da mensagem',
      showField: form => (form?.type_id == 4)
    })
  }

  configDecisionFields = {
    subHeaderDecision: this.fieldSubHeader({
      text: 'Informe os dados da tomada de decisão que será exibida.',
      showField: form => (form?.type_id == 9)
    }),
    DecisionConfigMessage: this.fieldEditor({
      value: 'config_message',
      text: 'Conteúdo da mensagem',
      placeholder: 'Ex: Mensagem que será exibida para o usuário...',
      showField: form => (form?.type_id == 9),
      required: true
    }),
    DecisionConfigColor: this.fieldColor({
      value: 'config_color',
      text: 'Cor da mensagem',
      showField: form => (form?.type_id == 9)
    })
  }

  configFormFields = {
    subHeaderForm: this.fieldSubHeader({
      text: 'Informe formulário/checklist que o usuário terá de responder.',
      showField: form => (form?.type_id == 7)
    }),
    config_form_id: this.fieldWiListDialog({
      value: 'config_form_id',
      text: 'Formulário/Checklist',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      wiConfig: 'service-desk-config-form',
      show: 'config_form_name',
      icon: 'list_alt',
      showField: form => (form?.type_id == 7)
    }),
    DiagnosticFormIdReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 7),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    })
  }

  configSetStatusFields = {
    subHeaderSetStatus: this.fieldSubHeader({
      text: 'Informe o status que será definido.',
      showField: form => (form?.type_id == 2)
    }),
    reference_stage_id: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 2),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    }),
    config_status_id: this.fieldSelectApi({
      value: 'config_status_id',
      text: 'Status do Diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 2),
      api: {
        url: 'networks/config/status/test-management-vtal'
      },
      show: 'name'
    })
  }

  configSetCategoryFields = {
    DiagnosticSetCategorySubHeader: this.fieldSubHeader({
      text: 'Informe a categoria que será definida.',
      showField: form => (form?.type_id == 3)
    }),
    DiagnosticSetCategoryReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 3),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    }),
    DiagnosticSetCategoryConfigCategoryId: this.fieldSelectApi({
      value: 'config_category_id',
      text: 'Categoria do Diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 3),
      api: {
        url: 'networks/config/category/test-management-vtal'
      },
      show: 'name'
    })
  }

  configSelectCategoryFields = {
    DiagnosticSelectCategorySubHeader: this.fieldSubHeader({
      text: 'Selecione a etapa de criação do diagnóstico',
      showField: form => (form?.type_id == 10)
    }),
    DiagnosticSelectCategoryReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 10),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    }),
    DiagnosticSelectCategoryConfigCategoryIds: this.fieldSelectApi({
      value: 'config_category_ids',
      text: 'Categorias do Diagnóstico',
      required: true,
      multiple: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => (form?.type_id == 10),
      api: {
        url: 'networks/config/category/test-management-vtal'
      },
      show: 'name'
    })
  }

  configCheckCategoryFields = {
    DiagnosticCheckCategorySubHeader: this.fieldSubHeader({
      text: 'Selecione a etapa de criação do diagnóstico',
      showField: form => (form?.type_id == 11)
    }),
    DiagnosticCheckCategoryReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (form?.type_id == 11),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    })
  }

  configShowDiagnosticEventsFields = {
    DiagnosticShowEventsSubHeader: this.fieldSubHeader({
      text: 'Selecione a etapa de criação do diagnóstico',
      showField: form => (form?.type_id == 14)
    }),
    DiagnosticShowEventsReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (form?.type_id == 14),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    })
  }

  configCheckHostTechnologyFields = {
    DiagnosticCheckHostTechnologySubHeader: this.fieldSubHeader({
      text: 'Selecione a etapa de criação do diagnóstico',
      showField: form => (form?.type_id == 12)
    }),
    DiagnosticCheckHostTechnologyReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (form?.type_id == 12),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    })
  }

  configCheckHostFields = {
    DiagnosticCheckHostSubHeader: this.fieldSubHeader({
      text: 'Selecione a etapa de criação do diagnóstico',
      showField: form => (form?.type_id == 5)
    }),
    DiagnosticCheckHostReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (form?.type_id == 5),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    })
  }

  configDiagnosticCreateDocumentFields = {
    DiagnosticCreateDocumentHeader: this.fieldSubHeader({
      text: 'Selecione a etapa de criação do diagnóstico',
      showField: form => (form?.type_id == 8)
    }),
    DiagnosticCreateDocumentReferenceStageId: this.fieldSelectApi({
      value: 'reference_stage_id',
      text: 'Etapa de criação do diagnóstico',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => (form?.type_id == 8),
      api: {
        url: 'processes/stage',
        params: form => ({
          process_id: form.process_id,
          type_id: 1
        })
      },
      show: 'name'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o processo e tipo de etapa.'
    }),
    type_id: this.fieldSelectApi({
      value: 'type_id',
      text: 'Tipo de Etapa',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      disabled: form => !!form.id,
      api: {
        url: 'processes/stage/type'
      },
      show: 'name',
      icon: 'icon'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da etapa',
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      required: true
    }),
    active: this.fieldSwitch({
      value: 'active',
      text: 'Está ativo?',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    ...this.configFormFields,
    ...this.configMessageFields,
    ...this.configDecisionFields,
    ...this.configCheckHostFields,
    ...this.configSetStatusFields,
    ...this.configSetCategoryFields,
    ...this.configCheckCategoryFields,
    ...this.configSelectCategoryFields,
    ...this.configCheckHostTechnologyFields,
    ...this.configShowDiagnosticEventsFields,
    ...this.configDiagnosticCreateDocumentFields,
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para descrição da etapa.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição do Formulário',
      placeholder: 'Ex: Formulário de coleta de dados...'
    })
  }
}
