<template>
  <WiView v-if="apiParams" index="talks-dashboard" apiUrl="talks/report/message/dashboard" :apiParams="apiParams" :notShowToolbar="true" :notGetID="true" @afterLoad="setContent">
    <template slot-scope="{ content, refresh }">
      <v-container style="padding: 10px;" fluid grid-list-md>
        <v-layout row wrap>
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-btn icon ripple @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-list-tile v-ripple avatar class="hidden-xs-only hidden-sm-only">
                <v-list-tile-avatar @click="refresh()" style="cursor: pointer;" size="45">
                  <img src="static/systems/modules/wises-technology-module-talk.png" alt="Dashboard Atendimentos">
                </v-list-tile-avatar>
                <v-list-tile-content @click="refresh()" style="cursor: pointer;">
                  <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                  <v-list-tile-title style="font-size:18px;">
                    <span>Atendimentos</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outline small>
                    <span class="pr-2 hidden-xs-only hidden-sm-only">Data inicial: </span>
                    <strong class="pr-2">{{ dateStart | moment('DD/MM/YY HH:mm') }}</strong>
                    <span class="pr-2 hidden-xs-only hidden-sm-only">Data final: </span>
                    <strong>{{ dateEnd | moment('DD/MM/YY HH:mm') }}</strong>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title>Insira uma data inicial e final.</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-text-field
                      dense
                      outline
                      v-model="dateStart"
                      type="datetime-local"
                      label="Data Inicial"
                    ></v-text-field>
                    <br>
                    <v-text-field
                      dense
                      outline
                      v-model="dateEnd"
                      type="datetime-local"
                      label="Data Final"
                    ></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn block outline color="green" @click="changeFilters()">
                      <v-icon>filter_list</v-icon>
                      <span>filtrar</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <span>*Dados obtidos em {{ content.cached_at | moment('DD/MM/YY à\\s HH:mm:ss') }}.</span>
            <span v-if="cacheExpiredAt >= 0"> Novos dados disponíveis em {{ cacheExpiredAt | number }} segundos ({{ content.cached_end_at | moment('DD/MM/YY à\\s HH:mm:ss') }}).</span>
            <span v-else @click="refresh()" style="cursor: pointer;"> Clique aqui para obter novos dados.</span>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Enviadas"
              icon="upload"
              color="#25D366"
              :data="content.statistics.total_messages_sent | number"
              :progress="100"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Recebidas"
              icon="download"
              color="#34B7F1"
              :data="content.statistics.total_messages_received | number"
              :progress="100"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Entregues"
              icon="done"
              color="#128C7E"
              :data="content.statistics.total_messages_sent_delivered | number"
              :progress="content.statistics.percentage_messages_sent_delivered"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Visualizadas"
              icon="done_all"
              color="blue"
              :data="content.statistics.total_messages_sent_viewed | number"
              :progress="content.statistics.percentage_messages_sent_viewed"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Mens. Campanhas"
              icon="campaign"
              color="orange"
              :data="content.statistics.total_messages_from_campaign | number"
              :progress="content.statistics.percentage_messages_from_campaign"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Contatos inválidos"
              icon="manage_accounts"
              color="#D32F2F"
              :data="content.statistics.total_messages_sent_error | number"
              :progress="content.statistics.percentage_messages_sent_error"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="mt-2 mb-5">
            <v-card class="elevation-10" style="border-radius: 10px;">
              <v-card-title>
                <h4>Canais de Atendimento</h4>
                <v-spacer></v-spacer>
                <v-btn outline small @click="exportChannelsStats()">
                  <v-icon left>download</v-icon>
                  Exportar Estatísticas
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-data-table
                :headers="headersTeams"
                :items="content.by_channels"
                disable-initial-sort
                :rows-per-page-items="[50, 5, 10, 100, 200]"
                rows-per-page-text="Ítens por página"
                style="border-radius: 10px;"
              >
                <template v-slot:items="props">
                  <td style="padding-left: 20px !important;">
                    <v-list dense one-line color="transparent" style="background: transparent; padding: 0 !important; margin: 0 !important;">
                      <v-list-tile avatar class="talk-dashboard-channel-item hidden-xs-only hidden-sm-only" style="padding: 0 !important; margin: 0 !important;">
                        <v-list-tile-avatar size="38">
                          <img src="static/icons/monitor/whatsapp.png" :alt="props.item.name">
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title style="font-size: 12px;">
                            <h2>
                              {{ props.item.name }}
                            </h2>
                          </v-list-tile-title>
                          <v-list-tile-sub-title style="font-size: 12px;">
                            <label style="color: grey;">
                              {{ props.item.recipient }}
                            </label>
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </td>
                  <td>
                    <h2>{{ props.item.total_messages_sent | number }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.total_messages_received | number }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.total_messages_sent_delivered | number }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.total_messages_sent_viewed | number }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.total_messages_from_campaign | number }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.total_messages_from_campaign_with_response | number }}</h2>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </WiView>
</template>
<script>
import DashboardTalksCard from '@/modules/talk/pages/Dashboards/DashboardCard'

const headers = [
  // { text: 'Canal', value: 'name' },
  { text: 'Enviadas', value: 'total_messages_sent' },
  { text: 'Recebidas', value: 'total_messages_received' },
  { text: 'Entregues', value: 'total_messages_sent_delivered' },
  { text: 'Visualizadas', value: 'total_messages_sent_viewed' },
  { text: 'Mens. Campanhas', value: 'total_messages_from_campaign' },
  { text: 'Retornos de Campanhas', value: 'total_campaign_replies' }
]

export default {
  name: 'dashboard-talks',
  data: () => ({
    dialog: false,
    content: {},
    refresh: null,
    apiParams: null,
    dateStart: null,
    dateEnd: null,
    headersOperators: [
      {
        text: 'Operador',
        align: 'left',
        value: 'name'
      },
      ...headers
    ],
    headersTeams: [
      {
        text: 'Canal',
        align: 'left',
        value: 'name'
      },
      ...headers
    ],
    cacheExpiredAt: 0,
    cacheExpiredAtIntercal: null
  }),
  methods: {
    setContent: function ({ content, refresh }) {
      this.content = content
      this.refresh = refresh
      this.setCacheExpiredAt(content?.cache_expire_in || 0)
    },
    setInitialDateInterval: function () {
      this.dateStart = this.$DateTime.date() + 'T00:00:00'
      this.dateEnd = this.$DateTime.date() + 'T23:59:59'
    },
    setApiParams: function () {
      if (this.dateStart && this.dateEnd) {
        this.apiParams = `?dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`
        this.$forceUpdate()
      }
    },
    changeFilters: function () {
      this.setApiParams()
      setTimeout(() => {
        this.refresh()
        this.dialog = false
      }, 50);
    },
    setCacheExpiredAt: function (cacheExpiredAt) {
      this.cacheExpiredAt = cacheExpiredAt
      clearInterval(this.cacheExpiredAtIntercal)
      this.cacheExpiredAtIntercal = setInterval(() => {
        this.cacheExpiredAt = this.cacheExpiredAt - 1
      }, 1000);
    },
    exportChannelsStats: function () {
      console.log({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }, 'exportChannelsStats')
      this.$WiApiPost({
        uri: 'talks/report/message/dashboard/export/by-channel',
        data: {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd
        },
        config: {
          msgLoad: {
            title: `Exportando estatísticas...`,
            text: 'Não irá demorar mais que alguns segundos.'
          },
          msgSucess: {
            title: 'Solicitação enviada com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja exportar as estatísticas?`,
            text: 'Esta ação não poderá ser desfeita.'
          }
        },
        callback: function (response) {
          if (response?.sucess?.data?.file_url) {
            window.open(response?.sucess?.data?.file_url, '_blank')
          }
        }
      })
    }
  },
  created: function () {
    this.setInitialDateInterval()
    this.setApiParams()    
  },
  components: {
    DashboardTalksCard
  }
}
</script>
<style scoped>
.talk-dashboard-channel-item >>> .v-list__tile {
  padding: 0 !important;
  margin: 0 !important;
}
</style>