import {
  Lang
} from '@/default/service/Lang'

export default {
  list: true,
  title: {
    list: 'Endereços',
    edit: {
      create: Lang.langText('register.config.person.address.title.edit.create', 'Novo Endereço'),
      update: Lang.langText('register.config.person.address.title.edit.update', 'Editar Endereço')
    }
  },
  icon: 'location_on',
  search: {
    placeholder: 'Pesquise pelo endereço, bairro ou CEP.',
    fields: ['id', 'address', 'district', 'zip_code']
  },
  apiUrl: 'register/person/address',
  maxWidth: '1000px',
  fields: [
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      required: true,
      list: true,
      edit: false
    },
    {
      value: 'address',
      text: 'Endereço/Logradouro',
      type: 'text',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      placeholder: 'Digite o endereço',
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'number',
      text: 'Número',
      type: 'text',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      placeholder: 'Ex: 820',
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'district',
      text: Lang.langText('register.config.person.address.fields.district.text', 'Bairro'),
      type: 'text',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      placeholder: 'Digite o bairro',
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'complement',
      text: 'Complemento',
      placeholder: 'Ex: APT. 201',
      type: 'text',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      list: true,
      edit: true
    },
    {
      value: 'zip_code',
      text: Lang.langText('register.config.person.address.fields.zip_code.text', 'CEP'),
      type: 'text',
      placeholder: Lang.langText('register.config.person.address.fields.zip_code.mask', '#####-###'),
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      mask: Lang.langText('register.config.person.address.fields.zip_code.mask', '#####-###'),
      required: true,
      list: true,
      edit: true
    },
    {
      value: 'iso',
      text: 'Cidade',
      required: true,
      type: 'db-autocomplete',
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      list: false,
      edit: true,
      autocomplete: true,
      placeholder: 'Selecione uma cidade',
      apiUrl: 'register/city',
      apiParams: {
        limit: 10000,
        order: 'name,asc'
      },
      show: 'name_and_state',
      selectValue: 'iso',
      showField: (form) => {
        return form.iso
      }
    },
    {
      value: 'city_id',
      text: Lang.langText('register.config.person.address.fields.city_id.text', 'Cidade'),
      required: true,
      type: 'db-autocomplete',
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      list: false,
      edit: true,
      autocomplete: true,
      apiUrl: 'register/city',
      placeholder: 'Selecione uma cidade',
      apiParams: {
        limit: 10000,
        order: 'name,asc'
      },
      show: 'name_and_state',
      showField: (form) => {
        return !form.iso
      }
    },
    {
      value: 'is_master',
      text: 'Endereço Principal',
      type: 'switch',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [{
        value: false,
        text: 'Não'
      }, {
        value: true,
        text: 'Sim'
      }],
      edit: true
    },
    {
      value: 'description',
      text: 'Descrição',
      type: 'text',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      placeholder: 'Se desejar, coloque uma descrição ou observação.',
      list: true,
      edit: true
    },
    {
      value: 'latitude',
      text: 'Latitude',
      type: 'location',
      location: 'latitude',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      list: true,
      edit: true
    },
    {
      value: 'longitude',
      text: 'Longitude',
      type: 'location',
      location: 'longitude',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      list: true,
      edit: true
    }
  ]
}