<template>
  <v-dialog v-model="dialog" scrollable max-width="85%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <v-btn small outline color="green" v-on="on">
        <v-icon>add</v-icon>
        <span>Adicionar Mais de uma Rede</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title style="padding: 15px;">
        <strong>Selecione uma rede ou sub-rede para adicionar</strong>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="min-height: 300px; padding: 0;">
        <view-detail v-if="dialog" title="Minha rede" apiUrl="topologia/dados" :notGetID="true" notShowToolbar="true">
          <template slot-scope="dados">
            <v-container align="center">
              <v-layout row wrap>
                <v-flex xs12 sm7 md7 lg7 d-flex style="padding: 10px;">
                  <v-card>
                    <v-treeview :search="search" :filter="filter" :open.sync="open" open-all :items="dados.content" :load-children="fetchUsers"  open-on-click transition item-text="nome_sigla_grupo">
                      <span @click="setSelected(item)" slot="label" slot-scope="{ item }" :style="{color: selected && selected.id == item.id ? 'orange' : 'gray'}">
                        {{item.nome_grupo}} <span v-if="item && item.sigla_grupo" :style="{color: selected && selected.id == item.id ? 'orange' : '#a8a8a8'}">({{item.sigla_grupo}})</span>
                      </span>
                      <v-icon medium slot="prepend" slot-scope="{ item }" :style="{'color': item.status.cor_status}">{{item.icone_tipo || 'device_hub'}}</v-icon> 
                    </v-treeview>
                  </v-card>
                </v-flex>
                <v-flex xs12 sm5 md5 lg5 d-flex text-xs-center style="padding: 10px;">
                  <v-scroll-y-transition mode="out-in">
                    <div v-if="!selected" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center;" > Selecione uma Rede... </div>
                    <v-card v-else :key="selected.id" >
                      <v-card-text>
                        <v-avatar>
                          <v-icon v-if="selected.icone_tipo" x-large>{{selected.icone_tipo}}</v-icon> 
                          <v-icon v-else x-large>device_hub</v-icon> 
                        </v-avatar>
                        <h3 class="headline mb-2">
                          {{ selected.nome_grupo }}
                        </h3>
                        <div v-if="selected.sigla_grupo" class="gray--text subheading font-weight-bold" style="color: gray;">({{ selected.sigla_grupo }})</div>
                        <div v-if="selected.obs_grupo" class="gray--text mb-2" style="color: gray;">{{ selected.obs_grupo }}</div>
                        <div class="gray--text mb-2"><strong>{{ selected.nome_tipo }}</strong></div>
                        <div class="gray--text subheading font-weight-bold" style="padding-bottom: 10px;">{{ selected.grupo_pai }}</div>
                        <v-divider></v-divider>
                        <div style="padding-top: 10px;">
                          <v-btn small outline color="green" @click="addGrupo(selected.id, 'este')">
                            <v-icon>add</v-icon>
                            <span>Adicionar Somente Esta rede</span>
                          </v-btn>
                          <v-btn small outline color="green" @click="addGrupo(selected.id, 'sub')">
                            <v-icon>add</v-icon>
                            <span>Adicionar Todas as <span>({{selected.num_disp}})</span> Sub-redes</span>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-scroll-y-transition>
                </v-flex>
              </v-layout>
            </v-container>
          </template>
        </view-detail>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" flat @click.native="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'DialogSelectGroup',
    data: () => ({
      dialog: false,
      items: [],
      selected: null,
      open: [],
      search: null,
      caseSensitive: false
    }),
    methods: {
      async fetchUsers (item) {
        return new Promise((resolve, reject) => {
          callApi.get({
            uri: 'grupo/dados',
            params: {limit: 1000, order: 'grupo_tipo_id,asc;nome_grupo,asc', where: 'grupo_dados_id,' + item.id},
            sucess: data => {
              resolve(data.data.data)
              var itens = data.data.data.filter(function (i) {
                if (i.sub_grupos > 0) {
                  i['children'] = []
                }
                return i
              })
              item.children.push(...itens)
            },
            error: err => {
              reject(err)
              return err
            }
          })
        })
      },
      setSelected: function (id) {
        if (id) {
          this.selected = id
        }
      },
      addGrupo: function (grupo, opcao) {
        callApi.post({
          uri: 'register/team/networks',
          data: {team_id: this.$route.params.id, grupo_dados_id: grupo, opcao: opcao},
          msgLoad: true,
          msgSucess: true,
          msgError: true,
          sucess: () => {
            this.refresh()
          },
          error: err => {
            console.log(err)
          }
        })
      }
    },
    components: {
      ViewDetail
    },
    computed: {
      filter () {
        return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
      }
    },
    props: ['refresh']
  }
</script>