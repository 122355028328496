var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"wi-image-dialog__activator"},on),[_c('v-img',{attrs:{"src":_vm.src,"lazy-src":"static/icons/not-found.png","alt":_vm.title,"aspect-ratio":_vm.aspectRatio},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":"","ma-0":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("image")]),(_vm.title)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-spacer'),(_vm.download)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","target":"_blank","link":"","href":(_vm.download + "&d=true")}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("download")])],1)]}}],null,false,3164180552)},[_c('span',[_vm._v("Fazer download")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","link":"","target":"_blank","href":_vm.src}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("open_in_new")])],1)]}}])},[_c('span',[_vm._v("Abrir imagem em uma nova aba")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('v-img',{staticStyle:{"max-height":"calc(100vh - 148px)"},attrs:{"src":_vm.src,"alt":_vm.title,"contain":"","lazy-src":"static/icons/not-found.png"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":"","ma-0":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }