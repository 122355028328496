export default {
  id: '9de722f3',
  list: true,
  title: {
    list: 'Membros',
    edit: {
      insert: 'Adicionar novo membro',
      update: 'Editando Membro'
    }
  },
  icon: 'people',
  search: {
    placeholder: 'Pesquise por ID, nome ou função',
    fields: ['person_id', 'person.name', 'person.first_name', 'function']
  },
  noData: {
    text: 'Esta equipe ainda não tem nenhum membro <br> Adicione um novo membro clicando no botão abaixo.'
  },
  apiUrl: 'register/team/person',
  params: {
    limit: 5
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  fieldsSelected: {
    itemName: 'membro(s)'
  },
  fields: [
    {
      text: 'Selecione o membro logo abaixo.',
      type: 'subheader',
      edit: true
    },
    {value: 'person.id', text: 'ID', list: true},
    {value: 'person.name', text: 'Nome', list: true},
    {value: 'person.first_name', text: 'Nome Completo', list: true},
    {value: 'person_id', text: 'Nome do Membro', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md8', 'lg8'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/person/collaborator', apiParams: {fields: 'id,name'}, show: 'name'},
    {value: 'function', text: 'Função na Equipe', type: 'text', size: ['xs12', 'sm12', 'md4', 'lg4'], required: false, list: true, edit: true}
  ]
}
