<template>
	<v-layout row >
		<v-flex xs12 sm12 >
			<v-card flat>
				<v-list two-line subheader dense>
					<v-subheader>
						<strong>
              <span>Informações Complementares </span>
              <span v-if="person.files" style="padding-left: 5px;">
                ({{person.files.length}})
              </span>
            </strong>
            <v-spacer></v-spacer>
						<v-btn
              small outline color="green" 
              @click="$WiEditDialog({
                wiConfig: 'person-file',
                onSubmit: refresh,
                data: {
                  person_id: $route.params.id
                },
                redirectOnSubmit: false
              })"
            >
							<v-icon>add</v-icon>
              <span>Arquivos</span>
						</v-btn>
					</v-subheader>
          <div v-if="person.files.length > 0">
            <div v-for="(info, keyInfo) in person.files" :key="keyInfo + '-info'" >
              <v-divider inset></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-img
                    v-if="info.file.extension == 'jpeg' || info.file.extension == 'jpg' || info.file.extension == 'png'"
                    :src="info.file.url" style="border: solid 1px lightgrey;"
                  ></v-img>
                  <v-icon v-else-if="info.file.extension == 'pdf'" class="red white--text">picture_as_pdf</v-icon>
                  <v-icon v-else class="blue white--text">description</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong> {{ info.description || '- - -' }}</strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title> {{ info.file.name }}.{{ info.file.extension }} </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn 
                    v-if="info.file.url"
                    :href="info.file.url"
                    ripple outline target="_blank" small class="px-2"
                  >
                    <v-icon>open_in_new</v-icon>
                    <span class="ml-1">Acessar</span>
                  </v-btn>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-btn 
                    v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" 
                    icon
                    ripple
                    @click="$WiEditDialog({
                      wiConfig: 'person-file',
                      onSubmit: refresh,
                      data: info,
                      redirectOnSubmit: false
                    })"
                  >
                    <v-icon color="grey lighten-1">edit</v-icon>
                  </v-btn>
                  <db-delete v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" v-on:action="refresh" store="info" :id="info.id"></db-delete>
                </v-list-tile-action>
              </v-list-tile>
            </div>
          </div>
          <div v-else>
            <v-divider></v-divider>
            <div style="margin: 20px 0; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 20px;">
              <div class="title">
                <h3>Nenhum arquivo no momento</h3>
              </div>
              <div class="description">
                <p style="font-size: 14px;">Adicione arquivos para complementar as informações do cadastro.</p>
              </div>
              <div class="action">
                <v-btn
                  small outline color="green" 
                  @click="$WiEditDialog({
                    wiConfig: 'person-file',
                    onSubmit: refresh,
                    data: {
                      person_id: $route.params.id
                    },
                    redirectOnSubmit: false
                  })"
                >
                  <v-icon>add</v-icon>
                  <span>Adicionar arquivo</span>
                </v-btn>
              </div>
            </div>
          </div>
					<v-divider ></v-divider>
				</v-list>
			</v-card>
		</v-flex>
  </v-layout>
</template>
<script>
  export default {
    name: 'PersonFiles',
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      }
    },
    props: {
      person: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    }
  }
</script>