<template>
  <v-layout row class="person-tabs">
    <v-flex xs12>
      <v-tabs class="elevation-17" v-model="tabAtiva" tabs show-arrows>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab href="#tab-1">
          <span style="padding-right: 5px;">{{$WiLangText('register.page.Person.tab1', 'Telefones/Endereços')}}</span>
        </v-tab>
        <v-tab href="#tab-2">
          <span style="padding-right: 5px;">Informações Adicionais</span>
        </v-tab>
        <v-tab href="#tab-3">
          <span style="padding-right: 5px;">Arquivos</span>
        </v-tab>
        <v-tab v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}"  v-if="$route.name == 'collaborator'" href="#tab-4">
          <span style="padding-right: 5px;">Usuário do Sistema</span>
        </v-tab>
        <v-tab-item value="tab-1">
          <v-divider></v-divider>
          <PersonContactAddress
            :personName="this.$route.name"
            :person="person"
            :refresh="refresh"
          ></PersonContactAddress>
        </v-tab-item>
        <v-tab-item value="tab-2" >
          <v-divider></v-divider>
          <PersonInformation 
            :person="person"
            :refresh="refresh"
          ></PersonInformation>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-divider></v-divider>
          <PersonFiles
            :personName="this.$route.name"
            :person="person"
            :refresh="refresh"
          ></PersonFiles>
        </v-tab-item>
        <v-tab-item
          v-prms="{'id': wiConfig.id.update, 'notCheck': person.id == user.id}" 
          value="tab-4"
        >
          <v-divider></v-divider>
          <PersonUsers
            :person="person"
            :refresh="refresh"
          ></PersonUsers>
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </v-layout>
</template>

<script>
  import PersonUsers from './PersonUsers'
  import PersonFiles from './PersonFiles'
  import PersonContactAddress from './PersonContactAddress'
  import PersonInformation from './PersonInformation'
  export default {
    name: 'PersonTabs',
    data () {
      return {
        tabAtiva: 'tab-1'
      }
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      }
    },
    props: {
      person: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      PersonUsers,
      PersonFiles,
      PersonInformation,
      PersonContactAddress
    }
  }
</script>
<style scoped>
  .person-tabs {
    margin-top: 20px;
  }
</style>