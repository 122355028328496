import router from '@/modules/juridical/routes'
import config from '@/modules/juridical/config'
import menu from '@/modules/juridical/menu'

export default {
  id: 'e80a117e-895f-48be-8c1a-47fd93f5b814',
  name: 'juridical',
  title: 'Jurídico',
  description: 'Gestão de Processos Jurídicos',
  icon: 'wises-technology-module-juridical.png',
  iconSystem: 'wises-technology-system-juridical.png',
  iconProfile: 'profile_service_desk.png',
  color: '#613E24',
  menu,
  router,
  config
}
