import ServidorDados from '@/modules/network/config/Servidor/ServidorDados'
import Disp from '@/modules/network/config/Disp/DispDados'
import DispNaoGrupo from '@/modules/network/config/Disp/DispNaoGrupo'
import DispTipo from '@/modules/network/config/Disp/DispTipo'
import DispConexao from '@/modules/network/config/Disp/DispConexao'
import Mapa from '@/modules/network/config/Mapa/Mapa'
import GrupoTipo from '@/modules/network/config/Grupo/GrupoTipo'
import GrupoDados from '@/modules/network/config/Grupo/GrupoDados'
import GrupoDadosCidades from '@/modules/network/config/Grupo/GrupoDadosCidades'
import GrupoDadosRegionais from '@/modules/network/config/Grupo/GrupoDadosRegionais'
import GrupoDadosEstacoes from '@/modules/network/config/Grupo/GrupoDadosEstacoes'
import GrupoConexao from '@/modules/network/config/Grupo/GrupoConexao'
import GrupoPai from '@/modules/network/config/Grupo/GrupoPai'
import EquipamentoDados from '@/modules/network/config/Equipamento/EquipamentoDados'
import EquipamentoFabricante from '@/modules/network/config/Equipamento/EquipamentoFabricante'

import Network from '@/modules/network/config/Network/Network'
import { NetworkType } from '@/modules/network/config/Network/NetworkType'
import { NetworkUser } from '@/modules/network/config/Network/NetworkUser'
import NetworkMove from '@/modules/network/config/Network/NetworkMove'
import Device from '@/modules/network/config/Device/Device'

import { Host } from '@/modules/network/config/Host/Host'
import { Producer } from '@/modules/network/config/Producer/Producer'
import { ProducerModel } from '@/modules/network/config/Producer/ProducerModel'

import { DeviceEvent } from '@/modules/network/config/Device/DeviceEvent'

export default {
  'servidores': ServidorDados,
  'dispositivos': Disp,
  'dispositivos-nao-grupo': DispNaoGrupo,
  'dispositivos-tipo': DispTipo,
  'dispositivos-conexao': DispConexao,
  'mapas': Mapa,
  'grupo-tipos': GrupoTipo,
  'grupos': GrupoDados,
  'grupos-cidades': GrupoDadosCidades,
  'grupos-regionais': GrupoDadosRegionais,
  'grupos-estacoes': GrupoDadosEstacoes,
  'grupo-conexao': GrupoConexao,
  'grupo-pai': GrupoPai,
  'equipamentos': EquipamentoDados,
  'fabricantes': EquipamentoFabricante,
  'networks': Network,
  'network-type': new NetworkType(),
  'network-user': new NetworkUser(),
  'network-move': NetworkMove,
  'devices': Device,
  'host': new Host(),
  'producer': new Producer(),
  'producer-model': new ProducerModel(),
  'device-event': new DeviceEvent()
}
