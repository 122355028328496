export default {
  methods: {
    restartExecution: function (processId) {
      this.$WiApiPost({
        uri: 'processes/execution',
        data: {
          'process_id': processId,
          'status_id': 1
        },
        callback: response => {
          if (response.sucess && response?.sucess?.id) {
            this.$router.push({
              name: 'process-execution',
              params: {
                id: response.sucess.id
              }
            })
          }
        },
        config: {
          msgLoad: {
            title: `Reiniciando processo...`,
            text: 'Não irá demorar mais que alguns segundos.'
          },
          msgSucess: {
            title: 'Novo processo iniciado com sucesso.',
            text: 'Insira os dados solicitados para avançar etapas.'
          },
          msgError: {
            title: 'Erro ao reiniciar processo.',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja iniciar um novo processo?`,
            text: 'Esta ação não poderá ser desfeita.'
          }
        }
      })
    }
  }
}
