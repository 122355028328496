import { Transfer } from './Transfer'
import {
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_CREATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_UPDATE,
  PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_DELETE
} from '@/default/constants/permissions'

export class TransferOutCustomer extends Transfer {
  constructor () {
    super()
    this.id = {
      id: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER,
      create: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_CREATE,
      update: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_UPDATE,
      delete: PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_DELETE
    }
    this.name = 'product-transfer-out-customer'
    this.title.list = 'Transfer de um Cliente'
    this.apiUrl = 'stocks/product-transfer-out/customer'
    this.urlDetails.name = 'product-transfer-out-customer'
    this.icon = 'group_add'
    // Fields
    this.fields[2].text = 'Cliente'
    this.fields[2].wiConfig = 'customer'
    this.fields[3].apiUrl = 'sales/config/status/product-transfer-out-customer'
    this.fields[4].apiUrl = 'sales/config/category/product-transfer-out-customer'
    this.fields[12].text = 'Cliente'
  }
}
